import { Grid, IconButton } from '@mui/material'
import { PendingActions as CheckIcon } from '@mui/icons-material'
import { styled } from '@mui/system'
import { useHistory } from 'react-router-dom'

import { ROUTES_PATHS } from 'core/routes'

const StyledApprovalIcon = styled(IconButton)(() => ({
  padding: '2px',
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 0 rgba(214, 17, 24, 0.7)',
    },

    '70%': {
      transform: 'scale(1.25)',
      boxShadow: '0 0 0 16px rgba(214, 17, 24, 0)',
    },

    '100%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 0 rgba(214, 17, 24, 0)',
    },
  },
  animation: 'pulse 2s infinite',
}))

export default function TopbarApprovals({ approvalsRequestsCount }) {
  const history = useHistory()

  if (!approvalsRequestsCount) return null

  return (
    <Grid item>
      <StyledApprovalIcon
        sx={{ padding: '2px' }}
        onClick={() => history.push(ROUTES_PATHS.approvals)}
      >
        <CheckIcon sx={{ color: 'error.dark', fontSize: '1.2em' }} />
      </StyledApprovalIcon>
    </Grid>
  )
}
