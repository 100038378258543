import { Box, Grid } from '@mui/material'

import { AdminMode, SpoofMode } from 'components'

import { LayoutType } from 'types/pageLayout'

/**
 * BlankLayout consists on a plain page with a wrapped JSX element (children)
 * @param {*} children
 */

export default function BlankLayout({
  children,
  layoutProps = { backgroundColor: '' },
}: LayoutType) {
  const { backgroundColor } = layoutProps

  return (
    <>
      <Grid
        container
        sx={{
          minHeight: '100vh',
          background: backgroundColor
            ? backgroundColor
            : 'linear-gradient(127.84deg, #bbf7f7 0%, #f3e1fc 100%)',
        }}
      >
        <Grid item xs={12}>
          <Box height={1}>
            <Grid container justifyContent='center' style={{ height: '100%' }}>
              <Grid item xs={12} style={{ height: '100%' }}>
                {children}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <AdminMode />
      <SpoofMode />
    </>
  )
}
