import * as React from 'react'

export default function FitIcon(props) {
  return (
    <svg
      width={101.234}
      height={101.234}
      viewBox='0 0 101.234 101.234'
      {...props}
    >
      <defs>
        <clipPath id='prefix__a'>
          <path
            data-name='Path 547'
            d='M0 24.587h60.329V-33.2H0z'
            transform='translate(0 33.196)'
            fill='none'
          />
        </clipPath>
      </defs>
      <g data-name='Group 415' transform='translate(-909 -2347.766)'>
        <circle
          data-name='Ellipse 27'
          cx={50.617}
          cy={50.617}
          r={50.617}
          transform='translate(909 2347.766)'
          fill='#fff'
        />
        <g
          data-name='Group 413'
          transform='translate(930.33 2369.609)'
          clipPath='url(#prefix__a)'
        >
          <g data-name='Group 412'>
            <path
              data-name='Path 546'
              d='M1.215 48.727s4.183 4.548 7.847 1.812 4.977-8.035 7.793-3.528a114.558 114.558 0 015.686 10.773s6.679-1.488 11.429-5.351 3.54-5.795 2.414-6.881-9.053-3.744-2.856-10.3c5.464-2.966 8.249 2.456 8.249 2.456s-.79 6.578 3.629 5.789 9.972-6.232 9.972-6.232l3.46-3.057s-5.792-7.124-6.84-10.948.764-4.507 3.1-5.069 6.479-1.81 4.87-7.967c-2.981-5.3-8.69-1.73-8.69-1.73s-5.051 6.961-6.44 4.023a28.809 28.809 0 01-4.907-8.571 16.984 16.984 0 00-1.57-3.943S29.95 3.02 26.612 5.554s-6.2 6.559.24 7.908c4.425 5.292-.581 9.091-.581 9.091s-4.652 2.233-7.066-1.629c-1.207-1.93.752-3.097-1.407-5.835-1.85-1.53-2.816-.926-5.17.32S1.767 22.866 1.767 22.866a27.432 27.432 0 015.831 9.337c1.944 5.5 1.622 5.7-1.276 7.511s-8.89 1.528-5.107 9.013'
              fill='#f88d35'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
