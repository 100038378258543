import {
  Button,
  ButtonProps,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material'
import { t } from 'i18n'

type Props = {
  dialogOpen: boolean
  setDialogOpen(value: boolean): void
  message: string
  item: any
  children?: React.ReactNode
  actionProps: {
    onClick(item?: any): void
    isLoading: boolean
    label?: string
    color?: ButtonProps['color']
    disabled?: boolean
  }
}

export default function ConfirmationDialog({
  dialogOpen,
  setDialogOpen,
  message,
  item,
  actionProps,
  children,
}: Props) {
  const {
    label = 'Confirm',
    color = 'primary',
    onClick,
    isLoading,
    disabled,
  } = actionProps

  const handleClose = () => {
    setDialogOpen(false)
  }

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      aria-labelledby='confirmation-dialog'
      aria-describedby={'confirmation-dialog'}
    >
      <DialogContent>
        <DialogContentText
          id={'confirmation-dialog-message'}
          data-cy='confirmationDialogMessage'
        >
          {message}
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          {t('cancel')}
        </Button>
        <Button
          onClick={() => onClick(item)}
          color={color}
          autoFocus
          disabled={disabled ?? isLoading}
          data-cy='confirmButton'
        >
          {isLoading ? <CircularProgress size={24} color={color} /> : label}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
