import { Grid, TextField } from '@mui/material'
import { useIntl } from 'react-intl'
import * as yup from 'yup'

import { FormHandler } from 'components'
import { t, useTranslation } from 'i18n'
import { CoreValueType } from 'types'

const validationSchema = yup.object({
  name: yup.string().required(t('nameIsRequired')),
  description: yup
    .string()
    .required(t('coreValues.validations.descriptionIsRequired')),
})

export default function ManageCoreValue({
  dialog,
  closeDialog,
  fetchRows,
}: any) {
  const intl = useIntl()
  const initialValues: CoreValueType = dialog.data
    ? dialog.data
    : {
        name: '',
        description: '',
        example: '',
      }

  return (
    <FormHandler
      title={useTranslation('coreValues.title')}
      descriptionCreateMode={useTranslation(
        'coreValues.descriptionCreateMode',
        {
          link: (
            <a
              href='https://faq.sparckco.com/knowledge/understanding-core-values'
              rel='noopener noreferrer'
              target='_blank'
            >
              {useTranslation('linkHere')}
            </a>
          ),
        },
      )}
      requestUrl='coreValues'
      data={dialog.data}
      closeDialog={closeDialog}
      fetchRows={fetchRows}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ formik }) => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} data-cy='coreValueName'>
            <TextField
              id='name'
              name='name'
              label={intl.formatMessage({ id: 'name' })}
              variant='outlined'
              value={formik.values.name}
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6} data-cy='coreValueDescription'>
            <TextField
              id='description'
              name='description'
              label={intl.formatMessage({ id: 'description' })}
              variant='outlined'
              value={formik.values.description}
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} data-cy='coreValueExample'>
            <TextField
              id='example'
              name='example'
              label={intl.formatMessage({ id: 'coreValues.example' })}
              variant='outlined'
              value={formik.values.example}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.example && Boolean(formik.errors.example)}
              helperText={formik.touched.example && formik.errors.example}
              fullWidth
            />
          </Grid>
        </Grid>
      )}
    </FormHandler>
  )
}
