import { ReactNode } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  useMediaQuery,
} from '@mui/material'
import { DialogCloseButton } from 'components'

/**
 * DialogTeleport combines default Dialog properties and style and a given component. It's an easy and fast way to show your container components inside dialogs.
 */

export type DialogTeleportProps = {
  dialogOpen: boolean
  handleDialogClose: () => void
  dialogTitle: string | any
  dialogAction?: {
    label: string
    function(): any
    disabled?: boolean
    isLoading?: boolean
  } | null
  dialogSize: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined
  isFullWidth?: boolean
  scroll?: 'body' | 'paper'
  children: ReactNode
}

export default function DialogTeleport({
  dialogOpen,
  handleDialogClose,
  dialogTitle,
  dialogAction,
  dialogSize,
  isFullWidth,
  children,
  scroll = 'paper',
}: DialogTeleportProps) {
  const fullScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  )

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleDialogClose}
      maxWidth={dialogSize}
      fullWidth={isFullWidth}
      fullScreen={fullScreen}
      scroll={scroll}
    >
      <DialogCloseButton handleClose={handleDialogClose} />
      {!!dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}
      <DialogContent>{children}</DialogContent>

      <DialogActions>
        {fullScreen && <Button onClick={handleDialogClose}>Cancel</Button>}

        {dialogAction && (
          <Button
            onClick={dialogAction.function}
            disabled={dialogAction.disabled}
          >
            {dialogAction.isLoading ? (
              <CircularProgress size={24} />
            ) : (
              dialogAction.label
            )}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
