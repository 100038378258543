import { QueryClient, QueryClientProvider } from 'react-query'

import ApplicationAuthentication from './ApplicationAuthentication'
import { useLogout } from 'utils/hooks'

const ApplicationRequest = () => {
  const { logout } = useLogout()

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 0,
        onError: (error: any) => {
          if (
            error.message === 'token_expired_with_new_token' ||
            error.message === 'token_expired'
          ) {
            logout()
          }
        },
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient} contextSharing>
      <ApplicationAuthentication />
    </QueryClientProvider>
  )
}

export default ApplicationRequest
