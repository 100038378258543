import { useQuery, UseQueryResult } from 'react-query'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { EmployeeType, SurveyType } from 'types'
import { API } from 'core/requests'

export const useFetchSurveys = (): UseQueryResult<SurveyType[], Error> => {
  return useQuery('surveys', () =>
    API.get('surveys', null, { queryParams: { limit: 5 } }),
  )
}

export const useFetchSuggestions = (): UseQueryResult<any[], Error> => {
  return useQuery('suggestions', () =>
    API.get('suggestionsBoxIdeas', null, {
      queryParams: { limit: 5 },
    }),
  )
}

export const useFetchEmployeesCount = (
  companyId: number,
): UseQueryResult<{ count: number }, Error> => {
  return useQuery(
    'employeesCount',
    () => API.get('companyEmployeesCount', companyId),
    { enabled: false },
  )
}

export const useFetchCompanyAdministrators = (): UseQueryResult<
  {EmployeeType: any},
  Error
> => {
  return useQuery('companyAdministrators', () =>
    API.get('users', null, {
      queryParams: {
        'role_types[0]': ['company_admin'],
        limit: 100,
      },
    }).then((response) => {
      return response.users;
    }),
  )
}
