import { useState } from 'react'

import { get } from 'lodash'
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  Paper,
  Typography,
} from '@mui/material'

import { DemographicsFilters, PulseSurveyStatistics } from 'components'
import { DemographicsFiltersType } from 'components/DemographicsFilters'
import { t } from 'i18n'

export default function PulseSurveyInsightsComponent(
  props: {
    pulseSurveyId: number | any
    pulseSurveyOverview: any
    isPulseSurveyOverviewLoading: boolean
    getPulseSurveyOverview: any
    filtersQuantityState: any
  } & Omit<DemographicsFiltersType, 'refetch'>,
) {
  const {
    pulseSurveyOverview,
    isPulseSurveyOverviewLoading,
    getPulseSurveyOverview,
    filtersQuantityState,
  } = props

  const [showFilters, setShowFilters] = useState(false)

  return (
    <Grid container>
      <Grid item xs={12}>
        {isPulseSurveyOverviewLoading ? (
          <Box width={1} my={5}>
            <Grid container justifyContent='center'>
              <CircularProgress size={40} />
            </Grid>
          </Box>
        ) : (
          <>
            <Box width={1} mb={2}>
              <Grid
                container
                spacing={2}
                alignItems='center'
                justifyContent='space-between'
              >
                <Grid item>
                  <Typography variant='h6' color='text.secondary'>
                    {get(pulseSurveyOverview, 'survey.name')} Insights
                  </Typography>
                </Grid>

                {location.pathname.startsWith('/manage-pulse-surveys/') &&
                  location.pathname.endsWith('insights') && (
                    <Grid item xs='auto'>
                      <Grid container justifyContent='flex-end'>
                        <Badge
                          badgeContent={filtersQuantityState}
                          color='secondary'
                        >
                          <Button
                            variant='outlined'
                            color='secondary'
                            onClick={() => setShowFilters(!showFilters)}
                            size='large'
                          >
                            {t('beheardReport.filterButtons.filters')}
                          </Button>
                        </Badge>
                      </Grid>
                    </Grid>
                  )}
              </Grid>
            </Box>

            <Grid container>
              <Grid item xs={12}>
                {location.pathname.startsWith('/manage-pulse-surveys/') &&
                  location.pathname.endsWith('insights') && (
                    <Collapse in={showFilters}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box mb={3}>
                            <Paper square>
                              <Box p={2}>
                                <DemographicsFilters
                                  {...props}
                                  hasResetFilters
                                  refetch={getPulseSurveyOverview}
                                />
                              </Box>
                            </Paper>
                          </Box>
                        </Grid>
                      </Grid>
                    </Collapse>
                  )}
              </Grid>
            </Grid>

            {pulseSurveyOverview && !pulseSurveyOverview.errors ? (
              <PulseSurveyStatistics
                pulseSurvey={get(pulseSurveyOverview, 'survey')}
              />
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12} textAlign={"center"}>
                  <Typography variant='h5' color='text.secondary'>
                    {t('pulseSurveyOverview.whenSurveyCloses')}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
}
