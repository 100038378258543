export enum BudgetBreakdownFilter {
  Department = 'departments',
  BusinessUnit = 'business_units',
  Territory = 'territories',
  Region = 'regions',
  Employees = 'employees',
}

export enum BudgetDistributionType {
  Flat = 'flat',
  Individual = 'individual',
  Demographics = 'demographics',
}

export enum BudgetProrateType {
  NoBudgetInPeriod = 'no_budget_in_period',
  RemainingFullMonths = 'remaining_full_months',
  RemainingMonthsWithCurrent = 'remaining_months_with_current',
}

export enum BudgetPeriod {
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  SemiAnnually = 'semi_annually',
  Annually = 'annually',
}

export enum BudgetBalanceFields {
  RewardsBudget = 'rewards_budget',
  TotalBudgetPerEmployee = 'total_budget_per_employee',
  AllocatedFunds = 'allocated_funds',
  Sent = 'sent',
  Redeemed = 'redeemed',
  Banked = 'banked',
  RewardBalance = 'reward_balance',
}

export enum BudgetNewEmployeesBudgetType {
  Manual = 'manual',
  Automatic = 'automatic',
}

export enum BudgetYearType {
  Calendar = 'calendar',
  Fiscal = 'fiscal',
}

export enum BudgetStatus {
  BudgetEnabled = 'budget_enabled',
  BudgetDisabled = 'budget_disabled',
}
