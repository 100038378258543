import { Button, Grid, Paper, Typography } from '@mui/material'

import {
  VirginExperiencesSettingsCredit,
  VirginExperiencesSettingsCreditAlerts,
  VirginExperiencesSettingsManageCreditPaymentsDialog,
  VirginExperiencesSettingsUpdateAlertsDialog,
  VirginExperiencesSettingsUpdateCreditDialog,
} from '../../components'
import { Props } from '../../VirginExperiencesSettings.props'

export default function VirginExperiencesSettingsComponent(props: Props) {
  const {
    setManageCreditPaymentsDialogOpen,
    setUpdateCreditAlertsDialogOpen,
    setUpdateCreditLimitDialogOpen,
  } = props

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <VirginExperiencesSettingsCard
            title={"Sparck's Credit"}
            description={
              'This section shows the credit used and credit available to Sparck with Virgin Experiences. Click Manage Credit to change the credit limit provided by Virgin Experiences.'
            }
            buttons={[
              {
                id: 'manage_credit',
                variant: 'contained',
                label: 'Manage Credit',
                onClick: () => setUpdateCreditLimitDialogOpen(true),
              },
            ]}
          >
            <VirginExperiencesSettingsCredit {...props} />
          </VirginExperiencesSettingsCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <VirginExperiencesSettingsCard
            title={'Remaining Credit Alerts'}
            description={
              'This section allows Sparck to create alerts based on the amount of credit available so Administrators know when Sparck is close to its limit and can make a payment to Virgin Experiences.  Click the Manage Credit Alerts button to set what amounts will trigger an alert.'
            }
            buttons={[
              {
                id: 'manage_credit_alerts',
                variant: 'contained',
                label: 'Manage Credit Alerts',
                onClick: () => setUpdateCreditAlertsDialogOpen(true),
              },
            ]}
          >
            <VirginExperiencesSettingsCreditAlerts {...props} />
          </VirginExperiencesSettingsCard>
        </Grid>

        <Grid item xs={12}>
          <VirginExperiencesSettingsCard
            title={'Credit Payments'}
            description={
              'This section allows us to select the orders that need to be paid and determine the amount of payment that is needed to be made.  Click the Manager Credit Payments button to start the process.'
            }
            buttons={[
              {
                id: 'manage_credit_payments',
                variant: 'contained',
                label: 'Manage Credit Payments',
                onClick: () => setManageCreditPaymentsDialogOpen(true),
              },
            ]}
          />
        </Grid>
      </Grid>

      <VirginExperiencesSettingsUpdateCreditDialog {...props} />
      <VirginExperiencesSettingsUpdateAlertsDialog {...props} />
      <VirginExperiencesSettingsManageCreditPaymentsDialog {...props} />
    </>
  )
}

function VirginExperiencesSettingsCard({
  title,
  description,
  children,
  buttons,
}: any) {
  return (
    <Paper sx={{ py: 2, px: 3, height: 1 }}>
      <Grid container>
        {title && (
          <Grid item xs={12}>
            <Typography
              variant='h6'
              color='text.primary'
              fontWeight={600}
              sx={{ mb: 0.5 }}
            >
              {title}
            </Typography>
          </Grid>
        )}

        {description && (
          <Grid item xs={12}>
            <Typography variant='body2' color='text.secondary' sx={{ mb: 0.5 }}>
              {description}
            </Typography>
          </Grid>
        )}

        {children && (
          <Grid item xs={12} sx={{ mt: 1 }}>
            {children}
          </Grid>
        )}

        {buttons && (
          <Grid item xs={12} sx={{ mt: 1.5 }}>
            <Grid container spacing={2} justifyContent='flex-end'>
              {buttons.map(button => (
                <Grid item key={button.id} xs={12} sm='auto'>
                  <Button
                    variant={button.variant ?? 'outlined'}
                    color='primary'
                    onClick={button.onClick}
                  >
                    {button.label}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}
