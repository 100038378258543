import { useState } from 'react'

import { Dialog, DialogContent, useTheme } from '@mui/material'
import { green } from '@mui/material/colors'
import {
  LocationCity as CompaniesIcon,
  // Edit as EditIcon,
  EmailOutlined as EmailIcon,
  RedeemOutlined as NGCManagementIcon,
  CelebrationOutlined as VirginExperiencesIcon,
  // Close as CloseIcon
} from '@mui/icons-material'
import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'

import { CompaniesEmails, CompaniesNGCManagement } from './components'
import { useFetchCompanies } from './Companies.queries'
import ManageCompany from './manageCompany/ManageCompany'
import { CompanyAdminLayout, DialogTeleport } from 'components'
import { initialDialog } from 'constants/dialog'
import { API } from 'core/requests'
import { ROUTES_PATHS } from 'core/routes'
import { VirginExperiencesOrganizational } from 'pages'
import { getDateLabel } from 'utils/tables/dataTypes'

export default function Companies() {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const intl = useIntl()
  const theme = useTheme()

  const [dialogCompany, setDialogCompany] = useState(initialDialog)
  const [companiesEmailsDialog, setCompaniesEmailsDialog] =
    useState(initialDialog)
  const [companiesNGCManagementDialog, setCompaniesNGCManagementDialog] =
    useState(initialDialog)
  const [
    virginExperiencesManagementDialog,
    setVirginExperiencesManagementDialog,
  ] = useState(initialDialog)

  const { data: companies } = useFetchCompanies()

  const sendWelcomeEmailMutation = useMutation(
    id => API.create('sendWelcomeEmail', id, { pathParams: { id: id } }),
    {
      onSettled: (response: any) => {
        if (!(response && response.errors)) {
          enqueueSnackbar(
            intl.formatMessage({
              id: 'companies.snackbar.success.emailSent',
            }),
            {
              variant: 'success',
            },
          )
        }
      },
    },
  )

  const MAP_ENDPOINT_TO_FUNCTION = {
    sendWelcomeEmail: sendWelcomeEmailMutation.mutate,
  }

  const handleSendEmail = (endpoint, companyId) => {
    MAP_ENDPOINT_TO_FUNCTION[endpoint](companyId)
  }

  const renderAccess = (rowData: any, application) => (
    <span
      style={{
        fontWeight: 600,
        color: rowData[application]
          ? theme.palette.primary.main
          : theme.palette.error.light,
      }}
    >
      {rowData[application] ? 'Yes' : 'No'}
    </span>
  )

  return (
    <>
      <CompanyAdminLayout
        slug='companies'
        title={intl.formatMessage({ id: 'companies.title' })}
        description={intl.formatMessage({ id: 'companies.description' })}
        color={green['A700']}
        requestUrl='companies'
        customHeaderActions={[
          {
            label: intl.formatMessage({ id: 'companies.createCompany' }),
            onClick: () => history.push(ROUTES_PATHS.signup),
          },
        ]}
        tableProps={{
          actions: [
            // {
            //   icon: () => <EditIcon color='primary' data-cy='editCompany' />,
            //   disabled: () => false,
            //   tooltip: () =>
            //     intl.formatMessage({ id: 'companies.editCompany' }),
            //   onClick: (_, rowData: any) =>
            //     setDialogCompany({
            //       isOpen: true,
            //       data: rowData,
            //     }),
            // },
            // {
            //   icon: () => <CloseIcon color='error' data-cy='removeCompany' />,
            //   disabled: () => false,
            //   tooltip: () =>
            //     intl.formatMessage({ id: 'companies.removeCompany' }),
            //   onClick: (_, rowData: any) =>
            //     setDialogCompany({
            //       isOpen: true,
            //       data: rowData,
            //     }),
            // },
            {
              icon: () => <EmailIcon />,
              disabled: () => false,
              tooltip: () => intl.formatMessage({ id: 'companies.emails' }),
              onClick: (_, rowData: any) =>
                setCompaniesEmailsDialog({
                  isOpen: true,
                  data: rowData,
                }),
            },
            {
              icon: () => <NGCManagementIcon />,
              disabled: () => false,
              tooltip: () =>
                intl.formatMessage({ id: 'companies.ngcManagement' }),
              onClick: (_, rowData: any) =>
                setCompaniesNGCManagementDialog({
                  isOpen: true,
                  data: rowData,
                }),
            },
            {
              icon: () => <VirginExperiencesIcon />,
              disabled: () => false,
              tooltip: () =>
                intl.formatMessage({
                  id: 'companies.virginExperiencesManagement',
                }),
              onClick: (_, rowData: any) =>
                setVirginExperiencesManagementDialog({
                  isOpen: true,
                  data: rowData,
                }),
            },
          ],
          columns: [
            {
              title: 'ID',
              field: 'id',
              editable: 'never',
            },
            {
              title: intl.formatMessage({ id: 'companies.companyName' }),
              field: 'name',
              editable: 'never',
            },
            {
              title: intl.formatMessage({ id: 'companies.beheardAccess' }),
              field: 'beheard_application',
              editable: 'never',
              render: rowData => renderAccess(rowData, 'beheard_application'),
            },
            {
              title: intl.formatMessage({ id: 'companies.beseenAccess' }),
              field: 'beseen_application',
              editable: 'never',
              render: rowData => renderAccess(rowData, 'beseen_application'),
            },
            {
              title: intl.formatMessage({ id: 'companies.enableRewardsStore' }),
              field: 'enable_rewards_store',
              editable: 'never',
              render: rowData => renderAccess(rowData, 'enable_rewards_store'),
            },
            {
              title: intl.formatMessage({ id: 'companies.clientSince' }),
              field: 'created_at',
              editable: 'never',
              render: (rowData: any) => getDateLabel(rowData.created_at),
            },
          ],
          editActionProps: {
            hidden: () => false,
          },
          deleteActionProps: {
            hidden: () => false,
          },
        }}
        disableCreate={true}
        ManagementForm={ManageCompany}
        IconHeader={CompaniesIcon}
      >
        {({ fetchData }) => (
          <>
            <DialogTeleport
              dialogTitle={`${intl.formatMessage({
                id: 'companies.sendEmailsTo',
              })} ${get(companiesEmailsDialog, 'data.name')}`}
              dialogAction={null}
              dialogSize='sm'
              dialogOpen={companiesEmailsDialog.isOpen}
              handleDialogClose={() =>
                setCompaniesEmailsDialog({ isOpen: false, data: null })
              }
              isFullWidth
            >
              <CompaniesEmails
                company={companiesEmailsDialog.data}
                handleSendEmail={handleSendEmail}
              />
            </DialogTeleport>

            <DialogTeleport
              dialogTitle={`${intl.formatMessage({
                id: 'companies.manageNgcFor',
              })} ${get(companiesNGCManagementDialog, 'data.name')}`}
              dialogAction={null}
              dialogSize='md'
              dialogOpen={companiesNGCManagementDialog.isOpen}
              handleDialogClose={() =>
                setCompaniesNGCManagementDialog({ isOpen: false, data: null })
              }
              isFullWidth
            >
              <CompaniesNGCManagement
                company={get(companiesNGCManagementDialog, 'data')}
              />
            </DialogTeleport>

            <Dialog
              open={virginExperiencesManagementDialog.isOpen}
              onClose={() =>
                setVirginExperiencesManagementDialog({
                  isOpen: false,
                  data: null,
                })
              }
              maxWidth={'lg'}
              fullWidth
            >
              <DialogContent sx={{ backgroundColor: 'background.default' }}>
                <VirginExperiencesOrganizational
                  company={get(virginExperiencesManagementDialog, 'data')}
                />
              </DialogContent>
            </Dialog>

            <DialogTeleport
              dialogTitle=''
              dialogAction={null}
              dialogSize='md'
              dialogOpen={!!dialogCompany.isOpen}
              handleDialogClose={() => setDialogCompany(initialDialog)}
              isFullWidth
            >
              <ManageCompany
                companies={companies}
                dialog={dialogCompany}
                closeDialog={() => setDialogCompany(initialDialog)}
                fetchCompanies={fetchData}
              />
            </DialogTeleport>
          </>
        )}
      </CompanyAdminLayout>
    </>
  )
}
