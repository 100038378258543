/**
 * Saves column order in Local Storage as array of columns names
 * @param {*} name
 * @param {*} columns
 * @param {*} source
 * @param {*} destination
 */
export const handleOnColumnDragged = (
  name: string,
  columns: any[],
  source: number,
  destination: number,
) => {
  columns.splice(destination, 0, columns.splice(source, 1)[0])

  localStorage.setItem(
    name,
    JSON.stringify(columns.map((column: { field: any }) => column.field)),
  )
}

/**
 * Returns sorted columns from Local Storage
 * @param {*} name
 * @param {*} columns
 */
export const getSortedColumnsFromLocalStorage = (
  name: string,
  columns: any[],
) => {
  if (columns?.length) {
    const localStorageColumnsOrder = localStorage.getItem(name)

    const arraysAreEqual = (left: any[], right: any[]) => {
      if (
        !Array.isArray(left) ||
        !Array.isArray(right) ||
        left.length !== right.length
      ) {
        return false
      }

      const arr1 = left.concat().sort()
      const arr2 = right.concat().sort()

      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
          return false
        }
      }

      return true
    }

    if (
      !localStorageColumnsOrder ||
      JSON.parse(localStorageColumnsOrder)?.length !== columns?.length ||
      !arraysAreEqual(
        JSON.parse(localStorageColumnsOrder),
        columns?.map((column: { field: any }) => column.field),
      )
    ) {
      localStorage.setItem(
        name,
        JSON.stringify(columns?.map((column: { field: any }) => column.field)),
      )
    }

    const sortedColumns: any[] = []

    JSON.parse(localStorage.getItem(name)!)?.forEach((columnName: any) => {
      sortedColumns.push(
        columns?.find((column: { field: any }) => column.field === columnName),
      )
    })

    return sortedColumns
  }

  return columns ?? []
}
