import { Box, Button, Grid, Typography, useTheme } from '@mui/material'

import { t } from 'i18n'

export default function LoadMoreButton({
  limit,
  setLimit,
  list,
  listLabel,
  numberOfQuestionsIncrement,
}) {
  const theme: any = useTheme()

  if (limit < list.length) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Box my={2} width={1}>
            <Grid container justifyContent='center'>
              <Button
                variant='contained'
                size='large'
                sx={{
                  background: `linear-gradient(135deg, ${theme.palette.error.main} 0%, ${theme.palette.secondary.light} 100%) !important`,
                }}
                onClick={() => setLimit(limit + numberOfQuestionsIncrement)}
              >
                {t('components.loadMoreButton.loadMore')} {listLabel}!
              </Button>

              <Grid item xs={12}>
                <Box mt={1}>
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    align='center'
                  >
                    {list.length - limit} {listLabel}{' '}
                    {t('components.loadMoreButton.remaining')}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    )
  } else {
    return null
  }
}
