import { useState } from 'react'

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
} from '@mui/material'
import { ArrowDropDown } from '@mui/icons-material'
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { useIntl } from 'react-intl'

import { downloadPremiumReportRequest } from 'core/requests'
import { t } from 'i18n'
import { useURLQuery } from 'utils/hooks'

export default function BeheardReportDownload(props) {
  const { filtersState } = props

  const [isReportLoading, setIsReportLoading] = useState(false)

  const query = useURLQuery()
  const intl = useIntl()

  const downloadMenuState = usePopupState({
    variant: 'popover',
    popupId: 'pdfOptions',
  })

  const premiumReportParams = {
    surveyId: Number(query.get('id')),
    sharedReportId: Number(query.get('permission_id')),
    filters: filtersState,
    setIsPdfReportLoading: setIsReportLoading,
  }

  const handlePremiumReportPDFNewTab = () => {
    downloadPremiumReportRequest({
      ...premiumReportParams,
      type: 'pdf',
      openInNewTab: true,
    })
    downloadMenuState.close()
  }

  const handlePremiumReportPDFDownload = () => {
    downloadPremiumReportRequest({
      ...premiumReportParams,
      type: 'pdf',
      openInNewTab: false,
    })
    downloadMenuState.close()
  }

  const handlePremiumReportXLSXDownload = () => {
    downloadPremiumReportRequest({
      ...premiumReportParams,
      type: 'xlsx',
      openInNewTab: false,
    })
    downloadMenuState.close()
  }

  return (
    <Grid item xs='auto' style={{ marginLeft: 8 }}>
      <Button
        variant='outlined'
        color='primary'
        size='large'
        disabled={isReportLoading}
        {...bindTrigger(downloadMenuState)}
      >
        <Grid container justifyContent='center'>
          <Box display='flex' alignItems='center'>
            {isReportLoading && (
              <CircularProgress size={20} style={{ marginRight: 8 }} />
            )}
            {t('beheardReport.filterButtons.download')}
            <ArrowDropDown style={{ marginLeft: 8 }} />
          </Box>
        </Grid>
      </Button>
      <Menu {...bindMenu(downloadMenuState)}>
        {[
          {
            title: intl.formatMessage({
              id: 'beheardReport.downloadMenu.pdfDownload',
            }),
            action: handlePremiumReportPDFDownload,
          },
          {
            title: intl.formatMessage({
              id: 'beheardReport.downloadMenu.xlsxDownload',
            }),
            action: handlePremiumReportXLSXDownload,
          },
          {
            title: intl.formatMessage({
              id: 'beheardReport.downloadMenu.pdfNewTab',
            }),
            action: handlePremiumReportPDFNewTab,
          },
        ].map(option => (
          <MenuItem onClick={option.action} key={option.title}>
            {option.title}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  )
}
