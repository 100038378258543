import { withRouter } from 'react-router-dom'
import { Box, Card, CardContent, Grid } from '@mui/material'

import { Props } from '../../Login.props'
import { ChangePassword, LoginForm, RecoverPassword } from '../../components'
import sparckFullColored from 'assets/images/sparck-full-colored.png'
import { ROUTES_PATHS } from 'core/routes'

const fullHeight = { height: '100%' }
const fullWidth = { width: '100%' }

function LoginComponent(props: Props) {
  const { location } = props

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      style={fullHeight}
    >
      <Grid item xs={11} sm={8} md={6} lg={4} xl={3}>
        <Box my={3} width={1}>
          <Card>
            <CardContent>
              <Box p={2} py={3}>
                <Grid container justifyContent='center'>
                  <Grid item xs={12} sm={6}>
                    <Box mb={4}>
                      <img
                        src={sparckFullColored}
                        alt='Sparck logo'
                        style={fullWidth}
                      />
                    </Box>
                  </Grid>
                </Grid>

                {location.pathname === ROUTES_PATHS.login && (
                  <LoginForm {...props} />
                )}

                {location.pathname === ROUTES_PATHS.recoverPassword && (
                  <RecoverPassword {...props} />
                )}

                {location.pathname === ROUTES_PATHS.changePassword && (
                  <ChangePassword {...props} />
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  )
}

export default withRouter(LoginComponent)
