import { Box, Button, CircularProgress, Grid } from '@mui/material'
import { useIntl } from 'react-intl'
import { UseMutationResult } from 'react-query'

import teledyneLogo from 'assets/images/marketing/teledyne-logo.png'
import flexcareLogo from 'assets/images/marketing/flexcare-logo.png'
import { MarketingHeader } from '../../components'
import {
  CustomerTestimonial,
  EngagementReportComparisonComponent,
  UpgradeCostEstimate,
} from 'components'

export default function MarketingComponent(props: {
  checkoutCurrentCartMutation: UseMutationResult
}) {
  const { checkoutCurrentCartMutation } = props

  const intl = useIntl()

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <MarketingHeader {...props} />
        </Grid>
      </Grid>
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item xs={11} sm={10} md={9} lg={8} xl={7}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box mt={4}>
                <UpgradeCostEstimate />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <EngagementReportComparisonComponent />
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container alignItems='stretch' style={{ height: '100%' }}>
                <Box mb={2}>
                  <CustomerTestimonial
                    testimonial={intl.formatMessage({
                      id: 'marketing.testimonialFlexCare',
                    })}
                    name='Bill McElyea'
                    role='VP of Human Resources'
                    company='FlexCare Medical Staffing'
                    tagline='2019 Best Places to Work'
                    logo={flexcareLogo}
                  />
                </Box>
                <iframe
                  title='Sparck video'
                  width='100%'
                  height='340'
                  src='https://www.youtube.com/embed/xwEjfMqFcv0'
                  frameBorder='0'
                ></iframe>
                <Box my={2}>
                  <CustomerTestimonial
                    testimonial={intl.formatMessage({
                      id: 'marketing.testimonialTeledyneTechnologies',
                    })}
                    name='Jennifer Hureaux'
                    role='HR Manager'
                    company='Teledyne Technologies'
                    tagline=''
                    logo={teledyneLogo}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box display='flex'>
                <Box m='auto'>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={checkoutCurrentCartMutation.mutate}
                    disabled={checkoutCurrentCartMutation.isLoading}
                    size='large'
                    sx={{ mt: 2, padding: '16px 64px', fontWeight: 700 }}
                  >
                    {checkoutCurrentCartMutation.isLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      intl.formatMessage({
                        id: 'marketing.upgradeNow',
                      })
                    )}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
