import React, { useState, useEffect } from 'react'

import { LANGUAGES } from 'constants/locale'
import { useCompany, useUser } from 'contexts'
import { Locale } from 'enums/locale'

type LocaleContextType = {
  locale: Locale | string
  setLocale: (value: Locale) => void
  APILanguageChanged: boolean
  setAPILanguageChanged: (value: boolean) => void
}
export const LocaleContext = React.createContext<LocaleContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

const localeFromLocalStorage = localStorage.getItem('locale')

export const LocaleProvider = ({ children }: Props) => {
  const { company } = useCompany()!
  const { user } = useUser()!

  const [locale, setLocale] = useState<any>(Locale.English)
  const [APILanguageChanged, setAPILanguageChanged] = useState(false)

  const getLocale = () => {
    if (user?.preferred_language) {
      const language: any = LANGUAGES.find(
        language => language.id === user?.preferred_language,
      )

      if (language.value) {
        localStorage.setItem('locale', language.value)
        return language.value
      }
    }

    return localeFromLocalStorage
  }

  useEffect(() => {
    if (company && company.allow_internationalization) {
      setLocale(getLocale() ?? Locale.English)
    }

    if (company && !company.allow_internationalization) {
      setLocale(Locale.English)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, user])

  return (
    <LocaleContext.Provider
      value={{ locale, setLocale, APILanguageChanged, setAPILanguageChanged }}
    >
      {children}
    </LocaleContext.Provider>
  )
}

export const useLocale = () => React.useContext(LocaleContext)
