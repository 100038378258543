import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  Check as CheckIcon,
  InfoOutlined as InfoOutlineIcon,
} from '@mui/icons-material'

import { t } from 'i18n'

const reportComparisonColumns = [
  {
    name: t('components.reportComparison.features'),
    center: false,
  },
  {
    name: t('components.reportComparison.freeReport'),
    center: true,
  },
  {
    name: t('components.reportComparison.premiumReport'),
    center: true,
  },
]

const reportComparison = [
  {
    id: 'companyEngagementScore',
    result: t('components.reportComparison.companyEngagementScore.title'),
    freeOverview: true,
    fullReport: true,
    tooltip: t('components.reportComparison.companyEngagementScore.tooltip'),
    filtered: false,
  },
  {
    id: 'participationRate',
    result: t('components.reportComparison.participationRate.title'),
    freeOverview: true,
    fullReport: true,
    tooltip: '',
    filtered: false,
  },
  {
    id: 'customQuestions',
    result: t('components.reportComparison.customQuestions.title'),
    freeOverview: true,
    fullReport: true,
    tooltip: '',
    filtered: false,
  },
  {
    id: 'shortTermTurnover',
    result: t('components.reportComparison.shortTermTurnover.title'),
    tooltip: t('components.reportComparison.shortTermTurnover.tooltip'),
    freeOverview: true,
    fullReport: true,

    filtered: true,
  },
  {
    id: 'longTermTurnover',
    result: t('components.reportComparison.longTermTurnover.title'),
    tooltip: t('components.reportComparison.longTermTurnover.tooltip'),
    freeOverview: false,
    fullReport: true,

    filtered: true,
  },
  {
    id: 'filters',
    result: t('components.reportComparison.filters.title'),
    tooltip: t('components.reportComparison.filters.tooltip'),
    freeOverview: false,
    fullReport: true,

    filtered: false,
  },
  {
    id: 'collaborationEffectivenessScore',
    result: t(
      'components.reportComparison.collaborationEffectivenessScore.title',
    ),
    tooltip: t(
      'components.reportComparison.collaborationEffectivenessScore.tooltip',
    ),
    freeOverview: true,
    fullReport: true,

    filtered: true,
  },
  {
    id: 'jobFitEffectivenessScore',
    result: t('components.reportComparison.jobFitEffectivenessScore.title'),
    tooltip: t('components.reportComparison.jobFitEffectivenessScore.tooltip'),
    freeOverview: true,
    fullReport: true,

    filtered: true,
  },
  {
    id: 'employeeAppreciationScore',
    result: t('components.reportComparison.employeeAppreciationScore.title'),
    tooltip: t('components.reportComparison.employeeAppreciationScore.tooltip'),
    freeOverview: true,
    fullReport: true,

    filtered: true,
  },
  {
    id: 'organizationalAlignmentScore',
    result: t('components.reportComparison.organizationalAlignmentScore.title'),
    tooltip: t(
      'components.reportComparison.organizationalAlignmentScore.tooltip',
    ),
    freeOverview: true,
    fullReport: true,

    filtered: true,
  },
  {
    id: 'growthEffectivenessScore',
    result: t('components.reportComparison.growthEffectivenessScore.title'),
    tooltip: t('components.reportComparison.growthEffectivenessScore.tooltip'),
    freeOverview: true,
    fullReport: true,

    filtered: true,
  },
  {
    id: 'topFiveMostImportantAreas',
    result: t('components.reportComparison.topFiveMostImportantAreas.title'),
    tooltip: t('components.reportComparison.topFiveMostImportantAreas.tooltip'),
    freeOverview: false,
    fullReport: true,

    filtered: false,
  },
  {
    id: 'topFiveFocusAreasForGreatestRoi',
    result: t(
      'components.reportComparison.topFiveFocusAreasForGreatestRoi.title',
    ),
    tooltip: t(
      'components.reportComparison.topFiveFocusAreasForGreatestRoi.tooltip',
    ),
    freeOverview: false,
    fullReport: true,

    filtered: false,
  },
  {
    id: 'topFiveCompanyStrengths',
    result: t('components.reportComparison.topFiveCompanyStrengths.title'),
    tooltip: t('components.reportComparison.topFiveCompanyStrengths.tooltip'),
    freeOverview: false,
    fullReport: true,

    filtered: false,
  },
  {
    id: 'actionableRecommendationsOrganizationAndManagers',
    result: t(
      'components.reportComparison.actionableRecommendationsOrganizationAndManagers.title',
    ),
    tooltip: t(
      'components.reportComparison.actionableRecommendationsOrganizationAndManagers.tooltip',
    ),
    freeOverview: false,
    fullReport: true,

    filtered: false,
  },
]

export default function EngagementReportComparison() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label='engagement-report-comparison'>
            <TableHead
              style={{
                background:
                  'linear-gradient(129.81deg,#f48b1e 0%,#f48b1e 0.01%,#fa7874 100%)',
                color: '#FFF',
              }}
            >
              <TableRow>
                {reportComparisonColumns.map((column, index) => (
                  <TableCell
                    align={column.center ? 'center' : 'left'}
                    key={index}
                    style={{
                      whiteSpace: 'normal',
                    }}
                  >
                    <Typography variant='body2' color='text.white'>
                      {column.name}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {reportComparison.map(reportItem => (
                <TableRow key={reportItem.id}>
                  <TableCell component='th' scope='row'>
                    <Typography variant='body1' color='text.secondary'>
                      {reportItem.result}
                      {reportItem.tooltip && (
                        <Tooltip title={reportItem.tooltip}>
                          <Box component='span' mx={1}>
                            <InfoOutlineIcon />
                          </Box>
                        </Tooltip>
                      )}
                    </Typography>
                  </TableCell>

                  {[reportItem.freeOverview, reportItem.fullReport].map(
                    (item, index) => (
                      <TableCell align='center' key={index}>
                        {item && <CheckIcon color='primary' />}
                      </TableCell>
                    ),
                  )}
                </TableRow>
              ))}
              <TableRow
                style={{
                  background:
                    'linear-gradient(129.81deg,#f48b1e 0%,#f48b1e 0.01%,#fa7874 100%)',
                  color: '#FFF',
                }}
              >
                <TableCell
                  colSpan={reportComparisonColumns.length}
                  style={{ color: '#FFF' }}
                >
                  <Typography variant='body2' paragraph>
                    <InfoOutlineIcon />{' '}
                    {t(
                      'components.survey.engagementReportComparisonComponent.info',
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}
