import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material'

// import { BudgetDetails } from '../../components'
// import { BudgetStatus } from 'enums/budget'
import ManageBudget from '../../manageBudget/ManageBudget'
import { BudgetType } from 'types/budget'

export type Props = {
  budgetSettings: BudgetType
  budgetSettingsIsLoading: boolean
  fetchBudgetSettings: any
}

export default function BudgetComponent(props: Props) {
  const { /*budgetSettings,*/ budgetSettingsIsLoading/*, fetchBudgetSettings*/ } = props

  if (budgetSettingsIsLoading)
    return (
      <Grid container spacing={2} justifyContent='center'>
        <CircularProgress size={40} />
      </Grid>
    )
  //hide budget old screen
  // if (budgetSettings?.status === BudgetStatus.BudgetEnabled) {
  //   return <BudgetDetails {...props} />
  // }

  // if (budgetSettings?.status === BudgetStatus.BudgetDisabled) {
  //   return <ManageBudget fetchBudgetSettings={fetchBudgetSettings} />
  // }

  return <ManageBudget {...props} /> //show budget new screen
  return null
}

export function BudgetDetailsHeaderCard(props) {
  return (
    <Paper sx={{ p: 2.5, height: 1 }}>
      <Grid container spacing={2} alignItems='center' mb={1.5}>
        <Grid item xs='auto'>
          <Box
            sx={{
              height: 48,
              width: 48,
              backgroundColor: props.color,
              color: '#FFF',
              borderRadius: '1rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '2rem',
            }}
          >
            <props.Icon
              sx={{
                color: '#FFF',
                fill: '#FFF',
                fontSize: '2rem',
                width: '2rem',
                height: '2rem',
              }}
            />
          </Box>
        </Grid>

        <Grid item xs>
          <Typography variant='h6' component='h2' fontWeight={600}>
            {props.title}
          </Typography>
        </Grid>

        {props.description && (
          <Grid item xs={12}>
            <Typography variant='body1' color='text.secondary'>
              {props.description}
            </Typography>
          </Grid>
        )}
      </Grid>

      {props.children}
    </Paper>
  )
}
