import { useState } from 'react'

import { Divider, Grid, Slider, Typography } from '@mui/material'

import { OnboardingCard } from '../../components'
import { LoadMoreButton } from 'components'
import { ONBOARDING_RATING_LABELS } from 'constants/survey'
import { t } from 'i18n'

const NUMBER_OF_QUESTIONS_INCREMENT = 5

export default function OnboardingRatingQuestions({
  answers,
  setAnswers,
  ratingQuestions,
  getStatement,
  theme,
  isMobile,
}) {
  const [numberOfAttributes, setNumberOfAttributes] = useState<number>(
    NUMBER_OF_QUESTIONS_INCREMENT,
  )

  return ratingQuestions.map(ratingQuestion => {
    return (
      <OnboardingCard
        key={ratingQuestion.id}
        backgroundColor1={theme.palette.primary.dark}
        backgroundColor2={theme.palette.primary.light}
        title={ratingQuestion.statement}
      >
        <Grid container justifyContent='center'>
          <Grid item xs={12} sm={11} md={10} lg={8}>
            {ratingQuestion.onboarding_choices_attributes
              .filter((_, index) => index < numberOfAttributes)
              .map((question: any) => {
                const ratingAnswer = answers.find(
                  answer =>
                    answer.question_id === ratingQuestion.id &&
                    answer.selected_choice_id === question.id,
                )

                if (ratingAnswer) {
                  return (
                    <Grid
                      container
                      justifyContent={isMobile ? 'center' : 'space-between'}
                      spacing={2}
                      alignItems='center'
                      key={question.id}
                    >
                      <Grid item xs>
                        <Typography
                          variant={isMobile ? 'body2' : 'body1'}
                          color={
                            ratingAnswer.satisfaction
                              ? 'primary.main'
                              : 'text.secondary'
                          }
                          fontWeight={ratingAnswer.satisfaction ? 600 : 400}
                          align={isMobile ? 'center' : 'left'}
                        >
                          {getStatement(question)}
                        </Typography>
                      </Grid>

                      <Grid item xs={isMobile ? 12 : 'auto'}>
                        <Grid container direction='column'>
                          <Slider
                            valueLabelDisplay='auto'
                            aria-label='custom slider'
                            value={ratingAnswer.satisfaction}
                            onChange={(event, newValue) => {
                              ratingAnswer.satisfaction = newValue

                              setAnswers([...answers])
                            }}
                            min={1}
                            max={5}
                            step={1}
                            sx={{
                              width: '100%',
                              minWidth: 240,
                            }}
                          />

                          <Grid item xs='auto'>
                            <Typography
                              variant='body1'
                              fontWeight={600}
                              align='left'
                              color={
                                ratingAnswer.satisfaction
                                  ? 'primary.main'
                                  : 'text.veryLight'
                              }
                            >
                              {ratingAnswer.satisfaction
                                ? ONBOARDING_RATING_LABELS[
                                    ratingAnswer.satisfaction
                                  ]
                                : t('onboarding.pleaseMakeSelection')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Divider style={{ margin: '16px 0px' }} />
                      </Grid>
                    </Grid>
                  )
                } else {
                  return null
                }
              })}
          </Grid>

          <Grid item xs={12}>
            <LoadMoreButton
              limit={numberOfAttributes}
              setLimit={setNumberOfAttributes}
              list={ratingQuestion.onboarding_choices_attributes}
              listLabel='questions'
              numberOfQuestionsIncrement={NUMBER_OF_QUESTIONS_INCREMENT}
            />
          </Grid>
        </Grid>
      </OnboardingCard>
    )
  })
}
