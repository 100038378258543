import { BaseSyntheticEvent, useEffect } from 'react'

import { Grid, TextField } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { MFAFactorSetupProps } from '../MFAFactorSetup/MFAFactorSetup'
import { t } from 'i18n'

const credentialsInitialValues: { password: string } = {
  password: '',
}

type Props = MFAFactorSetupProps & {
  activeStep: any
  userClickedNextPage: boolean
  setUserClickedNextPage(value: boolean): void
}

export default function MFAEnterCredentials(props: Props) {
  const { activeStep, userClickedNextPage, setUserClickedNextPage } = props

  const validationSchema = yup.object({
    password: yup
      .string()
      .required(t('signup.validations.employeesSchema.passwordIsRequired')),
  })

  const credentialsFormik = useFormik({
    initialValues: credentialsInitialValues,
    onSubmit: () => {},
    validationSchema: validationSchema,
  })

  useEffect(() => {
    if (userClickedNextPage) {
      activeStep?.onClickNextPage(credentialsFormik?.values.password)
      setUserClickedNextPage(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userClickedNextPage])

  return (
    <Grid container>
      <Grid item xs={12} sx={{ mb: 2 }}>
        {t('mfa.enterCredentials')}
      </Grid>

      <form
        onSubmit={credentialsFormik?.handleSubmit}
        style={{ width: '100%' }}
      >
        <Grid container spacing={3} alignItems='center'>
          <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
            <TextField
              id='password'
              name='password'
              type='password'
              label={t('signup.formLabels.password')}
              variant='outlined'
              data-cy='password'
              value={credentialsFormik?.values.password}
              required={!!credentialsFormik?.errors.password}
              onChange={(event: BaseSyntheticEvent) => {
                credentialsFormik?.handleChange(event)
              }}
              onBlur={credentialsFormik?.handleBlur}
              error={
                credentialsFormik?.touched.password &&
                Boolean(credentialsFormik?.errors.password)
              }
              helperText={
                credentialsFormik?.touched.password &&
                credentialsFormik?.errors.password
              }
              fullWidth
            />
          </Grid>
        </Grid>
      </form>
    </Grid>
  )
}
