import { HelpOutline } from '@mui/icons-material'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { useIntl } from 'react-intl'

import { recognitionIcons } from 'constants/recognition'
import { t } from 'i18n'

export default function ManageRecognitionInfo({ formik }) {
  const intl = useIntl()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          data-cy='name'
          id='name'
          name='name'
          label={t('name')}
          placeholder={intl.formatMessage({
            id: 'recognitions.fields.wayToGoTeam',
          })}
          variant='outlined'
          value={formik.values.name}
          required
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          data-cy='programIcon'
          select
          id='icon'
          name='icon'
          label={t('recognitions.fields.programIcon')}
          placeholder={intl.formatMessage({
            id: 'recognitions.fields.programIconPlaceholder',
          })}
          variant='outlined'
          value={formik.values.icon}
          required
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.icon && Boolean(formik.errors.icon)}
          helperText={formik.touched.icon && formik.errors.icon}
          fullWidth
        >
          {recognitionIcons.map(recognitionIcon => (
            <MenuItem
              data-cy='icon_option'
              key={recognitionIcon.value}
              value={recognitionIcon.value}
            >
              <Grid container alignItems='center'>
                <recognitionIcon.Icon
                  style={{ marginRight: 8, height: 14, width: 14 }}
                />
                {recognitionIcon.label}
              </Grid>
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12}>
        <TextField
          data-cy='description'
          id='description'
          name='description'
          label={t('description')}
          placeholder={intl.formatMessage({
            id: 'recognitions.fields.extraMile',
          })}
          variant='outlined'
          value={formik.values.description ?? ''}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          multiline
          rows={3}
          error={
            formik.touched.description && Boolean(formik.errors.description)
          }
          helperText={formik.touched.description && formik.errors.description}
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          <FormControlLabel
            style={{
              marginRight: 0,
              display: 'flex',
              justifyContent: 'center',
            }}
            control={
              <Checkbox
                checked={!!formik.values.has_group}
                onChange={() =>
                  formik.setFieldValue('has_group', !formik.values.has_group)
                }
                name='has_group'
                color='primary'
                data-cy='checkbox'
              />
            }
            label={t('recognitions.fields.allowGroupRecognition')}
          />
        </Grid>
      </Grid>

      {formik.values.has_group && (
        <Grid item xs={12}>
          <Typography variant='body1' color='text.secondary'>
            <Grid component='span' container alignItems='center'>
              {t('recognitions.groupRecognitionsBudgetImpact')}
              <Tooltip
                title={t('recognitions.groupRecognitionsBudgetImpactTooltip')}
              >
                <HelpOutline fontSize='small' style={{ marginLeft: 8 }} />
              </Tooltip>
            </Grid>
          </Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              data-cy='hasNomination'
              id='has_nominations'
              name='has_nominations'
              checked={formik.values.has_nominations}
              onChange={() =>
                formik.setFieldValue(
                  'has_nominations',
                  !formik.values.has_nominations,
                )
              }
            />
          }
          label={'Make this an Employee Nomination Event too'}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          spacing={2}
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item xs={12} sm='auto'>
            <FormControlLabel
              control={
                <Checkbox
                  data-cy='has_maximum_number_of_recognitions'
                  name='has_maximum_number_of_recognitions'
                  checked={!!formik.values.has_maximum_number_of_recognitions}
                  onChange={() =>
                    formik.setFieldValue(
                      'has_maximum_number_of_recognitions',
                      !formik.values.has_maximum_number_of_recognitions,
                    )
                  }
                />
              }
              label='Limit the number of recognitions that is possible to send'
            />
          </Grid>

          {formik.values.has_maximum_number_of_recognitions && (
            <Grid item xs={12} sm>
              <TextField
                data-cy='maximum_recognitions_number'
                id='maximum_number_of_recognitions'
                name='maximum_number_of_recognitions'
                label='Recognitions limit for this event'
                type='number'
                variant='outlined'
                value={formik.values.maximum_number_of_recognitions}
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.maximum_number_of_recognitions &&
                  Boolean(formik.errors.maximum_number_of_recognitions)
                }
                helperText={
                  formik.touched.maximum_number_of_recognitions &&
                  formik.errors.maximum_number_of_recognitions
                }
                fullWidth
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
