import { useState } from 'react'

import { Grid } from '@mui/material'

import { MFADisableDialogContent, MFAStepper } from '..'
import { MFASetupProps } from '../MFASetupComponent/MFASetupComponent'
import { DialogTeleport } from 'components'
import { useUser } from 'contexts'
import { t } from 'i18n'

export type MFAFactorSetupProps = MFASetupProps & {
  goToPreviousStep(): any
  goToNextStep(): any
}

export default function MFAFactorSetup(props: MFAFactorSetupProps) {
  const {
    factorSetupDialogOpen,
    setFactorSetupDialogOpen,
    selectedFactor,
    activeStepId,
    goToPreviousStep,
    finishButton,
    checkCredentialsMutation,
    createMFAFactorMutation,
    verifyMFAFactorMutation,
    disableMFAFactorMutation,
    backupCodesQuery,
  } = props

  const { fetchUser } = useUser()!

  const [userClickedNextPage, setUserClickedNextPage] = useState(false)

  const activeStep = selectedFactor?.steps?.find(
    step => step?.id === activeStepId,
  )
  const ActiveStepComponent = activeStep?.Component

  const handleDialogClose = () => {
    fetchUser()
    setFactorSetupDialogOpen(false)
  }

  return (
    <DialogTeleport
      dialogTitle={
        selectedFactor?.isEnabled
          ? t('mfa.mfaFactors.dialogTitle.disable', {
              selectedFactor: selectedFactor?.title,
            })
          : t('mfa.mfaFactors.dialogTitle.setup', {
              selectedFactor: selectedFactor?.title,
            })
      }
      dialogSize='md'
      dialogAction={
        selectedFactor?.isEnabled
          ? {
              label: t('mfa.disable'),
              function: () =>
                disableMFAFactorMutation.mutate({
                  factor_type: selectedFactor.id,
                }),
            }
          : null
      }
      dialogOpen={factorSetupDialogOpen}
      handleDialogClose={handleDialogClose}
      isFullWidth
    >
      <Grid container spacing={2} justifyContent='center'>
        {selectedFactor.isEnabled ? (
          <MFADisableDialogContent
            description={t('mfa.mfaFactors.disableDialogContent', {
              selectedFactor: selectedFactor?.title,
            })}
          />
        ) : (
          <>
            {ActiveStepComponent && (
              <Grid item xs={12}>
                <ActiveStepComponent
                  {...props}
                  activeStep={activeStep}
                  userClickedNextPage={userClickedNextPage}
                  setUserClickedNextPage={setUserClickedNextPage}
                  finishButton={
                    finishButton
                      ? finishButton
                      : {
                          label: t('mfa.gotIt'),
                          onClick: handleDialogClose,
                        }
                  }
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <MFAStepper
                steps={selectedFactor?.steps}
                activeStep={activeStep}
                setUserClickedNextPage={setUserClickedNextPage}
                goToPreviousStep={goToPreviousStep}
                nextPageIsLoading={
                  checkCredentialsMutation.isLoading ||
                  createMFAFactorMutation.isLoading ||
                  verifyMFAFactorMutation.isLoading ||
                  disableMFAFactorMutation.isLoading ||
                  backupCodesQuery.isLoading
                }
              />
            </Grid>
          </>
        )}
      </Grid>
    </DialogTeleport>
  )
}
