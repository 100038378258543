import { Box, Divider, Grid, Typography, useTheme } from '@mui/material'
import { useIntl } from 'react-intl'

import BeheardReportUnlockButton from '../BeheardReportUnlockButton/BeheardReportUnlockButton'
import { PreviousValueComparison, ProgressBar } from 'components'
import { t } from 'i18n'
import { get } from 'lodash'

export default function BeheardReportTurnoverForecast(props: any) {
  const { Description, surveyDetails, isFullReport } = props

  const engagementIndex = surveyDetails.engagement_index
  const turnover = surveyDetails.turnover
  const dontShowVisual = get(surveyDetails, "previous_survey_percents") ? true : false;

  const theme = useTheme()

  const intl = useIntl()

  const renderTurnoverOrUpgradeButton = turnoverItem => {
    if (turnoverItem.fullReportOnly && !isFullReport) {
      return (
        <Grid container key={turnoverItem.accessor}>
          <Grid item xs={12}>
            <Box my={2}>
              <Divider />
            </Box>
            <Typography
              variant='h6'
              color='text.primary'
              fontWeight='600'
              paragraph
            >
              {turnoverItem.label}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body2' color='text.primary' paragraph>
              {t('beheardReport.turnoverForecast.statement')}:{' '}
              {turnoverItem.questionStatement
                ? turnoverItem.questionStatement
                : ''}
            </Typography>
            <BeheardReportUnlockButton {...props} />
          </Grid>
        </Grid>
      )
    }

    return (
      <Grid container key={turnoverItem.accessor}>
        <Grid item xs={12}>
          <Box my={2}>
            <Divider />
          </Box>
          <Typography
            variant='h6'
            color='text.primary'
            fontWeight='600'
            paragraph
          >
            {turnoverItem.label}
          </Typography>
        </Grid>

        {turnover ? (
          <>
            <Grid item xs={12}>
              <Typography variant='body2' color='text.primary' paragraph>
                {t('beheardReport.turnoverForecast.statement')}:{' '}
                {turnoverItem.questionStatement
                  ? turnoverItem.questionStatement
                  : ''}
              </Typography>

              <Grid container justifyContent='space-between'>
                {[
                  {
                    label: intl.formatMessage({
                      id: 'beheardReport.turnoverForecast.atRisk',
                    }),
                    quantity: turnover[turnoverItem.accessor].at_risk,
                    percentage:
                      turnover[turnoverItem.accessor].at_risk_percentage,
                    color: theme.palette.error.main,
                  },
                  {
                    label: intl.formatMessage({
                      id: 'beheardReport.turnoverForecast.passive',
                    }),
                    quantity: turnover[turnoverItem.accessor].passive,
                    percentage:
                      turnover[turnoverItem.accessor].passive_percentage,
                    color: theme.palette.secondary.main,
                  },
                  {
                    label: intl.formatMessage({
                      id: 'beheardReport.turnoverForecast.committed',
                    }),
                    quantity: turnover[turnoverItem.accessor].committed,
                    percentage:
                      100 -
                      (turnover[turnoverItem.accessor].at_risk_percentage +
                        turnover[turnoverItem.accessor].passive_percentage),
                    color: theme.palette.primary.main,
                  },
                ].map(legendItem => (
                  <Grid item xs={12} sm='auto' key={legendItem.label}>
                    <Grid container alignItems='center'>
                      <Box mr={3}>
                        <div
                          style={{
                            width: 24,
                            height: 24,
                            borderRadius: '100%',
                            backgroundColor: legendItem.color,
                          }}
                        ></div>
                      </Box>
                      <Grid item>
                        <Typography variant='body2' color='text.primary'>
                          {legendItem.label}
                        </Typography>
                        <Typography
                          variant='body1'
                          color={legendItem.color}
                          fontWeight='600'
                        >
                          {legendItem.quantity}{' '}
                          {legendItem.quantity > 1
                            ? t('beheardReport.turnoverForecast.employees')
                            : t('beheardReport.turnoverForecast.employee')}{' '}
                          ({legendItem.percentage}
                          %)
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box my={2}>
                <ProgressBar
                  progress={[
                    {
                      percentage:
                        turnover[turnoverItem.accessor].at_risk_percentage,
                      color: 'error',
                    },
                    {
                      percentage:
                        turnover[turnoverItem.accessor].passive_percentage,

                      color: 'warning',
                      colorVariant: 'dark',
                    },
                    {
                      percentage:
                        turnover[turnoverItem.accessor].committed_percentage,
                      color: 'primary',
                    },
                  ]}
                  showLabel={false}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent='flex-start' spacing={2}>
                <Grid item xs={12} sm='auto'>
                  <Typography
                    variant='body2'
                    color='text.primary'
                    fontWeight={400}
                  >
                    {t('beheardReport.turnoverForecast.potentialTurnover')}
                  </Typography>
                  <Box mt={1} position='relative' display='inline-block'>
                    <Typography
                      variant='h3'
                      color='text.primary'
                      fontWeight={600}
                      paragraph
                    >
                      {turnover[turnoverItem.accessor].at_risk_percentage}%
                    </Typography>
                    {dontShowVisual && <PreviousValueComparison
                      currentValue={
                        turnover && turnover[turnoverItem.accessor].at_risk_percentage
                      }
                      previousValue={
                        turnover?.previous && turnover?.previous[turnoverItem.accessor]
                          .at_risk_percentage
                      }
                      positiveValueColor='primary.main'
                      negativeValueColor='error.main'
                    />}
                  </Box>
                </Grid>
                {turnover.previous && (
                  <Grid item xs={12} sm='auto'>
                    <Typography
                      variant='body2'
                      color='text.primary'
                      fontWeight={400}
                    >
                      {t('beheardReport.turnoverForecast.previousTurnover')}
                    </Typography>
                    <Box mt={1}>
                      <Typography
                        variant='h4'
                        color='text.primary'
                        fontWeight={700}
                        paragraph
                      >
                        {
                          turnover.previous[turnoverItem.accessor]
                            .at_risk_percentage
                        }
                        %
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
        ) : null}
      </Grid>
    )
  }

  if (engagementIndex) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Description>
            {t('beheardReport.turnoverForecast.description')}
          </Description>
        </Grid>

        {[
          {
            accessor: 'short_term',
            label: intl.formatMessage({
              id: 'beheardReport.turnoverForecast.shortTerm',
            }),
            questionStatement: intl.formatMessage({
              id: 'beheardReport.shortTermQuestion',
            }),
            fullReportOnly: true,
          },
          {
            accessor: 'long_term',
            label: intl.formatMessage({
              id: 'beheardReport.turnoverForecast.longTerm',
            }),
            questionStatement: intl.formatMessage({
              id: 'beheardReport.longTermQuestion',
            }),
            fullReportOnly: false,
          },
        ].map(renderTurnoverOrUpgradeButton)}
      </Grid>
    )
  } else {
    return null
  }
}
