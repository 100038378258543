import { useState, useEffect } from 'react'

import { useSnackbar } from 'notistack'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import { LoginComponent } from './components'
import {
  SPARCK_REFRESH_USER_TOKEN,
  SPARCK_USER_TOKEN,
  SPARCK_USER_TOKEN_ID,
  SPARCK_USER_ID,
} from 'constants/app'
import { useUser } from 'contexts'
import { API_BASE_URL } from 'core/environments'
import { API } from 'core/requests'
import { ROUTES_PATHS } from 'core/routes'
import { t } from 'i18n'
import { LoginType } from 'types'
import { decodeJWT } from 'utils'
import { decodeQueryParams, urlParams } from 'utils/queryParams'
import { currentBuildVersion, LS_BUILD_KEY } from 'utils/requests'

export default function Login() {
  const initialLoginState: LoginType = {
    email: '',
    password: '',
    token: null,
    remember_me: false,
  }

  const initialChangePasswordState: any = {
    current_password: null,
    new_password: '',
  }

  const { user, setUser } = useUser()!
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const [loginState, setLoginState] = useState(initialLoginState)

  const [mfaDetails, setMfaDetails] = useState(null)

  const [changePasswordState, setChangePasswordState] = useState(
    initialChangePasswordState,
  )

  const params: any = urlParams(history.location.search)
  const redirect = params.redirect && `/${params.redirect}`

  const authenticateUser = response => {
    const decodedToken = decodeJWT(response.access_token)

    localStorage.setItem(SPARCK_USER_TOKEN, response.access_token)
    localStorage.setItem(SPARCK_USER_TOKEN_ID, response.id)
    localStorage.setItem(SPARCK_USER_ID, decodedToken.sub)

    setUser({
      ...decodedToken,
      token: response.access_token,
    })
  }

  const startMFAAuthenticationMutation = useMutation(
    (payload: any) => API.create('startMFAAuthentication', payload),
    {
      onSuccess: response => {
        setMfaDetails(response)
        setLoginState({
          ...loginState,
          factor_type: response.factor_type,
        })
      },
    },
  )

  const finishMFAAuthenticationMutation = useMutation(
    (payload: any) => API.create('finishMFAAuthentication', payload),
    {
      onSuccess: response => {
        if (response && response.access_token) {
          authenticateUser(response)
        }
      },
    },
  )

  const loginMutation = useMutation(
    (payload: any) => API.create('login', payload),
    {
      onSuccess: (response: any) => {
        const responseData = response?.data

        if (responseData && responseData.access_token) {
          authenticateUser(responseData)
        }

        if (responseData && responseData.refresh_token) {
          localStorage.setItem(
            SPARCK_REFRESH_USER_TOKEN,
            responseData.refresh_token,
          )
        }

        if (responseData && responseData.mfa_required) {
          setMfaDetails(responseData)
          setLoginState({
            ...loginState,
            factor_type: responseData.factor_type,
          })
        }
      },
    },
  )

  const redirectIfNecessary = redirect
    ? `?redirect=${redirect.replace('/', '')}`
    : ''
  const loginRedirect = [ROUTES_PATHS.login, redirectIfNecessary].join('')

  const resendVerificationCodeMutation = useMutation((payload: any) =>
    API.create('createMFAFactor', payload),
  )

  const forgotPasswordMutation = useMutation(
    (payload: any) =>
      API.create('sendRecoveryInstructions', payload, {
        queryParams: { email: payload.email },
      }),
    {
      onSettled: (response: any) => {
        if (response && response.success) {
          history.push(loginRedirect)

          enqueueSnackbar(t('login.snackbar.success.checkEmail'), {
            variant: 'success',
          })
        }
      },
    },
  )

  const recoverPasswordMutation = useMutation(
    (payload: any) => API.create('passwordRecovery', payload),
    {
      onSettled: (response: any) => {
        if (response && response.id) {
          history.push(loginRedirect)

          enqueueSnackbar(t('login.snackbar.success.useNewPassword'), {
            variant: 'success',
          })
        }
      },
    },
  )

  const changePasswordMutation = useMutation(
    (payload: any) =>
      API.update('changePassword', payload, {
        pathParams: { id: payload.user_id },
      }),
    {
      onSettled: (response: any) => {
        if (response && !response.errors) {
          history.push(loginRedirect)

          enqueueSnackbar(t('login.snackbar.success.passwordUpdated'), {
            variant: 'success',
          })
        }
      },
    },
  )

  const changePassword = () => {
    changePasswordMutation.mutate({
      user: changePasswordState,
      user_id: user.id,
    })
  }

  useEffect(() => {
    const queryParams = decodeQueryParams(history.location.search)

    if (queryParams && queryParams.token) {
      setLoginState({ ...loginState, token: queryParams.token })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  useEffect(() => {
    fetch(API_BASE_URL.replace('v1/', ''), {
      // @ts-ignore
      headers: {
        'X-Front-End-Build-Version': currentBuildVersion(),
      },
      // @ts-ignore
      encoding: null,
      mode: 'cors',
      method: 'GET',
    })
      .then(response => response?.json())
      .then(response => {
        const reloadRequired = response?.reload

        if (reloadRequired) {
          localStorage.setItem(LS_BUILD_KEY, response.versions.current)
          window.location.reload()
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LoginComponent
      loginState={loginState}
      setLoginState={setLoginState}
      mfaDetails={mfaDetails}
      loginMutation={loginMutation}
      resendVerificationCodeMutation={resendVerificationCodeMutation}
      forgotPasswordMutation={forgotPasswordMutation}
      recoverPasswordMutation={recoverPasswordMutation}
      startMFAAuthenticationMutation={startMFAAuthenticationMutation}
      finishMFAAuthenticationMutation={finishMFAAuthenticationMutation}
      changePasswordState={changePasswordState}
      setChangePasswordState={setChangePasswordState}
      changePassword={changePassword}
      changePasswordIsLoading={changePasswordMutation.isLoading}
      redirect={redirect}
    />
  )
}
