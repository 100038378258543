import { useState, useEffect } from 'react'

import { CardGiftcardOutlined as RewardIcon } from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
  Zoom,
  Dialog,
  DialogActions,
} from '@mui/material'
import { isEmpty, sortBy, uniqBy } from 'lodash'

import {
  SparckEmployeesPicker,
  SparckMessage,
  SparckRewardsSections,
  SparckUserAvatar,
  SparckUserPreferences,
} from '../../components'
import { DialogTeleport, UsersAdvancedSearch } from 'components'
import { SPARCK_MAX_WIDTH } from 'constants/app'
import { t } from 'i18n'
import { initialDialog } from 'constants/dialog'
import { useURLQuery } from 'utils/hooks'

export default function SparckForm(props) {
  const {
    company,
    fromFeed,
    sparckState,
    setSparckState,
    handleSendSparck,
    sendSparckIsLoading,
    recognitionEvents,
    // fetchRecognitionEvents,
    setDialogSparckPreview,
    dialogRecognitionErrorMsg,
    setDialogRecognitionErrorMsg,
    recognitionErrorMsg,
    surveyRewardIntentions,
  } = props

  const query: any = useURLQuery()
  const [showMessageInput, setShowMessageInput] = useState(false)
  const [recognitionEventUnavailableDialogShow, setRecognitionEventUnavailableDialogShow] = useState(false)
  const [dialogUsersAdvancedSearchOpen, setDialogUsersAdvancedSearchOpen] = useState(false)
  const [dialogSelectProductOpen, setDialogSelectProductOpen] = useState(false)
  const [
    recognitionEventUnavailableDialog,
    setRecognitionEventUnavailableDialog,
  ] = useState<{ isOpen: boolean; data: unknown }>(initialDialog)

  const getRecognition = recognitionId =>
    recognitionEvents.find(event => event.event_id === +recognitionId)

  const openUsersAdvancedSearchDialog = () =>
    setDialogUsersAdvancedSearchOpen(true)

  const handleDialogClose = () => {
    setDialogUsersAdvancedSearchOpen(false)
  }

  function RecognitionEventUnavailable() {
    const errors: unknown[] =
      recognitionEventUnavailableDialog.data as unknown[]

    if (!errors?.length) return null

    return (
      <DialogTeleport
        dialogTitle='Recognition Event Unavailable'
        dialogAction={null}
        dialogSize='md'
        dialogOpen={recognitionEventUnavailableDialog.isOpen}
        handleDialogClose={() =>
          setRecognitionEventUnavailableDialog(initialDialog)
        }
        isFullWidth
      >
        <Grid container spacing={2} justifyContent='center'>
          <Grid item xs>
            <Typography>
              {errors.map((error: any) => error.message).join('\n')}
            </Typography>
          </Grid>
        </Grid>
      </DialogTeleport>
    )
  }

  useEffect(() => {
    let timeoutId;
  
    const handleRecognitionEvent = () => {
      if (recognitionEvents?.length > 0 && query.get('event') && sparckState.event === null && recognitionEventUnavailableDialogShow === false && recognitionEventUnavailableDialog.isOpen === false) {
        const recognitionEventTemp = recognitionEvents.filter((recognitionEvent: any) => recognitionEvent.icon == query.get('event'));
        setRecognitionEventUnavailableDialog({
          isOpen: true,
          data: recognitionEventTemp[0]?.validation?.errors,
        })
        setRecognitionEventUnavailableDialogShow(true)
      }
    };
  
    const debouncedHandleRecognitionEvent = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleRecognitionEvent, 1000);
    };
  
    debouncedHandleRecognitionEvent();
  
    return () => {
      clearTimeout(timeoutId);
    };
  }, [recognitionEvents, sparckState, query, recognitionEventUnavailableDialogShow, recognitionEventUnavailableDialog]);

  return (
    <>
      <Grid container justifyContent='center'>
        <Grid
          item
          xs={fromFeed ? 12 : 11}
          sm={fromFeed ? 12 : 10}
          md={fromFeed ? 12 : 9}
          lg={fromFeed ? 12 : 8}
          xl={fromFeed ? 12 : 7}
          style={{ zIndex: 999, maxWidth: SPARCK_MAX_WIDTH }}
        >
          <SparckUserAvatar {...props} />

          <Zoom in={true} timeout={fromFeed ? 0 : 1200}>
            <Paper>
              <Box p={3} width={1}>
                <Grid container spacing={2} justifyContent='space-between'>
                  <Grid item xs={12} sm={6}>
                    <Grid
                      container
                      spacing={2}
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <Grid item xs>
                        <SparckEmployeesPicker
                          {...props}
                          openUsersAdvancedSearchDialog={
                            openUsersAdvancedSearchDialog
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Tooltip
                      title={
                        isEmpty(sparckState.users_to_recognize)
                          ? t('sparcks.sparck.selectUsersFirst')
                          : isEmpty(recognitionEvents)
                          ? t('sparcks.sparck.noRecognitionEventFound')
                          : ''
                      }
                    >
                      <TextField
                        data-cy='event'
                        select
                        id='event_id'
                        name='event_id'
                        value={sparckState.event_id ?? ''}
                        disabled={
                          isEmpty(sparckState.users_to_recognize) ||
                          isEmpty(recognitionEvents)
                        }
                        onChange={event =>
                          setSparckState({
                            ...sparckState,
                            event: getRecognition(event.target.value),
                            event_id: event.target.value,
                          })
                        }
                        variant='outlined'
                        label={t('sparcks.sparck.formLabels.event')}
                        fullWidth
                      >
                        {sortBy(recognitionEvents ?? [], [
                          event => !event.validation?.allowed,
                        ]).map(recognitionEvent => (
                          <MenuItem
                            data-cy='event_list'
                            key={recognitionEvent.event_id}
                            value={recognitionEvent.event_id}
                            sx={{
                              color: !recognitionEvent.validation?.allowed
                                ? 'text.disabled'
                                : undefined,
                            }}
                            onClick={event => {
                              if (!recognitionEvent.validation?.allowed) {
                                event.preventDefault()

                                setRecognitionEventUnavailableDialog({
                                  isOpen: true,
                                  data: recognitionEvent.validation?.errors,
                                })
                              }
                            }}
                          >
                            <Grid container>
                              <Grid item xs={12}>
                                <Box
                                  component='div'
                                  display='flex'
                                  alignItems='center'
                                >
                                  {recognitionEvent.has_reward && (
                                    <RewardIcon sx={{ mr: 1 }} />
                                  )}
                                  {recognitionEvent.event_name}
                                </Box>
                              </Grid>
                            </Grid>
                          </MenuItem>
                        ))}
                      </TextField>
                    </Tooltip>
                  </Grid>

                  {sparckState.individual_to_recognize &&
                    !!sparckState.event_id && (
                      <Grid item xs={12}>
                        <SparckUserPreferences {...props} />
                      </Grid>
                    )}

                  <SparckMessage
                    {...props}
                    showMessageInput={showMessageInput}
                    setShowMessageInput={setShowMessageInput}
                  />

                  {(sparckState?.event?.has_reward) && (company?.enable_rewards_store === true) && (
                    <SparckRewardsSections
                      {...props}
                      fromFeed={fromFeed}
                      showMessageInput={showMessageInput}
                    />
                  )}

                  <Grid item xs={12}>
                    <Grid container justifyContent='center'>
                      <Collapse
                        in={
                          !isEmpty(sparckState.users_to_recognize) &&
                          sparckState.event?.event_id &&
                          showMessageInput
                        }
                        style={{ width: '100%' }}
                        timeout={600}
                      >
                        <Grid
                          container
                          justifyContent='center'
                          direction='column'
                        >
                          <FormControlLabel
                            style={{
                              marginRight: 0,
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                            control={
                              <Checkbox
                                checked={!sparckState.has_public_access}
                                onChange={event =>
                                  setSparckState({
                                    ...sparckState,
                                    has_public_access: !event.target.checked,
                                  })
                                }
                                name='has_public_access'
                                color='primary'
                              />
                            }
                            label={
                              <Typography variant='h6' color='text.secondary'>
                                {t('sparcks.sparck.makePrivate')}
                              </Typography>
                            }
                          />

                          <Grid item xs={12}>
                            <Box mt={fromFeed ? 1 : 3}>
                              <Grid container justifyContent='center'>
                                {!fromFeed && (
                                  <Box m={1}>
                                    <Button
                                      data-cy='open_sparck_preview'
                                      variant='outlined'
                                      color='primary'
                                      onClick={() =>
                                        setDialogSparckPreview(true)
                                      }
                                      size='large'
                                    >
                                      {t('sparcks.sparck.preview')}
                                    </Button>
                                  </Box>
                                )}

                                <Box m={1}>
                                  <Button
                                    data-cy='send_sparck'
                                    variant='contained'
                                    color='primary'
                                    onClick={() => {
                                      sparckState.rewards = sparckState?.rewards.map((reward) => {
                                        reward.amount = Math.round(sparckState?.event?.maximum_reward_amount_for_each_recognition)
                                        reward.details.sub_total = Math.round(sparckState?.event?.maximum_reward_amount_for_each_recognition)
                                        return reward
                                      })

                                      if(sparckState?.rewards.length === 0 && surveyRewardIntentions.length > 0){
                                        // sparckState.event.has_reward = false
                                        // sparckState.event.has_retailer_reward = false
                                        setDialogSelectProductOpen(true)
                                      } else {
                                        handleSendSparck()
                                      }
                                    }}
                                    size='large'
                                    disabled={sendSparckIsLoading}
                                  >
                                    {sendSparckIsLoading ? (
                                      <CircularProgress size={32} />
                                    ) : (
                                      t('sparcks.sparck.send')
                                    )}
                                  </Button>
                                </Box>

                              </Grid>
                            </Box>
                          </Grid>

                        </Grid>
                      </Collapse>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Zoom>
        </Grid>
      </Grid>

      <DialogTeleport
        dialogOpen={dialogUsersAdvancedSearchOpen}
        dialogSize='lg'
        dialogTitle={t('sparcks.sparck.advancedSearchDialogTitle')}
        handleDialogClose={handleDialogClose}
      >
        <UsersAdvancedSearch
          {...props}
          selectedIds={sparckState?.users_to_recognize?.map(user => user.id)}
          onSelectEmployees={selected => {
            setSparckState({
              ...sparckState,
              users_to_recognize: uniqBy(
                [...sparckState.users_to_recognize, ...selected],
                'id',
              ),
            })

            setDialogUsersAdvancedSearchOpen(false)
          }}
        />
      </DialogTeleport>

      <Dialog
        open={dialogRecognitionErrorMsg}
        maxWidth='sm'
        fullWidth={true}
        scroll={'paper'}
      >
        <Grid container spacing={2} justifyContent='flex-end'>
          <Grid item xs={12} sx={{textAlign: 'center', marginTop: 6, marginBottom: 2}}>
            {recognitionErrorMsg}
          </Grid>
        </Grid>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogRecognitionErrorMsg(false)
              sparckState.event.has_reward = true
              sparckState.event.has_retailer_reward = true
            }}
            color={'primary'}
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogSelectProductOpen}
        maxWidth='sm'
        fullWidth={true}
        scroll={'paper'}
      >
        <Grid container spacing={2} justifyContent='flex-end'>
          <Grid item xs={12} sx={{textAlign: 'center', marginTop: 6, paddingLeft: 5, paddingRight: 5}}>
            You don't want to include a reward? Select a gift or search for something unique straight from this screen.
          </Grid>
          <Grid item xs={12} sx={{textAlign: 'center', marginBottom: 2}}>
            Click continue to send this recognition without a reward.
          </Grid>
        </Grid>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogSelectProductOpen(false)
            }}
            color={'primary'}
            autoFocus
            sx={{display: 'flex', justifyContent: 'flex-start', flex: 1}}
          >
            SELECT A REWARD
          </Button>
          <Button
            onClick={() => {
              if(sparckState?.rewards.length === 0){
                sparckState.event.has_reward = false
                sparckState.event.has_retailer_reward = false
              }
              
              setDialogSelectProductOpen(false)
              handleSendSparck()
            }}
            color={'primary'}
            autoFocus
          >
            CONTINUE
          </Button>
        </DialogActions>
      </Dialog>


      <RecognitionEventUnavailable />
    </>
  )
}