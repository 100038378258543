import { useState } from 'react'

import { useTheme } from '@mui/material'
import {
  GroupAdd as GroupAddIcon,
  Loyalty as ShareReportsIcon,
} from '@mui/icons-material'
import { useMutation } from 'react-query'

import InviteShareReport from './inviteShareReport/InviteShareReport'
import ManageShareReport from './manageShareReport/ManageShareReport'
import { CompanyAdminLayout } from 'components'
import { initialDialog } from 'constants/dialog'
import { API } from 'core/requests'
import { UserPermission } from 'enums/user'
import { useTranslation } from 'i18n'
import { useSnackbar } from 'notistack'
import { getDateLabel } from 'utils/tables/dataTypes'

export default function ShareReports({ surveyId }) {
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()

  const [dialogInvite, setDialogInvite] = useState(initialDialog)

  const inviteToShareReport = useMutation(
    (surveyId: any) =>
      API.create('inviteShareReport', null, {
        pathParams: {
          id: surveyId,
        },
      }),
    {
      onSuccess: (response: any) => {
        if (response.id) {
          enqueueSnackbar('Invites sent successfully', {
            variant: 'success',
          })
          setDialogInvite(initialDialog)
        }
      },
    },
  )

  return (
    <>
      <CompanyAdminLayout
        slug='shareReports'
        title={useTranslation('shareReport.title')}
        description={useTranslation('shareReport.description')}
        color={theme.palette.info.main}
        requestUrl='shareReport'
        requestOptions={{
          queryParams: {
            survey_id: surveyId,
          },
        }}
        tableProps={{
          columns: [
            {
              title: useTranslation('shareReport.columnHeaders.sharedReport'),
              field: 'name',
              editable: 'never',
            },
            {
              title: useTranslation('shareReport.columnHeaders.surveyName'),
              field: 'survey.name',
              editable: 'never',
            },
            {
              title: useTranslation('shareReport.columnHeaders.expires'),
              field: 'expires_at',
              editable: 'never',
              render: (rowData: any) => getDateLabel(rowData.expires_at),
            },
          ],
          hasStatusSwitch: true,
          actions: [
            {
              disabled: row => !!row.invites_sent_at,
              icon: () => <GroupAddIcon data-cy='inviteShareReport' />,
              onClick: (_, data) =>
                setDialogInvite({ isOpen: true, data: data.id }),
            },
          ],
        }}
        managementPermission={UserPermission.ShareReport}
        managementFormProps={{
          surveyId: surveyId,
        }}
        ManagementForm={ManageShareReport}
        IconHeader={ShareReportsIcon}
      />

      <InviteShareReport
        dialog={dialogInvite}
        setDialog={setDialogInvite}
        invite={() => inviteToShareReport.mutate(dialogInvite?.data)}
      />
    </>
  )
}
