export default {
  onboarding: {
    ratingLabels: {
      notApplicable: 'Não Aplicável',
      notAtAllImportant: 'Não Tão Importante',
      slightlyImportant: 'Levement Importante',
      important: 'Importante',
      veryImportant: 'Muito Importante',
      extremelyImportant: 'Extremamente Importante',
    },
    header: {
      profile_questions: {
        title: 'Você é unicamente especial!',
        description:
          'Gostaríamos muito de saber mais sobre as coisas que te motivam. Abaixo estão algumas perguntas para você começar. Se mudar de ideia, você sempre pode atualizar suas respostas na página de seu perfil pessoal. Vamos nessa!',
      },
      rating_and_simple_select: {
        title: 'Queremos saber o que faz VOCÊ sorrir!',
        description:
          'Vamos começar com as diferentes formas de reconhecimento que significam mais para você após um trabalho bem executado. Em seguida, examinaremos rapidamente suas preferências de comunicação com seu gerente.',
      },
      reward: {
        title: 'Hora de se beneficiar!',
        description:
          'Estamos no meio do caminho. Pronto para se divertir? Vamos dar uma olhada em alguns benefícios da sua organização. Quais são importantes para você?',
      },
      event: {
        title: 'O que te faz se sentir apreciado no trabalho?',
        description1:
          'Os benefícios são excelentes, mas sabemos que a experiência de reconhecimento é igualmente importante. Cada pessoa é única na forma como se sente apreciada. Queremos saber o que isso significa para você usando as áreas de reconhecimento abaixo.',
        description2:
          'Para começar, siga a sequência de colunas da esquerda para a direita e selecione suas preferências. Não se preocupe! Você sempre pode fazer alterações mais tarde, se suas preferências mudarem. Divirta-se!',
      },
      choice_group: {
        title: 'É tudo sobre o estilo, baby!',
        description:
          'Aqui estamos na etapa final! Como você provavelmente percebeu na última página, as Preferências de Reconhecimento mudam com base no que você está sendo reconhecido no local de trabalho. No entanto, você sabe qual é o seu estilo de reconhecimento principal? A maioria não. Se você não sabe, seu gerente e colegas também não saberão. Faça esta avaliação rápida para determinar que tipo de apreciação se encaixa melhor em você. Rufem os tambores, por favor... vamos terminar com um estrondo e descobrir seu Estilo de Reconhecimento Sparck!',
      },
    },
    onboardingProgress: 'Seu Progresso:',
    landing: {
      welcomeAboard: 'Bem-vindo a bordo!',
      paragraph1:
        'Olá, {name}. Bem-vindo(a) à experiência de integração Sparck! Isso não vai demorar muito, e os resultados vão levar a coisas muito legais do outro lado.',
      paragraph2:
        'O objetivo deste processo é determinar como você - sim, você como indivíduo - gostaria de ser reconhecido por suas incríveis realizações e trabalho árduo. Mal podemos esperar para saber o que é mais importante para você no local de trabalho.',
      paragraph3:
        'Deve levar apenas cerca de 5 a 10 minutos (a menos que você queira que demore mais!) Não é tão ruim, certo? Lembre-se de que você precisa terminar a pesquisa de uma vez. Mas não se preocupe, você sempre pode mudar suas respostas mais tarde.',
      paragraph4:
        'Muito obrigado por tomar o tempo. Mal podemos esperar para ouvir o que te faz sorrir!',
    },
    pleaseMakeSelection: 'Por favor, selecione...',
    recognitionColumns: {
      recognitionAreas: 'Áreas de Reconhecimento',
      recognitionMeaningful: 'Reconhecimento seria importante?',
      publicSetting: 'Em público?',
      byWho: 'Por quem?',
      how: 'Como?',
    },
    pagination: {
      previous: 'Anterior',
      imReady: 'Estou pronto(a)!',
      backToProfile: 'Voltar ao Perfil',
      finish: 'Finalizar',
      next: 'Próxima',
    },
    finish: {
      congratulations: 'PARABÉNS!',
      completedOnboarding: 'Você completou o processo de integração!',
      thankYou:
        'Muito obrigado por tomar o tempo. Esperamos que você tenha se divertido!',
      revealRecognitionStyle:
        'Agora vamos para a página de seu perfil para revelar seu Estilo de Reconhecimento Sparck!',
      takeMeToMyDashboard: 'Leve-me ao meu Dashboard!',
    },
  },
  feed: {
    tabLabels: { post: 'Publicar', recognize: 'Reconheça!' },
    messagePlaceholder: 'Escreva sua mensagem aqui...',
    postButtonText: 'Publicar',
    like: 'Curtida',
    likes: 'Curtidas',
    comment: 'Comentário',
    comments: 'Comentários',
    commentPlaceholder: 'Escreva seu comentário aqui...',
    hasBeen: 'foi',
    haveBeen: 'foram',
    recognized: 'Reconhecido(a)!',
    multipleUsersRecognized: 'Reconhecidos(as)',
    reportPost: 'Denunciar publicação',
    suggestion: {
      placeholder: 'Digite sua sugestão aqui...',
      reviewMessage:
        'Esta sugestão será enviada ao administrador da organização para revisão.',
      makePrivate: 'Tornar esta sugestão anônima ',
      submitIdeas: 'Enviar suas ideias',
    },
    feedValueLabels: {
      purpose: 'Nosso Propósito',
      values: 'Nossos Valores',
      recognitionAreas: 'Nossas Áreas de Reconhecimento',
      suggestionBox: 'Caixa de Sugestões',
    },
    notDefined: 'Esta organização não definiu {value} até o momento',
    loadAllPosts: 'Carregar Todas as Publicações',
    endOfPosts: 'Impressionante! Você chegou até o final',
    removePost: 'Excluir Publicação',
    thisPost: 'esta publicação',
    commentInfo: { updated: 'Atualizada' },
    noPermissionToViewPosts: 'Você não tem permissão para ver as publicações.',
    noPosts:
      'No momento não há publicações. Seja a primeira pessoa a reconhecer alguém!',
    snackbar: {
      success: { suggestionSubmitted: 'Sua sugestão foi enviada com sucesso!' },
    },
  },
  giphy: {
    placeholder: 'O que você quer pesquisar?',
    dialog: { title: 'Selecione seu Giphy!' },
  },
  profile: {
    tabs: {
      labels: {
        about: 'Sobre {userName}',
        personal: 'Pessoal',
        professional: 'Profissional',
        myRewards: 'Recompensas',
        me: 'Mim',
      },
    },
    profileItemLabels: {
      position: 'Posição',
      generalSettings: 'Configurações Gerais',
      visibilitySettings: 'Configurações de Visibilidade',
      personalInterests: 'Interesses Pessoais',
      hireDate: 'Data de Admissão',
      location: 'Localização',
      reportsTo: 'Supervisor Direto',
      professionalInterests: 'Interesses Profissionais',
      dateOfBirth: 'Data de Nascimento',
      positionPlaceholder: 'e.g. Engenheiro Sênior',
      preferredPronouns: 'Pronomes Preferidos',
      preferredPronounsPlaceholder: 'e.g. Ele/Dele',
      howShouldRefer: 'Como devemos nos referir a você?',
      customPreferredPronouns: 'Pronomes Preferidos',
      preferredNotification: 'Método de Notificação',
      sms: 'SMS',
      all: 'Ambos (Email/SMS)',
      mfa: 'Autenticação Multifator',
    },
    pronouns: { he: 'Ele/Dele', she: 'Ela/Dela', they: 'Eles/Deles' },
    profileVisibilityLabels: {
      everyone: 'Todos',
      managersOnly: 'Apenas Gerentes',
    },
    profileStrength: 'Força do Perfil',
    impact: {
      myImpact: 'Meu Impacto',
      userImpact: 'Impacto de {userName}',
      totalRecognitions: { total: 'Total de', recognitions: 'Reconhecimentos' },
      progressBarLegend: { sent: 'Enviados', received: 'Recebidos' },
    },
    profileRecognitionStyle: {
      title: 'Estilo de Apreciação Sparck',
      takeSurvey: 'Responder',
      showMe: {
        title: 'Me Mostre',
        descriptionSeeLess:
          'Você se sente valorizado quando seu trabalho é reconhecido de forma atenciosa e significativa através de um presente ou benefício que se alinha a seus hobbies, interesses ou metas. Para você, é importante saber que o trabalho que faz traz valor ao seu empregador e aos colegas.',
        descriptionSeeMore:
          'Você sente alegria ao saber que sua dedicação e comprometimento estão sendo reconhecidos. Mas isso vai além do seu trabalho. Você se sente bem quando as pessoas separam um tempo para te conhecer como pessoa e não só como um funcionário. Significa mais para você receber um presente que reflita seus interesses pessoais depois de atingir uma meta trimestral, do que receber um reconhecimento verbal. Depois de trabalhar até tarde em um projeto, você adoraria que seu colega ou gerente te surpreendesse com sua bebida favorita como uma forma de te "edificar". São presentes genuínos que refletem suas metas de carreira ou que tem relação com seus hobbies pessoais que mais te fazem sentir bem. Significa que seus colegas e gerente se empenharam para te conhecer bem.',
      },
      celebrateWithMe: {
        title: 'Comemore Comigo',
        descriptionSeeLess:
          'Benefícios, mesmo que todos nós adoremos, podem significar menos para você do que o ambiente de trabalho. Você gosta de trabalhar com pessoas que se importam e são compassivas, que gostam de mostrar que você é importante pra elas de uma forma que se vê em uma equipe esportiva',
        descriptionSeeMore:
          'Você adora dar e receber "high fives" virtualmente por um trabalho bem feito quando estão trabalhando de forma a manter distância social. Quando é cumprimentado de manhã, um toque de cotovelo é mais caloroso que um cumprimento rápido no corredor ao passar por alguém. Quando você atinge uma meta individual ou como equipe, você encontra formas de se conectar com seus colegas quando eles postam ícones de high five em todo lugar pra comemorar o sucesso. Você sabe que está causando impacto quando seu gerente te dá um "joinha" por seus resultados. Isto te faz sentir que está sendo visto e que seus esforços estão fazendo diferença.',
      },
      connectWithMe: {
        title: 'Conecte-se Comigo',
        descriptionSeeLess:
          'Você é alguém que gosta extremamente de pessoas. Relacionamentos são importantes para você. Trabalhar com outras pessoas te faz feliz, e trocar ideias com outras pessoas te traz uma enorme satisfação. Você sente que sua alegria no trabalho aumenta você constrói conexões significativas no seu ambiente de trabalho e tem uma vida social ativa.',
        descriptionSeeMore:
          'Não se trata apenas de ter pessoas à sua volta. Você pode ser sociável, mas se trata da qualidade do tempo que você passa com as pessoas. Alguém que está ciente e dá atenção ao que se passa nas conversas, em vez de olhar emails ou verificar o telefone, mostra o quanto realmente se importa. Durante as reuniões com seu gerente, você sabe que você é parte importante da equipe quando eles entendem o que é importante pra você, escutam ativamente e conversam pessoalmente com você. A melhor forma de recompensar seu trabalho árduo é fazer parte de um projeto em equipe e colaborar com seus colegas. E, claro, muito trabalho e pouca diversão é chato - sair pra comemorar com a equipe ou uma atividade construtiva em equipe são eventos que criam uma cultura organizacional que te traz grande felicidade.',
      },
      tellMe: {
        title: 'Me diga',
        descriptionSeeLess:
          'Você valoriza palavras que demonstrem agradecimento em seu ambiente de trabalho. O elogio é importante pra você. Você gosta de saber que seus esforços e realizações estão sendo reconhecidos. Ouvir um "obrigado" ou "bom trabalho" significa mais para você do que uma recompensa ou um high-five.',
        descriptionSeeMore:
          'Estas afirmações da sua gerência e de seus colegas provam para você que seu trabalho árduo e tempo estão fazendo diferença e contribuindo para o propósito da organização. Não se trata apenas de palavras, mas da sinceridade por trás das palavras. É mais significativo para você quando você entende o "porquê" por trás do reconhecimento, já que isso mostra que eles realmente sabem e entendem o tempo e o comprometimento que você empregou. Depois de concluir um grande projeto ou ir além das expectativas para uma iniciativa importante da organização, significa muito quando um colega ou seu gerente passa em sua mesa ou envia um email para te agradecer pessoalmente. Quando você não está esperando um reconhecimento, isso reforça o fato de que você está em um ambiente de trabalho que te valoriza de forma verdadeira. Palavras significativas de várias formas, vindas de pessoas de toda a organização, contribuem para uma cultura organizacional que mostra que você é valorizado e é importante para a equipe!',
      },
      supportMe: {
        title: 'Me Apoie',
        descriptionSeeLess:
          'Você gosta de trabalhar em equipe e sabe que pode fazer mais dessa forma do que sozinho. Isso não significa, necessariamente, que você seja mais atraído por projetos colaborativos, mas trabalhar com colegas que são dispostos a ajudar é importante pra você. Você se sente valorizado quando os outros oferecem apoio quando você está sobrecarregado, tem prazos curtos a cumprir ou que estão prestes a vencer.',
        descriptionSeeMore:
          'Isto mostra que eles gostam de ter você mais do que como funcionário, mas como pessoa - eles se importam com você e com o que precisa para ser bem-sucedido. Um ambiente de trabalho que não só reconhece os resultados mas também fornece apoio para atingí-los é o que você mais valoriza. Quando as pessoas vão além de suas tarefas diárias para te ajudar em um projeto ou simplesmente verificar como você está durante um momento estressante, você sabe que sua contribuição é importante. Para você, isso vai além do ambiente de trabalho. Você se sente bem quando as pessoas notam que você pode estar tendo um dia difícil ou lidando com algum problema pessoal fora do trabalho e oferecem ajuda, independentemente se isso está aliviando ou não sua carga no trabalho, ou simplesmente estão lá para conversar. Estes atos de bondade mostram que você é valorizado e é uma parte importante da equipe.',
      },
      trustMe: {
        title: 'Trust Me',
        descriptionSeeLess:
          'Você é um líder nato e prospera quando recebe maiores responsabilidades. Enquanto alguns se acomodam com o conforto de suas tarefas diárias, você realmente atinge seu melhor quando te permitem liderar novas iniciativas. Você se sente mais valorizado quando as pessoas a sua volta notam seu trabalho árduo e te recompensam com uma chance de mostrar mais de seus pontos fortes. Você considera uma grande honra quando te confiam projetos de alta importância e muito da sua confiança no trabalho provém dessas oportunidades que te são concedidas.',
        descriptionSeeMore:
          'É importante notar que suas motivações pessoais estão voltadas para oportunidades de crescimento, o que significa que o acréscimo de tarefas sem o de responsabilidade não é algo que você busca. Você deseja aprimorar e expandir suas habilidades, então um investimento no seu desenvolvimento profissional é sempre bem-vindo! Nada te deixa mais feliz no trabalho do que saber que as pessoas confiam em você para liderar estratégias importantes para a empresa. Um ambiente que te permite abrir suas asas é ideal. Estas experiências te mostram que você é valorizado e é uma parte importante da equipe.',
      },
      undefined: {
        title: 'Qual é seu Estilo de Reconhecimento?',
        descriptionSeeLess:
          'Queremos te conhecer melhor! Responda nossa pesquisa e descubra seu estilo de reconhecimento.',
        noStyleYet: '{userName} ainda não tem um Estilo de Reconhecimento.',
      },
      seeOnboarding: 'Ver Onboarding',
      editPreferences: 'Editar Preferências',
      seeLess: 'Ler Menos',
      seeMore: 'Ler Mais',
      seeAllMyStyles: 'Ver todos os meus estilos',
    },
    recognitionMemories: {
      title: 'Memórias de Reconhecimento',
      titleSingleMemory: 'Memória de Reconhecimento',
      noRecognitionMemories: {
        youDontHave: 'Você ainda não tem {title}',
        userDoesntHave: '{userName} ainda não tem {title}',
      },
      received: 'Recebidas',
      sent: 'Enviadas',
      viewAll: 'Ver Todas',
      viewGivenUserRecognitions: 'Ver Reconhecimentos de {userName}',
    },
    changeProfilePicture: {
      dialogTitle: 'Altere sua foto de perfil!',
      dragAndDrop:
        'Arraste e solte sua linda foto de perfil ou simplesmente clique aqui!',
      selectPicture: 'Selecionar sua foto de perfil',
      savePicture: 'Salvar Foto de Perfil',
    },
    snackbar: {
      success: {
        informationUpdated: 'Suas informações foram atualizadas com sucesso!',
        profilePictureUpdated: 'Sua foto de perfil foi atualizada com sucesso!',
      },
      error: { userNotFound: 'Usuário não encontrado' },
    },
  },
  sparcks: {
    sparckMemories: {
      header: {
        firstLine: 'Caso tenha esquecido...',
        secondLine: 'Você é incrível!',
      },
      card: { privateSparck: 'Este Sparck é privado' },
      viewSparck: 'Ver Sparck',
      sent: 'Enviados',
      received: 'Recebidos',
      noSparckMemories: 'Ainda não há reconhecimentos.',
      onlyPublicSparcksMessage:
        'Você está vendo somente os reconhecimentos públicos.',
      public: 'Públicos',
      private: 'Privados',
    },
    sparck: {
      header: 'Quem vamos reconhecer hoje?',
      selectPeopleToRecognize:
        'Selecione as pessoas que você quer reconhecer abaixo',
      viewAll: 'Ver Todos',
      usersToRecognize: 'Usuários a serem Reconhecidos',
      formLabels: {
        name: 'Quero reconhecer...',
        event: 'O que vamos comemorar?',
      },
      advancedSearchOptions: 'Opções de Pesquisa Avançada',
      selectUsersFirst: 'Selecione os usuários a serem reconhecidos primeiro',
      noRecognitionEventFound:
        'Nenhum evento de reconhecimento foi encontrado para os usuários selecionados',
      advancedSearchDialogTitle: 'Pesquisa Avançada',
      makeMeaningfull: 'O que tornará isto mais significativo para',
      personalize: 'Personalize Seu Reconhecimento',
      needSomeIdeas: 'Precisa de Ideias?',
      sparckMessagePlaceholder: 'Escreva uma mensagem para {userName}...',
      sparckMentionWarning: {
        title: 'Risco de Privacidade!',
        description:
          'Você está @ mencionando alguém em uma mensagem privada. Isso fará com que o usuário @ mencionado tenha acesso à leitura da mensagem privada. Se você NÃO DESEJA permitir isso, por favor, remova o usuário @ mencionado da mensagem.',
        actionLabel: 'Entendi!',
      },
      selectedUsers: 'usuários selecionados',
      andMore: '{users} e mais {selectedCount}...',
      makePrivate: 'Tornar Privado',
      preview: 'Visualizar',
      send: 'Enviar',
      prefers: 'prefere ser reconhecido',
      prefersNot: { prefers: 'prefere', notToBe: 'não ser reconhecido' },
      inPublic: 'publicamente',
      inPrivate: 'em particular',
      by: 'por',
      in: 'em',
      noPreferences: 'não especificou preferências para',
      include: 'Incluir',
      rewardOptions: {
        reward: 'Recompensa da Organização',
        rewardNGC: 'Recompensa de Varejista',
      },
      selectedReward: 'Recompensa Selecionada',
      chooseAnother: 'Escolher Outra',
      selectedRecognitionEventAutomaticBankWarning:
        'O Evento de Reconhecimento selecionado tem um valor de recompensa que será automaticamente adicionado ao Banco de Recompensas do recipiente.',
      giftCardRewardDetails: 'Detalhes do Gift Card',
      virginExperiencesRewardDetails:
        'Detalhes da Recompensa de Virgin Experiences',
      selectOption: 'Selecione uma opção de',
      rewardFilterLabels: { all: 'Todos' },
      noRewards: 'Não há',
      sparckIdeas: {
        header: 'Aqui estão algumas Ideias de Sparck!',
        fieldLabel: 'Categoria da Mensagem',
      },
      sparckRewardsListFilterInfo:
        'Os resultados estão sendo filtrados com base na quantia máxima do evento de reconhecimento selecionado: {maxAmount}',
      allRewards: 'Todas',
      favoritesOnly: 'Somente Favoritas',
      rewardsSearch: 'Pesquisar Recompensas',
      searchForAReward: 'Pesquisar uma recompensa...',
      noRewardsFound: 'Nenhuma recompensa encontrada.',
      sparckConfirmGiftCardRewardDetails: {
        selectGiftCardAmount: 'Selecione o valor do Gift Card',
        recommendedGiftCardAmount: 'Valor recomendado para o Gift Card',
        selectADifferentValueAmount: 'ou selecione um valor diferente',
      },
      sparckConfirmVirginExperiencesrewardDetails: {
        chooseARewardOptionBellow: 'Escolha uma  opção abaixo:',
        confirmSelectedReward: 'Confirme a recompensa selecionada',
      },
    },
    sparckDisplayAnswer: {
      yourResponseWas: 'Sua resposta foi:',
      clickAnyMessage:
        'Clique em qualquer mensagem abaixo para enviar uma resposta rápida',
      thankYou: 'Obrigado!',
      youAreTooKind: 'Você é muito gentil!',
      wow: 'Uau!',
      typeCustomResponse: 'Ou digite uma resposta personalizada',
      placeholder: 'Muito obrigado pelo seu reconhecimento!',
      sendYourAnswer: 'Envie sua resposta!',
    },
    sparckDisplayComponent: {
      goBack: 'Voltar',
      noAuthorizationToViewSparck:
        'Você não tem permissão para visualizar este Sparck.',
      returnToDashboard: 'Voltar ao Dashboard',
      youHave: 'Você foi',
      has: 'foi',
      beenRecognized: 'Reconhecido!',
      weAreSoHappy: 'Estamos muito felizes',
      forYou: ' por você',
      congratulations: 'Parabéns!',
      private: 'privada',
      messageFrom: 'Uma mensagem {private} de',
      revealYourGift: 'Click below and reveal your gift!',
    },
    snackbar: {
      success: {
        youSparcked: 'Sparck enviado com sucesso!',
        responseSent: 'Sua resposta foi enviada com sucesso!',
      },
    },
  },
  people: {
    connect: 'Conecte-se com seus Colegas',
    searchFieldPlaceholder:
      'Procure por nomes, emails, departamentos, unidades de negócio e outros...',
    favoritesLabel: 'Somente meus favoritos',
    tooltip: {
      add: 'Adicionar aos favoritos',
      remove: 'Remover dos favoritos',
    },
    viewProfile: 'Ver perfil',
    search: 'Buscar',
    infoButtons: { recognize: 'Reconhecer' },
  },
  language:{
    chooselanguage:'Escolha seu idioma'
  },
  insights: {
    company: {
      title: 'Insights da Organização',
      link: 'Ir para Insights da Equipe',
      recognitionActivity: 'Nossa Atividade de Reconhecimento',
      recognitionByProgram: 'Reconhecimento por Evento',
      pulseSurveys: 'Pesquisas Pulse',
      engagementScore: 'Nível de Engajamento',
      engagementDrivers: 'Indicadores de Engajamento',
      topFiveAreas: '5 Principais Áreas',
    },
    team: {
      title: 'Insights da Equipe',
      link: 'Ir para Insights da Organização',
      recognitionByProgram: 'Reconhecimento por Evento',
      engagementScore: 'Nível de Engajamento',
      engagementDrivers: 'Indicadores de Engajamento',
      topFiveAreas: '5 Principais Áreas',
      rewardBudget: 'Orçamento de Recompensas do Meu Time',
      recognition: 'Reconhecimento da Equipe',
      pulseSurveyResults: 'Resultados da Pesquisa Pulse',
      rewardBudgetProgressLabels: { spent: 'Gasto', total: 'Total' },
      sparckTable: {
        titles: {
          myRecognitionReport: 'Minha Atividade de Reconhecimentos',
          myTeamRecognitionReport: 'Atividade de Reconhecimentos do Meu Time',
        },
        picture: 'Foto',
        recognitionsSent: 'Reconhecimentos Enviados',
        rewardsSent: 'Recomensas Enviadas',
        lastRecognitionSent: 'Último Reconhecimento Enviado',
        never: 'Nunca',
        action: 'Ação',
        recognizeNow: 'Reconhecer Agora',
        recognitionsReceived: 'Reconhecimentos Recebidos',
        recognitionReadiness: 'Rapidez em Reconhecer',
      },
    },
    filters: {
      labels: {
        period: 'Período',
        beheardSurvey: 'Pesquisa #BeHeard',
        pulseSurveys: 'Pesquisas Pulse',
        managers: 'Gerentes',
        loadingText: 'Carregando...',
      },
    },
    noData: 'Não há dados a serem mostrados.',
    managerSelectionError:
      'Certifique-se de haver selecionado um Gerente nos filtros acima.',
    sparcksProgressLabels: { sent: 'Enviados', goal: 'Meta' },
  },
  periodLabels: {
    allTime: 'Todos',
    thisYear: 'Este Ano',
    thisQuarter: 'Este Trimestre',
    thisMonth: 'Este Mês',
  },
  coreValues: {
    title: 'Valores Fundamentais',
    headerDescription:
      'Estes são os comportamentos chave que vão trazer a sua cultura à vida! Aprenda mais {link}!',
    descriptionCreateMode:
      'Vamos adicionar um valor fundamental ao seu newsfeed. Comece abaixo. Veja um exemplo {link}',
    example: 'Exemplo',
    validations: { descriptionIsRequired: 'Descrição do Valor é obrigatória' },
  },
  externalRewardsUpload: {
    title: 'Fazer Upload de Informações da Recompensa Externa',
    description:
      'Recompensas externas são recompensas fornecidas aos funcionários fora do sistema Sparck. Se você desejar manter registro de suas recompensas externas como parte do orçamento Sparck você poderá usar nossa planilha Excel como modelo para realizar o upload. O modelo é gerado de forma dinâmica especificamente para sua organização e inclui seus Eventos de Reconhecimento específicos.  ',
    selectAppropriateEvents:
      'Por favor, certifique-se de selecionar os Eventos de Reconhecimento apropriados para que possamos manter registro de seu orçamento de forma precisa.',
    downloadExcelTemplate: 'Baixar modelo Excel',
  },
  inappropriateContent: {
    title: 'Conteúdo Impróprio',
    description:
      'Abaixo você verá todas as denúncias reportadas por seus funcionários e diferentes ações que você pode tomar em cada uma.',
    contentAccepted: 'Este conteúdo foi aceito!',
    contentRemoved: 'Este conteúdo foi removido!',
    contentPrivate: 'Este conteúdo agora é privado!',
    reporter: 'Reporter',
    contentCreator: 'Criador do Conteúdo',
    hasInappropriateText: 'Tem Texto Inapropriado',
    hasInappropriateImage: 'Tem Imagem Inapropriada',
    hasInappropriateVideo: 'Tem Vídeo Inapropriado',
    createdAt: 'Criado Em',
    goToPage: 'Ir Para Página',
    allowContent: 'Permitir Conteúdo',
    removeContent: 'Remover Conteúdo',
    makeContentPrivate: 'Tornar Conteúdo Privado (apenas para Sparcks)',
    thisContent: 'este conteúdo',
  },
  onboardingQuestions: {
    types: {
      choiceGroup: 'Grupo de Escolha',
      event: 'Evento',
      rating: 'Avaliação',
      simpleSelect: 'Seleção Simples',
      multipleSelect: 'Seleção Múltipla',
    },
    title: 'Perguntas de Introdução',
    description:
      'Seus funcionários acabaram de acessar o Sparck pela primeira vez. Estas são as perguntas que eles verão primeiro na seção de Introdução!',
    questionStatement: 'Pergunta',
    type: 'Tipo da Pergunta',
    displayToAllCompanies: 'Mostre a questão para todas as organizações',
    alternatives: 'Alternativas',
    alternative: 'Alternativa',
    actions: 'Ações',
    validations: {
      statementIsRequired: 'Afirmação é obrigatória',
      typeIsRequired: 'Tipo da pergunta é obrigatório',
    },
  },
  rewards: {
    title: 'Benefícios',
    description:
      'Uma variedade de recompensas baseadas na sua cultura única. Seja criativo e divirta-se!',
    fields: {
      rewardName: 'Nome do Benefício',
      monetaryValue: 'Valor Monetário',
      limitedQuantityReward: 'Há uma quantidade limitada para este benefício',
      inventoryQuantity: 'Inventário',
    },
    manageReward: {
      validations: {
        suggestionRequired: 'Sugestão é obrigatória',
        rewardNameRequired: 'Nome do Benefício é obrigatório',
        monetaryValueRequired: 'Valor Monetário é obrigatório',
        minimumInventoryQuantity:
          'Quantidade do Inventário deve ser maior que zero',
        maximumInventoryQuantity:
          'Quantidade do Inventário deve ser menor que 9999',
        inventoryQuantityRequired: 'Quantidade do Inventário é obrigatória',
      },
    },
  },
  profileQuestions: {
    title: 'Perguntas de Perfil',
    description:
      'São perguntas pessoais e profissionais para incluir nos perfis de seus funcionários para descobrir como cada um é.',
    questionsSelectedFromLibrary: 'Perguntas selecionadas da biblioteca!',
    selectFromLibrary: 'Selecione Perguntas da Biblioteca',
    questions: 'Perguntas',
    fields: {
      statement: 'Pergunta',
      personal: 'Pessoal',
      professional: 'Profissional',
    },
    validations: {
      statementRequired: 'Declaração é obrigatória',
      questionTypeRequired: 'Tipo da Pergunta é obrigatória',
    },
  },
  recognitions: {
    title: 'Eventos de Reconhecimento',
    description:
      'É uma conquista e/ou comportamento que você deseja reforçar e reconhecer para elevar o desempenho e sua cultura única.',
    types: {
      customRecognitionProgram: 'Evento de Reconhecimento Customizado',
      workiversary: 'Aniversário de Trabalho',
      lifeEvent: 'Evento de Vida',
      coreValues: 'Valores Fundamentais',
      peerToPeer: 'Pessoa-para-Pessoa',
      spotBonus: 'Bônus',
      peerNominatedAward: 'Nomeação para Funcionários',
      managerNominatedAward: 'Nomeação para Gestores',
      employeeOfTheMonth: 'Funcionário do Mês',
      employeeOfTheYear: 'Funcionário do Ano',
    },
    frequencies: {
      monthly: 'Mensal',
      quarterly: 'Trimestral',
      semiAnnually: 'Semestral',
      annually: 'Anual',
      lifetime: 'Uma vez',
    },
    approved: {
      directManager: 'Gerente Direto',
      companyAdministrator: 'Administrador da Organização',
    },
    milestones: {
      everyYear: 'Anualmente',
      fixed: 'Fixo',
      customMilestone: 'Marco Personalizado',
    },
    groups: {
      peers: 'Funcionários',
      manager: 'Gestores',
      executiveTeam: 'Time Executivo',
      ceo: 'CEO',
    },
    settings: {
      aPersonalNote: 'Uma Nota Pessoal',
      aPersonalPhoneCall: 'Um Telefonema',
      aPersonalText: 'Um Texto Pessoal',
      inPerson: 'Pessoalmente',
      staffMeeting: 'Reunião de Equipe',
      companyEvent: 'Evento da Organização',
      groupEmail: 'E-mail do Grupo',
      companyDashboard: 'Dashboard da Organização',
      socialMediaShoutout: 'Mensagem nas Redes Sociais',
    },
    attributes: {
      workiversaryYear: 'Ano de Aniversário do Trabalho',
      lifeEvent: 'Evento de Vida',
      coreValue: 'Valor Fundamental',
    },
    icons: {
      anniversary: 'Aniversário de Trabalho',
      teamWork: 'Trabalho em Equipe',
      innovation: 'Inovação',
      strategic: 'Estratégico',
      mentorTeach: 'Mentoria',
      training: 'Treinamento',
      creative: 'Criatividade',
      newHome: 'Novo Lar',
      havingABaby: 'Bebê Chegando',
      graduation: 'Graduação',
      retirement: 'Aposentadoria',
      technicalDevelopment: 'Desenvolimento Técnico',
      exceededGoals: 'Metas Excedidas',
      fun: 'Diversão',
      celebration: 'Celebração',
      bugFix: 'Correção de Bug',
      newFamilyMember: 'Novo Membro Familiar',
    },
    welcome: {
      title: 'Bem-Vindo(a)! Precisa da ajuda do nosso assistente?',
      description:
        'Você está aqui porque quer criar a experiência perfeita para seus funcionários com um evento de reconhecimento personalizado. Oferecemos muitas configurações básicas e avançadas. Deseja nossa orientação durante este processo?',
      dontAskAgain: 'Não me pergunte outra vez',
      noThanks: 'Não, obrigado. Eu consigo.',
      showMeTheRopes: 'Sim, me mostre como funciona, por favor.',
    },
    fields: {
      program: 'Evento',
      programIcon: 'Ícone do Evento',
      programIconPlaceholder: 'Meu Reconhecimento Especial',
      setCustomTimeframe: 'Defina um Prazo Personalizado',
      setPeriod: 'Definir período',
      startDate: 'Data de Lançamento',
      endDate: 'Data de Encerramento',
      period: 'Período',
      howManyTimesPerPeriod: 'Quantas vezes por período?',
      tooltips: {
        recurrenceLimit:
          'Este é o número máximo de vezes que este evento de reconhecimento pode ser usado por funcionário no período selecionado.',
        recurrenceLimitPerEmployee:
          'Qual é o limite de vezes que um mesmo funcionário pode receber este evento de reconhecimento?',
        maximumRewardAmountPerRecognition:
          'Se houver múltiplas recompensas, inclua o orçamento máximo por recompensa para que você possa reconhecer somente até o valor desse orçamento.',
      },
      limitPerPeriodCheckbox:
        'Limite o número de funcionários que podem ser reconhecidos por período',
      limitPerEmployee: 'Limite de funcionários por período',
      allowRewards: 'Permitir Recompensas para este Evento de Reconhecimento',
      allowOrganizationalRewards: 'Permitir Recompensas da Organização',
      allowRetailerRewards: 'Permitir Recompensas de Varejistas',
      canAcceptOnly: 'Can Accept Only',
      canAcceptToBankOnly: 'Can Accept to Bank Only',
      canAcceptAndBank: 'Can Accept and Bank',
      canAcceptOnlyTooltip:
        'Recipient of reward can only accept the reward. They can’t save the value of the reward to their Sparck bank.',
      canAcceptToBankOnlyTooltip:
        'Recipient of reward can only add the value of the reward to their Sparck bank.',
      canAcceptAndBankTooltip:
        'Recipient of reward can either accept the reward or add the value of the reward to their Sparck bank.',
      budgetPerReward: 'Orçamento por Recompensa',
      setMilestoneBonus:
        'Configurar Bonus por Marco Histórico (por ocorrência de evento)',
      setMilestoneBonusTooltip:
        'Certifique-se de haver selecionado a disponibilidade Anual para desbloquear as configurações de Marco Histórico',
      wayToGoTeam: 'É isso aí, Equipe!',
      extraMile: 'Quando nossa equipe anda a segunda milha',
      whoCanRecognize: 'Quem pode Reconhecer',
      whoCanBeRecognized: 'Quem pode ser Reconhecido',
      hasReward: 'Oferece Recompensa',
      rewardAmount: 'Quantia da Recompensa',
      timeframe: 'Prazo',
      hasRequiresApprovalBy: 'Requer Permissão?',
      requiresApprovalBy: 'Permissão de',
      allowGroupRecognition: 'Permitir Reconhecimento em Grupo',
      whoIsThisEventFor: 'Para quais pessoas é este evento?',
      whichRolesCanSend:
        'Quais Responsabilidades/Cargos podem enviar Reconhecimentos com este Evento?',
      whichDemographicsCanReceive:
        'Quais Demografias podem receber Reconhecimentos com este Evento?',
    },
    groupRecognitionsBudgetImpact:
      'Reconhecimentos em grupo não impactarão o orçamento individual do destinatário',
    groupRecognitionsBudgetImpactTooltip:
      'Reconhecimentos em grupo não impactarão o orçamento individual do destinatário. Portanto, se um destinatário tem um orçamento restante de $10 para o período e recebe um reconhecimento em grupo no valor de $50, ele(a) ainda terá $10 restantes em seu orçamento individual.',
    rewardConfigurations: 'CONFIGURAÇÕES DE RECOMPENSAS',
    budgetImpact: 'IMPACTO NO ORÇAMENTO',
    budgetPerReward: 'Orçamento por Recompensa',
    frequency: 'Frequência',
    progressBarLabels: {
      used: 'Utilizado',
      event: 'Evento',
      remaining: 'Restante',
    },
    employeesTargeted: 'Público-Alvo',
    total: 'TOTAL',
    year: 'ano',
    tabLabels: {
      general: 'Geral',
      advanced: 'Avançado',
      tooltip: 'Há campos obrigatórios para esta seção',
    },
    formSteps: {
      general: {
        title: 'Informações Gerais',
        description:
          'Defina o Nome do seu Evento de Reconhecimento, o Ícone que você quer associar a ele (visível em Publicações de Reconhecimento no Feed, por exemplo) e uma descrição para ilustrar melhor porque este evento de reconhecimento é tão incrível!',
      },
      whoCanRecognize: {
        title: 'Quem pode enviar Reconhecimento por este Evento?',
        description:
          'Selecione os grupos Demográficos e Responsabilidades que você deseja permitir que enviem reconhecimentos relacionados a este evento. Todos terão permissão para ver este Evento de Reconhecimento no Feed mas somente os grupos demográficos e responsabilidades selecionados poderão enviar reconhecimentos associados a ele.',
      },
      whoCanReward: {
        title: 'Quem pode oferecer Recompensas por este Evento?',
        description:
          'Selecione os grupos Demográficos e as Responsabilidades que você deseja permitir recompensar por este evento. Todos os grupos selecionados que puderem reconhecer este evento também poderão, por padrão, oferecer recompensas.',
      },
      whoCanBeRecognized: {
        title: 'Quem pode receber Reconhecimento por este Evento?',
        description:
          'Selecione os grupos Demográficos e Responsabilidades que você deseja permitir que recebam reconhecimentos relacionados a este evento. Todos terão permissão para ver este Evento de Reconhecimento no Feed mas somente os grupos demográficos e responsabilidades selecionados poderão receber reconhecimentos associados a ele.',
      },
      timeframe: {
        title: 'Cronograma',
        description:
          'Este é um evento de reconhecimento fixo ou tem um prazo? Aqui é possível configurar um tempo limite para a existência deste evento de reconhecimento.',
      },
      availability: {
        title: 'Disponibilidade',
        description:
          'Quantas vezes este evento de reconhecimento deve estar disponível em um certo período de tempo? 10 vezes a cada mês? 4 vezes por trimestre? Uma vez ao ano?',
      },
      nomination: {
        title: 'Nomeações',
        description:
          'Esta tela permite definir se um funcionário pode ser indicado para este Evento de Reconhecimento e qual será o período de indicação.',
      },
      rewards: { title: 'Recompensas', description: 'Detalhes de Recompensas' },
      milestones: {
        title: 'Marcos Históricos',
        description: 'Detalhes de Marcos Históricos',
      },
      approval: {
        title: 'Fluxo de Aprovação',
        description: 'Detalhes do Fluxo de Aprovação',
      },
    },
    next: 'Próximo',
    back: 'Voltar',
    finish: 'Concluir',
    milestonesTable: {
      columns: { yearNumber: 'Ano', rewardAmount: 'Quantidade Da Recompnesa' },
    },
    validations: {
      iconIsRequired: 'Ícone é obrigatório',
      startDateIsRequired: 'Data de Início é obrigatória',
      minimumEndDate:
        'Data de Encerramento não pode ser anterior à data de Início',
      endDateIsRequired: 'Data de Encerramento é obrigatória',
      recurrenceIsRequired: 'Recorrência é obrigatória',
      limitIsRequired: 'Limite é obrigatório',
      budgetPerRewardIsRequired: 'Orçamento por Recompensa é obrigatório',
    },
    manageRecognitionsTitle: 'Eventos de Reconhecimento',
    whatSpecialEvent:
      'Qual Evento de Reconhecimento especial você quer trazer para sua organização?',
    recognitionEvents: 'Eventos de Reconhecimento',
  },
  suggestions: {
    title: 'Sugestões',
    description:
      'Aqui estão as opiniões e contribuições de seus funcionários para sua organização.',
    fields: {
      date: 'Data',
      from: 'De',
      suggestion: 'Sugestão',
      haveYouReplied: 'Você respondeu?',
    },
    settings: { reviewed: 'Analisada', implemented: 'Implementada' },
    manageSuggestion: 'Gerenciar Sugestão',
    replyWasSent: 'Sua resposta foi enviada!',
    accept: 'Aceitar',
    decline: 'Recusar',
    confirm: 'Confirmar',
    suggestion: 'Sugestão',
    answer: 'Resposta',
    typeReplyForSuggestion: 'Digite sua resposta para esta sugestão',
    sendReply: 'Send reply',
    moveForwardWithSuggestion: 'Você vai seguir adiante com essa sugestão?',
    areYouSure: 'Você tem certeza?',
    whereSuggestionChatBegins:
      'Aqui começa a conversa sobre esta sugestão. A primeira mensagem abaixo é a própria sugestão',
    typeYourReplyHere: 'Digite sua resposta aqui...',
  },
  takeAction: {
    title: 'Tomar Ação',
    description:
      'É hora de personalizar seu reconhecimento! Essas pessoas foram reconhecidas mas, ainda assim, significaria muito para elas ouvir de você. Veja detalhes do reconhecimento e como tomar ação em cada um.',
    filters: { open: 'Abertos', complete: 'Completos' },
    setAReminder: 'Definir um Lembrete',
    setReminder: 'Definir Lembrete',
    fields: {
      whoWasRecognized: 'Quem foi Reconhecido',
      byWhom: 'Por Quem',
      recognitionEvent: 'Evento do Reconhecimento',
      details: 'Detalhes',
      viewMessage: 'Ver Mensagem',
      recognizeThemThisWay: 'Reconheça Eles Desta Forma',
      reminderDate: 'Data do Lembrete',
    },
    recognize: 'Reconheça!',
    addReminder: 'Adicionar Lembrete',
    postComment: 'Publicar Comentário',
    markAsComplete: 'Marcar como Completo',
    delete: 'Remover',
    snackbar: {
      success: {
        actionCompleted: 'Esta ação foi realizada!',
        actionDisregarded: 'Esta ação foi descartada',
      },
    },
  },
  budgets: {
    companyRewardBudgetBreakdown: {
      budgetBreakdownFilters: {
        businessUnit: 'Unidade de Negócio',
        employee: 'Funcionário',
        region: 'Região',
        territory: 'Território',
      },
    },
  },
  budget: {
    budgetReports: {
      rewardsReport: {
        title: 'Relatório de Recompensas',
        description:
          'Este relatório fornece uma lista de todas as recompensas dadas aos funcionários desde a primeira do ano, a quantidade (valor), e de quem elas vieram. Clique no botão Baixar Relatório abaixo para baixar uma versão Excel deste relatório.',
      },
      budgetActivityReport: {
        title: 'Relatório de Atividade de Orçamento',
        description:
          'Este relatório identifica a quantidade gasta e a quantidade restante no período atual para cada funcionário na organização. Clique no botão Baixar Relatório abaixo para baixar uma versão Excel deste relatório.',
      },
      downloadReport: 'Baixar Relatório',
    },
    budgetDetails: {
      tabLabels: {
        general: 'Geral',
        externalRewardsUpload: 'Upload de Recompensas Externas',
        reports: 'Relatórios',
      },
    },
    budgetDetailsBody: {
      budgetSettings: 'Configurações de Orçamento de {limitType}',
      labels: {
        distributionType: 'Tipo de Distribuição',
        proRate: 'Rateio',
        rolloverPerPeriod: 'Transferir entre Períodos',
        rolloverAnnually: 'Transferir para o Ano Seguinte',
        amountForEachEmployee: 'Quantidade para cada funcionário',
      },
      breakdownBy: 'Relatório por',
      noSettings:
        'Não há configurações para este tipo de distribuição no momento.',
    },
    budgetDetailsHeader: {
      budgetSettings: 'Configurações de Orçamento',
      yearTypeHeaderCard: {
        title: 'Tipo do Calendário de Orçamento',
        description:
          'Você pode definir o Calendário de Orçamento como um calendário tradicional (Janeiro-Dezembro) ou um calendário de Ano Fiscal onde você define a data de início',
      },
      periodHeaderCard: {
        title: 'Período',
        description: 'Seu orçamento será reiniciado ao início de cada período.',
        period: 'Período',
      },
      budgetForNewEmployeesHeaderCard: {
        title: 'Orçamento para Novos Funcionários',
        description:
          'Quantidade em dólares que cada funcionário irá receber, se definida como automática.',
      },
      budgetType: 'Tipo de Orçamento',
      amount: 'Quantidade',
    },
    manageBudgetDistributionDemographics: {
      breakdownBy: 'Relatório por {selectedDemographic}',
      selectedDemographic: 'Grupo Demográfico',
      labels: {
        distributeEvenly: 'Distribuir Igualmente',
        amount: 'Quantidade',
      },
      byDemographic: 'Por Grupo Demográfico',
      byEmployee: 'Por Empregado',
      formHelperText:
        'Usar esta seção para calcular automaticamente as quantidades para os campos abaixo',
    },
    manageBudgetDistributionFlat: {
      amountFieldLabel:
        'Que quantidade você quer alocar como limite para cada funcionário?',
    },
    manageBudgetDistributionIndividual: {
      amountFieldLabel: 'Quantidade',
      selectIndividuals: 'Selecionar Indivíduos',
      dialogTitle: 'Selecionar Indivíduos',
      noEmployeesFound:
        'No employees were found with the current search criteria.',
      labels: {
        nameOrTitle: 'Nome/Título',
        demographics: 'Demografia',
        roles: 'Responsabilidades',
      },
      search: 'Buscar',
    },
    manageBudgetForm: {
      title: 'Bem-Vindo(a) à Tela de Configurações de Orçamento',
      description:
        'Vamos começar determinando como sua organização irá implementar seu orçamento de reconhecimento. Você pode escolher um limite baseado em gastos, um limite baseado em recebimento ou ambos.',
    },
    manageBudgetGeneralForm: {
      title: 'Configurações Gerais',
      labels: {
        yearType: 'Tipo do Calendário de Orçamento',
        startDate: 'Data de Início',
        endDate: 'Data de Encerramento',
        budgetPeriod: 'Período do Orçamento',
        budgetTypeForNewEmployees: 'Tipo de Orçamento para Novos Funcionários',
        amountPerNewEmployee: 'Quantidade por Novo Funcionário',
      },
      invalidDate: 'Data Inválida',
    },
    manageBudgetLimitTypes: {
      setRules: 'Definir  regras por {title}',
      spendingLimitSection: {
        title: 'Limite Baseado em Gastos',
        description:
          'Você pode selecionar quais funcionários (todos ou somente alguns) tem um orçamento para gastar com funcionários da organização e quanto será tal orçamento.',
      },
      receivingLimitSection: {
        title: 'Limite Baseado em Recebimento',
        description:
          'Você pode selecionar quais funcionários (todos ou somente alguns) tem permissão para receber recompensas e qual será o total do orçamento para as mesmas.',
      },
    },
    manageBudgetProrate: { prorateType: 'Tipo de Rateio' },
    manageBudgetReceivingForm: { title: 'Regras de Recebimento' },
    manageBudgetRollover: {
      labels: {
        allowRolloverForUnusedRewardBudget:
          'Permitir que funcionários transfiram orçamento não utilizado de um período para o próximo no mesmo calendário ou ano fiscal.',
        allowRolloverFromOneYearToAnother:
          'Permitir que funcionários transfiram recursos de um ano orçamentário para o próximo',
      },
    },
    manageBudgetSpendingForm: { title: 'Regras de Gastos' },
    manageBudget: {
      formHandlerTitle: 'Configuração de Orçamento',
      description: 'Descrição do Orçamento',
      budgetFormTitle: 'Orçamento',
      validations: {
        periodRequired: 'Período é obrigatório',
        startDate: {
          required: 'Data de Início é obrigatória',
          invalidDate: 'Data Inválida',
        },
      },
    },
    budgetPeriods: {
      monthly: 'Mensal',
      quarterly: 'Trimestral',
      semiAnnually: 'Semestral',
      annually: 'Anual',
    },
    budgetProrateTypes: {
      noBudgetInPeriod: 'O Funcionário não recebe orçamento no período atual',
      remainingFullMonths:
        'Rateio baseado em meses completos restantes no período',
      remainingMonthsWithCurrent:
        'Rateio baseado nos meses restantes do período (incluindo o atual)',
    },
    budgetDistributionTypes: {
      flat: {
        label: 'Plano',
        descriptionSpending:
          'Todos os funcionários recebem a mesma quantia para gastar com outros funcionários',
        descriptionReceiving:
          'Todos os funcionários recebem exatamente a mesma quantia por período',
      },
      individual: {
        label: 'Individual',
        descriptionSpending:
          'Definir Regras de Gastos por Indivíduos (ex.: John Doe, Jane Doe)',
      },
      demographics: {
        label: 'Grupo Demográfico',
        descriptionReceiving:
          'Definir Regras de Recebimento por grupo Demogŕafico (ex.: Departamento, Localidade)',
      },
    },
    budgetNewEmployeesBudgetTypes: {
      automatic: 'Automático',
      manual: 'Manual',
    },
    budgetYearTypes: { fiscal: 'Fiscal', calendar: 'Calendário' },
    updateBudgetSettings: 'Atualizar Orçamento',
    updateBudgetSettingsSnackbarSuccess:
      'Configurações de Orçamento atualizadas com sucesso.',
  },
  companyReporting: {
    sectionDataTitles: {
      recognitionsSent: 'Reconhecimentos Enviados',
      unusedRewards: 'Recompensas Não Utilizadas',
      feedMessagesCount: 'Contagem de Mensagens do Feed',
      numberOfComments: 'Número de Comentários',
    },
    reportSectionLabels: {
      topActiveUsers: 'Usuários Mais Ativos',
      noRecognitionsPerPeriod: 'Sem Reconhecimento por Período',
      financialReport: 'Relatório Financeiro',
    },
    formLabels: { section: 'Seção' },
    filters: { period: 'Período' },
    financialReportColumnHeaders: {
      recognitionDate: 'Data do Reconhecimento',
      recipientName: 'Nome do Destinatário',
      recognitionEvent: 'Evento do Reconhecimento',
      rewardType: 'Tipo de Recompensa',
      location: 'Localização',
      giverName: 'Nome do Remetente',
    },
    noRecognitionsByPeriod: {
      header: 'Usuários não reconhecidos neste período:',
      columnHeaders: {
        role: 'Responsabilidade',
        manager: 'Gerente',
        allTimeRecognitions: 'Todos os Reconhecimentos',
        lastRecognition: 'Último Reconhecimento',
      },
    },
    topActiveUsersColumnHeaders: {
      recognitionsSent: 'Reconhecimentos Enviados',
      messagesSent: 'Mensagens Enviadas',
      commentsPosted: 'Comentários Publicados',
      total: 'Total',
    },
  },
  sparckPreview: {
    title: 'É isso que o(a) {userName} verá',
    theSparckedPerson: 'a pessoa reconhecida',
  },
  sparckReward: {
    fixed: 'Fixo',
    range: 'Variado',
    options: 'Opções de {name}',
    giftCardMustBeWithin:
      'Gift Card deve estar dentro do intervalo e incremento de {value}',
    giftCardCantBeLess: 'Valor do Gift Card não pode ser menor que {value}',
    giftCardCantBeGreater: 'Valor do Gift Card não pode ser maior que {value}',
    selectGiftCardBetween:
      'Selecione um Gift Card entre {minimumValue} and {maximumValue}',
    recognitionProgramMaxBudget:
      'O orçamento máximo para este evento de reconhecimento é {value}',
    confirmAmount: 'Confirmar {amount}',
  },
  myRewards: {
    walletDescription:
      'When you reject a reward and bank it, you are putting the value of that reward into your wallet for later use. When you are ready to use those funds, simply click the Purchase Gift button below to view the rewards catalog and select the item you want to purchase. Remember you can select any amount up to the available balance of your wallet for your purchase. Have fun shopping!',
    wishlistDescription:
      "Like certain rewards more than others? Add them to your wishlist so they are easily accessible to you as well as people recognizing you. To add items to your wishlist, click the Add Items To My Wishlist button below, identify the items you like and click the heart icon. It's as simple as that!",
    tabLabels: {
      home: 'Home',
      myRewards: 'Minhas Recompensas',
      myPurchases: 'Minhas Compras',
    },
    youHaveNewRewards: 'You have {count} new rewards!',
    total: 'Total {name}',
    cardHeaders: {
      rewardsFrom: 'Recompensas {name}',
      availableToUse: 'disponível para uso',
      myWallet: 'Minha Carteira',
      myWishlist: 'Minha Lista de Desejos',
    },
    addMore: 'Adicionar Mais',
    addItemsToWishlist: 'Adicionar itens à minha lista de desejos',
    list: {
      title: 'Minhas Recompensas',
      visualizationModes: { cards: 'Cartões', list: 'Lista' },
    },
    redeem: 'Resgatar {name}',
    catalog: {
      title: 'Catálogo de Recompensas',
      noItems: 'Não há',
      tabLabels: {
        rewards: 'Recompensas da Organização',
        rewardsNGC: 'Recompensas de Varejistas',
      },
      makeWishListPublic:
        'Tornar minha lista de desejos pública (outros usuários poderão vê-la)',
    },
    columns: {
      received: 'Recebido',
      details: 'Detalhes',
      from: 'De',
      actions: 'Ações',
      bank: 'Guardar',
      accept: 'Aceitar',
      clickToRedeem: 'Clique para aceitar este benefício',
    },
    purchaseGift: 'Comprar Presente',
    myGiftCardPurchases: 'Minhas Compras de Gift Cards',
    redeemYourGiftCard: 'Resgatar seu Gift Card',
    myRewardsPurchasesTable: {
      columnTitles: {
        giftCard: 'Gift Card',
        brand: 'Marca',
        amount: 'Quantidade',
        acceptedAt: 'Aceitado em',
      },
      noPurchases: 'Ainda não há compras de gift cards.',
    },
    snackbar: {
      success: {
        acceptedReward:
          'Você aceitou sua recompensa com sucesso. Por favor, verifique seu email para obter instruções para recolhê-la.',
        bankedReward: 'Você guardou esta recompensa!',
      },
    },
  },
  sparckAppreciationAssessment: {
    landing: {
      title: 'Bem-Vindo(a) à Análise de Reconhecimento Sparck',
      description:
        'Estamos muito animados em colaborar com a descoberta do seu estilo de apreciação principal no seu ambiente de trabalho. Leva aproximadamente 60 segundos e você terá acesso aos seus resultados imediatamente. Isso é só o início de um processo para entender melhor como você se sente valorizado no trabalho. Comece apenas preenchendo seu e-mail para que possamos enviar seu Estilo Sparck. O resto é opcional. Divirta-se!',
    },
    questions: {
      title: 'Descubra seu Estilo de Reconhecimento Sparck',
      description:
        'Esta pesquisa rápida te ajudará a determinar seu estilo de apreciação principal no seu ambiente de trabalho. Não pense demais, escolha a opção que mais combina com você. Que comece a diversão!',
      questionTitle: 'É mais importante para mim no trabalho quando...',
    },
    results: {
      title: 'Seu Estilo de Apreciação Sparck',
      sparckAppreciationStyle: 'Estilo de Apreciação Sparck',
      checkYourEmail:
        'Check your email for the full results! Don’t forget to check your spam folder if you don’t see it.',
    },
    labels: {
      companyName: 'Nome da Organização',
      gender: 'Gênero',
      generation: 'Geração',
      currentPosition: 'Posição Atual',
      email: 'Email (obrigatório)',
      getStarted: 'Começar',
      preferredLanguage: 'Idioma Preferido',
      send: 'Mostre meus resultados!',
      contactInfo: 'Vamos Começar!',
      demographicInfo: 'Quer compartilhar mais?',
      demographicInfoTooltip:
        'Informação coletada é confidencial e usada apenas para fins estatísticos',
    },
    currentPositions: {
      entryLevel: 'Iniciante',
      midLevel: 'Intermediário',
      seniorLevel: 'Sênior',
      executive: 'Executivo',
    },
    generations: {
      babyBoomer: 'Baby Boomer (Nascidos 1946 - 1964)',
      generationX: 'Geração X (Nascidos 1965 - 1980)',
      generationY: 'Geração Y (Nascidos 1981 - 1996)',
      generationZ: 'Geração Z (Nascidos 1997 - 2012)',
      generationAlpha: 'Geração Alpha (Nascidos 2013 - 2025)',
    },
    validations: {
      requiredEmail: 'Email é obrigatório',
      validEmail: 'Digite um e-mail válido',
    },
  },
}
