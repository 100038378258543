import {
  AccountBalanceOutlined as WalletIcon,
  // FavoriteBorder as WishlistIcon,
  HelpOutline as HelpIcon,
  // RedeemOutlined as RewardIcon,
} from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { get } from 'lodash'

import walletBackgroundWaves from 'assets/images/myRewards/wallet-background-waves.png'
// import wishlistBackgroundWaves from 'assets/images/myRewards/wishlist-background-waves.png'
import { useCompany, useUser } from 'contexts'
// import { WishType } from 'enums/wish'
import { t } from 'i18n'
// import { getUniqueColor } from 'types/colors'
// import { formatToUSD } from 'utils/currency'
import { API } from 'core/requests'

// const CARDS_PREVIEW_COUNT = 3

export default function MyRewardsComponent(props) {
  const {
    // wishes,
    // wishesAreLoading,
    // setDialogCatalogOpen,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setPurchaseGiftDialogOpen,
  } = props

  const { user } = useUser()!
  const { company } = useCompany()!
  const theme = useTheme()
  // const walletBalance = formatToUSD(+get(user, 'wallet.balance', 0))
  // const walletSaved = formatToUSD(+get(user, 'wallet.saved', 0))
  // const walletSpent = formatToUSD(+get(user, 'wallet.spent', 0))
  const walletBalance = Math.round(+get(user, 'wallet.balance', 0))
  const walletSaved = Math.round(+get(user, 'wallet.saved', 0))
  const walletSpent = Math.round(+get(user, 'wallet.spent', 0))

  // const wishesPreviewCards = wishes.filter(
  //   (_, index) => index < CARDS_PREVIEW_COUNT,
  // )

  const getWalletDetails = (label, value) => (
    <Typography variant='body1' color='text.primary' sx={{ mb: 0.5 }}>
      {t('myRewards.total', {
        name: label,
      })}
      :{' '}
      <Typography
        component='span'
        variant='h6'
        color='text.primary'
        fontWeight={700}
      >
        {value}
        <Typography
          component='span'
          color='text.secondary'
          sx={{ marginLeft: '3px' }}
        >
          Points
        </Typography>
      </Typography>
    </Typography>
  )

  // const getWishlistDetails = (label, value) => (
  //   <Typography color='text.primary' variant='body1' sx={{ mb: 0.5 }}>
  //     <Typography
  //       component='span'
  //       color='text.primary'
  //       variant='h6'
  //       fontWeight={700}
  //     >
  //       {value}
  //     </Typography>{' '}
  //     {t('myRewards.cardHeaders.rewardsFrom', {
  //       name: label,
  //     })}
  //   </Typography>
  // )

  const goToRewardStore = async () => {
    API.get('ssoRewardCloudToken', null, {queryParams: {}}).then((ssoData) => {
      if (ssoData && ssoData?.url) {
        // Redirect the user to the product page with the SSO token
        window.open(ssoData?.url)
      }
    })
  }

  return (
    <Grid container spacing={2} sx={{ mb: 3 }}>
      <Grid item xs={12} md={12}>
        <MyRewardsHeaderCard
          title={t('myRewards.cardHeaders.myWallet')}
          Icon={WalletIcon}
          helperIconText={t('myRewards.walletDescription')}
          isLoading={!user?.id}
          backgroundImage={walletBackgroundWaves}
          LeftContent={
            <Box>
              {getWalletDetails('banked', walletSaved)}
              {getWalletDetails('spent', walletSpent)}
            </Box>
          }
          RightContent={
            <Box
              sx={{
                display: { xs: 'flex', sm: 'inherit' },
                justifyContent: { xs: 'space-between', sm: 'center' },
                mt: { xs: 2, sm: 0 },
                alignItems: 'center',
              }}
            >
              <Box>
                <Typography
                  color='text.primary'
                  variant='h4'
                  fontWeight={700}
                  sx={{ textAlign: { sm: 'center' } }}
                >
                  {walletBalance}
                  <Typography
                    component='span'
                    color='text.secondary'
                    sx={{ marginLeft: '3px' }}
                  >
                    Points
                  </Typography>
                </Typography>
                <Typography
                  color='text.secondary'
                  variant='body2'
                  sx={{ textAlign: { sm: 'center' } }}
                >
                  {t('myRewards.cardHeaders.availableToUse')}
                </Typography>
              </Box>

              <Box>
                <Grid container justifyContent='center'>
                  <Button
                    color='secondary'
                    variant='contained'
                    onClick={() => {
                      goToRewardStore()
                      // setPurchaseGiftDialogOpen(true)
                    }}
                    disabled={!+user.wallet?.balance}
                    sx={{ mt: { sm: 3 } }}
                  >
                    {company.name}{' '}{t('myRewards.purchaseGift')}
                  </Button>
                </Grid>
              </Box>
            </Box>
          }
          color={theme.palette.primary.main}
          actionButton={{
            onClick: () => {},
            label: t('myRewards.purchaseGift'),
          }}
        />
      </Grid>

      {/* <Grid item xs={12} md={6}>
        <MyRewardsHeaderCard
          title={t('myRewards.cardHeaders.myWishlist')}
          Icon={WishlistIcon}
          helperIconText={t('myRewards.wishlistDescription')}
          isLoading={wishesAreLoading}
          backgroundImage={wishlistBackgroundWaves}
          LeftContent={
            <Box>
              {getWishlistDetails(
                company?.name,
                wishes.filter(wish => wish.wishable_type === WishType.Company)
                  ?.length ?? 0,
              )}
              {getWishlistDetails(
                'Retailer',
                wishes.filter(wish => wish.wishable_type === WishType.Retailer)
                  ?.length ?? 0,
              )}
            </Box>
          }
          RightContent={
            <Box
              sx={{
                display: { xs: 'flex', sm: 'inherit' },
                justifyContent: { xs: 'space-between', sm: 'center' },
                mt: { xs: 2, sm: 0 },
                alignItems: 'center',
              }}
            >
              <Grid container spacing={1}>
                {wishesPreviewCards.map(card =>
                  card.preview_image ? (
                    <Grid item xs='auto'>
                      <Box
                        component='img'
                        src={card.preview_image}
                        alt='Gift Card preview'
                        sx={{
                          width: 64,
                          aspectRatio: '16/9',
                          borderRadius: '4px',
                        }}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs='auto'>
                      <Box
                        sx={{
                          bgcolor: getUniqueColor(theme, { id: card.id }),
                          width: 64,
                          aspectRatio: '16/9',
                          borderRadius: '4px',
                        }}
                      >
                        <Grid
                          container
                          justifyContent='center'
                          alignItems='center'
                          sx={{ height: 1 }}
                        >
                          <RewardIcon sx={{ color: 'text.white' }} />
                        </Grid>
                      </Box>
                    </Grid>
                  ),
                )}

                {wishes.length > CARDS_PREVIEW_COUNT && (
                  <Grid item xs='auto'>
                    <Box
                      sx={{
                        borderRadius: '100%',
                        height: 36,
                        width: 36,
                        bgcolor: 'secondary.dark',
                      }}
                    >
                      <Grid
                        container
                        justifyContent='center'
                        alignItems='center'
                        sx={{ height: 1 }}
                      >
                        <Typography
                          variant='body2'
                          fontWeight={600}
                          color='text.white'
                        >
                          +{wishes.length - CARDS_PREVIEW_COUNT}
                        </Typography>
                      </Grid>
                    </Box>
                  </Grid>
                )}
              </Grid>

              <Box>
                <Grid container justifyContent='flex-end'>
                  <Button
                    color='secondary'
                    variant='contained'
                    onClick={() => setDialogCatalogOpen(true)}
                    sx={{ mt: { sm: wishesPreviewCards?.length ? 5 : 11 } }}
                  >
                    {t('myRewards.addMore')}
                  </Button>
                </Grid>
              </Box>
            </Box>
          }
          color={theme.palette.error.light}
          actionButton={{
            onClick: () => {},
            label: t('myRewards.addItemsToWishlist'),
          }}
        />
      </Grid> */}
    </Grid>
  )
}

function MyRewardsHeaderCard(props) {
  return (
    <Paper
      sx={{
        p: 3,
        height: 1,
        backgroundImage: `url(${props.backgroundImage})`,
        backgroundSize: 'cover',
        position: 'relative',
      }}
    >
      {props.isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            height: '100%',
            bgcolor: 'rgba(255, 255, 255, 0.5)',
            zIndex: 1001,
            borderRadius: 4,
          }}
        >
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            height={1}
          >
            <CircularProgress size={48} color='primary' />
          </Grid>
        </Box>
      )}

      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        height={1}
      >
        <Grid item xs={12} sm>
          <Grid container spacing={2} alignItems='center' sx={{ mb: 1 }}>
            <Grid item xs='auto'>
              <Box
                sx={{
                  height: 64,
                  width: 64,
                  backgroundColor: props.color,
                  color: '#FFF',
                  borderRadius: '1rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '2rem',
                }}
              >
                <props.Icon
                  sx={{
                    color: '#FFF',
                    fill: '#FFF',
                    fontSize: '2.5rem',
                    width: '2.5rem',
                    height: '2.5rem',
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs>
              <Typography variant='h6' component='h2' fontWeight={600}>
                <Grid container component='span' alignItems='center'>
                  {props.title}{' '}
                  {props.helperIconText && (
                    <Tooltip title={props.helperIconText}>
                      <HelpIcon sx={{ color: 'text.light', ml: 1 }} />
                    </Tooltip>
                  )}
                </Grid>
              </Typography>
            </Grid>
          </Grid>

          {props.LeftContent}
        </Grid>

        <Grid item xs={12} sm='auto'>
          {props.RightContent}
        </Grid>
      </Grid>
    </Paper>
  )
}
