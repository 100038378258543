import { SPARCK_SPOOFED_USER_TOKEN, SPARCK_USER_TOKEN } from 'constants/app'

export const decodeJWT = (token: any = '') => {
  const base64Url = token && token.split('.')[1]

  if (!token || token === 'undefined' || !base64Url) return false

  const base64 = base64Url.replace('-', '+').replace('_', '/')
  const decodedData = JSON.parse(
    Buffer.from(base64, 'base64').toString('binary'),
  )

  return decodedData
}

export const getUserTokenFromLocalStorage = () => {
  return (
    localStorage.getItem(SPARCK_SPOOFED_USER_TOKEN) ??
    localStorage.getItem(SPARCK_USER_TOKEN)
  )
}

export const isTokenExpired = (token: any) =>
  Date.now() >= decodeJWT(token).exp * 1000

export const secondsToExpireToken = token =>
  decodeJWT(token).exp * 1000 - Date.now()
