import { Avatar, Box, Grid, Typography } from '@mui/material'

const avatarSize = {
  width: 80,
  height: 80,
}

export default function CustomerTestimonial({
  testimonial,
  name,
  role,
  company,
  tagline,
  logo,
}: any) {
  return (
    <Grid container>
      <Box p={4}>
        <Grid item xs={12}>
          <Typography
            variant='body1'
            color='text.secondary'
            fontWeight={300}
            align='justify'
          >
            {testimonial}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box my={3}>
            <Grid container justifyContent='space-between'>
              <Grid item xs='auto'>
                <Box mr={3}>
                  <Avatar
                    src={logo}
                    alt='Testimonial logo'
                    style={avatarSize}
                  />
                </Box>
              </Grid>

              <Grid item xs>
                <Typography variant='h6' color='text.primary' fontWeight={700}>
                  {name}
                </Typography>

                <Typography
                  variant='subtitle2'
                  color='text.secondary'
                  fontWeight={300}
                >
                  {role}
                </Typography>

                <Typography
                  variant='subtitle2'
                  color='text.secondary'
                  fontWeight={300}
                >
                  {company}
                </Typography>

                <Typography
                  variant='subtitle2'
                  color='text.secondary'
                  fontWeight={300}
                  fontStyle='italic'
                >
                  {tagline}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Grid>
  )
}
