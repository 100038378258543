import { Box, Tab, Tabs } from '@mui/material'

import { Props } from '../../CompanyAdminLayout.types'

type TabProps = Omit<Props, ''> & {
  showTab?: boolean
  tabList?: any
  tabValue?: any
  onTabChange?: any
}

function a11yCustomProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const CompanyAdminLayoutTab = (props: TabProps) => {
  return props.showTab ? (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      {Array.isArray(props.tabList) && (
        <Tabs
          value={props.tabValue}
          onChange={props.onTabChange}
          aria-label='Rewards system'
        >
          {Array.isArray(props.tabList) &&
            props.tabList.map((tab, index) => (
              <Tab
                key={index}
                data-cy='tabs'
                value={tab.value}
                label={tab.label}
                {...a11yCustomProps(index)}
              />
            ))}
        </Tabs>
      )}
    </Box>
  ) : null
}

export default CompanyAdminLayoutTab
