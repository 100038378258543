import { useState } from 'react'

import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import { SignupComponent } from './components'
import { initialDialog } from 'constants/dialog'
import { useUser } from 'contexts/UserContext'
import { API } from 'core/requests'
import { ROUTES_PATHS } from 'core/routes'

export default function Signup() {
  const { user } = useUser()!
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const intl = useIntl()

  const [dialogConfirmEmail, setDialogConfirmEmail] = useState(initialDialog)

  const createAccountMutation = useMutation(
    (payload: any) => API.create('companies', payload),
    {
      onSettled: (response: any) => {
        if (response && response.id) {
          if (user && user.sparck_administrator) {
            history.push(ROUTES_PATHS.companies)
          } else {
            history.push(ROUTES_PATHS.login)
          }

          enqueueSnackbar(
            `${intl.formatMessage({
              id: 'signup.snackbar.success.company',
            })} ${response.name} ${intl.formatMessage({
              id: 'signup.snackbar.success.createdSuccessfully',
            })}`,
            {
              variant: 'success',
            },
          )
        }
      },
    },
  )

  return (
    <SignupComponent
      dialogConfirmEmail={dialogConfirmEmail}
      setDialogConfirmEmail={setDialogConfirmEmail}
      createAccount={createAccountMutation.mutate}
      isSignupRequestLoading={createAccountMutation.isLoading}
    />
  )
}
