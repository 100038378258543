import { useState } from 'react'

import { Grid, TextField } from '@mui/material'
import { get } from 'lodash'
import { useIntl } from 'react-intl'
import * as yup from 'yup'

import { CRUDTable, FormHandler } from 'components'
import { API } from 'core/requests'
import { DemographicType as DemographicTypeEnum } from 'enums/demographic'
import { t } from 'i18n'
import { DemographicType } from 'types'

const validationSchema = yup.object({
  name: yup.string().required(t('nameIsRequired')),
})

export default function ManageDemographic({
  dialog,
  closeDialog,
  fetchRows,
}: any) {
  const intl = useIntl()

  const initialValues: DemographicType = dialog.data
    ? dialog.data
    : {
        name: '',
        type: DemographicTypeEnum.Text,
        demographics_attributes: [],
      }

  const [demographicOptions, setDemographicOptions] = useState(
    initialValues.demographics_attributes,
  )

  return (
    <FormHandler
      title={intl.formatMessage({ id: 'demographics.title' })}
      requestUrl='demographics'
      customMutationRequest={(payload: any) => {
        return API.createOrUpdate('demographics', {
          ...payload,
          demographics_attributes: demographicOptions,
        })
      }}
      // detailsRequestCallback={response => {
      //   setSelectedPermissions([
      //     ...response.permissions.map(permission => ({
      //       id: permission.id,
      //       permission_id: get(permission, 'permission.id'),
      //     })),
      //   ])
      // }}
      detailsRequestId={get(dialog, 'data.id')}
      data={dialog.data}
      closeDialog={closeDialog}
      fetchRows={fetchRows}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ formik }) => (
        <ManageDemographicForm
          formik={formik}
          demographicOptions={demographicOptions}
          setDemographicOptions={setDemographicOptions}
        />
      )}
    </FormHandler>
  )
}

function ManageDemographicForm({
  formik,
  demographicOptions,
  setDemographicOptions,
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} data-cy='demographicName'>
        <TextField
          id='name'
          name='name'
          label={t('name')}
          variant='outlined'
          value={formik.values.name}
          required
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} data-cy='demographicDescription'>
        <CRUDTable
          data-cy='demographicDescription'
          rows={demographicOptions}
          setRows={setDemographicOptions}
          limit={200}
          columns={[
            {
              id: 'name',
              label: t('name'),
              type: 'text',
              align: 'left',
              required: true,
              disabled: () => false,
              showEdit: () => true,
              defaultValue: '',
            },
          ]}
          showAdd
          showEdit={() => true}
          showRemove={() => true}
        />
      </Grid>
    </Grid>
  )
}
