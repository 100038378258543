import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import { BlankLayout } from 'layouts'

export default function UnderMaintenance() {
  return (
    <BlankLayout
      id='underMaintenance'
      fullWidth
      title='Under Maintenance'
      layoutProps={{ backgroundColor: null }}
    >
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        sx={{ height: '100%' }}
      >
        <Grid item xs={11} sm={8} md={6} lg={4} xl={3}>
          <Box my={3} width={1}>
            <Card>
              <CardContent>
                <Box p={2} py={3}>
                  <Grid container justifyContent='center'>
                    <Grid item xs={12}>
                      <Typography
                        component='h1'
                        variant='h5'
                        align='center'
                        fontWeight={600}
                        sx={{ mb: 0.5 }}
                      >
                        We're currently under maintenance.
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography component='h2' variant='body1' align='center'>
                        Sorry for the inconvenience. We'll be back shortly!
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </BlankLayout>
  )
}
