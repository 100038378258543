import { Box, Grid, Typography } from '@mui/material'

import sparckWave from 'assets/images/waves/wave-01.png'
import { TOPBAR_HEIGHT } from 'constants/app'
import { t } from 'i18n'

export default function SparckMemoriesHeader() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <img
          src={sparckWave}
          alt='Sparck wave header'
          style={{
            width: '100%',
            position: 'absolute',
            top: TOPBAR_HEIGHT,
            objectFit: 'fill',
            height: 280,
          }}
        />
      </Grid>

      <Grid item xs={12} style={{ zIndex: 999 }}>
        <Box mt={5}>
          <Typography variant='h5' color='text.secondary' align='center'>
            {t('sparcks.sparckMemories.header.firstLine')}
          </Typography>

          <Box width={1} mt={1}>
            <Typography
              variant='h2'
              color='text.primary'
              fontWeight={700}
              align='center'
              paragraph
            >
              {t('sparcks.sparckMemories.header.secondLine')}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}
