import {
  // Divider,
  // FormControl,
  // FormControlLabel,
  Grid,
  // Radio,
  // RadioGroup,
  // Typography,
} from '@mui/material'

import {
  ManageBudgetDistributionDemographics,
  ManageBudgetDistributionFlat,
  // ManageBudgetProrate,
  // ManageBudgetRollover,
} from '..'
// import { budgetDistributionTypes } from 'constants/budget'
import { BudgetDistributionType } from 'enums/budget'
// import { t } from 'i18n'

export default function ManageBudgetReceivingForm(props) {
  const { formik } = props

  const getSelectedDistributionForm = {
    [BudgetDistributionType.Flat]: ManageBudgetDistributionFlat,
    [BudgetDistributionType.Demographics]: ManageBudgetDistributionDemographics,
  }

  const SelectedDistributionForm =
    formik.values.receiving?.distribution &&
    getSelectedDistributionForm[formik.values.receiving?.distribution]

  // const handleDistributionChange = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  // ) => {
  //   formik.setFieldValue('receiving', {
  //     ...formik.values.receiving,
  //     distribution: (event.target as HTMLInputElement).value,
  //     amount:
  //       formik.values.receiving.distribution === ManageBudgetDistributionFlat
  //         ? 0
  //         : [],
  //   })
  // }

  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={12}>
        <Typography
          variant='body1'
          color='text.secondary'
          fontWeight={500}
          sx={{ textTransform: 'uppercase' }}
        >
          {t('budget.manageBudgetReceivingForm.title')}
        </Typography>
      </Grid> */}
      {/* <Grid item xs={12}>
        <FormControl>
          <RadioGroup
            aria-labelledby='distribution-label'
            name='distribution'
            onChange={handleDistributionChange}
            value={formik.values.receiving?.distribution ?? null}
          >
            {budgetDistributionTypes
              .filter(type => type.descriptionReceiving)
              .map(budgetDistribution => (
                <FormControlLabel
                  data-cy='receivingOption'
                  key={budgetDistribution.value}
                  value={budgetDistribution.value}
                  control={<Radio />}
                  label={budgetDistribution.descriptionReceiving}
                  sx={{ mb: 1.5 }}
                />
              ))}
          </RadioGroup>
        </FormControl>
      </Grid> */}

      {SelectedDistributionForm && (
        <Grid item xs={12}>
          <SelectedDistributionForm {...props} type='receiving' />
        </Grid>
      )}

      {/* <Grid item xs={12}>
        <ManageBudgetRollover {...props} type='receiving' />
      </Grid> */}

      {/* <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <ManageBudgetProrate {...props} type='receiving' />
      </Grid> */}
    </Grid>
  )
}
