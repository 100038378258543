import { API_ROUTES } from 'core/api'
import { API_BASE_URL } from 'core/environments'
import { getUserTokenFromLocalStorage } from 'utils'
import { APIRequest, RequestOptionsType } from 'utils/requests'
import { getSurveyReportQueryParams } from '../pages/beheardReport/BeheardReport'
export type APIOptions = {
  queryParams?: any
  pathParams?: any
  body?: any
}

export const API = {
  base: (
    url: keyof typeof API_ROUTES,
    options: Omit<RequestOptionsType, 'path'>,
  ) =>
    APIRequest({
      path: API_ROUTES[url],
      ...(options ?? {}),
    }),
  get: (
    url: keyof typeof API_ROUTES,
    id?: string | number | null,
    options?: APIOptions,
  ) =>
    APIRequest({
      path: API_ROUTES[url],
      method: 'GET',
      pathParams: {
        ...(id && { id: id }),
        ...(options ?? {}).pathParams,
      },
      ...options,
    }),
  create: (
    url: keyof typeof API_ROUTES,
    payload?: Record<any, unknown> | any,
    options?: APIOptions,
  ) =>
    APIRequest({
      path: API_ROUTES[url],
      method: 'POST',
      body: payload,
      pathParams: {
        ...((payload ?? {}).id && { id: payload.id }),
      },
      ...options,
    }),
  update: (
    url: keyof typeof API_ROUTES,
    payload?: Record<any, unknown> | any,
    options?: APIOptions,
  ) =>
    APIRequest({
      path: API_ROUTES[url],
      method: 'PATCH',
      body: payload,
      pathParams: {
        ...((payload ?? {}).id && { id: payload.id }),
      },
      ...options,
    }),
  createOrUpdate: (
    url: keyof typeof API_ROUTES,
    payload?: Record<any, unknown> | any,
    payloadId?: string,
    options?: APIOptions,
  ) => {
    const id = payloadId ?? payload.id

    return APIRequest({
      path: API_ROUTES[url],
      method: id ? 'PATCH' : 'POST',
      body: payload,
      pathParams: {
        ...(id && { id: id }),
      },
      ...options,
    })
  },
  delete: (
    url: keyof typeof API_ROUTES,
    id: string | null,
    options?: APIOptions,
  ) =>
    APIRequest({
      path: API_ROUTES[url],
      method: 'DELETE',
      pathParams: (options ?? {}).pathParams ?? {
        id: id,
      },
      ...(options ?? {}),
    }),
}

export const downloadOpenEndedAnswersRequest = (
  survey_id,
  shared_report_id,
  open_ended_download_format,
  filterState
) => {
  const request = new XMLHttpRequest()
  request.open(
    'POST',
    `${API_BASE_URL}${API_ROUTES.downloadOpenEndedAnswers
    }`,
    true,
  )
  // @ts-ignore
  request.setRequestHeader('Authorization', getUserTokenFromLocalStorage())
  request.responseType = 'blob'
  request.onload = function () {
    if (this.status === 200) {
      const file = window.URL.createObjectURL(this.response)
      const a = document.createElement('a')
      a.href = file
      a.download = this.response.name || 'Survey-OpenEnded-Answers'
      document.body.appendChild(a)
      a.click()

      document.body.removeChild(a)
    }
  }

  const requestData: any = {
    survey_id: survey_id,
    type: open_ended_download_format,
  }
  if (filterState?.criteria.length) {
    requestData.filters = filterState
  }

  if (shared_report_id) {
    requestData.shared_report_id = shared_report_id
  }
  request.setRequestHeader('Content-Type', 'application/json')

  request.send(JSON.stringify(requestData))
}

export const downloadPremiumReportRequest = ({
  surveyId,
  sharedReportId,
  type,
  filters,
  setIsPdfReportLoading,
  openInNewTab,
}) => {
  const request = new XMLHttpRequest()
  request.open(
    'POST',
    `${API_BASE_URL}${API_ROUTES.pdfPremiumReport}.${type}`,
    true,
  )
  // @ts-ignore
  request.setRequestHeader('Authorization', getUserTokenFromLocalStorage())
  request.responseType = 'blob'
  setIsPdfReportLoading(true)

  request.onload = function () {
    setIsPdfReportLoading(false)
    if (this.status === 200) {
      const file = window.URL.createObjectURL(this.response)
      const a = document.createElement('a')
      a.href = file

      if (openInNewTab) {
        window.open(file, '_blank')
      } else {
        a.download = `beheard_report_${+new Date()}`

        a.rel = 'noopener noreferrer'
        a.target = '_blank'

        document.body.appendChild(a)
        a.click()

        document.body.removeChild(a)
      }
    }
  }
  const requestData = {
    survey_id: surveyId,
    shared_report_id: sharedReportId,
    filters: getSurveyReportQueryParams(filters),
    type: type,
  }

  request.setRequestHeader('Content-Type', 'application/json')
  request.send(JSON.stringify(requestData))
}

export const recognitionActionRequestfile = (id, reminderDate) => {
  const request = new XMLHttpRequest()
  request.open('PATCH', `${API_BASE_URL}recognition/actions/${id}`, true)
  // @ts-ignore
  request.setRequestHeader('Authorization', getUserTokenFromLocalStorage())
  request.setRequestHeader('Content-Type', 'application/json')

  request.responseType = 'text'

  request.onreadystatechange = function () {
    if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
      const blob = new Blob([this.response], {
        type: 'text/calendar;charset=utf-8',
      })
      // window.navigator.msSaveOrOpenBlob(blob, 'reminder.ics')

      const file = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = file
      a.download = this.response.name || 'Employee-Recognition-Reminder'
      document.body.appendChild(a)
      a.click()

      document.body.removeChild(a)
    }
  }

  request.send(`{ "reminder_date": "${reminderDate.toISOString()}" }`)
}


export const downloadExternalRewardsTemplate = () => {
  const request = new XMLHttpRequest()

  request.open(
    'GET',
    `${API_BASE_URL}${API_ROUTES.downloadExternalRewardsTemplate}`,
    true,
  )
  // @ts-ignore
  request.setRequestHeader('Authorization', getUserTokenFromLocalStorage())
  request.responseType = 'blob'
  request.onload = function () {
    if (this.status === 200) {
      const file = window.URL.createObjectURL(this.response)
      const a = document.createElement('a')
      a.href = file
      a.download = this.response.name || 'Sparck External Rewards Template'
      document.body.appendChild(a)
      a.click()

      document.body.removeChild(a)
    }
  }
  request.send()
}

export const downloadRewardsReport = () => {
  const request = new XMLHttpRequest()

  request.open(
    'GET',
    `${API_BASE_URL}${API_ROUTES.downloadRewardsReport}`,
    true,
  )
  // @ts-ignore
  request.setRequestHeader('Authorization', getUserTokenFromLocalStorage())
  request.responseType = 'blob'
  request.onload = function () {
    if (this.status === 200) {
      const file = window.URL.createObjectURL(this.response)
      const a = document.createElement('a')
      a.href = file
      a.download = this.response.name || 'Sparck Rewards Report'
      document.body.appendChild(a)
      a.click()

      document.body.removeChild(a)
    }
  }
  request.send()
}

export const downloadBudgetActivity = () => {
  const request = new XMLHttpRequest()

  request.open(
    'GET',
    `${API_BASE_URL}${API_ROUTES.downloadBudgetActivity}`,
    true,
  )
  // @ts-ignore
  request.setRequestHeader('Authorization', getUserTokenFromLocalStorage())
  request.responseType = 'blob'
  request.onload = function () {
    if (this.status === 200) {
      const file = window.URL.createObjectURL(this.response)
      const a = document.createElement('a')
      a.href = file
      a.download = this.response.name || 'Sparck Budget Activity'
      document.body.appendChild(a)
      a.click()

      document.body.removeChild(a)
    }
  }
  request.send()
}