import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { Link } from 'react-router-dom'
// import { useSnackbar } from 'notistack'

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  useTheme,
} from '@mui/material'
import {
  Edit as EditIcon,
} from '@mui/icons-material'

// import PrintIcon from '@mui/icons-material/Print';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import SystemUpdateAltTwoToneIcon from '@mui/icons-material/SystemUpdateAltTwoTone';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import StarsRoundedIcon from '@mui/icons-material/StarsRounded';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import OutboxTwoToneIcon from '@mui/icons-material/OutboxTwoTone';
import {
  ConfirmationDialog,
  DialogTeleport,
  SurveyOpenAndCloseDateDialog,
  SurveyPreviewDialog,
} from 'components'
import { ManageSurvey, Surveys } from 'pages'
import { SurveyStatus } from 'enums/survey'
import { ROUTES_PATHS } from 'core/routes'
import { useUser } from 'contexts/UserContext'
import { initialDialog } from 'constants/dialog'
import { t } from 'i18n'

export default function SurveyOverviewActions({
  surveys,
  selectedSurvey,
  confirmDeleteDialogOpen,
  setConfirmDeleteDialogOpen,
  setConfirmCloseDialogOpen,
  setConfirmLaunchSurveyDialogOpen,
  setExtendSurveyDialogOpen,
  setInviteEmployeeDialogOpen,
  getSurveyOverview,
  fetchSurveys,
  addSurveyToCart,
  addSurveyToCartIsLoading,
  toggleSurveyReportLevelMutation,
  uninvitedUsers,
  launchSurveyMutation,
  deleteSurveyMutation,
  preview,
  getSurveyPreview,
  hide = false
}: any) {
  const theme = useTheme()
  const { user } = useUser()!

  // const { enqueueSnackbar } = useSnackbar()
  const [dialogManageSurvey, setDialogManageSurvey] = useState(initialDialog)
  const [closeDateDialogOpen, setCloseDateDialogOpen] = useState(false)
  const [confirmLaunchSurveyDialog, setConfirmLaunchSurveyDialog] = useState(false);
  const [surveyCloseDate, setSurveyCloseDate] = useState(
    selectedSurvey?.close_at,
  )
  const [previewDialog, setPreviewDialog] = useState(initialDialog)

  const companyHasActiveSurvey = surveys.find(
    survey => survey.status !== SurveyStatus.Close,
  )

  useEffect(() => {
    getSurveyPreview()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <>
      {!hide ? <Grid container justifyContent='flex-end'>
        <Grid item xs={12}>
          {!companyHasActiveSurvey && (
            <>
              <Box mb={1} data-cy='clone_survey'>
                <Button
                  variant='outlined'
                  color='primary'
                  fullWidth
                  onClick={() =>
                    setDialogManageSurvey({
                      isOpen: true,
                      data: { ...selectedSurvey, isClone: true },
                    })
                  }
                >
                  Clone Survey
                </Button>
              </Box>
            </>
          )}

          {(selectedSurvey.status === SurveyStatus.Scheduled ||      //{  2nd condition}
            selectedSurvey.status === SurveyStatus.AwaitingManualOpening) && (
              <>
                <Grid item xs='auto' justifyContent={'flex-end'}>
                  <Box mb={1} data-cy='launch_survey'>
                    <Button
                      variant='contained'
                      color='primary'
                      fullWidth
                      onClick={() => setCloseDateDialogOpen(true)}
                    >
                      {t('surveyOverview.launchSurvey')}
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs='auto' justifyContent={'flex-end'}>
                  <Box mb={1} data-cy='preview_survey'>
                    <Button
                      variant='contained'
                      color='primary'
                      fullWidth
                      onClick={async () => {
                        await getSurveyPreview()

                        setPreviewDialog({
                          isOpen: true,
                          data: {
                            ...preview.survey,
                            answers: [],
                          },
                        })
                      }}
                    >
                      Preview Survey
                    </Button>
                  </Box>
                </Grid>

                <Box mb={1} data-cy='editSurvey'>
                  <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    onClick={() =>
                      setDialogManageSurvey({
                        isOpen: true,
                        data: selectedSurvey,
                      })
                    }
                  >
                    {t('surveyOverview.editSurvey')}
                  </Button>
                </Box>
              </>
            )}

          {selectedSurvey.status === SurveyStatus.Open && (
            <>
              <Box mb={1}>
                <Button
                  variant='outlined'
                  color='primary'
                  fullWidth
                  onClick={() => setExtendSurveyDialogOpen(true)}
                >
                  {t('surveyOverview.extendSurvey')}
                </Button>
              </Box>
              <Box mb={1}>
                <Button
                  data-cy='close_survey'
                  variant='outlined'
                  color='error'
                  fullWidth
                  onClick={() => setConfirmCloseDialogOpen(true)}
                >
                  {t('surveyOverview.closeSurvey')}
                </Button>
              </Box>
            </>
          )}

          {selectedSurvey.status === SurveyStatus.Open && (
            <>
              <Box mb={1}>
                <Button
                  variant='outlined'
                  color='primary'
                  fullWidth
                  onClick={() => setExtendSurveyDialogOpen(true)}
                >
                  {t('surveyOverview.extendSurvey')}
                </Button>
              </Box>
              <Box mb={1}>
                <Button
                  data-cy='close_survey'
                  variant='outlined'
                  color='error'
                  fullWidth
                  onClick={() => setConfirmCloseDialogOpen(true)}
                >
                  {t('surveyOverview.closeSurvey')}
                </Button>
              </Box>
            </>
          )}

          {selectedSurvey.status === SurveyStatus.Close &&                    //3rd condition
            selectedSurvey.report_level === 'free' && (
              <>
                {user.sparck_administrator && (
                  <Box mb={1}>
                    <Button
                      variant='outlined'
                      fullWidth
                      color='primary'
                      onClick={() =>
                        toggleSurveyReportLevelMutation.mutate({
                          id: selectedSurvey.id,
                        })
                      }
                    >
                      {t('surveyOverview.applyUpgrade')}
                    </Button>
                  </Box>
                )}

                <Box mb={1}>
                  <Button
                    variant='contained'
                    fullWidth
                    onClick={addSurveyToCart}
                    disabled={addSurveyToCartIsLoading}
                  >
                    {addSurveyToCartIsLoading ? (
                      <CircularProgress size={24} color='secondary' />
                    ) : (
                      t('report.upgradeReport')
                    )}
                  </Button>
                </Box>
              </>
            )}

          {selectedSurvey.status === SurveyStatus.Close &&                 //4th condition
            selectedSurvey.report_level === 'free' && (
              <Box mb={1}>
                <Link
                  to={`${ROUTES_PATHS.beheardReport}?id=${selectedSurvey.id}&full_report=0`}
                >
                  <Button
                    variant='contained'
                    sx={{
                      background: `linear-gradient(135deg, ${theme.palette.secondary.main}, ${theme.palette.error.light})`,
                    }}
                    fullWidth
                    onClick={() => { }}
                  >
                    {t('report.freeReport')}
                  </Button>
                </Link>
              </Box>
            )}

          {selectedSurvey.status === SurveyStatus.Close &&         //5th condition
            selectedSurvey.report_level === 'upgraded' && (
              <>
                {user.sparck_administrator && (
                  <Box mb={1}>
                    <Button
                      variant='outlined'
                      color='primary'
                      fullWidth
                      onClick={() =>
                        toggleSurveyReportLevelMutation.mutate({
                          id: selectedSurvey.id,
                        })
                      }
                    >
                      {t('surveyOverview.applyDowngrade')}
                    </Button>
                  </Box>
                )}
                <Box mb={1}>
                  <Link
                    to={`${ROUTES_PATHS.beheardReport}?id=${selectedSurvey.id}&full_report=1`}
                  >
                    <Button
                      variant='contained'
                      sx={{
                        background: `linear-gradient(135deg, ${theme.palette.secondary.main}, ${theme.palette.error.light})`,
                      }}
                      fullWidth
                      onClick={() => { }}
                    >
                      {t('report.premiumReport')}
                    </Button>
                  </Link>
                </Box>
              </>
            )}

          {!isEmpty(uninvitedUsers) &&
            selectedSurvey.status !== SurveyStatus.Close && (
              <Box mb={1} data-cy='inviteEmployees'>
                <Button
                  variant='outlined'
                  color='primary'
                  fullWidth
                  onClick={() =>
                    setInviteEmployeeDialogOpen({ isOpen: true, data: null })
                  }
                >
                  {t('surveyOverview.inviteEmployees')}
                </Button>
              </Box>
            )}

          {selectedSurvey.status !== SurveyStatus.Open &&            // 6th condition
            selectedSurvey.report_level !== 'upgraded' && (
              <Box mb={1} data-cy='deleteSurvey'>
                <Button
                  variant='outlined'
                  color='error'
                  fullWidth
                  onClick={() => setConfirmDeleteDialogOpen(true)}
                >
                  {t('surveyOverview.deleteSurvey')}
                </Button>
              </Box>
            )}
        </Grid>
      </Grid> :
        <Grid container spacing={2} justifyContent='flex-end'>
          {/* share Button */}
          {/* {selectedSurvey.status === SurveyStatus.Close &&
            <Grid item xs='auto' justifyContent={'flex-end'}>
              <Box mb={2} data-cy='clone_survey'>
                <Button
                  variant='outlined'
                  onClick={() => {
                    `Survey Shared Link has been copied to your clipboard`,
                    navigator.clipboard.writeText(`${window.location.hostname}${ROUTES_PATHS.beheardReport}?id=${selectedSurvey.id}&full_report=1`); enqueueSnackbar(
                      { variant: 'success' },
                    )
                  }}
                >
                  <ShareIcon />  &nbsp;
                  Share
                </Button>
              </Box>
            </Grid>
          } */}
          {selectedSurvey.status === SurveyStatus.Close &&
            selectedSurvey.report_level === 'free' && (
              <Grid item xs='auto'>
                <Box mb={2}>
                  <Link
                    to={`${ROUTES_PATHS.beheardReport}?id=${selectedSurvey.id}&full_report=0`}
                  >
                    <Button
                      variant='outlined'
                      color='primary'
                      fullWidth
                      onClick={() => { }}
                    >
                      <BookmarksIcon />  &nbsp;
                      {t('report.freeReport')}
                    </Button>
                  </Link>
                </Box>
              </Grid>
            )}
          {(selectedSurvey.status === SurveyStatus.Scheduled ||
            selectedSurvey.status === SurveyStatus.AwaitingManualOpening) && (
              <Grid item xs='auto'>
                <Box data-cy='launch_survey'>
                  <Button
                    style={{ fontSize: '12px' }}
                    variant='outlined'
                    color='primary'
                    onClick={() => setCloseDateDialogOpen(true)}
                  >
                    <OutboxTwoToneIcon />  &nbsp;
                    {t('surveyOverview.launchSurvey')}
                  </Button>
                </Box>
              </Grid>
            )}


          {/* Print Button */}
          {/* {(selectedSurvey.status === SurveyStatus.Scheduled ||
            selectedSurvey.status === SurveyStatus.Close) && (
              <Grid item xs='auto'>
                <Box mb={2}>
                  <Link
                    to={`${ROUTES_PATHS.beheardReport}?id=${selectedSurvey.id}&full_report=${selectedSurvey.report_level === 'upgraded' ? 1 : 0}`}
                  >
                    <Button
                      variant='outlined'>
                      <PrintIcon />  &nbsp;
                      {t("surveyOverview.printSurvey")}
                    </Button>
                  </Link>
                </Box>
              </Grid>
            )} */}

          {/* Close button */}
          {selectedSurvey.status === SurveyStatus.Open && (
            <>
              <Grid item xs='auto' justifyContent={'flex-end'}>
                <Box data-cy='close_survey'>
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={() =>
                      setConfirmCloseDialogOpen({
                        isOpen: true,
                        data: { ...selectedSurvey, isClone: true },
                      })
                    }
                  >
                    <CancelOutlinedIcon />  &nbsp;
                    {t('surveyOverview.closeSurvey')}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs='auto' justifyContent={'flex-end'}>
                <Box data-cy='close_survey'>
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={() =>
                      setExtendSurveyDialogOpen({
                        isOpen: true,
                        data: { ...selectedSurvey, isClone: true },
                      })
                    }
                  >
                    <EventOutlinedIcon />  &nbsp;
                    {t('surveyOverview.extendSurvey')}
                  </Button>
                </Box>
              </Grid>
            </>
          )}

          {/* Clone Button */}
          {!companyHasActiveSurvey && (
            <Grid item xs='auto' justifyContent={'flex-end'}>
              <Box mb={2} data-cy='clone_survey'>
                <Button
                  variant='outlined'
                  onClick={() =>
                    setDialogManageSurvey({
                      isOpen: true,
                      data: { ...selectedSurvey, isClone: true },
                    })
                  }>
                  <FileCopyOutlinedIcon />  &nbsp;
                  Clone
                </Button>
              </Box>
            </Grid>)}

          {/* Apply Upgrade Button */}
          {selectedSurvey.status === SurveyStatus.Close &&
            selectedSurvey.report_level === 'free' && (
              <>

                {/* Upgrade Report Button */}
                <Grid item xs='auto' >
                  <Box mb={2}>
                    <Button
                      variant='contained'
                      color='secondary'
                      onClick={addSurveyToCart}
                      disabled={addSurveyToCartIsLoading}
                    >
                      {addSurveyToCartIsLoading ? (
                        <CircularProgress size={24} color='secondary' />
                      ) : (
                        <ReceiptTwoToneIcon />
                      )}  &nbsp;
                      {t('report.upgradeReport')}
                    </Button>
                  </Box></Grid>
              </>
            )}

          {/* Delete Button */}
          {selectedSurvey.status !== SurveyStatus.Open &&
            selectedSurvey.report_level !== 'upgraded' && (
              <Grid item xs='auto'>
                <Box mb={2} data-cy='deleteSurvey'>
                  <Button
                    variant='outlined'
                    onClick={() => setConfirmDeleteDialogOpen(true)}
                  >
                    <DeleteOutlineTwoToneIcon />  &nbsp;
                    {t('surveyOverview.deleteSurvey')}
                  </Button>
                </Box>
              </Grid>
            )}

          {/* Launch Survey Button */}
          {(selectedSurvey.status === SurveyStatus.Scheduled ||
            selectedSurvey.status === SurveyStatus.AwaitingManualOpening) && (
              <>
                {/* Preview Survey Button */}
                <Grid item xs='auto'>
                  <Box mb={2} data-cy='preview_survey'>
                    <Button
                      variant='outlined'
                      onClick={async () => {
                        await getSurveyPreview()

                        setPreviewDialog({
                          isOpen: true,
                          data: {
                            ...preview.survey,
                            answers: [],
                          },
                        })
                      }}>

                      <ManageSearchIcon />  &nbsp;
                      Preview Survey
                    </Button>
                  </Box>
                </Grid>

                {/* Edit Survey Button */}
                <Grid item xs='auto'>
                  <Box mb={2} data-cy='editSurvey'>
                    <Button
                      variant='outlined'
                      onClick={() =>
                        setDialogManageSurvey({
                          isOpen: true,
                          data: selectedSurvey,
                        })
                      }
                      color='primary'
                    >
                      <EditIcon />  &nbsp;
                      {t('surveyOverview.editSurvey')}
                    </Button>
                  </Box>
                </Grid>
              </>
            )}

          {/* Apply DownGrade Button */}
          {selectedSurvey.status === SurveyStatus.Close &&
            selectedSurvey.report_level === 'upgraded' && (
              <>
                {user.sparck_administrator && (
                  <Grid item xs='auto' justifyContent={'flex-beetween'}>
                    <Box mb={2}>
                      <Button
                        variant='outlined'
                        onClick={() =>
                          toggleSurveyReportLevelMutation.mutate({
                            id: selectedSurvey.id,
                          })
                        }>
                        <SystemUpdateAltTwoToneIcon />  &nbsp;
                        {t('surveyOverview.applyDowngrade')}
                      </Button>
                    </Box></Grid>
                )}

                {/* Premium Report Button */}
                <Grid item xs='auto' >
                  <Box mb={2}>
                    <Link
                      to={`${ROUTES_PATHS.beheardReport}?id=${selectedSurvey.id}&full_report=1`}
                    >
                      <Button
                        variant='outlined'
                      >
                        <StarsRoundedIcon />  &nbsp;
                        {t('report.premiumReport')}
                      </Button>
                    </Link>
                  </Box>
                </Grid>
              </>
            )}
        </Grid >
      }

      {
        dialogManageSurvey.isOpen &&
        <DialogTeleport
          dialogTitle=''
          dialogAction={null}
          dialogSize='md'
          dialogOpen={!!dialogManageSurvey.isOpen}
          handleDialogClose={() =>
            setDialogManageSurvey({ isOpen: false, data: null })

          }
          isFullWidth
        >
          <ManageSurvey
            dialog={dialogManageSurvey}
            closeDialog={() => setDialogManageSurvey(initialDialog)}
            fetchSurveyOverview={getSurveyOverview}
            fetchSurveys={fetchSurveys}
          />
        </DialogTeleport>
      }

      <SurveyOpenAndCloseDateDialog
        title='When do you want your survey to close?'
        isDialogOpen={closeDateDialogOpen}
        setIsDialogOpen={setCloseDateDialogOpen}
        survey={selectedSurvey}
        action={date => {
          setSurveyCloseDate(date)
          setCloseDateDialogOpen(false)
          setConfirmLaunchSurveyDialog(true)
          setConfirmLaunchSurveyDialogOpen(true)
        }}
        showCloseDate
      />

      <ConfirmationDialog
        dialogOpen={confirmLaunchSurveyDialog}
        setDialogOpen={setConfirmLaunchSurveyDialog}
        message={t('components.confirmationDialog.launchBeheardSurveyMessage', {
          link: <Link to={ROUTES_PATHS.employees}>{t('linkHere')}</Link>,
        })}
        item={selectedSurvey}
        actionProps={{
          onClick: () => {
            launchSurveyMutation.mutate({
              ...selectedSurvey,
              close_at: surveyCloseDate,
            })
            setConfirmLaunchSurveyDialog(false)
          },
          isLoading: launchSurveyMutation.isLoading,
          label: t('surveyOverview.launchSurvey'),
        }}
      />

      <ConfirmationDialog
        dialogOpen={confirmDeleteDialogOpen}
        setDialogOpen={setConfirmDeleteDialogOpen}
        message={t('components.confirmationDialog.defaultMessage', {
          action: t('components.confirmationDialog.delete'),
          item: selectedSurvey?.name,
        })}
        item={selectedSurvey}
        actionProps={{
          onClick: () => deleteSurveyMutation.mutate(selectedSurvey),
          isLoading: deleteSurveyMutation.isLoading,
          label: t('surveyDialogs.confirmDelete.actionLabel'),
          color: 'error',
        }}
      />

      <SurveyPreviewDialog
        size='lg'
        previewDialog={previewDialog}
        setPreviewDialog={setPreviewDialog}
      >
        <Surveys preview={previewDialog?.data} />
      </SurveyPreviewDialog>
    </>
  )
}
