import { useEffect } from 'react'

import { useFormik } from 'formik'
import { Button, Grid, TextField, Typography } from '@mui/material'
import * as yup from 'yup'

import { MFAFactorSetupProps } from '../MFAFactorSetup/MFAFactorSetup'
import { MFAFactorType } from 'enums/multiFactorAuthentication'
import { t } from 'i18n'
import { MFA_FACTOR_LABEL } from 'utils/multiFactorAuthentication'

const validationSchema = yup.object({
  verification_code: yup
    .string()
    .required(t('mfa.validations.verificationCodeRequired')),
})

const initialValues = {
  verification_code: '',
}

type Props = MFAFactorSetupProps & {
  activeStep: any
  userClickedNextPage: boolean
  setUserClickedNextPage(value: boolean): void
}

export default function MFAEnterVerificationCode(props: Props) {
  const {
    userClickedNextPage,
    setUserClickedNextPage,
    activeStep,
    selectedFactor,
    resendVerificationCodeMutation,
  } = props

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {},
    validationSchema: validationSchema,
  })

  const handleResendVerificationCode = () => {
    resendVerificationCodeMutation.mutate({
      factor_type: selectedFactor?.id,
    })
  }

  useEffect(() => {
    if (userClickedNextPage) {
      activeStep?.onClickNextPage(formik.values.verification_code)
      setUserClickedNextPage(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userClickedNextPage])

  return (
    <Grid container justifyContent='center'>
      <Grid item xs={12}>
        <Typography align='center' sx={{ my: 2 }} fontWeight={500}>
          {t(`mfa.enter${MFA_FACTOR_LABEL[selectedFactor?.id]}Code`)}
        </Typography>
      </Grid>
      <Grid
        container
        component='form'
        onSubmit={formik.handleSubmit}
        justifyContent='center'
      >
        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          <TextField
            id='verification_code'
            name='verification_code'
            type='text'
            label={t('mfa.labels.enterYourCode')}
            variant='outlined'
            data-cy='verification_code'
            value={formik.values.verification_code}
            required={!!formik.errors.verification_code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.verification_code &&
              Boolean(formik.errors.verification_code)
            }
            helperText={
              formik.touched.verification_code &&
              formik.errors.verification_code
            }
            fullWidth
          />
        </Grid>
      </Grid>

      {selectedFactor?.id === MFAFactorType.SMS && (
        <Grid item xs={12} sx={{ my: 2, textAlign: 'center' }}>
          <Button
            variant='text'
            color='primary'
            onClick={handleResendVerificationCode}
          >
            {t('mfa.resendCode')}
          </Button>
        </Grid>
      )}
    </Grid>
  )
}
