import { Grid } from '@mui/material'

import { NominationsManagementNominationEvent } from '../../components'

export default function NominationsManagementHistory({ previousNominations }) {
  return (
    <Grid container>
      {previousNominations.map(nomination => (
        <Grid key={nomination.id} item xs={12} sx={{ mb: 2 }}>
          <NominationsManagementNominationEvent nomination={nomination} />
        </Grid>
      ))}
    </Grid>
  )
}
