import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'

import { t } from 'i18n'
import { UseMutationResult } from 'react-query'

export type HRISImportComponentProps = {
  hrisSystem: any
  setHrisSystem(arg0: any): any
  importHRISEmployees: any
  setImportHRISEmployees(arg0: any): any
  hrisImportMutation: UseMutationResult
  hrisSuggestionMutation: UseMutationResult
  hrisSystems: any[]
}

export default function HRISImportComponent(props: HRISImportComponentProps) {
  const {
    hrisSystem,
    setHrisSystem,
    importHRISEmployees,
    setImportHRISEmployees,
    hrisSuggestionMutation,
    hrisImportMutation,
    hrisSystems,
  } = props

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={3}>
          <Typography variant='h5' color='text.primary' paragraph>
            {t('hrisImport.importEmployeesFromHRIS')}
          </Typography>

          <Typography variant='body2' color='text.secondary' paragraph>
            {t('hrisImport.selectHRIS')}
          </Typography>
        </Box>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={9}>
          <TextField
            select
            id='hris'
            label={t('hrisImport.hrisSystem')}
            placeholder='e.g. Optimum HRIS'
            variant='outlined'
            data-cy='selectHrisSystem'
            value={importHRISEmployees.hris}
            required
            onChange={e =>
              setImportHRISEmployees({
                ...importHRISEmployees,
                hris: e.target.value,
              })
            }
            fullWidth
          >
            {hrisSystems.map(system => (
              <MenuItem key={system.id} value={system.id} data-cy='hrisOption'>
                {t(`${system.label}`)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            id='hris_id'
            label={t('hrisImport.companyId')}
            variant='outlined'
            data-cy='companyId'
            value={importHRISEmployees.hris_id}
            required
            onChange={e =>
              setImportHRISEmployees({
                ...importHRISEmployees,
                hris_id: e.target.value,
              })
            }
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            color='primary'
            variant='contained'
            data-cy='hrisImportButton'
            style={{ height: '100%' }}
            onClick={() => hrisImportMutation.mutate(importHRISEmployees)}
            disabled={
              !importHRISEmployees.hris ||
              !importHRISEmployees.hris_id ||
              hrisImportMutation.isLoading
            }
            size='large'
            fullWidth
          >
            {hrisImportMutation.isLoading ? (
              <CircularProgress size={24} />
            ) : (
              t('hrisImport.importEmployeesLabel')
            )}
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box mt={5}>
          <Typography variant='h6' color='text.primary' paragraph>
            {t('hrisImport.doYouUseAnotherHRIS')}
          </Typography>

          <Typography variant='body2' color='text.secondary' paragraph>
            {t('hrisImport.comingSoon')}
          </Typography>
        </Box>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs>
          <TextField
            id='hrisSystem'
            label={t('hrisImport.hrisSystem')}
            placeholder='e.g. Optimum HRIS'
            variant='outlined'
            value={hrisSystem.hris_suggestion}
            required
            onChange={e =>
              setHrisSystem({ ...hrisSystem, hris_suggestion: e.target.value })
            }
            fullWidth
          />
        </Grid>

        <Grid item xs='auto'>
          <Button
            color='primary'
            variant='contained'
            style={{ height: '100%' }}
            onClick={() => hrisSuggestionMutation.mutate(hrisSystem)}
            disabled={
              hrisSuggestionMutation.isLoading || !hrisSystem.hris_suggestion
            }
            fullWidth
          >
            {hrisSuggestionMutation.isLoading ? (
              <CircularProgress size={24} />
            ) : (
              t('hrisImport.submit')
            )}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography color='text.secondary' variant='body2'>
            {t('hrisImport.futureDevelopment')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
