import { Typography } from '@mui/material'
import { t } from 'i18n'
import { getManagerCertificationVideoURL } from 'utils/managerCertification'

enum Section {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
}

enum Subsection {
  LearnIt = 'learn_it',
  PracticeIt = 'practice_it',
  MasterIt = 'master_it',
}

export enum TaskType {
  DownloadAssets = 'downloadAssets',
  Quiz = 'quiz',
  Redirect = 'redirect',
  Video = 'video',
}

export type DownloadData = {
  label: string
  href: string
}[]

export type QuizData = {
  id: string
  questions: {
    id: string
    label: string
    description?: string
    optional?: boolean
  }[]
}

export type RedirectData = {
  pathString: string
}

export type VideoData = {
  src: string
  scriptPDF?: string
}

export type TaskData = DownloadData | QuizData | RedirectData | VideoData

export type EmployeeRecognitionCertificationContentSection = {
  id: Section
  emoji: string
  title: string
  description?: string
  subsections: EmployeeRecognitionCertificationContentSubsection[]
}

export type EmployeeRecognitionCertificationContentSubsection = {
  id: Subsection
  title: string
  description?: string
  tasks: {
    id: string | number
    type: TaskType
    title: string
    description?: string
    data: TaskData
  }[]
}

type EmployeeRecognitionCertificationContent = {
  roadmap: EmployeeRecognitionCertificationContentSection[]
}

export const employeeRecognitionCertificationContent: EmployeeRecognitionCertificationContent =
  {
    roadmap: [
      {
        id: Section.One,
        emoji: '🎉',
        title: t(
          'managerCertification.managerCertificationData.roadmap.dayOne.title',
        ),
        description: t(
          'managerCertification.managerCertificationData.roadmap.dayOne.description',
        ),
        subsections: [
          {
            id: Subsection.LearnIt,
            title: t('managerCertification.managerCertificationData.learnIt'),
            description: t(
              'managerCertification.managerCertificationData.roadmap.dayOne.sections.learnItDescription',
            ),
            tasks: [
              {
                id: 1,
                type: TaskType.Video,
                title: t(
                  'managerCertification.managerCertificationData.roadmap.dayOne.tasks.trainingVideoName',
                ),
                data: {
                  src: getManagerCertificationVideoURL('day-1-training'),
                  scriptPDF:
                    'https://f.hubspotusercontent10.net/hubfs/5358414/Manager%20Certification%20Day%201%20Video%20Script%20(2).pdf',
                },
              },
              {
                id: 2,
                type: TaskType.DownloadAssets,
                title: t(
                  'managerCertification.managerCertificationData.roadmap.trainingResources',
                ),
                description: '',
                data: [
                  {
                    label: t(
                      'managerCertification.managerCertificationTask.trainingResourceForSection',
                      { section: Section.One },
                    ),
                    href: 'https://f.hubspotusercontent10.net/hubfs/5358414/Day%201%20Infographic-Dec-06-2021-07-13-53-22-PM.pdf',
                  },
                ],
              },
            ],
          },
          {
            id: Subsection.MasterIt,
            title: t('managerCertification.managerCertificationData.masterIt'),
            description: t(
              'managerCertification.managerCertificationData.roadmap.dayOne.sections.masterItDescription',
            ),
            tasks: [
              {
                id: 1,
                type: TaskType.Quiz,
                title: t(
                  'managerCertification.managerCertificationData.roadmap.takeTheQuiz',
                ),
                description: '',
                data: {
                  id: 'day_1_quiz',
                  questions: [
                    {
                      id: 'who_are_you_going_to_recognize',
                      label: t(
                        'managerCertification.managerCertificationQuizDialog.quizQuestions.sectionOne.whoAreYouGoingToRecognize',
                      ),
                    },
                    {
                      id: 'running_buddy',
                      label: t(
                        'managerCertification.managerCertificationQuizDialog.quizQuestions.sectionOne.runningBuddy',
                      ),
                      optional: true,
                    },
                    {
                      id: 'three_components_of_three_minute_sparck_sprint',
                      label: t(
                        'managerCertification.managerCertificationQuizDialog.quizQuestions.sectionOne.threeComponentsOfThreeMinuteSparckSprint',
                      ),
                    },
                    {
                      id: 'three_areas_to_deliver_effective_recognition',
                      label: t(
                        'managerCertification.managerCertificationQuizDialog.quizQuestions.sectionOne.threeAreasToDeliverEffectiveRecognition',
                      ),
                    },
                    {
                      id: 'feedback_or_suggestions_section_1',
                      label: t(
                        'managerCertification.managerCertificationQuizDialog.quizQuestions.sectionOne.feedbackOrSuggestionsSection1',
                      ),
                      optional: true,
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      {
        id: Section.Two,
        emoji: '🤔',
        title: t(
          'managerCertification.managerCertificationData.roadmap.dayTwo.title',
        ),
        description: t(
          'managerCertification.managerCertificationData.roadmap.dayTwo.description',
        ),
        subsections: [
          {
            id: Subsection.LearnIt,
            title: t('managerCertification.managerCertificationData.learnIt'),
            tasks: [
              {
                id: 1,
                type: TaskType.Video,
                title: t(
                  'managerCertification.managerCertificationData.roadmap.dayTwo.tasks.trainingVideoName',
                ),
                data: {
                  src: getManagerCertificationVideoURL('day-2-training'),
                  scriptPDF:
                    'https://f.hubspotusercontent10.net/hubfs/5358414/Manager%20Certification%20Day%202%20Video%20Script%20(1).pdf',
                },
              },
              {
                id: 2,
                type: TaskType.DownloadAssets,
                title: t(
                  'managerCertification.managerCertificationData.roadmap.trainingResources',
                ),
                description: '',
                data: [
                  {
                    label: t(
                      'managerCertification.managerCertificationTask.trainingResourceForSection',
                      { section: Section.Two },
                    ),
                    href: 'https://f.hubspotusercontent10.net/hubfs/5358414/Day%202%20Infographic%20%20(3).pdf',
                  },
                ],
              },
            ],
          },
          {
            id: Subsection.PracticeIt,
            title: t(
              'managerCertification.managerCertificationData.practiceIt',
            ),
            description: t(
              'managerCertification.managerCertificationData.roadmap.dayTwo.sections.practiceItDescription',
            ),
            tasks: [
              {
                id: 1,
                type: TaskType.Video,
                title: t(
                  'managerCertification.managerCertificationData.roadmap.watchSparckTour',
                ),
                data: {
                  src: getManagerCertificationVideoURL('day-2-tour'),
                },
              },
            ],
          },
          {
            id: Subsection.MasterIt,
            title: t('managerCertification.managerCertificationData.masterIt'),
            description: t(
              'managerCertification.managerCertificationData.roadmap.dayTwo.sections.masterItDescription',
            ),
            tasks: [
              {
                id: 1,
                type: TaskType.Quiz,
                title: t(
                  'managerCertification.managerCertificationData.roadmap.takeTheQuiz',
                ),
                description: '',
                data: {
                  id: 'day_2_quiz',
                  questions: [
                    {
                      id: 'did_the_person_change',
                      label: t(
                        'managerCertification.managerCertificationQuizDialog.quizQuestions.sectionTwo.didThePersonChange',
                      ),
                    },
                    {
                      id: 'goal_during_your_reflection',
                      label: t(
                        'managerCertification.managerCertificationQuizDialog.quizQuestions.sectionTwo.goalDuringYourReflection',
                      ),
                    },
                    {
                      id: 'feedback_or_suggestions_section_2',
                      label: t(
                        'managerCertification.managerCertificationQuizDialog.quizQuestions.sectionTwo.feedbackOrSuggestionsSection2',
                      ),
                      optional: true,
                    },
                  ],
                },
              },
              {
                id: 2,
                type: TaskType.Redirect,
                title: t(
                  'managerCertification.managerCertificationData.roadmap.dayTwo.tasks.challengeName',
                ),
                description: t(
                  'managerCertification.managerCertificationData.roadmap.dayTwo.tasks.challengeDescription',
                ),
                data: {
                  pathString: 'teamInsights',
                },
              },
            ],
          },
        ],
      },
      {
        id: Section.Three,
        emoji: '🔗',
        title: t(
          'managerCertification.managerCertificationData.roadmap.dayThree.title',
        ),
        description: t(
          'managerCertification.managerCertificationData.roadmap.dayThree.description',
        ),
        subsections: [
          {
            id: Subsection.LearnIt,
            title: t('managerCertification.managerCertificationData.learnIt'),
            tasks: [
              {
                id: 1,
                type: TaskType.Video,
                title: t(
                  'managerCertification.managerCertificationData.roadmap.dayThree.tasks.trainingVideoName',
                ),
                data: {
                  src: getManagerCertificationVideoURL('day-3-training'),
                  scriptPDF:
                    'https://f.hubspotusercontent10.net/hubfs/5358414/Manager%20Certification%20Day%203%20Video%20Script-1.pdf',
                },
              },
              {
                id: 2,
                type: TaskType.DownloadAssets,
                title: t(
                  'managerCertification.managerCertificationData.roadmap.trainingResources',
                ),
                description: '',
                data: [
                  {
                    label: t(
                      'managerCertification.managerCertificationTask.trainingResourceForSection',
                      { section: Section.Three },
                    ),
                    href: 'https://f.hubspotusercontent10.net/hubfs/5358414/Day%203%20Infographic%20-4.pdf',
                  },
                  {
                    label: t(
                      'managerCertification.managerCertificationTask.frogQuestions',
                      { section: Section.Three },
                    ),
                    href: 'https://f.hubspotusercontent10.net/hubfs/5358414/FROG%20Method%20Download%20-4.pdf',
                  },
                ],
              },
              {
                id: 3,
                type: TaskType.Video,
                title: t(
                  'managerCertification.managerCertificationData.roadmap.dayThree.tasks.sparckAppreciationStyles',
                ),
                description: t(
                  'managerCertification.managerCertificationData.roadmap.dayThree.tasks.sparckAppreciationStylesDescription',
                  {
                    link: (
                      <a
                        href='https://f.hubspotusercontent10.net/hubfs/5358414/Appreciation%20Styles%20Final-1.pdf'
                        rel='noopener'
                        target='_blank'
                        style={{ color: 'teal' }}
                      >
                        {t('linkHere')}
                      </a>
                    ),
                  },
                ),
                data: {
                  src: getManagerCertificationVideoURL(
                    'sparck-appreciation-styles',
                  ),
                },
              },
            ],
          },
          {
            id: Subsection.PracticeIt,
            title: t(
              'managerCertification.managerCertificationData.practiceIt',
            ),
            tasks: [
              {
                id: 1,
                type: TaskType.Video,
                title: t(
                  'managerCertification.managerCertificationData.roadmap.watchSparckTour',
                ),
                data: {
                  src: getManagerCertificationVideoURL('day-3-tour'),
                },
              },
            ],
          },
          {
            id: Subsection.MasterIt,
            title: t('managerCertification.managerCertificationData.masterIt'),
            description: t(
              'managerCertification.managerCertificationData.roadmap.dayThree.sections.masterItDescription',
            ),
            tasks: [
              {
                id: 1,
                type: TaskType.Quiz,
                title: t(
                  'managerCertification.managerCertificationData.roadmap.takeTheQuiz',
                ),
                description: '',
                data: {
                  id: 'day_3_quiz',
                  questions: [
                    {
                      id: 'g_in_the_frog_stands_for',
                      label: t(
                        'managerCertification.managerCertificationQuizDialog.quizQuestions.sectionThree.gInTheFrogStandsFor',
                      ),
                    },
                    {
                      id: 'your_sparck_appreciation_style',
                      label: t(
                        'managerCertification.managerCertificationQuizDialog.quizQuestions.sectionThree.yourSparckAppreciationStyle',
                      ),
                    },
                    {
                      id: 'appreciation_style_of_the_employee_you_are_going_to_recognize',
                      label: t(
                        'managerCertification.managerCertificationQuizDialog.quizQuestions.sectionThree.appreciationStyleOfTheEmployeeYouAreGoingToRecognize',
                      ),
                    },
                    {
                      id: 'feedback_or_suggestions_section_3',
                      label: t(
                        'managerCertification.managerCertificationQuizDialog.quizQuestions.sectionThree.feedbackOrSuggestionsSection3',
                      ),
                      optional: true,
                    },
                  ],
                },
              },
              {
                id: 2,
                type: TaskType.Redirect,
                title: t(
                  'managerCertification.managerCertificationData.roadmap.dayThree.tasks.challengeName',
                ),
                description: t(
                  'managerCertification.managerCertificationData.roadmap.dayThree.tasks.challengeDescription',
                ),
                data: {
                  pathString: 'employeesDirectory',
                },
              },
            ],
          },
        ],
      },
      {
        id: Section.Four,
        emoji: '😃',
        title: t(
          'managerCertification.managerCertificationData.roadmap.dayFour.title',
        ),
        description: t(
          'managerCertification.managerCertificationData.roadmap.dayFour.description',
        ),
        subsections: [
          {
            id: Subsection.LearnIt,
            title: t('managerCertification.managerCertificationData.learnIt'),
            tasks: [
              {
                id: 1,
                type: TaskType.Video,
                title: t(
                  'managerCertification.managerCertificationData.roadmap.dayFour.tasks.trainingVideoName',
                ),
                data: {
                  src: getManagerCertificationVideoURL('day-4-training'),
                  scriptPDF:
                    'https://f.hubspotusercontent10.net/hubfs/5358414/Manager%20Certification%20Day%204%20Video%20Script-1.pdf',
                },
              },
              {
                id: 2,
                type: TaskType.DownloadAssets,
                title: t(
                  'managerCertification.managerCertificationData.roadmap.trainingResources',
                ),
                description: '',
                data: [
                  {
                    label: t(
                      'managerCertification.managerCertificationTask.trainingResourceForSection',
                      { section: Section.Four },
                    ),
                    href: 'https://f.hubspotusercontent10.net/hubfs/5358414/Day%204%20Infographic%20-2.pdf',
                  },
                ],
              },
            ],
          },
          {
            id: Subsection.PracticeIt,
            title: t(
              'managerCertification.managerCertificationData.practiceIt',
            ),
            tasks: [
              {
                id: 1,
                type: TaskType.Video,
                title: t(
                  'managerCertification.managerCertificationData.roadmap.watchSparckTour',
                ),
                data: {
                  src: getManagerCertificationVideoURL('day-4-tour'),
                },
              },
            ],
          },
          {
            id: Subsection.MasterIt,
            title: t('managerCertification.managerCertificationData.masterIt'),
            description: t(
              'managerCertification.managerCertificationData.roadmap.dayFour.sections.masterItDescription',
            ),
            tasks: [
              {
                id: 1,
                type: TaskType.Quiz,
                title: t(
                  'managerCertification.managerCertificationData.roadmap.takeTheQuiz',
                ),
                description: '',
                data: {
                  id: 'day_4_quiz',
                  questions: [
                    {
                      id: 'how_would_you_describe_the_two_types_or_rewards',
                      label: t(
                        'managerCertification.managerCertificationQuizDialog.quizQuestions.sectionFour.howWouldYouDescribeTheTwoTypesOrRewards',
                      ),
                    },
                    {
                      id: 'recognition_needs_to_be_frequent_specific_and',
                      label: t(
                        'managerCertification.managerCertificationQuizDialog.quizQuestions.sectionFour.recognitionNeedsToBeFrequentSpecificAnd',
                      ),
                    },
                    {
                      id: 'what_are_you_recognizing_them_for_section_4',
                      label: t(
                        'managerCertification.managerCertificationQuizDialog.quizQuestions.sectionFour.whatAreYouRecognizingThemForSection4',
                      ),
                    },
                    {
                      id: 'feedback_or_suggestions_section_4',
                      label: t(
                        'managerCertification.managerCertificationQuizDialog.quizQuestions.sectionFour.feedbackOrSuggestionsSection4',
                      ),
                      optional: true,
                    },
                  ],
                },
              },
              {
                id: 2,
                type: TaskType.Redirect,
                title: t(
                  'managerCertification.managerCertificationData.roadmap.dayFour.tasks.challengeName',
                ),
                description: t(
                  'managerCertification.managerCertificationData.roadmap.dayFour.tasks.challengeDescription',
                ),
                data: {
                  pathString: 'sparck',
                },
              },
            ],
          },
        ],
      },
      {
        id: Section.Five,
        emoji: '⏰',
        title: t(
          'managerCertification.managerCertificationData.roadmap.dayFive.title',
        ),
        description: t(
          'managerCertification.managerCertificationData.roadmap.dayFive.description',
          {
            rememberMessage: (
              <Typography variant='h6' color='text.secondary' paragraph>
                {t(
                  'managerCertification.managerCertificationData.roadmap.dayFive.rememberMessage',
                )}
              </Typography>
            ),
          },
        ),
        subsections: [
          {
            id: Subsection.LearnIt,
            title: t('managerCertification.managerCertificationData.learnIt'),
            tasks: [
              {
                id: 1,
                type: TaskType.Video,
                title: t(
                  'managerCertification.managerCertificationData.roadmap.dayFive.tasks.trainingVideoName',
                ),
                data: {
                  src: getManagerCertificationVideoURL('day-5-training'),
                  scriptPDF:
                    'https://f.hubspotusercontent10.net/hubfs/5358414/Manager%20Certification%20Day%205%20Video%20Script-1.pdf',
                },
              },
              {
                id: 2,
                type: TaskType.DownloadAssets,
                title: t(
                  'managerCertification.managerCertificationData.roadmap.trainingResources',
                ),
                description: '',
                data: [
                  {
                    label: t(
                      'managerCertification.managerCertificationTask.trainingResourceForSection',
                      { section: Section.Five },
                    ),
                    href: 'https://f.hubspotusercontent10.net/hubfs/5358414/Day%205%20Infographic%20-2.pdf',
                  },
                ],
              },
            ],
          },
          {
            id: Subsection.PracticeIt,
            title: t(
              'managerCertification.managerCertificationData.practiceIt',
            ),
            tasks: [
              {
                id: 1,
                type: TaskType.Video,
                title: t(
                  'managerCertification.managerCertificationData.roadmap.watchSparckTour',
                ),
                data: {
                  src: getManagerCertificationVideoURL('day-5-tour'),
                },
              },
            ],
          },
          {
            id: Subsection.MasterIt,
            title: t('managerCertification.managerCertificationData.masterIt'),
            description: t(
              'managerCertification.managerCertificationData.roadmap.dayFive.sections.masterItDescription',
            ),
            tasks: [
              {
                id: 1,
                type: TaskType.Quiz,
                title: t(
                  'managerCertification.managerCertificationData.roadmap.takeTheQuiz',
                ),
                description: '',
                data: {
                  id: 'day_5_quiz',
                  questions: [
                    {
                      id: 'most_valuable_thing_you_learned_this_week',
                      label: t(
                        'managerCertification.managerCertificationQuizDialog.quizQuestions.sectionFive.mostValuableThingYouLearnedThisWeek',
                      ),
                    },
                    {
                      id: 'what_would_you_change_about_the_certification',
                      label: t(
                        'managerCertification.managerCertificationQuizDialog.quizQuestions.sectionFive.whatWouldYouChangeAboutTheCertification',
                      ),
                    },
                    {
                      id: 'what_day_are_you_going_to_recognize_them',
                      label: t(
                        'managerCertification.managerCertificationQuizDialog.quizQuestions.sectionFive.whatDayAreYouGoingToRecognizeThem',
                      ),
                    },
                    {
                      id: 'how_are_you_going_to_recognize_them',
                      label: t(
                        'managerCertification.managerCertificationQuizDialog.quizQuestions.sectionFive.howAreYouGoingToRecognizeThem',
                      ),
                    },
                    {
                      id: 'what_are_you_recognizing_them_for_section_5',
                      label: t(
                        'managerCertification.managerCertificationQuizDialog.quizQuestions.sectionFive.whatAreYouRecognizingThemForSection5',
                      ),
                    },
                    {
                      id: 'what_is_your_recognition_preference',
                      label: t(
                        'managerCertification.managerCertificationQuizDialog.quizQuestions.sectionFive.whatIsYourRecognitionPreference',
                      ),
                    },
                    {
                      id: 'can_we_tag_you_in_social_media',
                      label: t(
                        'managerCertification.managerCertificationQuizDialog.quizQuestions.sectionFive.canWeTagYouInSocialMedia',
                      ),
                    },
                    {
                      id: 'testimonial',
                      description: t(
                        'managerCertification.managerCertificationQuizDialog.quizQuestions.sectionFive.testimonial.description',
                      ),
                      label: t(
                        'managerCertification.managerCertificationQuizDialog.quizQuestions.sectionFive.testimonial.label',
                      ),
                      optional: true,
                    },
                  ],
                },
              },
              {
                id: 2,
                type: TaskType.Redirect,
                title: t(
                  'managerCertification.managerCertificationData.roadmap.dayFive.tasks.challengeName',
                ),
                description: t(
                  'managerCertification.managerCertificationData.roadmap.dayFive.tasks.challengeDescription',
                ),
                data: {
                  pathString: 'sparck',
                },
              },
            ],
          },
        ],
      },
    ],
  }
