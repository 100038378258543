import React, { useState, useEffect } from 'react'
import { Box, Button, CircularProgress, Grid, Tab, Tabs, Tooltip, IconButton, Badge } from '@mui/material'
import { get, isEmpty } from 'lodash'
import { UseMutationResult } from 'react-query'
import { useTheme } from '@mui/material'

import BookmarksIcon from '@mui/icons-material/Bookmarks';
// import PrintIcon from '@mui/icons-material/Print';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SystemUpdateAltTwoToneIcon from '@mui/icons-material/SystemUpdateAltTwoTone';
import OpenInBrowserTwoToneIcon from '@mui/icons-material/OpenInBrowserTwoTone';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import OutboxTwoToneIcon from '@mui/icons-material/OutboxTwoTone';

import {
  Edit as EditIcon,
} from '@mui/icons-material'
import { useUser } from 'contexts/UserContext'
// import { format, parseISO } from 'date-fns'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)

import { ManageSurvey, Surveys } from 'pages'
import {
  DialogTeleport,
  SurveyPreviewDialog,
} from 'components'

import { SurveyOverviewInfo } from '..'
import { SurveyOverviewInviteEmployee/*, SurveyOverviewActions*/ } from '../../components'
import { CompanyAdminLayout, ConfirmationDialog, SurveyOpenAndCloseDateDialog } from 'components'
import { t } from 'i18n'
// import { ShareReport } from 'pages'
import { SurveyType } from 'types'
import { ROUTES_PATHS } from 'core/routes'
import { initialDialog } from 'constants/dialog'
import StarsRoundedIcon from '@mui/icons-material/StarsRounded';
import { a11yTabProps } from 'utils/accessibility'
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import {
  Loyalty as ShareReportsIcon,
} from '@mui/icons-material'
import { useHistory, Link } from 'react-router-dom'
import { SurveyStatus } from 'enums/survey'

enum SurveyOverviewTab {
  Info = 'info',
  // SharedReports = 'sharedReports',
}

export type SurveyOverviewComponentProps = {
  company: any
  surveys: SurveyType[] | null
  isSurveysLoading: boolean
  fetchSurveys(): void
  surveyOverview: any
  isSurveyOverviewLoading: boolean
  launchSurveyMutation: UseMutationResult
  extendSurveyMutation: UseMutationResult
  inviteEmployeesMutation: UseMutationResult
  closeSurveyMutation: UseMutationResult
  deleteSurveyMutation: UseMutationResult
  selectedSurvey: any
  setSelectedSurvey: any
  confirmCloseDialogOpen: boolean
  setConfirmCloseDialogOpen(value: boolean): void
  confirmDeleteDialogOpen: boolean
  setConfirmDeleteDialogOpen(value: boolean): void
  confirmLaunchSurveyDialogOpen: boolean
  setConfirmLaunchSurveyDialogOpen(value: boolean): void
  extendSurveyDialogOpen: boolean
  setExtendSurveyDialogOpen(value: boolean): void
  inviteEmployeesDialogOpen: boolean
  setInviteEmployeeDialogOpen(value: boolean): void
  getSurveyOverview(): void
  addSurveyToCart(): void
  addSurveyToCartIsLoading: boolean
  toggleSurveyReportLevelMutation: UseMutationResult
  uninvitedUsers: any[]
  preview: any
  getSurveyPreview: () => any
  count: any
  setCount: any
}

const jsonStatus = {
  1: "open",
  2: "awaiting_manual_opening",
  3: "close",
  4: "scheduled"
}

export default function SurveyOverviewComponent(
  props: SurveyOverviewComponentProps,
) {
  const {
    surveys,
    isSurveysLoading,
    selectedSurvey,
    company,
    setSelectedSurvey,
    surveyOverview,
    isSurveyOverviewLoading,
    uninvitedUsers,
    closeSurveyMutation,
    extendSurveyMutation,
    inviteEmployeesMutation,
    confirmCloseDialogOpen,
    setConfirmCloseDialogOpen,
    extendSurveyDialogOpen,
    setExtendSurveyDialogOpen,
    inviteEmployeesDialogOpen,
    setInviteEmployeeDialogOpen,
    setConfirmLaunchSurveyDialogOpen,
    preview,
    toggleSurveyReportLevelMutation,
    getSurveyPreview
  } = props

  const theme = useTheme()
  const [dialogManageSurvey, setDialogManageSurvey] = useState(initialDialog)
  const history = useHistory()
  const [selectedTab, setSelectedTab] = useState(SurveyOverviewTab.Info)
  const [showDetails, setShowDetails] = useState<any>(false)
  const [closeDateDialogOpen, setCloseDateDialogOpen] = useState(false)
  const [deleteSurvey, setDeleteSurvey] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [confirmLaunchSurveyDialog, setConfirmLaunchSurveyDialog] = useState(false);
  
  const [surveyCloseDate, setSurveyCloseDate] = useState(
    selectedSurvey?.close_at,
  )
  const [previewDialog, setPreviewDialog] = useState(initialDialog)
  const [tabValue, setTabValue] = useState(1);
  const [newSurvey, setNewSurvey] = useState(true);

  const { user } = useUser()!
  const hide = true;

  const companyHasSufficientEmployees =
    get(company, 'current_number_of_employees') >= 5

  // const companyHasActiveSurvey = surveys?.find(
  //   survey => survey.status !== SurveyStatus.Close,
  // )

  useEffect(() => {
    if (surveys) {
      setNewSurvey(false)
      surveys?.map((item: any) => {
        if (item?.status === 'awaiting_manual_opening' || item?.status === 'scheduled' || item?.status === 'open') {
          setNewSurvey(true)
        }
      })
    }
  }, [surveys])

  const tabs = [
    {
      value: SurveyOverviewTab.Info,
      label: t('surveyOverview.tabs.labels.general'),
      Component: SurveyOverviewInfo,
    },
    // {
    //   value: SurveyOverviewTab.SharedReports,
    //   label: t('surveyOverview.tabs.labels.sharedReports'),
    //   Component: ShareReport,
    // },
  ]

  let surveysOpen = 0;
  let surveysPending = 0;
  let surveysClosed = 0;
  let surveysScheduled = 0;

  if (surveys) {
    surveys?.map((item: any) => {
      if(item?.status === "open"){
        surveysOpen++;
      }

      if(item?.status === "awaiting_manual_opening"){
        surveysPending++;
      }

      if(item?.status === "close"){
        surveysClosed++;
      }

      if(item?.status === "scheduled"){
        surveysScheduled++;
      }
    })
  }

  const statusTabs = [
    {
      value: 1,
      // label: "Open"
      label: <Badge badgeContent={surveysOpen} color='primary' sx={{'& .MuiBadge-badge': {right: 0, top: 8, padding: '0 4px',},}} style={{paddingRight: (surveysOpen > 99) ? '20px' : '14px'}}>
        Open
      </Badge>
    },
    {
      value: 2,
      // label: "Pending"
      label: <Badge badgeContent={surveysPending} color='primary' sx={{'& .MuiBadge-badge': {right: 0, top: 8, padding: '0 4px',},}} style={{paddingRight: (surveysPending > 99) ? '20px' : '14px'}}>
        Pending
      </Badge>
    },
    {
      value: 3,
      // label: "Closed"
      label: <Badge badgeContent={surveysClosed} color='primary' sx={{'& .MuiBadge-badge': {right: 0, top: 8, padding: '0 4px',},}} style={{paddingRight: (surveysClosed > 99) ? '20px' : '14px'}}>
        Closed
      </Badge>
    },
    {
      value: 4,
      // label: "Scheduled"
      label: <Badge badgeContent={surveysScheduled} color='primary' sx={{'& .MuiBadge-badge': {right: 0, top: 8, padding: '0 4px',},}} style={{paddingRight: (surveysScheduled > 99) ? '20px' : '14px'}}>
        Scheduled
      </Badge>
    },

  ]

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  useEffect(() => {
    setSelectedTab(SurveyOverviewTab.Info)
  }, [selectedSurvey])

  const openSurvey = () => {
    setDialogManageSurvey({ isOpen: true, data: null })
    // console.log("ROUTES_PATHS.manageSurveyFrom ", ROUTES_PATHS.manageSurveyFrom);
    history.push(ROUTES_PATHS.manageSurvey)
  }

  function onFilterChange(list: any) {
    return list?.filter(res => res?.status == jsonStatus[tabValue]);
  }

  return (
    <>
      <Grid container>
        {showDetails ?
          // Show Detail section
          <>
            {isSurveysLoading ||
              isSurveyOverviewLoading ||
              !surveyOverview ||
              isEmpty(surveyOverview) ||
              !selectedSurvey ? (
              <Grid container justifyContent='center'>
                <Box mt={5}>
                  <CircularProgress size={40} />
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Button
                  style={{ padding: "8px 12px", fontSize: ".8em", float: "left", margin: "20px 0px" }}
                  variant='contained'
                  color='secondary'
                  onClick={() =>
                    setShowDetails(false)
                  }
                >
                  Back
                </Button>
                {/* Manual lanuch table data */}
                <Grid container>
                  {selectedSurvey && selectedSurvey?.report_level === 'upgraded' && (
                    <Grid item xs={12}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
                        <Tabs value={selectedTab} onChange={handleTabChange}>
                          {tabs.map((tab, index) => (
                            <Tab
                              key={index}
                              data-cy='tabs'
                              value={tab.value}
                              label={tab.label}
                              {...a11yTabProps(index)}
                            />
                          ))}
                        </Tabs>
                      </Box>
                    </Grid>
                  )}

                  {/* Closed survey data table data */}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {tabs.map((tab, index) => (
                          <TabPanel
                            key={index}
                            value={tab.value}
                            selectedTab={selectedTab}
                            index={index}
                          >
                            <tab.Component
                              {...props}
                              surveyId={selectedSurvey?.id}
                            />
                          </TabPanel>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
          :
          <>
            <Grid item xs={11} >
              {hide && <Box mb={2} >
                <Button
                  style={{ float: 'right', marginBottom: '-38px' }}
                  data-cy='new_survey'
                  variant='outlined'
                  color='primary'
                  onClick={() =>
                    // setDialogManageSurvey({ isOpen: true, data: null })
                    openSurvey()
                  }
                  disabled={!companyHasSufficientEmployees || newSurvey}
                >
                  {t('surveyOverview.newSurvey')}
                </Button>
              </Box>}
            </Grid>

            {dialogManageSurvey.isOpen &&
              <DialogTeleport
                dialogTitle=''
                dialogAction={null}
                dialogSize='md'
                dialogOpen={!!dialogManageSurvey.isOpen}
                handleDialogClose={() =>
                  setDialogManageSurvey({ isOpen: false, data: null })

                }
                isFullWidth
              >

                <ManageSurvey
                  dialog={dialogManageSurvey}
                  closeDialog={() => setDialogManageSurvey(initialDialog)}
                  // fetchSurveyOverview={getSurveyOverview}
                  // fetchSurveys={fetchSurveys}
                  {...props}
                />
              </DialogTeleport>
            }

            <CompanyAdminLayout
              slug='shareReports'
              title='BeHeard Surveys'
              description='Manage BeHeard Surveys sertting for each survey in your organization'
              color={theme.palette.info.main}
              surveys={surveys}
              isManageSurvey={true}
              requestUrl='surveys'
              showTab={true}
              tabList={statusTabs}
              tabValue={tabValue}
              filterCondition={onFilterChange}
              setDeleteData={setDeleteData}
              deleteData={deleteData}
              managementPermission={false}
              onTabChange={(event: React.SyntheticEvent, newValue: number) => setTabValue(newValue)}
              requestOptions={{
                queryParams: {
                  id: selectedSurvey?.id,
                },
              }}
              tableProps={{
                columns: [
                  {
                    title: "Survey Name",
                    field: 'name',
                    editable: 'never',
                    render: (rowData: any) => <a onClick={() => {
                      setSelectedSurvey(rowData);
                      //    setSurveyCloseDate(rowData.created_at);
                      setShowDetails(true);
                    }}>
                      <Box
                        // component='li'
                        sx={{ '& > span': { mr: 1, flexShrink: 0 } }}
                      >
                        {rowData?.name}
                        &nbsp;
                      </Box>

                    </a>
                  },
                  {
                    title: 'Close Date',//useTranslation('shareReport.columnHeaders.sharedReport'),
                    field: 'close_at',
                    editable: 'never',
                    tooltip: 'Survey will close on',
                    render: (rowData: any) => <>{rowData.close_at ? dayjs(rowData.close_at).tz(company.timezone).format("MM/DD/YYYY") : 'N/A'}</>
                  },
                  {
                    title: 'Engagement',//useTranslation('shareReport.columnHeaders.sharedReport'),
                    field: 'engagement_percentage',
                    editable: 'never',
                    tooltip: 'The extent to which employees feel passionate about their jobs, are committed to our organization, put discretionary effort into their work and are willing to refer.',
                  },
                  {
                    title: 'eNPS Score',//useTranslation('shareReport.columnHeaders.sharedReport'),
                    field: 'enps_percentage',
                    editable: 'never',
                    tooltip: 'Employee Net Promoter Score.',
                  },
                  {
                    title: 'Participation ',//useTranslation('shareReport.columnHeaders.sharedReport'),
                    field: 'participation_rate',
                    editable: 'never',
                    tooltip: '% of Participant attend the survey',
                    render: (rowData: any) => rowData.participation_rate + "%"
                  },
                ],
                actions: [
                  // apply downgrade button MJ
                  {
                    icon: (row) => row.status === SurveyStatus.Close &&
                      row.report_level === 'upgraded' && (
                        <>
                          {user.sparck_administrator &&
                            <Tooltip title={t('surveyOverview.applyDowngrade')}>
                              <IconButton
                                onClick={() =>
                                  toggleSurveyReportLevelMutation.mutate({
                                    id: row.id,
                                  })
                                }
                              >

                                <SystemUpdateAltTwoToneIcon />
                                {/* {t('surveyOverview.deleteSurvey')} */}
                              </IconButton>
                            </Tooltip>}
                        </>
                      )
                    ,
                    onClick: (() => { }),
                    hidden: (row) => !(row.status === SurveyStatus.Close &&
                      row.report_level === 'upgraded' && user.sparck_administrator)
                  },
                  // apply Upgrade button MJ For Admin
                  {
                    icon: (row) => row.status === SurveyStatus.Close &&
                      row.report_level === 'free' && user.sparck_administrator && (
                        <>
                          <Tooltip title={t('surveyOverview.applyUpgrade')}>
                            <IconButton
                              onClick={() =>
                                toggleSurveyReportLevelMutation.mutate({
                                  id: row.id,
                                })
                              }
                            >
                              <OpenInBrowserTwoToneIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      )
                    ,
                    onClick: (() => { }),
                    hidden: (row) => !(row.status === SurveyStatus.Close &&
                      row.report_level === 'free' && user.sparck_administrator)
                  },
                  // apply Upgrade button MJ For Managers
                  {
                    icon: (row) => row.status === SurveyStatus.Close &&
                      row.report_level === 'free' && !user.sparck_administrator && (
                        <>
                          <Button color='secondary' variant="contained" startIcon={<StarsRoundedIcon />}
                            sx={{
                              fontSize: '0.75rem',
                              fontWeight: 700,
                              color: 'text.white',
                              // hover:,
                              borderRadius: 1,
                            }}
                            onClick={() =>
                              toggleSurveyReportLevelMutation.mutate({
                                id: row.id,
                              })
                            }
                          >
                            upgrade
                          </Button>
                        </>
                      )
                    ,
                    onClick: (() => { }),
                    hidden: (row) => !(row.status === SurveyStatus.Close &&
                      row.report_level === 'free' && !user.sparck_administrator)
                  },
                  // Clone survey button MJ
                  // {
                  //   icon: (row) => companyHasActiveSurvey &&
                  //     (<Tooltip title="Clone">
                  //       <IconButton
                  //         onClick={() =>
                  //           setDialogManageSurvey({
                  //             isOpen: true,
                  //             data: { ...row, isClone: true },
                  //           })
                  //         }
                  //       >
                  //         <FileCopyOutlinedIcon />
                  //         {/* {t('surveyOverview.deleteSurvey')} */}
                  //       </IconButton>
                  //     </Tooltip>)
                  //   ,
                  //   onClick: (() => { }),
                  //   hidden: () => !(companyHasActiveSurvey)
                  // },
                  {
                    icon: (row) => 
                      (<Tooltip title="Clone">
                        <IconButton
                          onClick={() =>
                            setDialogManageSurvey({
                              isOpen: true,
                              data: { ...row, isClone: true },
                            })
                          }
                        >
                          <FileCopyOutlinedIcon />
                          {/* {t('surveyOverview.deleteSurvey')} */}
                        </IconButton>
                      </Tooltip>)
                    ,
                    onClick: (() => { }),
                    hidden: () => false
                  },
                  // Preview Survey button MJ
                  {
                    icon: (row) => ((row.status === SurveyStatus.Scheduled ||
                      row.status === SurveyStatus.AwaitingManualOpening)) && (
                        <Tooltip title="Preview Survey">
                          <IconButton
                            onClick={async () => {
                              await getSurveyPreview()
                              setPreviewDialog({
                                isOpen: true,
                                data: {
                                  ...preview?.survey,
                                  answers: [],
                                },
                              })
                            }}
                          >
                            <ManageSearchIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    onClick: (() => { }),
                    hidden: (row) => !(row.status === SurveyStatus.Scheduled ||
                      row.status === SurveyStatus.AwaitingManualOpening)
                  },
                  // Launch Survey button MJ
                  {
                    icon: (row) => ((row.status === SurveyStatus.Scheduled ||
                      row.status === SurveyStatus.AwaitingManualOpening)) &&
                      <Tooltip title={t('surveyOverview.launchSurvey')}>
                        <IconButton
                          onClick={() => { setSelectedSurvey(row); setCloseDateDialogOpen(true) }}
                        >
                          <OutboxTwoToneIcon />
                        </IconButton>
                      </Tooltip>
                    ,
                    onClick: (() => { }),
                    hidden: (row) => !(row.status === SurveyStatus.Scheduled ||
                      row.status === SurveyStatus.AwaitingManualOpening)
                  },
                  // Print Survey button MJ - Commented As it is not displaying
                  // {
                  //   icon: (row) => ((row.status === SurveyStatus.Scheduled ||
                  //     row.status === SurveyStatus.AwaitingManualOpening)) &&
                  //     <Link
                  //       to={`${ROUTES_PATHS.beheardReport}?id=${row.id}&full_report=0`}
                  //     >
                  //       <Tooltip title={'Print'}>
                  //         <IconButton
                  //         >
                  //           <PrintIcon />
                  //         </IconButton>
                  //       </Tooltip>
                  //     </Link>,
                  //   onClick: (() => { }),
                  //   hidden: (row) => !(row.status === SurveyStatus.Scheduled ||
                  //     row.status === SurveyStatus.Close)
                  // },
                  // Delete Survey button MJ
                  {
                    icon: (row) => (row && row.status !== SurveyStatus.Open && row.report_level !== 'upgraded') ?
                      <Tooltip title={t('surveyOverview.deleteSurvey')}>
                        <IconButton
                          onClick={() => { setDeleteSurvey(true); setDeleteData(row); }}
                        >
                          <DeleteOutlineTwoToneIcon />
                          {/* {t('surveyOverview.deleteSurvey')} */}
                        </IconButton>

                        {/* {t('surveyOverview.deleteSurvey')} */}

                      </Tooltip>
                      : null,
                    onClick: (() => { }),
                    hidden: (row) => !(row && row.status !== SurveyStatus.Open && row.report_level !== 'upgraded')
                  },
                  // Edit Survey button MJ
                  {
                    icon: (row) => (row.status === SurveyStatus.Scheduled ||
                      row.status === SurveyStatus.AwaitingManualOpening) ?
                      <Tooltip title={t('surveyOverview.editSurvey')}>
                        <IconButton
                          onClick={() => setDialogManageSurvey({ isOpen: true, data: row, })}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      : null,
                    onClick: (() => { }),
                    hidden: (row) => !(row.status === SurveyStatus.Scheduled ||
                      row.status === SurveyStatus.AwaitingManualOpening)
                  },
                  // Extend Survey button MJ
                  {
                    icon: (row) => (row.status === SurveyStatus.Open) ?
                      <Tooltip title={t('surveyOverview.extendSurvey')}>
                        <IconButton
                          onClick={() => setExtendSurveyDialogOpen(true)}
                        >
                          <EventOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                      : null,
                    onClick: (() => { }),
                    hidden: (row) => !(row.status === SurveyStatus.Open)
                  },
                  // Close Survey button MJ
                  {
                    icon: (row) => (row.status === SurveyStatus.Open) ?
                      <Tooltip title={t('surveyOverview.closeSurvey')}>
                        <IconButton
                          onClick={() => setConfirmCloseDialogOpen(true)}
                        >
                          <CancelOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                      : null,
                    onClick: (() => { }),
                    hidden: (row) => !(row.status === SurveyStatus.Open)
                  },
                  // Free report button MJ
                  {
                    icon: (row) => (row.status === SurveyStatus.Close &&
                      row.report_level === 'free') ?
                      <Link
                        to={`${ROUTES_PATHS.beheardReport}?id=${row.id}&full_report=0`}
                      >
                        <Tooltip title={t('report.freeReport')}>
                          <IconButton
                            onClick={() => setConfirmCloseDialogOpen(true)}
                          >
                            <BookmarksIcon />
                          </IconButton>
                        </Tooltip>
                      </Link>
                      : null,
                    onClick: (() => { }),
                    hidden: (row) => !(row.status === SurveyStatus.Close &&
                      row.report_level === 'free')
                  },
                  // Premium Report button MJ
                  {
                    icon: (row) => (row.status === SurveyStatus.Close &&
                      row.report_level === 'upgraded') ?
                      <Link
                        to={`${ROUTES_PATHS.beheardReport}?id=${row.id}&full_report=1`}
                      >
                        <Tooltip title={t('report.premiumReport')}>
                          <IconButton
                            onClick={() => setConfirmCloseDialogOpen(true)}
                            sx={{
                              background: `linear-gradient(135deg, ${theme.palette.secondary.main}, ${theme.palette.error.light})`,
                            }}
                          >
                            <StarsRoundedIcon />
                          </IconButton>
                        </Tooltip>
                      </Link>
                      : null,
                    onClick: (() => { }),
                    hidden: (row) => !(row.status === SurveyStatus.Close &&
                      row.report_level === 'upgraded')
                  },
                  // Invite employee in survey button MJ
                  {
                    icon: (row) => (!isEmpty(uninvitedUsers) &&
                      row.status !== SurveyStatus.Close) &&
                      (<Tooltip title={t('surveyOverview.inviteEmployees')}>
                        <IconButton
                          onClick={() => setInviteEmployeeDialogOpen(true)}
                        >
                          <PersonRemoveIcon />
                          {/* <FileCopyOutlinedIcon /> */}
                        </IconButton>
                      </Tooltip>)
                    ,
                    onClick: (() => { }),
                    hidden: (row) => !(!isEmpty(uninvitedUsers) &&
                      row.status !== SurveyStatus.Close)
                  },
                ],
                editActionProps: {
                  hidden: () => true,
                },
                deleteActionProps: {
                  hidden: () => true,
                },
              }}
              deleteSurveyData={deleteSurvey}
              setDeleteSurveyData={setDeleteSurvey}
              // managementPermission={UserPermission.ShareReport}
              managementFormProps={{
                surveyId: selectedSurvey,
              }}
              // ManagementForm={ManageShareReport}
              IconHeader={ShareReportsIcon}
            />

            {dialogManageSurvey.isOpen &&
              <DialogTeleport
                dialogTitle=''
                dialogAction={null}
                dialogSize='md'
                dialogOpen={!!dialogManageSurvey.isOpen}
                handleDialogClose={() =>
                  setDialogManageSurvey({ isOpen: false, data: null })

                }
                isFullWidth
              >
                <ManageSurvey
                  dialog={dialogManageSurvey}
                  closeDialog={() => setDialogManageSurvey(initialDialog)}
                  fetchSurveyOverview={props.getSurveyOverview}
                  fetchSurveys={props.fetchSurveys}
                />
              </DialogTeleport>
            }


            {/* 
            <SurveyPreviewDialog
              size='lg'
              previewDialog={previewDialog}
              setPreviewDialog={setPreviewDialog}
            >
              <Surveys preview={previewDialog?.data} />
            </SurveyPreviewDialog> */}
            <SurveyPreviewDialog
              size='lg'
              previewDialog={previewDialog}
              setPreviewDialog={setPreviewDialog}
            >
              <Surveys preview={previewDialog?.data} />
            </SurveyPreviewDialog>
          </>
        }
        <ConfirmationDialog
          dialogOpen={confirmCloseDialogOpen}
          setDialogOpen={setConfirmCloseDialogOpen}
          message={t('components.confirmationDialog.defaultMessage', {
            action: t('surveyDialogs.confirmClose.close'),
            item: selectedSurvey?.name,
          })}
          item={selectedSurvey}
          actionProps={{
            onClick: () => closeSurveyMutation.mutate(selectedSurvey),
            isLoading: closeSurveyMutation.isLoading,
            label: t('surveyDialogs.confirmClose.actionLabel'),
          }}
        />
        <SurveyOpenAndCloseDateDialog
          title={t('surveyDialogs.extendSurvey.title')}
          isDialogOpen={extendSurveyDialogOpen}
          setIsDialogOpen={setExtendSurveyDialogOpen}
          survey={selectedSurvey}
          action={newCloseDate =>
            extendSurveyMutation.mutate({
              id: selectedSurvey.id,
              new_date: newCloseDate,
            })
          }
          showCloseDate
          isLoading={extendSurveyMutation.isLoading}
        />
        <SurveyOverviewInviteEmployee
          isDialogOpen={inviteEmployeesDialogOpen}
          setIsDialogOpen={setInviteEmployeeDialogOpen}
          inviteEmployees={data => inviteEmployeesMutation.mutate(data)}
          survey={selectedSurvey}
          isInviteEmployeeLoading={inviteEmployeesMutation.isLoading}
          uninvitedUsers={uninvitedUsers}
        />
        <SurveyOpenAndCloseDateDialog
          title='When do you want your survey to close?'
          isDialogOpen={closeDateDialogOpen}
          setIsDialogOpen={setCloseDateDialogOpen}
          survey={selectedSurvey}
          action={date => {
            setSurveyCloseDate(date)
            setCloseDateDialogOpen(false)
            setConfirmLaunchSurveyDialog(true)
            setConfirmLaunchSurveyDialogOpen(true)
          }}
          showCloseDate
        />
        <ConfirmationDialog
          // dialogOpen={props.confirmLaunchSurveyDialogOpen || confirmLaunchSurveyDialog}
          // setDialogOpen={() => { props.confirmLaunchSurveyDialogOpen ? setConfirmLaunchSurveyDialog(false) : setConfirmLaunchSurveyDialog(false) }}
          dialogOpen={confirmLaunchSurveyDialog}
          setDialogOpen={setConfirmLaunchSurveyDialog}
          message={t('components.confirmationDialog.launchBeheardSurveyMessage', {
            link: <Link to={ROUTES_PATHS.employees}>{t('linkHere')}</Link>,
          })}
          item={selectedSurvey}
          actionProps={{
            onClick: () => {
              const closeDate = new Date(surveyCloseDate);
              closeDate.setMinutes(closeDate.getMinutes() - closeDate.getTimezoneOffset());
              props.launchSurveyMutation.mutate({
                ...selectedSurvey,
                close_at: closeDate.toJSON(),
              })
              setConfirmLaunchSurveyDialog(false)
            },
            isLoading: props.launchSurveyMutation.isLoading,
            label: t('surveyOverview.launchSurvey'),
          }}
        />
        {/* <ConfirmationDialog
          dialogOpen={props.confirmLaunchSurveyDialogOpen}
          setDialogOpen={setConfirmLaunchSurveyDialogOpen}
          message={t('components.confirmationDialog.launchBeheardSurveyMessage', {
            link: <Link to={ROUTES_PATHS.employees}>{t('linkHere')}</Link>,
          })}
          item={selectedSurvey}
          actionProps={{
            onClick: () => {
              props.launchSurveyMutation.mutate({
                ...selectedSurvey,
                close_at: surveyCloseDate,
              })
            },
            isLoading: props.launchSurveyMutation.isLoading,
            label: t('surveyOverview.launchSurvey'),
          }}
        /> */}
        {/* <ConfirmationDialog
          dialogOpen={deletePopup}
          setDialogOpen={() => setDeletePopup(false)}
          message={t('components.confirmationDialog.defaultMessage', {
            action: t('components.confirmationDialog.delete'),
            item: selectedSurvey?.name,
          })}
          item={selectedSurvey}
          actionProps={{
            onClick: () => deleteSurveyMutation.mutate(selectedSurvey),
            isLoading: deleteSurveyMutation.isLoading,
            label: t('surveyDialogs.confirmDelete.actionLabel'),
            color: 'error',
          }}
        /> */}

      </Grid>
    </>
  )
}

function TabPanel(props) {
  const { children, value, index, selectedTab, ...other } = props
  return (
    <div
      role='tabpanel'
      hidden={value !== selectedTab}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === selectedTab && <Box width={1}>{children}</Box>}
    </div>
  )
}