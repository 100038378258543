import { Grid } from '@mui/material'

import { DemographicsPicker } from 'components'
import { t } from 'i18n'

export default function ManageRecognitionWhoIsThisEventFor({
  formik,
  demographics,
  demographicsIsLoading,
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DemographicsPicker
          key='demographics'
          fieldName='demographics'
          formik={formik}
          demographics={demographics}
          demographicsIsLoading={demographicsIsLoading}
          label={t('recognitions.fields.whoIsThisEventFor')}
        />
      </Grid>
    </Grid>
  )
}
