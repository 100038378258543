import { Button, Grid, Skeleton, Typography } from '@mui/material'

import { Props } from '../../VirginExperiencesOrganizational.props'
import { SparckTable } from 'components'

export default function VirginExperiencesOrganizationalBalanceAlerts(
  props: Props,
) {
  const {
    virginExperiencesOrganizational,
    virginExperiencesOrganizationalIsLoading,
    setUpdateBalanceAlertsDialogOpen,
  } = props

  return (
    <Grid container>
      {virginExperiencesOrganizationalIsLoading ? (
        <Grid item xs={12}>
          <Skeleton width='100%' height={40} />
        </Grid>
      ) : (
        <Grid item xs={12}>
          {virginExperiencesOrganizational?.remaining_balance_alerts?.length >
          0 ? (
            <SparckTable
              slug='remaining_balance_alerts'
              data={virginExperiencesOrganizational?.remaining_balance_alerts}
              columns={[
                {
                  title: 'Amount',
                  field: 'remaining_amount',
                  editable: 'never',
                },
                {
                  title: 'Alert was sent',
                  field: 'alert_sent',
                  editable: 'never',
                  render: (rowData: any) => (rowData.alert_sent ? 'Yes' : 'No'),
                },
              ]}
              style={{ boxShadow: 'none' }}
              hideToolbar
            />
          ) : (
            <Typography variant='body1' color='text.secondary' fontWeight={500}>
              No credit alerts configured yet.
            </Typography>
          )}
        </Grid>
      )}

      <Grid item xs={12} sx={{ mt: 1.5 }}>
        <Grid container justifyContent='flex-end'>
          <Grid item xs={12} sm='auto'>
            <Button
              variant='contained'
              color='primary'
              onClick={() => setUpdateBalanceAlertsDialogOpen(true)}
            >
              Manage Balance Alerts
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
