import { get } from 'lodash'
import { Box, Chip, Grid } from '@mui/material'
import { useIntl } from 'react-intl'

import { AvoidCopy, CRUDTableDND } from 'components'
import { useUser } from 'contexts'
import { UserPermission } from 'enums/user'
import { t } from 'i18n'
import { capitalizeFirstLetter } from 'utils/app'

const PULSE_SURVEY_QUESTIONS_LIMIT = 50

export default function ManagePulseSurveyQuestions(props: any) {
  const { pulseQuestions, setPulseQuestions, pulseLibraryQuestions } = props

  const { user } = useUser()!
  const intl = useIntl()
  console.log(pulseQuestions
    .filter(row => row.library_question_id)
    .map(row => row.library_question_id));

  return (
    <Grid container style={{ marginTop: 16 }}>
      <Grid item xs={12} data-cy='pulseSurveyQuestions'>
        <AvoidCopy>
          <CRUDTableDND
            rows={pulseQuestions}
            setRows={setPulseQuestions}
            columns={[
              {
                id: 'statement',
                label: t(
                  'pulseSurveyOverview.dialogs.questionsTable.tableHeaders.statement',
                ),
                type: 'text',
                align: 'left',
                required: true,
                disabled: () => false,
                // showEdit: row => !row.library_question_id,
                showEdit: () => true,
                defaultValue: '',
                textFieldProps: {
                  required: pulseQuestions.length === 0,
                  helperText:
                    pulseQuestions.length === 0
                      ? intl.formatMessage({
                        id: 'pulseSurveyOverview.dialogs.questionsTable.statementHelperText',
                      })
                      : '',
                  placeholder: intl.formatMessage({
                    id: 'pulseSurveyOverview.dialogs.questionsTable.statementPlaceholder',
                  }),
                },
              },
              {
                id: 'show_importance',
                label: t(
                  'pulseSurveyOverview.dialogs.questionsTable.tableHeaders.showImportance',
                ),
                type: 'checkbox',
                align: 'center',
                required: false,
                disabled: () => false,
                showEdit: () => true,
                defaultValue: true,
              },
              {
                id: 'open_ended',
                label: t(
                  'pulseSurveyOverview.dialogs.questionsTable.tableHeaders.isOpenEnded',
                ),
                type: 'checkbox',
                align: 'center',
                required: false,
                disabled: () => false,
                showEdit: () => true,
                defaultValue: false,
              },
            ]}
            limit={PULSE_SURVEY_QUESTIONS_LIMIT}
            showAdd
            // showEdit={row => !row.library_question_id}
            showEdit={() => true}
            showRemove={() => true}
            {...((user.permissions ?? []).includes(
              UserPermission.ViewPulseSurveyLibrary,
            ) && {
              libraryProps: {
                title: intl.formatMessage({
                  id: 'pulseSurveyOverview.dialogs.questionsTable.header',
                }),

                rows: get(pulseLibraryQuestions, 'questions', []).map(
                  (question) => ({
                    ...question,
                    api_id: question.id,
                    id: `${question.id}_${question.type}`,
                  }),
                ),
                columns: [
                  {
                    id: 'statement',
                    label: t(
                      'pulseSurveyOverview.dialogs.questionsTable.librarySelection.headers.statement',
                    ),
                  },
                  {
                    id: 'type',
                    label: t('type'),
                    render: row => (
                      <Box mt={1}>
                        <Chip
                          label={
                            <span style={{ fontWeight: 500 }}>
                              {capitalizeFirstLetter(row.type)}
                            </span>
                          }
                          color={
                            row.type === 'beheard' ? 'primary' : 'secondary'
                          }
                        />
                      </Box>
                    ),
                  },
                ],
                orderBy: 'position',
                selectedIds: pulseQuestions
                  .filter(row => row.library_question_id)
                  .map(row => row.library_question_id),
                onSelectClick: selected => {
                  // console.log(selected);

                  setPulseQuestions([
                    ...pulseQuestions.filter(row => !row.library_question_id),
                    ...selected.map(selectedQuestion => ({
                      library_question_id: selectedQuestion.id,
                      statement: selectedQuestion.statement,
                      show_importance: false,
                      open_ended: false,
                      _destroy: false,
                      pulseType: selectedQuestion?.type
                    })),
                  ])
                },
              },
            })}
          />
        </AvoidCopy>
      </Grid>
    </Grid>
  )
}
