import { CircularProgress, Grid } from '@mui/material'

import {
  OrganizationReportingSelectedReport,
  OrganizationReportingSummary,
} from '../../components'

type Props = {
  company: any
  reportsList: any[]
  reportsListIsLoading: boolean
  organizationSummaryData: any[]
  organizationSummaryDataIsLoading: boolean
  selectedReport: string | null
  setSelectedReport(value: string): void
  selectedReportData: any
  selectedReportDataIsLoading: boolean
  fetchSelectedReportData(): void
  selectedReportFilters: any
  setSelectedReportFilters(value: any): void
}

export default function OrganizationReportingComponent(props: Props) {
  const {
    company,
    reportsListIsLoading,
    organizationSummaryData,
    organizationSummaryDataIsLoading,
  } = props

  return (
    <Grid container spacing={2}>
      {!organizationSummaryDataIsLoading && (
        <Grid item xs={12}>
          <OrganizationReportingSummary
            company={company}
            organizationSummaryData={organizationSummaryData}
          />
        </Grid>
      )}

      {!reportsListIsLoading && (
        <Grid item xs={12}>
          <OrganizationReportingSelectedReport
            {...props}
            organizationSummaryData={organizationSummaryData}
          />
        </Grid>
      )}

      {(reportsListIsLoading || organizationSummaryDataIsLoading) && (
        <Grid item xs={12}>
          <Grid container justifyContent='center'>
            <Grid item xs='auto'>
              <CircularProgress size={64} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
