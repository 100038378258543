import { Fragment } from 'react'

import DateFnsUtils from '@date-io/date-fns'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import enUSLocale from 'date-fns/locale/en-US'
import esLocale from 'date-fns/locale/es'
import ptBRLocale from 'date-fns/locale/pt-BR'
import jaLocale from 'date-fns/locale/ja'
import deLocale from 'date-fns/locale/de'
import arLocale from 'date-fns/locale/ar-SA'
import daLocale from 'date-fns/locale/da'
import nlLocale from 'date-fns/locale/nl'
import frLocale from 'date-fns/locale/fr-CA'
import fiLocale from 'date-fns/locale/fi'
import itLocale from 'date-fns/locale/it'
import ruLocale from 'date-fns/locale/ru'
import svLocale from 'date-fns/locale/sv'
import esBRLocale from 'date-fns/locale/es'
import euLocale from 'date-fns/locale/eu'
import caLocale from 'date-fns/locale/ca'
import flatten from 'flat'
import { IntlProvider } from 'react-intl'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Router } from 'react-router-dom'

import {
  CompaniesProvider,
  CompanyProvider,
  DarkModeProvider,
  LocaleProvider,
  PulseSurveysProvider,
  ShoppingCartItemsCountProvider,
  SidebarExpandedProvider,
  useLocale,
  UserProvider,
} from 'contexts'
import ApplicationStyle from './ApplicationStyle'
import { history } from 'core/history'
import { Locale } from 'enums/locale'
import messages from 'i18n'

export default function ApplicationGlobalContexts() {
  const queryClient = new QueryClient()

  return (
    <Router history={history}>
      <QueryClientProvider client={queryClient} contextSharing>
        <CompanyProvider>
          <UserProvider>
            <LocaleProvider>
              <I18nProvider>
                <DarkModeProvider>
                  <CompaniesProvider>
                    <ShoppingCartItemsCountProvider>
                      <PulseSurveysProvider>
                        <SidebarExpandedProvider>
                          <ApplicationStyle />
                        </SidebarExpandedProvider>
                      </PulseSurveysProvider>
                    </ShoppingCartItemsCountProvider>
                  </CompaniesProvider>
                </DarkModeProvider>
              </I18nProvider>
            </LocaleProvider>
          </UserProvider>
        </CompanyProvider>
      </QueryClientProvider>
    </Router>
  )
}

function I18nProvider({ children }) {
  const { locale }: any = useLocale()

  const LOCALE = {
    [Locale.English]: enUSLocale,
    [Locale.BrazilianPortuguese]: ptBRLocale,
    [Locale.Spanish]: esLocale,
    [Locale.Japanese]: jaLocale,
    [Locale.German]: deLocale,
    [Locale.Arabic]: arLocale,
    [Locale.Danish]: daLocale,
    [Locale.Dutch]: nlLocale,
    [Locale.French]: frLocale,
    [Locale.EUSpanish]: euLocale,
    [Locale.Finnish]: fiLocale,
    [Locale.Italian]: itLocale,
    [Locale.Russian]: ruLocale,
    [Locale.Swedish]: svLocale,
    [Locale.BrazilianSpanish]: esBRLocale,
    [Locale.CanadianFrench]: caLocale,

  }

  return (
    <IntlProvider
      textComponent={Fragment}
      locale={locale}
      defaultLocale={Locale.English}
      messages={flatten(messages[locale], { safe: true }) as any}
    >
      <LocalizationProvider
        utils={DateFnsUtils}
        locale={LOCALE[locale]}
        dateAdapter={AdapterDateFns}
      >
        {children}
      </LocalizationProvider>
    </IntlProvider>
  )
}
