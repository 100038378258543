import { useState } from 'react'
import { Grid as GiphyGrid } from '@giphy/react-components'
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  useMediaQuery,
} from '@mui/material'
import { SearchOutlined as SearchIcon } from '@mui/icons-material'
import { useIntl } from 'react-intl'

import poweredByGiphyImage from 'assets/images/giphy/powered-by-giphy.png'
import { DialogTeleport } from 'components'
import { giphy } from 'constants/giphy'

const GIPHY_KEYS = [
  'happy',
  'awesome',
  'great work',
  'birthday',
  'congratulations',
  'spark',
  'dogs',
  'cats',
  'hamsters',
  'guinea pigs',
  'party hard',
  'kermit',
]

type GiphyDialogType = {
  dialogOpen: boolean
  setDialogOpen(value: boolean): void
  columns?: number
  gutter?: number
  limit?: number
  setGif(value: { id: string; src: string }): void
}

export default function GiphyDialog({
  dialogOpen,
  setDialogOpen,
  columns = 3,
  gutter = 6,
  limit = 10,
  setGif,
}: GiphyDialogType) {
  const GIPHY_DEFAULT_KEY =
    GIPHY_KEYS[Math.floor(Math.random() * GIPHY_KEYS.length)]

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.only('xs'))
  const isTablet = useMediaQuery((theme: any) => theme.breakpoints.only('sm'))

  const [searchKey, setSearchKey] = useState(GIPHY_DEFAULT_KEY)
  const [searchText, setSearchText] = useState('')

  const intl = useIntl()

  const handleFetchGifs = (offset: number) => {
    return giphy.search(searchKey, {
      offset,
      limit: limit,
      rating: 'pg',
    })
  }

  const handleGifClick = (gif, event) => {
    event.preventDefault()
    setGif({ id: gif.id, src: gif.images.original.url })
    setDialogOpen(false)
  }

  return (
    <DialogTeleport
      dialogTitle={intl.formatMessage({ id: 'giphy.dialog.title' })}
      dialogAction={null}
      dialogSize='md'
      dialogOpen={dialogOpen}
      handleDialogClose={() => setDialogOpen(false)}
      isFullWidth
    >
      <Grid container justifyContent='center'>
        <Grid item xs={12}>
          <Grid container justifyContent='flex-end'>
            <Box
              component='img'
              src={poweredByGiphyImage}
              alt='Powered by GIPHY'
              sx={{ maxWidth: 240 }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          onKeyUp={({ key }) => key === 'Enter' && setSearchKey(searchText)}
        >
          <TextField
            value={searchText}
            onChange={event => setSearchText(event.target.value)}
            variant='outlined'
            placeholder={intl.formatMessage({ id: 'giphy.placeholder' })}
            margin='normal'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    disabled={!searchText}
                    onClick={() => {
                      setSearchKey(searchText)
                    }}
                    size='large'
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
          />
          <Grid item xs={12} data-cy='gif_option'>
            <GiphyGrid
              width={isMobile ? 360 : isTablet ? 540 : 840}
              columns={columns}
              gutter={gutter}
              fetchGifs={handleFetchGifs}
              key={searchKey}
              onGifClick={handleGifClick}
            />
          </Grid>
        </Grid>
      </Grid>
    </DialogTeleport>
  )
}
