import { Box, FormControlLabel, Switch, Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'

import { ActiveInactiveStatus } from 'enums/app'
import { t } from 'i18n'
import { capitalizeFirstLetter } from 'utils/app'
import { formatToUSD } from 'utils/currency'

const getStatusColor = status =>
  status === ActiveInactiveStatus.Active ? 'primary.dark' : 'text.primary'

export const getSwitch = (rowData, accessor, handleActivation) => {
  return (
    <FormControlLabel
      control={
        <Switch
          data-cy='status'
          checked={rowData[accessor] === ActiveInactiveStatus.Active}
          onChange={async event => {
            event.stopPropagation()

            await handleActivation({
              id: rowData.id,
              [accessor]: event.target.checked
                ? ActiveInactiveStatus.Active
                : ActiveInactiveStatus.Inactive,
            })
          }}
          color='primary'
          name={accessor}
        />
      }
      label={
        <Typography
          variant='body2'
          color={getStatusColor(rowData[accessor])}
          fontWeight={400}
        >
          {capitalizeFirstLetter(rowData[accessor] || '')}
        </Typography>
      }
    />
  )
}

/**
 * Material-UI date-fns label
 * @param {*} value
 */
export const getDateLabel = (value: any, dateFormat = 'MM/dd/yyyy') => {
  if (value) {
    return format(
      typeof value === 'string' ? parseISO(value) : value,
      dateFormat,
    )
  } else {
    return value
  }
}

/**
 * Material-UI currency-formatted label
 * @param {*} value
 */
export const getCurrencyLabel = (value: number) => formatToUSD(value)

export const getBoolean = (value: any) => (
  <Box
    component='span'
    sx={{
      fontWeight: 600,
      color: value ? 'primary.main' : 'error.light',
    }}
  >
    {value ? t('yes') : t('no')}
  </Box>
)

export const getNotApplicable = () => (
  <Box component='span' sx={{ color: 'text.light' }}>
    N/A
  </Box>
)
