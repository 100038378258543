export enum NotifiableType {
  PulseSurvey = 'PulseSurveys::Survey',
  Sparck = 'Recognitions::Recognition',
  Survey = 'Survey',
  Mention = 'Mention',
  NominationAward = 'Nominations::Award',
  NominationEvent = 'Nominations::Event',
  SuggestionBoxIdea = 'SuggestionBoxIdea',
  GeneralNotification = 'GeneralNotification',
  User = 'User',
}

export enum MentionNotificationSource {
  Post = 'Post',
  Sparck = 'Recognitions::Recognition',
  Comment = 'Comment',
}
