import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material'
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material'
import { useIntl } from 'react-intl'
import { FetchOptions } from 'react-query/types/core/query'

import { t } from 'i18n'
import { EmployeeType } from 'types'

type Props = {
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
  fetchEmployees: (
    options?: FetchOptions | undefined,
  ) => Promise<EmployeeType[] | undefined>
  favoritesOnly: boolean
  setFavoritesOnly: React.Dispatch<React.SetStateAction<boolean>>
}

export default function EmployeesDirectorySearch({
  setSearch,
  favoritesOnly,
  setFavoritesOnly,
}: Props) {
  const [searchState, setSearchState] = useState('')

  const intl = useIntl()

  return (
    <Box width={1} my={1}>
      <Grid container spacing={2} justifyContent='center' alignItems='center'>
        <Grid
          item
          xs={12}
          sm
          onKeyUp={({ key }) => key === 'Enter' && setSearch(searchState)}
        >
          <TextField
            id='search'
            value={searchState}
            onChange={event => setSearchState(event.target.value)}
            variant='outlined'
            placeholder={intl.formatMessage({
              id: 'people.searchFieldPlaceholder',
            })}
            InputProps={{
              endAdornment: searchState && (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={async () => {
                      await setSearchState('')
                      setSearch('')
                    }}
                    size="large">
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm='auto'>
          <Button
            variant='contained'
            color='primary'
            size='large'
            onClick={() => setSearch(searchState)}
          >
            <SearchIcon /> {t('people.search')}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            key={'favorites_only'}
            control={
              <Checkbox
                checked={favoritesOnly}
                onChange={() => setFavoritesOnly(!favoritesOnly)}
                name={'favorites_nly'}
              />
            }
            label={t('people.favoritesLabel')}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
