import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@mui/material'

import {
  VirginExperiencesOrganizationalBalance,
  VirginExperiencesOrganizationalBalanceAlerts,
  VirginExperiencesOrganizationalUpdateAlertsDialog,
  VirginExperiencesOrganizationalUpdateBalanceDialog,
} from '../../components'
import { Props } from '../../VirginExperiencesOrganizational.props'

export default function VirginExperiencesOrganizationalComponent(props: Props) {
  const {
    company,
    virginExperiencesOrganizational,
    virginExperiencesOrganizationalIsLoading,
    addVirginExperiencesToCompany,
  } = props

  return (
    <>
      {virginExperiencesOrganizationalIsLoading ? (
        <Grid container justifyContent='center' sx={{ my: 5 }}>
          <Grid item xs='auto'>
            <CircularProgress size={40} />
          </Grid>
        </Grid>
      ) : virginExperiencesOrganizational ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <VirginExperiencesOrganizationalCard
              title={'Balance'}
              description={
                "Are you suggesting that coconuts migrate? You don't vote for kings. Burn her! You don't vote for kings. Did you dress her up like this?"
              }
            >
              <VirginExperiencesOrganizationalBalance {...props} />
            </VirginExperiencesOrganizationalCard>
          </Grid>

          <Grid item xs={12} md={6}>
            <VirginExperiencesOrganizationalCard
              title={'Remaining Balance Alerts'}
              description={
                "Well, I got better. I am your king. You don't frighten us, English pig-dogs! Go and boil your bottoms, sons of a silly person! I blow my nose at you, so-called Ah-thoor Keeng, you and all your silly English K-n-n-n-n-n-n-n-niggits!"
              }
            >
              <VirginExperiencesOrganizationalBalanceAlerts {...props} />
            </VirginExperiencesOrganizationalCard>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <VirginExperiencesOrganizationalCard
              title={`Add Virgin Experiences to ${company?.name}`}
              description={
                'Do you want to set up Virgin Experiences for this company?'
              }
            >
              <Grid container justifyContent='center' sx={{ mt: 3 }}>
                <Grid item xs='auto'>
                  <Button
                    variant='contained'
                    color='primary'
                    disabled={addVirginExperiencesToCompany.isLoading}
                    onClick={() =>
                      addVirginExperiencesToCompany.mutate({
                        company_id: company?.id,
                      })
                    }
                  >
                    {addVirginExperiencesToCompany.isLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      'Begin Virgin Experiences setup'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </VirginExperiencesOrganizationalCard>
          </Grid>
        </Grid>
      )}

      <VirginExperiencesOrganizationalUpdateBalanceDialog {...props} />
      <VirginExperiencesOrganizationalUpdateAlertsDialog {...props} />
    </>
  )
}

function VirginExperiencesOrganizationalCard({ title, description, children }) {
  return (
    <Paper sx={{ py: 2, px: 3, height: 1 }}>
      <Grid container>
        {title && (
          <Grid item xs={12}>
            <Typography
              variant='h6'
              color='text.primary'
              fontWeight={600}
              sx={{ mb: 0.5 }}
            >
              {title}
            </Typography>
          </Grid>
        )}

        {description && (
          <Grid item xs={12}>
            <Typography variant='body2' color='text.secondary' sx={{ mb: 0.5 }}>
              {description}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12} sx={{ mt: 1 }}>
          {children}
        </Grid>
      </Grid>
    </Paper>
  )
}
