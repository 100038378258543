import {
  Box,
  Card,
  CardContent,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'

import firstPlace from 'assets/images/managerCertification/first-place.png'
import secondPlace from 'assets/images/managerCertification/second-place.png'
import thirdPlace from 'assets/images/managerCertification/third-place.png'
import leaderboardBackground from 'assets/images/managerCertification/manager-certification-leaderboard-background.png'
import { UserAvatar } from 'components'
import { t } from 'i18n'

export default function ManagerCertificationLeaderboard(props) {
  const { userCertificationBoards } = props

  const usersWhoFinishedTheCertification =
    userCertificationBoards?.first_completed_users

  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('sm'),
    {
      defaultMatches: true,
    },
  )

  function EmptyLeaderBoardMessage() {
    return (
      <Grid container justifyContent='center'>
        <Grid item>
          <Typography align='center' variant='h6' color='text.light'>
            {t('managerCertification.noOneHasCompletedTheCertification')}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container>
      <Typography color='text.primary' variant='h5' fontWeight={600} paragraph>
        {t('managerCertification.leaderboard.title')}
      </Typography>

      {usersWhoFinishedTheCertification?.length ? (
        <Grid item xs={12}>
          <Box
            sx={{
              background: `linear-gradient(180deg, #20EBE1 7.81%, #FFCD3A 100%)`,
              borderRadius: 4,
            }}
          >
            <Grid
              container
              sx={{
                background: `url(${leaderboardBackground})`,
                mt: 2,
                justifyContent: 'center',
                py: 12,
                borderRadius: 4,
              }}
            >
              <Grid item xs={12} mt={2}>
                <Box
                  mb={3}
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <Card
                    elevation={2}
                    sx={{
                      width: isMobile ? '100%' : '40%',
                      pb: 3,
                      height: 700,
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        width: 8,
                        borderRadius: 8,
                        backgroundColor: 'transparent',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        borderRadius: 8,
                        height: 5,
                        backgroundColor: '#d9d9d9',
                      },
                    }}
                  >
                    <Box px={isMobile ? 1 : 2}>
                      <CardContent>
                        <Grid container alignItems='center'>
                          <Grid item mb={4} mt={2} xs={12}>
                            <Typography
                              color='text.primary'
                              fontSize={24}
                              fontWeight='600'
                              textAlign='center'
                            >
                              {t('managerCertification.leaderboard.header')}
                            </Typography>
                          </Grid>

                          {usersWhoFinishedTheCertification?.map(
                            (user, index) => (
                              <Grid
                                key={user.user_id}
                                item
                                xs={12}
                                mt={index > 0 ? 4 : 0}
                              >
                                <Grid container alignItems='center'>
                                  {index <= 2 && (
                                    <Grid item xs={1}>
                                      <Grid container>
                                        <img
                                          src={
                                            index === 0
                                              ? firstPlace
                                              : index === 1
                                              ? secondPlace
                                              : thirdPlace
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                  )}
                                  {index > 2 && (
                                    <Grid item xs={1}>
                                      <Grid container>
                                        <Typography
                                          color='text.primary'
                                          fontSize={16}
                                          fontWeight='500'
                                          marginLeft={1}
                                        >
                                          {index + 1}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  )}

                                  <Grid item ml={2}>
                                    <UserAvatar
                                      name={user.full_name}
                                      size={40}
                                      src={user.avatar_url}
                                    />
                                  </Grid>
                                  <Grid item ml={isMobile ? 0 : 2}>
                                    <Typography
                                      fontSize={isMobile ? 14 : 18}
                                      fontWeight={index <= 2 ? '600' : 'normal'}
                                      color='text.primary'
                                      lineHeight='27px'
                                    >
                                      {user.full_name}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ),
                          )}
                        </Grid>
                      </CardContent>
                    </Box>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      ) : (
        <EmptyLeaderBoardMessage />
      )}
    </Grid>
  )
}
