import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'

import { SparckAppreciationAssessmentResults } from '../../components'
import { t } from 'i18n'
import { OnboardingTitle } from 'pages/onboarding/components'

export default function SparckAppreciationAssessmentQuestions({
  questions,
  answers,
  setAnswers,
  answerAssessment,
  assessmentResults,
  theme,
  isMobile,
}) {
  return (
    <Grid container justifyContent='center'>
      {assessmentResults ? (
        <SparckAppreciationAssessmentResults
          assessmentResults={assessmentResults}
        />
      ) : (
        <>
          <Grid item xs={12}>
            <Box width={1} mb={5}>
              <Typography variant='body1' color='text.secondary' paragraph>
                {t('sparckAppreciationAssessment.questions.description')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <OnboardingTitle
              backgroundColor1={theme.palette.primary.main}
              backgroundColor2={theme.palette.primary.light}
              title={t('sparckAppreciationAssessment.questions.questionTitle')}
            />
          </Grid>
          <Grid item xs={12}>
            {answers
              .map(answer => answer.choiceGroupId)
              .map((choiceGroupId, index) => {
                const choiceGroupAnswer = answers.find(
                  answer => answer.choiceGroupId === choiceGroupId,
                )

                return (
                  <FormControl
                    component='fieldset'
                    key={`${choiceGroupId}-${index}`}
                    style={{ width: '100%' }}
                  >
                    <RadioGroup
                      aria-label='choice-group-check'
                      name='choice-group-check'
                      value={choiceGroupAnswer.answerId}
                      onChange={event => {
                        if (!choiceGroupAnswer.answerId) {
                          window.scrollBy(0, 160)
                        }

                        choiceGroupAnswer.answerId = +event.target.value

                        setAnswers([...answers])
                      }}
                    >
                      <Box
                        my={3}
                        width={1}
                        style={{
                          zIndex: 998,
                          marginBottom: isMobile ? 8 : '-24px',
                        }}
                      >
                        <Grid container justifyContent='center'>
                          <Grid item xs={isMobile ? 12 : 'auto'}>
                            <Paper
                              style={{
                                width: isMobile ? '100%' : 'auto',
                                backgroundColor: choiceGroupAnswer.answerId
                                  ? theme.palette.primary.light
                                  : '#FFF',
                              }}
                            >
                              <Box px={3} py={1}>
                                <Typography
                                  variant='h6'
                                  color={`text.${
                                    choiceGroupAnswer.answerId
                                      ? 'white'
                                      : 'primary'
                                  }`}
                                  align='center'
                                >
                                  {index + 1}
                                </Typography>
                              </Box>
                            </Paper>
                          </Grid>
                        </Grid>
                      </Box>
                      <Grid container justifyContent='center' spacing={2}>
                        {questions
                          .filter(
                            question =>
                              question.choice_group === +choiceGroupId,
                          )
                          .map(question => (
                            <Grid key={question.id} item xs={12} sm={6}>
                              <Box width={1} mb={2} height={1}>
                                <Paper
                                  data-cy='question'
                                  style={{
                                    ...(question.id ===
                                      choiceGroupAnswer.answerId && {
                                      backgroundColor: `${theme.palette.primary.main}15`,
                                      outline: `1px solid ${theme.palette.primary.main}`,
                                    }),
                                    height: '100%',
                                    width: '100%',
                                  }}
                                >
                                  <FormControlLabel
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      paddingLeft: 24,
                                    }}
                                    value={question.id}
                                    control={<Radio color='primary' />}
                                    label={
                                      <Typography
                                        variant={isMobile ? 'body2' : 'body1'}
                                        color='text.secondary'
                                      >
                                        <Box width={1} py={3} px={2}>
                                          {question.statement}
                                        </Box>
                                      </Typography>
                                    }
                                  />
                                </Paper>
                              </Box>
                            </Grid>
                          ))}
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                )
              })}
          </Grid>

          <Grid item xs={12}>
            <Box width={1} mt={2}>
              <Button
                data-cy='sendAnswers'
                onClick={() => answerAssessment.mutate()}
                variant='contained'
                color='primary'
                size='large'
                disabled={
                  answerAssessment.isLoading ||
                  answers.find(answer => !answer.answerId)
                }
                fullWidth
              >
                {t('sparckAppreciationAssessment.labels.send')}{' '}
                {answerAssessment.isLoading && <CircularProgress size={24} />}
              </Button>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  )
}
