import { Grid, Typography } from '@mui/material'

import { CRUDTable } from 'components'
import { OnboardingQuestionType } from 'enums/onboardingQuestion'
import { t } from 'i18n'

const MAX_QUESTIONS = 30

export default function ManageOnboardingQuestionAlternatives(props: any) {
  const { formik, onboardingChoices, setOnboardingChoices } = props

  if (
    [
      OnboardingQuestionType.Rating,
      OnboardingQuestionType.SimpleSelect,
    ].includes(formik.values.type)
  ) {
    return (
      <>
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
          style={{ marginTop: 16 }}
        >
          <Typography variant='h5' color='text.primary' paragraph>
            {t('onboardingQuestions.alternatives')}
          </Typography>
        </Grid>

        <Grid container style={{ marginTop: 16 }}>
          <Grid item xs={12}>
            <CRUDTable
              rows={onboardingChoices}
              setRows={setOnboardingChoices}
              columns={[
                {
                  id: 'statement',
                  defaultValue: '',
                  label: t('onboardingQuestions.alternative'),
                  required: true,
                  type: 'text',
                  showEdit: () => true,
                },
              ]}
              limit={MAX_QUESTIONS}
              showAdd
              showEdit={() => true}
              showRemove={() => true}
            />
          </Grid>
        </Grid>
      </>
    )
  }

  return null
}
