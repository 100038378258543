import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'

// import { MFAOptions } from 'components/MFASetup/components'
import { MFASetup } from 'pages'

const settingsSections = [
  {
    id: 'generalSettings',
    label: 'General Settings',
    settings: [
      {
        id: 'mfa',
        title: 'Multi-Factor Authentication',
        Content: MFASetup,
        defaultExpanded: true,
      },
    ],
  },
]

export default function MySettings() {
  return (
    <>
      <Grid container justifyContent='center' sx={{ mt: 2 }}>
        <Grid item xs={11} lg={10} sx={{ maxWidth: '1440px' }}>
          <Grid container spacing={2}>
            {settingsSections.map(section => (
              <SettingsSection section={section} key={section.id}>
                {section.settings.map(setting => (
                  <Grid container key={setting.id}>
                    <Grid item xs={12}>
                      <AccordionSection section={setting} />
                    </Grid>
                  </Grid>
                ))}
              </SettingsSection>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

function SettingsSection({ children, section }) {
  return (
    <>
      <Grid item xs={12}>
        <Typography color='text.secondary' variant='h6'>
          {section.label}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

function AccordionSection({ section }) {
  return (
    <Accordion defaultExpanded={section.defaultExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${section.id}a-content`}
        id={`panel${section.id}a-header`}
      >
        <Typography variant='h6' color='text.primary' fontWeight={600}>
          {section.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <section.Content />
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}
