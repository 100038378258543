import {
  Add as AddIcon,
  HelpOutline as HelpOutlineIcon,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Grid,
  styled,
  Theme,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useMediaQuery,
} from '@mui/material'
import pluralize from 'pluralize'
import { useIntl } from 'react-intl'

import { Props } from '../../CompanyAdminLayout.types'
import { capitalizeFirstLetter } from 'utils/app'

type HeaderProps = Omit<Props, 'requestUrl'> & {
  setDialogForm: any
  userHasManagementPermission: boolean
  isManageSurvey?: boolean
}

const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: 'rgba(32, 32, 32, 0.9) !important',
    borderRadius: '0.5rem !important',
  },
}))

export default function CompanyAdminLayoutHeader({
  slug,
  title,
  description,
  color,
  IconHeader,
  setDialogForm,
  ManagementForm,
  customHeaderActions = [],
  disableCreate,
  userHasManagementPermission,
}: HeaderProps) {
  const intl = useIntl()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'))

  return (
    <Grid container justifyContent='space-between' alignItems='center'>
      <Grid item xs={8} sm sx={{ mb: { xs: 2, sm: 0 } }}>
        <Grid container alignItems='center'>
          <Grid item xs='auto'>
            <Box
              sx={{
                height: 64,
                width: 64,
                backgroundColor: color,
                borderRadius: '1rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mr: 3.5,
                color: '#FFF',
                fontSize: '2.25rem',
              }}
            >
              <IconHeader
                sx={{
                  color: '#FFF',
                  fill: '#FFF',
                  fontSize: '2.25rem',
                  width: '2.25rem',
                  height: '2.25rem',
                }}
              />
            </Box>
          </Grid>

          <Grid item xs>
            <Typography variant='h4' color='text.primary' fontWeight={'bold'}>
              <Grid container component='span' alignItems='center'>
                {title}
                {description && (
                  <DarkTooltip
                    title={description}
                    placement={isMobile ? 'bottom-start' : 'right'}
                  >
                    <HelpOutlineIcon sx={{ ml: 1.5, color: 'text.light' }} />
                  </DarkTooltip>
                )}
              </Grid>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm='auto'>
        <Grid container spacing={1} justifyContent='center' alignItems='center'>
          {[
            ...customHeaderActions,
            ...(ManagementForm && userHasManagementPermission && !disableCreate
              ? [
                  {
                    label: `${intl.formatMessage({
                      id: 'companyAdminLayout.add',
                    })} ${pluralize.singular(title)}`,
                    onClick: () => setDialogForm({ isOpen: true, data: null }),
                    dataCy: `add${capitalizeFirstLetter(slug)}Button`,
                    variant: 'contained',
                    color: color,
                    Icon: AddIcon,
                  },
                ]
              : []),
          ].map((action, index) => (
            <Grid item key={index}>
              <Button
                variant={action.variant ?? 'outlined'}
                sx={{
                  my: {
                    xs: 2,
                    sm: 0,
                  },
                  borderRadius: '2.5rem',
                  ...(action.variant === 'contained' && {
                    bgcolor: action.color ?? color ?? 'primary.main',
                  }),
                  ...(action.variant !== 'contained' && {
                    color: action.color ?? color ?? 'primary.main',
                    borderColor: action.color ?? color ?? 'primary.main',
                  }),
                  // '&:hover': {
                  //   bgcolor: action.color ?? color ?? 'primary.main',
                  // },
                }}
                size='large'
                onClick={action.onClick}
                data-cy={action?.dataCy}
              >
                <Tooltip title={action.tooltip ?? ''}>
                  <Grid component='span' container alignItems='center'>
                    {action.Icon && <action.Icon style={{ marginRight: 8 }} />}
                    {action.label}
                  </Grid>
                </Tooltip>
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}
