import { useState } from 'react'

import { Lottie } from '@crello/react-lottie'
import { Box, Dialog, Grid, Typography, useTheme, Zoom } from '@mui/material'

import giftAnimationData from 'assets/animations/gift.json'
import { ReceivedReward } from 'components'
import { useUser } from 'contexts'
import { RewardType } from 'enums/reward'
import { RewardIntentionStatus } from 'enums/reward'
import { t } from 'i18n'
import { getUniqueColor } from 'types/colors'

export default function SparckDisplayRewards({
  sparck,
  isLoading,
  fetchSparck,
}) {
  const [animateGift, setAnimateGift] = useState(false)
  const [giftAnimationComplete, setGiftAnimationComplete] = useState(false)
  const [receivedRewardDialog, setReceivedRewardDialog] = useState(false)

  const theme = useTheme()
  const { user } = useUser()!

  const giftAnimationConfig = {
    animationData: giftAnimationData,
    loop: false,
    autoplay: animateGift,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      viewBoxSize: '0 150 1150 900',
    },
  }

  const companyReward = sparck.rewards?.find(
    reward =>
      reward.type === RewardType.Company &&
      reward.user_rewarded_id === user?.id,
  )
  const retailerReward = sparck.rewards?.find(
    reward =>
      reward.type === RewardType.Retailer &&
      reward.user_rewarded_id === user?.id,
  )

  const renderReceivedReward = () => (
    <ReceivedReward
      reward={retailerReward}
      fetchSparck={fetchSparck}
      showBankedReason
      showRating
    />
  )

  return (
    <>
      <Grid container justifyContent='center'>
        <Grid item xs={11} sm={10} md={9} lg={7} xl={6}>
          <Box width={1} mb={1}>
            {!isLoading && (
              <Zoom in={!isLoading} timeout={1000}>
                <Box mb={3} width={1}>
                  <Grid container spacing={2} justifyContent='center'>
                    {retailerReward && (
                      <Grid item xs={12} md={6}>
                        {giftAnimationComplete ||
                        retailerReward?.status !==
                          RewardIntentionStatus.Waiting ? (
                          renderReceivedReward()
                        ) : (
                          <div
                            onClick={() => setAnimateGift(true)}
                            style={{ position: 'relative' }}
                          >
                            {!animateGift && (
                              <Typography
                                variant='h4'
                                align='center'
                                color='text.veryLight'
                                fontWeight={600}
                                style={{
                                  position: 'absolute',
                                  left: 0,
                                  right: 0,
                                  margin: '0 auto',
                                  bottom: '60%',
                                }}
                              >
                                {t(
                                  'sparcks.sparckDisplayComponent.revealYourGift',
                                )}
                              </Typography>
                            )}
                            <Lottie
                              config={giftAnimationConfig}
                              playingState={animateGift ? 'playing' : 'stopped'}
                              style={{ cursor: 'pointer' }}
                              lottieEventListeners={[
                                {
                                  name: 'complete',
                                  callback: () => {
                                    setAnimateGift(false)
                                    setGiftAnimationComplete(true)
                                  },
                                },
                                {
                                  name: 'enterFrame',
                                  callback: animation => {
                                    if (
                                      animation.currentTime >= 60 &&
                                      !receivedRewardDialog
                                    ) {
                                      setReceivedRewardDialog(true)
                                    }
                                  },
                                },
                              ]}
                            />
                          </div>
                        )}
                      </Grid>
                    )}
                    {companyReward && (
                      <Grid item xs={12} md={6}>
                        <ReceivedReward
                          reward={{
                            ...companyReward,
                            backgroundColor: getUniqueColor(theme, {
                              isGradient: true,
                              id: companyReward?.id,
                            }),
                          }}
                          fetchSparck={fetchSparck}
                          showBankedReason
                          showRating
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Zoom>
            )}
          </Box>
        </Grid>
      </Grid>

      <Dialog
        open={receivedRewardDialog}
        onClose={() => setReceivedRewardDialog(false)}
        aria-labelledby='received-reward'
      >
        {renderReceivedReward()}
      </Dialog>
    </>
  )
}
