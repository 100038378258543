import { useState } from 'react'
import { get, isEmpty } from 'lodash'
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material'

import { UserAvatar } from 'components'
import { t } from 'i18n'
import { getUserFullName } from 'utils/user'

export default function SurveyOverviewInviteEmployee({
  isDialogOpen,
  setIsDialogOpen,
  inviteEmployees,
  survey,
  isInviteEmployeeLoading,
  uninvitedUsers,
}) {
  const [selectedEmployees, setSelectedEmployees] = useState<any[]>([])

  const handleClose = () => {
    setIsDialogOpen(false)
  }

  return (
    <Dialog
      open={isDialogOpen}
      maxWidth='sm'
      fullWidth
      onClose={handleClose}
      aria-labelledby='invite-employees-dialog-title'
      aria-describedby='invite-employees-dialog-description'
    >
      <DialogTitle id='invite-employees-dialog-title'>
        {t('surveyOverview.employeesInvitation.inviteEmployees')}
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <Typography variant='body2' color='text.secondary' paragraph>
            {t('surveyOverview.employeesInvitation.chooseEmployees')}{' '}
            {get(survey, 'name')}.
          </Typography>
        </Grid>
        <Autocomplete
          multiple
          id='uninvitedUsers'
          value={selectedEmployees}
          onChange={(event: any, newValue: any | null) => {
            setSelectedEmployees(newValue)
          }}
          autoHighlight
          options={uninvitedUsers || []}
          getOptionLabel={employee => getUserFullName(employee)}
          renderOption={(props, employee: any) => (
            <Box
              component='li'
              sx={{ '& > img': { mr: 3, flexShrink: 0 } }}
              {...props}
            >
              <UserAvatar
                userId={get(employee, 'id')}
                name={get(employee, 'first_name')}
                src={get(employee, 'avatar.url')}
                size={24}
              />
              <Box component='span' ml={1}>
                {getUserFullName(employee)}
              </Box>
            </Box>
          )}
          renderInput={params => (
            <TextField
              {...params}
              label={t('surveyOverview.employeesInvitation.textFieldLabel')}
              variant='outlined'
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          {t('surveyOverview.employeesInvitation.cancelButton')}
        </Button>
        <Button
          onClick={() =>
            inviteEmployees({
              survey_id: get(survey, 'id'),
              user_ids: selectedEmployees.map(employee => employee.id),
            })
          }
          color='primary'
          disabled={isInviteEmployeeLoading || isEmpty(selectedEmployees)}
          autoFocus
        >
          {isInviteEmployeeLoading ? (
            <CircularProgress size={24} />
          ) : (
            t('surveyOverview.employeesInvitation.inviteButton')
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
