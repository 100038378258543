import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { isEmpty } from 'lodash'
import { Link } from 'react-router-dom'

import { DashboardComponentProps } from '../DashboardComponent/DashboardComponent'
import { t } from 'i18n'

type DashboardTasksProps = DashboardComponentProps

export default function DashboardCard(props: DashboardTasksProps) {
  const { tasks } = props

  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('md'),
    {
      defaultMatches: true,
    },
  )

  const handleLinkClick = link => {
    if (link.startsWith('http')) {
      window.open(link)
    }
  }

  return (
    <Grid container>
      {isEmpty(tasks) ? (
        <Grid item xs={12}>
          <Typography
            variant='h6'
            color='text.secondary'
            align='left'
            paragraph
          >
            {/* {t('dashboard.noTasks')} */}
          </Typography>
        </Grid>
      ) : (
        tasks!.map(task => (
          <Grid item xs={12} key={task.id}>
            <Box mb={3}>
              <Card elevation={2}>
                <Box px={2} py={1}>
                  <CardContent>
                    <Grid container>
                      <Grid item></Grid>

                      <Grid item xs>
                        <Typography
                          color='text.primary'
                          variant='h6'
                          fontWeight={600}
                          gutterBottom
                        >
                          {task.title}
                        </Typography>
                        <Typography
                          color='text.secondary'
                          variant='body1'
                          paragraph
                        >
                          {task.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Grid
                      container
                      justifyContent={isMobile ? 'center' : 'flex-end'}
                    >
                      <Link
                        to={task.link}
                        onClick={() => handleLinkClick(task.link)}
                      >
                        <Button color='primary' variant='contained'>
                          {t('dashboard.getStarted')}
                        </Button>
                      </Link>
                    </Grid>
                  </CardActions>
                </Box>
              </Card>
            </Box>
          </Grid>
        ))
      )}
    </Grid>
  )
}
