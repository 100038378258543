import { Box, CircularProgress, Grid } from '@mui/material'

import { RecognitionsByEvent } from 'components'

export default function ProfileMyImpact({ userInsights, userIsLoading }) {
  return (
    <Grid container>
      {userIsLoading ? (
        <Box py={5} width={1}>
          <Grid container justifyContent='center'>
            <CircularProgress size={64} />
          </Grid>
        </Box>
      ) : (
        <Grid item xs={12}>
          {userInsights && (
            <Grid container>
              <RecognitionsByEvent
                recognitionsByEvent={userInsights?.recognitions?.by_events}
                showViewModesFilter
                showProgressBar
              />
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  )
}
