import { Fragment, useState } from 'react'
import { get, isEmpty } from 'lodash'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { HelpOutline as HelpIcon } from '@mui/icons-material'
import { Skeleton } from '@mui/material'
import { useIntl } from 'react-intl'

import { UserPermission } from 'enums/user'
import { t } from 'i18n'

const initialNominationState = {
  event_id: null,
  nominee: null,
  message: '',
}

export default function FeedValues(props: any) {
  const {
    company,
    coreValues,
    coreValuesIsLoading,
    recognitions,
    recognitionsIsLoading,
    nominationsPrograms,
    createNominationMutation,
    employees,
    suggestionState,
    setSuggestionState,
    publishSuggestion,
    publishSuggestionStatus,
    user,
  } = props

  const [nominationState, setNominationState] = useState(initialNominationState)
  const [nominationInput, setNominationInput] = useState('')

  const intl = useIntl()
  const theme: any = useTheme()

  const feedValuesSections = [
    {
      id: 'purpose',
      label: t('feed.feedValueLabels.purpose'),
      data: company,
      accessor: 'purpose_statement',
      isLoading: !!isEmpty(company),
      color: theme.palette.primary.main,
      display: true,
    },
    {
      id: 'values',
      label: t('feed.feedValueLabels.values'),
      data: coreValues,
      accessor: 'name',
      descriptionAccessor: 'description',
      isLoading: coreValuesIsLoading,
      color: theme.palette.success.main,
      display:
        coreValues?.length > 0 &&
        (user.permissions ?? []).includes(UserPermission.ViewCoreValues),
    },
    {
      id: 'recognition_areas',
      label: t('feed.feedValueLabels.recognitionAreas'),
      data: recognitions,
      accessor: 'name',
      descriptionAccessor: 'description',
      isLoading: recognitionsIsLoading,
      color: theme.palette.secondary.main,
      display:
        recognitions?.length > 0 &&
        (user.permissions ?? []).includes(UserPermission.ViewRecognitions),
    },
  ]

  const handleChange = (value, attribute) => {
    setNominationState({ ...nominationState, [attribute]: value })
  }

  const renderFeedValueContent = feedValueSection => {
    if (feedValueSection.isLoading) {
      return <Skeleton />
    } else if (
      Array.isArray(feedValueSection.data)
        ? isEmpty(feedValueSection.data)
        : isEmpty(feedValueSection.data[feedValueSection.accessor])
    ) {
      return (
        <Typography variant='body2' paragraph>
          {t('feed.notDefined', {
            value: feedValueSection.label,
          })}
        </Typography>
      )
    } else if (Array.isArray(feedValueSection.data)) {
      return feedValueSection.data.map((item, index) => (
        <Grid
          key={index}
          container
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item xs>
            <Typography variant='body2' paragraph>
              {item[feedValueSection.accessor]}
            </Typography>
          </Grid>

          <Grid item xs='auto'>
            <Typography variant='body2' paragraph>
              {feedValueSection.descriptionAccessor &&
                item[feedValueSection.descriptionAccessor] && (
                  <Tooltip
                    title={item[feedValueSection.descriptionAccessor]}
                    enterTouchDelay={0}
                  >
                    <HelpIcon
                      style={{
                        marginLeft: 16,
                        color: theme.palette.text.veryLight,
                      }}
                    />
                  </Tooltip>
                )}
            </Typography>
          </Grid>
        </Grid>
      ))
    } else {
      return (
        <Typography variant='body2'>
          {feedValueSection.data[feedValueSection.accessor]}
        </Typography>
      )
    }
  }

  const renderSection = section => (
    <Box width={1} marginBottom={2} key={section.id}>
      <Grid item xs={12}>
        <Card square style={{ borderTop: `4px solid ${section.color}` }}>
          <CardContent>
            <Typography gutterBottom variant='h6' paragraph>
              {section.label}
            </Typography>
            {section.content}
          </CardContent>
        </Card>
      </Grid>
    </Box>
  )

  return (
    <Grid container>
      {company?.nominations_enabled &&
        nominationsPrograms?.length > 0 &&
        renderSection({
          id: 'nomination',
          label: 'Nominations are Open!',
          color: theme.palette.primary.light,
          content: (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography
                  variant='body1'
                  color='text.secondary'
                  sx={{ mb: 1 }}
                >
                  Nominate someone who shines in this area
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  value={nominationState.nominee}
                  onChange={(event: any, newValue: any) => {
                    handleChange(newValue, 'nominee')
                  }}
                  inputValue={nominationInput}
                  onInputChange={(event, newInputValue) => {
                    setNominationInput(newInputValue)
                  }}
                  id='nominee'
                  options={employees}
                  getOptionLabel={(option: any) => option.full_name}
                  renderInput={params => (
                    <TextField {...params} label='Nominee' />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  select
                  id='event_id'
                  name='event_id'
                  variant='outlined'
                  value={nominationState.event_id}
                  onChange={event =>
                    handleChange(event?.target.value, 'event_id')
                  }
                  fullWidth
                  size='small'
                  label='Nomination'
                >
                  {(nominationsPrograms ?? [])?.map(event => (
                    <MenuItem key={event.id} value={event.id}>
                      {event.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  multiline
                  id='message'
                  name='message'
                  variant='outlined'
                  value={nominationState.message}
                  onChange={event =>
                    handleChange(event?.target.value, 'message')
                  }
                  fullWidth
                  size='small'
                  label='Tell us why'
                  minRows={3}
                  maxRows={5}
                />
              </Grid>

              <Grid item xs={12}>
                <Box mt={2}>
                  <Grid container justifyContent='flex-end'>
                    <Button
                      variant='contained'
                      color='primary'
                      size='small'
                      disabled={
                        !nominationState.nominee ||
                        !nominationState.event_id ||
                        !nominationState.message
                      }
                      onClick={async () => {
                        await createNominationMutation.mutate({
                          ...nominationState,
                          nominees_ids: [(nominationState.nominee as any)?.id],
                        })

                        setNominationState(initialNominationState)
                      }}
                    >
                      {createNominationMutation.isLoading && (
                        <CircularProgress
                          size={16}
                          sx={{ mr: 1, color: 'text.white' }}
                        />
                      )}{' '}
                      Nominate
                    </Button>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          ),
        })}

      {get(company, 'show_suggestion_box') &&
        (user.permissions ?? []).includes(UserPermission.ViewSuggestionsBox) &&
        renderSection({
          id: 'suggestion_box',
          label:
            get(company, 'suggestion_box_name') ||
            t('feed.feedValueLabels.suggestionBox'),
          color: theme.palette.primary.main,
          content: (
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  multiline
                  id='suggestion_box'
                  name='suggestion_box'
                  value={suggestionState.description}
                  onChange={event =>
                    setSuggestionState({
                      ...suggestionState,
                      description: event.target.value,
                    })
                  }
                  variant='outlined'
                  placeholder={intl.formatMessage({
                    id: 'feed.suggestion.placeholder',
                  })}
                  fullWidth
                  size='small'
                  minRows={3}
                  maxRows={8}
                  helperText={t('feed.suggestion.reviewMessage')}
                />
              </Grid>

              <Grid item xs={12}>
                <Box mt={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='anonymous'
                        checked={suggestionState.anonymous}
                        onChange={event =>
                          setSuggestionState({
                            ...suggestionState,
                            anonymous: event.target.checked,
                          })
                        }
                      />
                    }
                    label={t('feed.suggestion.makePrivate')}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box mt={2}>
                  <Grid container justifyContent='flex-end'>
                    <Button
                      variant='contained'
                      color='primary'
                      size='small'
                      disabled={
                        publishSuggestionStatus.isLoading ||
                        !suggestionState.description
                      }
                      onClick={() => {
                        publishSuggestion(suggestionState)
                      }}
                    >
                      {publishSuggestionStatus.isLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        t('feed.suggestion.submitIdeas')
                      )}
                    </Button>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          ),
        })}

      {feedValuesSections
        .filter(value => value.display)
        .map((feedValueSection, index) => (
          <Grid item xs={12} key={index}>
            {renderSection({
              ...feedValueSection,
              content: (
                <Fragment>{renderFeedValueContent(feedValueSection)}</Fragment>
              ),
            })}
          </Grid>
        ))}
    </Grid>
  )
}
