import { Button, ButtonGroup, lighten, useTheme } from '@mui/material'
import {
  ThumbUpAlt as ThumbUpIcon,
  ThumbUpAltOutlined as ThumbUpOutlinedIcon,
  ThumbDownAlt as ThumbDownIcon,
  ThumbDownAltOutlined as ThumbDownOutlinedIcon,
} from '@mui/icons-material'

export default function ThumbsSelector({ value, onClick }) {
  const theme: any = useTheme()

  return (
    <ButtonGroup disableElevation>
      {[
        {
          id: 'true',
          value: true,
          customColor: theme.palette.primary.main,
          color: 'primary',
          variant: value ? 'contained' : 'outlined',
          Icon: value ? ThumbUpIcon : ThumbUpOutlinedIcon,
        },
        {
          id: 'false',
          value: false,
          color: 'secondary',
          customColor: theme.palette.error.light,
          variant: value === false ? 'contained' : 'outlined',
          Icon: value === false ? ThumbDownIcon : ThumbDownOutlinedIcon,
        },
      ].map((button: any) => (
        <Button
          key={button.id}
          variant={button.variant}
          // color={button.color}
          {...(button.customColor && {
            style: {
              backgroundColor:
                button.variant === 'contained' ? button.customColor : '#FFF',
              borderColor: lighten(button.customColor, 0.6),
              color:
                button.variant === 'contained' ? '#FFF' : button.customColor,
            },
          })}
          onClick={() => onClick(button)}
        >
          {
            <button.Icon
              style={{
                color:
                  button.variant === 'contained'
                    ? '#FFF'
                    : lighten(button.customColor, value !== null ? 0.5 : 0.1),
              }}
            />
          }
        </Button>
      ))}
    </ButtonGroup>
  )
}
