import { Box } from '@mui/material'
import { useIntl } from 'react-intl'

import { DialogTeleport } from 'components'
import { SparckDisplay } from 'pages'
import { getUserFullName } from 'utils/user'

export default function SparckPreview({
  sparckState,
  dialogSparckPreview,
  setDialogSparckPreview,
}) {
  const intl = useIntl()

  return (
    <DialogTeleport
      dialogTitle={intl.formatMessage(
        { id: 'sparckPreview.title' },
        {
          userName:
            (sparckState.individual_to_recognize &&
              getUserFullName(sparckState.individual_to_recognize)) ??
            intl.formatMessage({ id: 'sparckPreview.theSparckedPerson' }),
        },
      )}
      dialogAction={null}
      dialogSize='lg'
      dialogOpen={dialogSparckPreview}
      handleDialogClose={() => setDialogSparckPreview(false)}
      isFullWidth
    >
      <Box pb={8} data-cy='sparck_preview_body'>
        <SparckDisplay sparckPreview={sparckState} />
      </Box>
    </DialogTeleport>
  )
}
