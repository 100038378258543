import { Fragment, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { isEmpty } from 'lodash'

import { initialDialog } from 'constants/dialog'

export default function NominationsManagementConfirmNomination({
  nomination,
  selectedAwarded,
  awardNomineesMutation,
  setSelectedNomineeDialog,
}) {
  const [winners, setWinners] = useState([])

  useEffect(() => setWinners(selectedAwarded), [selectedAwarded])

  if (selectedAwarded?.length === 0) return null

  return (
    <Grid container>
      {selectedAwarded?.map(award => (
        <NomineeMessagesSection
          award={award}
          winners={winners}
          setWinners={setWinners}
        />
      ))}

      <Grid item xs={12}>
        <Grid container justifyContent='flex-end'>
          <Button
            variant='contained'
            color='primary'
            disabled={isEmpty(winners)}
            onClick={async () => {
              await awardNomineesMutation.mutate({
                event_id: nomination.id,
                winners_ids: winners.map((winner: any) => +winner.user?.id),
              })

              setSelectedNomineeDialog(initialDialog)
            }}
          >
            Confirm Award
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

function NomineeMessagesSection({ award, winners, setWinners }) {
  const handleSelectedNomination = (userId, nominationId) => {
    const newWinners = [...winners]
    const newWinner = { ...winners.find(winner => winner.user?.id === userId) }

    newWinner.selected_nomination_id = +nominationId
    newWinners[newWinners.map(winner => winner.user?.id).indexOf(userId)] =
      newWinner

    setWinners([...newWinners])
  }

  if (!award) return null

  const nominations: any = award.nominations

  const selectedNominee = award.user?.full_name
  const selectedNominationId = winners.find(
    winner => winner.user?.id === award.user?.id,
  )?.selected_nomination_id

  return (
    <Grid item xs={12} sx={{ mb: 2 }}>
      <Box
        sx={{
          p: 2.5,
          borderRadius: '0.5em',
          border: (theme: any) =>
            `1.5px solid ${
              selectedNominationId
                ? theme.palette.primary.main
                : theme.palette.text.veryLight
            }`,
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant='h6'
              color='text.primary'
              fontWeight={600}
              sx={{
                pl: 2,
                mb: 1,
                borderLeft: theme => `5px solid ${theme.palette.primary.main}`,
              }}
            >
              You are awarding {selectedNominee}!
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControl>
              <FormLabel id='confirm-nomination' sx={{ mb: 2 }}>
                Select the message below that was the determining factor for
                winning the award (optional)
              </FormLabel>
              <RadioGroup
                aria-labelledby='confirm-nomination'
                name='confirm-nomination'
                value={selectedNominationId ?? ''}
                onChange={event =>
                  handleSelectedNomination(award.user?.id, event.target.value)
                }
              >
                {(selectedNominationId
                  ? nominations.filter(
                      nomination => +nomination.id === +selectedNominationId,
                    )
                  : nominations
                ).map((nomination: any) => (
                  <Fragment key={nomination.id}>
                    <Divider />

                    <FormControlLabel
                      key={nomination.id}
                      value={nomination.id + ''}
                      control={<Radio />}
                      label={nomination.message}
                      sx={{ my: 1 }}
                    />
                  </Fragment>
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>

          {selectedNominationId > 0 && (
            <Grid item xs={12}>
              <Button
                color='primary'
                onClick={() => handleSelectedNomination(award.user?.id, null)}
              >
                Choose another
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  )
}
