// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useEffect, useRef } from 'react'

import { useMutation } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'

import {
  SPARCK_REFRESH_USER_TOKEN,
  SPARCK_USER_TOKEN,
  SPARCK_USER_TOKEN_ID,
  SPARCK_USER_ID,
} from 'constants/app'
import { useCompany, useUser } from 'contexts'
import { API } from 'core/requests'
import { ROUTES_PATHS } from 'core/routes'

export const useLogout = () => {
  const history = useHistory()

  const { setUser } = useUser()!
  const { setCompany } = useCompany()!

  const logoutMutation = useMutation((tokenId: any) =>
    API.delete('logout', null, { pathParams: { token_id: tokenId } }),
  )

  const logout = () => {
    logoutMutation.mutate(localStorage.getItem(SPARCK_USER_TOKEN_ID))
    localStorage.removeItem(SPARCK_USER_TOKEN)
    localStorage.removeItem(SPARCK_USER_TOKEN_ID)
    localStorage.removeItem(SPARCK_USER_ID)
    localStorage.removeItem(SPARCK_REFRESH_USER_TOKEN)
    setUser(null)
    setCompany(null)
    history.push(ROUTES_PATHS.login)
  }

  return { logout }
}

export function useInterval(callback, delay) {
  const savedCallback: any = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export function useURLQuery() {
  return new URLSearchParams(useLocation().search)
}
