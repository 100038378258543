import { useEffect, useMemo } from 'react'

import { Box, Fade, Grid, Paper, Typography } from '@mui/material'
import { get } from 'lodash'

import {
  recognitionGroups,
  recognitionGroupsSettings,
} from 'constants/recognition'
import { t } from 'i18n'
import { getUserFullName } from 'utils/user'

export default function SparckUserPreferences(props) {
  const { fromFeed, sparckState, setSparckState } = props

  const recognitionPreferences = useMemo(() => {
    const recognitionEvents = get(
      sparckState,
      'individual_to_recognize.recognition.onboarding.events',
    )

    if (recognitionEvents) {
      return recognitionEvents.find(
        event => event.recognitions_event_id === sparckState.event_id,
      )
    }

    return null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sparckState.individual_to_recognize, sparckState.event_id])

  const getRecognizedByWho = useMemo(
    () =>
      recognitionPreferences &&
      recognitionGroups.find(
        group =>
          group.value === recognitionPreferences.wants_to_be_recognized_by_who,
      ),
    [recognitionPreferences],
  )

  const getRecognizedInWhatSetting = useMemo(
    () =>
      recognitionPreferences &&
      recognitionGroupsSettings.find(
        setting =>
          setting.value ===
          recognitionPreferences.wants_to_be_recognized_in_what_setting,
      ),
    [recognitionPreferences],
  )

  useEffect(() => {
    if (
      get(recognitionPreferences, 'wants_to_be_recognized') &&
      !recognitionPreferences.wants_to_be_recognized_in_public
    ) {
      setSparckState({ ...sparckState, has_public_access: false })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recognitionPreferences])

  const renderUserPreferences = (): any => {
    const wantsRecognition = get(
      recognitionPreferences,
      'wants_to_be_recognized',
      null,
    )

    const wantsRecognitionInPublic = get(
      recognitionPreferences,
      'wants_to_be_recognized_in_public',
    )

    if (!fromFeed || (fromFeed && wantsRecognition === true)) {
      return (
        <Paper variant='outlined'>
          <Box py={8} px={1} width={1}>
            <Grid container>
              <Grid item xs={12}>
                <Fade
                  in={
                    !!sparckState.individual_to_recognize &&
                    !!sparckState.event_id
                  }
                  timeout={1200}
                >
                  <Typography
                    variant={fromFeed ? 'h6' : 'h5'}
                    color='primary.main'
                    fontWeight={600}
                    align='center'
                    paragraph
                  >
                    {t('sparcks.sparck.makeMeaningfull')}{' '}
                    {getUserFullName(sparckState.individual_to_recognize)}?
                  </Typography>
                </Fade>

                <Grid item xs={12}>
                  <Fade
                    in={
                      !!sparckState.individual_to_recognize &&
                      !!sparckState.event_id
                    }
                    timeout={1200}
                  >
                    <div>
                      <Typography
                        variant={fromFeed ? 'body2' : 'body1'}
                        color='text.secondary'
                        align='center'
                      >
                        <Typography
                          component='span'
                          color='secondary'
                          fontWeight={700}
                        >
                          {getUserFullName(sparckState.individual_to_recognize)}
                        </Typography>{' '}
                        {wantsRecognition ? (
                          <span>
                            {t('sparcks.sparck.prefers')}{' '}
                            <Typography
                              component='span'
                              color='secondary'
                              fontWeight={700}
                            >
                              {wantsRecognitionInPublic
                                ? t('sparcks.sparck.inPublic')
                                : t('sparcks.sparck.inPrivate')}
                            </Typography>
                            {getRecognizedByWho && (
                              <span>
                                {' '}
                                {t('sparcks.sparck.by')}{' '}
                                <Typography
                                  component='span'
                                  color='secondary'
                                  fontWeight={700}
                                >
                                  {getRecognizedByWho.label}
                                </Typography>
                              </span>
                            )}
                            {getRecognizedInWhatSetting && (
                              <span>
                                {' '}
                                {t('sparcks.sparck.in')}{' '}
                                <Typography
                                  component='span'
                                  color='secondary'
                                  fontWeight={700}
                                >
                                  {getRecognizedInWhatSetting.label}
                                </Typography>
                              </span>
                            )}{' '}
                            {t('for')}{' '}
                            <Typography
                              component='span'
                              color='secondary'
                              fontWeight={700}
                            >
                              {get(sparckState, 'event.event_name')}
                            </Typography>
                          </span>
                        ) : wantsRecognition === false ? (
                          <span>
                            {t('sparcks.sparck.prefersNot.prefers')}{' '}
                            <Typography
                              component='span'
                              color='secondary'
                              fontWeight={700}
                            >
                              {t('sparcks.sparck.prefersNot.notToBe')}
                            </Typography>{' '}
                            {t('for')}{' '}
                            <Typography
                              component='span'
                              color='secondary'
                              fontWeight={700}
                            >
                              {get(sparckState, 'event.event_name')}
                            </Typography>
                          </span>
                        ) : (
                          <span>
                            {t('sparcks.sparck.noPreferences')}{' '}
                            <Typography
                              component='span'
                              color='secondary'
                              fontWeight={700}
                            >
                              {get(sparckState, 'event.event_name')}
                            </Typography>
                          </span>
                        )}
                        .
                      </Typography>
                    </div>
                  </Fade>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      )
    }

    return null
  }

  return <>{renderUserPreferences()}</>
}
