import { FavoriteBorder as LoveIcon } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'

import { useCompany } from 'contexts'
import { t } from 'i18n'

export default function Copyright() {
  const { company } = useCompany()!

  return (
    <Grid container>
      <Grid item xs={12} sx={{ mb: 1 }}>
        <Typography variant='body2' align='center' fontSize='0.825rem'>
          <Grid
            container
            component='span'
            justifyContent='center'
            alignItems='center'
          >
            {t('footer.weLove')}{' '}
            <LoveIcon sx={{ color: 'error.main', mx: 1 }} />{' '}
            {company && company?.name}
          </Grid>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant='body2'
          color='text.secondary'
          align='center'
          fontSize='0.7rem'
        >
          {t('footer.slogan')}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant='body2'
          color='text.secondary'
          align='center'
          fontSize='0.7rem'
        >
          Copyright &copy; 2017 - {new Date().getFullYear()}
        </Typography>
      </Grid>
    </Grid>
  )
}
