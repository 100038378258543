import { useState } from 'react'

import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'

import { VirginExperiencesRewardCard } from 'components'
import { RewardProvider, RewardType } from 'enums/reward'
import { t } from 'i18n'
import { formatToUSD } from 'utils/currency'

type Props = {
  reward: any
  onConfirmReward(reward: any): void
  rewardMaximumValue: number
}

export default function SparckConfirmVirginExperiencesRewardDetails({
  reward,
  onConfirmReward,
  rewardMaximumValue,
}: Props) {
  const [selectedRewardOptionId, setSelectedRewardOptionId] =
    useState<any>(null)

  const handleRewardOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSelectedRewardOptionId((event.target as HTMLInputElement).value)
  }

  const handleConfirm = reward => {
    const rewardOption = reward?.product_options?.find(
      option => option.id === selectedRewardOptionId,
    )

    onConfirmReward({
      provider: RewardProvider.VirginExperiences,
      type: RewardType.Retailer,
      amount: rewardOption.price,
      sku: rewardOption.option_sku,
      details: reward,
    })
  }

  if (!reward) return null

  return (
    <Card
      style={{
        position: 'relative',
        overflow: 'inherit',
        borderRadius: 32,
      }}
      elevation={0}
    >
      <VirginExperiencesRewardCard reward={reward} />

      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel id='demo-controlled-radio-buttons-group'>
                {t(
                  'sparcks.sparck.sparckConfirmVirginExperiencesrewardDetails.chooseARewardOptionBellow',
                )}
              </FormLabel>
              <RadioGroup
                aria-labelledby='demo-controlled-radio-buttons-group'
                name='controlled-radio-buttons-group'
                value={selectedRewardOptionId}
                onChange={handleRewardOptionChange}
              >
                {reward?.product_options
                  ?.filter(
                    rewardOption => +rewardOption?.price <= +rewardMaximumValue,
                  )
                  .map(rewardOption => (
                    <FormControlLabel
                      key={rewardOption.id}
                      value={rewardOption.id}
                      control={<Radio />}
                      sx={{ mt: 1 }}
                      label={
                        <Typography variant='body1' color='text.primary'>
                          <Typography
                            component='span'
                            variant='body1'
                            color='success.main'
                            fontWeight={600}
                            sx={{ display: 'inline' }}
                          >
                            {formatToUSD(rewardOption.price)}
                          </Typography>{' '}
                          <Typography
                            component='span'
                            sx={{ display: 'inline' }}
                          >
                            {rewardOption.name}
                          </Typography>
                        </Typography>
                      }
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            {selectedRewardOptionId && (
              <Grid item xs={12} mt={2}>
                <Button
                  variant='contained'
                  color='primary'
                  size='large'
                  fullWidth
                  onClick={() => handleConfirm(reward)}
                >
                  {t(
                    'sparcks.sparck.sparckConfirmVirginExperiencesrewardDetails.confirmSelectedReward',
                  )}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
