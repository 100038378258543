export default {
  beheardReport: {
    reportBeingGenerated: 'Your #BeHeard Report is being generated...',
    title: {
      report: '#BeHeard {type} Report',
      premium: 'Premium',
      free: 'Free',
    },
    shortTermQuestion:
      'The chances of leaving my job voluntarily during the next year are Extremely Low.',
    longTermQuestion:
      'I feel that I will have a long career at this organization.',
    sectionTitles: {
      overview: 'OVERVIEW',
      characteristics: 'CHARACTERISTICS',
      participation: 'PARTICIPATION',
      results: 'RESULTS',
      topFiveRecommendations: 'TOP 5 RECOMMENDATIONS',
      employeeTurnoverForecast: 'EMPLOYEE TURNOVER FORECAST',
      comparisonGraphs: 'COMPARISON GRAPHS',
      fullReport: 'FULL REPORT',
      customQuestions: 'CUSTOM QUESTIONS',
    },
    navigationTitles: {
      overview: 'Overview',
      characteristics: 'Characteristics',
      results: 'Results',
      topFiveRecommendations: 'Top 5 Recommendations',
      employeeTurnoverForecast: 'Turnover',
      comparisonGraphs: 'Comparison Graphs',
      fullReport: 'Full Report',
      customQuestions: 'Custom Questions',
    },
    overview: {
      overviewDescription:
        'Your #BeHeard Premium Report provides much more than an employee engagement score. It provides key findings about the extent your employees feel passionate about their jobs, are committed to your organization, put discretionary effort into their work and are willing to refer. That’s because employee engagement relates directly to productivity, revenue, absenteeism, turnover, customer satisfaction and other organization insights. In addition to your overall employee engagement score, this report provides results on five engagement drivers, forecasts short and long-term turnover, and offers customized recommendations for taking action to increase engagement.',
    },
    characteristics: {
      description:
        'Before you dive into the results, let’s start by looking at the key characteristics of engaged and disengaged employees. This is why taking action to build employee engagement matters!',
      engagedEmployees: { title: 'Engaged Employees' },
      disengagedEmployees: { title: 'Disengaged Employees' },
    },
    participation: {
      overallRate: 'Overall Rate',
      description:
        'Click on each demographic filter below to view specific participation rates.',
      noParticipation: 'Check back, no one has participated yet.',
      demographicsWithLessThanFiveParticipants:
        'Demographics that have less than 5 participants for a particular value will show N/A. (e.g., 4 participants in the IT Department will show N/A)',
    },
    results: {
      description:
        'This section provides a snapshot of your engagement results including the Top 5 Action Areas, the Top 5 Strengths, and the Top 5 Most Important Items to your employees.',
      engagementScore: { engagement: 'Engagement', score: 'Score' },
      engagementDriversHeader: 'Engagement Driver Scores',
      effectiveness: 'EFFECTIVENESS',
    },
    progressBarLegend: {
      legendTitle: 'Effectiveness Score Legend',
      colorMeaning: {
        negative: 'Unfavorable',
        neutral: 'Neutral',
        positive: 'Favorable',
      },
    },
    driverScore: {
      alignment:
        'The extent to which employees believe in and put effort toward this organization’s mission, values and goals.',
      engagement:
        'The extent to which employees feel passionate about their jobs, are committed to our organization, put discretionary effort into their work and are willing to refer.',
      fit: 'The extent to which employees feel they are in the right jobs and have the necessary skills, knowledge and abilities to adequately perform in their roles.',
      growth:
        'The extent to which employees feel supported to advance their career or develop personal skill sets within this organization.',
      team: 'The extent to which this organization promotes a team-based work style (i.e. departments or groups work well together).',
      valuing:
        'The extent to which employees feel valued by this organization.',
      custom_ratings_questions: 'Custom Ratings Questions',
    },
    topFiveText: {
      action: {
        title: 'Top 5 Action Areas',
        description:
          'Your employees ranked the following statements as low in effectiveness and high in importance (i.e. these items have the biggest ROI Gap). You will get the greatest return on investment if you focus your engagement efforts on these five areas (listed in order of priority).',
      },
      strength: {
        title: 'Top 5 Strengths',
        description:
          'Your employees ranked the following statements as high in effectiveness and high in importance (i.e. these items have the smallest ROI Gap). Use this data to promote your employee brand in talent attraction, engagement and retention strategies. These are your greatest strengths!',
      },
      importance: {
        title: 'Top 5 Most Important Items',
        description:
          'Your employees ranked the following five statements as the most important to them.',
      },
      topFiveResultDetails: {
        effectiveness: 'Effectiveness',
        importance: 'Importance',
        previousEffectiveness: 'Previous Effectiveness',
        benchmarkEffectiveness: 'Benchmark Effectiveness',
      },
      topFiveRecommendations: {
        description:
          'These actionable recommendations are based on your Top 5 Action Areas. This is where your efforts to boost engagement will give you the largest return on investment. Time to take action!',
        noRecommendations:
          'There are no Recommendations for the results displayed.',
      },
      tooltip: { roiGap: 'Difference between effectiveness and importance' },
    },
    filterButtons: { filters: 'Filters', download: 'Download' },
    downloadMenu: {
      pdfNewTab: 'Open in new tab (PDF)',
      pdfDownload: 'PDF',
      xlsxDownload: 'Spreadsheet (.xlsx)',
    },
    noData: 'There are no {title} for the results displayed.',
    turnoverOverviewDescription:
      'The likelihood employees will leave your organization within the next twelve months.',
    turnoverForecast: {
      statement: 'STATEMENT',
      atRisk: 'At Risk',
      passive: 'Passive',
      committed: 'Committed',
      employee: 'employee',
      employees: 'employees',
      potentialTurnover: 'Potential Turnover',
      previousTurnover: 'Previous Turnover',
      description:
        "Your short and long-term outlook is predicted using your employees' responses to the turnover statements in the survey.",
      shortTerm: 'Short-Term Outlook',
      longTerm: 'Long-Term Outlook',
    },
    comparisonGraphs: {
      description:
        'Now it’s time to take a closer look at engagement results across the organization. Simply hover over the bar charts to see specific results. Depending on your permission settings, you can use Filters to change the comparison graphs.',
      demographic: 'Demographic',
      legend: {
        engaged: 'Engaged',
        neutrals: 'Neutral',
        disengaged: 'Disengaged',
      },
    },
    fullReport: {
      engagementScore: 'Engagement Score',
      participationRate: 'Participation Rate',
      legend: {
        strengths: 'Strengths',
        recommendedActionAreas: 'Recommended Action Areas',
      },
      tableHeaders: {
        score: 'Score',
        result: 'Result',
        effectiveness: 'Effectiveness',
        importance: 'Importance',
        previousEffectiveness: 'Prev. Effec.',
        benchmark: 'Benchmark',
      },
      tableData: { viewRecommendations: 'View recommendations here' },
      openEndedQuestions: 'Open-Ended Questions',
      noCustomRatingQuestions:
        'No custom ratings questions were used in this survey.',
      noCustomOpenEndedQuestions:
        'No custom open-ended questions were used in this survey.',
      buttonLabels: {
        xlsx: 'Download answers as XLSX',
        pdf: 'Download answers as PDF',
      },
    },
    unlockPremiumReport: 'CLICK HERE TO UNLOCK THE PREMIUM REPORT',
    snackbar: {
      success: {
        surveyAddedToCart: 'Survey added to your cart successfully',
        filters: 'Your filters have been applied!',
      },
    },
  },
  report: {
    upgradeReport: 'Upgrade Report',
    freeReport: 'Free Report',
    premiumReport: 'Premium Report',
    shareReport: 'Share Report',
  },
  survey: {
    status: {
      scheduled: 'Scheduled',
      open: 'Open',
      closed: 'Closed',
      awaitingManualOpening: 'Awaiting Manual Launch',
    },
    snackbar: {
      success: {
        alreadyFinished: 'Ya terminaste esta encuesta',
        finishedSurvey:
          '¡Terminaste la encuesta! Gracias por tomarse el tiempo',
        giftCardRedeemed:
          '¡Tarjeta de regalo canjeada con éxito! Esté atento a un correo electrónico con el enlace de su tarjeta de regalo electrónica.',
      },
    },
  },
  surveyKey: {
    status: {
      surveykey: 'Survey key',
      paragraph1:
        'La encuesta utiliza Caras y Estrellas para la calificación.  Califique su experiencia en el lugar de trabajo y lo importante que es para cada declaración. La satisfacción se basa en las caras y la importancia se basa en las estrellas del 1 al 5. ',
      paragraph2: 'Califique su experiencia sobre el uso de nuestro producto.',
      title1: 'Satisfaction',
      title2: 'Importance',
    },
  },
  surveyinstruction: {
    surveyinstructionkey: 'Survey Instructions',
    surveytitle: ' This survey has a',
    twopart: 'two-part rating process',
    using: 'for each statement using',
    faces: 'Faces',
    and: 'and',
    star: 'Stars.',
    rate: 'Rate how much you',
    agree: 'agree or disagree',
    statement: 'with each statement using the',
    then: 'and then',
    how: 'how',
    important: 'important',
    eachstatement: 'each statement is to you in the workplace using a',
    starrating: '5-star rating',
    scale: 'scale.',
  },
  surveyDialogs: {
    title: 'Encuestas',
    addSurvey: {
      descriptionFirstLine:
        'It’s time to make the survey your own. First, select your launch timeline below to get started. You can make changes before the survey opens and extend the survey before it closes. Click',
      link: 'HERE',
      descriptionSecondLine:
        'for best practices to launch and market the survey.',
    },
    editSurvey: {
      description:
        'Need to edit your survey? No problem. You can do that here. Please make your changes below.',
    },
    extendSurvey: { title: 'Extend Survey', closeDate: 'Close Date' },
    confirmClose: {
      title: 'Close Survey',
      actionLabel: 'Close Survey',
      close: 'close',
    },
    confirmDelete: { title: 'Remove Survey', actionLabel: 'Delete Survey' },
    formLabels: {
      surveyName: 'Survey Name',
      launchDate: 'Launch Date',
      closeDate: 'Close Date',
      allowReward: 'Reward survey participation with a Gift Card',
      allowRewardTooltip:
        'Los empleados podrán seleccionar su tarjeta de regalo directamente desde nuestro sistema después de participar.',
      allowAnswersToBeUpdated:
        'Give employees permission to edit their survey responses',
      allowAnswersToBeUpdatedTooltip:
        'Las ediciones solo se pueden realizar cuando la encuesta está abierta',
      rewardAmount: 'Gift Card Amount',
    },
    customizedQuestions: {
      header: 'Add Custom Questions/Statements',
      description:
        'In addition to the 38 questions in our statistically valid #BeHeard engagement survey, you can add up to five 5-star rating questions and five open ended questions. If you want some ideas for your custom questions, check out examples',
      link: 'HERE',
      statement: 'Statement',
      statementPlaceholder: 'Type your question/statement...',
      fiveStarQuestions: { header: '5-Star Rating Questions' },
      openEndedQuestions: { header: 'Open-Ended Questions' },
    },
  },
  surveyLanding: {
    welcomeToSparck: 'Bienvenido a Sparck!',
    areYouReady:
      '¿Está listo para participar en la encuesta {beheard} y ser escuchado?',
    thankYou: 'Gracias por dejar que se escuche su voz.',
    noActiveSurveys:
      'No hay ninguna encuesta activa que completar en este momento, pero nos alegra que se haya registrado.',
    whatBeHeardSurveyIs:
      'La encuesta #BeHeard es una encuesta anónima diseñada para ser un lugar seguro para compartir sus comentarios sobre el lugar de trabajo. Su organización se preocupa por lo que usted piensa y quiere darle una voz confidencial para hablar libremente, ¡por eso se asoció con Sparck! Solo se compartirán los resultados agregados al final para ayudar a dar forma a las estrategias organizativas que avanzan en función de las áreas que más significan para usted y sus colegas.',
    youAreAwesome:
      '¡Usted es LO MÁXIMO! Gracias por tomarse el tiempo necesario para participar en la encuesta #BeHeard y ser escuchado. Sus comentarios y opiniones no tienen precio.',
    feedbackImportance:
      "Your feedback is really important to us, so we'll send you an email as soon as a new survey is posted!",
    eachItemExplanation:
      'La encuesta solo tomará unos 10 minutos en completarse. Cada elemento tendrá dos partes: primero, calificará en qué medida está de acuerdo o en desacuerdo con cada declaración del lugar de trabajo eligiendo una de las caras. Una vez que lo haya hecho, calificará la importancia de cada afirmación para usted utilizando las estrellas. ¡Mira el ejemplo!',
    iAmReady: 'Estoy listo para empezar',
    updateMyAnswers: 'Revisa mis respuestas!',
    surveyPagination: { previous: 'Anterior', nextPage: 'Siguiente Página' },
    submitSurvey: 'Enviar Encuesta',
    pendingGiftCards: 'Tienes {count} tarjetas de regalo pendientes! 🎉',
    pendingGiftCardLabel: '{rewardValue} para {surveyName}',
    redeem: 'Canjear',
    giftCardRedeemed: '¡Tarjeta de regalo canjeada con éxito!',
    departmentTerm: 'Departamento',
    departmentValue: ' ',
    missionAndValueTerm: 'Misión y valores',
    missionAndValueText:
      'Estamos construyendo algo especial, centrados en ofrecer los mejores resultados para nuestros clientes mediante una integración y automatización inteligentes',
    missionAndValueValue: 'Valores: Sé aliente. Sé tú mismo. Sé Boomi.',
    managerTerm: 'Gerente',
    managerValue: 'Tu líder directo al 8 de junio de 2023',
    seniorLeadershipTeamTerm: 'Equipo de liderazgo sénior ',
    seniorLeadershipTeamValue: 'Equipo de liderazgo ejecutivo',
    surveyInstructionsTitle: 'Survey Instructions',
    surveyInstructionsContent:
      'This survey has a two-part rating process for each statement using Faces  and Stars.  Rate how much you agree or disagree with each statement using the faces and then how important each statement is to you in the workplace using a 5-star rating scale.',
    boomiSurveyLandingContent:
      'La encuesta #BeHeard es una encuesta anónima diseñada para ser un lugar seguro para usted a fin de compartir sus comentarios y opiniones acerca de su experiencia en Boomi.  ¡Boomi se preocupa por su opinión y quiere brindarle una voz confidencial para que hable con libertad, razón por la que estamos trabajando conjuntamente con Sparck! Únicamente se compartirán los resultados totales al final, para ayudar a dar forma a estrategias organizativas de cara al futuro en las áreas de mayor importancia para usted y sus colegas en Boomi. Antes de comenzar, he aquí algunas definiciones para referencia de todos los participantes:',
    boomiSurveyLandingContentTiming:
      'Sólo se necesitan 15 minutos para completar la encuesta. Cada artículo tendrá dos partes: primero, usted calificará en qué medida está de acuerdo o en desacuerdo con cada declaración relacionada con el centro de trabajo eligiendo una de las caras. Una vez que lo haya hecho, calificará cuán importante es cada declaración para usted utilizando las estrellas. ¡Compruebe el ejemplo animado! ',
    topicCards: {
      secure: {
        title: 'Tus Datos estan {keyword}',
        keyword: 'Seguros',
        description:
          'Nos tomamos muy en serio la protección de datos. Sparck utiliza un conjunto de tecnologías y protocolos que previenen las fugas de datos y los ciberataques.',
      },
      anonymous: {
        title: 'Tu Participación es {keyword}',
        keyword: 'Anónima',
        description:
          '¡La confidencialidad es muy importante para nosotros! Su organización o gerente nunca podrá ver sus respuestas individuales. Los datos que proporcionamos son estrictamente anónimos.',
      },
      giftCard: {
        title: 'Esta Encuesta Tiene un {keyword}!',
        keyword: 'Tarjeta de Regalo',
        description:
          '¡Eso es correcto! Como "Gracias" por completar la encuesta, su organización le ofrece una tarjeta de regalo de su elección. Puedes canjearlo al final. ¡Disfrutar!',
      },
      answersUpdate: {
        title: '{keyword} Tus Respuestas',
        keyword: 'Actualize',
        description:
          '¡Una gran noticia! Puede volver para actualizar y editar sus respuestas en cualquier momento mientras la encuesta aún esté abierta. ¡Vuelve a iniciar sesión!',
      },
    },
  },
  surveyOverview: {
    snackbarSuccess: {
      openSurvey: 'The survey is now open!',
      extendedSurvey: 'The survey close date has been extended successfully!',
      closedSurvey: 'The survey has been closed!',
      deletedSurvey: 'The survey has been archived!',
      employeesInvited: 'Employees have been invited successfully!',
      addedToCart: 'Survey added to your cart successfully',
    },
    selectLabel: 'Encuesta',
    minimumNumberOfEmployees:
      'Your organization must have at least 5 employees to create a new survey.',
    whenSurveyCloses:
      'When your survey closes the #BeHeard results will be immediately available.',
    scheduledLaunchSurveyInfo:
      "Your survey isn't live yet.  It will Launch Automatically on {launchDate}. If you would like to launch it manually you can click the Launch Survey button or edit your survey to launch manually.",
    manualLaunchSurveyInfo:
      "Your survey isn't live yet and it is set to Launch Manually. When you are ready to Launch your survey, click the Launch Survey button.",
    newSurvey: 'New Survey',
    launchSurvey: 'Launch Survey',
    editSurvey: 'Edit Survey',
    extendSurvey: 'Extend Survey',
    closeSurvey: 'Close Survey',
    applyUpgrade: 'Apply Upgrade',
    printSurvey: 'Print',
    applyDowngrade: 'Apply Downgrade',
    inviteEmployees: 'Invite Employees',
    deleteSurvey: 'Archive Survey',
    anticipatedTurnover: 'Anticipated Turnover more than 12 Months Away',
    overallParticipation: 'Overall Participation Rate',
    person: 'Person',
    people: 'People',
    employeesInvitation: {
      inviteEmployees: 'Invite Employees',
      chooseEmployees: 'Choose below the employees you want to invite for',
      textFieldLabel: 'Choose one or more employees',
      cancelButton: 'Cancel',
      inviteButton: 'Invite Employees',
    },
    tabs: {
      labels: { general: 'General', sharedReports: 'Informes Compartidos' },
    },
    validations: {
      surveyNameIsRequired: 'Survey Name is required',
      minimumLaunchDate: 'You cannot select a past date',
      maximumLaunchDate: 'Launch Date cannot be after the Close Date',
      launchDateIsRequired: 'Launch Date is required',
      minimumCloseDate: 'Close Date cannot be before Launch Date',
      closeDateIsRequired: 'Close Date is required',
      rewardAmountIsRequired: 'Gift Card amount is required',
    },
  },
  surveyProgress: {
    bulletLabels: {
      createdOn: 'Created on',
      openOn: 'Open on',
      closedOn: 'Closed on',
      willCloseOn: 'Will Close on',
    },
  },
  ratingSection: {
    toWhatExtent:
      '¿En qué medida está de acuerdo o en desacuerdo con esta afirmación?',
    important: 'Esto es importante para mí.',
    ratingLabels: {
      stronglyDisagree: 'Totalmente En Desacuerdo',
      disagree: 'En Desacuerdo',
      neutral: 'Neutro',
      agree: 'De Acuerdo',
      stronglyAgree: 'Totalmente de acuerdo',
      notApplicable: 'No aplica',
    },
    change: 'Cambiar',
    unansweredItem: 'Please respond to every item before continuing.',
    formLabels: {
      textField: 'Your answer',
      placeholder: 'Type anything you want here...',
      helperText:
        'To ensure your confidentiality, do not put any personally identifiable information.',
    },
  },
  manageShareReport: {
    reportSections: {
      labels: {
        comparisonGraph: 'Comparison Graph',
        results: 'Results',
        topFiveActionAreas: 'Top 5 Action Areas',
        topFiveStrengths: 'Top 5 Strengths',
        topFiveMostImportantItems: 'Top 5 Most Important Items',
        recommendations: 'Recommendations',
        turnoverForecast: 'Employee turnover forecast',
        fullReport: 'Full Report',
        customRatingQuestions: 'Custom Ratings Questions',
        customOpenEndedQuestions: 'Custom Open-Ended Questions',
      },
    },
    previewFor: 'Preview for {name}',
    reportWillBeSharedWithRoles: 'This report will be shared with',
    formHandler: {
      title: 'Compartir Informe',
      labels: {
        name: 'Nombre del Informe Compartido',
        expirationDate: '¿Cuánto tiempo estará disponible?',
        whatToSee: '¿Qué pueden ver?',
        limitReport: 'Limitar Informe por Datos Demográphicos',
        whichRoles: 'Which Roles can see this Share Report?',
        roles: 'Roles',
        whichDemographics: 'Which Demographics will receive this Share Report?',
      },
      placeholders: { beheardReport: '#BeHeard ShareReport 001' },
      dialog: {
        title: 'Warning!',
        content: {
          firstLine:
            '¿Está seguro de que desea dar acceso a las respuestas de su empleado?',
          secondLine:
            'Las respuestas contienen información muy sensible. Asegúrese de haber seleccionado los datos demográficos correctos para esta acción.',
        },
        buttonText: '¡Entendí!',
      },
    },
    snackbars: { invitesSent: 'Invitaciones enviadas con éxito' },
  },
  inviteShareReport: {
    dialogTitle: 'Invitaciṍn para Compartir Informe',
    dialogActionLabel: 'Compartir este informe',
    clickToInvite:
      'Se creó su informe compartido, pero debe invitar personas. Haga clic en "Compartir este informe" para enviar correos electrónicos a sus invitados',
  },
  shareReport: {
    title: 'Compartir Informes',
    description:
      'Tú controlas quién tiene acceso a tus informes #BeHeard Premium',
    columnHeaders: {
      sharedReport: 'Nombre del Informe Compartido',
      surveyName: 'Nombre de la Encuesta',
      expires: 'Expira',
    },
    validations: {
      minimumExpirationDate: 'No puede seleccionar una fecha pasada',
      expirationDateIsRequired: 'La fecha de caducidad es obligatoria',
    },
  },
}
