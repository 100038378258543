import {
  Grid,
  LinearProgress,
  useScrollTrigger,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { styled } from '@mui/system'

import { ProgressBar } from 'components'
import { DialogTeleport } from 'components'
import { SurveysComponentProps } from '../SurveysComponent/SurveysComponent'
import { TOPBAR_HEIGHT } from 'constants/app'
import { getSurveyProgressPercentage } from 'utils/survey'
import ReactPlayer from 'react-player';
import beheardSurveyMobileVideo from 'assets/videos/beheard-survey-mobile.mov'


export default function SurveyHeader(props: SurveysComponentProps) {
  const { surveyQuestions, surveyIsLoading, surveyState, preview, isMobile
  } =
    props

  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  })
  const [helpDialogOpen, setHelpDialogOpen] = useState(false)
  const scrolled = isMobile ? false : scrollTrigger

  const StyledHeader = styled(Grid)(() => ({
    background: 'linear-gradient(127.84deg, #bbf7f7 0%, #f3e1fc 100%)',
    boxShadow:
      scrolled && !preview
        ? '0px 2px 4px -1px rgba(0, 0, 0, 0.05), 0px 4px 5px 0px rgba(0, 0, 0, 0.03), 0px 1px 10px 0px rgba(0, 0, 0, 0.03)'
        : 'none',
    padding: '8px',
    paddingTop: '16px',
    transition: 'padding 0.5s',
    position: preview ? 'static' : 'sticky',
    top: `${TOPBAR_HEIGHT}px`,
    zIndex: 996,
  }))

  const StyledTitle = styled(Typography)(() => ({
    fontSize: isMobile
      ? '20px'
      : scrolled && !preview
        ? '1.4rem !important'
        : 'inherit',
    transition: 'font-size 0.5s',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
    overflow: 'hidden',
    marginY: '1rem',
  }))

  return (
    <StyledHeader container justifyContent='center' alignItems='center'>

      <Grid item xs={11} md={10} xl={8}>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item xs={12} sm={8}>
            <DialogTeleport
              dialogOpen={helpDialogOpen}
              dialogSize='sm'
              dialogTitle=''
              handleDialogClose={() => setHelpDialogOpen(false)}
              isFullWidth
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h2> Survey Key {' '}</h2>
                  <ol>
                    <li>
                      <Typography variant='body1' color='text.primary' sx={{ mb: 2 }}>
                        First, you'll{' '}
                        <b>
                          rate to what extent you agree or disagree with each
                          workplace statement
                        </b>{' '}
                        by choosing one of the faces.
                      </Typography>
                    </li>

                    <li>
                      <Typography variant='body1' color='text.primary'>
                        Once you've done that, you'll{' '}
                        <b>rate how important each statement is to you</b> using the
                        stars.
                      </Typography>
                    </li>
                  </ol>

                  <Typography variant='body1' color='text.primary' sx={{ mt: 3 }}>
                    Check the animated example below.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <ReactPlayer
                    url={beheardSurveyMobileVideo}
                    width='100%'
                    height={'100%'}
                    playing
                    loop
                    muted
                    playsInline
                    style={{
                      minHeight: '100%',
                    }}
                  />
                </Grid>
              </Grid>
            </DialogTeleport>
            <Typography style={{ display: 'flex' }}>
              <StyledTitle
                color='text.primary'
                align={isMobile ? 'center' : 'left'}
                variant='h4'
                fontWeight='bold'
              >

                {surveyIsLoading ? (
                  <LinearProgress style={{ maxWidth: 400, height: 24 }} />
                ) : preview && !surveyState.name ? (
                  'Survey Preview '
                ) : (
                  surveyState.name
                )}
              </StyledTitle>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <ProgressBar
              progress={[
                {
                  percentage: getSurveyProgressPercentage(surveyQuestions),
                  color: 'secondary',
                },
              ]}
              showLabel
              withGradient
            />
          </Grid>
        </Grid>
      </Grid>
    </StyledHeader>
  )
}
