import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from '@mui/material'

import { formatToUSD } from 'utils/currency'

type Props = {
  reward: any
  onClick?: (reward: any) => any
  cardSx?: any
}

export default function VirginExperiencesRewardCard({
  reward,
  onClick,
  cardSx,
}: Props) {
  const title = reward?.name
  const description = reward?.meta_description
  const image = reward?.details?.image_url
  const rewardOptionsPrices = reward.product_options?.map(
    option => +option.price,
  )

  const cheapestRewardAmount = Math.min(...(rewardOptionsPrices ?? []))
  const mostExpensiveRewardAmount = Math.max(...(rewardOptionsPrices ?? []))

  return (
    <Card
      key={`${reward.id}`}
      sx={{
        height: 1,
        ...(cardSx && {
          ...cardSx,
        }),
      }}
    >
      <CardActionArea onClick={() => onClick && onClick(reward)}>
        {image && (
          <Grid item xs={12} sx={{ position: 'relative' }}>
            <Box
              component='img'
              src={image}
              alt='title'
              sx={{
                aspectRatio: '1',
                borderRadius: 4,
                width: '100%',
                objectFit: 'cover',
              }}
            />

            <Box
              sx={{
                position: 'absolute',
                bottom: 2,
                left: 0,
                right: 0,
                margin: '0 auto',
                padding: 1,
                bgcolor: 'text.primary',
                color: 'text.white',
              }}
            >
              <Typography variant='body2' fontWeight={600} align='center'>
                {cheapestRewardAmount === mostExpensiveRewardAmount
                  ? formatToUSD(cheapestRewardAmount)
                  : `${formatToUSD(cheapestRewardAmount)} - ${formatToUSD(
                      mostExpensiveRewardAmount,
                    )}`}
              </Typography>
            </Box>
          </Grid>
        )}
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant='body1'
                color='text.primary'
                fontWeight={500}
                align='center'
                sx={{ mb: 1 }}
              >
                {title}
              </Typography>
            </Grid>

            {description && (
              <Grid item xs={12}>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  align='justify'
                >
                  {description}
                </Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
