import { useState, useEffect } from 'react'

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  Grid,
  MenuItem,
} from '@mui/material'
import { isEmpty, sortBy } from 'lodash'
import { useSnackbar } from 'notistack'

import { t } from 'i18n'
import DialogTeleport from './DialogTeleport'
import { initialDialog } from 'constants/dialog'
import { ManageShareReport } from 'pages'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
}

export type DemographicsFiltersType = {
  beheardReport?: any
  demographics: any[]
  demographicsIsLoading: boolean
  filtersState: any
  setFiltersState: any
  hasResetFilters?: boolean
  roles?: any[]
  refetch?: () => any
  allowShareReport?: boolean
}

function filterData(data) {
  let result = { ...data }
  if (data) {
    if (data.name.includes('Below')) {
      const splittedData = data.name?.split(' ')[1]
      result = { ...result, index: parseInt(splittedData) - 1 }
    }
    if (data.name.includes('-')) {
      const splittedData = data.name?.split('-')[0]
      result = { ...result, index: parseInt(splittedData) }
    }
    if (data.name.includes('+')) {
      const splittedData = data.name?.split('-')[0]
      result = { ...result, index: parseInt(splittedData) }
    }
  }

  return result
}

export default function DemographicsFilters({
  beheardReport,
  demographics,
  demographicsIsLoading,
  filtersState,
  setFiltersState,
  hasResetFilters,
  roles,
  refetch,
  // allowShareReport,
}: DemographicsFiltersType) {
  const [resetFilterMode, setResetFilterMode] = useState(false)
  const [dialogShareReport, setDialogShareReport] = useState(initialDialog)

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (isEmpty(filtersState) && resetFilterMode) {
      refetch && refetch()
      setResetFilterMode(false)
      enqueueSnackbar(
        t('components.demographicsFilters.snackbar.success.resetFilters'),
        {
          variant: 'success',
        },
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersState, resetFilterMode])

  const resetFilters = () => {
    setFiltersState({})
    setResetFilterMode(true)
  }

  const categories = demographics

  return isEmpty(demographics) ? (
    <Grid container justifyContent='center' my={5}>
      {t('thereAreNo', {
        name: t('routes.demographics'),
      })}
    </Grid>
  ) : demographicsIsLoading ? (
    <Grid container justifyContent='center'>
      <CircularProgress size={32} />
    </Grid>
  ) : (
    <>
      <Grid container spacing={2}>
        {sortBy(categories, [category => category.name]).map(category => (
          <Grid item xs={12} sm={4} md={4} lg={3} xl={2} key={category.name}>
            <FormControl fullWidth variant='outlined'>
              <InputLabel
                id={category.id + ''}
                style={{ whiteSpace: 'nowrap' }}
              >
                {category.name}
              </InputLabel>
              <Select
                labelId={category.id + ''}
                label={category.name}
                id={category.id + ''}
                multiple
                value={filtersState[category.id] || []}
                onChange={e =>
                  setFiltersState({
                    ...filtersState,
                    [category.id]: e.target.value,
                  })
                }
                variant='outlined'
                MenuProps={MenuProps}
              >
                {category?.demographics_attributes
                  ?.map(res => filterData(res))
                  ?.sort((a, b) => parseFloat(a.index) - parseFloat(b.index))
                  ?.map(todo => {
                    delete todo.index
                    return todo
                  })
                  .map(demographic => (
                    <MenuItem
                      key={demographic.id}
                      value={demographic.id}
                    // disabled={demographic.participants < 5}
                    >
                      {demographic.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        ))}

        {roles && roles?.length > 0 && (
          <Grid item xs={12} sm={4} md={4} lg={3} xl={2} key={'roles'}>
            <FormControl fullWidth variant='outlined'>
              <InputLabel id={'roles'} style={{ whiteSpace: 'nowrap' }}>
                Roles
              </InputLabel>
              <Select
                labelId={'roles'}
                label={'Roles'}
                id={'roles'}
                multiple
                value={filtersState['roles'] || []}
                onChange={e =>
                  setFiltersState({
                    ...filtersState,
                    ['roles']: e.target.value,
                  })
                }
                variant='outlined'
                MenuProps={MenuProps}
              >
                {roles?.map(role => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>

      {(hasResetFilters || refetch) && (
        <Grid container justifyContent='flex-end' spacing={2}>
          {hasResetFilters && (
            <Grid item>
              <Box mt={3}>
                <Button
                  variant='outlined'
                  color='secondary'
                  onClick={resetFilters}
                >
                  {t('components.demographicsFilters.buttons.resetFilters')}
                </Button>
              </Box>
            </Grid>
          )}
          {refetch && (
            <Grid item>
              <Box mt={3}>
                <Button variant='contained' color='primary' onClick={refetch}>
                  {t('components.demographicsFilters.buttons.applyFilters')}
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
      )}

      <DialogTeleport
        dialogOpen={dialogShareReport.isOpen}
        dialogSize='md'
        dialogTitle={'Share this Report'}
        handleDialogClose={() => setDialogShareReport(initialDialog)}
      >
        <ManageShareReport
          dialog={dialogShareReport}
          closeDialog={() => setDialogShareReport(initialDialog)}
          surveyId={beheardReport?.survey?.id}
          isAddMode
        />
      </DialogTeleport>
    </>
  )
}
