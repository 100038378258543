import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'
import { useIntl } from 'react-intl'

export default function SettingsRecognitionFrequency(props) {
  const { companyState, setCompanyState } = props

  const intl = useIntl()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyState({
      ...companyState,
      recognition_frequency: (event.target as HTMLInputElement).value,
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel id='recognitionFrequency-label'>
            {intl.formatMessage({
              id: 'settings.sectionTitles.recognitionFrequency',
            })}
          </FormLabel>
          <RadioGroup
            name='recognitionFrequency'
            value={companyState.recognition_frequency}
            onChange={handleChange}
          >
            {[7, 15, 30].map(frequency => (
              <FormControlLabel
                value={frequency}
                control={<Radio />}
                label={intl.formatMessage(
                  {
                    id: 'settings.sectionControlLabels.recognitionFrequency.atLeastEvery',
                  },
                  { frequency: frequency },
                )}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  )
}
