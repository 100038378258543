import {
  Block as NotApplicableIcon,
  SentimentDissatisfied as NeutralEmoji,
  Mood as StronglyAgreeEmoji,
  MoodBad as StronglyDisagreeEmoji,
  SentimentVeryDissatisfied as DisagreeEmoji,
  SentimentSatisfiedAltSharp as AgreeEmoji,
  Star as StarIcon,
} from '@mui/icons-material'

import { t } from 'i18n'

export const QUESTIONS_PER_PAGE = 10

export const STARS_QUANTITY = 5

export const BEHEARD_RATING_LABELS = [
  t('ratingSection.ratingLabels.notApplicable'),
  t('ratingSection.ratingLabels.stronglyDisagree'),
  t('ratingSection.ratingLabels.disagree'),
  t('ratingSection.ratingLabels.neutral'),
  t('ratingSection.ratingLabels.agree'),
  t('ratingSection.ratingLabels.stronglyAgree'),
]

export const ONBOARDING_RATING_LABELS = [
  t('onboarding.ratingLabels.notApplicable'),
  t('onboarding.ratingLabels.notAtAllImportant'),
  t('onboarding.ratingLabels.slightlyImportant'),
  t('onboarding.ratingLabels.important'),
  t('onboarding.ratingLabels.veryImportant'),
  t('onboarding.ratingLabels.extremelyImportant'),
]

const NOT_APPLICABLE_OBJECT = theme => ({
  id: STARS_QUANTITY + 1,
  value: 'not_applicable',
  description: BEHEARD_RATING_LABELS[0],
  Icon: NotApplicableIcon,
  color: theme.palette.text.veryLight,
})

export const EMOJIS = theme => [
  {
    id: 1,
    value: 1,
    description: BEHEARD_RATING_LABELS[1],
    Icon: StronglyDisagreeEmoji,
    color: theme.palette.primary.main,
  },
  {
    id: 2,
    value: 2,
    description: BEHEARD_RATING_LABELS[2],
    Icon: DisagreeEmoji,
    color: theme.palette.primary.main,
  },
  {
    id: 3,
    value: 3,
    description: BEHEARD_RATING_LABELS[3],
    Icon: NeutralEmoji,
    color: theme.palette.primary.main,
  },
  {
    id: 4,
    value: 4,
    description: BEHEARD_RATING_LABELS[4],
    Icon: AgreeEmoji,
    color: theme.palette.primary.main,
  },
  {
    id: 5,
    value: 5,
    description: BEHEARD_RATING_LABELS[5],
    Icon: StronglyAgreeEmoji,
    color: theme.palette.primary.main,
  },
  NOT_APPLICABLE_OBJECT(theme),
]

export const STARS = theme => [
  {
    id: 1,
    value: 1,
    description: BEHEARD_RATING_LABELS[1],
    Icon: StarIcon,
    color: theme.palette.secondary.light,
  },
  {
    id: 2,
    value: 2,
    description: BEHEARD_RATING_LABELS[2],
    Icon: StarIcon,
    color: theme.palette.secondary.light,
  },
  {
    id: 3,
    value: 3,
    description: BEHEARD_RATING_LABELS[3],
    Icon: StarIcon,
    color: theme.palette.secondary.light,
  },
  {
    id: 4,
    value: 4,
    description: BEHEARD_RATING_LABELS[4],
    Icon: StarIcon,
    color: theme.palette.secondary.light,
  },
  {
    id: 5,
    value: 5,
    description: BEHEARD_RATING_LABELS[5],
    Icon: StarIcon,
    color: theme.palette.secondary.light,
  },
  NOT_APPLICABLE_OBJECT(theme),
]
