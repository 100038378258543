import { Box, Grid } from '@mui/material'

import { SurveyPagination } from '../../components'
import { SurveysComponentProps } from '../SurveysComponent/SurveysComponent'

export default function SurveyFooter(props: SurveysComponentProps) {
  return (
    <Grid container justifyContent='center' alignItems='center'>
      <Grid item xs={12} sm>
        <Grid container justifyContent='center'>
          <Box my={3}>
            <SurveyPagination {...props} />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}
