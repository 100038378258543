import { Grid } from '@mui/material'
import { OnboardingCard } from '..'
import { ProfileAutocompleteQuestions } from 'components'

export default function OnboardingProfileQuestions({
  profileState,
  setProfileState,
  profileQuestions,
  theme,
  onboardingFromLoggedUser,
  isSupervisorView,
}) {
  return (
    <OnboardingCard
      key={'profile_questions'}
      backgroundColor1={theme.palette.primary.dark}
      backgroundColor2={theme.palette.primary.light}
      title={''}
    >
      <Grid container justifyContent='center'>
        <Grid item xs={12} sm={11} md={10} lg={8}>
          <ProfileAutocompleteQuestions
            profileState={profileState}
            setProfileState={setProfileState}
            questions={profileQuestions}
            answersAttributes={'profile_answers_attributes'}
            userCanEdit={onboardingFromLoggedUser || isSupervisorView}
            isFromOnboarding
            showLoadMore
          />
        </Grid>
      </Grid>
    </OnboardingCard>
  )
}
