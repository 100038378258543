import { useState } from 'react'

import { CheckCircle as CheckIcon } from '@mui/icons-material'
import { useIntl } from 'react-intl'
import { useQuery } from 'react-query'

import { SparckTable } from 'components'
import { API } from 'core/requests'
import { getPagination } from 'utils/pagination'

export default function ManageEmployeeSelectEmployees({ onSelect }) {
  const intl = useIntl()

  const [pagination, setPagination] = useState(getPagination('employees'))

  const { data: employees } = useQuery('getEmployees', () => API.get('users'))

  return (
    <SparckTable
      slug='employees'
      title={intl.formatMessage({ id: 'employees.title' })}
      pagination={pagination}
      setPagination={setPagination}
      data={employees}
      columns={[
        {
          title: intl.formatMessage({ id: 'firstName' }),
          field: 'first_name',
        },
        {
          title: intl.formatMessage({ id: 'lastName' }),
          field: 'last_name',
        },
        {
          title: intl.formatMessage({ id: 'employees.fields.email' }),
          field: 'email',
          editable: 'never',
        },
        {
          title: intl.formatMessage({ id: 'employees.fields.title' }),
          field: 'title',
        },
        {
          title: intl.formatMessage({ id: 'department' }),
          field: 'department',
        },
        {
          title: intl.formatMessage({ id: 'employees.fields.location' }),
          field: 'location',
        },
      ]}
      actions={[
        {
          tooltip: () => intl.formatMessage({ id: 'select' }),
          icon: () => <CheckIcon color='primary' fontSize='large' />,
          onClick: (_, data) => onSelect(data),
        },
      ]}
      options={{
        pageSizeOptions: [10, 20, 50],
        selection: true,
        columnsButton: false,
        exportMenu: [],
        searchFieldAlignment: 'right',
        filtering: true,
        showTitle: true,
      }}
    />
  )
}
