import { Box, Card, CardContent, Typography } from '@mui/material'

import { TOPBAR_HEIGHT } from 'constants/app'

export default function BeheardReportCard({
  id,
  title,
  color,
  Component,
}: any) {
  return (
    <Box mb={3} style={{ position: 'relative' }}>
      <div
        id={id}
        style={{ position: 'absolute', top: `-${TOPBAR_HEIGHT + 16}px` }}
      ></div>
      <Card key={id} style={{ borderTop: `20px solid ${color}` }}>
        <CardContent>
          <Box pb={3}>
            <Typography
              variant='h5'
              fontWeight='600'
              style={{ color: `${color}` }}
            >
              {title}
            </Typography>
          </Box>
          {Component}
        </CardContent>
      </Card>
    </Box>
  )
}
