/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AccountBalanceOutlined as BudgetIcon,
  AccountCircle as AccountCircleIcon,
  ApprovalOutlined as CertificationIcon,
  AssignmentIndOutlined as ProfileQuestionsIcon,
  AssignmentOutlined as RolesIcon,
  Attractions as VirginExperiencesIcon,
  Business as CompanyIcon,
  CelebrationOutlined as RecognizeIcon,
  ContactSupportOutlined as OnboardingIcon,
  DashboardOutlined as DashboardIcon,
  FavoriteBorder as CoreValuesIcon,
  GroupsOutlined as DemographicsIcon,
  HomeOutlined as FeedIcon,
  LightbulbOutlined as InsightsIcon,
  LocationCity as CompaniesIcon,
  MilitaryTechOutlined as NominationsManagementIcon,
  PaymentOutlined as InvoicesIcon,
  PeopleOutline as EmployeesIcon,
  PersonOutline as ProfileIcon,
  PlaylistAddCheck as SurveyOverviewIcon,
  Poll as PollIcon,
  RedeemOutlined as RewardsIcon,
  SentimentVerySatisfiedOutlined as RecognitionsIcon,
  SettingsOutlined as SettingsIcon,
  VisibilityOffOutlined as InappropriateContentIcon,
} from '@mui/icons-material'
import { GoMegaphone as SuggestionsIcon } from 'react-icons/go'
import { RiLineChartLine, RiPulseLine as PulseIcon } from 'react-icons/ri'
import { RouteProps } from 'react-router-dom'

import { Application } from 'enums/app'
import { SidebarGroup } from 'enums/sidebar'
import { UserPermission, UserRole } from 'enums/user'
import { BaseLayout, BlankLayout } from 'layouts'
import { t } from 'i18n'
import {
  Approvals,
  BeheardReport,
  Budget,
  Companies,
  Company,
  CompanyInsights,
  CoreValues,
  Dashboard,
  Demographics,
  Employees,
  EmployeesDirectory,
  Feed,
  InappropriateContent,
  Invoices,
  Login,
  ManagePulseSurvey,
  ManagerCertification,
  ManagerCertificationTask,
  ManageSurvey,
  Marketing,
  MFASetupRequired,
  MyRewards,
  MySettings,
  NominationsManagement,
  Notifications,
  Onboarding,
  OnboardingQuestions,
  OrganizationReporting,
  Profile,
  ProfileQuestions,
  PulseSurveyInsights,
  PulseSurveyOverview,
  PulseSurveys,
  Recognitions,
  Rewards,
  Roles,
  Settings,
  ShareReport,
  ShoppingCart,
  Signup,
  Sparck,
  SparckAppreciationAssessment,
  SparckDisplay,
  SparckMemories,
  SuggestionChat,
  Suggestions,
  SurveyLanding,
  SurveyOverview,
  Surveys,
  TakeAction,
  TeamInsights,
  VirginExperiencesSettings,
} from 'pages'

export const ROUTES_PATHS = {
  approvals: '/approvals',
  beheardReport: '/beheard-report',
  beheardShareReport: '/beheard-share-report',
  billing: '/billing',
  budget: '/budget',
  changePassword: '/change-password',
  communityInsights: '/insights/company',
  companies: '/companies',
  company: '/company',
  companyReporting: '/company-reporting',
  coreValues: '/core-values',
  coreValuesPageId: '/core-values/:pageId',
  dashboard: '/',
  demographics: '/demographics',
  employees: '/employees',
  employeesDirectory: '/employees-directory',
  employeesPageId: '/employees/:pageId',
  feed: '/feed',
  feedDisplay: '/feed/:postId',
  hris: '/hris',
  inappropriateContent: '/inappropriate-content',
  invoices: '/invoices',
  invoicesPageId: '/invoices/:pageId',
  login: '/login',
  manageCoreValue: '/manage-core-value',
  manageEmployee: '/manage-employee',
  manageOnboardingQuestion: '/manage-onboarding-question',
  manageProfileQuestion: '/manage-profile-question',
  managePulseSurvey: '/manage-pulse-surveys-survey',
  managerCertification: '/employee-recognition-certification',
  managerCertificationTask: '/employee-recognition-certification/:taskId',
  manageRecognition: '/manage-recognition',
  manageReward: '/manage-reward',
  manageShareReport: '/manage-share-report',
  manageSurvey: '/manage-surveys/survey',
  manageSurveyFrom: '/pages/manageSurvey/components', // changes
  marketing: '/marketing',
  MFASetupRequired: '/mfa-setup',
  myRewards: '/my-rewards',
  mySettings: '/my-settings',
  nominationsManagement: '/nominations-management',
  notifications: '/notifications',
  onboarding: '/onboarding',
  onboardingQuestions: '/onboarding-questions',
  onboardingQuestionsPageId: '/onboarding-questions/:pageId',
  onboardingUserId: '/onboarding/:userId',
  profile: '/profile',
  profilePageId: '/profile/:profileId',
  profileQuestions: '/profile-questions',
  profileQuestionsId: '/profile-questions/:pageId',
  pulseSurveyInsights: '/manage-pulse-surveys/:id/insights',
  pulseSurveyOverview: '/manage-pulse-surveys',
  pulseSurveys: '/pulse-surveys',
  recognitionPrograms: '/recognition-events',
  recognitionProgramsPageId: '/recognition-events/:pageId',
  recoverPassword: '/recover-password',
  report: '/report',
  rewards: '/rewards',
  rewardsPageId: '/rewards/:pageId',
  roles: '/roles',
  settings: '/settings',
  shareReport: '/shared-reports',
  shareReportPageId: '/shared-reports/:pageId',
  shoppingCart: '/shopping-cart',
  signup: '/signup',
  sparck: '/sparck',
  sparckAppreciationAssessment: '/sparck-appreciation-assessment',
  sparckDisplay: '/sparck/:pageId',
  sparckDisplayPublic: '/public/sparck/:pageUUID',
  sparckMemories: '/sparcks',
  suggestionChat: '/suggestion',
  suggestions: '/suggestions',
  survey: '/survey',
  surveyLanding: '/landing/survey',
  surveyOverview: '/manage-surveys',
  takeAction: '/take-action',
  teamInsights: '/insights/team',
  uploadEmployees: '/upload-employees',
  virginExperiencesSettings: '/virgin-experiences-settings',
}

export type SparckRouteType = Omit<RouteProps, 'path'> & {
  id: string
  title: string
  Component: any
  Layout: any
  path: string | string[]
  Icon?: any
  userRoles: UserRole[]
  requiredPermissions: UserPermission[]
  applications: Application[]
  isPrivate: boolean
  mobileOnly?: boolean
  sparckAdministratorOnly?: boolean
  componentProps?: any
  layoutProps?: {
    showSidebar?: boolean
    showTopbar?: boolean
    showGoBackButton?: boolean
    showCopyright?: boolean
    fullWidth?: boolean
    backgroundColor?: string
  }
  children?: keyof typeof ROUTES_PATHS[]
}

export const ROUTES: SparckRouteType[] = [
  {
    id: 'login',
    title: t('routes.login'),
    Component: Login,
    Layout: BlankLayout,
    path: [
      ROUTES_PATHS.login,
      ROUTES_PATHS.recoverPassword,
      ROUTES_PATHS.changePassword,
    ],
    userRoles: [],
    requiredPermissions: [],
    applications: [Application.BeHeard, Application.BeSeen],
    isPrivate: false,
  },
  {
    id: 'signup',
    title: t('routes.signup'),
    Component: Signup,
    Layout: BlankLayout,
    path: ROUTES_PATHS.signup,
    userRoles: [],
    requiredPermissions: [],
    applications: [Application.BeHeard, Application.BeSeen],
    isPrivate: false,
  },
  {
    id: 'dashboard',
    title: t('routes.dashboard'),
    Component: Dashboard,
    Layout: BaseLayout,
    Icon: DashboardIcon,
    path: ROUTES_PATHS.dashboard,
    userRoles: [
      UserRole.Employee,
      UserRole.Manager,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.CEO,
      UserRole.CompanyAdmin,
    ],
    requiredPermissions: [],
    applications: [Application.BeHeard, Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
    },
  },
  {
    id: 'beheardSurvey',
    title: t('routes.beheardSurvey'),
    Component: SurveyLanding,
    Layout: BaseLayout,
    Icon: SurveyOverviewIcon,
    path: ROUTES_PATHS.surveyLanding,
    userRoles: [
      UserRole.Employee,
      UserRole.Manager,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.CEO,
      UserRole.CompanyAdmin,
    ],
    requiredPermissions: [UserPermission.TakeBeheardSurvey],
    applications: [Application.BeHeard],
    isPrivate: true,
    layoutProps: {
      fullWidth: true,
      showTopbar: true,
    },
  },
  {
    id: 'employees',
    title: t('routes.employees'),
    Component: Employees,
    Layout: BaseLayout,
    Icon: EmployeesIcon,
    path: [ROUTES_PATHS.employees, ROUTES_PATHS.employeesPageId],
    userRoles: [UserRole.CompanyAdmin],
    requiredPermissions: [UserPermission.ViewEmployees],
    applications: [Application.BeHeard, Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
    },
  },
  {
    id: 'roles',
    title: t('routes.roles'),
    Component: Roles,
    Layout: BaseLayout,
    Icon: RolesIcon,
    path: [ROUTES_PATHS.roles],
    userRoles: [],
    requiredPermissions: [],
    applications: [Application.BeHeard, Application.BeSeen],
    isPrivate: true,
    sparckAdministratorOnly: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
    },
  },
  {
    id: 'demographics',
    title: t('routes.demographics'),
    Component: Demographics,
    Layout: BaseLayout,
    Icon: DemographicsIcon,
    path: [ROUTES_PATHS.demographics],
    userRoles: [UserRole.CompanyAdmin],
    requiredPermissions: [],
    applications: [Application.BeHeard, Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
    },
  },
  {
    id: 'survey',
    title: t('routes.survey'),
    Component: Surveys,
    Layout: BaseLayout,
    Icon: PollIcon,
    path: ROUTES_PATHS.survey,
    userRoles: [
      UserRole.Employee,
      UserRole.Manager,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.CEO,
      UserRole.CompanyAdmin,
    ],
    requiredPermissions: [UserPermission.TakeBeheardSurvey],
    applications: [Application.BeHeard],
    isPrivate: true,
    layoutProps: {
      fullWidth: true,
      showTopbar: true,
      showGoBackButton: true,
    },
  },
  {
    id: 'companies',
    title: t('routes.companies'),
    Component: Companies,
    Layout: BaseLayout,
    Icon: CompaniesIcon,
    path: ROUTES_PATHS.companies,
    userRoles: [],
    requiredPermissions: [],
    applications: [Application.BeHeard, Application.BeSeen],
    isPrivate: true,
    sparckAdministratorOnly: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
    },
  },
  {
    id: 'company',
    title: t('routes.company'),
    Component: Company,
    Layout: BaseLayout,
    Icon: CompanyIcon,
    path: ROUTES_PATHS.company,
    userRoles: [UserRole.CompanyAdmin],
    requiredPermissions: [],
    applications: [Application.BeHeard, Application.BeSeen],
    isPrivate: true,
    mobileOnly: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
    },
  },
  // {
  //   id: 'invoices',
  //   title: t('routes.invoices'),
  //   Component: Invoices,
  //   Layout: BaseLayout,
  //   Icon: InvoicesIcon,
  //   path: [ROUTES_PATHS.invoices, ROUTES_PATHS.invoicesPageId],
  //   userRoles: [UserRole.CompanyAdmin],
  //   requiredPermissions: [UserPermission.ViewInvoices],
  //   applications: [Application.BeHeard, Application.BeSeen],
  //   isPrivate: true,
  //   layoutProps: {
  //     showTopbar: true,
  //     showSidebar: true,
  //     showCopyright: true,
  //   },
  // },
  {
    id: 'surveyOverview',
    title: t('routes.surveyOverview'),
    Component: SurveyOverview,
    Layout: BaseLayout,
    Icon: SurveyOverviewIcon,
    path: ROUTES_PATHS.surveyOverview,
    userRoles: [UserRole.CompanyAdmin],
    requiredPermissions: [UserPermission.ViewBeheardSurveys],
    applications: [Application.BeHeard],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
    },
  },
  {
    id: 'beheardReport',
    title: t('routes.beheardReport'),
    Component: BeheardReport,
    Layout: BaseLayout,
    Icon: SurveyOverviewIcon,
    path: ROUTES_PATHS.beheardReport,
    userRoles: [
      UserRole.Employee,
      UserRole.Manager,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.CEO,
      UserRole.CompanyAdmin,
    ],
    requiredPermissions: [UserPermission.ViewBeheardSurveys],
    applications: [Application.BeHeard],
    isPrivate: true,
    layoutProps: {
      fullWidth: true,
      showTopbar: true,
      showCopyright: true,
      showGoBackButton: true,
    },
  },
  {
    id: 'beheardShareReport',
    title: 'Beheard Share Report',
    Component: BeheardReport,
    Layout: BaseLayout,
    Icon: SurveyOverviewIcon,
    path: [ROUTES_PATHS.beheardShareReport],
    userRoles: [
      UserRole.Employee,
      UserRole.Manager,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.CEO,
      UserRole.CompanyAdmin,
    ],
    requiredPermissions: [],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      fullWidth: true,
      showTopbar: true,
      showCopyright: true,
      showGoBackButton: true,
    },
  },
  {
    id: 'marketing',
    title: t('routes.marketing'),
    Component: Marketing,
    Layout: BaseLayout,
    Icon: EmployeesIcon,
    path: ROUTES_PATHS.marketing,
    userRoles: [UserRole.CompanyAdmin],
    requiredPermissions: [],
    applications: [Application.BeHeard, Application.BeSeen],
    isPrivate: false,
    layoutProps: {
      showSidebar: true,
      fullWidth: true,
      showTopbar: true,
      showGoBackButton: true,
    },
  },
  {
    id: 'manageSurvey',
    title: t('routes.manageSurvey'),
    Component: ManageSurvey,
    Layout: BaseLayout,
    Icon: EmployeesIcon,
    path: ROUTES_PATHS.manageSurvey,
    userRoles: [UserRole.CompanyAdmin],
    requiredPermissions: [UserPermission.ViewBeheardSurveys],
    applications: [Application.BeHeard],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
    },
  },
  {
    id: 'shareReport',
    title: t('routes.shareReport'),
    Component: ShareReport,
    Layout: BaseLayout,
    Icon: EmployeesIcon,
    path: [ROUTES_PATHS.shareReport, ROUTES_PATHS.shareReportPageId],
    userRoles: [UserRole.CompanyAdmin],
    requiredPermissions: [UserPermission.ViewBeheardSurveys],
    applications: [Application.BeHeard],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
    },
  },
  {
    id: 'shoppingCart',
    title: t('routes.shoppingCart'),
    Component: ShoppingCart,
    Layout: BaseLayout,
    Icon: '',
    path: ROUTES_PATHS.shoppingCart,
    isPrivate: true,
    applications: [Application.BeHeard],
    requiredPermissions: [UserPermission.ViewShoppingCarts],
    userRoles: [UserRole.CompanyAdmin],
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
    },
  },
  // {
  //   id: 'rewards',
  //   title: t('routes.rewards'),
  //   Component: Rewards,
  //   Layout: BaseLayout,
  //   Icon: RewardsIcon,
  //   path: [ROUTES_PATHS.rewards, ROUTES_PATHS.rewardsPageId],
  //   userRoles: [UserRole.CompanyAdmin],
  //   requiredPermissions: [UserPermission.ViewRewards],
  //   applications: [Application.BeSeen],
  //   isPrivate: true,
  //   layoutProps: {
  //     showSidebar: true,
  //     showTopbar: true,
  //     showCopyright: true,
  //   },
  // },
  {
    id: 'profileQuestions',
    title: t('routes.profileQuestions'),
    Component: ProfileQuestions,
    Layout: BaseLayout,
    Icon: ProfileQuestionsIcon,
    path: [ROUTES_PATHS.profileQuestions, ROUTES_PATHS.profileQuestionsId],
    userRoles: [UserRole.CompanyAdmin],
    requiredPermissions: [UserPermission.ViewProfileQuestions],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
    },
  },
  {
    id: 'coreValues',
    title: t('routes.coreValues'),
    Component: CoreValues,
    Layout: BaseLayout,
    Icon: CoreValuesIcon,
    path: [ROUTES_PATHS.coreValues, ROUTES_PATHS.coreValuesPageId],
    userRoles: [UserRole.CompanyAdmin],
    requiredPermissions: [UserPermission.ViewCoreValues],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
    },
  },
  {
    id: 'recognitionPrograms',
    title: t('routes.recognitionPrograms'),
    Component: Recognitions,
    Layout: BaseLayout,
    Icon: RecognitionsIcon,
    path: [
      ROUTES_PATHS.recognitionPrograms,
      ROUTES_PATHS.recognitionProgramsPageId,
    ],
    userRoles: [UserRole.CompanyAdmin],
    requiredPermissions: [UserPermission.ViewRecognitions],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
    },
  },
  {
    id: 'pulseSurveyOverview',
    title: t('routes.pulseSurveyOverview'),
    Component: PulseSurveyOverview,
    Layout: BaseLayout,
    Icon: PulseIcon,
    path: `${ROUTES_PATHS.pulseSurveyOverview}/:id?`,
    userRoles: [UserRole.CompanyAdmin],
    requiredPermissions: [UserPermission.ViewPulseSurveys],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
    },
  },
  {
    id: 'pulseSurveyInsights',
    title: t('routes.pulseSurveyInsights'),
    Component: PulseSurveyInsights,
    Layout: BaseLayout,
    Icon: PulseIcon,
    path: `${ROUTES_PATHS.pulseSurveyInsights}`,
    userRoles: [UserRole.CompanyAdmin],
    requiredPermissions: [UserPermission.ViewPulseSurveys],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
    },
  },
  {
    id: 'managePulseSurvey',
    title: t('routes.managePulseSurvey'),
    Component: ManagePulseSurvey,
    Layout: BaseLayout,
    Icon: EmployeesIcon,
    path: ROUTES_PATHS.managePulseSurvey,
    userRoles: [UserRole.CompanyAdmin],
    requiredPermissions: [UserPermission.ViewPulseSurveys],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
    },
  },
  {
    id: 'managerCertification',
    title: t('routes.managerCertification'),
    Component: ManagerCertification,
    Layout: BaseLayout,
    Icon: CertificationIcon,
    path: [ROUTES_PATHS.managerCertification],
    userRoles: [
      UserRole.CompanyAdmin,
      UserRole.CEO,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.Manager,
    ],
    requiredPermissions: [],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
      showGoBackButton: true,
    },
  },
  {
    id: 'managerCertificationTask',
    title: t('routes.managerCertificationTask'),
    Component: ManagerCertificationTask,
    Layout: BaseLayout,
    path: [ROUTES_PATHS.managerCertificationTask],
    userRoles: [
      UserRole.CompanyAdmin,
      UserRole.CEO,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.Manager,
    ],
    requiredPermissions: [],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showTopbar: true,
      showCopyright: true,
      showGoBackButton: true,
    },
  },
  {
    id: 'settings',
    title: t('routes.settings'),
    Component: Settings,
    Layout: BaseLayout,
    Icon: SettingsIcon,
    path: [ROUTES_PATHS.settings],
    userRoles: [UserRole.CompanyAdmin],
    requiredPermissions: [UserPermission.ViewSettings],
    applications: [Application.BeHeard, Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
    },
  },
  {
    id: 'profile',
    title: t('routes.profile'),
    Component: Profile,
    Layout: BaseLayout,
    Icon: ProfileIcon,
    path: [ROUTES_PATHS.profile, ROUTES_PATHS.profilePageId],
    userRoles: [
      UserRole.Employee,
      UserRole.Manager,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.CEO,
      UserRole.CompanyAdmin,
    ],
    requiredPermissions: [],
    applications: [Application.BeHeard, Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      fullWidth: true,
      showTopbar: true,
      showCopyright: true,
      showGoBackButton: true,
    },
  },
  {
    id: 'MFASetupRequired',
    title: t('routes.MFASetupRequired'),
    Component: MFASetupRequired,
    Layout: BlankLayout,
    Icon: AccountCircleIcon,
    path: [ROUTES_PATHS.MFASetupRequired],
    userRoles: [
      UserRole.Employee,
      UserRole.Manager,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.CEO,
      UserRole.CompanyAdmin,
    ],
    requiredPermissions: [],
    applications: [Application.BeHeard, Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      fullWidth: true,
    },
  },
  {
    id: 'onboarding',
    title: t('routes.onboarding'),
    Component: Onboarding,
    Layout: BlankLayout,
    Icon: AccountCircleIcon,
    path: [ROUTES_PATHS.onboarding, ROUTES_PATHS.onboardingUserId],
    userRoles: [
      UserRole.Employee,
      UserRole.Manager,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.CEO,
      UserRole.CompanyAdmin,
    ],
    requiredPermissions: [],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      fullWidth: true,
    },
  },
  {
    id: 'onboardingQuestions',
    title: t('routes.onboardingQuestions'),
    Component: OnboardingQuestions,
    Layout: BaseLayout,
    Icon: OnboardingIcon,
    path: [
      ROUTES_PATHS.onboardingQuestions,
      ROUTES_PATHS.onboardingQuestionsPageId,
    ],
    userRoles: [UserRole.CompanyAdmin],
    requiredPermissions: [UserPermission.ViewOnboardingQuestions],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
    },
  },
  {
    id: 'survey',
    title: t('routes.manageSurvey'),
    Component: ManageSurvey,
    Layout: BaseLayout,
    Icon: FeedIcon,
    path: [ROUTES_PATHS.manageSurveyFrom, ROUTES_PATHS.manageSurveyFrom],
    userRoles: [
      UserRole.Employee,
      UserRole.Manager,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.CEO,
      UserRole.CompanyAdmin,
    ],
    requiredPermissions: [],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showTopbar: true,
      showSidebar: true,
      showCopyright: true,
    },
  },
  {
    id: 'feed',
    title: t('routes.feed'),
    Component: Feed,
    Layout: BaseLayout,
    Icon: FeedIcon,
    path: [ROUTES_PATHS.feed, ROUTES_PATHS.feedDisplay],
    userRoles: [
      UserRole.Employee,
      UserRole.Manager,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.CEO,
      UserRole.CompanyAdmin,
    ],
    requiredPermissions: [],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showTopbar: true,
      showSidebar: true,
      showCopyright: true,
    },
  },
  {
    id: 'people',
    title: t('routes.people'),
    Component: EmployeesDirectory,
    Layout: BaseLayout,
    Icon: EmployeesIcon,
    path: [ROUTES_PATHS.employeesDirectory],
    userRoles: [
      UserRole.Employee,
      UserRole.Manager,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.CEO,
      UserRole.CompanyAdmin,
    ],
    requiredPermissions: [],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
    },
  },
  {
    id: 'pulseSurveys',
    title: t('routes.pulseSurveys'),
    Component: PulseSurveys,
    Layout: BaseLayout,
    Icon: PulseIcon,
    path: ROUTES_PATHS.pulseSurveys,
    userRoles: [
      UserRole.Employee,
      UserRole.Manager,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.CEO,
      UserRole.CompanyAdmin,
    ],
    requiredPermissions: [UserPermission.TakePulseSurvey],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      fullWidth: true,
      showTopbar: true,
      showCopyright: true,
      showGoBackButton: true,
    },
  },
  {
    id: 'recognize',
    title: t('routes.recognize'),
    Component: Sparck,
    Layout: BaseLayout,
    Icon: RecognizeIcon,
    path: ROUTES_PATHS.sparck,
    userRoles: [
      UserRole.Employee,
      UserRole.Manager,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.CEO,
      UserRole.CompanyAdmin,
    ],
    requiredPermissions: [UserPermission.CreateRecognition],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      fullWidth: true,
      showTopbar: true,
      showGoBackButton: true,
    },
  },
  {
    id: 'sparckDisplay',
    title: t('routes.sparckDisplay'),
    Component: SparckDisplay,
    Layout: BaseLayout,
    path: ROUTES_PATHS.sparckDisplay,
    userRoles: [
      UserRole.Employee,
      UserRole.Manager,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.CEO,
      UserRole.CompanyAdmin,
    ],
    requiredPermissions: [],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      fullWidth: true,
      showTopbar: true,
      showCopyright: true,
      showGoBackButton: true,
    },
  },
  {
    id: 'sparckDisplay',
    title: t('routes.sparckDisplay'),
    Component: SparckDisplay,
    Layout: BlankLayout,
    path: ROUTES_PATHS.sparckDisplayPublic,
    userRoles: [],
    requiredPermissions: [],
    applications: [Application.BeHeard, Application.BeSeen],
    isPrivate: false,
    layoutProps: {
      fullWidth: true,
      backgroundColor: '#FFF',
    },
  },
  {
    id: 'sparckMemories',
    title: t('routes.sparckMemories'),
    Component: SparckMemories,
    Layout: BaseLayout,
    path: ROUTES_PATHS.sparckMemories,
    userRoles: [
      UserRole.Employee,
      UserRole.Manager,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.CEO,
      UserRole.CompanyAdmin,
    ],
    requiredPermissions: [],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      fullWidth: true,
      showTopbar: true,
      showCopyright: true,
      showGoBackButton: true,
    },
  },
  {
    id: 'notifications',
    title: t('routes.notifications'),
    Component: Notifications,
    Layout: BaseLayout,
    path: ROUTES_PATHS.notifications,
    userRoles: [
      UserRole.Employee,
      UserRole.Manager,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.CEO,
      UserRole.CompanyAdmin,
    ],
    requiredPermissions: [],
    applications: [Application.BeHeard, Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showTopbar: true,
      showCopyright: true,
      showSidebar: true,
    },
  },
  {
    id: 'companyInsights',
    title: t('routes.companyInsights'),
    Component: CompanyInsights,
    Layout: BaseLayout,
    path: ROUTES_PATHS.communityInsights,
    userRoles: [
      UserRole.Manager,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.CEO,
      UserRole.CompanyAdmin,
    ],
    requiredPermissions: [UserPermission.ViewCompanyInsights],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
      showGoBackButton: true,
    },
  },
  {
    id: 'teamInsights',
    title: t('routes.teamInsights'),
    Component: TeamInsights,
    Layout: BaseLayout,
    Icon: InsightsIcon,
    path: ROUTES_PATHS.teamInsights,
    userRoles: [
      UserRole.Manager,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.CEO,
      UserRole.CompanyAdmin,
    ],
    requiredPermissions: [UserPermission.ViewTeamsInsights],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
      showGoBackButton: true,
    },
  },
  {
    id: 'budget',
    title: t('routes.budget'),
    Component: Budget,
    Layout: BaseLayout,
    Icon: BudgetIcon,
    path: [ROUTES_PATHS.budget],
    userRoles: [UserRole.CompanyAdmin],
    requiredPermissions: [UserPermission.ViewBudget],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
    },
  },
  {
    id: 'takeAction',
    title: t('routes.takeAction'),
    Component: TakeAction,
    Layout: BaseLayout,
    path: [ROUTES_PATHS.takeAction],
    userRoles: [
      UserRole.CompanyAdmin,
      UserRole.CEO,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.Manager,
      UserRole.Employee,
    ],
    requiredPermissions: [UserPermission.ViewTakeActions],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
      showGoBackButton: true,
    },
  },
  {
    id: 'approvals',
    title: t('routes.approvals'),
    Component: Approvals,
    Layout: BaseLayout,
    Icon: SuggestionsIcon,
    path: [ROUTES_PATHS.approvals],
    userRoles: [
      UserRole.CompanyAdmin,
      UserRole.CEO,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.Manager,
    ],
    // requiredPermissions: [UserPermission.ViewApprovals],
    requiredPermissions: [],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
    },
  },
  {
    id: 'inappropriateContent',
    title: t('routes.inappropriateContent'),
    Component: InappropriateContent,
    Layout: BaseLayout,
    Icon: InappropriateContentIcon,
    path: [ROUTES_PATHS.inappropriateContent],
    userRoles: [UserRole.CompanyAdmin],
    requiredPermissions: [],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
    },
  },
  {
    id: 'nominationsManagement',
    title: 'Nominations',
    Component: NominationsManagement,
    Layout: BaseLayout,
    Icon: NominationsManagementIcon,
    path: [ROUTES_PATHS.nominationsManagement],
    userRoles: [UserRole.CompanyAdmin],
    requiredPermissions: [],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
    },
  },
  {
    id: 'suggestions',
    title: t('routes.suggestions'),
    Component: Suggestions,
    Layout: BaseLayout,
    Icon: SuggestionsIcon,
    path: [ROUTES_PATHS.suggestions],
    userRoles: [UserRole.CompanyAdmin],
    requiredPermissions: [UserPermission.ViewSuggestions],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
    },
  },
  {
    id: 'suggestionChat',
    title: t('routes.suggestionChat'),
    Component: SuggestionChat,
    Layout: BaseLayout,
    Icon: SuggestionsIcon,
    path: [`${ROUTES_PATHS.suggestionChat}/:id`],
    userRoles: [
      UserRole.Employee,
      UserRole.Manager,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.CEO,
      UserRole.CompanyAdmin,
    ],
    requiredPermissions: [],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
    },
  },
  {
    id: 'companyReporting',
    title: t('routes.companyReporting'),
    Component: OrganizationReporting,
    Layout: BaseLayout,
    Icon: RiLineChartLine,
    path: ROUTES_PATHS.companyReporting,
    userRoles: [UserRole.CompanyAdmin],
    requiredPermissions: [UserPermission.ViewCompanyReporting],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showSidebar: true,
      showTopbar: true,
      showCopyright: true,
    },
  },
  {
    id: 'myRewards',
    title: t('routes.myRewards'),
    Component: MyRewards,
    Layout: BaseLayout,
    Icon: AccountCircleIcon,
    path: [ROUTES_PATHS.myRewards],
    userRoles: [
      UserRole.Employee,
      UserRole.Manager,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.CEO,
      UserRole.CompanyAdmin,
    ],
    requiredPermissions: [],
    applications: [Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showTopbar: true,
      showCopyright: true,
      showGoBackButton: true,
    },
  },
  {
    id: 'mySettings',
    title: t('routes.mySettings'),
    Component: MySettings,
    Layout: BaseLayout,
    path: [ROUTES_PATHS.mySettings],
    userRoles: [
      UserRole.Employee,
      UserRole.Manager,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.CEO,
      UserRole.CompanyAdmin,
    ],
    requiredPermissions: [],
    applications: [Application.BeHeard, Application.BeSeen],
    isPrivate: true,
    layoutProps: {
      showTopbar: true,
      showCopyright: true,
      showGoBackButton: true,
    },
  },
  {
    id: 'virginExperiencesSettings',
    title: t('routes.virginExperiencesSettings'),
    Component: VirginExperiencesSettings,
    Layout: BaseLayout,
    Icon: VirginExperiencesIcon,
    path: [ROUTES_PATHS.virginExperiencesSettings],
    userRoles: [UserRole.CompanyAdmin],
    requiredPermissions: [],
    applications: [Application.BeHeard, Application.BeSeen],
    isPrivate: true,
    sparckAdministratorOnly: true,
    layoutProps: {
      showTopbar: true,
      showSidebar: true,
      showCopyright: true,
    },
  },
  {
    id: 'sparckAppreciationAssessment',
    title: t('routes.sparckAppreciationAssessment'),
    Component: SparckAppreciationAssessment,
    Layout: BlankLayout,
    Icon: AccountCircleIcon,
    path: [ROUTES_PATHS.sparckAppreciationAssessment],
    userRoles: [],
    requiredPermissions: [],
    applications: [],
    isPrivate: false,
    layoutProps: {
      fullWidth: true,
    },
  },
]

const getRoute = (routeId): SparckRouteType | any => {
  const route: any = ROUTES.find(
    (route: SparckRouteType) => route.id === routeId,
  )

  return {
    ...route,
    path: Array.isArray(route.path) ? route.path[0] : route.path,
  }
}

export const TOPBAR_ROUTES = [
  getRoute('feed'),
  getRoute('profile'),
  {
    id: 'surveys',
    title: t('routes.surveys'),
    userRoles: [
      UserRole.Employee,
      UserRole.Manager,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.CEO,
      UserRole.CompanyAdmin,
    ],
    applications: [Application.BeSeen, Application.BeHeard],
    requiredPermissions: [],
    children: [getRoute('beheardSurvey'), getRoute('pulseSurveys')],
  },
  getRoute('recognize'),
  getRoute('people'),
  {
    id: 'insights',
    title: t('routes.insights'),
    userRoles: [
      UserRole.Manager,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.CEO,
      UserRole.CompanyAdmin,
    ],
    applications: [Application.BeSeen],
    requiredPermissions: [],
    children: [getRoute('companyInsights'), getRoute('teamInsights')],
  },
  getRoute('takeAction'),
  getRoute('company'),
]

export const TOPBAR_PROFILE_ROUTES = [
  getRoute('dashboard'),
  getRoute('myRewards'),
  {
    id: 'profile',
    title: t('routes.profile'),
    userRoles: [
      UserRole.Employee,
      UserRole.Manager,
      UserRole.Executive,
      UserRole.SecondAdmin,
      UserRole.CEO,
      UserRole.CompanyAdmin,
    ],
    applications: [Application.BeSeen],
    requiredPermissions: [],
    children: [],
  },
  // getRoute('profile'),
  getRoute('sparckMemories'),
  getRoute('mySettings'),
]

export const SIDEBAR_ROUTES = (user, company) => ({
  [SidebarGroup.Sparck]: [
    getRoute('companies'),
    getRoute('roles'),
    getRoute('virginExperiencesSettings'),
  ],
  [SidebarGroup.General]: [
    ...(company?.beseen_application
      ? [getRoute('profile')]
      : []),
    // getRoute('profile'),
    getRoute('dashboard'),
    getRoute('feed'),
    getRoute('recognize'),
    ...(user?.has_manager_certification_invite
      ? [getRoute('managerCertification')]
      : []),
    getRoute('people'),
    getRoute('teamInsights'),
    getRoute('beheardSurvey'),
    getRoute('pulseSurveys'),
  ],
  [SidebarGroup.Management]: [
    getRoute('company'),
    getRoute('employees'),
    getRoute('demographics'),
    getRoute('coreValues'),
    // getRoute('managerCertification'),
    getRoute('surveyOverview'),
    getRoute('pulseSurveyOverview'),
    getRoute('recognitionPrograms'),
    // getRoute('rewards'),
    ...(company?.enable_rewards_store
      ? [getRoute('budget')]
      : []),
    // getRoute('budget'),
    ...(company?.nominations_enabled
      ? [getRoute('nominationsManagement')]
      : []),
    getRoute('companyReporting'),
    getRoute('onboardingQuestions'),
    getRoute('profileQuestions'),
  ],
  [SidebarGroup.Content]: [
    getRoute('inappropriateContent'),
    getRoute('suggestions'),
  ],
  [SidebarGroup.Others]: [
    // getRoute('invoices'),
    getRoute('settings'),
  ],
})
