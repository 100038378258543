import { Box, Grid, Typography } from '@mui/material'

import { t } from 'i18n'

type Props = {
  isMobile: boolean
  topic: {
    id: string
    description: string
    keyword: string
    image: any
  }
}

export default function SurveyLandingTopicCard({ topic, isMobile }: Props) {
  const { id, description, keyword, image } = topic

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box component='img' src={image} alt={keyword} sx={{ width: '100%' }} />
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Typography
          align='center'
          variant='h5'
          color='text.primary'
          sx={{ height: isMobile ? 'inherit' : 64 }}
          paragraph={isMobile}
        >
          {t(`surveyLanding.topicCards.${id}.title`, {
            keyword: (
              <span style={{ fontWeight: 600 }}>
                {t(`surveyLanding.topicCards.${id}.keyword`)}
              </span>
            ),
          })}
        </Typography>

        <Typography align='justify' variant='body1' color='text.secondary'>
          {description}
        </Typography>
      </Grid>
    </Grid>
  )
}
