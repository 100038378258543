import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { t } from 'i18n'

export default function ManagerCertificationFormBuilder(props) {
  const { formik, questions, onSubmit, sectionId } = props

  return (
    <Grid container component='form' onSubmit={onSubmit}>
      {questions.map(question => (
        <>
          {question.description && (
            <Grid item xs={12}>
              <Typography variant='body1' color='text.secondary' paragraph>
                {question.description}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} mb={2} key={question.id}>
            <TextField
              id={question.id}
              name={question.id}
              type='text'
              label={question.label}
              variant='outlined'
              data-cy={question.id}
              value={formik.values[question.id]}
              required={!!formik.errors[question.id]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched[question.id] &&
                Boolean(formik.errors[question.id])
              }
              helperText={
                formik.touched[question.id] && formik.errors[question.id]
              }
              fullWidth
            />
          </Grid>
        </>
      ))}

      {sectionId === 1 && (
        <Grid item xs={12} mt={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {t(
                'managerCertification.managerCertificationFormBuilder.termsOfCommunicationFirstParagraph',
              )}
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='terms_of_communication_accepted'
                    data-cy='terms_of_communication_accepted'
                    checked={
                      formik.values.terms_of_communication_accepted ?? false
                    }
                    onChange={() =>
                      formik.setFieldValue(
                        'terms_of_communication_accepted',
                        !formik.values.terms_of_communication_accepted,
                      )
                    }
                  />
                }
                label={t(
                  'managerCertification.managerCertificationFormBuilder.agreeToReceiveCommunications',
                )}
              />
            </Grid>

            <Grid item xs={12}>
              {t(
                'managerCertification.managerCertificationFormBuilder.termsOfCommunicationMiddleParagraph',
              )}
            </Grid>

            <Grid item xs={12}>
              {t(
                'managerCertification.managerCertificationFormBuilder.termsOfCommunicationLastParagraph',
              )}
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12} mt={2}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button variant='contained' onClick={() => onSubmit(questions)}>
            {t('managerCertification.submit')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}
