import { get } from 'lodash'
import { Grid, Typography, Box } from '@mui/material'

import { ProgressBar } from 'components'
import { t } from 'i18n'

import { SurveryOverviewData } from './types'

interface OverallParticipationProps {
  surveyOverview: Partial<SurveryOverviewData>
}

export default function AnticipatedTurnover({ surveyOverview }: OverallParticipationProps) {
  
  if (get(surveyOverview, 'turnover')) {
    // const anticipatedTurnover = get(surveyOverview, 'turnover.long_term.at_risk') + get(surveyOverview, 'turnover.long_term.passive');
    // const anticipatedTurnoverPercentage = get(surveyOverview, 'turnover.long_term.at_risk_percentage') + get(surveyOverview, 'turnover.long_term.passive_percentage');

    const anticipatedTurnover = get(surveyOverview, 'turnover.long_term.at_risk');
    const anticipatedTurnoverPercentage = get(surveyOverview, 'turnover.long_term.at_risk_percentage');

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h6' color='text.primary' paragraph>
            <Grid container component='span' justifyContent='space-between'>
              <span>{t('surveyOverview.anticipatedTurnover')}</span>
              {/* <span>
                { `${anticipatedTurnover} ` }
                { anticipatedTurnover === 1 ? t('surveyOverview.person') : t('surveyOverview.people')} 
              </span> */}
            </Grid>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <span>
            { `${anticipatedTurnover} ` }
            { anticipatedTurnover === 1 ? t('surveyOverview.person') : t('surveyOverview.people')} 
          </span>
          <ProgressBar
            progress={[
              {
                percentage: anticipatedTurnoverPercentage,
                color: 'anticipatedProgress',
              },
            ]}
            showLabel
            // withGradient
          />
          <Box mt={1} position='relative' display='inline-block'>
            <Typography
              variant='h3'
              color='text.primary'
              fontWeight={600}
              paragraph
            >
              {anticipatedTurnoverPercentage}%
            </Typography>
          </Box>
        </Grid>
      </Grid>
    )
  } else {
    return null
  }
}
