import { Box, Button, Grid, lighten, Paper, Typography } from '@mui/material'

import { Props } from '../../CompanyAdminLayout.types'

export default function CompanyAdminLayoutActions({
  customActions = [],
}: Props) {
  if (customActions.length > 0) {
    return (
      <Grid
        container
        justifyContent='center'
        style={{ marginTop: 24, zIndex: 999 }}
      >
        <Grid item xs={12} style={{ zIndex: 999 }}>
          <Grid container alignItems='stretch' spacing={2}>
            {customActions?.map(action => (
              <Grid item xs={12} sm key={action.title}>
                <Paper
                  style={{
                    background: `linear-gradient(135deg, ${
                      action.color
                    } 0%, ${lighten(action.color, 0.3)} 100%)`,
                    marginBottom: 16,
                    height: '100%',
                  }}
                >
                  <Box p={3}>
                    <Box width={1} mb={0.5}>
                      <Grid component='span' container alignItems='center'>
                        {action.Icon && (
                          <action.Icon
                            style={{
                              color: '#FFF',
                              marginRight: 8,
                              fontSize: 24,
                            }}
                          />
                        )}
                        <Typography
                          variant='h6'
                          color='text.white'
                          fontWeight={700}
                        >
                          {action.title}
                        </Typography>
                      </Grid>
                    </Box>

                    {action.description && (
                      <Typography variant='body2' color='text.white' paragraph>
                        {action.description}
                      </Typography>
                    )}

                    <Grid container justifyContent='flex-end'>
                      <Button
                        variant='outlined'
                        sx={{
                          '&.MuiButton-outlined': {
                            color: '#FFF',
                            borderColor: '#fff',
                          },
                        }}
                        onClick={action.onClick}
                      >
                        {action.actionLabel}
                      </Button>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return null
}
