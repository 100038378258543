import { Box, Button, Fade, Grid, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'

import { ROUTES_PATHS } from 'core/routes'
import { t } from 'i18n'

export default function TopbarTakeAction({ takeActionsCount }) {
  const history = useHistory()

  if (!takeActionsCount) return null

  return (
    <Grid item>
      <Fade in={!!takeActionsCount}>
        <Button
          variant='contained'
          onClick={() => history.push(ROUTES_PATHS.takeAction)}
          sx={{
            background: theme =>
              `linear-gradient(90deg, ${theme.palette.warning.main} 0%, ${theme.palette.secondary.dark} 100%)`,
            boxShadow: '0px 4px 12px rgba(110, 120, 130, 0.1)',
            borderRadius: 40,
          }}
        >
          {t('routes.takeAction')}{' '}
          <Box
            component='span'
            sx={{
              borderRadius: '100%',
              outline: '2px solid #FFF',
              ml: 2,
              px: 1,
            }}
          >
            <Typography variant='body2' fontWeight={600}>
              {takeActionsCount}
            </Typography>
          </Box>
        </Button>
      </Fade>
    </Grid>
  )
}
