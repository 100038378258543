import palette from 'theme/palette'

export default darkMode => ({
  styleOverrides: {
    tooltip: {
      fontSize: '0.9rem',
      color: 'white',
      background: `linear-gradient(135deg, ${
        palette(darkMode).error.light
      } 0%, ${palette(darkMode).secondary.light} 100%)`,
    },
  },
})
