import { Box, Card, CardContent, darken, Grid, Typography } from '@mui/material'
import { styled } from '@mui/system'

import { RatingSection } from '../../components'

import { SurveyQuestionType } from 'types'
import { isQuestionAnswered, getCurrentQuestionId } from 'utils/survey'

export type SurveyQuestionCardProps = {
  surveyQuestion: SurveyQuestionType
  surveyQuestions: SurveyQuestionType[]
  setSurveyQuestions(surveyQuestions: SurveyQuestionType[]): any
  isMobile?: boolean
  isUnansweredQuestion?: boolean
}

type StyledQuestionNumberProperties = {
  isQuestionAnswered?: boolean
  isCurrentQuestion?: boolean
  isUnansweredQuestion?: boolean
}

type StyledCardProperties = {
  isQuestionAnswered?: boolean
  isCurrentQuestion?: boolean
}

const StyledQuestionNumber = styled('div')<StyledQuestionNumberProperties>(
  ({ theme, isQuestionAnswered, isUnansweredQuestion }) => ({
    marginRight: '16px',
    color: isUnansweredQuestion
      ? theme.palette.text.veryLight
      : isQuestionAnswered
      ? theme.palette.primary.main
      : !isQuestionAnswered && theme.palette.secondary.main,
    fontWeight: 'bold',
    display: 'inline-block',
  }),
)

const StyledCard = styled(Card)<StyledCardProperties>(
  ({ theme, isCurrentQuestion }) => ({
    backgroundColor: isCurrentQuestion
      ? theme.palette.background.paper
      : darken(theme.palette.background.paper, 0.05),
    position: 'relative',
    zIndex: 996,
  }),
)

export default function SurveyQuestionCard(props: SurveyQuestionCardProps) {
  const { surveyQuestion, surveyQuestions, isMobile } = props

  const isCurrentQuestion =
    getCurrentQuestionId(surveyQuestions) === surveyQuestion.id

  const isUnansweredQuestion =
    !isCurrentQuestion && !isQuestionAnswered(surveyQuestion)

  return (
    <StyledCard isCurrentQuestion={isCurrentQuestion}>
      <CardContent style={{ padding: isMobile ? 8 : 24 }}>
        <Box py={1} px={isMobile ? 2 : 4} pb={0}>
          <Grid
            container
            justifyContent={isMobile ? 'center' : 'space-between'}
            alignItems='center'
          >
            <Grid item xs={12} md={isMobile ? 12 : true}>
              <Typography
                color={`${
                  isQuestionAnswered(surveyQuestion) ? 'primary' : 'text'
                }.${
                  isQuestionAnswered(surveyQuestion)
                    ? 'main'
                    : isUnansweredQuestion
                    ? 'veryLight'
                    : 'primary'
                }`}
                variant='h6'
                fontWeight={
                  isCurrentQuestion || isQuestionAnswered(surveyQuestion)
                    ? 'bold'
                    : 500
                }
                align={isMobile ? 'center' : 'left'}
                paragraph={isMobile}
              >
                <StyledQuestionNumber
                  isQuestionAnswered={!!isQuestionAnswered(surveyQuestion)}
                  isUnansweredQuestion={isUnansweredQuestion}
                >
                  {surveyQuestion.index}
                </StyledQuestionNumber>
                {surveyQuestion.statement}
              </Typography>
            </Grid>

            <Grid item xs={12} md>
              <RatingSection
                {...props}
                isUnansweredQuestion={isUnansweredQuestion}
              />
            </Grid>
          </Grid>
        </Box>
      </CardContent>

      {isUnansweredQuestion && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.05)',
          }}
        ></div>
      )}
    </StyledCard>
  )
}
