import React from 'react'

import { Person as PersonIcon } from '@mui/icons-material'
import { Avatar } from '@mui/material'
import { SxProps } from '@mui/system'
import { useHistory } from 'react-router'

import { ROUTES_PATHS } from 'core/routes'

type Props = {
  userId?: number
  name: string
  src?: string
  customSrc?: any
  size: number
  style?: React.CSSProperties
  sx?: SxProps | any
  onClick?: (event: Event) => void
}

export default function UserAvatar(props: Props) {
  const { name, src, size, style, onClick } = props
  const { userId, sx, customSrc, ...rest } = props

  const history = useHistory()

  const handleClick = event => {
    if (onClick) {
      onClick(event)
    } else if (userId) {
      history.push(`${ROUTES_PATHS.profile}/${userId}`)
    }
  }

  if (src) {
    return (
      <Avatar
        {...rest}
        alt={`Picture of ${name}`}
        aria-controls='profile'
        aria-haspopup='true'
        style={{
          ...style,
          width: size,
          height: size,
          cursor: userId || onClick ? 'pointer' : 'default',
        }}
        onClick={handleClick}
        src={src}
        sx={sx ?? {}}
      />
    )
  } else {
    return (
      <Avatar
        {...rest}
        alt={`Picture of ${name}`}
        aria-controls='profile'
        aria-haspopup='true'
        style={{
          ...style,
          width: size,
          height: size,
          cursor: userId || onClick ? 'pointer' : 'default',
        }}
        onClick={handleClick}
        sx={sx ?? {}}
      >
        {customSrc ? (
          customSrc
        ) : (
          <PersonIcon
            sx={{
              width: size - 8,
              height: size - 8,
            }}
          />
        )}
      </Avatar>
    )
  }
}
