import { format, parseISO } from 'date-fns'
import { Box, CircularProgress, Divider, Grid, Typography } from '@mui/material'
import { PollOutlined as PollIcon } from '@mui/icons-material'
import { useIntl } from 'react-intl'
import { Link as RouterLink } from 'react-router-dom'

import CompanyCard from '../CompanyCard/CompanyCard'
import { ROUTES_PATHS } from 'core/routes'
import { t } from 'i18n'
import { getSurveyStatusLabel } from 'utils/survey'

export default function CompanySurveyUpdatesComponent(props: any) {
  const {
    surveys,
    isSurveysLoading,
    addSurveyToCart,
    addSurveyToCartIsLoading,
  } = props

  const intl = useIntl()

  const renderLabelAndValue = (label: string, value: string | any) => (
    <Box mr={1}>
      <Typography
        color='text.secondary'
        fontWeight='bold'
        variant='body2'
        paragraph
      >
        {label}:{' '}
        <Typography color='text.secondary' variant='body2' component='span'>
          {value}
        </Typography>
      </Typography>
    </Box>
  )

  const renderLink = (label: string, path: string) => (
    <Box mr={3}>
      <RouterLink to={`${path}`}>
        <Typography color='primary.dark' fontWeight={600} paragraph>
          {label}
        </Typography>
      </RouterLink>
    </Box>
  )

  return (
    <CompanyCard
      list={surveys}
      isListLoading={isSurveysLoading}
      title={t('myCompany.companySurveyUpdates.cardTitle')}
      emptyMessage={t('myCompany.companySurveyUpdates.emptyMessage')}
      redirectMessage={t('myCompany.companySurveyUpdates.seeAllSurveys')}
      redirectRoute={ROUTES_PATHS.surveyOverview}
    >
      {surveys?.map((surveyUpdate: any, index: number) => (
        <Box mb={3} key={surveyUpdate.name + index}>
          <Grid container>
            <Grid item>
              <Box mr={3}>
                <PollIcon color='primary' fontSize='large' />
              </Box>
            </Grid>

            <Grid item xs>
              <Typography
                color='text.secondary'
                fontWeight='bold'
                variant='h6'
                paragraph
              >
                {surveyUpdate.name}
              </Typography>

              <Grid container justifyContent='space-between'>
                {renderLabelAndValue(
                  t('myCompany.companySurveyUpdates.columnLabels.surveyType'),
                  surveyUpdate.type,
                )}
                {renderLabelAndValue(
                  t('status'),
                  getSurveyStatusLabel(surveyUpdate.status, intl),
                )}
                {surveyUpdate.open_at &&
                  renderLabelAndValue(
                    t('myCompany.companySurveyUpdates.columnLabels.openDate'),
                    format(parseISO(surveyUpdate.open_at), 'P'),
                  )}
                {surveyUpdate.close_at &&
                  renderLabelAndValue(
                    t('myCompany.companySurveyUpdates.columnLabels.closeDate'),
                    format(parseISO(surveyUpdate.close_at), 'P'),
                  )}
                {renderLabelAndValue(
                  t('myCompany.companySurveyUpdates.columnLabels.invites'),
                  surveyUpdate.invites,
                )}
                {renderLabelAndValue(
                  t('participation'),
                  `${surveyUpdate.participation_rate}%`,
                )}
              </Grid>
              <Grid container>
                {renderLink(
                  t(
                    'myCompany.companySurveyUpdates.linkLabels.manageBeheardSurvey',
                  ),
                  `${ROUTES_PATHS.surveyOverview}?id=${surveyUpdate.id}`,
                )}
                {surveyUpdate.status === 'close' &&
                  surveyUpdate.report_level === 'upgraded' && (
                    <>
                      {renderLink(
                        t(
                          'myCompany.companySurveyUpdates.linkLabels.beheardPremiumReport',
                        ),
                        `${ROUTES_PATHS.beheardReport}?id=${surveyUpdate.id}&full_report=1`,
                      )}
                      {renderLink(
                        t(
                          'myCompany.companySurveyUpdates.linkLabels.shareReport',
                        ),
                        `${ROUTES_PATHS.shareReport}?survey_id=${surveyUpdate.id}`,
                      )}
                    </>
                  )}

                {surveyUpdate.status === 'close' &&
                  surveyUpdate.report_level === 'free' && (
                    <>
                      <Box mr={3}>
                        <Typography
                          color='primary.dark'
                          fontWeight={600}
                          paragraph
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            addSurveyToCart({
                              product_key: 'full_report',
                              object_name_to_upgrade: 'Survey',
                              object_id_to_upgrade: surveyUpdate.id,
                            })
                          }
                        >
                          {addSurveyToCartIsLoading && (
                            <Box mr={3} component='span'>
                              <CircularProgress size={16} />
                            </Box>
                          )}
                          {t(
                            'myCompany.companySurveyUpdates.linkLabels.upgradeToBeheardPremiumReport',
                          )}
                        </Typography>
                      </Box>
                      {renderLink(
                        t(
                          'myCompany.companySurveyUpdates.linkLabels.beheardFreeReport',
                        ),
                        `${ROUTES_PATHS.beheardReport}?id=${surveyUpdate.id}&full_report=0`,
                      )}
                    </>
                  )}
              </Grid>
            </Grid>
          </Grid>
          <Divider />
        </Box>
      ))}
    </CompanyCard>
  )
}
