import { useEffect, useState } from 'react'

import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material'
import { useIntl } from 'react-intl'
import * as yup from 'yup'

import {
  OnboardingQuestionType,
  OnboardingQuestionVisibility,
} from 'enums/onboardingQuestion'
import { FormHandler } from 'components'
import { useUser } from 'contexts'
import { onboardingQuestionTypes } from 'constants/onboardingQuestion'
import { ManageOnboardingQuestionAlternatives } from './components'
import { API } from 'core/requests'
import { t } from 'i18n'

const validationSchema = yup.object({
  statement: yup
    .string()
    .required(t('onboardingQuestions.validations.statementIsRequired')),
  type: yup
    .string()
    .required(t('onboardingQuestions.validations.typeIsRequired')),
})

export default function ManageOnboardingQuestion({
  dialog,
  closeDialog,
  fetchRows,
}: any) {
  const intl = useIntl()

  const [onboardingChoices, setOnboardingChoices] = useState<any[]>([])

  const initialValues: OnboardingQuestionType = dialog.data
    ? dialog.data
    : {
        statement: '',
        type: '',
        sequence: 0,
        visibility: OnboardingQuestionVisibility.InsideCompany,
        onboarding_choices_attributes: [],
      }

  return (
    <FormHandler
      title={intl.formatMessage({ id: 'onboardingQuestions.title' })}
      requestUrl='onboardingQuestions'
      customMutationRequest={(payload: any) => {
        return API.createOrUpdate(
          'onboardingQuestions',
          { ...payload, onboarding_choices_attributes: onboardingChoices },
          payload.id,
        )
      }}
      data={dialog.data}
      closeDialog={closeDialog}
      fetchRows={fetchRows}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ formik, isEditMode }) => (
        <ManageOnboardingQuestionForm
          formik={formik}
          isEditMode={isEditMode}
          onboardingChoices={onboardingChoices}
          setOnboardingChoices={setOnboardingChoices}
        />
      )}
    </FormHandler>
  )
}

function ManageOnboardingQuestionForm({
  formik,
  isEditMode,
  onboardingChoices,
  setOnboardingChoices,
}) {
  const { user } = useUser()!

  useEffect(() => {
    setOnboardingChoices([...formik.values.onboarding_choices_attributes])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.onboarding_choices_attributes])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          id='statement'
          name='statement'
          data-cy='questionName'
          label={t('onboardingQuestions.questionStatement')}
          variant='outlined'
          value={formik.values.statement}
          required
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.statement && Boolean(formik.errors.statement)}
          helperText={formik.touched.statement && formik.errors.statement}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          select
          id='type'
          name='type'
          data-cy='type'
          label={t('onboardingQuestions.type')}
          variant='outlined'
          value={formik.values.type}
          required
          disabled={isEditMode}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.type && Boolean(formik.errors.type)}
          helperText={formik.touched.type && formik.errors.type}
          fullWidth
        >
          {onboardingQuestionTypes
            .filter(
              type =>
                type.active &&
                ![
                  OnboardingQuestionType.Event,
                  OnboardingQuestionType.Reward,
                  OnboardingQuestionType.ChoiceGroup,
                ].includes(type.value),
            )
            .map(onboardingQuestionType => (
              <MenuItem
                data-cy='onboardingQuestionType'
                key={onboardingQuestionType.value}
                value={onboardingQuestionType.value}
              >
                {onboardingQuestionType.label}
              </MenuItem>
            ))}
        </TextField>
      </Grid>

      {user.sparck_administrator &&
        ![OnboardingQuestionType.Reward, OnboardingQuestionType.Event].includes(
          formik.values.type,
        ) && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    formik.values?.visibility ===
                    OnboardingQuestionVisibility.AcrossCompanies
                  }
                  onChange={() =>
                    formik.setFieldValue(
                      'visibility',
                      formik.values.visibility ===
                        OnboardingQuestionVisibility.AcrossCompanies
                        ? OnboardingQuestionVisibility.InsideCompany
                        : OnboardingQuestionVisibility.AcrossCompanies,
                    )
                  }
                  name='visibility'
                />
              }
              label={t('onboardingQuestions.displayToAllCompanies')}
            />
          </Grid>
        )}

      {formik.values.type && (
        <Grid item xs={12}>
          <ManageOnboardingQuestionAlternatives
            formik={formik}
            onboardingChoices={onboardingChoices}
            setOnboardingChoices={setOnboardingChoices}
          />
        </Grid>
      )}
    </Grid>
  )
}
