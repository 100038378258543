import {
  Favorite as LoveIcon,
  FavoriteBorder as LoveOutlinedIcon,
} from '@mui/icons-material'
import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Rating,
  Tabs,
  Tab,
  Typography,
  useTheme,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { get, isEmpty } from 'lodash'
import { UseMutationResult } from 'react-query'

import { CatalogType } from 'enums/rewardsCatalog'
import { WishType } from 'enums/wish'
import { t } from 'i18n'
import { getRewardDescription, getRewardName } from 'utils/reward'
import { getUniqueColor } from 'types/colors'

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#FFF',
  },
})

type Props = {
  selectedTab: CatalogType
  setSelectedTab: (value: CatalogType) => void
  rewards: any[]
  rewardsIsLoading: boolean
  rewardsNGC: any[]
  rewardsNGCIsLoading: boolean
  addToWishlist: UseMutationResult
  removeFromWishlist: UseMutationResult
  wishlist: any
  updateWishlist: UseMutationResult
}

const tabs = [
  {
    id: 'rewardsNGC',
    label: t('myRewards.catalog.tabLabels.rewardsNGC'),
    wishType: WishType.Retailer,
    value: CatalogType.RewardsNGC,
  },
  {
    id: 'rewards',
    label: t('myRewards.catalog.tabLabels.rewards'),
    wishType: WishType.Company,
    value: CatalogType.Rewards,
  },
]

export default function RewardsComponent({
  selectedTab,
  setSelectedTab,
  rewards,
  rewardsIsLoading,
  rewardsNGC,
  rewardsNGCIsLoading,
  addToWishlist,
  removeFromWishlist,
  wishlist,
  updateWishlist,
}: Props) {
  const theme: any = useTheme()

  const list =
    selectedTab === CatalogType.RewardsNGC
      ? rewardsNGC ?? []
      : (rewards ?? []).map(reward => ({
          ...reward,
          backgroundColor: getUniqueColor(theme, {
            isGradient: true,
            id: reward.id,
          }),
        }))

  const handleWishAction = item => {
    const addToWishlistPayload = {
      wishable_id: item.id,
      wishable_type: tabs.find(tab => tab.value === selectedTab)!.wishType,
    }

    if (item.wish_id) {
      removeFromWishlist.mutate(item.wish_id)
    } else {
      addToWishlist.mutate(addToWishlistPayload)
    }
  }

  const renderRewardNGC = reward => (
    <>
      {get(reward, 'product.faceplate_url') && (
        <img
          src={get(reward, 'product.faceplate_url')}
          alt='Brand logo'
          style={{
            width: '100%',
            aspectRatio: '16/9',
          }}
        />
      )}
    </>
  )

  const renderReward = reward => (
    <Grid
      container
      alignItems='center'
      justifyContent='center'
      style={{
        width: '100%',
        aspectRatio: '16/9',
        background: reward.backgroundColor,
        paddingTop: 8,
        paddingBottom: 8,
        // height: '100%',
      }}
    >
      <Box px={1} width={1}>
        <Typography
          variant='h6'
          color='text.white'
          fontWeight={700}
          align='center'
        >
          {getRewardName(reward)}
        </Typography>

        <Typography variant='body2' color='text.white' align='center'>
          {getRewardDescription(reward)}
        </Typography>

        {get(reward, 'onboarding_answer.satisfaction') && (
          <Grid container justifyContent='center'>
            <StyledRating
              name={`rating-${reward.id}`}
              value={get(reward, 'onboarding_answer.satisfaction')}
              max={get(reward, 'onboarding_answer.satisfaction')}
              sx={{
                mt: 1,
              }}
              readOnly
            />
          </Grid>
        )}
      </Box>
    </Grid>
  )

  return (
    <Grid container>
      {(selectedTab === CatalogType.RewardsNGC && rewardsNGCIsLoading) ||
      (selectedTab === CatalogType.Rewards && rewardsIsLoading) ? (
        <Grid item xs={12}>
          <Box width={1} my={5}>
            <Grid container justifyContent='center'>
              <CircularProgress size={40} />
            </Grid>
          </Box>
        </Grid>
      ) : (
        <>
          {wishlist && !isEmpty(list) && (
            <Grid item xs={12}>
              <Grid container justifyContent='center'>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='anonymous'
                      checked={wishlist.visibility === 'public'}
                      onChange={() =>
                        updateWishlist.mutate({
                          visibility:
                            wishlist.visibility === 'public'
                              ? 'private'
                              : 'public',
                        })
                      }
                    />
                  }
                  label={t('myRewards.catalog.makeWishListPublic')}
                />
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} sx={{ mb: 3 }}>
            <Grid container justifyContent='center'>
              <Tabs
                value={selectedTab}
                onChange={(event, value) => setSelectedTab(value)}
                centered
              >
                {tabs.map(tab => (
                  <Tab key={tab.id} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {isEmpty(list) ? (
              <Box width={1} my={5}>
                <Typography
                  variant='h6'
                  color='text.secondary'
                  align='center'
                  paragraph
                >
                  {t('myRewards.catalog.noItems')}{' '}
                  {tabs.find(tab => tab.value === selectedTab)?.label}
                </Typography>
              </Box>
            ) : (
              <Grid container spacing={4} justifyContent='center'>
                {(list ?? []).map(item => (
                  <Grid
                    item
                    key={item.id}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={selectedTab === CatalogType.Rewards ? 3 : 2}
                    sx={{ zIndex: 1, position: 'relative' }}
                  >
                    <Card sx={{ height: 1, zIndex: 1 }}>
                      <Grid container sx={{ height: 1, zIndex: 1 }}>
                        {selectedTab === CatalogType.RewardsNGC
                          ? renderRewardNGC(item)
                          : renderReward(item)}
                      </Grid>
                    </Card>
                    <Paper
                      sx={{
                        borderRadius: '100%',
                        position: 'absolute',
                        top: 16,
                        right: -16,
                        zIndex: 999,
                      }}
                      onClick={() => handleWishAction(item)}
                    >
                      <IconButton sx={{ zIndex: 999 }}>
                        {item.wish_id ? (
                          <LoveIcon
                            style={{
                              fill: theme.palette.error.light,
                              zIndex: 999,
                            }}
                          />
                        ) : (
                          <LoveOutlinedIcon style={{ zIndex: 999 }} />
                        )}
                      </IconButton>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Grid>
  )
}
