import { teal } from '@mui/material/colors'
import { ContactSupportOutlined as OnboardingQuestionsIcon } from '@mui/icons-material'
import { useIntl } from 'react-intl'

import ManageOnboardingQuestion from './manageOnboardingQuestion/ManageOnboardingQuestion'
import { CompanyAdminLayout } from 'components'
import { onboardingQuestionTypes } from 'constants/onboardingQuestion'
import { UserPermission } from 'enums/user'

export default function OnboardingQuestions() {
  const intl = useIntl()

  return (
    <>
      <CompanyAdminLayout
        slug='onboardingQuestions'
        title={intl.formatMessage({ id: 'onboardingQuestions.title' })}
        description={intl.formatMessage({
          id: 'onboardingQuestions.description',
        })}
        color={teal['A700']}
        requestUrl='onboardingQuestions'
        tableProps={{
          columns: [
            {
              title: intl.formatMessage({
                id: 'onboardingQuestions.questionStatement',
              }),
              field: 'statement',
              editable: 'never',
            },
            {
              title: intl.formatMessage({ id: 'onboardingQuestions.type' }),
              field: 'type',
              editable: 'never',
              render: (rowData: any) => {
                const onboardingQuestion = onboardingQuestionTypes.find(
                  question => question.value === rowData.type,
                )

                return onboardingQuestion ? onboardingQuestion.label : ''
              },
            },
          ],
        }}
        managementPermission={UserPermission.ManageOnboardingQuestions}
        ManagementForm={ManageOnboardingQuestion}
        IconHeader={OnboardingQuestionsIcon}
      />
    </>
  )
}
