import palette from 'theme/palette'

export default darkMode => ({
  styleOverrides: {
    root: {
      borderBottom: `1px solid ${palette(darkMode).divider}`,
    },
    sizeSmall: {
      padding: '2px 5px',
    },
    alignLeft: {
      width: 'auto !important',
    },
  },
})
