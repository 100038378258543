import jsPDF from 'jspdf'

import sparckLogo from '../assets/images/sparck-logo-colored.png'

export const MFA_FACTOR_LABEL = {
  auth_app: 'AuthApp',
  sms: 'SMS',
  email: 'Email',
}

export const userHasMFAEnabled = user =>
  user.mfa_details?.auth_app_enabled ||
  user.mfa_details?.sms_enabled ||
  user.mfa_details?.email_enabled

export const copyToClipboard = async (
  text: string,
  onSuccess?: () => void | undefined,
  onError?: () => void | undefined,
) => {
  if ('clipboard' in navigator) {
    await navigator.clipboard.writeText(text)
    if (onSuccess) onSuccess()
  } else {
    if (onError) onError()
  }
}

type FileGenerationActionType = 'print' | 'download'

export const donwnloadOrPrintBackupCodes = (
  backupCodes,
  action: FileGenerationActionType,
) => {
  const doc = new jsPDF('p', 'pt')

  const print = () => {
    if (action === 'print') {
      doc.autoPrint({ variant: 'non-conform' })

      doc.output('dataurlnewwindow', {
        filename: 'my-sparck-backupcodes.pdf',
      })

      window.document.title
    }
  }

  const download = () => doc.save('my-sparck-backup-codes.pdf')

  const getCodePosition = percentage =>
    (doc.internal.pageSize.getWidth() * percentage) / 100

  const halfPagePoint = getCodePosition(50)

  let verticalPosition = 80

  doc.addImage(sparckLogo, 15, 10, 60, 60)

  doc.setFontSize(24)
  doc.setFont('Courier', 'bold', undefined)
  doc.text('Your Sparck MFA Backup Codes', halfPagePoint, verticalPosition, {
    align: 'center',
  })
  doc.setFont('Courier', 'normal', undefined)

  verticalPosition += 40

  const descriptionText =
    'Please save the below back up codes in the event you are unable to use your authentication device and need to access your Sparck account.'

  const lines = doc.splitTextToSize(descriptionText, 900)

  doc.setFontSize(14)
  doc.setFont('Courier', 'normal', undefined)
  doc.text(lines, 50, verticalPosition)

  verticalPosition += 60

  doc.setLineWidth(3.0)
  doc.roundedRect(
    getCodePosition(25),
    verticalPosition,
    getCodePosition(50),
    getCodePosition(50),
    8,
    8,
    'S',
  )
  doc.setLineWidth(1.0)

  verticalPosition += 40

  doc.setFontSize(12)
  backupCodes?.forEach((code, index) => {
    doc.text(code, halfPagePoint, index * 25 + verticalPosition, {
      align: 'center',
    })
  })

  action === 'download' ? download() : print()
}
