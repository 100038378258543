import React from 'react'
import { Box, Grid } from '@mui/material'
import { Pagination } from '@mui/material';

type Props = {
  employeesCount: number
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  pageSize: number
  totalCount: number
}

export default function EmployeesDirectoryPagination({
  currentPage,
  setCurrentPage,
  totalCount,
}: Props) {
  return (
    <Box width={1} my={3} mt={5}>
      <Grid container justifyContent='center'>
        <Pagination
          count={totalCount}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
          color='primary'
          size='large'
        />
      </Grid>
    </Box>
  )
}
