import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material'

import { Props } from '../../Login.props'
import PasswordRules from 'components/PasswordRules'
import { ROUTES_PATHS } from 'core/routes'
import { t } from 'i18n'
import { isValidPassword } from 'utils/formValidation'

export default function LoginForm(props: Props) {
  const {
    changePassword,
    changePasswordIsLoading,
    changePasswordState,
    setChangePasswordState,
    history,
  } = props

  return (
    <>
      <Grid container justifyContent='center'>
        <Grid item xs={12}>
          <Typography variant='h5' color='text.secondary' align='center'>
            {t('login.changePassword.title')}
          </Typography>
        </Grid>
      </Grid>

      <Box my={5}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id='current_password'
              type='password'
              label={t('login.changePassword.formLabels.currentPassword')}
              variant='filled'
              data-cy='current_Password'
              value={changePasswordState.current_password}
              onChange={e =>
                setChangePasswordState({
                  ...changePasswordState,
                  current_password: e.target.value,
                })
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id='new_password'
              type='password'
              label={t('login.changePassword.formLabels.newPassword')}
              variant='filled'
              data-cy='new_password'
              value={changePasswordState.new_password}
              onChange={e =>
                setChangePasswordState({
                  ...changePasswordState,
                  new_password: e.target.value,
                })
              }
              fullWidth
            />
          </Grid>

          <PasswordRules password={changePasswordState.new_password} />
        </Grid>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            color='primary'
            variant='contained'
            size='large'
            data-cy='change_password_button'
            onClick={changePassword}
            disabled={
              changePasswordIsLoading ||
              !isValidPassword(changePasswordState.new_password)
            }
            fullWidth
          >
            {changePasswordIsLoading ? (
              <CircularProgress size={24} />
            ) : (
              t('login.changePassword.changePasswordButton')
            )}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Button
            color='primary'
            variant='outlined'
            size='large'
            onClick={() => history.push(ROUTES_PATHS.login)}
            fullWidth
          >
            {t('login.changePassword.backToLoginButton')}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
