import { isEmpty } from 'lodash'
import {
  Box,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material'
import {
  PublicOutlined as PublicIcon,
  LockOutlined as PrivateIcon,
} from '@mui/icons-material'
import { SparckMemoriesCard, SparckMemoriesHeader } from '../../components'
import { t } from 'i18n'
import { useState } from 'react'
import { useIntl } from 'react-intl'

export enum SparckMemoriesTab {
  Sent = 'sent',
  Received = 'received',
}

type TabPanelProps = {
  children?: React.ReactNode
  index: number
  value: string
}

export default function SparckMemoriesComponent(props) {
  const {
    visibleUserID,
    receivedSparcks,
    receivedSparcksAreLoading,
    fetchReceivedSparcks,
    sentSparcks,
    sentSparcksAreLoading,
    fetchSentSparcks,
  } = props

  const [selectedTab, setSelectedTab] = useState(SparckMemoriesTab.Received)

  const intl = useIntl()

  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('lg'),
  )

  const publicReceivedSparcks = receivedSparcks.filter(
    sparck => sparck.has_public_access,
  )
  const publicSentSparcks = sentSparcks.filter(
    sparck => sparck.has_public_access,
  )

  const privateReceivedSparcksCount =
    receivedSparcks.length - publicReceivedSparcks.length

  const privateSentSparcksCount = sentSparcks.length - publicSentSparcks.length

  const tabs = [
    {
      value: SparckMemoriesTab.Received,
      label: intl.formatMessage({ id: 'sparcks.sparckMemories.received' }),
      data: visibleUserID ? publicReceivedSparcks : receivedSparcks,
      isLoading: receivedSparcksAreLoading,
      refetch: fetchReceivedSparcks,
    },
    {
      value: SparckMemoriesTab.Sent,
      label: intl.formatMessage({ id: 'sparcks.sparckMemories.sent' }),
      data: visibleUserID ? publicSentSparcks : sentSparcks,
      isLoading: sentSparcksAreLoading,
      refetch: fetchSentSparcks,
    },
  ]

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div
        role='tabpanel'
        hidden={value !== selectedTab}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === selectedTab && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    )
  }

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)

    const tab = tabs.find(tab => tab.value === newValue)

    tab?.refetch()
  }

  function renderSparckMemories(sparckMemories, isLoading) {
    if (isLoading) {
      return (
        <Grid container justifyContent='center'>
          <CircularProgress size={64} />
        </Grid>
      )
    }

    return (
      <Grid container justifyContent='center' spacing={2}>
        {isEmpty(sparckMemories) ? (
          <Box p={2} mt={5}>
            <Typography variant='h6' color='text.light'>
              {t('sparcks.sparckMemories.noSparckMemories')}
            </Typography>
          </Box>
        ) : (
          <>
            {visibleUserID ? (
              <Box
                p={2}
                mt={5}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Typography variant='h6' color='text.light'>
                  {t('sparcks.sparckMemories.onlyPublicSparcksMessage')}
                </Typography>
              </Box>
            ) : (
              <Box
                mt={2}
                mb={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Typography variant='h6' color='text.light' mr={4}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <PublicIcon sx={{ mr: 1 }} />
                    {t('sparcks.sparckMemories.public')}:{' '}
                    {selectedTab === SparckMemoriesTab.Received
                      ? publicReceivedSparcks.length
                      : publicSentSparcks.length}
                  </Box>
                </Typography>
                <Typography variant='h6' color='text.light'>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <PrivateIcon sx={{ mr: 1 }} />
                    {t('sparcks.sparckMemories.private')}:{' '}
                    {selectedTab === SparckMemoriesTab.Received
                      ? privateReceivedSparcksCount
                      : privateSentSparcksCount}
                  </Box>
                </Typography>
              </Box>
            )}

            {sparckMemories?.map(sparck => (
              <Grid key={sparck.id} item xs={12} md={6} lg={4}>
                <SparckMemoriesCard
                  {...props}
                  sparck={sparck}
                  selectedTab={selectedTab}
                  visibleUserID={visibleUserID}
                />
              </Grid>
            ))}
          </>
        )}
      </Grid>
    )
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <SparckMemoriesHeader />
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ width: '100%', mt: isSmallScreen ? 16 : 8 }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label='sparck memories tabs'
              centered
            >
              {tabs.map((tab, index) => (
                <Tab
                  value={tab.value}
                  label={tab.label}
                  {...a11yProps(index)}
                  key={tab.value}
                />
              ))}
            </Tabs>
          </Box>
          {tabs.map((tab, index) => (
            <TabPanel value={tab.value} index={index} key={tab.value}>
              <Grid container justifyContent='center'>
                <Grid item xs={11} sm={10}>
                  {renderSparckMemories(tab.data, tab.isLoading)}
                </Grid>
              </Grid>
            </TabPanel>
          ))}
        </Box>
      </Grid>
    </Grid>
  )
}
