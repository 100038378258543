import { get, isEmpty } from 'lodash'
import { Box, Grid, Typography } from '@mui/material'

// import { ProgressBar } from 'components'
import { t } from 'i18n'
import { TeamInsightsProps } from '../TeamInsightsComponent/TeamInsightsComponent'
import { formatToUSD } from 'utils/currency'
// import { capitalize } from 'lodash'
import { Pie } from 'react-chartjs-2'
import { PERIODS } from 'constants/period'

export default function TeamInsightsRewardBudgetProgress(
  props: TeamInsightsProps,
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { insights, insightsMeta, filters } = props

  let selectedPeriod: any;
  PERIODS.map(period => {
    if(period.id === filters.period){
      selectedPeriod = period.label
    }
  })

  if (!isEmpty(insights)) {
    // const spent =
    //   get(insights, 'budget.budget_recognition') &&
    //   get(insights, 'budget.budget_recognition').reduce(
    //     (acc, value) => acc + +value.budget.spent,
    //     0,
    //   )
    
    const initialBudgetGoal = get(insights, 'manager_goal_overview.default_team_budget_goal', 0.00)
    const currentBudgetGoal = get(insights, 'manager_goal_overview.custom_team_budget_goal', 0.00)
    const budgetGoalSpentOnTeam = get(insights, 'manager_goal_overview.team_budget_goal_spent', 0.00)
    const totalBudgetSpent = get(insights, 'manager_goal_overview.total_budget_spent', 0.00)
    const totalAvailableBudget = currentBudgetGoal - totalBudgetSpent
    const totalBudgetSpentOnOthers = totalBudgetSpent - budgetGoalSpentOnTeam
    const budgetAdiustments =  currentBudgetGoal - initialBudgetGoal;

    // const available = get(insights, 'budget.available') && parseFloat(get(insights, 'budget.available'))
    // const maxEmployeeRewardAmount = get(insights, 'budget.max_employee_reward_amount') && parseFloat(get(insights, 'budget.max_employee_reward_amount'))
    // const budgetPeriod = get(insights, 'budget.period') && get(insights, 'budget.period')

    // const teamMembers = get(insightsMeta, 'my_team_members') && get(insightsMeta, 'my_team_members')
    // const teamCount = teamMembers.length
    // const totalAmount = (maxEmployeeRewardAmount * teamCount)
    // const percentageSpent = Math.round((spent * 100) / (totalAmount))
    
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={8} mb={5} sx={{textAlign: "left"}}>
              <Box sx={{fontWeight: "bold", fontSize: 16}}>
                Total Budget:
              </Box>
              <Box pt={1} pb={1} pl={5} color='text.secondary' sx={{fontWeight: "bold", fontSize: 13}}>
                Initial Budget:
              </Box>
              <Box pb={1} pl={5} color='text.secondary' sx={{fontWeight: "bold", fontSize: 13}}>
                Adiustments:
              </Box>
              <Box sx={{fontWeight: "bold", fontSize: 16}}>
                Rewards Sent:
              </Box>
              <Box pt={1} pl={8} color='text.warning' sx={{fontWeight: "bold", fontSize: 13}}>
                My Team:
              </Box>
              <Box pt={1} pb={1} pl={8} color='primary.main' sx={{fontWeight: "bold", fontSize: 13}}>
                Others:
              </Box>
              <Box sx={{fontWeight: "bold", fontSize: 16}}>
                Available Balance:
              </Box>
            </Grid>
            <Grid item xs={4} sx={{textAlign: "right"}}>
              <Box sx={{fontWeight: "bold", fontSize: 16}}>
                {(filters.period === "all_time") ? "NA" : formatToUSD(currentBudgetGoal) }
              </Box>
              <Box pt={1} pb={1} color={'text.secondary'} sx={{fontWeight: "bold", fontSize: 13}}>
                {(filters.period === "all_time") ? "NA" : formatToUSD(initialBudgetGoal)}
              </Box>
              <Box pb={1} color={budgetAdiustments.toString().includes('-') ? 'error.main' : 'text.secondary'} sx={{fontWeight: "bold", fontSize: 13}}>
                {(filters.period === "all_time") ? "NA" : formatToUSD(budgetAdiustments)}
              </Box>
              <Box sx={{fontWeight: "bold", fontSize: 16}}>
                {(filters.period === "all_time") ? "NA" : formatToUSD(totalBudgetSpent)}
              </Box>
              <Box pt={1} color='text.warning' sx={{fontWeight: "bold", fontSize: 13}}>
                {(filters.period === "all_time") ? "NA" : formatToUSD(budgetGoalSpentOnTeam)}
              </Box>
              <Box pt={1} pb={1} color='primary.main' sx={{fontWeight: "bold", fontSize: 13}}>
                {(filters.period === "all_time") ? "NA" : formatToUSD(totalBudgetSpentOnOthers)}
              </Box>
              <Box color={totalAvailableBudget.toString().includes('-') ? 'error.main' : ''} sx={{fontWeight: "bold", fontSize: 16}}>
                {(filters.period === "all_time") ? "NA" : formatToUSD(totalAvailableBudget)}
              </Box>
            </Grid>
            <Grid item xs={12} mb={5}>
              <Pie
                data={{
                  labels: ['Available Balance', 'My Team', 'Others'],
                  datasets: [
                    {
                      label: t('insights.team.rewardBudget', { period: t(selectedPeriod.props.id) }),
                      data: [totalAvailableBudget, budgetGoalSpentOnTeam, totalBudgetSpentOnOthers],
                      backgroundColor: [
                        '#006666',
                        '#f26522',
                        '#1bb4ac',
                      ],
                      borderColor: [
                        '#006666',
                        '#f26522',
                        '#1bb4ac',
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
                height={200}
                options={
                  {
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                      legend: {
                        display: true,
                        position: "bottom"
                      },
                      title: {
                        display: false,
                        text: t('insights.team.rewardBudget', { period: t(selectedPeriod.props.id) }),
                      },
                      tooltip: {
                        enabled: true,
                        callbacks: {
                          label: (tooltipItem) => {
                            return `${tooltipItem.label}: ${formatToUSD(tooltipItem.formattedValue)}`;
                          },
                        },
                      },
                      // datalabels: {
                      //   display: true,
                      //   textAlign: 'center',
                      //   font: {
                      //     family: 'Calibri',
                      //     color: '#000000',
                      //     size: 14
                      //   },
                      //   formatter: (value, ctx) => {
                      //     let sum = 0;
                      //     const dataArr = ctx.chart.data.datasets[0].data;
                      //     dataArr.map(data => {
                      //         sum += data;
                      //     });
                      //     const percentage = (value * 100 / sum).toFixed(2) + "%";
                      //     const label = ctx.chart.data.labels[ctx.dataIndex];
                      //     return label + ': ' + percentage;
                      //   },
                      //   anchor: 'end', 
                      //   align: 'end', 
                      //   offset: 10,
                      // },
                    },

                  } as any
                }
              />
            </Grid>
          </Grid>

          {/* <Box mb={2} width={1}> */}
            {/* <Grid item xs={12}>
              <Grid container justifyContent='space-between'>
                {[
                  {
                    id: 'available',
                    label: t('insights.team.rewardBudgetProgressLabels.total'),
                    // value: available,
                    value: totalAvailableBudget,
                    align: 'left',
                  },
                  {
                    id: 'total_spent',
                    label: t('insights.team.rewardBudgetProgressLabels.spent'),
                    value: totalBudgetSpent,
                    align: 'right',
                  },
                  {
                    id: 'team_spent',
                    label: t('insights.team.rewardBudgetProgressLabels.spentOnTeam'),
                    value: budgetGoalSpentOnTeam,
                    align: 'right',
                  }
                ].map((legend: any) => (
                  <Grid item key={legend.id}>
                    <Grid container direction='column'>
                      <Typography
                        variant='body1'
                        color='text.secondary'
                        fontWeight={600}
                        fontSize={12}
                        align={legend.align}
                      >
                        {legend.label}
                      </Typography>

                      <Typography
                        variant='body1'
                        color={legend.value.toString().includes('-') ? 'error.main' : 'secondary.main'}
                        fontWeight={600}
                        fontSize={14}
                        align={legend.align}
                      >
                        {formatToUSD(legend.value)}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid> */}

            {/* <Grid item xs={12}>
              <ProgressBar
                labelVariant='h6'
                showLabel
                progress={[
                  {
                    percentage: (percentageSpent >= 100) ? 100 : percentageSpent,
                    color: 'secondary',
                  },
                ]}
              />
            </Grid> */}
          {/* </Box> */}

          {get(insights, 'budget.budget_recognition', []).map((item, index) => (
            <Grid container justifyContent='space-between' key={index}>
              <Typography variant='body2' color='text.secondary' paragraph>
                {item.name}
              </Typography>

              <Typography
                variant='body2'
                color='text.primary'
                fontWeight={700}
                paragraph
              >
                {formatToUSD(item.budget.spent)}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    )
  }

  return null
}
