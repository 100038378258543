import { Button, Grid, Skeleton, Typography } from '@mui/material'

import { Props } from '../../VirginExperiencesOrganizational.props'
import { formatToUSD } from 'utils/currency'

export default function VirginExperiencesOrganizationalBalance(props: Props) {
  const {
    virginExperiencesOrganizational,
    virginExperiencesOrganizationalIsLoading,
    setUpdateBalanceLimitDialogOpen,
  } = props

  return (
    <Grid container>
      {virginExperiencesOrganizationalIsLoading ? (
        <Grid item xs={12}>
          <Skeleton width='100%' height={40} />
        </Grid>
      ) : (
        virginExperiencesOrganizational && (
          <>
            <Grid item xs={12} lg={4}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant='body1'
                    color='text.secondary'
                    fontWeight={500}
                  >
                    Balance
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant='h5'
                    color='text.primary'
                    fontWeight={700}
                  >
                    {formatToUSD(virginExperiencesOrganizational?.balance ?? 0)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        )
      )}

      <Grid item xs={12} sx={{ mt: 1.5 }}>
        <Grid container justifyContent='flex-end'>
          <Grid item xs={12} sm='auto'>
            <Button
              variant='contained'
              color='primary'
              onClick={() => setUpdateBalanceLimitDialogOpen(true)}
            >
              Manage Balance
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
