import palette from 'theme/palette'

export default darkMode => ({
  styleOverrides: {
    root: {
      color: palette(darkMode).icon,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.03)',
      },
    },
  },
})
