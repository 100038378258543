import { useState } from 'react'

import {
  alpha,
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { formatRelative, parseISO } from 'date-fns'
import { orderBy } from 'lodash'
import { useQuery, useMutation } from 'react-query'

import { DialogTeleport, UserAvatar } from 'components'
import { API } from 'core/requests'

export default function SuggestionNotesDialog({ dialog, setDialog }) {
  const [note, setNote] = useState('')

  const {
    data: suggestionNotes,
    isLoading: suggestionNotesAreLoading,
    refetch: fetchSuggestionNotes,
  } = useQuery(
    'getSuggestionNotes',
    () =>
      API.get('suggestionNotes', null, {
        queryParams: {
          suggestion_box_idea_id: dialog?.data?.id,
        },
      }),
    {
      enabled: !!dialog?.data?.id,
    },
  )

  const suggestionNoteMutation = useMutation(
    (payload: any) => API.create('suggestionNotes', payload),
    {
      onSuccess: (response: any) => {
        if (response.id) {
          setNote('')
          fetchSuggestionNotes()
        }
      },
    },
  )

  const handleSend = () => {
    suggestionNoteMutation.mutate({
      suggestion_box_idea_id: dialog?.data?.id,
      note: note,
    })
  }

  return (
    <DialogTeleport
      dialogTitle={`Suggestion Notes`}
      dialogAction={null}
      dialogSize='sm'
      dialogOpen={dialog.isOpen}
      handleDialogClose={() => setDialog({ isOpen: false, data: null })}
      isFullWidth
    >
      <Grid container spacing={2}>
        {dialog?.data?.description && (
          <Grid item xs={12}>
            <Typography variant='body1' paragraph>
              Suggestion:{' '}
              <Typography component='span' variant='body1' fontWeight={500}>
                {dialog?.data?.description}
              </Typography>
            </Typography>
          </Grid>
        )}

        {suggestionNotesAreLoading ? (
          <Grid item xs={12}>
            <Grid container justifyContent='center'>
              <CircularProgress size={40} sx={{ my: 5 }} />
            </Grid>
          </Grid>
        ) : (
          orderBy(suggestionNotes ?? [], ['created_at'])?.map(
            suggestionNote => (
              <Grid item xs={12} key={suggestionNote.id}>
                <SuggestionNote note={suggestionNote} />
              </Grid>
            ),
          )
        )}

        <Grid item xs={12}>
          <Typography variant='body2' color='text.secondary' align='justify'>
            * the notes above are only available for company admins of this
            company. Non-admins won't be able to see any notes from any
            suggestions. To talk to the employee owner of the suggestion use the
            Suggestion Chat action.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            spacing={1}
            justifyContent='space-between'
            alignItems='flex-end'
          >
            <Grid item xs={12}>
              <TextField
                id='note'
                multiline
                variant='outlined'
                value={note}
                label='Write your note here...'
                onChange={(event: any) => setNote(event.target.value)}
                rows={3}
                maxRows={8}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleSend}
                fullWidth
              >
                Add Note{' '}
                {suggestionNoteMutation.isLoading && (
                  <CircularProgress size={24} />
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogTeleport>
  )
}

function SuggestionNote({ note }) {
  const theme = useTheme()

  return (
    <Box
      p={3}
      mb={1}
      sx={{
        border: '1px solid',
        borderColor: 'warning.light',
        borderRadius: 1,
        bgcolor: alpha(theme.palette.warning.light, 0.05),
      }}
    >
      <Grid container spacing={2}>
        <Grid item>
          <UserAvatar
            userId={note?.user?.id}
            name={note?.user?.name}
            src={note?.user?.avatar?.url}
            size={48}
          />
        </Grid>

        <Grid item xs>
          <Typography variant='body2' color='text.primary' fontWeight={600}>
            {note?.user?.name}
          </Typography>
          <Typography fontSize='0.8125rem' color='text.secondary'>
            {note.updated_at !== note.created_at && 'Updated'}{' '}
            {formatRelative(parseISO(note.created_at), new Date())}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='body1'>{note.note}</Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
