import { useState } from 'react'

import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { QuestionAnswerOutlined as AnswersIcon } from '@mui/icons-material'
import { camelCase, get, isEmpty, startCase } from 'lodash'
import { Bar } from 'react-chartjs-2'
import { useIntl } from 'react-intl'

import { DialogTeleport } from 'components'
import { PulseSurveyQuestionType } from 'enums/pulseSurvey'
import { SurveyEngagement } from 'enums/survey'
import { t } from 'i18n'
import { getEngagementScoreColor } from 'utils/survey'

const engagementScores = [
  SurveyEngagement.Engaged,
  SurveyEngagement.Neutrals,
  SurveyEngagement.Disengaged,
]
const importanceScores = ['important', 'indifferent', 'not_important']

const MINIMUM_EMPLOYEES_PARTICIPATION = 5

export default function PulseSurveyStatistics({
  pulseSurvey,
  horizontalView,
}: any) {
  const theme: any = useTheme()

  const intl = useIntl()

  const [dialogAnswers, setDialogAnswers] = useState<any>({
    isOpen: false,
    answers: [],
  })

  const engagementScoreLabels = [
    intl.formatMessage({
      id: 'pulseSurvey.statistics.tableHeaders.agree',
    }),
    intl.formatMessage({
      id: 'pulseSurvey.statistics.tableHeaders.neutral',
    }),
    intl.formatMessage({
      id: 'pulseSurvey.statistics.tableHeaders.disagree',
    }),
  ]

  const importanceScoreLabels = [
    intl.formatMessage({
      id: 'pulseSurvey.statistics.tableHeaders.important',
    }),
    intl.formatMessage({
      id: 'pulseSurvey.statistics.tableHeaders.indifferent',
    }),
    intl.formatMessage({
      id: 'pulseSurvey.statistics.tableHeaders.not_important',
    }),
  ]

  if (pulseSurvey) {
    const getDatasetValues = dataset =>
      pulseSurvey.questions.map(question =>
        !isEmpty(question.statistics)
          ? question.statistics[0]['engagement_scale'][dataset]
          : 0,
      )

    const chartData = {
      labels: pulseSurvey.questions.map((_, index) => `Q${index + 1}`),
      datasets: engagementScores.map(dataset => ({
        label: dataset,
        data: getDatasetValues(dataset),
        backgroundColor: theme.palette[getEngagementScoreColor(dataset)].light,
        maxBarThickness: 16,
        categoryPercentage: 0.5,
        barPercentage: 1,
      })),
    }

    const chartOptions = {
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        y: {
          max: 100,
          min: 0,
          ticks: {
            callback: value => value + '%',
          },
        },
      },
    }

    const renderTableCellScores = (question, index, scores, scaleAccessor) =>
      scores.map(score => (
        <TableCell key={index + score} align='center'>
          {question.type === PulseSurveyQuestionType.OpenEnded ||
          (question.type === PulseSurveyQuestionType.OpenEndedAndImportance &&
            scaleAccessor === 'engagement_scale') ||
          isEmpty(question.statistics)
            ? 'N/A'
            : `${question.statistics[0][scaleAccessor][score]}%`}
        </TableCell>
      ))

    return (
      <>
        <Grid container spacing={3} alignItems='center'>
          {get(pulseSurvey, 'participation.count', 0) >=
            MINIMUM_EMPLOYEES_PARTICIPATION && (
            <Grid item xs={12} md={horizontalView ? 6 : 12}>
              <Bar data={chartData} options={chartOptions} />
            </Grid>
          )}

          <Grid item xs={12} md={horizontalView ? 6 : 12}>
            <Grid container>
              <Grid item xs={12}>
                <Box my={3} width={1}>
                  <Typography
                    variant='h6'
                    color='text.secondary'
                    fontWeight={500}
                    align='center'
                  >
                    {t('participation')}:{' '}
                    <Typography
                      component='span'
                      variant='h3'
                      color='secondary.main'
                      fontWeight={700}
                    >
                      {get(pulseSurvey, 'participation.percentage', 0)}%
                    </Typography>
                  </Typography>
                </Box>
              </Grid>

              {get(pulseSurvey, 'participation.count', 0) >=
                MINIMUM_EMPLOYEES_PARTICIPATION && (
                <Grid item xs={12}>
                  <TableContainer>
                    <Table aria-label='pulse surveys table'>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={1}></TableCell>
                          <TableCell
                            colSpan={3}
                            align='center'
                            style={{
                              borderLeft: '1px solid #EEE',
                              borderRight: '1px solid #EEE',
                            }}
                          >
                            <Typography variant='body1' fontWeight={600}>
                              {t(
                                'pulseSurvey.statistics.tableHeaders.satisfaction',
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell
                            colSpan={3}
                            align='center'
                            style={{
                              borderLeft: '1px solid #EEE',
                              borderRight: '1px solid #EEE',
                            }}
                          >
                            <Typography variant='body1' fontWeight={600}>
                              {t(
                                'pulseSurvey.statistics.tableHeaders.importance',
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell
                            colSpan={3}
                            align='center'
                            style={{
                              borderLeft: '1px solid #EEE',
                              borderRight: '1px solid #EEE',
                            }}
                          >
                            <Typography variant='body1' fontWeight={600}>
                              {t(
                                'pulseSurvey.statistics.tableHeaders.openEnded',
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            {t('pulseSurvey.statistics.tableHeaders.statement')}
                          </TableCell>
                          {[
                            ...engagementScoreLabels,
                            ...importanceScoreLabels,
                          ].map(score => (
                            <TableCell key={score} align='center'>
                              {startCase(camelCase(score))}
                            </TableCell>
                          ))}
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {pulseSurvey.questions.map((question, index) => (
                          <TableRow key={index}>
                            <TableCell component='th' scope='row'>
                              Q{index + 1}. {question.statement}
                            </TableCell>
                            {renderTableCellScores(
                              question,
                              index,
                              engagementScores,
                              'engagement_scale',
                            )}
                            {renderTableCellScores(
                              question,
                              index,
                              importanceScores,
                              'importance_scale',
                            )}

                            {}
                            <TableCell>
                              {[
                                PulseSurveyQuestionType.OpenEndedAndImportance,
                                PulseSurveyQuestionType.OpenEnded,
                              ].includes(question.type) &&
                                question.open_ended_answers?.length > 0 && (
                                  <Grid container justifyContent='center'>
                                    <Tooltip title={t('participation')}>
                                      <IconButton
                                        onClick={() =>
                                          setDialogAnswers({
                                            isOpen: true,
                                            answers:
                                              question.open_ended_answers,
                                          })
                                        }
                                        color='primary'
                                        size='small'
                                      >
                                        <AnswersIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <DialogTeleport
          dialogTitle={t('pulseSurvey.statistics.answers')}
          dialogAction={null}
          dialogSize='sm'
          dialogOpen={!!dialogAnswers.isOpen}
          handleDialogClose={() =>
            setDialogAnswers({ isOpen: false, answers: [] })
          }
          isFullWidth
        >
          <List>
            {get(dialogAnswers, 'answers', []).map((answer, index) => (
              <ListItem key={answer}>
                <Typography variant='body1' color='text.primary'>
                  {index + 1}. {answer}
                </Typography>
              </ListItem>
            ))}
          </List>
        </DialogTeleport>
      </>
    )
  }

  return null
}
