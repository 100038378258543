import { useState, useEffect } from 'react'

import { isEmpty } from 'lodash'
import { useQuery, useMutation } from 'react-query'
import { useParams } from 'react-router-dom'

import { PulseSurveyInsightsComponent } from './components'
import { API } from 'core/requests'

export const getPulseSurveyQueryParams = filters => {
  const initialNewFilters: any = {}
  const newFilters: any = initialNewFilters

  Object.keys(filters).forEach(key => {
    if (filters[key].length) {
      newFilters[key] = filters[key].join('|')
    }
  })

  return newFilters
}

let filtersQuantity = 0

export default function PulseSurveyOverview({
  pulseSurvey,
}: {
  pulseSurvey: any
}) {
  const [filtersState, setFiltersState] = useState({})
  const [filtersQuantityState, setFiltersQuantityState] = useState(0)
  const [pulseSurveyOverview, setPulseSurveyOverview] = useState({})

  const query: any = useParams()

  const pulseSurveyId = (pulseSurvey && pulseSurvey.id) ?? query.id

  const handlePulseSurveyOverviewFetch = () => {
    getPulseSurveyOverview({
      id: pulseSurveyId,
      queryParams: getPulseSurveyQueryParams(filtersState),
    })
  }

  const {
    data: demographics,
    isLoading: demographicsIsLoading,
    refetch: fetchDemographics,
  } = useQuery('getDemographics', () => API.get('demographics', null), {
    enabled: false,
  })

  const {
    mutate: getPulseSurveyOverview,
    isLoading: isPulseSurveyOverviewLoading,
  } = useMutation(
    (payload: any) =>
      API.create('pulseSurveyOverview', payload.queryParams, {
        pathParams: {
          id: pulseSurveyId,
        },
        body: {
          criteria: Object.keys(filtersState)?.map(categoryId => ({
            category: +categoryId,
            demographics: filtersState[categoryId],
          })),
        },
      }),
    {
      onSuccess: (response: any) => {
        if (response) {
          setPulseSurveyOverview(response)
        }
      },
    },
  )

  useEffect(() => {
    fetchDemographics()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isEmpty(demographics)) {
      const filters = {}
      demographics.forEach(demographic => {
        filters[demographic.id] = []
      })

      setFiltersState(filters)
    }
  }, [demographics])

  useEffect(() => {
    if (pulseSurveyId) {
      handlePulseSurveyOverviewFetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pulseSurveyId])

  useEffect(() => {
    filtersQuantity = 0

    if (!isEmpty(filtersState)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [_, value] of Object.entries(filtersState)) {
        // @ts-ignore
        filtersQuantity += value.length
      }
    }

    setFiltersQuantityState(filtersQuantity)
  }, [filtersState])

  return (
    <PulseSurveyInsightsComponent
      pulseSurveyId={pulseSurveyId}
      pulseSurveyOverview={pulseSurveyOverview}
      isPulseSurveyOverviewLoading={isPulseSurveyOverviewLoading}
      getPulseSurveyOverview={handlePulseSurveyOverviewFetch}
      demographics={demographics}
      demographicsIsLoading={demographicsIsLoading}
      filtersState={filtersState}
      setFiltersState={setFiltersState}
      filtersQuantityState={filtersQuantityState}
    />
  )
}
