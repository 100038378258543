import { Box, Grid, Typography } from '@mui/material'

import { ManagerCertificationSection } from '../../components'
import sneakPeekThumbnail from 'assets/images/managerCertification/sneak-peek-video-thumbnail.png'
import { t } from 'i18n'
import { getManagerCertificationVideoURL } from 'utils/managerCertification'

export default function ManagerCertificationSneakPeek() {
  return (
    <>
      <ManagerCertificationSection>
        <Grid container>
          <Grid item>
            <Typography
              color='text.primary'
              variant='h5'
              fontWeight={600}
              paragraph
            >
              {t(
                'managerCertification.managerCertificationComponent.sections.sneakPeek.title',
              )}
            </Typography>
          </Grid>

          <Grid item>
            <Typography
              color='text.secondary'
              variant='body1'
              paragraph
              align='justify'
            >
              {t(
                'managerCertification.managerCertificationComponent.sections.sneakPeek.description',
              )}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent='center' mt={2}>
              <Grid item xs={12} sm={11} md={10} lg={9}>
                <Box
                  component='video'
                  width={'100%'}
                  poster={sneakPeekThumbnail}
                  controls
                  sx={{ aspectRatio: '16/9' }}
                >
                  <source
                    src={getManagerCertificationVideoURL('introduction-video')}
                    type='video/mp4'
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ManagerCertificationSection>
    </>
  )
}
