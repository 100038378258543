import { ApprovalStatus } from 'enums/approvals'
import { t } from 'i18n'

export const getApprovalsStatusDetails = theme => ({
  [ApprovalStatus.Pending]: {
    label: t('statuses.pending'),
    color: theme.palette.secondary.main,
  },
  [ApprovalStatus.Approved]: {
    label: t('statuses.approved'),
    color: theme.palette.primary.main,
  },
  [ApprovalStatus.Declined]: {
    label: t('statuses.declined'),
    color: theme.palette.error.light,
  },
})
