import { useState, useEffect } from 'react'

import { DashboardComponent } from './components'
import { useFetchReminders, useFetchTasks } from './Dashboard.queries'
import { Release2_0 } from 'components'
import { UserRole } from 'enums/user'

import { get } from 'lodash'


export default function Dashboard() {
  const { isLoading: isTasksLoading, data: tasksData } = useFetchTasks()
  const { data: remindersFromAPI } = useFetchReminders()

  const [reminders, setReminders] = useState<any>([])

  useEffect(() => {
    if (remindersFromAPI?.length > 0) {
      const newReminders = remindersFromAPI
        .find(reminder => reminder.profile === UserRole.Employee)
        ?.reminders?.filter(
          reminder =>
            reminder.data?.length > 0 &&
            ['peer_birthdays', 'peer_workiversaries'].includes(reminder.type),
        )

      setReminders(newReminders)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remindersFromAPI])

  return (
    <>
      <DashboardComponent
        tasks={get(tasksData, 'data.tasks', [])}
        isTasksLoading={isTasksLoading}
        reminders={reminders ?? []}
      />

      <Release2_0 />
    </>
  )
}
