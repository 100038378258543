import { useState, Fragment } from 'react'
import {
  Collapse,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
} from '@mui/material'

import { DialogTeleport } from 'components'
import { t } from 'i18n'

export default function SparckIdeas({
  sparckIdeas,
  sparckState,
  setSparckState,
  dialogSparckIdeas,
  setDialogSparckIdeas,
  setTextFieldWithMentionsContent,
}) {
  const [sparckIdeasState, setSparckIdeasState] = useState({
    category: '',
  })

  return (
    <DialogTeleport
      dialogTitle={t('sparcks.sparck.sparckIdeas.header')}
      dialogAction={null}
      dialogSize='md'
      dialogOpen={dialogSparckIdeas}
      handleDialogClose={() => setDialogSparckIdeas(false)}
      isFullWidth
    >
      <Grid container>
        <Grid item xs={12}>
          <TextField
            data-cy='category'
            select
            id='category'
            name='category'
            label={t('sparcks.sparck.sparckIdeas.fieldLabel')}
            value={sparckIdeasState.category}
            onChange={event =>
              setSparckIdeasState({
                ...sparckIdeasState,
                category: event.target.value,
              })
            }
            variant='outlined'
            fullWidth
          >
            {Object.keys(sparckIdeas).map(category => (
              <MenuItem key={category} value={category} data-cy='category_list'>
                {category}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      {!!sparckIdeasState.category && (
        <Collapse in={!!sparckIdeasState.category} timeout={2400}>
          <List>
            {sparckIdeas[sparckIdeasState.category].map(suggestion => (
              <Fragment key={suggestion.id}>
                <ListItem
                  data-cy='message_list'
                  button
                  key={suggestion.id}
                  onClick={() => {
                    setSparckState({
                      ...sparckState,
                      message: suggestion.description,
                    })

                    setTextFieldWithMentionsContent(suggestion.description)

                    setDialogSparckIdeas(false)
                  }}
                >
                  <ListItemText primary={suggestion.description} />
                </ListItem>
                <Divider />
              </Fragment>
            ))}
          </List>
        </Collapse>
      )}
    </DialogTeleport>
  )
}
