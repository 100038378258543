import { Box, Grid, Paper } from '@mui/material'
import { Alert, AlertTitle } from '@mui/material';

import { t } from 'i18n'

type Props = {
  isInFilter?: boolean
}

export default function MinimumParticipantsAlert({
  isInFilter = false,
}: Props) {
  return (
    <Grid item xs={12}>
      <Box width={1} mb={3}>
        <Paper square>
          <Alert severity='warning' style={{ marginTop: 16 }}>
            {isInFilter && (
              <AlertTitle>
                {t('components.demographicsFilters.noResults')}
              </AlertTitle>
            )}
            <strong>
              {t('components.demographicsFilters.lessThanFiveRespondents')}
            </strong>{' '}
            {isInFilter && t('components.demographicsFilters.modifySelections')}
          </Alert>
        </Paper>
      </Box>
    </Grid>
  )
}
