import { OnboardingQuestionType } from 'enums/onboardingQuestion'
import { t } from 'i18n'

export const onboardingQuestionTypes = [
  {
    value: OnboardingQuestionType.ChoiceGroup,
    label: t('onboardingQuestions.types.choiceGroup'),
    active: true,
  },
  {
    value: OnboardingQuestionType.Event,
    label: t('onboardingQuestions.types.event'),
    active: true,
  },
  {
    value: OnboardingQuestionType.Reward,
    label: t('reward'),
    active: true,
  },
  {
    value: OnboardingQuestionType.Rating,
    label: t('onboardingQuestions.types.rating'),
    active: true,
  },
  {
    value: OnboardingQuestionType.SimpleSelect,
    label: t('onboardingQuestions.types.simpleSelect'),
    active: true,
  },
  {
    value: OnboardingQuestionType.MultipleSelect,
    label: t('onboardingQuestions.types.multipleSelect'),
    active: true,
  },
]
