import { useState } from 'react'

import {
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import { List as ListIcon, GridOn as CardsIcon } from '@mui/icons-material'

import { MyRewardsGrid, MyRewardsTable } from '../../components'
import { RewardIntentionStatus } from 'enums/reward'
import { t } from 'i18n'

export default function MyRewardsList(props) {
  const { myRewards } = props

  const [visualizationState, setVisualizationState] = useState('cards')

  const visualizationModes = [
    {
      id: 'cards',
      label: t('myRewards.list.visualizationModes.cards'),
      Icon: CardsIcon,
    },
    {
      id: 'list',
      label: t('myRewards.list.visualizationModes.list'),
      Icon: ListIcon,
    },
  ]

  const oldRewards = (myRewards ?? []).filter(
    reward => reward.status !== RewardIntentionStatus.Waiting,
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid
          container
          alignItems='end'
          justifyContent='space-between'
          sx={{ mb: 1 }}
        >
          <Typography variant='h5' fontWeight={600} color='text.primary'>
            {t('myRewards.list.title')}
          </Typography>
          <ToggleButtonGroup
            value={visualizationState}
            exclusive
            onChange={(event, value) => {
              if (value && value !== visualizationState)
                setVisualizationState(value)
            }}
            aria-label='text alignment'
          >
            {visualizationModes.map(visualization => (
              <ToggleButton
                value={visualization.id}
                aria-label={visualization.id}
                key={visualization.id}
              >
                <visualization.Icon sx={{ mr: 1 }} /> {visualization.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {visualizationState === 'list' && (
          <MyRewardsTable {...props} myRewards={oldRewards} />
        )}

        {visualizationState === 'cards' && (
          <MyRewardsGrid {...props} myRewards={oldRewards} />
        )}
      </Grid>
    </Grid>
  )
}
