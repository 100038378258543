import { Box, Grid, Paper, Typography } from '@mui/material'
import { formatToUSD } from 'utils/currency'

enum FieldType {
  Integer = 'integer',
}

enum FieldFormat {
  Currency = 'currency',
  Percentage = 'percentage',
}

const getFormattedValue = (summaryData, field: any) => {
  const fieldValue = summaryData[field.name]

  if (field.format === FieldFormat.Currency) {
    return (
      <Box component='span' color='success.main'>
        {formatToUSD(fieldValue)}
      </Box>
    )
  }

  if (field.format === FieldFormat.Percentage) {
    return (+fieldValue ?? 0)?.toFixed(2) + '%'
  }

  if (field.type === FieldType.Integer) return +fieldValue

  return fieldValue
}

export default function OrganizationReportingSummary({
  company,
  organizationSummaryData,
}) {
  const summaryMeta = organizationSummaryData.meta
  const summaryData = organizationSummaryData.data
  // const summaryFilters = organizationSummaryData.filters

  summaryMeta.fields = summaryMeta?.fields.filter((field: any) => {
    if(field.name === 'budget_main_balance'){
      if(company?.enable_rewards_store === true){
        return field
      }
    } else {
      return field
    }
  })

  return (
    <Grid container spacing={2}>
      {summaryMeta?.fields.length > 0 && (summaryMeta.fields.map((field: any) => (
        <Grid key={field.name} item xs={12} sm={6} md={4} lg={3}>
          <Paper sx={{ p: 2, height: 1 }}>
            <Grid
              container
              direction='column'
              justifyContent='space-between'
              sx={{ height: 1 }}
            >
              <Grid item xs>
                <Typography
                  variant='body1'
                  fontWeight={500}
                  color='text.primary'
                  sx={{ mb: 1 }}
                >
                  {field.label}
                </Typography>
              </Grid>

              <Grid item xs='auto'>
                <Typography variant='h4' fontWeight={700} color='text.primary'>
                  {getFormattedValue(summaryData, field)}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )))}
    </Grid>
  )
}
