import { useEffect } from 'react'

import { get } from 'lodash'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'

import { ROUTES, ROUTES_PATHS, SparckRouteType } from './routes'
import { ErrorBoundary } from 'components'
import { useCompany, useUser } from 'contexts'
import { UserPermission, UserRole } from 'enums/user'
import { isTokenExpired } from 'utils'
import { validateRoutePermission } from 'utils/app'
import { userHasMFAEnabled } from 'utils/multiFactorAuthentication'
import { userHasBeseenPermissions } from 'utils/user'

export default function ApplicationRoutes(): JSX.Element {
  return (
    <Switch>
      {ROUTES.map((route: SparckRouteType) => (
        <ApplicationRoute exact={true} {...route} key={route.id} />
      ))}
      <Redirect to={ROUTES_PATHS.dashboard} />
    </Switch>
  )
}

type ApplicationRouteProps = SparckRouteType & {
  key: string
}

function ApplicationRoute(props: ApplicationRouteProps) {
  const {
    id,
    title,
    Component,
    Layout,
    isPrivate,
    componentProps = {},
    layoutProps = {},
    path,
  } = props

  const { user } = useUser()!
  const { company } = useCompany()!

  const location = useLocation()
  const history: any = useHistory()

  const renderComponentWithLayout = (
    title: string,
    routerProps = {},
    componentProps = {},
  ) => {
    return (
      <Layout id={id} title={title} layoutProps={layoutProps}>
        <ErrorBoundary history={history} location={location} user={user}>
          <Component {...routerProps} {...componentProps} />
        </ErrorBoundary>
      </Layout>
    )
  }

  const renderRoute = (routerProps, componentProps) => {
    if (!isPrivate) {
      return renderComponentWithLayout(title, routerProps, componentProps)
    }

    if (!get(user, 'id') || (user.token && isTokenExpired(user?.token))) {
      const redirect =
        location.pathname !== '/'
          ? `?redirect=${location.pathname.replace('/', '')}`
          : ''

      return <Redirect to={`${ROUTES_PATHS.login}${redirect}`} />
    }

    if (user?.id && validateRoutePermission(user, props)) {
      return renderComponentWithLayout(title, routerProps, componentProps)
    } else {
      if (
        (user.roles ?? []).includes(UserRole.CompanyAdmin) ||
        user.sparck_administrator
      ) {
        return <Redirect to={ROUTES_PATHS.dashboard} />
      } else {
        if (userHasBeseenPermissions(user)) {
          return <Redirect to={ROUTES_PATHS.feed} />
        }

        return <Redirect to={ROUTES_PATHS.dashboard} />
      }
    }
  }

  useEffect(() => {
    if (user && company) {
      const shouldRedirectToMFASetup =
        isPrivate &&
        !user.sparck_administrator &&
        !userHasMFAEnabled(user) &&
        company?.mfa_required

      if (shouldRedirectToMFASetup) {
        history.push(ROUTES_PATHS.MFASetupRequired)
      }

      const shouldRedirectToOnboarding =
        !shouldRedirectToMFASetup &&
        isPrivate &&
        user?.onboarding === 'waiting' &&
        location.pathname !== ROUTES_PATHS.MFASetupRequired &&
        ![UserRole.CompanyAdmin].some(role =>
          (user?.roles ?? []).includes(role),
        ) &&
        userHasBeseenPermissions(user) &&
        (user?.permissions ?? []).includes(UserPermission.TakeOnboarding)

      if (shouldRedirectToOnboarding) {
        history.push(ROUTES_PATHS.onboarding)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, company, path])

  return (
    <Route
      {...props}
      render={routerProps => renderRoute(routerProps, componentProps)}
    />
  )
}
