import { ArrowBack as ArrowBackIcon } from '@mui/icons-material'
import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Skeleton,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import ReactPlayer from 'react-player/lazy'
import { Link as RouterLink, useHistory } from 'react-router-dom'

import {
  SurveyLandingTopicCard,
  SurveyLandingWelcomeHeader,
} from '../../components'
import anonymousPeopleImage from 'assets/images/surveyLanding/anonymous-people.png'
import answersUpdateImage from 'assets/images/surveyLanding/answers-update.png'
import dataSecurityImage from 'assets/images/surveyLanding/data-security.png'
import giftCardImage from 'assets/images/surveyLanding/gift-card.png'
import { ReactComponent as ManLookingAtSurveyQuestionsImage } from 'assets/svg/surveyLanding/man-looking-at-survey-questions-right.svg'
import beheardSurveyMobileVideo from 'assets/videos/beheard-survey-mobile.mov'
import { useUser } from 'contexts/UserContext'
import { ROUTES_PATHS } from 'core/routes'
import { UserRole } from 'enums/user'
import { t } from 'i18n'
import SurveyLanguagePicker from 'components/SurveyLanguagePicker'

export default function SurveyLandingComponent({
  openedSurvey,
  userTookOpenedSurvey,
  openedSurveyAllowAnswersToBeUpdated,
  userAllowedToTakeOpenedSurvey,
  isSurveyParticipationLoading,
}: any) {
  const topicCards = [
    {
      id: 'secure',
      description: t('surveyLanding.topicCards.secure.description'),
      keyword: t('surveyLanding.topicCards.secure.keyword'),
      image: dataSecurityImage,
      display: true,
    },
    {
      id: 'anonymous',
      description: t('surveyLanding.topicCards.anonymous.description'),
      keyword: t('surveyLanding.topicCards.anonymous.keyword'),
      image: anonymousPeopleImage,
      display: true,
    },
    {
      id: 'giftCard',
      description: t('surveyLanding.topicCards.giftCard.description'),
      keyword: t('surveyLanding.topicCards.giftCard.keyword'),
      image: giftCardImage,
      // display: true,
      display: openedSurvey && openedSurvey.allow_reward,
    },
    {
      id: 'answersUpdate',
      description: t('surveyLanding.topicCards.answersUpdate.description'),
      keyword: t('surveyLanding.topicCards.answersUpdate.keyword'),
      image: answersUpdateImage,
      display: openedSurveyAllowAnswersToBeUpdated,
    },
  ]
  const { user } = useUser()!
  const history = useHistory()

  const starhData = localStorage.getItem('sparck_company')
  const updatedData = JSON.parse(JSON.parse(JSON.stringify(starhData)));

  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('md'),
    {
      defaultMatches: true,
    },
  )

  const isDesktopLarge = useMediaQuery(
    (theme: Theme) => theme.breakpoints.up('xl'),
    {
      defaultMatches: true,
    },
  )

  return (
    <>
      <Grid container>
        <SurveyLandingWelcomeHeader />
        {isSurveyParticipationLoading ? (
          <Box mt={8} width={1}>
            <Grid container justifyContent='center'>
              <Grid item xs={12}>
                <Grid container justifyContent='center'>
                  <Skeleton
                    variant='text'
                    style={{ maxWidth: 400, width: '100%' }}
                    height={32}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent='center'>
                  <Skeleton
                    variant='text'
                    style={{ maxWidth: 400, width: '100%', marginTop: 16 }}
                    height={16}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <>
            <Grid
              container
              justifyContent='center'
              sx={{ mt: isMobile ? 2 : isDesktopLarge ? 8 : 4 }}
            >
              <Grid item xs={11} xl={9}>
                <Grid container>
                  <Grid item xs={12}>
                    <Link
                      variant='body1'
                      component='button'
                      onClick={() => history.push(ROUTES_PATHS.dashboard)}
                      sx={{ fontWeight: 500, mb: 2 }}
                    >
                      <Grid container alignItems='center'>
                        <ArrowBackIcon sx={{ mr: 1 }} /> Return to Dashboard
                      </Grid>
                    </Link>
                  </Grid>
                </Grid>
                {/* code commented as requested by Anna @ 06June2023
                https://app.asana.com/0/1204187854063133/1204751318166043
                {surveyRewardsIntentions.length > 0 && (
                  <SurveyLandingGiftCards
                    dialogReward={dialogReward}
                    setDialogReward={setDialogReward}
                    surveyRewardsIntentions={surveyRewardsIntentions}
                    isSurveyRewardsIntentionsLoading={
                      isSurveyRewardsIntentionsLoading
                    }
                    acceptRewardMutation={acceptRewardMutation}
                    isMobile={isMobile}
                  />
                )} */}
                <Grid container spacing={12}>
                  <Grid
                    item
                    xs={12}
                    lg={userAllowedToTakeOpenedSurvey ? 5 : 12}
                    xl={userAllowedToTakeOpenedSurvey ? 6 : 12}
                  >
                    <Grid
                      container
                      alignItems='center'
                      style={{ height: '100%' }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          variant={
                            !openedSurvey
                              ? 'h5'
                              : isDesktopLarge
                                ? 'h3'
                                : isMobile
                                  ? 'h5'
                                  : 'h4'
                          }
                          color='text.primary'
                          paragraph
                        >
                          {userAllowedToTakeOpenedSurvey &&
                            t('surveyLanding.areYouReady', {
                              beheard: (
                                <Typography
                                  component='span'
                                  variant={isDesktopLarge ? 'h3' : 'h4'}
                                  fontWeight={700}
                                >
                                  #BeHeard
                                </Typography>
                              ),
                            })}
                          {!userAllowedToTakeOpenedSurvey &&
                            openedSurvey &&
                            t('surveyLanding.thankYou')}
                          {!openedSurvey && t('surveyLanding.noActiveSurveys')}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          variant={isDesktopLarge ? 'h6' : 'body1'}
                          color='text.secondary'
                          sx={{ lineHeight: 2 }}
                          paragraph
                        >
                          {userAllowedToTakeOpenedSurvey && updatedData && (updatedData['id'] == 694) ?
                            <>
                              <p>{t('surveyLanding.boomiSurveyLandingContent')} </p>
                              <table style={{ border: '1px solid gray', borderCollapse: 'collapse' }}>
                                <thead>
                                  <tr>
                                    <th style={{ border: '1px solid gray', padding: '8px', minWidth: isDesktopLarge ? '200px' : '50px' }}>Term</th>
                                    <th style={{ border: '1px solid gray', padding: '8px' }}>Definition</th>

                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td style={{ border: '1px solid gray', padding: '8px' }}>
                                      <Typography
                                        variant='body2'
                                        color='text.primary'
                                        fontWeight={500}
                                        textAlign={'left'}
                                      >
                                        {t('surveyLanding.departmentTerm')}
                                      </Typography>
                                    </td>
                                    <td style={{ border: '1px solid gray', padding: '8px' }}>
                                      <Typography component='p' ml={2} variant='body1' color='text.primary'>
                                        Below are the following departments at Boomi. For further questions, please refer to the FAQs on the <b>#BeHeard Intranet Page:</b>
                                      </Typography>
                                      <ul>
                                        <Typography component='li' variant='body1' color='text.secondary'>
                                          Customer Office
                                        </Typography>
                                        <Typography component='li' variant='body1' color='text.secondary'>
                                          Engineering
                                        </Typography>
                                        <Typography component='li' variant='body1' color='text.secondary'>
                                          Finance
                                        </Typography>
                                        <Typography component='li' variant='body1' color='text.secondary'>
                                          HR
                                        </Typography>
                                        <Typography component='li' variant='body1' color='text.secondary'>
                                          IT
                                        </Typography>
                                        <Typography component='li' variant='body1' color='text.secondary'>
                                          Legal
                                        </Typography>
                                        <Typography component='li' variant='body1' color='text.secondary'>
                                          Marketing
                                        </Typography>
                                        <Typography component='li' variant='body1' color='text.secondary'>
                                          Product Group
                                        </Typography>
                                        <Typography component='li' variant='body1' color='text.secondary'>
                                          Sales
                                        </Typography>
                                        <Typography component='li' variant='body1' color='text.secondary'>
                                          Strategy
                                        </Typography>
                                        <Typography component='li' variant='body1' color='text.secondary'>
                                          Services and Support
                                        </Typography>
                                      </ul>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td style={{ border: '1px solid gray', padding: '8px' }}>
                                      <Typography
                                        variant='body2'
                                        color='text.primary'
                                        fontWeight={500}
                                      >
                                        {t('surveyLanding.missionAndValueTerm')}
                                      </Typography>
                                    </td>
                                    <td style={{ border: '1px solid gray', padding: '8px' }}>
                                      <Typography component='p' ml={2} variant='body1' color='text.secondary'>
                                        {t('surveyLanding.missionAndValueText')}
                                        <br />
                                        {t('surveyLanding.missionAndValueValue')}
                                      </Typography>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td style={{ border: '1px solid gray', padding: '8px' }}>
                                      <Typography
                                        variant='body2'
                                        color='text.primary'
                                        fontWeight={500}
                                      >
                                        {t('surveyLanding.managerTerm')}
                                      </Typography>
                                    </td>
                                    <td style={{ border: '1px solid gray', padding: '8px' }}>
                                      <Typography component='p' ml={2} variant='body1' color='text.secondary'>
                                        {t('surveyLanding.managerValue')}.
                                      </Typography>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td style={{ border: '1px solid gray', padding: '8px' }}>
                                      <Typography
                                        variant='body2'
                                        color='text.primary'
                                        fontWeight={500}
                                      >
                                        {t('surveyLanding.seniorLeadershipTeamTerm')}
                                      </Typography>
                                    </td>
                                    <td style={{ border: '1px solid gray', padding: '8px' }}>
                                      <Typography component='p' ml={2} variant='body1' color='text.secondary'>
                                        {t('surveyLanding.seniorLeadershipTeamValue')}.
                                      </Typography>
                                    </td>
                                  </tr>

                                </tbody>
                              </table>
                              <p>{t('surveyLanding.boomiSurveyLandingContentTiming')}</p>
                            </>
                            : <>
                              {userAllowedToTakeOpenedSurvey && (
                                <>
                                  {t('surveyLanding.whatBeHeardSurveyIs')}
                                  <br />
                                  {t('surveyLanding.eachItemExplanation')}
                                </>
                              )}
                            </>
                          }

                          {!userAllowedToTakeOpenedSurvey &&
                            openedSurvey &&
                            t('surveyLanding.youAreAwesome')}
                          {!openedSurvey &&
                            t('surveyLanding.feedbackImportance')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {userAllowedToTakeOpenedSurvey && (
                    <Grid item xs={12} lg={7} xl={6}>
                      <Grid
                        container
                        spacing={2}
                        alignItems='center'
                        style={{ height: '100%' }}
                      >
                        {!isMobile && (
                          <Grid item xs={12} md={5} lg={4} xl={5}>
                            <Grid container justifyContent='center'>
                              <ManLookingAtSurveyQuestionsImage />
                            </Grid>
                          </Grid>
                        )}
                        <Grid item xs={12} md={7} lg={8} xl={7}>
                          <ReactPlayer
                            url={beheardSurveyMobileVideo}
                            width='100%'
                            height={'100%'}
                            playing
                            loop
                            muted
                            playsInline
                            style={{
                              minHeight: isDesktopLarge ? 500 : '100%',
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                {/* <Box
                  sx={{
                    display: 'flex',
                    '& > :not(style)': {
                      m: 1,
                      p: 1,
                      width: 528,
                      height: 528,
                    },
                  }}
                >
                  {topicCards
                    .filter(card => card.display)
                    .map(topic => (
                      <span>
                        {userAllowedToTakeOpenedSurvey && (
                          <Grid item xs={12} md={10} lg={8} xl={12}>
                            <Grid
                              justifyContent='centre'
                              sx={{
                                p: 4,
                              }}
                            >
                              <Grid key={topic.id} item>
                                <SurveyLandingTopicCard
                                  topic={topic}
                                  isMobile={isMobile}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </span>
                    ))}
                </Box> */}

                {userAllowedToTakeOpenedSurvey && (
                  <Grid container justifyContent='center'>
                    <Grid item xs={12} md={10} lg={9} xl={12}>
                      <Grid
                        container
                        spacing={isMobile ? 4 : 12}
                        alignItems='stretch'
                        justifyContent='center'
                        sx={{
                          mt: 4,
                        }}
                      >
                        {topicCards
                          .filter(card => card.display)
                          .map(topic => (
                            <Grid key={topic.id} item xs={12} sm={6} xl={3}>
                              <SurveyLandingTopicCard
                                topic={topic}
                                isMobile={isMobile}
                              />
                            </Grid>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Box my={2}>
                  <Divider />
                </Box>

                <Grid container justifyContent='center'>
                  {userAllowedToTakeOpenedSurvey && <SurveyLanguagePicker />}
                </Grid>

                {userAllowedToTakeOpenedSurvey && (
                  <Grid container justifyContent='center' sx={{ mt: 7 }}>
                    <RouterLink to={ROUTES_PATHS.survey}>
                      <Button
                        color='primary'
                        variant='contained'
                        size='large'
                        data-cy='answer_survey'
                      >
                        {userAllowedToTakeOpenedSurvey && userTookOpenedSurvey
                          ? t('surveyLanding.updateMyAnswers')
                          : t('surveyLanding.iAmReady')}
                      </Button>
                    </RouterLink>
                  </Grid>
                )}

                {!user.roles.includes(UserRole.Employee) &&
                  !user.roles.includes(UserRole.Manager) &&
                  !openedSurvey && (
                    <Grid container justifyContent='center' sx={{ mt: 10 }}>
                      <Grid item>
                        <RouterLink to={ROUTES_PATHS.surveyOverview}>
                          <Button color='primary' variant='contained'>
                            {t('actions.create', {
                              name: t('routes.beheardSurvey'),
                            })}
                          </Button>
                        </RouterLink>
                      </Grid>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}
