import { useMemo } from 'react'

import {
  Box,
  Button,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Grid,
  Link,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { Lock as LockIcon } from '@mui/icons-material'
import { formatRelative, parseISO } from 'date-fns'
import { get } from 'lodash'
import { useHistory } from 'react-router'

import { SparckMemoriesTab } from '../SparckMemoriesComponent.tsx/SparckMemoriesComponent'
import sparckNoMediaBanner from 'assets/images/sparck-no-card.png'
import { MediaDisplay, UserAvatar } from 'components'
import { hostname } from 'core/environments'
import { ROUTES_PATHS } from 'core/routes'
import { t } from 'i18n'
import { getRecognitionMedia } from 'utils/recognition'

const useStyles = makeStyles(() =>
  createStyles({
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
  }),
)

const MAX_RECOGNIZED_USERS_TO_DISPLAY = 2

export default function SparckMemoriesCard({
  sparck,
  selectedTab,
  createSocialShare,
  socialClick,
  setSocialClick,
  socialOptions,
  company,
  visibleUserID,
}) {
  const classes = useStyles()
  const history = useHistory()

  const sparckMedia = useMemo(() => getRecognitionMedia(sparck), [sparck])

  const handleSocialClick = async social => {
    await setSocialClick({ type: social.id, uuid: '' })

    createSocialShare({ social_media: social.id, recognition_id: sparck.id })
  }

  const cardTitle =
    selectedTab === SparckMemoriesTab.Received ? (
      <Typography variant='body1' color='text.primary' fontWeight={500}>
        Recognized for{' '}
        <Typography
          component='span'
          variant='body1'
          color='text.primary'
          fontWeight={700}
        >
          {sparck?.event?.name}
        </Typography>
      </Typography>
    ) : (
      <Typography variant='body1' color='text.primary' fontWeight={500}>
        Recognized{' '}
        <Typography
          component='span'
          variant='body1'
          color='text.primary'
          fontWeight={700}
        >
          {sparck.users_recognized
            ?.filter((_, index) => index < MAX_RECOGNIZED_USERS_TO_DISPLAY)
            .map(user => user.full_name)
            .join(', ')}{' '}
          {sparck.users_recognized?.length > MAX_RECOGNIZED_USERS_TO_DISPLAY
            ? `& ${
                sparck.users_recognized?.length -
                MAX_RECOGNIZED_USERS_TO_DISPLAY
              } more`
            : ''}
        </Typography>
      </Typography>
    )

  const cardSubheader =
    selectedTab === SparckMemoriesTab.Received ? (
      <Typography variant='body2' color='text.secondary'>
        by{' '}
        <Link href={ROUTES_PATHS.profile}>
          {get(sparck, 'user_recognizing.name')}
        </Link>{' '}
        • {formatRelative(parseISO(sparck.created_at), new Date())}
      </Typography>
    ) : (
      <Typography variant='body2' color='text.secondary'>
        for{' '}
        <Typography
          component='span'
          variant='body2'
          color='text.secondary'
          fontWeight={700}
        >
          {sparck?.event?.name}
        </Typography>{' '}
        • {formatRelative(parseISO(sparck.created_at), new Date())}
      </Typography>
    )

  return (
    <>
      <Card sx={{ height: 1 }}>
        <CardHeader
          avatar={
            <UserAvatar
              userId={get(sparck, 'user_recognizing.id')}
              name={get(sparck, 'user_recognizing.name')}
              src={
                selectedTab === SparckMemoriesTab.Received
                  ? sparck?.user_recognizing?.avatar?.url
                  : sparck.users_recognized?.length === 1
                  ? sparck.users_recognized[0].avatar?.url
                  : null
              }
              size={48}
            />
          }
          title={cardTitle}
          subheader={cardSubheader}
        />

        {sparckMedia ? (
          <Box sx={{ aspectRatio: '16/9', width: 1 }}>
            <MediaDisplay media={sparckMedia} />
          </Box>
        ) : (
          <CardMedia
            className={classes.media}
            image={sparckNoMediaBanner}
            title={'No media'}
            sx={{ aspectRatio: '16/9' }}
          />
        )}

        <CardContent style={{ padding: 16 }}>
          <Grid
            container
            justifyContent={
              sparck.has_public_access ? 'flex-end' : 'space-between'
            }
            alignItems='center'
          >
            <Grid item xs={12}>
              <Grid
                container
                justifyContent='space-between'
                alignItems='center'
              >
                <Grid item>
                  <Grid container alignItems='center'>
                    {get(company, 'social_media_sharing') &&
                      socialOptions(sparck)
                        .filter(social => social.display)
                        .map((social, index) => (
                          <social.ShareIcon
                            key={social.id + index}
                            size={32}
                            round
                            // onClick={() => social.ref.current.click()}
                            onClick={() => handleSocialClick(social)}
                            style={{ marginRight: 4, cursor: 'pointer' }}
                          />
                        ))}
                  </Grid>
                </Grid>

                <Grid item>
                  <Button
                    size='small'
                    color='primary'
                    variant='contained'
                    onClick={() =>
                      history.push({
                        pathname: `${ROUTES_PATHS.sparck}/${sparck.id}`,
                        state: {
                          visibleUserID: visibleUserID,
                        },
                      })
                    }
                  >
                    {t('sparcks.sparckMemories.viewSparck')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            {!sparck.has_public_access && (
              <Typography variant='body1' color='text.secondary' sx={{ mt: 2 }}>
                <Grid container component='span' alignItems='center'>
                  <Box component='span' mr={1}>
                    <LockIcon color='primary' />
                  </Box>{' '}
                  {t('sparcks.sparckMemories.card.privateSparck')}
                </Grid>
              </Typography>
            )}
          </Grid>
        </CardContent>
      </Card>

      {get(company, 'social_media_sharing') &&
        socialOptions(sparck)
          .filter(social => social.display)
          .map((social, index) => (
            <social.ShareComponent
              {...social.props}
              key={social.id + index}
              ref={social.ref}
              url={`${hostname}/public/sparck/${socialClick.uuid}`}
              style={{ display: 'none' }}
            >
              ''
            </social.ShareComponent>
          ))}
    </>
  )
}
