import React, { useState } from 'react'

import { useQuery } from 'react-query'

import { API } from 'core/requests'

type BadgesCountContextType = {
  badgesCount: any
  setBadgesCount: (value: any) => void
  fetchBadgesCount: () => void
}
const BadgesCountContext = React.createContext<
  BadgesCountContextType | undefined
>(undefined)

type Props = {
  children: React.ReactNode
}

export const BadgesCountProvider = ({ children }: Props) => {
  const [badgesCount, setBadgesCount] = useState({})

  const { refetch: fetchBadgesCount } = useQuery(
    'getBadgesCount',
    () => API.get('badgesCount'),
    {
      refetchInterval: 60000,
      onSuccess: response => {
        if (response) setBadgesCount(response)
      },
    },
  )

  return (
    <BadgesCountContext.Provider
      value={{ badgesCount, setBadgesCount, fetchBadgesCount }}
    >
      {children}
    </BadgesCountContext.Provider>
  )
}

export const useBadgesCount = () => React.useContext(BadgesCountContext)
