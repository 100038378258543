import { get } from 'lodash'

import { isDevEnvironment } from 'core/environments'

export const getRewardName = reward =>
  get(reward, 'suggestion.name') ?? get(reward, 'name', '')

export const getRewardDescription = reward =>
  get(reward, 'suggestion.description') ?? get(reward, 'description', '')

export const shouldDisableRewardRedeem = company => {
  if (!isDevEnvironment && company?.id === 496) return true
  return false
}
