import { forwardRef, useState, useEffect, useImperativeHandle } from 'react'

import {
  Gif as GifIcon,
  Image as ImageIcon,
  Loyalty as CardIcon,
} from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import { useQuery } from 'react-query'

import {
  DialogTeleport,
  Dropzone,
  GiphyDialog,
  MediaDisplay,
  SparckCard,
} from 'components'
import { get, isEmpty } from 'lodash'
import { t } from 'i18n'
import { API } from 'core/requests'

export enum MediaType {
  Gif = 'gif',
  ObjectURL = 'objectURL',
  SparckCard = 'sparckCard',
  URL = 'url',
}

type SelectedMedia = {
  id?: number
  name?: string
  src: string | null
  isImage?: boolean
  type?: string
  file?: any
}

type Props = {
  allowGifs?: boolean
  allowImagesAndVideos?: boolean
  allowCards?: boolean
  onSelectMedia: (media: SelectedMedia) => void
}

const renderMediaButton = ({ label, Icon, onClick, isActive }) => (
  <Box py={1} px={2} data-cy='addMedia' onClick={onClick}>
    <Typography
      variant='body2'
      color={isActive ? 'primary.main' : 'text.secondary'}
      fontWeight={isActive ? 700 : 500}
      style={{ cursor: 'pointer' }}
    >
      <Grid container component='span' alignItems='center'>
        <Icon style={{ marginRight: 8 }} />
        {label}
      </Grid>
    </Typography>
  </Box>
)

const initialSelectedMedia = {
  src: null,
  isImage: false,
  name: '',
  type: '',
}

export const MediaPicker = forwardRef((props: Props, ref) => {
  const { allowGifs, allowImagesAndVideos, allowCards, onSelectMedia } = props

  const [showDropzone, setShowDropzone] = useState<boolean>(false)
  const [inputMediaURL, setInputMediaURL] = useState('')

  const [dialogGifsOpen, setDialogGifsOpen] = useState<boolean>(false)
  const [gif, setGif] = useState({ id: '', src: '' })

  const [dialogSparckCardsOpen, setDialogSparckCardsOpen] =
    useState<boolean>(false)
  const [card, setCard] = useState<any>(null)

  const [selectedMedia, setSelectedMedia] =
    useState<SelectedMedia>(initialSelectedMedia)

  const intl = useIntl()
  const { enqueueSnackbar } = useSnackbar()

  const clearSelectedMedia = () => {
    setSelectedMedia(initialSelectedMedia)
    setInputMediaURL('')
    setGif({ id: '', src: '' })
    setShowDropzone(false)
  }

  useImperativeHandle(ref, () => {
    return {
      clearSelectedMedia: clearSelectedMedia,
    }
  })

  useEffect(() => {
    setSelectedMedia({
      name: gif.id,
      src: gif.src,
      isImage: true,
      type: MediaType.Gif,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gif])

  useEffect(() => {
    setSelectedMedia({
      id: card?.id,
      name: card?.title,
      src: card?.url,
      isImage: true,
      type: MediaType.SparckCard,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card])

  useEffect(() => {
    onSelectMedia(selectedMedia)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMedia])

  return (
    <>
      <Grid container>
        {allowImagesAndVideos && showDropzone && (
          <Grid item xs={12} mb={2}>
            <Box mt={2} mb={1} width={1} data-cy='dropzone'>
              <Dropzone
                allowedFileTypes='image/*, video/mp4'
                multiple={false}
                onDrop={(acceptedFiles, fileRejections) => {
                  if (acceptedFiles.length > 0) {
                    const media = Object.assign(acceptedFiles[0], {
                      preview: URL.createObjectURL(acceptedFiles[0]),
                    })

                    setSelectedMedia({
                      src: media.preview,
                      isImage: get(media, 'type', '').startsWith('image'),
                      name: media.name,
                      type: MediaType.ObjectURL,
                      file: media,
                    })

                    setShowDropzone(false)
                  }

                  fileRejections.forEach(rejectedFile => {
                    rejectedFile.errors.forEach(error => {
                      enqueueSnackbar(error.message, {
                        variant: 'error',
                      })
                    })
                  })
                }}
                label={t('components.mediaPicker.dragAndDrop')}
              />
            </Box>

            <Grid container spacing={1} alignItems='center'>
              <Grid item xs='auto'>
                <Typography variant='body2' color='text.secondary'>
                  {t('components.mediaPicker.pasteLink')}
                </Typography>
              </Grid>

              <Grid item xs>
                <TextField
                  id='media_url'
                  variant='outlined'
                  data-cy='media_url'
                  value={inputMediaURL}
                  onChange={event => setInputMediaURL(event.target.value)}
                  placeholder={intl.formatMessage({
                    id: 'components.mediaPicker.pasteLinkPlaceholder',
                  })}
                  size='small'
                  fullWidth
                />
              </Grid>

              {inputMediaURL && (
                <Grid item xs='auto'>
                  <Button
                    variant='contained'
                    color='primary'
                    data-cy='verify_url'
                    onClick={() => {
                      setSelectedMedia({
                        name: inputMediaURL,
                        src: inputMediaURL,
                        isImage: false,
                        type: MediaType.URL,
                      })

                      setShowDropzone(false)
                    }}
                  >
                    {t('components.mediaPicker.verify')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}

        {!isEmpty(selectedMedia.src) && (
          <Grid item xs={12} key={selectedMedia.src}>
            <Box my={2} width={1} sx={{ aspectRatio: '16/9' }}>
              <MediaDisplay
                media={{
                  src: selectedMedia.src,
                  isImage: selectedMedia.isImage,
                  name: selectedMedia.name,
                  type: selectedMedia.type,
                }}
                onError={() => setSelectedMedia(initialSelectedMedia)}
                setShowDropzone={setShowDropzone}
                isPreview
              />
            </Box>
          </Grid>
        )}

        {!selectedMedia.src && (
          <>
            {allowGifs &&
              renderMediaButton({
                label: t('components.mediaPicker.addGif'),
                Icon: GifIcon,
                onClick: () => {
                  setDialogGifsOpen(!dialogGifsOpen)
                },
                isActive: dialogGifsOpen,
              })}

            {allowImagesAndVideos &&
              renderMediaButton({
                label: t('components.mediaPicker.addMedia'),
                Icon: ImageIcon,
                onClick: () => {
                  setShowDropzone(!showDropzone)
                },
                isActive: showDropzone,
              })}
            {allowCards &&
              renderMediaButton({
                label: t('components.mediaPicker.addCard'),
                Icon: CardIcon,
                onClick: () => {
                  setDialogSparckCardsOpen(!dialogSparckCardsOpen)
                },
                isActive: dialogSparckCardsOpen,
              })}
          </>
        )}
      </Grid>

      {allowGifs && (
        <GiphyDialog
          dialogOpen={dialogGifsOpen}
          setDialogOpen={setDialogGifsOpen}
          setGif={setGif}
        />
      )}

      {allowCards && (
        <DialogSparckCards
          dialogOpen={dialogSparckCardsOpen}
          setDialogOpen={setDialogSparckCardsOpen}
          setCard={setCard}
        />
      )}
    </>
  )
})

function DialogSparckCards({ dialogOpen, setDialogOpen, setCard }) {
  const {
    data: cards,
    isLoading: cardsIsLoading,
    refetch: fetchCards,
  } = useQuery('getCards', () => API.get('cards'), { enabled: false })

  useEffect(() => {
    if (dialogOpen) fetchCards()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogOpen])

  const onSelectCard = card => {
    setCard(card)
    setDialogOpen(false)
  }

  return (
    <DialogTeleport
      dialogTitle={t('components.mediaPicker.sparckCardsDialog.title')}
      dialogAction={null}
      dialogSize='lg'
      dialogOpen={dialogOpen}
      handleDialogClose={() => setDialogOpen(false)}
      isFullWidth
    >
      <Grid container spacing={2} justifyContent='center'>
        {cardsIsLoading ? (
          <CircularProgress size={40} sx={{ my: 5 }} />
        ) : isEmpty(cards) ? (
          <Box width={1} my={5}>
            <Typography align='center' variant='body1'>
              {t('components.mediaPicker.sparckCardsDialog.noCards')}
            </Typography>
          </Box>
        ) : (
          (cards ?? []).map(card => (
            <Grid key={card.id} item xs={12} sm={6} md={4} lg={3}>
              <SparckCard card={card} onSelectCard={onSelectCard} />
            </Grid>
          ))
        )}
      </Grid>
    </DialogTeleport>
  )
}
