import { useState } from 'react'

import { useMutation, useQuery } from 'react-query'
import { useSnackbar } from 'notistack'

import { VirginExperiencesOrganizationalType } from './VirginExperiencesOrganizational.props'
import { API } from 'core/requests'
import { VirginExperiencesOrganizationalComponent } from './components'
import { formatToUSD } from 'utils/currency'

export default function VirginExperiencesOrganizational({ company }) {
  const { enqueueSnackbar } = useSnackbar()

  const [virginExperiencesOrganizational, setVirginExperiencesOrganizational] =
    useState<VirginExperiencesOrganizationalType>(null)
  const [
    addVirginExperiencesToCompanyDialog,
    setAddVirginExperiencesToCompanyDialog,
  ] = useState(false)
  const [addFundsDialogOpen, setUpdateBalanceLimitDialogOpen] = useState(false)
  const [updateBalanceAlertsDialogOpen, setUpdateBalanceAlertsDialogOpen] =
    useState(false)

  const { isLoading: virginExperiencesOrganizationalIsLoading } = useQuery(
    'getVirginExperiencesOrganizational',
    () =>
      API.get('virginExperiencesOrganizational', null, {
        queryParams: {
          company_id: company?.id,
        },
      }),
    {
      enabled: true,
      onSuccess: response => {
        if (response?.company_id) {
          setVirginExperiencesOrganizational(response)
        }
      },
    },
  )

  const addVirginExperiencesToCompany = useMutation(
    (payload: any) => API.create('virginExperiencesOrganizational', payload),
    {
      onSuccess: (response: any) => {
        if (response?.company_id) {
          setVirginExperiencesOrganizational(response)
          setUpdateBalanceLimitDialogOpen(false)

          enqueueSnackbar(
            `Successfully added funds. Total: ${formatToUSD(
              response?.balance,
            )}`,
            { variant: 'success' },
          )
        }
      },
    },
  )

  const addFunds = useMutation(
    (payload: any) =>
      API.update('virginExperiencesOrganizationalAddFunds', payload),
    {
      onSuccess: (response: any) => {
        if (response?.balance) {
          setVirginExperiencesOrganizational(response)
          setUpdateBalanceLimitDialogOpen(false)

          enqueueSnackbar(
            `Successfully added funds. Total: ${formatToUSD(
              response?.balance,
            )}`,
            { variant: 'success' },
          )
        }
      },
    },
  )

  const updateBalanceAlerts = useMutation(
    (payload: any) =>
      API.update('virginExperiencesOrganizationalBalanceAlerts', payload),
    {
      onSuccess: (response: any) => {
        if (response?.balance) {
          setVirginExperiencesOrganizational(response)
          setUpdateBalanceAlertsDialogOpen(false)

          enqueueSnackbar(`Successfully updated balance alerts!`, {
            variant: 'success',
          })
        }
      },
    },
  )

  return (
    <VirginExperiencesOrganizationalComponent
      company={company}
      virginExperiencesOrganizational={virginExperiencesOrganizational}
      virginExperiencesOrganizationalIsLoading={
        virginExperiencesOrganizationalIsLoading
      }
      addFunds={addFunds}
      addFundsDialogOpen={addFundsDialogOpen}
      setUpdateBalanceLimitDialogOpen={setUpdateBalanceLimitDialogOpen}
      updateBalanceAlerts={updateBalanceAlerts}
      updateBalanceAlertsDialogOpen={updateBalanceAlertsDialogOpen}
      setUpdateBalanceAlertsDialogOpen={setUpdateBalanceAlertsDialogOpen}
      addVirginExperiencesToCompany={addVirginExperiencesToCompany}
      addVirginExperiencesToCompanyDialog={addVirginExperiencesToCompanyDialog}
      setAddVirginExperiencesToCompanyDialog={
        setAddVirginExperiencesToCompanyDialog
      }
    />
  )
}
