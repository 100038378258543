import { useQuery } from 'react-query'

import { BudgetComponent } from './components'
import { API } from 'core/requests'

export default function Budget() {
  const {
    data: budgetSettings,
    isLoading: budgetSettingsIsLoading,
    refetch: fetchBudgetSettings,
  } = useQuery('getBudgetSettings', () => API.get('getBudgetSettings'), {
    enabled: true,
  })

  return (
    <BudgetComponent
      budgetSettings={budgetSettings}
      budgetSettingsIsLoading={budgetSettingsIsLoading}
      fetchBudgetSettings={fetchBudgetSettings}
    />
  )
}
