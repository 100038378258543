import { Box, Grid, Typography } from '@mui/material'

import { ProgressBar } from 'components'
import { OnboardingPage } from 'enums/onboardingQuestion'
import { t } from 'i18n'

export default function OnboardingProgress({ pagination, pages }) {
  if (pagination !== OnboardingPage.Landing) {
    return (
      <Box mb={3}>
        <Grid
          container
          spacing={2}
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item xs={12} sm='auto'>
            <Typography variant='h5' color='text.primary' fontWeight={600}>
              {t('onboarding.onboardingProgress')}
            </Typography>
          </Grid>

          <Grid item xs={12} sm>
            <ProgressBar
              progress={[
                {
                  percentage: Math.round(
                    (pages.indexOf(pagination) * 100) / (pages.length - 1),
                  ),
                  color: 'secondary',
                },
              ]}
              showLabel
              labelVariant='body1'
              withGradient
            />
          </Grid>
        </Grid>
      </Box>
    )
  }
  return null
}
