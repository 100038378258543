import { Box, Button, Grid, Link, Typography, useTheme } from '@mui/material'

import calculatorIcon from 'assets/images/company/calculator-icon.png'
import { CardColoredWithLogo } from 'components'
import { t } from 'i18n'

export default function CompanyCostAnalysis() {
  const theme = useTheme()

  return (
    <CardColoredWithLogo
      backgroundColor1={theme.palette.primary.light}
      backgroundColor2={theme.palette.primary.dark}
      fullHeight
    >
      <Typography color='text.white' variant='h5' paragraph>
        {t('myCompany.companyCostAnalysis.cardTitle')}
      </Typography>

      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
      >
        <Grid item xs={6} sm>
          <Typography color='text.white' variant='h6'>
            {t('myCompany.companyCostAnalysis.content')}
          </Typography>
          <Box mt={3}>
            <Link
              href='https://resources.sparckco.com/the-ultimate-hr-guide-to-gain-leadership-buy-in '
              target='_blank'
              rel='noopener noreferrer'
            >
              <Button
                variant='outlined'
                size='large'
                sx={{
                  '&.MuiButton-outlined': {
                    color: 'white',
                    borderColor: 'white',
                  },
                }}
              >
                {t('myCompany.companyCostAnalysis.getStartedNow')}
              </Button>
            </Link>
          </Box>
        </Grid>

        <Grid item xs={6} sm='auto'>
          <img
            src={calculatorIcon}
            alt='Report icon'
            style={{ width: '100%', zIndex: 999, maxWidth: 160 }}
          />
        </Grid>
      </Grid>
    </CardColoredWithLogo>
  )
}
