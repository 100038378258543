import * as React from 'react'

export default function ValuingIcon(props) {
  return (
    <svg
      width={101.234}
      height={101.234}
      viewBox='0 0 101.234 101.234'
      {...props}
    >
      <defs>
        <clipPath id='prefix__a'>
          <path
            className='prefix__a'
            d='M0 17.45h39.531v-44.416H0z'
            transform='translate(0 26.966)'
          />
        </clipPath>
        <style>{'.prefix__a{fill:#fa8d25}'}</style>
      </defs>
      <g transform='translate(-509 -2341.766)'>
        <circle
          cx={50.617}
          cy={50.617}
          r={50.617}
          transform='translate(509 2341.766)'
          fill='#fff'
        />
        <g transform='translate(539 2366.966)' clipPath='url(#prefix__a)'>
          <path
            className='prefix__a'
            d='M30.735 12.792a21.77 21.77 0 002.915-5.88q.939-3.014-1.433-6.77-.5-.593-2.026 1.285t-4.199 5.139a25.321 25.321 0 01-5.139 4.941q-.3.2-2.273 1.532t-3.064 2.127q-1.09.793-2.619 2.026a13.365 13.365 0 00-2.273 2.224 3.024 3.024 0 00-.741 1.68v16.9q0 2.276 7.116 4.348a50.609 50.609 0 0014.231 2.073q2.273 0 5.287-10.476t3.014-14.034q0-1.482-2.57-2.322a28.836 28.836 0 00-5.337-1.186q-2.764-.346-2.864-.642 0-.1 1.977-2.965M4.744 16.399a7.154 7.154 0 00-3.113 2.322A7.27 7.27 0 000 23.666v11.955a7.078 7.078 0 001.631 4.892 6.855 6.855 0 003.064 2.224 10.219 10.219 0 002.718.4c.4 0 .428-.163.1-.494q-2.572-2.668-2.57-5.139V21.785a7.28 7.28 0 012.469-5.139c.46-.46.46-.692 0-.692a9.39 9.39 0 00-2.668.445'
          />
        </g>
      </g>
    </svg>
  )
}
