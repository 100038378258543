import { Grid, TextField } from '@mui/material'

import { MoneyInput } from 'components'
import { t } from 'i18n'

export default function ManageBudgetDistributionFlat({ formik, type, isDisabled }) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          data-cy='amount'
          id='amount'
          name='amount'
          label={t('budget.manageBudgetDistributionFlat.amountFieldLabel')}
          variant='outlined'
          value={+Math.abs(parseFloat(formik.values[type]?.amount)) ?? 10}
          required
          onChange={event =>
            formik.setFieldValue(type, {
              ...formik.values[type],
              amount: Math.abs(parseFloat(event?.target.value)),
            })
          }
          fullWidth
          margin='normal'
          InputProps={{
            inputComponent: MoneyInput,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
        />
      </Grid>
    </Grid>
  )
}
