import { useState } from 'react'

import { Button, Chip, Grid, Link, Typography, useTheme } from '@mui/material'
import { teal } from '@mui/material/colors'
import {
  Check as CheckIcon,
  Clear as CancelIcon,
  NoteAltOutlined as NoteIcon,
  QuestionAnswerOutlined as AnswersIcon,
} from '@mui/icons-material'
import { get } from 'lodash'
import { GoMegaphone as SuggestionsIcon } from 'react-icons/go'
import { useIntl } from 'react-intl'
import { useMutation } from 'react-query'

import {
  SuggestionAssigneDialog,
  SuggestionNotesDialog,
  SuggestionResolveDialog,
} from './components'
import { CompanyAdminLayout, DialogTeleport } from 'components'
import { API } from 'core/requests'
import { initialDialog } from 'constants/dialog'
import { getSuggestionsStatusDetails } from 'constants/suggestions'
import { useUser } from 'contexts'
import { AssignMode } from 'enums/suggestion'
import { UserPermission } from 'enums/user'
import { SuggestionChat } from 'pages'
import { getDateLabel } from 'utils/tables/dataTypes'

const renderStatus = (rowData: any, theme: any) => (
  <Chip
    style={{
      fontWeight: 600,
      backgroundColor: getSuggestionsStatusDetails(theme)[rowData.status].color,
      color: '#FFF',
      marginBottom: 0,
    }}
    label={getSuggestionsStatusDetails(theme)[rowData.status].label}
  />
)

const renderHaveReplied = (rowData: any, theme: any) => (
  <span
    style={{
      fontWeight: 600,
      color:
        (rowData.replies ?? []).length > 0
          ? theme.palette.primary.main
          : theme.palette.error.light,
    }}
  >
    {(rowData.replies ?? []).length > 0 ? 'Yes' : 'No'}
  </span>
)

export default function Suggestions() {
  const { user } = useUser()!
  const theme = useTheme()
  const intl = useIntl()

  const [suggestionAcceptDialog, setSuggestionAcceptDialog] =
    useState(initialDialog)
  const [suggestionDeclineDialog, setSuggestionDeclineDialog] =
    useState(initialDialog)
  const [suggestionChatDialog, setSuggestionChatDialog] =
    useState(initialDialog)
  const [suggestionNotesDialog, setSuggestionNotesDialog] =
    useState(initialDialog)
  const [suggestionAssigneDialog, setSuggestionAssigneDialog] =
    useState<any>(initialDialog)

  return (
    <>
      <CompanyAdminLayout
        slug='suggestions'
        title={intl.formatMessage({ id: 'suggestions.title' })}
        description={intl.formatMessage({ id: 'suggestions.description' })}
        color={teal[500]}
        requestUrl='suggestionsBoxIdeas'
        filters={[
          {
            id: 'status',
            label: 'Status',
            defaultValue: ['pending'],
            values: [
              {
                value: 'pending',
                label: intl.formatMessage({
                  id: 'statuses.pending',
                }),
              },
              {
                value: 'accepted',
                label: intl.formatMessage({
                  id: 'statuses.accepted',
                }),
              },
              {
                value: 'declined',
                label: intl.formatMessage({
                  id: 'statuses.declined',
                }),
              },
            ],
          },
        ]}
        tableProps={{
          columns: [
            {
              title: intl.formatMessage({ id: 'suggestions.fields.date' }),
              field: 'created_at',
              render: (rowData: any) => getDateLabel(rowData.created_at),
              editable: 'never',
            },
            {
              title: intl.formatMessage({ id: 'suggestions.fields.from' }),
              field: 'user.name',
              render: (rowData: any) => get(rowData, 'user.name', 'Anonymous'),
              editable: 'never',
            },
            {
              title: intl.formatMessage({
                id: 'suggestions.fields.suggestion',
              }),
              field: 'description',
              editable: 'never',
              filtering: true,
            },
            {
              title: intl.formatMessage({
                id: 'suggestions.fields.haveYouReplied',
              }),
              field: 'replies',
              render: rowData => renderHaveReplied(rowData, theme),
              editable: 'never',
            },
            {
              title: intl.formatMessage({ id: 'status' }),
              field: 'status',
              render: rowData => renderStatus(rowData, theme),
              editable: 'never',
            },
            {
              title: 'Assignee',
              field: 'assignee',
              render: (rowData: any) =>
                rowData?.assignee ? (
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant='body2' color='text.primary'>
                        {rowData?.assignee?.full_name}
                      </Typography>
                    </Grid>

                    {user?.id === rowData?.assignee?.id && (
                      <Grid item xs={12}>
                        <Button
                          color='primary'
                          size='small'
                          sx={{ px: 0 }}
                          onClick={() =>
                            setSuggestionAssigneDialog({
                              isOpen: true,
                              data: {
                                id: rowData?.id,
                                assignMode: AssignMode.Unassign,
                              },
                            })
                          }
                        >
                          Unassign
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                ) : (
                  <Typography sx={{ minWidth: 160 }}>
                    <Link
                      onClick={() =>
                        setSuggestionAssigneDialog({
                          isOpen: true,
                          data: {
                            id: rowData?.id,
                            assignMode: AssignMode.Assign,
                          },
                        })
                      }
                    >
                      Assign to Me
                    </Link>
                  </Typography>
                ),
              editable: 'never',
              width: 300,
            },
          ],
          ...((user.permissions ?? []).includes(
            UserPermission.ManageSuggestions,
          ) && {
            actions: [
              {
                icon: () => <AnswersIcon />,
                tooltip: () =>
                  intl.formatMessage({ id: 'suggestions.manageSuggestion' }),
                onClick: (_, suggestion: any) =>
                  setSuggestionChatDialog({
                    isOpen: true,
                    data: suggestion,
                  }),
              },
              {
                icon: () => <NoteIcon />,
                tooltip: () => 'Suggestion Notes (for company admins only)',
                onClick: (_, suggestion: any) =>
                  setSuggestionNotesDialog({ isOpen: true, data: suggestion }),
              },
              {
                icon: () => <CancelIcon color='error' />,
                tooltip: () =>
                  intl.formatMessage({ id: 'suggestions.decline' }),
                onClick: (_, suggestion: any) =>
                  setSuggestionDeclineDialog({
                    isOpen: true,
                    data: suggestion,
                  }),
              },
              {
                icon: () => <CheckIcon color='primary' />,
                tooltip: () => intl.formatMessage({ id: 'suggestions.accept' }),
                onClick: (_, suggestion: any) =>
                  setSuggestionAcceptDialog({ isOpen: true, data: suggestion }),
              },
            ],
          }),
          editActionProps: {
            hidden: () => true,
          },
          deleteActionProps: {
            hidden: () => true,
          },
        }}
        managementPermission={UserPermission.ManageSuggestions}
        IconHeader={SuggestionsIcon}
      >
        {({ fetchData }) => (
          <SuggestionsDialogs
            intl={intl}
            fetchSuggestions={fetchData}
            suggestionAcceptDialog={suggestionAcceptDialog}
            setSuggestionAcceptDialog={setSuggestionAcceptDialog}
            suggestionDeclineDialog={suggestionDeclineDialog}
            setSuggestionDeclineDialog={setSuggestionDeclineDialog}
            suggestionChatDialog={suggestionChatDialog}
            setSuggestionChatDialog={setSuggestionChatDialog}
            suggestionAssigneDialog={suggestionAssigneDialog}
            setSuggestionAssigneDialog={setSuggestionAssigneDialog}
            suggestionNotesDialog={suggestionNotesDialog}
            setSuggestionNotesDialog={setSuggestionNotesDialog}
          />
        )}
      </CompanyAdminLayout>
    </>
  )
}

function SuggestionsDialogs({
  intl,
  fetchSuggestions,
  suggestionAcceptDialog,
  setSuggestionAcceptDialog,
  suggestionDeclineDialog,
  setSuggestionDeclineDialog,
  suggestionChatDialog,
  setSuggestionChatDialog,
  suggestionAssigneDialog,
  setSuggestionAssigneDialog,
  suggestionNotesDialog,
  setSuggestionNotesDialog,
}) {
  const [refetchSuggestion, setRefetchSuggestion] = useState(false)

  const acceptSuggestionMutation = useMutation(
    (payload: any) => API.update('acceptSuggestion', payload),
    {
      onSuccess: (response: any) => {
        if (response.id) {
          setSuggestionAcceptDialog(initialDialog)
          fetchSuggestions()
        }
      },
    },
  )

  const declineSuggestionMutation = useMutation(
    (payload: any) => API.update('declineSuggestion', payload),
    {
      onSuccess: (response: any) => {
        if (response.id) {
          setSuggestionDeclineDialog(initialDialog)
          fetchSuggestions()
        }
      },
    },
  )

  return (
    <>
      <SuggestionResolveDialog
        dialog={suggestionAcceptDialog}
        setDialog={setSuggestionAcceptDialog}
        mutate={acceptSuggestionMutation.mutateAsync}
        mutateIsLoading={acceptSuggestionMutation.isLoading}
        actionLabel={intl.formatMessage({ id: 'suggestions.accept' })}
        actionButtonColor='primary'
        setRefetchSuggestion={setRefetchSuggestion}
        setSuggestionChatDialog={setSuggestionChatDialog}
      />

      <SuggestionResolveDialog
        dialog={suggestionDeclineDialog}
        setDialog={setSuggestionDeclineDialog}
        mutate={declineSuggestionMutation.mutateAsync}
        mutateIsLoading={declineSuggestionMutation.isLoading}
        actionLabel={intl.formatMessage({ id: 'suggestions.decline' })}
        actionButtonColor='primary'
        setRefetchSuggestion={setRefetchSuggestion}
        setSuggestionChatDialog={setSuggestionChatDialog}
      />

      <DialogTeleport
        dialogOpen={suggestionChatDialog.isOpen}
        handleDialogClose={() => setSuggestionChatDialog(initialDialog)}
        dialogTitle={intl.formatMessage({ id: 'routes.suggestionChat' })}
        dialogSize='lg'
      >
        <SuggestionChat
          suggestionId={suggestionChatDialog.data?.id}
          setSuggestionAcceptDialog={setSuggestionAcceptDialog}
          setSuggestionDeclineDialog={setSuggestionDeclineDialog}
          refetchSuggestion={refetchSuggestion}
          setRefetchSuggestion={setRefetchSuggestion}
          fromDialog
        />
      </DialogTeleport>

      <SuggestionAssigneDialog
        dialog={suggestionAssigneDialog}
        setDialog={setSuggestionAssigneDialog}
        fetchSuggestions={fetchSuggestions}
      />

      <SuggestionNotesDialog
        dialog={suggestionNotesDialog}
        setDialog={setSuggestionNotesDialog}
      />
    </>
  )
}
