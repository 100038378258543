import React, { useState } from 'react'

import { useSnackbar } from 'notistack'
import { useMutation } from 'react-query'

import { ExternalRewardsUploadComponent } from './components'
import { API } from 'core/requests'
import { t } from 'i18n'

type Props = {
  setDialogBulkUpload?: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ExternalRewardsUpload(props: Props | any) {
  const { setDialogBulkUpload } = props

  const [loading, setLoading] = useState(false)
  const [uploadErrors, setUploadErrors] = useState<any[]>([])
  const [importedRewards, setImportedRewards] = useState<number>(0)

  const { enqueueSnackbar } = useSnackbar()

  const uploadSpreadsheet = useMutation(
    (payload: any) => API.createOrUpdate('uploads', payload),
    {
      onSettled: async (response: any) => {
        if (response && response.id) {
          setUploadErrors([])
          importSpreadsheet.mutate()
        }
      },
      onError: () => {
        setLoading(false)
      },
    },
  )

  const importSpreadsheet = useMutation(
    () => API.create('importExternalRewards'),
    {
      onSettled: (response: any) => {
        if (response && !response.errors.length) {
          if (setDialogBulkUpload) setDialogBulkUpload(false)

          enqueueSnackbar(t('employeesUpload.employeesImportedSuccessfully'), {
            variant: 'success',
          })
        } else {
          setLoading(false)

          if (response.errors) {
            const fatalError = response.errors.find(
              error => error.type === 'fatal',
            )

            if (fatalError) {
              if (setDialogBulkUpload) setDialogBulkUpload(false)
              enqueueSnackbar(fatalError.message, {
                variant: 'error',
                persist: true,
              })
            } else {
              setUploadErrors(response.errors)
            }
          }
          setImportedRewards(response)
        }
      },
    },
  )

  return (
    <ExternalRewardsUploadComponent
      uploadSpreadsheet={uploadSpreadsheet}
      loading={loading}
      setLoading={setLoading}
      uploadErrors={uploadErrors}
      importedRewards={importedRewards}
    />
  )
}
