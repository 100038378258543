import {
  SPARCK_COMPANY,
  SPARCK_SPOOFED_COMPANY,
  SPARCK_SPOOFED_USER_ID,
} from 'constants/app'

export const getSpoofedUserIdFromLocalStorage = () => {
  return localStorage.getItem(SPARCK_SPOOFED_USER_ID)
}

export const getUserCompanyFromLocalStorage = () => {
  if (localStorage.getItem(SPARCK_COMPANY)){
    return JSON.parse(localStorage.getItem(SPARCK_COMPANY)!)
  }else{
    return null;
  }
}

export const getSpoofedCompanyFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem(SPARCK_SPOOFED_COMPANY)!)
}

export const isCompanySpoofedMode = (company): boolean => {
  const spoofedCompany = getUserCompanyFromLocalStorage()

  return company && spoofedCompany && company?.id !== spoofedCompany?.id
}

export const isUserSpoofedMode = (): boolean =>
  !!getSpoofedUserIdFromLocalStorage()
