import { useTheme } from '@mui/material'
import { Loyalty as CoreValuesIcon } from '@mui/icons-material'

import ManageCoreValue from './manageCoreValue/ManageCoreValue'
import { CompanyAdminLayout } from 'components'
import { UserPermission } from 'enums/user'
import { useTranslation } from 'i18n'

export default function CoreValues() {
  const theme = useTheme()

  return (
    <>
      <CompanyAdminLayout
        slug='coreValues'
        title={useTranslation('coreValues.title')}
        description={useTranslation('coreValues.headerDescription', {
          link: (
            <a
              href='https://faq.sparckco.com/knowledge/understanding-core-values'
              rel='noopener noreferrer'
              target='_blank'
              style={{ color: '#FFF', fontWeight: 700 }}
            >
              {useTranslation('linkHere')}
            </a>
          ),
        })}
        color={theme.palette.secondary.dark}
        requestUrl='coreValues'
        tableProps={{
          columns: [
            {
              title: useTranslation('name'),
              field: 'name',
              editable: 'never',
            },
            {
              title: useTranslation('description'),
              field: 'description',
              editable: 'never',
            },
          ],
        }}
        managementPermission={UserPermission.ManageCoreValues}
        ManagementForm={ManageCoreValue}
        IconHeader={CoreValuesIcon}
      />
    </>
  )
}
