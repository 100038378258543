import { DemographicCategory } from 'enums/demographic'

export const allowedDemographicsCategories = [
  DemographicCategory.BusinessUnit,
  DemographicCategory.Custom,
  DemographicCategory.Department,
  DemographicCategory.Location,
  DemographicCategory.Region,
  DemographicCategory.Territory,
]
