import { useState } from 'react'

import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import { useMutation, useQuery } from 'react-query'
import * as yup from 'yup'

import { ManageBudgetForm } from './components'
import { FormHandler } from 'components'
import { API } from 'core/requests'
import {
  BudgetNewEmployeesBudgetType,
  BudgetPeriod,
  BudgetProrateType,
  BudgetStatus,
  BudgetYearType,
} from 'enums/budget'
import { t } from 'i18n'
import { BudgetType } from 'types'

const validationSchema = yup.object({
  period: yup
    .string()
    .required(t('budget.manageBudget.validations.periodRequired')),
  year_start_date: yup
    .date()
    .nullable()
    .when('year_type', {
      is: BudgetYearType.Fiscal,
      then: yup
        .date()
        .nullable()
        .typeError(t('budget.manageBudget.validations.startDate.invalidDate'))
        .required(t('budget.manageBudget.validations.startDate.required')),
    }),
})

export default function ManageBudget({
  dialog,
  closeDialog,
  budgetSettings,
  fetchBudgetSettings,
}: any) {
  const intl = useIntl()

  const { enqueueSnackbar } = useSnackbar()
  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;
  const [isDisabled, setIsDisabled] = useState(true);

  // const initialValues: BudgetType = dialog?.data    //old condition for initialValues
  //   ? dialog?.data
  const initialValues: BudgetType = budgetSettings?.status === BudgetStatus.BudgetEnabled    //change condition for new design
    ? budgetSettings
    : {
      has_spending_limit: false,
      has_receiving_limit: true, //set default vlu (true) for Receiving Based Limit checked
      new_employees_budget_type: BudgetNewEmployeesBudgetType.Manual, //change new_employees_budget_type type Automatic to Manual
      new_employees_base_amount: 0,//set default new_employees_base_amount 0
      year_type: BudgetYearType.Calendar, //change Calendar type Fiscal to Calendar
      year_start_date: new Date(`12/31/${previousYear}`), //set default year_start_date
      year_end_date: new Date(`12/31/${currentYear}`),//set default year_end_date
      period: BudgetPeriod.Quarterly,
      has_prorate: false,
      prorate_type: BudgetProrateType.NoBudgetInPeriod,
      enforce_manager_sending_limit: false,
      enforce_employee_receiving_limit: false,
      spending: {
        distribution: '',
        amount: null,
        rollover_per_period: false,
        rollover_annually: false,
      },
      receiving: {
        distribution: 'flat',  //set default vlu (flat/demographics) for RECEIVING RULES checked
        amount: null,
        rollover_per_period: false,
        rollover_annually: false,
      },
    }

  const [spendingBudgetPerEmployee, setSpendingBudgetPerEmployee] =
    useState(null)
  const [receivingBudgetPerEmployee, setReceivingBudgetPerEmployee] =
    useState(null)

  const { data: demographics, isLoading: demographicsIsLoading } = useQuery(
    'getDemographics',
    () => API.get('demographics'),
  )

  // const { data: roles, isLoading: rolesIsLoading } = useQuery('getRoles', () =>
  //   API.get('roles'),
  // )

  const updateBudgetSettingsMutation = useMutation(
    (payload: any) => API.update('updateBudgetSettings', payload),
    {
      onSuccess: (response: any, payload: any) => {
        if (response.status) {
          fetchBudgetSettings()
          // closeDialog()  //no need to close dialog

          if(!payload?.isEnforceManagerSpendingLimit){
            // toggleIsDisabled(isDisabled)
          }
          
          enqueueSnackbar(
            intl.formatMessage({
              id: 'budget.updateBudgetSettingsSnackbarSuccess',
            }),
            {
              variant: 'success',
            },
          )
        }
      },
    },
  )

  const toggleIsDisabled = (isDisabled: any) => {
    setIsDisabled(!isDisabled);
  };

  return (
    <>
      <FormHandler
        title={intl.formatMessage({
          id: 'budget.manageBudget.formHandlerTitle',
        })}
        descriptionCreateMode={intl.formatMessage({
          id: 'budget.manageBudget.description',
        })}
        requestUrl='updateBudgetSettings'
        detailsRequestId={get(dialog, 'data.id')}
        detailsRequestCallback={() => { }}
        data={dialog?.data}
        closeDialog={closeDialog}
        fetchRows={fetchBudgetSettings}
        initialValues={initialValues}
        validationSchema={validationSchema}
        hideActionButton
        hideHeader
      >
        {({ formik, isEditMode }) => (
          <ManageBudgetForm
            title={intl.formatMessage({
              id: 'budget.manageBudget.budgetFormTitle',
            })}
            formik={formik}
            updateBudgetSettingsMutation={updateBudgetSettingsMutation}
            isEditMode={isEditMode}
            spendingBudgetPerEmployee={spendingBudgetPerEmployee}
            setSpendingBudgetPerEmployee={setSpendingBudgetPerEmployee}
            receivingBudgetPerEmployee={receivingBudgetPerEmployee}
            setReceivingBudgetPerEmployee={setReceivingBudgetPerEmployee}
            budgetSettings={budgetSettings}
            demographics={demographics}
            demographicsIsLoading={demographicsIsLoading}
            // roles={roles}
            // rolesIsLoading={rolesIsLoading}
            toggleIsDisabled={toggleIsDisabled}
            isDisabled={isDisabled}
          />
        )}
      </FormHandler>
    </>
  )
}
