import { Box, Grid, Typography } from '@mui/material'

import { useUser } from 'contexts/UserContext'
import { t } from 'i18n'
import { getPartOfDay } from 'utils/date'
import { useLocale } from 'contexts'

export default function CompanyHeader() {
  const { user } = useUser()!
  const { locale }: any = useLocale()

  return (
    <>
      <Grid item xs={12}>
        <Box mt={3} mb={5}>
          <Typography color='text.primary' variant='h4'>
            {t('myCompany.greetings.good')} {getPartOfDay(locale)},{' '}
            {user.first_name}. {t('myCompany.greetings.welcomeBack')}
          </Typography>
        </Box>
      </Grid>
    </>
  )
}
