import { Box, Grid, Grow, Typography } from '@mui/material'

import sparckHeaderBottomCurved from 'assets/images/sparck/sparck-header-bottom-curved.png'
import { t } from 'i18n'

export default function SparckHeader() {
  return (
    <Grid container justifyContent='center'>
      <Grid item xs={12}>
        <Box
          component='div'
          sx={{
            background: `linear-gradient(
            270deg,
            rgba(26, 207, 198, 0.2) 0%,
            rgba(242, 152, 58, 0.2) 100%
          )`,
            position: 'relative',
          }}
        >
          <Box width={1} py={8}>
            <Grow in={true} timeout={1200}>
              <Typography
                component='h1'
                variant='h3'
                color='text.primary'
                align='center'
                fontWeight={600}
              >
                {t('sparcks.sparck.header')}
              </Typography>
            </Grow>
          </Box>
        </Box>
        <Box
          component='img'
          sx={{
            width: '100%',
            height: '80px',
            position: 'absolute',
          }}
          alt='Sparck header bottom curve'
          src={sparckHeaderBottomCurved}
        />
      </Grid>
    </Grid>
  )
}
