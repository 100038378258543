import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { UseMutationResult } from 'react-query'

import { Dropzone } from 'components'
import { useCompany } from 'contexts'
import { downloadExternalRewardsTemplate } from 'core/requests'
import { t } from 'i18n'
import { EmployeeUploadError } from 'types'

type Props = {
  uploadSpreadsheet: UseMutationResult
  loading: boolean
  setLoading(value: boolean): void
  uploadErrors: EmployeeUploadError[]
  importedRewards: number
}

export default function ExternalRewardsUploadComponent(props: Props) {
  const {
    uploadSpreadsheet,
    loading,
    setLoading,
    uploadErrors,
    importedRewards,
  } = props

  const { enqueueSnackbar } = useSnackbar()
  const { company } = useCompany()!

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={1}>
          <Typography variant='h5' color='text.primary' paragraph>
            {t('externalRewardsUpload.title')}
          </Typography>

          <Typography variant='body2' color='text.secondary' paragraph>
            {t('externalRewardsUpload.description')}
          </Typography>

          <Typography variant='body2' color='text.secondary' paragraph>
            {t('externalRewardsUpload.selectAppropriateEvents')}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} sx={{ mb: 2, mt: 1 }}>
        <Button color='primary' onClick={downloadExternalRewardsTemplate}>
          {t('externalRewardsUpload.downloadExcelTemplate')}
        </Button>
      </Grid>

      <Grid item xs={12} data-cy='dropzone'>
        {loading ? (
          <Grid container spacing={2} justifyContent='center' sx={{ my: 3 }}>
            <Grid item xs='auto'>
              <CircularProgress size={64} />
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant='body1'
                color='text.secondary'
                align='center'
                fontWeight={600}
              >
                {t('employeesUpload.importingYourSpreadsheet')}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <>
            <Dropzone
              allowedFileTypes='.xlsx'
              multiple={false}
              onDrop={(acceptedFiles, fileRejections) => {
                if (acceptedFiles.length > 0) {
                  const formData = new FormData()

                  formData.append('file', acceptedFiles[0])
                  formData.append('attach_to', 'Company')
                  formData.append('record_id', company.id)
                  formData.append('record_field', 'rewards_spreadsheet')

                  setLoading(true)

                  uploadSpreadsheet.mutate(formData)
                }

                fileRejections.forEach(rejectedFile => {
                  rejectedFile.errors.forEach(error => {
                    enqueueSnackbar(error.message, {
                      variant: 'error',
                    })
                  })
                })
              }}
              label={t('employeesUpload.dragAndDrop')}
            />

            {importedRewards > 0 && uploadErrors.length > 0 && (
              <Alert
                severity='warning'
                sx={{
                  my: 2,
                }}
              >
                {t('components.employeesUploadComponent.warningMessage', {
                  importedEmployees: importedRewards,
                })}
              </Alert>
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
}
