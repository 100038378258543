import { useState, useEffect, useRef } from 'react'
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
} from 'react-share'
import { useMutation, useQuery } from 'react-query'

import { SparckMemoriesComponent } from './components'
import { API } from 'core/requests'
import { useCompany } from 'contexts'
import { get } from 'lodash'
import { useLocation } from 'react-router-dom'

type Location = {
  state: {
    visibleUserID: string
  }
}

export default function SparckMemories() {
  const { state } = useLocation() as Location

  const {
    data: receivedSparcks,
    isLoading: receivedSparcksAreLoading,
    refetch: fetchReceivedSparcks,
  } = useQuery(
    'getReceivedSparcks',

    () =>
      API.get('sparckMemories', null, {
        queryParams: { user_id: state?.visibleUserID },
      }),
    { enabled: true },
  )

  const {
    data: sentSparcks,
    refetch: fetchSentSparcks,
    isLoading: sentSparcksAreLoading,
  } = useQuery(
    'getSentSparcks',

    () =>
      API.get('sparcksSent', null, {
        queryParams: { user_id: state?.visibleUserID },
      }),
    { enabled: false },
  )

  const facebookRef = useRef<any>(null)
  const twitterRef = useRef<any>(null)
  const linkedinRef = useRef<any>(null)

  const { company } = useCompany()!

  const getSocialMessage = sparck =>
    `I was Sparcked by ${get(sparck, 'owner.name')} for ${
      sparck.title
    }. Take a look!`

  const socialOptions = (sparck = null) => [
    {
      id: 'facebook',
      ref: facebookRef,
      ShareComponent: FacebookShareButton,
      ShareIcon: FacebookIcon,
      display: get(company, 'social_media_sharing_on_facebook'),
      props: {
        quote: sparck && getSocialMessage(sparck),
        hashtag: 'Sparck',
      },
    },
    {
      id: 'twitter',
      ref: twitterRef,
      ShareComponent: TwitterShareButton,
      ShareIcon: TwitterIcon,
      display: get(company, 'social_media_sharing_on_twitter'),
      props: {
        title: sparck && getSocialMessage(sparck),
        hashtags: ['Sparck', 'recognition'],
      },
    },
    {
      id: 'linkedin',
      ref: linkedinRef,
      ShareComponent: LinkedinShareButton,
      ShareIcon: LinkedinIcon,
      display: get(company, 'social_media_sharing_on_linkedin'),
      props: {
        title: 'Sparck',
        summary: sparck && getSocialMessage(sparck),
      },
    },
  ]

  const [socialClick, setSocialClick] = useState({
    type: '',
    uuid: '',
  })

  const socialShareMutation = useMutation(
    (payload: any) => API.create('createSocialShare', payload),
    {
      onSettled: (response: any) => {
        if (response.id) {
          setSocialClick({
            type: socialClick.type,
            uuid: response.uuid,
          })
        }
      },
    },
  )

  useEffect(() => {
    if (socialClick.type && socialClick.uuid) {
      const selectedSocialOption: any = socialOptions().find(
        social => social.id === socialClick.type,
      )

      selectedSocialOption.ref.current.click()

      setSocialClick({ type: '', uuid: '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialClick])

  return (
    <SparckMemoriesComponent
      receivedSparcks={receivedSparcks || []}
      fetchReceivedSparcks={fetchReceivedSparcks}
      receivedSparcksAreLoading={receivedSparcksAreLoading}
      sentSparcks={sentSparcks || []}
      fetchSentSparcks={fetchSentSparcks}
      sentSparcksAreLoading={sentSparcksAreLoading}
      createSocialShare={socialShareMutation.mutate}
      socialClick={socialClick}
      setSocialClick={setSocialClick}
      socialOptions={socialOptions}
      company={company}
      visibleUserID={state?.visibleUserID}
    />
  )
}
