import { Box, Card, CardContent, Grid, Typography } from '@mui/material'

import { getRecognitionStyle } from 'constants/recognitionStyle'
import { t } from 'i18n'

export default function SparckAppreciationAssessmentResults({
  assessmentResults,
}) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          variant='h6'
          color='text.secondary'
          align='center'
          paragraph
        >
          {t('sparckAppreciationAssessment.results.checkYourEmail')}
        </Typography>
      </Grid>

      {assessmentResults?.slice(0, 2).map(result => {
        const recognitionStyle = getRecognitionStyle[result.letter]

        return (
          <Grid item xs={12} key={recognitionStyle.id}>
            <Grid
              container
              justifyContent='center'
              sx={{
                backgroundImage: `url(${recognitionStyle.backgroundImage})`,
                pt: 6,
                mb: 3,
              }}
            >
              <Grid item xs={11} md={9} lg={8} key={recognitionStyle.id}>
                <Card
                  elevation={0}
                  sx={{ height: '100%', borderRadius: '1rem 1rem 0rem 0rem' }}
                >
                  <Box
                    sx={{
                      py: 1.5,
                      px: 3,
                      ml: 3,
                      mt: 3,
                      bgcolor: 'warning.dark',
                      borderRadius: '8px 0px 0px 8px',
                    }}
                  >
                    <Typography
                      variant='body2'
                      color='white'
                      sx={{
                        textTransform: 'uppercase',
                        fontWeight: 600,
                      }}
                    >
                      {t(
                        'sparckAppreciationAssessment.results.sparckAppreciationStyle',
                      )}
                    </Typography>
                  </Box>
                  <CardContent>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          variant='h5'
                          color={
                            recognitionStyle.id ===
                            'recognition_style_undefined'
                              ? 'text.light'
                              : 'primary.dark'
                          }
                          sx={{
                            textTransform: 'uppercase',
                            fontSize: '1.75rem !important',
                          }}
                          fontWeight={600}
                        >
                          {recognitionStyle.name}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} mt={2}>
                        <Typography
                          variant='body1'
                          color='text.secondary'
                          align='center'
                          fontWeight={300}
                          style={{
                            textAlign: 'justify',
                            fontSize: '1rem',
                            lineHeight: '1.75rem',
                          }}
                        >
                          {result.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        )
      })}
    </Grid>
  )
}
