import { Box, Grid, Typography, useTheme } from '@mui/material'

import { EMOJIS, STARS } from 'constants/survey'
import { t } from 'i18n'
import { isQuestionAnswered } from 'utils/survey'

export default function RatingItemAnswered(props: any) {
  const { accessor, handleRating, surveyQuestion, renderItem } = props

  const theme = useTheme()

  const ratingItem = (
    accessor === 'satisfactionScore' ? EMOJIS(theme) : STARS(theme)
  ).find(rating => rating.value === surveyQuestion[accessor])

  return (
    <Grid
      item
      key={accessor}
      style={{
        position: 'relative',
        width: isQuestionAnswered(surveyQuestion) ? 130 : 48,
      }}
    >
      <Grid container direction='column'>
        <Grid item>
          <Grid container justifyContent='center'>
            {renderItem(
              ratingItem,
              0,
              accessor,
              isQuestionAnswered(surveyQuestion) ? 'large' : 'small',
            )}
          </Grid>
        </Grid>

        {ratingItem && isQuestionAnswered(surveyQuestion) && (
          <Grid item>
            <Box
              component='div'
              sx={{
                margin: '0px 8px',
                textAlign: 'center',
              }}
            >
              <Typography
                component='span'
                color='text.secondary'
                variant='body2'
                align='center'
              >
                {ratingItem.description}
                <Box mx={1}>
                  <Typography
                    data-cy='changeButton'
                    component='span'
                    color='primary.main'
                    variant='body2'
                    fontWeight='500'
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleRating(0, accessor)}
                  >
                    {t('ratingSection.change')}
                  </Typography>
                </Box>
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
