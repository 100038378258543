import { Box, Button, Grid, Link, Typography, useTheme } from '@mui/material'

import resourcesIcon from 'assets/images/company/resources-icon.png'
import { CardColoredWithLogo } from 'components'
import { t } from 'i18n'

export default function CompanyFreeResources() {
  const theme = useTheme()

  return (
    <CardColoredWithLogo
      backgroundColor1={theme.palette.secondary.light}
      backgroundColor2={theme.palette.secondary.dark}
      fullHeight
    >
      <Typography color='text.white' variant='h5' paragraph>
        {t('myCompany.companyFreeResources.cardTitle')}
      </Typography>

      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
      >
        <Grid item xs={6} sm>
          <Typography color='text.white' variant='h6'>
            {t('myCompany.companyFreeResources.content')}
          </Typography>
          <Box mt={3}>
            <Link
              href='https://resources.sparckco.com/how-to-get-the-best-beheard-survey-results'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Button
                variant='outlined'
                size='large'
                sx={{
                  '&.MuiButton-outlined': {
                    color: 'white',
                    borderColor: 'white',
                  },
                }}
              >
                {t('myCompany.companyFreeResources.letsDoIt')}
              </Button>
            </Link>
          </Box>
        </Grid>

        <Grid item xs={6} sm='auto'>
          <img
            src={resourcesIcon}
            alt='Report icon'
            style={{ width: '100%', zIndex: 999, maxWidth: 160 }}
          />
        </Grid>
      </Grid>
    </CardColoredWithLogo>
  )
}
