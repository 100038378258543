import { Box, Grid, Typography, useMediaQuery, Theme } from '@mui/material'

import { ManagerCertificationSection } from '../../components'
import { t } from 'i18n'
import { getManagerCertificationVideoURL } from 'utils/managerCertification'

export default function ManagerCertificationComponent() {
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  )

  return (
    <>
      <ManagerCertificationSection>
        <Grid
          container
          spacing={isSmallScreen ? 1 : 2}
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={6}>
            <Typography
              color='text.primary'
              variant='h5'
              fontWeight={600}
              textAlign='left'
              paragraph
            >
              {t(
                'managerCertification.managerCertificationComponent.sections.realLifeSparckStory.title',
              )}
            </Typography>
            <Typography
              color='text.secondary'
              variant='body1'
              fontWeight={700}
              mb={1}
            >
              {t(
                'managerCertification.managerCertificationComponent.sections.realLifeSparckStory.subtitle',
              )}
            </Typography>
            <Typography
              color='text.secondary'
              variant='body1'
              paragraph
              align='justify'
            >
              {t(
                'managerCertification.managerCertificationComponent.sections.realLifeSparckStory.description',
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container justifyContent='center'>
              <Box
                component='video'
                width={isSmallScreen ? '100%' : '90%'}
                poster='https://api-na1.hubapi.com/filemanager/api/v3/files/thumbnail-redirect/61005809806?portalId=5358414&amp;size=medium'
                controls
                sx={{ aspectRatio: '16/9' }}
              >
                <source
                  src={getManagerCertificationVideoURL('sparck-story')}
                  type='video/mp4'
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </ManagerCertificationSection>
    </>
  )
}
