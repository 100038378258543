import { useState } from 'react'

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link as MUILink,
  Paper,
  Rating,
  TextField,
  Tooltip,
  Typography,
  useTheme,
  Dialog,
} from '@mui/material'
import { InfoOutlined as InfoIcon } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import { useMutation /*, useQuery*/ } from 'react-query'
import { Link } from 'react-router-dom'

import virginExperiencesLogo from 'assets/images/virgin-experiences-logo.png'
import { DialogTeleport, ConfirmationDialog } from 'components'
import { useUser } from 'contexts'
import { API } from 'core/requests'
import { ROUTES_PATHS } from 'core/routes'
import {
  RewardIntentionStatus,
  RewardProductType,
  RewardType,
} from 'enums/reward'
import { t } from 'i18n'
import { getUniqueColor } from 'types/colors'
// import { formatToUSD } from 'utils/currency'
import { get } from 'lodash'

type Props = {
  reward: any
  fetchSparck?: any
  acceptRewardCallback?: any
  bankRewardCallback?: any
  redeemRewardCallback?: any
  showBankedReason?: boolean
  showRating?: boolean
}

export default function ReceivedReward({
  reward,
  fetchSparck,
  acceptRewardCallback,
  bankRewardCallback,
  redeemRewardCallback,
  showBankedReason,
  showRating,
}: Props) {
  const intl = useIntl()
  const { user, setUser, fetchUser } = useUser()!
  const { enqueueSnackbar } = useSnackbar()
  const [notAcceptedReasonDialog, setNotAcceptedReasonDialog] = useState(false)
  const [addRewardAmountToWalletDialog, setAddRewardAmountToWalletDialog] = useState(false)
  const [confirmRewardBankedDialog, setConfirmRewardBankedDialog] = useState<any>({
    isOpen: false,
    payload: {},
  })
  const [redeemRewardLoading, setRedeemRewardLoading] = useState(false)

  const updateReward = useMutation(
    (payload: any) => API.update('myRewards', payload),
    {
      onSuccess: (response: any) => {
        if (response.id) {
          if (fetchSparck) fetchSparck()
          if (notAcceptedReasonDialog) setNotAcceptedReasonDialog(false)
        }
      },
    },
  )

  const redirectToRewardStore = async (payload: any) => {
    API.get('ssoRewardCloudToken', null, {queryParams: {
      reward_id: payload?.id,
      catalog_id: payload?.catalog_id || "",
      product_id: payload?.product_id || ""
    }}).then((rewardStoreSSO) => {
      if (rewardStoreSSO && rewardStoreSSO?.url) {
        setRedeemRewardLoading(false)
        // Redirect the user to the product page with the SSO token
        window.open(rewardStoreSSO?.url)
      }
    })
  }

  const acceptRewardMutation = useMutation(
    async (payload: any) => {
      return await API.update('acceptReward', payload)
    },
    {
      onSuccess: (response: any, variables: any) => {
        const payload = variables;
        if (response.id) {
          redirectToRewardStore(payload);
          if (fetchSparck) fetchSparck()
          if (acceptRewardCallback) acceptRewardCallback()
          fetchUser()
          enqueueSnackbar(
            t('components.receivedReward.snackbar.success.acceptedReward'),
            { variant: 'success' },
          )
        }
      },
    },
  )

  const redeemRewardMutation = useMutation(
    (payload: any) => API.update('redeemReward', payload),
    {
      onSuccess: (response: any) => {
        if (response.id) {
          if (fetchSparck) fetchSparck()
          if (redeemRewardCallback) redeemRewardCallback()
          enqueueSnackbar(
            t('components.receivedReward.snackbar.success.redeemedReward'),
            { variant: 'success' },
          )
        }
      },
    },
  )

  const addRewardAmountToWalletMutation = useMutation(
    async (payload: any) => {
      setAddRewardAmountToWalletDialog(true)
      setConfirmRewardBankedDialog({
        isOpen: false,
        payload: {},
      })
      return API.update('addRewardAmountToWallet', payload)
    },
    {
      onSuccess: async (response: any) => {
        try {
          if (response?.id) {
            if (fetchSparck) fetchSparck()
            if (bankRewardCallback) bankRewardCallback()
            setUser({
              ...user,
              wallet: {
                ...user.wallet,
                balance: user.wallet.balance + response.value,
                saved: user.wallet.saved + response.value,
              },
            })
            await new Promise<void>((resolve) => setTimeout(() => {
              setAddRewardAmountToWalletDialog(false)
              resolve()
            }, 2000))
            enqueueSnackbar(
              t('components.receivedReward.snackbar.success.bankedReward'),
              { variant: 'success' }
            )
            window.location.href = `${ROUTES_PATHS.profile}?tab=3`
          }
        } catch (error) {
          console.error('Error adding reward to wallet:', error)
          enqueueSnackbar(t('components.receivedReward.snackbar.error'), { variant: 'error' })
        }
      },
    }
  )

  const getRewardName = reward => ({
    [RewardType.Company]: reward.product?.name || `Reward #${reward.id}`,
    [RewardType.Retailer]:
      reward?.retailer_details?.name ?? reward?.retailer_details?.brand_name,
  })

  const handleRating = (event, newValue) =>
    updateReward.mutate({
      id: reward?.id,
      rating: newValue,
    })

  // const handleRedeem = () =>
  //   reward.type === RewardType.Company
  //     ? redeemRewardMutation.mutate({ id: reward?.id })
  //     : reward?.product?.claim_url
  //     ? window.open(reward!.product!.claim_url, '_blank')?.focus()
  //     : null

  const handleRedeem = async (reward) => {
    if(reward.type === RewardType.Retailer && RewardIntentionStatus.Accepted){
      const payload = {
        id: reward?.id,
        catalog_id: reward?.retailer_details?.key?.details?.catalog_id || null,
        product_id: reward?.retailer_details?.key?.product_id || null
      }
      setRedeemRewardLoading(true)
      redirectToRewardStore(payload)
    }
  }

  const actionsButtons = [
    {
      id: 'bank',
      label: intl.formatMessage({
        id: 'components.receivedReward.actionButtonLabels.bank',
      }),
      show: +reward?.value && reward.has_bank_action,
      variant: 'outlined',
      // actionRequest: addRewardAmountToWalletMutation.mutate,
      actionRequest: (payload) => {
        setConfirmRewardBankedDialog({
          isOpen: true,
          payload: payload,
        })
      },
      actionRequestIsLoading: addRewardAmountToWalletMutation.isLoading,
    },
    {
      id: 'accept',
      label: intl.formatMessage({
        id: 'components.receivedReward.actionButtonLabels.accept',
      }),
      show: reward.has_accept_action,
      variant: 'contained',
      actionRequest: acceptRewardMutation.mutate,
      actionRequestIsLoading: acceptRewardMutation.isLoading,
    },
  ]

  const getStatusLabel = {
    [RewardIntentionStatus.Waiting]: intl.formatMessage({
      id: 'components.receivedReward.getStatusLabels.received',
    }),
    [RewardIntentionStatus.Accepted]: intl.formatMessage({
      id: 'components.receivedReward.getStatusLabels.accepted',
    }),
    [RewardIntentionStatus.Banked]: intl.formatMessage({
      id: 'components.receivedReward.getStatusLabels.banked',
    }),
    [RewardIntentionStatus.Redeemed]: intl.formatMessage({
      id: 'components.receivedReward.getStatusLabels.redeemed',
    }),
  }

  const getStatusLabelTooltip = {
    [RewardIntentionStatus.Waiting]: intl.formatMessage({
      id: 'components.receivedReward.getStatusLabelsTooltip.received',
    }),
    [RewardIntentionStatus.Accepted]: intl.formatMessage({
      id: 'components.receivedReward.getStatusLabelsTooltip.accepted',
    }),
    [RewardIntentionStatus.Banked]: intl.formatMessage({
      id: 'components.receivedReward.getStatusLabelsTooltip.banked',
    }),
    [RewardIntentionStatus.Redeemed]: intl.formatMessage({
      id: 'components.receivedReward.getStatusLabelsTooltip.redeemed',
    }),
  }

  const userRewarding = reward?.user_rewarding ?? reward?.user_recognizing

  return (
    <>
      <Paper sx={{ p: 2, height: 1 }}>
        <Grid container spacing={2} sx={{ height: 1 }}>
          <Grid item xs={12}>
            <Typography variant='body1' color='secondary' fontWeight={700}>
              Congrats, {Math.round(reward?.value)} Reward Points! <Tooltip
                title="Congrats on your reward! You can either redeem the total reward points for something else with the Gift Receipt option OR accept this gift. The choice is yours, rock star."
              >
                <InfoIcon style={{ color: '#AAA', marginRight: 3, fontSize: '16px' }} />
              </Tooltip>
            </Typography>
            <ReceivedRewardCover
              reward={reward}
              getRewardName={getRewardName}
            />

            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant='h5'
                  fontWeight={700}
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {/* {+reward?.value ? <>{formatToUSD(reward?.value)} </> : null} */}
                  {/* {+reward?.value ? <>{Math.round(reward?.value)} </> : null}
                  <Typography
                    component='span'
                    color='text.secondary'
                    sx={{ marginLeft: '-2px' }}
                  >
                    Points
                  </Typography> */}
                  <Typography component='span' variant='h6' fontWeight={500}>
                    {getRewardName(reward)[reward.type]}
                  </Typography>
                </Typography>
              </Grid>

              {userRewarding && (
                <Grid item xs={12}>
                  <Typography variant='body1'>
                    from{' '}
                    <Link
                      style={{ fontWeight: 600 }}
                      to={`${ROUTES_PATHS.profile}/${userRewarding?.id}`}
                    >
                      {userRewarding?.full_name}
                    </Link>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sx={{ mb: 0 }}>
                <Typography variant='body1' color='text.secondary' fontWeight={600}>
                  Keep the gift or exchange for points?
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ mt: 1 }}>
                {reward.status === RewardIntentionStatus.Waiting ? (
                  <Grid container justifyContent='center' spacing={2}>
                    {actionsButtons.map(actionButton =>
                      actionButton.show ? (
                        <Grid item xs={12} sm={6} key={actionButton.id}>
                          <Button
                            variant={actionButton.variant as any}
                            onClick={() =>
                              actionButton.actionRequest({
                                id: reward?.id,
                                catalog_id: reward?.retailer_details?.key?.details?.catalog_id || null,
                                product_id: reward?.retailer_details?.key?.product_id || null
                              })
                            }
                            color='primary'
                            fullWidth
                            sx={{padding: "5px"}}
                          >
                            {actionButton.actionRequestIsLoading ? (
                              <CircularProgress
                                size={24}
                                sx={{
                                  color:
                                    actionButton.variant === 'contained'
                                      ? 'text.white'
                                      : 'primary.main',
                                }}
                              />
                            ) : (
                              actionButton.label
                            )}
                          </Button>
                        </Grid>
                      ) : null,
                    )}
                  </Grid>
                ) : (
                  <Typography
                    variant='body1'
                    color={'text.primary'}
                    fontWeight={400}
                    sx={{
                      border: "1px solid #000",
                      borderRadius: "18px",
                      backgroundColor: "primary.main",
                      color: "text.white",
                      padding: "5px 10px"
                    }}
                  >
                    {reward?.automatically_banked &&
                    reward.status === RewardIntentionStatus.Banked
                      ? t(
                          'components.receivedReward.rewardWasAutomaticallyBanked',
                        )
                      : t('components.receivedReward.rewardStatus', {
                          status: (
                            <Tooltip
                              title={getStatusLabelTooltip[reward?.status]}
                            >
                              <Typography
                                variant='body1'
                                component='span'
                                fontWeight={600}
                                sx={{
                                  textDecorationLine: 'underline',
                                  textDecorationStyle: 'dotted',
                                  textDecorationColor: '#AAA',
                                  textUnderlineOffset: '2px',
                                }}
                              >
                                {getStatusLabel[reward?.status]}
                              </Typography>
                            </Tooltip>
                          ),
                        })}
                  </Typography>
                )}
              </Grid>

              {reward.status === RewardIntentionStatus.Accepted && (reward.type === RewardType.Company || reward.type === RewardType.Retailer) ? (
                  <Grid item xs={12} sx={{ mt: 2, mb: 1 }}>
                    <Button variant='contained' color="secondary" fullWidth onClick={() => {handleRedeem(reward)}} sx={{
                      border: "1px solid #000",
                      borderRadius: "18px",
                      color: "text.white",
                      padding: "5px 10px"
                    }}>
                      {redeemRewardMutation.isLoading || redeemRewardLoading ? (
                        <CircularProgress color='primary' size={24} />
                      ) : (
                        // <>Redeem {getRewardName(reward)[reward.type]}</>
                        intl.formatMessage({
                          id: 'myRewards.columns.clickToRedeem',
                        })
                      )}
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xs={12} sx={{ mt: 2, mb: 1 }}>&nbsp;</Grid>
                )}

              {showBankedReason && (
                <>
                  {reward.status === RewardIntentionStatus.Banked &&
                  !reward?.not_accepted_reason ? (
                    <Grid item xs={12}>
                      <Typography variant='body2'>
                        <MUILink
                          onClick={() => setNotAcceptedReasonDialog(true)}
                        >
                          {t('components.receivedReward.shareWhyYouBankedIt')}
                        </MUILink>
                      </Typography>
                    </Grid>
                  ) : (
                    reward?.not_accepted_reason && (
                      <Grid item xs={12}>
                        <Typography variant='body2' color='text.secondary'>
                          {t('components.receivedReward.bankedReason', {
                            reason: reward?.not_accepted_reason,
                          })}
                        </Typography>
                      </Grid>
                    )
                  )}
                </>
              )}

              {showRating && (
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <Grid
                    container
                    spacing={1}
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Grid item xs={12} sm='auto'>
                      <Typography variant='body1' color='text.secondary'>
                        {reward?.rating
                          ? t('components.receivedReward.youRatedThisReward')
                          : t('components.receivedReward.rateThisReward')}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm='auto'>
                      <Rating
                        defaultValue={0}
                        value={reward?.rating}
                        onChange={handleRating}
                        size='large'
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <NotAcceptedReasonDialog
        rewardId={reward?.id}
        rewardName={getRewardName(reward)[reward.type]}
        notAcceptedReasonDialog={notAcceptedReasonDialog}
        setNotAcceptedReasonDialog={setNotAcceptedReasonDialog}
        updateReward={updateReward}
      />

      <Dialog
        open={addRewardAmountToWalletDialog}
        maxWidth='sm'
        fullWidth={true}
        scroll={'paper'}
      >
        <Grid container spacing={2} justifyContent='flex-end'>
          <Grid item xs={12} sx={{textAlign: 'center', marginTop: 6, marginBottom: 6}}>
            {t('components.receivedReward.rewardBankedDialog.text1')}
            <br />
            {t('components.receivedReward.rewardBankedDialog.text2', {
              points: Math.round(reward?.value),
            })}
          </Grid>
        </Grid>
      </Dialog>

      <ConfirmationDialog
        dialogOpen={confirmRewardBankedDialog.isOpen}
        setDialogOpen={setConfirmRewardBankedDialog}
        message={''}
        item={''}
        actionProps={{
          onClick: () => addRewardAmountToWalletMutation.mutate(confirmRewardBankedDialog?.payload),
          isLoading: addRewardAmountToWalletMutation.isLoading,
          label: t('components.receivedReward.confirmRewardBankedDialog.actionLabel'),
          color: 'primary',
        }}
      >
        <Grid container spacing={2} justifyContent='flex-end'>
          <Grid item xs={12} sx={{textAlign: 'center'}}>
            <Typography variant='body1' component='h6' color='secondary' fontWeight={700} sx={{marginBottom: 1, fontSize: '26px'}}>
              {t('components.receivedReward.confirmRewardBankedDialog.title')}
            </Typography>
            {t('components.receivedReward.confirmRewardBankedDialog.text', {
              points: <Typography variant='body1' component='span' color='secondary' fontWeight={700}>
              {Math.round(reward?.value)} points
            </Typography>,
            })}
          </Grid>
        </Grid>
      </ConfirmationDialog>
    </>
  )
}

function ReceivedRewardCover({ reward, getRewardName }) {
  const theme = useTheme()

  const rewardImage =
    reward?.retailer_details?.product_faceplate_url ??
    reward?.retailer_details?.image_url

  const retailerRewardImages = get(reward?.retailer_details, "key.details.images") && get(reward?.retailer_details, "key.details.images")[2]?.url;

  return reward.type === RewardType.Retailer && rewardImage ? (
    <Grid container>
      <Grid item xs={12} sx={{ position: 'relative'}}>
        <Box
          component='img'
          src={rewardImage}
          alt='Brand logo'
          sx={{
            width: '100%',
            aspectRatio: '16/9',
            borderRadius: '1em',
            mb: 2,
            objectFit:
              reward.product_type === RewardProductType.VirginExperiences
                ? 'contain'
                : 'cover',
            background:
              reward.product_type === RewardProductType.VirginExperiences
                ? '#333'
                : 'inherit',
          }}
        />

        {reward.product_type === RewardProductType.VirginExperiences && (
          <Box
            sx={{ position: 'absolute', bottom: 16, right: 0, width: '20%' }}
          >
            <Box
              component='img'
              src={virginExperiencesLogo}
              alt='Virgin Experiences logo'
              sx={{ width: 1 }}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  ) : retailerRewardImages ?
    <Grid container>
      <Grid item xs={12} sx={{ position: 'relative' }}>
        <Box
          component='img'
          src={retailerRewardImages}
          alt='Brand logo'
          sx={{
            width: '100%',
            aspectRatio: '16/9',
            borderRadius: '1em',
            objectFit: 'contain',
            mb: 2,
          }}
        />
      </Grid>
    </Grid>
    : (
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        sx={{
          width: 1,
          aspectRatio: '16/9',
          borderRadius: '1em',
          objectFit: 'contain',
          mb: 2,
          background: getUniqueColor(theme, {
            id: reward.id,
            isGradient: true,
          }),
        }}
      >
        <Grid item>
          <Typography variant='h5' color='text.white' fontWeight={700}>
            {getRewardName(reward)[reward.type]}
          </Typography>
        </Grid>
      </Grid>
    )
}

function NotAcceptedReasonDialog({
  rewardId,
  rewardName,
  notAcceptedReasonDialog,
  setNotAcceptedReasonDialog,
  updateReward,
}) {
  const intl = useIntl()

  const [notAcceptedReason, setNotAcceptedReason] = useState('')

  const sendNotAcceptedReason = async () => {
    updateReward.mutate({
      id: rewardId,
      not_accepted_reason: notAcceptedReason,
    })
  }

  return (
    <DialogTeleport
      dialogTitle={t('components.receivedReward.notAcceptedReasonDialogTitle', {
        rewardName: rewardName,
      })}
      dialogAction={null}
      dialogSize='md'
      dialogOpen={notAcceptedReasonDialog}
      handleDialogClose={() => setNotAcceptedReasonDialog(false)}
      isFullWidth
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id='not_accepted_reason'
            name='not_accepted_reason'
            value={notAcceptedReason}
            onChange={event => setNotAcceptedReason(event.target.value)}
            label={t('components.receivedReward.bankedReasonLabel')}
            placeholder={intl.formatMessage({
              id: 'components.receivedReward.bankedReasonPlaceholder',
            })}
            multiline
            minRows={3}
            maxRows={7}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent='flex-end'>
            <Button
              variant='contained'
              color='primary'
              size='large'
              disabled={!notAcceptedReason}
              onClick={sendNotAcceptedReason}
            >
              {updateReward.isLoading ? (
                <CircularProgress size={24} />
              ) : (
                t('sparcks.sparck.send')
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </DialogTeleport>
  )
}
