import { useQuery } from 'react-query'

import { OrganizationReportingComponent } from './components'
import { API } from 'core/requests'
import { useEffect, useState } from 'react'
import { useCompany } from 'contexts'

const SUMMARY_REPORT_KEY = 'summary-report'
const budgetReports = ['budget-summary-report', 'budget-report', 'financial-report'];

export default function OrganizationReporting() {
  const { company } = useCompany()!
  const [reportsList, setReportsList] = useState<any[]>([])
  const [selectedReportFilters, setSelectedReportFilters] = useState<
    Record<any, unknown>
  >({})
  const [selectedReport, setSelectedReport] = useState<string | null>(null)

  const { isLoading: reportsListIsLoading } = useQuery(
    'getReportsList',
    () => API.get('analyticsReports'),
    {
      enabled: true,
      onSuccess: response => {
        if (response?.data)
          setReportsList(
            response?.data?.filter((reportOption: any) => {
              if(reportOption.key !== SUMMARY_REPORT_KEY){
                if(company?.enable_rewards_store === true && budgetReports.includes(reportOption.key)){
                  return reportOption
                } else if(!budgetReports.includes(reportOption.key)) {
                  return reportOption
                }
              }
            }),
          )
      },
    },
  )

  const {
    data: organizationSummaryData,
    isLoading: organizationSummaryDataIsLoading,
  } = useQuery(
    'getOrganizationSummaryData',
    () => API.get('analyticsReports', SUMMARY_REPORT_KEY),
    { enabled: true },
  )

  const {
    data: selectedReportData,
    isLoading: selectedReportDataIsLoading,
    refetch: fetchSelectedReportData,
    isFetching: selectedReportDataIsFetching,
  } = useQuery(
    'getSelectedReportData',
    () =>
      API.get('analyticsReports', selectedReport, {
        queryParams: selectedReportFilters,
      }),
    { enabled: false },
  )

  useEffect(() => {
    if (reportsList?.length) setSelectedReport(reportsList[0].key)
  }, [reportsList])

  useEffect(() => {
    const clearFiltersAndFetch = async () => {
      if (selectedReport) {
        await setSelectedReportFilters({})
        fetchSelectedReportData()
      }
    }

    clearFiltersAndFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReport])

  useEffect(() => {
    if (selectedReportData?.filter) {
      const filters: any = {}

      selectedReportData?.filter?.forEach(filter => {
        filters[filter.attribute] = filter.default
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReportData])

  return (
    <OrganizationReportingComponent
      company={company}
      reportsList={reportsList}
      reportsListIsLoading={reportsListIsLoading}
      organizationSummaryData={organizationSummaryData}
      organizationSummaryDataIsLoading={organizationSummaryDataIsLoading}
      selectedReport={selectedReport}
      setSelectedReport={setSelectedReport}
      selectedReportData={selectedReportData}
      selectedReportDataIsLoading={
        selectedReportDataIsLoading || selectedReportDataIsFetching
      }
      fetchSelectedReportData={fetchSelectedReportData}
      selectedReportFilters={selectedReportFilters}
      setSelectedReportFilters={setSelectedReportFilters}
    />
  )
}
