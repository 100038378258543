import { get } from 'lodash'
import { Grid, Typography } from '@mui/material'
import { useIntl } from 'react-intl'

import { ProgressBar } from 'components'

export default function CompanyInsightsSparcksProgress({ insights }) {
  const intl = useIntl()

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <Grid container justifyContent='space-between'>
            {[
              {
                label: `${intl.formatMessage({
                  id: 'insights.sparcksProgressLabels.sent',
                })}`,
                value: get(insights, 'recognitions.all'),
                align: 'left',
              },
              {
                label: intl.formatMessage({
                  id: 'insights.sparcksProgressLabels.goal',
                }),
                value: get(insights, 'recognitions.goal'),
                align: 'right',
              },
            ].map((legend: any) => (
              <Grid item key={legend.label}>
                <Grid container direction='column'>
                  <Typography
                    variant='body1'
                    color='text.secondary'
                    fontWeight={600}
                    align={legend.align}
                  >
                    {legend.label}
                  </Typography>

                  <Typography
                    variant='body1'
                    color='secondary.main'
                    fontWeight={600}
                    align={legend.align}
                  >
                    {legend.value}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <ProgressBar
            labelVariant='h6'
            showLabel
            progress={[
              {
                percentage: Math.round(
                  (insights.recognitions?.all * 100) /
                    insights.recognitions?.goal,
                ),
                color: 'secondary',
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
