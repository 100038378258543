import { Fragment, useState } from 'react'

import {
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material'
import {
  CheckOutlined as CheckIcon,
  DeleteOutlined as DeleteIcon,
  EditOutlined as EditIcon,
  MoreVert as OptionsIcon,
} from '@mui/icons-material'
import { formatRelative, parseISO } from 'date-fns'
import { get } from 'lodash'
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'

import { Props as FeedPostProps } from '../FeedPost/FeedPost'
import { ReportInappropriateContent, UserAvatar } from 'components'
import { recognitionIcons } from 'constants/recognition'
import { t } from 'i18n'
import { getDisplayNameForGroup } from 'utils/user'

enum PostableType {
  Default = 'Default',
  Recognition = 'Recognitions::Recognition',
}

type Props = FeedPostProps & {
  editPostState: any
  postIsEditMode: boolean | any
  setPostIsEditMode: (value: boolean | any) => void
}

export default function FeedPostHeader(props: Props) {
  const {
    post,
    setFeedPostsConfirmDelete,
    publishPost,
    publishPostStatus,
    uploadPostMediaStatus,
    postIsEditMode,
    setPostIsEditMode,
    editPostState,
    user,
  } = props

  const [dialogReportPost, setDialogReportPost] = useState(false)

  const theme: any = useTheme()

  const optionsMenuState = usePopupState({
    variant: 'popover',
    popupId: 'optionsMenu',
  })

  const postIsUpdating =
    (publishPostStatus.isLoading &&
      get(publishPostStatus, 'variables.id') === post.id) ||
    (uploadPostMediaStatus.isLoading &&
      get(uploadPostMediaStatus, 'variables.id') === post.id)

  const getRecognitionIcon = icon => {
    if (!icon) return null

    const recognitionIcon: any = recognitionIcons.find(
      recognitionIcon => recognitionIcon.value === icon,
    )

    if (recognitionIcon)
      return <recognitionIcon.Icon style={{ marginBottom: '-6px' }} />
    return null
  }

  const renderPostHeader = post => {
    if (get(post, 'postable.type') === 'Recognitions::Recognition') {
      const usersRecognized = get(post, 'postable.content.users_recognized', [])

      const multipleUsersHaveBeenRecognized = usersRecognized?.length > 1

      const postTitle = (
        <Typography variant='body2' color='text.primary' fontWeight={600}>
          {getDisplayNameForGroup(usersRecognized)}{' '}
          {multipleUsersHaveBeenRecognized
            ? t('feed.haveBeen')
            : t('feed.hasBeen')}{' '}
          <span style={{ color: theme.palette.primary.dark }}>
            {multipleUsersHaveBeenRecognized
              ? t('feed.multipleUsersRecognized')
              : t('feed.recognized')}
          </span>
        </Typography>
      )

      return (
        <Grid container spacing={2} alignItems='center'>
          <Grid
            item
            style={{ position: 'relative', marginRight: 40, marginBottom: 16 }}
          >
            <UserAvatar
              userId={post.postable?.content?.users_recognized[0]?.id}
              name={post.postable?.content?.users_recognized[0]?.name}
              src={post.postable?.content?.users_recognized[0]?.avatar?.url}
              size={64}
              style={{
                zIndex: 1001,
                boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.20)',
              }}
            />
            <UserAvatar
              userId={get(post, 'postable.content.user_recognizing.id')}
              name={get(post, 'postable.content.user_recognizing.name')}
              src={get(post, 'postable.content.user_recognizing.avatar.url')}
              size={48}
              style={{
                position: 'absolute',
                left: 60,
                bottom: 8,
                boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.20)',
              }}
            />
          </Grid>

          <Grid item xs>
            <Typography variant='body2' color='text.primary' fontWeight={600}>
              {postTitle}
            </Typography>

            <Typography fontSize='0.8125rem' color='text.secondary' paragraph>
              <Typography
                variant='body1'
                fontSize='0.8125rem'
                component='span'
                color='text.secondary'
                fontWeight={600}
                style={{ color: theme.palette.secondary.dark }}
              >
                {getRecognitionIcon(get(post, 'postable.content.event.icon'))}{' '}
                {get(post, 'postable.content.event.name')}
              </Typography>{' '}
              by {get(post, 'postable.content.user_recognizing.name')} •{' '}
              {post.updated_at !== post.created_at && 'Updated'}{' '}
              {formatRelative(parseISO(post.created_at), new Date())}
            </Typography>
          </Grid>
        </Grid>
      )
    }

    return (
      <Grid container spacing={2}>
        <Grid item>
          <UserAvatar
            userId={get(post, 'user.id')}
            name={get(post, 'user.name')}
            src={get(post, 'user.avatar.url')}
            size={48}
          />
        </Grid>

        <Grid item xs>
          <Typography variant='body2' color='text.primary' fontWeight={600}>
            {get(post, 'user.name')}
          </Typography>
          <Typography fontSize='0.8125rem' color='text.secondary' paragraph>
            {post.updated_at !== post.created_at && 'Updated'}{' '}
            {formatRelative(parseISO(post.created_at), new Date())}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const postType = post.postable?.type ?? PostableType.Default

  const getDisplayConditionByType = {
    [PostableType.Default]: user.id === get(post, 'user.id'),
    [PostableType.Recognition]:
      user.id === get(post, 'postable.content.user_recognizing.id'),
  }

  const renderEdit = post => {
    if (getDisplayConditionByType[postType] || user.sparck_administrator) {
      return (
        <IconButton
          data-cy='edit_post'
          disabled={postIsUpdating}
          onClick={() => {
            if (postIsEditMode) {
              publishPost({
                id: post.id,
                ...editPostState,
              } as any)
            }

            setPostIsEditMode && setPostIsEditMode(!postIsEditMode)
          }}
          size='large'
        >
          {postIsUpdating ? (
            <CircularProgress color='primary' size={24} />
          ) : postIsEditMode ? (
            <CheckIcon color='primary' />
          ) : (
            <EditIcon color='primary' />
          )}
        </IconButton>
      )
    }

    return null
  }

  const renderDelete = post => {
    if (getDisplayConditionByType[postType]) {
      return (
        <IconButton
          data-cy='delete_post'
          onClick={() =>
            setFeedPostsConfirmDelete({
              isOpen: true,
              data: post,
            })
          }
          size='large'
        >
          <DeleteIcon color='error' />
        </IconButton>
      )
    }

    return null
  }

  const renderOptions = post => (
    <Fragment>
      <IconButton {...bindTrigger(optionsMenuState)} size='large'>
        <OptionsIcon />
      </IconButton>

      <Menu {...bindMenu(optionsMenuState)}>
        <MenuItem key={'report-post'} onClick={() => setDialogReportPost(true)}>
          {t('feed.reportPost')}
        </MenuItem>
      </Menu>

      <ReportInappropriateContent
        recordId={post.id}
        recordType={'Post'}
        dialog={dialogReportPost}
        setDialog={setDialogReportPost}
      />
    </Fragment>
  )

  return (
    <Grid container justifyContent='space-between'>
      <Grid item xs>
        {renderPostHeader(post)}
      </Grid>

      <Grid item>
        <Grid container spacing={1}>
          {renderEdit(post)}
          {renderDelete(post)}
          {renderOptions(post)}
        </Grid>
      </Grid>
    </Grid>
  )
}
