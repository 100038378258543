import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import {
  EngagementDriverScoresComponent,
  EngagementScoreComponent,
  PulseSurveyStatistics,
  RecognitionsByEvent,
} from 'components'
import { ROUTES_PATHS } from 'core/routes'
import { get, isEmpty } from 'lodash'
import { Link } from 'react-router-dom'

import {
  InsightsCard,
  CompanyInsightsSparcksProgress,
  InsightsFilters,
} from '..'
import { PulseSurveyType, SurveyType } from 'types'
import { UserRole } from 'enums/user'
import { useUser } from 'contexts'
import { t } from 'i18n'
import { BeheardReportRecommendations } from 'pages/beheardReport/components'

export interface InsightsProps {
  insights: {
    company_wide: {
      our_sparcks: {
        goal: number
        sparcks_sent_from: {
          managers: any[]
          team: any[]
        }
        total: number
      }
      pulse_survey: any[]
    }
  }
  insightsIsLoading: boolean
  filters: {
    period: string
    survey: any
    pulse_survey: any
    manager: any
  }
  setFilters: any
  surveys: SurveyType[]
  pulseSurveys: PulseSurveyType[]
  company?: any
}

const METRICS_SPACING = 3

const ConditionalWrap = ({ condition, wrap, children }) =>
  condition ? wrap(children) : children

export default function CompanyInsightsComponent(props: InsightsProps) {
  const { insights, insightsIsLoading, company } = props

  const { user } = useUser()!

  const recognitionsByEvent = get(insights, 'recognitions.by_events')

  const surveyDetails = get(insights, 'last_premium_report')
  const pulseSurveyDetails = get(insights, 'pulse_survey_insights.survey')

  const displayPulseSurveys = get(company, 'display_pulse_survey_results')

  const renderLoading = () => (
    <Box my={5} width={1}>
      <Grid container justifyContent='center'>
        <CircularProgress size={64} />
      </Grid>
    </Box>
  )

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box my={METRICS_SPACING} width={1}>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Typography variant='h6' color='text.secondary' align='left'>
              {t('insights.company.title')}
            </Typography>

            <Link to={ROUTES_PATHS.teamInsights}>
              {t('insights.company.link')}
            </Link>
          </Grid>
        </Box>

        <Grid container>
          <Grid item xs={12}>
            <InsightsFilters {...props} />
          </Grid>

          <Grid item xs={12} style={{ marginTop: 24 }}>
            {!insightsIsLoading && !isEmpty(insights) ? (
              <Grid container>
                <Grid item xs={12}>
                  <Grid container spacing={METRICS_SPACING}>
                    <Grid
                      item
                      xs={12}
                      sm={displayPulseSurveys ? 5 : 12}
                      lg={displayPulseSurveys ? 4 : 12}
                    >
                      <Grid
                        container
                        direction={
                          displayPulseSurveys ? 'column' : 'row-reverse'
                        }
                        style={{ height: '100%' }}
                        spacing={displayPulseSurveys ? 0 : METRICS_SPACING}
                      >
                        <ConditionalWrap
                          condition={!displayPulseSurveys}
                          wrap={children => (
                            <Grid
                              item
                              {...{
                                xs: 12,
                                sm: 6,
                              }}
                            >
                              {children}
                            </Grid>
                          )}
                        >
                          <InsightsCard
                            title={t('insights.company.recognitionActivity')}
                          >
                            <CompanyInsightsSparcksProgress {...props} />
                          </InsightsCard>
                        </ConditionalWrap>

                        <ConditionalWrap
                          condition={!displayPulseSurveys}
                          wrap={children => (
                            <Grid
                              item
                              {...{
                                xs: 12,
                                sm: 6,
                              }}
                            >
                              {children}
                            </Grid>
                          )}
                        >
                          <InsightsCard
                            title={t('insights.company.recognitionByProgram')}
                            boxProps={{ flex: 1 }}
                          >
                            {insightsIsLoading ? (
                              renderLoading()
                            ) : (
                              <RecognitionsByEvent
                                {...props}
                                recognitionsByEvent={recognitionsByEvent}
                              />
                            )}
                          </InsightsCard>
                        </ConditionalWrap>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={7} lg={8}>
                      <Grid
                        container
                        direction='column'
                        style={{ height: '100%' }}
                      >
                        {get(company, 'display_pulse_survey_results') && (
                          <InsightsCard
                            title={t('insights.company.pulseSurveys')}
                            boxProps={{ flex: 1 }}
                          >
                            {insightsIsLoading ? (
                              renderLoading()
                            ) : (
                              <PulseSurveyStatistics
                                pulseSurvey={pulseSurveyDetails}
                              />
                            )}
                          </InsightsCard>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={METRICS_SPACING}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        direction='column'
                        style={{ height: '100%' }}
                      >
                        {get(company, 'display_organization_wide_results') && (
                          <InsightsCard
                            title={t('insights.company.engagementScore')}
                          >
                            {insightsIsLoading ? (
                              renderLoading()
                            ) : (
                              <EngagementScoreComponent
                                surveyDetails={surveyDetails}
                              />
                            )}
                          </InsightsCard>
                        )}

                        {get(company, 'display_organization_wide_results') && (
                          <InsightsCard
                            title={t('insights.company.engagementDrivers')}
                            boxProps={{ flex: 1 }}
                          >
                            {insightsIsLoading ? (
                              renderLoading()
                            ) : (
                              <EngagementDriverScoresComponent
                                surveyDetails={surveyDetails}
                              />
                            )}
                          </InsightsCard>
                        )}
                      </Grid>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                      <Grid
                        container
                        direction='column'
                        style={{ height: '100%' }}
                      >
                        {get(company, 'display_organization_wide_results') && (
                          <InsightsCard
                            title={t('insights.company.topFiveAreas')}
                        
                          >
                            {insightsIsLoading ? (
                              renderLoading()
                            ) : (
                              // <TopFiveAreas
                              //   surveyDetails={{
                              //     ...surveyDetails,
                              //     sections_allowed: {
                              //       top_5_action_areas: true,
                              //       top_5_engagement_strengths: true,
                              //       top_5_importance: true,
                              //     },
                              //   }}
                              //   isFullReport={true}
                              //   addSurveyToCart={() => {}}
                              //   addSurveyToCartIsLoading={false}
                              //   hideImportanceSection
                              // />
                              <BeheardReportRecommendations Description={'aaa'} surveyDetails={surveyDetails} isFullReport={true} />

                            )}
                          </InsightsCard>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : insightsIsLoading ? (
              renderLoading()
            ) : (
              <Grid item xs={12}>
                <Box my={5} width={1}>
                  <Typography
                    variant='h6'
                    color='text.secondary'
                    align='center'
                    paragraph
                  >
                    {t('insights.noData')}
                  </Typography>

                  {!user.roles.includes(UserRole.Manager) && (
                    <Typography
                      variant='h6'
                      color='text.secondary'
                      align='center'
                      paragraph
                    >
                      {t('insights.managerSelectionError')}
                    </Typography>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
