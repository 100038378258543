import { useEffect, useState } from 'react'

import {
  Box,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { FiberManualRecord as CircleIcon } from '@mui/icons-material'
import { Doughnut } from 'react-chartjs-2'
import { useIntl } from 'react-intl'
import { usePrevious } from 'react-use'

import { ProgressBar } from '../components'
import { ActiveInactiveStatus } from 'enums/app'
import { ActivityType, ViewMode } from 'enums/recognitionsByEvent'
import { t } from 'i18n'
import { getUniqueColor } from 'types/colors'

const viewModes = [
  {
    value: ViewMode.Total,
    label: 'Total Recognitions',
  },
  {
    value: ViewMode.Received,
    label: 'Received Only',
  },
  {
    value: ViewMode.Sent,
    label: 'Sent Only',
  },
]

const activityTypes = [
  {
    id: ActivityType.Team,
    label: 'Team',
  },
  {
    id: ActivityType.Manager,
    label: 'Manager',
  },
]

const getRecognitionsSum = (recognitionEvent, viewMode) => {
  if (viewMode === ViewMode.Received) {
    return recognitionEvent.total_received
  } else if (viewMode === ViewMode.Sent) {
    return recognitionEvent.total_sent
  } else {
    return (
      recognitionEvent.total ??
      recognitionEvent.total_sent + recognitionEvent.total_received
    )
  }
}

type Props = {
  recognitionsByEvent: any
  onActivityTypeChange?(value: any, isEmployee: boolean): void
  teamMembers?: any[]
  showViewModesFilter?: boolean
  showActivityTypesFilter?: boolean
  showProgressBar?: boolean
}

export default function RecognitionsByEvent({
  recognitionsByEvent,
  onActivityTypeChange,
  teamMembers = [],
  showViewModesFilter,
  showActivityTypesFilter,
  showProgressBar,
}: Props) {
  const theme = useTheme()

  const [viewMode, setViewMode] = useState(ViewMode.Total)
  const [activityType, setActivityType] = useState(ActivityType.Team)
  const previousActivityType = usePrevious(activityType)

  const activityItems = [...activityTypes, ...teamMembers]

  const breakdownWithTotal = recognitionsByEvent?.filter(
    item => getRecognitionsSum(item, viewMode) > 0,
  )

  const recognitionsByEventSum = recognitionsByEvent
    ? recognitionsByEvent.reduce(
        (acc, breakdown) => acc + getRecognitionsSum(breakdown, viewMode),
        0,
      )
    : 0

  useEffect(() => {
    if (activityType !== previousActivityType && onActivityTypeChange) {
      const isEmployee = ![ActivityType.Team, ActivityType.Manager].includes(
        activityType,
      )

      onActivityTypeChange(activityType, isEmployee)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityType])

  const renderBreakdownItem = (breakdown, index) => (
    <Box mb={0.5} width={1} key={breakdown.name + index}>
      <Grid container justifyContent='space-between' alignItems='center'>
        <Grid item xs>
          <Typography
            variant='subtitle2'
            color={
              getRecognitionsSum(breakdown, viewMode)
                ? 'text.primary'
                : 'text.light'
            }
          >
            <Grid container component='span' alignItems='center'>
              {getRecognitionsSum(breakdown, viewMode) ? (
                <>
                  <CircleIcon
                    style={{
                      color: getUniqueColor(theme, { id: index }),
                      marginRight: 8,
                    }}
                  />{' '}
                </>
              ) : null}

              {breakdown.name}
              {breakdown.status === ActiveInactiveStatus.Inactive && (
                <Typography
                  component='span'
                  variant='body2'
                  color='error.light'
                  sx={{ ml: 1 }}
                >
                  (deactivated)
                </Typography>
              )}
            </Grid>
          </Typography>
        </Grid>

        <Typography
          variant='h6'
          fontWeight={700}
          color={
            getRecognitionsSum(breakdown, viewMode)
              ? 'text.primary'
              : 'text.light'
          }
        >
          {getRecognitionsSum(breakdown, viewMode)}
        </Typography>
      </Grid>
    </Box>
  )

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent='center' sx={{ mb: 2 }}>
            {showViewModesFilter && (
              <Grid item xs={12} md={6}>
                <TextField
                  select
                  id='viewMode'
                  label='View Mode'
                  value={viewMode}
                  onChange={(event: any) => setViewMode(event.target.value)}
                  size='small'
                  fullWidth
                >
                  {viewModes.map(viewMode => (
                    <MenuItem key={viewMode.value} value={viewMode.value}>
                      {viewMode.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}

            {showActivityTypesFilter && (
              <Grid item xs={12} md={6}>
                <TextField
                  select
                  id='activityType'
                  label='Activity Of'
                  value={activityType}
                  onChange={(event: any) => setActivityType(event.target.value)}
                  size='small'
                  fullWidth
                >
                  {activityItems.map(activityItem => (
                    <MenuItem key={activityItem.id} value={activityItem.id}>
                      {activityItem.label ?? activityItem.full_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      {recognitionsByEventSum > 0 ? (
        <Grid container>
          <Grid item xs={12} style={{ position: 'relative' }}>
            <Grid
              container
              justifyContent='center'
              alignItems='center'
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                bottom: 0,
                margin: '0 auto',
              }}
            >
              <Grid item>
                <Typography
                  color={'text.primary'}
                  variant='h2'
                  fontWeight='bold'
                  align='center'
                >
                  {recognitionsByEventSum}
                </Typography>
                <Typography
                  color={'text.light'}
                  variant='subtitle2'
                  align='center'
                >
                  {t('profile.impact.totalRecognitions.total')}
                  <br />
                  {t('profile.impact.totalRecognitions.recognitions')}
                </Typography>
              </Grid>
            </Grid>
            <Doughnut
              data={{
                labels: breakdownWithTotal.map(breakdown => breakdown.name),
                datasets: [
                  {
                    data: breakdownWithTotal.map(breakdown =>
                      getRecognitionsSum(breakdown, viewMode),
                    ),
                    backgroundColor: breakdownWithTotal.map((_, index) =>
                      getUniqueColor(theme, { id: index }),
                    ),
                  },
                ],
              }}
              height={200}
              options={
                {
                  maintainAspectRatio: false,
                  responsive: true,
                  cutout: '75%',
                  plugins: {
                    legend: { display: false },
                  },
                } as any
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Box
              mt={3}
              pb={1}
              width={1}
              sx={{
                maxHeight: 378,
                borderBottom: '1px solid #EEE',
                overflowY: 'auto',
              }}
            >
              {breakdownWithTotal.map(renderBreakdownItem)}

              {recognitionsByEvent
                .filter(item => getRecognitionsSum(item, viewMode) === 0)
                .map(renderBreakdownItem)}
            </Box>
          </Grid>

          {showProgressBar && (
            <RecognitionsByEventProgressBar
              recognitionsByEvent={recognitionsByEvent}
            />
          )}
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Typography variant='subtitle2' color={'text.light'} align='center'>
            {t('components.recognitionsByEvent.noData')}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

function RecognitionsByEventProgressBar({ recognitionsByEvent }) {
  const intl = useIntl()

  const totalSentRecognitions = recognitionsByEvent
    ? recognitionsByEvent.reduce(
        (acc, breakdown) => acc + getRecognitionsSum(breakdown, ViewMode.Sent),
        0,
      )
    : 0

  const totalReceivedRecognitions = recognitionsByEvent
    ? recognitionsByEvent.reduce(
        (acc, breakdown) =>
          acc + getRecognitionsSum(breakdown, ViewMode.Received),
        0,
      )
    : 0

  return (
    <Grid item xs={12}>
      {recognitionsByEvent && (
        <Grid container>
          <Box mt={3} width={1}>
            <Grid item xs={12}>
              <Grid container justifyContent='space-between'>
                {[
                  {
                    label: intl.formatMessage({
                      id: 'profile.impact.progressBarLegend.sent',
                    }),
                    value: totalSentRecognitions,
                    color: 'primary',
                    align: 'left',
                  },
                  {
                    label: intl.formatMessage({
                      id: 'profile.impact.progressBarLegend.received',
                    }),
                    value: totalReceivedRecognitions,
                    color: 'secondary',
                    align: 'right',
                  },
                ].map((legend: any) => (
                  <Grid item key={legend.label}>
                    <Grid container direction='column'>
                      <Typography
                        variant='body1'
                        color={`${legend.color}.main`}
                        fontWeight={600}
                        align={legend.align}
                      >
                        {legend.label}
                      </Typography>

                      <Typography
                        variant='body1'
                        color={`${legend.color}.main`}
                        fontWeight={600}
                        align={legend.align}
                      >
                        {legend.value}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <ProgressBar
                labelVariant='h6'
                showLabel
                progress={[
                  {
                    percentage:
                      Math.round(
                        (totalSentRecognitions * 100) /
                          (totalSentRecognitions + totalReceivedRecognitions),
                      ) ?? 0,
                    color: 'primary',
                  },
                  {
                    percentage:
                      Math.round(
                        (totalReceivedRecognitions * 100) /
                          (totalSentRecognitions + totalReceivedRecognitions),
                      ) ?? 0,
                    color: 'secondary',
                  },
                ]}
              />
            </Grid>
          </Box>
        </Grid>
      )}
    </Grid>
  )
}
