export enum UserRole {
  Employee = 'employee',
  Manager = 'manager',
  Executive = 'executive',
  CEO = 'ceo',
  SecondAdmin ='second_admin',
  CompanyAdmin = 'company_admin',
}

export enum UserPermission {
  CreateFeedRecognition = 'create.feed-recognition',
  CreateRecognition = 'create.recognition',
  ManageApprovals = 'manage.approvals',
  ManageBeheardSurveys = 'manage.beheard-surveys',
  ManageBudget = 'manage.budget',
  ManageComments = 'manage.comments',
  ManageCoreValues = 'manage.core-values',
  ManageEmployees = 'manage.employees',
  ManageOnboardingQuestions = 'manage.onboarding-questions',
  ManageRewards = 'manage.rewards',
  ManagePosts = 'manage.posts',
  ManageProfileQuestions = 'manage.profile-questions',
  ManagePulseSurveys = 'manage.pulse-surveys',
  ManageRecognitionEvents = 'manage.recognitions',
  ManageShoppingCarts = 'manage.shopping-carts',
  ManageSuggestions = 'manage.suggestions',
  ManageTakeActions = 'manage.take-actions',
  ManageTeamsProfiles = 'manage.teams-profiles',
  SendEmails = 'send.emails',
  ShareReport = 'share.report',
  TakeBeheardSurvey = 'take.beheard-survey',
  TakeOnboarding = 'take.onboarding',
  TakePulseSurvey = 'take.pulse-survey',
  ViewAdministrators = 'view.administrators',
  ViewApprovals = 'view.approvals',
  ViewBeheardSurveys = 'view.beheard-surveys',
  ViewBudget = 'view.budget',
  ViewComments = 'view.comments',
  ViewCompanyInsights = 'view.company-insights',
  ViewCompanyReporting = 'view.company-reporting',
  ViewCoreValues = 'view.core-values',
  ViewEmployees = 'view.employees',
  ViewEmployeesProfile = 'view.employees-profile',
  ViewInvoices = 'view.invoices',
  ViewOnboardingQuestions = 'view.onboarding-questions',
  ViewOtherManagersInsights = 'view.other-managers-insights',
  ViewRewards = 'view.rewards',
  ViewPosts = 'view.posts',
  ViewPriorityTasks = 'view.priority-tasks',
  ViewProfileQuestionLibrary = 'view.profile-question-library',
  ViewProfileQuestions = 'view.profile-questions',
  ViewPulseSurveyLibrary = 'view.pulse-survey-library',
  ViewPulseSurveys = 'view.pulse-surveys',
  ViewRecognitions = 'view.recognitions',
  ViewRegularTasks = 'view.regular-tasks',
  ViewReminders = 'view.reminders', // ???
  ViewSettings = 'view.settings',
  ViewShoppingCarts = 'view.shopping-carts',
  ViewSuggestions = 'view.suggestions',
  ViewSuggestionsBox = 'view.suggestion-box',
  ViewTakeActions = 'view.take-actions',
  ViewTeamsInsights = 'view.teams-insights',
  ViewTeamsProfiles = 'view.teams-profiles',
}
