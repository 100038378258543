import { CurrentPosition } from 'enums/currentPosition'

export const CURRENT_POSITIONS = [
  {
    label: 'sparckAppreciationAssessment.currentPositions.entryLevel',
    value: CurrentPosition.EntryLevel,
  },
  {
    label: 'sparckAppreciationAssessment.currentPositions.midLevel',
    value: CurrentPosition.MidLevel,
  },
  {
    label: 'sparckAppreciationAssessment.currentPositions.seniorLevel',
    value: CurrentPosition.SeniorLevel,
  },
  {
    label: 'sparckAppreciationAssessment.currentPositions.executive',
    value: CurrentPosition.Executive,
  },
]
