import { useFormik } from 'formik'
import * as yup from 'yup'

import { ManagerCertificationFormBuilder } from '../../components'
import { DialogTeleport } from 'components'
import { t } from 'i18n'

export default function ManagerCertificationQuizDialog(props) {
  const {
    dialogOpen,
    setDialogOpen,
    questions,
    sectionId,
    userCertification,
    userCertificationMutation,
  } = props

  const setInitialValues = () => {
    const initialValues = {}

    questions.forEach(question => (initialValues[`${question.id}`] = ''))

    return {
      ...initialValues,
      terms_of_communication_accepted: false,
    }
  }

  const buildValidationSchema = () => {
    const validationSchema = {}

    questions.forEach(question => {
      if (!question.optional) {
        validationSchema[`${question.id}`] = yup
          .string()
          .required(
            t(
              'managerCertification.managerCertificationQuizDialog.requiredField',
            ),
          )
      }
    })

    return yup.object({
      ...validationSchema,
    })
  }

  const formik = useFormik({
    initialValues: setInitialValues(),
    onSubmit: () => {},
    validationSchema: buildValidationSchema,
  })

  const handleSubmit = questions => {
    const payload: any = {}

    questions.forEach(question => {
      payload[`${question.id}${question.optional ? '__optional' : ''}`] =
        formik.values[question.id]
    })

    const userCertificationPayload = {
      ...userCertification,
      certification_state: {
        sections: userCertification?.certification_state?.sections.map(
          section => {
            if (section.id === sectionId) {
              return {
                ...section,
                questions_answers: payload,
              }
            }

            return section
          },
        ),
        terms_of_communication_accepted:
          sectionId === 1
            ? formik.values.terms_of_communication_accepted
            : userCertification?.certification_state
                ?.terms_of_communication_accepted,
      },
    }

    userCertificationMutation.mutate(userCertificationPayload)
    setDialogOpen(false)
  }

  return (
    <DialogTeleport
      dialogTitle={t(
        'managerCertification.managerCertificationQuizDialog.title',
        {
          section: sectionId,
        },
      )}
      dialogAction={null}
      dialogSize='md'
      dialogOpen={dialogOpen}
      handleDialogClose={() => setDialogOpen(false)}
      isFullWidth
    >
      <ManagerCertificationFormBuilder
        sectionId={sectionId}
        formik={formik}
        questions={questions}
        onSubmit={handleSubmit}
        userCertification={userCertification}
      />
    </DialogTeleport>
  )
}
