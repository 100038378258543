import { useState } from 'react'

import { CircularProgress, Grid } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useQuery, useMutation } from 'react-query'

import { DialogTeleport } from 'components'
import { initialDialog } from 'constants/dialog'
import { useUser } from 'contexts'
import { API } from 'core/requests'
import { t } from 'i18n'
import {
  SparckConfirmGiftCardRewardDetails,
  SparckConfirmVirginExperiencesRewardDetails,
  SparckRetailerRewardsList,
} from 'pages/sparck/components'

export default function PurchaseRetailerRewardDialog({
  dialogOpen,
  setDialogOpen,
}) {
  const { user, setUser } = useUser()!
  const { enqueueSnackbar } = useSnackbar()

  const [
    confirmGiftCardRewardDetailsDialog,
    setConfirmGiftCardRewardDetailsDialog,
  ] = useState(initialDialog)
  const [
    confirmVirginExperiencesRewardDetailsDialog,
    setConfirmVirginExperiencesRewardDetailsDialog,
  ] = useState(initialDialog)

  const walletAmount = user?.wallet?.balance

  const { data: rewardsNGC, isLoading: rewardsNGCAreLoading } = useQuery(
    'getRewardsNGC',
    () =>
      API.get('rewardsNGC', null, {
        queryParams: {
          max_amount: walletAmount,
        },
      }),
    { enabled: !!walletAmount },
  )

  const {
    data: rewardsVirginExperiences,
    isLoading: rewardsVirginExperiencesAreLoading,
  } = useQuery(
    'getRewardsVirginExperiences',
    () =>
      API.get('rewardsVirginExperiences', null, {
        queryParams: {
          // queryParams: {
          //   q: {
          //     product_options_price_lteq: walletAmount,
          //     region_id_eq: VIRGIN_EXPERIENCES_CALIFORNIA_REGION_ID,
          //   },
          // },
        },
      }),
    { enabled: !!walletAmount },
  )

  const { mutate: purchaseReward, isLoading: purchaseRewardIsLoading } =
    useMutation((payload: any) => API.create('myRewardsPurchases', payload), {
      onSuccess: (response: any) => {
        if (response.id) {
          setDialogOpen(false)
          setUser({
            ...user,
            wallet: {
              ...user.wallet,
              balance: +user.wallet.balance - +response.amount,
              spent: +user.wallet.spent + +response.amount,
            },
          })
          enqueueSnackbar(
            t(
              'components.purchaseRetailerRewardDialog.snackbar.giftCardPurchased',
            ),
            {
              variant: 'success',
            },
          )
        }
      },
    })

  const handleClose = () => setDialogOpen(false)

  return (
    <>
      <DialogTeleport
        key={'select-reward-dialog'}
        dialogTitle={t('components.purchaseRetailerRewardDialog.title')}
        dialogAction={null}
        dialogSize='lg'
        dialogOpen={dialogOpen}
        handleDialogClose={handleClose}
        isFullWidth
      >
        {purchaseRewardIsLoading ||
        rewardsNGCAreLoading ||
        rewardsVirginExperiencesAreLoading ? (
          <Grid container justifyContent='center'>
            <Grid item xs='auto' sx={{ my: 5 }}>
              <CircularProgress size={64} />
            </Grid>
          </Grid>
        ) : (
          <SparckRetailerRewardsList
            rewardsNGC={rewardsNGC}
            rewardsVirginExperiences={rewardsVirginExperiences}
            giftCardMaximumValue={walletAmount}
            onSelectReward={reward => {
              if (reward?.brand?.id) {
                setConfirmGiftCardRewardDetailsDialog({
                  isOpen: true,
                  data: reward,
                })
              }

              if (reward?.sku) {
                setConfirmVirginExperiencesRewardDetailsDialog({
                  isOpen: true,
                  data: reward,
                })
              }
            }}
          />
        )}
      </DialogTeleport>

      <DialogTeleport
        dialogOpen={confirmGiftCardRewardDetailsDialog.isOpen}
        dialogSize='xs'
        dialogTitle={t(
          'components.purchaseRetailerRewardDialog.retailerRewardDetails',
        )}
        handleDialogClose={() =>
          setConfirmGiftCardRewardDetailsDialog(initialDialog)
        }
      >
        <SparckConfirmGiftCardRewardDetails
          reward={confirmGiftCardRewardDetailsDialog?.data}
          onConfirmReward={reward => {
            purchaseReward(reward)
            setConfirmGiftCardRewardDetailsDialog(initialDialog)
          }}
          giftCardMaximumValue={walletAmount}
        />
      </DialogTeleport>

      <DialogTeleport
        dialogOpen={confirmVirginExperiencesRewardDetailsDialog.isOpen}
        dialogSize='xs'
        dialogTitle={t(
          'components.purchaseRetailerRewardDialog.retailerRewardDetails',
        )}
        handleDialogClose={() =>
          setConfirmVirginExperiencesRewardDetailsDialog(initialDialog)
        }
      >
        <SparckConfirmVirginExperiencesRewardDetails
          reward={confirmVirginExperiencesRewardDetailsDialog?.data}
          onConfirmReward={reward => {
            purchaseReward(reward)
            setConfirmVirginExperiencesRewardDetailsDialog(initialDialog)
          }}
          rewardMaximumValue={walletAmount}
        />
      </DialogTeleport>
    </>
  )
}
