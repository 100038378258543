import { DatePicker } from '@mui/lab'
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'

import { SparckTable, UserAvatar } from 'components'
import { useMemo } from 'react'

enum ReportType {
  Object = 'object',
  Array = 'array',
  Tree = 'tree',
}

enum FieldType {
  Integer = 'integer',
}

enum FieldFormat {
  Currency = 'currency',
  Date = 'date',
}

enum CustomRenderType {
  Avatar = 'avatar',
}

enum FilterType {
  Single = 'single',
  Multiple = 'multiple',
  Timeframe = 'timeframe',
  Date = 'date',
}

const getColumnType = column => {
  if ([FieldFormat.Currency, FieldFormat.Date].includes(column.format)) {
    return column.format
  }
  if (column.type === FieldType.Integer) return 'numeric'

  return 'string'
}

const customRenderRowType = column => {
  if (column.name?.includes('_avatar')) return CustomRenderType.Avatar
  return false
}

const customRenderData = (row, column, type) => {
  if (type === CustomRenderType.Avatar) {
    return (
      <UserAvatar
        name={row?.user_name ?? 'User'}
        size={40}
        src={row[column.name]}
      />
    )
  }
}

export default function OrganizationReportingSelectedReport(props) {
  const {
    reportsList,
    selectedReport,
    selectedReportData,
    selectedReportDataIsLoading,
  } = props

  const selectedReportMeta = useMemo(
    () => selectedReportData?.meta,
    [selectedReportData],
  )

  const selectedReportType = useMemo(
    () => reportsList.find(report => report.key === selectedReport)?.type,
    [reportsList, selectedReport],
  )

  const normalizedSelectedReportData = useMemo(
    () =>
      selectedReportData?.data?.map(row => ({
        ...row,
        parentId: row?.parent_id,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedReportData],
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Grid container spacing={1}>
            {normalizedSelectedReportData && !selectedReportDataIsLoading && (
              <>
                <Grid item xs={12} md={6}>
                  <OrganizationReportingReportPicker {...props} />
                </Grid>

                <Grid item xs={12}>
                  <OrganizationReportingFilters {...props} />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              {!normalizedSelectedReportData ||
              !selectedReportMeta ||
              selectedReportDataIsLoading ? (
                <Grid container justifyContent='center'>
                  <Grid item xs='auto' sx={{ my: 5 }}>
                    <CircularProgress size={48} />
                  </Grid>
                </Grid>
              ) : (
                <>
                  <SparckTable
                    slug={selectedReport}
                    data={normalizedSelectedReportData}
                    isLoading={selectedReportDataIsLoading}
                    columns={selectedReportMeta?.fields?.map(column => ({
                      title: column.label,
                      field: column.name,
                      type: getColumnType(column),
                      align:
                        column.format === FieldFormat.Currency
                          ? 'right'
                          : 'left',
                      ...(!!customRenderRowType(column) && {
                        render: rowData =>
                          customRenderData(
                            rowData,
                            column,
                            customRenderRowType(column),
                          ),
                      }),
                    }))}
                    {...(selectedReportType === ReportType.Tree && {
                      parentChildData: (row: any, rows) =>
                        rows.find((a: any) => a.id === row.parentId),
                    })}
                    style={{ boxShadow: 'none' }}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

function OrganizationReportingReportPicker({
  reportsList,
  selectedReport,
  setSelectedReport,
}) {
  return (
    <TextField
      select
      id='reportsList'
      label='Selected Report'
      value={selectedReport ?? ''}
      onChange={(event: any) => setSelectedReport(event.target.value)}
      variant='outlined'
      margin='normal'
      InputLabelProps={{
        shrink: true,
        sx: { fontSize: '1.1em', mb: 0.5 },
      }}
      InputProps={{
        sx: { fontSize: '1.2em', fontWeight: 600 },
      }}
      fullWidth
    >
      {reportsList?.map(reportOption => (
        <MenuItem key={reportOption.key} value={reportOption.key}>
          {reportOption.name}
        </MenuItem>
      ))}
    </TextField>
  )
}

function OrganizationReportingFilters({
  selectedReportData,
  selectedReportDataIsLoading,
  fetchSelectedReportData,
  selectedReportFilters,
  setSelectedReportFilters,
}) {
  const clearFilters = async () => {
    await setSelectedReportFilters({})
    fetchSelectedReportData()
  }
  const getFilterPicker = filter => {
    const handleChange = value => {
      setSelectedReportFilters({
        ...selectedReportFilters,
        [filter.attribute]: value,
      })
    }

    const handleMultipleChange = (event: SelectChangeEvent<any>) => {
      const {
        target: { value },
      } = event

      setSelectedReportFilters({
        ...selectedReportFilters,
        [filter.attribute]:
          typeof value === 'string' ? value.split(',') : value,
      })
    }

    if (filter.type === FilterType.Single) {
      return (
        <Grid item xs={12} md>
          <TextField
            id={filter.attribute}
            label={filter.label}
            value={selectedReportFilters[filter.attribute] ?? ''}
            onChange={event => handleChange(event.target.value)}
            variant='outlined'
            fullWidth
            size='small'
          />
        </Grid>
      )
    }

    if (filter.type === FilterType.Multiple) {
      return (
        <Grid item xs={12} md>
          <FormControl fullWidth size='small'>
            <InputLabel id={`${filter.attribute}-label`}>
              {filter.label}
            </InputLabel>
            <Select
              labelId={`${filter.attribute}-label`}
              id={`${filter.attribute}`}
              multiple
              value={selectedReportFilters[filter.attribute] ?? []}
              onChange={handleMultipleChange}
              input={
                <OutlinedInput label={filter.label} fullWidth size='small' />
              }
              fullWidth
              size='small'
              renderValue={selected =>
                selected
                  .map(
                    selectedId =>
                      filter.options.find(option => option.id === selectedId)[
                        filter.options_attribute_for_display
                      ],
                  )
                  .join(', ')
              }
            >
              {filter.options.map(option => {
                const optionLabel = option[filter.options_attribute_for_display]
                const optionValue = option[filter.options_attribute_for_filter]

                return (
                  <MenuItem key={optionValue} value={optionValue}>
                    {optionLabel}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>

          {/* <TextField
            select
            id={filter.attribute}
            label={filter.label}
            value={selectedReportFilters[filter.attribute] ?? ''}
            onChange={event => handleChange(event.target.value)}
            variant='outlined'
            
            fullWidth
          >
            <MenuItem key='' value=''>
              <em>None</em>
            </MenuItem>

            {filter.options.map(option => {
              const optionLabel = option[filter.options_attribute_for_display]
              const optionValue = option[filter.options_attribute_for_filter]

              return (
                <MenuItem key={optionValue} value={optionValue}>
                  {optionLabel}
                </MenuItem>
              )
            })}
          </TextField> */}
        </Grid>
      )
    }

    if (filter.type === FilterType.Date) {
      return (
        <Grid item xs={12} md>
          <DatePicker
            renderInput={params => (
              <TextField
                {...params}
                data-cy={filter.attribute}
                id={filter.attribute}
                name={filter.attribute}
                label={filter.label}
                variant='outlined'
                size='small'
              />
            )}
            value={selectedReportFilters[filter.attribute] ?? ''}
            onChange={value => handleChange(value)}
          />
        </Grid>
      )
    }

    if (filter.type === FilterType.Timeframe) {
      return (
        <>
          <Grid item xs={12} md>
            <TextField
              select
              id={filter.attribute}
              label={filter.label}
              value={selectedReportFilters[filter.attribute] ?? ''}
              onChange={event => handleChange(event.target.value)}
              variant='outlined'
              size='small'
              fullWidth
            >
              <MenuItem key='' value=''>
                <em>None</em>
              </MenuItem>

              {filter.options.map(option => (
                <MenuItem key={option.key} value={option.key}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {filter.conditional_attributes.map(
            (conditionalAttribute, index) =>
              selectedReportFilters[filter.attribute] ===
                conditionalAttribute.when_selected_option_is && (
                <Grid key={index} item xs={12} md>
                  <Grid container spacing={1}>
                    {conditionalAttribute.then_accept_params?.map(newParams =>
                      getFilterPicker({
                        ...newParams,
                        type: newParams.attribute_format,
                      }),
                    )}
                  </Grid>
                </Grid>
              ),
          )}
        </>
      )
    }
  }

  return (
    <Grid container spacing={1} alignItems='center'>
      {selectedReportData?.filter?.map(getFilterPicker)}

      <Grid item xs={12} sx={{ mt: 1, mb: 2 }}>
        <Grid container spacing={1} justifyContent='flex-end'>
          <Grid item xs={12} sm='auto'>
            <Button variant='outlined' color='primary' onClick={clearFilters}>
              Clear Filters
            </Button>
          </Grid>

          <Grid item xs={12} sm='auto'>
            <Button
              variant='contained'
              color='primary'
              onClick={fetchSelectedReportData}
            >
              {selectedReportDataIsLoading && <CircularProgress size={24} />}{' '}
              Apply Filters
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
