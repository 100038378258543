import { Box, Grid, Paper } from '@mui/material'
import { OnboardingTitle } from '../../components'

export default function OnboardingCard({
  backgroundColor1,
  backgroundColor2,
  title,
  children,
}) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper style={{ position: 'relative', marginBottom: 40 }}>
          <OnboardingTitle
            backgroundColor1={backgroundColor1}
            backgroundColor2={backgroundColor2}
            title={title}
          />
          <Box p={2} pt={4} width={1}>
            {children}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}
