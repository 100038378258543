// import { SurveyOverviewType } from 'types'

import { useQuery, UseQueryResult } from 'react-query'
import { SurveyType } from 'types'
import { API } from 'core/requests'

export const useFetchSurveys = (): UseQueryResult<SurveyType[], Error> =>
  useQuery('surveys', () => API.get('surveys'))

export const useFetchUninvitedUsers = (
  id,
): UseQueryResult<SurveyType[], Error> =>
  useQuery(['uninvitedUsers', id], () => API.get('surveyUninvitedUsers', id), {
    enabled: false,
  })

const fetchSurveyOverview: () => Promise<any> = () =>
  Promise.resolve({
    company: {
      name: 'Sparck CO',
    },
    survey: {
      id: 1,
      name: '#BeHeard Engagement Survey',
      open_at: new Date(),
      close_at: new Date(),
      invites: 500,
      completes: 500,
      participation_rate: 100,
    },
    participation_by_demographic: [
      {
        label: 'Department',
        values: [
          {
            label: 'Accounting',
            percentage: 100,
          },
          {
            label: 'Human Resources',
            percentage: 100,
          },
          {
            label: 'Information Technology',
            percentage: 100,
          },
          {
            label: 'Marketing',
            percentage: 100,
          },
          {
            label: 'Sales',
            percentage: 100,
          },
        ],
      },
      {
        label: 'Tenure',
        values: [
          {
            label: '1 year',
            percentage: 0,
          },
          {
            label: '2 to 5 years',
            percentage: 0,
          },
          {
            label: '6 to 10 years',
            percentage: 100,
          },
          {
            label: '10+ years',
            percentage: 100,
          },
        ],
      },
      {
        label: 'Age',
        values: [
          {
            label: '18 to 25',
            percentage: 100,
          },
          {
            label: '26 to 35',
            percentage: 100,
          },
          {
            label: '36 to 45',
            percentage: 0,
          },
          {
            label: '46 to 55',
            percentage: 100,
          },
          {
            label: '56 to 65',
            percentage: 100,
          },
          {
            label: '65+',
            percentage: 100,
          },
        ],
      },
      {
        label: 'Gender',
        values: [
          {
            label: 'Female',
            percentage: 100,
          },
          {
            label: 'Male',
            percentage: 100,
          },
          {
            label: 'Other',
            percentage: 100,
          },
        ],
      },
      {
        label: 'Location',
        values: [
          {
            label: 'Chicago',
            percentage: 100,
          },
          {
            label: 'Curitiba',
            percentage: 100,
          },
          {
            label: 'New York',
            percentage: 100,
          },
          {
            label: 'Sacramento',
            percentage: 100,
          },
          {
            label: 'Seattle',
            percentage: 100,
          },
        ],
      },
      {
        label: 'Business Unit',
        values: [
          {
            label: 'SaaS Products',
            percentage: 100,
          },
          {
            label: 'Strategic Deployment',
            percentage: 100,
          },
          {
            label: 'Training Services',
            percentage: 100,
          },
        ],
      },
      {
        label: 'Territory',
        values: [
          {
            label: 'Europe',
            percentage: 100,
          },
          {
            label: 'North America',
            percentage: 100,
          },
          {
            label: 'South America',
            percentage: 100,
          },
        ],
      },
      {
        label: 'Region',
        values: [
          {
            label: 'East',
            percentage: 100,
          },
          {
            label: 'North',
            percentage: 100,
          },
          {
            label: 'South',
            percentage: 100,
          },
          {
            label: 'Western',
            percentage: 100,
          },
        ],
      },
      {
        label: 'Custom Demographic #1',
        values: [
          {
            label: 'C',
            percentage: 100,
          },
          {
            label: 'Elixir',
            percentage: 100,
          },
          {
            label: 'Java',
            percentage: 100,
          },
          {
            label: 'PHP',
            percentage: 100,
          },
          {
            label: 'Python',
            percentage: 100,
          },
          {
            label: 'Ruby',
            percentage: 100,
          },
        ],
      },
      {
        label: 'Custom Demographic #2',
        values: [
          {
            label: 'Facil',
            percentage: 100,
          },
          {
            label: 'Laravel',
            percentage: 100,
          },
          {
            label: 'Martini',
            percentage: 100,
          },
          {
            label: 'Phoenix',
            percentage: 100,
          },
          {
            label: 'Spring',
            percentage: 100,
          },
        ],
      },
    ],
    comparisons: {
      engagement: [
        {
          segment: 'a',
          percentages: {
            engaged: 7,
            neutrals: 10,
            disengaged: 30,
          },
        },
        {
          segment: 'b',
          percentages: {
            engaged: 7,
            neutrals: 10,
            disengaged: 31,
          },
        },
        {
          segment: 'c',
          percentages: {
            engaged: 7,
            neutrals: 10,
            disengaged: 30,
          },
        },
        {
          segment: 'd',
          percentages: {
            engaged: 7,
            neutrals: 10,
            disengaged: 29,
          },
        },
        {
          segment: 'e',
          percentages: {
            engaged: 7,
            neutrals: 10,
            disengaged: 29,
          },
        },
      ],
    },
    scores: {
      percents: {
        engagement: [
          {
            label: 'Engaged',
            value: 7,
          },
          {
            label: 'Neutral',
            value: 10,
          },
          {
            label: 'Disengaged',
            value: 30,
          },
        ],
        drivers: [
          {
            label: 'Engagement',
            value: 49,
          },
          {
            label: 'Fit',
            value: 47,
          },
          {
            label: 'Valuing',
            value: 47,
          },
          {
            label: 'Alignment',
            value: 46,
          },
          {
            label: 'Growth',
            value: 46,
          },
          {
            label: 'Team',
            value: 48,
          },
        ],
      },
    },
    turnover: {
      long_term: 249,
      long_term_percentage: 50,
    },
  })

export default {
  fetchSurveyOverview,
}
