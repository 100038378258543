import { useEffect } from 'react'

import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import pluralize from 'pluralize'
import { useIntl } from 'react-intl'
import { useMutation, useQuery } from 'react-query'

import { API } from 'core/requests'
import { Props } from './FormHandler.types'
import { t } from 'i18n'

export default function FormHandler(props: Props) {
  const {
    title = '',
    requestUrl,
    detailsRequestId,
    detailsRequestCallback,
    itemAccessor,
    customMutationRequest,
    data,
    closeDialog,
    fetchRows,
    initialValues,
    onSubmit,
    mutationOnSuccess,
    isAddMode,
  } = props

  const isEditMode = !!data && !data?.isClone && !isAddMode

  const { enqueueSnackbar } = useSnackbar()
  const intl = useIntl()

  const {
    refetch: getItem,
    isLoading: itemIsLoading,
    data: itemFromAPI,
  } = useQuery(`getItem${title}`, () => API.get(requestUrl, detailsRequestId), {
    cacheTime: 50,
    enabled: false,
    onSuccess: detailsRequestCallback,
  })

  useEffect(() => {
    if (detailsRequestId) {
      getItem()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsRequestId])

  const mutation = useMutation(
    (payload: any) =>
      customMutationRequest
        ? customMutationRequest(payload)
        : API.createOrUpdate(requestUrl, payload),
    {
      onSettled: (response: any) => {
        if (response && !response.errors) {
          if (mutationOnSuccess) return mutationOnSuccess(response)
          if (closeDialog) closeDialog()
          if (fetchRows) fetchRows()

          enqueueSnackbar(
            title
              ? `${pluralize.singular(title)} ${intl.formatMessage({
                id: 'formHandler.successWithTitle',
              })} ${isEditMode
                ? intl.formatMessage({
                  id: 'formHandler.updated',
                })
                : intl.formatMessage({
                  id: 'formHandler.created',
                })
              }`
              : intl.formatMessage({
                id: 'formHandler.success',
              }),
            {
              variant: 'success',
            },
          )
        }
      },
    },
  )

  if (
    !itemIsLoading &&
    (detailsRequestId
      ? itemFromAPI &&
      get(itemFromAPI, itemAccessor ? `${itemAccessor}.id` : 'id') ===
      detailsRequestId
      : true)
  ) {
    return (
      <Form
        {...props}
        isEditMode={isEditMode}
        mutation={mutation}
        initialValues={detailsRequestId ? itemFromAPI : initialValues}
        onSubmit={onSubmit}
      />
    )
  }

  return (
    <Grid container justifyContent='center'>
      <Box my={5}>
        <CircularProgress size={40} />
      </Box>
    </Grid>
  )
}

function Form({
  title = '',
  descriptionCreateMode,
  descriptionUpdateMode,
  children,
  validationSchema,
  onSubmit,
  secondaryActionButtons,
  submitLabel,
  mutation,
  initialValues,
  isEditMode,
  itemAccessor,
  hideHeader,
  hideActionButton,
  data,
}: any) {
  const intl = useIntl()

  const formik = useFormik({
    initialValues:
      itemAccessor && initialValues[itemAccessor]
        ? initialValues[itemAccessor]
        : initialValues,
    validationSchema: validationSchema,
    onSubmit: values => {
      if (onSubmit) {
        onSubmit(values, mutation.mutate)
      } else {
        mutation.mutate(values)
      }
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container>
        {!hideHeader && (
          <Box mb={1}>
            {title && (
              <Typography
                variant='h5'
                color='text.primary'
                // fontWeight={600}
                paragraph
              >
                {isEditMode
                  ? t('formHandler.edit')
                  : data?.isClone
                    ? 'Clone'
                    : t('formHandler.create')}{' '}
                {pluralize.singular(title)}
              </Typography>
            )}

            <Typography variant='body2' color='text.secondary' paragraph>
              {isEditMode ? descriptionUpdateMode : descriptionCreateMode}
            </Typography>
          </Box>
        )}

        {formik.values &&
          children({
            formik: formik,
            mutation: mutation,
            isEditMode: isEditMode,
          })}

        {!hideActionButton && (
          <Box width={1} my={2}>
            <Grid container justifyContent='flex-end' spacing={2}>
              {(secondaryActionButtons ?? []).map(button => (
                <Grid key={button.id} item>
                  <Button
                    color={button.color ?? 'secondary'}
                    variant={button.variant ?? 'outlined'}
                    onClick={() => button.onClick(formik)}
                    size='large'
                  >
                    {button.isLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      button.label
                    )}
                  </Button>
                </Grid>
              ))}
              <Grid item>
                <Button
                  type='submit'
                  color='primary'
                  variant='contained'
                  size='large'
                  disabled={mutation.isLoading}
                  data-cy='submitButton'
                >
                  {mutation.isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    submitLabel ??
                    `${isEditMode
                      ? intl.formatMessage({
                        id: 'formHandler.update',
                      })
                      : intl.formatMessage({
                        id: 'formHandler.create',
                      })
                    } ${pluralize.singular(title)}`
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Grid>
    </form>
  )
}
