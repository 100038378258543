import * as React from 'react'

export default function TeamIcon(props) {
  return (
    <svg
      width={101.234}
      height={101.234}
      viewBox='0 0 101.234 101.234'
      {...props}
    >
      <defs>
        <clipPath id='prefix__a'>
          <path
            className='prefix__a'
            d='M0 21.056h53.927V-26.4H0z'
            transform='translate(0 26.4)'
          />
        </clipPath>
        <style>{'.prefix__a{fill:#fa8d25}'}</style>
      </defs>
      <g transform='translate(-1309 -2341.766)'>
        <circle
          cx={50.617}
          cy={50.617}
          r={50.617}
          transform='translate(1309 2341.766)'
          fill='#fff'
        />
        <g transform='translate(1333 2363.344)' clipPath='url(#prefix__a)'>
          <path
            className='prefix__a'
            d='M53.927 44.868q-.108-2.589-.27-5.447a9.47 9.47 0 00-.485-3.29q-.972-1.833-7.01-4.314a11.8 11.8 0 01-4.152-2.589 5.221 5.221 0 01-1.133-3.559 2.942 2.942 0 01.7-1.78A6.362 6.362 0 0042.6 21.14a1.632 1.632 0 01.755-.863q.647-.429.755-2.265 0-1.294-.647-1.618l.437-3.234a6.167 6.167 0 00-1.3-4.208q-1.513-2.157-5.177-2.157T32.3 8.952a6.281 6.281 0 00-1.238 4.208q.105 1.4.431 3.236-.647.321-.647 1.616c.07 1.224.324 1.979.755 2.265a1.64 1.64 0 01.755.863 6.339 6.339 0 001.025 2.75 2.953 2.953 0 01.7 1.78 5.337 5.337 0 01-2.157 4.53q6.687 3.343 8.3 4.8a5.58 5.58 0 011.618 4.368v8.092h12.08zM22 29.876a6.95 6.95 0 01-1.508-4.746 3.955 3.955 0 011.025-2.37 7.468 7.468 0 001.348-3.67 2.251 2.251 0 01.971-1.186q.863-.647 1.079-3.02c0-1.079-.253-1.762-.755-2.049l.539-4.422a8.493 8.493 0 00-1.78-5.553q-2-2.855-6.849-2.858T9.222 2.86a8.473 8.473 0 00-1.78 5.555q.216 1.941.539 4.422l-.216.108a1.789 1.789 0 00-.377.7 4 4 0 00-.162 1.24q.216 2.373 1.079 3.02a2.269 2.269 0 01.971 1.186 7.451 7.451 0 001.348 3.669 3.966 3.966 0 011.024 2.37 6.959 6.959 0 01-1.51 4.746 15.706 15.706 0 01-5.608 3.451Q0 35.16 0 37.533v9.927h37.426v-7.446q0-2.481-9.815-6.687A15.672 15.672 0 0122 29.876'
          />
        </g>
      </g>
    </svg>
  )
}
