export default {
  beheardReport: {
    reportBeingGenerated: 'Your #BeHeard Report is being generated...',
    title: {
      report: '#BeHeard {type} Report',
      premium: 'Premium',
      free: 'Free',
    },
    shortTermQuestion:
      'The chances of leaving my job voluntarily during the next year are Extremely Low.',
    longTermQuestion:
      'I feel that I will have a long career at this organization.',
    sectionTitles: {
      overview: 'OVERVIEW',
      characteristics: 'CHARACTERISTICS',
      participation: 'PARTICIPATION',
      results: 'RESULTS',
      topFiveRecommendations: 'TOP 5 RECOMMENDATIONS',
      employeeTurnoverForecast: 'EMPLOYEE TURNOVER FORECAST',
      comparisonGraphs: 'COMPARISON GRAPHS',
      fullReport: 'FULL REPORT',
      customQuestions: 'CUSTOM QUESTIONS',
    },
    navigationTitles: {
      overview: 'Overview',
      characteristics: 'Characteristics',
      results: 'Results',
      topFiveRecommendations: 'Top 5 Recommendations',
      employeeTurnoverForecast: 'Turnover',
      comparisonGraphs: 'Comparison Graphs',
      fullReport: 'Full Report',
      customQuestions: 'Custom Questions',
    },
    overview: {
      overviewDescription:
        'Your #BeHeard Premium Report provides much more than an employee engagement score. It provides key findings about the extent your employees feel passionate about their jobs, are committed to your organization, put discretionary effort into their work and are willing to refer. That’s because employee engagement relates directly to productivity, revenue, absenteeism, turnover, customer satisfaction and other organization insights. In addition to your overall employee engagement score, this report provides results on five engagement drivers, forecasts short and long-term turnover, and offers customized recommendations for taking action to increase engagement.',
    },
    characteristics: {
      description:
        'Before you dive into the results, let’s start by looking at the key characteristics of engaged and disengaged employees. This is why taking action to build employee engagement matters!',
      engagedEmployees: { title: 'Engaged Employees' },
      disengagedEmployees: { title: 'Disengaged Employees' },
    },
    participation: {
      overallRate: 'Overall Rate',
      description:
        'Click on each demographic filter below to view specific participation rates.',
      noParticipation: 'Check back, no one has participated yet.',
      demographicsWithLessThanFiveParticipants:
        'Demographics that have less than 5 participants for a particular value will show N/A. (e.g., 4 participants in the IT Department will show N/A)',
    },
    results: {
      description:
        'This section provides a snapshot of your engagement results including the Top 5 Action Areas, the Top 5 Strengths, and the Top 5 Most Important Items to your employees.',
      engagementScore: { engagement: 'Engagement', score: 'Score' },
      engagementDriversHeader: 'Engagement Driver Scores',
      effectiveness: 'EFFECTIVENESS',
    },
    progressBarLegend: {
      legendTitle: 'Effectiveness Score Legend',
      colorMeaning: {
        negative: 'Unfavorable',
        neutral: 'Neutral',
        positive: 'Favorable',
      },
    },
    driverScore: {
      alignment:
        'The extent to which employees believe in and put effort toward this organization’s mission, values and goals.',
      engagement:
        'The extent to which employees feel passionate about their jobs, are committed to our organization, put discretionary effort into their work and are willing to refer.',
      fit: 'The extent to which employees feel they are in the right jobs and have the necessary skills, knowledge and abilities to adequately perform in their roles.',
      growth:
        'The extent to which employees feel supported to advance their career or develop personal skill sets within this organization.',
      team: 'The extent to which this organization promotes a team-based work style (i.e. departments or groups work well together).',
      valuing:
        'The extent to which employees feel valued by this organization.',
      custom_ratings_questions: 'Custom Ratings Questions',
    },
    topFiveText: {
      action: {
        title: 'Top 5 Action Areas',
        description:
          'Your employees ranked the following statements as low in effectiveness and high in importance (i.e. these items have the biggest ROI Gap). You will get the greatest return on investment if you focus your engagement efforts on these five areas (listed in order of priority).',
      },
      strength: {
        title: 'Top 5 Strengths',
        description:
          'Your employees ranked the following statements as high in effectiveness and high in importance (i.e. these items have the smallest ROI Gap). Use this data to promote your employee brand in talent attraction, engagement and retention strategies. These are your greatest strengths!',
      },
      importance: {
        title: 'Top 5 Most Important Items',
        description:
          'Your employees ranked the following five statements as the most important to them.',
      },
      topFiveResultDetails: {
        effectiveness: 'Effectiveness',
        importance: 'Importance',
        previousEffectiveness: 'Previous Effectiveness',
        benchmarkEffectiveness: 'Benchmark Effectiveness',
      },
      topFiveRecommendations: {
        description:
          'These actionable recommendations are based on your Top 5 Action Areas. This is where your efforts to boost engagement will give you the largest return on investment. Time to take action!',
        noRecommendations:
          'There are no Recommendations for the results displayed.',
      },
      tooltip: { roiGap: 'Difference between effectiveness and importance' },
    },
    filterButtons: { filters: 'Filters', download: 'Download' },
    downloadMenu: {
      pdfNewTab: 'Open in new tab (PDF)',
      pdfDownload: 'PDF',
      xlsxDownload: 'Spreadsheet (.xlsx)',
    },
    noData: 'There are no {title} for the results displayed.',
    turnoverOverviewDescription:
      'The likelihood employees will leave your organization within the next twelve months.',
    turnoverForecast: {
      statement: 'STATEMENT',
      atRisk: 'At Risk',
      passive: 'Passive',
      committed: 'Committed',
      employee: 'employee',
      employees: 'employees',
      potentialTurnover: 'Potential Turnover',
      previousTurnover: 'Previous Turnover',
      description:
        "Your short and long-term outlook is predicted using your employees' responses to the turnover statements in the survey.",
      shortTerm: 'Short-Term Outlook',
      longTerm: 'Long-Term Outlook',
    },
    comparisonGraphs: {
      description:
        'Now it’s time to take a closer look at engagement results across the organization. Simply hover over the bar charts to see specific results. Depending on your permission settings, you can use Filters to change the comparison graphs.',
      demographic: 'Demographic',
      legend: {
        engaged: 'Engaged',
        neutrals: 'Neutral',
        disengaged: 'Disengaged',
      },
    },
    fullReport: {
      engagementScore: 'Engagement Score',
      participationRate: 'Participation Rate',
      legend: {
        strengths: 'Strengths',
        recommendedActionAreas: 'Recommended Action Areas',
      },
      tableHeaders: {
        score: 'Score',
        result: 'Result',
        effectiveness: 'Effectiveness',
        importance: 'Importance',
        previousEffectiveness: 'Prev. Effec.',
        benchmark: 'Benchmark',
      },
      tableData: { viewRecommendations: 'View recommendations here' },
      openEndedQuestions: 'Open-Ended Questions',
      noCustomRatingQuestions:
        'No custom ratings questions were used in this survey.',
      noCustomOpenEndedQuestions:
        'No custom open-ended questions were used in this survey.',
      buttonLabels: {
        xlsx: 'Download answers as XLSX',
        pdf: 'Download answers as PDF',
      },
    },
    unlockPremiumReport: 'CLICK HERE TO UNLOCK THE PREMIUM REPORT',
    snackbar: {
      success: {
        surveyAddedToCart: 'Survey added to your cart successfully',
        filters: 'Your filters have been applied!',
      },
    },
  },
  report: {
    upgradeReport: 'Upgrade Report',
    freeReport: 'Free Report',
    premiumReport: 'Premium Report',
    shareReport: 'Share Report',
  },
  survey: {
    status: {
      scheduled: 'Scheduled',
      open: 'Open',
      closed: 'Closed',
      awaitingManualOpening: 'Awaiting Manual Launch',
    },
    snackbar: {
      success: {
        alreadyFinished: 'You already finished this survey',
        finishedSurvey: 'You finished the survey! Thanks for taking the time',
        giftCardRedeemed:
          'Gift Card redeemed successfully! Watch for an email with your electronic gift card link.',
      },
    },
  },
  surveyKey: {
    status: {
      surveykey: 'Survey Key',
      paragraph1:
        'The survey uses Faces and Stars for rating.  Rate your workplace experience and how important it is for each statement. Satisfaction is based on Faces and Importance is based on Stars from 1 to 5. ',
      paragraph2: 'Rate Your experience about using our product.',
      title1: 'Satisfaction',
      title2: 'Importance',
    },
  },
  surveyinstruction: {
    surveyinstructionkey: 'Survey Instructions',
    surveytitle: ' This survey has a',
    twopart: 'two-part rating process',
    using: 'for each statement using',
    faces: 'Faces',
    and: 'and',
    star: 'Stars.',
    rate: 'Rate how much you',
    agree: 'agree or disagree',
    statement: 'with each statement using the',
    then: 'and then',
    how: 'how',
    important: 'important',
    eachstatement: 'each statement is to you in the workplace using a',
    starrating: '5-star rating',
    scale: 'scale.',
  },
  surveyDialogs: {
    title: 'Surveys',
    addSurvey: {
      descriptionFirstLine:
        'It’s time to make the survey your own. First, select your launch timeline below to get started. You can make changes before the survey opens and extend the survey before it closes. Click',
      link: 'HERE',
      descriptionSecondLine:
        'for best practices to launch and market the survey.',
    },
    editSurvey: {
      description:
        'Need to edit your survey? No problem. You can do that here. Please make your changes below.',
    },
    extendSurvey: { title: 'Extend Survey', closeDate: 'Close Date' },
    confirmClose: {
      title: 'Close Survey',
      actionLabel: 'Close Survey',
      close: 'close',
    },
    confirmDelete: { title: 'Remove Survey', actionLabel: 'Archive Survey' },
    demographicsPickerLabel:
        'Which Demographics will receive this survey?',
    rolesPicker: {
      label: 'Which Roles will receive this survey?',
      selectLabel: 'Roles',
    },
    employeesPicker: {
      label: 'Which Employees will be excluded from this survey?',
      selectLabel: 'Excluded Employees',
    },
    formLabels: {
      surveyName: 'Survey Name',
      launchDate: 'Launch DateTime',
      closeDate: 'Close DateTime',
      allowReward: 'Reward survey participation with a Gift Card',
      allowRewardTooltip:
        'Employees will be able to select their gift card directly from our system after they participate',
      allowAnswersToBeUpdated:
        'Give employees permission to edit their survey responses',
      allowAnswersToBeUpdatedTooltip:
        'Edits can only be made when the survey is open',
      rewardAmount: 'Gift Card Amount',
    },
    customizedQuestions: {
      header: 'Add Custom Questions/Statements',
      description:
        'In addition to the 38 questions in our statistically valid #BeHeard engagement survey, you can add up to five 5-star rating questions and five open ended questions. If you want some ideas for your custom questions, check out examples',
      link: 'HERE',
      statement: 'Statement',
      statementPlaceholder: 'Type your question/statement...',
      fiveStarQuestions: { header: '5-Star Rating Questions' },
      openEndedQuestions: { header: 'Open-Ended Questions' },
    },
  },
  surveyLanding: {
    welcomeToSparck: 'Welcome to Sparck!',
    areYouReady: 'Are You Ready to {beheard}?',
    thankYou: 'Thank you for letting your voice be heard.',
    noActiveSurveys:
      "There is no active survey to complete right now, but we're glad you checked in.",
    whatBeHeardSurveyIs:
      'The #BeHeard Survey is an anonymous survey designed to be a safe place for you to share your feedback about the workplace. Your organization cares about what you think and wants to give you a confidential voice to speak freely, that’s why they partnered with Sparck! Only the aggregated results will be shared at the end to help shape organizational strategies moving forward based on the areas that mean the most to you and your colleagues.',
    youAreAwesome:
      'You are AWESOME! Thank you for taking the time to #BeHeard. Your feedback is priceless.',
    feedbackImportance:
      "Your feedback is really important to us, so we'll send you an email as soon as a new survey is posted!",
    eachItemExplanation:
      "The survey will only take about 10 minutes to complete. Each item will have two parts: first, you'll rate to what extent you agree or disagree with each workplace statement by choosing one of the faces. Once you've done that, you'll rate how important each statement is to you using the stars. Check the animated example!",
    iAmReady: "I'm ready to take the survey!",
    updateMyAnswers: 'Review my answers!',
    surveyPagination: { previous: 'Previous', nextPage: 'Next Page' },
    submitSurvey: 'Submit Survey',
    pendingGiftCards: 'You have {count} pending Gift Cards! 🎉',
    pendingGiftCardLabel: '{rewardValue} for {surveyName}',
    redeem: 'Redeem',
    giftCardRedeemed: 'Gift Card redeemed successfully!',
    departmentTerm: 'Department',
    departmentValue: ' ',
    missionAndValueTerm: 'Mission and Values',
    missionAndValueText:
      'We are building something special, focused on unlocking the best outcomes for our customers through intelligent integration and automation.',
    missionAndValueValue: 'Values: Be Bold. Be You. Be Boomi.',
    managerTerm: 'Manager',
    managerValue: 'Your direct leader as of June 8, 2023',
    seniorLeadershipTeamTerm: 'Senior Leadership Team',
    seniorLeadershipTeamValue: 'Executive Leadership Team',
    surveyInstructionsTitle: 'Survey Instructions',
    surveyInstructionsContent:
      'This survey has a two-part rating process for each statement using Faces  and Stars.  Rate how much you agree or disagree with each statement using the faces and then how important each statement is to you in the workplace using a 5-star rating scale.',
    boomiSurveyLandingContent:
      'The #BeHeard Survey is an anonymous survey designed to be a safe place for you to share your feedback about your experience at Boomi. Boomi cares about what you think and wants to give you a confidential voice to speak freely, thats why they partnered with Sparck! Only the aggregated results will be shared at the end to help shape organizational strategies moving forward based on the areas that mean the most to you and your colleagues at Boomi. Before you get started, here are some definitions to help everyone align:',
    boomiSurveyLandingContentTiming:
      'The survey will only take about 15 minutes to complete. Each item will have two parts: first, you’ll rate to what extent you agree or disagree with each workplace statement by choosing one of the faces. Once you have done that, you will rate how important each statement is to you using the stars. Check the animated example!',
    topicCards: {
      secure: {
        title: 'Your Data is {keyword}',
        keyword: 'Secure',
        description:
          'We take data protection very seriously. Sparck uses a set of technologies and protocols that prevents data leaks and cyber attacks.',
      },
      anonymous: {
        title: 'Participation is {keyword}',
        keyword: 'Anonymous',
        description:
          'Confidentiality is very important to us! Your organization or manager will never be able to see your individual responses. The data we provide is strictly anonymous.',
      },
      giftCard: {
        title: 'This Survey Has a {keyword}!',
        keyword: 'Gift Card',
        description:
          'That’s right! As a “Thank You” for completing the survey, your organization is treating you to a giftcard of your choice. You can redeem it at the end. Enjoy!',
      },
      answersUpdate: {
        title: '{keyword} Your Answers',
        keyword: 'Update',
        description:
          'Great news! You can come back to update and edit your answers at any point while the survey is still open. Just log back in!',
      },
    },
  },
  surveyOverview: {
    snackbarSuccess: {
      openSurvey: 'The survey is now open!',
      extendedSurvey: 'The survey close date has been extended successfully!',
      closedSurvey: 'The survey has been closed!',
      deletedSurvey: 'The survey has been archived!',
      employeesInvited: 'Employees have been invited successfully!',
      addedToCart: 'Survey added to your cart successfully',
    },
    selectLabel: 'Survey',
    participation: 'PARTICIPATION',
    minimumNumberOfEmployees:
      'Your organization must have at least 5 employees to create a new survey.',
    whenSurveyCloses:
      'When your survey closes the #BeHeard results will be immediately available.',
    scheduledLaunchSurveyInfo:
      "Your survey isn't live yet.  It will Launch Automatically on {launchDate}. If you would like to launch it manually you can click the Launch Survey button or edit your survey to Launch Manually.",
    manualLaunchSurveyInfo:
      "Your survey isn't live yet and it is set to Launch Manually. When you are ready to launch your survey, click the Launch Survey button.",
    newSurvey: 'New Survey',
    launchSurvey: 'Launch Survey',
    editSurvey: 'Edit Survey',
    extendSurvey: 'Extend Survey',
    closeSurvey: 'Close Survey',
    applyUpgrade: 'Apply Upgrade',
    printSurvey: 'Print',
    applyDowngrade: 'Apply Downgrade',
    inviteEmployees: 'Invite Employees',
    deleteSurvey: 'Archive Survey',
    anticipatedTurnover: 'Anticipated Turnover more than 12 Months Away',
    overallParticipation: 'Overall Participation Rate',
    person: 'Person',
    people: 'People',
    employeesInvitation: {
      inviteEmployees: 'Invite Employees',
      chooseEmployees: 'Choose below the employees you want to invite for',
      textFieldLabel: 'Choose one or more employees',
      cancelButton: 'Cancel',
      inviteButton: 'Invite Employees',
    },
    tabs: { labels: { general: 'General', sharedReports: 'Shared Reports' } },
    validations: {
      surveyNameIsRequired: 'Survey Name is required',
      minimumLaunchDate: 'You cannot select a past date',
      maximumLaunchDate: 'Launch Date cannot be after the Close Date',
      launchDateIsRequired: 'Launch Date is required',
      minimumCloseDate: 'Close Date cannot be before Launch Date',
      closeDateIsRequired: 'Close Date is required',
      rewardAmountIsRequired: 'Gift Card amount is required',
    },
  },
  surveyProgress: {
    bulletLabels: {
      createdOn: 'Created on',
      openOn: 'Open on',
      closedOn: 'Closed on',
      willCloseOn: 'Will Close on',
    },
  },
  ratingSection: {
    toWhatExtent:
      'To what extent do you agree or disagree with this statement?',
    important: 'This is important to me',
    ratingLabels: {
      stronglyDisagree: 'Strongly Disagree',
      disagree: 'Disagree',
      neutral: 'Neutral',
      agree: 'Agree',
      stronglyAgree: 'Strongly Agree',
      notApplicable: 'Not Applicable',
    },
    change: 'Change',
    unansweredItem: 'Please respond to every item before continuing.',
    formLabels: {
      textField: 'Your answer',
      placeholder: 'Type anything you want here...',
      helperText:
        'To ensure your confidentiality, do not put any personally identifiable information.',
    },
  },
  manageShareReport: {
    reportSections: {
      labels: {
        comparisonGraph: 'Comparison Graph',
        results: 'Results',
        topFiveActionAreas: 'Top 5 Action Areas',
        topFiveStrengths: 'Top 5 Strengths',
        topFiveMostImportantItems: 'Top 5 Most Important Items',
        recommendations: 'Recommendations',
        turnoverForecast: 'Employee turnover forecast',
        fullReport: 'Full Report',
        customRatingQuestions: 'Custom Ratings Questions',
        customOpenEndedQuestions: 'Custom Open-Ended Questions',
      },
    },
    previewFor: 'Preview for {name}',
    reportWillBeSharedWithRoles: 'This report will be shared with',
    formHandler: {
      title: 'Share Report',
      labels: {
        name: 'Shared Report Name',
        expirationDate: 'How long is it available?',
        whatToSee: 'What can they see?',
        limitReport: 'Limit Report by Demographics',
        whichRoles: 'Which Roles can see this Share Report?',
        roles: 'Roles',
        whichDemographics: 'Which Demographics will receive this Share Report?',
      },
      placeholders: { beheardReport: '#BeHeard ShareReport 001' },
      dialog: {
        title: 'Warning!',
        content: {
          firstLine:
            "Are you sure you want to give access to your employee's answers?",
          secondLine:
            'The answers contain very sensitive information. Please be absolutely sure that you selected the correct demographics for this action.',
        },
        buttonText: 'I understood!',
      },
    },
    snackbars: { invitesSent: 'Invites sent successfully' },
  },
  inviteShareReport: {
    dialogTitle: 'Invite for Share Report',
    dialogActionLabel: 'Share this report',
    clickToInvite:
      'Your Shared Report was created but you need to invite. Click "Share this report" to send e-mails to your invitees',
  },
  shareReport: {
    title: 'Share Reports',
    description: 'You control who has access to your #BeHeard Premium Reports',
    columnHeaders: {
      sharedReport: 'Shared Report Name',
      surveyName: 'Survey Name',
      expires: 'Expires',
    },
    validations: {
      minimumExpirationDate: 'You cannot select a past date',
      expirationDateIsRequired: 'Expiration Date is required',
    },
  },
}
