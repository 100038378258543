import { Box, Grid, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { useIntl } from 'react-intl'
import { useCompany } from 'contexts'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

import { SurveyStatus as PulseSurveyStatus } from 'enums/survey'

type ProgressBarBulletProps = {
  filled: boolean
}

const ProgressBarBullet = styled('div')<ProgressBarBulletProps>(
  ({ theme, filled }) => ({
    width: '20px',
    height: '20px',
    borderRadius: '100%',
    zIndex: 995,
    backgroundColor: filled ? theme.palette.primary.main : '#CCC',
    border: '3px solid #f9f9f9',
  }),
)

export default function PulseSurveyOverviewProgress({
  selectedPulseSurvey,
}: any) {
  const intl = useIntl()
  const { status, created_at, open_at, close_at, actually_closed_at } = selectedPulseSurvey
  const { company, } = useCompany()!
  const closeAt = (actually_closed_at === null) ?  close_at : actually_closed_at
  
  const percentage =
    status === PulseSurveyStatus.Scheduled ||
    status === PulseSurveyStatus.AwaitingManualOpening
      ? 8
      : status === PulseSurveyStatus.Open
      ? 56
      : 100

  return (
    <Grid container alignItems='center' style={{ maxWidth: 500 }}>
      <Grid item xs={12}>
        <Grid
          container
          alignItems='center'
          justifyContent='space-between'
          style={{ position: 'relative' }}
        >
          <Box
            component='div'
            sx={{
              position: 'absolute',
              width: '95%',
              height: '6px',
              backgroundColor: '#ddd',
              margin: '0 auto',
              left: '0px',
              right: '0px',
            }}
          >
            <Box
              component='div'
              sx={{
                position: 'absolute',
                width: `${percentage}%`,
                transition: 'width 0.5s',
                height: '100%',
                backgroundColor: 'primary.main',
              }}
            />
          </Box>

          <ProgressBarBullet filled={true} />
          <ProgressBarBullet
            filled={
              status === PulseSurveyStatus.Open ||
              status === PulseSurveyStatus.Close
            }
          />
          <ProgressBarBullet filled={status === PulseSurveyStatus.Close} />
        </Grid>
        <Box mt={1} width={1}>
          <Grid container alignItems='center' justifyContent='space-between'>
            {[
              {
                label: intl.formatMessage({
                  id: 'surveyProgress.bulletLabels.createdOn',
                }),
                value: dayjs(created_at).tz(company.timezone).format('MMM Do YYYY'),
              },
              {
                label: intl.formatMessage({
                  id: 'surveyProgress.bulletLabels.openOn',
                }),
                value:
                  status === PulseSurveyStatus.AwaitingManualOpening || !open_at
                    ? intl.formatMessage({ id: 'statuses.pending' })
                    : open_at
                    ? dayjs(open_at).tz(company.timezone).format('MMM Do YYYY')
                    : '--',
              },
              {
                label:
                  status !== 'close'
                    ? intl.formatMessage({
                        id: 'surveyProgress.bulletLabels.willCloseOn',
                      })
                    : intl.formatMessage({
                        id: 'surveyProgress.bulletLabels.closedOn',
                      }),
                value: closeAt
                  ? dayjs(closeAt).tz(company.timezone).format('MMM Do YYYY')
                  : 'Open Indefinitely',
              },
            ].map((legend: any, index: number) => (
              <Grid item key={legend.label}>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  align={
                    index === 2 ? 'right' : index === 1 ? 'center' : 'left'
                  }
                >
                  {legend.label}
                </Typography>
                <Typography
                  variant='body1'
                  color='text.primary'
                  fontWeight={600}
                  align={
                    index === 2 ? 'right' : index === 1 ? 'center' : 'left'
                  }
                >
                  {legend.value}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}
