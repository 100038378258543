import { useState } from 'react'

import {
  Button,
  CircularProgress,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from 'react-query'

import { sparckBotLinkByEnvironment } from 'constants/botIntegrations'
import { useCompany } from 'contexts'
import { ENV } from 'core/environments'
import { API } from 'core/requests'
import { t } from 'i18n'

export default function SettingsSlackIntegration({
  saveSettings,
  companyState,
  setDialogSlackIntegration,
}) {
  const [workspaceID, setWorkspaceID] = useState('')
  const [userClickedSparckBotLink, setUserClickedSparckBotLink] =
    useState(false)

  const { company } = useCompany()!
  const { enqueueSnackbar } = useSnackbar()

  const { data: botSettings, refetch: fetchBotSettings } = useQuery(
    'getBotSettings',
    () =>
      API.get('botSettings', null, {
        queryParams: {
          company_id: company?.id,
        },
      }),
    {
      enabled: !!company?.id,
      onSuccess: response => {
        if (response.company_id) {
          setWorkspaceID(response.slack_workspace_id)
        }
      },
    },
  )

  const { mutate: configureSlack, isLoading: configureSlackIsLoading } =
    useMutation((payload: any) => API.update('configureSlack', payload), {
      onSuccess: (response: any) => {
        if (response.slack_workspace_id) {
          fetchBotSettings()
          enqueueSnackbar(
            `You successfully set your Workspace ID: ${response.slack_workspace_id}`,
            { variant: 'success' },
          )
        }
      },
    })

  const closeDialogAndRefetch = () => {
    saveSettings(companyState)
    setDialogSlackIntegration({ isOpen: false, data: null })
  }

  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid item xs={12}>
        <Typography variant='body1' color='text.secondary'>
          {t('settings.slackIntegration.step1')}
        </Typography>
      </Grid>

      <Grid item xs={12} sm>
        <TextField
          id='workspaceID'
          name='workspaceID'
          label={t('settings.slackIntegration.workspaceIdOfSlack')}
          value={workspaceID}
          onChange={event => setWorkspaceID(event.target.value)}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm='auto'>
        <Button
          variant='contained'
          color='primary'
          size='large'
          disabled={!workspaceID || configureSlackIsLoading}
          onClick={() =>
            configureSlack({
              company_id: company?.id,
              workspace_id: workspaceID,
            })
          }
        >
          {t('settings.slackIntegration.setWorkspaceID')}{' '}
          {configureSlackIsLoading && <CircularProgress size={16} />}
        </Button>
      </Grid>

      {botSettings?.slack_workspace_id && (
        <>
          <Grid item xs={12} mt={1}>
            <Typography variant='body1' color='text.secondary'>
              {t('settings.slackIntegration.step2')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Link
              href={sparckBotLinkByEnvironment[ENV]}
              target='_blank'
              rel='noopener noreferrer'
              onClick={() => {
                setUserClickedSparckBotLink(true)
              }}
            >
              {t('settings.slackIntegration.allowSparckToIntegrateSlack')}
            </Link>
          </Grid>

          {userClickedSparckBotLink && (
            <Grid item xs={12}>
              <Typography
                variant='body1'
                fontWeight={600}
                color='text.secondary'
              >
                {t('settings.slackIntegration.alreadyClickedSparckBot', {
                  link: (
                    <Link onClick={closeDialogAndRefetch}>
                      {t('clickHere')}
                    </Link>
                  ),
                })}
              </Typography>
            </Grid>
          )}
        </>
      )}
    </Grid>
  )
}
