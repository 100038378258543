import { useQuery, useMutation } from 'react-query'
import { useSnackbar } from 'notistack'

import { ManagerCertificationTaskComponent } from './components'
import { API } from 'core/requests'
import { t } from 'i18n'

export default function ManagerCertificationTask() {
  const { enqueueSnackbar } = useSnackbar()

  const { data: userCertification, refetch: fetchUserCertification } = useQuery(
    'getUserCertification',
    () => API.get('employeeRecognitionCertification'),
    { enabled: true },
  )

  const userCertificationMutation = useMutation(
    (payload: any) => API.update('employeeRecognitionCertification', payload),
    {
      onSuccess: () => {
        fetchUserCertification()
        enqueueSnackbar(
          t(
            'managerCertification.managerCertificationQuizDialog.snackbarSuccess',
          ),
          {
            variant: 'success',
          },
        )
      },
    },
  )

  return (
    <ManagerCertificationTaskComponent
      userCertification={userCertification}
      fetchUserCertification={fetchUserCertification}
      userCertificationMutation={userCertificationMutation}
    />
  )
}
