import { useState } from 'react'

import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material'

import { DialogTeleport, LibrarySelection, SparckTable } from 'components'
import { Props as VirginExperienceProps } from 'pages/virginExperiencesSettings/VirginExperiencesSettings.props'
import { formatToUSD } from 'utils/currency'
import { getDateLabel } from 'utils/tables/dataTypes'
import { getUserFullName } from 'utils/user'

export default function VirginExperiencesSettingsManageCreditPaymentsDialog(
  props: VirginExperienceProps,
) {
  const {
    virginExperiencesCreditPayments,
    virginExperiencesCreditPaymentsIsLoading,
    manageCreditPaymentsDialogOpen,
    setManageCreditPaymentsDialogOpen,
    createCreditPaymentDialogOpen,
    setCreateCreditPaymentDialogOpen,
    virginExperiencesPendingOrders,
    virginExperiencesPendingOrdersAreLoading,
  } = props

  const [selectedOrdersData, setSelectedOrdersData] = useState(null)

  const onSelectOrdersIds = async selected => {
    await setSelectedOrdersData(getSelectedOrdersData(selected) as any)
    setCreateCreditPaymentDialogOpen(true)
  }

  const getSelectedOrdersData = selectedOrders => ({
    orders: selectedOrders,
    totalAmount: selectedOrders.reduce(
      (acc, value) => acc + +value.total_amount,
      0,
    ),
  })

  const handleDialogClose = () => setManageCreditPaymentsDialogOpen(false)

  return (
    <>
      <DialogTeleport
        dialogOpen={manageCreditPaymentsDialogOpen}
        handleDialogClose={handleDialogClose}
        dialogSize={'lg'}
        dialogTitle={'Credit Payments Management'}
        isFullWidth
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {virginExperiencesPendingOrdersAreLoading ? (
              <Grid container justifyContent='center'>
                <Grid item xs='auto'>
                  <CircularProgress />
                </Grid>
              </Grid>
            ) : (
              <LibrarySelection
                title={'Pending Orders'}
                rows={virginExperiencesPendingOrders}
                columns={[
                  {
                    id: 'id',
                    label: 'Order ID',
                  },
                  {
                    id: 'customer_first_name',
                    label: 'Rewarded User',
                    render: row =>
                      getUserFullName({
                        first_name: row.customer_first_name,
                        last_name: row.customer_last_name,
                      }),
                  },
                  {
                    id: 'total_amount',
                    label: 'Total Amount',
                    render: row => formatToUSD(row.total_amount),
                  },
                  {
                    id: 'product_sku',
                    label: 'Product SKU',
                  },
                  {
                    id: 'status',
                    label: 'Status',
                  },
                  {
                    id: 'created_at',
                    label: 'Created At',
                  },
                ]}
                orderBy='created_at'
                selectedIds={[]}
                rowsPerPage={virginExperiencesPendingOrders?.length}
                onSelectClick={onSelectOrdersIds}
              />
            )}
          </Grid>

          <Grid item xs={12} sx={{ mt: 4 }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant='h6' color='text.primary'>
                  Credit Payments
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <SparckTable
                  slug='creditPayments'
                  data={virginExperiencesCreditPayments}
                  isLoading={virginExperiencesCreditPaymentsIsLoading}
                  columns={[
                    {
                      field: 'id',
                      title: 'ID',
                    },
                    {
                      field: 'amount',
                      title: 'Amount',
                      render: (rowData: any) => formatToUSD(rowData.amount),
                    },
                    {
                      field: 'status',
                      title: 'Status',
                    },
                    {
                      field: 'created_at',
                      title: 'Created At',
                      render: (rowData: any) =>
                        getDateLabel(rowData.created_at),
                    },
                    {
                      field: 'order_ids',
                      title: 'Order IDs',
                      render: (rowData: any) => rowData?.order_ids?.join(','),
                    },
                  ]}
                  hideToolbar
                  style={{ boxShadow: 'none' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogTeleport>

      <DialogTeleport
        dialogTitle={'Confirm Credit Payment'}
        dialogAction={null}
        dialogSize='md'
        dialogOpen={createCreditPaymentDialogOpen}
        handleDialogClose={() => setCreateCreditPaymentDialogOpen(false)}
        isFullWidth
      >
        <VirginExperiencesSettingsConfirmCreditPayment
          {...props}
          creditPaymentData={selectedOrdersData}
        />
      </DialogTeleport>
    </>
  )
}

function VirginExperiencesSettingsConfirmCreditPayment({
  creditPaymentMutation,
  creditPaymentData,
}) {
  const [details, setDetails] = useState('')

  const createCreditPayment = () =>
    creditPaymentMutation.mutate({
      order_ids: creditPaymentData.orders.map(order => +order.id),
      amount: creditPaymentData.totalAmount,
      details: details,
    })

  if (!creditPaymentData) return null

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='body1' color='text.primary' paragraph>
          Are you sure you want to confirm this credit payment? (add details if
          needed)
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ mb: 3 }}>
        <SparckTable
          slug='confirmCreditPaymentOrders'
          data={creditPaymentData.orders}
          columns={[
            {
              field: 'id',
              title: 'Order ID',
            },
            {
              field: 'total_amount',
              title: 'Total Amount',
              render: (row: any) => formatToUSD(row.total_amount),
            },
            {
              field: 'created_at',
              title: 'Created At',
            },
          ]}
          style={{ boxShadow: 'none' }}
          hideToolbar
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant='body1' color='text.primary' paragraph>
          Total Amount:{' '}
          <Typography
            component='span'
            variant='body1'
            color='text.primary'
            fontWeight={700}
          >
            {formatToUSD(creditPaymentData.totalAmount)}
          </Typography>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          multiline
          id='details'
          label='Credit Payment Details'
          value={details}
          onChange={event => setDetails(event.target.value)}
          minRows={3}
          maxRows={5}
          margin='normal'
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent='flex-end'>
          <Grid item xs='auto'>
            <Button
              variant='contained'
              color='primary'
              onClick={createCreditPayment}
              disabled={creditPaymentMutation.isLoading}
            >
              Confirm Credit Payment{' '}
              {creditPaymentMutation.isLoading && (
                <CircularProgress size={24} />
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
