import { Grid, Switch } from '@mui/material'
import { useIntl } from 'react-intl'

import { SettingsControlSection } from '../SettingsComponent/SettingsComponent'

export default function SettingsProfanityFilter(props) {
  const intl = useIntl()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} data-cy='profanity_filter'>
        <SettingsControlSection
          {...props}
          id='profanity_filter_enabled'
          label={intl.formatMessage({
            id: 'settings.sectionControlLabels.profanityFilter.enableProfanityFilter',
          })}
          ControlComponent={Switch}
        />
      </Grid>
    </Grid>
  )
}
