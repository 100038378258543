import { Box, Divider, Grid, Typography, useTheme } from '@mui/material'
import { ChatOutlined as ChatIcon } from '@mui/icons-material'
import { format, parseISO } from 'date-fns'
import { get } from 'lodash'

import CompanyCard from '../CompanyCard/CompanyCard'
import { getSuggestionsStatusDetails } from 'constants/suggestions'
import { ROUTES_PATHS } from 'core/routes'
import { t } from 'i18n'

export default function CompanySuggestionUpdatesComponent(props: any) {
  const { suggestions, isSuggestionsLoading } = props

  const theme = useTheme()

  const renderLabelAndValue = (label: string, value: string | any) => (
    <Box mr={1}>
      <Typography
        color='text.secondary'
        fontWeight='bold'
        variant='body2'
        paragraph
      >
        {label}:{' '}
        <Typography color='text.secondary' variant='body2' component='span'>
          {value}
        </Typography>
      </Typography>
    </Box>
  )

  return (
    <CompanyCard
      list={suggestions}
      isListLoading={isSuggestionsLoading}
      title={t('myCompany.companySuggestionUpdates.cardTitle')}
      emptyMessage={t('myCompany.companySuggestionUpdates.emptyMessage')}
      redirectMessage={t(
        'myCompany.companySuggestionUpdates.seeAllSuggestions',
      )}
      redirectRoute={ROUTES_PATHS.suggestions}
    >
      {suggestions.map((suggestionUpdate: any) => (
        <Box mb={3} key={suggestionUpdate.id}>
          <Grid container>
            <Grid item>
              <Box mr={3}>
                <ChatIcon color='primary' fontSize='large' />
              </Box>
            </Grid>

            <Grid item xs>
              <Typography
                color='text.secondary'
                fontWeight='bold'
                variant='h6'
                paragraph
              >
                {format(parseISO(suggestionUpdate.created_at), 'P')} •{' '}
                {get(suggestionUpdate, 'user.name', 'Anonymous')}
              </Typography>

              <Grid container justifyContent='space-between'>
                {renderLabelAndValue(
                  t('myCompany.companySuggestionUpdates.columnLabels.date'),
                  format(parseISO(suggestionUpdate.created_at), 'P'),
                )}
                {renderLabelAndValue(
                  t('myCompany.companySuggestionUpdates.columnLabels.from'),
                  get(suggestionUpdate, 'user.name', 'Anonymous'),
                )}
                {renderLabelAndValue(
                  t(
                    'myCompany.companySuggestionUpdates.columnLabels.numberOfReplies',
                  ),
                  get(suggestionUpdate, 'replies', []).length,
                )}
                {renderLabelAndValue(
                  t('status'),
                  <span
                    style={{
                      color:
                        getSuggestionsStatusDetails(theme)[
                          suggestionUpdate.status
                        ].color,
                    }}
                  >
                    {
                      getSuggestionsStatusDetails(theme)[
                        suggestionUpdate.status
                      ].label
                    }
                  </span>,
                )}
                <Grid item xs={12}>
                  {renderLabelAndValue(
                    t(
                      'myCompany.companySuggestionUpdates.columnLabels.suggestion',
                    ),
                    suggestionUpdate.description,
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
        </Box>
      ))}
    </CompanyCard>
  )
}
