import { CircularProgress, Grid, useTheme } from '@mui/material'

import { ReceivedReward } from 'components'
import { RewardType } from 'enums/reward'
import { getRandomColor } from 'types/colors'

export default function MyRewardsGrid({
  myRewards,
  myRewardsIsLoading,
  fetchRewards,
}) {
  const theme = useTheme()

  return (
    <Grid container spacing={2}>
      {myRewardsIsLoading ? (
        <Grid item xs={12}>
          <Grid container justifyContent='center' sx={{ mb: 3 }}>
            <CircularProgress size={64} />
          </Grid>
        </Grid>
      ) : (
        (myRewards ?? []).map(reward => (
          <Grid item xs={12} sm={6} md={4} key={reward.id}>
            {reward.type === RewardType.Company ? (
              <ReceivedReward
                reward={{
                  ...reward,
                  backgroundColor: getRandomColor(theme, { isGradient: true }),
                }}
                fetchSparck={fetchRewards}
                redeemRewardCallback={() => {
                  fetchRewards()
                }}
              />
            ) : (
              <ReceivedReward reward={reward} fetchSparck={fetchRewards} />
            )}
          </Grid>
        ))
      )}
    </Grid>
  )
}
