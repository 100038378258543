/* eslint-disable @typescript-eslint/no-unused-vars */
import { Fragment, useCallback, useState, useMemo, useEffect } from 'react'
// import { useIntl } from 'react-intl'
import {
  Close as CloseIcon,
  CardGiftcardOutlined as RewardNGCIcon,
  LocalCafeOutlined as RewardIcon,
} from '@mui/icons-material'
import { Button, Card, CircularProgress, Collapse, Grid, MenuItem, TextField, Typography } from '@mui/material'
import { get, isEmpty, shuffle, uniqBy } from 'lodash'

import {
  SparckConfirmGiftCardRewardDetails,
  SparckConfirmVirginExperiencesRewardDetails,
  SparckRetailerRewardsList,
  SparckOrganizationalRewardsList,
  SparckCorporateRewardsList,
} from '..'
import {
  DialogTeleport,
  OrganizationalRewardCard,
  GiftCardRewardCard,
  VirginExperiencesRewardCard,
} from 'components'
import { initialDialog } from 'constants/dialog'
import { useCompany } from 'contexts'
import { RewardProvider, RewardType } from 'enums/reward'
import { WishType } from 'enums/wish'
import { t } from 'i18n'
// import { formatToUSD } from 'utils/currency'
// import { getRewardName } from 'utils/reward'
import { getUserWishesByWishType } from 'utils/wishlist'
import CorporateRewardCard from 'components/CorporateRewardCard'
// import { API } from 'core/requests'
// import { getUserTokenFromLocalStorage } from 'utils'
// import { any } from 'cypress/types/bluebird'
// import { API_ROUTES } from 'core/api'
// import { API_BASE_URL } from 'core/environments'
// import { useMutation } from 'react-query'
import { Box } from '@mui/system'

const displayRetailerRewards = (company, sparckState) =>
  sparckState?.event?.has_retailer_reward && get(company, 'rewards_system')

const displayOrganizationalRewards = (company, sparckState) =>
  sparckState?.event?.has_company_reward &&
  !sparckState?.event?.automatically_bank_company_reward &&
  get(company, 'rewards_system')

// const displayCorporateRewards = (company, sparckState) =>
//   get(company, 'rewards_system')

const displayCorporateRewards = (company, sparckState) =>
  get(company, 'rewards_system')

const REWARDS_PREVIEW_COUNT = 6

export default function SparckRewardsSections(props) {
  const {
    sparckState,
    setSparckState,
    rewards,
    rewardsNGC,
    showMessageInput,
    topFiveWishlistedRewards,
    surveyRewardIntentions,
    handleSearch
  } = props

  const { company } = useCompany()!

  const [
    confirmGiftCardRewardDetailsDialog,
    setConfirmGiftCardRewardDetailsDialog,
  ] = useState(initialDialog)
  const [
    confirmVirginExperiencesRewardDetailsDialog,
    setConfirmVirginExperiencesRewardDetailsDialog,
  ] = useState(initialDialog)

  const [retailerRewardsDialogOpen, setRetailerRewardsDialogOpen] =
    useState(false)
  const [organizationalRewardsDialogOpen, setOrganizationalRewardsDialogOpen] =
    useState(false)
  const [corporateRewardsDialogOpen, setCorporateRewardsDialogOpen] =
    useState(false)

  const [retailerRewardsPreview, setRetailerRewardsPreview] = useState<any[]>(
    [],
  )
  const [organizationalRewardsPreview, setOrganizationalRewardsPreview] =
    useState<any[]>([])

  const [corporateRewardsPreview, setCorporateRewardsPreview] =
    useState<any[]>([])

  const selectedOrganizationalReward = sparckState.rewards?.find(
    reward => reward?.type === RewardType.Company,
  )
  const selectedRetailerReward = sparckState.rewards?.find(
    reward => reward?.type === RewardType.Retailer,
  )
  const selectedCorporateReward = sparckState.rewards?.find(
    reward => reward?.type === RewardType.Corporate,
  )

  const handleRetailerRewardSelect = reward => {
    if (reward?.brand?.id) {
      setConfirmGiftCardRewardDetailsDialog({ isOpen: true, data: reward })
    }

    if (reward?.sku) {
      setConfirmVirginExperiencesRewardDetailsDialog({
        isOpen: true,
        data: reward,
      })
    }
  }

  const handleOrganizationalRewardSelect = useCallback(
    reward => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const newRewards = sparckState.rewards.filter(
        reward => reward.type !== RewardType.Company,
      )

      setSparckState({
        ...sparckState,
        rewards: [
          {
            provider: RewardProvider.Company,
            type: RewardType.Company,
            product_id: reward.id,
            amount: reward.amount,
            details: reward,
          },
        ],
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sparckState],
  )

  const handleCorporateRewardSelect = useCallback(
    cor_reward => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const newRewards = sparckState.rewards.filter(
        reward => reward.type !== RewardType.Corporate,
      )

      setSparckState({
        ...sparckState,
        rewards: [
          {
            provider: RewardProvider.Corporate,
            type: RewardType.Corporate,
            //corp_type: RewardType.Corporate,
            product_id: cor_reward.id,
            amount: cor_reward.sub_total,
            details: cor_reward,
          },
        ],
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sparckState],
  )

  const handleRewardByType = useCallback(
    rewardType => {
      const newRewards = sparckState.rewards?.filter(
        reward => reward.type !== rewardType,
      )

      setSparckState({
        ...sparckState,
        rewards: newRewards,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sparckState],
  )

  const getRewardsPreview = useCallback(
    type => {
      if (
        sparckState.event_id &&
        sparckState?.individual_to_recognize?.id &&
        topFiveWishlistedRewards
      ) {
        const rewardsList = type === 'organizational' ? rewards : rewardsNGC
        const wishType =
          type === 'organizational' ? WishType.Company : WishType.Retailer

        const topOrganizationRewards = get(
          topFiveWishlistedRewards,
          `data.company.${type}`,
          [],
        )

        const topSparckRewards = get(
          topFiveWishlistedRewards,
          `data.sparck.${type}`,
          [],
        )

        const userToRecognizeWishedRewardsIds = getUserWishesByWishType(
          sparckState?.individual_to_recognize,
          wishType,
        )?.map(wish => +wish.wishable_id)

        const wishedRewards = shuffle(
          rewardsList?.filter(reward =>
            userToRecognizeWishedRewardsIds?.includes(+reward.id),
          ),
        )
        const rewardsListMaxAmount = rewardsList


        const rewardsPreview = uniqBy(
          [
            ...wishedRewards,
            ...topOrganizationRewards,
            ...(type === 'retailer' ? topSparckRewards : []),
            ...rewardsListMaxAmount,
          ],
          'id',
        ).slice(0, REWARDS_PREVIEW_COUNT)

        return rewardsPreview
      }

      return []
    },
    [
      rewards,
      rewardsNGC,
      sparckState.event_id,
      sparckState?.individual_to_recognize,
      topFiveWishlistedRewards,
    ],
  )

  const rewardsSections = useMemo(
    () => [
      // {
      //   id: 'retailerRewards',
      //   selectedReward: selectedRetailerReward,
      //   displayRewards: displayRetailerRewards,
      //   title: 'Retailer Rewards',
      //   list: rewardsNGC,
      //   listPreview: retailerRewardsPreview ?? [],
      //   Icon: RewardNGCIcon,
      //   getRewardCard: selectedReward =>
      //     selectedReward?.details?.brand?.id || selectedReward?.brand?.id
      //       ? GiftCardRewardCard
      //       : VirginExperiencesRewardCard,
      //   onSelectReward: handleRetailerRewardSelect,
      //   onRemoveReward: () => handleRewardByType(RewardType.Retailer),
      //   rewardsDialogOpen: retailerRewardsDialogOpen,
      //   setRewardsDialogOpen: setRetailerRewardsDialogOpen,
      //   RewardsDialog: SparckRetailerRewardsList
      // },
      // {
      //   id: 'organizationalRewards',
      //   selectedReward: selectedOrganizationalReward,
      //   displayRewards: displayOrganizationalRewards,
      //   title: 'Organizational Rewards',
      //   list: rewards,
      //   listPreview: rewards ?? [],
      //   Icon: RewardIcon,
      //   getRewardCard: () => OrganizationalRewardCard,
      //   onSelectReward: handleOrganizationalRewardSelect,
      //   onRemoveReward: () => handleRewardByType(RewardType.Company),
      //   rewardsDialogOpen: organizationalRewardsDialogOpen,
      //   setRewardsDialogOpen: setOrganizationalRewardsDialogOpen,
      //   RewardsDialog: SparckOrganizationalRewardsList,
      // },
      {
        id: 'corporateRewards',
        selectedReward: selectedCorporateReward,
        displayRewards: displayCorporateRewards,
        // title: 'Corporate Rewards',
        title: `${company.name} Reward Store`,
        list: surveyRewardIntentions,
        listPreview: surveyRewardIntentions ?? [],
        Icon: RewardIcon,
        getRewardCard: (selectedReward) => CorporateRewardCard,
        onSelectReward: handleCorporateRewardSelect,
        onRemoveReward: () => handleRewardByType(RewardType.Corporate),
        rewardsDialogOpen: corporateRewardsDialogOpen,
        setRewardsDialogOpen: setCorporateRewardsDialogOpen,
        RewardsDialog: SparckCorporateRewardsList,
        handleSearch: handleSearch
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      handleOrganizationalRewardSelect,
      handleRewardByType,
      handleCorporateRewardSelect,
      setCorporateRewardsDialogOpen,
      corporateRewardsDialogOpen,
      organizationalRewardsDialogOpen,
      organizationalRewardsPreview,
      retailerRewardsDialogOpen,
      retailerRewardsPreview,
      rewards,
      rewardsNGC,
      selectedOrganizationalReward,
      selectedRetailerReward,
      selectedCorporateReward
    ],
  )

  useEffect(() => {
    if (
      retailerRewardsPreview?.length === 0 &&
      (rewardsNGC?.length > 0 || topFiveWishlistedRewards?.length > 0)
    ) {
      setRetailerRewardsPreview([...getRewardsPreview('retailer')])
    }

    if (
      organizationalRewardsPreview?.length === 0 &&
      (rewards?.length > 0 || topFiveWishlistedRewards?.length > 0)
    ) {
      setOrganizationalRewardsPreview([...getRewardsPreview('organizational')])
    }

    if (
      corporateRewardsPreview?.length === 0 &&
      (surveyRewardIntentions?.length > 0 || topFiveWishlistedRewards?.length > 0)
    ) {
      setCorporateRewardsPreview([...getRewardsPreview('organizational')])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sparckState.individual_to_recognize,
    sparckState.event_id,
    rewards,
    rewardsNGC,
    topFiveWishlistedRewards,
  ])

  return (
    !isEmpty(sparckState.users_to_recognize) &&
    sparckState.event_id &&
    showMessageInput && (
      <>
        <Grid item xs={12}>
          <Collapse
            in={
              !isEmpty(sparckState.users_to_recognize) &&
              sparckState.event_id &&
              showMessageInput
            }
            timeout={600}
          >
            <Grid container justifyContent='center' spacing={2} sx={{ my: 2 }}>
              {rewardsSections && rewardsSections.map(
                ({
                  id,
                  selectedReward,
                  displayRewards,
                  title,
                  listPreview,
                  Icon,
                  getRewardCard,
                  onSelectReward,
                  onRemoveReward,
                  rewardsDialogOpen,
                  setRewardsDialogOpen,
                  RewardsDialog,
                  handleSearch
                }) => {
                  if (id === "retailerRewards") return false;

                  const RewardCard = getRewardCard(selectedReward)

                  return (
                    displayRewards(company, sparckState) && (
                      <Fragment key={id}>
                        {(selectedReward && sparckState.rewards) && (
                          <Grid item xs={12} md={6} lg={6} key={id}>
                            <Card>
                              <Grid
                                container
                                direction='row'
                                sx={{ height: 1 }}
                              >
                                <Grid item xs={12} sx={{ mt: 2, mb: 1, px: 2 }}>
                                  <RewardCard
                                    reward={selectedReward.details}
                                    cardSx={{
                                      aspectRatio: '16/9',
                                      width: '100%',
                                    }}
                                    {...(id === 'corporateRewards' && {
                                      selectedRewards: true
                                    })}
                                  />
                                </Grid>
                                {/* {
                                  id === "corporateRewards" && <Grid item xs={12} sx={{ mt: 2, mb: 0.5, px: 2 }}>
                                    <Box
                                      sx={{
                                        padding: 1,
                                        bgcolor: 'text.primary',
                                        color: 'text.white',
                                      }}
                                    >
                                      <Typography variant='body2' fontWeight={600} align='center'>
                                        {`${selectedReward?.amount}  Points`}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                } */}
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ mt: 2, mb: 0.5, px: 2 }}
                                >
                                  <Typography
                                    variant='body1'
                                    fontWeight={500}
                                    align='center'
                                    sx={{
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {/* <Typography
                                      component='span'
                                      variant='h6'
                                      fontWeight={700}
                                    >
                                      {/* {formatToUSD(selectedReward?.amount)}{' '} *}
                                      {Math.round(selectedReward?.amount)} 
                                      {
                                        <Typography
                                          component='span'
                                          color='text.secondary'
                                          sx={{ marginLeft: '2px' }}
                                          fontSize={14}
                                        >
                                          Points
                                        </Typography>
                                      } {' '}
                                    </Typography> */}
                                    {/* {getRewardName(selectedReward?.details)
                                      ? getRewardName(selectedReward?.details)
                                      : selectedReward?.details?.brand?.name ??
                                      t('sparcks.sparck.selectedReward')}{' '} */}
                                  </Typography>
                                </Grid>

                                <Grid item xs={12} sx={{ mb: 1 }}>
                                  <Grid
                                    container
                                    spacing={2}
                                    justifyContent='center'
                                  >
                                    <Grid item>
                                      <Button
                                        color='error'
                                        onClick={onRemoveReward}
                                      >
                                        <CloseIcon
                                          sx={{ fontSize: '1.15em', mr: 0.25 }}
                                        />{' '}
                                        Remove
                                      </Button>
                                    </Grid>

                                    <Grid item>
                                      <Button
                                        color='info'
                                        onClick={() =>
                                          setRewardsDialogOpen(true)
                                        }
                                      >
                                        {t('sparcks.sparck.chooseAnother')}
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                        )}

                        {
                          !sparckState.rewards.length && (
                            <Grid item xs={12} key={id}>
                              <RewardsTrendingList
                                id={id}
                                title={title}
                                Icon={Icon}
                                listPreview={listPreview}
                                getRewardCard={getRewardCard}
                                onSelectReward={onSelectReward}
                                setRewardsDialogOpen={setRewardsDialogOpen}
                                handleSearch={handleSearch}
                                CorporateRewardsCategory={sparckState?.CorporateRewardsCategory}
                                corporateRewardsLoading={sparckState?.corporateRewardsLoading}
                                sparckState={sparckState}
                              />
                            </Grid>
                          )
                        }

                        <DialogTeleport
                          dialogOpen={rewardsDialogOpen}
                          handleDialogClose={() => setRewardsDialogOpen(false)}
                          dialogSize='lg'
                          dialogTitle={title}
                          isFullWidth
                          scroll='body'
                        >
                          <RewardsDialog
                            {...props}
                            onSelectReward={reward => {
                              onSelectReward(reward)
                              setRewardsDialogOpen(false)
                            }}
                          />
                        </DialogTeleport>
                      </Fragment>
                    )
                  )
                },
              )}

              {sparckState?.event?.automatically_bank_company_reward && (
                <Grid item xs={12}>
                  <Typography
                    variant='body1'
                    fontWeight={500}
                    color='secondary'
                    align='center'
                    paragraph
                  >
                    {t(
                      'sparcks.sparck.selectedRecognitionEventAutomaticBankWarning',
                    )}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Collapse>
        </Grid>

        <DialogTeleport
          dialogOpen={confirmGiftCardRewardDetailsDialog.isOpen}
          dialogSize='xs'
          dialogTitle={t('sparcks.sparck.giftCardRewardDetails')}
          handleDialogClose={() =>
            setConfirmGiftCardRewardDetailsDialog(initialDialog)
          }
        >
          <SparckConfirmGiftCardRewardDetails
            reward={confirmGiftCardRewardDetailsDialog?.data}
            onConfirmReward={reward => {
              const newRewards = sparckState.rewards?.filter(
                reward => reward.type !== RewardType.Retailer,
              )
              setSparckState({
                ...sparckState,
                rewards: [...newRewards, reward],
              })

              setConfirmGiftCardRewardDetailsDialog(initialDialog)
            }}
            giftCardMaximumValue={
              +sparckState?.event?.maximum_reward_amount_for_each_recognition
            }
          />
        </DialogTeleport>

        <DialogTeleport
          dialogOpen={confirmVirginExperiencesRewardDetailsDialog.isOpen}
          dialogSize='sm'
          dialogTitle={t('sparcks.sparck.virginExperiencesRewardDetails')}
          handleDialogClose={() =>
            setConfirmVirginExperiencesRewardDetailsDialog(initialDialog)
          }
        >
          <SparckConfirmVirginExperiencesRewardDetails
            reward={confirmVirginExperiencesRewardDetailsDialog?.data}
            onConfirmReward={reward => {
              const newRewards = sparckState.rewards?.filter(
                reward => reward.type !== RewardType.Retailer,
              )
              setSparckState({
                ...sparckState,
                rewards: [...newRewards, reward],
              })

              setConfirmVirginExperiencesRewardDetailsDialog(initialDialog)
            }}
            rewardMaximumValue={
              +sparckState?.event?.maximum_reward_amount_for_each_recognition
            }
          />
        </DialogTeleport>
      </>
    )
  )
}

function RewardsTrendingList({
  id,
  title,
  Icon,
  listPreview,
  getRewardCard,
  onSelectReward,
  setRewardsDialogOpen,
  handleSearch,
  CorporateRewardsCategory,
  corporateRewardsLoading,
  sparckState
}) {
  const [searchText, setSearchText] = useState('')
  const [currSelected, setCurrSelected] = useState("")

  const renderLoading = () => (
    <Box my={5} width={1}>
      <Grid container justifyContent='center'>
        <CircularProgress size={64} />
      </Grid>
    </Box>
  )

  useEffect(() => {
    if (CorporateRewardsCategory && CorporateRewardsCategory.length) {
      setCurrSelected(CorporateRewardsCategory[1]["id"]);
    }
  }, [CorporateRewardsCategory]);

  return (
    <>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item xs>
              <Typography component='h3' variant='body1' fontWeight={500}>
                <Grid component='span' container alignItems='center'>
                  <Icon sx={{ mr: 1 }} /> {title}
                </Grid>
              </Typography>
              <Grid item xs={12} sx={{ marginBottom: 0, marginTop: 1 }}>
                <Typography component='div' variant='body2' color='text.secondary'>
                  Great news, you can reward <Typography component='span' variant='body2' color='secondary' fontWeight={700}>{Math.round(sparckState?.event?.maximum_reward_amount_for_each_recognition)}</Typography> points. Personalize your gift below.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {id === "corporateRewards" &&
            <Grid container sx={{ mt: 4 }} spacing={.5} component={'form'} onSubmit={(event) => {
              event.preventDefault();
              handleSearch({ searchText, currSelected });
            }}>
              <>
                <Grid item xs={12} md={6} xl={6}>
                  <TextField
                    data-cy='event'
                    select
                    id='event_id'
                    name='event_id'
                    value={currSelected}
                    size='small'
                    // style={{ maxWidth: 150, marginBottom: 16, marginLeft: 10, height: 7 }}
                    onChange={event => {
                      setCurrSelected(event.target.value);
                    }}
                    variant='outlined'
                    label={t('sparcks.sparck.formLabels.rewardsCatalog')}
                    fullWidth
                  >
                    {(CorporateRewardsCategory ?? []).map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={3} xl={3}>
                  <TextField
                    id='rewardsSearch'
                    variant='outlined'
                    className='custom_SparckRewardSection'
                    size='small'
                    // style={{ maxWidth: 300, marginBottom: 16, marginLeft: 10, height: 7 }}
                    value={searchText}
                    onChange={(event: any) => {
                      setSearchText(event.target.value)
                    }}
                    label={t('sparcks.sparck.rewardsSearch')}
                    // placeholder={intl.formatMessage({
                    //   id: 'sparcks.sparck.searchForAReward',
                    // })}
                    placeholder='Search from our Store'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3} xl={3}>
                  <Button
                    variant='contained'
                    color='primary'
                    style={{ marginLeft: 5, marginTop: 5 }}
                    size='small'
                    type='submit'
                  >
                    search
                  </Button>

                  <Button
                    variant='contained'
                    color='primary'
                    style={{ marginLeft: 5, marginTop: 5 }}
                    size='small'
                    onClick={() => setRewardsDialogOpen(true)}
                  >
                    {t('sparcks.sparck.viewAll')}
                  </Button>
                </Grid>
              </>
            </Grid>
          }
        </Grid>

        {id != "corporateRewards" ? (listPreview ?? [])?.filter((_, index) => index < REWARDS_PREVIEW_COUNT)
          .map((reward, index) => {
            const RewardCard = getRewardCard(reward)

            return (
              <Grid
                item
                xs={12}
                className='custom_topProductDisplay'
                sm={index <= 1 ? 6 : 4}
                lg={listPreview?.length < REWARDS_PREVIEW_COUNT ? 3 : true}
                key={reward.id}
              >
                <RewardCard
                  id={id}
                  reward={reward}
                  onClick={onSelectReward}
                  {...(id === 'organizationalRewards' && {
                    cardSx: {
                      height: 1,
                    },
                  })}
                />
              </Grid>
            )
          }) : corporateRewardsLoading ? renderLoading() : (!corporateRewardsLoading && !listPreview?.length) ?
            <Grid item xs={12} sx={{ my: 3 }}>
              <Typography variant='body1' align='center' color='text.secondary'>
                {t('sparcks.sparck.noRewardsFound')}
              </Typography>
            </Grid>
            :
            (listPreview ?? [])?.filter((_, index) => index < REWARDS_PREVIEW_COUNT)
              .map((reward, index) => {
                const RewardCard = getRewardCard(reward)
                return (
                  <Grid
                    item
                    xs={12}
                    className='custom_topProductDisplay'
                    sm={index <= 1 ? 6 : 4}
                    lg={listPreview?.length < REWARDS_PREVIEW_COUNT ? 3 : true}
                    key={reward.id}
                  >
                    <RewardCard
                      id={id}
                      reward={reward}
                      onClick={onSelectReward}
                    />
                  </Grid>
                )
              })}
      </Grid>
    </>
  )
}