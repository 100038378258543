import { Badge, Grid, IconButton, useTheme } from '@mui/material'
import { ShoppingCartOutlined as ShoppingCartIcon } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'

import { ROUTES_PATHS } from 'core/routes'
import { UserRole } from 'enums/user'

export default function TopbarShoppingCart({ user, shoppingCartItemsCount }) {
  const theme: any = useTheme()
  const history = useHistory()

  if (
    shoppingCartItemsCount > 0 &&
    (user.sparck_administrator ||
      [UserRole.CompanyAdmin].some(role => user.roles.includes(role)))
  ) {
    return (
      <Grid item>
        <IconButton
          type='button'
          onClick={() => history.push(ROUTES_PATHS.shoppingCart)}
          size='large'
        >
          <Badge badgeContent={shoppingCartItemsCount} color='primary'>
            <ShoppingCartIcon
              style={{
                fontSize: 30,
                color: theme.palette.text.secondary,
                cursor: 'pointer',
              }}
            />
          </Badge>
        </IconButton>
      </Grid>
    )
  }

  return null
}
