import { useEffect, useState } from 'react'

import { teal } from '@mui/material/colors'
import {
  VisibilityOff as InappropriateContentIcon,
  Clear as CancelIcon,
  Check as CheckIcon,
  Lock as LockIcon,
  OpenInBrowser as OpenInBrowserIcon,
} from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'

import { CompanyAdminLayout, ConfirmationDialog } from 'components'
import { initialDialog } from 'constants/dialog'
import { API } from 'core/requests'
import { ROUTES_PATHS } from 'core/routes'
import { getBoolean } from 'utils/tables/dataTypes'

export default function InappropriateContent() {
  const { enqueueSnackbar } = useSnackbar()

  const intl = useIntl()
  const history = useHistory()

  const [dialogRemoveContent, setDialogRemoveContent] =
    useState<any>(initialDialog)
  const [shouldRefetch, setShouldRefetch] = useState<boolean>(false)

  const allowContentMutation = useMutation(
    (payload: any) => API.update('allowReportedContent', payload),
    {
      onSuccess: (response: any) => {
        if (response.id) {
          enqueueSnackbar(
            intl.formatMessage({ id: 'inappropriateContent.contentAccepted' }),
            {
              variant: 'success',
            },
          )

          setShouldRefetch(true)
        }
      },
    },
  )

  const removeContentMutation = useMutation(
    (payload: any) => API.update('removeReportedContent', payload),
    {
      onSuccess: (response: any) => {
        if (response.id) {
          enqueueSnackbar(
            intl.formatMessage({ id: 'inappropriateContent.contentRemoved' }),
            {
              variant: 'success',
            },
          )

          setShouldRefetch(true)
        }
      },
    },
  )

  const depriveContentMutation = useMutation(
    (payload: any) => API.update('depriveReportedContent', payload),
    {
      onSuccess: (response: any) => {
        if (response.id) {
          enqueueSnackbar(
            intl.formatMessage({ id: 'inappropriateContent.contentPrivate' }),
            {
              variant: 'success',
            },
          )

          setShouldRefetch(true)
        }
      },
    },
  )

  const getPath = id => ({
    Post: `${ROUTES_PATHS.feed}/${id}`,
  })

  return (
    <>
      <CompanyAdminLayout
        slug='inappropriateContent'
        title={intl.formatMessage({ id: 'inappropriateContent.title' })}
        description={intl.formatMessage({
          id: 'inappropriateContent.description',
        })}
        color={teal[600]}
        requestUrl='reportedRecords'
        // filters={[
        //   {
        //     id: 'moderation_result',
        //     label: 'Status',
        //     defaultValue: ['moderation_pending'],
        //     values: [
        //       {
        //         value: 'moderation_pending',
        //         label: 'Pending',
        //       },
        //       {
        //         value: 'allowed_content',
        //         label: 'Allowed',
        //       },
        //       {
        //         value: 'removed_content',
        //         label: 'Removed',
        //       },
        //       {
        //         value: 'private_content',
        //         label: 'Private',
        //       },
        //     ],
        //   },
        // ]}
        tableProps={{
          columns: [
            {
              title: intl.formatMessage({ id: 'type' }),
              field: 'record_type',
              editable: 'never',
            },
            {
              title: intl.formatMessage({
                id: 'inappropriateContent.reporter',
              }),
              field: 'reporter.full_name',
              editable: 'never',
            },
            {
              title: intl.formatMessage({
                id: 'inappropriateContent.contentCreator',
              }),
              field: 'content_creator.full_name',
              editable: 'never',
            },
            {
              title: intl.formatMessage({
                id: 'inappropriateContent.hasInappropriateText',
              }),
              field: 'has_inappropriate_text',
              editable: 'never',
              render: (rowData: any) =>
                getBoolean(rowData.has_inappropriate_text),
            },
            {
              title: intl.formatMessage({
                id: 'inappropriateContent.hasInappropriateImage',
              }),
              field: 'has_inappropriate_image',
              editable: 'never',
              render: (rowData: any) =>
                getBoolean(rowData.has_inappropriate_image),
            },
            {
              title: intl.formatMessage({
                id: 'inappropriateContent.hasInappropriateVideo',
              }),
              field: 'has_inappropriate_video',
              editable: 'never',
              render: (rowData: any) =>
                getBoolean(rowData.has_inappropriate_video),
            },
            {
              title: intl.formatMessage({
                id: 'inappropriateContent.createdAt',
              }),
              field: 'created_at',
              type: 'date',
              editable: 'never',
            },
          ],
          actions: [
            {
              icon: () => <OpenInBrowserIcon />,
              disabled: () => false,
              tooltip: () =>
                intl.formatMessage({ id: 'inappropriateContent.goToPage' }),
              onClick: (_, action) =>
                history.push(getPath(action.record_id)[action.record_type]),
            },
            {
              icon: () => <CheckIcon color='primary' />,
              disabled: action =>
                action.moderation_result !== 'moderation_pending',
              tooltip: () =>
                intl.formatMessage({ id: 'inappropriateContent.allowContent' }),
              onClick: (_, action) => allowContentMutation.mutate(action),
            },
            {
              icon: () => <CancelIcon color='error' />,
              disabled: action =>
                action.moderation_result !== 'moderation_pending',
              tooltip: () =>
                intl.formatMessage({
                  id: 'inappropriateContent.removeContent',
                }),
              onClick: (_, action) =>
                setDialogRemoveContent({ isOpen: true, data: action }),
            },
            { 
              icon: () => <LockIcon />,
              disabled: action =>
                action.moderation_result === 'moderation_pending' &&
                action.record_type !== 'Sparck',
              tooltip: () =>
                intl.formatMessage({
                  id: 'inappropriateContent.makeContentPrivate',
                }),
              onClick: (_, action) => depriveContentMutation.mutate(action),
            },
          ],
          editActionProps: {
            hidden: () => true,
          },
          deleteActionProps: {
            hidden: () => true,
          },
        }}
        IconHeader={InappropriateContentIcon}
      >
        {({ fetchData }) => (
          <Actions
            shouldRefetch={shouldRefetch}
            setShouldRefetch={setShouldRefetch}
            fetchData={fetchData}
            setDialogRemoveContent={setDialogRemoveContent}
          />
        )}
      </CompanyAdminLayout>

      <ConfirmationDialog
        dialogOpen={dialogRemoveContent.isOpen}
        setDialogOpen={setDialogRemoveContent}
        message={intl.formatMessage(
          { id: 'components.confirmationDialog.defaultMessage' },
          {
            action: intl.formatMessage({
              id: 'components.confirmationDialog.delete',
            }),
            item: intl.formatMessage({
              id: 'inappropriateContent.thisContent',
            }),
          },
        )}
        item={dialogRemoveContent?.data}
        actionProps={{
          onClick: () =>
            removeContentMutation.mutate(dialogRemoveContent?.data),
          isLoading: removeContentMutation.isLoading,
          label: intl.formatMessage({
            id: 'components.confirmationDialog.delete',
          }),
          color: 'error',
        }}
      />
    </>
  )
}

function Actions({
  fetchData,
  shouldRefetch,
  setShouldRefetch,
  setDialogRemoveContent,
}) {
  useEffect(() => {
    if (shouldRefetch) {
      fetchData()
      setDialogRemoveContent(initialDialog)

      setShouldRefetch(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetch])

  return null
}
