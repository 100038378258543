import { Link } from 'react-router-dom'
import { Button, Grid, Typography, useTheme } from '@mui/material'

import reportIcon from 'assets/images/company/report-icon.png'
import { CardColoredWithLogo } from 'components'
import { ROUTES_PATHS } from 'core/routes'
import { t } from 'i18n'

export default function CompanyBeheard() {
  const theme = useTheme()

  return (
    <CardColoredWithLogo
      backgroundColor1={theme.palette.error.main}
      backgroundColor2={theme.palette.error.light}
      fullHeight
    >
      <Typography color='text.white' variant='h5' paragraph>
        {t('myCompany.companyBeheard.cardTitle')}
      </Typography>

      <Typography color='text.white' variant='body2' paragraph>
        {t('myCompany.companyBeheard.getMoreFromResults')}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Link to={ROUTES_PATHS.marketing}>
            <Button
              variant='outlined'
              size='small'
              sx={{
                '&.MuiButton-outlined': {
                  color: 'white',
                  borderColor: 'white',
                },
              }}
            >
              {t('myCompany.companyBeheard.learnMore')}
            </Button>
          </Link>
        </Grid>

        <Grid item xs={6} style={{ zIndex: 999 }}>
          <img
            src={reportIcon}
            alt='Report icon'
            style={{ width: '100%', zIndex: 999, maxWidth: 160 }}
          />
        </Grid>
      </Grid>
    </CardColoredWithLogo>
  )
}
