export enum RecognitionType {
  Birthday = 'birthday',
  Workiversary = 'workiversary',
  LifeEvent = 'life_event',
  CoreValue = 'core_value',
  PeerToPeer = 'peer_to_peer',
  SpotBonus = 'spot_bonus',
  PeerNominated = 'peer_nominated',
  ManagerNominated = 'manager_nominated',
  EmployeeOfTheMonth = 'employee_of_the_month',
  EmployeeOfTheYear = 'employee_of_the_year',
  Custom = 'custom',
}

export enum RecognitionOffer {
  Reward = 'reward',
  RewardNGC = 'rewardNGC',
  EmployeeChoice = 'employee_choice',
}

export enum RecognitionFrequency {
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  SemiAnnually = 'semi_annually',
  Annually = 'annually',
  Lifetime = 'lifetime',
}

export enum RecognitionMilestone {
  WithoutMilestone = 'without_milestone',
  EveryYearMilestone = 'every_year_milestone',
  FixedMilestone = 'fixed_milestone',
  CustomMilestone = 'custom_milestone',
}

export enum RecognitionGroup {
  Nobody = 'nobody',
  Peers = 'peers',
  Manager = 'manager',
  ExecutiveTeam = 'executive_team',
  CEO = 'ceo',
}

export enum RecognitionGroupSetting {
  NoSetting = 'no_setting',
  PersonalNote = 'personal_note',
  PersonalPhoneCall = 'personal_phone_call',
  PersonalText = 'personal_text',
  InPerson = 'in_person',
  StaffMeeting = 'staff_meeting',
  CompanyEvent = 'company_event',
  GroupEmail = 'group_email',
  CompanyDashboard = 'company_dashboard',
  SocialMediaShoutout = 'social_media_shoutout',
}
