import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { InfoOutlined as InfoIcon } from '@mui/icons-material'
import { t } from 'i18n'
import { useIntl } from 'react-intl'

import { DatePickerWithWorkaround } from 'components'

export default function ManageEmployeeDemographics({
  formik,
  bulkEditMode,
  defaultDemographics,
}) {
  const intl = useIntl()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6' color='text.secondary'>
          {t('employees.demographicInformation')}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextField
          select
          data-cy='job_type'
          id='job_type'
          name='job_type'
          label={t('employees.fields.jobType')}
          placeholder=''
          variant='outlined'
          value={formik.values.job_type}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.job_type && Boolean(formik.errors.job_type)}
          helperText={formik.touched.job_type && formik.errors.job_type}
          fullWidth
        >
          {defaultDemographics?.job_type?.map(job_type => (
            <MenuItem key={job_type.id} value={job_type.id} data-cy='jobOption'>
              {job_type.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      {!bulkEditMode && (
        <>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              data-cy='gender'
              select
              id='gender'
              name='gender'
              label={t('employees.fields.gender')}
              placeholder={intl.formatMessage({
                id: 'employees.placeholders.male',
              })}
              variant='outlined'
              value={formik.values.gender}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.gender && Boolean(formik.errors.gender)}
              helperText={formik.touched.gender && formik.errors.gender}
              fullWidth
            >
              {defaultDemographics?.gender?.map(gender => (
                <MenuItem
                  key={gender.id}
                  value={gender.id}
                  data-cy='genderOption'
                >
                  {gender.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              data-cy='ethnicity'
              select
              id='ethnicity'
              name='ethnicity'
              label={t('employees.fields.ethnicity')}
              placeholder=''
              variant='outlined'
              value={formik.values.ethnicity}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.ethnicity && Boolean(formik.errors.ethnicity)
              }
              helperText={formik.touched.ethnicity && formik.errors.ethnicity}
              fullWidth
            >
              {defaultDemographics?.ethnicity?.map(ethnicity => (
                <MenuItem
                  key={ethnicity.id}
                  value={ethnicity.id}
                  data-cy='ethnicityOption'
                >
                  {ethnicity.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              data-cy='performance_level'
              id='performance_level'
              name='performance_level'
              label={t('employees.fields.performanceLevel')}
              placeholder=''
              variant='outlined'
              value={formik.values.performance_level}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.performance_level &&
                Boolean(formik.errors.performance_level)
              }
              helperText={
                formik.touched.performance_level &&
                formik.errors.performance_level
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Tooltip
                      title={t('employees.fields.performanceLevelTooltip')}
                    >
                      <InfoIcon style={{ color: '#AAA' }} />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <DatePickerWithWorkaround
              renderInput={params => (
                <TextField
                  {...params}
                  data-cy='hired_at'
                  id='hired_at'
                  name='hired_at'
                  label={t('employees.fields.hireDate')}
                  variant='outlined'
                  fullWidth
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.hired_at && Boolean(formik.errors.hired_at)
                  }
                  helperText={formik.touched.hired_at && formik.errors.hired_at}
                />
              )}
              value={formik.values.hired_at}
              onChange={value => formik.setFieldValue('hired_at', value)}
              data-cy='hiredAt'
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <DatePickerWithWorkaround
              renderInput={params => (
                <TextField
                  {...params}
                  data-cy='date_of_birth'
                  id='date_of_birth'
                  name='date_of_birth'
                  label={t('employees.fields.dateOfBirth')}
                  variant='outlined'
                  fullWidth
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.date_of_birth &&
                    Boolean(formik.errors.date_of_birth)
                  }
                  helperText={
                    formik.touched.date_of_birth && formik.errors.date_of_birth
                  }
                />
              )}
              value={formik.values.date_of_birth}
              disableFuture
              onChange={value => formik.setFieldValue('date_of_birth', value)}
              /* maxDate={s
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 15),
                  )
                } */
              data-cy='dateOfBirth'
            />
          </Grid>
        </>
      )}

      <Grid item xs={12} sm={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.high_value_talent}
              onChange={() =>
                formik.setFieldValue(
                  'high_value_talent',
                  !formik.values.high_value_talent,
                )
              }
              data-cy='high_value_talent'
              name='high_value_talent'
              color='primary'
            />
          }
          label={
            <Typography>
              <Grid container alignItems='center' component='span'>
                {t('employees.fields.highValueTalent')}{' '}
                <Tooltip title={t('employees.fields.highValueTalentTooltip')}>
                  <InfoIcon style={{ color: '#AAA', marginLeft: 8 }} />
                </Tooltip>
              </Grid>
            </Typography>
          }
        />
      </Grid>
    </Grid>
  )
}
