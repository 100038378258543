import { useState } from 'react'

import { Group as GroupIcon, Groups as GroupsIcon } from '@mui/icons-material'
import {
  Box,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material'
import { get } from 'lodash'
import { useIntl } from 'react-intl'

import sparckLogoColored from 'assets/images/sparck-logo-colored.png'
import { DialogTeleport, UserAvatar } from 'components'
import { t } from 'i18n'
import { getUserFullName } from 'utils/user'

const AVATAR_BIG_SIZE = 120
const AVATAR_SMALL_SIZE = 72

export default function SparckUserAvatar(props) {
  const { sparckState, fromFeed } = props

  const hasUsersToBeRecognized = sparckState.users_to_recognize.length > 0

  const intl = useIntl()

  const [selectedUsersDialogOpen, setSelectedUsersDialogOpen] = useState(false)

  const avatarSize = fromFeed ? AVATAR_SMALL_SIZE : AVATAR_BIG_SIZE

  const getAvatarName = () => {
    if (sparckState.users_to_recognize.length === 1) {
      return getUserFullName(sparckState.individual_to_recognize)
    }

    if (sparckState.users_to_recognize.length === 2) {
      return sparckState.users_to_recognize
        .map(user => user.first_name)
        .join(' & ')
    }

    if (sparckState.users_to_recognize.length >= 3) {
      return intl.formatMessage(
        { id: 'sparcks.sparck.andMore' },
        {
          users: sparckState.users_to_recognize
            .slice(0, 2)
            .map(user => user.first_name)
            .join(', '),
          selectedCount: sparckState.users_to_recognize.length - 2,
        },
      )
    }
  }

  const getAvatarSrc = () => {
    if (sparckState.users_to_recognize.length === 1) {
      return {
        src: get(sparckState, 'individual_to_recognize.avatar.url'),
      }
    }

    if (sparckState.users_to_recognize.length === 2) {
      return {
        customSrc: (
          <GroupIcon
            sx={{
              width: avatarSize - 16,
              height: avatarSize - 16,
            }}
          />
        ),
      }
    }

    if (sparckState.users_to_recognize.length >= 3) {
      return {
        customSrc: (
          <GroupsIcon
            sx={{
              width: avatarSize - 16,
              height: avatarSize - 16,
            }}
          />
        ),
      }
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid container justifyContent='center'>
            <Grid item xs={12}>
              <Paper style={{ marginTop: 36, marginBottom: 16 }}>
                <Box py={2} pt={8}>
                  <Grid
                    container
                    direction='column'
                    style={{ position: 'relative' }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: fromFeed ? '-80px' : '-128px',
                        margin: '0 auto',
                      }}
                    >
                      {!hasUsersToBeRecognized ? (
                        <Paper
                          elevation={2}
                          sx={{
                            borderRadius: '100%',
                            width: avatarSize,
                            height: avatarSize,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Box
                            component='img'
                            src={sparckLogoColored}
                            alt='Sparck Logo'
                            sx={{
                              width: avatarSize * 0.7,
                              height: avatarSize * 0.7,
                            }}
                            textAlign='center'
                          />
                        </Paper>
                      ) : (
                        <UserAvatar
                          name={getAvatarName()}
                          size={avatarSize}
                          {...{
                            ...getAvatarSrc(),
                          }}
                        />
                      )}
                    </div>
                    <Grid
                      container
                      alignItems='center'
                      justifyContent='center'
                      p={2}
                    >
                      <Typography
                        variant='h4'
                        color='text.primary'
                        align='center'
                        fontWeight='bold'
                      >
                        {hasUsersToBeRecognized
                          ? getAvatarName()
                          : t('sparcks.sparck.selectPeopleToRecognize')}
                      </Typography>
                    </Grid>

                    {sparckState.users_to_recognize.length >= 3 && (
                      <Link onClick={() => setSelectedUsersDialogOpen(true)}>
                        <Typography
                          variant='body1'
                          align='center'
                          fontWeight={600}
                        >
                          {t('sparcks.sparck.viewAll')}
                        </Typography>
                      </Link>
                    )}
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <DialogTeleport
        dialogTitle={t('sparcks.sparck.usersToRecognize')}
        dialogSize='sm'
        dialogOpen={selectedUsersDialogOpen}
        handleDialogClose={() => setSelectedUsersDialogOpen(false)}
      >
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {sparckState.users_to_recognize.map(user => {
            return (
              <ListItem key={user.id} disablePadding sx={{ mb: 2 }}>
                <ListItemAvatar>
                  <UserAvatar
                    name={`Avatar ${user.full_name}`}
                    src={user.avatar?.url}
                    size={40}
                  />
                </ListItemAvatar>
                <ListItemText id={user.id} primary={user.full_name} />
              </ListItem>
            )
          })}
        </List>
      </DialogTeleport>
    </>
  )
}
