import { useState, useEffect, useMemo } from 'react'

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { get, remove } from 'lodash'
import { useIntl } from 'react-intl'
import { useQuery } from 'react-query'
import * as yup from 'yup'

import { FormHandler } from 'components'
import { roleTypes } from 'constants/roles'
import { useCompany, useUser } from 'contexts'
import { API } from 'core/requests'
import { RoleType as RoleTypeEnum } from 'enums/role'
import { t } from 'i18n'
import { RoleType } from 'types'
import {
  beheardOnlyPermissions,
  permissionsByRoleType,
} from 'utils/permissions'
import { userIsCompanyAdmin } from 'utils/user'

const validationSchema = yup.object({
  name: yup.string().required(t('nameIsRequired')),
  type: yup.string().required(t('roles.validations.typeIsRequired')),
  description: yup
    .string()
    .required(t('roles.validations.descriptionIsRequired')),
})

export default function ManageRole({ dialog, closeDialog, fetchRows }: any) {
  const intl = useIntl()

  const { data: permissions } = useQuery('permissions', () =>
    API.get('permissions'),
  )

  const initialValues: RoleType = dialog.data
    ? dialog.data
    : {
        name: '',
        type: RoleTypeEnum.Employee,
        beseen_application: false,
        role_permissions_attributes: [],
      }

  const [selectedPermissions, setSelectedPermissions] = useState(
    get(dialog, 'data.permissions', []),
  )

  return (
    <FormHandler
      title={intl.formatMessage({ id: 'roles.title' })}
      requestUrl='roles'
      customMutationRequest={(payload: any) => {
        return API.createOrUpdate('roles', {
          ...payload,
          role_permissions_attributes: selectedPermissions,
        })
      }}
      detailsRequestCallback={response => {
        setSelectedPermissions([
          ...response.permissions.map(permission => ({
            id: permission.id,
            permission_id: get(permission, 'permission.id'),
          })),
        ])
      }}
      detailsRequestId={get(dialog, 'data.id')}
      data={dialog.data}
      closeDialog={closeDialog}
      fetchRows={fetchRows}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ formik }) =>
        (permissions ?? []).length > 0 && (
          <ManageRoleForm
            formik={formik}
            dialog={dialog}
            permissions={permissions}
            selectedPermissions={selectedPermissions}
            setSelectedPermissions={setSelectedPermissions}
          />
        )
      }
    </FormHandler>
  )
}

function ManageRoleForm({
  formik,
  dialog,
  permissions,
  selectedPermissions,
  setSelectedPermissions,
}) {
  const { company } = useCompany()!
  const { user } = useUser()!

  const companyHasBeseenAccess = get(company, 'beseen_application')

  const getPermissions = () =>
    permissions
      .filter(permission =>
        companyHasBeseenAccess && formik.values.beseen_application
          ? permission
          : beheardOnlyPermissions.includes(permission.key),
      )
      .filter(permission =>
        permissionsByRoleType[formik.values.type].includes(permission.key),
      )

  const permissionsByCategory = useMemo(() => {
    const permissions = getPermissions()

    const permissionsCategories: {
      id: string
      label: string
      permissionConditions(value: any): boolean
      permissions?: any[]
    }[] = [
      {
        id: 'actions',
        label: 'Actions',
        permissionConditions: (permission: any) =>
          permission.key?.startsWith('create') ||
          permission.key?.startsWith('take'),
      },
      {
        id: 'management',
        label: 'Management',
        permissionConditions: (permission: any) =>
          permission.key?.startsWith('manage'),
      },
      {
        id: 'visualization',
        label: 'Visualization',
        permissionConditions: (permission: any) =>
          permission.key?.startsWith('view'),
      },
      {
        id: 'others',
        label: 'Others',
        permissionConditions: (permission: any) =>
          permission.key?.startsWith('send') ||
          permission.key?.startsWith('share'),
      },
    ]

    const newPermissionsCategories = permissionsCategories.map(
      permissionCategory => ({
        ...permissionCategory,
        permissions: permissions.filter(
          permissionCategory.permissionConditions,
        ),
      }),
    )

    return newPermissionsCategories.filter(
      (permissionCategory: any) => permissionCategory.permissions?.length > 0,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, formik.values.beseen_application, formik.values.type])

  const populatePermissionsFromRoleType = () => {
    const newPermissions: any = []

    getPermissions().forEach(filteredPermission => {
      if (
        !selectedPermissions.find(
          permission => permission.permission_id === filteredPermission.id,
        )
      ) {
        const newPermission = (permissions ?? []).find(
          (permission: any) => permission.key === filteredPermission.key,
        )

        if (newPermission) {
          newPermissions.push({ permission_id: newPermission.id })
        }
      }
    })

    setSelectedPermissions([
      ...selectedPermissions.map(permission => ({
        ...permission,
        _destroy: false,
      })),
      ...newPermissions,
    ])
  }

  const handleSelectAll = event => {
    if (event.target.checked) {
      populatePermissionsFromRoleType()
    } else {
      setSelectedPermissions([
        ...selectedPermissions
          .filter(permission => permission.id)
          .map(permission => ({
            ...permission,
            _destroy: true,
          })),
      ])
    }
  }

  const handleCheckboxChange = permission => event => {
    const selectedPermission = selectedPermissions.find(
      selected => selected.permission_id === permission.id,
    )

    if (event.target.checked && get(selectedPermission, 'id')) {
      selectedPermission._destroy = false
    }

    if (event.target.checked && !get(selectedPermission, 'id')) {
      selectedPermissions.push({ permission_id: permission.id })
    }

    if (!event.target.checked && get(selectedPermission, 'id')) {
      selectedPermission._destroy = true
    }

    if (!event.target.checked && !get(selectedPermission, 'id')) {
      remove(selectedPermissions, { permission_id: permission.id })
    }

    setSelectedPermissions([...selectedPermissions])
  }

  useEffect(() => {
    if (formik.values.type && !get(dialog, 'data.id')) {
      populatePermissionsFromRoleType()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.type])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} data-cy='roleName'>
        <TextField
          id='name'
          name='name'
          label={t('name')}
          variant='outlined'
          value={formik.values.name}
          required
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6} data-cy='roleName'>
        <TextField
          select
          id='type'
          name='type'
          label={t('type')}
          variant='outlined'
          value={formik.values.type}
          required
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.type && Boolean(formik.errors.type)}
          helperText={formik.touched.type && formik.errors.type}
          fullWidth
        >
          {/* {roleTypes.map(roleType => (
            <MenuItem key={roleType.id} value={roleType.id}>
              {roleType.label}
            </MenuItem>
          ))} */}
          {roleTypes.map(roleType => {
            if (roleType.id != RoleTypeEnum.SecondAdmin || company.id == 694) {
              return (
                <MenuItem key={roleType.id} value={roleType.id}>
                  {roleType.label}
                </MenuItem>
              )
            } else {
              return null
            }
          })}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={6} data-cy='roleDescription'>
        <TextField
          id='description'
          name='description'
          label={t('description')}
          variant='outlined'
          value={formik.values.description}
          required
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.description && Boolean(formik.errors.description)
          }
          helperText={formik.touched.description && formik.errors.description}
          fullWidth
        />
      </Grid>

      {companyHasBeseenAccess && (
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            data-cy='beseen_application_label'
            key={'beseen_application'}
            control={
              <Checkbox
                data-cy='enable_beseen_checkbox'
                checked={!!formik.values.beseen_application}
                onChange={formik.handleChange}
                name={'beseen_application'}
              />
            }
            label={t('roles.fields.allowBeseenApplication')}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Grid
          container
          spacing={2}
          justifyContent='flex-start'
          alignItems='center'
          sx={{ mb: 1 }}
        >
          <Grid item xs='auto'>
            <Typography variant='h6' color='text.primary' fontWeight={400}>
              {t('roles.fields.permissions')}
            </Typography>
          </Grid>

          <Grid item xs='auto'>
            <Grid item key={'select-all'} xs={12}>
              <FormControlLabel
                key={'select-all'}
                data-cy='selectAll'
                control={
                  <Checkbox
                    data-cy='checkbox'
                    checked={
                      selectedPermissions.filter(
                        permission => !permission._destroy,
                      ).length >= getPermissions().length
                    }
                    onChange={handleSelectAll}
                    name={'selectAll'}
                  />
                }
                label={t('roles.fields.selectAll')}
              />
            </Grid>
          </Grid>
        </Grid>

        <FormGroup row>
          <Grid container spacing={2}>
            {permissionsByCategory &&
              permissionsByCategory?.map(permissionCategory => (
                <>
                  <Grid item xs={12} key={permissionCategory.id}>
                    <Typography
                      variant='body1'
                      color='text.primary'
                      fontWeight={600}
                    >
                      {permissionCategory.label}
                    </Typography>
                  </Grid>

                  {permissionCategory.permissions.map(permission => (
                    <Grid item key={permission.id} xs={12}>
                      <FormControlLabel
                        key={permission.id}
                        data-cy='permission'
                        control={
                          <Checkbox
                            data-cy='checkbox'
                            data-cy-key={permission.key}
                            checked={
                              !!selectedPermissions.find(
                                selectedPermission =>
                                  selectedPermission.permission_id ===
                                    permission.id &&
                                  !selectedPermission._destroy,
                              )
                            }
                            onChange={handleCheckboxChange(permission)}
                            name={permission.name}
                          />
                        }
                        label={
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography variant='body1' fontWeight={400}>
                                {permission.name}
                              </Typography>
                            </Grid>

                            {userIsCompanyAdmin(user) && (
                              <Grid item xs={12}>
                                <Typography variant='body2' color='text.light'>
                                  {permission.key}
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                        }
                      />
                    </Grid>
                  ))}
                </>
              ))}
          </Grid>
        </FormGroup>
      </Grid>
    </Grid>
  )
}
