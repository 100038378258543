export const TOP_LINE_TEAL_HEIGHT = 12
export const TOPBAR_HEIGHT = 64
export const TOPBAR_GREEN_HEIGHT = 40
export const DRAWER_MARGIN_LEFT = 40
export const DRAWER_OPENED_WIDTH = 260

export const SPARCK_MAX_WIDTH = 1444

export const SPARCK_MAX_FILE_SIZE_MB = 20

export const PROFILE_BACKGROUND_HEIGHT_DESKTOP = 320
export const PROFILE_BACKGROUND_HEIGHT_MOBILE = 160

export const SPARCK_USER_TOKEN = 'sparck_user_token'
export const SPARCK_USER_TOKEN_ID = 'sparck_user_token_id'
export const SPARCK_USER_ID = 'sparck_user_id'
export const SPARCK_SPOOFED_USER_TOKEN = 'sparck_spoofed_user_token'
export const SPARCK_SPOOFED_USER_ID = 'sparck_spoofed_user_id'
export const SPARCK_COMPANY = 'sparck_company'
export const SPARCK_SPOOFED_COMPANY = 'sparck_spoofed_company'

export const SPARCK_REFRESH_USER_TOKEN = 'sparck_refresh_user_token'

export const VIRGIN_EXPERIENCES_CALIFORNIA_REGION_ID = 84

export const SPARCK_UNDER_MAINTENANCE = false
