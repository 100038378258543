import { Card, Box, CardActionArea, CardContent } from '@mui/material'

import sparckLogoTransparent from 'assets/images/sparck-logo-transparent.png'

export default function CardColoredWithLogo({
  children,
  backgroundColor1,
  backgroundColor2,
  logoSize,
  clickable,
  fullHeight,
  dense,
  top,
}: any) {
  return (
    <Card
      sx={{
        position: 'relative',
        height: fullHeight ? '100%' : 'inherit',
        background: `linear-gradient(135deg, ${backgroundColor1}, ${backgroundColor2})`,
      }}
    >
      <Box
        component='img'
        sx={{
          position: 'absolute',
          maxWidth: logoSize ? logoSize : 160,
          [`${top ? 'top' : 'bottom'}`]: 0,
          right: 0,
        }}
        src={sparckLogoTransparent}
        alt='logo'
      />
      {clickable ? (
        <CardActionArea>
          <CardContent
            style={{ padding: dense ? '16px 24px' : '24px', height: '100%' }}
          >
            {children}
          </CardContent>
        </CardActionArea>
      ) : (
        <CardContent
          style={{ padding: dense ? '16px 24px' : '24px', height: '100%' }}
        >
          {children}
        </CardContent>
      )}
    </Card>
  )
}
