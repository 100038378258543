import React, { useState } from 'react'

type PulseSurveysContextType = {
  pulseSurveys: any[]
  setPulseSurveys: (value: any) => void
}
const PulseSurveysContext = React.createContext<
  PulseSurveysContextType | undefined
>(undefined)

type Props = {
  children: React.ReactNode
}

export const PulseSurveysProvider = ({ children }: Props) => {
  const [pulseSurveys, setPulseSurveys] = useState([])

  return (
    <PulseSurveysContext.Provider value={{ pulseSurveys, setPulseSurveys }}>
      {children}
    </PulseSurveysContext.Provider>
  )
}

export const usePulseSurveys = () => React.useContext(PulseSurveysContext)
