import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { TeamInsightsComponent } from './components'
import { API } from 'core/requests'
// import { Period } from 'enums/period'
import { isEmpty } from 'lodash'
import { useCompany, useUser } from 'contexts'
import { UserRole } from 'enums/user'

export default function Insights() {
  const { company } = useCompany()!
  const { user } = useUser()!

  const [filters, setFilters] = useState<any>({
    period: 'ytd',
    survey: null,
    pulse_survey: null,
    roll_up: false,
    manager: user.roles.includes(UserRole.Manager) ? { id: user?.sparck_administrator === true ? null : user.id } : null,
    activity_of: null,
  });

  const [teaminsights, setTeaminsights] = useState<any>(null);
  const [teamInsightsLoading, setTeamInsightsLoading] = useState<boolean>(false);
  const fetchTeamInsights = async () => {
    !teaminsights && setTeamInsightsLoading(true);
    const data = await API.get(filters?.roll_up ? 'rollUpTeamInsights' : 'teamInsights', null, {
      queryParams: {
        period: filters.period,
        survey_id: filters.survey?.id,
        pulse_survey_id: filters.pulse_survey?.id,
        manager_id: filters.manager?.id,
        activity_of: filters.activity_of,
        employees_ids: filters.employees_ids,
      },
    });
    if (data?.errors) {
      await setTeamInsightsLoading(false);
    }
    if (data) {
       setTeaminsights(data);
    }
  }

  const {
    data: departmentinsights,
    refetch: fetchDepartmentInsights,
  } = useQuery(
    'getdepartmentInsights',
    () =>
      API.get('departmentInsights', null, {
        queryParams: {
          period: filters.period,
          survey_id: filters.survey?.id,
          pulse_survey_id: filters.pulse_survey?.id,
          manager_id: filters.manager?.id,
          activity_of: filters.activity_of,
          employees_ids: filters.employees_ids,
        },
      }),
    {
      enabled: false,
    },
  )

  const {
    data: organisationinsights,
    isLoading: insightsIsLoading,
    refetch: fetchOrganizationalInsights,
  } = useQuery(
    'getorganizationalInsights',
    () =>
      API.get('organizationalInsights', null, {
        queryParams: {
          period: filters.period,
          survey_id: filters.survey?.id,
          pulse_survey_id: filters.pulse_survey?.id,
          manager_id: filters.manager?.id,
          activity_of: filters.activity_of,
          employees_ids: filters.employees_ids,
        },
      }),
    {
      enabled: false,
    },
  )

  const { data: surveys, refetch: fetchSurveys } = useQuery(
    'getSurveys',
    () => API.get('surveys'),
    { enabled: false },
  )

  const { data: pulseSurveys, refetch: fetchPulseSurveys } = useQuery(
    'getPulseSurveys',
    () => API.get('pulseSurveys'),
    {
      enabled: false,
    },
  )

  useEffect(() => {
    fetchSurveys()
    fetchPulseSurveys()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (company && filters.manager?.id) {
      fetchDepartmentInsights()
      fetchOrganizationalInsights()
      fetchTeamInsights()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  useEffect(() => {
    if (company && filters.manager?.id) setTeamInsightsLoading(true);
  }, [filters.period, filters.survey, filters.roll_up, filters.pulse_survey,  filters.manager, company])

  useEffect(() => {
    if (company && user.roles.includes(UserRole.CompanyAdmin)) {
      setFilters({
        ...filters,
        manager: company?.managers?.find(manager => manager.id === user.id),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company])

  useEffect(() => {
    if (teaminsights && teaminsights?.data) {
      setTeamInsightsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teaminsights])

  return (
    <TeamInsightsComponent
      insights={teaminsights?.data}
      companyInsightsData={organisationinsights?.data}
      departmentInsightsData={departmentinsights?.data}
      insightsMeta={teaminsights?.meta}
      insightsIsLoading={insightsIsLoading || isEmpty(company)}
      teamInsightsLoading={teamInsightsLoading}
      filters={filters}
      setFilters={setFilters}
      insightsAll={teaminsights}
      surveys={surveys ?? []}
      pulseSurveys={pulseSurveys ?? []}
      company={company}
    />
  )
}
