import { get } from 'lodash'
import { Box, Grid, Typography } from '@mui/material'

import { ProgressBar } from 'components'
import { t } from 'i18n'
import { TeamInsightsProps } from '../TeamInsightsComponent/TeamInsightsComponent'
import { Bar } from 'react-chartjs-2'

export default function TeamSparckProgressBar(props: TeamInsightsProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { insights, insightsMeta, filters } = props

  // const sent = get(insights, 'recognitions.all', 0)
  // const goalAll = get(insights, 'recognitions.goal', 0)
  // const frequency = get(insightsMeta, 'recognition_frequency.manager_level', 0)
  // const frequency = get(insightsMeta, 'recognition_frequency.company_level', 0)
  // const teamMembers = get(insightsMeta, 'my_team_members', [])
  // const period = get(filters, 'period', 0)
  const recognitionsGoalTotal = get(insights, 'manager_goal_overview.team_recognitions_goal', 0)
  const recognitionsGoalSentToTeam = get(insights, 'manager_goal_overview.team_recognitions_goal_sent', 0)
  const totalRecognitionsSent = get(insights, 'manager_goal_overview.total_recognitions_sent', 0)
  const recognitionSentToOthers = totalRecognitionsSent - recognitionsGoalSentToTeam
  
  // Define the periods in weeks
  // const periods = {
  //   all_time: "",
  //   ytd: Math.round(365 / frequency),
  //   semiAnnual: Math.round(182 / frequency),
  //   qtd: Math.round(91 / frequency),
  //   mtd: Math.round(30 / frequency),
  // };

  // const canculateGoal = (period) => {
  //   const weeksInPeriod = periods[period];
  //   let goal
  //   if(weeksInPeriod === ""){
  //     goal = goalAll;
  //   } else {
  //     goal = Math.ceil((weeksInPeriod * teamMembers?.length));
  //   }
    
  //   return (isNaN(goal)) ? 0 : goal;
  // }

  // const goal = canculateGoal(period)
  // const percentage = isNaN(Math.round((sent / goal) * 100)) ? 0 : Math.round((sent / goal) * 100)
  const percentage = isNaN(Math.round((recognitionsGoalSentToTeam / recognitionsGoalTotal) * 100)) ? 0 : Math.round((recognitionsGoalSentToTeam / recognitionsGoalTotal) * 100)

  return (
    <Grid container>
      <Grid item xs={3}>
        <Grid container>
          <Grid item xs={12} pb={1}>&nbsp;</Grid>
          <Grid item xs={12}>
            <Box pt={3} pb={2} sx={{fontWeight: "bold", fontSize: 14}}>
              My Team
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{fontWeight: "bold", fontSize: 14}}>
              Others
            </Box>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Box sx={{fontWeight: "bold", fontSize: 14}}>
              Total
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={9}>
        <Grid container>
          <Grid item xs={12}>
            <Box pl={3} pr={3}>
              <ProgressBar
                progress={[
                  {
                    percentage: percentage,
                    color: 'secondary',
                  },
                ]}
                showLabel
                withGradient
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container justifyContent='space-between'>
          {[
            {
              label: t('insights.sparcksProgressLabels.sent'),
              value: recognitionsGoalSentToTeam,
              align: 'left',
            },
            {
              label: t('insights.sparcksProgressLabels.goal'),
              value: recognitionsGoalTotal,
              align: 'right',
            },
          ].map((legend: any) => (
            <Box pl={3} pr={3} key={legend.value}>
              <Grid item xs={12}>
                <Typography
                  variant='body1'
                  color='text.secondary'
                  align={legend.align}
                  fontSize={12}
                >
                  {legend.label}
                </Typography>
                <Typography
                  variant='body2'
                  color='text.primary'
                  fontWeight={600}
                  align={legend.align}
                  paragraph
                >
                  {legend.value}
                </Typography>
              </Grid>
            </Box>
          ))}
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Box pl={3} pr={3} sx={{fontWeight: "bold", fontSize: 14}}>
              {recognitionSentToOthers}
            </Box>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Box pl={3} pr={3} sx={{fontWeight: "bold", fontSize: 14}}>
              {totalRecognitionsSent}
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} mt={8}>
        <Grid container>
          {/* <Grid item xs={12}>
            <Bar 
              data={
                {
                  labels: ['My Team Goal', 'My Team'],
                  datasets: [{
                    // label: 'My First Dataset',
                    data: [recognitionsGoalTotal, recognitionsGoalSentToTeam],
                    backgroundColor: [
                      '#006666',
                      '#f26522',
                    ],
                    borderColor: [
                      '#006666',
                      '#f26522',
                    ],
                    borderWidth: 1,
                    maxBarThickness: 50,
                    categoryPercentage: 0.5,
                    barPercentage: 1,
                  }]
                }
              }
              height={200}
              options={
                {
                  maintainAspectRatio: false,
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: true,
                      text: 'My Team Recognition',
                    },
                    tooltip: {
                      enabled: true,
                    },
                  },
                  scales: {
                    x: {
                      beginAtZero: true
                    }
                  },
                  indexAxis: 'y',
                }
              }
            />
          </Grid> */}

          <Grid item xs={12}>
            <Bar 
              data={
                {
                  labels: ['My Team', 'Others', 'Total'],
                  datasets: [{
                    // label: 'My First Dataset',
                    data: [recognitionsGoalSentToTeam, recognitionSentToOthers, totalRecognitionsSent],
                    backgroundColor: [
                      '#f26522',
                      '#1bb4ac',
                      '#006666'
                    ],
                    borderColor: [
                      '#f26522',
                      '#1bb4ac',
                      '#006666'
                    ],
                    borderWidth: 1,
                    maxBarThickness: 50,
                    categoryPercentage: 0.5,
                    barPercentage: 1,
                    borderRadius: 0
                  }]
                }
              }
              height={200}
              options={
                {
                  maintainAspectRatio: false,
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: true,
                      text: 'My Recognition Activity',
                    },
                    tooltip: {
                      enabled: true,
                    },
                  },
                  scales: {
                    y: {
                      beginAtZero: true,
                      grid: {
                        offset: true
                      }
                    },
                    // x: {
                    //   grid: {
                    //     offset: true
                    //   }
                    // }
                  },
                  indexAxis: 'x',
                }
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
