import { Box, Grid, Paper, Typography } from '@mui/material'

import { Props } from '../BudgetComponent/BudgetComponent'
import { budgetProrateTypes } from 'constants/budget'
import { BudgetDistributionType } from 'enums/budget'
import { t } from 'i18n'
import { formatToUSD } from 'utils/currency'

export default function BudgetDetailsBody(props: Props) {
  const { budgetSettings } = props

  const renderLimitTypeSettings = limitType => {
    return (
      <Paper sx={{ p: 2.5, height: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h6' component='h3' fontWeight={500} paragraph>
              {t('budget.budgetDetailsBody.budgetSettings', {
                limitType: (
                  <Box component='span' sx={{ textTransform: 'capitalize' }}>
                    {limitType}
                  </Box>
                ),
              })}
            </Typography>
          </Grid>

          {budgetSettings[limitType]?.distribution ? (
            <>
              <Grid item xs={12} md={6}>
                <BudgetDetailsLabelValueSection
                  label={t('budget.budgetDetailsBody.labels.distributionType')}
                  value={budgetSettings[limitType]?.distribution}
                />
              </Grid>

              {limitType === 'receiving' && budgetSettings.has_prorate && (
                <Grid item xs={12} md={6}>
                  <BudgetDetailsLabelValueSection
                    label={t('budget.budgetDetailsBody.labels.proRate')}
                    value={
                      budgetSettings.prorate_type
                        ? budgetProrateTypes.find(
                            type => type.value === budgetSettings.prorate_type,
                          )?.label
                        : ''
                    }
                  />
                </Grid>
              )}

              <Grid item xs={12} md={6}>
                <BudgetDetailsLabelValueSection
                  label={t('budget.budgetDetailsBody.labels.rolloverPerPeriod')}
                  value={renderYesOrNo(
                    budgetSettings[limitType]?.rollover_per_period,
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <BudgetDetailsLabelValueSection
                  label={t('budget.budgetDetailsBody.labels.rolloverAnnually')}
                  value={renderYesOrNo(
                    budgetSettings[limitType]?.rollover_annually,
                  )}
                />
              </Grid>

              {budgetSettings[limitType]?.distribution && (
                <Grid item xs={12} mt={1}>
                  {renderByDistributionType(budgetSettings[limitType])}
                </Grid>
              )}
            </>
          ) : (
            <Grid item xs={12}>
              <Typography variant='body1' color='text.secondary'>
                {t('budget.budgetDetailsBody.noSettings')}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        {renderLimitTypeSettings('spending')}
      </Grid>

      <Grid item xs={12} lg={6}>
        {renderLimitTypeSettings('receiving')}
      </Grid>
    </Grid>
  )
}

function BudgetDetailsLabelValueSection({ label, value }) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          variant='body2'
          color='text.secondary'
          fontWeight={500}
          paragraph
        >
          {label}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant='body1'
          color='text.primary'
          fontWeight={500}
          sx={{ textTransform: 'capitalize' }}
          paragraph
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  )
}

const renderByDistributionType = budgetLimit => {
  if (budgetLimit.distribution === BudgetDistributionType.Flat) {
    return (
      <BudgetDetailsLabelValueSection
        label={t('budget.budgetDetailsBody.labels.amountForEachEmployee')}
        value={formatToUSD(budgetLimit.amount)}
      />
    )
  }

  if (budgetLimit.distribution === BudgetDistributionType.Demographics) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant='body1'
            color='text.primary'
            fontWeight={500}
            paragraph
          >
            {t('budget.budgetDetailsBody.breakdownBy')}{' '}
            {budgetLimit.category_name}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {(budgetLimit.amount ?? []).map(demographic => (
            <Grid
              key={demographic.demographic_name}
              container
              spacing={2}
              justifyContent='space-between'
              alignItems='center'
            >
              <Grid item xs>
                <Typography variant='body1' color='text.secondary' paragraph>
                  {demographic.demographic_name}
                </Typography>
              </Grid>

              <Grid item xs='auto'>
                <Typography
                  variant='body1'
                  color='text.primary'
                  fontWeight={600}
                  paragraph
                >
                  {formatToUSD(demographic.amount)}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    )
  }

  if (budgetLimit.distribution === BudgetDistributionType.Individual) {
    return <p>Individual</p>
  }

  return null
}

const renderYesOrNo = (value: boolean | any) => (
  <Box component='span' color={value ? 'primary.main' : 'error.main'}>
    {value ? t('yes') : t('no')}
  </Box>
)
