import React from 'react'

import Appsignal from '@appsignal/javascript'
import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import { SentimentVeryDissatisfiedOutlined as ErrorIcon } from '@mui/icons-material'

import { TOPBAR_HEIGHT, TOPBAR_GREEN_HEIGHT } from 'constants/app'
import { isDevEnvironment } from 'core/environments'
import { ROUTES_PATHS } from 'core/routes'
import { t } from 'i18n'
import palette from 'theme/palette'

interface IProps {
  history: any
  location: any
  user: any
}

interface IState {
  error: any
  errorInfo: any
}

const appSignal = new Appsignal({
  key: 'c9d25a69-f95e-4791-befa-26eb696d46bb',
})

export default class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
    if (!isDevEnvironment) {
      appSignal.sendError(error, span => {
        span.setNamespace('Frontend')
        span.setTags({
          user_id: this.props.user?.sub ?? this.props.user?.id,
          url: window.location.pathname + window.location.search,
        })
      })
    }
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          style={{
            height: `calc(100vh - ${TOPBAR_HEIGHT}px - ${TOPBAR_GREEN_HEIGHT}px)`,
          }}
        >
          <Grid item xs={11} sm={10} md={8} lg={6} xl={5}>
            <Paper>
              <Box p={5}>
                <Grid container justifyContent='center'>
                  <ErrorIcon
                    style={{
                      fontSize: 64,
                      color: palette('light').error.main,
                      marginBottom: 16,
                    }}
                  />

                  <Grid item xs={12}>
                    <Typography
                      align='center'
                      variant='h4'
                      color='text.primary'
                      fontWeight={700}
                      paragraph
                    >
                      {t('components.errorBoundary.somethingHappened')}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      align='center'
                      variant='body1'
                      color='text.secondary'
                      paragraph
                    >
                      {t('components.errorBoundary.unexpectedError', {
                        email: 'support@sparckco.com',
                      })}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={{ mt: 2 }}>
                  <Grid container justifyContent='center'>
                    <Button
                      variant='contained'
                      color='primary'
                      size='large'
                      onClick={() => {
                        if (
                          this.props.location?.pathname === ROUTES_PATHS.feed
                        ) {
                          this.props.history?.push(ROUTES_PATHS.dashboard)
                        } else {
                          this.props.history?.push(ROUTES_PATHS.feed)
                        }

                        window.location.reload()
                      }}
                    >
                      {t('components.errorBoundary.reloadThisPage')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )
    }
    return this.props.children
  }
}
