import { useEffect, useState } from 'react'

import { isEmpty } from 'lodash'
import { Autocomplete, Box, TextField } from '@mui/material'
import { useIntl } from 'react-intl'

import { useCompanies, useCompany, useUser } from 'contexts'
import { SPARCK_SPOOFED_COMPANY } from 'constants/app'
import {
  getSpoofedCompanyFromLocalStorage,
  getUserCompanyFromLocalStorage,
} from 'utils/admin'

export default function AdminCompaniesSelect() {
  const { user } = useUser()!
  const { setCompany } = useCompany()!
  const { companies } = useCompanies()!

  const intl = useIntl()
  const spoofedCompany = getSpoofedCompanyFromLocalStorage()

  const [selectedCompanyState, setSelectedCompanyState] = useState<any>(
    spoofedCompany ?? null,
  )

  const onSelectedCompanyChange = async (
    event: any,
    newSelectedCompany: any,
  ) => {
    if (newSelectedCompany && newSelectedCompany !== null) {
      setSelectedCompanyState(newSelectedCompany)
      await setCompany(newSelectedCompany)
      localStorage.setItem(
        SPARCK_SPOOFED_COMPANY,
        JSON.stringify(newSelectedCompany),
      )
      window.location.reload()
    } else {
      const userCompany = getUserCompanyFromLocalStorage()

      if (userCompany) {
        setSelectedCompanyState(newSelectedCompany)
        await setCompany(getUserCompanyFromLocalStorage()!)
        window.location.reload()
      }
    }
  }

  useEffect(() => {
    if (!selectedCompanyState && !!spoofedCompany) {
      localStorage.removeItem(SPARCK_SPOOFED_COMPANY)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompanyState])

  if (user.sparck_administrator) {
    return (
      <Box mx={2} my={1}>
        <Autocomplete
          id='surveyAutocomplete'
          value={selectedCompanyState}
          onChange={onSelectedCompanyChange}
          loading={isEmpty(companies)}
          loadingText={intl.formatMessage({
            id: 'components.adminCompaniesSelect.textFieldLabel',
          })}
          options={companies || []}
          getOptionLabel={option => `${option.name} • ${option.id}`}
          renderInput={params => (
            <TextField
              data-cy='survey_autocomplete'
              {...params}
              label={intl.formatMessage({
                id: 'components.adminCompaniesSelect.textFieldLabel',
              })}
              variant='outlined'
            />
          )}
        />
      </Box>
    )
  } else {
    return null
  }
}
