/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dispatch, SetStateAction, useEffect, useState, useRef } from 'react'
import { Autocomplete, Badge, Button, Card, CardContent, Checkbox, CircularProgress, Collapse, Divider, FormControlLabel, Grid, MenuItem, Tab, Tabs, TextField, Tooltip, Typography, Dialog, DialogActions } from '@mui/material'
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch'
import { UseMutationResult, useMutation, useQuery } from 'react-query'
import { ManageBudgetGeneralForm } from '..'
import { BudgetBalanceFields, BudgetPeriod, BudgetProrateType } from 'enums/budget'
import { t } from 'i18n'
import { BudgetType } from 'types'
import { Box } from '@mui/system'
import { API } from 'core/requests'
import { Pie } from 'react-chartjs-2'
import { Loyalty as PaymentSharp } from '@mui/icons-material'
import PaymentIcon from '@mui/icons-material/Payment';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CompanyAdminLayout from 'components/companyAdminLayout/CompanyAdminLayout'
import { format, parseISO } from 'date-fns'
import { InfoOutlined as InfoIcon } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { formatToUSD } from 'utils/currency'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from '../StripeCheckoutForm/CheckoutForm'
import { DialogTeleport, MoneyInput, SparckTable, UserAvatar } from 'components'
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from '@mui/icons-material'
import { getPagination } from 'utils/pagination'
import ManageAddBankAccount from '../ManageAddBankAccount/ManageAddBankAccount'
import { budgetDistributionTableFields, budgetRewardsFiltersInitialState, budgetRewardsTableFilters } from 'constants/budget'
import { get } from 'lodash'
import { useToggle } from 'react-use'
import { useCompany } from 'contexts'
import { useUser } from 'contexts'
import { ConfirmationDialog } from 'components'
import { getEnvironmentName } from 'core/environments'
import { useIntl } from 'react-intl'

type Props = {
  title: string
  formik: any
  isEditMode: boolean
  updateBudgetSettingsMutation: UseMutationResult
  spendingBudgetPerEmployee: BudgetType['spending']
  setSpendingBudgetPerEmployee: Dispatch<SetStateAction<any>>
  receivingBudgetPerEmployee: BudgetType['receiving']
  setReceivingBudgetPerEmployee: Dispatch<SetStateAction<any>>
  budgetSettings: any
  demographics: any[]
  demographicsIsLoading: boolean
  toggleIsDisabled: Dispatch<SetStateAction<any>>
  isDisabled: boolean
}

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&::before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&::after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

enum FieldType {
  Integer = 'integer',
}

enum FieldFormat {
  Currency = 'currency',
  Date = 'date',
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface RewardHistoryFilterType {
  name: string,
  label: string,
}

export default function ManageBudgetForm(props: Props) {
  const intl = useIntl()
  const { formik, updateBudgetSettingsMutation, budgetSettings, isDisabled, toggleIsDisabled } = props
  const [stripe, setStripe] = useState<any>(null);
  const [value, setValue] = useState(0);
  const [walletMoney, setWalletMoney] = useState<number>(1.0);
  const [floatValue, setFloatValue] = useState<any>(50);
  const [check, setCheck] = useState(false);
  const [currentTransactionValue, setCurrentTransaction] = useState<any>(null);
  const { enqueueSnackbar } = useSnackbar()
  const [openStripe, setOpenStripe] = useState(false);
  const [openAddBank, setOpenAddBank] = useState(false);
  const [fromInvitees, setFromInvitees] = useState<any>([]);
  const [toInvitees, setToInvitees] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [transactionData, setTransactionData] = useState<any>([]);
  const [distributionAmount, setDistributionAmount] = useState<number>(0.00)
  const [isToggled, toggle] = useToggle(false)
  const [pagination, setPagination] = useState<{
    currentPage: number
    pageSize: number
  }>(getPagination('distributionAmount'))
  const [rewardsHistory, setRewardsHistory] = useState<any>([]);
  const [rewardsFiltersState, setRewardsFiltersState] = useState<any>(budgetRewardsFiltersInitialState);
  const [showFilters, setShowFilters] = useState(true)
  const [filtersCount, setFiltersCount] = useState<number>(0)
  const [enforceManagerSpendingLimit, setEnforceManagerSpendingLimit] = useState(get(budgetSettings, "enforce_manager_sending_limit"))
  const [enforcePerEmployeeLimit, setEnforcePerEmployeeLimit] = useState(get(budgetSettings, "enforce_employee_receiving_limit"))

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { company, companyIsLoading } = useCompany()!
  const { user } = useUser()!
  const managers = get(company, 'managers', [])
  
  const [confirmUpdateStatusDialog, setConfirmUpdateStatusDialog] = useState<any>({
    isOpen: false,
    data: {},
  })
  const [confirmAllocateTransferFundsDialog, setConfirmAllocateTransferFundsDialog] = useState<any>({
    isOpen: false,
    data: {},
  })
  const [managerPagination, setManagerPagination] = useState(getPagination('managers'))
  const [editManagerSpendingLimitDialog, setEditManagerSpendingLimitDialog] = useState<any>({
    isOpen: false,
    data: {},
  })

  const tableRef = useRef();

  const environment = getEnvironmentName()
  const stripePublishableKey = (environment === "production") ? 'pk_live_hBPodxsQaQwlptYfBFDj3O2Q' : 'pk_test_Z7ExyDBLRFkva7tt9SJNaxzn'

  const handleStripeOpen = () => {
    setOpenStripe(true);
  };

  const handleClose = () => {
    setOpenStripe(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleEnforceManagerSpendingLimit = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnforceManagerSpendingLimit(event.target.checked)
    updateBudgetSettingsMutation.mutate({
      ...formik.values,
      isEnforceManagerSpendingLimit: true,
      enforce_manager_sending_limit: event.target.checked,
      enforce_employee_receiving_limit: enforcePerEmployeeLimit
    })
  }

  const handleEnforcePerEmployeeLimit = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnforcePerEmployeeLimit(event.target.checked)
    updateBudgetSettingsMutation.mutate({
      ...formik.values,
      enforce_manager_sending_limit: enforceManagerSpendingLimit,
      enforce_employee_receiving_limit: event.target.checked
    })
  }

  const { refetch: getCurrentTransaction } = useQuery('getcurrenttrasaction', () => API.create('getcurrenttrasaction'), {
    onSuccess(response) {
      setCurrentTransaction(response?.data);
      toggle();
      if (response?.data?.float_data && response?.data?.float_data > 0) {
        response?.data?.float_data >= 50 && setFloatValue(response?.data?.float_data);
        setCheck(true);
      }
    },
  })

  const { mutate: AddFloatValue } = useMutation((flot: any) => API.create('addFloatValue', {
    amount: parseFloat(flot),
    currency: 'usd',
    description: "Charge for points",
  }),
    {
      onSuccess: response => {
        !response?.errors && enqueueSnackbar(
          'Float limit set up successfully',
          { variant: 'success' },
        )
      },
    }
  )

  const { data: totalEmployeeCount } = useQuery('totalEmployeeCount', () => API.get('getEmployeeCount'));

  const {
    data: transactionsData,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLoading: transactionsDataIsLoading,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    refetch: fetchTransactionsData,
  } = useQuery('transactionsData', () => API.get('get_distributions_amount_transactions', null, {}))

  const {
    data: rewardsHistoryData,
    isLoading: rewardsHistoryLoading,
  } = useQuery('rewardsHistory', () => API.get('rewardsHistory', null, {}))

  const distribution_amount = useMutation((payload: any) => API.create('budget_distribution', payload), {
      onSuccess: (response: any) => {
        if (!response.errors) {
          enqueueSnackbar("Success",
            {
              variant: 'success',
            },
          ),
          getCurrentTransaction();
          setFromInvitees([]);
          setToInvitees([]);
          setDistributionAmount(0.00);
          fetchTransactionsData();
          setTransactionData(transactionsData);
        }
      },
    },
  )

  const updateManagerBudgetLimitMutation = useMutation(
    (payload: any) => API.update('updateManagerBudgetLimit', payload),
    {
      onSuccess: (response: any) => {
        if (response) {
          fetchTransactionsData()
          setDistributionAmount(0.00)
          setEditManagerSpendingLimitDialog({
            isOpen: false,
            data: {},
          })
          enqueueSnackbar(
            intl.formatMessage({
              id: 'budget.updateBudgetSettingsSnackbarSuccess',
            }),
            {
              variant: 'success',
            },
          )
        }
      },
    },
  )

  const updatePamentStatusMutation = useMutation(
    (payload: any) => API.update('updateBudgetPamentStatus', payload),
    {
      onSettled: (response: any) => {
        if (response) {
          enqueueSnackbar(t('budget.manageBudgetForm.confirmUpdate.successMessage'), {
            variant: 'success',
          })
          setConfirmUpdateStatusDialog({
            isOpen: false,
            data: {},
          })
          toggle();
        }
      },
    },
  )

  const getColumnType = column => {
    if ([FieldFormat.Currency, FieldFormat.Date].includes(column.format)) {
      return column.format
    }
    if (column.type === FieldType.Integer) return 'numeric'

    return 'string'
  }

  const totalEmployee = totalEmployeeCount?.users_count ? totalEmployeeCount?.users_count : 0;

  const getBudgetBalance = (field: string) => {

    switch (field) {
      case BudgetBalanceFields.RewardsBudget:
        return currentTransactionValue?.total_balance ? formatToUSD(currentTransactionValue?.total_balance) : formatToUSD(0)

      case BudgetBalanceFields.TotalBudgetPerEmployee:
        if (!totalEmployee || !get(budgetSettings, "receiving.amount")) return formatToUSD(0)
        return formatToUSD(totalEmployee * parseFloat(get(budgetSettings, "receiving.amount")))

      case BudgetBalanceFields.AllocatedFunds:
        return currentTransactionValue?.allocated_funds ? formatToUSD(currentTransactionValue?.allocated_funds) : formatToUSD(0)

      case BudgetBalanceFields.Sent:
        if (!currentTransactionValue?.redeem_balance && !currentTransactionValue?.banked_balance) return formatToUSD(0);
        if (!currentTransactionValue?.redeem_balance && currentTransactionValue?.banked_balance) return formatToUSD(parseFloat(currentTransactionValue?.banked_balance));
        if (currentTransactionValue?.redeem_balance && !currentTransactionValue?.banked_balance) return formatToUSD(parseFloat(currentTransactionValue?.redeem_balance));
        return formatToUSD(parseFloat(currentTransactionValue?.redeem_balance) + parseFloat(currentTransactionValue?.banked_balance));

      case BudgetBalanceFields.Redeemed:
        return currentTransactionValue?.redeem_balance ? formatToUSD(currentTransactionValue?.redeem_balance) : formatToUSD(0)

      case BudgetBalanceFields.Banked:
        return currentTransactionValue?.banked_balance ? formatToUSD(currentTransactionValue?.banked_balance) : formatToUSD(0)

      case BudgetBalanceFields.RewardBalance:
        if (!currentTransactionValue?.total_balance) return formatToUSD(0);
        if (!currentTransactionValue?.redeem_balance && !currentTransactionValue?.banked_balance) return formatToUSD(parseFloat(currentTransactionValue?.total_balance));
        if (!currentTransactionValue?.redeem_balance && currentTransactionValue?.banked_balance) return formatToUSD(parseFloat(currentTransactionValue?.total_balance) - parseFloat(currentTransactionValue?.banked_balance));
        if (currentTransactionValue?.redeem_balance && !currentTransactionValue?.banked_balance) return formatToUSD(parseFloat(currentTransactionValue?.total_balance) - parseFloat(currentTransactionValue?.redeem_balance));
        return formatToUSD(parseFloat(currentTransactionValue?.total_balance) - (parseFloat(currentTransactionValue?.redeem_balance) + parseFloat(currentTransactionValue?.banked_balance)));

      default: return formatToUSD(0)
    }
  }
  
  const getProfarmaPeriod = (period: any = 'monthly', max_amount: any = '0') => {
    const max_employee_balance = parseFloat(max_amount);
    const total_balance = currentTransactionValue?.remaining_balance ? parseFloat(currentTransactionValue?.remaining_balance) : 0;
    const profarma = max_employee_balance * totalEmployee;

    switch (period) {
      case BudgetPeriod.Monthly: return {
        lable: t('budget.budgetPeriods.monthly'),
        period: formatToUSD(profarma),
        yearly: formatToUSD(profarma * 12),
        remaning_amount: formatToUSD(total_balance - (profarma * 12))
      };
      case BudgetPeriod.Quarterly:
        return {
          lable: t('budget.budgetPeriods.quarterly'),
          period: formatToUSD(profarma),
          yearly: formatToUSD(profarma * 4),
          remaning_amount: formatToUSD(total_balance - (profarma * 4))
        };
      case BudgetPeriod.SemiAnnually: return {
        lable: t('budget.budgetPeriods.semiAnnually'),
        period: formatToUSD(profarma),
        yearly: formatToUSD(profarma * 2),
        remaning_amount: formatToUSD(total_balance - (profarma * 2))
      };
      case BudgetPeriod.Annually: return {
        lable: t('budget.budgetPeriods.annually'),
        period: formatToUSD(profarma),
        yearly: formatToUSD(profarma),
        remaning_amount: formatToUSD(total_balance - profarma)
      };
      default: return {
        lable: t('budget.budgetPeriods.monthly'),
        period: formatToUSD(profarma),
        yearly: formatToUSD(profarma * 12),
        remaning_amount: formatToUSD(total_balance - (profarma * 12))
      };
    }
  }

  const getFiltersCount = () => {
    let count = 0;
    for (const key in rewardsFiltersState) {
      const element = rewardsFiltersState[key];
      element && count++
    }
    setFiltersCount(count)
  }

  const resetFilters = () => {
    setRewardsFiltersState(budgetRewardsFiltersInitialState)
    setRewardsHistory(rewardsHistoryData)
  }

  const applyFilters = () => {
    let filterRewards = [...rewardsHistoryData];

    if (rewardsFiltersState.full_name) {
      filterRewards = (filterRewards ?? []).filter((ele) => {
        return (rewardsFiltersState.full_name && rewardsFiltersState.full_name.includes(ele.full_name))
      })
    }
    if (rewardsFiltersState.event_name) {
      filterRewards = (filterRewards ?? []).filter((ele) => {
        return (rewardsFiltersState.event_name && rewardsFiltersState.event_name.includes(ele.event_name))
      })
    }
    if (rewardsFiltersState.department_demographic) {
      filterRewards = (filterRewards ?? []).filter((ele) => {
        return (rewardsFiltersState.department_demographic && rewardsFiltersState.department_demographic.includes(ele.department_demographic))
      })
    }
    if (rewardsFiltersState.location_demographic) {
      filterRewards = (filterRewards ?? []).filter((ele) => {
        return (rewardsFiltersState.location_demographic && rewardsFiltersState.location_demographic.includes(ele.location_demographic))
      })
    }
    if (rewardsFiltersState.user_rewarding_full_name) {
      filterRewards = (filterRewards ?? []).filter((ele) => {
        return (rewardsFiltersState.user_rewarding_full_name && rewardsFiltersState.user_rewarding_full_name.includes(ele.user_rewarding_full_name))
      })
    }

    setRewardsHistory(filterRewards)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getCurrentTransaction() }, []);

  useEffect(() => {
    const initStripe = async () => {
      const stripePromise = await loadStripe(stripePublishableKey)
      setStripe(stripePromise);
    };

    initStripe();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => setRewardsHistory(rewardsHistoryData), [rewardsHistoryData]);

  useEffect(() => setTransactionData(transactionsData), [transactionsData]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getFiltersCount(), [rewardsFiltersState]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Grid container mt={3}>
          <Grid item xs={6}>
            <Typography variant='h5' component='h5' fontWeight={700} >
              Total Deposits:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h5' color={'#000000'} component='h5' fontWeight={700} textAlign={"end"} >{getBudgetBalance(BudgetBalanceFields.RewardsBudget)}</Typography>
          </Grid>

          {/* <Grid item xs={6}>
            <Typography variant='h6' component='h6' fontWeight={500} textAlign={"end"} >
              Total Budget (Per Employee):
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h6' color={'#0bad00'} component='h6' fontWeight={600} textAlign={"end"}>{getBudgetBalance(BudgetBalanceFields.TotalBudgetPerEmployee)}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant='h6' component='h6' fontWeight={500} textAlign={"end"} >
              Allocated Funds:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h6' color={'#fdc822'} component='h6' fontWeight={600} textAlign={"end"}>{getBudgetBalance(BudgetBalanceFields.AllocatedFunds)}</Typography>
          </Grid> */}
        </Grid>

        <Grid container mt={3}>
          <Grid item xs={6}>
            <Typography variant='h5' component='h5' fontWeight={700} >
              Rewards Sent:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h5' color={'#000000'} component='h5' fontWeight={700} textAlign={"end"}>{getBudgetBalance(BudgetBalanceFields.Sent)}</Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography variant='h6' component='h6' fontWeight={500} textAlign={"end"} >
              Redeemed:
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant='h6' color={'#006666'} component='h6' fontWeight={600} textAlign={"end"} >{getBudgetBalance(BudgetBalanceFields.Redeemed)} </Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography variant='h6' component='h6' fontWeight={500} textAlign={"end"} >
              Banked:
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant='h6' color={'#f26522'} component='h6' fontWeight={600} textAlign={"end"} >{getBudgetBalance(BudgetBalanceFields.Banked)}</Typography>
          </Grid>
        </Grid>

        <Grid container mt={3}>
          <Grid item xs={6}>
            <Typography variant='h5' component='h5' fontWeight={700} >
              Available Balance:
              <Tooltip
                title="This is the estimated balance based on the scenario planning and what you have currently deposited into Sparck."
              >
                <InfoIcon style={{ color: '#AAA', marginRight: 3, fontSize: '16px' }} />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h5' color={'#000000'} component='h5' fontWeight={700} textAlign={"end"}>{getBudgetBalance(BudgetBalanceFields.RewardBalance)}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={6} xl={6} textAlign={'center'}>
        <Typography variant='h5' component='h5' fontWeight={600}>
          Rewards Activity
        </Typography>
        {/* TODO : to show number on pie chart https://stackoverflow.com/a/64992945 */}
        <Box>
          <Pie
            data={{
              labels: ['Redeemed', 'Banked', 'Available Balance'],
              datasets: [
                {
                  label: 'Balance Fund',
                  data: [currentTransactionValue?.redeem_balance, currentTransactionValue?.banked_balance, currentTransactionValue?.remaining_balance],
                  backgroundColor: [
                    '#006666',
                    '#f26522',
                    '#1bb4ac',
                  ],
                  borderColor: [
                    '#006666',
                    '#f26522',
                    '#1bb4ac',
                  ],
                  borderWidth: 1,
                },
              ],
            }}
            height={200}
            options={
              {
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                  legend: {
                    display: true,
                    position: "bottom"
                  },
                },

              } as any
            }
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="Budgeting page report">
              <Tab label="Budget Setup" {...a11yProps(0)} />
              <Tab label="Manage Funds" {...a11yProps(1)} />
              {/*<Tab label="Add funds" {...a11yProps(1)} />*/}
              {/*<Tab label="Float" {...a11yProps(2)} />*/}
              <Tab label="Reporting" {...a11yProps(2)} />
            </Tabs>
          </Box>
          
          {/* First Tab : Budget Setup */}
          <TabPanel value={value} index={0}>
            <Grid container spacing={2}>

              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid container display={'flex'} justifyContent={'space-between'}>
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Typography variant='h5' component='h5' fontWeight={700}>
                          Budget Settings
                        </Typography>

                        <Grid item xs={12} mt={3}>
                          <Android12Switch 
                            checked={enforcePerEmployeeLimit}
                            onChange={handleEnforcePerEmployeeLimit}
                            inputProps={{ 'aria-label': 'controlled' }}
                          /> Enforce Per Employee Limit
                          {/* <Tooltip
                            title="This setting will enforce Manager Budget spending limits for each manager visible on the manager's Team Insights Page. Allocation of funds can be used to transter funds between managers as well as the overall organization's avallable funds."
                          >
                            <InfoIcon style={{ color: '#AAA', marginRight: 3, fontSize: '16px' }} />
                          </Tooltip> */}
                        </Grid>

                        <Grid item xs={12} mt={3}>
                          <Android12Switch 
                            checked={enforceManagerSpendingLimit}
                            onChange={handleEnforceManagerSpendingLimit}
                            inputProps={{ 'aria-label': 'controlled' }}
                          /> Enforce Manager Spending Limit
                          <Tooltip
                            title="This setting will enforce Manager Budget spending limits for each manager visible on the manager's Team Insights Page. Allocation of funds can be used to transter funds between managers as well as the overall organization's avallable funds."
                          >
                            <InfoIcon style={{ color: '#AAA', marginRight: 3, fontSize: '16px' }} />
                          </Tooltip>
                        </Grid>

                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              {/**Budget Settings */}
              <Grid item xs={12}>
                {/* <Collapse in={enforcePerEmployeeLimit}> */}
                  <Card>
                    <CardContent>
                      <Grid container display={'flex'} justifyContent={'space-between'}>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Typography variant='h5' component='h5' fontWeight={700}>
                            {t('budget.manageBudgetGeneralForm.title')}
                          </Typography>
                        </Grid>
                        {
                          (totalEmployee && currentTransactionValue?.remaining_balance) ? <>
                            <Grid item xs={12} sm={12} md={12} lg={5} xl={3}>
                              <Grid mb={'0.5rem'} xs={7} sm={7} md={7} textAlign='right'>
                                <Typography variant='body2' component='span' fontWeight={500}><b>Budget Scenario Planning</b></Typography>
                              </Grid>
                              <Grid container textAlign={'end'}>
                                <Grid item xs={7}>
                                  <Typography>
                                    <Grid container alignItems='center' justifyContent={'end'} component='span'>
                                      Available Balance
                                      <Tooltip
                                        title="This is the estimated balance based on the scenario planning and what you have currently deposited into Sparck."
                                      >
                                        <InfoIcon style={{ color: '#AAA', marginRight: 3, fontSize: '16px' }} />
                                      </Tooltip>
                                    </Grid>
                                  </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                  {/* <Typography color={getProfarmaPeriod(get(formik, "values.period"), get(formik, "values.receiving.amount")).remaning_amount.includes('-') ? "red" : ""}> */}
                                  <Typography color={getBudgetBalance(BudgetBalanceFields.RewardBalance).includes('-') ? "red" : ""}>
                                    {
                                      // (get(formik, "values.period") && get(formik, "values.receiving.amount"))
                                      //   ? (currentTransactionValue?.total_balance > 0) ? getProfarmaPeriod(get(formik, "values.period"), get(formik, "values.receiving.amount")).remaning_amount : getBudgetBalance(BudgetBalanceFields.RewardsBudget)
                                      //   : getProfarmaPeriod().remaning_amount

                                      // (get(formik, "values.period") && get(formik, "values.receiving.amount") && currentTransactionValue?.total_balance > 0)
                                      //   ? getProfarmaPeriod(get(formik, "values.period"), get(formik, "values.receiving.amount")).remaning_amount
                                      //   : getProfarmaPeriod().remaning_amount

                                      getBudgetBalance(BudgetBalanceFields.RewardBalance)
                                    }
                                  </Typography>
                                </Grid>

                                <Grid item xs={7}>
                                  <Typography>
                                    Total Employees
                                  </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography >{totalEmployee}</Typography>
                                </Grid>

                                <Grid item xs={7}>
                                  <Typography>
                                    {
                                      (get(formik, "values.period") && get(formik, "values.receiving.amount"))
                                        ? getProfarmaPeriod(get(formik, "values.period"), get(formik, "values.receiving.amount")).lable
                                        : getProfarmaPeriod().lable
                                    }
                                  </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography >
                                    {
                                      (get(formik, "values.period") && get(formik, "values.receiving.amount"))
                                        ? getProfarmaPeriod(get(formik, "values.period"), get(formik, "values.receiving.amount")).period
                                        : getProfarmaPeriod().period
                                    }
                                  </Typography>
                                </Grid>

                                {
                                  get(formik, "values.period") != BudgetPeriod.Annually && <>
                                    <Grid item xs={7}>
                                      <Typography>
                                        Annually
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                      <Typography >
                                        {
                                          (get(formik, "values.period") && get(formik, "values.receiving.amount"))
                                            ? getProfarmaPeriod(get(formik, "values.period"), get(formik, "values.receiving.amount")).yearly
                                            : getProfarmaPeriod().yearly
                                        }
                                      </Typography>
                                    </Grid>
                                  </>
                                }

                                <Grid item xs={7}>
                                  <Typography>
                                    <Grid container alignItems='center' justifyContent={'end'} component='span'>
                                      Annual Forecast
                                    </Grid>
                                  </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography color={getProfarmaPeriod(get(formik, "values.period"), get(formik, "values.receiving.amount")).remaning_amount.includes('-') ? "red" : ""}>
                                    ({
                                      (get(formik, "values.period") && get(formik, "values.receiving.amount"))
                                        ? getProfarmaPeriod(get(formik, "values.period"), get(formik, "values.receiving.amount")).remaning_amount
                                        : getProfarmaPeriod().yearly
                                    })
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </> : null
                        }

                        <Grid item xs={12} mt={3}>
                          <Grid container justifyContent='flex-end'>
                            <Button
                              data-cy='editBudgetSettingsForm'
                              variant='contained'
                              color='primary'
                              size='large'
                              onClick={() => {
                                toggleIsDisabled(isDisabled)
                              }}
                            >
                              {!isDisabled ? "Un " : ""} {t('budget.editBudgetSettings')}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={12}>
                          <ManageBudgetGeneralForm {...props} />
                        </Grid>
                        <Grid item xs={12} mt={3} ml={3}>
                          <Grid container>
                            <Button
                              data-cy='submitBudgetSettingsForm'
                              variant='contained'
                              color='primary'
                              size='large'
                              onClick={() => {
                                updateBudgetSettingsMutation.mutate({
                                  ...formik.values,
                                  year_start_date: formik.values.year_start_date,
                                  year_end_date: formik.values.year_end_date,
                                  has_prorate:
                                    !!formik.values.prorate_type &&
                                    formik.values.prorate_type !==
                                    BudgetProrateType.NoBudgetInPeriod,
                                })
                              }}
                              disabled={updateBudgetSettingsMutation.isLoading || !get(formik, 'values.receiving.amount') || isDisabled}
                            >
                              {t('budget.updateBudgetSettings')}{' '}
                              {updateBudgetSettingsMutation.isLoading && (
                                <CircularProgress size={16} />
                              )}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                {/* </Collapse> */}
              </Grid>

              {/**Allocation Amount Starts */}
              <Grid item xs={12}>
                <Collapse in={enforceManagerSpendingLimit}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant='h5' component='h5' fontWeight={700}>Managers Spending Limit</Typography>
                        </Grid>

                        {/* <Grid container spacing={2} my={1}>

                          <Grid item xs={4}>
                            <Typography variant='body2' component='div' fontSize={"1rem"} fontWeight={700} mb={1}>From</Typography>
                            <Autocomplete
                              // multiple
                              id="fromInvitees"
                              loading={companyIsLoading}
                              loadingText={t('loading')}
                              options={managers || []}
                              disableCloseOnSelect
                              value={fromInvitees}
                              onChange={(event, value: any) => {
                                setFromInvitees(value)
                              }}
                              // getOptionLabel={(option: any) => `${option?.first_name} ${option?.last_name}`}
                              getOptionLabel={(option: any) => (option?.first_name && option?.last_name) ? `${option?.first_name} ${option?.last_name}` : ""}
                              // eslint-disable-next-line @typescript-eslint/no-unused-vars
                              renderOption={(props, option, { selected }) => (
                                <Box
                                  component='li'
                                  sx={{
                                    '& > img': { mr: 3, flexShrink: 0 },
                                    '&.MuiAutocomplete-option[aria-selected="true"]': {
                                      backgroundColor: 'transparent',
                                      width: '100%',
                                    },
                                  }}
                                  {...props}
                                >
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                                    checkedIcon={<CheckBoxIcon fontSize='small' />}
                                    style={{ marginRight: 8, marginLeft: -16 }}
                                    // checked={(fromInvitees ?? []).map(user => user.id)
                                    //   .includes(option.id)}
                                    checked={(fromInvitees?.id === option?.id)}
                                  />

                                  <UserAvatar
                                    name={option?.first_name}
                                    src={option?.avatar?.url}
                                    size={24}
                                  />

                                  <Box component='span' ml={1}>
                                    {`${option?.first_name} ${option?.last_name}`}
                                  </Box>
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField  {...params}
                                  label={"Managers"} //todo lable name should we dynamic
                                  variant='outlined'
                                  required={fromInvitees?.length === 0}
                                  name='fromInvitees'
                                />
                              )}
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={4}>
                            <Typography variant='body2' component='div' fontSize={"1rem"} fontWeight={700} mb={1}>To</Typography>
                            <Autocomplete
                              // multiple
                              id="toInvitees"
                              loading={companyIsLoading}
                              loadingText={t('loading')}
                              options={managers || []}
                              disableCloseOnSelect
                              value={toInvitees}
                              onChange={(event, value: any) => setToInvitees(value)}
                              // getOptionLabel={(option: any) => `${option?.first_name} ${option?.last_name}`}
                              getOptionLabel={(option: any) => (option?.first_name && option?.last_name) ? `${option?.first_name} ${option?.last_name}` : ""}
                              // eslint-disable-next-line @typescript-eslint/no-unused-vars
                              renderOption={(props, option, { selected }) => (
                                <Box
                                  component='li'
                                  sx={{
                                    '& > img': { mr: 3, flexShrink: 0 },
                                    '&.MuiAutocomplete-option[aria-selected="true"]': {
                                      backgroundColor: 'transparent',
                                      width: '100%',
                                    },
                                  }}
                                  {...props}
                                >
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                                    checkedIcon={<CheckBoxIcon fontSize='small' />}
                                    style={{ marginRight: 8, marginLeft: -16 }}
                                    // checked={(toInvitees ?? []).map(user => user.id)
                                    //   .includes(option.id)}
                                    checked={(toInvitees?.id === option?.id)}
                                  />

                                  <UserAvatar
                                    name={option?.first_name}
                                    src={option?.avatar?.url}
                                    size={24}
                                  />

                                  <Box component='span' ml={1}>
                                    {`${option?.first_name} ${option?.last_name}`}
                                  </Box>
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField  {...params}
                                  label={"Managers"} //todo lable name should we dynamic
                                  variant='outlined'
                                  required={toInvitees?.length === 0}
                                  name='toInvitees'
                                />
                              )}
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={2}>
                            <Typography variant='body2' component='div' fontSize={"1rem"} fontWeight={700} mb={1}>Amount</Typography>
                            <TextField
                              data-cy='amount'
                              id='amount'
                              name='amount'
                              label={'Enter Amount Here'}
                              variant='outlined'
                              value={+distributionAmount}
                              required
                              onChange={(event: any) => setDistributionAmount(event.target.value)}
                              fullWidth
                              margin='normal'
                              InputProps={{
                                inputComponent: MoneyInput,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{marginTop: 0}}
                            />
                          </Grid>
                          
                          <Grid item xs={2}>
                            <Grid container justifyContent='flex-end'  pt={5}>
                              <Button
                                data-cy='submitBudgetSettingsForm'
                                variant='contained'
                                color='primary'
                                size='large'
                                onClick={() => {
                                  // distribution_amount.mutate({
                                  //   user_id: fromInvitees.length ? fromInvitees.map(e => e.id) : [],
                                  //   amount: Number(distributionAmount)
                                  // })
                                  setConfirmAllocateTransferFundsDialog({isOpen: true, data: {
                                    // from_user_id: fromInvitees.length ? fromInvitees.map(e => e.id) : [],
                                    // to_user_id: toInvitees.length ? toInvitees.map(e => e.id) : [],
                                    from_user: fromInvitees.length ? fromInvitees[0] : {},
                                    to_user: toInvitees.length ? toInvitees[0] : {},
                                    amount: Number(distributionAmount)
                                  }})
                                }}
                                // disabled={fromInvitees.length && toInvitees.length && distributionAmount > 0 ? false : true}
                                disabled={fromInvitees && toInvitees && distributionAmount > 0 ? false : true}
                              >
                                {"Transfer"}{' '}
                                {distribution_amount.isLoading && (
                                  <CircularProgress size={16} />
                                )}
                              </Button>
                            </Grid>
                          </Grid>

                        </Grid> */}

                        {/* <Grid item xs={12}>
                          <Typography variant='h5' component='h5' fontWeight={700}>Payments & Transfers</Typography>
                        </Grid> */}

                        <Grid item xs={12}>
                          <SparckTable
                            slug={'managers-spending-imit'}
                            title={''}
                            data={transactionsDataIsLoading ? [] : transactionData}
                            isLoading={transactionsDataIsLoading}
                            columns={[
                              {
                                title: 'Name',
                                field: 'full_name',
                                align: 'left'
                              },
                              {
                                title: 'Initial Limit',
                                field: 'default_spending_limit',
                                align: 'left',
                                render: (row: any) => formatToUSD(row.default_spending_limit),
                              },
                              {
                                title: 'Current Limit',
                                field: 'custom_spending_limit',
                                align: 'left',
                                render: (row: any) => formatToUSD(row.custom_spending_limit),
                              },
                              {
                                title: 'Available Balance',
                                field: 'remaining_spending_limit',
                                align: 'left',
                                render: (row: any) => formatToUSD(row.remaining_spending_limit),
                              },
                            ]}
                            actions={[
                              {
                                icon: () => <EditIcon color='primary' data-cy='editSpendingLimt' />,
                                disabled: () => false,
                                tooltip: () => { return 'Edit Spending Limit'},
                                onClick: (_, rowData: any) => {
                                  setDistributionAmount(rowData?.amount)
                                  setEditManagerSpendingLimitDialog({
                                    isOpen: true,
                                    data: rowData,
                                  })
                                } 
                              },
                            ]}
                            pagination={pagination}
                            setPagination={setPagination}
                            style={{ boxShadow: 'none' }}
                            options={{
                              pageSize: 25,
                              pageSizeOptions: [25, 50, 100],
                              selection: false,
                              columnsButton: true,
                              // exportMenu: [],
                              searchFieldAlignment: 'right',
                              filtering: false,
                              showTitle: false,
                            }}
                          />
                        </Grid>

                      </Grid>
                    </CardContent>
                  </Card>
                </Collapse>
              </Grid>
            </Grid>
          </TabPanel>

          {/* Second Tab : Manage Funds*/}
          <TabPanel value={value} index={1}>
            <Grid container spacing={2}>
              
              {/**Float */}
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid item xs={12}>
                      <Typography variant='h5' component='h5' fontWeight={700}>Add a Float Value for Budget Notification</Typography>
                    </Grid>
                    {/* this area to give possibility to write something else lable instead of manager. */}
                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <FormControlLabel
                        key={'allowManagerAlternativeText'}
                        control={
                          <Checkbox
                            data-cy='allowManagerAlternativeText'
                            name='allowManagerAlternativeText'
                            onChange={(e) => {
                              setCheck(e.target.checked)
                            }}
                            checked={check}
                          />
                        }
                        label={
                          <Typography>
                            <Grid container alignItems='center' component='span'>
                              Float Value {' '}
                              <Tooltip
                                title="Get daily alerts when your remaining reward balance is less than the dollar amount of your choice (Float Amount) so you don't interrupt the rewarding process and stay within budget."
                              >
                                <InfoIcon style={{ color: '#AAA', marginLeft: 4 }} />
                              </Tooltip>
                            </Grid>
                          </Typography>
                        }
                      />
                    </Grid>
                    {
                      check &&
                      <>
                        <Divider />
                        <Box display={'flex'} alignItems={'center'} mt={4}>
                          <Typography variant='body2' component='span' fontWeight={500} marginRight={4}>
                            <TextField
                              data-cy='float'
                              id='float'
                              name='float'
                              label='Add Float Value'
                              variant='outlined'
                              value={+Math.abs(floatValue) ?? 50.00}
                              required
                              onChange={(e: any) => setFloatValue(Math.abs(parseFloat(e.target.value)))}
                              fullWidth
                              margin='normal'
                              InputProps={{
                                inputComponent: MoneyInput,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Typography>
                          <Box>
                            <Button variant="contained" color={'primary'} disabled={floatValue < 50 ? true : false} onClick={() => AddFloatValue(floatValue)}><PaymentIcon style={{ marginRight: 3 }} />Save</Button>
                          </Box>
                        </Box>
                        {floatValue < 50 && <Typography variant='body2' component='span' fontSize={"0.75rem"} color={'red'} >Float value must be greater than 50.</Typography>}
                      </>
                    }
                  </CardContent>
                </Card>
              </Grid>

              {/** Add Funds */}
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant='h5' component='h4' fontWeight={700}>Add Funds To Your Account
                          <Tooltip
                            title="Please allow 3-4 business days for the funds to be added to your personalized rewards store. You will be notified as soon as funds are available."
                          >
                            <InfoIcon style={{ color: '#AAA', marginLeft: 4 }} />
                          </Tooltip>
                        </Typography>
                        <Box display={'flex'} alignItems={'center'} mt={4} mb={1}>
                          <Typography variant='body2' component='span' fontWeight={500} marginRight={4}>
                            <TextField
                              data-cy='amount'
                              id='amount'
                              name='amount'
                              label={'Enter Amount Here'}
                              variant='outlined'
                              value={+walletMoney ?? 1.00}
                              required
                              onChange={(event: any) => setWalletMoney(event.target.value)}
                              fullWidth
                              margin='normal'
                              InputProps={{
                                inputComponent: MoneyInput,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Typography>
                          <Box marginRight={2}>
                            <Button variant="contained" color={'primary'} onClick={handleStripeOpen} disabled={walletMoney ? false : true}><PaymentIcon style={{ marginRight: 3 }} />Credit Card</Button>
                          </Box>
                          <Box>
                            <Button variant="contained" color={'primary'} onClick={() => setOpenAddBank(true)} disabled={walletMoney ? false : true}><AccountBalanceIcon style={{ marginRight: 3 }} />ACH Transfer</Button>
                          </Box>
                        </Box>
                        <Divider />
                      </Grid>

                      <Grid item xs={12}>
                        <ManageAddBankAccount openAddBank={openAddBank} setOpenAddBank={setOpenAddBank} stripePromise={stripe} getCurrentTransaction={getCurrentTransaction} walletMoney={walletMoney} setWalletMoney={setWalletMoney} />
                        <Divider />
                      </Grid>

                      <Grid item xs={12}>
                        {/* <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} my={1}>
                          <Typography variant='body2' component='span' fontWeight={500}><b>Payments</b></Typography>
                        </Box> */}
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={4} >
                          <Grid container>
                            <Grid item xs={12}>
                              <CompanyAdminLayout
                                slug='payments_history'
                                title='Payments'
                                description=''
                                color={'#000'}
                                isManageSurvey={true}
                                requestUrl='paymentsDataApi'
                                showTab={true}
                                tableProps={{
                                  tableRef: tableRef,
                                  columns: [
                                    {
                                      title: "ID",
                                      field: 'token_id',
                                      editable: 'never',
                                    },
                                    {
                                      title: "Item",
                                      field: 'brand',
                                      editable: 'never',
                                    },
                                    {
                                      title: "Number",
                                      field: 'Number',
                                      editable: 'never',
                                    },
                                    {
                                      title: 'Date',
                                      field: 'create_date',
                                      editable: 'never',
                                      render: (rowData: any) => <>{rowData.create_date ? format(parseISO(rowData.create_date), 'MM/dd/yy') : 'N/A'}</>
                                    },
                                    {
                                      title: 'Amount Added',
                                      field: 'amount',
                                      editable: 'never',
                                      tooltip: 'Amount Added',
                                      render: (rowData: any) => <>{formatToUSD(rowData?.amount) ? formatToUSD(rowData?.amount) : 'N/A'}</>
                                    },
                                    {
                                      title: "Status",
                                      field: 'status',
                                      editable: 'never',
                                      render: (rowData: any) => <>{(rowData?.vendor_payment_status === "in_progress" && rowData?.status === "succeeded") ? "Processing" : (rowData?.status === "succeeded") ? "Complete" : rowData?.status}</>
                                    },
                                  ],
                                  editActionProps: {
                                    hidden: () => true,
                                  },
                                  deleteActionProps: {
                                    hidden: () => true,
                                  },
                                  actions: [
                                    ...(user.sparck_administrator
                                      ? [
                                          {
                                            icon: (rowData: any) => (rowData?.vendor_payment_status === "in_progress" && rowData?.status === "succeeded") ? <EditIcon color={'primary'} /> : <DoneIcon color={'primary'} />,
                                            disabled: (rowData: any) => (rowData?.vendor_payment_status === "in_progress" && rowData?.status === "succeeded") ? false : true,
                                            tooltip: (rowData: any) => (rowData?.vendor_payment_status === "in_progress" && rowData?.status === "succeeded") ? "Update Status" : "Complete",
                                            onClick: (_, rowData: any) =>
                                              setConfirmUpdateStatusDialog({
                                                isOpen: true,
                                                data: rowData,
                                              }),
                                          },
                                        ]
                                      : []),
                                  ],
                                }}
                                IconHeader={PaymentSharp}
                                refreshData={isToggled}
                                managementFormProps={{
                                  tableRef: tableRef,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Divider />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

            </Grid>
          </TabPanel>

          {/* Second Tab : Add Funds */}
          {/*<TabPanel value={value} index={1}>
            <Grid container>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant='h5' component='h4' fontWeight={700}>Add Funds To Your Account</Typography>
                        <Box display={'flex'} alignItems={'center'} mt={4} mb={1}>
                          <Typography variant='body2' component='span' fontWeight={500} marginRight={4}>
                            <TextField
                              data-cy='amount'
                              id='amount'
                              name='amount'
                              label={'Enter Amount Here'}
                              variant='outlined'
                              value={+walletMoney ?? 1.00}
                              required
                              onChange={(event: any) => setWalletMoney(event.target.value)}
                              fullWidth
                              margin='normal'
                              InputProps={{
                                inputComponent: MoneyInput,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Typography>
                          <Box marginRight={2}>
                            <Button variant="contained" color={'primary'} onClick={handleStripeOpen} disabled={walletMoney ? false : true}><PaymentIcon style={{ marginRight: 3 }} />Credit Card</Button>
                          </Box>
                          <Box>
                            <Button variant="contained" color={'primary'} onClick={() => setOpenAddBank(true)} disabled={walletMoney ? false : true}>Bank ACH</Button>
                          </Box>
                        </Box>
                        <Divider />
                      </Grid>

                      <Grid item xs={12}>
                        <ManageAddBankAccount openAddBank={openAddBank} setOpenAddBank={setOpenAddBank} stripePromise={stripe} getCurrentTransaction={getCurrentTransaction} walletMoney={walletMoney} setWalletMoney={setWalletMoney} />
                        <Divider />
                      </Grid>

                      <Grid item xs={12}>
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} my={1}>
                          <Typography variant='body2' component='span' fontWeight={500}><b>Payments</b></Typography>
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={4} >
                          <Grid container>
                            <Grid item xs={12}>
                              <CompanyAdminLayout
                                slug='payments_history'
                                title='Payments'
                                description=''
                                color={'#000'}
                                isManageSurvey={true}
                                requestUrl='paymentsDataApi'
                                showTab={true}
                                tableProps={{
                                  columns: [
                                    {
                                      title: "ID",
                                      field: 'token_id',
                                      editable: 'never',
                                    },
                                    {
                                      title: "Brand",
                                      field: 'brand',
                                      editable: 'never',
                                    },
                                    {
                                      title: "Number",
                                      field: 'Number',
                                      editable: 'never',
                                    },
                                    {
                                      title: 'Date',
                                      field: 'create_date',
                                      editable: 'never',
                                      render: (rowData: any) => <>{rowData.create_date ? format(parseISO(rowData.create_date), 'MM/dd/yy') : 'N/A'}</>
                                    },
                                    {
                                      title: 'Amount Added',
                                      field: 'amount',
                                      editable: 'never',
                                      tooltip: 'Amount Added',
                                      render: (rowData: any) => <>{formatToUSD(rowData?.amount) ? formatToUSD(rowData?.amount) : 'N/A'}</>
                                    },
                                    {
                                      title: "Status",
                                      field: 'status',
                                      editable: 'never',
                                    },
                                  ],
                                }}
                                IconHeader={PaymentSharp}
                                refreshData={isToggled}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Divider />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </TabPanel>*/}

          {/* Third Tab : Float */}
          {/*<TabPanel value={value} index={2}>
            <Grid container>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid item xs={12}>
                      <Typography variant='h5' component='h5' fontWeight={700}>Add a Float Value for Budget Notification</Typography>
                    </Grid>
                    {/* this area to give possibility to write something else lable instead of manager. *
                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                    >
                      <FormControlLabel
                        key={'allowManagerAlternativeText'}
                        control={
                          <Checkbox
                            data-cy='allowManagerAlternativeText'
                            name='allowManagerAlternativeText'
                            onChange={(e) => {
                              setCheck(e.target.checked)
                            }}
                            checked={check}
                          />
                        }
                        label={
                          <Typography>
                            <Grid container alignItems='center' component='span'>
                              Float Value {' '}
                              <Tooltip
                                title="Once you enable and set a Float Value, you will receive daily notifications until the available balance is greater than the Float Value setting TO Get daily alerts when your remaining reward balance hits the dollar amount of your choice (Float Amount) so you don't interrupt the rewarding process and stay within budget."
                              >
                                <InfoIcon style={{ color: '#AAA', marginLeft: 4 }} />
                              </Tooltip>
                            </Grid>
                          </Typography>
                        }
                      />
                    </Grid>
                    {
                      check &&
                      <>
                        <Divider />
                        <Box display={'flex'} alignItems={'center'} mt={4}>
                          <Typography variant='body2' component='span' fontWeight={500} marginRight={4}>
                            <TextField
                              data-cy='float'
                              id='float'
                              name='float'
                              label='Add Float Value'
                              variant='outlined'
                              value={+Math.abs(floatValue) ?? 50.00}
                              required
                              onChange={(e: any) => setFloatValue(Math.abs(parseFloat(e.target.value)))}
                              fullWidth
                              margin='normal'
                              InputProps={{
                                inputComponent: MoneyInput,
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Typography>
                          <Box>
                            <Button variant="contained" color={'primary'} disabled={floatValue < 50 ? true : false} onClick={() => AddFloatValue(floatValue)}><PaymentIcon style={{ marginRight: 3 }} />Save</Button>
                          </Box>
                        </Box>
                        {floatValue < 50 && <Typography variant='body2' component='span' fontSize={"0.75rem"} color={'red'} >Float value must be greater than 50.</Typography>}
                      </>
                    }
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </TabPanel>*/}

          {/* Fourth Tab : TRANSACTION */}
          <TabPanel value={value} index={2}>
            <Grid container>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Grid
                              container
                              spacing={1}
                              justifyContent='space-between'
                              alignItems='center'
                            >
                              <Grid item xs>
                                <Typography variant='h5' component='h4' fontWeight={700}>Rewards Report</Typography>
                              </Grid>
                              {rewardsHistory && <Grid item xs='auto'>
                                <Grid container justifyContent='flex-end'>
                                  <Badge badgeContent={filtersCount} color='secondary'>
                                    <Button
                                      variant='outlined'
                                      color='secondary'
                                      onClick={() => setShowFilters(!showFilters)}
                                      size='large'
                                    >
                                      {t('beheardReport.filterButtons.filters')}
                                    </Button>
                                  </Badge>
                                </Grid>
                              </Grid>}
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            {!rewardsHistory ? (
                              <Grid container justifyContent='center'>
                                <Grid item xs='auto' sx={{ my: 5 }}>
                                  <CircularProgress size={48} />
                                </Grid>
                              </Grid>
                            ) : (
                              <>
                                <Collapse in={showFilters}>
                                  <Grid container spacing={2} my={2}>
                                    {
                                      budgetRewardsTableFilters.map((filter: RewardHistoryFilterType) => {
                                        return (
                                          <Grid item xs={12} md key={filter.name}>
                                            <TextField
                                              select
                                              id={filter.name}
                                              name={filter.name}
                                              label={filter.label}
                                              variant='outlined'
                                              value={rewardsFiltersState[`${filter.name}`]}
                                              onChange={(event) => setRewardsFiltersState({ ...rewardsFiltersState, [event.target.name]: event.target.value })}
                                              fullWidth
                                              margin='dense'
                                            >
                                              {([... new Set(rewardsHistoryData?.map(data => data && data[`${filter.name}`]))] ?? []).filter(data => data && data).map((item: any) => (
                                                <MenuItem key={item} value={item}>
                                                  {item}
                                                </MenuItem>
                                              ))}
                                            </TextField>
                                          </Grid>
                                        )
                                      })
                                    }

                                    <Grid container justifyContent='flex-end' spacing={2}>
                                      {rewardsFiltersState && (
                                        <Grid item>
                                          <Box mt={3}>
                                            <Button
                                              variant='outlined'
                                              color='secondary'
                                              onClick={resetFilters}
                                            >
                                              {t('components.demographicsFilters.buttons.resetFilters')}
                                            </Button>
                                          </Box>
                                        </Grid>
                                      )}

                                      {rewardsFiltersState && (
                                        <Grid item>
                                          <Box mt={3}>
                                            <Button variant='contained' color='primary' onClick={applyFilters}>
                                              {t('components.demographicsFilters.buttons.applyFilters')}
                                            </Button>
                                          </Box>
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Collapse>

                                <Grid item xs={12}>
                                  <SparckTable
                                    slug={'rewards_history'}
                                    data={rewardsHistory ? rewardsHistory : []}
                                    isLoading={rewardsHistoryLoading}
                                    columns={[
                                      {
                                        title: 'Recognition Date',
                                        field: 'created_at',
                                        editable: 'never',
                                        render: (rowData: any) => <>{rowData.created_at ? format(parseISO(rowData.created_at), 'MM/dd/yy') : 'N/A'}</>
                                      },
                                      {
                                        title: 'Recipient Employee ID',
                                        field: 'user_rewarded_company_id',
                                        editable: 'never',
                                        hidden: true
                                      },
                                      {
                                        title: 'Recipient Name',
                                        field: 'full_name',
                                        editable: 'never',
                                      },
                                      {
                                        title: 'Recognition Event',
                                        field: 'event_name',
                                        editable: 'never',
                                      },
                                      {
                                        title: 'Reward Type',
                                        field: 'retailer_type',
                                        editable: 'never',
                                        hidden: true
                                      },
                                      {
                                        title: 'Reward Value',
                                        field: 'value',
                                        editable: 'never',
                                        render: (rowData: any) => <>{rowData.value ? formatToUSD(rowData.value) : formatToUSD(0)}</>
                                      },
                                      {
                                        title: 'Department',
                                        field: 'department_demographic',
                                        editable: 'never',
                                      },
                                      {
                                        title: 'Location',
                                        field: 'location_demographic',
                                        editable: 'never',
                                        hidden: true
                                      },
                                      {
                                        title: 'From Employee ID',
                                        field: 'user_rewarding_company_id',
                                        editable: 'never',
                                        hidden: true
                                      },
                                      {
                                        title: 'From Name',
                                        field: 'user_rewarding_full_name',
                                        editable: 'never',
                                      }
                                    ]}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    style={{ boxShadow: 'none' }}
                                  />
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card >
              </Grid>
            </Grid>
          </TabPanel>
        </Box >
      </Grid >

      <DialogTeleport
        dialogOpen={openStripe}
        handleDialogClose={handleClose}
        dialogTitle="Card Details"
        dialogSize={"sm"}
        isFullWidth={true}
      >
        <Elements stripe={stripe}>
          <CheckoutForm handleClose={handleClose} setWalletMoney={setWalletMoney} amount={walletMoney} getCurrentTransaction={getCurrentTransaction} refreshTableData={toggle} />
        </Elements>
      </DialogTeleport>
      
      <ConfirmationDialog
        dialogOpen={confirmUpdateStatusDialog.isOpen}
        setDialogOpen={setConfirmUpdateStatusDialog}
        message={t('budget.manageBudgetForm.confirmationDialog.defaultMessage')}
        item={confirmUpdateStatusDialog?.data?.status}
        actionProps={{
          onClick: () => updatePamentStatusMutation.mutate({"token_id": confirmUpdateStatusDialog?.data?.token_id, "company_id": company.id}),
          isLoading: updatePamentStatusMutation.isLoading,
          label: t('budget.manageBudgetForm.confirmUpdate.actionLabel'),
          color: 'primary',
        }}
      />

      <ConfirmationDialog
        dialogOpen={confirmAllocateTransferFundsDialog.isOpen}
        setDialogOpen={setConfirmAllocateTransferFundsDialog}
        message={t('budget.manageBudgetForm.confirmAllocateTransferFunds.defaultMessage', {
          amount: <Typography variant='body2' component='span' fontSize={"1rem"} fontWeight={700} mb={1}>{formatToUSD(confirmAllocateTransferFundsDialog?.data?.amount)}</Typography>,
          fromUser: <Typography variant='body2' component='span' fontSize={"1rem"} fontWeight={700} mb={1}>{(`${confirmAllocateTransferFundsDialog?.data?.from_user?.first_name} ${confirmAllocateTransferFundsDialog?.data?.from_user?.last_name}`).trim()}</Typography>,
          toUser: <Typography variant='body2' component='span' fontSize={"1rem"} fontWeight={700} mb={1}>{(`${confirmAllocateTransferFundsDialog?.data?.to_user?.first_name} ${confirmAllocateTransferFundsDialog?.data?.to_user?.last_name}`).trim()}</Typography>
        })}
        item={confirmAllocateTransferFundsDialog?.data}
        actionProps={{
          onClick: () => {
            setFromInvitees([])
            setToInvitees([])
            setDistributionAmount(0.00)
            setConfirmAllocateTransferFundsDialog({
              isOpen: false,
              data: {},
            })
          },
          isLoading: distribution_amount.isLoading,
          label: t('budget.manageBudgetForm.confirmAllocateTransferFunds.actionLabel'),
          color: 'primary',
        }}
      />

      <Dialog
        open={editManagerSpendingLimitDialog.isOpen}
        maxWidth='sm'
        fullWidth={true}
        scroll={'paper'}
      > 
        <Grid container spacing={2}>
          <Grid item xs={12} sm={11} data-cy='distributionAmount' sx={{marginLeft: 3}}>
            <Box display={'flex'} alignItems={'center'} mt={4}>
              <TextField
                data-cy='amount'
                id='amount'
                name='amount'
                label={'Enter Amount Here'}
                variant='outlined'
                value={+distributionAmount}
                required
                onChange={(event: any) => setDistributionAmount(event.target.value)}
                fullWidth
                margin='normal'
                InputProps={{
                  inputComponent: MoneyInput,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{marginTop: 0}}
              />
            </Box>
          </Grid>
        </Grid>
        <DialogActions>
          <Button
            onClick={() => {
              setEditManagerSpendingLimitDialog({
                isOpen: false,
                data: {},
              })
            }}
            color={'primary'}
            autoFocus
            disabled={updateManagerBudgetLimitMutation.isLoading}
          >
            CANCEL
          </Button>
          <Button
            onClick={() => {
              const params = {
                company_id: company.id,
                user_id: editManagerSpendingLimitDialog.data.user_id,
                new_amount: distributionAmount
              }
              
              updateManagerBudgetLimitMutation.mutate(params)
            }}
            color={'primary'}
            autoFocus
            disabled={updateManagerBudgetLimitMutation.isLoading}
          > 
            {updateManagerBudgetLimitMutation.isLoading ? (
              <CircularProgress size={24} />
            ) : (
              'SAVE'
            )}
          </Button>
        </DialogActions>
      </Dialog>

    </Grid >
  )
}