import React, { useState } from 'react'

import { useSnackbar } from 'notistack'
import { useMutation } from 'react-query'
import { FetchOptions } from 'react-query/types/core/query'

import { EmployeesUploadComponent } from './components'
import { API } from 'core/requests'
import { t } from 'i18n'
import { EmployeeType, EmployeeUploadError } from 'types'

type Props = {
  dialogBulkUpload: boolean
  setDialogBulkUpload: React.Dispatch<React.SetStateAction<boolean>>
  fetchEmployees: (
    options?: FetchOptions | undefined,
  ) => Promise<EmployeeType[] | undefined>
  employeesUpload: any
  setEmployeesUpload: React.Dispatch<React.SetStateAction<any>>
  slug: string
}

export default function EmployeesUpload(props: Props) {
  const { setDialogBulkUpload, fetchEmployees } = props

  const [loading, setLoading] = useState(false)
  const [uploadErrors, setUploadErrors] = useState<EmployeeUploadError[]>([])
  const [importedEmployees, setImportedEmployees] = useState<number>(0)

  const { enqueueSnackbar } = useSnackbar()

  const uploadSpreadsheet = useMutation(
    (payload: any) => API.createOrUpdate('uploads', payload),
    {
      onSettled: async (response: any) => {
        if (response && response.id) {
          setUploadErrors([])
          importSpreadsheet.mutate()
        }
      },
      onError: () => {
        setLoading(false)
      },
    },
  )

  const importSpreadsheet = useMutation(() => API.create('importEmployees'), {
    onSettled: (response: any) => {
      if (response && !response.errors.length) {
        if (setDialogBulkUpload) setDialogBulkUpload(false)
        if (fetchEmployees) fetchEmployees()

        enqueueSnackbar(t('employeesUpload.employeesImportedSuccessfully'), {
          variant: 'success',
        })
      } else {
        setLoading(false)

        if (response.errors) {
          const fatalError = response
            .errors
            .find(error => error.type === 'fatal')

          if (fatalError) {
            setDialogBulkUpload(false)
            enqueueSnackbar(fatalError.message, { variant: 'error', persist: true })
          } else {
            setUploadErrors(response.errors)
          }
        }
        setImportedEmployees(response.total_users_imported_or_updated)
      }
    },
  })

  return (
    <EmployeesUploadComponent
      uploadSpreadsheet={uploadSpreadsheet}
      loading={loading}
      setLoading={setLoading}
      uploadErrors={uploadErrors}
      importedEmployees={importedEmployees}
    />
  )
}
