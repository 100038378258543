import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { sortBy } from 'lodash'
import { useIntl } from 'react-intl'

import { PulseSurveyOverviewActions } from '..'
import { PulseSurveyOverviewProgress } from '../../components'
import { ConfirmationDialog } from 'components'
import { t } from 'i18n'
import { getSurveyStatusLabel as getPulseSurveyStatusLabel } from 'utils/survey'
import { PulseSurveyInsights } from 'pages'

export default function PulseSurveyOverviewComponent(props: any) {
  const {
    pulseSurveys,
    isPulseSurveysLoading,
    selectedPulseSurvey,
    setSelectedPulseSurvey,
    closePulseSurvey,
    isClosePulseSurveyLoading,
    deletePulseSurvey,
    isDeletePulseSurveyLoading,
    confirmCloseDialogOpen,
    setConfirmCloseDialogOpen,
    confirmDeleteDialogOpen,
    setConfirmDeleteDialogOpen,
    setExtendPulseSurveyDialogOpen,
    setConfirmLaunchDialogOpen,
  } = props

  const intl = useIntl()

  const pulseSurveysWithStatusLabel =
    (pulseSurveys ?? []).map(pulseSurvey => ({
      ...pulseSurvey,
      statusLabel: getPulseSurveyStatusLabel(pulseSurvey.status ?? '', intl),
    })) ?? []

  return (
    <>
      <Grid container>
        <Box mb={5} width={1}>
          <Grid item xs={12}>
            <Box my={3} mb={5} width={1}>
              <Typography color='text.secondary' variant='h4'>
                {t('pulseSurveyOverview.header')}
                {/* <Badge
                  badgeContent='BETA'
                  color='primary'
                  sx={{
                    '& .MuiBadge-badge': {
                      transform: 'scale(1) translate(104%, -100%)',
                      borderRadius: '4px',
                      height: '17px',
                      fontSize: '9px',
                      fontWeight: 600,
                    },
                  }}
                /> */}
              </Typography>
            </Box>
          </Grid>

          <Grid container spacing={3} alignItems='center'>
            <Grid item xs={12} sm={6} md={5}>
              <Autocomplete
                id='pulseSurveyAutocomplete'
                value={selectedPulseSurvey ?? null}
                onChange={(event, newSelectedPulseSurvey: any) => {
                  setSelectedPulseSurvey(newSelectedPulseSurvey)
                }}
                loading={isPulseSurveysLoading}
                loadingText={intl.formatMessage({ id: 'loading' })}
                options={sortBy(pulseSurveysWithStatusLabel, 'statusLabel')}
                groupBy={option => option.statusLabel}
                getOptionLabel={option => option.name}
                disableClearable
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('pulseSurveyOverview.textFieldLabel')}
                    variant='outlined'
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={7}>
              <Grid container justifyContent='flex-end'>
                {selectedPulseSurvey && (
                  <PulseSurveyOverviewProgress
                    selectedPulseSurvey={selectedPulseSurvey}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {isPulseSurveysLoading || !selectedPulseSurvey ? (
          <Grid container justifyContent='center'>
            <Box mt={5}>
              <CircularProgress size={40} />
            </Box>
          </Grid>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8} lg={9}>
                <PulseSurveyInsights pulseSurvey={selectedPulseSurvey} />
              </Grid>

              <Grid item xs={12} md={4} lg={3}>
                <Card>
                  <CardContent>
                    <PulseSurveyOverviewActions
                      {...props}
                      setConfirmCloseDialogOpen={setConfirmCloseDialogOpen}
                      setExtendPulseSurveyDialogOpen={
                        setExtendPulseSurveyDialogOpen
                      }
                      setConfirmLaunchDialogOpen={setConfirmLaunchDialogOpen}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <ConfirmationDialog
              dialogOpen={confirmCloseDialogOpen}
              setDialogOpen={setConfirmCloseDialogOpen}
              message={t('components.confirmationDialog.defaultMessage', {
                action: t(
                  'pulseSurveyOverview.dialogs.confirmClose.deleteItemLabel',
                ),
                item: selectedPulseSurvey?.name,
              })}
              item={selectedPulseSurvey}
              actionProps={{
                onClick: () => closePulseSurvey.mutate(selectedPulseSurvey),
                isLoading: isClosePulseSurveyLoading,
                label: t(
                  'pulseSurveyOverview.dialogs.confirmClose.actionLabel',
                ),
              }}
            />

            <ConfirmationDialog
              dialogOpen={confirmDeleteDialogOpen}
              setDialogOpen={setConfirmDeleteDialogOpen}
              message={
                selectedPulseSurvey?.has_answers
                  ? t(
                      'pulseSurveyOverview.dialogs.confirmDelete.alternateConfirmDeleteLabel',
                    )
                  : t('components.confirmationDialog.defaultMessage', {
                      action: t('components.confirmationDialog.delete'),
                      item: selectedPulseSurvey?.name,
                    })
              }
              item={selectedPulseSurvey}
              actionProps={{
                onClick: () => deletePulseSurvey.mutate(selectedPulseSurvey),
                isLoading: isDeletePulseSurveyLoading,
                label: t(
                  'pulseSurveyOverview.dialogs.confirmDelete.actionLabel',
                ),
                color: 'error',
              }}
            />
          </>
        )}
      </Grid>
    </>
  )
}
