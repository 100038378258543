import { Grid } from '@mui/material'
import { SparckForm, SparckHeader, SparckPreview } from '../../components'

export default function SparckComponent(props) {
  const { fromFeed } = props

  return (
    <Grid container>
      <Grid item xs={12}>
        {!fromFeed && <SparckHeader />}
        <SparckForm {...props} />
        {!fromFeed && <SparckPreview {...props} />}
      </Grid>
    </Grid>
  )
}
