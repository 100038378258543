/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'
import { get } from 'lodash'
import { Doughnut } from 'react-chartjs-2'

import {
  SurveyDriverScore,
  SurveyEnps,
  SurveyAvgEnps,
  SurveyScorePrevious,
  SurveyScorePreviousavgenps,
  SurveyEnpsForReportPage,
} from 'enums/survey'
import { t } from 'i18n'
import {
  getEngagementScoreColor,
  getEngagementScoreEmoji,
  getDriverScoreDescription,
  getEnpsScoreColor,
  getEnpsScoreColorForReportPage,
} from 'utils/survey'
import * as React from 'react'
import {
  SentimentVeryDissatisfied as DisengagedIcon,
  SentimentSatisfied as NeutralIcon,
  InsertEmoticon as EngagedIcon,
} from '@mui/icons-material'
import { Popover } from '@mui/material'

import InfoIcon from '@mui/icons-material/Info'

import { PreviousValueComparison } from 'components'

export default function EngagementScoreComponent({
  surveyDetails,
  isTeamInsight,
  surveyCompanyDetails,
  surveyDepartmentDetails,
  surveyCompanyDetailsTemp,
  surveyDepartmentDetailsTemp,
  surveyTeamDetailsTemp
}: any) {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const getDoughnut = (data = {}, type: string) => {
    if (type == "enps") {
      return Object.entries(get(data, type) ? get(data, type) : {})?.map((score: any) => ({
        key: score[0],
        label: score[0] ? score[0].charAt(0).toUpperCase() + score[0].slice(1) : "",
        value: score[1]
      })).filter((score: any) => score.key != "enps")
    }
    return Object.entries(get(data, type) ? get(data, type) : {})?.map((score: any) => ({
      key: score[0],
      label: score[0] ? score[0].charAt(0).toUpperCase() + score[0].slice(1) : "",
      value: score[1]
    }));
  }
  const getEnpsDoughnutScore = (vlu: any) => vlu == 0 || vlu == '00' ? `` : vlu > 0 ? `+${vlu}` : `${vlu}`;

  const engagementTeamDoughnut = getDoughnut(surveyTeamDetailsTemp, 'engagement');
  const engagementCompanyDoughnut = getDoughnut(surveyCompanyDetailsTemp, 'engagement');
  const engagementDepartmentDoughnut = getDoughnut(surveyDepartmentDetailsTemp, 'engagement');
  const enpsTeamDoughnut = getDoughnut(surveyTeamDetailsTemp, 'enps');
  const enpsCompanyDoughnut = getDoughnut(surveyCompanyDetailsTemp, 'enps');
  const enpsDepartmentDoughnut = getDoughnut(surveyDepartmentDetailsTemp, 'enps');

  // [START] For new engagement score
  const showDepartmentDetails = (surveyDepartmentDetails || surveyDepartmentDetailsTemp) ? true : false;
  const chartEngagementScore =
    get(surveyDetails, 'scores.percents.drivers') &&
    get(surveyDetails, 'scores.percents.drivers').find(
      driver => driver.key === SurveyDriverScore.Engagement,
    )

  const chartEngagementTeamScore =
    get(surveyTeamDetailsTemp, 'drivers.engagement') &&
    get(surveyTeamDetailsTemp, 'drivers.engagement')

  const chartEngagementCompanyScore =
    get(surveyCompanyDetailsTemp, 'drivers.engagement') &&
    get(surveyCompanyDetailsTemp, 'drivers.engagement')

  const chartDepartmentCompanyScore =
    get(surveyDepartmentDetailsTemp, 'drivers.engagement') &&
    get(surveyDepartmentDetailsTemp, 'drivers.engagement')

  const chartEngagementScorePrevious =
    get(surveyDetails, 'previous_scores.drivers') &&
    get(surveyDetails, 'previous_scores.drivers').find(
      drivers => drivers.key === SurveyScorePrevious.Engagement,
    )

  const chartOrganisationScore =
    get(surveyCompanyDetails, 'scores.percents.drivers') &&
    get(surveyCompanyDetails, 'scores.percents.drivers').find(
      driver => driver.key === SurveyDriverScore.Engagement,
    )
  const chartOrganisationScorePrevious =
    get(surveyCompanyDetails, 'previous_scores.avgenps') &&
    get(surveyCompanyDetails, 'previous_scores.avgenps').find(
      avgenps => avgenps.key === SurveyDriverScore.Engagement,
    )

  const chartDepartmentScore =
    get(surveyDepartmentDetails, 'scores.percents.drivers') &&
    get(surveyDepartmentDetails, 'scores.percents.drivers').find(
      driver => driver.key === SurveyDriverScore.Engagement,
    )
  const chartDepartmentScorePrevious =
    get(surveyDepartmentDetails, 'previous_scores.avgenps') &&
    get(surveyDepartmentDetails, 'previous_scores.avgenps').find(
      avgenps => avgenps.key === SurveyDriverScore.Engagement,
    )
  // end
  // enps score start
  const chartEngagementeNPSScorePrevious =
    get(surveyDetails, 'previous_scores.avgenps') &&
    get(surveyDetails, 'previous_scores.avgenps').find(
      avgenps =>
        avgenps.key === SurveyScorePreviousavgenps.EmployeeNetPromotorScore,
    )

  const chartEnpsScore =
    get(surveyTeamDetailsTemp, 'enps') &&
    get(surveyTeamDetailsTemp, 'enps')?.enps

  const chartEnpsScoreforReport =
    get(surveyDetails, 'scores.percents.avgenps') &&
    get(surveyDetails, 'scores.percents.avgenps').find(
      avgenps => avgenps.key === SurveyAvgEnps.EmployeeNetPromotorScore,
    )

  const chartOrganisationEngagementeNPSScorePrevious =
    get(surveyCompanyDetails, 'previous_scores.avgenps') &&
    get(surveyCompanyDetails, 'previous_scores.avgenps').find(
      avgenps =>
        avgenps.key === SurveyScorePreviousavgenps.EmployeeNetPromotorScore,
    )
  const chartOrganisationEnpsScore =
    get(surveyCompanyDetailsTemp, 'enps') &&
    get(surveyCompanyDetailsTemp, 'enps')?.enps

  const chartDepartmentEngagementeNPSScorePrevious =
    get(surveyDepartmentDetails, 'previous_scores.avgenps') &&
    get(surveyDepartmentDetails, 'previous_scores.avgenps').find(
      avgenps =>
        avgenps.key === SurveyScorePreviousavgenps.EmployeeNetPromotorScore,
    )
  const chartDepartmentEnpsScore =
    get(surveyDepartmentDetailsTemp, 'enps') &&
    get(surveyDepartmentDetailsTemp, 'enps')?.enps

  const enpsTeamScores = get(surveyDetails, 'scores.percents.enps')
  const enpsCompanyScores = get(surveyCompanyDetails, 'scores.percents.enps')
  const enpsDepartmentScores = get(
    surveyDepartmentDetails,
    'scores.percents.enps',
  )
  // end

  const MINIMUM_PARTICIPANTS_REQUIRED = 5
  const engagementScores = get(surveyDetails, 'scores.percents.engagement')
  const driverScores = get(surveyDetails, 'scores.percents.drivers')
  const previousDriverScores = get(surveyDetails, 'previous_scores.drivers')

  const survey = surveyDetails?.survey
  // commented below line as we are displaying no change trends
  const surveyHasMinimumParticipantsRequired = survey?.current_participation >= MINIMUM_PARTICIPANTS_REQUIRED
  const dontShowVisual = surveyDetails?.previous_survey_percents ? true : false 
  const showTrends = (survey.report_level == "upgraded") ? surveyHasMinimumParticipantsRequired : surveyHasMinimumParticipantsRequired // Make it false to hide for free reports

  const loggedInCompanyData = localStorage.getItem('sparck_company')
  const companyDetailLocalStorage = JSON.parse(
    JSON.parse(JSON.stringify(loggedInCompanyData)),
  )
  const companyName =
    companyDetailLocalStorage['name'] != ''
      ? companyDetailLocalStorage['name']
      : 'this company'

  const promoter =
    enpsTeamDoughnut &&
    enpsTeamDoughnut.find(
      enps => enps.key === SurveyEnps.Promoter,
    )
  const passive =
    enpsTeamDoughnut &&
    enpsTeamDoughnut.find(
      enps => enps.key === SurveyEnps.Passive,
    )
  const detractor =
    enpsTeamDoughnut &&
    enpsTeamDoughnut.find(
      enps => enps.key === SurveyEnps.Detractor,
    )
  const promoters =
    get(surveyDetails, 'scores.percents.enps') &&
    get(surveyDetails, 'scores.percents.enps').find(
      enps => enps.key === SurveyEnpsForReportPage.Promoter,
    )
  const passives =
    get(surveyDetails, 'scores.percents.enps') &&
    get(surveyDetails, 'scores.percents.enps').find(
      enps => enps.key === SurveyEnpsForReportPage.Passive,
    )
  const detractors =
    get(surveyDetails, 'scores.percents.enps') &&
    get(surveyDetails, 'scores.percents.enps').find(
      enps => enps.key === SurveyEnpsForReportPage.Detractor,
    )

  const renderLegend = engagementScore => {

    const Emoji = getEngagementScoreEmoji(engagementScore.key)
    const color = getEngagementScoreColor(engagementScore.key)

    const engagementColor: any = color
    const EngagementEmoji: any = Emoji

    return (
      <Grid
        container
        justifyContent={isTeamInsight ? '' : 'space-between'}
        alignItems='center'
        key={engagementScore.key}
      >
        <Typography
          color='text.primary'
          variant='body2'
          paragraph
          flex={isTeamInsight ? '0 0 60%' : 'auto'}
        >
          <Grid container alignItems='center' component='span'>
            <EngagementEmoji color={engagementColor} />
            <Box component='span' ml={isTeamInsight ? 1 : 2}>
              {engagementScore.label}
            </Box>
          </Grid>
        </Typography>
        <Typography
          color={isTeamInsight ? 'GrayText' : 'text.primary'}
          fontWeight={isTeamInsight ? 700 : 500}
          variant='body1'
          paragraph
        >
          {engagementScore.value}%
        </Typography>
      </Grid>
    )
  }

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  if (driverScores) {
    return (
      <Grid container id='engagement-score' spacing={2}>
        {survey && (
          <Grid item xs={12} md={6}>
            <Card style={{ height: '100%' }}>
              <CardContent>
                <Typography color='text.primary' variant='h5' fontWeight='bold'>
                  Engagement Score
                </Typography>
                <Grid
                  container
                  id='engagement-score'
                  display={!isTeamInsight ? 'flex' : ''}
                  justifyContent={!isTeamInsight ? 'center' : ''}
                >
                  <Grid
                    item
                    mt={3} xs={12} sm={12} md={12} lg={isTeamInsight ? 6 : 6} xl={isTeamInsight ? 6 : 6}
                    style={isTeamInsight ? { position: 'relative' } : { position: 'relative', marginRight: "auto" }}
                  >
                    <Grid
                      container
                      justifyContent='center'
                      alignItems='center'
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        bottom: 0,
                        margin: '0 auto',
                      }}
                    >
                      <Grid item>
                        {dontShowVisual && showTrends && (
                          <Typography
                            style={
                              isTeamInsight
                                ? {
                                  position: 'relative',
                                  width: '50%',
                                  height: '50%',
                                  top: -24,
                                  right: 35,
                                  margin: '0 auto',
                                }
                                : {
                                  position: 'relative',
                                  width: '50%',
                                  height: '50%',
                                  top: -24,
                                  right: 35,
                                  margin: '0 auto',
                                }
                            }
                          >
                            {chartEngagementScore?.value == 0 || (
                              <PreviousValueComparison
                                currentValue={chartEngagementScore?.value}
                                previousValue={
                                  chartEngagementScorePrevious?.value
                                }
                              />
                            )}
                          </Typography>
                        )}

                        {
                          isTeamInsight ?
                            <Typography
                              color='text.primary'
                              variant='h3'
                              fontWeight='bold'
                              align='center'
                            >
                              {chartEngagementTeamScore
                                ? chartEngagementTeamScore
                                : 'N/A'}
                              {chartEngagementTeamScore && (
                                <Typography
                                  component='span'
                                  variant='body1'
                                  fontWeight={900}
                                >
                                  %
                                </Typography>
                              )}
                            </Typography>
                            : <Typography
                              color='text.primary'
                              variant='h3'
                              fontWeight='bold'
                              align='center'
                            >
                              {chartEngagementScore
                                ? chartEngagementScore.value
                                : 'N/A'}
                              {chartEngagementScore && (
                                <Typography
                                  component='span'
                                  variant='body1'
                                  fontWeight={900}
                                >
                                  %
                                </Typography>
                              )}
                            </Typography>
                        }
                      </Grid>
                    </Grid>

                    <Doughnut
                      data={{
                        labels: isTeamInsight ? engagementTeamDoughnut.map(
                          engagementScore => engagementScore.label,
                        ) : engagementScores.map(
                          engagementScore => engagementScore.label,
                        ),
                        datasets: [
                          {
                            data: isTeamInsight ? engagementTeamDoughnut.map(
                              engagementScore => engagementScore.value,
                            ) : engagementScores.map(
                              engagementScore => engagementScore.value,
                            ),
                            backgroundColor: isTeamInsight ? engagementTeamDoughnut.map(
                              engagementScore =>
                                theme.palette[
                                  getEngagementScoreColor(engagementScore.key)
                                ]?.light,
                            ) : engagementScores.map(
                              engagementScore =>
                                theme.palette[
                                  getEngagementScoreColor(engagementScore.key)
                                ]?.light,
                            ),
                            weight: 0.5,
                          },
                        ],
                      }}
                      height={200}
                      options={
                        {
                          maintainAspectRatio: false,
                          responsive: true,
                          cutout: '75%',
                          plugins: {
                            legend: { display: false },
                          },
                        } as any
                      }
                    />

                  </Grid>

                  {isTeamInsight && (
                    <Grid item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={showDepartmentDetails ? 3 : 6}
                      xl={showDepartmentDetails ? 3 : 6}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            color='GrayText'
                            variant='body2'
                            fontWeight={700}
                            align='center'
                          >
                            Organizational
                            <br />
                            Score
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ position: 'relative', mb: 2 }}>
                          <Grid
                            container
                            justifyContent='center'
                            alignItems='center'
                            style={{
                              position: 'absolute',
                              width: '100%',
                              height: '100%',
                              top: 0,
                              bottom: 0,
                              margin: '0 auto',
                            }}
                          >
                            <Grid item>
                              {dontShowVisual && (
                                <Typography
                                  style={{
                                    position: 'relative',
                                    width: '50%',
                                    height: '50%',
                                    top: -24,
                                    right: 35,
                                    margin: '0 auto',
                                  }}
                                >
                                  {chartOrganisationScore.value == 0 || (
                                    <PreviousValueComparison
                                      currentValue={
                                        chartOrganisationScore?.value
                                      }
                                      previousValue={
                                        chartOrganisationScorePrevious?.value
                                      }
                                    />
                                  )}
                                </Typography>
                              )}
                              {
                                isTeamInsight ?
                                  <Typography
                                    color='text.primary'
                                    variant='h5'
                                    fontWeight='bold'
                                    align='center'
                                  >
                                    {chartEngagementCompanyScore
                                      ? chartEngagementCompanyScore
                                      : 'N/A'}
                                    {chartEngagementCompanyScore && (
                                      <Typography
                                        component='span'
                                        variant='body1'
                                        fontWeight={900}
                                      >
                                        %
                                      </Typography>
                                    )}
                                  </Typography>
                                  :
                                  <Typography
                                    color='text.primary'
                                    variant='h5'
                                    fontWeight='bold'
                                    align='center'
                                  >
                                    {chartOrganisationScore
                                      ? chartOrganisationScore.value
                                      : 'N/A'}
                                    {chartOrganisationScore && (
                                      <Typography
                                        component='span'
                                        variant='body1'
                                        fontWeight={900}
                                      >
                                        %
                                      </Typography>
                                    )}
                                  </Typography>
                              }
                            </Grid>
                          </Grid>

                          <Doughnut
                            data={{
                              labels: engagementCompanyDoughnut.map(
                                engagementScore => engagementScore.label,
                              ),
                              datasets: [
                                {
                                  data: engagementCompanyDoughnut.map(
                                    engagementScore => engagementScore.value,
                                  ),
                                  backgroundColor: engagementCompanyDoughnut.map(
                                    engagementScore =>
                                      theme.palette[
                                        getEngagementScoreColor(
                                          engagementScore.key,
                                        )
                                      ]?.light,
                                  ),
                                  weight: 0.5,
                                },
                              ],
                            }}
                            height={200}
                            options={
                              {
                                maintainAspectRatio: false,
                                responsive: true,
                                cutout: '75%',
                                plugins: {
                                  legend: { display: false },
                                },
                              } as any
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  {(isTeamInsight && showDepartmentDetails) && (
                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            color='GrayText'
                            variant='body2'
                            fontWeight={700}
                            align='center'
                          >
                            Department
                            <br />
                            Score
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ position: 'relative', mb: 2 }}>
                          <Grid
                            container
                            justifyContent='center'
                            alignItems='center'
                            style={{
                              position: 'absolute',
                              width: '100%',
                              height: '100%',
                              top: 0,
                              bottom: 0,
                              margin: '0 auto',
                            }}
                          >
                            <Grid item>
                              {dontShowVisual && (
                                <Typography
                                  style={{
                                    position: 'relative',
                                    width: '50%',
                                    height: '50%',
                                    top: -24,
                                    right: 35,
                                    margin: '0 auto',
                                  }}
                                >
                                  {chartEngagementScore.value == 0 || (
                                    <PreviousValueComparison
                                      currentValue={chartEngagementScore?.value}
                                      previousValue={
                                        chartDepartmentScorePrevious?.value
                                      }
                                    />
                                  )}
                                </Typography>
                              )}
                              <Typography
                                color='text.primary'
                                variant='h5'
                                fontWeight='bold'
                                align='center'
                              >
                                {chartDepartmentCompanyScore
                                  ? chartDepartmentCompanyScore
                                  : ''}
                                {chartDepartmentCompanyScore && (
                                  <Typography
                                    component='span'
                                    variant='body1'
                                    fontWeight={900}
                                  >
                                    %
                                  </Typography>
                                )}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Doughnut
                            data={{
                              labels: engagementDepartmentDoughnut.map(
                                engagementScore => engagementScore.label,
                              ),
                              datasets: [
                                {
                                  data: engagementDepartmentDoughnut.map(
                                    engagementScore => engagementScore.value,
                                  ),
                                  backgroundColor: engagementDepartmentDoughnut.map(
                                    engagementScore =>
                                      theme.palette[
                                        getEngagementScoreColor(
                                          engagementScore.key,
                                        )
                                      ]?.light,
                                  ),
                                  weight: 0.5,
                                },
                              ],
                            }}
                            height={200}
                            options={
                              {
                                maintainAspectRatio: false,
                                responsive: true,
                                cutout: '75%',
                                plugins: {
                                  legend: { display: false },
                                },
                              } as any
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  <Grid
                    item
                    mt={3} xs={12} sm={12} md={12} lg={isTeamInsight ? 6 : 6} xl={isTeamInsight ? 6 : 6} ml={isTeamInsight ? '' : 'auto'}
                  >
                    <Grid
                      container
                      justifyContent={isTeamInsight ? '' : 'space-between'}
                      alignItems='center'
                    >
                      <Typography
                        color={isTeamInsight ? 'GrayText' : 'text.primary'}
                        fontWeight={isTeamInsight ? 700 : 500}
                        variant='body1'
                        flex={isTeamInsight ? '0 0 60%' : 'auto'}
                        paragraph
                      >
                        {t('participation')}
                      </Typography>
                      <Typography
                        color={isTeamInsight ? 'GrayText' : 'text.primary'}
                        fontWeight={isTeamInsight ? 700 : 500}
                        variant='body1'
                        paragraph
                      >
                        {Math.round(survey.participation_rate)}% (
                        {survey.current_participation}/{survey.invites})
                      </Typography>

                    </Grid>
                    {isTeamInsight && (
                      <>
                        <Divider sx={{ margin: '0 0 15px' }} />
                        {engagementTeamDoughnut.map(engagementScore =>
                          renderLegend(engagementScore),
                        )}
                      </>
                    )}
                    {!isTeamInsight && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ position: 'relative' }}>
                      <Divider sx={{ margin: '0 0 15px' }} />
                      {
                        engagementScores.map(engagementScore =>
                          renderLegend(engagementScore),
                        )
                      }
                    </Grid>}
                  </Grid>

                  {!isTeamInsight && (
                    <Grid item xs={12}
                      sm={12} md={12} lg={6} xl={6} mt={2}
                      style={{
                        marginLeft: "auto"
                      }}>
                      <Typography
                        variant='body2'
                        color='text.secondary'
                        paragraph
                      >
                        <Box my={2}>
                          {getDriverScoreDescription(
                            SurveyDriverScore.Engagement,
                          )}
                        </Box>
                      </Typography>
                      <br />
                    </Grid>
                  )}

                  {isTeamInsight && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      pl={3}
                      pt={2}
                    >
                      <Typography
                        variant='body2'
                        color='text.secondary'
                        paragraph
                      >
                        <Box my={2}>
                          {getDriverScoreDescription(
                            SurveyDriverScore.Engagement,
                          )}
                        </Box>
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )
        }

        <Grid item xs={12} md={6}>
          <Card style={{ height: '100%' }}>
            <CardContent>
              <Box>
                <Typography
                  aria-owns={open ? 'mouse-over-popover' : undefined}
                  aria-haspopup='true'
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                >
                  <Typography
                    color='text.primary'
                    variant='h5'
                    fontWeight='bold'
                  >
                    <Grid
                      container
                      component='span'
                      alignItems='center'
                      justifyContent={'flex-start'}
                    >
                      <Grid item xs='auto'>
                        eNPS
                      </Grid>

                      <Grid item xs='auto'>
                        <InfoIcon sx={{ ml: 1 }} color='success' />
                      </Grid>
                    </Grid>
                  </Typography>
                </Typography>
                <Popover
                  id='mouse-over-popover'
                  sx={{
                    pointerEvents: 'none',
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <Box sx={{ px: 3, py: 2.5, maxWidth: '28em' }}>
                    <Typography variant='h6' fontWeight={'bold'} sx={{ mb: 1 }}>
                      Employee Net Promoter Score
                    </Typography>

                    <Typography variant='body2' color='text.secondary'>
                      Percentage of Promoters (4‘s and 5’s) minus percentage of
                      Detractors (1’s and 2’s). The result can range from -100
                      to +100.
                    </Typography>

                    <Typography
                      variant='body2'
                      color='text.light'
                      sx={{ mt: 1.5, fontSize: '0.8em' }}
                    >
                      Please note: There may be a 1 or 2 point variation in your
                      final eNPS score from the percentages listed below due to
                      rounding, the number in the circle is the most accurate.
                    </Typography>
                  </Box>
                </Popover>
              </Box>

              <Grid
                container
                id='enps-score'
                display={!isTeamInsight ? 'flex' : ''}
                justifyContent={!isTeamInsight ? 'center' : ''}
              >
                <Grid
                  item
                  mt={3} xs={12} sm={12} md={12} lg={isTeamInsight ? 6 : 6} xl={isTeamInsight ? 6 : 6}
                  style={isTeamInsight ? { position: 'relative' } : { position: 'relative', marginRight: "auto" }}
                >
                  {/* <Grid item xs={12} mt={2} style={{ position: 'relative' }}> */}
                  <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      top: 0,
                      bottom: 0,
                      margin: '0 auto',
                    }}
                  >
                    <Grid item>
                      {dontShowVisual && showTrends && (
                        <Typography
                          style={{
                            position: 'relative',
                            width: '50%',
                            height: '50%',
                            top: -24,
                            right: 35,
                            margin: '0 auto',
                          }}
                        >
                          {' '}
                          {(chartEnpsScoreforReport && chartEnpsScoreforReport == 0) || (
                            <PreviousValueComparison
                              currentValue={chartEnpsScoreforReport ? chartEnpsScoreforReport.value : 0}
                              previousValue={parseInt(
                                chartEngagementeNPSScorePrevious?.value,
                              )}
                            />
                          )}
                        </Typography>
                      )}
                      {
                        isTeamInsight ? <Typography variant='h3' fontWeight='bold' align='center'>
                          {getEnpsDoughnutScore(chartEnpsScore)}
                          {chartEnpsScore && (
                            <Typography
                              component='span'
                              variant='body1'
                              fontWeight={900}
                            ></Typography>
                          )}
                        </Typography> : <Typography variant='h3' fontWeight='bold' align='center'>
                          {chartEnpsScoreforReport ? chartEnpsScoreforReport.value : '0'}
                          {chartEnpsScoreforReport && (
                            <Typography
                              component='span'
                              variant='body1'
                              fontWeight={900}
                            ></Typography>
                          )}
                        </Typography>
                      }
                    </Grid>
                  </Grid>
                  <Doughnut
                    data={{
                      labels: isTeamInsight && enpsTeamDoughnut ? enpsTeamDoughnut.map(
                        engagementScore => engagementScore.label,
                      ) : enpsTeamScores ? enpsTeamScores.map(
                        engagementScore => engagementScore.label,
                      ) : [],
                      datasets: [
                        {
                          data: isTeamInsight && enpsTeamDoughnut ? enpsTeamDoughnut?.map(
                            engagementScore => engagementScore.value,
                          ) : enpsTeamScores ? enpsTeamScores?.map(
                            engagementScore => engagementScore.value,
                          ) : [],
                          backgroundColor: isTeamInsight && enpsTeamDoughnut ? enpsTeamDoughnut?.map(
                            engagementScore =>
                              theme.palette[
                                getEnpsScoreColor(engagementScore.key)
                              ]?.light,
                          ) : enpsTeamScores ? enpsTeamScores?.map(
                            engagementScore =>
                              theme.palette[
                                getEnpsScoreColorForReportPage(engagementScore.key)
                              ]?.light,
                          ) : [],
                        },
                      ],
                    }}
                    height={200}
                    options={
                      {
                        maintainAspectRatio: false,
                        responsive: true,
                        cutout: '75%',
                        plugins: {
                          legend: { display: false },
                          formatter: value => {
                            return value + '%'
                          },
                        },
                      } as any
                    }
                  />
                </Grid>

                {isTeamInsight && (
                  <Grid item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={showDepartmentDetails ? 3 : 6}
                    xl={showDepartmentDetails ? 3 : 6}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography
                          color='GrayText'
                          variant='body2'
                          fontWeight={700}
                          align='center'
                        >
                          Organizational <br /> Score
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sx={{ position: 'relative' }}>
                        <Grid
                          container
                          justifyContent='center'
                          alignItems='center'
                          style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            top: 0,
                            bottom: 0,
                            margin: '0 auto',
                          }}
                        >
                          <Grid item>
                            {dontShowVisual && (
                              <Typography
                                style={{
                                  position: 'relative',
                                  width: '50%',
                                  height: '50%',
                                  top: -24,
                                  right: 35,
                                  margin: '0 auto',
                                }}
                              >
                                {chartOrganisationEnpsScore == 0 || (
                                  <PreviousValueComparison
                                    currentValue={
                                      chartOrganisationEnpsScore
                                    }
                                    previousValue={
                                      chartOrganisationEngagementeNPSScorePrevious?.value
                                    }
                                  />
                                )}
                              </Typography>
                            )}
                            <Typography
                              color='text.primary'
                              variant='h5'
                              fontWeight='bold'
                              align='center'
                            >
                              {chartOrganisationEnpsScore
                                ? getEnpsDoughnutScore(chartOrganisationEnpsScore)
                                : 'N/A'}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Doughnut
                          data={{
                            datasets: [
                              {
                                data: enpsCompanyDoughnut.map(
                                  engagementScore => engagementScore.value,
                                ),
                                backgroundColor: enpsCompanyDoughnut.map(
                                  engagementScore =>
                                    theme.palette[
                                      getEnpsScoreColor(engagementScore.key)
                                    ]?.light,
                                ),
                                weight: 0.5,
                              },
                            ],
                          }}
                          height={200}
                          options={
                            {
                              maintainAspectRatio: false,
                              responsive: true,
                              cutout: '75%',
                              plugins: {
                                legend: { display: false },
                              },
                            } as any
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {(isTeamInsight && showDepartmentDetails) && (
                  <>
                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            color='GrayText'
                            variant='body2'
                            fontWeight={700}
                            align='center'
                          >
                            Department <br /> Score
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ position: 'relative' }}>
                          <Grid
                            container
                            justifyContent='center'
                            alignItems='center'
                            style={{
                              position: 'absolute',
                              width: '100%',
                              height: '100%',
                              top: 0,
                              bottom: 0,
                              margin: '0 auto',
                            }}
                          >
                            <Grid item>
                              {dontShowVisual && (
                                <Typography
                                  style={{
                                    position: 'relative',
                                    width: '50%',
                                    height: '50%',
                                    top: -15,
                                    right: 35,
                                    margin: '0 auto',
                                  }}
                                >
                                  {chartDepartmentEnpsScore == 0 || (
                                    <PreviousValueComparison
                                      currentValue={
                                        chartDepartmentEnpsScore
                                      }
                                      previousValue={
                                        chartDepartmentEngagementeNPSScorePrevious?.value
                                      }
                                    />
                                  )}
                                </Typography>
                              )}
                              <Typography
                                color='text.primary'
                                variant='h5'
                                fontWeight='bold'
                                align='center'
                              >
                                {chartDepartmentEnpsScore
                                  ? getEnpsDoughnutScore(chartDepartmentEnpsScore) : ' '}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Doughnut
                            data={{
                              datasets: [
                                {
                                  data: enpsDepartmentDoughnut?.map(
                                    engagementScore => engagementScore.value,
                                  ),
                                  backgroundColor: enpsDepartmentDoughnut?.map(
                                    engagementScore =>
                                      theme.palette[
                                        getEnpsScoreColor(engagementScore.key)
                                      ]?.light,
                                  ),
                                  weight: 0.5,
                                },
                              ],
                            }}
                            height={200}
                            options={
                              {
                                maintainAspectRatio: false,
                                responsive: true,
                                cutout: '75%',
                                plugins: {
                                  legend: { display: false },
                                },
                              } as any
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider sx={{ margin: '0 0 15px' }} />
                  </>
                )}
                {!isTeamInsight &&
                  <Grid
                    item
                    mt={3} xs={12} sm={12} md={12} lg={isTeamInsight ? 12 : 6} xl={isTeamInsight ? 12 : 6} ml={isTeamInsight ? '' : 'auto'}
                  >
                    <Grid item mt={3} xs={12} lg={isTeamInsight ? 6 : 12} marginTop={6}>
                      <Grid
                        container
                        justifyContent={isTeamInsight ? '' : 'space-between'}
                      >
                        <Grid
                          style={{
                            flex: '0 0 60%',
                          }}
                        >
                          <Typography
                            style={{ textTransform: 'capitalize' }}
                            variant='body2'
                          >
                            {
                              <EngagedIcon
                                color={'primary'}
                                style={{ verticalAlign: 'middle' }}
                              />
                            }{' '}
                            {SurveyEnpsForReportPage?.Promoter}
                          </Typography>
                        </Grid>
                        <Typography
                          color={isTeamInsight ? 'GrayText' : 'text.primary'}
                          fontWeight={isTeamInsight ? 700 : 500}
                          variant='body2'
                          paragraph
                        >
                          {promoters?.value ? promoters?.value : 0}%
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        justifyContent={isTeamInsight ? '' : 'space-between'}
                      >
                        <Grid
                          style={{
                            flex: '0 0 60%',
                          }}
                        >
                          <Typography
                            style={{ textTransform: 'capitalize' }}
                            variant='body2'
                          >
                            {
                              <NeutralIcon
                                color={'secondary'}
                                style={{ verticalAlign: 'middle' }}
                              />
                            }{' '}
                            {SurveyEnpsForReportPage?.Passive}
                          </Typography>
                        </Grid>
                        <Typography
                          color={isTeamInsight ? 'GrayText' : 'text.primary'}
                          fontWeight={isTeamInsight ? 700 : 500}
                          variant='body2'
                          paragraph
                        >
                          {passives?.value ? passives?.value : 0}%
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        justifyContent={isTeamInsight ? '' : 'space-between'}
                      >
                        <Grid
                          style={{
                            flex: '0 0 60%',
                          }}
                        >
                          <Typography
                            style={{ textTransform: 'capitalize' }}
                            variant='body2'
                          >
                            {
                              <DisengagedIcon
                                color={'error'}
                                style={{ verticalAlign: 'middle' }}
                              />
                            }{' '}
                            {SurveyEnpsForReportPage?.Detractor}
                          </Typography>
                        </Grid>
                        <Typography
                          color={isTeamInsight ? 'GrayText' : 'text.primary'}
                          fontWeight={isTeamInsight ? 700 : 500}
                          variant='body2'
                          paragraph
                        >
                          {detractors?.value ? detractors?.value : 0}%
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                }

                {isTeamInsight && (
                  <>
                    <Grid item mt={3} xs={12} lg={isTeamInsight ? 6 : 12}>
                      <Grid
                        container
                        justifyContent={isTeamInsight ? '' : 'space-between'}
                      >
                        <Grid
                          style={{
                            flex: '0 0 60%',
                          }}
                        >
                          <Typography
                            style={{ textTransform: 'capitalize' }}
                            variant='body2'
                          >
                            {
                              <EngagedIcon
                                color={'primary'}
                                style={{ verticalAlign: 'middle' }}
                              />
                            }{' '}
                            {SurveyEnps?.Promoter}
                          </Typography>
                        </Grid>
                        <Typography
                          color={isTeamInsight ? 'GrayText' : 'text.primary'}
                          fontWeight={isTeamInsight ? 700 : 500}
                          variant='body2'
                          paragraph
                        >
                          {promoter?.value ? promoter?.value : 0}%
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        justifyContent={isTeamInsight ? '' : 'space-between'}
                      >
                        <Grid
                          style={{
                            flex: '0 0 60%',
                          }}
                        >
                          <Typography
                            style={{ textTransform: 'capitalize' }}
                            variant='body2'
                          >
                            {
                              <NeutralIcon
                                color={'secondary'}
                                style={{ verticalAlign: 'middle' }}
                              />
                            }{' '}
                            {SurveyEnps.Passive}
                          </Typography>
                        </Grid>
                        <Typography
                          color={isTeamInsight ? 'GrayText' : 'text.primary'}
                          fontWeight={isTeamInsight ? 700 : 500}
                          variant='body2'
                          paragraph
                        >
                          {passive?.value ? passive?.value : 0}%
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        justifyContent={isTeamInsight ? '' : 'space-between'}
                      >
                        <Grid
                          style={{
                            flex: '0 0 60%',
                          }}
                        >
                          <Typography
                            style={{ textTransform: 'capitalize' }}
                            variant='body2'
                          >
                            {
                              <DisengagedIcon
                                color={'error'}
                                style={{ verticalAlign: 'middle' }}
                              />
                            }{' '}
                            {SurveyEnps.Detractor}
                          </Typography>
                        </Grid>
                        <Typography
                          color={isTeamInsight ? 'GrayText' : 'text.primary'}
                          fontWeight={isTeamInsight ? 700 : 500}
                          variant='body2'
                          paragraph
                        >
                          {detractor?.value ? detractor?.value : 0}%
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      mt={3}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      pl={3}
                      pt={1}
                    >
                      <Grid
                        container
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <Typography
                          variant='body2'
                          color='text.secondary'
                          paragraph
                        >
                          {/* Employee Net Promoter Score  <br /> */}
                          <b>
                            {' '}
                            I would refer {companyName} to a friend as a good
                            place to work.
                          </b>{' '}
                          This statement measures employee satisfaction and
                          loyalty.
                        </Typography>
                      </Grid>
                    </Grid>
                  </>

                )}

                {!isTeamInsight && (
                  <Grid item xs={12}
                    sm={12} md={12} lg={6} xl={6} mt={2}
                    style={{
                      marginLeft: "auto"
                    }}>
                    <Grid
                      container
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <Typography
                        variant='body2'
                        color='text.secondary'
                        paragraph
                      >
                        <Box my={2}>
                          <b>
                            I would refer {companyName} to a friend as a good
                            place to work.{' '}
                          </b>
                          This statement measures employee satisfaction and
                          loyalty.
                        </Box>
                      </Typography>
                    </Grid>
                  </Grid>
                )}


              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid >
    )
  } else {
    return null
  }
}
