import { Box } from '@mui/material'

export default function AvoidCopy({ children }) {
  return (
    <Box
      component='div'
      sx={{
        WebkitTouchCallout: 'none',
        WebkitUserSelect: 'none',
        KhtmlUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
      }}
    >
      {children}
    </Box>
  )
}
