import * as React from 'react'

import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from '@mui/icons-material'
import {
  Box,
  Checkbox,
  Chip,
  Link,
  ListSubheader,
  Popper,
  styled,
  TextField,
} from '@mui/material'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import { useDebounce } from 'react-use'
import { ListChildComponentProps, VariableSizeList } from 'react-window'

import { UserAvatar } from 'components'
import { t } from 'i18n'

const LISTBOX_PADDING = 8 // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props
  const dataSet = data[index]
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  }

  if (Object.prototype.hasOwnProperty.call(dataSet, 'group')) {
    return (
      <ListSubheader key={dataSet.key} component='div' style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    )
  }

  return (
    <Box width={1} {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1]}
    </Box>
  )
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props
  const itemData: React.ReactChild[] = []
  ;(children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item)
      itemData.push(...(item.children || []))
    },
  )

  const itemCount = itemData.length
  // const itemSize = smUp ? 36 : 48
  // const itemSize = smUp ? 56 : 48
  const itemSize = 48

  const getChildSize = (child: React.ReactChild) => {
    if (Object.prototype.hasOwnProperty.call(child, 'group')) {
      return 48
    }

    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width='100%'
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType='ul'
          itemSize={index => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
})

export default function SparckEmployeesPicker({
  sparckState,
  setSparckState,
  employees,
  employeesIsLoading,
  fetchRecognitionEvents,
  openUsersAdvancedSearchDialog,
}) {
  const [debounceFetchRecognitionEvents] = useDebounce(
    () => {
      if (sparckState?.users_to_recognize.length) {
        fetchRecognitionEvents()
      }
    },
    1000,
    [sparckState?.users_to_recognize],
  )

  return (
    <Autocomplete
      id='sparck-employees'
      data-cy='employees'
      multiple
      value={sparckState.users_to_recognize}
      onChange={(event, value) => {
        setSparckState({
          ...sparckState,
          users_to_recognize: value,
          individual_to_recognize: value.length === 1 ? value[0] : null,
        })
        debounceFetchRecognitionEvents()
      }}
      loading={employeesIsLoading}
      loadingText={t('loading')}
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      options={employees || []}
      getOptionLabel={option => `${option.full_name}`}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={params => (
        <TextField
          {...params}
          label={t('sparcks.sparck.formLabels.name')}
          variant='outlined'
          helperText={
            <Link onClick={openUsersAdvancedSearchDialog}>
              {t('sparcks.sparck.advancedSearchOptions')}
            </Link>
          }
        />
      )}
      renderOption={(props, option: any) => [
        props,
        <Box
          component='li'
          sx={{
            '& > img': { mr: 3, flexShrink: 0 },
            '&.MuiAutocomplete-option[aria-selected="true"]': {
              backgroundColor: 'transparent',
              width: '100%',
            },
          }}
          {...props}
        >
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
            checkedIcon={<CheckBoxIcon fontSize='small' />}
            style={{ marginRight: 8, marginLeft: -16 }}
            checked={sparckState.users_to_recognize
              .map(user => user.id)
              .includes(option.id)}
          />

          <UserAvatar
            name={option?.first_name}
            src={option?.avatar?.url}
            size={24}
          />

          <Box component='span' ml={1}>
            {`${option.full_name}`}
          </Box>
        </Box>,
      ]}
      renderTags={values =>
        values.map(value => (
          <Chip
            key={value.id}
            label={value.full_name}
            onDelete={() =>
              setSparckState({
                ...sparckState,
                users_to_recognize: sparckState.users_to_recognize.filter(
                  user => user.id !== value.id,
                ),
              })
            }
            {...(value.avatar?.url && {
              avatar: (
                <UserAvatar
                  size={16}
                  name={value.full_name}
                  src={value.avatar?.url}
                />
              ),
            })}
          />
        ))
      }
      disableListWrap
      disableCloseOnSelect
      limitTags={5}
      fullWidth
    />
  )
}
