import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'
import {
  CheckCircle as CheckIcon,
  VideocamOutlined as VideoIcon,
  MenuBookOutlined as TrainingIcon,
  ContactSupportOutlined as QuizIcon,
  AssignmentLateOutlined as ChallengeIcon,
} from '@mui/icons-material'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { ManagerCertificationSubmitStoryCardContent } from '../../components'
import { TaskType } from 'constants/managerCertification'
import { ROUTES_PATHS } from 'core/routes'
import { t } from 'i18n'

function TaskContentItem(props) {
  const { Icon = null, description = '', amount = null } = props

  if (!amount) return null
  return (
    <Grid item mt={2}>
      <Grid container alignItems='center'>
        <Icon sx={{ color: 'primary.main', mr: 1 }} />

        <Typography color='text.secondary' fontSize={16}>
          {amount} {description}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default function ManagerCertificationTasks(props) {
  const { roadmap, userCertification } = props

  const intl = useIntl()

  const history = useHistory()

  const isTaskComplete = task =>
    userCertification?.certification_state?.sections?.find(
      section => section.id === task.id,
    )?.status === 'completed'

  return (
    <Grid container justifyContent='center'>
      <Grid item xs={12}>
        <Typography
          color='text.primary'
          variant='h5'
          fontWeight={600}
          paragraph
          textAlign='left'
        >
          {t(
            'managerCertification.managerCertificationComponent.sections.roadmap.title',
          )}
        </Typography>
      </Grid>
      <Typography color='text.secondary' variant='body1'>
        {t(
          'managerCertification.managerCertificationComponent.sections.roadmap.description',
        )}
      </Typography>

      <Grid item mt={8} xs={11}>
        <Grid container justifyContent='space-around' spacing={2}>
          {(roadmap ?? [])?.map((day, index) => {
            let trainingVideosQuantity = 0
            let resourcesQuantity = 0
            let quizzesQuantity = 0
            let challengesQuantity = 0

            day.subsections?.forEach(subsection => {
              subsection?.tasks?.forEach(task => {
                if (task.type === TaskType.DownloadAssets) resourcesQuantity++
                if (task.type === TaskType.Quiz) quizzesQuantity++
                if (task.type === TaskType.Redirect) challengesQuantity++
                if (task.type === TaskType.Video) trainingVideosQuantity++
              })
            })

            return (
              <ManagerCertificationTaskCard
                isTaskComplete={isTaskComplete(day)}
                key={day.id}
              >
                <CardContent>
                  <Typography
                    color='text.primary'
                    fontSize={day.id <= 5 ? 28 : 24}
                    fontWeight={700}
                    textAlign='left'
                    mb={1}
                  >
                    {`${intl.formatMessage({
                      id: 'managerCertification.managerCertificationTask.day',
                    })} ${day.id}`}{' '}
                    {day.emoji}
                  </Typography>

                  <Typography
                    color={'text.primary'}
                    fontSize={18}
                    paragraph
                    mb={4}
                  >
                    {day.title}
                  </Typography>

                  <TaskContentItem
                    amount={trainingVideosQuantity}
                    description={
                      trainingVideosQuantity > 1
                        ? t(
                            'managerCertification.managerCertificationTasks.trainingVideos',
                          )
                        : t(
                            'managerCertification.managerCertificationTasks.trainingVideo',
                          )
                    }
                    Icon={VideoIcon}
                  />

                  <TaskContentItem
                    amount={resourcesQuantity}
                    description={
                      resourcesQuantity > 1
                        ? t(
                            'managerCertification.managerCertificationTasks.trainingResources',
                          )
                        : t(
                            'managerCertification.managerCertificationTasks.trainingResource',
                          )
                    }
                    Icon={TrainingIcon}
                  />

                  <TaskContentItem
                    amount={quizzesQuantity}
                    description={
                      quizzesQuantity > 1
                        ? t(
                            'managerCertification.managerCertificationTasks.quizzes',
                          )
                        : t(
                            'managerCertification.managerCertificationTasks.quiz',
                          )
                    }
                    Icon={QuizIcon}
                  />

                  <TaskContentItem
                    amount={challengesQuantity}
                    description={
                      challengesQuantity > 1
                        ? t(
                            'managerCertification.managerCertificationTasks.challenges',
                          )
                        : t(
                            'managerCertification.managerCertificationTasks.challenge',
                          )
                    }
                    Icon={ChallengeIcon}
                  />
                </CardContent>
                <CardActions>
                  <Grid container justifyContent='flex-end'>
                    <Button
                      sx={{ mb: day.id <= 5 ? 0 : 2 }}
                      size='large'
                      variant='contained'
                      disabled={
                        index > 0 && !isTaskComplete(roadmap[index - 1])
                      }
                      onClick={() =>
                        history.push({
                          pathname: `${ROUTES_PATHS.managerCertification}/${day.id}`,
                        })
                      }
                    >
                      {t(
                        'managerCertification.managerCertificationComponent.sections.roadmap.enter',
                      )}
                    </Button>
                  </Grid>
                </CardActions>
              </ManagerCertificationTaskCard>
            )
          })}
          <ManagerCertificationTaskCard
            isTaskComplete={!!userCertification?.certification_state?.story}
          >
            <ManagerCertificationSubmitStoryCardContent {...props} />
          </ManagerCertificationTaskCard>
        </Grid>
      </Grid>
    </Grid>
  )
}

export function ManagerCertificationTaskCard(props) {
  const { isTaskComplete, cardKey = null, children } = props
  const theme = useTheme()

  return (
    <Grid
      item
      xs={12}
      md={6}
      lg={4}
      key={cardKey}
      sx={{ position: 'relative' }}
    >
      {isTaskComplete && (
        <CheckIcon
          sx={{
            color: 'primary.main',
            position: 'absolute',
            top: 2,
            right: -12,
            fontSize: 32,
            width: 32,
            zIndex: 999,
            backgroundColor: '#FFF',
            borderRadius: '100%',
          }}
        />
      )}
      <Card
        sx={{
          border: isTaskComplete
            ? `3px solid ${theme.palette.primary.main}`
            : 0,
          width: '100%',
          position: 'relative',
          height: 1,
        }}
      >
        <Box px={2} py={1}>
          {children}
        </Box>
      </Card>
    </Grid>
  )
}
