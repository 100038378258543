import { HelpOutline as HelpIcon } from '@mui/icons-material'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
} from '@mui/material'

import { recognitionFrequencies } from 'constants/recognition'
import { t } from 'i18n'

export default function ManageRecognitionAvailability({ formik }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              data-cy='hasRecurrence'
              id='has_recurrence'
              name='has_recurrence'
              checked={formik.values.has_recurrence}
              onChange={() =>
                formik.setFieldValue(
                  'has_recurrence',
                  !formik.values.has_recurrence,
                )
              }
            />
          }
          label={t('recognitions.fields.setPeriod')}
        />
      </Grid>

      {formik.values.has_recurrence && (
        <>
          <Grid item xs={12} sm={4}>
            <TextField
              select
              data-cy='recurrenceType'
              id='recurrence_type'
              name='recurrence_type'
              label={t('recognitions.fields.period')}
              variant='outlined'
              value={formik.values.recurrence_type}
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.recurrence_type &&
                Boolean(formik.errors.recurrence_type)
              }
              helperText={
                formik.touched.recurrence_type && formik.errors.recurrence_type
              }
              fullWidth
            >
              {recognitionFrequencies.map(recognitionFrequency => (
                <MenuItem
                  data-cy='recognitionFrequency'
                  key={recognitionFrequency.value}
                  value={recognitionFrequency.value}
                >
                  {recognitionFrequency.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              type='number'
              data-cy='recurrenceLimit'
              id='recurrence_limit'
              name='recurrence_limit'
              label={t('recognitions.fields.howManyTimesPerPeriod')}
              variant='outlined'
              value={formik.values.recurrence_limit}
              onChange={formik.handleChange}
              required
              onBlur={formik.handleBlur}
              error={
                formik.touched.recurrence_limit &&
                Boolean(formik.errors.recurrence_limit)
              }
              helperText={
                formik.touched.recurrence_limit &&
                formik.errors.recurrence_limit
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <Tooltip
                      title={t('recognitions.fields.tooltips.recurrenceLimit')}
                    >
                      <HelpIcon />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  data-cy='hasRecurrence'
                  id='has_recurrence_limit_per_employee'
                  name='has_recurrence_limit_per_employee'
                  checked={formik.values.has_recurrence_limit_per_employee}
                  onChange={() =>
                    formik.setFieldValue(
                      'has_recurrence_limit_per_employee',
                      !formik.values.has_recurrence_limit_per_employee,
                    )
                  }
                />
              }
              label={t('recognitions.fields.limitPerPeriodCheckbox')}
            />
          </Grid>

          {formik.values.has_recurrence_limit_per_employee && (
            <Grid item xs={12} sm={6}>
              <TextField
                type='number'
                data-cy='recurrenceLimit'
                id='recurrence_limit_per_employee'
                name='recurrence_limit_per_employee'
                label={t('recognitions.fields.limitPerEmployee')}
                variant='outlined'
                value={formik.values.recurrence_limit_per_employee}
                onChange={formik.handleChange}
                required
                onBlur={formik.handleBlur}
                error={
                  formik.touched.recurrence_limit_per_employee &&
                  Boolean(formik.errors.recurrence_limit_per_employee)
                }
                helperText={
                  formik.touched.recurrence_limit_per_employee &&
                  formik.errors.recurrence_limit_per_employee
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Tooltip
                        title={t(
                          'recognitions.fields.tooltips.recurrenceLimitPerEmployee',
                        )}
                      >
                        <HelpIcon />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  )
}
