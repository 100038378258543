import { useState, useEffect } from 'react'

import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  FormControlLabel,
  MobileStepper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { isEmpty } from 'lodash'
import pluralize from 'pluralize'
import { useIntl } from 'react-intl'

import {
  ManageRecognitionAdvanced,
  ManageRecognitionApproval,
  ManageRecognitionAvailability,
  ManageRecognitionGeneral,
  ManageRecognitionInfo,
  ManageRecognitionMilestonesTable,
  ManageRecognitionNomination,
  ManageRecognitionRewards,
  ManageRecognitionTimeframe,
  ManageRecognitionWhoCanRecognize,
  ManageRecognitionWhoIsThisEventFor,
} from '../../components'
import { NavigationMode } from '../../ManageRecognition'
import { ReactComponent as StepsSVG } from 'assets/svg/recognitionEvents/woman-looking-at-steps.svg'
import { a11yTabProps } from 'utils/accessibility'
import { t } from 'i18n'

enum ManageRecognitionTab {
  General = 'general',
  Advanced = 'advanced',
}

enum ManageRecognitionStep {
  Approval = 'approval',
  Availability = 'availability',
  Demographics = 'demographics',
  Info = 'info',
  Milestones = 'milestones',
  Nomination = 'nomination',
  Rewards = 'rewards',
  Roles = 'roles',
  Timeframe = 'timeframe',
  WhoCanRecognize = 'whoCanRecognize',
  WhoIsThisEventFor = 'whoIsThisEventFor',
}

type Props = {
  formik: any
  title: string
  mutation: any
  isEditMode: boolean
  roles: any
  rolesIsLoading: boolean
  nominationProgram: any
  demographics: any
  demographicsIsLoading: boolean
  navigationMode: NavigationMode | null
  setNavigationMode(value: NavigationMode | null | any): void
}

export default function ManageRecognitionForm(props: Props) {
  const { formik, demographics, nominationProgram, navigationMode } = props

  useEffect(() => {
    if (demographics?.length > 0 && formik.values.demographics?.length === 0) {
      const newDemographics = demographics.map(demographic => ({
        category: demographic.id,
        demographics: [],
      }))

      formik.setFieldValue('demographics', [...newDemographics])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demographics])

  useEffect(() => {
    formik.setFieldValue('nomination.frequency', formik.values.recurrence_type)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.recurrence_type])

  useEffect(() => {
    if (nominationProgram?.id) {
      formik.setFieldValue('nomination', {
        ...nominationProgram,
        frequency: nominationProgram.frequency ?? formik.values.recurrence_type,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nominationProgram])

  if (!navigationMode) {
    return <ManageRecognitionNavigationMode {...props} />
  }

  if (navigationMode === NavigationMode.Tabs) {
    return <ManageRecognitionFormTabs {...props} />
  }

  if (navigationMode === NavigationMode.Steps) {
    return <ManageRecognitionFormSteps {...props} />
  }

  return null
}

function ManageRecognitionNavigationMode({ setNavigationMode }: Props) {
  const [dontAskMeAgain, setDontAskMeAgain] = useState(false)

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDontAskMeAgain(event.target.checked)
  }

  const handleRedirect = (navigationMode: NavigationMode) => {
    if (dontAskMeAgain) {
      localStorage.setItem('recognitionEventNavigation', navigationMode)
    }

    setNavigationMode(navigationMode)
  }

  useEffect(() => {
    const navigationModeFromLocalStorage = localStorage.getItem(
      'recognitionEventNavigation',
    )

    if (navigationModeFromLocalStorage) {
      setNavigationMode(navigationModeFromLocalStorage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container my={3}>
      <Grid item xs={12}>
        <Grid container justifyContent='center'>
          <Grid item xs={12} sm={9} md={6} mb={5}>
            <StepsSVG style={{ width: '100%', height: '100%' }} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h5' align='center' fontWeight={600} mb={2}>
          {t('recognitions.welcome.title')}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant='body1' align='center' paragraph>
          {t('recognitions.welcome.description')}
        </Typography>
      </Grid>

      <Grid item xs={12} mt={2}>
        <Grid container justifyContent='center'>
          <FormControlLabel
            control={
              <Checkbox
                data-cy='dontAskMeAgain'
                checked={dontAskMeAgain}
                onChange={handleCheckboxChange}
              />
            }
            label={t('recognitions.welcome.dontAskAgain')}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} mt={2}>
        <Grid container spacing={2} justifyContent='center'>
          <Grid item xs={12} sm='auto'>
            <Button
              data-cy='goToRecognitionForm'
              color='primary'
              variant='outlined'
              onClick={() => handleRedirect(NavigationMode.Tabs)}
            >
              {t('recognitions.welcome.noThanks')}
            </Button>
          </Grid>

          <Grid item xs={12} sm='auto'>
            <Button
              data-cy='goToRecognitionHelp'
              color='primary'
              variant='contained'
              onClick={() => handleRedirect(NavigationMode.Steps)}
            >
              {t('recognitions.welcome.showMeTheRopes')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

function ManageRecognitionFormTabs(props: Props) {
  const { formik, title, mutation, isEditMode } = props

  const intl = useIntl()

  const [selectedTab, setSelectedTab] = useState(ManageRecognitionTab.General)

  const tabs = [
    {
      value: ManageRecognitionTab.General,
      label: intl.formatMessage({
        id: 'recognitions.tabLabels.general',
      }),
      disabled: false,
      Component: ManageRecognitionGeneral,
    },
    {
      value: ManageRecognitionTab.Advanced,
      label: intl.formatMessage({
        id: 'recognitions.tabLabels.advanced',
      }),
      disabled: !!(formik.errors.name || formik.errors.icon),
      Component: ManageRecognitionAdvanced,
    },
  ]

  const lastTab = tabs[tabs.length - 1].value

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label='basic tabs example'
          >
            {tabs.map((tab, index) => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={tab.label}
                disabled={tab.disabled}
                {...a11yTabProps(index)}
              />
            ))}
          </Tabs>
        </Box>
        {tabs.map((tab, index) => (
          <TabPanel
            key={tab.value}
            value={tab.value}
            selectedTab={selectedTab}
            index={index}
          >
            <tab.Component {...props} />
          </TabPanel>
        ))}
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent='flex-end'>
          {isEditMode || selectedTab === lastTab ? (
            <Button
              type='submit'
              color='primary'
              variant='contained'
              size='large'
              disabled={mutation.isLoading}
              data-cy='submitButton'
            >
              {`${
                isEditMode
                  ? intl.formatMessage({
                      id: 'formHandler.update',
                    })
                  : intl.formatMessage({
                      id: 'formHandler.create',
                    })
              } ${pluralize.singular(title)}`}
              {mutation.isLoading && (
                <CircularProgress sx={{ ml: 1 }} size={24} />
              )}
            </Button>
          ) : (
            <Tooltip
              title={
                formik.errors.name ?? !isEmpty(formik.errors)
                  ? intl.formatMessage({
                      id: 'recognitions.tabLabels.tooltip',
                    })
                  : ''
              }
            >
              <span>
                <Button
                  color='primary'
                  variant='contained'
                  size='large'
                  data-cy='nextButton'
                  disabled={
                    tabs[tabs.findIndex(tab => tab.value === selectedTab) + 1]
                      ?.disabled
                  }
                  onClick={() =>
                    setSelectedTab(
                      tabs[tabs.findIndex(tab => tab.value === selectedTab) + 1]
                        .value,
                    )
                  }
                >
                  {intl.formatMessage({
                    id: 'recognitions.next',
                  })}
                </Button>
              </span>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

function ManageRecognitionFormSteps(props: Props) {
  const { formik } = props

  const theme = useTheme()
  const intl = useIntl()

  const [selectedStep, setSelectedStep] = useState(0)

  const handleNextStep = () => {
    setSelectedStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBackStep = () => {
    setSelectedStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleSubmit = () => formik.handleSubmit()

  const steps = [
    {
      id: ManageRecognitionStep.Info,
      title: intl.formatMessage({ id: 'recognitions.formSteps.general.title' }),
      description: intl.formatMessage({
        id: 'recognitions.formSteps.general.description',
      }),
      Component: ManageRecognitionInfo,
    },
    {
      id: ManageRecognitionStep.WhoIsThisEventFor,
      title: intl.formatMessage({
        id: 'recognitions.formSteps.whoIsThisEventFor.title',
      }),
      description: (
        <>
          <Typography variant='body1' color='text.secondary' mb={1}>
            {intl.formatMessage({
              id: 'recognitions.formSteps.whoIsThisEventFor.description',
            })}
          </Typography>
        </>
      ),
      Component: ManageRecognitionWhoIsThisEventFor,
    },
    {
      id: ManageRecognitionStep.WhoCanRecognize,
      title: intl.formatMessage({
        id: 'recognitions.formSteps.whoCanRecognize.title',
      }),
      description: (
        <>
          <Typography variant='body1' color='text.secondary' mb={1}>
            {intl.formatMessage({
              id: 'recognitions.formSteps.whoCanRecognize.description',
            })}
          </Typography>
        </>
      ),
      Component: ManageRecognitionWhoCanRecognize,
    },
    {
      id: ManageRecognitionStep.Rewards,
      title: intl.formatMessage({
        id: 'recognitions.formSteps.rewards.title',
      }),
      description: intl.formatMessage({
        id: 'recognitions.formSteps.rewards.description',
      }),
      Component: ManageRecognitionRewards,
    },
    {
      id: ManageRecognitionStep.Timeframe,
      title: intl.formatMessage({
        id: 'recognitions.formSteps.timeframe.title',
      }),
      description: intl.formatMessage({
        id: 'recognitions.formSteps.timeframe.description',
      }),
      Component: ManageRecognitionTimeframe,
    },
    ...(formik.values.type !== 'birthday'
      ? [
          {
            id: ManageRecognitionStep.Availability,
            title: intl.formatMessage({
              id: 'recognitions.formSteps.availability.title',
            }),
            description: intl.formatMessage({
              id: 'recognitions.formSteps.availability.description',
            }),
            Component: ManageRecognitionAvailability,
          },
        ]
      : []),
    ...(formik.values.has_nominations && formik.values.has_recurrence
      ? [
          {
            id: ManageRecognitionStep.Nomination,
            title: intl.formatMessage({
              id: 'recognitions.formSteps.nomination.title',
            }),
            description: intl.formatMessage({
              id: 'recognitions.formSteps.nomination.description',
            }),
            Component: ManageRecognitionNomination,
          },
        ]
      : []),
    {
      id: ManageRecognitionStep.Milestones,
      title: intl.formatMessage({
        id: 'recognitions.formSteps.milestones.title',
      }),
      description: (
        <>
          <Typography variant='body1' color='text.secondary' mb={1}>
            {intl.formatMessage({
              id: 'recognitions.formSteps.milestones.description',
            })}
          </Typography>
        </>
      ),
      Component: ManageRecognitionMilestonesTable,
    },
    {
      id: ManageRecognitionStep.Approval,
      title: intl.formatMessage({
        id: 'recognitions.formSteps.approval.title',
      }),
      description: intl.formatMessage({
        id: 'recognitions.formSteps.approval.description',
      }),
      Component: ManageRecognitionApproval,
    },
  ]

  const title = steps[selectedStep].title
  const description = steps[selectedStep].description
  const SelectedComponent = steps[selectedStep].Component

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='h6' color='text.primary' fontWeight={600} mb={1}>
          {selectedStep + 1}. {title}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {typeof description === 'string' ? (
          <Typography variant='body1' color='text.secondary' mb={1}>
            {description}
          </Typography>
        ) : (
          description
        )}
      </Grid>

      <Grid item xs={12} my={3}>
        <SelectedComponent {...props} />
      </Grid>

      <Grid item xs={12} mt={3}>
        <Grid container justifyContent='center'>
          <Grid item xs={12} sm={9} md={8}>
            <MobileStepper
              variant='text'
              steps={steps.length}
              position='static'
              activeStep={selectedStep}
              nextButton={
                <Button
                  data-cy='nextPage'
                  size='small'
                  onClick={
                    selectedStep !== steps.length - 1
                      ? handleNextStep
                      : handleSubmit
                  }
                  disabled={
                    !isEmpty(formik.errors) ||
                    !formik.values.name ||
                    !formik.values.icon
                  }
                >
                  {selectedStep !== steps.length - 1
                    ? t('recognitions.next')
                    : t('recognitions.finish')}
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeftIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size='small'
                  onClick={handleBackStep}
                  disabled={
                    selectedStep === 0 ||
                    !isEmpty(formik.errors) ||
                    !formik.values.name ||
                    !formik.values.icon
                  }
                >
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowRightIcon />
                  ) : (
                    <KeyboardArrowLeftIcon />
                  )}
                  {t('recognitions.back')}
                </Button>
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

function TabPanel(props) {
  const { children, value, index, selectedTab, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== selectedTab}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === selectedTab && (
        <Box width={1} py={3}>
          {children}
        </Box>
      )}
    </div>
  )
}
