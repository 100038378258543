import { DEV, HEROKU, PROD } from 'core/environments'

const TESTING_ENV_LINK =
  'https://slack.com/oauth/v2/authorize?scope=channels%3ahistory%2cchannels%3aread%2cchannels%3amanage%2cchat%3awrite.public%2cchat%3awrite%2cdnd%3aread%2cemoji%3aread%2cfiles%3aread%2cfiles%3awrite%2cgroups%3ahistory%2cgroups%3aread%2cim%3ahistory%2cim%3aread%2cim%3awrite%2cmpim%3ahistory%2cmpim%3aread%2cmpim%3awrite%2cpins%3aread%2cpins%3awrite%2creactions%3aread%2creactions%3awrite%2cteam%3aread%2cusergroups%3aread%2cusers%3aread%2cusers%3awrite%2cusers%3aread.email&client_id=3074489071686.3284428927425&redirect_uri=https%3a%2f%2fslack.botframework.com%2fHome%2fauth%2fv2&state=SparckBotStaging'

export const sparckBotLinkByEnvironment = {
  [DEV]: TESTING_ENV_LINK,
  [HEROKU]: TESTING_ENV_LINK,
  [PROD]:
    'https://slack.com/oauth/v2/authorize?scope=channels%3ahistory%2cchannels%3aread%2cchannels%3amanage%2cchat%3awrite.public%2cchat%3awrite%2cdnd%3aread%2cemoji%3aread%2cfiles%3aread%2cfiles%3awrite%2cgroups%3ahistory%2cgroups%3aread%2cim%3ahistory%2cim%3aread%2cim%3awrite%2cmpim%3ahistory%2cmpim%3aread%2cmpim%3awrite%2cpins%3aread%2cpins%3awrite%2creactions%3aread%2creactions%3awrite%2cteam%3aread%2cusergroups%3aread%2cusers%3aread%2cusers%3awrite%2cusers%3aread.email&client_id=906749017527.2670609830548&redirect_uri=https%3a%2f%2fslack.botframework.com%2fHome%2fauth%2fv2&state=SparckBotProduction',
}
