import { useEffect } from 'react'

import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'

import { MarketingComponent } from './components'
import { useShoppingCartItemsCount } from 'contexts'
import { API } from 'core/requests'
import { t } from 'i18n'

export default function Marketing() {
  const { enqueueSnackbar } = useSnackbar()

  const { setShoppingCartItemsCount } = useShoppingCartItemsCount()!
  const history = useHistory()
  const queryParams = new URLSearchParams(history.location.search)

  const addSurveyToCartMutation = useMutation(
    (payload: any) => API.create('shoppingCartAddItem', payload),
    {
      onSettled: (response: any) => {
        if ((response && response.id) || (response && response.success)) {
          enqueueSnackbar(t('marketing.snackbar.success.surveyAddedToCart'), {
            variant: 'success',
          })

          setShoppingCartItemsCount(state => state + 1)
        }
      },
    },
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (
      queryParams.get('id') &&
      queryParams.get('name') &&
      queryParams.get('object_id_to_upgrade') &&
      queryParams.get('object_name_to_upgrade') &&
      queryParams.get('product_key')
    ) {
      addSurveyToCartMutation.mutate({
        product_key: queryParams.get('product_key'),
        object_name_to_upgrade: queryParams.get('object_name_to_upgrade'),
        object_id_to_upgrade: queryParams.get('object_id_to_upgrade'),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkoutCurrentCartMutation = useMutation(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (_: any) => API.create('shoppingCartCheckout'),
    {
      onSettled: (response: any) => {
        const stripeLinkToPay = get(response, 'cart.stripe_link_to_pay')
        if ((response && response.id) || stripeLinkToPay) {
          window.open(stripeLinkToPay)
        }
      },
    },
  )

  return (
    <MarketingComponent
      checkoutCurrentCartMutation={checkoutCurrentCartMutation}
    />
  )
}
