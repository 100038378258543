import { useEffect } from 'react'

import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router'

import { SuggestionChatComponent } from './components'
import { API } from 'core/requests'

type RouteParams = {
  id: string
}

export default function SuggestionChat(props) {
  const { id } = useParams<RouteParams>()

  const suggestionId = id ?? props.suggestionId

  const {
    data: suggestion,
    isLoading: suggestionIsLoading,
    refetch: fetchSuggestion,
  } = useQuery(
    'getSuggestion',
    () => API.get('suggestionsBoxIdeas', suggestionId),
    {
      enabled: !!suggestionId,
      refetchInterval: 60000,
      onSettled: () => {
        const chatBoxElement = window.document.getElementById('chat-box')

        chatBoxElement?.scroll({
          top: chatBoxElement.scrollHeight,
          behavior: 'smooth',
        })
      },
    },
  )

  const sendReplyMutation = useMutation(
    (payload: any) =>
      API.update('replyToSuggestion', {
        id: suggestionId,
        suggestion_box_reply: { body: payload },
      }),
    {
      onSuccess: (response: any) => {
        if (response.id) {
          fetchSuggestion()
        }
      },
    },
  )

  useEffect(() => {
    if (props.refetchSuggestion) {
      fetchSuggestion()
      props.setRefetchSuggestion(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refetchSuggestion])

  return (
    <SuggestionChatComponent
      {...props}
      suggestion={suggestion}
      suggestionIsLoading={suggestionIsLoading}
      sendReplyMutation={sendReplyMutation}
    />
  )
}
