import {
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
} from '@mui/material'
import { Send as SendIcon } from '@mui/icons-material'

import { employeeEmails } from 'constants/emails'
import { t } from 'i18n'
import { EmployeeType } from 'types'
import { useCompany } from 'contexts'

type Props = {
  employee: EmployeeType | null
  handleSendEmail: (endpoint: string, payload: any) => void
}

export default function EmployeesEmails({ employee, handleSendEmail }: Props) {
  const { company } = useCompany()!
  employee = {...employee, company_id: company.id}
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {employeeEmails.map(employeeEmail => (
            <ListItem key={employeeEmail.id}>
              <ListItemText
                primary={t(employeeEmail.name)}
                secondary={t(employeeEmail.description)}
              />
              <ListItemSecondaryAction>
                <IconButton
                  data-cy='send'
                  edge='end'
                  aria-label='send'
                  onClick={() =>
                    handleSendEmail(
                      employeeEmail.endpoint,
                      employeeEmail.getPayload(employee),
                    )
                  }
                  size="large">
                  <SendIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
}
