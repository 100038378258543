import { useState } from 'react'

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material'
import { useIntl } from 'react-intl'

export default function ApprovalResolveDialog({
  id,
  dialog,
  setDialog,
  mutate,
  mutateIsLoading,
  actionLabel,
  actionButtonColor,
  setRefetchApproval,
}) {
  const intl = useIntl()
  const [note, setNote] = useState('')

  const handleCloseDialog = () => setDialog({ isOpen: false, data: null })
  const handleResolve = async () => {
    await mutate({
      id: dialog.data?.id,
      [id === 'accept' ? 'approve_note' : 'declined_reason']: note,
    })
    setRefetchApproval(true)
  }

  return (
    <Dialog open={dialog.isOpen} onClose={handleCloseDialog}>
      <DialogTitle>
        {actionLabel} Approval #{dialog.data?.id}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DialogContentText>
              Are you sure you want to {actionLabel} this Recognition?
            </DialogContentText>
          </Grid>

          <Grid item xs={12}>
            <TextField
              id='note'
              value={note}
              onChange={event => setNote(event.target.value)}
              label={`${actionLabel} Reason`}
              multiline
              minRows={3}
              maxRows={5}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCloseDialog} color='primary'>
          {intl.formatMessage({ id: 'cancel' })}
        </Button>

        <Button
          onClick={handleResolve}
          color={actionButtonColor || 'primary'}
          disabled={mutateIsLoading}
          autoFocus
        >
          {mutateIsLoading ? <CircularProgress size={16} /> : actionLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
