import React, { useState } from 'react'

type CompaniesContextType = {
  companies: any
  setCompanies: (value: any) => void
}
const CompaniesContext = React.createContext<CompaniesContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

export const CompaniesProvider = ({ children }: Props) => {
  const [companies, setCompanies] = useState(undefined)

  return (
    <CompaniesContext.Provider value={{ companies, setCompanies }}>
      {children}
    </CompaniesContext.Provider>
  )
}

export const useCompanies = () => React.useContext(CompaniesContext)
