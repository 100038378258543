import { useMemo } from 'react'

import {
  Box,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { addDays, format, subDays } from 'date-fns'

import { nominationOccurrenceTypes } from 'constants/nomination'
import { NominationFrequency, NominationOccurrenceType } from 'enums/nomination'
import { daysBetweenTwoDates, getDateWithoutTimezone } from 'utils/date'

const currentYear = new Date().getFullYear()

export default function ManageRecognitionNomination({ formik }) {
  const firstDateInPeriod = useMemo(
    () => getDateWithoutTimezone(new Date(`${currentYear}-01-01`)),
    [],
  )

  const lastDateInPeriod = useMemo(() => {
    const lastDateByFrequency = {
      [NominationFrequency.Monthly]: '01-31',
      [NominationFrequency.Quarterly]: '03-31',
      [NominationFrequency.SemiAnnually]: '06-30',
      [NominationFrequency.Annually]: '12-31',
    }

    return getDateWithoutTimezone(
      new Date(
        `${currentYear}-${
          lastDateByFrequency[formik.values.nomination.frequency]
        }`,
      ),
    )
  }, [formik.values.nomination.frequency])

  const daysBetweenFirstAndLastDates = useMemo(() => {
    if (!formik.values.nomination.frequency) return 0

    return daysBetweenTwoDates(firstDateInPeriod, lastDateInPeriod)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.nomination.frequency])

  return (
    <Grid container spacing={2}>
      {formik.values.has_nominations && formik.values.has_recurrence && (
        <>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  select
                  id='occurrence_type'
                  name='occurrence_type'
                  label={'Nomination Period'}
                  variant='outlined'
                  value={formik.values.nomination.occurrence_type}
                  onChange={event =>
                    formik.setFieldValue(
                      'nomination.occurrence_type',
                      event.target.value,
                    )
                  }
                  onBlur={formik.handleBlur}
                  fullWidth
                >
                  {nominationOccurrenceTypes.map(occurrenceType => (
                    <MenuItem
                      key={occurrenceType.value}
                      value={occurrenceType.value}
                    >
                      {occurrenceType.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>

          {formik.values.nomination.occurrence_type ===
            NominationOccurrenceType.Periodical && (
            <Grid item xs={12}>
              <Box
                sx={{
                  borderStyle: 'solid',
                  borderColor: 'text.veryLight',
                  p: 2,
                  borderRadius: 1,
                }}
              >
                <Grid container spacing={2} justifyContent='space-between'>
                  <Grid item xs={12}>
                    <Typography variant='body1' color='text.primary' paragraph>
                      The nomination timeline has been set for{' '}
                      <b>
                        {format(
                          addDays(
                            firstDateInPeriod,
                            +formik.values.nomination.days_prior_start,
                          ),
                          'PPP',
                        )}
                      </b>{' '}
                      to{' '}
                      <b>
                        {format(
                          subDays(
                            lastDateInPeriod,
                            +formik.values.nomination.days_upon_finish,
                          ),
                          'PPP',
                        )}
                      </b>
                      . You can customize the nomination open and end dates
                      within this period of time. The timeframe will update
                      automatically as you adjust the days.
                    </Typography>
                  </Grid>

                  {formik.values.nomination.occurrence_type ===
                    NominationOccurrenceType.Periodical && (
                    <>
                      <Grid item xs={12} md={6}>
                        <TextField
                          type='number'
                          id='days_prior_start'
                          name='days_prior_start'
                          label={'Nomination Open'}
                          variant='outlined'
                          value={formik.values.nomination.days_prior_start}
                          onChange={event =>
                            formik.setFieldValue(
                              'nomination.days_prior_start',
                              event.target.value,
                            )
                          }
                          onBlur={formik.handleBlur}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                days
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          type='number'
                          id='days_upon_finish'
                          name='days_upon_finish'
                          label={'Nomination End'}
                          variant='outlined'
                          value={formik.values.nomination.days_upon_finish}
                          onChange={event =>
                            formik.setFieldValue(
                              'nomination.days_upon_finish',
                              event.target.value,
                            )
                          }
                          onBlur={formik.handleBlur}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                days
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            position: 'relative',
                            height: '.5em',
                            bgcolor: 'text.veryLight',
                            width: 1,
                            borderRadius: 1,
                          }}
                        >
                          <Box
                            sx={{
                              position: 'absolute',
                              bgcolor: 'primary.light',
                              height: '150%',
                              borderRadius: 1,
                              left: `${
                                Math.floor(
                                  (100 *
                                    formik.values.nomination.days_prior_start) /
                                    daysBetweenFirstAndLastDates,
                                ) || 0
                              }%`,
                              right: `${
                                Math.floor(
                                  (100 *
                                    formik.values.nomination.days_upon_finish) /
                                    daysBetweenFirstAndLastDates,
                                ) || 0
                              }%`,
                              bottom: 0,
                              top: '-2px',
                              transition: 'right 0.5s, left 0.5s',
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      sx={{
                        mt: 1,
                        justifyContent: { xs: 'center', sm: 'space-between' },
                      }}
                    >
                      <Grid
                        item
                        xs='auto'
                        sx={{ display: { xs: 'none', sm: 'inherit' } }}
                      >
                        {format(firstDateInPeriod, 'PPP')}
                      </Grid>

                      <Grid item xs='auto'>
                        {daysBetweenFirstAndLastDates -
                          formik.values.nomination.days_prior_start -
                          formik.values.nomination.days_upon_finish}{' '}
                        days
                      </Grid>

                      <Grid
                        item
                        xs='auto'
                        sx={{ display: { xs: 'none', sm: 'inherit' } }}
                      >
                        {format(lastDateInPeriod, 'PPP')}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
        </>
      )}
    </Grid>
  )
}
