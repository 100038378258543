import { useState } from 'react'

import { Grid, Paper, useMediaQuery, Theme } from '@mui/material'

import {
  ProfileBackground,
  ProfileChangePicture,
  ProfileHeader,
  ProfileTabs,
} from '../../components'
import {
  PROFILE_BACKGROUND_HEIGHT_DESKTOP,
  PROFILE_BACKGROUND_HEIGHT_MOBILE,
} from 'constants/app'

export default function ProfileComponent(props: any) {
  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('md'),
    {
      defaultMatches: true,
    },
  )

  const [tab, setTab] = useState(props.tabIndex)
  const [editMode, setEditMode] = useState(false)

  const profileBackgroundHeight = isMobile
    ? PROFILE_BACKGROUND_HEIGHT_MOBILE
    : PROFILE_BACKGROUND_HEIGHT_DESKTOP

  return (
    <Grid container>
      <Grid item xs={12}>
        <ProfileBackground profileBackgroundHeight={profileBackgroundHeight} />
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          zIndex: 999,
          marginTop: `${
            profileBackgroundHeight - profileBackgroundHeight * 0.16
          }px`,
        }}
      >
        <Grid container justifyContent='center'>
          <Grid item xs={11} lg={10} style={{ maxWidth: '1440px' }}>
            <Paper sx={{ bgcolor: tab === 0 ? 'inherit' : 'background.paper' }}>
              <ProfileHeader
                {...props}
                editMode={editMode}
                setEditMode={setEditMode}
              />
              <Grid container>
                <Grid item xs={12}>
                  <ProfileTabs
                    {...props}
                    tab={tab}
                    setTab={setTab}
                    editMode={editMode}
                    setEditMode={setEditMode}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <ProfileChangePicture {...props} />
    </Grid>
  )
}
