import { get } from 'lodash'
import { useTheme } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from 'react-query'

import { ShoppingCartComponent } from './components'
import { useShoppingCartItemsCount } from 'contexts'
import { API } from 'core/requests'
import { t } from 'i18n'

export default function ShoppingCart() {
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const { setShoppingCartItemsCount } = useShoppingCartItemsCount()!

  const { data: shoppingCart, refetch: fetchShoppingCart } = useQuery(
    'getShoppingCart',
    () => API.get('shoppingCart'),
    {
      enabled: true,
      refetchOnWindowFocus: true,
    },
  )

  const totalAmount = shoppingCart?.cart?.items
    .map(item => Number(item.product.amount))
    .reduce((acc, item) => acc + item, 0)

  const checkoutCurrentCartMutation = useMutation(
    () => API.create('shoppingCartCheckout'),
    {
      onSettled: (response: any) => {
        const stripeLinkToPay = get(response, 'cart.stripe_link_to_pay')

        if ((response && response.id) || stripeLinkToPay) {
          window.open(stripeLinkToPay)
        } else {
          enqueueSnackbar(response.message, { variant: 'error' })
        }
      },
    },
  )

  const removeItemFromCartMutation = useMutation(
    (payload: any) =>
      API.delete('shoppingCartRemoveItem', null, {
        queryParams: payload,
      }),
    {
      onSettled: (response: any) => {
        if ((response && response.id) || (response && response.success)) {
          enqueueSnackbar(t('shoppingCart.snackbar.success.itemRemoved'), {
            variant: 'success',
          })
          setShoppingCartItemsCount(state => state - 1)
          fetchShoppingCart()
        } else if (response && response.status === 'conflict') {
          enqueueSnackbar(t('shoppingCart.snackbar.error.couldNotRemoveItem'), {
            variant: 'error',
          })
        }
      },
    },
  )

  const handleProceedToCheckout = () => {
    checkoutCurrentCartMutation.mutate()
  }

  const handleRemoveItemFromCart = ({
    object_name_to_upgrade,
    object_id_to_upgrade,
  }) => {
    removeItemFromCartMutation.mutate({
      object_name_to_upgrade,
      object_id_to_upgrade,
    })
  }

  return (
    <ShoppingCartComponent
      shoppingCart={shoppingCart}
      theme={theme}
      handleProceedToCheckout={handleProceedToCheckout}
      handleRemoveItemFromCart={handleRemoveItemFromCart}
      totalAmount={totalAmount}
    />
  )
}
