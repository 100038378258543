export enum ViewMode {
  Total = 'total',
  Received = 'total_received',
  Sent = 'total_sent',
}

export enum ActivityType {
  Team = 'team',
  Manager = 'manager',
  Custom = 'custom',
}
