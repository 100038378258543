import { useState } from 'react'

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material'
import { Link, useParams } from 'react-router-dom'

import { ManagerCertificationQuizDialog } from '..'
import {
  EmployeeRecognitionCertificationContentSection,
  employeeRecognitionCertificationContent,
  DownloadData,
  QuizData,
  RedirectData,
  TaskType,
  VideoData,
} from 'constants/managerCertification'
import { t } from 'i18n'
import { ROUTES_PATHS } from 'core/routes'

type TaskParams = {
  taskId: string
}

enum SectionStatus {
  Pending = 'pending',
  Completed = 'completed',
}

export default function ManagerCertificationTaskComponent(props: any) {
  const { userCertification } = props

  const { taskId } = useParams<TaskParams>()

  const getTaskContentByType = {
    [TaskType.DownloadAssets]: ManagerCertificationTaskDownloadAssetsContent,
    [TaskType.Quiz]: ManagerCertificationTaskQuizContent,
    [TaskType.Redirect]: ManagerCertificationTaskRedirectContent,
    [TaskType.Video]: ManagerCertificationTaskVideoContent,
  }

  const currentSection = employeeRecognitionCertificationContent.roadmap.find(
    section => section.id === +taskId,
  )!

  const isSectionCompleted =
    userCertification?.certification_state?.sections?.find(
      section => section.id === currentSection?.id,
    ).status === SectionStatus.Completed

  return (
    <Grid container spacing={2}>
      <ManagerCertificationTaskTitle currentSection={currentSection} />
      <ManagerCertificationTaskDescription currentSection={currentSection} />
      <ManagerCertificationTaskSubsections
        {...props}
        currentSection={currentSection}
        getTaskContentByType={getTaskContentByType}
        isSectionCompleted={isSectionCompleted}
      />
    </Grid>
  )
}

function ManagerCertificationTaskTitle({ currentSection }) {
  return (
    <Grid item xs={12}>
      <Typography color='text.primary' variant='h4' fontWeight={600} paragraph>
        {t('managerCertification.managerCertificationTask.day')}{' '}
        {currentSection.id}: {currentSection.title}
      </Typography>
    </Grid>
  )
}

function ManagerCertificationTaskDescription({ currentSection }) {
  return (
    <Grid item xs={12}>
      <Typography variant='h6' color='text.secondary' paragraph>
        {currentSection.description}
      </Typography>
    </Grid>
  )
}

function ManagerCertificationTaskSubsections(props: {
  currentSection: EmployeeRecognitionCertificationContentSection
  getTaskContentByType: any
  isSectionCompleted: boolean
}) {
  const { currentSection, getTaskContentByType } = props

  return (
    <Grid item xs={12}>
      {currentSection.subsections.map(subsection => (
        <Grid key={subsection.id} container spacing={2} sx={{ mb: 5 }}>
          <Grid item xs={12}>
            <Typography color='text.primary' variant='h5' fontWeight={600}>
              {subsection.title}
            </Typography>
          </Grid>

          {subsection.description && (
            <Grid item xs={12}>
              <Typography color='text.secondary' variant='h6' sx={{ mb: 1 }}>
                {subsection.description}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            {subsection.tasks.map(task => {
              const TaskContent = getTaskContentByType[task.type]

              return (
                <Accordion key={task.id}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${task.id}-content`}
                    id={`${task.id}-header`}
                    sx={{ py: 1 }}
                  >
                    <Typography
                      variant='h6'
                      color='text.primary'
                      fontWeight={600}
                    >
                      {task.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {task.description && (
                        <Grid item xs={12}>
                          <Typography variant='body1' color='text.secondary'>
                            {task.description}
                          </Typography>
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <TaskContent {...props} data={task.data} />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

function ManagerCertificationTaskDownloadAssetsContent({
  data,
}: {
  data: DownloadData
}) {
  return (
    <>
      {data.map(downloadData => (
        <Typography
          key={downloadData.href}
          variant='h6'
          color='text.primary'
          paragraph
        >
          {t('managerCertification.managerCertificationTask.clickForResource', {
            link: (
              <Box
                component='a'
                sx={{ color: 'primary.main' }}
                href={downloadData.href}
                rel='noopener'
                target='_blank'
              >
                {t('linkHere')}
              </Box>
            ),
            name: downloadData.label,
          })}
        </Typography>
      ))}
    </>
  )
}

function ManagerCertificationTaskQuizContent(props: {
  data: QuizData
  currentSection: EmployeeRecognitionCertificationContentSection
  isSectionCompleted: boolean
}) {
  const { currentSection, data, isSectionCompleted } = props

  const [quizDialogOpen, setQuizDialogOpen] = useState(false)

  return (
    <>
      <Button
        variant='contained'
        color='primary'
        size='large'
        onClick={() => setQuizDialogOpen(true)}
        disabled={isSectionCompleted}
      >
        {t('managerCertification.managerCertificationTask.takeTheQuiz')}
      </Button>

      <ManagerCertificationQuizDialog
        {...props}
        sectionId={currentSection.id}
        dialogOpen={quizDialogOpen}
        setDialogOpen={setQuizDialogOpen}
        questions={data.questions}
      />
    </>
  )
}

function ManagerCertificationTaskRedirectContent({
  data,
}: {
  data: RedirectData
}) {
  return (
    <Link to={ROUTES_PATHS[data.pathString]}>
      {t('managerCertification.managerCertificationTask.takeMeToTheChallenge')}
    </Link>
  )
}

function ManagerCertificationTaskVideoContent({ data }: { data: VideoData }) {
  return (
    <Grid container justifyContent='center'>
      {data.scriptPDF && (
        <Grid item xs={12}>
          <Typography color='text.secondary' paragraph fontSize={18}>
            {t(
              'managerCertification.managerCertificationTask.clickForVideoScript',
              {
                link: (
                  <Box
                    component='a'
                    sx={{ color: 'primary.main' }}
                    href={data.scriptPDF}
                    rel='noopener'
                    target='_blank'
                  >
                    {t('linkHere')}
                  </Box>
                ),
              },
            )}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12} md={9} lg={8}>
        <video width='100%' height='480px' controls>
          <source src={data.src} type='video/mp4' />
        </video>
      </Grid>
    </Grid>
  )
}
