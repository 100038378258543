import { Grid, TextField, Typography } from '@mui/material'
import { useIntl } from 'react-intl'

import { RatingSectionItems } from '../../components'
import { SurveyQuestionCardProps } from '../SurveyQuestionCard/SurveyQuestionCard'
import {
  SurveyQuestionScoreType,
  SurveyQuestionType as SurveyQuestionTypeEnum,
} from 'enums/survey'
import { t } from 'i18n'
import { getCurrentQuestionId } from 'utils/survey'

export default function RatingSection(props: SurveyQuestionCardProps) {
  const { surveyQuestion, surveyQuestions, setSurveyQuestions, isMobile } =
    props

  const intl = useIntl()

  const handleRating = (
    value: number | string,
    accessor: SurveyQuestionScoreType,
  ) => {
    surveyQuestion[accessor] = value
    setSurveyQuestions([...surveyQuestions])

    if (accessor === SurveyQuestionScoreType.ImportanceScore && value !== 0) {
      window.scrollBy(0, 176)
    }
  }

  return (
    <Grid container direction='column' justifyContent='flex-end'>
      {surveyQuestion.type !== SurveyQuestionTypeEnum.OpenEnded ? (
        <>
          <Grid item>
            {getCurrentQuestionId(surveyQuestions) === surveyQuestion.id && (
              <Typography
                variant='body2'
                color='text.dark'
                fontWeight={500}
                align={isMobile ? 'center' : 'right'}
                paragraph
              >
                {!surveyQuestion.satisfactionScore
                  ? t('ratingSection.toWhatExtent')
                  : t('ratingSection.important')}
              </Typography>
            )}
          </Grid>
          <Grid
            data-cy='rating_section'
            container
            justifyContent={isMobile ? 'center' : 'flex-end'}
            alignItems='center'
          >
            <RatingSectionItems
              {...props}
              handleRating={handleRating}
              isMobile={isMobile}
            />
          </Grid>
        </>
      ) : (
        <TextField
          data-cy='open_ended_answer'
          id={`answer-${surveyQuestion.id}`}
          label={t('ratingSection.formLabels.textField')}
          placeholder={intl.formatMessage({
            id: 'ratingSection.formLabels.placeholder',
          })}
          variant='outlined'
          multiline
          maxRows={3}
          value={surveyQuestion.openEndedAnswer}
          onChange={e =>
            handleRating(
              e.target.value,
              SurveyQuestionScoreType.OpenEndedAnswer,
            )
          }
          helperText={t('ratingSection.formLabels.helperText')}
        />
      )}
    </Grid>
  )
}
