import { useMemo } from 'react'

import { formatRelative, parseISO } from 'date-fns'
import { get } from 'lodash'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Slide,
  Typography,
  useTheme,
  Zoom,
} from '@mui/material'
import { Skeleton } from '@mui/material'
import parse from 'html-react-parser'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { SparckDisplayAnswer, SparckDisplayRewards } from '..'
import sparckWaveTeal from 'assets/images/waves/wave-02.png'
import sparckLogo from 'assets/images/sparck-logo-colored.png'
import sparckLogoTransparent from 'assets/images/sparck-logo-transparent.png'
import { MediaDisplay, UserAvatar } from 'components'
import { useUser } from 'contexts'
import { ROUTES_PATHS } from 'core/routes'
import { mentionsToPlainText } from 'utils/mentionsParser'
import { getRecognitionMedia } from 'utils/recognition'

const LOGO_SIZE = 100
const AVATAR_SIZE = 120

export default function SparckDisplayComponent(props) {
  const {
    sparck,
    isLoading,
    pageId,
    visibleUserID,
    userNotAuthorizedToViewSparck,
  } = props

  const { user } = useUser()!
  const theme = useTheme()
  const intl = useIntl()
  const history = useHistory()

  const sparckMedia = useMemo(() => getRecognitionMedia(sparck), [sparck])

  const usersRecognized = get(sparck, 'users_recognized', [])
  const loggedUserWasRecognizedHere = usersRecognized?.find(
    u => u.id === user.id,
  )
  const visibleUserWasRecognizedHere = usersRecognized?.find(
    u => u.id === +visibleUserID,
  )

  const selectedUser = visibleUserWasRecognizedHere
    ? visibleUserWasRecognizedHere
    : loggedUserWasRecognizedHere
    ? loggedUserWasRecognizedHere
    : usersRecognized[0]

  const selectedUserIsLoggedUser = selectedUser?.id === user?.id

  const formattedSparckMessage = mentionsToPlainText(sparck.message)

  const redirectToDashboard = () => history.push(ROUTES_PATHS.dashboard)

  if (userNotAuthorizedToViewSparck) {
    return (
      <Grid container justifyContent='center'>
        <Grid item xs={12} sm={9} md={8} lg={5} xl={4}>
          <Paper sx={{ p: 3, mt: 5, width: 1 }}>
            <Grid container spacing={2} justifyContent='center'>
              <Grid item xs={12}>
                <Typography
                  component='h1'
                  variant='h6'
                  fontWeight={500}
                  align='center'
                >
                  {intl.formatMessage({
                    id: 'sparcks.sparckDisplayComponent.noAuthorizationToViewSparck',
                  })}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent='center'>
                  <Button
                    variant='contained'
                    color='primary'
                    size='large'
                    onClick={redirectToDashboard}
                  >
                    {intl.formatMessage({
                      id: 'sparcks.sparckDisplayComponent.returnToDashboard',
                    })}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          component='img'
          sx={{
            width: '100%',
            maxHeight: '200px',
            minHeight: '180px',
          }}
          src={sparckWaveTeal}
          alt='Sparck teal wave background'
        />
      </Grid>

      <Grid item xs={12}>
        <Zoom in={!isLoading} timeout={1000}>
          <Grid container justifyContent='center'>
            <Paper
              elevation={4}
              style={{
                borderRadius: '100%',
                position: 'relative',
                marginTop: '-120px',
              }}
            >
              <Box width={1} p={3}>
                {isLoading ? (
                  <Grid container justifyContent='center'>
                    <CircularProgress size={LOGO_SIZE} />
                  </Grid>
                ) : (
                  <Box
                    component='img'
                    sx={{
                      width: `${LOGO_SIZE}px`,
                      height: `${LOGO_SIZE}px`,
                    }}
                    src={sparckLogo}
                    alt='Sparck logo'
                  />
                )}
              </Box>
            </Paper>
          </Grid>
        </Zoom>
      </Grid>

      <Grid item xs={12}>
        <Zoom in={!isLoading} timeout={1000}>
          <Box width={1} my={3} mb={3}>
            <Typography
              variant='h2'
              color='text.primary'
              align='center'
              fontWeight={700}
              paragraph
              style={{ fontSize: 42 }}
            >
              {isLoading ? (
                <Grid container justifyContent='center'>
                  <Skeleton width={240} />
                </Grid>
              ) : (
                `${
                  selectedUserIsLoggedUser
                    ? intl.formatMessage({
                        id: 'sparcks.sparckDisplayComponent.youHave',
                      })
                    : `${selectedUser?.full_name} ${intl.formatMessage({
                        id: 'sparcks.sparckDisplayComponent.has',
                      })}`
                } ${intl.formatMessage({
                  id: 'sparcks.sparckDisplayComponent.beenRecognized',
                })}`
              )}
            </Typography>

            <Typography
              variant='h5'
              color={'#A9B7BD'}
              align='center'
              fontWeight={500}
              paragraph
            >
              {isLoading ? (
                <Grid container justifyContent='center'>
                  <Skeleton width={200} />
                </Grid>
              ) : (
                `${intl.formatMessage({
                  id: 'sparcks.sparckDisplayComponent.weAreSoHappy',
                })}${
                  selectedUserIsLoggedUser
                    ? intl.formatMessage({
                        id: 'sparcks.sparckDisplayComponent.forYou',
                      })
                    : ''
                }. ${intl.formatMessage({
                  id: 'sparcks.sparckDisplayComponent.congratulations',
                })}`
              )}
            </Typography>
          </Box>
        </Zoom>
      </Grid>

      <Grid item xs={12}>
        {selectedUserIsLoggedUser && pageId && (
          <SparckDisplayRewards {...props} />
        )}

        <Grid item xs={12}>
          <Grid
            container
            justifyContent='center'
            style={{
              background: `linear-gradient(270deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.dark} 100%)`,
              marginTop: 40,
              position: 'relative',
            }}
          >
            <Grid
              item
              xs={11}
              sm={10}
              md={9}
              lg={7}
              xl={6}
              style={{ zIndex: 999 }}
            >
              <Slide direction='up' in={!isLoading} timeout={1200}>
                <Paper
                  square
                  elevation={4}
                  style={{
                    marginTop: '-40px',
                    marginBottom: '-40px',
                    backgroundColor: '#FFFDF6',
                  }}
                >
                  <Box px={5} py={4}>
                    <Grid container justifyContent='center' direction='column'>
                      <Grid item xs={12}>
                        <Grid container justifyContent='center'>
                          <Box mb={4}>
                            {isLoading ? (
                              <Skeleton
                                variant='circular'
                                width={AVATAR_SIZE}
                                height={AVATAR_SIZE}
                              />
                            ) : (
                              <UserAvatar
                                userId={get(sparck, 'user_recognizing.id')}
                                name={get(sparck, 'user_recognizing.name')}
                                src={get(sparck, 'user_recognizing.avatar.url')}
                                size={AVATAR_SIZE}
                              />
                            )}
                          </Box>
                        </Grid>
                      </Grid>

                      <Typography
                        variant='h4'
                        color='text.primary'
                        fontWeight={700}
                        align='center'
                      >
                        {isLoading ? (
                          <Skeleton width='80%' />
                        ) : (
                          <>
                            {intl.formatMessage(
                              {
                                id: 'sparcks.sparckDisplayComponent.messageFrom',
                              },
                              {
                                private: !sparck.has_public_access && (
                                  <Typography
                                    component='span'
                                    variant='h4'
                                    color='primary.main'
                                    fontWeight={700}
                                  >
                                    {intl.formatMessage({
                                      id: 'sparcks.sparckDisplayComponent.private',
                                    })}
                                  </Typography>
                                ),
                              },
                            )}{' '}
                            {sparck.user_recognizing?.name ??
                              sparck.user_recognizing?.first_name}
                          </>
                        )}
                      </Typography>

                      <Box width={1} mt={1}>
                        <Typography
                          variant='h6'
                          color='text.secondary'
                          fontWeight={500}
                          align='center'
                          paragraph
                        >
                          {isLoading ? (
                            <Skeleton width='50%' />
                          ) : (
                            <>
                              {intl.formatMessage({
                                id: 'for',
                              })}{' '}
                              <Typography
                                component='span'
                                variant='h6'
                                color='text.secondary'
                                fontWeight={600}
                              >
                                {get(sparck, 'event.name')}
                              </Typography>
                            </>
                          )}
                        </Typography>
                      </Box>

                      <Box mb={1}>
                        <Typography
                          variant='body2'
                          color='text.secondary'
                          align='center'
                        >
                          {isLoading ? (
                            <Skeleton width={120} />
                          ) : (
                            formatRelative(
                              sparck.created_at
                                ? parseISO(sparck.created_at)
                                : new Date(),
                              new Date(),
                            )
                          )}
                        </Typography>
                      </Box>

                      <Box width={1} mt={5}>
                        <Typography
                          variant='body1'
                          color='text.primary'
                          align='justify'
                        >
                          {isLoading ? (
                            <>
                              <Skeleton width={'100%'} />
                              <Skeleton width={'100%'} />
                              <Skeleton width={'70%'} />
                            </>
                          ) : (
                            parse(formattedSparckMessage)
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                  </Box>
                  <Box width={1} p={3} sx={{ aspectRatio: '16/9' }}>
                    {sparckMedia && <MediaDisplay media={sparckMedia} />}
                  </Box>
                </Paper>
              </Slide>
            </Grid>

            <img
              src={sparckLogoTransparent}
              alt='Sparck logo transparent'
              style={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                height: '50%',
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      {selectedUserIsLoggedUser && pageId && <SparckDisplayAnswer {...props} />}
    </Grid>
  )
}
