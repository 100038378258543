import { Grid, Typography } from '@mui/material'
import { uniqBy } from 'lodash'
import { t } from 'i18n'
import { useIntl } from 'react-intl'

import { CRUDTable } from 'components'

export default function ManageEmployeeRolesAndSupervisors({
  selectedSuperiors,
  setSelectedSuperiors,
  employees,
  roles,
  selectedRoles,
  setSelectedRoles,
}) {
  const intl = useIntl()

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CRUDTable
            rows={selectedSuperiors}
            setRows={setSelectedSuperiors}
            columns={[
              {
                id: 'full_name',
                label: t('name'),
                type: 'text',
                align: 'left',
                required: true,
                disabled: () => true,
                showEdit: () => false,
                defaultValue: '',
              },
              {
                id: 'title',
                label: t('employees.fields.title'),
                type: 'text',
                align: 'left',
                required: true,
                disabled: () => true,
                showEdit: () => false,
                defaultValue: '',
              },
              {
                id: 'department',
                label: t('department'),
                type: 'text',
                align: 'left',
                required: true,
                disabled: () => true,
                showEdit: () => false,
                defaultValue: '',
              },
            ]}
            limit={30}
            showEdit={() => false}
            showRemove={() => true}
            libraryProps={{
              title: intl.formatMessage({ id: 'employees.fields.supervisors' }),
              rows: employees ?? [],
              columns: [
                {
                  id: 'full_name',
                  label: t('name'),
                },
                {
                  id: 'title',
                  label: t('employees.fields.title'),
                },
                {
                  id: 'department',
                  label: t('department'),
                },
              ],
              orderBy: 'full_name',
              selectedIds: selectedSuperiors.map(row => row.superior_id),
              onSelectClick: selected => {
                setSelectedSuperiors(
                  uniqBy(
                    [
                      ...selectedSuperiors,
                      ...selected.map(selectedSupervisor => ({
                        superior_id: selectedSupervisor.id,
                        full_name: selectedSupervisor.full_name,
                        title: selectedSupervisor.title,
                        department: selectedSupervisor.department,
                        _destroy: false,
                      })),
                    ],
                    'superior_id',
                  ),
                )
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <CRUDTable
            rows={selectedRoles}
            setRows={setSelectedRoles}
            columns={[
              {
                id: 'name',
                label: t('name'),
                type: 'text',
                align: 'left',
                required: true,
                disabled: () => true,
                showEdit: () => false,
                defaultValue: '',
              },
            ]}
            limit={30}
            showEdit={() => false}
            showRemove={() => true}
            libraryProps={{
              title: intl.formatMessage({ id: 'employees.fields.roles' }),
              rows: roles ?? [],
              columns: [
                {
                  id: 'name',
                  label: t('name'),
                },
              ],
              orderBy: 'full_name',
              selectedIds: selectedRoles.map(row => row.role_id),
              onSelectClick: selected => {
                setSelectedRoles(
                  uniqBy(
                    [
                      ...selectedRoles,
                      ...selected.map(selectedRole => ({
                        role_id: selectedRole.id,
                        name: selectedRole.name,
                        _destroy: false,
                      })),
                    ],
                    'role_id',
                  ),
                )
              },
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant='body1' color='text.secondary'>
            {t('employees.employeeRoleDisclaimer')}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}
