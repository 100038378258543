import {
  Box,
  Card,
  CardActionArea,
  Grid,
  Rating,
  Typography,
  useTheme,
} from '@mui/material'
import withStyles from '@mui/styles/withStyles'

import { getUniqueColor } from 'types/colors'
import { getRewardName } from 'utils/reward'

const WhiteRating = withStyles({
  iconFilled: {
    color: '#FFF',
  },
})(Rating)

type Props = {
  reward: any
  onClick?: (reward: any) => any
  cardSx?: any
}

export default function OrganizationalRewardCard({
  reward,
  onClick,
  cardSx,
}: Props) {
  const theme = useTheme()

  const rewardBackgroundColor = getUniqueColor(theme, {
    id: reward?.id,
    isGradient: true,
  })

  return (
    <Card
      key={`${reward.id}`}
      sx={{
        position: 'relative',
        overflow: 'inherit',
        height: 1,
        background: rewardBackgroundColor,
        ...(cardSx && {
          ...cardSx,
        }),
      }}
    >
      <CardActionArea
        onClick={() => onClick && onClick(reward)}
        sx={{ height: 1, borderRadius: 4 }}
      >
        <Box p={2} width={1} height={1} position='relative'>
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            style={{ height: '100%' }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant='body1'
                  fontWeight={500}
                  color='text.white'
                  align='center'
                  sx={{ mb: 0.5 }}
                >
                  {getRewardName(reward)}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent='center'>
                  <WhiteRating
                    name={getRewardName(reward)}
                    value={reward?.onboarding_answer?.satisfaction}
                    readOnly
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </CardActionArea>
    </Card>
  )
}
