import { useEffect, useState } from 'react'
import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import { useMutation } from 'react-query'

import { SettingsComponent } from './components'
import { useCompany } from 'contexts/CompanyContext'
import { API } from 'core/requests'
import { t } from 'i18n'

export default function Settings() {
  const { enqueueSnackbar } = useSnackbar()
  const { company, setCompany } = useCompany()!

  const [companyState, setCompanyState] = useState<any>({})

  const [
    dialogRemoveSlackIntegrationOpen,
    setDialogRemoveSlackIntegrationOpen,
  ] = useState(false)
  const [
    dialogRemoveMSTeamsIntegrationOpen,
    setDialogRemoveMSTeamsIntegrationOpen,
  ] = useState(false)

  const saveSettingsMutation = useMutation(
    (payload: any) => {
      const companyId = get(company, 'id') ?? get(payload, 'id')

      return API.update('companies', payload, {
        pathParams: {
          id: companyId ? companyId : null,
        },
      })
    },
    {
      onSettled: (response: any) => {
        if (response && response?.company?.id) {
          enqueueSnackbar(t('settings.snackbarSuccess.saved'), {
            variant: 'success',
          })

          setCompany(response?.company)
        }
      },
    },
  )

  const { mutate: enableNominations } = useMutation(
    () => API.create('nominationsOrganizations'),
    {
      onSuccess: (response: any) => {
        if (response.success) {
          setCompanyState({ ...companyState, nominations_enabled: true })
        }
      },
    },
  )

  const { mutate: disableNominations } = useMutation(
    (payload: any) => {
      return API.delete('nominationsOrganizations', payload)
    },
    {
      onSuccess: (response: any) => {
        if (response.success) {
          setCompanyState({ ...companyState, nominations_enabled: false })
        }
      },
    },
  )

  const {
    mutate: removeSlackIntegration,
    isLoading: removeSlackIntegrationIsLoading,
  } = useMutation(
    () =>
      API.delete('removeSlackIntegration', null, {
        queryParams: { company_id: company?.id },
      }),
    {
      onSuccess: () => {
        setDialogRemoveSlackIntegrationOpen(false)
        saveSettingsMutation.mutate(companyState)
      },
    },
  )

  const {
    mutate: removeMSTeamsIntegration,
    isLoading: removeMSTeamsIntegrationIsLoading,
  } = useMutation(
    () =>
      API.delete('removeMSTeamsIntegration', null, {
        queryParams: { company_id: company?.id },
      }),
    {
      onSuccess: () => {
        setDialogRemoveMSTeamsIntegrationOpen(false)
        saveSettingsMutation.mutate(companyState)
      },
    },
  )

  const handleSaveSettings = () => {
    saveSettingsMutation.mutate(companyState)

    if (companyState.nominations_enabled) {
      enableNominations()
    } else {
      disableNominations(company?.id)
    }
  }

  useEffect(() => {
    if (company) {
      setCompanyState({ ...company })
    }
  }, [company])

  return (
    <SettingsComponent
      companyState={companyState}
      setCompanyState={setCompanyState}
      handleSaveSettings={handleSaveSettings}
      saveSettings={saveSettingsMutation.mutate}
      saveSettingsIsLoading={saveSettingsMutation.isLoading}
      removeSlackIntegration={removeSlackIntegration}
      removeSlackIntegrationIsLoading={removeSlackIntegrationIsLoading}
      dialogRemoveSlackIntegrationOpen={dialogRemoveSlackIntegrationOpen}
      setDialogRemoveSlackIntegrationOpen={setDialogRemoveSlackIntegrationOpen}
      removeMSTeamsIntegration={removeMSTeamsIntegration}
      removeMSTeamsIntegrationIsLoading={removeMSTeamsIntegrationIsLoading}
      dialogRemoveMSTeamsIntegrationOpen={dialogRemoveMSTeamsIntegrationOpen}
      setDialogRemoveMSTeamsIntegrationOpen={
        setDialogRemoveMSTeamsIntegrationOpen
      }
    />
  )
}
