import { useQuery } from 'react-query'

import { ManagerCertificationComponent } from './components'
import { employeeRecognitionCertificationContent } from 'constants/managerCertification'
import { API } from 'core/requests'

export default function ManagerCertification() {
  const { data: userCertification, refetch: fetchUserCertification } = useQuery(
    'getUserCertificationData',
    () => API.get('employeeRecognitionCertification'),
    { enabled: true },
  )

  const {
    data: userCertificationBoards,
    refetch: fetchUserCertificationBoards,
  } = useQuery(
    'userCertificationBoards',
    () => API.get('employeeRecognitionCertificationBoards'),
    { enabled: true },
  )

  return (
    <ManagerCertificationComponent
      roadmap={employeeRecognitionCertificationContent.roadmap}
      userCertificationBoards={userCertificationBoards}
      fetchUserCertificationBoards={fetchUserCertificationBoards}
      userCertification={userCertification}
      fetchUserCertification={fetchUserCertification}
    />
  )
}
