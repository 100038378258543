import { SidebarGroup } from 'enums/sidebar'
import React, { useState } from 'react'

type SidebarExpandedContextType = {
  sidebarExpanded: SidebarGroup[]
  setSidebarExpanded: (value: SidebarGroup[]) => void
}
const SidebarExpandedContext = React.createContext<
  SidebarExpandedContextType | undefined
>(undefined)

type Props = {
  children: React.ReactNode
}

export const SidebarExpandedProvider = ({ children }: Props) => {
  const [sidebarExpanded, setSidebarExpanded] = useState<SidebarGroup[]>([
    SidebarGroup.Sparck,
    SidebarGroup.General,
    SidebarGroup.Management,
  ])

  return (
    <SidebarExpandedContext.Provider
      value={{ sidebarExpanded, setSidebarExpanded }}
    >
      {children}
    </SidebarExpandedContext.Provider>
  )
}

export const useSidebarExpanded = () => React.useContext(SidebarExpandedContext)
