import { Box, Button, CircularProgress, Grid } from '@mui/material'

import { t } from 'i18n'

export default function BeheardReportUnlockButton({
  addSurveyToCart,
  addSurveyToCartIsLoading,
}) {
  return (
    <Grid container justifyContent='flex-end'>
      <Grid item>
        <Box my={3}>
          <Button
            color='primary'
            onClick={addSurveyToCart}
            disabled={addSurveyToCartIsLoading}
          >
            {t('beheardReport.unlockPremiumReport')}{' '}
            {addSurveyToCartIsLoading && <CircularProgress size={24} />}
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}
