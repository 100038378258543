import { useState } from 'react'

import { useSnackbar } from 'notistack'
import { useMutation } from 'react-query'

import { SurveyLandingComponent } from './components'
import {
  useFetchSurveyParticipation,
  useFetchSurveyRewardsIntentions,
} from './SurveyLanding.queries'
import { API } from 'core/requests'
import { SurveyStatus } from 'enums/survey'
import { t } from 'i18n'
import { DialogData } from 'types'

export default function SurveyLanding() {
  const { enqueueSnackbar } = useSnackbar()

  const [dialogReward, setDialogReward] = useState<DialogData>({
    isOpen: false,
    data: null,
  })

  const {
    isLoading: isSurveyParticipationLoading,
    data: surveyParticipations,
  } = useFetchSurveyParticipation()

  const {
    data: surveyRewardsIntentions,
    isLoading: isSurveyRewardsIntentionsLoading,
    refetch: fetchSurveyRewardsIntentions,
  } = useFetchSurveyRewardsIntentions()

  const acceptRewardMutation = useMutation(
    (payload: any) => API.update('acceptSurveyRewardIntention', payload),
    {
      onSettled: (response: any) => {
        if (response && response.id) {
          fetchSurveyRewardsIntentions()
          setDialogReward({ isOpen: false, data: null })

          enqueueSnackbar(t('surveyLanding.giftCardRedeemed'), {
            variant: 'success',
          })
        }
      },
    },
  )

  const openedSurvey = (surveyParticipations ?? [])
    .map(surveyParticipations => surveyParticipations.survey)
    .find(survey => survey.status === SurveyStatus.Open)

  const openedSurveyAllowAnswersToBeUpdated =
    openedSurvey && openedSurvey.allow_answers_to_be_updated

  const userTookOpenedSurvey =
    openedSurvey &&
    (surveyParticipations ?? []).find(
      participation => participation.survey.id === openedSurvey.id,
    ).status === 'finished'

  const userAllowedToTakeOpenedSurvey =
    openedSurvey &&
    (openedSurveyAllowAnswersToBeUpdated || !userTookOpenedSurvey)

  return (
    <SurveyLandingComponent
      openedSurvey={openedSurvey}
      userTookOpenedSurvey={userTookOpenedSurvey}
      openedSurveyAllowAnswersToBeUpdated={openedSurveyAllowAnswersToBeUpdated}
      userAllowedToTakeOpenedSurvey={userAllowedToTakeOpenedSurvey}
      isSurveyParticipationLoading={isSurveyParticipationLoading}
      dialogReward={dialogReward}
      setDialogReward={setDialogReward}
      surveyRewardsIntentions={(surveyRewardsIntentions ?? []).filter(
        reward => reward.status === 'waiting',
      )}
      isSurveyRewardsIntentionsLoading={isSurveyRewardsIntentionsLoading}
      acceptRewardMutation={acceptRewardMutation}
    />
  )
}
