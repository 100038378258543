import {
  SentimentVeryDissatisfied as DisengagedIcon,
  SentimentSatisfied as NeutralIcon,
  InsertEmoticon as EngagedIcon,
} from '@mui/icons-material'

import {
  SurveyDriverScore,
  SurveyEngagement,
  SurveyTopFive,
  SurveyStatus,
  SurveyEnps,
  SurveyEnpsForReportPage,
} from 'enums/survey'
import { t } from 'i18n'
import { SurveyQuestionType } from 'types'

export const isQuestionAnswered = (question: SurveyQuestionType) =>
  (question.satisfactionScore && question.importanceScore) ||
  question.openEndedAnswer

export const arePendingQuestions = (surveyQuestions: SurveyQuestionType[]) =>
  !!surveyQuestions?.find(
    (question: SurveyQuestionType) => !isQuestionAnswered(question),
  )

export const getCurrentQuestionId = (surveyQuestions: SurveyQuestionType[]) => {
  const currentQuestion = surveyQuestions.find(
    (question: SurveyQuestionType) => !isQuestionAnswered(question),
  )

  return currentQuestion ? currentQuestion.id : false
}

export const getSurveyProgressPercentage = (
  surveyQuestions: SurveyQuestionType[],
) =>
  Math.round(
    (surveyQuestions?.filter((question: SurveyQuestionType) =>
      isQuestionAnswered(question),
    ).length *
      100) /
    surveyQuestions?.length,
  )

export const getEngagementScoreEmoji = (key: SurveyEngagement) => {
  switch (key) {
    case SurveyEngagement.Engaged:
      return EngagedIcon
    case SurveyEngagement.Neutral:
      return NeutralIcon
    case SurveyEngagement.Neutrals:
      return NeutralIcon
    case SurveyEngagement.Disengaged:
      return DisengagedIcon
  }
}

export const getEngagementScoreColor = (key: SurveyEngagement) => {
  switch (key) {
    case SurveyEngagement.Engaged:
      return 'primary'
    case SurveyEngagement.Neutral:
      return 'secondary'
    case SurveyEngagement.Neutrals:
      return 'secondary'
    case SurveyEngagement.Disengaged:
      return 'error'
  }
}

export const getEnpsScoreColor = (key: SurveyEnps) => {
  switch (key) {
    case SurveyEnps.Promoter:
      return 'primary'
    case SurveyEnps.Passive:
      return 'enpscoloursecondary'
    case SurveyEnps.Detractor:
      return 'error'
  }
}

// Without s value we are getting and generating for color in doughnut
export const getEnpsScoreColorForReportPage = (key: SurveyEnpsForReportPage) => {
  switch (key) {
    case SurveyEnpsForReportPage.Promoter:
      return 'primary'
    case SurveyEnpsForReportPage.Passive:
      return 'enpscoloursecondary'
    case SurveyEnpsForReportPage.Detractor:
      return 'error'
  }
}

export const getSurveyStatusLabel = (status, intl) => {
  switch (status) {
    case SurveyStatus.Scheduled:
      return intl.formatMessage({ id: 'survey.status.scheduled' })
    case SurveyStatus.Open:
      return intl.formatMessage({ id: 'survey.status.open' })
    case SurveyStatus.Close:
      return intl.formatMessage({ id: 'survey.status.closed' })
    case SurveyStatus.AwaitingManualOpening:
      return intl.formatMessage({ id: 'survey.status.awaitingManualOpening' })
  }
}

export const getTopFiveText = (type: SurveyTopFive) => [
  t(`beheardReport.topFiveText.${type}.title`),
  t(`beheardReport.topFiveText.${type}.description`),
]

export const getTopFiveColor = (type: SurveyTopFive) => {
  switch (type) {
    case SurveyTopFive.Action:
      return 'error'
    case SurveyTopFive.Strength:
      return 'secondary'
    case SurveyTopFive.Importance:
      return 'primary'
  }
}

export const getDriverScoreDescription = (driverScore: SurveyDriverScore) =>
  t(`beheardReport.driverScore.${driverScore}`)
