import { useEffect, useState } from 'react'

import { Grid, TextField } from '@mui/material'

import { DialogTeleport, MoneyInput } from 'components'
import { Props as VirginExperienceProps } from 'pages/virginExperiencesSettings/VirginExperiencesSettings.props'

export default function VirginExperiencesSettingsUpdateCreditDialog(
  props: VirginExperienceProps,
) {
  const {
    virginExperiencesSettings,
    updateCreditLimit,
    updateCreditLimitDialogOpen,
    setUpdateCreditLimitDialogOpen,
  } = props

  const [newCreditLimit, setNewCreditLimit] = useState<string | number>(0)

  const handleDialogClose = () => setUpdateCreditLimitDialogOpen(false)
  const updateNewCreditLimit = event => setNewCreditLimit(event.target.value)

  useEffect(() => {
    if (virginExperiencesSettings?.credit_limit) {
      setNewCreditLimit(virginExperiencesSettings.credit_limit)
    }
  }, [virginExperiencesSettings])

  return (
    <DialogTeleport
      dialogOpen={updateCreditLimitDialogOpen}
      handleDialogClose={handleDialogClose}
      dialogSize={'xs'}
      dialogTitle={"Update Sparck's Credit"}
      dialogAction={{
        function: () =>
          updateCreditLimit.mutate({ new_credit_limit: +newCreditLimit }),
        label: 'Update Credit Limit',
        disabled: updateCreditLimit.isLoading,
        isLoading: updateCreditLimit.isLoading,
      }}
      isFullWidth
    >
      <Grid container>
        <Grid item xs={12}>
          <TextField
            id='new_credit_limit'
            name='new_credit_limit'
            label={'New Credit Limit'}
            variant='outlined'
            value={newCreditLimit}
            onChange={updateNewCreditLimit}
            required
            fullWidth
            margin='dense'
            InputProps={{
              inputComponent: MoneyInput,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
    </DialogTeleport>
  )
}
