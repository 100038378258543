import { Box, Grid, Typography } from '@mui/material'

import { useUser } from 'contexts/UserContext'
import { useLocale } from 'contexts'
import { t } from 'i18n'
import { getDayOfWeek } from 'utils/date'

export default function DashboardHeader() {
  const { user } = useUser()!
  const { locale } = useLocale()!

  return (
    <Grid item xs={12}>
      <Box mt={3} mb={5}>
        <Typography color='text.primary' variant='h4' fontWeight={600}>
          {t('dashboard.greetings', {
            day: getDayOfWeek(locale),
            userName: user.first_name,
          })}
        </Typography>
      </Box>
    </Grid>
  )
}
