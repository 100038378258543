import { get, isEmpty } from 'lodash'
import { useLocation } from 'react-router-dom'
import { /*Checkbox, FormControlLabel,*/ Grid, MenuItem, TextField } from '@mui/material'
import { Autocomplete } from '@mui/material'

import { PERIODS } from 'constants/period'
import { useCompany /*, useUser*/ } from 'contexts'
import { ROUTES_PATHS } from 'core/routes'
// import { UserRole } from 'enums/user'
import { t } from 'i18n'
import { getUserFullName } from 'utils/user'

export default function InsightsFilters({
  filters,
  setFilters,
  surveys,
  pulseSurveys,
}) {
  // const { user } = useUser()!
  const { company } = useCompany()!
  const location = useLocation()
  const managers = get(company, 'managers', [])
  const showManagerField =
    managers &&
    location.pathname === ROUTES_PATHS.teamInsights;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md>
            <TextField
              select
              id='period'
              name='period'
              label={t('insights.filters.labels.period')}
              variant='outlined'
              value={filters.period}
              onChange={event =>
                setFilters({
                  ...filters,
                  period: event.target.value,
                })
              }
              fullWidth
              size='small'
            >
              {PERIODS.map(period => (
                <MenuItem key={period.id} value={period.id}>
                  {period.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {get(company, 'display_managers_teams_beheard_results') && (
            <Grid item xs={12} md>
              <Autocomplete
                id='survey_id'
                value={filters.survey}
                onChange={(event, survey) => {
                  setFilters({ ...filters, survey: survey })
                }}
                loading={isEmpty(surveys)}
                loadingText={t('loading')}
                options={surveys ?? []}
                getOptionLabel={option => option.name || ''}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('insights.filters.labels.beheardSurvey')}
                    variant='outlined'
                    fullWidth
                    size='small'
                  />
                )}
              />
            </Grid>
          )}

          {(get(company, 'display_managers_teams_pulse_survey_results') ||
            get(company, 'display_pulse_survey_results')) && (
              <Grid item xs={12} md>
                <Autocomplete
                  id='pulse_survey_id'
                  value={filters.pulse_survey}
                  onChange={(event, pulseSurvey) => {
                    setFilters({
                      ...filters,
                      pulse_survey: pulseSurvey,
                    })
                  }}
                  loading={isEmpty(pulseSurveys)}
                  loadingText={t('loading')}
                  options={pulseSurveys ?? []}
                  getOptionLabel={option => option.name || ''}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={t('insights.filters.labels.pulseSurveys')}
                      variant='outlined'
                      fullWidth
                      size='small'
                    />
                  )}
                />
              </Grid>
            )}

         {/* {managers && <Grid item >
            <FormControlLabel
              key={'roll_up'}
              control={
                <Checkbox
                  value={filters.roll_up}
                  onChange={() => setFilters({
                    ...filters,
                    roll_up: !filters.roll_up,
                  })}
                  name={'roll_up'}
                />
              }
              label={'Roll Up'}
            />
          </Grid>} */}

          {showManagerField && (
            <Grid item xs={12} md>
              <Autocomplete
                id='manager_id'
                value={filters.manager}
                onChange={(event, manager) => {
                  setFilters({
                    ...filters,
                    manager: manager,
                  })
                }}
                loading={isEmpty(managers)}
                loadingText={t('insights.filters.labels.loadingText')}
                options={managers}
                getOptionLabel={manager => getUserFullName(manager) ?? ''}
                // disabled={filters.survey ? false : true}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('insights.filters.labels.managers')}
                    variant='outlined'
                    fullWidth
                    size='small'
                  />
                )}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
