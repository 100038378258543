export const containsMinimumLengthRequired = value => value?.length >= 8
export const containsCapitalLetter = value => value.match(/[A-Z]/)
export const containsLowercaseLetter = value => value.match(/[a-z]/)
export const containsNumber = value => value.match(/[0-9]/)
// eslint-disable-next-line no-useless-escape
export const containsSpecialCharacter = value =>
  value.match(/[,;.?!@#$%^&*+\-{}()[\]]/)
export const isValidPassword = value =>
  containsMinimumLengthRequired(value) &&
  containsCapitalLetter(value) &&
  containsLowercaseLetter(value) &&
  containsNumber(value) &&
  containsSpecialCharacter(value)
