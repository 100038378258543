import { Grid, Typography } from '@mui/material'

import { t } from 'i18n'

export default function SparckRewardsListFilterInfo(props) {
  const { maxAmount } = props

  return (
    <Grid item xs={12}>
      <Typography mb={2} color='text.secondary'>
        {t('sparcks.sparck.sparckRewardsListFilterInfo', {
          maxAmount: (
            <Typography component='span' fontWeight='600'>
              {Math.round(Number(maxAmount))}
            </Typography>
          ),
        })}
      </Typography>
    </Grid>
  )
}
