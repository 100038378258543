import { useState } from 'react'

import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { useIntl } from 'react-intl'

import { t } from 'i18n'

export default function SparckDisplayAnswer({
  sparck,
  isLoading,
  isSparckPreview,
  answerSparck,
  answerSparckStatus,
}) {
  const [answer, setAnswer] = useState<string>('')

  const intl = useIntl()

  if (!isLoading && !isSparckPreview) {
    return (
      <Grid item xs={12}>
        <Box width={1} mt={10}>
          {sparck.answer ? (
            <Grid container justifyContent='center'>
              <Grid item xs={12}>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  align='center'
                  paragraph
                >
                  {t('sparcks.sparckDisplayAnswer.yourResponseWas')}
                </Typography>

                <Typography
                  variant='h6'
                  color='text.primary'
                  align='center'
                  fontWeight={600}
                  paragraph
                >
                  {sparck.answer}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container justifyContent='center'>
              <Grid item xs={11} sm={10} md={8} lg={6} xl={5}>
                <Grid container justifyContent='center' direction='column'>
                  <Typography
                    variant='h6'
                    color='text.secondary'
                    align='center'
                    paragraph
                  >
                    {t('sparcks.sparckDisplayAnswer.clickAnyMessage')}
                  </Typography>

                  <Grid item xs={12}>
                    <Grid container justifyContent='center'>
                      {[
                        intl.formatMessage({
                          id: 'sparcks.sparckDisplayAnswer.thankYou',
                        }),
                        intl.formatMessage({
                          id: 'sparcks.sparckDisplayAnswer.youAreTooKind',
                        }),
                        intl.formatMessage({
                          id: 'sparcks.sparckDisplayAnswer.wow',
                        }),
                      ].map(item => (
                        <Chip
                          key={item}
                          label={item}
                          clickable
                          onClick={() => setAnswer(item)}
                          color='primary'
                          variant='outlined'
                        />
                      ))}
                    </Grid>
                  </Grid>

                  <Box mt={4} width={1}>
                    <Typography
                      variant='h6'
                      color='text.secondary'
                      align='center'
                      paragraph
                    >
                      {t('sparcks.sparckDisplayAnswer.typeCustomResponse')}
                    </Typography>
                  </Box>

                  <Grid item xs={12}>
                    <TextField
                      id='answer'
                      name='answer'
                      variant='outlined'
                      value={answer}
                      onChange={event => setAnswer(event.target.value)}
                      placeholder={intl.formatMessage({
                        id: 'sparcks.sparckDisplayAnswer.placeholder',
                      })}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box width={1} mt={3}>
                      <Button
                        variant='contained'
                        color='primary'
                        size='large'
                        onClick={() =>
                          answerSparck({
                            id: sparck.id,
                            answer: answer,
                          })
                        }
                        disabled={answerSparckStatus.isLoading || !answer}
                        fullWidth
                      >
                        {answerSparckStatus.isLoading ? (
                          <CircularProgress size={24} />
                        ) : (
                          intl.formatMessage({
                            id: 'sparcks.sparckDisplayAnswer.sendYourAnswer',
                          })
                        )}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
      </Grid>
    )
  }

  return null
}
