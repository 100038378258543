import { useState, useEffect } from 'react'
import { Grid, MenuItem, TextField, useTheme } from '@mui/material'
import { Bar } from 'react-chartjs-2'
import { useIntl } from 'react-intl'

import { BeheardReportUnlockButton } from '../../components'
import { SurveyEngagement } from 'enums/survey'
import { t } from 'i18n'
import { getEngagementScoreColor } from 'utils/survey'
import { capitalizeFirstLetter } from 'utils/app'

export default function BeheardReportComparisonGraph(props) {
  const { Description, surveyDetails, isFullReport } = props

  const intl = useIntl()

  const [demographic, setDemographic] = useState('')

  useEffect(() => {
    if (surveyDetails.comparisons && surveyDetails.comparisons.length > 0) {
      setDemographic(surveyDetails.comparisons[0].label)
    }
  }, [surveyDetails.comparisons])

  const theme = useTheme()

  const getComparisons = () =>
    surveyDetails.comparisons.find(
      comparison => comparison.label === demographic,
    )
      ? surveyDetails.comparisons.find(
          comparison => comparison.label === demographic,
        ).values
      : []

  const getDatasetValues = (dataset: any) => {
    const initialDatasetValues: any = []
    const datasetValues = initialDatasetValues

    getComparisons().forEach(value => {
      datasetValues.push(value.percentages ? value.percentages[dataset] : 0)
    })

    return datasetValues
  }

  const getCategoryPercentage = dataLength =>
    dataLength < 2
      ? 0.05
      : dataLength < 5
      ? 0.1
      : dataLength < 10
      ? 0.3
      : dataLength < 20
      ? 0.5
      : 0.6

  if (surveyDetails.comparisons && isFullReport) {
    return (
      <Grid container>
        <Description>
          {t('beheardReport.comparisonGraphs.description')}
        </Description>
        <Grid container justifyContent='flex-end'>
          <TextField
            select
            id='addressState'
            name='addressState'
            label={t('beheardReport.comparisonGraphs.demographic')}
            variant='outlined'
            value={demographic}
            onChange={event => setDemographic(event.target.value)}
            fullWidth
            style={{ maxWidth: 300, marginBottom: 16 }}
          >
            {surveyDetails.comparisons
              .filter(comparison => comparison.values.length > 0)
              .map(comparison => (
                <MenuItem key={comparison.label} value={comparison.label}>
                  {comparison.label}
                </MenuItem>
              ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent='center'>
            <Grid item xs={12}>
              <Bar
                data={{
                  labels: getComparisons().map(comparison => comparison.label),
                  datasets: [
                    SurveyEngagement.Engaged,
                    SurveyEngagement.Neutrals,
                    SurveyEngagement.Disengaged,
                  ].map(dataset => ({
                    label: capitalizeFirstLetter(
                      intl.formatMessage({
                        id: `beheardReport.comparisonGraphs.legend.${dataset}`,
                      }),
                    ),
                    data: getDatasetValues(dataset),
                    backgroundColor:
                      theme.palette[getEngagementScoreColor(dataset)]?.light,
                    maxBarThickness: 8,
                    categoryPercentage: getCategoryPercentage(
                      getDatasetValues(dataset).length,
                    ),
                    barPercentage: 1,
                  })),
                }}
                height={400}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  plugins: {
                    legend: {
                      align: 'end',
                      labels: {
                        usePointStyle: true,
                        padding: 24,
                      },
                    },
                    tooltip: {},
                  },
                  scales: {
                    x: {
                      grid: {
                        display: false,
                      },
                    },
                    y: {
                      min: 0,
                      max: 100,
                      ticks: {
                        stepSize: 25,
                        callback: value => value + '%',
                      },
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  } else if (!isFullReport) {
    return <BeheardReportUnlockButton {...props} />
  } else {
    return null
  }
}
