import { Card, CardActionArea, CardMedia } from '@mui/material'

export default function SparckCard({ card, onSelectCard }) {
  return (
    <Card
      style={{
        height: '100%',
        position: 'relative',
        overflow: 'inherit',
      }}
    >
      <CardActionArea
        onClick={() => onSelectCard(card)}
        style={{
          height: '100%',
        }}
      >
        <CardMedia
          component='img'
          alt={card.title}
          image={card.url}
          title={card.title}
          style={{ width: '100%', aspectRatio: '16/9' }}
        />
      </CardActionArea>
    </Card>
  )
}
