import { useState } from 'react'

import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from 'react-query'

import tutorialFindLink1 from 'assets/images/msTeams/tutorial-find-link-1.png'
import tutorialFindLink2 from 'assets/images/msTeams/tutorial-find-link-2.png'
import tutorialInstallBot1 from 'assets/images/msTeams/tutorial-install-bot-1.png'
import tutorialInstallBot2 from 'assets/images/msTeams/tutorial-install-bot-2.png'
import tutorialInstallBot3 from 'assets/images/msTeams/tutorial-install-bot-3.png'
import tutorialInstallBot4 from 'assets/images/msTeams/tutorial-install-bot-4.png'
import tutorialSetPolicies1 from 'assets/images/msTeams/tutorial-set-policies-1.png'
import tutorialSetPolicies2 from 'assets/images/msTeams/tutorial-set-policies-2.png'
import tutorialSetPolicies3 from 'assets/images/msTeams/tutorial-set-policies-3.png'
import { PopupSteps } from 'components'
import { useCompany } from 'contexts'
import { ENV } from 'core/environments'
import { API } from 'core/requests'
import { BotIntegrationStatus } from 'enums/bot'
import { t } from 'i18n'

export default function SettingsMSTeamsIntegration({ companyState }) {
  const [MSTeamsConfigurationLink, setMSTeamsConfigurationLink] = useState('')
  const [dialogTutorialFindLink, setDialogTutorialFindLink] = useState(false)
  const [dialogTutorialInstallBot, setDialogTutorialInstallBot] =
    useState(false)
  const [dialogTutorialSetPolicies, setDialogTutorialSetPolicies] =
    useState(false)

  const { company } = useCompany()!
  const { enqueueSnackbar } = useSnackbar()

  const { data: botSettings, refetch: fetchBotSettings } = useQuery(
    'getBotSettings',
    () =>
      API.get('botSettings', null, {
        queryParams: {
          company_id: company?.id,
        },
      }),
    {
      enabled: !!company?.id,
      onSuccess: response => {
        if (response.company_id) {
          setMSTeamsConfigurationLink(response.msteams_feed_channel_id)
        }
      },
    },
  )

  const { mutate: configureMSTeams, isLoading: configureMSTeamsIsLoading } =
    useMutation((payload: any) => API.update('configureMSTeams', payload), {
      onSuccess: (response: any) => {
        if (response.msteams_feed_channel_id) {
          fetchBotSettings()
          enqueueSnackbar(`You successfully set your Channel Link`, {
            variant: 'success',
          })
        }
      },
    })

  const stepsTutorialFindLink = [
    {
      id: '1',
      label: t(
        'settings.msTeamsIntegration.stepsTutorialFindLink.stepOne.label',
      ),
      description: t(
        'settings.msTeamsIntegration.stepsTutorialFindLink.stepOne.description',
      ),
      image: tutorialFindLink1,
    },
    {
      id: '2',
      label: t(
        'settings.msTeamsIntegration.stepsTutorialFindLink.stepTwo.label',
      ),
      description: t(
        'settings.msTeamsIntegration.stepsTutorialFindLink.stepOne.description',
      ),
      image: tutorialFindLink2,
    },
  ]

  const stepsTutorialInstallBot = [
    {
      id: '1',
      label: t(
        'settings.msTeamsIntegration.stepsTutorialInstallBot.stepOne.label',
      ),
      CustomDescription: (
        <>
          <ul>
            <Typography component='li' variant='body1' color='text.secondary'>
              {t(
                'settings.msTeamsIntegration.stepsTutorialInstallBot.stepOne.customDescription.partOne',
              )}
            </Typography>

            <Typography component='li' variant='body1' color='text.secondary'>
              {t(
                'settings.msTeamsIntegration.stepsTutorialInstallBot.stepOne.customDescription.partTwo',
              )}
            </Typography>
          </ul>
        </>
      ),
      image: tutorialInstallBot1,
    },
    {
      id: '2',
      label: t(
        'settings.msTeamsIntegration.stepsTutorialInstallBot.stepTwo.label',
      ),
      description: t(
        'settings.msTeamsIntegration.stepsTutorialInstallBot.stepTwo.description',
      ),
      image: tutorialInstallBot2,
    },
    {
      id: '3',
      label: t(
        'settings.msTeamsIntegration.stepsTutorialInstallBot.stepThree.label',
      ),
      description: t(
        'settings.msTeamsIntegration.stepsTutorialInstallBot.stepThree.description',
      ),
      image: tutorialInstallBot3,
    },
    {
      id: '4',
      label: t(
        'settings.msTeamsIntegration.stepsTutorialInstallBot.stepFour.label',
      ),
      description: t(
        'settings.msTeamsIntegration.stepsTutorialInstallBot.stepFour.description',
      ),
      image: tutorialInstallBot4,
    },
  ]

  const stepsTutorialSetPolicies = [
    {
      id: '1',
      label: t(
        'settings.msTeamsIntegration.stepsTutorialSetPolicies.stepOne.label',
      ),
      CustomDescription: (
        <>
          <ul>
            <Typography component='li' variant='body1' color='text.secondary'>
              {t(
                'settings.msTeamsIntegration.stepsTutorialSetPolicies.stepOne.customDescription.partOne',
              )}{' '}
              <a
                href='https://admin.teams.microsoft.com/policies/app-setup'
                target='_blank'
                rel='noopener noreferrer'
              >
                https://admin.teams.microsoft.com/policies/app-setup
              </a>
            </Typography>

            <Typography component='li' variant='body1' color='text.secondary'>
              {t(
                'settings.msTeamsIntegration.stepsTutorialSetPolicies.stepOne.customDescription.partTwo',
              )}
            </Typography>

            <Typography component='li' variant='body1' color='text.secondary'>
              {t(
                'settings.msTeamsIntegration.stepsTutorialSetPolicies.stepOne.customDescription.partThree',
              )}
            </Typography>

            <Typography component='li' variant='body1' color='text.secondary'>
              {t(
                'settings.msTeamsIntegration.stepsTutorialSetPolicies.stepOne.customDescription.partFour',
              )}
            </Typography>
          </ul>
        </>
      ),
      image: tutorialSetPolicies1,
    },
    {
      id: '2',
      label: t(
        'settings.msTeamsIntegration.stepsTutorialSetPolicies.stepTwo.label',
      ),
      CustomDescription: (
        <>
          <ul>
            <Typography component='li' variant='body1' color='text.secondary'>
              {t(
                'settings.msTeamsIntegration.stepsTutorialSetPolicies.stepTwo.customDescription.partOne',
              )}
            </Typography>

            <Typography component='li' variant='body1' color='text.secondary'>
              {t(
                'settings.msTeamsIntegration.stepsTutorialSetPolicies.stepTwo.customDescription.partTwo',
              )}
            </Typography>

            <Typography component='li' variant='body1' color='text.secondary'>
              {t(
                'settings.msTeamsIntegration.stepsTutorialSetPolicies.stepTwo.customDescription.partThree',
              )}
            </Typography>

            <Typography component='li' variant='body1' color='text.secondary'>
              {t(
                'settings.msTeamsIntegration.stepsTutorialSetPolicies.stepTwo.customDescription.partFour',
              )}
            </Typography>
          </ul>
        </>
      ),
      image: tutorialSetPolicies2,
    },
    {
      id: '3',
      label: t(
        'settings.msTeamsIntegration.stepsTutorialSetPolicies.stepThree.label',
      ),
      CustomDescription: (
        <>
          <ul>
            <Typography component='li' variant='body1' color='text.secondary'>
              {t(
                'settings.msTeamsIntegration.stepsTutorialSetPolicies.stepThree.customDescription.partOne',
              )}
            </Typography>

            <Typography component='li' variant='body1' color='text.secondary'>
              {t(
                'settings.msTeamsIntegration.stepsTutorialSetPolicies.stepThree.customDescription.partTwo',
              )}
            </Typography>

            <Typography component='li' variant='body1' color='text.secondary'>
              {t(
                'settings.msTeamsIntegration.stepsTutorialSetPolicies.stepThree.customDescription.partThree',
              )}
            </Typography>
          </ul>
        </>
      ),
      image: tutorialSetPolicies3,
    },
  ]

  return (
    <>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12}>
          <Typography variant='body1' color='text.secondary'>
            {t('settings.msTeamsIntegration.stepOne')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm>
          <TextField
            id='MSTeamsConfigurationLink'
            name='MSTeamsConfigurationLink'
            label={t('settings.msTeamsIntegration.channelLinkLabel')}
            value={MSTeamsConfigurationLink}
            onChange={event => setMSTeamsConfigurationLink(event.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm='auto'>
          <Button
            variant='contained'
            color='primary'
            size='large'
            disabled={!MSTeamsConfigurationLink || configureMSTeamsIsLoading}
            onClick={() =>
              configureMSTeams({
                company_id: company?.id,
                msteams_configuration_link: MSTeamsConfigurationLink,
              })
            }
          >
            {t('settings.msTeamsIntegration.save')}{' '}
            {configureMSTeamsIsLoading && <CircularProgress size={16} />}
          </Button>
        </Grid>

        {botSettings?.msteams_feed_channel_id && (
          <>
            <Grid item xs={12} mt={1}>
              <Typography variant='body1' color='text.secondary'>
                {t('settings.msTeamsIntegration.stepTwo')}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Link
                href={
                  ENV === 'prod'
                    ? '/files/sparck-bot-msteams-prod.zip'
                    : '/files/sparck-bot-msteams-dev.zip'
                }
                target='_self'
                download
                sx={{ fontWeight: 600 }}
              >
                {t('settings.msTeamsIntegration.downloadMSTeamsSparckBot')}
              </Link>
            </Grid>
          </>
        )}

        <Grid item xs={12} sx={{ mt: 3 }}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Typography variant='body1' color='text.primary' fontWeight={500}>
            {t('settings.msTeamsIntegration.frequentlyAskedQuestions')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Link onClick={() => setDialogTutorialFindLink(true)}>
            {t('settings.msTeamsIntegration.whereToFindChannelLink')}
          </Link>
        </Grid>

        {(companyState?.bot_engine?.msteams_configuration_status !==
          BotIntegrationStatus.NotIntegrated ||
          botSettings?.msteams_configuration_status !==
            BotIntegrationStatus.NotIntegrated) && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Link onClick={() => setDialogTutorialInstallBot(true)}>
                {t('settings.msTeamsIntegration.whatToDoNext')}
              </Link>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Link onClick={() => setDialogTutorialSetPolicies(true)}>
                {t('settings.msTeamsIntegration.sparckBotByDefault')}
              </Link>
            </Grid>
          </>
        )}
      </Grid>

      <PopupSteps
        dialogOpen={dialogTutorialFindLink}
        setDialogOpen={setDialogTutorialFindLink}
        steps={stepsTutorialFindLink}
      />

      <PopupSteps
        dialogOpen={dialogTutorialInstallBot}
        setDialogOpen={setDialogTutorialInstallBot}
        steps={stepsTutorialInstallBot}
      />

      <PopupSteps
        dialogOpen={dialogTutorialSetPolicies}
        setDialogOpen={setDialogTutorialSetPolicies}
        steps={stepsTutorialSetPolicies}
      />
    </>
  )
}
