import { Grid, Skeleton, Typography } from '@mui/material'

import { NominationsManagementNominationEvent } from '../../components'

export default function NominationsManagementPending(props) {
  const { currentNominations, currentNominationsAreLoading } = props

  if (currentNominationsAreLoading) {
    return (
      <Grid container>
        {[...Array(5)].map((_, index) => (
          <Grid item key={index} xs={12} sx={{ mb: 2 }}>
            <Skeleton variant='rectangular' height={200} />
          </Grid>
        ))}
      </Grid>
    )
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='body1' color='text.primary' paragraph>
          {currentNominations.length > 0
            ? 'View all the nominations in each active category and select your winners.'
            : 'No nominations yet.'}
        </Typography>
      </Grid>

      {currentNominations.map(nomination => (
        <Grid key={nomination.id} item xs={12} sx={{ mb: 2 }}>
          <NominationsManagementNominationEvent
            {...props}
            nomination={nomination}
            showAddToAwardedListButton
          />
        </Grid>
      ))}
    </Grid>
  )
}
