import { useState } from 'react'

import { useMutation, useQuery } from 'react-query'
import { useSnackbar } from 'notistack'

import { VirginExperiencesSettingsType } from './VirginExperiencesSettings.props'
import { API } from 'core/requests'
import { VirginExperiencesSettingsComponent } from './components'
import { formatToUSD } from 'utils/currency'

export default function VirginExperiencesSettings() {
  const { enqueueSnackbar } = useSnackbar()

  const [virginExperiencesSettings, setVirginExperiencesSettings] =
    useState<VirginExperiencesSettingsType>(null)
  const [updateCreditLimitDialogOpen, setUpdateCreditLimitDialogOpen] =
    useState(false)
  const [updateCreditAlertsDialogOpen, setUpdateCreditAlertsDialogOpen] =
    useState(false)
  const [manageCreditPaymentsDialogOpen, setManageCreditPaymentsDialogOpen] =
    useState(false)
  const [createCreditPaymentDialogOpen, setCreateCreditPaymentDialogOpen] =
    useState(false)

  const { isLoading: virginExperiencesSettingsIsLoading } = useQuery(
    'getVirginExperiencesSettings',
    () => API.get('virginExperiencesSettings'),
    {
      enabled: true,
      onSuccess: response => {
        setVirginExperiencesSettings(response)
      },
    },
  )

  const {
    data: virginExperiencesCreditPayments,
    isLoading: virginExperiencesCreditPaymentsIsLoading,
  } = useQuery(
    'getVirginExperiencesCreditPayments',
    () => API.get('virginExperiencesSettingsCreditPayments'),
    {
      enabled: manageCreditPaymentsDialogOpen,
    },
  )

  const {
    data: virginExperiencesPendingOrders,
    refetch: fetchVirginExperiencesPendingOrders,
    isLoading: virginExperiencesPendingOrdersAreLoading,
  } = useQuery(
    'getVirginExperiencesPendingOrders',
    () => API.get('virginExperiencesSettingsPendingOrders'),
    {
      enabled: manageCreditPaymentsDialogOpen,
    },
  )

  const creditPaymentMutation = useMutation(
    (payload: any) =>
      API.createOrUpdate('virginExperiencesSettingsCreditPayments', payload),
    {
      onSuccess: (response: any) => {
        if (response?.id) {
          setCreateCreditPaymentDialogOpen(false)
          fetchVirginExperiencesPendingOrders()

          enqueueSnackbar(`Successfully created a Credit Payment!`, {
            variant: 'success',
          })
        }
      },
    },
  )

  const updateCreditLimit = useMutation(
    (payload: any) =>
      API.update('virginExperiencesSettingsCreditLimit', payload),
    {
      onSuccess: (response: any) => {
        if (response?.credit_limit) {
          setVirginExperiencesSettings(response)
          setUpdateCreditLimitDialogOpen(false)

          enqueueSnackbar(
            `Successfully updated Sparck's credit limit: ${formatToUSD(
              response?.credit_limit,
            )}`,
            { variant: 'success' },
          )
        }
      },
    },
  )

  const updateCreditAlerts = useMutation(
    (payload: any) =>
      API.update('virginExperiencesSettingsCreditAlerts', payload),
    {
      onSuccess: (response: any) => {
        if (response?.credit_limit) {
          setVirginExperiencesSettings(response)
          setUpdateCreditAlertsDialogOpen(false)

          enqueueSnackbar(`Successfully updated Sparck's credit alerts!`, {
            variant: 'success',
          })
        }
      },
    },
  )

  return (
    <VirginExperiencesSettingsComponent
      virginExperiencesSettings={virginExperiencesSettings}
      virginExperiencesSettingsIsLoading={virginExperiencesSettingsIsLoading}
      virginExperiencesCreditPayments={virginExperiencesCreditPayments}
      virginExperiencesCreditPaymentsIsLoading={
        virginExperiencesCreditPaymentsIsLoading
      }
      updateCreditLimit={updateCreditLimit}
      updateCreditLimitDialogOpen={updateCreditLimitDialogOpen}
      setUpdateCreditLimitDialogOpen={setUpdateCreditLimitDialogOpen}
      updateCreditAlerts={updateCreditAlerts}
      updateCreditAlertsDialogOpen={updateCreditAlertsDialogOpen}
      setUpdateCreditAlertsDialogOpen={setUpdateCreditAlertsDialogOpen}
      manageCreditPaymentsDialogOpen={manageCreditPaymentsDialogOpen}
      setManageCreditPaymentsDialogOpen={setManageCreditPaymentsDialogOpen}
      creditPaymentMutation={creditPaymentMutation}
      createCreditPaymentDialogOpen={createCreditPaymentDialogOpen}
      setCreateCreditPaymentDialogOpen={setCreateCreditPaymentDialogOpen}
      virginExperiencesPendingOrders={virginExperiencesPendingOrders}
      virginExperiencesPendingOrdersAreLoading={
        virginExperiencesPendingOrdersAreLoading
      }
    />
  )
}
