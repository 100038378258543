export const decodeQueryParams = (search: any) => {
  if (search) {
    const decoded = search.substring(1)
    return JSON.parse(
      '{"' +
        decodeURI(decoded)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}',
    )
  }
  return null
}

export const urlParams = url => {
  const entries: any = new URLSearchParams(url).entries()
  const result = {}

  for (const entry of entries) {
    const [key, value] = entry
    result[key] = value
  }

  return result
}
