import React, { Fragment } from 'react'

import { isEmpty } from 'lodash'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material'
import InfiniteScroll from 'react-infinite-scroll-component'
import { UseMutationResult } from 'react-query'
import { FetchOptions } from 'react-query/types/core/query'

import { FeedPost } from '../../components'
import { UserPermission } from 'enums/user'
import { UserData } from '../FeedPost/FeedPost'
import { t } from 'i18n'
import { DialogData, PostType } from 'types'

type Props = {
  posts: PostType[]
  postId: number
  publishPost: (any) => void
  publishPostStatus: UseMutationResult
  postsIsLoading: boolean
  hasMorePosts: boolean
  setFeedPostsConfirmDelete: React.Dispatch<React.SetStateAction<DialogData>>
  deleteCommentMutation: UseMutationResult
  publishComment: (any) => void
  publishCommentStatus: UseMutationResult
  likePost: (params: { post_id: number; method: string }) => void
  user: UserData
  uploadPostMediaStatus: UseMutationResult
  fetchPosts: (
    options?: FetchOptions | undefined,
  ) => Promise<PostType[] | undefined>
  setPostsState: React.Dispatch<React.SetStateAction<PostType[]>>
}

export default function FeedPosts(props: Props) {
  const { posts, postId, postsIsLoading, fetchPosts, hasMorePosts, user } =
    props

  return (
    <Grid container>
      {!(user.permissions ?? []).includes(UserPermission.ViewPosts) ? (
        <Box mt={3} width={1}>
          <Typography variant='h6' color='text.secondary' align='center'>
            {t('feed.noPermissionToViewPosts')}
          </Typography>
        </Box>
      ) : isEmpty(posts) && !postsIsLoading ? (
        <Box mt={3} width={1}>
          <Typography variant='h6' color='text.secondary' align='center'>
            {t('feed.noPosts')}
          </Typography>
        </Box>
      ) : (
        <Grid item xs={12}>
          <InfiniteScroll
            dataLength={posts.length}
            next={fetchPosts}
            style={{ width: '100%' }}
            hasMore={postId ? false : hasMorePosts}
            loader={
              <Grid container>
                {[...Array(2)].map((e, index) => (
                  <Box key={index} width={1} marginTop={2}>
                    <Card style={{ width: '100%' }}>
                      <CardHeader
                        avatar={
                          <Skeleton
                            animation='wave'
                            variant='circular'
                            width={40}
                            height={40}
                          />
                        }
                        title={
                          <Skeleton
                            animation='wave'
                            height={10}
                            width='80%'
                            style={{ marginBottom: 6 }}
                          />
                        }
                        subheader={
                          <Skeleton animation='wave' height={10} width='40%' />
                        }
                      />
                      <CardContent style={{ paddingTop: 0 }}>
                        {
                          <>
                            <Skeleton
                              animation='wave'
                              height={10}
                              style={{ marginBottom: 6 }}
                            />
                            <Skeleton
                              animation='wave'
                              height={10}
                              width='80%'
                            />
                          </>
                        }
                      </CardContent>
                    </Card>
                  </Box>
                ))}
              </Grid>
            }
          >
            {posts.length > 0 &&
              posts.map(post => (
                <Fragment key={post.id}>
                  <FeedPost {...props} key={post.id} post={post} />
                </Fragment>
              ))}
          </InfiniteScroll>
        </Grid>
      )}
    </Grid>
  )
}
