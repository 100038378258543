import { useEffect, useState } from 'react'

import { startOfDay } from 'date-fns'
import { get } from 'lodash'
import { useIntl } from 'react-intl'
import { useMutation, useQuery } from 'react-query'
import * as yup from 'yup'

import { ManageSurveyForm } from './components'
import { FormHandler } from 'components'
import { initialDialog } from 'constants/dialog'
import { API } from 'core/requests'
import { ROUTES_PATHS } from 'core/routes'
import { SurveyStatus } from 'enums/survey'
import { t } from 'i18n'
import { CompanyType } from 'types'
import { useCompany } from 'contexts'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)

const validationSchema = yup.object({
  name: yup
    .string()
    .nullable()
    .required(t('surveyOverview.validations.surveyNameIsRequired')),
  status: yup.string().nullable().required(),
  open_at: yup
    .date()
    .typeError(t('invalidDateFormat'))
    .nullable()
    .when('status', {
      is: SurveyStatus.Scheduled,
      then: yup
        .date()
        .typeError(t('invalidDateFormat'))
        .nullable()
        .min(
          startOfDay(new Date()),
          t('surveyOverview.validations.minimumLaunchDate'),
        )
        .max(
          yup.ref('close_at'),
          t('surveyOverview.validations.maximumLaunchDate'),
        )
        .required(t('surveyOverview.validations.launchDateIsRequired')),
    }),
  close_at: yup
    .date()
    .typeError(t('invalidDateFormat'))
    .nullable()
    .when('status', {
      is: SurveyStatus.Scheduled,
      then: yup
        .date()
        .typeError(t('invalidDateFormat'))
        .nullable()
        .min(
          yup.ref('open_at'),
          t('surveyOverview.validations.minimumCloseDate'),
        )
        .required(t('surveyOverview.validations.closeDateIsRequired')),
    }),
  reward_amount: yup
    .string()
    .nullable()
    .when('allow_reward', {
      is: true,
      then: yup
        .string()
        .nullable()
        .required(t('surveyOverview.validations.rewardAmountIsRequired'))
        .test(
          'min-monetary-value',
          t('surveyOverview.validations.rewardAmountIsRequired'),
          function (value: any) {
            if (this.parent.reward_amount) {
              return value >= 1.0
            }
            return false
          },
        ),
    }),
})

const getInitialSurveyQuestion = type => ({
  statement: '',
  _destroy: false,
  type: type,
})

export default function ManageSurvey({
  dialog,
  closeDialog,
  fetchSurveys,
}: any) {
  const initialCompany: CompanyType = {}

  const [ratingQuestions, setRatingQuestions] = useState([])
  const [openEndedQuestions, setOpenEndedQuestions] = useState([])
  const [companyState, setCompanyState] = useState(initialCompany)
  const { company, setCompany } = useCompany()!

  const [previewDialog, setPreviewDialog] = useState(initialDialog)

  const { data: demographics, isLoading: demographicsIsLoading } = useQuery(
    'getDemographics',
    () => API.get('demographics'),
  )

  const intl = useIntl()

  const surveyId = get(dialog, 'data.id')

  const questionsAttributes = [
    ...ratingQuestions.map((question: any) => ({
      ...question,
      type: 'satisfaction',
    })),
    ...openEndedQuestions.map((question: any) => ({
      ...question,
      type: 'open_ended',
    })),
  ]

  const initialValues = get(dialog, 'data')
    ? get(dialog, 'data')
    : {
        name: '',
        open_at: null,
        close_at: null,
        allow_reward: false,
        demographics: [],
        roles_who_can_see_insights: [],
        allow_answers_to_be_updated: false,
        reward_amount: '1',
        satisfaction: [getInitialSurveyQuestion('satisfaction')],
        open_ended: [getInitialSurveyQuestion('open_ended')],
      }

  const previewMutation = useMutation(
    (payload: any) => API.create('surveyPreview', payload),
    {
      onSuccess: (response: any) => {
        if (response && !response.error) {
          setPreviewDialog({ isOpen: true, data: response?.survey })
        }
      },
    },
  )

  useEffect(() => {
    if (get(company, 'id')) {
      setCompanyState({ ...company })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company])

  const updateCompanyMutation = useMutation(
    (payload: any) => {
      const companyId =  get(payload.companyState, 'id')
      return API.update('companies', payload.companyState, {
        pathParams: {
          id: companyId ? companyId : null,
        },
      })
    },
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSettled: (response: any,error:any,variables,contexts) => {
        if (response?.company) {
          setCompany(response.company)
          const values = {...variables.values}
          
          variables.createUpdateAction({
            ...values,
            id: dialog?.data?.isClone ? null : variables.values.id,
            questions_attributes: dialog?.data?.isClone
              ? questionsAttributes.map((question: any) => {
                  delete question.id
                  delete question.survey_id
  
                  return question
                })
              : questionsAttributes,
          })
  
          if (!dialog) {
            window.location.replace(
              window.location.origin + ROUTES_PATHS.surveyOverview,
            )
          }
        }
      },
    },
  )

  return (
    <>
      <FormHandler
        title={intl.formatMessage({ id: 'surveyDialogs.title' })}
        descriptionCreateMode={
          <span>
            {t('surveyDialogs.addSurvey.descriptionFirstLine')}{' '}
            <a
              href='https://faq.sparckco.com/knowledge/set-a-beheard-survey-launch-strategy'
              rel='noopener noreferrer'
              target='_blank'
            >
              {t('surveyDialogs.addSurvey.link')}{' '}
            </a>
            {t('surveyDialogs.addSurvey.descriptionSecondLine')}
          </span>
        }
        descriptionUpdateMode={
          <span>{t('surveyDialogs.editSurvey.description')}</span>
        }
        requestUrl='surveys'
        detailsRequestId={surveyId}
        itemAccessor={'survey'}
        detailsRequestCallback={response => {
          if (response?.survey?.id) {
            const customQuestions = response.survey?.questions.filter(
              question => question.driver === 'custom',
            )

            setRatingQuestions(
              customQuestions?.filter(
                question => question.type === 'satisfaction',
              ),
            )
            setOpenEndedQuestions(
              customQuestions?.filter(
                question => question.type === 'open_ended',
              ),
            )
          }
        }}
        data={get(dialog, 'data')}
        closeDialog={closeDialog}
        fetchRows={fetchSurveys}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, createUpdateAction) => {
          if(values.status === "scheduled") {
            values.open_at = dayjs(values.open_at).format("YYYY-MM-DD HH:00:00")
            values.close_at = dayjs(values.close_at).format("YYYY-MM-DD HH:00:00")
            updateCompanyMutation.mutate({companyState,createUpdateAction,values})
          } else {
            createUpdateAction({
              ...values,
              id: dialog?.data?.isClone ? null : values.id,
              questions_attributes: dialog?.data?.isClone
                ? questionsAttributes.map((question: any) => {
                    delete question.id
                    delete question.survey_id
  
                    return question
                  })
                : questionsAttributes,
            })
            if (!dialog) {
              window.location.replace(
                window.location.origin + ROUTES_PATHS.surveyOverview,
              )
            }
          }
        }}
        secondaryActionButtons={[
          {
            id: 'preview',
            label: 'Preview',
            onClick: formik => {
              previewMutation.mutate({
                ...formik.values,
                open_at:
                  formik.values.status === SurveyStatus.Scheduled
                    ? formik.values.open_at
                    : null,
                close_at:
                  formik.values.status === SurveyStatus.Scheduled
                    ? formik.values.close_at
                    : null,
                questions_attributes: questionsAttributes.map(question => ({
                  ...question,
                  id: null,
                })),
              })
            },
            variant: 'outlined',
            color: 'primary',
            isLoading: false,
          },
        ]}
      >
        {({ formik }) => (
          <ManageSurveyForm
            formik={formik}
            isClone={dialog?.data?.isClone}
            demographics={demographics}
            demographicsIsLoading={demographicsIsLoading}
            ratingQuestions={ratingQuestions}
            setRatingQuestions={setRatingQuestions}
            openEndedQuestions={openEndedQuestions}
            setOpenEndedQuestions={setOpenEndedQuestions}
            previewDialog={previewDialog}
            setPreviewDialog={setPreviewDialog}
            companyState={companyState}
            setCompanyState={setCompanyState}
          />
        )}
      </FormHandler>
    </>
  )
}

// function enqueueSnackbar(arg0: any, arg1: { variant: string }) {
//   throw new Error('Error While creating a servey.')
// }
