import { DatePicker } from '@mui/lab'
import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material'

import { t } from 'i18n'

export default function ManageRecognitionTimeframe({ formik }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              data-cy='hasTimeFrame'
              id='has_time_frame'
              name='has_time_frame'
              checked={!!formik.values.has_time_frame}
              onChange={() =>
                formik.setFieldValue(
                  'has_time_frame',
                  !formik.values.has_time_frame,
                )
              }
            />
          }
          label={t('recognitions.fields.setCustomTimeframe')}
        />
      </Grid>

      {formik.values.has_time_frame && (
        <>
          <Grid item xs={12} sm={6}>
            <DatePicker
              renderInput={params => (
                <TextField
                  {...params}
                  data-cy='startDate'
                  id='time_frame_start_at'
                  name='time_frame_start_at'
                  label={t('recognitions.fields.startDate')}
                  variant='outlined'
                  required={formik.errors.time_frame_start_at}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.time_frame_start_at &&
                    Boolean(formik.errors.time_frame_start_at)
                  }
                  helperText={
                    formik.touched.time_frame_start_at &&
                    formik.errors.time_frame_start_at
                  }
                  fullWidth
                />
              )}
              value={formik.values.time_frame_start_at}
              onChange={value =>
                formik.setFieldValue('time_frame_start_at', value)
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DatePicker
              renderInput={params => (
                <TextField
                  {...params}
                  data-cy='endDate'
                  id='time_frame_end_at'
                  name='time_frame_end_at'
                  label={t('recognitions.fields.endDate')}
                  variant='outlined'
                  required={formik.errors.time_frame_end_at}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.time_frame_end_at &&
                    Boolean(formik.errors.time_frame_end_at)
                  }
                  helperText={
                    formik.touched.time_frame_end_at &&
                    formik.errors.time_frame_end_at
                  }
                  fullWidth
                />
              )}
              value={formik.values.time_frame_end_at}
              disablePast
              onChange={value =>
                formik.setFieldValue('time_frame_end_at', value)
              }
              minDate={formik.values.time_frame_start_at}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}
