export default {
    defaultProps: {
      MenuProps: {
        PaperProps: {
          style: {
             width: 'auto !important'
          },
        },
      },
    }
  }