import { useEffect } from 'react'

import {
  Button,
  Grid,
  Grow,
  Paper,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import { keyframes } from '@mui/system'
import { useTimeout } from 'react-use'

import sparckLogo from 'assets/images/sparck-logo-colored.png'
import {
  SPARCK_SPOOFED_COMPANY,
  SPARCK_SPOOFED_USER_ID,
  SPARCK_SPOOFED_USER_TOKEN,
  SPARCK_USER_TOKEN,
  SPARCK_USER_TOKEN_ID,
} from 'constants/app'

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const SparckLogo = styled('img')({
  width: '100%',
  height: '100%',
  animation: `${spin} 1.5s infinite ease`,
})

export default function ApplicationLoading() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [shouldDisplayHardRefresh, _, reset] = useTimeout(10000)

  const hardRefresh = () => {
    localStorage.removeItem(SPARCK_SPOOFED_COMPANY)
    localStorage.removeItem(SPARCK_USER_TOKEN)
    localStorage.removeItem(SPARCK_USER_TOKEN_ID)
    localStorage.removeItem(SPARCK_SPOOFED_USER_TOKEN)
    localStorage.removeItem(SPARCK_SPOOFED_USER_ID)

    window.location.reload()
  }

  useEffect(() => {
    reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      sx={{
        background: 'linear-gradient(127.84deg, #bbf7f7 0%, #f3e1fc 100%)',
        width: '100%',
        height: '100vh',
      }}
    >
      <Grid item xs='auto'>
        <Grid container justifyContent='center'>
          <Grid item xs='auto'>
            <Grow in={true}>
              <Paper
                elevation={4}
                sx={{
                  height: 240,
                  width: 240,
                  p: 4,
                  borderRadius: '100%',
                  bgcolor: 'text.white',
                  mb: 3,
                }}
              >
                <SparckLogo src={sparckLogo} alt='Sparck logo' />
              </Paper>
            </Grow>
          </Grid>
          {shouldDisplayHardRefresh() && (
            <Grid item xs={12}>
              <Stack>
                <Typography variant='h5' align='center' fontWeight={600}>
                  It seems the application is not responding...
                </Typography>

                <Grid container justifyContent='center'>
                  <Button
                    variant='contained'
                    color='primary'
                    size='large'
                    onClick={hardRefresh}
                    sx={{ maxWidth: '20em', mt: 2 }}
                  >
                    Click here to refresh
                  </Button>
                </Grid>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
