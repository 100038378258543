import { useQuery } from 'react-query'

import { MyRewardsPurchasesComponent } from './components'
import { API } from 'core/requests'

export default function MyRewardsPurchases() {
  // const { data: rewardsPurchases, isLoading: rewardsPurchasesIsLoading } =
  //   useQuery('getRewardsNGC', () => API.get('myRewardsPurchases'))

  const { data: rewardStorePurchases, isLoading: rewardStorePurchasesIsLoading } =
    useQuery('getRewardsGRS', () => API.get('myRewardStorePurchases'))

  return (
    <MyRewardsPurchasesComponent
      // rewardsPurchases={rewardsPurchases}
      // rewardsPurchasesIsLoading={rewardsPurchasesIsLoading}
      rewardStorePurchases={rewardStorePurchases}
      rewardStorePurchasesIsLoading={rewardStorePurchasesIsLoading}
    />
  )
}
