import {
  DeleteOutlined as DeleteIcon,
  MarkEmailReadOutlined as MarkAsReadIcon,
  MoreHoriz as MoreOptionsIcon,
} from '@mui/icons-material'
import {
  alpha,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material'
import { formatRelative, parseISO } from 'date-fns'
import { get } from 'lodash'
import {
  bindPopover,
  usePopupState,
  bindTrigger,
} from 'material-ui-popup-state/hooks'
import { useHistory } from 'react-router-dom'

import sparckLogoWhite from 'assets/images/sparck-logo-white.png'
import { ROUTES_PATHS } from 'core/routes'

export default function NotificationItem(props) {
  const {
    notification,
    markNotificationAsReadMutation,
    deletePostMutation,
    getNotificationData,
  } = props

  const history = useHistory()

  const moreOptionsState = usePopupState({
    variant: 'popover',
    popupId: `moreOptions`,
  })

  const options = [
    ...(!notification.read
      ? [
          {
            id: 'markAsRead',
            label: 'Mark as Read',
            Icon: MarkAsReadIcon,
            onClick: () => markNotificationAsReadMutation.mutate(notification),
          },
        ]
      : []),
    {
      id: 'delete',
      label: 'Delete',
      Icon: DeleteIcon,
      onClick: () => deletePostMutation.mutate(notification.id),
    },
  ]

  const showLoading =
    (deletePostMutation.isLoading &&
      deletePostMutation.variables === notification.id) ||
    (markNotificationAsReadMutation.isLoading &&
      markNotificationAsReadMutation.variables?.id === notification.id)

  const {
    avatar = sparckLogoWhite,
    title = '',
    description = '',
    path = ROUTES_PATHS.dashboard,
    actionLabel = '',
    date,
  }: any = getNotificationData(notification)

  return (
    <Box key={notification.id} width={1}>
      <Divider />
      <Box
        p={2}
        sx={{
          ...(!notification.read && {
            outline: theme => `1px solid ${theme.palette.primary.main}`,
          }),
          position: 'relative',
          backgroundColor: theme =>
            notification.read
              ? 'inherit'
              : alpha(theme.palette.primary.main, 0.07),
        }}
      >
        {showLoading && (
          <Box
            sx={{
              position: 'absolute',
              height: 1,
              width: 1,
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              bgcolor: 'rgba(255, 255, 255, 0.5)',
              zIndex: 1000,
            }}
          >
            <Grid
              container
              justifyContent='center'
              alignItems='center'
              sx={{ height: 1 }}
            >
              <CircularProgress size={40} />
            </Grid>
          </Box>
        )}
        <Grid item xs={12} key={notification.id}>
          <Grid
            container
            spacing={2}
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item>
              <Avatar
                sx={{
                  backgroundColor: notification.read
                    ? 'text.veryLight'
                    : 'primary.main',
                  padding: avatar === sparckLogoWhite ? 1.5 : 0,
                }}
                src={avatar}
              />
            </Grid>

            <Grid item xs>
              <Typography
                variant='body2'
                color={notification.read ? 'text.light' : 'text.primary'}
                style={{
                  wordBreak: 'break-word',
                  marginBottom: 4,
                }}
              >
                {title}
              </Typography>
              {get(
                notification,
                `notifiable.content.${date ?? 'created_at'}`,
              ) && (
                <Typography
                  variant='body2'
                  color={notification.read ? 'text.light' : 'text.secondary'}
                  style={{
                    wordBreak: 'break-word',
                    marginBottom: 8,
                  }}
                >
                  {formatRelative(
                    parseISO(
                      get(
                        notification,
                        `notifiable.content.${date ?? 'created_at'}`,
                      ),
                    ),
                    new Date(),
                  )}
                </Typography>
              )}

              {description && (
                <Typography
                  variant='body1'
                  color={notification.read ? 'text.light' : 'text.secondary'}
                  style={{
                    wordBreak: 'break-word',
                    marginBottom: 8,
                  }}
                >
                  {description}
                </Typography>
              )}

              <Grid container justifyContent='space-between'>
                <Button
                  size='small'
                  color={notification.read ? 'info' : 'primary'}
                  sx={{
                    fontWeight: notification.read ? 'inherit' : 600,
                  }}
                  onClick={() => {
                    markNotificationAsReadMutation.mutate(notification)
                    history.push(path)
                  }}
                >
                  {actionLabel}
                </Button>

                <IconButton
                  {...bindTrigger(moreOptionsState)}
                  size='small'
                  color='info'
                >
                  <MoreOptionsIcon />
                </IconButton>

                <Popover
                  {...bindPopover(moreOptionsState)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <NotificationItemOptions
                    options={options}
                    moreOptionsState={moreOptionsState}
                  />
                </Popover>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

function NotificationItemOptions({ options, moreOptionsState }) {
  return (
    <List>
      {options.map(option => (
        <ListItem key={option.id} disablePadding>
          <ListItemButton
            onClick={() => {
              option.onClick()
              moreOptionsState.close()
            }}
          >
            <ListItemIcon sx={{ minWidth: 40 }}>
              <option.Icon />
            </ListItemIcon>
            <ListItemText primary={option.label} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}
