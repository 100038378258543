import { useState, useEffect } from 'react'

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { groupBy } from 'lodash'
import { useIntl } from 'react-intl'
import { useMutation, useQuery } from 'react-query'

import { allowedDemographicsCategories } from 'constants/demographics'
import { LibrarySelection } from 'components'
import { API } from 'core/requests'
import { t } from 'i18n'

const MAX_ROWS_PER_PAGE = 10

const getDemographicsByCategory = demographics =>
  Object.keys(groupBy(demographics, 'demographic_category_id')).map(
    demographic => ({
      category: +demographic,
      demographics: groupBy(demographics, 'demographic_category_id')[
        demographic
      ].map(d => d.id),
    }),
  )

const getDemographicsWithCategories = demographicCategory =>
  demographicCategory.demographics_attributes.map(demographic => ({
    ...demographic,
    demographic_category_id: demographicCategory.id,
  }))

export default function UsersAdvancedSearch(props) {
  const { selectedIds, onSelectEmployees } = props

  const intl = useIntl()

  const [demographics, setDemographics] = useState(props.demographics ?? [])
  const [roles, setRoles] = useState(props.roles ?? [])
  const [textFilter, setTextFilter] = useState('')
  const [demographicsFilters, setDemographicsFilters] = useState<any[]>([])
  const [rolesFilters, setRolesFilters] = useState<any[]>([])
  const [employeesByDemographics, setEmployeesByDemographics] = useState([])

  useQuery('getDemographics', () => API.get('demographics'), {
    enabled: !props?.demographics?.length,
    onSuccess: response => {
      if (response?.length > 0) setDemographics(response)
    },
  })

  useQuery('getRoles', () => API.get('roles'), {
    enabled: !props?.roles?.length,
    onSuccess: response => {
      if (response?.length > 0) setRoles(response)
    },
  })

  const {
    mutate: fetchEmployeesByDemographics,
    isLoading: fetchEmployeesByDemographicsIsLoading,
  } = useMutation(
    () =>
      API.create('usersAdvancedSearch', {
        avatars: false,
        demographics_criteria: getDemographicsByCategory(demographicsFilters),
        roles: rolesFilters?.map(role => role?.id),
        q: textFilter,
      }),
    {
      onSuccess: (response: any) => {
        setEmployeesByDemographics(response)
      },
    },
  )

  const handleFiltersChange = (filters, setFilters, item) => {
    if (filters.map(d => d.id).includes(item.id)) {
      setFilters(filters.filter(d => d.id !== item.id))
    } else {
      setFilters([...filters, item])
    }
  }

  useEffect(() => {
    fetchEmployeesByDemographics()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container spacing={2} alignItems='center'>
      <Grid item xs={12} sm>
        <TextField
          id='textFilter'
          name='textFilter'
          label={t(
            'budget.manageBudgetDistributionIndividual.labels.nameOrTitle',
          )}
          value={textFilter}
          onChange={event => setTextFilter(event.target.value)}
          size='small'
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm>
        <FormControl fullWidth size='small'>
          <InputLabel htmlFor='demographics-select'>
            {t('budget.manageBudgetDistributionIndividual.labels.demographics')}
          </InputLabel>
          <Select
            id='demographics-select'
            label={t(
              'budget.manageBudgetDistributionIndividual.labels.demographics',
            )}
            multiple
            value={demographicsFilters}
            renderValue={(selected: any[]) =>
              selected.map(d => d.name).join(', ')
            }
            size='small'
            fullWidth
          >
            {demographics
              ?.filter(d => allowedDemographicsCategories.includes(d.category))
              .map(demographicCategory => [
                <ListSubheader key={demographicCategory.id}>
                  {demographicCategory.name}
                </ListSubheader>,
                getDemographicsWithCategories(demographicCategory).map(
                  (demographic: any) => (
                    <MenuItem
                      key={demographic.id}
                      value={demographic}
                      onClick={() =>
                        handleFiltersChange(
                          demographicsFilters,
                          setDemographicsFilters,
                          demographic,
                        )
                      }
                    >
                      <Checkbox
                        checked={
                          demographicsFilters
                            .map(d => d.id)
                            .indexOf(demographic.id) > -1
                        }
                      />
                      <ListItemText primary={demographic.name} />
                    </MenuItem>
                  ),
                ),
              ])}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm>
        <FormControl fullWidth size='small'>
          <InputLabel htmlFor='roles-select'>
            {t('budget.manageBudgetDistributionIndividual.labels.roles')}
          </InputLabel>
          <Select
            id='roles-select'
            label={t('budget.manageBudgetDistributionIndividual.labels.roles')}
            multiple
            value={rolesFilters}
            renderValue={(selected: any[]) =>
              selected.map(d => d.name).join(', ')
            }
            size='small'
            fullWidth
          >
            {roles.map(role => [
              <MenuItem
                key={role.id}
                value={role}
                onClick={() =>
                  handleFiltersChange(rolesFilters, setRolesFilters, role)
                }
              >
                <Checkbox
                  checked={rolesFilters.map(d => d.id).indexOf(role.id) > -1}
                />
                <ListItemText primary={role.name} />
              </MenuItem>,
            ])}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm='auto'>
        <Button
          variant='contained'
          color='primary'
          onClick={() => fetchEmployeesByDemographics()}
        >
          {t('budget.manageBudgetDistributionIndividual.search')}
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Box width={1} mt={3}>
          {employeesByDemographics?.length === 0 &&
          !fetchEmployeesByDemographicsIsLoading ? (
            <Typography variant='body1' color='text.secondary' align='center'>
              {t('budget.manageBudgetDistributionIndividual.noEmployeesFound')}
            </Typography>
          ) : (
            <LibrarySelection
              title={intl.formatMessage({ id: 'employees.title' })}
              rows={employeesByDemographics}
              columns={[
                {
                  id: 'full_name',
                  label: intl.formatMessage({
                    id: 'firstName',
                  }),
                },
                {
                  id: 'email',
                  label: intl.formatMessage({ id: 'email' }),
                },
              ]}
              orderBy='full_name'
              selectedIds={selectedIds}
              rowsPerPage={
                employeesByDemographics?.length <= MAX_ROWS_PER_PAGE
                  ? employeesByDemographics?.length
                  : MAX_ROWS_PER_PAGE
              }
              onSelectClick={onSelectEmployees}
            />
          )}
          {fetchEmployeesByDemographicsIsLoading && <LinearProgress />}
        </Box>
      </Grid>
    </Grid>
  )
}
