export const employeeUploadFields = {
  company_internal_id:
    'components.employeesUploadComponent.employeeUploadFields.companyInternalId',
  first_name: 'firstName',
  last_name: 'lastName',

  email: 'email',
  cell_number:
    'components.employeesUploadComponent.employeeUploadFields.cellNumber',

  title: 'components.employeesUploadComponent.employeeUploadFields.title',
  hired_at: 'components.employeesUploadComponent.employeeUploadFields.hiredAt',
  gender: 'components.employeesUploadComponent.employeeUploadFields.gender',
  ethnicity:
    'components.employeesUploadComponent.employeeUploadFields.ethnicity',
  date_of_birth:
    'components.employeesUploadComponent.employeeUploadFields.dateOfBirth',
  job_type: 'components.employeesUploadComponent.employeeUploadFields.jobType',
  business_unit:
    'components.employeesUploadComponent.employeeUploadFields.businessUnit',
  department: 'department',
  region: 'components.employeesUploadComponent.employeeUploadFields.region',
  territory:
    'components.employeesUploadComponent.employeeUploadFields.territory',
  location:
    'components.employeesUploadComponent.employeeUploadFields.locationName',
  city: 'components.employeesUploadComponent.employeeUploadFields.city',
  state: 'components.employeesUploadComponent.employeeUploadFields.state',
  country: 'components.employeesUploadComponent.employeeUploadFields.country',
  postal_code:
    'components.employeesUploadComponent.employeeUploadFields.postalCode',
  performance_level:
    'components.employeesUploadComponent.employeeUploadFields.performanceLevel',
  high_value_talent:
    'components.employeesUploadComponent.employeeUploadFields.highValueTalent',
  roles: 'components.employeesUploadComponent.employeeUploadFields.roles',
  superiors:
    'components.employeesUploadComponent.employeeUploadFields.superiors',
  preferred_language:
    'components.employeesUploadComponent.employeeUploadFields.preferredLanguage',
}
