import { CheckCircle as CheckIcon } from '@mui/icons-material'
import { Box, Button, Grid, Typography } from '@mui/material'

import { MFABackupCodes } from '..'
import { MFAFactorSetupProps } from '../MFAFactorSetup/MFAFactorSetup'
import { MFAFactorType } from 'enums/multiFactorAuthentication'
import { t } from 'i18n'

type Props = MFAFactorSetupProps & {
  activeStep: any
  userClickedNextPage: boolean
  setUserClickedNextPage(value: boolean): void
  finishButton?: any
}

export default function MFASuccess(props: Props) {
  const { finishButton, selectedFactor } = props

  return (
    <Grid container justifyContent='center'>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <CheckIcon color='primary' sx={{ textAlign: 'center' }} />
      </Grid>

      <Grid item xs={12}>
        <Typography variant='h6' color='primary' align='center' sx={{ mb: 2 }}>
          {t('mfa.successfullyEnabled')}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ mt: 2 }}>
        <Typography align='center' variant='h6'>
          {t('mfa.yourBackupCodes')}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ mt: 1, mb: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ width: '60%' }}>
            <Typography align='center'>
              {t('mfa.yourBackupCodesDescription')}
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} sx={{ my: 3 }}>
        <MFABackupCodes {...props} />
      </Grid>

      {selectedFactor?.id === MFAFactorType.SMS && (
        <Grid item xs={12} sx={{ px: 2, mt: 2 }}>
          <Typography align='center'>{t('mfa.footerMessage')}</Typography>
        </Grid>
      )}

      {finishButton && (
        <Grid item xs={12}>
          <Grid container justifyContent='center'>
            <Button
              variant='contained'
              color='primary'
              size='large'
              onClick={finishButton.onClick}
              sx={{ mt: 4, mb: 3 }}
            >
              {finishButton.label}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
