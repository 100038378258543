import { get } from 'lodash'
import { useIntl } from 'react-intl'
import * as yup from 'yup'

import { useFetchSuggestions } from './ManageReward.queries'
import { FormHandler } from 'components'
import { API } from 'core/requests'
import { ActiveInactiveStatus } from 'enums/app'
import { t } from 'i18n'
import { RewardType } from 'types'
import ManageRewardForm from '../manageRewardForm/ManageRewardForm'

const customRewardOption = { id: null, name: 'Custom Reward', _custom: true }

const validationSchema = yup.object().shape({
  suggestion: yup
    .object()
    .nullable()
    .when('name', {
      is: name => !name,
      then: yup
        .object()
        .nullable()
        .required(t('rewards.manageReward.validations.suggestionRequired')),
    }),
  name: yup.string().when('suggestion_id', {
    is: suggestion_id => suggestion_id === customRewardOption.id,
    then: yup
      .string()
      .required(t('rewards.manageReward.validations.rewardNameRequired')),
  }),
  description: yup.string().nullable(),
  amount: yup
    .number()
    .nullable()
    .required(t('rewards.manageReward.validations.monetaryValueRequired')),
  inventory_quantity: yup
    .number()
    .nullable()
    .when('has_inventory', {
      is: has_inventory => has_inventory,
      then: yup
        .number()
        .nullable()
        .min(0, t('rewards.manageReward.validations.minimumInventoryQuantity'))
        .max(
          9999,
          t('rewards.manageReward.validations.maximumInventoryQuantity'),
        )
        .required(
          t('rewards.manageReward.validations.inventoryQuantityRequired'),
        ),
    }),
})

export default function ManageReward({ dialog, closeDialog, fetchRows }: any) {
  const intl = useIntl()

  const initialValues: RewardType = dialog.data
    ? {
      ...dialog!.data,
      suggestion:
        get(dialog.data, 'suggestion.id') === null
          ? customRewardOption
          : get(dialog.data, 'suggestion'),
      suggestion_id: get(dialog.data, 'suggestion.id'),
      name: get(dialog.data, 'name'),
      description: get(dialog.data, 'suggestion.description')
        ? get(dialog.data, 'suggestion.description')
        : get(dialog.data, 'description'),
      amount: get(dialog.data, 'amount'),
      has_inventory: get(dialog.data, 'has_inventory'),
      inventory_quantity: get(dialog.data, 'inventory_quantity'),
    }
    : {
      suggestion: null,
      suggestion_id: null,
      name: '',
      description: '',
      amount: null,
      has_inventory: false,
      inventory_quantity: null,
      status: ActiveInactiveStatus.Active,
    }

  const { isLoading: isSuggestionsLoading, data: suggestions } =
    useFetchSuggestions()

  return (
    <FormHandler
      title={intl.formatMessage({ id: 'rewards.title' })}
      requestUrl='rewards'
      customMutationRequest={(payload: any) => {
        return API.createOrUpdate('rewards', {
          ...payload,
          suggestion_id:
            payload.suggestion_id !== 'custom' ? payload.suggestion_id : null,
        })
      }}
      data={dialog.data}
      closeDialog={closeDialog}
      fetchRows={fetchRows}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ formik }) => (<ManageRewardForm formik={formik} suggestions={suggestions} isSuggestionsLoading={isSuggestionsLoading} customRewardOption={customRewardOption} />)}
    </FormHandler>
  )
}
