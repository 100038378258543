import { GlobalStyles, useTheme } from '@mui/material'

export default function GlobalStyle() {
  const theme: any = useTheme()

  return (
    <GlobalStyles
      styles={
        {
          html: {
            scrollBehavior: 'smooth !important',
          },
          body: {
            fontFamily: 'Poppins, sans-serif !important',
            backgroundColor: `${theme.palette.background.default} !important`,
          },

          a: {
            textDecoration: 'none !important',
            cursor: 'pointer !important',
            color: theme.palette.primary.main,
            fontWeight: 500,
          },
          '& #client-snackbar': {
            color: '#222 !important',
            fontWeight: '500 !important',
            fontSize: '14px !important',
            maxWidth: '640px !important',
          },
          '& #notistack-snackbar': {
            color: '#222 !important',
            fontWeight: '500 !important',
            fontSize: '14px !important',
            maxWidth: '640px !important',
          },
          '& .SnackbarItem-variantSuccess': {
            backgroundColor: '#ceffe8 !important',
          },
          '& .SnackbarItem-variantError': {
            backgroundColor: '#ffcece !important',
          },
          '& .SnackbarItem-variantWarning': {
            backgroundColor: '#fff6ce !important',
          },
          '& .SnackbarItem-variantInfo': {
            backgroundColor: '#ceefff !important',
          },
          '& .MuiTableHead-root > .MuiTableRow-head > th': {
            whiteSpace: 'nowrap',
          },

          '& .sparck-table-with-actions table': {
            borderCollapse: 'separate' /* Don't collapse */,
          },

          '& .sparck-table-with-actions .MuiBox-root table td:last-child': {
            position: 'sticky',
            right: 0,
            backgroundColor: '#FFF',
            boxShadow: '0px 6px 4px 0px rgb(179 179 179 / 75%)',
          },
          '& .sparck-table-with-actions .MuiBox-root table th:last-child': {
            position: 'sticky',
            right: 0,
            backgroundColor: '#FFF',
            boxShadow: '0px 6px 4px 0px rgb(179 179 179 / 75%)',
          },
        } as any
      }
    />
  )
}
