import { Typography } from '@mui/material'

import wave from '../../../../assets/images/waves/wave-06.png'
import { t } from 'i18n'

export default function MyImpactHeader(props) {
  const { profileId, profileState } = props

  return (
    <div style={{ position: 'relative' }}>
      <img src={wave} alt='' style={{ width: '100%', height: 80, borderRadius: 15 }} />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 10,
        }}
      >
        <Typography
          variant='h6'
          color='text.white'
          align='center'
          fontWeight={600}
          paragraph
          style={{ position: 'absolute', top: 20 }}
        >
          {profileId
            ? t('profile.impact.userImpact', {
                userName: profileState.first_name,
              })
            : t('profile.impact.myImpact')}
        </Typography>
      </div>
    </div>
  )
}
