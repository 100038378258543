import { Box, Grid, Typography } from '@mui/material'
import {
  AccessTime as TimeIcon,
  TrackChanges as WayIcon,
  Redeem as RewardIcon,
} from '@mui/icons-material'

import { ManagerCertificationSection } from '../../components'

import { t } from 'i18n'

const minutes = [
  {
    id: 1,
    description: t(
      'managerCertification.managerCertificationComponent.minutes.minuteOneDescription',
    ),
  },
  {
    id: 2,
    description: t(
      'managerCertification.managerCertificationComponent.minutes.minuteTwoDescription',
    ),
  },
  {
    id: 3,
    description: t(
      'managerCertification.managerCertificationComponent.minutes.minuteThreeDescription',
    ),
  },
]

const secretComponents = [
  {
    id: 1,
    Icon: TimeIcon,
    description: t(
      'managerCertification.managerCertificationComponent.secretComponents.rightTime',
    ),
  },
  {
    id: 2,
    Icon: WayIcon,
    description: t(
      'managerCertification.managerCertificationComponent.secretComponents.rightWay',
    ),
  },
  {
    id: 3,
    Icon: RewardIcon,
    description: t(
      'managerCertification.managerCertificationComponent.secretComponents.rightReward',
    ),
  },
]

export default function ManagerCertificationEmployeeRecognition() {
  return (
    <>
      <ManagerCertificationSection>
        <Typography
          color='text.primary'
          variant='h5'
          fontWeight={600}
          paragraph
        >
          {t(
            'managerCertification.managerCertificationComponent.sections.welcome.title',
          )}
        </Typography>
        <Typography
          color='text.secondary'
          variant='body1'
          paragraph
          align='justify'
        >
          {t(
            'managerCertification.managerCertificationComponent.sections.welcome.description.firstParagraph',
          )}
        </Typography>

        <Box component='ul' color='text.secondary' pb={2}>
          {minutes.map(minute => (
            <Box component='li' key={minute.id} mb={1}>
              {minute.description}
            </Box>
          ))}
        </Box>

        <Typography
          color='text.secondary'
          variant='body1'
          paragraph
          align='justify'
        >
          {t(
            'managerCertification.managerCertificationComponent.sections.welcome.description.middleParagraph',
          )}
        </Typography>
        <Typography
          color='text.secondary'
          paragraph
          variant='body1'
          align='justify'
        >
          {t(
            'managerCertification.managerCertificationComponent.sections.welcome.description.lastParagraph',
          )}
        </Typography>
        <Grid
          container
          alignItems='center'
          justifyContent='space-around'
          spacing={2}
        >
          {secretComponents.map(component => (
            <Grid item key={component.id} xs={12} sm={4}>
              <Grid
                container
                direction='column'
                rowSpacing={1}
                alignItems='center'
                spacing={2}
              >
                <Grid item xs={12}>
                  <component.Icon
                    sx={{ fontSize: 80, color: 'text.secondary' }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant='body1'
                    color='text.secondary'
                    fontWeight={600}
                  >
                    {component.description}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </ManagerCertificationSection>
    </>
  )
}
