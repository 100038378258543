import { Button, Grid, Paper, Typography } from '@mui/material'

import { downloadBudgetActivity, downloadRewardsReport } from 'core/requests'
import { t } from 'i18n'

type ReportType = {
  id: string
  title: string
  description: string
  downloadReport(): void
}

export default function BudgetReports() {
  const reports: ReportType[] = [
    {
      id: 'rewardsReport',
      title: t('budget.budgetReports.rewardsReport.title'),
      description: t('budget.budgetReports.rewardsReport.description'),
      downloadReport: downloadRewardsReport,
    },
    {
      id: 'budgetActivityReport',
      title: t('budget.budgetReports.budgetActivityReport.title'),
      description: t('budget.budgetReports.budgetActivityReport.description'),
      downloadReport: downloadBudgetActivity,
    },
  ]

  return (
    <Grid container spacing={2}>
      {reports.map((report: ReportType) => (
        <Grid item xs={12} md={6} key={report.id}>
          <BudgetReportCard {...report} />
        </Grid>
      ))}
    </Grid>
  )
}

function BudgetReportCard({
  id,
  title,
  description,
  downloadReport,
}: ReportType) {
  return (
    <Paper sx={{ p: 2.5, width: 1 }} key={id}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h6' color='text.primary' paragraph>
            {title}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='body1' color='text.secondary' paragraph>
            {description}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent='flex-end'>
            <Button
              data-cy='rewardsReport'
              color='primary'
              variant='contained'
              onClick={downloadReport}
            >
              {t('budget.budgetReports.downloadReport')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}
