// import { Period } from 'enums/period'
import { t } from 'i18n'

export const PERIODS = [
  {
    id: 'all_time',
    label: t('periodLabels.allTime'),
  },
  {
    id: 'ytd',
    label: t('periodLabels.thisYear'),
  },
  {
    id: 'qtd',
    label: t('periodLabels.thisQuarter'),
  },
  {
    id: 'mtd',
    label: t('periodLabels.thisMonth'),
  },
]
