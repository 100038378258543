import React from 'react'
import { Grid } from '@mui/material'
import {
  EmployeesDirectoryHeader,
  EmployeesDirectoryItems,
  EmployeesDirectorySearch,
  EmployeesDirectoryPagination,
} from '..'
import { EmployeeType } from 'types'
import { FetchOptions } from 'react-query/types/core/query'

type Props = {
  slug: string
  fetchEmployees: (options?: FetchOptions | undefined) => any
  employeesCount: number
  employees: EmployeeType[]
  employeesIsLoading: boolean
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  pageSize: number
  totalCount: number
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
  favorite: ({ favorite: { user_id: number } }) => void
  unfavorite: (employeeFavoriteId: number) => void
  favoritesOnly: boolean
  setFavoritesOnly: React.Dispatch<React.SetStateAction<boolean>>
}

export default function EmployeesDirectoryComponent(props: Props) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <EmployeesDirectoryHeader {...props} />
      </Grid>

      <Grid item xs={12}>
        <EmployeesDirectorySearch {...props} />
      </Grid>

      <Grid item xs={12}>
        <EmployeesDirectoryItems {...props} />
      </Grid>

      <Grid item xs={12}>
        <EmployeesDirectoryPagination {...props} />
      </Grid>
    </Grid>
  )
}
