import { Box, Grid, Paper, Typography } from '@mui/material'
import ReactDomServer from 'react-dom/server'
import { useIntl } from 'react-intl'

import AlignmentIcon from 'assets/svg/beheardReport/AlignmentIcon'
import FitIcon from 'assets/svg/beheardReport/FitIcon'
import GrowthIcon from 'assets/svg/beheardReport/GrowthIcon'
import TeamIcon from 'assets/svg/beheardReport/TeamIcon'
import TurnoverIcon from 'assets/svg/beheardReport/TurnoverIcon'
import ValuingIcon from 'assets/svg/beheardReport/ValuingIcon'
import { SurveyDriverScore } from 'enums/survey'
import { t } from 'i18n'
import { getDriverScoreDescription } from 'utils/survey'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function BeheardReportOverview({ Title, Description }: any) {
  const intl = useIntl()

  const overviewItems = [
    {
      title: intl.formatMessage({
        id: 'beheardSurveyDrivers.alignment',
      }),
      description: getDriverScoreDescription(SurveyDriverScore.Alignment),
      Icon: AlignmentIcon,
    },
    {
      title: intl.formatMessage({
        id: 'beheardSurveyDrivers.growth',
      }),
      description: getDriverScoreDescription(SurveyDriverScore.Growth),
      Icon: GrowthIcon,
    },
    {
      title: intl.formatMessage({
        id: 'beheardSurveyDrivers.turnover',
      }),
      description: intl.formatMessage({
        id: 'beheardReport.turnoverOverviewDescription',
      }),
      Icon: TurnoverIcon,
    },
    {
      title: intl.formatMessage({
        id: 'beheardSurveyDrivers.valuing',
      }),
      description: getDriverScoreDescription(SurveyDriverScore.Valuing),
      Icon: ValuingIcon,
    },
    {
      title: intl.formatMessage({
        id: 'beheardSurveyDrivers.fit',
      }),
      description: getDriverScoreDescription(SurveyDriverScore.Fit),
      Icon: FitIcon,
    },
    {
      title: intl.formatMessage({
        id: 'beheardSurveyDrivers.team',
      }),
      description: getDriverScoreDescription(SurveyDriverScore.Team),
      Icon: TeamIcon,
    },
  ]

  return (
    <Grid container>
      <Grid item xs={12}>
        <Description>
          {t('beheardReport.overview.overviewDescription')}
        </Description>
      </Grid>

      {overviewItems.map(overviewItem => (
        <Grid item xs={12} sm={4} key={overviewItem.title}>
          <Box my={1}>
            <Grid container justifyContent='center'>
              <Grid item xs={12}>
                <Grid container justifyContent='center'>
                  <Paper
                    elevation={4}
                    style={{
                      borderRadius: '100%',
                      width: 100,
                      height: 100,
                      margin: '16px 0px',
                    }}
                  >
                    <img
                      alt={overviewItem.title}
                      src={`data:image/svg+xml;base64,${btoa(
                        ReactDomServer.renderToString(
                          <overviewItem.Icon xmlns='http://www.w3.org/2000/svg' />,
                        ),
                      )}`}
                    />
                  </Paper>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant='h6'
                  color='text.primary'
                  fontWeight={600}
                  align='center'
                >
                  {overviewItem.title}
                </Typography>
              </Grid>

              <Typography
                variant='body2'
                color='text.primary'
                fontWeight={400}
                align='center'
                style={{ padding: '0px 25px' }}
              >
                {overviewItem.description}
              </Typography>
            </Grid>
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}
