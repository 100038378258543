import { Avatar, Box, Grid, Typography, useTheme } from '@mui/material'
import {
  ThumbUpOutlined as EngagedIcon,
  ThumbDownOutlined as DisengagedIcon,
} from '@mui/icons-material'
import { useIntl } from 'react-intl'

import { characteristics } from 'constants/beheardReport'
import { useLocale } from 'contexts'
import { Locale } from 'enums/locale'
import { t } from 'i18n'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function BeheardReportOverview({ Title, Description }: any) {
  const theme = useTheme()
  const { locale }: any = useLocale()
  const intl = useIntl()

  const definitionItems = [
    {
      title: intl.formatMessage({
        id: 'beheardReport.characteristics.engagedEmployees.title',
      }),
      Icon: (
        <Avatar style={{ padding: 16 }}>
          <EngagedIcon fontSize='large' />
        </Avatar>
      ),
      characteristics: characteristics[locale ?? Locale.English]['engaged'],
    },
    {
      title: intl.formatMessage({
        id: 'beheardReport.characteristics.disengagedEmployees.title',
      }),
      Icon: (
        <Avatar
          style={{ padding: 16, backgroundColor: theme.palette.error.light }}
        >
          <DisengagedIcon fontSize='large' />
        </Avatar>
      ),
      characteristics: characteristics[locale ?? Locale.English]['disengaged'],
    },
  ]

  return (
    <Grid container>
      <Grid item xs={12}>
        <Description>
          {t('beheardReport.characteristics.description')}
        </Description>
      </Grid>

      {definitionItems.map(definitionItem => (
        <Grid item xs={12} sm={6} key={definitionItem.title}>
          <Box my={3}>
            <Grid container justifyContent='center'>
              <Grid item xs={12} sm={11} md={10} lg={9}>
                <Grid container direction='column' justifyContent='center'>
                  <Box my={2}>{definitionItem.Icon}</Box>
                  <Typography
                    variant='h6'
                    color='text.primary'
                    fontWeight={600}
                    paragraph
                  >
                    {definitionItem.title}
                  </Typography>
                  {definitionItem.characteristics.map(characteristic => (
                    <ul
                      key={characteristic}
                      style={{ marginTop: 0, width: '100%' }}
                    >
                      <li>
                        <Typography
                          variant='body2'
                          color='text.primary'
                          fontWeight={400}
                        >
                          {characteristic}
                        </Typography>
                      </li>
                    </ul>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}
