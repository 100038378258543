import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material'
import { Button, Grid, Menu, MenuItem } from '@mui/material'
import {
  bindTrigger,
  usePopupState,
  bindMenu,
} from 'material-ui-popup-state/hooks'
import { useMutation, useQueryClient } from 'react-query'

import { useCompany, useLocale, useUser } from 'contexts'
import { LANGUAGES } from 'constants/locale'
import { API } from 'core/requests'
import { Locale } from 'enums/locale'
import { useLocation } from 'react-router-dom'

export default function LanguagePicker() {
  const { user } = useUser()!
  const { company } = useCompany()!
  const { locale, setLocale, setAPILanguageChanged } = useLocale()!
  
  const queryClient = useQueryClient()
  const location = useLocation()

  const localeMenuState = usePopupState({
    variant: 'popover',
    popupId: `localeMenu`,
  })

  const changeLanguageMutation = useMutation(
    (selectedLanguage: any) =>
      API.update('users', {
        id: user.id,
        preferred_language: selectedLanguage,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries()
        setAPILanguageChanged(true)
      },
    },
  )

  const handleLanguageChange = language => {
    setLocale(language.value)
    localStorage.setItem('locale', language.value)
    changeLanguageMutation.mutate(language.id)

    bindMenu(localeMenuState).onClose()
  }

  const renderLanguages = () => {
    return (
      <>
        <Grid item>
          <Button
            size='small'
            sx={{
              fontSize: '1rem',
              fontWeight: 600,
              color: 'text.secondary',
              minWidth: '40px',
            }}
            {...bindTrigger(localeMenuState)}
          >
            {LANGUAGES.find(
              language => language.value === locale ?? Locale.English,
            )?.id.toUpperCase()}

            <ArrowDropDownIcon />
          </Button>
        </Grid>

        <Menu {...bindMenu(localeMenuState)}>
          {LANGUAGES.map(language => (
            <MenuItem
              key={language.value}
              value={language.value}
              onClick={() => handleLanguageChange(language)}
            >
              {language.label}
            </MenuItem>
          ))}
        </Menu>
      </>
    )
  }

  if (
    (location.pathname === "/landing/survey" ||  location.pathname === "/survey") &&
    company &&
    company.allow_internationalization
  ) {
    return <Grid item>{renderLanguages()}</Grid>
  } else {
    if (locale !== 'en-us') {
      handleLanguageChange(LANGUAGES[0])
    }
    return null
  }
}
