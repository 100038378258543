import { useState, useCallback } from 'react'

import { get } from 'lodash'
import { Box, Button, CircularProgress, Grid, Slider } from '@mui/material'
import { useSnackbar } from 'notistack'
import Cropper from 'react-easy-crop'

import { DialogTeleport, Dropzone } from 'components'
import { useUser } from 'contexts'
import { t } from 'i18n'
import { getCroppedImage } from 'utils/canvas'

const PICTURE_OUTPUT_FORMAT = 'image/png'

export default function ProfileChangePicture({
  dialogChangePicture,
  setDialogChangePicture,
  uploadPicture,
  setCroppedPicture,
  changePictureIsLoading,
  setChangePictureIsLoading,
}) {
  const [imageSrc, setImageSrc] = useState<any>(null)
  const [crop, setCrop] = useState<any>({ x: 0, y: 0 })
  const [zoom, setZoom] = useState<any>(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null)

  const { user } = useUser()!
  const { enqueueSnackbar } = useSnackbar()

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const saveProfilePicture = useCallback(async () => {
    try {
      setChangePictureIsLoading(true)
      const canvas: any = await getCroppedImage(imageSrc, croppedAreaPixels)

      const picture: any = await new Promise(resolve => {
        canvas.toBlob(file => {
          resolve(file)
        }, PICTURE_OUTPUT_FORMAT)
      })

      setCroppedPicture(picture)

      const formData = new FormData()

      formData.append('file', picture)
      formData.append('attach_to', 'User')
      formData.append('record_id', get(user, 'sub'))
      formData.append('record_field', 'avatar')

      uploadPicture(formData)
    } catch (e) {
      console.error(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSrc, croppedAreaPixels])

  const onFileChange = async files => {
    if (files.length > 0) {
      const selectedFile = files[0]

      const imageDataUrl = await readFile(selectedFile)

      setImageSrc(imageDataUrl)
    }
  }

  function readFile(file) {
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  return (
    <DialogTeleport
      dialogTitle={t('profile.changeProfilePicture.dialogTitle')}
      dialogAction={null}
      dialogSize='md'
      dialogOpen={dialogChangePicture}
      handleDialogClose={() => setDialogChangePicture(false)}
      isFullWidth
    >
      <Grid container justifyContent='center'>
        <Grid item xs={12} sm={10} md={9} lg={8}>
          <Grid container data-cy='drag_and_drop_picture'>
            {imageSrc ? (
              <Grid item xs={12}>
                <Box
                  component='div'
                  sx={{
                    position: 'relative',
                    width: '100%',
                    height: '400px',
                    background: '#333',
                  }}
                >
                  <Cropper
                    image={imageSrc}
                    crop={crop}
                    cropShape='round'
                    zoom={zoom}
                    aspect={1 / 1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />
                </Box>

                <Grid container>
                  <Grid item xs={12}>
                    <Box my={2}>
                      <Slider
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby='Zoom'
                        onChange={(e, zoom) => setZoom(zoom)}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Dropzone
                  allowedFileTypes='image/*'
                  multiple={false}
                  onDrop={async (acceptedFiles, fileRejections) => {
                    onFileChange(acceptedFiles)

                    fileRejections.forEach(rejectedFile => {
                      rejectedFile.errors.forEach(error => {
                        enqueueSnackbar(error.message, {
                          variant: 'error',
                        })
                      })
                    })
                  }}
                  label={t('profile.changeProfilePicture.dragAndDrop')}
                />
              </Grid>
            )}
          </Grid>
          {imageSrc && (
            <Grid container spacing={2} justifyContent={'space-between'}>
              <Grid item xs={12} sm='auto'>
                <input
                  id='contained-button-file'
                  type='file'
                  onChange={event => onFileChange(event.target.files)}
                  accept='image/*'
                  style={{ display: 'none' }}
                />
                <label htmlFor='contained-button-file'>
                  <Button
                    variant='outlined'
                    color='primary'
                    component='span'
                    size='large'
                    fullWidth
                  >
                    {t('profile.changeProfilePicture.selectPicture')}
                  </Button>
                </label>
              </Grid>

              <Grid item xs={12} sm='auto'>
                <Button
                  data-cy='save_profile_picture'
                  onClick={saveProfilePicture}
                  variant='contained'
                  color='primary'
                  size='large'
                  disabled={changePictureIsLoading}
                  fullWidth
                >
                  {changePictureIsLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    t('profile.changeProfilePicture.savePicture')
                  )}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </DialogTeleport>
  )
}
