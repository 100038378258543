import { useState } from 'react'

import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import { useMutation } from 'react-query'

import { HRISImportComponent } from './components'
import { API } from 'core/requests'
import { useCompany, useUser } from 'contexts'
import { t } from 'i18n'

const hrisSystems = [
  {
    id: 'trinet_hris',
    label: 'hrisImport.trinet',
  },
]

export default function HRISImport(props: any) {
  const { closeDialog, fetchEmployees } = props
  const { user } = useUser()!
  const { company } = useCompany()!

  const initialHrisSystem: any = {
    id: company ? company.id : user.company.id,
    hris_suggestion: '',
  }

  const [hrisSystem, setHrisSystem] = useState(initialHrisSystem)
  const [importHRISEmployees, setImportHRISEmployees] = useState({
    id: company ? company.id : user.company.id,
    hris: 'trinet_hris',
    hris_id: '',
  })

  const { enqueueSnackbar } = useSnackbar()

  const hrisSuggestionMutation = useMutation(
    (payload: any) => {
      const companyId = get(company, 'id') ?? get(payload, 'id')

      return API.update('companies', payload, {
        pathParams: {
          id: companyId ? companyId : null,
        },
      })
    },
    {
      onSettled: (response: any) => {
        if (response && response.id) {
          enqueueSnackbar(
            t('hrisImport.submittedHRISSystem', {
              hrisSuggestion: response.hris_suggestion,
            }),
            {
              variant: 'success',
            },
          )
          closeDialog()
        }
      },
    },
  )

  const hrisImportMutation = useMutation(
    (payload: any) => API.create('importHRIS', payload),
    {
      onSettled: (response: any) => {
        if (response && response.success) {
          enqueueSnackbar(t('hrisImport.employeesImported'), {
            variant: 'success',
          })
          closeDialog()

          if (fetchEmployees) {
            fetchEmployees()
          }
        }
      },
    },
  )

  return (
    <HRISImportComponent
      hrisSystem={hrisSystem}
      setHrisSystem={setHrisSystem}
      importHRISEmployees={importHRISEmployees}
      setImportHRISEmployees={setImportHRISEmployees}
      hrisSuggestionMutation={hrisSuggestionMutation}
      hrisImportMutation={hrisImportMutation}
      hrisSystems={hrisSystems}
    />
  )
}
