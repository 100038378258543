import { Box, Grid, useTheme, Typography } from '@mui/material'
// import { AccountCircleOutlined as AccountCircleIcon } from '@mui/icons-material'

import { CardColoredWithLogo } from 'components'
import { t } from 'i18n'

export default function CompanyGotQuestions() {
  const theme = useTheme()

  const renderSection = (label: string, values: any[]) => {
    return (
      <Box mb={3}>
        <Box mb={1}>
          <Typography color='text.white' variant='body2' fontWeight={400}>
            {label}
          </Typography>
        </Box>

        {values.map(value => (
          <Grid container key={value.name}>
            {/* <Grid item>
              <Box mr={2}>
                <AccountCircleIcon style={{ color: 'white' }} />
              </Box>
            </Grid> */}

            <Grid item sm>
              <Box mb={1}>
                <Box mb={1}>
                  <Typography
                    color='text.white'
                    variant='body2'
                    fontWeight={400}
                  >
                    {value.name}
                  </Typography>
                  <a href={`mailto:${value.email}`}>
                    <Typography color='text.white' variant='body2' fontWeight={'bold'}>
                      {value.email}
                    </Typography>
                  </a>
                </Box>
              </Box>
            </Grid>
          </Grid>
        ))}
      </Box>
    )
  }

  return (
    <CardColoredWithLogo
      backgroundColor1={theme.palette.primary.main}
      backgroundColor2={theme.palette.primary.light}
      fullHeight
    >
      <Typography color='text.white' variant='h5' paragraph>
        {t('myCompany.companyGotQuestions.cardTitle')}
      </Typography>

      <Typography color='text.white' variant='body2' paragraph>
        {t('myCompany.companyGotQuestions.weAreHereToHelp')}
      </Typography>

      {renderSection(
        t('myCompany.companyGotQuestions.sectionLabels.contactSparckTeam'),
        [{ email: 'hello@sparckco.com' }],
      )}

      {/* {renderSection(
        t('myCompany.companyGotQuestions.sectionLabels.customerExperience'),
        [{ name: 'Abigail Kidd', email: 'abigail.kidd@sparckco.com' }],
      )} */}

      {/* {renderSection(
        t('myCompany.companyGotQuestions.sectionLabels.technicalSupport'),
        [
          {
            name: 'Brent Fitzpatrick',
            email: 'brent.fitzpatrick@sparckco.com',
          },
        ],
      )} */}
    </CardColoredWithLogo>
  )
}
