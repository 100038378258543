import { alpha, Box, Grid, useTheme } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import { useIntl } from 'react-intl'

import { SPARCK_MAX_FILE_SIZE_MB } from 'constants/app'

const getColor = (props, theme) => {
  if (props.isDragAccept) {
    return theme.palette.primary.main
  }
  if (props.isDragReject) {
    return theme.palette.error.main
  }
  if (props.isDragActive) {
    return theme.palette.info.main
  }
  return '#bbb'
}

export default function Dropzone({
  onDrop,
  allowedFileTypes = 'image/*',
  multiple = true,
  label,
}) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: allowedFileTypes,
    onDrop: onDrop,
    maxSize: SPARCK_MAX_FILE_SIZE_MB * 1024 * 1024 * 200000,
    multiple: multiple,
  })

  const intl = useIntl()
  const theme = useTheme()

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          component='div'
          {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
          sx={{
            cursor: 'pointer',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '48px 16px',
            borderWidth: 2,
            borderRadius: 2,
            borderColor: props => alpha(getColor(props, theme), 0.5),
            borderStyle: 'dashed',
            backgroundColor: props => alpha(getColor(props, theme), 0.1),
            color: props => getColor(props, theme),
            fontWeight: 600,
            outline: 'none',
            transitionProperty: 'border, color, backgroundColor',
            transitionDuration: '0.24s',
            transitionTimingFunction: 'ease-in-out',
          }}
        >
          <input {...getInputProps()} />
          <p>
            {label ??
              intl.formatMessage({
                id: 'components.dropzone',
              })}
          </p>
        </Box>
      </Grid>
    </Grid>
  )
}
