import { Grid } from '@mui/material'

import { RolesPicker } from 'components'
import { t } from 'i18n'

export default function ManageRecognitionWhoCanRecognize({ formik, roles }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RolesPicker
          formik={formik}
          roles={roles}
          label={t('recognitions.fields.whichRolesCanSend')}
          selectLabel={t('recognitions.fields.whoCanRecognize')}
          fieldName='roles_who_can_recognize'
        />
      </Grid>
    </Grid>
  )
}
