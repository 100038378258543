import { useQuery } from 'react-query'
import { API } from 'core/requests'

export const useFetchTasks = (): any => {
  return useQuery('tasks', () => API.get('tasks'))
}

export const useFetchReminders = (): any => {
  return useQuery('reminders', () => API.get('reminders'))
}
