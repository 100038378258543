import { useState } from 'react'

import { Box, Button, Grid, Grow, Typography } from '@mui/material'
import ReactPlayer from 'react-player'

import sparckHeaderBottomCurved from 'assets/images/sparck/sparck-header-bottom-curved.png'
import beheardSurveyMobileVideo from 'assets/videos/beheard-survey-mobile.mov'
import { t } from 'i18n'
import { DialogTeleport } from 'components'

export default function PulseSurveyHeader() {
  const [helpDialogOpen, setHelpDialogOpen] = useState(false)

  return (
    <>
      <Grid container justifyContent='center'>
        <Grid item xs={12} sx={{ position: 'relative' }}>
          <Box
            component='div'
            sx={{
              background: `linear-gradient(
              270deg,
              rgba(26, 207, 198, 0.2) 0%,
              rgba(242, 152, 58, 0.2) 100%
            )`,
              position: 'relative',
            }}
          >
            <Box width={1} py={8}>
              <Grow in={true} timeout={1200}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant='h2'
                      color='text.primary'
                      align='center'
                      fontWeight={600}
                    >
                      {t('pulseSurvey.header')}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      variant='h6'
                      color='text.secondary'
                      align='center'
                      fontWeight={500}
                    >
                      {t('pulseSurvey.responsesAreAnonymous')}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Grid container justifyContent='center'>
                      <Grid item xs='auto'>
                        <Button onClick={() => setHelpDialogOpen(true)}>
                          {t('pulseSurvey.needHelp')}{' '}
                          {t('pulseSurvey.clickHere')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grow>
            </Box>
          </Box>
          <Box
            component='img'
            sx={{
              width: '100%',
              height: '80px',
              position: 'absolute',
            }}
            alt='Pulse Survey header bottom curve'
            src={sparckHeaderBottomCurved}
          />
        </Grid>
      </Grid>

      <DialogTeleport
        dialogOpen={helpDialogOpen}
        dialogSize='sm'
        dialogTitle=''
        handleDialogClose={() => setHelpDialogOpen(false)}
        isFullWidth
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='body1' color='text.primary'>
              The survey will only take a minute or two to complete.{' '}
              <b>Each item will have two parts</b>:
            </Typography>
            <ol>
              <li>
                <Typography variant='body1' color='text.primary' sx={{ mb: 2 }}>
                  First, you'll{' '}
                  <b>
                    rate to what extent you agree or disagree with each
                    workplace statement
                  </b>{' '}
                  by choosing one of the faces.
                </Typography>
              </li>

              <li>
                <Typography variant='body1' color='text.primary'>
                  Once you've done that, you'll{' '}
                  <b>rate how important each statement is to you</b> using the
                  stars.
                </Typography>
              </li>
            </ol>

            <Typography variant='body1' color='text.primary' sx={{ mt: 3 }}>
              Check the animated example below.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <ReactPlayer
              url={beheardSurveyMobileVideo}
              width='100%'
              height={'100%'}
              playing
              loop
              muted
              playsInline
              style={{
                minHeight: '100%',
              }}
            />
          </Grid>
        </Grid>
      </DialogTeleport>
    </>
  )
}
