import React, { useState } from 'react'

// import { useMediaQuery } from '@mui/material'

type DarkModeContextType = {
  darkMode: 'light' | 'dark' | 'auto'
  setDarkMode: (value: 'light' | 'dark' | 'auto') => void
}
const DarkModeContext = React.createContext<DarkModeContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

export const DarkModeProvider = ({ children }: Props) => {
  // const darkModeLocalStorage = localStorage.getItem('darkMode')
  // const prefersDarkModeSystem = useMediaQuery('(prefers-color-scheme: dark)')

  const [darkMode, setDarkMode] = useState<'light' | 'dark' | 'auto'>(
    // darkModeLocalStorage ?? prefersDarkModeSystem ? 'dark' : 'light',
    'light',
  )

  // useEffect(() => {
  //   if (!darkModeLocalStorage)
  //     setDarkMode(prefersDarkModeSystem ? 'dark' : 'light')
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [prefersDarkModeSystem])

  return (
    <DarkModeContext.Provider value={{ darkMode, setDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  )
}

export const useDarkMode = () => React.useContext(DarkModeContext)
