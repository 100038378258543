import React, { useState } from 'react'

type ShoppingCartContextType = {
  shoppingCartItemsCount: number
  setShoppingCartItemsCount: React.Dispatch<React.SetStateAction<number>>
}

const ShoppingCartItemsCountContext = React.createContext<
  ShoppingCartContextType | undefined
>(undefined)

type Props = {
  children: React.ReactNode
}

export const ShoppingCartItemsCountProvider = ({ children }: Props) => {
  const [shoppingCartItemsCount, setShoppingCartItemsCount] =
    useState<number>(0)

  return (
    <ShoppingCartItemsCountContext.Provider
      value={{ shoppingCartItemsCount, setShoppingCartItemsCount }}
    >
      {children}
    </ShoppingCartItemsCountContext.Provider>
  )
}

export const useShoppingCartItemsCount = () =>
  React.useContext(ShoppingCartItemsCountContext)
