import { Grid, Typography } from '@mui/material'
import { styled } from '@mui/system'

type ProgressBarProps = {
  progress: {
    percentage: number
    label?: string
    color?: string
    colorVariant?: string
  }[]
  showLabel?: boolean
  withGradient?: boolean
  labelVariant?: any
}
interface ProgressPercentageFillProperties {
  percentage: number
  backgroundColor: string
  colorVariant: string
  withGradient: boolean
}

const ProgressPercentageFill = styled(Grid)<ProgressPercentageFillProperties>(
  ({ theme, percentage, backgroundColor, colorVariant, withGradient }) => ({
    position: 'relative',
    textAlign: 'center',
    height: '100%',
    paddingTop: '2px',
    width: `${percentage}%`,
    transition: 'width 0.5s',
    background: withGradient
      ? `linear-gradient(
        127.84deg,
        ${theme.palette[backgroundColor].dark} 0%,
        ${theme.palette[backgroundColor].main} 100%
      );`
      : theme.palette[backgroundColor][colorVariant],
  }),
)

export default function ProgressBar({
  progress,
  showLabel = true,
  withGradient,
  labelVariant = 'body2',
}: ProgressBarProps) {
  const progressSum = progress.reduce((a, b) => +a + +b.percentage, 0)

  if (progress.length > 1) {
    const lastItemInBar = progress.length - 1
    progress[lastItemInBar].percentage =
      +progress[lastItemInBar].percentage + 100 - +progressSum
  }

  return (
    <Grid
      container
      justifyContent='flex-start'
      alignItems='center'
      sx={{
        width: '100%',
        height: showLabel ? 'inherit' : 32,
        backgroundColor: 'rgba(0, 0, 0, 0.15)',
        color: '#fff',
        fontWeight: 'bold',
        position: 'relative',
        margin: '8px 0px',
      }}
    >
      {progress.map((progressItem: any, index: number) => (
        <ProgressPercentageFill
          percentage={progressItem.percentage}
          backgroundColor={progressItem.color || 'secondary'}
          colorVariant={progressItem.colorVariant || 'main'}
          withGradient={withGradient || false}
          key={index}
        >
          {showLabel && (
            <Typography
              variant={labelVariant}
              zIndex={996}
              fontWeight='bold'
              align='center'
              visibility={progressItem.percentage > 3 ? 'visible' : 'hidden'}
            >
              {progressItem.label
                ? progressItem.label
                : `${progressItem.percentage}%`}
            </Typography>
          )}
        </ProgressPercentageFill>
      ))}
    </Grid>
  )
}
