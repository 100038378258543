import { get } from 'lodash'
import { Box, Grid, Typography } from '@mui/material'
import { t } from 'i18n'
import { getDriverScoreDescription } from 'utils/survey'
import { SurveyDriverScore } from 'enums/survey'
import { PreviousValueComparison } from 'components'

export default function EngagementScoreComponent({
  surveyDetails,
  isTeamInsight,
  // insightsAll,
  surveyCompanyDetails,
  surveyDepartmentDetails,
  surveyTeamDetails
}: any) {

  const MINIMUM_PARTICIPANTS_REQUIRED = 5
  const survey = surveyDetails.survey
  const driverScores = get(surveyDetails, 'scores.percents.drivers')
  const previousDriverScores = get(surveyDetails, 'previous_scores.drivers')
  const engagementScores = get(surveyDetails, 'scores.percents.engagement')
  const surveyHasMinimumParticipantsRequired = survey?.current_participation >= MINIMUM_PARTICIPANTS_REQUIRED
  const dontShowVisual = get(surveyDetails, "previous_survey_percents") ? true : false;
  const showTrends = (survey.report_level == "upgraded") ? surveyHasMinimumParticipantsRequired : surveyHasMinimumParticipantsRequired // Make it false to hide for free reports
  // console.log("surveyDetails: ", surveyDetails);

  const renderDriverScore = (
    title: string,
    description: string,
    value: number,
    index: number,
    driverScoreLength = 0,
    key?: any,
  ) => {

    // const orgValue =
    //   get(surveyCompanyDetails, 'scores.percents.drivers') &&
    //   get(surveyCompanyDetails, 'scores.percents.drivers').find(
    //     driver => driver.key === key,
    //   )?.value

    // const departmentValue =
    //   get(surveyDepartmentDetails, 'scores.percents.drivers') &&
    //   get(surveyDepartmentDetails, 'scores.percents.drivers').find(
    //     driver => driver.key === key,
    //   )?.value

    const orgValue = get(surveyCompanyDetails, 'drivers') && get(surveyCompanyDetails, 'drivers').hasOwnProperty(key) ? get(surveyCompanyDetails, 'drivers')[key] : '';
    const departmentValue = get(surveyDepartmentDetails, 'drivers') && get(surveyDepartmentDetails, 'drivers').hasOwnProperty(key) ? get(surveyDepartmentDetails, 'drivers')[key] : '';
    const teamValue = get(surveyTeamDetails, 'drivers') && get(surveyTeamDetails, 'drivers').hasOwnProperty(key) ? get(surveyTeamDetails, 'drivers')[key] : '';
    const previousValue = (previousDriverScores && previousDriverScores[index]) ? previousDriverScores[index]?.value : '';

    return (
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        lg
        key={title}
        sx={{
          paddingRight: 1,
          borderRight: theme =>
            driverScoreLength - 1 !== index
              ? `2px solid ${theme.palette.divider}`
              : 0,
        }}
      >
        <Grid container>
          <Grid item xs={12} style={{ height: '40px', maxHeight: '40px' }}>
            <Typography
              color={isTeamInsight ? 'GrayText' : 'text.primary'}
              fontWeight={700}
              variant='body1'
              align={isTeamInsight ? 'center' : 'center'}
              paragraph
            >
              {title}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            style={
              isTeamInsight
                ? {
                  padding: '0 12px',
                  position: 'relative',
                }
                : {}
            }
          >
            {isTeamInsight && (
              <Typography
                component='span'
                style={
                  title != 'Growth'
                    ? {
                      position: 'absolute',
                      // borderRight: '1px solid #6e788230',
                      height: '200px',
                      right: 0,
                    }
                    : {}
                }
              ></Typography>
            )}
            <Box mb={1}>
              <Grid container justifyContent='center'>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  style={
                    isTeamInsight
                      ? {
                        textAlign: 'center',
                        width: '100%',
                      }
                      : {}
                  }
                >
                  <Typography
                    variant='h5'
                    fontWeight='bold'
                    component='span'
                    color={
                      (isTeamInsight ? teamValue : value) < 70
                        ? 'error.main'
                        : ((isTeamInsight ? teamValue : value) >= 70 && (isTeamInsight ? teamValue : value) <= 79)
                          ? 'warning.main'
                          : 'primary.main'
                    }
                    style={
                      isTeamInsight
                        ? {
                          fontSize: '2.5rem',
                        }
                        : {}
                    }
                  >
                    {isTeamInsight ? teamValue : value}
                    {isTeamInsight && (
                      <Typography
                        component='span'
                        style={{
                          position: 'absolute',
                          fontSize: '1.5rem',
                        }}
                      >
                        %
                      </Typography>
                    )}

                    {!isTeamInsight && '%'}
                  </Typography>{' '}
                </Typography>
                
                {dontShowVisual && showTrends && (
                  <Typography
                    style={
                      {
                        position: 'relative',
                        top: -2,
                      }
                    }
                  >
                    <PreviousValueComparison
                      currentValue={value}
                      previousValue={previousValue}
                      arrowSize = {'18px'}
                      textFontSize = {'12px'}
                    />
                  </Typography>
                )}

                {isTeamInsight && (
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    display='flex'
                    justifyContent='space-between'
                    width='100%'
                    mt={2}
                  >
                    <Typography variant='body2'>
                      Organizational Score:
                    </Typography>
                    <Typography
                      fontWeight={700}
                      variant='body2'
                      component='span'
                      color={
                        orgValue < 70
                          ? 'error.main'
                          : orgValue >= 70 && orgValue <= 79
                            ? 'warning.main'
                            : 'primary.main'
                      }
                    >
                      {orgValue ? orgValue + '%' : 'N/A'}
                    </Typography>
                  </Typography>
                )}

                {isTeamInsight && departmentValue && (
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    display='flex'
                    justifyContent='space-between'
                    width='100%'
                    my={1}
                  >
                    <Typography variant='body2'>Department Score:</Typography>
                    <Typography
                      fontWeight={700}
                      variant='body2'
                      color={
                        departmentValue < 70
                          ? 'error.main'
                          : departmentValue >= 70 && departmentValue <= 79
                            ? 'warning.main'
                            : 'primary.main'
                      }
                    >
                      {' '}
                      {departmentValue ? departmentValue + '%' : 'N/A'}
                    </Typography>
                  </Typography>
                )}
                {isTeamInsight && (
                  <Typography
                    sx={{
                      borderTop: '2px dashed #6e7882a8',
                      width: '100%',
                      margin: '10px 0',
                    }}
                  ></Typography>
                )}
                {dontShowVisual && (
                  <Grid container justifyContent='flex-end'>
                    <Typography
                      // variant='h6'
                      fontWeight='bold'
                      component='span'
                      color={
                        value < 70
                          ? 'secondary.main'
                          : value >= 70 && value <= 79
                            ? 'secondary.main'
                            : 'secondary.main'
                      }
                    ></Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
            <Typography color='text.secondary' variant='body2' paragraph>
              {description}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  if (survey && engagementScores) {
    const filteredData: any = driverScores?.filter(
      (driverScore: any) =>
        driverScore.key !== SurveyDriverScore.Engagement &&
        driverScore.key !== SurveyDriverScore.CustomRatingsQuestions,
    )

    return (
      <>
        <Typography
          color='text.primary'
          mb={4}
          mt={2}
          variant='h5'
          fontWeight='bold'
        >
          {t('beheardReport.results.engagementDriversHeader')}
        </Typography>

        <Grid container spacing={2} alignItems='stretch'>
          {filteredData?.map((driverScore: any, index: number) =>
            renderDriverScore(
              driverScore.label,
              getDriverScoreDescription(driverScore.key),
              driverScore.value,
              index,
              filteredData.length,
              driverScore.key,
            ),
          )}
        </Grid>
      </>
    )
  } else {
    return null
  }
}
