import { useState } from 'react'

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  Grid,
  Typography,
} from '@mui/material'
import { get } from 'lodash'

import { getRecognitionStyle } from 'constants/recognitionStyle'
import { ROUTES_PATHS } from 'core/routes'
import { t } from 'i18n'

export default function ProfileRecognitionStyle(props) {
  const {
    recognitionStyle,
    userIsLoading,
    user,
    profileState,
    history,
    isProfileFromLoggedUser,
  } = props

  const [recognitionStylesDialogOpen, setRecognitionStylesDialogOpen] =
    useState(false)
  const [recognitionStyleSeeMore, setRecognitionStyleSeeMore] = useState(false)

  const userIsDirectSupervisor = (
    profileState.superior_direct_reports_attributes ?? []
  ).find(supervisor => get(supervisor, 'superior.id') === user.id)

  const recognitionStyles = get(user, 'recognition.styles' ?? [])?.slice(0, 2)

  const isRecognitionStyleUndefined =
    recognitionStyle.id === 'recognition_style_undefined'

  const handleSeeMore = () => {
    setRecognitionStyleSeeMore(!recognitionStyleSeeMore)
  }

  return (
    <>
      <Card elevation={2} style={{ height: '100%' }}>
        <Box
          sx={{
            py: 1.5,
            px: 3,
            ml: 3,
            mt: 3,
            bgcolor: 'warning.dark',
            borderRadius: '8px 0px 0px 8px',
          }}
        >
          <Typography
            variant='body2'
            color='white'
            sx={{
              textTransform: 'uppercase',
              fontWeight: 600,
            }}
          >
            {t('profile.profileRecognitionStyle.title')}
          </Typography>
        </Box>
        <CardContent>
          {userIsLoading ? (
            <Box py={5} width={1}>
              <Grid container justifyContent='center'>
                <CircularProgress size={64} />
              </Grid>
            </Box>
          ) : (
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant='h5'
                  color={
                    isRecognitionStyleUndefined ? 'text.light' : 'primary.dark'
                  }
                  sx={{
                    textTransform: 'uppercase',
                    fontSize: '1.75rem !important',
                  }}
                  fontWeight={600}
                >
                  {isRecognitionStyleUndefined && !isProfileFromLoggedUser
                    ? null
                    : recognitionStyle.name}
                </Typography>
              </Grid>

              <Grid item xs={12} mt={2}>
                <Typography
                  variant='body1'
                  color='text.secondary'
                  align='center'
                  fontWeight={300}
                  style={{ textAlign: 'justify', lineHeight: '1.75rem' }}
                >
                  {isRecognitionStyleUndefined
                    ? recognitionStyle.descriptionSeeLess(
                        isProfileFromLoggedUser,
                        profileState.first_name,
                      )
                    : recognitionStyle.descriptionSeeLess}{' '}
                  {recognitionStyleSeeMore && (
                    <>{recognitionStyle.descriptionSeeMore}</>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box mt={2}>
                  <Grid
                    container
                    justifyContent={
                      recognitionStyle.id !== 'recognition_style_undefined' &&
                      isProfileFromLoggedUser
                        ? 'space-between'
                        : 'flex-end'
                    }
                  >
                    {recognitionStyle.id !== 'recognition_style_undefined' &&
                      (isProfileFromLoggedUser || userIsDirectSupervisor) && (
                        <Button
                          color='primary'
                          size='small'
                          onClick={() =>
                            history.push(
                              userIsDirectSupervisor
                                ? `${ROUTES_PATHS.onboarding}/${profileState.id}`
                                : ROUTES_PATHS.onboarding,
                            )
                          }
                        >
                          {userIsDirectSupervisor
                            ? t('profile.profileRecognitionStyle.seeOnboarding')
                            : t(
                                'profile.profileRecognitionStyle.editPreferences',
                              )}
                        </Button>
                      )}

                    {recognitionStyle.id !== 'recognition_style_undefined' ? (
                      <Button
                        color='primary'
                        size='small'
                        onClick={
                          recognitionStyles?.length > 1
                            ? () =>
                                setRecognitionStylesDialogOpen(
                                  !recognitionStylesDialogOpen,
                                )
                            : handleSeeMore
                        }
                      >
                        {recognitionStyles?.length > 1
                          ? t('profile.profileRecognitionStyle.seeAllMyStyles')
                          : recognitionStyleSeeMore
                          ? t('profile.profileRecognitionStyle.seeLess')
                          : t('profile.profileRecognitionStyle.seeMore')}
                      </Button>
                    ) : (
                      isProfileFromLoggedUser && (
                        <Button
                          color='primary'
                          variant='contained'
                          onClick={() => history.push(ROUTES_PATHS.onboarding)}
                        >
                          {t('profile.profileRecognitionStyle.takeSurvey')}
                        </Button>
                      )
                    )}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>

      <Dialog
        open={recognitionStylesDialogOpen}
        onClose={() => setRecognitionStylesDialogOpen(false)}
        maxWidth='sm'
        fullWidth
      >
        {recognitionStyles?.map(style => {
          const recognitionStyle = getRecognitionStyle[style.letter]

          return (
            <Grid item xs={12} key={recognitionStyle.id}>
              <Grid
                container
                justifyContent='center'
                sx={{
                  backgroundImage: `url(${recognitionStyle.backgroundImage})`,
                  pt: 6,
                }}
              >
                <Grid item xs={11} md={10} key={recognitionStyle.id}>
                  <Card
                    elevation={0}
                    sx={{ height: '100%', borderRadius: '1rem 1rem 0rem 0rem' }}
                  >
                    <Box
                      sx={{
                        py: 1.5,
                        px: 3,
                        ml: 3,
                        mt: 3,
                        bgcolor: 'warning.dark',
                        borderRadius: '8px 0px 0px 8px',
                      }}
                    >
                      <Typography
                        variant='body2'
                        color='white'
                        sx={{
                          textTransform: 'uppercase',
                          fontWeight: 600,
                        }}
                      >
                        {t(
                          'sparckAppreciationAssessment.results.sparckAppreciationStyle',
                        )}
                      </Typography>
                    </Box>
                    <CardContent>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            variant='h5'
                            color={
                              recognitionStyle.id ===
                              'recognition_style_undefined'
                                ? 'text.light'
                                : 'primary.dark'
                            }
                            sx={{
                              textTransform: 'uppercase',
                              fontSize: '1.75rem !important',
                            }}
                            fontWeight={600}
                          >
                            {recognitionStyle.name}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} mt={2}>
                          <Typography
                            variant='body1'
                            color='text.secondary'
                            align='center'
                            fontWeight={300}
                            style={{
                              textAlign: 'justify',
                              fontSize: '1rem',
                              lineHeight: '1.75rem',
                            }}
                          >
                            {style.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          )
        })}
      </Dialog>
    </>
  )
}
