import { Alert, AlertTitle, Grid, Typography } from '@mui/material'

import { DialogTeleport } from 'components'

import { t } from 'i18n'

export default function SparckMentionWarning(props) {
  const { dialogOpen, setDialogOpen } = props

  const handleClose = () => {
    setDialogOpen(false)
  }

  return (
    <DialogTeleport
      dialogTitle=''
      dialogSize='sm'
      dialogOpen={dialogOpen}
      dialogAction={{
        label: t('sparcks.sparck.sparckMentionWarning.actionLabel'),
        function: handleClose,
      }}
      handleDialogClose={handleClose}
    >
      <Alert severity='warning'>
        <AlertTitle>
          {t('sparcks.sparck.sparckMentionWarning.title')}
        </AlertTitle>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='body1' paragraph>
              {t('sparcks.sparck.sparckMentionWarning.description')}
            </Typography>
          </Grid>
        </Grid>
      </Alert>
    </DialogTeleport>
  )
}
