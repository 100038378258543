import { useState, useEffect } from 'react'

import { HelpOutline as HelpIcon } from '@mui/icons-material'
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'

import { MoneyInput, RolesPicker } from 'components'
import { t } from 'i18n'

enum RadioActionOption {
  AcceptOnly = 'canAcceptOnly',
  AcceptToBankOnly = 'canAcceptToBankOnly',
  AcceptAndBank = 'canAcceptAndBank',
}

export default function ManageRecognitionRewards({ formik, roles }) {
  const [selectedRolesList, setSelectedRolesList] = useState([])
  const [selectedActionOption, setSelectedActionOption] =
    useState<RadioActionOption | null>(null)

  const hasActionsButtons = [
    {
      id: RadioActionOption.AcceptOnly,
      label: t(`recognitions.fields.${RadioActionOption.AcceptOnly}`),
    },
    {
      id: RadioActionOption.AcceptToBankOnly,
      label: t(`recognitions.fields.${RadioActionOption.AcceptToBankOnly}`),
    },
    {
      id: RadioActionOption.AcceptAndBank,
      label: t(`recognitions.fields.${RadioActionOption.AcceptAndBank}`),
    },
  ]

  const handleCompanyRewardChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (formik.values.has_retailer_reward)
      formik.setFieldValue('has_company_reward', event.target.checked)
  }

  const handleRetailerRewardChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (formik.values.has_company_reward)
      formik.setFieldValue('has_retailer_reward', event.target.checked)
  }

  const handleSelectedActionOption = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = (event.target as HTMLInputElement)
      .value as RadioActionOption | null
    setSelectedActionOption(value)
  }

  const handleHasActionChange = options => {
    Object.keys(options).forEach(optionKey => {
      formik.setFieldValue(`has_${optionKey}_action`, options[optionKey])
    })
  }

  const calculateSelectedRolesList = () => {
    formik.setFieldValue(
      'roles_who_can_reward',
      formik.values.roles_who_can_recognize,
    )

    setSelectedRolesList(
      roles?.filter(role =>
        formik.values?.roles_who_can_recognize?.includes(role.id),
      ),
    )
  }

  useEffect(() => {
    if (formik.values?.roles_who_can_recognize?.length && roles?.length) {
      calculateSelectedRolesList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!formik.values.id && formik.values.has_reward) {
      setSelectedActionOption(RadioActionOption.AcceptAndBank)
    }

    if (
      !formik.values.has_retailer_reward &&
      !formik.values.has_company_reward
    ) {
      formik.setFieldValue('has_company_reward', true)
      formik.setFieldValue('has_retailer_reward', true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.has_reward])

  useEffect(() => {
    if (!formik.values.has_reward) {
      setSelectedActionOption(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.has_reward])

  useEffect(() => {
    if (formik.values.id) {
      const { has_accept_action, has_bank_action } = formik.values

      if (has_accept_action && !has_bank_action) {
        setSelectedActionOption(RadioActionOption.AcceptOnly)
      }

      if (!has_accept_action && has_bank_action) {
        setSelectedActionOption(RadioActionOption.AcceptToBankOnly)
      }

      if (has_accept_action && has_bank_action) {
        setSelectedActionOption(RadioActionOption.AcceptAndBank)
      }

      if (!has_accept_action && !has_bank_action) {
        setSelectedActionOption(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.id])

  useEffect(() => {
    if (selectedActionOption === RadioActionOption.AcceptOnly) {
      handleHasActionChange({ accept: true, bank: false })
    }

    if (selectedActionOption === RadioActionOption.AcceptToBankOnly) {
      handleHasActionChange({ accept: false, bank: true })
    }

    if (selectedActionOption === RadioActionOption.AcceptAndBank) {
      handleHasActionChange({ accept: true, bank: true })
    }

    if (!selectedActionOption) {
      handleHasActionChange({ accept: false, bank: false })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActionOption])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='body2' color='text.secondary'>
          {t('recognitions.rewardConfigurations')}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm='auto'>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    data-cy='hasReward'
                    name='has_reward'
                    checked={!!formik.values.has_reward}
                    onChange={() =>
                      formik.setFieldValue(
                        'has_reward',
                        !formik.values.has_reward,
                      )
                    }
                  />
                }
                label={t('recognitions.fields.allowRewards')}
              />
            </FormGroup>
          </Grid>

          {formik.values.has_reward && (
            <Grid item xs={12} sm>
              <TextField
                data-cy='maximumRewardAmount'
                id='maximum_reward_amount_for_each_recognition'
                name='maximum_reward_amount_for_each_recognition'
                label={t('recognitions.fields.budgetPerReward')}
                variant='outlined'
                value={formik.values.maximum_reward_amount_for_each_recognition}
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.maximum_reward_amount_for_each_recognition &&
                  Boolean(
                    formik.errors.maximum_reward_amount_for_each_recognition,
                  )
                }
                helperText={
                  formik.touched.maximum_reward_amount_for_each_recognition &&
                  formik.errors.maximum_reward_amount_for_each_recognition
                }
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: MoneyInput,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Tooltip
                        title={t(
                          'recognitions.fields.tooltips.maximumRewardAmountPerRecognition',
                        )}
                      >
                        <HelpIcon />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}

          {formik.values.has_reward && (
            <Grid item xs={12} sm={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      data-cy='hasCompanyReward'
                      name='has_company_reward'
                      checked={!!formik.values.has_company_reward}
                      onChange={handleCompanyRewardChange}
                    />
                  }
                  label={t('recognitions.fields.allowOrganizationalRewards')}
                />
              </FormGroup>
            </Grid>
          )}

          {formik.values.has_reward && (
            <Grid item xs={12} sm={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      data-cy='hasRetailerReward'
                      name='has_retailer_reward'
                      checked={!!formik.values.has_retailer_reward}
                      onChange={handleRetailerRewardChange}
                    />
                  }
                  label={t('recognitions.fields.allowRetailerRewards')}
                />
              </FormGroup>
            </Grid>
          )}

          {formik.values.has_reward && (
            <Grid item xs={12}>
              <FormControl>
                <RadioGroup
                  aria-labelledby='has-actions'
                  name='has-actions'
                  value={selectedActionOption || RadioActionOption.AcceptToBankOnly}
                  onChange={handleSelectedActionOption}
                >
                  {hasActionsButtons.map(actionButton => (
                    <FormControlLabel
                      key={actionButton.id}
                      value={actionButton.id}
                      control={<Radio />}
                      label={
                        <Grid container component='span' alignItems='center'>
                          <Typography variant='body1' sx={{ mr: 1 }}>
                            {actionButton.label}
                          </Typography>

                          <Tooltip
                            title={t(
                              `recognitions.fields.${actionButton.id}Tooltip`,
                            )}
                          >
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
          )}

          {formik.values.has_reward && selectedRolesList?.length > 0 && (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Typography variant='body1' color='text.primary'>
                  {t('recognitions.formSteps.whoCanReward.title')}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant='body2' color='text.secondary'>
                  {t('recognitions.formSteps.whoCanReward.description')}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <RolesPicker
                  formik={formik}
                  roles={selectedRolesList}
                  label={''}
                  selectLabel={t('recognitions.formSteps.whoCanReward.title')}
                  fieldName='roles_who_can_reward'
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}