import { useState } from 'react'

import { Chip, useTheme } from '@mui/material'
import { teal } from '@mui/material/colors'
import { Check as CheckIcon, Clear as CancelIcon } from '@mui/icons-material'
// import { useIntl } from 'react-intl'
import { useMutation } from 'react-query'

import { ApprovalResolveDialog } from './components'
import { CompanyAdminLayout } from 'components'
import { API } from 'core/requests'
import { initialDialog } from 'constants/dialog'
import { getApprovalsStatusDetails } from 'constants/approvals'
import { useUser } from 'contexts'
import { UserPermission } from 'enums/user'
import { getDateLabel } from 'utils/tables/dataTypes'
import { formatToUSD } from 'utils/currency'

const renderStatus = (rowData: any, theme: any) => (
  <Chip
    style={{
      fontWeight: 600,
      backgroundColor: getApprovalsStatusDetails(theme)[rowData.status].color,
      color: '#FFF',
    }}
    label={getApprovalsStatusDetails(theme)[rowData.status].label}
  />
)

export default function Approvals() {
  const { user } = useUser()!
  const theme = useTheme()
  // const intl = useIntl()

  const [approvalAcceptDialog, setApprovalAcceptDialog] =
    useState(initialDialog)
  const [approvalDeclineDialog, setApprovalDeclineDialog] =
    useState(initialDialog)

  return (
    <>
      <CompanyAdminLayout
        slug='approvals'
        // title={intl.formatMessage({ id: 'approvals.title' })}
        // description={intl.formatMessage({ id: 'approvals.description' })}
        title={'Reward Approvals'}
        description={
          'Below you will find a list of Rewards that need your approval before the reward and the recognition they are associated with can be sent to their recipient. Please review each record below and set your approval status and an optional note.'
        }
        color={teal[500]}
        requestUrl='approvals'
        filters={[
          {
            id: 'status',
            label: 'Status',
            defaultValue: ['pending'],
            values: [
              {
                value: 'pending',
                // label: intl.formatMessage({
                //   id: 'statuses.pending',
                // }),
                label: 'Pending',
              },
              {
                value: 'approved',
                // label: intl.formatMessage({
                //   id: 'statuses.accepted',
                // }),
                label: 'Accepted',
              },
              {
                value: 'declined',
                // label: intl.formatMessage({
                //   id: 'statuses.declined',
                // }),
                label: 'Declined',
              },
            ],
          },
        ]}
        tableProps={{
          columns: [
            {
              // title: intl.formatMessage({ id: 'approvals.fields.date' }),
              title: 'Date',
              field: 'created_at',
              render: (rowData: any) => getDateLabel(rowData.created_at),
              editable: 'never',
            },
            {
              // title: intl.formatMessage({ id: 'approvals.fields.requester' }),
              title: 'Requester',
              field: 'requester_id',
              editable: 'never',
              render: (rowData: any) => rowData?.requester?.full_name,
            },
            {
              title: 'Amount',
              field: 'approveable_id',
              editable: 'never',
              render: () => formatToUSD(15),
            },
            {
              // title: intl.formatMessage({ id: 'status' }),
              title: 'Status',
              field: 'status',
              render: rowData => renderStatus(rowData, theme),
              editable: 'never',
            },
          ],
          ...(!(user.permissions ?? []).includes(
            UserPermission.ManageApprovals,
          ) && {
            actions: [
              {
                icon: () => <CancelIcon color='error' />,
                // tooltip: () => intl.formatMessage({ id: 'approvals.decline' }),
                tooltip: () => 'Decline Recognition',
                onClick: (_, approval: any) =>
                  setApprovalDeclineDialog({
                    isOpen: true,
                    data: approval,
                  }),
              },
              {
                icon: () => <CheckIcon color='primary' />,
                tooltip: () => 'Accept Recognition',
                // tooltip: () => intl.formatMessage({ id: 'approvals.accept' }),
                onClick: (_, approval: any) =>
                  setApprovalAcceptDialog({ isOpen: true, data: approval }),
              },
            ],
          }),
          editActionProps: {
            hidden: () => true,
          },
          deleteActionProps: {
            hidden: () => true,
          },
        }}
        // managementPermission={UserPermission.ManageApprovals}
        IconHeader={CheckIcon}
      >
        {({ fetchData }) => (
          <ApprovalsDialogs
            fetchApprovals={fetchData}
            approvalAcceptDialog={approvalAcceptDialog}
            setApprovalAcceptDialog={setApprovalAcceptDialog}
            approvalDeclineDialog={approvalDeclineDialog}
            setApprovalDeclineDialog={setApprovalDeclineDialog}
          />
        )}
      </CompanyAdminLayout>
    </>
  )
}

function ApprovalsDialogs({
  fetchApprovals,
  approvalAcceptDialog,
  setApprovalAcceptDialog,
  approvalDeclineDialog,
  setApprovalDeclineDialog,
}) {
  const acceptApprovalMutation = useMutation(
    (payload: any) => API.update('approveApprovalRequest', payload),
    {
      onSuccess: (response: any) => {
        if (response.id) {
          setApprovalAcceptDialog(initialDialog)
          fetchApprovals()
        }
      },
    },
  )

  const declineApprovalMutation = useMutation(
    (payload: any) => API.update('declineApprovalRequest', payload),
    {
      onSuccess: (response: any) => {
        if (response.id) {
          setApprovalDeclineDialog(initialDialog)
          fetchApprovals()
        }
      },
    },
  )

  return (
    <>
      <ApprovalResolveDialog
        id='approve'
        dialog={approvalAcceptDialog}
        setDialog={setApprovalAcceptDialog}
        mutate={acceptApprovalMutation.mutateAsync}
        mutateIsLoading={acceptApprovalMutation.isLoading}
        // actionLabel={intl.formatMessage({ id: 'approvals.accept' })}
        actionLabel={'Approve'}
        actionButtonColor='primary'
        setRefetchApproval={fetchApprovals}
      />

      <ApprovalResolveDialog
        id='decline'
        dialog={approvalDeclineDialog}
        setDialog={setApprovalDeclineDialog}
        mutate={declineApprovalMutation.mutateAsync}
        mutateIsLoading={declineApprovalMutation.isLoading}
        // actionLabel={intl.formatMessage({ id: 'approvals.decline' })}
        actionLabel={'Decline'}
        actionButtonColor='primary'
        setRefetchApproval={fetchApprovals}
      />
    </>
  )
}
