import { useState, useEffect } from 'react'

import { Dialog, DialogContent } from '@mui/material'
import { get } from 'lodash'
import ReactGA from 'react-ga'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'

import ApplicationRoutes from './ApplicationRoutes'
import { API } from './requests'
import zebraGif from 'assets/images/zebra.gif'
import { SPARCK_UNDER_MAINTENANCE } from 'constants/app'
import {
  useCompanies,
  useCompany,
  usePulseSurveys,
  useShoppingCartItemsCount,
  useUser,
} from 'contexts'
import { isDevEnvironment } from 'core/environments'
import { UserRole } from 'enums/user'
import { UnderMaintenance } from 'pages'
import { handleEasterEgg } from 'utils/easterEgg'

const ApplicationContainer = () => {
  const [zebra, setZebra] = useState(false)

  const location = useLocation()
  const { setCompanies } = useCompanies()!
  const { setShoppingCartItemsCount } = useShoppingCartItemsCount()!
  const { setPulseSurveys } = usePulseSurveys()!
  const { user } = useUser()!
  const { company } = useCompany()!

  useQuery('getCompanies', () => API.get('companies'), {
    enabled: Boolean(user?.sparck_administrator && user?.id && company?.name),
    onSuccess: (response: any) => {
      setCompanies(response)
    },
  })

  useQuery('getShoppingCart', () => API.get('shoppingCart'), {
    enabled: Boolean(
      (user?.id && company?.name && get(user, 'sparck_administrator')) ||
        [UserRole.CompanyAdmin].some(role =>
          get(user, 'roles', []).includes(role),
        ),
    ),
    onSuccess: (response: any) => {
      setShoppingCartItemsCount(response.cart?.items?.length ?? 0)
    },
  })

  useQuery(
    'myPulseSurveys',
    () =>
      API.get('pulseSurveys', null, {
        queryParams: {
          my_surveys: true,
        },
      }),
    {
      enabled: Boolean(user?.id && company?.name),
      onSuccess: (response: any) => {
        setPulseSurveys(response)
      },
    },
  )

  useEffect(() => {
    // Easter Egg
    document.addEventListener('keyup', handleEasterEgg(setZebra))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isDevEnvironment) {
      ReactGA.pageview(location.pathname + location.search)
    }
  }, [location])

  return (
    <>
      {SPARCK_UNDER_MAINTENANCE ? <UnderMaintenance /> : <ApplicationRoutes />}
      {isDevEnvironment && (
        <Dialog open={zebra} onClose={() => setZebra(false)}>
          <DialogContent>
            <img
              src={zebraGif}
              alt='ZEBRA'
              style={{ width: '100%', objectFit: 'cover', height: 500 }}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export default ApplicationContainer
