import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { groupBy } from 'lodash'

import { OnboardingCard } from '../../components'

export default function OnboardingChoiceGroupQuestions({
  choiceGroupQuestions,
  answers,
  setAnswers,
  theme,
  isMobile,
}) {
  return choiceGroupQuestions.map(choiceGroupQuestion => {
    const choiceGroupIds = Object.keys(
      groupBy(
        choiceGroupQuestion.onboarding_choices_attributes,
        'choice_group',
      ),
    )

    return (
      <OnboardingCard
        key={choiceGroupQuestion.statement}
        backgroundColor1={theme.palette.success.main}
        backgroundColor2={theme.palette.success.light}
        title={choiceGroupQuestion.statement}
      >
        <Grid container justifyContent='center'>
          <Grid item xs={12}>
            {choiceGroupIds.map((choiceGroupId, index) => {
              const choiceGroupAnswer = answers.find(
                answer =>
                  answer.question_id === choiceGroupQuestion.id &&
                  answer.choice_group === +choiceGroupId,
              )

              if (choiceGroupAnswer)
                return (
                  <FormControl
                    component='fieldset'
                    key={`${choiceGroupId}-${index}`}
                    style={{ width: '100%' }}
                  >
                    <RadioGroup
                      aria-label='choice-group-check'
                      name='choice-group-check'
                      value={choiceGroupAnswer.selected_choice_id}
                      onChange={event => {
                        if (!choiceGroupAnswer.selected_choice_id) {
                          window.scrollBy(0, 160)
                        }

                        choiceGroupAnswer.selected_choice_id =
                          +event.target.value

                        setAnswers([...answers])
                      }}
                    >
                      <Box
                        my={3}
                        width={1}
                        style={{
                          zIndex: 998,
                          marginBottom: isMobile ? 8 : '-24px',
                        }}
                      >
                        <Grid container justifyContent='center'>
                          <Grid item xs={isMobile ? 12 : 'auto'}>
                            <Paper
                              style={{
                                width: isMobile ? '100%' : 'auto',
                                backgroundColor:
                                  choiceGroupAnswer.selected_choice_id
                                    ? theme.palette.primary.light
                                    : '#FFF',
                              }}
                            >
                              <Box px={3} py={1}>
                                <Typography
                                  variant='h6'
                                  color={`text.${
                                    choiceGroupAnswer.selected_choice_id
                                      ? 'white'
                                      : 'primary'
                                  }`}
                                  align='center'
                                >
                                  {index + 1}
                                </Typography>
                              </Box>
                            </Paper>
                          </Grid>
                        </Grid>
                      </Box>
                      <Grid container justifyContent='center' spacing={2}>
                        {choiceGroupQuestion.onboarding_choices_attributes
                          .filter(
                            question =>
                              question.choice_group === +choiceGroupId,
                          )
                          .map(question => (
                            <Grid key={question.id} item xs={12} sm={6}>
                              <Box width={1} mb={2} height={1}>
                                <Paper
                                  style={{
                                    ...(question.id ===
                                      choiceGroupAnswer.selected_choice_id && {
                                      backgroundColor: `${theme.palette.primary.main}15`,
                                      outline: `1px solid ${theme.palette.primary.main}`,
                                    }),
                                    height: '100%',
                                    width: '100%',
                                  }}
                                >
                                  <FormControlLabel
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      paddingLeft: 24,
                                    }}
                                    value={question.id}
                                    control={<Radio color='primary' />}
                                    label={
                                      <Typography
                                        variant={isMobile ? 'body2' : 'body1'}
                                        color='text.secondary'
                                      >
                                        <Box width={1} py={3} px={2}>
                                          {question.statement}
                                        </Box>
                                      </Typography>
                                    }
                                  />
                                </Paper>
                              </Box>
                            </Grid>
                          ))}
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                )

              return null
            })}
          </Grid>
        </Grid>
      </OnboardingCard>
    )
  })
}
