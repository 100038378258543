import {
  Checkbox,
  FormControlLabel,
  Grid,
} from '@mui/material'

import { t } from 'i18n'

export default function ManageRecognitionApproval({ formik }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                data-cy='hasRequireApproval'
                id='has_approval'
                name='has_approval'
                checked={!!formik.values.has_approval}
                onChange={() =>
                  formik.setFieldValue(
                    'has_approval',
                    !formik.values.has_approval,
                  )
                }
              />
            }
            label={t('recognitions.fields.hasRequiresApprovalBy')}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
