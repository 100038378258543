import { useEffect, useState } from 'react'

import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import { alpha } from '@mui/material/styles'
import { cloneDeep } from 'lodash'

// import { allowedDemographicsCategories } from 'constants/demographics'
import { t } from 'i18n'

const renderLoading = () => (
  <Grid container justifyContent='center' my={3}>
    <CircularProgress size={40} />
  </Grid>
)

export default function DemographicsPicker({
  formik,
  demographics,
  demographicsIsLoading,
  label,
  fieldName,
}) {
  const [demographicsSectionsOpened, setDemographicsSectionsOpened] = useState<
    any[]
  >([])

  const [dialogDemographicsOpen, setDialogDemographicsOpen] = useState(false)

  useEffect(() => {
    if (!demographicsSectionsOpened.length) {
      setDemographicsSectionsOpened(
        (demographics ?? []).filter(demographic =>
          formik.values[fieldName]
            ?.filter(demographic => demographic?.demographics?.length > 0)
            .flatMap(demographic => demographic?.category)
            .includes(demographic?.id),
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values[fieldName], demographics])

  return (
    <>
      <Box mb={2} key={fieldName}>
        {demographicsIsLoading ? (
          renderLoading()
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant='body1'
                color='text.secondary'
                sx={{ textTransform: 'uppercase' }}
              >
                {label}
              </Typography>
            </Grid>

            {demographicsSectionsOpened.length > 0 && (
              <Grid item xs={12}>
                <Grid container>
                  {demographicsSectionsOpened.map((demographic, index) => (
                    <DemographicSection
                      key={fieldName + index}
                      index={index}
                      formik={formik}
                      demographic={demographic}
                      demographicsSectionsOpened={demographicsSectionsOpened}
                      setDemographicsSectionsOpened={
                        setDemographicsSectionsOpened
                      }
                      fieldName={fieldName}
                    />
                  ))}
                </Grid>
              </Grid>
            )}

            <Grid item xs={12}>
              <Button
                data-cy='demographics'
                variant='contained'
                color='primary'
                size='large'
                disabled={
                  demographicsSectionsOpened.length === demographics.length
                }
                sx={{ borderRadius: '2rem' }}
                onClick={() => {
                  setDialogDemographicsOpen(true)
                }}
              >
                <AddIcon /> {t('components.demographicsPicker.add')}{' '}
                {demographicsSectionsOpened.length > 0 &&
                  t('components.demographicsPicker.additional')}{' '}
                {t('components.demographicsPicker.demographic')}
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>

      <DialogDemographics
        demographics={(demographics ?? []).filter(
          demographic =>
            demographic.demographics_attributes.length > 0 &&
            // allowedDemographicsCategories.includes(demographic.category) &&
            !demographicsSectionsOpened.map(d => d.id).includes(demographic.id),
        )}
        dialogDemographicsOpen={dialogDemographicsOpen}
        setDialogDemographicsOpen={setDialogDemographicsOpen}
        demographicsSectionsOpened={demographicsSectionsOpened}
        setDemographicsSectionsOpened={setDemographicsSectionsOpened}
      />
    </>
  )
}

function DemographicSection({
  index,
  formik,
  demographic,
  demographicsSectionsOpened,
  setDemographicsSectionsOpened,
  fieldName,
}) {
  const handleRemoveDemographic = () => {
    let newDemographicsSections = [...demographicsSectionsOpened]
    const newEventDemographics = formik.values[fieldName]

    newDemographicsSections = (newDemographicsSections ?? []).filter(
      d => d.id !== demographic.id,
    )

    const eventDemographic = newEventDemographics.find(
      d => d.category === demographic.id,
    )

    eventDemographic.demographics = []

    formik.setFieldValue(fieldName, newEventDemographics)
    setDemographicsSectionsOpened([...newDemographicsSections])
  }

  const handleDemographicAttributeChange = (
    eventDemographic,
    attributeIsSelected,
    attribute,
    fieldName,
  ) => {
    const newDemographicsValues = [...(eventDemographic?.demographics ?? [])]

    if (attributeIsSelected) {
      newDemographicsValues.splice(
        eventDemographic?.demographics?.indexOf(attribute.id),
        1,
      )
    } else {
      newDemographicsValues.push(attribute.id)
    }

    const newDemographics = cloneDeep(formik.values[fieldName])

    newDemographics.find(d => d.category === demographic.id).demographics =
      newDemographicsValues

    formik.setFieldValue(fieldName, newDemographics)
  }

  return (
    <Grid
      container
      key={fieldName + index}
      sx={{ py: 2, px: 3, mb: 2, bgcolor: '#f6f6f6', position: 'relative' }}
    >
      <IconButton
        size='small'
        sx={{ position: 'absolute', right: 8, top: 8 }}
        onClick={handleRemoveDemographic}
      >
        <CloseIcon sx={{ color: 'error.light' }} />
      </IconButton>
      <Grid item xs={12}>
        <Typography variant='body1' fontWeight={500} mb={1}>
          {demographic.name}
        </Typography>
      </Grid>

      {demographic &&
        demographic?.demographics_attributes?.map(attribute => {
          const eventDemographic = formik.values[fieldName].find(
            d => d.category === demographic.id,
          )

          const attributeIsSelected =
            eventDemographic?.demographics?.indexOf(attribute.id) > -1

          return (
            <Grid item key={fieldName + attribute.id}>
              <Chip
                key={fieldName + attribute.id}
                variant='filled'
                label={
                  <Typography
                    variant='body2'
                    fontWeight={attributeIsSelected ? 600 : 400}
                  >
                    {attribute.name}
                  </Typography>
                }
                onClick={() =>
                  handleDemographicAttributeChange(
                    eventDemographic,
                    attributeIsSelected,
                    attribute,
                    fieldName,
                  )
                }
                {...(attributeIsSelected && {
                  sx: {
                    bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
                    border: theme => `1px solid ${theme.palette.primary.main}`,
                  },
                })}
              />
            </Grid>
          )
        })}
    </Grid>
  )
}

function DialogDemographics({
  demographics,
  dialogDemographicsOpen,
  setDialogDemographicsOpen,
  demographicsSectionsOpened,
  setDemographicsSectionsOpened,
}) {
  const handleClose = () => {
    setDialogDemographicsOpen(false)
  }

  const handleListItemClick = demographic => {
    setDemographicsSectionsOpened([...demographicsSectionsOpened, demographic])

    handleClose()
  }

  return (
    <Dialog onClose={handleClose} open={dialogDemographicsOpen}>
      <DialogTitle>Select a Demographic</DialogTitle>
      <List sx={{ pt: 0 }}>
        {demographics.map(demographic => (
          <ListItem
            data-cy='demographicOption'
            button
            onClick={() => handleListItemClick(demographic)}
            key={demographic.id}
          >
            <ListItemText primary={demographic.name} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  )
}
