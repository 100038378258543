import { useQuery } from 'react-query'

import { MFASetupRequiredComponent } from './components'
import { useUser } from 'contexts'
import { API } from 'core/requests'

export default function MFASetupRequired() {
  const { user, setUser } = useUser()!

  const { refetch: fetchUser } = useQuery(
    'user',
    () => API.get('users', user?.id),
    {
      enabled: false,
      onSettled: (response: any) => {
        if (response.id) {
          setUser({
            ...user,
            ...response,
          })
        }
      },
    },
  )

  return <MFASetupRequiredComponent fetchUser={fetchUser} />
}
