import { Close as CloseIcon } from '@mui/icons-material'
import { Grid, useTheme, Paper, Theme, IconButton } from '@mui/material'

import { useUser } from 'contexts'
import { isUserSpoofedMode } from 'utils/admin'
import { useSpoofUser } from 'utils/spoof'

export default function AdminMode() {
  const theme: Theme = useTheme()

  const { user } = useUser()!
  const { unspoof } = useSpoofUser()

  if (isUserSpoofedMode() && user.id) {
    return (
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          margin: '0 auto',
          zIndex: 1202,
          maxWidth: 360,
        }}
      >
        <Paper
          data-cy='is_admin_mode'
          elevation={4}
          style={{
            borderRadius: '0 0 8px 8px',
            backgroundColor: theme.palette.secondary.dark,
            color: '#FFF',
            padding: '8px 24px',
            textAlign: 'center',
          }}
        >
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs>
              Spoof Mode - {user?.first_name} {user?.last_name}
            </Grid>

            <Grid item xs='auto'>
              <IconButton size='small' onClick={unspoof}>
                <CloseIcon sx={{ color: '#FFF' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    )
  } else {
    return null
  }
}
