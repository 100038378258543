import { useState } from 'react'

import { Box, Button, Divider, Grid, Typography } from '@mui/material'

import {
  SettingsSlackIntegration,
  SettingsMSTeamsIntegration,
} from '../../components'
import { ConfirmationDialog, DialogTeleport } from 'components'
import { initialDialog } from 'constants/dialog'
import { BotIntegrationStatus } from 'enums/bot'
import { t } from 'i18n'

export default function SettingsComponent({
  companyState,
  saveSettings,
  removeSlackIntegration,
  removeSlackIntegrationIsLoading,
  removeMSTeamsIntegration,
  removeMSTeamsIntegrationIsLoading,
  dialogRemoveSlackIntegrationOpen,
  setDialogRemoveSlackIntegrationOpen,
  dialogRemoveMSTeamsIntegrationOpen,
  setDialogRemoveMSTeamsIntegrationOpen,
}) {
  const [dialogSlackIntegration, setDialogSlackIntegration] =
    useState(initialDialog)

  const [dialogMSTeamsIntegration, setDialogMSTeamsIntegration] =
    useState(initialDialog)

  const getBotIntegrationStatusColor = {
    [BotIntegrationStatus.NotIntegrated]: 'error.main',
    [BotIntegrationStatus.Pending]: 'warning.main',
    [BotIntegrationStatus.Integrated]: 'primary.main',
  }

  const renderIntegrationSection = options => (
    <Grid item xs={12}>
      <Grid
        container
        spacing={2}
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item xs>
          <Typography variant='body1'>
            <Grid container component='span' alignItems='center'>
              <Box
                sx={{
                  height: 12,
                  width: 12,
                  bgcolor:
                    getBotIntegrationStatusColor[
                      companyState?.bot_engine[options.configurationStatus] ??
                        ''
                    ] ?? 'error.main',
                  mr: 1,
                  borderRadius: '100%',
                }}
              ></Box>
              {options.title}
            </Grid>
          </Typography>

          {companyState?.bot_engine[options.feedChannelId] && (
            <Typography variant='body2' color='text.secondary'>
              {t('settings.settingsIntegration.sparckFeedChannelDefined')}
            </Typography>
          )}
        </Grid>

        <Grid item xs='auto'>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Button
                variant='contained'
                color='primary'
                disabled={
                  companyState?.bot_engine[options.configurationStatus] ===
                  BotIntegrationStatus.Integrated
                }
                onClick={() =>
                  options.setDialogIntegration({
                    isOpen: true,
                    data: null,
                  })
                }
                fullWidth
              >
                {t('settings.settingsIntegration.integrate')}
              </Button>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                variant='outlined'
                color='error'
                disabled={
                  companyState?.bot_engine[options.configurationStatus] ===
                  BotIntegrationStatus.NotIntegrated
                }
                onClick={() => options.setDialogRemoveIntegrationOpen(true)}
                fullWidth
              >
                {t('settings.settingsIntegration.remove')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />
    </Grid>
  )

  return (
    <>
      <Grid container spacing={2}>
        {renderIntegrationSection({
          title: t(
            'settings.settingsIntegration.integrationSectionTitles.slackBot',
          ),
          feedChannelId: 'slack_feed_channel_id',
          configurationStatus: 'slack_configuration_status',
          setDialogIntegration: setDialogSlackIntegration,
          setDialogRemoveIntegrationOpen: setDialogRemoveSlackIntegrationOpen,
        })}

        {renderIntegrationSection({
          title: t(
            'settings.settingsIntegration.integrationSectionTitles.msTeams',
          ),
          feedChannelId: 'msteams_feed_channel_id',
          configurationStatus: 'msteams_configuration_status',
          setDialogIntegration: setDialogMSTeamsIntegration,
          setDialogRemoveIntegrationOpen: setDialogRemoveMSTeamsIntegrationOpen,
        })}
      </Grid>

      <DialogTeleport
        dialogTitle={t(
          'settings.settingsIntegration.slackBotIntegrationDialogTitle',
        )}
        dialogAction={null}
        dialogSize='md'
        dialogOpen={dialogSlackIntegration.isOpen}
        handleDialogClose={() =>
          setDialogSlackIntegration({ isOpen: false, data: null })
        }
        isFullWidth
      >
        <SettingsSlackIntegration
          saveSettings={saveSettings}
          companyState={companyState}
          setDialogSlackIntegration={setDialogSlackIntegration}
        />
      </DialogTeleport>

      <ConfirmationDialog
        dialogOpen={dialogRemoveSlackIntegrationOpen}
        setDialogOpen={setDialogRemoveSlackIntegrationOpen}
        message={t('components.confirmationDialog.defaultMessage', {
          action: t(
            'settings.settingsIntegration.slackIntegrationConfirmDelete.actionLabel',
          ),
          item: t(
            'settings.settingsIntegration.slackIntegrationConfirmDelete.itemName',
          ),
        })}
        item={null}
        actionProps={{
          onClick: removeSlackIntegration,
          isLoading: removeSlackIntegrationIsLoading,
          label: t(
            'settings.settingsIntegration.slackIntegrationConfirmDelete.actionLabel',
          ),
          color: 'error',
        }}
      />

      <DialogTeleport
        dialogTitle={t(
          'settings.settingsIntegration.msTeamsBotIntegrationDialogTitle',
        )}
        dialogAction={null}
        dialogSize='md'
        dialogOpen={dialogMSTeamsIntegration.isOpen}
        handleDialogClose={() =>
          setDialogMSTeamsIntegration({ isOpen: false, data: null })
        }
        isFullWidth
      >
        <SettingsMSTeamsIntegration companyState={companyState} />
      </DialogTeleport>

      <ConfirmationDialog
        dialogOpen={dialogRemoveMSTeamsIntegrationOpen}
        setDialogOpen={setDialogRemoveMSTeamsIntegrationOpen}
        message={t('components.confirmationDialog.defaultMessage', {
          action: t(
            'settings.settingsIntegration.msTeamsIntegrationConfirmDelete.actionLabel',
          ),
          item: t(
            'settings.settingsIntegration.msTeamsIntegrationConfirmDelete.itemName',
          ),
        })}
        item={null}
        actionProps={{
          onClick: removeMSTeamsIntegration,
          isLoading: removeMSTeamsIntegrationIsLoading,
          label: t(
            'settings.settingsIntegration.msTeamsIntegrationConfirmDelete.actionLabel',
          ),
          color: 'error',
        }}
      />
    </>
  )
}
