import { useState, useEffect } from 'react'

import { isEmpty } from 'lodash'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useParams } from 'react-router-dom'

import { SparckDisplayComponent } from './components'
import { useUser } from 'contexts/UserContext'
import { API } from 'core/requests'
import { t } from 'i18n'

type Location = {
  state: {
    visibleUserID: string
  }
}

export default function SparckDisplay({ sparckPreview }) {
  const { state } = useLocation() as Location

  const { pageId, pageUUID }: any = useParams()
  const { user } = useUser()!
  const { enqueueSnackbar } = useSnackbar()

  const [sparckState, setSparckState] = useState({})
  const [userNotAuthorizedToViewSparck, setUserNotAuthorizedToViewSparck] =
    useState(false)

  const { isLoading: sparckIsLoading, refetch: fetchSparck } = useQuery(
    'getSparck',
    () => API.get('sparcks', pageId),
    {
      enabled: false,
      onSettled: (response: any) => {
        if (response.id) {
          setSparckState(response)
        }

        if (
          response?.errors?.length &&
          response.error_type === 'user_not_authorized'
        ) {
          setUserNotAuthorizedToViewSparck(true)
        }
      },
    },
  )

  const { refetch: fetchPublicSparck } = useQuery(
    'getPublicSparck',
    () => API.get('publicSparck', pageUUID),
    {
      enabled: false,
      onSuccess: (response: any) => {
        setSparckState(response)
      },
    },
  )

  const answerSparckMutation = useMutation(
    (payload: any) => API.create('answerSparck', payload),
    {
      onSuccess: (response: any) => {
        if (response.id) {
          fetchSparck()
          enqueueSnackbar(t('sparcks.snackbar.success.responseSent'), {
            variant: 'success',
          })
        }
      },
    },
  )

  useEffect(() => {
    if (pageId) {
      fetchSparck()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId])

  useEffect(() => {
    if (pageUUID) {
      fetchPublicSparck()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageUUID])

  useEffect(() => {
    if (sparckPreview) {
      setSparckState({
        ...sparckPreview,
        users_recognized: sparckPreview.users_to_recognize,
        user_recognizing: user,
      })
    }
  }, [sparckPreview, user])

  return (
    <SparckDisplayComponent
      sparck={sparckState || []}
      isLoading={
        sparckPreview
          ? isEmpty(sparckState)
          : sparckIsLoading || isEmpty(sparckState)
      }
      isSparckPreview={!!sparckPreview}
      answerSparck={answerSparckMutation.mutate}
      answerSparckStatus={answerSparckMutation}
      fetchSparck={fetchSparck}
      pageId={pageId}
      visibleUserID={state?.visibleUserID}
      userNotAuthorizedToViewSparck={userNotAuthorizedToViewSparck}
    />
  )
}
