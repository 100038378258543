import { Box, Grid, Typography } from '@mui/material'

import { t } from 'i18n'

export default function ProgressBarLegend() {
  return (
    <Box my={1}>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant='body1'
            color='text.secondary'
            fontWeight={600}
            align='left'
          >
            {t('beheardReport.progressBarLegend.legendTitle')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            display={'flex'}
            justifyContent={'space-around'}
            style={{
              background: `linear-gradient(270deg, #1ACFC6 0%, #FFCB2F 52.95%, #EF3B42 100%)`,
            }}
            borderRadius={2}
            color='white'
            fontSize='.8rem'
          >
            <p>
              {t('beheardReport.progressBarLegend.colorMeaning.negative')} (0% -
              69%)
            </p>
            <p>
              {' '}
              {t('beheardReport.progressBarLegend.colorMeaning.neutral')} (70% -
              79%)
            </p>
            <p>
              {t('beheardReport.progressBarLegend.colorMeaning.positive')} (80%
              - 100%)
            </p>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
