export enum Locale {
  BrazilianPortuguese = 'pt-br',
  English = 'en-us',
  Spanish = 'es-es',
  Japanese = 'ja-ja',
  German = 'de-de',
  Arabic = 'ar-sa',
  Danish = 'da-dk',
  Dutch = 'nl-nl',
  French = 'fr-ca',
  Finnish = 'fi-fl',
  Italian = 'it-it',
  Russian = 'ru-ru',
  Swedish = 'sv-se',
  BrazilianSpanish = 'es-br',
  CanadianFrench = 'ca-cf',
  EUSpanish = 'eu-es',

}
