import * as React from 'react'

export default function AlignmentIcon(props) {
  return (
    <svg
      width={101.234}
      height={101.234}
      viewBox='0 0 101.234 101.234'
      {...props}
    >
      <defs>
        <clipPath id='prefix__a'>
          <path
            className='prefix__a'
            d='M0 30.2h42.209v-55.4H0z'
            transform='translate(0 25.2)'
          />
        </clipPath>
        <style>{'.prefix__a{fill:#fa8d25}'}</style>
      </defs>
      <g transform='translate(-509 -1848.766)'>
        <circle
          cx={50.617}
          cy={50.617}
          r={50.617}
          transform='translate(509 1848.766)'
          fill='#fff'
        />
        <g transform='rotate(-138 647.553 857.134)' clipPath='url(#prefix__a)'>
          <path
            className='prefix__a'
            d='M39.901 2.308A7.644 7.644 0 0034.295 0a7.637 7.637 0 00-5.606 2.308 7.636 7.636 0 00-2.308 5.606 7.282 7.282 0 004.617 7.123 8.817 8.817 0 01-1.913 5.013 9.224 9.224 0 01-3.364 2.7q-1.715.725-5.54 1.913-6.463 1.979-9.1 3.957V15.169a7.53 7.53 0 004.748-7.255 7.644 7.644 0 00-2.309-5.606A7.644 7.644 0 007.914 0a7.637 7.637 0 00-5.606 2.308A7.636 7.636 0 000 7.914a7.53 7.53 0 004.75 7.255v24.93a7.627 7.627 0 00-4.749 7.387 7.629 7.629 0 002.308 5.606A7.629 7.629 0 007.915 55.4a7.636 7.636 0 005.605-2.308 7.637 7.637 0 002.308-5.606 7.234 7.234 0 00-4.617-7.255 11.771 11.771 0 011.055-3.957 6.545 6.545 0 012.506-2.638 18.775 18.775 0 013.101-1.586q1.385-.524 4.155-1.451 3.034-.92 4.946-1.715a27.128 27.128 0 004.485-2.506 11.839 11.839 0 004.023-4.551 18.751 18.751 0 001.847-6.661 7.229 7.229 0 004.88-7.255 7.644 7.644 0 00-2.308-5.606M4.75 4.617a4.319 4.319 0 013.166-1.319 4.548 4.548 0 013.234 1.319 4.365 4.365 0 011.385 3.3 4.211 4.211 0 01-1.385 3.163 4.539 4.539 0 01-3.232 1.319A4.31 4.31 0 014.75 11.08a4.31 4.31 0 01-1.32-3.166 4.474 4.474 0 011.319-3.3M3.43 47.486a4.474 4.474 0 011.319-3.3 4.319 4.319 0 013.166-1.319 4.548 4.548 0 013.235 1.321 4.365 4.365 0 011.385 3.3 4.211 4.211 0 01-1.385 3.162 4.539 4.539 0 01-3.232 1.319A4.31 4.31 0 014.75 50.65a4.31 4.31 0 01-1.319-3.166M29.81 7.914a4.474 4.474 0 011.319-3.3 4.319 4.319 0 013.166-1.319 4.548 4.548 0 013.232 1.319 4.365 4.365 0 011.385 3.3 4.211 4.211 0 01-1.385 3.166 4.539 4.539 0 01-3.232 1.319 4.31 4.31 0 01-3.166-1.319 4.31 4.31 0 01-1.319-3.166'
          />
        </g>
      </g>
    </svg>
  )
}
