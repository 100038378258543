import { Locale } from 'enums/locale'

export const LANGUAGES = [
  {
    id: 'en',
    label: 'English',
    shortLabel: 'ENG',
    value: Locale.English,
  },
  {
    id: 'es',
    label: 'Español',
    shortLabel: 'SPA',
    value: Locale.Spanish,
  },
  {
    id: 'pt-br',
    label: 'Portuguese BR',
    shortLabel: 'BRA',
    value: Locale.BrazilianPortuguese,
  },
  {
    id: 'ja',
    label: 'Japanese',
    shortLabel: 'JAP',
    value: Locale.Japanese,
  },
  {
    id: 'de',
    label: 'German',
    shortLabel: 'GER',
    value: Locale.German,
  },
  {
    id: 'ar',
    label: 'Arabic',
    shortLabel: 'SAU',
    value: Locale.Arabic,
  },
  {
    id: 'da',
    label: 'Danish',
    shortLabel: 'DAN',
    value: Locale.Danish,
  },
  {
    id: 'nl',
    label: 'Dutch',
    shortLabel: 'DUT',
    value: Locale.Dutch,
  },
  {
    id: 'fr',
    label: 'French',
    shortLabel: 'FRE',
    value: Locale.French,
  },
  {
    id: 'fi',
    label: 'Finnish',
    shortLabel: 'FIN',
    value: Locale.Finnish,
  },
  {
    id: 'it',
    label: 'Italian',
    shortLabel: 'ITA',
    value: Locale.Italian,
  },
  {
    id: 'sv',
    label: 'Swedish',
    shortLabel: 'SWE',
    value: Locale.Swedish,
  },
  {
    id: 'ru',
    label: 'Russian',
    shortLabel: 'RU',
    value: Locale.Russian,
  },
  {
    id: 'fr',
    label: 'Canadian French',
    shortLabel: 'FR',
    value: Locale.CanadianFrench,
  },
  {
    id: 'es',
    label: 'EU Spanish',
    shortLabel: 'ES',
    value: Locale.EUSpanish,
  },
]
