import { Box } from '@mui/material'
import sparckProfileBanner from 'assets/images/sparck-profile-banner.png'

export default function ProfileBackground({ profileBackgroundHeight }) {
  return (
    <div style={{ position: 'relative' }}>
      <Box
        component='div'
        sx={{
          width: '100%',
          height: `${profileBackgroundHeight}px`,
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          position: 'absolute',
          left: '0px',
          right: '0px',
          top: '0px',
          bottom: '0px',
          zIndex: 999,
        }}
      ></Box>
      <Box
        component='img'
        sx={{
          width: '100%',
          height: `${profileBackgroundHeight}px`,
          objectFit: 'cover',
          position: 'absolute',
        }}
        src={sparckProfileBanner}
        alt='Profile background'
      />
    </div>
  )
}
