import { Grid, Skeleton, Typography } from '@mui/material'

import { Props } from '../../VirginExperiencesSettings.props'
import { SparckTable } from 'components'

export default function VirginExperiencesSettingsCreditAlerts(props: Props) {
  const { virginExperiencesSettings, virginExperiencesSettingsIsLoading } =
    props

  return (
    <Grid container>
      {virginExperiencesSettingsIsLoading ? (
        <Grid item xs={12}>
          <Skeleton width='100%' height={40} />
        </Grid>
      ) : (
        <Grid item xs={12}>
          {virginExperiencesSettings?.remaining_credit_alerts?.length > 0 ? (
            <SparckTable
              slug='remaining_credit_alerts'
              data={virginExperiencesSettings?.remaining_credit_alerts}
              columns={[
                {
                  title: 'Amount',
                  field: 'remaining_amount',
                  editable: 'never',
                },
                {
                  title: 'Alert was sent',
                  field: 'alert_sent',
                  editable: 'never',
                  render: (rowData: any) => (rowData.alert_sent ? 'Yes' : 'No'),
                },
              ]}
              style={{ boxShadow: 'none' }}
              hideToolbar
            />
          ) : (
            <Typography variant='body1' color='text.secondary' fontWeight={500}>
              No credit alerts configured yet.
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  )
}
