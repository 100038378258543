import palette from 'theme/palette'

export default darkMode => ({
  styleOverrides: {
    root: {
      width: 56,
      height: 56,
    },
    colorDefault: {
      backgroundColor: palette(darkMode).primary.main,
    },
  },
})
