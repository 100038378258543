import qs from 'qs'

import {
  SPARCK_USER_TOKEN,
  SPARCK_USER_ID,
  SPARCK_USER_TOKEN_ID,
} from 'constants/app'
import { API_BASE_URL } from 'core/environments'
import { history } from 'core/history'
import { ROUTES_PATHS } from 'core/routes'
import SnackbarUtils from 'core/SnackbarConfiguration'
import { getUserTokenFromLocalStorage } from 'utils'
import { getSpoofedCompanyFromLocalStorage } from 'utils/admin'

export type RequestOptionsType = {
  path: string
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
  queryParams?: any
  pathParams?: any
  body?: any
}

// used in admin mode
export const getCompanyId = () => {
  const company = getSpoofedCompanyFromLocalStorage()

  return company?.id ?? ''
}

export const LS_BUILD_KEY = 'current.build.version'
export const currentBuildVersion = () => localStorage.getItem(LS_BUILD_KEY)

export const defaultHeaders = (token, body) => ({
  headers: {
    Accept: 'application/json',
    ...(token && { Authorization: `Bearer ${token}` }),
    'Access-Control-Expose-Headers': '*',
    ...(!(body instanceof FormData) && {
      'Content-Type': 'application/json',
    }),
    ...(currentBuildVersion() && {
      'X-Front-End-Build-Version': currentBuildVersion(),
    }),
  },
})

export const validateAPIResponse = async response => {
  const data = await response.json()

  const refreshToken = response.headers.get('X-Refreshed-Token')

  if (refreshToken) {
    localStorage.setItem(SPARCK_USER_TOKEN, refreshToken)
  }

  const reloadRequired =
    currentBuildVersion() && response.status === 428 && data.reload

  if (reloadRequired) {
    localStorage.removeItem(SPARCK_USER_TOKEN)
    localStorage.removeItem(SPARCK_USER_TOKEN_ID)
    localStorage.removeItem(SPARCK_USER_ID)

    localStorage.setItem(LS_BUILD_KEY, data.versions.current)

    window.location.reload()
  }

  if (response && response.status >= 400) {
    if (
      !(
        data &&
        data.error_type === 'user_not_authorized' &&
        !getUserTokenFromLocalStorage()
      )
    ) {
      if (
        data &&
        data.errors &&
        data.error_type &&
        ![
          'minimum_participants_needed',
          'token_expired',
          'record_not_unique',
        ].includes(data.error_type) &&
        !data.token_expired
      ) {
        data.errors.forEach(error => {
          SnackbarUtils.error(error)
        })
      }
      if (data.error_type && data.error_type === 'token_expired') {
        history.push(ROUTES_PATHS.login)
        localStorage.removeItem(SPARCK_USER_TOKEN)
        localStorage.removeItem(SPARCK_USER_ID)
        throw new Error('token_expired')
      }
    }
  }

  return data
}

const handlePathParams = (url, pathParams) => {
  for (const match of url.pathname.matchAll(/(:\w+)/g)) {
    const matchName = match[0].replace(':', '')
    const matchValue = pathParams[matchName]
    url.pathname = url.pathname.replace(match[0], matchValue ? matchValue : '')
  }

  return url
}

const handleSearchParams = (queryParams, method) => {
  const companyId = getCompanyId()

  const newQueryParams =
    companyId && method === 'GET'
      ? { ...queryParams, company_id: companyId }
      : queryParams

  const searchParams = qs.stringify(newQueryParams, { arrayFormat: 'comma' })

  return searchParams
}

const getRequestURL = (requestOptions: RequestOptionsType) => {
  const { path, queryParams = {}, pathParams = {}, method } = requestOptions

  const url: URL = handlePathParams(
    new URL(`${API_BASE_URL}${path}`),
    pathParams,
  )

  const searchParams: any = handleSearchParams(queryParams, method)

  url.search = searchParams.toString()
  url.toString().replace(/(\/)\W+/g, '/')

  return url.toString()
}

const getRequestBody = (method, body) => {
  const companyId = getCompanyId()

  if (body instanceof FormData) {
    return body
  }

  if (body) {
    if (companyId) body.company_id = companyId

    return JSON.stringify(body)
  }

  if (companyId && method !== 'GET') {
    return JSON.stringify({ company_id: companyId })
  }

  return null
}

export const APIRequest = (requestOptions: RequestOptionsType) => {
  const { method, body } = requestOptions

  return fetch(getRequestURL(requestOptions), {
    ...defaultHeaders(getUserTokenFromLocalStorage(), body),
    // @ts-ignore
    encoding: null,
    mode: 'cors',
    method: method,
    body: getRequestBody(method, body),
  }).then(validateAPIResponse)
}
