import { useState, useEffect, useMemo } from 'react'

import {
  Add as IncrementIcon,
  Remove as DecrementIcon,
} from '@mui/icons-material'
import {
  alpha,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Link,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { sortBy, sortedUniq, uniq } from 'lodash'
import { useIntl } from 'react-intl'

import { MoneyInput, GiftCardRewardCard } from 'components'
import { RewardProvider, RewardType } from 'enums/reward'
import { t } from 'i18n'
import { formatToUSD } from 'utils/currency'
import { VIRGIN_EXPERIENCES_GIFT_CARD } from 'utils/virginExperiences'

const getGiftCardIncrementValue = option =>
  option?.increment > 1 ? option?.increment : 1

const getGiftCardMinimumValue = option => +option?.minimum

const getGiftCardMaximumValue = (option: any, giftCardMaximumValue: any) =>
  Math.min(
    ...[
      ...(option ? [+option?.maximum] : []),
      ...(giftCardMaximumValue ? [+giftCardMaximumValue] : []),
    ],
  )

type Props = {
  reward: any
  onConfirmReward(reward: any): void
  giftCardMaximumValue: number
}

export default function SparckConfirmGiftCardRewardDetails({
  reward,
  onConfirmReward,
  giftCardMaximumValue,
}: Props) {
  const intl = useIntl()

  const product = reward?.product

  const [selectedAmount, setSelectedAmount] = useState(0)
  const [showValuePicker, setShowValuePicker] = useState(false)

  const isValueValidInFixedOption = (value, option) => +option.value === +value
  const isValueValidInRangeOption = (value, option) => {
    const incrementValue = getGiftCardIncrementValue(option)
    const minimumValue = getGiftCardMinimumValue(option)
    const maximumValue = getGiftCardMaximumValue(option, giftCardMaximumValue)

    return (
      value <= +maximumValue &&
      value >= +minimumValue &&
      Math.round(value % +incrementValue) === 0
    )
  }

  const isValueValid = value => {
    if (product?.fixed_options?.length > 0 && !product?.range_options?.length) {
      return product?.fixed_options?.find(option =>
        isValueValidInFixedOption(+value, option),
      )
    }

    if (product?.range_options?.length > 0) {
      return product?.range_options?.some(option =>
        isValueValidInRangeOption(+value, option),
      )
    }

    return false
  }

  const suggestedValues = [1, 2, 5, 10, 15, 20, 30, 50, 100]

  const validSuggestedValues = useMemo(
    () =>
      uniq(
        sortedUniq([
          ...suggestedValues,
          ...(product?.fixed_options ?? [])?.map(option => +option.value),
        ]),
      ).filter(suggestedValue =>
        !showValuePicker
          ? +suggestedValue === +selectedAmount
          : isValueValid(suggestedValue),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reward, showValuePicker],
  )

  const handleConfirm = reward => {
    const productSKU =
      product?.fixed_options?.find(option =>
        isValueValidInFixedOption(+selectedAmount, option),
      )?.sku ??
      product?.range_options?.find(option =>
        isValueValidInRangeOption(+selectedAmount, option),
      )?.sku

    onConfirmReward({
      provider:
        reward.id === VIRGIN_EXPERIENCES_GIFT_CARD
          ? RewardProvider.VirginExperiences
          : RewardProvider.NGC,
      type: RewardType.Retailer,
      amount: selectedAmount,
      sku: productSKU,
      details: reward,
    })
  }

  const handleShowValuePicker = () => {
    setShowValuePicker(true)
  }

  useEffect(() => {
    if (!selectedAmount && product) {
      const newValue = Math.min(
        ...[
          ...(+giftCardMaximumValue ? [+giftCardMaximumValue] : []),
          ...(product?.fixed_options?.length > 0
            ? [product?.fixed_options[product?.fixed_options.length - 1]?.value]
            : []),
          ...(product?.range_options?.length > 0
            ? [
                product?.range_options[product?.range_options.length - 1]
                  ?.maximum,
              ]
            : []),
        ],
      )

      setSelectedAmount(newValue)

      if (!validSuggestedValues.includes(newValue)) {
        validSuggestedValues.push(newValue)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  if (!reward) return null

  return (
    <Card
      style={{
        position: 'relative',
        overflow: 'inherit',
        borderRadius: 32,
      }}
      elevation={0}
    >
      <GiftCardRewardCard reward={reward} />

      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant='body1'
              fontWeight={500}
              paragraph
              align='center'
            >
              {showValuePicker
                ? t(
                    'sparcks.sparck.sparckConfirmGiftCardRewardDetails.selectGiftCardAmount',
                  )
                : t(
                    'sparcks.sparck.sparckConfirmGiftCardRewardDetails.recommendedGiftCardAmount',
                  )}
            </Typography>
          </Grid>

          {validSuggestedValues?.length > 0 && (
            <>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={1}
                  justifyContent='center'
                  sx={{ mb: 2 }}
                >
                  {sortBy(sortedUniq([...validSuggestedValues]))?.map(
                    suggestedValue => (
                      <Grid item xs={6} sm={4} key={suggestedValue}>
                        <ChipValue
                          value={suggestedValue}
                          setValue={setSelectedAmount}
                          selectedAmount={selectedAmount}
                        />
                      </Grid>
                    ),
                  )}
                </Grid>
              </Grid>

              {!showValuePicker && (
                <Typography
                  variant='body1'
                  align='center'
                  sx={{ width: '100%' }}
                >
                  <Link onClick={handleShowValuePicker}>
                    {t(
                      'sparcks.sparck.sparckConfirmGiftCardRewardDetails.selectADifferentValueAmount',
                    )}
                  </Link>
                </Typography>
              )}

              {showValuePicker && product?.range_options?.length > 0 && (
                <ValuePicker
                  selectedAmount={selectedAmount}
                  setSelectedAmount={setSelectedAmount}
                  rangeOptions={product?.range_options}
                  giftCardMaximumValue={giftCardMaximumValue}
                />
              )}

              {isValueValid(selectedAmount) && (
                <Grid item xs={12} mt={2}>
                  <Button
                    variant='contained'
                    color='primary'
                    size='large'
                    fullWidth
                    onClick={() => handleConfirm(reward)}
                  >
                    {intl.formatMessage(
                      { id: 'sparckReward.confirmAmount' },
                      { amount: formatToUSD(selectedAmount) },
                    )}
                  </Button>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}

function ChipValue({ value, setValue, selectedAmount }) {
  const theme = useTheme()

  return (
    <Chip
      onClick={() => setValue(value)}
      label={
        <Typography variant='body2' fontWeight={600}>
          {formatToUSD(value)}
        </Typography>
      }
      sx={{
        width: '100%',
        mb: 0,
        ...(value === +selectedAmount && {
          outline: `1.5px solid ${theme.palette.primary.main}`,
          bgcolor: alpha(theme.palette.primary.main, 0.15),
        }),
      }}
    />
  )
}

function ValuePicker({
  selectedAmount,
  setSelectedAmount,
  rangeOptions,
  giftCardMaximumValue = null,
}: any) {
  const [errorMessage, setErrorMessage] = useState('')

  const intl = useIntl()

  const selectedRangeOption =
    rangeOptions.find(
      rangeOption =>
        selectedAmount <= +rangeOption?.maximum &&
        selectedAmount >= +rangeOption?.minimum &&
        Math.round(selectedAmount % +rangeOption?.increment) === 0,
    ) ?? rangeOptions[0]

  const minimumValue = +getGiftCardMinimumValue(selectedRangeOption)
  const maximumValue = +getGiftCardMaximumValue(
    selectedRangeOption,
    giftCardMaximumValue,
  )

  const incrementValue = getGiftCardIncrementValue(selectedRangeOption)

  const decrement = () => {
    if (+selectedAmount > 0 && +selectedAmount > minimumValue) {
      setSelectedAmount(+selectedAmount - +incrementValue)
    }
  }

  const increment = () => {
    if (+selectedAmount < maximumValue) {
      setSelectedAmount(+selectedAmount + +incrementValue)
    }
  }

  const onInputValueChange = (event: any) => {
    const { value } = event.target

    setSelectedAmount(value)
  }

  useEffect(() => {
    if (
      Math.round(+selectedAmount % +incrementValue) !== 0 ||
      !Number.isInteger(+selectedAmount)
    ) {
      setErrorMessage(
        intl.formatMessage(
          { id: 'sparckReward.giftCardMustBeWithin' },
          {
            value: formatToUSD(incrementValue),
          },
        ),
      )
    } else if (+selectedAmount < minimumValue) {
      setErrorMessage(
        intl.formatMessage(
          { id: 'sparckReward.giftCardCantBeLess' },
          {
            value: formatToUSD(minimumValue),
          },
        ),
      )
    } else if (+selectedAmount > maximumValue) {
      setErrorMessage(
        intl.formatMessage(
          { id: 'sparckReward.giftCardCantBeGreater' },
          {
            value: formatToUSD(maximumValue),
          },
        ),
      )
    } else {
      setErrorMessage('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAmount])

  return (
    <Box mt={2} width={1}>
      <Grid container>
        <Grid item xs={12}>
          <Grid
            container
            spacing={1}
            alignItems='center'
            justifyContent='space-between'
          >
            <Grid item xs='auto'>
              <Button
                variant='outlined'
                color='secondary'
                onClick={decrement}
                style={{
                  borderRadius: '100%',
                  minWidth: 0,
                  height: 48,
                  width: 48,
                }}
              >
                <DecrementIcon />
              </Button>
            </Grid>

            <Grid item xs>
              <TextField
                id='selectedAmount'
                name='selectedAmount'
                variant='outlined'
                value={selectedAmount}
                onChange={onInputValueChange}
                inputProps={{
                  style: {
                    fontSize: 16,
                  },
                }}
                fullWidth
                size='small'
                InputProps={{
                  inputComponent: MoneyInput,
                }}
              />
            </Grid>

            <Grid item xs='auto'>
              <Button
                variant='contained'
                color='primary'
                onClick={increment}
                style={{
                  borderRadius: '100%',
                  minWidth: 0,
                  height: 48,
                  width: 48,
                }}
              >
                <IncrementIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {errorMessage ? (
          <Box mt={1} width={1}>
            <Grid item xs={12}>
              <Typography
                variant='body2'
                color='error.light'
                align='center'
                paragraph
              >
                {errorMessage}
              </Typography>
            </Grid>
          </Box>
        ) : (
          <Box mt={1} width={1}>
            <Typography variant='body2' color='text.secondary' align='center'>
              {intl.formatMessage(
                { id: 'sparckReward.recognitionProgramMaxBudget' },
                {
                  value: formatToUSD(maximumValue),
                },
              )}
            </Typography>
          </Box>
        )}
      </Grid>
    </Box>
  )
}
