import { useState } from 'react'
import {
  Button,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material'

import { ManagerCertificationSubmitStoryForm } from '..'
import { DialogTeleport } from 'components'
import { t } from 'i18n'

export default function ManagerCertificationSubmitStoryCardContent(props) {
  const [submitStoryDialogOpen, setSubmitStoryDialogOpen] = useState(false)

  const { userCertification } = props

  return (
    <>
      <CardContent>
        <Typography
          color='text.primary'
          fontSize={24}
          fontWeight={700}
          textAlign='center'
          mt={2}
        >
          {t('managerCertification.submitStoryCard.title')}
        </Typography>
        <Typography
          color={'text.secondary'}
          fontSize={16}
          paragraph
          textAlign='justify'
          mt={2}
        >
          {t('managerCertification.submitStoryCard.description')}
        </Typography>
      </CardContent>
      <CardActions sx={{ mb: 6 }}>
        <Grid container justifyContent='center' mt={-3}>
          <Button
            size='large'
            variant='contained'
            onClick={() => setSubmitStoryDialogOpen(true)}
            disabled={!userCertification?.completed_at}
          >
            {t('managerCertification.submit')}
          </Button>
        </Grid>
      </CardActions>
      <DialogTeleport
        dialogTitle={t('managerCertification.submitStoryCard.title')}
        dialogAction={null}
        dialogSize='md'
        dialogOpen={submitStoryDialogOpen}
        handleDialogClose={() => setSubmitStoryDialogOpen(false)}
        isFullWidth
      >
        <ManagerCertificationSubmitStoryForm
          setSubmitStoryDialogOpen={setSubmitStoryDialogOpen}
        />
      </DialogTeleport>
    </>
  )
}
