export enum SurveyQuestionType {
  Default = 'default',
  Custom = 'custom',
  OpenEnded = 'open_ended',
}

export enum SurveyQuestionScoreType {
  SatisfactionScore = 'satisfactionScore',
  ImportanceScore = 'importanceScore',
  OpenEndedAnswer = 'openEndedAnswer',
}

export enum SurveyEngagement {
  Engaged = 'engaged',
  Neutral = 'neutral',
  Neutrals = 'neutrals',
  Disengaged = 'disengaged',
}

export enum SurveyEnps {
  Promoter = 'promoters',
  Passive = 'passives',
  Detractor = 'detractors',
}

// because new api of team insight is not having s in last.
export enum SurveyEnpsForReportPage {
  Promoter = 'promoter',
  Passive = 'passive',
  Detractor = 'detractor',
}

export enum SurveyAvgEnps {
  EmployeeNetPromotorScore = 'employee_net_promotor_score',
}

export enum SurveyDriverScore {
  Alignment = 'alignment',
  Engagement = 'engagement',
  Fit = 'fit',
  Growth = 'growth',
  Team = 'team',
  Valuing = 'valuing',
  CustomRatingsQuestions = 'custom_ratings_questions',
}

export enum SurveyTopFive {
  Action = 'action',
  Strength = 'strength',
  Importance = 'importance',
}

export enum SurveyStatus {
  Scheduled = 'scheduled',
  Open = 'open',
  Close = 'close',
  AwaitingManualOpening = 'awaiting_manual_opening',
}
export enum SurveyScorePrevious {
  Alignment = 'alignment',
  Engagement = 'engagement',
  Fit = 'fit',
  Growth = 'growth',
  Team = 'team',
  Valuing = 'valuing',
  CustomRatingsQuestions = 'custom_ratings_questions',
}
export enum SurveyScorePreviousavgenps {
  EmployeeNetPromotorScore = 'employee_net_promotor_score',
}
