import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'

import { getUniqueColor } from 'types/colors'

type Props = {
  reward: any
  onClick?: (reward: any) => any
  cardSx?: any
}

export default function GiftCardRewardCard({ reward, onClick, cardSx }: Props) {
  const product = reward?.product
  const brand = reward?.brand

  const theme = useTheme()

  const rewardBackgroundColor = getUniqueColor(theme, {
    id: reward?.id,
    isGradient: true,
  })

  return (
    <Card
      key={`${reward.id}`}
      sx={{
        position: 'relative',
        overflow: 'inherit',
        aspectRatio: '16/9',
        ...(cardSx && {
          ...cardSx,
        }),
      }}
    >
      <CardActionArea
        onClick={() => onClick && onClick(reward)}
        sx={{ height: 1, borderRadius: 4, aspectRatio: '16/9' }}
      >
        {product?.faceplate_url || brand?.logo_url ? (
          <CardMedia
            component='img'
            className='reward-image'
            sx={{ aspectRatio: '16/9', borderRadius: 4 }}
            alt={brand.name}
            image={
              product.faceplate_url ? product.faceplate_url : brand.logo_url
            }
            title={brand.name}
          />
        ) : (
          <Box
            p={2}
            width={1}
            height={1}
            sx={{
              aspectRatio: '16/9',
              borderRadius: 4,
              background: rewardBackgroundColor,
            }}
          >
            <Grid
              container
              justifyContent='center'
              alignItems='center'
              style={{ height: '100%' }}
            >
              <Typography
                variant='body1'
                fontWeight={500}
                color='text.white'
                align='center'
              >
                {brand?.name}
              </Typography>
            </Grid>
          </Box>
        )}
      </CardActionArea>
    </Card>
  )
}
