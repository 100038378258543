import { Box, Button, Grid } from '@mui/material'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'

import { ROUTES_PATHS } from 'core/routes'
import {
  OnboardingPage,
  OnboardingQuestionType,
} from 'enums/onboardingQuestion'
import { t } from 'i18n'

export default function OnboardingPagination({
  answers,
  pages,
  pagination,
  setPagination,
  finishOnboarding,
  saveProfileQuestions,
  isSupervisorView,
  userId,
}) {
  const history: any = useHistory()

  const handleNextPage = () => {
    if (pagination === OnboardingPage.ProfileQuestions && !isSupervisorView) {
      saveProfileQuestions()
    }

    setPagination(pages[pages.indexOf(pagination) + 1])
  }

  const isFirstPage = () => pagination === pages[0]
  const isLastPage = () => pagination === pages[pages.length - 1]

  const isNextPageButtonDisabled = () => {
    if (isSupervisorView) return false

    let unansweredQuestions: any = []

    if (pagination === OnboardingPage.RatingAndSimpleSelect) {
      unansweredQuestions = answers.filter(
        answer =>
          (answer.type === OnboardingQuestionType.Rating &&
            !answer.satisfaction) ||
          (answer.type === OnboardingQuestionType.SimpleSelect &&
            !answer.selected_choice_id),
      )
    }

    if (pagination === OnboardingPage.Reward) {
      unansweredQuestions = answers.filter(
        answer =>
          answer.type === OnboardingQuestionType.Reward && !answer.satisfaction,
      )
    }

    if (pagination === OnboardingPage.Event) {
       answers.map((answer: any) => {
        if(answer.type === OnboardingQuestionType.Event){
          if(answer.wants_to_be_recognized === null || answer.wants_to_be_recognized === undefined || answer.wants_to_be_recognized === ""){
            unansweredQuestions.push(answer)
          } else if(answer.wants_to_be_recognized === true){
            if(answer.wants_to_be_recognized_in_public === null || answer.wants_to_be_recognized_in_public === undefined || answer.wants_to_be_recognized_in_public === ""){
              unansweredQuestions.push(answer)
            }
          }
        }
      })
    }

    if (pagination === OnboardingPage.ChoiceGroup) {
      unansweredQuestions = answers.filter(
        answer =>
          answer.type === OnboardingQuestionType.ChoiceGroup &&
          answer.choice_group !== null &&
          !answer.selected_choice_id,
      )
    }

    return !isEmpty(unansweredQuestions)
  }

  return (
    <Box my={3}>
      <Grid container justifyContent='center' spacing={2}>
        {!isFirstPage() && (
          <Grid item>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => {
                window.scrollTo(0, 0)

                setPagination(pages[pages.indexOf(pagination) - 1])
              }}
              size='large'
            >
              {t('onboarding.pagination.previous')}
            </Button>
          </Grid>
        )}

        <Grid item>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              window.scrollTo(0, 0)
              if (isLastPage()) {
                if (isSupervisorView) {
                  history.push(`${ROUTES_PATHS.profile}/${userId}`)
                } else {
                  finishOnboarding()
                }
              } else {
                handleNextPage()
              }
            }}
            disabled={!!isNextPageButtonDisabled()}
            size='large'
          >
            {isFirstPage()
              ? t('onboarding.pagination.imReady')
              : isLastPage()
              ? isSupervisorView
                ? t('onboarding.pagination.backToProfile')
                : t('onboarding.pagination.finish')
              : t('onboarding.pagination.next')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
