import { IconButton } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'

export default function DialogCloseButton({ handleClose }: any) {
  return (
    <IconButton
      data-cy='closeDialog'
      onClick={handleClose}
      style={{
        position: 'absolute',
        right: 8,
        top: 8,
        zIndex: 999,
        backgroundColor: '#FFF',
      }}
      size='large'
    >
      <CloseIcon />
    </IconButton>
  )
}
