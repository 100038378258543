import { Fragment } from 'react'

import { get } from 'lodash'
import {
  Box,
  Divider,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import {
  AvoidCopy,
  PreviousValueComparison,
  ProgressBarLegend,
} from 'components'
import { SurveyTopFive } from 'enums/survey'
import { getTopFiveColor, getTopFiveText } from 'utils/survey'
import { BeheardReportUnlockButton } from 'pages/beheardReport/components'
import { t } from 'i18n'

export default function TopFiveAreas({
  surveyDetails,
  isFullReport,
  addSurveyToCart,
  addSurveyToCartIsLoading,
  hideImportanceSection = false,
}) {
  const theme = useTheme()

  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('md'),
    {
      defaultMatches: true,
    },
  );
  const dontShowVisual = get(surveyDetails, "previous_survey_percents") ? true : false;

  const topFives = [
    {
      type: SurveyTopFive.Action,
      display: get(surveyDetails, 'sections_allowed.top_5_action_areas'),
    },
    {
      type: SurveyTopFive.Strength,
      display: get(
        surveyDetails,
        'sections_allowed.top_5_engagement_strengths',
      ),
    },
    {
      type: SurveyTopFive.Importance,
      display:
        get(surveyDetails, 'sections_allowed.top_5_importance') &&
        !hideImportanceSection,
    },
  ]

  const getTopFive = type =>
    surveyDetails.top_five
      .filter(topFive => topFive.type === type)
      .filter((_, index) => index < 5)

  return (
    <Grid container>
      <Grid item xs={12}>
        {topFives.map((topFive, ind) => {
          const [title, description] = getTopFiveText(topFive.type)

          if (topFive.display) {
            return (
              <Fragment key={topFive.type}>
                <Grid container key={title}>
                  <Grid item xs={12}>
                    <Grid container style={{ position: 'relative' }}>
                      <div
                        style={{
                          position: 'absolute',
                          bottom: 16,
                          left: 0,
                          right: 0,
                        }}
                      >
                        <Divider style={{ height: 2 }} />
                      </div>
                      <Typography
                        variant='h4'
                        color='text.primary'
                        fontWeight='600'
                        mt={2}
                        pb={1}
                        borderBottom={`${`2px solid ${theme.palette[getTopFiveColor(topFive.type)].dark
                          }`}`}
                        zIndex={999}
                        width='100%'
                        paragraph
                      >
                        {title}
                      </Typography>
                    </Grid>
                    <Typography
                      variant='body2'
                      color='text.primary'
                      fontWeight={400}
                      paragraph
                    >
                      {description}
                    </Typography>
                  </Grid>
                </Grid>

                {isFullReport && surveyDetails.top_five ? (
                  surveyDetails.top_five &&
                    surveyDetails.top_five.filter(
                      topFiveElement => topFiveElement.type === topFive.type,
                    ).length > 0 ? (
                    <>
                      {topFive.type !== SurveyTopFive.Importance && (
                        <Grid container justifyContent='flex-start'>
                          <Grid item xs={12} sm={9} lg={6}>
                            <ProgressBarLegend />
                          </Grid>
                        </Grid>
                      )}

                      {getTopFive(topFive.type).map((topFiveElement, index) => {
                        const roiGap = topFiveElement.roi_gap.toFixed()

                        return (
                          <Fragment key={index}>
                            <Grid
                              container
                              key={topFiveElement.statement + index}
                              sx={{ mb: 2 }}
                            >
                              <Grid item xs={12} sm={8}>
                                <Grid
                                  container
                                  direction='column'
                                  justifyContent='space-between'
                                  style={{ height: '100%' }}
                                >
                                  <Grid item>
                                    <Grid
                                      container
                                      spacing={2}
                                      alignItems='center'
                                    >
                                      <Grid item xs style={{ width: '100%' }}>
                                        <Typography
                                          variant='h6'
                                          color='text.primary'
                                        >
                                          <Grid
                                            container
                                            spacing={2}
                                            component='span'
                                          >
                                            <Grid item>{index + 1}.</Grid>

                                            <Grid item xs>
                                              <AvoidCopy>
                                                {topFiveElement.statement}
                                              </AvoidCopy>
                                            </Grid>
                                          </Grid>
                                        </Typography>
                                      </Grid>

                                      {topFive.type ===
                                        SurveyTopFive.Action && (
                                          // <Box component="span" sx={{ p:0.3,width: 160, border: '3px solid #657780' }}>
                                          <>
                                            <Divider sx={{ height: "120px", borderRightWidth: 3 }}
                                              orientation="vertical" variant="middle" flexItem />
                                            <Grid item xs='auto'>
                                              <Typography
                                                variant='body2'
                                                color='#657780'
                                                fontWeight={500}
                                              >
                                                <Grid container alignItems='center' mr={2}>
                                                  Importance Gap
                                                </Grid>
                                              </Typography>
                                              <Box mt={1}>
                                                <Typography
                                                  variant='h3'
                                                  color={
                                                    roiGap <= 5
                                                      // ? 'primary.main'
                                                      ? '000000'
                                                      : roiGap >= 10
                                                        // ? 'error.main'
                                                        ? '000000'
                                                        // : 'secondary.main'
                                                        : '000000'
                                                  }
                                                  fontWeight={600}
                                                  align='center'
                                                >
                                                  {roiGap}
                                                  <span style={{ fontSize: '60%', color: '000000', verticalAlign: 'top' }}>%</span>
                                                </Typography>
                                              </Box>
                                            </Grid>
                                            {/*</Box>*/}
                                            <Divider sx={{ height: "120px", borderRightWidth: 3 }}
                                              orientation="vertical" variant="middle" flexItem />
                                          </>
                                        )}
                                    </Grid>
                                  </Grid>

                                  <Grid item style={{ width: '100%' }}>
                                    <Grid container>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{ marginBottom: 8 }}
                                      >
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs={12} sm={4}>
                                <Box ml={isMobile ? 0 : 4}>
                                  <Grid container spacing={2}>
                                    {[
                                      {
                                        id: 'effectiveness',
                                        label: t(
                                          'beheardReport.topFiveText.topFiveResultDetails.effectiveness',
                                        ),
                                        value: `${get(
                                          topFiveElement,
                                          'total_satisfaction',
                                          0,
                                        ).toFixed()}%`,
                                        display: true,
                                      },
                                      {
                                        id: 'importance',
                                        label: t(
                                          'beheardReport.topFiveText.topFiveResultDetails.importance',
                                        ),
                                        value: `${get(
                                          topFiveElement,
                                          'total_importance',
                                          0,
                                        ).toFixed()}%`,
                                        display: true,
                                      },
                                      {
                                        id: 'previous_effectiveness',
                                        label: get(
                                          topFiveElement,
                                          'previous_total_satisfaction',
                                          0,
                                        ) ? t(
                                          'beheardReport.topFiveText.topFiveResultDetails.previousEffectiveness',
                                        ) : " ",
                                        value: get(
                                          topFiveElement,
                                          'previous_total_satisfaction',
                                          0,
                                        )
                                          ? `${(
                                            get(
                                              topFiveElement,
                                              'previous_total_satisfaction',
                                            ) ?? 0
                                          ).toFixed()}%`
                                          : " ",
                                        display:
                                          (topFive.type !==
                                            SurveyTopFive.Importance) && dontShowVisual,
                                      },
                                      {
                                        id: 'benchmark_effectiveness',
                                        label: t(
                                          'beheardReport.topFiveText.topFiveResultDetails.benchmarkEffectiveness',
                                        ),
                                        value: `${get(
                                          topFiveElement,
                                          'benchmark',
                                          0,
                                        ).toFixed()}%`,
                                        display:
                                          topFive.type !==
                                          SurveyTopFive.Importance,
                                      },
                                    ].map(
                                      item =>
                                        item.display && (
                                          <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            key={item.id}
                                          >
                                            <Typography
                                              variant='body2'
                                              color='text.primary'
                                              fontWeight={400}
                                              style={{
                                                wordBreak: 'break-word',
                                              }}
                                            >
                                              {item.label}
                                            </Typography>
                                            <Box
                                              mt={1}
                                              position='relative'
                                              display='inline-block'
                                            >
                                              <Typography
                                                variant='h4'
                                                color={item.id ===
                                                  'effectiveness' ? parseInt(item.value) <= 69 ? 'error' : parseInt(item.value) > 79 ? 'primary' : 'warning.main' : 'inherit'}
                                                fontWeight={500}
                                              >
                                                {item.value}
                                              </Typography>
                                              {item.id ===
                                                'effectiveness' && ind !== (topFives.length - 1) && dontShowVisual && (
                                                  <PreviousValueComparison
                                                    currentValue={
                                                      topFiveElement.total_satisfaction
                                                    }
                                                    previousValue={
                                                      topFiveElement.previous_total_satisfaction
                                                    }
                                                  />
                                                )}
                                            </Box>
                                          </Grid>
                                        ),
                                    )}
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>
                            <Box mb={2}>
                              <Divider
                                style={{
                                  height:
                                    index ===
                                      surveyDetails.top_five.filter(
                                        topFiveElement =>
                                          topFiveElement.type === topFive.type,
                                      ).length -
                                      1
                                      ? topFive.type ===
                                        SurveyTopFive.Importance
                                        ? 0
                                        : 3
                                      : 1,
                                }}
                              />
                            </Box>
                          </Fragment>
                        )
                      })}
                    </>
                  ) : (
                    <Box mt={1} mb={5}>
                      <Typography
                        variant='body1'
                        color='text.primary'
                        fontWeight='bold'
                      >
                        {t('beheardReport.noData', {
                          title: title,
                        })}
                      </Typography>
                    </Box>
                  )
                ) : (
                  <BeheardReportUnlockButton
                    addSurveyToCart={addSurveyToCart}
                    addSurveyToCartIsLoading={addSurveyToCartIsLoading}
                  />
                )}
              </Fragment>
            )
          }
          return null
        })}
      </Grid>
    </Grid>
  )
}
