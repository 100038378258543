import { isEqual } from 'lodash'

const easterEggCode: any = []

/**
 * ZEBRAAAAAAAAAAAAAAAAA
 * @param {*} key
 */
export const handleEasterEgg = (setEasterEggDialog: any) => (key: any) => {
  if (key.code === 'Escape' || easterEggCode.length > 8) {
    easterEggCode.length = 0
  } else {
    easterEggCode.push(key.code)
  }

  if (
    isEqual(easterEggCode, [
      'ArrowUp',
      'ArrowDown',
      'ArrowUp',
      'ArrowDown',
      'ArrowLeft',
      'ArrowRight',
      'ArrowLeft',
      'ArrowRight',
      'KeyZ',
    ])
  ) {
    setEasterEggDialog(true)
  }
}
