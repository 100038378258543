import { amber, deepOrange, teal } from '@mui/material/colors'

export const randomColors = theme => ({
  0: theme.palette?.primary.main,
  1: theme.palette?.secondary.main,
  2: theme.palette?.error.light,
  3: theme.palette?.primary.light,
  4: theme.palette?.secondary.light,
  5: teal[800],
  6: teal[700],
  7: teal[600],
  8: teal[500],
  9: teal[400],
  10: teal[300],
  11: amber[800],
  12: amber[700],
  13: amber[600],
  14: amber[500],
  15: amber[400],
  16: amber[300],
  17: deepOrange[800],
  18: deepOrange[700],
  19: deepOrange[600],
  20: deepOrange[500],
  21: deepOrange[400],
  22: deepOrange[300],
})

export const randomGradientColors = theme => ({
  0: `linear-gradient(45deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%)`,
  1: `linear-gradient(45deg, ${theme.palette.secondary.main} 0%, ${theme.palette.secondary.light} 100%)`,
  2: `linear-gradient(45deg, ${theme.palette.secondary.main} 0%, ${theme.palette.error.main} 100%)`,
  3: `linear-gradient(45deg, ${theme.palette.secondary.main} 0%, ${theme.palette.warning.dark} 100%)`,
  4: `linear-gradient(45deg, ${theme.palette.primary.main} 0%, ${theme.palette.info.main} 100%)`,
})

export const getRandomColor: any = (theme, options = {}): string => {
  const { isGradient }: any = options

  const randomValue = Math.round(
    Math.random() *
      (Object.keys(
        isGradient ? randomGradientColors(theme) : randomColors(theme),
      ).length -
        1),
  )

  if (isGradient) {
    return randomGradientColors(theme)[randomValue]
  }

  return randomColors(theme)[randomValue]
}

export const getUniqueColor = (theme, options = {}): string => {
  const { isGradient, id }: any = options

  if (isGradient) {
    return randomGradientColors(theme)[
      id % Object.keys(randomGradientColors(theme)).length
    ]
  }

  return randomColors(theme)[id % Object.keys(randomColors(theme)).length]
}
