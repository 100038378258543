import { Box, Grid, Theme, useMediaQuery } from '@mui/material'

import {
    SurveyHeaderBackground,
} from 'pages/surveys/components'

export default function DriversComponent(props) {
    const {
        driverQuestions,
        surveyIsLoading,
    } = props


    const isMobile = useMediaQuery(
        (theme: Theme) => theme.breakpoints.down('md'),
        {
            defaultMatches: true,
        },
    )

    return (
        <>
            <Grid container>
                <SurveyHeaderBackground />
                {/* <SurveyHeader {...props} isMobile={isMobile} /> */}
                <div>Header</div>
                {!surveyIsLoading ? (
                    <>
                        <Grid item xs={12} sx={{ marginTop: '32px', zIndex: 995, fontWeight: 'bold', }} >
                            <Grid container justifyContent='center'>
                                {driverQuestions
                                    .map((question: any, index) => (
                                        <Grid
                                            item
                                            xs={12}
                                            md={10}
                                            xl={8}
                                            key={question.id ?? index + 1}
                                        >
                                            <Box mb={isMobile ? 2 : 4}>
                                                {question}
                                            </Box>
                                        </Grid>
                                    ))}
                            </Grid>
                        </Grid>

                        {/* <Grid item xs={12}>
                            <SurveyFooter {...props} />
                            <div>Footer</div>
                        </Grid> */}

                        {/* <Grid item xs={12} data-cy='starRatingQuestion'>
                            <CRUDTable
                                rows={ratingQuestions}
                                setRows={setRatingQuestions}
                                columns={[
                                    {
                                        id: 'statement',
                                        label: t('surveyDialogs.customizedQuestions.statement'),
                                        type: 'text',
                                        align: 'left',
                                        required: true,
                                        disabled: () => false,
                                        showEdit: () => true,
                                        defaultValue: '',
                                        textFieldProps: {
                                            placeholder: intl.formatMessage({
                                                id: 'surveyDialogs.customizedQuestions.statementPlaceholder',
                                            }),
                                        },
                                    },
                                ]}
                                surveyFlag={true}
                                limit={RATING_QUESTIONS_LIMIT}
                                showAdd
                                showEdit={() => true}
                                showRemove={() => true}
                            />
                        </Grid> */}
                    </>
                ) : (
                    <Box py={10} width={1}></Box>
                )}
            </Grid>
        </>
    )
}
