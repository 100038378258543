import { Box, Grid, Typography, useTheme } from '@mui/material'

import { BeheardReportUnlockButton } from '../../components'
import { AvoidCopy } from 'components'
import { SurveyTopFive } from 'enums/survey'
import { t } from 'i18n'

export default function BeheardReportRecommendations(props) {
  const { Description, surveyDetails, isFullReport } = props

  const theme = useTheme()

  const topFive = surveyDetails?.top_five || []
  const recommendations = topFive.filter(
    topFiveItem => topFiveItem.type === SurveyTopFive.Action,
  )
  // We only want the first five. Setting the length is better than slicing
  // because prevent allocating more memory without need.

  return (
    <Grid container>
      <Box component='span' sx={{ mb: 3 }}>
        <Description>
          {t('beheardReport.topFiveText.topFiveRecommendations.description')}
        </Description>
      </Box>

      {isFullReport ? (
        recommendations && recommendations.length > 0 ? (
          recommendations.map(
            (topFiveElement, index) =>
              index <= 4 && (
                <Box mb={3} width={1} key={index}>
                  <Grid item xs={12}>
                    <Typography
                      variant='body1'
                      component='h6'
                      color='text.primary'
                      fontWeight='500'
                      paragraph
                    >
                      <AvoidCopy>
                        {index + 1}. {topFiveElement.statement}
                      </AvoidCopy>
                    </Typography>

                    <Grid container>
                      <Box
                        pl={3}
                        pt={1}
                        style={{
                          borderLeft: `3px solid ${theme.palette.primary.main}`,
                        }}
                      >
                        {topFiveElement.recommendations.map(recommendation => (
                          <Typography
                            key={recommendation}
                            variant='body2'
                            color='text.primary'
                            paragraph
                          >
                            {recommendation}
                          </Typography>
                        ))}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ),
          )
        ) : (
          <Box mt={1} mb={5}>
            <Typography variant='body1' color='text.primary' fontWeight='bold'>
              {t(
                'beheardReport.topFiveText.topFiveRecommendations.noRecommendations',
              )}
            </Typography>
          </Box>
        )
      ) : (
        <BeheardReportUnlockButton {...props} />
      )}
    </Grid>
  )
}
