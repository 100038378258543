import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { Grid, useTheme } from '@mui/material'
import { MentionsInput, Mention } from 'react-mentions'
import { useQuery } from 'react-query'

import genericAvatar from 'assets/images/generic-avatar.png'
import { TextFieldEmojiPicker } from 'components'
import { API } from 'core/requests'

import { inputStyles } from './styles'

type Props = {
  initialContent?: string
  initialMentions?: number[]
  onContentChange: (value: string, mentions: any[]) => void
  placeholder?: string
}

export const TextFieldWithMentions = forwardRef(
  ({ initialContent, onContentChange, placeholder }: Props, ref) => {
    const [content, setContent] = useState(initialContent ?? '')
    const [mentionsIds, setMentionsIds] = useState<any[]>([])

    const theme = useTheme()

    const { data: mentionSuggestions, refetch: fetchMentions } = useQuery(
      'getMentions',
      () => API.get('employeesAutocomplete'),
      {
        enabled: false,
      },
    )

    const handleContentChange = (
      event: { target: { value: string } },
      newValue,
      newPlainTextValue,
      mentions,
    ) => {
      const newMentionsIds = mentions.map(mention => +mention.id)

      setContent(event.target.value)
      setMentionsIds(newMentionsIds)

      onContentChange(event.target.value, newMentionsIds)
    }

    const clearContent = () => setContent('')

    useImperativeHandle(ref, () => {
      return {
        clearContent: clearContent,
        setContent: setContent,
      }
    })

    useEffect(() => {
      fetchMentions()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
      >
        <Grid item xs>
          <MentionsInput
            data-cy='postContent'
            value={content}
            onChange={handleContentChange}
            placeholder={placeholder}
            style={inputStyles}
          >
            <Mention
              markup='@[__display__](__id__)'
              trigger='@'
              displayTransform={(display, id) => `@${id}`}
              data={mentionSuggestions?.map(item => ({
                id: item.id,
                display: item.full_name,
              }))}
              renderSuggestion={entry => (
                <Grid
                  container
                  alignItems='center'
                  data-cy='mentionSuggestions'
                >
                  <img
                    src={
                      mentionSuggestions.find(item => item.id === entry.id)
                        .avatar.url ?? genericAvatar
                    }
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: 10,
                      marginRight: 8,
                    }}
                    alt={entry.display}
                  />
                  {entry.display}
                </Grid>
              )}
              style={{
                backgroundColor: theme.palette.primary.contrastText,
                borderRadius: 4,
                color: theme.palette.primary.main,
                zIndex: 1,
                position: 'relative',
              }}
              appendSpaceOnAdd
            />
          </MentionsInput>
        </Grid>
        <Grid item xs='auto'>
          <TextFieldEmojiPicker
            onEmojiSelect={selectedEmoji => {
              onContentChange(content + selectedEmoji.emoji, mentionsIds)
              setContent(content + selectedEmoji.emoji)
            }}
          />
        </Grid>
      </Grid>
    )
  },
)
