import { useState } from 'react'

import {
  Box,
  Button,
  Dialog,
  MobileStepper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material'
import SwipeableViews from 'react-swipeable-views'

import { t } from 'i18n'

type Step = {
  id: number | string
  label?: string
  description?: string
  image?: string | null
  CustomRender?: any
  CustomDescription?: any
}

type Props = {
  dialogOpen: boolean
  setDialogOpen(value: boolean): void
  steps: Step[]
  localStorageKey?: string
}

export default function PopupSteps({
  dialogOpen,
  setDialogOpen,
  steps,
  localStorageKey,
}: Props) {
  const theme = useTheme()

  const [activeStep, setActiveStep] = useState(0)

  const fullScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  )
  const isLastPage = activeStep === steps.length - 1

  const handleClose = () => setDialogOpen(false)

  const closeDialogAndSaveLocalStorage = () => {
    if (localStorageKey) localStorage.setItem(localStorageKey, 'true')
    handleClose()
  }

  const handleNext = () => {
    if (isLastPage) return closeDialogAndSaveLocalStorage()
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    if (activeStep === 0) return closeDialogAndSaveLocalStorage()
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleStepChange = (step: number) => {
    setActiveStep(step)
  }

  const CustomRender = steps[activeStep]?.CustomRender
  const CustomDescription = steps[activeStep]?.CustomDescription

  return (
    <Dialog
      open={dialogOpen}
      maxWidth='md'
      fullWidth
      fullScreen={fullScreen}
      disableEscapeKeyDown
      {...(!localStorageKey && {
        onClose: handleClose,
      })}
    >
      <SwipeableViews
        axis={'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {steps.map((step, index) => (
          <div key={step.id}>
            {Math.abs(activeStep - index) <= steps.length - 1 && step.image ? (
              <img
                style={{
                  display: 'block',
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step.image}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </SwipeableViews>

      {CustomRender ? (
        CustomRender
      ) : (
        <>
          <Box py={3} px={2}>
            <Typography
              variant='h5'
              color='text.primary'
              fontWeight={700}
              align='center'
              paragraph
            >
              {steps[activeStep]?.label}
            </Typography>

            {CustomDescription ? (
              CustomDescription
            ) : (
              <Typography variant='body1' color='text.secondary'>
                {steps[activeStep]?.description}
              </Typography>
            )}
          </Box>
        </>
      )}

      <MobileStepper
        steps={steps.length}
        position='static'
        activeStep={activeStep}
        nextButton={
          <Button onClick={handleNext} sx={{ ml: activeStep === 0 ? 10 : 0 }}>
            {isLastPage
              ? t('components.popupSteps.gotIt')
              : t('components.popupSteps.next')}
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeftIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </Button>
        }
        backButton={
          <Button onClick={handleBack}>
            {activeStep > 0 && (
              <>
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowRightIcon />
                ) : (
                  <KeyboardArrowLeftIcon />
                )}
              </>
            )}
            {activeStep === 0
              ? t('components.popupSteps.dontShowAgain')
              : t('components.popupSteps.back')}
          </Button>
        }
      />
    </Dialog>
  )
}
