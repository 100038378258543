import { useState, useRef } from 'react'

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
} from '@mui/material'
import {
  CloseOutlined as CloseIcon,
  Gif as GifIcon,
  Send as SendIcon,
} from '@mui/icons-material'
import { get } from 'lodash'
import { useIntl } from 'react-intl'

import { FeedComment } from '../../components'
import { gifStyle } from '../FeedComment/FeedComment'
import { Props as FeedPostProps } from '../FeedPost/FeedPost'
import { TextFieldWithMentions, UserAvatar } from 'components'
import { UserPermission } from 'enums/user'
import { CommentType } from 'types'
import { getUserFullName } from 'utils/user'

type Props = FeedPostProps & {
  gif: { id: string; src: string }
  setGif: (value: { id: string; src: string }) => void
  setDialogGifs: (value: boolean) => void
  highlightedCommentId?: number
}

export default function FeedPost(props: Props) {
  const intl = useIntl()

  const textFieldMentionsRef = useRef<any>(null)

  const {
    post,
    publishComment,
    publishCommentStatus,
    user,
    gif,
    setGif,
    setDialogGifs,
    highlightedCommentId,
  } = props

  const initialCommentState = {
    post_id: post.id,
    content: '',
    editMode: false,
    mentions: [],
  }

  const [commentState, setCommentState] = useState<any>(initialCommentState)

  return (
    <Box padding={2}>
      {(user.permissions ?? []).includes(UserPermission.ManageComments) && (
        <>
          <Box display='flex' justifyContent='center' p={1}>
            {gif.src && (
              <div style={{ position: 'relative' }}>
                <img src={gif.src} alt='Gif from Giphy' style={gifStyle} />

                <Button
                  variant='contained'
                  onClick={() => setGif({ id: '', src: '' })}
                  sx={{
                    position: 'absolute',
                    top: 16,
                    right: 16,
                    backgroundColor: '#fff',
                    color: '#888',
                    borderRadius: '100%',
                    minHeight: ' 32px !important',
                    minWidth: '32px !important',
                    height: 32,
                    width: 32,
                    ':hover': {
                      backgroundColor: '#d5d5d5',
                    },
                  }}
                >
                  <CloseIcon />
                </Button>
              </div>
            )}
          </Box>
          <Grid
            container
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
          >
            <Grid item xs='auto'>
              <UserAvatar
                userId={get(user, 'sub')}
                name={getUserFullName(user)}
                src={get(user, 'avatar.url')}
                size={40}
              />
            </Grid>

            <Grid item xs>
              <TextFieldWithMentions
                ref={textFieldMentionsRef}
                onContentChange={(content, mentions) => {
                  setCommentState({ ...commentState, content, mentions })
                }}
                placeholder={intl.formatMessage({
                  id: 'feed.commentPlaceholder',
                })}
              />
            </Grid>

            <Grid item xs='auto'>
              <IconButton
                data-cy='show_gif_options'
                aria-label='toggle password visibility'
                onClick={() => setDialogGifs(true)}
                edge='end'
                size='large'
              >
                <GifIcon />
              </IconButton>
            </Grid>

            <Grid item xs='auto'>
              <Button
                data-cy='post_comment'
                variant='contained'
                color='primary'
                onClick={() => {
                  publishComment({
                    post_id: post.id,
                    content: commentState.content,
                    mentions: commentState.mentions,
                    gif_id_on_provider: gif.id,
                  })

                  textFieldMentionsRef?.current?.clearContent()

                  setCommentState(initialCommentState)
                  setGif({ id: '', src: '' })
                }}
                disabled={
                  (publishCommentStatus.isLoading &&
                    get(publishCommentStatus, 'variables.post_id') ===
                      post.id) ||
                  !commentState.content
                }
              >
                {publishCommentStatus.isLoading &&
                get(publishCommentStatus, 'variables.post_id') === post.id ? (
                  <CircularProgress size={24} />
                ) : (
                  <SendIcon />
                )}
              </Button>
            </Grid>
          </Grid>
        </>
      )}

      <Divider sx={{ my: 2 }} />

      <Grid container>
        {post?.comments?.map((comment: CommentType) => {
          return (
            <FeedComment
              {...props}
              key={comment.id}
              comment={comment}
              highlightedCommentId={highlightedCommentId}
            />
          )
        })}
      </Grid>
    </Box>
  )
}
