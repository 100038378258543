import { Box, Grid, Paper, Typography } from '@mui/material'
import { isEmpty } from 'lodash'

import { PulseSurveyHeader, PulseSurveyQuestion } from '../../components'
import { t } from 'i18n'

export default function PulseSurveysComponent(props) {
  const { pulseSurveys } = props

  return (
    <Grid container justifyContent='center'>
      <PulseSurveyHeader />
      <Grid item xs={12} sm={10} md={8} lg={7} xl={6}>
        {isEmpty(pulseSurveys) ? (
          <Grid container justifyContent='center'>
            <Grid item xs={12} style={{ zIndex: 999 }}>
              <Paper>
                <Box p={3} py={5}>
                  <Typography
                    variant='h6'
                    color='text.secondary'
                    align='center'
                  >
                    {t('pulseSurvey.noPulseSurveys')}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        ) : (
          pulseSurveys?.map((pulseSurvey, index) => (
            <Grid
              container
              justifyContent='center'
              key={pulseSurvey.id ?? index}
            >
              <Grid item xs={12} style={{ zIndex: 999 }}>
                <Box mb={3}>
                  <PulseSurveyQuestion {...props} pulseSurvey={pulseSurvey} />
                </Box>
              </Grid>
            </Grid>
          ))
        )}
      </Grid>
    </Grid>
  )
}
