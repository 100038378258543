import { useState, useEffect } from 'react'

import { isEmpty, remove } from 'lodash'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router'

import { FeedComponent } from './components'
import { initialDialog } from 'constants/dialog'
import { useCompany } from 'contexts/CompanyContext'
import { useUser } from 'contexts/UserContext'
import { API } from 'core/requests'
import { ActiveInactiveStatus } from 'enums/app'
import { t } from 'i18n'

const initialSuggestion = {
  description: '',
  anonymous: false,
}

const NUMBER_OF_POSTS_PER_PAGE = 20

const initialPostState = { content: '', external_media_url: '' }

type RouteParams = {
  postId: string
}

export default function Feed() {
  const { user } = useUser()!
  const { company } = useCompany()!
  const { enqueueSnackbar } = useSnackbar()

  const { postId } = useParams<RouteParams>()

  const [postMedia, setPostMedia] = useState<any>({})
  const [postState, setPostState] = useState<{
    content?: string
    external_media_url?: any
  }>({
    content: '',
  })
  const [postsState, setPostsState] = useState<any>([])

  const [feedPostsConfirmDelete, setFeedPostsConfirmDelete] =
    useState(initialDialog)
  const [suggestionState, setSuggestionState] = useState(initialSuggestion)
  const [hasMorePosts, setHasMorePosts] = useState<boolean>(true)

  const addMediaToPost = response => {
    const postIndex = postsState.findIndex(post => post.id === response.id)

    if (postIndex >= 0) {
      postsState[postIndex].media = response.media
    }
  }

  const replacePost = response => {
    const postIndex = postsState.findIndex(post => post.id === response.id)

    if (postIndex >= 0) {
      postsState[postIndex] = response
      setPostsState([...postsState])
    } else {
      setPostsState([response, ...postsState])
    }
  }

  const removeLike = post_id => {
    const postIndex = postsState.findIndex(post => post.id === post_id)

    // postsState[postIndex].likes = postsState[postIndex].likes.select(like => like.user.id != currentUser.id)
    postsState[postIndex].current_user_liked_this_post = false

    setPostsState([...postsState])
  }

  const removePost = response => {
    remove(postsState, { id: response.post_id })

    setPostsState([...postsState])
  }

  const removeComment = response => {
    remove(postsState.find(post => post.id === response.post_id).comments, {
      id: response.comment_id,
    })

    setPostsState([...postsState])
  }

  const { isLoading: postsIsLoading, refetch: fetchPosts } = useQuery(
    'getPosts',
    () =>
      API.get('posts', postId, {
        queryParams: {
          ...(postsState.length > 0 && {
            last_created_at: postsState[postsState?.length - 1]?.created_at,
            last_id: postsState[postsState?.length - 1]?.id,
          }),
          limit: NUMBER_OF_POSTS_PER_PAGE,
        },
      }),
    {
      enabled: false,
      onSuccess: response => {
        if (Array.isArray(response)) {
          setPostsState([...postsState, ...response])
        } else {
          setPostsState([...postsState, response])
        }

        if (response.length < NUMBER_OF_POSTS_PER_PAGE) {
          setHasMorePosts(false)
        }
      },
    },
  )

  const uploadPostMediaMutation = useMutation(
    (payload: any) => API.createOrUpdate('uploads', payload),
    {
      onSettled: async (response: any) => {
        if (response && response.id) {
          addMediaToPost(response)
          setPostState(initialPostState)
          setPostMedia({})
        }
      },
    },
  )

  const publishPostMutation = useMutation(
    (payload: any) => API.createOrUpdate('posts', payload),
    {
      onSettled: (response: any) => {
        if (response && response.id) {
          setPostState({ content: '' })
          replacePost(response)

          if (!isEmpty(postMedia)) {
            const formData = new FormData()

            formData.append('file', postMedia)
            formData.append('attach_to', 'Feed::Post')
            formData.append('record_id', response.id)
            formData.append('record_field', 'media')

            uploadPostMediaMutation.mutate(formData)
          } else {
            setPostState(initialPostState)
            setPostMedia({})
          }
        }
      },
    },
  )

  const deletePostMutation = useMutation(
    (payload: any) => API.delete('posts', payload.id),
    {
      onSettled: (response: any) => {
        if (response && response.post_id) {
          setFeedPostsConfirmDelete({ isOpen: false, data: null })

          removePost(response)
        }
      },
    },
  )

  const publishCommentMutation = useMutation(
    (payload: any) => API.createOrUpdate('comments', payload),
    {
      onSettled: (response: any) => {
        if (response && response.id) {
          replacePost(response)
        }
      },
    },
  )

  const deleteCommentMutation = useMutation(
    (payload: any) => API.delete('comments', payload.id),
    {
      onSettled: (response: any) => {
        if (response && response.comment_id) {
          removeComment(response)
        }
      },
    },
  )

  const likePostMutation = useMutation(
    (payload: any) =>
      API.base('likes', {
        pathParams: {
          id: payload.method === 'DELETE' ? payload.post_id : null,
        },
        method: payload.method,
        body: payload,
      }),
    {
      onSettled: (response: any) => {
        if (response) {
          if (response && response.post_id) {
            removeLike(+response.post_id)
          }

          if (response && response.id) {
            replacePost(response)
          }
        }
      },
    },
  )

  const publishSuggestionMutation = useMutation(
    (payload: any) => API.create('suggestionsBoxIdeas', payload),
    {
      onSuccess: (response: any) => {
        if (response && response.id) {
          enqueueSnackbar(t('feed.snackbar.success.suggestionSubmitted'), {
            variant: 'success',
          })

          setSuggestionState(initialSuggestion)
        }
      },
    },
  )

  const createNominationMutation = useMutation(
    (payload: any) => API.create('nominations', payload),
    {
      onSuccess: (response: any) => {
        if (response && response.success) {
          enqueueSnackbar(t('Woo Hoo! Your nomination is in!'), {
            variant: 'success',
          })
        }
      },
    },
  )

  const { data: employees, refetch: fetchEmployees } = useQuery(
    'employees',
    () => API.get('employeesAutocomplete'),
  )

  const {
    data: coreValues,
    isLoading: coreValuesIsLoading,
    refetch: fetchCoreValues,
  } = useQuery('getCoreValues', () => API.get('coreValues'), {
    enabled: false,
  })

  const {
    data: recognitions,
    isLoading: recognitionsIsLoading,
    refetch: fetchRecognitions,
  } = useQuery(
    'getRecognitions',
    () =>
      API.get('recognitions', null, {
        queryParams: { status: ActiveInactiveStatus.Active },
      }),
    {
      enabled: false,
    },
  )

  const { data: nominationsPrograms, refetch: fetchNominationsPrograms } =
    useQuery('getNominationsPrograms', () => API.get('nominationsPrograms'), {
      enabled: false,
    })

  useEffect(() => {
    fetchCoreValues()
    fetchRecognitions()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (company?.nominations_enabled) {
      fetchEmployees()
      fetchNominationsPrograms()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company])

  useEffect(() => {
    fetchPosts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId])

  return (
    <FeedComponent
      id='feed'
      user={user}
      postState={postState}
      setPostState={setPostState}
      setPostsState={setPostsState}
      feedPostsConfirmDelete={feedPostsConfirmDelete}
      setFeedPostsConfirmDelete={setFeedPostsConfirmDelete}
      suggestionState={suggestionState}
      setSuggestionState={setSuggestionState}
      hasMorePosts={hasMorePosts}
      posts={postsState ?? []}
      postsIsLoading={postsIsLoading}
      fetchPosts={fetchPosts}
      publishPost={publishPostMutation.mutate}
      publishPostStatus={publishPostMutation}
      uploadPostMediaStatus={uploadPostMediaMutation}
      deletePostMutation={deletePostMutation}
      publishComment={publishCommentMutation.mutate}
      publishCommentStatus={publishCommentMutation}
      deleteCommentMutation={deleteCommentMutation}
      likePost={likePostMutation.mutate}
      likePostStatus={likePostMutation}
      publishSuggestion={publishSuggestionMutation.mutate}
      publishSuggestionStatus={publishSuggestionMutation}
      company={company}
      coreValues={coreValues}
      coreValuesIsLoading={coreValuesIsLoading}
      recognitions={recognitions}
      recognitionsIsLoading={recognitionsIsLoading}
      nominationsPrograms={nominationsPrograms?.data}
      createNominationMutation={createNominationMutation}
      employees={employees}
      postId={postId}
      postMedia={postMedia}
      setPostMedia={setPostMedia}
      NUMBER_OF_POSTS_PER_PAGE={NUMBER_OF_POSTS_PER_PAGE}
    />
  )
}
