import { Grid, TextField } from '@mui/material'
import { DatePicker } from '@mui/lab'

import { DialogTeleportTest } from 'components'
import { recognitionActionRequestfile } from 'core/requests'
import { t } from 'i18n'

export default function TakeActionReminder({
  // setReminder,
  reminderDate,
  setReminderDate,
  reminderDialog,
  setReminderDialog,
}) {
  return (
    <DialogTeleportTest
      dialogTitle={t('takeAction.setAReminder')}
      dialogAction={{
        function: () => {
          recognitionActionRequestfile(reminderDialog.data.id, reminderDate)
          setReminderDialog({ isOpen: false, data: null })
        },
        label: t('takeAction.setReminder'),
      }}
      dialogSize='sm'
      dialogOpen={reminderDialog.isOpen}
      handleDialogClose={() => setReminderDialog({ isOpen: false, data: null })}
      isFullWidth
    >
      <Grid container>
        <Grid item xs={12}>
          <DatePicker
            renderInput={params => (
              <TextField
                {...params}
                id='reminder_date'
                name='reminder_date'
                label={t('takeAction.fields.reminderDate')}
                variant='outlined'
                required
                fullWidth
              />
            )}
            value={reminderDate}
            onChange={(value: any) => setReminderDate(value)}
            disablePast={true}
          />
        </Grid>
      </Grid>
    </DialogTeleportTest>
  )
}
