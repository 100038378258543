import { colors, darken } from '@mui/material'

const white = '#FFFFFF'

export default darkMode => ({
  darkMode,
  ...(darkMode !== 'dark'
    ? {
        primary: {
          contrastText: white,
          dark: '#1e8f83',
          main: '#1bb4ac',
          light: '#1acfc6',
        },
        secondary: {
          contrastText: white,
          dark: '#F26522',
          main: '#f48b1e',
          light: '#FFC20E',
        },
        success: {
          contrastText: white,
          dark: '#256e20',
          main: '#3aa832',
          light: '#40c238',
        },
        info: {
          contrastText: white,
          dark: '#34515e',
          main: '#607d8b',
          light: '#8eacbb',
        },
        warning: {
          contrastText: white,
          dark: '#EAAF20',
          main: '#FFC20D',
          light: '#ffcb2f',
        },
        error: {
          contrastText: white,
          dark: '#D61119',
          main: '#ED1C24',
          light: '#ff6166',
        },
        purple: {
          contrastText: white,
          dark: '#781e8f',
          main: '#931bb4',
          light: '#bf45de',
        },
        text: {
          black: '#000',
          blue: "#267eae",
          primary: '#464C53',
          secondary: '#6E7882',
          warning: '#F26522',
          light: '#9DABB9',
          veryLight: '#bccbd1',
          white: white,
        },
        background: {
          default: '#F6F8FA',
          paper: white,
        },
        enpscolourprimary: {
          contrastText: white,
          dark:  '#00af50',
          main: '#00af50',
          light: '#00af50',
        },
        enpscoloursecondary: {
          contrastText: white,
          dark:  '#ffc000',
          main: '#ffc000',
          light: '#ffc000',
        },
        enpscolourerror: {
          contrastText: white,
          dark:  '#fe0001',
          main: '#fe0001',
          light: '#fe0001',
        },
        anticipatedProgress: {
          contrastText: white,
          // dark: '#F26522',
          // main: '#FFCB2F',
          // light: '#FFC20E',
          dark: '#D61119',
          main: '#ED1C24',
          light: '#ff6166',
        },
        icon: colors.blueGrey[600],
        divider: colors.grey[200],
      }
    : {
        // palette values for dark mode
        primary: {
          contrastText: white,
          dark: darken('#1e8f83', 0.12),
          main: darken('#1bb4ac', 0.12),
          light: darken('#1acfc6', 0.12),
        },
        secondary: {
          contrastText: white,
          dark: darken('#F26522', 0.12),
          main: darken('#f48b1e', 0.12),
          light: darken('#FFC20E', 0.12),
        },
        success: {
          contrastText: white,
          dark: darken('#256e20', 0.12),
          main: darken('#3aa832', 0.12),
          light: darken('#40c238', 0.12),
        },
        info: {
          contrastText: white,
          dark: darken('#34515e', 0.12),
          main: darken('#607d8b', 0.12),
          light: darken('#8eacbb', 0.12),
        },
        warning: {
          contrastText: white,
          dark: darken('#EAAF20', 0.12),
          main: darken('#FFC20D', 0.12),
          light: darken('#ffcb2f', 0.12),
        },
        error: {
          contrastText: white,
          dark: darken('#D61119', 0.12),
          main: darken('#ED1C24', 0.12),
          light: darken('#ff6166', 0.12),
        },
        purple: {
          contrastText: white,
          dark: darken('#781e8f', 0.12),
          main: darken('#931bb4', 0.12),
          light: darken('#bf45de', 0.12),
        },
        text: {
          black: '#000',
          primary: '#FFF',
          secondary: '#DDD',
          light: '#AAA',
          veryLight: '#888',
          white: white,
        },
        background: {
          default: '#202020',
          paper: '#333333',
        },
        icon: colors.blueGrey[900],
        divider: colors.grey[700],
      }),
})
