import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'

import { Link } from 'react-router-dom'

import { DashboardComponentProps } from '../DashboardComponent/DashboardComponent'
import { SparckFlag } from 'components'
import { t } from 'i18n'
import { TaskType } from 'types'

type DashboardProgressTasksProps = DashboardComponentProps & {
  progressTasks: TaskType[]
  withFlag: boolean
}

export default function DashboardCard(props: DashboardProgressTasksProps) {
  const { progressTasks, withFlag } = props

  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('md'),
    {
      defaultMatches: true,
    },
  )

  return (
    <Grid container>
      {progressTasks!.map(progressTask => (
        <Grid item xs={12} key={progressTask.id}>
          <Box mb={3}>
            <Card style={{ position: 'relative' }} elevation={2}>
              {withFlag && <SparckFlag left={true} />}
              <Box px={2} py={1} alignItems='center' justifyContent='center'>
                <CardContent
                  sx={{
                    paddingLeft: `${withFlag ? '96px' : 'inherit'} !important`,
                  }}
                >
                  <Typography color='text.primary' variant='h6' gutterBottom>
                    {progressTask.title}
                  </Typography>
                  <Typography color='text.secondary' variant='body2' paragraph>
                    {progressTask.description}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Grid
                    container
                    justifyContent={isMobile ? 'center' : 'flex-end'}
                  >
                    <Link to={progressTask.link}>
                      <Button color='primary' variant='contained'>
                        {t('dashboard.getStarted')}
                      </Button>
                    </Link>
                  </Grid>
                </CardActions>
              </Box>
            </Card>
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}
