export const VIRGIN_EXPERIENCES_GIFT_CARD = 'VIRGIN-EXPERIENCES-GIFT-CARD'

export const virginExperiencesGiftCard = {
  id: VIRGIN_EXPERIENCES_GIFT_CARD,
  wish_id: null,
  brand: {
    id: 'VIRGIN-EXPERIENCES-GIFT-CARD-BRAND',
    name: 'Virgin Experiences',
    logo_url:
      'https://d2l34t1fl9ccx8.cloudfront.net/assets/skin/www/default/images/veg/og-default.png',
  },
  product: {
    faceplate_url:
      'https://d2l34t1fl9ccx8.cloudfront.net/assets/skin/www/default/images/veg/og-default.png',
    fixed_options: [],
    range_options: [
      {
        id: 'VIRGIN-EXPERIENCES-GIFT-CARD-BRAND-RANGE',
        sku: 'VOU-NAT-0001',
        minimum: '50.0',
        maximum: '9999.99',
        increment: '0.01',
      },
    ],
  },
}
