import React, { useState } from 'react'

import { get, omit } from 'lodash'
import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import { PictureAsPdfRounded, BorderAllRounded } from '@mui/icons-material'
import { styled } from '@mui/styles'
import { useIntl } from 'react-intl'

import { AvoidCopy, ProgressBarLegend } from 'components'
import { BeheardReportUnlockButton } from '../../components'
import { fullReportItems } from 'constants/beheardReport'
import { downloadOpenEndedAnswersRequest } from 'core/requests'
import { SurveyDriverScore } from 'enums/survey'
import { t } from 'i18n'
import { getDriverScoreDescription } from 'utils/survey'

type LegendCircleProps = {
  color: string
}

const LegendCircle = styled('span')<LegendCircleProps>(({ color }) => ({
  height: '12px',
  width: '12px',
  marginRight: '8px',
  borderRadius: '100%',
  backgroundColor: color,
  display: 'inline-block',
}))

export const getSurveyReportQueryParams = filters => {
  const newFilters: any = { criteria: [] }

  Object.keys(omit(filters, 'roles')).forEach(key => {
    if (filters[key].length) {
      newFilters.criteria.push({
        category: +key,
        demographics: filters[key],
      })
    }
  })
  if (filters.roles) {
    newFilters.roles = filters.roles
  }

  return newFilters
}

export default function BehardReportFullReport(props: any) {
  const { surveyDetails, isFullReport, surveyPermissionId } = props
  const dontShowVisual = get(surveyDetails, "previous_survey_percents") ? true : false;

  const intl = useIntl()

  const openEndedDownloadButtons = [
    {
      label: intl.formatMessage({
        id: 'beheardReport.fullReport.buttonLabels.xlsx',
      }),
      Icon: BorderAllRounded,
      format: 'xlsx',
    },
    {
      label: intl.formatMessage({
        id: 'beheardReport.fullReport.buttonLabels.pdf',
      }),
      Icon: PictureAsPdfRounded,
      format: 'pdf',
    },
  ]

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [openedPopoverId, setOpenedPopoverId] = useState<number | null>(null)

  const handleClick =
    (questionId: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
      setOpenedPopoverId(questionId)
    }

  const handleClose = () => {
    setAnchorEl(null)
    setOpenedPopoverId(null)
  }

  const theme = useTheme()

  const drivers = get(surveyDetails, 'scores.percents.drivers')

  const hasRecommendations = (question, type) =>
    surveyDetails.top_five &&
    surveyDetails.top_five.find(
      topFive => topFive.question_id === question.id && topFive.type === type,
    )

  const previousSatisfactions = surveyDetails['previous_engagement_index']
    ? surveyDetails['previous_engagement_index']
        .flatMap(driver => driver.questions)
        .reduce((hash, question) => {
          hash[question.id] = question.percentages.effectiveness
          return hash
        }, {})
    : null

  return (
    <Grid container>
      {get(surveyDetails, 'sections_allowed.full_report') &&
        surveyDetails.survey &&
        !!drivers && (
          <Grid container>
            {[
              {
                label: intl.formatMessage({
                  id: 'beheardReport.fullReport.engagementScore',
                }),
                value: drivers?.find(
                  engagement => engagement.key === SurveyDriverScore.Engagement,
                )?.value,
              },
              {
                label: intl.formatMessage({
                  id: 'beheardReport.fullReport.participationRate',
                }),
                value: surveyDetails?.survey?.participation_rate,
              },
            ].map(headerItem => (
              <Grid item xs={12} sm={6} key={headerItem.label}>
                <Typography
                  variant='h1'
                  style={{ fontSize: 80 }}
                  color='text.primary'
                  fontWeight={600}
                  align='center'
                >
                  {Math.round(headerItem.value)}%
                </Typography>

                <Typography
                  variant='h5'
                  style={{ fontSize: 16, textTransform: 'uppercase' }}
                  color='text.primary'
                  paragraph
                  align='center'
                >
                  {headerItem.label}
                </Typography>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Box py={2} width={1}>
                <Divider />
              </Box>
            </Grid>
          </Grid>
        )}

      {drivers && (
        <Grid container>
          {drivers.map(
            driver =>
              ((driver.key !== SurveyDriverScore.CustomRatingsQuestions &&
                get(surveyDetails, 'sections_allowed.full_report')) ||
                (driver.key === SurveyDriverScore.CustomRatingsQuestions &&
                  get(surveyDetails, 'sections_allowed.custom_ratings'))) && (
                <Box
                  my={2}
                  width={1}
                  key={driver.key}
                  id={fullReportItems[driver.key]}
                >
                  <Grid item xs={12}>
                    <Typography
                      variant='h5'
                      color='text.primary'
                      fontWeight={600}
                    >
                      {driver.label}
                    </Typography>

                    <Typography variant='body2' color='text.primary'>
                      {getDriverScoreDescription(driver.key)}
                    </Typography>
                  </Grid>

                  {surveyDetails.engagement_index.find(
                    engagementIndex =>
                      engagementIndex.driver.label === driver.label,
                  ) ? (
                    <>
                      {surveyDetails.engagement_index.find(
                        engagementIndex =>
                          engagementIndex.driver.label === driver.label,
                      ).questions.length > 0 ? (
                        <>
                          <Grid item xs={12}>
                            <Box>
                              <Grid container alignItems='center'>
                                <Grid item xs={12} sm={6}>
                                  {[
                                    {
                                      label: intl.formatMessage({
                                        id: 'beheardReport.fullReport.legend.strengths',
                                      }),
                                      color: 'primary',
                                    },
                                    {
                                      label: intl.formatMessage({
                                        id: 'beheardReport.fullReport.legend.recommendedActionAreas',
                                      }),
                                      color: 'secondary',
                                    },
                                  ].map(legendSection => (
                                    <Typography
                                      key={legendSection.label}
                                      variant='body2'
                                      color='text.primary'
                                      paragraph
                                    >
                                      <LegendCircle
                                        color={
                                          theme.palette[legendSection.color]
                                            .main
                                        }
                                      />
                                      {legendSection.label}
                                    </Typography>
                                  ))}
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  <ProgressBarLegend />
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <TableContainer>
                              <Table size='small'>
                                <TableHead style={{ backgroundColor: '#FFF' }}>
                                  <TableRow>
                                    {[
                                      isFullReport ? (
                                        <Typography variant='h6'>
                                          {intl.formatMessage({
                                            id: 'beheardReport.fullReport.tableHeaders.score',
                                          })}
                                          : <b>{driver.value}%</b>
                                        </Typography>
                                      ) : (
                                        intl.formatMessage({
                                          id: 'beheardReport.fullReport.tableHeaders.score',
                                        })
                                      ),
                                      // intl.formatMessage({
                                      //   id: 'beheardReport.fullReport.tableHeaders.result',
                                      // }),
                                      intl.formatMessage({
                                        id: 'beheardReport.fullReport.tableHeaders.effectiveness',
                                      }),
                                      intl.formatMessage({
                                        id: 'beheardReport.fullReport.tableHeaders.importance',
                                      }),
                                      intl.formatMessage({
                                        id: 'beheardReport.fullReport.tableHeaders.previousEffectiveness',
                                      }),
                                      intl.formatMessage({
                                        id: 'beheardReport.fullReport.tableHeaders.benchmark',
                                      }),
                                    ].map((tableHeader, index) =>
                                      dontShowVisual ? (
                                        <TableCell key={index} align={index != 0 ? 'center' : 'left'}>
                                          {tableHeader}
                                        </TableCell>
                                      ) : (
                                        index !== 3 && (
                                          <TableCell key={index} align={index != 0 ? 'center' : 'left'}>
                                            {tableHeader}
                                          </TableCell>
                                        )
                                      ),
                                    )}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {surveyDetails.engagement_index
                                    .find(
                                      engagementIndex =>
                                        engagementIndex.driver.label ===
                                        driver.label,
                                    )
                                    .questions.map(
                                      (question: any, index: number) => (
                                        <TableRow
                                          key={question.id}
                                          style={{
                                            backgroundColor:
                                              index % 2 === 0
                                                ? theme.palette.action.hover
                                                : 'inherit',
                                          }}
                                        >
                                          <TableCell
                                            style={{ width: '50%' }}
                                            component='th'
                                            scope='row'
                                          >
                                            <Typography
                                              variant='body1'
                                              color='text.primary'
                                              style={{
                                                display: 'inline-block',
                                                width: '100%',
                                              }}
                                            >
                                              <Grid
                                                container
                                                component='span'
                                                mt={1}
                                              >
                                                <Grid component='span' item>
                                                  {hasRecommendations(
                                                    question,
                                                    'strength',
                                                  ) && (
                                                    <LegendCircle
                                                      color={
                                                        theme.palette.primary
                                                          .main
                                                      }
                                                    />
                                                  )}

                                                  {hasRecommendations(
                                                    question,
                                                    'action',
                                                  ) && (
                                                    <LegendCircle
                                                      color={
                                                        theme.palette.secondary
                                                          .main
                                                      }
                                                    />
                                                  )}
                                                </Grid>

                                                <Grid component='span' item xs>
                                                  <AvoidCopy>
                                                    {question.statement}
                                                  </AvoidCopy>
                                                </Grid>
                                              </Grid>
                                            </Typography>

                                            <Grid container>
                                              {hasRecommendations(
                                                question,
                                                'action',
                                              ) && (
                                                  <LegendCircle
                                                    color={
                                                      theme.palette.secondary
                                                        .main
                                                    }
                                                  />
                                                ) && (
                                                  <Grid item xs={12} mb={2}>
                                                    <Link
                                                      component='button'
                                                      color='secondary'
                                                      style={{
                                                        marginTop: '15px',
                                                      }}
                                                    >
                                                      <Typography
                                                        variant='body2'
                                                        color='secondary.main'
                                                        fontWeight='bold'
                                                        onClick={handleClick(
                                                          question.id,
                                                        )}
                                                      >
                                                        {t(
                                                          'beheardReport.fullReport.tableData.viewRecommendations',
                                                        )}
                                                      </Typography>
                                                    </Link>
                                                    <Popover
                                                      id={question.id}
                                                      elevation={2}
                                                      open={
                                                        openedPopoverId ===
                                                        question.id
                                                      }
                                                      anchorEl={anchorEl}
                                                      onClose={handleClose}
                                                      anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                      }}
                                                      transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                      }}
                                                      PaperProps={{
                                                        style: {
                                                          maxWidth: 500,
                                                          marginTop: 8,
                                                        },
                                                      }}
                                                    >
                                                      <Box
                                                        m={2}
                                                        pl={2}
                                                        style={{
                                                          borderLeft: `3px solid ${theme.palette.primary.main}`,
                                                        }}
                                                      >
                                                        {hasRecommendations(
                                                          question,
                                                          'action',
                                                        ).recommendations.map(
                                                          (
                                                            recommendation,
                                                            index,
                                                          ) => (
                                                            <Typography
                                                              key={
                                                                recommendation +
                                                                index
                                                              }
                                                              variant='body2'
                                                              color='text.light'
                                                              paragraph
                                                            >
                                                              {recommendation}
                                                            </Typography>
                                                          ),
                                                        )}
                                                      </Box>
                                                    </Popover>
                                                  </Grid>
                                                )}
                                            </Grid>
                                          </TableCell>
                                          {/* <TableCell
                                            style={{
                                              width: '50%',
                                              minWidth: 200,
                                            }}
                                          >
                                            <ProgressBar
                                              progress={[
                                                {
                                                  percentage:
                                                    question.percentages
                                                      .disengaged,
                                                  color: 'error',
                                                },
                                                {
                                                  percentage:
                                                    question.percentages
                                                      .neutrals,

                                                  color: 'warning',
                                                  colorVariant: 'dark',
                                                },
                                                {
                                                  percentage:
                                                    question.percentages
                                                      .engaged,
                                                  color: 'primary',
                                                },
                                              ]}
                                              labelVariant='body2'
                                            />
                                          </TableCell> */}
                                          {(dontShowVisual ? [
                                            'effectiveness',
                                            'importance',
                                            'previous_effectiveness',
                                            'benchmark',
                                          ] : [
                                            'effectiveness',
                                            'importance',
                                            'benchmark',
                                          ]).map(percentageType =>
                                            dontShowVisual ? (
                                              <TableCell key={percentageType}>
                                                <Typography
                                                  variant='body2'
                                                  fontWeight='bold'
                                                  color={
                                                    percentageType ==
                                                    'effectiveness'
                                                      ? question.percentages[
                                                          percentageType
                                                        ] <= 69
                                                        ? 'error'
                                                        : question.percentages[
                                                            percentageType
                                                          ] > 79
                                                        ? 'primary'
                                                        : 'secondary'
                                                      : 'inherit'
                                                  }
                                                  align='center'
                                                >
                                                  {percentageType !==
                                                  'previous_effectiveness'
                                                    ? question.percentages[
                                                        percentageType
                                                      ] !== null
                                                      ? `${question.percentages[percentageType]}%`
                                                      : '--'
                                                    : previousSatisfactions &&
                                                      previousSatisfactions[
                                                        question.id
                                                      ]
                                                    ? `${
                                                        previousSatisfactions[
                                                          question.id
                                                        ]
                                                      }%`
                                                    : 'N/A'}
                                                </Typography>
                                              </TableCell>
                                            ) : (
                                              percentageType !==
                                                'previous_effectiveness' && (
                                                <TableCell key={percentageType}>
                                                  <Typography
                                                    variant='body2'
                                                    fontWeight='bold'
                                                    color={
                                                      percentageType ==
                                                      'effectiveness'
                                                        ? question.percentages[
                                                            percentageType
                                                          ] <= 69
                                                          ? 'error'
                                                          : question
                                                              .percentages[
                                                              percentageType
                                                            ] > 79
                                                          ? 'primary'
                                                          : 'secondary'
                                                        : 'inherit'
                                                    }
                                                    align='center'
                                                  >
                                                    {dontShowVisual == false &&
                                                    percentageType ==
                                                      'previous_effectiveness'
                                                      ? ''
                                                      : percentageType !==
                                                        'previous_effectiveness'
                                                      ? question.percentages[
                                                          percentageType
                                                        ] !== null
                                                        ? `${question.percentages[percentageType]}%`
                                                        : '--'
                                                      : previousSatisfactions &&
                                                        previousSatisfactions[
                                                          question.id
                                                        ]
                                                      ? `${
                                                          previousSatisfactions[
                                                            question.id
                                                          ]
                                                        }%`
                                                      : 'N/A'}
                                                  </Typography>
                                                </TableCell>
                                              )
                                            ),
                                          )}
                                        </TableRow>
                                      ),
                                    )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </>
                      ) : (
                        driver.key ===
                          SurveyDriverScore.CustomRatingsQuestions && (
                          <Typography
                            variant='body2'
                            color='text.primary'
                            fontWeight={500}
                          >
                            {t(
                              'beheardReport.fullReport.noCustomRatingQuestions',
                            )}
                          </Typography>
                        )
                      )}
                    </>
                  ) : (
                    <BeheardReportUnlockButton {...props} />
                  )}
                  <Box mb={2}>
                    <Divider
                      style={{
                        height:
                          driver.key ===
                          SurveyDriverScore.CustomRatingsQuestions
                            ? 0
                            : 3,
                      }}
                    />
                  </Box>
                </Box>
              ),
          )}

          {get(
            surveyDetails,
            'sections_allowed.custom_open_ended_download',
          ) && (
            <Box my={2} width={1} key={'open-ended-questions'}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant='h5'
                    color='text.primary'
                    fontWeight={600}
                    paragraph
                  >
                    {t('beheardReport.fullReport.openEndedQuestions')}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  {surveyDetails.has_open_ended_answers ? (
                    <Grid container spacing={1}>
                      {openEndedDownloadButtons.map(downloadButton => (
                        <Grid item key={downloadButton.label}>
                          <Button
                            style={{ minWidth: 300 }}
                            variant='outlined'
                            color='primary'
                            startIcon={<downloadButton.Icon />}
                            onClick={() =>
                              downloadOpenEndedAnswersRequest(
                                surveyDetails.survey.id,
                                surveyPermissionId,
                                downloadButton.format,
                                getSurveyReportQueryParams(props?.filtersState),
                              )
                            }
                          >
                            {downloadButton.label}
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Typography
                      variant='body2'
                      color='text.primary'
                      fontWeight={500}
                    >
                      {t('beheardReport.fullReport.noCustomOpenEndedQuestions')}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      )}
    </Grid>
  )
}
