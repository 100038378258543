import { useEffect, useState } from 'react'

import { Grid } from '@mui/material'
import { debounce, get } from 'lodash'
import { useSnackbar } from 'notistack'
import pluralize from 'pluralize'
import { useIntl } from 'react-intl'
import { useMutation, useQuery } from 'react-query'

import { Props } from './CompanyAdminLayout.types'
import {
  CompanyAdminLayoutActions,
  CompanyAdminLayoutFilters,
  CompanyAdminLayoutHeader,
  CompanyAdminLayoutTable,
} from './components'

import { ConfirmationDialog, DialogTeleport } from 'components'
import { initialDialog } from 'constants/dialog'
import { getPagination } from 'utils/pagination'
import { API } from 'core/requests'
import { useUser } from 'contexts'

import CompanyAdminLayoutTab from './components/CompanyAdminLayoutTab/CompanyAdminLayoutTab'

export default function CompanyAdminLayout(props: Props) {
  const {
    slug,
    title,
    requestUrl,
    requestOptions,
    customUpdateRequest,
    customDeleteRequest,
    ManagementForm,
    managementPermission,
    filters = [],
    tableProps,
    managementDialogProps,
    managementFormProps,
    children,
    // isManageSurvey=true,
    refreshData,
    surveys,
  } = props

  const { enqueueSnackbar } = useSnackbar()
  const [totalCount, setTotalCount] = useState(0)
  const { user } = useUser()!
  const intl = useIntl()

  const userHasManagementPermission = managementPermission
    ? (user.permissions ?? []).includes(managementPermission)
    : true

  const initialFiltersState = slug === 'employees'? {limit:10,offset:0}:{};

  filters.forEach(filter => {
    initialFiltersState[filter.id] = filter.defaultValue ?? []
  })

  const [rows, setRows] = useState<any>([])
  const [filtersState, setFiltersState] = useState(initialFiltersState)
  const [, setRowData] = useState<any>([])

  const [pagination, setPagination] = useState<{
    currentPage: number
    pageSize: number
  }>(getPagination(slug))

  const [searchtext, setSearchText] = useState('');
  const [dialogForm, setDialogForm] = useState(initialDialog)
  const [dialogDelete, setDialogDelete] = useState<any>(initialDialog)

  const { isFetching: dataIsLoading, refetch: fetchData } = useQuery(
    slug,
    () => {
      if (slug !== 'employees') {
        return API.get(requestUrl, null, requestOptions ?? {
          queryParams: filtersState,
        });
      }
    },
    {
      onSuccess: response => {
        if (slug != 'employees') {
          if (response?.length) {
            setTotalCount(response.length);
            setRows(response);
          }
          if (response?.data?.length) {
            setTotalCount(response.data.length);
            setRows(response.data);
          }
        }
      },
    }
  );

  const { mutate: updateItem } = useMutation(
    (payload: any) =>
      customUpdateRequest
        ? customUpdateRequest(payload)
        : API.update(requestUrl, payload),
    {
      onSuccess: (response: any) => {
        if (response && !response.errors) {
          fetchData()

          enqueueSnackbar(
            title
              ? `${pluralize.singular(title)} ${intl.formatMessage({
                  id: 'companyAdminLayout.successfullyUpdated',
                })}`
              : intl.formatMessage({
                  id: 'companyAdminLayout.success',
                }),
            {
              variant: 'success',
            },
          )
        }
      },
    },
  )

  const { mutate: removeItem, isLoading: removeItemIsLoading } = useMutation(
    (payload: any) =>
      customDeleteRequest
        ? customDeleteRequest(payload)
        : API.delete(requestUrl, payload.id),
    {
      onSettled: () => {
        setDialogDelete({ isOpen: false, data: null })
        fetchData()
        if (slug === 'employees') {
          if (tableProps?.tableRef?.current && tableProps?.tableRef?.current?.onQueryChange) {
            tableProps?.tableRef?.current?.onQueryChange();
          }
        }
        enqueueSnackbar(
          title
            ? `${pluralize.singular(title)} ${intl.formatMessage({
                id: 'companyAdminLayout.successfullyDeleted',
              })}`
            : intl.formatMessage({
                id: 'companyAdminLayout.success',
              }),
          {
            variant: 'success',
          },
        )
      },
    },
  )

  const debouncedSearchTable = debounce((a)=>{
    setFiltersState({...a})
  }, 300); 
  
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersState, refreshData, surveys])

  useEffect(() => {
    const rowListData: any = props.filterCondition
      ? props?.filterCondition(props.surveys)
      : props.surveys

    setRowData(rowListData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tabValue])

  function getListData(data) {
    return props.filterCondition ? props?.filterCondition([...data]) : [...data]
  }

  useEffect(() => {
    if (props.deleteSurveyData && !dialogDelete.isOpen) {
      setDialogDelete({
        isOpen: props.deleteSurveyData,
        data: { ...props.deleteData },
      })
    }
    if (props.deleteSurveyData && dialogDelete.isOpen) {
      props.setDeleteSurveyData(!props.deleteSurveyData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.deleteSurveyData, dialogDelete.isOpen])

  useEffect(()=>{
    setFiltersState({...filtersState,limit:pagination.pageSize,offset:(pagination.currentPage*10)})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pagination])

  useEffect(()=>{
    const filterObj ={
      limit:10,
      offset:0,
      search:searchtext,
      status: []
    }
    debouncedSearchTable(filterObj)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[searchtext])

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <CompanyAdminLayoutHeader
            {...props}
            userHasManagementPermission={userHasManagementPermission}
            setDialogForm={setDialogForm}
            intl={intl}
          />
          <CompanyAdminLayoutTab {...props} />
          <CompanyAdminLayoutActions {...props} intl={intl} />
          <CompanyAdminLayoutFilters
            {...props}
            filtersState={filtersState}
            setFiltersState={setFiltersState}
            dataIsLoading={dataIsLoading}
            intl={intl}
          />
          {tableProps && (
            <CompanyAdminLayoutTable
              {...props}
              data={getListData(rows)}
              dataIsLoading={dataIsLoading}
              setDialogForm={setDialogForm}
              setDialogDelete={setDialogDelete}
              pagination={pagination}
              setPagination={setPagination}
              updateItem={updateItem}
              userHasManagementPermission={userHasManagementPermission}
              intl={intl}
              totalCount={totalCount}
              setSearchText={setSearchText}
            />
          )}
          {children &&
            children({
              fetchData: fetchData,
              updateItem: updateItem,
              removeItem: removeItem,
              dialogForm: dialogForm,
              setDialogForm: setDialogForm,
            })}
        </Grid>
      </Grid>

      {ManagementForm && dialogForm.isOpen && (
        <DialogTeleport
          dialogTitle=''
          dialogAction={null}
          dialogSize='md'
          dialogOpen={!!dialogForm.isOpen}
          handleDialogClose={() => setDialogForm(initialDialog)}
          isFullWidth
          {...managementDialogProps}
        >
          <ManagementForm
            {...managementFormProps}
            dialog={dialogForm}
            closeDialog={() => setDialogForm(initialDialog)}
            rows={rows}
            fetchRows={fetchData}
          />
        </DialogTeleport>
      )}

      <ConfirmationDialog
        dialogOpen={dialogDelete.isOpen}
        setDialogOpen={setDialogDelete}
        message={intl.formatMessage(
          { id: 'components.confirmationDialog.defaultMessage' },
          {
            action: intl.formatMessage({
              id: 'components.confirmationDialog.delete',
            }),
            item:
              get(dialogDelete, 'data.name') ??
              intl.formatMessage({ id: 'companyAdminLayout.thisItem' }),
          },
        )}
        item={dialogDelete?.data}
        actionProps={{
          onClick: () => removeItem(dialogDelete?.data),
          isLoading: removeItemIsLoading,
          label: intl.formatMessage({
            id: 'components.confirmationDialog.delete',
          }),
          color: 'error',
        }}
      />
    </>
  )
}
