export enum DemographicType {
  Boolean = 'boolean',
  Range = 'range',
  Text = 'text',
  Virtual = 'virtual',
}

export enum DemographicCategory {
  Age = 'age',
  BusinessUnit = 'business_unit',
  City = 'city',
  Country = 'country',
  Custom = 'custom',
  Department = 'department',
  Ethnicity = 'ethnicity',
  Gender = 'gender',
  HighValueTalent = 'high_value_talent',
  JobType = 'job_type',
  Location = 'location',
  Region = 'region',
  State = 'state',
  Tenure = 'tenure',
  Territory = 'territory',
}
