import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import { FormattedDate } from 'react-intl'

import { SurveyOverviewProgress } from '../../components'
import { SurveyOverviewActions } from '..'
import {
  EngagementScoreComponent,
  EngagementDriverScoresComponent,
  SurveyParticipation,
  MinimumParticipantsAlert
} from 'components'
import { SurveyStatus } from 'enums/survey'
import { t } from 'i18n'
// import OverallParticipation from 'components/survey/OverallParticipation'
import AnticipatedTurnover from 'components/survey/AnticipatedTurnover'

export default function SurveyOverviewInfo(props: any) {
  const { selectedSurvey, surveyOverview } = props
  const MINIMUM_PARTICIPANTS_REQUIRED = 5
  const surveyHasMinimumParticipantsRequired = selectedSurvey?.current_participation >= MINIMUM_PARTICIPANTS_REQUIRED
  // console.log("surveyOverview: ", surveyOverview, "selectedSurvey: ", selectedSurvey, "surveyHasMinimumParticipantsRequired: ", surveyHasMinimumParticipantsRequired);
  // const isFullReport = (selectedSurvey.report_level === "upgraded") ? true : false

  return (
    (surveyHasMinimumParticipantsRequired) ? (
      <>
        <Grid container spacing={1} mb={5} mt={1}>
          <Grid item xs={5}>
            <Grid container justifyContent='flex-start'>
              {selectedSurvey && (
                <SurveyOverviewProgress selectedSurvey={selectedSurvey} />
              )}
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <SurveyOverviewActions hide={true} {...props} />
          </Grid>
        </Grid>,

        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={12}>
            {selectedSurvey.status === SurveyStatus.Close &&
              surveyOverview &&
              !surveyOverview.errors && (
                <Grid container spacing={2}>
                  {/* <Grid item xs={12} md={6}>
                    <Card style={{ height: '100%' }}>
                      <CardContent> */}
                  {surveyOverview.scores && (
                    <EngagementScoreComponent
                      surveyDetails={surveyOverview}
                    />
                  )}
                  {/* </CardContent>
                    </Card>
                  </Grid> */}

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card>
                      <CardContent>
                        {surveyOverview.scores && (
                          <EngagementDriverScoresComponent
                            surveyDetails={surveyOverview}
                          />
                        )}
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card>
                      <CardContent>
                        {surveyOverview.turnover && (
                          <AnticipatedTurnover surveyOverview={surveyOverview} />
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                  
                  {/*
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card>
                      <CardContent>
                        {surveyOverview.turnover && (
                          <OverallParticipation surveyOverview={surveyOverview} />
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                  */}

                  {/* TODO to make graph for participation number.
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Card>
                      <CardContent>
                        {surveyOverview.turnover && (
                          <AnticipatedTurnover surveyOverview={surveyOverview} />
                        )}
                      </CardContent>
                    </Card>
                  </Grid> */}
                  
                </Grid>
              )}

            {selectedSurvey.status !== SurveyStatus.Scheduled &&
              surveyOverview &&
              !surveyOverview.errors &&
              surveyOverview?.participation_by_demographic?.length ? (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box mt={2}>
                    <Card style={{ borderTop: `20px solid #426E86` }}>
                      <CardContent>
                        <Box pb={3}>
                          <Typography
                            variant='h5'
                            fontWeight='600'
                            style={{ color: `#426E86` }}
                          >
                            {t('surveyOverview.participation')}
                          </Typography>
                        </Box>
                        <SurveyParticipation surveyDetails={surveyOverview} />
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
              </Grid>
            ) : null}

            {selectedSurvey.status === SurveyStatus.Open &&
              !surveyOverview?.participation_by_demographic?.length && (
                <Typography variant='h5' color='text.secondary'>
                  {t('surveyOverview.whenSurveyCloses')}
                </Typography>
              )}

            {selectedSurvey.status === SurveyStatus.Scheduled &&
              selectedSurvey.open_at && (
                <Typography variant='h5' color='text.secondary'>
                  {t('surveyOverview.scheduledLaunchSurveyInfo', {
                    launchDate: (
                      <FormattedDate
                        value={selectedSurvey.open_at}
                        year='numeric'
                        month='numeric'
                        day='numeric'
                        timeZone='utc'
                      />
                    ),
                  })}
                </Typography>
              )}

            {selectedSurvey.status === SurveyStatus.AwaitingManualOpening && (
              <Typography variant='h5' color='text.secondary'>
                {t('surveyOverview.manualLaunchSurveyInfo')}
              </Typography>
            )}
          </Grid>

          {/* <Grid item xs={12} md={4} lg={3}>
            <Card>
              <CardContent>
                <SurveyOverviewActions hide={true} {...props} />
              </CardContent>
            </Card>
          </Grid> */}
        </Grid>
      </>
    ) : (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MinimumParticipantsAlert />
        </Grid>
      </Grid>
    )
  )
}
