import { useState, useEffect } from 'react'

import { HelpOutline as HelperIcon } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/lab'
import { startOfDay } from 'date-fns'
import { get, isEmpty } from 'lodash'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import { useQuery, useMutation } from 'react-query'
import * as yup from 'yup'

import InviteShareReport from '../inviteShareReport/InviteShareReport'
import {
  DialogTeleport,
  FormHandler,
  SurveyParticipation,
  TopFiveAreas,
} from 'components'
import { beheardReportExample } from 'constants/beheardReport'
import { initialDialog } from 'constants/dialog'
import { API } from 'core/requests'
import { t, useTranslation } from 'i18n'
import { useURLQuery } from 'utils/hooks'
import {
  BeheardReportComparisonGraph,
  BeheardReportDescription,
  BeheardReportFullReport,
  BeheardReportRecommendations,
  BeheardReportResults,
  BeheardReportTitle,
  BeheardReportTurnoverForecast,
} from 'pages/beheardReport/components'
import { ROUTES_PATHS } from 'core/routes'

const validationSchema = yup.object({
  name: yup.string().required(t('nameIsRequired')),
  expires_at: yup
    .date()
    .typeError(t('invalidDateFormat'))
    .nullable()
    .min(
      startOfDay(new Date()),
      t('shareReport.validations.minimumExpirationDate'),
    )
    .required(t('shareReport.validations.expirationDateIsRequired')),
})

export default function ManageShareReport({
  dialog,
  closeDialog,
  fetchRows,
  surveyId,
  isAddMode,
}: any) {
  const { enqueueSnackbar } = useSnackbar()
  const [dialogInvite, setDialogInvite] = useState(initialDialog)
  const [dialogPreview, setDialogPreview] = useState<any>({
    isOpen: false,
    name: '',
    Component: null,
  })

  const shareReportId = get(dialog, 'data.id')
  const query = useURLQuery()

  const inviteToShareReport = useMutation(
    (surveyId: any) =>
      API.create('inviteShareReport', null, {
        pathParams: {
          id: surveyId,
        },
      }),
    {
      onSuccess: (response: any) => {
        if (response.id) {
          enqueueSnackbar(t('manageShareReport.snackbars.invitesSent'), {
            variant: 'success',
          })
          setDialogInvite(initialDialog)
          closeDialog()
        }
      },
    },
  )

  const { data: demographics, isLoading: demographicsIsLoading } = useQuery(
    'getDemographics',
    () => API.get('demographics'),
  )

  const {
    data: surveys,
    isLoading: isSurveysLoading,
    refetch: fetchSurveys,
  } = useQuery('surveys', () => API.get('surveys'), {
    enabled: false,
  })

  const initialValues = dialog.data
    ? dialog.data
    : {
        name: '',
        survey_id: surveyId ?? query.get('survey_id'),
        invitees: [],
        expires_at: null,
        has_comparison_graph: false,
        has_custom_open_ended_download: false,
        has_custom_ratings: false,
        has_employee_turnover_forecast: false,
        has_filter: false,
        has_full_report: false,
        has_participation: false,
        has_recommendations: false,
        has_results: false,
        has_top_5_action_areas: false,
        has_top_5_engagement_strengths: false,
        has_top_5_importance: false,
        roles: [],
        demographics: [],
      }

  if (!surveyId && !query.get('survey_id') && isEmpty(surveys)) {
    fetchSurveys()
  }

  return (
    <>
      <FormHandler
        title={useTranslation('manageShareReport.formHandler.title')}
        requestUrl='shareReport'
        detailsRequestId={shareReportId}
        data={dialog.data}
        closeDialog={closeDialog}
        fetchRows={fetchRows}
        initialValues={initialValues}
        validationSchema={validationSchema}
        isAddMode={isAddMode}
        customMutationRequest={(payload: any) => {
          return API.createOrUpdate('shareReport', {
            ...payload,
            roles: payload?.roles,
            demographics: payload?.demographics ?? [],
          })
        }}
        mutationOnSuccess={(response: any) => {
          if (!response.invites_sent_at)
            setDialogInvite({ isOpen: true, data: response.id })

          if (response?.id) {
            const sharedReportUrl = `${window.location.hostname}${ROUTES_PATHS.login}?redirect=beheard-report&id=${surveyId || query.get('survey_id')}&shared_report_id=${response?.id}&full_report=1`

            navigator.clipboard.writeText(sharedReportUrl)

            enqueueSnackbar(
              'BeHeard Report link with applied filters has been copied to your clipboard',
              {
                variant: 'success',
              },
            )
          }

          if (fetchRows) fetchRows()
        }}
      >
        {({ formik }) => (
          <ManageShareReportForm
            formik={formik}
            demographics={demographics}
            demographicsIsLoading={demographicsIsLoading}
            surveyId={surveyId}
            query={query}
            surveys={surveys}
            isSurveysLoading={isSurveysLoading}
            setDialogPreview={setDialogPreview}
          />
        )}
      </FormHandler>

      <InviteShareReport
        dialog={dialogInvite}
        setDialog={setDialogInvite}
        invite={() => inviteToShareReport.mutate(dialogInvite?.data)}
      />

      <DialogTeleport
        dialogTitle={
          <>
            {t('manageShareReport.previewFor', { name: dialogPreview?.name })}
          </>
        }
        dialogAction={null}
        dialogSize='md'
        dialogOpen={dialogPreview.isOpen}
        handleDialogClose={() =>
          setDialogPreview({ isOpen: false, name: '', Component: null })
        }
        isFullWidth
      >
        {dialogPreview.Component && (
          <dialogPreview.Component
            surveyDetails={beheardReportExample}
            key={1}
            Title={BeheardReportTitle}
            Description={BeheardReportDescription}
            isFullReport
          />
        )}
      </DialogTeleport>
    </>
  )
}

function ManageShareReportForm({
  formik,
  demographics,
  demographicsIsLoading,
  surveyId,
  query,
  surveys,
  isSurveysLoading,
  setDialogPreview,
}) {
  const reportSections = [
    {
      id: 'has_participation',
      label: t('participation'),
      Component: SurveyParticipation,
    },
    {
      id: 'has_comparison_graph',
      label: t('manageShareReport.reportSections.labels.comparisonGraph'),
      Component: BeheardReportComparisonGraph,
    },
    {
      id: 'has_results',
      label: t('manageShareReport.reportSections.labels.results'),
      Component: BeheardReportResults,
    },
    {
      id: 'has_top_5_action_areas',
      label: t('manageShareReport.reportSections.labels.topFiveActionAreas'),
      Component: TopFiveAreas,
    },
    {
      id: 'has_top_5_engagement_strengths',
      label: t('manageShareReport.reportSections.labels.topFiveStrengths'),
      Component: TopFiveAreas,
    },
    {
      id: 'has_top_5_importance',
      label: t(
        'manageShareReport.reportSections.labels.topFiveMostImportantItems',
      ),
      Component: TopFiveAreas,
    },
    {
      id: 'has_recommendations',
      label: t('manageShareReport.reportSections.labels.recommendations'),
      Component: BeheardReportRecommendations,
    },
    {
      id: 'has_employee_turnover_forecast',
      label: t('manageShareReport.reportSections.labels.turnoverForecast'),
      Component: BeheardReportTurnoverForecast,
    },
    {
      id: 'has_full_report',
      label: t('manageShareReport.reportSections.labels.fullReport'),
      Component: BeheardReportFullReport,
    },
    {
      id: 'has_custom_ratings',
      label: t('manageShareReport.reportSections.labels.customRatingQuestions'),
    },
    {
      id: 'has_custom_open_ended_download',
      label: t(
        'manageShareReport.reportSections.labels.customOpenEndedQuestions',
      ),
    },
  ]

  const [openEndedWarningDialog, setOpenEndedWarningDialog] = useState(false)

  const intl = useIntl()

  const listSelectedRolesAndDemographics = () => {
    return (
      <Typography variant='body1' paragraph mt={2}>
        {t(' ', {
          // {t('manageShareReport.reportWillBeSharedWithRoles', {
          selectedRoles: (
            <Typography
              component='span'
              variant='body1'
              fontWeight={600}
              mt={2}
            >
              {/* {selectedRoles} */}
            </Typography>
          ),
        })}
      </Typography>
    )
  }

  useEffect(() => {
    if (demographics?.length > 0 && formik.values.demographics?.length === 0) {
      const newDemographics = demographics.map(demographic => ({
        category: demographic.id,
        demographics: [],
      }))

      formik.setFieldValue('demographics', [...newDemographics])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demographics])

  return (
    <>
      {demographicsIsLoading ? (
        <Grid container justifyContent='center'>
          <CircularProgress size={40} />
        </Grid>
      ) : (
        <Grid container spacing={2} alignItems='center'>
          {!surveyId && !query.get('survey_id') && (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id='surveyAutocomplete'
                    value={(surveys ?? []).find(
                      survey => survey.id === formik.values.survey_id,
                    )}
                    onChange={(event, newSelectedSurvey: any) => {
                      formik.setFieldValue('survey_id', newSelectedSurvey.id)
                    }}
                    loading={isSurveysLoading}
                    loadingText={t('loading')}
                    options={surveys ?? []}
                    getOptionLabel={option => option.name}
                    disableClearable
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={t('surveyOverview.selectLabel')}
                        variant='outlined'
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <TextField
              id='name'
              name='name'
              label={intl.formatMessage({
                id: 'manageShareReport.formHandler.labels.name',
              })}
              placeholder={intl.formatMessage({
                id: 'manageShareReport.formHandler.placeholders.beheardReport',
              })}
              variant='outlined'
              value={formik.values.name}
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <DatePicker
              renderInput={params => (
                <TextField
                  {...params}
                  id='expires_at'
                  name='expires_at'
                  label={intl.formatMessage({
                    id: 'manageShareReport.formHandler.labels.expirationDate',
                  })}
                  variant='outlined'
                  required
                  fullWidth
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.expires_at &&
                    Boolean(formik.errors.expires_at)
                  }
                  helperText={
                    formik.touched.expires_at && formik.errors.expires_at
                  }
                />
              )}
              value={formik.values.expires_at}
              onChange={value => formik.setFieldValue('expires_at', value)}
              disablePast
            />
          </Grid>

          <Grid item xs={12} lg={6} style={{ marginTop: 16 }}>
            <FormLabel component='legend' sx={{ mb: 2 }}>
              {intl.formatMessage({
                id: 'manageShareReport.formHandler.labels.whatToSee',
              })}
            </FormLabel>
            <FormGroup row>
              {reportSections.map(section => {
                return (
                  <Grid container alignItems='center' key={section.id}>
                    <Grid item xs mr={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!!formik.values[section.id]}
                            onChange={() =>
                              formik.setFieldValue(
                                section.id,
                                !formik.values[section.id],
                              )
                            }
                            name={'' + section.id}
                          />
                        }
                        label={section.label}
                      />
                    </Grid>
                    {section.Component && (
                      <Grid item xs='auto'>
                        <IconButton
                          onClick={() =>
                            setDialogPreview({
                              isOpen: true,
                              Component: section.Component,
                              name: section.label,
                            })
                          }
                        >
                          <HelperIcon />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                )
              })}
            </FormGroup>
          </Grid>

          {/* <Grid item xs={12} mt={2}>
            <RolesPicker
              formik={formik}
              roles={roles}
              label={t('manageShareReport.formHandler.labels.whichRoles')}
              selectLabel={t('manageShareReport.formHandler.labels.roles')}
              fieldName='roles'
            />
          </Grid> */}

          {(formik.values.demographics?.length > 0 ||
            formik.values?.roles?.length > 0) && (
            <Grid item xs={12}>
              {listSelectedRolesAndDemographics()}
            </Grid>
          )}
        </Grid>
      )}

      <DialogTeleport
        dialogTitle={intl.formatMessage({
          id: 'manageShareReport.formHandler.dialog.title',
        })}
        dialogAction={null}
        dialogSize='sm'
        dialogOpen={openEndedWarningDialog}
        handleDialogClose={() => setOpenEndedWarningDialog(false)}
        isFullWidth
      >
        <Typography variant='h6' color='error.main' paragraph>
          {intl.formatMessage({
            id: 'manageShareReport.formHandler.dialog.content.firstLine',
          })}
        </Typography>

        <Typography variant='body2' color='text.secondary'>
          {intl.formatMessage({
            id: 'manageShareReport.formHandler.dialog.content.secondLine',
          })}
        </Typography>

        <Box mt={3}>
          <Button
            color='primary'
            variant='contained'
            onClick={() => setOpenEndedWarningDialog(false)}
            fullWidth
          >
            {intl.formatMessage({
              id: 'manageShareReport.formHandler.dialog.buttonText',
            })}
          </Button>
        </Box>
      </DialogTeleport>
    </>
  )
}
