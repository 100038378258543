import { Box, Paper, Theme, useMediaQuery, Typography } from '@mui/material'

export default function OnboardingTitle({
  backgroundColor1,
  backgroundColor2,
  title,
}) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <Paper
      square
      style={{
        background: `linear-gradient(135deg, ${backgroundColor1}, ${backgroundColor2})`,
        position: 'sticky',
        top: 0,
        bottom: 'calc(100% - 24px)',
        right: 0,
        left: 0,
        margin: '0 auto',
        width: '100%',
        maxHeight: 104,
        zIndex: 999,
      }}
    >
      <Box py={2} px={1} width={1}>
        <Typography
          variant={isMobile ? 'body2' : 'h6'}
          color='text.white'
          align='center'
          fontWeight={600}
        >
          {title}
        </Typography>
      </Box>
    </Paper>
  )
}
