import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Theme,
  Typography,
  useTheme,
} from '@mui/material'

export default function RolesPicker({
  formik,
  roles,
  label,
  selectLabel,
  fieldName,
}) {
  const theme = useTheme()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant='body1'
          color='text.secondary'
          sx={{ textTransform: 'uppercase' }}
        >
          {label}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id='multiple-chip-label'>{selectLabel}</InputLabel>
          <Select
            data-cy='rolesPicker'
            labelId='multiple-chip-label'
            id={`multiple-chip-roles-picker`}
            multiple
            value={formik.values[fieldName] ?? []}
            onChange={event =>
              formik.setFieldValue(fieldName, event.target.value)
            }
            input={
              <OutlinedInput
                id={`select-multiple-chip-roles-picker`}
                label={selectLabel}
              />
            }
            renderValue={(selected: any) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {selected.map(roleId => (
                  <Chip
                    key={roleId}
                    label={(roles ?? []).find(role => role.id === roleId)?.name}
                  />
                ))}
              </Box>
            )}
            fullWidth
          >
            {(roles ?? []).map(role => (
              <MenuItem
                data-cy='roles'
                key={role.id}
                value={role.id}
                style={getStyles(role.id, formik.values[fieldName], theme)}
              >
                <Checkbox
                  checked={formik.values[fieldName]?.indexOf(role.id) > -1}
                  size='small'
                />
                {role.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

function getStyles(name: string, value: readonly string[], theme: Theme) {
  return {
    fontWeight:
      value?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}
