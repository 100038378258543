import { useState, useEffect } from 'react'

import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { get, isEmpty } from 'lodash'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import { useMutation, useQuery } from 'react-query'

import { MoneyInput } from 'components'
import { API } from 'core/requests'
import { t } from 'i18n'
import { formatToUSD } from 'utils/currency'
import { getDateLabel } from 'utils/tables/dataTypes'

export default function CompaniesNGCManagement({ company }) {
  const intl = useIntl()
  const theme: any = useTheme()
  const { enqueueSnackbar } = useSnackbar()

  const [NGCState, setNGCState] = useState<any>({
    ngc_api_client_id: null,
    ngc_api_secret: null,
  })

  const [NGCAmountState, setNGCAmountState] = useState<any>(0)

  const { refetch: fetchNGCAccount } = useQuery(
    'getNGCAccounts',
    () =>
      API.get('NGCAccounts', null, {
        queryParams: {
          company_id: get(company, 'id'),
        },
      }),
    {
      enabled: false,
      onSuccess: response => {
        setNGCState({ ...response, company_id: get(company, 'id') })
      },
    },
  )

  const { data: NGCFunds, refetch: fetchNGCFunds } = useQuery(
    'getNGCFunds',
    () =>
      API.get('NGCFunds', null, {
        queryParams: {
          company_id: get(company, 'id'),
        },
      }),
    {
      enabled: false,
    },
  )

  const NGCAccountMutation = useMutation(
    (payload: any) =>
      API.base('NGCAccounts', {
        queryParams: {
          company_id: payload.company_id,
        },
        method: payload.account_id ? 'PATCH' : 'POST',
        body: payload,
      }),
    {
      onSuccess: (response: any) => {
        if (response.account_id) {
          setNGCState(response)

          enqueueSnackbar(
            intl.formatMessage({
              id: 'companies.ngcAccountSuccessfullyUpdated',
            }),
            {
              variant: 'success',
            },
          )
        }
      },
    },
  )

  const NGCFundMutation = useMutation(
    (payload: any) =>
      API.base('NGCFunds', {
        queryParams: {
          company_id: payload.company_id,
        },
        method: 'POST',
        body: payload,
      }),
    {
      onSuccess: (response: any) => {
        if (response.ngc_amount) {
          enqueueSnackbar(
            intl.formatMessage({
              id: 'companies.ngcAccountSuccessfullyUpdated',
            }),
            {
              variant: 'success',
            },
          )

          fetchNGCFunds()
        }
      },
    },
  )

  useEffect(() => {
    if (get(company, 'id')) {
      fetchNGCAccount()
      fetchNGCFunds()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company])

  const fundsHavePendingFund = (NGCFunds?.length ? NGCFunds : []).find(
    fund => fund.status === 'pending',
  )

  return (
    <Grid container>
      <Grid item xs={12}>
        {!isEmpty(NGCState) && (
          <Grid spacing={2} container>
            <Grid item xs={12} md={6}>
              <TextField
                id='ngc_api_client_id'
                name='ngc_api_client_id'
                label={intl.formatMessage({ id: 'companies.ngcApiClientId' })}
                variant='outlined'
                value={NGCState.ngc_api_client_id ?? ''}
                onChange={event =>
                  setNGCState({
                    ...NGCState,
                    ngc_api_client_id: event.target.value,
                  })
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                id='ngc_api_secret'
                name='ngc_api_secret'
                label={intl.formatMessage({ id: 'companies.ngcApiSecret' })}
                variant='outlined'
                value={NGCState.ngc_api_secret ?? ''}
                onChange={event =>
                  setNGCState({
                    ...NGCState,
                    ngc_api_secret: event.target.value,
                  })
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent='flex-end'>
                <Button
                  variant='contained'
                  color='primary'
                  disabled={NGCAccountMutation.isLoading}
                  onClick={() => NGCAccountMutation.mutate(NGCState)}
                >
                  {t('companies.saveChanges')}
                </Button>
              </Grid>
            </Grid>

            {NGCState.account_id && (
              <>
                <Grid item xs={12}>
                  <Typography variant='h6' color='primary.text'>
                    {t('companies.funds')}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Grid item xs>
                      <TextField
                        id='amount'
                        name='amount'
                        label={t('companies.fundsAmount')}
                        variant='outlined'
                        value={NGCAmountState ?? ''}
                        onChange={event =>
                          setNGCAmountState(event.target.value)
                        }
                        fullWidth
                        size='small'
                        disabled={fundsHavePendingFund}
                        InputProps={{
                          inputComponent: MoneyInput,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs='auto'>
                      <Button
                        variant='contained'
                        color='primary'
                        disabled={
                          NGCFundMutation.isLoading || fundsHavePendingFund
                        }
                        onClick={() =>
                          NGCFundMutation.mutate({
                            company_id: get(company, 'id', ''),
                            amount: NGCAmountState,
                          })
                        }
                      >
                        {t('companies.addFunds')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                {(NGCFunds ?? []).length > 0 && (
                  <Grid item xs={12}>
                    <TableContainer component={Paper}>
                      <Table aria-label='ngc funds table'>
                        <TableHead>
                          <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>{t('companies.ngcAmount')}</TableCell>
                            <TableCell>{t('companies.sparckAmount')}</TableCell>
                            <TableCell>{t('companies.total')}</TableCell>
                            <TableCell>{t('status')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(NGCFunds ?? []).map(fund => (
                            <TableRow key={fund.id}>
                              <TableCell component='th' scope='row'>
                                {fund.id}
                              </TableCell>
                              <TableCell component='th' scope='row'>
                                {getDateLabel(fund.updated_at)}
                              </TableCell>
                              <TableCell>
                                {formatToUSD(fund.ngc_amount)}
                              </TableCell>
                              <TableCell>
                                {formatToUSD(fund.sparck_amount)}
                              </TableCell>
                              <TableCell>
                                {formatToUSD(fund.total_amount)}
                              </TableCell>
                              <TableCell>
                                <span
                                  style={{
                                    fontWeight: 600,
                                    color:
                                      fund.status === 'pending'
                                        ? theme.palette.secondary.main
                                        : theme.palette.primary.main,
                                  }}
                                >
                                  {fund.status === 'pending'
                                    ? intl.formatMessage({
                                        id: 'pending',
                                      })
                                    : intl.formatMessage({
                                        id: 'companies.released',
                                      })}
                                </span>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
