import { useTheme } from '@mui/material'
import { Assignment as DemographicsIcon } from '@mui/icons-material'
import { useIntl } from 'react-intl'

import ManageDemographic from './manageDemographic/ManageDemographic'
import { CompanyAdminLayout } from 'components'

export default function Demographics() {
  const theme: any = useTheme()
  const intl = useIntl()

  return (
    <>
      <CompanyAdminLayout
        slug='demographics'
        title={intl.formatMessage({ id: 'demographics.title' })}
        description={intl.formatMessage({ id: 'demographics.description' })}
        color={theme.palette.primary.main}
        requestUrl='demographics'
        tableProps={{
          columns: [
            {
              title: intl.formatMessage({ id: 'name' }),
              field: 'name',
              editable: 'never',
            },
          ],
          editActionProps: {
            disabled: row => row.type !== 'text',
          },
          deleteActionProps: {
            disabled: row => row.category !== 'custom',
          },
        }}
        ManagementForm={ManageDemographic}
        IconHeader={DemographicsIcon}
      />
    </>
  )
}
