import { Fragment, useEffect, useState } from 'react'

import { get, isEmpty } from 'lodash'
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  Link,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useIntl } from 'react-intl'

import {
  BeheardReportCard,
  BeheardReportDescription,
  BeheardReportDownload,
  BeheardReportTitle,
} from '../../components'
import sparckLogoColored from 'assets/images/sparck-logo-colored.png'
import { DemographicsFilters, MinimumParticipantsAlert } from 'components'
import {
  DRAWER_OPENED_WIDTH,
  TOPBAR_GREEN_HEIGHT,
  TOPBAR_HEIGHT,
} from 'constants/app'
import { beheardReportItems } from 'constants/beheardReport'
import { t } from 'i18n'

const MINIMUM_PARTICIPANTS_REQUIRED = 5

export default function BeheardReportComponent(props: any) {
  const { filtersState } = props

  const {
    beheardReport,
    beheardReportIsLoading,
    isFullReport,
    addSurveyToCart,
    addSurveyToCartIsLoading,
    surveyPermissionId,
    filtersQuantityState,
    fetchSurveyReportFromAPI,
    minimumParticipantsAlert,
    roles,
    fetchRoles,
  } = props

  const [showFilters, setShowFilters] = useState(false)

  const intl = useIntl()

  const surveyName = get(beheardReport, 'survey.name', 'this survey')

  const surveyHasMinimumParticipantsRequired =
    beheardReport.survey?.current_participation >= MINIMUM_PARTICIPANTS_REQUIRED

  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  const scrollToSection = sectionId => {
    window.document.getElementById(sectionId)!.scrollIntoView()
  }

  useEffect(() => {
    if (showFilters && !roles?.length) {
      fetchRoles()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFilters])

  return (
    <>
      {beheardReportIsLoading || !beheardReport ? (
        <Box mt={3}>
          <Grid container justifyContent='center'>
            <Box position='relative' my={3}>
              <CircularProgress size={160} thickness={1.2} />
              <img
                src={sparckLogoColored}
                alt='Sparck logo'
                style={{
                  position: 'absolute',
                  width: 80,
                  height: 80,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  margin: '0 auto',
                  left: 0,
                  right: 0,
                }}
              />
            </Box>
            <Grid item xs={12}>
              <Typography variant='h4' color='text.primary' align='center'>
                {t('beheardReport.reportBeingGenerated')}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Grid container>
          <Box display={{ xs: 'none', md: 'block' }}>
            <div
              style={{
                position: 'fixed',
                top: TOPBAR_HEIGHT,
                width: DRAWER_OPENED_WIDTH,
              }}
            >
              <Paper
                style={{
                  paddingTop: TOPBAR_GREEN_HEIGHT,
                  height: `100vh`,
                }}
              >
                {beheardReportItems(
                  beheardReport.sections_allowed,
                  surveyHasMinimumParticipantsRequired,
                )
                  .filter(item => !!item.render)
                  .map(beheardItem => (
                    <Fragment key={beheardItem.id}>
                      <Link
                        onClick={() => scrollToSection(beheardItem.id)}
                        key={beheardItem.id}
                      >
                        <ListItem button>
                          <ListItemText
                            style={{
                              whiteSpace: 'pre-line',
                            }}
                          >
                            <Typography variant='body2' color='text.secondary'>
                              {beheardItem.navigationTitle}
                            </Typography>
                          </ListItemText>
                        </ListItem>
                      </Link>
                      <Divider />
                    </Fragment>
                  ))}
              </Paper>
            </div>
          </Box>

          <Grid
            item
            style={{
              paddingLeft: isDesktop ? DRAWER_OPENED_WIDTH : 0,
              maxWidth: '100%',
            }}
          >
            <Grid container justifyContent='center'>
              <Grid item xs={11} sm={10} md={9}>
                <Box mb={2} style={{ width: '100%' }}>
                  <Grid
                    container
                    spacing={1}
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Grid item xs>
                      <Box my={3}>
                        {surveyName && (
                          <Typography color='text.secondary' variant='h4'>
                            {intl.formatMessage(
                              { id: 'beheardReport.title.report' },
                              {
                                type: +isFullReport
                                  ? intl.formatMessage({
                                    id: 'beheardReport.title.premium',
                                  })
                                  : intl.formatMessage({
                                    id: 'beheardReport.title.free',
                                  }),
                              },
                            )}{' '}
                            {intl.formatMessage({
                              id: 'for',
                            })}{' '}
                            {surveyName}
                          </Typography>
                        )}
                      </Box>
                    </Grid>

                    {!!+isFullReport &&
                      get(beheardReport, 'sections_allowed.filter') &&
                      surveyHasMinimumParticipantsRequired && (
                        <>
                          <Grid item xs='auto'>
                            <Grid container justifyContent='flex-end'>
                              <Badge
                                badgeContent={filtersQuantityState}
                                color='secondary'
                              >
                                <Button
                                  variant='outlined'
                                  color='secondary'
                                  onClick={() => setShowFilters(!showFilters)}
                                  size='large'
                                >
                                  {t('beheardReport.filterButtons.filters')}
                                </Button>
                              </Badge>
                            </Grid>
                          </Grid>

                          <BeheardReportDownload filtersState={filtersState} />
                        </>
                      )}
                  </Grid>
                </Box>

                <Grid item xs={12}>
                  {isEmpty(beheardReport) ? (
                    <Grid container justifyContent='center'>
                      <Box mt={5}>
                        <CircularProgress size={40} />
                      </Box>
                    </Grid>
                  ) : (
                    <>
                      {!!+isFullReport && (
                        <Collapse in={showFilters}>
                          <Grid container>
                            {minimumParticipantsAlert && (
                              <MinimumParticipantsAlert isInFilter />
                            )}

                            <Grid item xs={12}>
                              <Box mb={3}>
                                <Paper square>
                                  <Box p={2}>
                                    <DemographicsFilters
                                      {...props}
                                      roles={roles}
                                      hasResetFilters
                                      refetch={fetchSurveyReportFromAPI}
                                      allowShareReport
                                    />
                                  </Box>
                                </Paper>
                              </Box>
                            </Grid>
                          </Grid>
                        </Collapse>
                      )}
                      {beheardReportItems(
                        beheardReport.sections_allowed,
                        surveyHasMinimumParticipantsRequired,
                      )
                        .filter(item => !!item.render && item.Component)
                        .map(reportItem => (
                          <BeheardReportCard
                            id={reportItem.id}
                            key={reportItem.id}
                            title={reportItem.title}
                            color={reportItem.color}
                            Component={
                              // @ts-ignore
                              <reportItem.Component
                                key={reportItem.id}
                                surveyDetails={beheardReport}
                                Title={BeheardReportTitle}
                                Description={BeheardReportDescription}
                                isFullReport={!!+isFullReport}
                                addSurveyToCart={addSurveyToCart}
                                addSurveyToCartIsLoading={
                                  addSurveyToCartIsLoading
                                }
                                filtersState={filtersState}
                                surveyPermissionId={surveyPermissionId}
                              />
                            }
                          />
                        ))}
                    </>
                  )}
                </Grid>
                
                <Grid item xs={12}>
                  {!surveyHasMinimumParticipantsRequired && <MinimumParticipantsAlert />}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}
