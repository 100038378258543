import { Chip, useTheme } from '@mui/material'
import { AssignmentInd as ProfileQuestionsIcon } from '@mui/icons-material'
import { get } from 'lodash'
import { useIntl } from 'react-intl'

import ManageProfileQuestion from './manageProfileQuestion/ManageProfileQuestion'
import { CompanyAdminLayout } from 'components'
import { useUser } from 'contexts'
import { UserPermission } from 'enums/user'
import { capitalizeFirstLetter } from 'utils/app'

const renderStatement = profileQuestion =>
  get(profileQuestion, 'profile_library_question.statement') ??
  profileQuestion.statement

const renderQuestionType = (profileQuestion: any) => (
  <Chip
    label={
      <b>
        {capitalizeFirstLetter(
          get(profileQuestion, 'profile_library_question.type') ??
            profileQuestion.type,
        )}
      </b>
    }
    color={
      get(profileQuestion, 'profile_library_question.type') ===
        'professional' || profileQuestion.type === 'professional'
        ? 'primary'
        : 'secondary'
    }
  />
)

export default function ProfileQuestions() {
  const theme = useTheme()
  const intl = useIntl()
  const { user } = useUser()!

  return (
    <>
      <CompanyAdminLayout
        slug='profileQuestions'
        title={intl.formatMessage({ id: 'profileQuestions.title' })}
        description={intl.formatMessage({ id: 'profileQuestions.description' })}
        color={theme.palette.warning.dark}
        requestUrl='profileQuestions'
        tableProps={{
          columns: [
            {
              title: intl.formatMessage({
                id: 'profileQuestions.fields.statement',
              }),
              field: 'statement',
              editable: 'never',
              render: renderStatement,
            },
            {
              title: intl.formatMessage({ id: 'type' }),
              field: 'type',
              editable: 'never',
              render: renderQuestionType,
            },
          ],
          editActionProps: {
            hidden: row =>
              !user.sparck_administrator || row.profile_library_question_id,
          },
          deleteActionProps: {
            disabled: row => (user.sparck_administrator) ? !user.sparck_administrator : row.profile_library_question_id,
          },
          hasStatusSwitch: true,
        }}
        managementPermission={UserPermission.ManageProfileQuestions}
        ManagementForm={ManageProfileQuestion}
        IconHeader={ProfileQuestionsIcon}
      />
    </>
  )
}
