
import { useMemo, useState } from 'react'

import {
  alpha,
  Chip,
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { useIntl } from 'react-intl'

import { SparckRewardsListFilterInfo } from '..'
// import { OrganizationalRewardCard } from 'components'
import { WishType } from 'enums/wish'
import { t } from 'i18n'
import { getRewardName } from 'utils/reward'
import CorporateRewardCard from 'components/CorporateRewardCard'

export default function SparckCorporateRewardsList({
  sparckState,
  // rewards,
  onSelectReward,
  surveyRewardIntentions
}) {
  const theme = useTheme()
  const intl = useIntl()
  const REWARDS_PREVIEW_COUNT = 5
  const [searchText, setSearchText] = useState('')
  const [favoritesOnly, setFavoritesOnly] = useState(false)

  const favoritesOnlyChips = [
    {
      id: 'allRewards',
      label: t('sparcks.sparck.allRewards'),
      onClick: () => setFavoritesOnly(false),
      isSelected: !favoritesOnly,
    },
    {
      id: 'favoritesOnly',
      label: t('sparcks.sparck.favoritesOnly'),
      onClick: () => setFavoritesOnly(true),
      isSelected: !!favoritesOnly,
    },
  ]

  const individualToRecognizeOrganizationalWishesIds = useMemo(
    () =>
      sparckState?.individual_to_recognize?.wishlist?.wishes
        ?.filter(wish => wish.wishable_type === WishType.Company)
        ?.map(wish => +wish.wishable_id),

    [sparckState.individual_to_recognize],
  )

  const filteredRewards = useMemo(() => {
    let newRewards = surveyRewardIntentions

    if (favoritesOnly && individualToRecognizeOrganizationalWishesIds?.length) {
      newRewards = newRewards.filter(reward =>
        individualToRecognizeOrganizationalWishesIds.includes(reward.id),
      )
    }

    if (searchText) {
      newRewards = newRewards.filter(reward =>
        getRewardName(reward)
          ?.toLowerCase()
          ?.includes(searchText.toLowerCase()),
      )
    }

    return newRewards
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyRewardIntentions, searchText, favoritesOnly])

  return (
    <Grid container justifyContent='center' spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems='center' sx={{ mb: 3 }}>
          <Grid item xs={12} sm>
            <TextField
              id='rewardsSearch'
              variant='outlined'
              value={searchText}
              onChange={(event: any) => setSearchText(event.target.value)}
              label={t('sparcks.sparck.rewardsSearch')}
              placeholder={intl.formatMessage({
                id: 'sparcks.sparck.searchForAReward',
              })}
              fullWidth
            />
          </Grid>

          <SparckRewardsListFilterInfo
            maxAmount={
              sparckState?.event?.maximum_reward_amount_for_each_recognition
            }
          />

          {individualToRecognizeOrganizationalWishesIds?.length > 0 && (
            <Grid item xs={12} sm='auto'>
              {favoritesOnlyChips.map(chip => (
                <Chip
                  key={chip.id}
                  onClick={chip.onClick}
                  label={chip.label}
                  sx={{
                    mb: 0,
                    ...(chip.isSelected && {
                      outline: `1.5px solid ${theme.palette.primary.main}`,
                      bgcolor: alpha(theme.palette.primary.main, 0.15),
                    }),
                  }}
                />
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>

      {filteredRewards.length > 0 ? (
        filteredRewards.map((reward, index) => {

          return (
            <Grid
              item
              xs={12}
              className='custom_topProductDisplay'
              sm={index <= 1 ? 6 : 4}
              lg={filteredRewards?.length < REWARDS_PREVIEW_COUNT ? 3 : true}
              key={reward.id}
            >
              <CorporateRewardCard
                reward={reward}
                onClick={onSelectReward}
                {...(reward.id === 'organizationalRewards' && {
                  cardSx: {
                    height: 1,
                  },
                })}
              />
            </Grid>
          )
        })
      ) : (
        <Grid item xs={12} sx={{ my: 3 }}>
          <Typography variant='body1' align='center' color='text.secondary'>
            {t('sparcks.sparck.noRewardsFound')}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}
