import { Grid, Skeleton, Typography } from '@mui/material'

import { Props } from '../../VirginExperiencesSettings.props'
import { formatToUSD } from 'utils/currency'

export default function VirginExperiencesSettingsCredit(props: Props) {
  const { virginExperiencesSettings, virginExperiencesSettingsIsLoading } =
    props

  const availableCredit = virginExperiencesSettings?.available_credit
  const totalCredit = virginExperiencesSettings?.credit_limit
  const usedCredit =
    +virginExperiencesSettings?.credit_limit -
    +virginExperiencesSettings?.available_credit

  return (
    <Grid container>
      {virginExperiencesSettingsIsLoading ? (
        <Grid item xs={12}>
          <Skeleton width='100%' height={40} />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} lg={4}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant='body1'
                  color='text.secondary'
                  fontWeight={500}
                >
                  Credit Used
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant='h5' color='text.primary' fontWeight={700}>
                  {formatToUSD(usedCredit)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={4}>
            <Grid container>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <Typography variant='body2' color='text.secondary'>
                  Available Credit
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant='body1'
                  color='text.primary'
                  fontWeight={600}
                >
                  {formatToUSD(availableCredit)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={4}>
            <Grid container>
              <Grid item xs={12} sx={{ mt: 1 }}>
                <Typography variant='body2' color='text.secondary'>
                  Total
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant='body1'
                  color='text.primary'
                  fontWeight={600}
                >
                  {formatToUSD(totalCredit)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}
