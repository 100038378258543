import { RoleType } from 'enums/role'
import { t } from 'i18n'

export const roleTypes = [
  {
    id: RoleType.CompanyAdmin,
    label: t('roles.types.companyAdmin'),
  },
  {
    id: RoleType.CEO,
    label: t('roles.types.ceo'),
  },
  {
    id: RoleType.Executive,
    label: t('roles.types.executive'),
  },
  {
    id: RoleType.Manager,
    label: t('roles.types.manager'),
  },
  {
    id: RoleType.SecondAdmin,
    label: t('roles.types.second_admin'),
  },
  {
    id: RoleType.Employee,
    label: t('roles.types.employee'),
  },
]
