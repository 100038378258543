import { useState } from 'react'

import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'

import sparckMFA from 'assets/images/multiFactorAuthentication/sparck-mfa.png'
import { MFASetup } from 'pages'
import { useCompany } from 'contexts'
import { ROUTES_PATHS } from 'core/routes'
import { t } from 'i18n'

export default function MFASetupRequiredComponent({ fetchUser }) {
  const { company } = useCompany()!
  const history = useHistory()

  const [showMFASetup, setShowMFASetup] = useState(false)

  return (
    <Grid container justifyContent='center' sx={{ mt: 5 }}>
      <Grid item xs={12} sm={9} md={8} lg={6}>
        <Paper sx={{ p: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent='center'>
                <Grid item xs={9} md={8} lg={6} xl={5}>
                  <Box
                    component='img'
                    src={sparckMFA}
                    alt='Sparck logo with shield badge'
                    sx={{ width: '100%', mb: 1 }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant='h5'
                component='h1'
                fontWeight={600}
                align='center'
              >
                {t('mfa.mfaRequired', {
                  organizationName: company?.name,
                })}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='body1' align='justify' paragraph>
                {t('mfa.mfaRequiredDescription', {
                  organizationName: company?.name,
                })}
              </Typography>
            </Grid>

            {!showMFASetup && (
              <Grid item xs={12}>
                <Grid container justifyContent='center'>
                  <Button
                    variant='contained'
                    color='primary'
                    size='large'
                    onClick={() => setShowMFASetup(true)}
                  >
                    {t('mfa.startMFASetup')}
                  </Button>
                </Grid>
              </Grid>
            )}

            {showMFASetup && (
              <Grid item xs={12}>
                <MFASetup
                  finishButton={{
                    label: t('mfa.takeMeToSparck'),
                    onClick: () => history.push(ROUTES_PATHS.dashboard),
                  }}
                  onVerifyMFAFactorSuccess={fetchUser}
                />
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
