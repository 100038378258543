import { useState, useEffect } from 'react'

import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import { SurveysComponent } from './components'
import { QUESTIONS_PER_PAGE } from 'constants/survey'
import { API } from 'core/requests'
import { ROUTES_PATHS } from 'core/routes'
import { t } from 'i18n'
import { DialogData, SurveyQuestionType } from 'types'
import { SurveyQuestionType as SurveyQuestionCategory } from 'enums/survey'

const getQuestionType = (type, driver) => {
  if (type === 'open_ended') {
    return 'open_ended'
  }

  if (driver === 'custom') {
    return SurveyQuestionCategory.Custom
  } else {
    return SurveyQuestionCategory.Custom
  }
}

const getSatisfactionAnswer = answer => {
  switch (answer) {
    case 'not_applicable':
      return 'not_applicable'
    case 'strongly_disagree':
      return 1
    case 'disagree':
      return 2
    case 'neutral':
      return 3
    case 'agree':
      return 4
    case 'strongly_agree':
      return 5
  }
}

const getDefaultAnswer = (answer, accessor) => {
  if (answer && (answer[accessor] || answer[accessor] === 0)) {
    if (answer[accessor] === 0) {
      return 'not_applicable'
    }
    if (accessor === 'satisfaction') {
      return getSatisfactionAnswer(answer[accessor])
    }
    return answer[accessor]
  }
  return 0
}

let answeredQuestions: any[] = []

export default function Surveys(props) {
  const { preview } = props

  const initialSurveyQuestions: SurveyQuestionType[] = []

  const [surveyState, setSurveyState] = useState<any>({})
  const [surveyQuestions, setSurveyQuestions] = useState(initialSurveyQuestions)
  const [page, setPage] = useState(1)
  const [rewardIntentionId, setRewardIntentionId] = useState<number | any>(null)
  const [dialogReward, setDialogReward] = useState<DialogData>({
    isOpen: false,
    data: null,
  })

  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const getSurveyQuestions = (surveyFromAPI: any) => {
    const answered: any[] = []

    const surveyQuestionsFromAPI = surveyFromAPI?.survey?.questions?.map(
      (question, index) => {
        const answer =
          surveyFromAPI.answers.find(
            answer => answer.question_id === question.id,
          ) || ''

        if (
          answer &&
          !answered.find(
            answered => answered.question_id === answer.question_id,
          )
        ) {
          answered.push({ question_id: question.id, id: answer.id })
        }

        return {
          id: question.id,
          index: index + 1,
          statement: question.statement,
          type: getQuestionType(question.type, question.driver),
          satisfactionScore: getDefaultAnswer(answer, 'satisfaction'),
          importanceScore: getDefaultAnswer(answer, 'importance'),
          openEndedAnswer:
            answer && answer.open_ended_text ? answer.open_ended_text : '',
        }
      },
    )

    answeredQuestions = answered

    const currentPage = +(answered.length / QUESTIONS_PER_PAGE)

    setPage(
      surveyFromAPI.survey.allow_answers_to_be_updated
        ? 1
        : currentPage
        ? currentPage
        : 1,
    )

    return surveyQuestionsFromAPI
  }

  const rewardIntentionMutation = useMutation(
    (payload: any) => API.update('acceptSurveyRewardIntention', payload),
    {
      onSettled: (response: any) => {
        if (response && response.id) {
          enqueueSnackbar(t('survey.snackbar.success.giftCardRedeemed'), {
            variant: 'success',
          })
        }
      },
    },
  )

  const takeSurvey = useQuery('takeSurvey', () => API.get('takeSurvey'), {
    enabled: false,
    onSuccess: (response: any) => {
      if (response && response.errors) {
        history.push(ROUTES_PATHS.dashboard)
        enqueueSnackbar(t('survey.snackbar.success.alreadyFinished'), {
          variant: 'success',
        })
      }

      if (response && response.survey) {
        setSurveyState(response.survey)
        setSurveyQuestions(getSurveyQuestions(response))
      }
    },
  })

  const finishSurveyMutation = useMutation(() => API.update('finishSurvey'), {
    onSettled: (response: any) => {
      if (response) {
        enqueueSnackbar(
          response.message ?? t('survey.snackbar.success.finishedSurvey'),
          {
            variant: 'success',
          },
        )

        answeredQuestions = []

        if (get(response, 'survey.reward') && !surveyState.reward_intention) {
          setRewardIntentionId(get(response, 'survey.reward.id'))
        } else {
          history.push(ROUTES_PATHS.surveyLanding)
        }
      }
    },
  })

  const answerQuestionMutation = useMutation(
    (payload: any) =>
      API.base('answers', {
        method: 'POST',
        body: payload,
      }),
    {
      onSettled: (response: any) => {
        answeredQuestions.push(...response.answers)
      },
    },
  )

  useEffect(() => {
    if (!preview) {
      takeSurvey.refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (preview) {
      setSurveyState(preview)
      setSurveyQuestions(getSurveyQuestions({ survey: preview, answers: [] }))
    }
  }, [preview])

  const paginateQuestions = (index: number) =>
    index >= (page - 1) * QUESTIONS_PER_PAGE &&
    index < (page - 1) * QUESTIONS_PER_PAGE + QUESTIONS_PER_PAGE

  const sendAnswersToAPI = () => {
    const initialQuestions: any[] = []
    const questions = initialQuestions

    surveyQuestions
      .filter((q, index) => paginateQuestions(index))
      .forEach(question => {
        const alreadyAnswered = answeredQuestions.find(
          answeredQuestion => answeredQuestion.question_id === question.id,
        )

        questions.push({
          question_id: question.id,
          id: alreadyAnswered ? alreadyAnswered.id : null,
          importance: question.importanceScore,
          satisfaction: question.satisfactionScore,
          open_ended_text: question.openEndedAnswer,
        })
      })

    answerQuestionMutation.mutate({ answers: questions })

    // for (const question of questions) {
    //   const alreadyAnswered = answeredQuestions.find(
    //     answeredQuestion => answeredQuestion.question_id === question.id,
    //   )

    //   answerQuestionMutation.mutate({
    //     question_id: question.id,
    //     answer_id: alreadyAnswered ? alreadyAnswered.answer_id : '',
    //     importance: question.importanceScore,
    //     satisfaction: question.satisfactionScore,
    //     open_ended_text: question.openEndedAnswer,
    //   })
    // }
  }

  return (
    <SurveysComponent
      surveyQuestions={surveyQuestions}
      setSurveyQuestions={setSurveyQuestions}
      surveyIsLoading={takeSurvey.isLoading}
      surveyState={surveyState}
      page={page}
      setPage={setPage}
      paginateQuestions={paginateQuestions}
      sendAnswersToAPI={sendAnswersToAPI}
      answeredQuestions={answeredQuestions}
      finishSurvey={finishSurveyMutation.mutate}
      dialogReward={dialogReward}
      setDialogReward={setDialogReward}
      rewardIntentionId={rewardIntentionId}
      acceptReward={rewardIntentionMutation.mutate}
      confirmGiftCardIsLoading={rewardIntentionMutation.isLoading}
      preview={preview}
    />
  )
}
