import { useState } from 'react'
import { t } from 'i18n'
import { Box, Grid, Theme, Typography, useMediaQuery,Button } from '@mui/material'
import { get } from 'lodash'
import HelpIcon from '@mui/icons-material/Help';
import {
  SurveyFooter,
  SurveyHeader,
  SurveyHeaderBackground,
  SurveyQuestionCard,
} from '../../components'
import { SurveyReward } from 'components'
import { UseMutateFunction } from 'react-query'
import { DialogData, SurveyQuestionType } from 'types'
import { DialogTeleport } from 'components'
import ReactPlayer from 'react-player';
import beheardSurveyMobileVideo from 'assets/videos/beheard-survey-mobile.mov'
export type SurveysComponentProps = {
  surveyQuestions: SurveyQuestionType[]
  setSurveyQuestions(surveyQuestions: SurveyQuestionType[]): any
  surveyIsLoading?: boolean
  surveyState: any
  page: number
  setPage(page: number): any
  paginateQuestions(index: number): boolean
  sendAnswersToAPI(): any
  answeredQuestions: any
  finishSurvey: UseMutateFunction<any, unknown, void, unknown>
  isMobile?: boolean
  dialogReward: DialogData
  setDialogReward(value: DialogData): void
  rewardIntentionId: number | any
  acceptReward: UseMutateFunction<any, unknown, any, unknown>
  confirmGiftCardIsLoading?: boolean
  preview?: any
}

export default function SurveysComponent(props: SurveysComponentProps) {
  const {
    surveyQuestions,
    paginateQuestions,
    surveyIsLoading,
    rewardIntentionId,
    acceptReward,
    preview,
  } = props

  const [selectedGiftCard, setSelectedGiftCard] = useState<any>(null)
  const [helpDialogOpen, setHelpDialogOpen] = useState(false)
  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('md'),
    {
      defaultMatches: true,
    },
  )
  const companyID = localStorage.getItem('sparck_company')
  const companyIDValue = JSON.parse(JSON.parse(JSON.stringify(companyID)));

  return (
    <>
      <Grid container>
        <SurveyHeaderBackground />
        <SurveyHeader {...props} isMobile={isMobile} />
        {!surveyIsLoading ? (
          <>
            <Grid
              item
              xs={12}
              sx={{
                marginTop: '32px',
                zIndex: 995,
              }}
            >
              <Grid container mt={0} justifyContent='center'>
                {companyIDValue && (companyIDValue['id'] == 694) &&
                  <>
              <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                <h3>{t("surveyinstruction.surveyinstructionkey")} <Button onClick={() => setHelpDialogOpen(true)}>
                  <HelpIcon fontSize='large'/></Button> </h3>
                <Typography mb={2}>
                    {t("surveyinstruction.surveytitle")} <b>{t("surveyinstruction.twopart")}</b> {t("surveyinstruction.using")} <b>{t("surveyinstruction.faces")} </b>
                    {t("surveyinstruction.and")} <b>{t("surveyinstruction.star")}</b><br></br>{t("surveyinstruction.rate")} <b>{t("surveyinstruction.agree")} </b>
                    {t('surveyinstruction.statement')} <b>{t("surveyinstruction.star")}</b> {t('surveyinstruction.then')}<br></br>{t('surveyinstruction.how')} 
                    <b> {t('surveyinstruction.important')} </b>{t('surveyinstruction.eachstatement')} <b>{t('surveyinstruction.starrating')}</b> {t('surveyinstruction.scale')}                 
                </Typography>
              </Grid>
                  </>
                  } 
              <DialogTeleport
                dialogOpen={helpDialogOpen}
                dialogSize='sm'
                dialogTitle=''
                handleDialogClose={() => setHelpDialogOpen(false)}
                isFullWidth
              >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h2> {t('surveyKey.status.surveykey')} {' '}</h2>
                  <ol>
                    <li>
                      <Typography variant='body1' color='text.primary' sx={{ mb: 2 }}>
                        First, you'll{' '}
                        <b>
                          rate to what extent you agree or disagree with each
                          workplace statement
                        </b>{' '}
                        by choosing one of the faces.
                      </Typography>
                    </li>

                    <li>
                      <Typography variant='body1' color='text.primary'>
                        Once you've done that, you'll{' '}
                        <b>rate how important each statement is to you</b> using the
                        stars.
                      </Typography>
                    </li>
                  </ol>

                  <Typography variant='body1' color='text.primary' sx={{ mt: 3 }}>
                    Check the animated example below.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <ReactPlayer
                    url={beheardSurveyMobileVideo}
                    width='100%'
                    height={'100%'}
                    playing
                    loop
                    muted
                    playsInline
                    style={{
                      minHeight: '100%',
                    }}
                  />
                </Grid>
              </Grid>
              </DialogTeleport>
                {surveyQuestions?.filter((question: SurveyQuestionType, index: number) =>
                    paginateQuestions(index),
                  )
                  .map((question: SurveyQuestionType, index) => (
                    <Grid
                      item
                      xs={12}
                      md={10}
                      xl={8}
                      key={question.id ?? index + 1}
                    >
                      <Box mb={isMobile ? 2 : 4}>
                        <SurveyQuestionCard
                          {...props}
                          surveyQuestion={question}
                          isMobile={isMobile}
                        />
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <SurveyFooter {...props} />
            </Grid>
          </>
        ) : (
          <Box py={10} width={1}></Box>
        )}
      </Grid>

      {!preview && (
        <SurveyReward
          {...props}
          selectedGiftCard={selectedGiftCard}
          setSelectedGiftCard={setSelectedGiftCard}
          confirmGiftCard={() => {
            acceptReward({
              sku: get(selectedGiftCard, 'sku'),
              id: rewardIntentionId,
            })
          }}
        />
      )}
    </>
  )
}
