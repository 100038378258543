import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material'

import { DialogCloseButton } from 'components'
import { t } from 'i18n'

type TermsAndConditionsProps = {
  dialogOpen: boolean
  setDialogOpen(value: boolean): any
}

export default function TermsAndConditions({
  dialogOpen,
  setDialogOpen,
}: TermsAndConditionsProps) {
  return (
    <Dialog
      open={dialogOpen}
      fullWidth={true}
      maxWidth={'md'}
      onClose={() => setDialogOpen(false)}
      aria-labelledby='dialogTermsAndConditions'
    >
      <DialogCloseButton handleClose={() => setDialogOpen(false)} />
      <DialogTitle id='dialogTermsAndConditions'>
        {t('components.termsAndConditions.dialogTitle')}
      </DialogTitle>
      <DialogContent data-cy='terms_and_conditions_dialog'>
        <Grid container>
          <Typography
            variant='h5'
            color='text.primary'
            fontWeight={500}
            paragraph
          >
            {t('components.termsAndConditions.dialogContent.firstLine')}
          </Typography>
          <br />
          <br />

          <Typography
            variant='h6'
            color='text.primary'
            fontWeight={500}
            paragraph
          >
            {t('components.termsAndConditions.dialogContent.secondLine')}
          </Typography>
          <br />
          <br />
          <Typography color='text.secondary' paragraph>
            <h2>Terms of Service</h2>

            <h3>
              To access the Sparck Services (including websites, mobile apps,
              subdomains, and other services), users must agree to and abide by
              these Terms.
            </h3>
            <h4>Introduction; Your Agreement to these Terms of Service</h4>
            <p>
              Welcome to SparckCo, Inc. ("Sparck" or "We"). These Terms of
              Service (along with any additional guidelines and/or future
              modifications, these "Terms") govern your use of the Sparck web
              site, as well as any other websites, mobile apps, subdomains, or
              services owned, controlled or offered by Sparck (collectively, the
              "Sparck Services"). To access the Sparck Services, users must at
              all times agree to and abide by these Terms. The Sparck Services
              allow you to submit, store, and access your data, documents,
              calendars, technology, and other information (collectively,
              "Information").
            </p>
            <p>
              PLEASE READ CAREFULLY THE FOLLOWING TERMS. BY REGISTERING FOR
              AND/OR ACCESSING, BROWSING, USING OR SUBSCRIBING TO THE SPARCK
              SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND
              AGREE TO BE BOUND BY THESE TERMS AND OUR{' '}
              <a href='https://faq.sparckco.com/knowledge/sparck-privacy-policy'>
                PRIVACY POLICY
              </a>
              , WHICH IS INCORPORATED HEREIN BY REFERENCE. IF AT ANY TIME YOU DO
              NOT AGREE TO THESE TERMS, THEN PLEASE TERMINATE YOUR USE OF THE
              SPARCK SERVICES.
            </p>

            <p>1. User Accounts and Account Activity Responsibility.</p>

            <p>
              1.1. User Accounts. In order to use certain aspects of the Sparck
              Services, you will have to register and create an account ("User
              Account"). When creating your account for the Sparck Services, you
              agree to provide true, accurate, current, and complete
              information. You further agree to maintain and update your
              personal information as needed to keep it true, accurate, current,
              and complete. You are solely responsible for maintaining the
              confidentiality of your account and password and for restricting
              access to your computer and/or other electronic devices which you
              use to access the Sparck Services, and you agree to accept
              responsibility for all activities that occur under your account or
              password. If you have reason to believe that your account is no
              longer secure (for example, in the event of a loss, theft or
              unauthorized disclosure or use of your ID, password, or any
              credit, debit or charge card number), you agree to immediately
              notify Sparck. You may be liable for the losses incurred by Sparck
              or others due to any unauthorized use of your account.
            </p>
            <p>
              1.2. Administrator Accounts. The person who first completes the
              Sparck Services registration on behalf of any Subscribing
              Organization is the initial "Administrator" for purposes of such
              Subscribing Organization's use of the Sparck Services, and
              exercises certain options to initially determine the level of
              access, privacy, and security for the Sparck Services related to
              the Subscribing Organization ("Administrator Account"). For
              example, the Administrator will determine who can be a User of the
              Sparck Services under the Subscribing Organization associated with
              that Administrator and Subscribing Organization and the level of
              privileges that such Users will possess. Once initial registration
              has been completed, each Subscribing Organization will be able to
              register additional Administrators, as permitted by the
              functionality of the Sparck Service. Each Administrator may
              designate other Users as additional and/or successor
              Administrators, and is responsible for confirming that those
              person(s) accept such responsibility. Upon becoming an
              Administrator, each person will be deemed to agree to the
              obligations hereunder. In addition, any person designated as the
              billing contact in the Sparck Services billing record for a
              Subscribing Organization will be deemed to assume the rights and
              obligations of an Administrator. ADMINISTRATORS ARE FULLY AND
              SOLELY RESPONSIBLE FOR ALL ACTIVITY OCCURRING UNDER THE APPLICABLE
              SUBSCRIBING ORGANIZATION ACCOUNT.
            </p>
            <p>
              1.3. Account Information. You acknowledge and agree that Sparck
              may access, preserve and disclose your account information and
              related contents (collectively, your "Information") if required to
              do so by law or in a good faith belief that such access
              preservation or disclosure is reasonably necessary to: (a) comply
              with legal process; (b) enforce these Terms; (c) respond to claims
              that any Information violates the rights of third parties; (d)
              respond to your requests for customer service; or (e) protect the
              rights, property or personal safety of Sparck, its users, or the
              public.
            </p>

            <p>
              2. License Grant. Subject to the terms and conditions set forth
              herein, Sparck hereby grants to you a limited, personal,
              non-transferable license to use the Sparck Services in the manner
              contemplated by these Terms solely for the purposes of submitting,
              storing, and accessing your Information. Users shall have no right
              to sub-license or resell the Sparck Services or any component
              thereof.
            </p>
            <p>
              3. Privacy. Your privacy is important to Sparck. Please carefully
              read our{' '}
              <a href='https://faq.sparckco.com/knowledge/sparck-privacy-policy'>
                Privacy Policy
              </a>
              , which is incorporated herein by reference, for details relating
              to the collection, use, and disclosure of your personal
              information.
            </p>
            <p>
              4. Individual Features and Services. When using the Sparck
              Services, you will be subject to any additional posted guidelines
              or rules applicable to specific services and features which may be
              posted from time to time (the "Guidelines"). All such Guidelines
              are hereby incorporated by reference into the Terms.
            </p>
            <p>
              5. Modification of the Terms. Sparck reserves the right, at its
              sole discretion, to change, modify, add, or remove portions of
              these Terms at any time. When we change these Terms in a material
              manner, we will update the 'last modified' date at the bottom of
              this page. You agree to review these Terms and any Guidelines
              periodically for changes. Your continued use of the Sparck
              Services after the posting of changes constitutes your binding
              acceptance of such changes. If any such revision is unacceptable
              to you, your only remedy is to terminate your Sparck User Account.
              For any material changes to these Terms, such amended terms will
              automatically be effective thirty days after they are initially
              posted on the Sparck Services.
            </p>
            <p>
              6. Digital Millennium Copyright Act. It is Sparck's policy to
              respond to notices of alleged copyright infringement that comply
              with the Digital Millennium Copyright Act. Sparck will promptly
              terminate without notice any user's access to the Sparck Services,
              if that user is determined by Sparck to be a "repeat infringer." A
              repeat infringer is a user who has been notified by Sparck of
              infringing activity violations more than twice and/or who has had
              user submitted Information removed from the Sparck Services more
              than twice.
            </p>
            <p>
              7. DMCA Notice. If you believe that your copyrighted work has been
              copied in a way that constitutes copyright infringement and is
              accessible via the Sparck Services, please notify Sparck's
              copyright agent, as set forth in the Digital Millennium Copyright
              Act of 1998 ("DMCA"). For your complaint to be valid under the
              DMCA, you must provide the following information in writing:
            </p>
            <p>
              i. An electronic or physical signature of a person authorized to
              act on behalf of the copyright owner;
            </p>
            <p>
              ii. Identification of the copyrighted work that you claim has been
              infringed;
            </p>

            <p>
              iii. Identification of the material that is claimed to be
              infringing and where it is located on the Service;
            </p>

            <p>
              iv. Information reasonably sufficient to permit Sparck to contact
              you, such as your address, telephone number, and, e-mail address;
            </p>
            <p>
              v. A statement that you have a good faith belief that use of the
              material in the manner complained of is not authorized by the
              copyright owner, its agent, or law; and
            </p>
            <p>
              vi. A statement, made under penalty of perjury, that the above
              information is accurate, and that you are the copyright owner or
              are authorized to act on behalf of the owner.
            </p>

            <p>
              The above information must be submitted to the following DMCA
              Agent:
            </p>
            <p>Name:</p>
            <p>Attn: DMCA Notice</p>
            <p>Sparck: SparckCo, Inc.</p>
            <p>Address: 1007 7th Street, 5th Floor, Sacramento, CA 95814</p>
            <p>Telephone: 916-414-1512</p>
            <p>
              Email:{' '}
              <a href='mailto:dmca@sparckco.com' rel=' noopener'>
                dmca@sparckco.com
              </a>
            </p>
            <p>
              UNDER FEDERAL LAW, IF YOU KNOWINGLY MISREPRESENT THAT ONLINE
              MATERIAL IS INFRINGING, YOU MAY BE SUBJECT TO CRIMINAL PROSECUTION
              FOR PERJURY AND CIVIL PENALTIES, INCLUDING MONETARY DAMAGES, COURT
              COSTS, AND ATTORNEYS' FEES. Please note that this procedure is
              exclusively for notifying Sparck that your copyrighted material
              has been infringed. The preceding requirements are intended to
              comply with Sparck's rights and obligations under the DMCA,
              including 17 U.S.C. §512(c), but do not constitute legal advice.
              It may be advisable to contact an attorney regarding your rights
              and obligations under the DMCA and other applicable laws.
            </p>

            <p>8. Mobile Software</p>

            <p>
              8.1. Generally. We may make available software to access the
              Service via a mobile device ("Mobile Software"). To use the Mobile
              Software you must have a mobile device that is compatible with the
              Mobile Software. Sparck does not warrant that the Mobile Software
              will be compatible with your mobile device. You may use mobile
              data in connection with the Mobile Software and may incur
              additional charges from your wireless provider for these services.
              You agree that you are solely responsible for any such charges.
              Sparck hereby grants you a non-exclusive, non-transferable,
              revocable license to use a compiled code copy of the Mobile
              Software for one Sparck account on any mobile device owned or
              leased solely by you, for your personal use. You may not: (a)
              modify, disassemble, decompile or reverse engineer the Mobile
              Software, except to the extent that such restriction is expressly
              prohibited by law; (b) rent, lease, loan, resell, sublicense,
              distribute or otherwise transfer the Mobile Software to any third
              party or use the Mobile Software to provide time sharing or
              similar services for any third party; (c) make any copies of the
              Mobile Software; (d) remove, circumvent, disable, damage or
              otherwise interfere with security-related features of the Mobile
              Software, features that prevent or restrict use or copying of any
              content accessible through the Mobile Software, or features that
              enforce limitations on use of the Mobile Software; or (e) delete
              the copyright and other proprietary rights notices on the Mobile
              Software.
            </p>
            <p>
              8.2. You acknowledge that Sparck may from time to time issue
              upgraded versions of the Mobile Software, and may automatically
              electronically upgrade the version of the Mobile Software that you
              are using on your mobile device. You consent to such automatic
              upgrading on your mobile device, and agree that the terms and
              conditions of these Terms will apply to all such upgrades. Any
              third-party code that may be incorporated in the Mobile Software
              is covered by the applicable open source or third-party license
              EULA, if any, authorizing use of such code. The foregoing license
              grant is not a sale of the Mobile Software or any copy thereof,
              and Sparck or its third-party partners or suppliers retain all
              right, title, and interest in the Mobile Software (and any copy
              thereof). Any attempt by you to transfer any of the rights, duties
              or obligations hereunder, except as expressly provided for in
              these Terms, is void. Sparck reserves all rights not expressly
              granted under these Terms. If the Mobile Software is being
              acquired on behalf of the United States Government, then the
              following provision applies. The Mobile Software will be deemed to
              be "commercial computer software" and "commercial computer
              software documentation," respectively, pursuant to DFAR Section
              227.7202 and FAR Section 12.212, as applicable. Any use,
              reproduction, release, performance, display or disclosure of the
              Service and any accompanying documentation by the U.S. Government
              will be governed solely by these Terms of Service and is
              prohibited except to the extent expressly permitted by these Terms
              of Service. The Mobile Software originates in the United States,
              and is subject to United States export laws and regulations. The
              Mobile Software may not be exported or re-exported to certain
              countries or those persons or entities prohibited from receiving
              exports from the United States. In addition, the Mobile Software
              may be subject to the import and export laws of other countries.
              You agree to comply with all United States and foreign laws
              related to use of the Mobile Software and the Service.
            </p>
            <p>
              8.3. Mobile Software from Apple App Store. The following applies
              to any Mobile Software you acquire from the Apple App Store
              ("Apple-Sourced Software"): You acknowledge and agree that these
              Terms are solely between you and Sparck, not Apple, Inc.
              ("Apple"), and that Apple has no responsibility for the
              Apple-Sourced Software or content thereof. Your use of the
              Apple-Sourced Software must comply with the App Store Terms of
              Service. You acknowledge that Apple has no obligation whatsoever
              to furnish any maintenance and support services with respect to
              the Apple-Sourced Software. In the event of any failure of the
              Apple-Sourced Software to conform to any applicable warranty, you
              may notify Apple, and Apple will refund the purchase price for the
              Apple-Sourced Software to you; to the maximum extent permitted by
              applicable law, Apple will have no other warranty obligation
              whatsoever with respect to the Apple-Sourced Software, and any
              other claims, losses, liabilities, damages, costs or expenses
              attributable to any failure to conform to any warranty will be
              solely governed by these Terms and any law applicable to Sparck as
              provider of the software. You acknowledge that Apple is not
              responsible for addressing any claims of you or any third party
              relating to the Apple-Sourced Software or your possession and/or
              use of the Apple-Sourced Software, including, but not limited to:
              (a) product liability claims; (b) any claim that the Apple-Sourced
              Software fails to conform to any applicable legal or regulatory
              requirement; and (c) claims arising under consumer protection or
              similar legislation; and all such claims are governed solely by
              these Terms and any law applicable to Sparck as provider of the
              software. You acknowledge that, in the event of any third-party
              claim that the Apple-Sourced Software or your possession and use
              of that Apple-Sourced Software infringes that third party's
              intellectual property rights, Sparck, not Apple, will be solely
              responsible for the investigation, defense, settlement and
              discharge of any such intellectual property infringement claim to
              the extent required by these Terms. You and Sparck acknowledge and
              agree that Apple, and Apple's subsidiaries, are third-party
              beneficiaries of these Terms as relates to your license of the
              Apple-Sourced Software, and that, upon your acceptance of the
              terms and conditions of these Terms, Apple will have the right
              (and will be deemed to have accepted the right) to enforce these
              Terms as relates to your license of the Apple-Sourced Software
              against you as a third-party beneficiary thereof.
            </p>
            <p>
              8.4. Mobile Software from Google Play Store. The following applies
              to any Mobile Software you acquire from the Google Play Store
              ("Google-Sourced Software"): (a) you acknowledge that the
              Agreement is between you and Sparck only, and not with Google,
              Inc. ("Google"); (b) your use of Google-Sourced Software must
              comply with Google's then-current Google Play Store Terms of
              Service; (c) Google is only a provider of the Google Play Store
              where you obtained the Google-Sourced Software; (d) Sparck, and
              not Google, is solely responsible for its Google-Sourced Software;
              (e) Google has no obligation or liability to you with respect to
              Google-Sourced Software or the Agreement; and (f) you acknowledge
              and agree that Google is a third-party beneficiary to the
              Agreement as it relates to Sparck's Google-Sourced Software.
            </p>

            <p>
              9. Customer Service; Customer Communications and Notice. Please
              email{' '}
              <a href='mailto:tos@sparckco.com' rel=' noopener'>
                tos@sparckco.com
              </a>{' '}
              for further assistance. Under these Terms, you expressly agree and
              consent to receive communications and notices, including any
              notices required by law, from Sparck electronically (in lieu of
              communication by postal mail). You agree that all agreements,
              notices, disclosures, and other communications that we provide to
              you electronically satisfy any legal requirement that such
              communications be in writing. We will communicate with you by
              email or by posting notices on the Sparck Services. You expressly
              acknowledge and agree that we may also use your email address to
              send you other messages, such as newsletters, changes to features
              of the Sparck Services, and special offers. If you do not want to
              receive such email messages, you may opt out or change your
              preferences in your profile settings. Opting out may prevent you
              from receiving email messages regarding updates, improvements, or
              offers.
            </p>
            <p>
              10. Eligibility. THE COMPANY SERVICES ARE NOT AVAILABLE TO PERSONS
              UNDER 18 YEARS OF AGE OR TO ANY USERS PREVIOUSLY SUSPENDED OR
              REMOVED FROM THE COMPANY SERVICES BY COMPANY. Sparck may terminate
              your account, delete any content or information that you have
              posted on the Sparck Services, and/or prohibit you from using or
              accessing the Services (or any portion, aspect or feature of the
              Services) for any reason or no reason, at any time in its sole
              discretion, with or without notice, including without limitation
              if it believes that you are under 18. By clicking the "I Agree"
              button or by otherwise subscribing to or using the Sparck Services
              you represent that you are at least 18 years of age.
            </p>
            <p>11. Payment Terms; Charges and Taxes.</p>

            <p>
              11.1. Billing. Customer will pay for the Sparck Services as agreed
              upon the contract agreement. We may add or adjust Service fees at
              any time by providing you with thirty (30) days prior notice. You
              are responsible for all charges incurred under your account made
              by you or anyone who uses your account (including your
              Administrators).
            </p>
            <p>
              11.2. Payment. All payments due are in U.S. dollars unless
              otherwise indicated on the order page. Fees for credit card orders
              are due immediately upon order placement. Declined credit card
              authorizations shall be considered delinquent. You agree to pay
              for all services that you purchase through the Sparck order page,
              and we may charge your selected payment method for any such fees
              owed. You are required to keep your billing information current,
              complete, and accurate (e.g., a change in billing address, credit
              card number, or expiration date) and to notify Sparck if your
              selected payment method is cancelled (e.g., for loss or theft).
              Authorization to charge your chosen payment method account will
              remain in effect until you cancel or modify your payment
              preferences; provided, however, that such notice will not affect
              charges submitted before Sparck could reasonably act. Billing
              recurrence is based on the product plan you sign up for:
            </p>
            <h3>
              <strong>Basic Plan</strong>
            </h3>
            <p>
              The Basic Plan is free to all companies and provides access to the
              engagement survey with limited reporting information.&nbsp;
              Because this is a free service, there is no billing and therefore
              no payment method require to sign up.
            </p>
            <h3>
              <strong>Standard Plan</strong>
            </h3>
            <p>
              The Standard Plan provides full access to the Interactive
              Engagement Results and is charged on a survey by survey basis
              based specifically on the number of employees taking the
              survey.&nbsp; Payment occurs through the Premium Report upgrade
              process and is a single payment for each survey the Premium Report
              access is requested.
            </p>
            <h3>
              <strong>Premium Plan</strong>
            </h3>
            <p>
              The Premium Plan provides full access to the Sparck platform and
              unlimited access to create engagement surveys with one (1) Full
              Report included for the first survey created.&nbsp; There will be
              unlimited access to the free report with limited reporting
              information for all surveys distributed.&nbsp; However, there will
              be an additional fee when upgrading any surveys to the Full
              report.&nbsp; The price will be based on the number of employees
              that were included to take the survey.&nbsp; Charges for the
              Premium Plan will be payable in advance and will be charged
              annually. Charges for additional Premium Reports will be billed on
              the date the Premium Report purchase is processed.&nbsp; You must
              cancel your subscription at least thirty (30) days prior to the
              first day of the next annual renewal date, to avoid being charged
              the renewal fee for such period.
            </p>
            <h3>
              <strong>All Plans</strong>
            </h3>
            <p>
              Billing (where applicable) is determined by the date you sign up
              and the billing amount is based on the number of employees in your
              rollout group (e.g., Department, Location, Business unit or
              Company).&nbsp; There will be no additional charges for new
              employees brought into the rollout group until the next billing
              period.&nbsp; However, additional rollout groups (e.g., Additional
              Departments, Locations, Business Units, etc. not part of the
              original rollout group) with be charged based on the number of
              employees in said group when they sign up.&nbsp; THERE ARE NO
              REFUNDS FOR SINGLE SURVEY FEES OR ANNUAL FEES PAID HEREUNDER, FOR
              ANY REASON.&nbsp;&nbsp;&nbsp; No refunds are available if you
              downgrade the number of users or use fewer than the number of
              seats allotted during the contract year.&nbsp; However, you may
              use those seats for other users.
            </p>
            <p>
              11.3. Delinquent Payments. Delinquent payments may bear interest
              at the rate of 1.5% percent per month (or the highest rate
              permitted by law, if less) from the payment due date until paid in
              full. Customer will be responsible for all reasonable expenses
              (including attorneys' fees) incurred by Sparck in collecting such
              delinquent amounts, except where such delinquent amounts are due
              to Sparck's billing inaccuracies.
            </p>
            <p>
              11.4. Adding users.&nbsp; Sparck categorizes their clients into
              enterprise and non-enterprise.&nbsp; Enterprise clients are any
              clients whose company employee count is 1,000 or greater, while
              non-enterprise clients will have an employee count of 999 or
              fewer.&nbsp; Users added during a cycle will not be billed until
              the start of a new term.&nbsp; So, if the term client is billed
              annually any users added during the term will not be billed until
              the next annual renewal.&nbsp; The exception is for enterprise
              customers who start their service with a defined group of users
              such as a department, location or business unit.&nbsp; If they add
              users to the same defined group (e.g., department, location or
              business unit, etc.), those new users will not be billed until the
              beginning of the next renewal term.&nbsp; However, if the client
              adds a new defined group (e.g., department, location or business
              unit, etc.), that group will be billed for a new contract term to
              run independently of the original group unless the enterprise
              requests the groups to be combined into a single contract term in
              which case Sparck will bill them a prorated amount for the
              remainder of the initial accounts term.
            </p>

            <p>
              12. Users' Rights to Information. Upon any termination of a User's
              account, Administrators will have fifteen (15) days to request a
              copy of all data that has been input by or on behalf of the User
              into such User's account on the Sparck Services. Sparck will
              provide the data in Microsoft Excel spreadsheet format within two
              (2) business days of such request, to the e-mail address on file
              in the Administrator's account. Fifteen (15) days after
              termination of a User's account, Sparck will have no obligation to
              retain, and liability for, all User data.
            </p>
            <p>
              13. Restrictions. When using the Sparck Services you agree not to:
            </p>

            <p>
              13.1. Upload or transmit via the Sparck Services pornographic,
              threatening, embarrassing, hateful, racially or ethnically
              insulting, libelous, or otherwise inappropriate content;
            </p>
            <p>
              13.2. Use the Sparck Services for any purpose that is unlawful or
              is otherwise prohibited by these Terms;
            </p>
            <p>
              13.3. Use the Sparck Services in any manner that in our sole
              discretion could damage, disable, overburden, or impair the
              services;
            </p>
            <p>
              13.4. Attempt to gain unauthorized access to the Sparck Services,
              or any part of them, other accounts, computer systems or networks
              connected to the Sparck Services, or any part of them, through
              hacking, password mining or any other means or interfere or
              attempt to interfere with the proper working of the Sparck
              Services or any activities conducted on the Sparck Services;
            </p>
            <p>
              13.5. Modify the Sparck Services in any manner or form, or use
              modified versions of the Sparck Services, including (without
              limitation) for the purpose of obtaining unauthorized access to
              the Sparck Services;
            </p>
            <p>
              13.6. Use any robot, spider, scraper, or other automated means to
              access the Sparck Services for any purpose without our express
              written permission, or bypass any measures we may use to prevent
              or restrict access to the Sparck Services;
            </p>
            <p>
              13.7. Impersonate another person or access another User's account
              without that person's permission or to violate any contractual or
              fiduciary relationships;
            </p>
            <p>
              13.8. Share Sparck-issued passwords with any third party or
              encourage any other User to do so;
            </p>
            <p>
              13.9. Misrepresent the source, identity, or content of Information
              transmitted via the Sparck Services;
            </p>
            <p>
              13.10. Modify, adapt, translate or create derivative works based
              upon the Sparck Services;
            </p>
            <p>
              13.11. Reverse engineer, decompile, disassemble or otherwise
              attempt to discover the source code of the Sparck Services, except
              and only to the extent that such activity is expressly permitted
              by applicable law notwithstanding this limitation;
            </p>
            <p>
              13.12. Rent, lease, loan, resell, sublicense, distribute or
              otherwise transfer the Sparck Services to any third party; provide
              time sharing or similar services for any third party; or use the
              Sparck Services for any purpose other than your own internal
              personal or business use;
            </p>
            <p>
              13.13. Remove, circumvent, disable, damage or otherwise interfere
              with security-related features of the Sparck Services, features
              that prevent or restrict use or copying of any content accessible
              through the Sparck Services or Sparck Services, or features that
              enforce limitations on use of the Sparck Services or Sparck
              Services;
            </p>
            <p>
              13.14. Access the Sparck Services if you are a direct competitor
              of Sparck, except with Sparck's prior written consent, or for any
              other competitive purposes; or
            </p>
            <p>
              13.15. Collect or harvest any personally identifiable information,
              including account names, from the Sparck Services.
            </p>

            <p>
              14. Violations; Termination. You agree that Sparck, in its sole
              discretion and for any or no reason, may terminate any account (or
              any part thereof) you may have with Sparck. In addition, Sparck
              reserves the right to discontinue any aspect of the Sparck
              Services at any time, including the right to discontinue the
              display of any Information. You agree that any termination of your
              access to the Sparck Services or any account you may have or
              portion thereof may be affected without prior notice, and you
              agree that Sparck will not be liable to you or any third-party for
              such termination. Any suspected fraudulent, abusive, or illegal
              activity that may be grounds for termination of your use of the
              Sparck Services may be referred to appropriate law enforcement
              authorities. These remedies are in addition to any other remedies
              Sparck may have at law or in equity.
            </p>
            <p>15. User Provided Information.</p>

            <p>
              15.1. Definition of User Provided Information; Ownership. Sparck
              may now or in the future permit the uploading of Information
              through the Sparck Services ("User Provided Information"), and the
              hosting, sharing, display and/or analysis of such User Provided
              Information. Users may use their account settings to control other
              Users' access to their User Provided Information ("User
              Permissions"). User Provided Information shall also include,
              without limitation, questions, responses, and related data, in any
              format or media, whether now known or hereafter developed within
              the Sparck Services and through any applicable channels.&nbsp;
              Sparck will use its commercially reasonable efforts to apply User
              Permissions. However, Sparck does not guarantee that User
              Permissions will always be applied, or that your User Provided
              Information will be kept secure from viewing by others, or from
              damage or loss. Sparck takes no responsibility and assumes no
              liability for any User Provided Information that you or any other
              Users or third parties post or send over the Sparck Services. You
              understand and agree that any loss or damage of any kind that
              occurs as a result of the use of any User Provided Information
              that you send, upload, download, stream, post, transmit, display,
              or otherwise make available or access through your use of the
              Sparck Services is solely your responsibility. If you want Sparck
              to remove your User Provided Information from the Sparck Services,
              please delete it as specified in the Sparck Services. However,
              other Users may have already copied or forwarded your User
              Provided Information to others, making recovery impossible. Sparck
              is not responsible for any public display or misuse of your User
              Provided Information. You understand that whether or not such User
              Provided Information is displayed, Sparck does not guarantee any
              confidentiality with respect to any User Provided Information.
              Users retain all right, title, and interest in and to all User
              Provided Information.
            </p>
            <p>
              15.2. License Grant to Sparck. By submitting User Provided
              Information to Sparck Services, you hereby grant to Sparck a
              worldwide, non-exclusive, sublicensable, transferable, perpetual,
              irrevocable, fully paid-up, and royalty-free license to use,
              display, reproduce, modify, publish, distribute, list information
              regarding, edit, translate and analyze your User Provided
              Information within the Sparck Services in any formats and through
              any applicable channels.&nbsp; Sparck's use of User Provided
              Content and/or related data in a manner that identifies any User
              shall be in accordance with Sparck's Privacy Policy.&nbsp; Subject
              to the Sparck Privacy Policy, Sparck shall be free to use any User
              Provided Information, or content or information submitted by any
              user or Administrator, for any purpose whatsoever, which may
              include without limitation the purposes of providing the
              applicable features and functionality of the Sparck Services,
              improving the way the Sparck Services work and look, to create new
              features and functionality, or aggregating non-personally
              identifiable data for marketing or promotional purposes.
            </p>
            <p>
              15.3. Limited License Grant to other Sparck Users. By submitting
              User Provided Information to Sparck Services, in cases where you
              display your User Provided Information for other Users to view or
              when you directly exchange or otherwise provide your User Provided
              Information to other Users as permitted by certain Sparck Services
              functionality and these Terms, you hereby grant to such Users of
              the Sparck Services a non-exclusive license to use, display, and
              reproduce such User Provided Information as necessary for such
              Users to use the relevant Sparck Services functionality or
              features.
            </p>
            <p>
              15.4. License Termination. The foregoing licenses granted by you
              terminate as to specific User Provided Information once you remove
              or delete such User Provided Information from the Sparck Services;
              except for Sparck's right to archive such User Provided
              Information in accordance with its standard policies and to use
              such Information in aggregate form to improve the way the Sparck
              Services work and look, and to create new features and
              functionality.
            </p>
            <p>
              15.5. Required Rights. You shall be solely responsible for your
              own User Provided Information and the consequences of posting or
              publishing it. In connection with User Provided Information, you
              affirm, covenant, represent and warrant that you own, or have the
              necessary licenses, rights, consents, and permissions to use and
              to authorize Sparck and Sparck's Users to use the User Provided
              Information uploaded by you as necessary to exercise the licenses
              granted by you in this Section 14, and otherwise in the manner
              contemplated by Sparck and these Terms.
            </p>
            <p>
              15.6. User Provided Information Representations. You agree not to
              submit User Provided Information that: (i) may create a risk of
              harm, loss, physical or mental injury, emotional distress, death,
              disability, disfigurement, or physical or mental illness to you,
              to any other person, or to any animal; (ii) may create a risk of
              any other loss or damage to any person or property; (iii) may
              constitute or contribute to a crime or tort; (iv) contains any
              information or content that we deem to be unlawful, harmful,
              abusive, racially or ethnically offensive, defamatory, infringing,
              invasive of personal privacy or publicity rights, harassing,
              humiliating to other people (publicly or otherwise), libelous,
              threatening, or otherwise objectionable; (v) contains any
              information or content that is illegal; (vi) contains any
              information or content that you do not have a right to make
              available under any law or under contractual or fiduciary
              relationships; or (vii) contains any information or content that
              you know is not correct and current. You agree that any User
              Provided Information that you post does not and will not violate
              third-party rights of any kind, including without limitation any
              intellectual property rights, rights of publicity and privacy.
              Sparck reserves the right, but is not obligated, to reject and/or
              remove any User Provided Information that Sparck believes, in its
              sole discretion, violates these provisions.
            </p>
            <p>
              15.7. User Provided Information Disclaimer. You understand that
              when using Sparck Services you will be exposed to User Provided
              Information from a variety of sources, and that Sparck is not
              responsible for the accuracy, usefulness, safety, or intellectual
              property rights of or relating to such User Provided Information.
              You further understand and acknowledge that you may be exposed to
              User Provided Information that is inaccurate, offensive, indecent
              or objectionable, and you agree to waive, and hereby do waive, any
              legal or equitable rights or remedies you have or may have against
              Sparck with respect thereto, and agree to indemnify and hold
              Sparck, its owners/operators, affiliates, and/or licensors,
              harmless to the fullest extent allowed by law regarding all
              matters related to your use of User Provided Information. Sparck
              does not endorse any User Provided Information or any opinion,
              recommendation or advice expressed therein or based upon such User
              Provided Information, and Sparck expressly disclaims any and all
              liability in connection with User Provided Information. If
              notified by a User or a content owner of User Provided Information
              that allegedly does not conform to these Terms, Sparck may
              investigate the allegation and determine in good faith and in its
              sole discretion whether to remove the User Provided Information,
              which it reserves the right to do at any time. Sparck does not
              permit copyright infringing activities on Sparck Services.
            </p>

            <p>
              16. Ownership; Proprietary Rights. The Sparck Services are owned
              and operated by Sparck. The visual interfaces, graphics, design,
              compilation, information, computer code, products, software,
              services, and all other elements of the Sparck Services provided
              by Sparck, but expressly excluding any of the foregoing owned or
              licensed by and posted to the Sparck Services at the direction of
              Users (including without limitation User Provided Information)
              ("Materials"), are protected by United States copyright, trade
              dress, patent, and trademark laws, international conventions, and
              all other relevant intellectual property and proprietary rights,
              and applicable laws. Except for any technology licensed by Sparck,
              which is owned by and provided by our third-party licensors, all
              Materials contained in the Sparck Services, including without
              limitation the intellectual property rights therein and thereto,
              are the property of Sparck or affiliated companies. All
              trademarks, service marks, and trade names are proprietary to
              Sparck or its affiliates and/or third-party licensors. Except as
              expressly provided herein, nothing in this Agreement shall be
              deemed to create a license in or under any such Materials or the
              intellectual property rights therein or thereto, you agree not to
              sell, license, distribute, copy, modify, publicly perform or
              display, transmit, publish, edit, adapt, create derivative works
              from, or otherwise make unauthorized use of the Materials.
            </p>
            <p>17. Third-Party Sites, Disclaimer of Content Warranties.</p>

            <p>
              17.1. The Sparck Services may call the servers of other web sites
              or services solely at the direction of and as a convenience to
              Users ("Third-party Sites"). Any business relationship, exchange
              of data or other interaction between you and a third party, and/or
              any purchase, download or use by you of any product or service
              offered by a third-party (each, a "Third-Party Interaction"), is
              solely between you and such third-party, and you hereby agree to
              release and hold the Sparck harmless from and will look to such
              third-party with regard to any claims relating to or arising out
              of a Third-Party Interaction.&nbsp; Sparck&nbsp; is not a party
              to, has no involvement or interest in, and has no obligation in
              connection with, any communication, transaction, interaction,
              dispute or any relation whatsoever between you and any third-party
              through the Sparck Services; including, but not limited to,
              interactions related to payment and delivery of items and
              services, and any other terms, conditions, warranties or
              representations associated with such Third-Party
              Interactions.&nbsp; You hereby acknowledge such third-party owns
              all right, title and interest in and to the graphics, logos,
              service marks and trademarks, applications, multimedia content, or
              other information, published on Sparck website and referring to
              such third-party. You further acknowledge and agree you solely are
              responsible for ensuring that your Third-Party Interactions: (i)
              comply with all applicable foreign, federal and state laws and
              regulations; and (ii) do not violate any term, condition, rule,
              procedure, policy or other guideline, published by a third-party
              with which you interact or violate any proprietary rights of such
              third-party. Sparck makes no express or implied warranties with
              regard to the Information, or other material, products, or
              services that are contained on or accessible through Third-party
              Sites. Access and use of Third-Party Sites, including the
              information, material, products, and services on such sites or
              available through such sites, is solely at your own risk.
            </p>
            <p>
              17.2. YOU ACKNOWLEDGE THAT SPARCK DOES NOT MANAGE OR CONTROL THE
              USER PROVIDED INFORMATION THAT YOU ACCESS, STORE OR DISTRIBUTE
              THROUGH THE SPARCK SERVICES, AND ACCEPTS NO RESPONSIBILITY OR
              LIABILITY FOR THAT INFORMATION REGARDLESS OF WHETHER SUCH USER
              PROVIDED INFORMATION IS TRANSMITTED TO OR BY YOU IN BREACH OF
              THESE TERMS. SPARCK MAKES NO WARRANTY WITH RESPECT TO SUCH USER
              PROVIDED INFORMATION YOU MAY ACCESS, STORE OR DISTRIBUTE THROUGH
              THE SPARCK SERVICES. IN PARTICULAR, WITHOUT LIMITING THE
              GENERALITY OF THE FOREGOING, SPARCK MAKES NO WARRANTY THAT SUCH
              USER PROVIDED INFORMATION WILL BE FREE OF ANY VIRUS, WORM, TROJAN
              HORSE, EASTER EGG, TIME BOMB, CANCELBOT, OR OTHER DESTRUCTIVE OR
              MALICIOUS CODE OR PROGRAMS. YOU AGREE TO WAIVE, AND HEREBY DO
              WAIVE, ANY LEGAL OR EQUITABLE RIGHTS OR REMEDIES YOU HAVE OR MAY
              HAVE AGAINST SPARCK WITH RESPECT TO THIRD-PARTY AND/OR USER
              PROVIDED INFORMATION THAT YOU CHOOSE TO ACCESS, STORE OR
              DISTRIBUTE, THROUGH THE SPARCK SERVICES.
            </p>

            <p>
              18. Security and Privacy Settings. We have implemented
              commercially reasonable technical and organizational measures
              designed to secure your personal information from accidental loss
              and from unauthorized access, use, alteration or disclosure.
              However, we cannot guarantee that unauthorized third parties will
              never be able to defeat those measures or use your personal
              information for improper purposes. You understand that internet
              technologies have the inherent potential for disclosure. You
              acknowledge that you are under no obligation to provide personal
              or sensitive information in order to use the Sparck Services, and
              that you provide any personal or sensitive information at your own
              risk.
            </p>
            <p>19. Disclaimers; No Warranties.</p>

            <p>
              19.1. THE SPARCK SERVICES AND ANY THIRD-PARTY OR USER PROVIDED
              INFORMATION, SOFTWARE, SERVICES, OR APPLICATIONS MADE AVAILABLE IN
              CONJUNCTION WITH OR THROUGH THE SPARCK SERVICES ARE PROVIDED "AS
              IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND EITHER
              EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO
              APPLICABLE LAW, SPARCK, ITS SUPPLIERS, LICENSORS, AND PARTNERS
              DISCLAIM ALL WARRANTIES, STATUTORY, EXPRESS OR IMPLIED, INCLUDING,
              BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
              FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF PROPRIETARY
              RIGHTS.
            </p>
            <p>
              19.2. SPARCK, ITS SUPPLIERS, LICENSORS, AND PARTNERS DO NOT
              WARRANT THAT THE FUNCTIONS CONTAINED IN THE SPARCK SERVICES WILL
              BE UNINTERRUPTED OR ERROR-FREE, THAT THE SPARCK SERVICES WILL MEET
              YOUR REQUIREMENTS, THAT DEFECTS WILL BE CORRECTED, OR THAT THE
              SPARCK SERVICES OR THE SERVER THAT MAKES THEM AVAILABLE IS FREE OF
              VIRUSES OR OTHER HARMFUL COMPONENTS.
            </p>
            <p>
              19.3. SPARCK, ITS SUPPLIERS, LICENSORS, AND PARTNERS DO NOT
              WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE
              RESULTS OF THE USE OF THE SPARCK SERVICES IN TERMS OF CORRECTNESS,
              ACCURACY, RELIABILITY, OR OTHERWISE. YOU (AND NOT SPARCK NOR ITS
              SUPPLIERS, LICENSOR OR PARTNERS) ASSUME THE ENTIRE COST OF ANY
              NECESSARY SERVICING, REPAIR, OR CORRECTION. YOU UNDERSTAND AND
              AGREE THAT YOU DOWNLOAD OR OTHERWISE OBTAIN THIRD-PARTY OR USER
              PROVIDED INFORMATION, MATERIAL, OR DATA THROUGH THE USE OF THE
              SPARCK SERVICES AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL
              BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR
              LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH THIRD-PARTY OR
              SUBSCRIBER PROVIDED INFORMATION, MATERIAL, OR DATA. SPARCK WILL
              NOT BE RESPONSIBLE OR LIABLE FOR THE DELETION, CORRECTION,
              DESTRUCTION, DAMAGE, LOSS, OR FAILURE TO STORE OR MAINTAIN ANY
              THIRD-PARTY OR USER PROVIDED INFORMATION.
            </p>
            <p>
              19.4. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED
              WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF
              THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS,
              EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT
              HAVE ADDITIONAL RIGHTS.
            </p>
            <p>
              19.5. At all times, the Sparck Services will be rendered by Sparck
              for you and not for any other party. Sparck's views expressed
              therein are for the sole purpose of assisting you in evaluating
              and making decisions regarding various potential courses of action
              or opportunities. Sparck's views are not a recommendation of any
              particular course of action and are not intended to induce any
              party to take any action. You and your end users should make an
              independent determination of the course of action to pursue based
              on your own analysis of all available information including the
              advice of other third parties.&nbsp; Information obtained by
              Sparck from you or third parties on your behalf has been assumed
              to be accurate and complete and Sparck shall rely on and will not
              independently verify it.&nbsp; Sparck's views expressed in the
              results of the Sparck Services are based on various assumptions
              that may or may not prove to be correct and on information made
              available to us as of, the date thereof.&nbsp;
            </p>
            <p>
              Neither Sparck, nor any of its subsidiaries or affiliates or any
              third party that may promote the Sparck Services or provide a link
              to the Sparck Services, shall be liable for any information
              provided to you via the Sparck Services.&nbsp; You acknowledge
              that your reliance on any information provided by Sparck via the
              Sparck Services is solely at your own risk and you assume full
              responsibility for all risk associated therewith.
            </p>

            <p>20. Limitation of Liability.</p>

            <p>
              20.1. UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO,
              NEGLIGENCE, WILL SPARCK OR ITS AFFILIATES, CONTRACTORS, EMPLOYEES,
              AGENTS, OR THIRD-PARTY PARTNERS, LICENSORS, OR SUPPLIERS BE LIABLE
              FOR ANY SPECIAL, INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE,
              RELIANCE, OR EXEMPLARY DAMAGES (INCLUDING WITHOUT LIMITATION
              LOSSES OR LIABILITY RESULTING FROM LOSS OF DATA, LOSS OF REVENUE,
              ANTICIPATED PROFITS, OR LOSS OF BUSINESS OPPORTUNITY) THAT RESULT
              FROM YOUR USE OR YOUR INABILITY TO USE THE INFORMATION OR
              MATERIALS ON THE SPARCK SERVICES, OR ANY OTHER INTERACTIONS WITH
              SPARCK, EVEN IF SPARCK OR A SPARCK- AUTHORIZED REPRESENTATIVE HAS
              BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. APPLICABLE LAW
              MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR
              INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR
              EXCLUSION MAY NOT APPLY TO YOU. IN SUCH CASES, SPARCK'S LIABILITY
              WILL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
            </p>
            <p>
              20.2. IN NO EVENT WILL SPARCK'S OR ITS AFFILIATES', CONTRACTORS',
              EMPLOYEES', AGENTS', OR THIRD-PARTY PARTNERS', LICENSOR'S, OR
              SUPPLIERS' TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND
              CAUSES OF ACTION ARISING OUT OF OR RELATING TO THESE TERMS OR YOUR
              USE OF THE SPARCK SERVICES, INCLUDING WITHOUT LIMITATION YOUR
              INTERACTIONS WITH OTHER USERS, (WHETHER IN CONTRACT, TORT
              INCLUDING NEGLIGENCE, WARRANTY, OR OTHERWISE) EXCEED THE AMOUNT
              PAID BY YOU, IF ANY, FOR ACCESSING THE SPARCK SERVICES DURING THE
              TWELVE (12) MONTHS IMMEDIATELY PRECEDING THE DAY THE ACT OR
              OMISSION OCCURRED THAT GAVE RISE TO YOUR CLAIM OR ONE HUNDRED
              DOLLARS, WHICHEVER IS GREATER.
            </p>
            <p>
              20.3. YOU ACKNOWLEDGE AND AGREE THAT SPARCK HAS OFFERED ITS
              PRODUCTS AND SERVICES, SET ITS PRICES, AND ENTERED INTO THESE
              TERMS IN RELIANCE UPON THE DISCLAIMERS OF WARRANTY AND THE
              LIMITATIONS OF LIABILITY SET FORTH HEREIN, THAT THE DISCLAIMERS OF
              WARRANTY AND THE LIMITATIONS OF LIABILITY SET FORTH HEREIN REFLECT
              A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN THE PARTIES
              (INCLUDING THE RISK THAT A CONTRACT REMEDY MAY FAIL OF ITS
              ESSENTIAL PURPOSE AND CAUSE CONSEQUENTIAL LOSS), AND THAT THE
              DISCLAIMERS OF WARRANTY AND THE LIMITATIONS OF LIABILITY SET FORTH
              HEREIN FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN YOU AND
              SPARCK.
            </p>

            <p>
              21. Location of the Sparck Services. The Sparck Services are
              controlled and operated from our facilities in the United States.
              Sparck makes no representations that the Sparck Services are
              appropriate or available for use in other locations. Those who
              access or use the Sparck Services from other jurisdictions do so
              at their own volition and are entirely responsible for compliance
              with local law, including but not limited to export and import
              regulations. You may not use the Sparck Services if you are a
              resident of a country embargoed by the United States, or are a
              foreign person or entity blocked or denied by the United States
              government. Unless otherwise explicitly stated, all materials
              found on the Sparck Services are solely directed to individuals,
              companies, or other entities located in the U.S. By using the
              Sparck Services, you are consenting to have your personal data
              transferred to and processed in the United States.
            </p>
            <p>
              22. Indemnification; Hold Harmless. You agree to defend, indemnify
              and hold harmless Sparck and its subsidiaries, agents, managers,
              and other affiliated companies, and their employees, contractors,
              agents, officers and directors, from and against any and all
              claims, damages, obligations, losses, liabilities, costs or debt,
              and expenses (including but not limited to attorney's fees)
              arising from: (i) your use of and access to the Sparck Services,
              including any data or work transmitted or received by you; (ii)
              your violation of any term of this Agreement, including without
              limitation, your breach of any of the representations and
              warranties above; (iii) your violation of any third-party right,
              including without limitation any right of privacy, publicity
              rights or intellectual property rights; (iv) your violation of any
              law, rule or regulation of the United States or any other country;
              (v) any claim or damages that arise as a result of any of your
              User Provided Information or any other data that are submitted via
              your account; or (vi) any other party's access and use of the
              Service with your unique username, password or other appropriate
              security code. Sparck will have the right to control the defense,
              settlement, adjustment or compromise of any such claims, actions
              or proceedings by using counsel selected by Sparck. Sparck will
              use reasonable efforts to notify you of any such claims, actions,
              or proceedings upon becoming aware of the same.
            </p>
            <p>23. Miscellaneous.</p>

            <p>
              23.1. Notice. Sparck may provide you with notices, including those
              regarding changes to Sparck's terms and conditions, by email,
              regular mail or postings on the Sparck Services. Notice will be
              deemed given twenty-four hours after email is sent, unless Sparck
              is notified that the email address is invalid. Alternatively, we
              may give you legal notice by mail to a postal address, if provided
              by you through the Sparck Services. In such case, notice will be
              deemed given three days after the date of mailing. Notice posted
              on the Sparck Services is deemed given five days following the
              initial posting.
            </p>
            <p>
              23.2. Waiver. The failure of Sparck to exercise or enforce any
              right or provision of these Terms will not constitute a waiver of
              such right or provision. Any waiver of any provision of these
              Terms will be effective only if in writing and signed by Sparck.
            </p>
            <p>
              23.3. Governing Law. These Terms will be governed by and construed
              in accordance with the laws of the State of California, without
              giving effect to any principles of conflicts of law.
            </p>
            <p>
              23.4. Jurisdiction. You agree that any action at law or in equity
              arising out of or relating to these Terms or Sparck will be filed
              only in the state or federal courts in and for Sacramento County,
              California, and you hereby consent and submit to the personal and
              exclusive jurisdiction of such courts for the purposes of
              litigating any such action.
            </p>
            <p>
              23.5. Severability. If any provision of these Terms or any
              Guideline is held to be unlawful, void, or for any reason
              unenforceable, then that provision will be limited or eliminated
              from these Terms to the minimum extent necessary and will not
              affect the validity and enforceability of any remaining
              provisions.
            </p>
            <p>
              23.6. Assignment. These Terms and related Guidelines, and any
              rights and licenses granted hereunder, may not be transferred or
              assigned by you, but may be assigned by Sparck without
              restriction.
            </p>
            <p>
              23.7. Survival. Upon termination of these Terms, any provision
              which, by its nature or express terms should survive, shall
              survive such termination or expiration, including, but not limited
              to, Sections 4-6, and 8 through 22 herein.
            </p>
            <p>
              23.8. Headings. The heading references herein are for convenience
              purposes only, do not constitute a part of these Terms, and will
              not be deemed to limit or affect any of the provisions hereof.
            </p>
            <p>
              23.9. Entire Agreement. This is the entire agreement between you
              and Sparck relating to the subject matter herein and will not be
              modified except in a writing, signed by both parties, or by a
              change to these Terms or Guidelines made by Sparck as set forth in
              Section 3 above.
            </p>
            <p>
              23.10. Claims. YOU AND COMPANY AGREE THAT ANY CAUSE OF ACTION
              ARISING OUT OF OR RELATED TO THE COMPANY SERVICES MUST COMMENCE
              WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE,
              SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
            </p>
            <p>
              23.11. Disclosures. The services hereunder are offered by
              SparckCo, Inc., located at: 1007 7th Street, 5th Floor,
              Sacramento, CA 95814 and email:{' '}
              <a href='mailto:tos@sparckco.com' rel=' noopener'>
                tos@sparckco.com
              </a>
              . If you are a California resident, you may have this same
              information emailed to you by sending a letter to the foregoing
              address with your email address and a request for this
              information.
            </p>
            <p>
              23.12. Publicity. You hereby consent to Sparck's use of your name
              and logo in Sparck's marketing and promotional materials and on
              the Sparck website.
            </p>
            <p>&nbsp;</p>
            <p>
              <em>These Terms were last modified on June 29, 2020.</em>
            </p>
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setDialogOpen(false)}
          color='primary'
          data-cy='close_terms_and_conditions'
        >
          {t('components.termsAndConditions.okButton')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
