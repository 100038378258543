import { Box, Grid, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { format, parseISO } from 'date-fns'

import { SurveyStatus } from 'enums/survey'
import { t } from 'i18n'

type ProgressBarBulletProps = {
  filled: boolean
}

const ProgressBarBullet = styled('div')<ProgressBarBulletProps>(
  ({ theme, filled }) => ({
    width: '20px',
    height: '20px',
    backgroundColor: filled ? theme.palette.primary.main : '#CCC',
    borderRadius: '100%',
    zIndex: 995,
    border: '3px solid #f9f9f9',
  }),
)

export default function SurveyOverviewProgress({ selectedSurvey }: any) {
  const { status, created_at, open_at, close_at } = selectedSurvey

  const percentage =
    status === SurveyStatus.Scheduled ||
    status === SurveyStatus.AwaitingManualOpening
      ? 8
      : status === SurveyStatus.Open
      ? 56
      : 100

  return (
    <Grid container alignItems='center' style={{ maxWidth: 500 }}>
      <Grid item xs={12}>
        <Grid
          container
          alignItems='center'
          justifyContent='space-between'
          style={{ position: 'relative' }}
        >
          <Box
            component='div'
            sx={{
              position: 'absolute',
              width: '95%',
              height: '6px',
              backgroundColor: '#ddd',
              margin: '0 auto',
              left: '0px',
              right: '0px',
            }}
          >
            <Box
              component='div'
              sx={{
                position: 'absolute',
                width: `${percentage}%`,
                transition: 'width 0.5s',
                height: '100%',
                backgroundColor: 'primary.main',
              }}
            />
          </Box>

          <ProgressBarBullet filled />
          <ProgressBarBullet
            filled={
              status === SurveyStatus.Open || status === SurveyStatus.Close
            }
          />
          <ProgressBarBullet filled={status === SurveyStatus.Close} />
        </Grid>
        <Box mt={1} width={1}>
          <Grid container alignItems='center' justifyContent='space-between'>
            {[
              {
                label: t('surveyProgress.bulletLabels.createdOn'),
                value: format(parseISO(created_at), 'MMM do yyyy'),
              },
              {
                label: t('surveyProgress.bulletLabels.openOn'),
                value:
                  status === SurveyStatus.Scheduled ||
                  status === SurveyStatus.AwaitingManualOpening
                    ? t('statuses.pending')
                    : open_at
                    ? format(parseISO(open_at), 'MMM do yyyy')
                    : '--',
              },
              {
                label:
                  status !== 'close'
                    ? t('surveyProgress.bulletLabels.willCloseOn')
                    : t('surveyProgress.bulletLabels.closedOn'),
                value: close_at
                  ? format(parseISO(close_at), 'MMM do yyyy')
                  : 'Open Indefinitely',
              },
            ].map((legend: any, index: number) => (
              <Grid item key={index}>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  align={
                    index === 2 ? 'right' : index === 1 ? 'center' : 'left'
                  }
                >
                  {legend.label}
                </Typography>
                <Typography
                  variant='body1'
                  color='text.primary'
                  fontWeight={600}
                  align={
                    index === 2 ? 'right' : index === 1 ? 'center' : 'left'
                  }
                >
                  {legend.value}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}
