import { Grid, Typography } from '@mui/material'

import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowBack as ArrowBackIcon,
} from '@mui/icons-material'

const getDifference = (value, previousValue) =>
  value - (previousValue || 0).toFixed()

export default function PreviousValueComparison({
  currentValue,
  previousValue,
  positiveValueColor = 'primary.main',
  negativeValueColor = 'error.main',
  noChangeValueColor = 'secondary.light',
  arrowSize = '20px',
  textFontSize = '14px'
}) {
  const difference = getDifference(currentValue, previousValue)

  const color = difference >= 0 ? (difference === 0) ? noChangeValueColor : positiveValueColor : negativeValueColor

  // if (!previousValue || Number.isNaN(difference) || !difference) return null

  return (
    <Typography
      variant='body1'
      component='span'
      color={color}
      fontWeight={600}
      style={{
        position: 'absolute',
        margin: '0 auto',
        top: 0,
        bottom: 0,
        left: '90%',
        minWidth: '5em',
      }}
    >
      <Grid container component='span'>
        <Grid item xs='auto'>
          {difference >= 0 ? (difference === 0) ? <ArrowBackIcon style={{width: arrowSize, height: arrowSize}}/> : <ArrowUpwardIcon style={{width: arrowSize, height: arrowSize}} /> : <ArrowDownwardIcon style={{width: arrowSize, height: arrowSize}} />}
        </Grid>

        <Grid item xs='auto' style={{fontSize: textFontSize}}>
          {difference >= 0 ? (difference === 0) ? '' : '+' : '-'}
          {Math.abs(difference)}
        </Grid>
      </Grid>
    </Typography>
  )
}
