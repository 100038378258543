import { Grid, Typography } from '@mui/material'

export default function MFADisableDialogContent({ description }) {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant='body1' fontWeight={600} paragraph>
          {description}
        </Typography>
      </Grid>
    </>
  )
}
