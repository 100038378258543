export default {
  beheardReport: {
    reportBeingGenerated: 'Your #BeHeard Report is being generated...',
    title: {
      report: '#BeHeard {type} Report',
      premium: 'Premium',
      free: 'Free',
    },
    shortTermQuestion:
      'The chances of leaving my job voluntarily during the next year are Extremely Low.',
    longTermQuestion:
      'I feel that I will have a long career at this organization.',
    sectionTitles: {
      overview: 'OVERVIEW',
      characteristics: 'CHARACTERISTICS',
      participation: 'PARTICIPATION',
      results: 'RESULTS',
      topFiveRecommendations: 'TOP 5 RECOMMENDATIONS',
      employeeTurnoverForecast: 'EMPLOYEE TURNOVER FORECAST',
      comparisonGraphs: 'COMPARISON GRAPHS',
      fullReport: 'FULL REPORT',
      customQuestions: 'CUSTOM QUESTIONS',
    },
    navigationTitles: {
      overview: 'Overview',
      characteristics: 'Characteristics',
      results: 'Results',
      topFiveRecommendations: 'Top 5 Recommendations',
      employeeTurnoverForecast: 'Turnover',
      comparisonGraphs: 'Comparison Graphs',
      fullReport: 'Full Report',
      customQuestions: 'Custom Questions',
    },
    overview: {
      overviewDescription:
        'Your #BeHeard Premium Report provides much more than an employee engagement score. It provides key findings about the extent your employees feel passionate about their jobs, are committed to your organization, put discretionary effort into their work and are willing to refer. That’s because employee engagement relates directly to productivity, revenue, absenteeism, turnover, customer satisfaction and other organization insights. In addition to your overall employee engagement score, this report provides results on five engagement drivers, forecasts short and long-term turnover, and offers customized recommendations for taking action to increase engagement.',
    },
    characteristics: {
      description:
        'Before you dive into the results, let’s start by looking at the key characteristics of engaged and disengaged employees. This is why taking action to build employee engagement matters!',
      engagedEmployees: { title: 'Engaged Employees' },
      disengagedEmployees: { title: 'Disengaged Employees' },
    },
    participation: {
      overallRate: 'Overall Rate',
      description:
        'Click on each demographic filter below to view specific participation rates.',
      noParticipation: 'Check back, no one has participated yet.',
      demographicsWithLessThanFiveParticipants:
        'Demographics that have less than 5 participants for a particular value will show N/A. (e.g., 4 participants in the IT Department will show N/A)',
    },
    results: {
      description:
        'This section provides a snapshot of your engagement results including the Top 5 Action Areas, the Top 5 Strengths, and the Top 5 Most Important Items to your employees.',
      engagementScore: { engagement: 'Engagement', score: 'Score' },
      engagementDriversHeader: 'Engagement Driver Scores',
      effectiveness: 'EFFECTIVENESS',
    },
    progressBarLegend: {
      legendTitle: 'Effectiveness Score Legend',
      colorMeaning: {
        negative: 'Unfavorable',
        neutral: 'Neutral',
        positive: 'Favorable',
      },
    },
    driverScore: {
      alignment:
        'The extent to which employees believe in and put effort toward this organization’s mission, values and goals.',
      engagement:
        'The extent to which employees feel passionate about their jobs, are committed to our organization, put discretionary effort into their work and are willing to refer.',
      fit: 'The extent to which employees feel they are in the right jobs and have the necessary skills, knowledge and abilities to adequately perform in their roles.',
      growth:
        'The extent to which employees feel supported to advance their career or develop personal skill sets within this organization.',
      team: 'The extent to which this organization promotes a team-based work style (i.e. departments or groups work well together).',
      valuing:
        'The extent to which employees feel valued by this organization.',
      custom_ratings_questions: 'Custom Ratings Questions',
    },
    topFiveText: {
      action: {
        title: 'Top 5 Action Areas',
        description:
          'Your employees ranked the following statements as low in effectiveness and high in importance (i.e. these items have the biggest ROI Gap). You will get the greatest return on investment if you focus your engagement efforts on these five areas (listed in order of priority).',
      },
      strength: {
        title: 'Top 5 Strengths',
        description:
          'Your employees ranked the following statements as high in effectiveness and high in importance (i.e. these items have the smallest ROI Gap). Use this data to promote your employee brand in talent attraction, engagement and retention strategies. These are your greatest strengths!',
      },
      importance: {
        title: 'Top 5 Most Important Items',
        description:
          'Your employees ranked the following five statements as the most important to them.',
      },
      topFiveResultDetails: {
        effectiveness: 'Effectiveness',
        importance: 'Importance',
        previousEffectiveness: 'Previous Effectiveness',
        benchmarkEffectiveness: 'Benchmark Effectiveness',
      },
      topFiveRecommendations: {
        description:
          'These actionable recommendations are based on your Top 5 Action Areas. This is where your efforts to boost engagement will give you the largest return on investment. Time to take action!',
        noRecommendations:
          'There are no Recommendations for the results displayed.',
      },
      tooltip: { roiGap: 'Difference between effectiveness and importance' },
    },
    filterButtons: { filters: 'Filters', download: 'Download' },
    downloadMenu: {
      pdfNewTab: 'Open in new tab (PDF)',
      pdfDownload: 'PDF',
      xlsxDownload: 'Spreadsheet (.xlsx)',
    },
    noData: 'There are no {title} for the results displayed.',
    turnoverOverviewDescription:
      'The likelihood employees will leave your organization within the next twelve months.',
    turnoverForecast: {
      statement: 'STATEMENT',
      atRisk: 'At Risk',
      passive: 'Passive',
      committed: 'Committed',
      employee: 'employee',
      employees: 'employees',
      potentialTurnover: 'Potential Turnover',
      previousTurnover: 'Previous Turnover',
      description:
        "Your short and long-term outlook is predicted using your employees' responses to the turnover statements in the survey.",
      shortTerm: 'Short-Term Outlook',
      longTerm: 'Long-Term Outlook',
    },
    comparisonGraphs: {
      description:
        'Now it’s time to take a closer look at engagement results across the organization. Simply hover over the bar charts to see specific results. Depending on your permission settings, you can use Filters to change the comparison graphs.',
      demographic: 'Demographic',
      legend: {
        engaged: 'Engaged',
        neutrals: 'Neutral',
        disengaged: 'Disengaged',
      },
    },
    fullReport: {
      engagementScore: 'Engagement Score',
      participationRate: 'Participation Rate',
      legend: {
        strengths: 'Strengths',
        recommendedActionAreas: 'Recommended Action Areas',
      },
      tableHeaders: {
        score: 'Score',
        result: 'Result',
        effectiveness: 'Effectiveness',
        importance: 'Importance',
        previousEffectiveness: 'Prev. Effec.',
        benchmark: 'Benchmark',
      },
      tableData: { viewRecommendations: 'View recommendations here' },
      openEndedQuestions: 'Open-Ended Questions',
      noCustomRatingQuestions:
        'No custom ratings questions were used in this survey.',
      noCustomOpenEndedQuestions:
        'No custom open-ended questions were used in this survey.',
      buttonLabels: {
        xlsx: 'Download answers as XLSX',
        pdf: 'Download answers as PDF',
      },
    },
    unlockPremiumReport: 'CLICK HERE TO UNLOCK THE PREMIUM REPORT',
    snackbar: {
      success: {
        surveyAddedToCart: 'Survey added to your cart successfully',
        filters: 'Your filters have been applied!',
      },
    },
  },
  report: {
    upgradeReport: 'Upgrade Report',
    freeReport: 'Free Report',
    premiumReport: 'Premium Report',
    shareReport: 'Share Report',
  },
  survey: {
    status: {
      scheduled: 'Scheduled',
      open: 'Open',
      closed: 'Closed',
      awaitingManualOpening: 'Awaiting Manual Launch',
    },
    snackbar: {
      success: {
        alreadyFinished: 'You already finished this survey',
        finishedSurvey: 'You finished the survey! Thanks for taking the time',
        giftCardRedeemed:
          'Gift Card redeemed successfully! Watch for an email with your electronic gift card link.',
      },
    },
  },
  surveyKey: {
    status: {
      surveykey: 'Survey Key',
      paragraph1:
        'De enquête gebruikt gezichten en sterren voor beoordeling.  Beoordeel uw werkplekervaring en hoe belangrijk deze is voor elke verklaring. Tevredenheid is gebaseerd op gezichten en belangrijkheid is gebaseerd op sterren van 1 tot 5. ',
      paragraph2: 'Beoordeel uw ervaring met het gebruik van ons product.',
      title1: 'Satisfaction',
      title2: 'Importance',
    },
  },
  surveyinstruction: {
    surveyinstructionkey: 'Instructies voor enquêtes',
    surveytitle: ' Deze enquête heeft een',
    twopart: 'Tweedelig beoordelingsproces',
    using: 'voor elke instructie met behulp van',
    faces: 'Gezichten',
    and: 'en',
    star: 'Sterren.',
    rate: 'Beoordeel hoeveel u',
    agree: 'Eens of oneens',
    statement: 'bij elke instructie met behulp van de',
    then: 'en dan',
    how: 'hoe',
    important: 'belangrijk',
    eachstatement:
      'Elke verklaring is voor u op de werkplek met behulp van een',
    starrating: '5-sterrenbeoordeling',
    scale: 'schub.',
  },
  surveyDialogs: {
    title: 'efterforskning',
    addSurvey: {
      descriptionFirstLine:
        'It’s time to make the survey your own. First, select your launch timeline below to get started. You can make changes before the survey opens and extend the survey before it closes. Click',
      link: 'HERE',
      descriptionSecondLine:
        'for best practices to launch and market the survey.',
    },
    editSurvey: {
      description:
        'Need to edit your survey? No problem. You can do that here. Please make your changes below.',
    },
    extendSurvey: { title: 'Extend Survey', closeDate: 'Close Date' },
    confirmClose: {
      title: 'Close Survey',
      actionLabel: 'Close Survey',
      close: 'close',
    },
    confirmDelete: { title: 'Remove Survey', actionLabel: 'Archive Survey' },
    formLabels: {
      surveyName: 'Survey Name',
      launchDate: 'Launch Date',
      closeDate: 'Close Date',
      allowReward: 'Reward survey participation with a Gift Card',
      allowRewardTooltip:
        'Employees will be able to select their gift card directly from our system after they participate',
      allowAnswersToBeUpdated:
        'Give employees permission to edit their survey responses',
      allowAnswersToBeUpdatedTooltip:
        'Edits can only be made when the survey is open',
      rewardAmount: 'Gift Card Amount',
    },
    customizedQuestions: {
      header: 'Add Custom Questions/Statements',
      description:
        'In addition to the 38 questions in our statistically valid #BeHeard engagement survey, you can add up to five 5-star rating questions and five open ended questions. If you want some ideas for your custom questions, check out examples',
      link: 'HERE',
      statement: 'Statement',
      statementPlaceholder: 'Type your question/statement...',
      fiveStarQuestions: { header: '5-Star Rating Questions' },
      openEndedQuestions: { header: 'Open-Ended Questions' },
    },
  },
  surveyLanding: {
    welcomeToSparck: 'Welkom bij Sparck!',
    areYouReady: 'Ben je klaar om {beheard}?',
    thankYou: 'Bedankt dat je je stem hebt laten horen.',
    noActiveSurveys:
      "There is no active survey to complete right now, but we're glad you checked in.",
    whatBeHeardSurveyIs:
      "De #BeHeard-enquête is een anonieme enquête die is ontworpen om u een veilige plek te bieden om uw feedback over de werkplek te delen. Uw organisatie geeft om wat u denkt en wil u een vertrouwelijke stem geven om vrijuit te spreken, daarom zijn zij een partnerschap aangegaan met Sparck! Aan het einde worden alleen de samengevoegde resultaten gedeeld om de organisatorische strategieën voor de toekomst vorm te geven op basis van de gebieden die het meest voor u en uw collega's betekenen.",
    youAreAwesome:
      'Je bent geweldig! Bedankt dat je de tijd hebt genomen om te #BeHearden. Uw feedback is onbetaalbaar.',
    feedbackImportance:
      "Your feedback is really important to us, so we'll send you an email as soon as a new survey is posted!",
    eachItemExplanation:
      'Het invullen van de enquête duurt slechts ongeveer 10 minuten. Elk item bestaat uit twee delen: ten eerste beoordeelt u in hoeverre u het eens of oneens bent met elke werkplekverklaring door een van de gezichten te kiezen. Als je dat eenmaal hebt gedaan, geef je met behulp van de sterren aan hoe belangrijk elke uitspraak voor je is. Bekijk het geanimeerde voorbeeld!',
    iAmReady: 'Ik ben klaar om de enquête in te vullen!',
    updateMyAnswers: 'Review my answers!',
    surveyPagination: { previous: 'Vorige', nextPage: 'Volgende pagina' },
    submitSurvey: 'Vragenlijst versturen',
    pendingGiftCards: 'You have {count} pending Gift Cards! 🎉',
    pendingGiftCardLabel: '{rewardValue} for {surveyName}',
    redeem: 'Redeem',
    giftCardRedeemed: 'Gift Card redeemed successfully!',
    departmentTerm: 'Afdeling',
    departmentValue: ' ',
    missionAndValueTerm: 'Missie en waarden',
    missionAndValueText:
      'We bouwen iets speciaals op en focussen op het ontsluiten van de beste uitkomsten voor onze klanten door middel van intelligente integratie en automatisering',
    missionAndValueValue: 'Waarden: Wees ondernemend. Wees jezelf. Wees Boomi.',
    managerTerm: 'Manager',
    managerValue: 'Je direct leidinggevende met ingang van 8 juni 2023',
    seniorLeadershipTeamTerm: 'Senior leadershipteam',
    seniorLeadershipTeamValue: 'Uitvoerend leadershipteam',
    surveyInstructionsTitle: 'Survey Instructions',
    surveyInstructionsContent:
      'This survey has a two-part rating process for each statement using Faces  and Stars.  Rate how much you agree or disagree with each statement using the faces and then how important each statement is to you in the workplace using a 5-star rating scale.',
    boomiSurveyLandingContent:
      'De #BeHeard-enquête is anoniem en is een veilige plek om je feedback over je ervaring bij Boomi te delen. Boomi geeft om wat je denkt en wil je een vertrouwelijke stem geven zodat je vrijuit kunt spreken. Daarom werkt Boomi samen met Sparck! Alleen de bijeengevoegde resultaten worden aan het eind gedeeld om toekomstige organisatorische strategieën vorm te geven op basis van welke gebieden voor jou en jouw collega’s het meest betekenen bij Boomi. Voor je begint, zijn hier enkele omschrijvingen om iedereen op één lijn te brengen:',
    boomiSurveyLandingContentTiming:
      'De enquête neemt ongeveer 15 minuten in beslag. Elk punt bestaat uit twee delen. Eerst geef je aan in welke mate je het eens of oneens bent met elke uitspraak over de werkplek. Dit doe je door een van de gezichtjes te kiezen. Daarna geef je aan hoe belangrijk elke uitspraak voor je is. Dit doe je met de sterretjes. Bekijk het animatievoorbeeld!',
    topicCards: {
      secure: {
        title: 'Uw gegevens zijn {keyword}',
        keyword: 'Veilig',
        description:
          'Wij nemen gegevensbescherming zeer serieus. Sparck maakt gebruik van een set technologieën en protocollen die datalekken en cyberaanvallen voorkomen.',
      },
      anonymous: {
        title: 'Deelname is {keyword}',
        keyword: 'Anoniem',
        description:
          'Geheimhouding is erg belangrijk voor ons! Uw organisatie of leidinggevende krijgt nooit uw individuele antwoorden te zien. De gegevens die we verstrekken zijn strikt anoniem.',
      },
      giftCard: {
        title: 'This Survey Has a {keyword}!',
        keyword: 'Gift Card',
        description:
          'That’s right! As a “Thank You” for completing the survey, your organization is treating you to a giftcard of your choice. You can redeem it at the end. Enjoy!',
      },
      answersUpdate: {
        title: '{keyword} Your Answers',
        keyword: 'Update',
        description:
          'Great news! You can come back to update and edit your answers at any point while the survey is still open. Just log back in!',
      },
    },
  },
  surveyOverview: {
    snackbarSuccess: {
      openSurvey: 'The survey is now open!',
      extendedSurvey: 'The survey close date has been extended successfully!',
      closedSurvey: 'The survey has been closed!',
      deletedSurvey: 'The survey has been archived!',
      employeesInvited: 'Employees have been invited successfully!',
      addedToCart: 'Survey added to your cart successfully',
    },
    selectLabel: 'Survey',
    minimumNumberOfEmployees:
      'Your organization must have at least 5 employees to create a new survey.',
    whenSurveyCloses:
      'When your survey closes the #BeHeard results will be immediately available.',
    scheduledLaunchSurveyInfo:
      "Your survey isn't live yet.  It will Launch Automatically on {launchDate}. If you would like to launch it manually you can click the Launch Survey button or edit your survey to Launch Manually.",
    manualLaunchSurveyInfo:
      "Your survey isn't live yet and it is set to Launch Manually. When you are ready to launch your survey, click the Launch Survey button.",
    newSurvey: 'New Survey',
    launchSurvey: 'Launch Survey',
    editSurvey: 'Edit Survey',
    extendSurvey: 'Extend Survey',
    closeSurvey: 'Close Survey',
    applyUpgrade: 'Apply Upgrade',
    printSurvey: 'Print',
    applyDowngrade: 'Apply Downgrade',
    inviteEmployees: 'Invite Employees',
    deleteSurvey: 'Archive Survey',
    anticipatedTurnover: 'Anticipated Turnover more than 12 Months Away',
    overallParticipation: 'Overall Participation Rate',
    person: 'Person',
    people: 'People',
    employeesInvitation: {
      inviteEmployees: 'Invite Employees',
      chooseEmployees: 'Choose below the employees you want to invite for',
      textFieldLabel: 'Choose one or more employees',
      cancelButton: 'Cancel',
      inviteButton: 'Invite Employees',
    },
    tabs: { labels: { general: 'General', sharedReports: 'Shared Reports' } },
    validations: {
      surveyNameIsRequired: 'Survey Name is required',
      minimumLaunchDate: 'You cannot select a past date',
      maximumLaunchDate: 'Launch Date cannot be after the Close Date',
      launchDateIsRequired: 'Launch Date is required',
      minimumCloseDate: 'Close Date cannot be before Launch Date',
      closeDateIsRequired: 'Close Date is required',
      rewardAmountIsRequired: 'Gift Card amount is required',
    },
  },
  surveyProgress: {
    bulletLabels: {
      createdOn: 'Created on',
      openOn: 'Open on',
      closedOn: 'Closed on',
      willCloseOn: 'Will Close on',
    },
  },
  ratingSection: {
    toWhatExtent: 'In hoeverre bent u het eens of oneens met deze stelling?',
    important: 'Dit is belangrijk voor mij',
    ratingLabels: {
      stronglyDisagree: 'Sterk mee oneens',
      disagree: 'Het oneens zijn',
      neutral: 'Neutrale',
      agree: 'Mee eens zijn',
      stronglyAgree: 'Sterk mee eens',
      notApplicable: 'Niet toepasbaar',
    },
    change: 'Wijziging',
    unansweredItem: 'Please respond to every item before continuing.',
    formLabels: {
      textField: 'Your answer',
      placeholder: 'Type anything you want here...',
      helperText:
        'To ensure your confidentiality, do not put any personally identifiable information.',
    },
  },
  manageShareReport: {
    reportSections: {
      labels: {
        comparisonGraph: 'Comparison Graph',
        results: 'Results',
        topFiveActionAreas: 'Top 5 Action Areas',
        topFiveStrengths: 'Top 5 Strengths',
        topFiveMostImportantItems: 'Top 5 Most Important Items',
        recommendations: 'Recommendations',
        turnoverForecast: 'Employee turnover forecast',
        fullReport: 'Full Report',
        customRatingQuestions: 'Custom Ratings Questions',
        customOpenEndedQuestions: 'Custom Open-Ended Questions',
      },
    },
    previewFor: 'Preview for {name}',
    reportWillBeSharedWithRoles: 'This report will be shared with',
    formHandler: {
      title: 'Share Report',
      labels: {
        name: 'Shared Report Name',
        expirationDate: 'How long is it available?',
        whatToSee: 'What can they see?',
        limitReport: 'Limit Report by Demographics',
        whichRoles: 'Which Roles can see this Share Report?',
        roles: 'Roles',
        whichDemographics: 'Which Demographics will receive this Share Report?',
      },
      placeholders: { beheardReport: '#BeHeard ShareReport 001' },
      dialog: {
        title: 'Warning!',
        content: {
          firstLine:
            "Are you sure you want to give access to your employee's answers?",
          secondLine:
            'The answers contain very sensitive information. Please be absolutely sure that you selected the correct demographics for this action.',
        },
        buttonText: 'I understood!',
      },
    },
    snackbars: { invitesSent: 'Invites sent successfully' },
  },
  inviteShareReport: {
    dialogTitle: 'Invite for Share Report',
    dialogActionLabel: 'Share this report',
    clickToInvite:
      'Your Shared Report was created but you need to invite. Click "Share this report" to send e-mails to your invitees',
  },
  shareReport: {
    title: 'Share Reports',
    description: 'You control who has access to your #BeHeard Premium Reports',
    columnHeaders: {
      sharedReport: 'Shared Report Name',
      surveyName: 'Survey Name',
      expires: 'Expires',
    },
    validations: {
      minimumExpirationDate: 'You cannot select a past date',
      expirationDateIsRequired: 'Expiration Date is required',
    },
  },
}
