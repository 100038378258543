import { Button, Checkbox, FormControlLabel, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ClearIcon from '@mui/icons-material/Clear';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { API } from 'core/requests';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { useCompany } from 'contexts';
import { DialogTeleport, MoneyInput } from 'components';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { budgetLoadingInitialState, budgetAddBackFormInitialState, budgetAccountHolderTypes } from 'constants/budget';

type LoadingTypes = {
    addBankLoading: boolean;
    addAmountLoading: boolean;
};

const validationSchema = yup.object({
    account_holder_name: yup.string().required("This field is Required"),
    account_holder_type: yup.string().required("This field is Required"),
    account_number: yup.number().required("This field is Required"),
    routing_number: yup.number().required("This field is Required"),
    amount: yup.number().min(1).required("This field is Required"),
    save_bank: yup.boolean(),
});

const ManageAddBankAccount = ({ openAddBank, setOpenAddBank, stripePromise, getCurrentTransaction, walletMoney, setWalletMoney }) => {

    const { enqueueSnackbar } = useSnackbar()
    const { company }: any = useCompany()
    const [showBank, setShowBank] = useState<any>(false);
    const [bankAccounts, setBankAccounts] = useState<any>({});
    const [openAddAmount, setOpenAddAmount] = useState(false);
    const [loading, setLoading] = useState<LoadingTypes>(budgetLoadingInitialState);

    const handleGenerateToken = async (bankAccountData) => {
        try {
            if (!stripePromise) {
                return { error: 'Stripe promise required' };
            }

            const { token, error } = await stripePromise.createToken('bank_account', bankAccountData);

            if (token) {
                // Use the generated token for payment or further processing
                return { token: token }
            } else {
                // Handle the error
                throw error;
            }
        } catch (error) {
            return { error: error }
        }

    };

    const { mutate: refetchBankAccounts } = useMutation(() => API.get('getBankAccountDetails'), {
			onSuccess: (response: any) => { !response?.errors && setBankAccounts(response) }
    })

		let payloadTmp:any = {};
		const { mutate: addMoneyACH } = useMutation((payload: any) => {
			payloadTmp = payload;
			return API.create('addMoneyACH', payload);
		}, {
			onSuccess: (response: any) => {
				if (!response?.errors) {
					
					if(payloadTmp.save_bank){
						enqueueSnackbar('Bank account added successfully.',
							{
									variant: 'success',
							},
						);
					}
					
					enqueueSnackbar('Funds added successfully.',
						{
								variant: 'success',
						},
					);

					setLoading(budgetLoadingInitialState);
					getCurrentTransaction();
					refetchBankAccounts();
					formik.resetForm();
					setWalletMoney(1.0);
					setOpenAddBank(false);
				} else setLoading(budgetLoadingInitialState);
			},
		});

    // const { mutate: addMoneyACH } = useMutation((payload: any) => API.create('addMoneyACH', payload), {
    //     onSuccess: (response: any) => {
    //         if (!response?.errors) {
    //             enqueueSnackbar('Bank account added successfully.',
    //                 {
    //                     variant: 'success',
    //                 },
    //             );
    //             enqueueSnackbar('Success! Your account has been updated with the added funds.',
    //                 {
    //                     variant: 'success',
    //                 },
    //             );
    //             setLoading(budgetLoadingInitialState);
    //             getCurrentTransaction();
    //             refetchBankAccounts();
    //             formik.resetForm();
    //             setWalletMoney(1.0);
    //             setOpenAddBank(false);
    //         } else setLoading(budgetLoadingInitialState);
    //     },
    // })

    const { mutate: addFundInBank } = useMutation((payload: any) => API.create('addFundInBank', payload), {
        onSuccess: (response: any) => {
            if (!response?.errors) {
                enqueueSnackbar('Deposit in progress.  Please allow 3-4 business days for the processing.',
                    {
                        variant: 'success',
                    },
                );
                getCurrentTransaction();
                refetchBankAccounts();
                setLoading(budgetLoadingInitialState);
                setOpenAddAmount(false);
            } else setLoading(budgetLoadingInitialState);
        },
    })

    const deleteBankAccountMutation = useMutation(
        (payload: any) => API.delete('removeBankAccount', null,
            {
                queryParams: { company_id: company?.id },
                body: payload
            }),
        {
            onSettled: (response: any) => {
                if (!response.errors) {
                    setBankAccounts({});
                    enqueueSnackbar('Bank account removed successfully.',
                        {
                            variant: 'success',
                        });
                    refetchBankAccounts();
                    formik.setValues({
                        ...budgetAddBackFormInitialState,
                        amount: walletMoney
                    })
                }
            },
        },
    )

    const formik = useFormik({
        initialValues: budgetAddBackFormInitialState,
        validationSchema: validationSchema,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSubmit: async (values, { resetForm }) => {
            setLoading({
                ...loading,
                addBankLoading: true
            });
            const { token, error }: any = await handleGenerateToken({
                country: 'US',
                currency: 'usd',
                routing_number: values.routing_number,
                account_number: values.account_number,
                account_holder_name: values.account_holder_name,
                account_holder_type: values.account_holder_type,
            })
            if (token) {
                await addMoneyACH({
                    amount: values?.amount && parseFloat(values?.amount),
                    currency: "usd",
                    token: token?.id,
                    stripe_data: token,
                    save_bank: values.save_bank
                });
            } else {
                setLoading(budgetLoadingInitialState);
                if (error?.code === "account_number_invalid") {
                    enqueueSnackbar("Invalid account number",
                        {
                            variant: 'error',
                        },
                    )
                    return;
                }
                enqueueSnackbar(error?.message,
                    {
                        variant: 'error',
                    },
                )
            }
        },
    });

    const addAmountForm = useFormik({
        initialValues: {
            amount: "0",
        },
        validationSchema: yup.object({
            amount: yup.number().min(1).required("This field is Required"),
        }),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSubmit: async (values, { resetForm }) => {
            setLoading({
                ...loading,
                addAmountLoading: true
            });
            await addFundInBank({
                amount: parseFloat(values.amount),
                bank_account_id: (bankAccounts?.bank_account?.data?.length) && bankAccounts?.bank_account?.data[0].id,
                currency: "usd"
            })
        },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => refetchBankAccounts(), []);

    useEffect(() => {
        formik.setValues({ ...formik.values, amount: walletMoney })
        addAmountForm.setValues({ amount: walletMoney })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletMoney])

    useEffect(() => {
        if (get(bankAccounts, "bank_account.data") && get(bankAccounts, "bank_account.data").length) {
            setShowBank(true)
            formik.setValues({
                account_holder_name: get(bankAccounts, "bank_account.data")[0].account_holder_name,
                account_holder_type: get(bankAccounts, "bank_account.data")[0].account_holder_type,
                account_number: get(bankAccounts, "bank_account.data")[0].account_number,
                routing_number: get(bankAccounts, "bank_account.data")[0].routing_number,
                amount: get(bankAccounts, "result")[0] && get(bankAccounts, "result").reverse()[0].amount,
                save_bank: get(bankAccounts, "result")[0] && get(bankAccounts, "result")[0].save_bank
            })
            addAmountForm.setValues({ amount: get(bankAccounts, "result")[0] && get(bankAccounts, "result")[0].amount })
        } else setShowBank(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bankAccounts])

    return (
        <>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} my={1}>
                <Typography variant='body2' component='span' fontWeight={500}><b>Bank Accounts</b></Typography>
            </Box>
            {(!showBank) && <div style={{ textAlign: "center" }}><Typography variant='h6' component='h6' fontWeight={500}>NO Bank Found</Typography></div>}

            {
                showBank && bankAccounts?.bank_account?.data.map((account: any) => {
                    return <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={4} sx={{ border: '1px solid #607d8b', borderRadius: 3, p: 1 }} key={account?.id}>
                        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography variant='h6' color={'primary'} component='h6' fontWeight={700} style={{ marginRight: 7 }}>{account?.bank_name}</Typography>
                            <Typography variant='body2' component='span' color={'info'} fontWeight={500}> **** {account?.last4} </Typography>
                        </Box>
                        <Box>
                            <Button variant="contained" onClick={() => setOpenAddAmount(true)}>Add Funds</Button>
                            <Button variant="text" onClick={() => deleteBankAccountMutation.mutate({
                                id: account?.id
                            })}><ClearIcon />Delete</Button>
                        </Box>
                    </Box>
                })
            }

            {/* Add bank account dialog */}
            <DialogTeleport
                dialogOpen={openAddBank}
                handleDialogClose={() => setOpenAddBank(false)}
                dialogTitle="Bank Details"
                dialogSize={"sm"}
                isFullWidth={true}
            >
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField name="account_holder_name" label="Account Holder Name" variant="outlined" fullWidth value={formik.values.account_holder_name}
                                onChange={formik.handleChange}
                                error={formik.touched.account_holder_name && Boolean(formik.errors.account_holder_name)}
                                helperText={formik.touched.account_holder_name && formik.errors.account_holder_name} />

                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-select-currency"
                                select
                                name="account_holder_type" label="Account Holder Type" variant="outlined" fullWidth value={formik.values.account_holder_type}
                                onChange={formik.handleChange}
                                error={formik.touched.account_holder_type && Boolean(formik.errors.account_holder_type)}
                                helperText={formik.touched.account_holder_type && formik.errors.account_holder_type}
                            >
                                {budgetAccountHolderTypes.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField name="account_number" label="Account Number" variant="outlined" type='number' fullWidth value={formik.values.account_number}
                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => formik.setValues({ ...formik.values, account_number: `${event.target.value}` })}
                                error={formik.touched.account_number && Boolean(formik.errors.account_number)}
                                helperText={formik.touched.account_number && formik.errors.account_number} />

                        </Grid>
                        <Grid item xs={12}>
                            <TextField name="routing_number" label="Routing Number" variant="outlined" fullWidth value={formik.values.routing_number}
                                onChange={formik.handleChange}
                                error={formik.touched.routing_number && Boolean(formik.errors.routing_number)}
                                helperText={formik.touched.routing_number && formik.errors.routing_number} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                data-cy='amount'
                                id='amount'
                                name="amount"
                                label="Enter Amount"
                                variant="outlined"
                                fullWidth
                                value={formik.values.amount}
                                onChange={formik.handleChange}
                                error={formik.touched.amount && Boolean(formik.errors.amount)}
                                helperText={formik.touched.amount && formik.errors.amount}
                                margin='normal'
                                InputProps={{
                                    inputComponent: MoneyInput,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <Box>
                                <FormControlLabel
                                    key={'allowSaveBank'}
                                    control={
                                        <Checkbox
                                            data-cy='allowSaveBank'
                                            name='allowSaveBank'
                                            onChange={() => formik.setValues({ ...formik.values, save_bank: !formik.values.save_bank })}
                                            checked={formik.values.save_bank}
                                        />
                                    }
                                    label="Save Bank Account"
                                />
                            </Box>

                            <Box>
                                <LoadingButton
                                    type="submit"
                                    loading={loading.addBankLoading}
                                    autoFocus
                                    variant='contained'
                                >
                                    <span>Add Funds</span>
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </DialogTeleport>

            {/* Add amount to bank dialog */}
            <DialogTeleport
                dialogOpen={openAddAmount}
                handleDialogClose={() => setOpenAddAmount(false)}
                dialogTitle="Add Funds"
                dialogSize={"sm"}
                isFullWidth={true}
            >
                <form onSubmit={addAmountForm.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                data-cy='amount'
                                id='amount'
                                name="amount"
                                label="Enter Amount"
                                variant="outlined"
                                fullWidth
                                value={addAmountForm.values.amount}
                                onChange={addAmountForm.handleChange}
                                error={addAmountForm.touched.amount && Boolean(addAmountForm.errors.amount)}
                                helperText={addAmountForm.touched.amount && addAmountForm.errors.amount}
                                margin='normal'
                                InputProps={{
                                    inputComponent: MoneyInput,
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box textAlign={'end'}>
                                <LoadingButton
                                    type="submit"
                                    loading={loading.addAmountLoading}
                                    autoFocus
                                    variant='contained'
                                >
                                    <span>Add Funds</span>
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </DialogTeleport>
        </>
    )
}

export default ManageAddBankAccount