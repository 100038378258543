/**
 * Material-UI overrides and palette definitions
 */

import { responsiveFontSizes } from '@mui/material'
import { createTheme } from '@mui/material/styles'

import palette from '../palette'
import breakpoints from './breakpoints'
import components from './components'
import typography from './typography'

const materialTheme = darkMode =>
  createTheme({
    zIndex: {
      appBar: 1200,
      drawer: 1100,
    },
    breakpoints,
    palette: palette(darkMode),
    components: components(darkMode),
    typography,
  })

export default darkMode => responsiveFontSizes(materialTheme(darkMode))
