import { useEffect, useState } from 'react'

import { DatePicker } from '@mui/lab'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material'

import { t } from 'i18n'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)
import { useCompany } from 'contexts'

type Props = {
  title: string
  isDialogOpen: boolean
  setIsDialogOpen: (value: boolean) => void
  survey: any
  action: (value?: any) => void
  showOpenDate?: boolean
  showCloseDate: boolean
  isLoading?: boolean
}

export default function SurveyOpenAndCloseDateDialog({
  title,
  isDialogOpen,
  setIsDialogOpen,
  survey,
  action,
  showOpenDate,
  showCloseDate,
  isLoading,
}: Props) {
  const [closeDate, setCloseDate] = useState(null)
  const [openDate, setOpenDate] = useState(null)
  const { company } = useCompany()!

  const handleClose = () => {
    setIsDialogOpen(false)
  }

  const handleAction = () => {
    if (showOpenDate && showCloseDate) {
      return action({ open_at: openDate, close_at: closeDate })
    }

    if (!showOpenDate) {
      return action(closeDate)
    }
  }

  useEffect(() => {
    if (survey?.open_at) setOpenDate(survey?.open_at)
    if (survey?.close_at) setCloseDate(survey?.close_at)
  }, [survey])

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleClose}
      aria-labelledby='dialog-title'
      aria-describedby='dialog-description'
    >
      <DialogTitle id='date-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {showOpenDate && (
            <Grid item xs>
              <DatePicker
                label='Launch Date'
                value={openDate}
                onChange={(value: any) => setOpenDate(value)}
                disablePast
                renderInput={params => <TextField {...params} fullWidth />}
              />
            </Grid>
          )}

          {showCloseDate && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs>
                {/* <DatePicker
                  label={t('surveyDialogs.extendSurvey.closeDate')}
                  value={closeDate}
                  onChange={(value: any) => setCloseDate(value)}
                  disablePast
                  renderInput={params => <TextField {...params} fullWidth />}
                /> */}
                <DateTimePicker 
                  label={t('surveyDialogs.extendSurvey.closeDate')}
                  value={dayjs(closeDate).tz(company.timezone)}
                  onChange={(value: any) => setCloseDate(value)}
                  disablePast
                  minDate={dayjs(openDate).tz(company.timezone)}
                  views={['year', 'day', 'hours']}
                  timezone={company.timezone}
                />
              </Grid>
            </LocalizationProvider>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          {t('cancel')}
        </Button>
        <Button
          onClick={handleAction}
          color='primary'
          disabled={
            isLoading ||
            (showCloseDate && !closeDate) ||
            (showOpenDate && !openDate)
          }
          autoFocus
        >
          {isLoading ? <CircularProgress size={24} /> : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
