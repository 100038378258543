import { get } from 'lodash'

import { SurveyParticipation } from 'components'
import { Locale } from 'enums/locale'
import { SurveyDriverScore } from 'enums/survey'
import {
  BeheardReportComparisonGraph,
  BeheardReportDefinition,
  BeheardReportFullReport,
  BeheardReportOverview,
  BeheardReportRecommendations,
  BeheardReportResults,
  BeheardReportTurnoverForecast,
} from 'pages/beheardReport/components'
import { t } from 'i18n'

export const beheardReportItems = (sectionsAllowed, fiveParticipantsOrMore) => [
  {
    id: 'overview',
    title: t('beheardReport.sectionTitles.overview'),
    navigationTitle: t('beheardReport.navigationTitles.overview'),
    color: '#E35D2A',
    Component: BeheardReportOverview,
    render: true,
  },
  {
    id: 'definition',
    title: t('beheardReport.sectionTitles.characteristics'),
    navigationTitle: t('beheardReport.navigationTitles.characteristics'),
    color: '#A6CFAF',
    Component: BeheardReportDefinition,
    render: true,
  },
  {
    id: 'participation',
    title: t('beheardReport.sectionTitles.participation'),
    navigationTitle: t('participation'),
    color: '#426E86',
    Component: SurveyParticipation,
    render: fiveParticipantsOrMore && get(sectionsAllowed, 'participation'),
  },
  {
    id: 'results',
    title: t('beheardReport.sectionTitles.results'),
    navigationTitle: t('beheardReport.navigationTitles.results'),
    color: '#75BEE4',
    Component: BeheardReportResults,
    render:
      fiveParticipantsOrMore &&
      (get(sectionsAllowed, 'results') ||
        get(sectionsAllowed, 'top_5_action_areas') ||
        get(sectionsAllowed, 'top_5_engagement_strengths') ||
        get(sectionsAllowed, 'top_5_importance')),
  },
  {
    id: 'topFive',
    title: t('beheardReport.sectionTitles.topFiveRecommendations'),
    navigationTitle: t('beheardReport.navigationTitles.topFiveRecommendations'),
    color: '#1BB4AC',
    Component: BeheardReportRecommendations,
    render: fiveParticipantsOrMore && get(sectionsAllowed, 'recommendations'),
  },
  {
    id: 'employeeTurnoverForecast',
    title: t('beheardReport.sectionTitles.employeeTurnoverForecast'),
    navigationTitle: t(
      'beheardReport.navigationTitles.employeeTurnoverForecast',
    ),
    color: '#BDB391',
    Component: BeheardReportTurnoverForecast,
    render:
      fiveParticipantsOrMore &&
      get(sectionsAllowed, 'employee_turnover_forecast'),
  },
  {
    id: 'comparisonGraph',
    title: t('beheardReport.sectionTitles.comparisonGraphs'),
    navigationTitle: t('beheardReport.navigationTitles.comparisonGraphs'),
    color: '#F79900',
    Component: BeheardReportComparisonGraph,
    render: fiveParticipantsOrMore && get(sectionsAllowed, 'comparison_graph'),
  },
  {
    id: 'fullReport',
    title: t('beheardReport.sectionTitles.fullReport'),
    navigationTitle: t('beheardReport.navigationTitles.fullReport'),
    color: '#607d8b',
    Component: BeheardReportFullReport,
    render:
      fiveParticipantsOrMore &&
      (get(sectionsAllowed, 'full_report') ||
        get(sectionsAllowed, 'custom_ratings') ||
        get(sectionsAllowed, 'custom_open_ended_download')),
  },
  {
    id: 'customQuestions',
    title: t('beheardReport.sectionTitles.customQuestions'),
    navigationTitle: t('beheardReport.navigationTitles.customQuestions'),
    color: '#607d8b',
    Component: null,
    render:
      fiveParticipantsOrMore &&
      get(sectionsAllowed, 'custom_open_ended_download'),
  },
]

export const fullReportItems = {
  [SurveyDriverScore.Engagement]: 'engagement',
  [SurveyDriverScore.Fit]: 'fit',
  [SurveyDriverScore.Alignment]: 'alignment',
  [SurveyDriverScore.Team]: 'team',
  [SurveyDriverScore.Valuing]: 'valuing',
  [SurveyDriverScore.Growth]: 'growth',
  [SurveyDriverScore.CustomRatingsQuestions]: 'customQuestions',
}

export const characteristics = {
  [Locale.English]: {
    engaged: [
      'Consistently exceed performance requirements',
      'Feel a sense of belonging to the organization',
      'Motivated to go the extra mile',
      'Willing and happy to refer potential employees',
      'Believe in, support, and are committed to the goals and objectives of the organization',
    ],
    disengaged: [
      'Lack enthusiasm and initiative to meet goals',
      'Tend to miss deadlines, arrive late for work, and break their promises',
      'Not motivated to help others or go above and beyond',
      'Actively looking for a new job',
      'Do not believe in, support, or committed to organizational goals and objectives',
    ],
  },
  [Locale.BrazilianPortuguese]: {
    engaged: [
      'Excedem a performance requerida constantemente',
      'Sentem-se parte da organização',
      'Motivados a caminhar a segunda milha',
      'Felizes e dispostos a recomendar pontenciais funcionários',
      'Acreditam, apóiam e são comprometidos com as metas e objetivos organizacionais',
    ],
    disengaged: [
      'Falta de entusiasmo e iniciativa para atingir metas',
      'Tendência a descumprir prazos, chegar atrasado no trabalho e quebrar promessas',
      'Sem motivação parar ajudar os outros ou ir além',
      'Buscam ativamente por outro emprego',
      'Não acreditam, não apoiam ou não estão comprometidos com as metas e objetivos organizacionais',
    ],
  },
  [Locale.Spanish]: {
    engaged: [
      'Consistently exceed performance requirements',
      'Feel a sense of belonging to the organization',
      'Motivated to go the extra mile',
      'Willing and happy to refer potential employees',
      'Believe in, support, and are committed to the goals and objectives of the organization',
    ],
    disengaged: [
      'Lack enthusiasm and initiative to meet goals',
      'Tend to miss deadlines, arrive late for work, and break their promises',
      'Not motivated to help others or go above and beyond',
      'Actively looking for a new job',
      'Do not believe in, support, or committed to organizational goals and objectives',
    ],
  },
  [Locale.Japanese]: {
    engaged: [
      'パフォーマンス要件を常に上回る',
      '組織への帰属意識を感じる',
      '一歩先を行くモチベーション',
      '潜在的な従業員を喜んで紹介します',
      '組織の目標と目的を信じ、支援し、コミットする',
    ],
    disengaged: [
      '目標を達成するための熱意とイニシアチブの欠如',
      '締め切りに間に合わない、仕事に遅れる、約束を破る傾向がある',
      '他の人を助けたり、それ以上のことをしたりする動機がない',
      '新しい仕事を積極的に探す',
      '組織の目標や目的を信じたり、支持したり、コミットしたりしないでください',
    ],
  },
  [Locale.German]: {
    engaged: [
      'Übertreffen Sie konsequent die Leistungsanforderungen',
      'Fühlen Sie sich der Organisation zugehörig',
      'Motiviert, die Extrameile zu gehen',
      'Bereitschaft und Freude, potenzielle Mitarbeiter zu empfehlen',
      'An die Ziele und Zielsetzungen der Organisation glauben, sie unterstützen und ihnen verpflichtet sein',
    ],
    disengaged: [
      'Mangelnde Begeisterung und Initiative, um Ziele zu erreichen',
      'Neigen dazu, Fristen zu verpassen, zu spät zur Arbeit zu kommen und ihre Versprechen zu brechen',
      'Nicht motiviert, anderen zu helfen oder darüber hinauszugehen',
      'Aktiv auf der Suche nach einem neuen Job',
      'Glauben, unterstützen oder verpflichten Sie sich nicht zu organisatorischen Zielen und Zielsetzungen',
    ],
  },
  [Locale.Arabic]: {
    engaged: [
      'تجاوز متطلبات الأداء باستمرار',
      'الشعور بالانتماء إلى المنظمة',
      'الدافع للذهاب إلى أبعد من ذلك',
      'على استعداد وسعيد لإحالة الموظفين المحتملين',
      'يؤمنون ويدعمون ويلتزمون بأهداف وغايات المنظمة',
    ],
    disengaged: [
      'قلة الحماس والمبادرة لتحقيق الأهداف',
      'يميلون إلى تفويت المواعيد النهائية ، والوصول متأخرًا عن العمل ، والحنث بوعودهم',
      'ليس الدافع لمساعدة الآخرين أو الذهاب إلى أبعد من ذلك',
      'بنشاط يبحث عن وظيفة جديدة',
      'لا تؤمن بالأهداف والغايات التنظيمية أو تدعمها أو تلتزم بها',
    ],
  },
  [Locale.Danish]: {
    engaged: [
      'Overgå konsekvent ydeevnekravene',
      'Føl et tilhørsforhold til organisationen',
      'Motiveret til at gå den ekstra mil',
      'Henviser gerne og gerne potentielle medarbejdere',
      'Tror på, støtter og er forpligtet til organisationens mål og målsætninger',
    ],
    disengaged: [
      'Mangler entusiasme og initiativ til at nå mål',
      'Har tendens til at overse deadlines, komme for sent på arbejde og bryde deres løfter',
      'Ikke motiveret til at hjælpe andre eller gå ud over det',
      'Søger aktivt nyt job',
      'Tro ikke på, støtte eller forpligtet til organisatoriske mål og målsætninger',
    ],
  },
  [Locale.Dutch]: {
    engaged: [
      'Overschrijd consequent de prestatie-eisen',
      'Voel je verbonden met de organisatie',
      'Gemotiveerd om een stap verder te gaan',
      'Bereid en graag potentiële medewerkers doorverwijzen',
      'Geloven in, ondersteunen en toegewijd zijn aan de doelen en doelstellingen van de organisatie',
    ],
    disengaged: [
      'Gebrek aan enthousiasme en initiatief om doelen te bereiken',
      'Hebben de neiging om deadlines te missen, te laat op het werk te komen en hun beloften te breken',
      'Not motivated to help others or go above and beyond',
      'Niet gemotiveerd om anderen te helpen of verder te gaan',
      'Geloof niet in, ondersteun of zet u niet in voor de doelen en doelstellingen van de organisatie',
    ],
  },
  [Locale.French]: {
    engaged: [
      'Dépasse constamment les exigences de performance',
      'Se sentir connecté à l\'organisation',
      'Motivé à faire un effort supplémentaire',
      'Volonté et heureux de référer des employés potentiels',
      'Croire, soutenir et s\'engager envers les buts et objectifs de l\'organisation',
    ],
    disengaged: [
      'Manque d\'enthousiasme et d\'initiative pour atteindre les objectifs',
      'Ont tendance à manquer les délais, à arriver en retard au travail et à rompre leurs promesses',
      'Pas motivé pour aider les autres ou aller au-delà',
      'En recherche active d\'un nouvel emploi',
      'Ne pas croire, soutenir ou s\'engager envers les buts et objectifs de l\'organisation',
    ],
  },
  [Locale.Finnish]: {
    engaged: [
      'Ylittää jatkuvasti suorituskykyvaatimukset',
      'Tunne, että kuulut organisaatioon',
      'Motivoitunut ylimääräiseen',
      'Halukas ja mielellään ohjaamaan potentiaalisia työntekijöitä',
      'Usko, tue ja sitoudu organisaation päämääriin ja tavoitteisiin',
    ],
    disengaged: [
      'Puuttuu innostus ja oma-aloitteisuus tavoitteiden saavuttamiseksi',
      'Yleensä myöhästyt määräajoista, saapuvat myöhässä töihin ja rikkovat lupauksensa',
      'Ei motivoitunut auttamaan muita tai menemään yli ja pidemmälle',
      'Etsii aktiivisesti uutta työtä',
      'Älä usko, tue tai sitoutu organisaation tavoitteisiin',
    ],
  },
  [Locale.Italian]: {
    engaged: [
      'Supera costantemente i requisiti di prestazione',
      'Sentire un senso di appartenenza all organizzazione',
      'Motivato a fare il possibile',
      'Disposto e felice di indirizzare potenziali dipendenti',
      'Credere, sostenere e impegnarsi per gli scopi e gli obiettivi dell organizzazione',
    ],
    disengaged: [
      'Mancanza di entusiasmo e iniziativa per raggiungere gli obiettivi',
      'Tendono a non rispettare le scadenze, ad arrivare in ritardo al lavoro e a infrangere le promesse',
      'Non motivato ad aiutare gli altri o ad andare oltre',
      'Ricerca attiva di un nuovo lavoro',
      'Non credere, sostenere o impegnarsi in scopi e obiettivi organizzativi',
    ],
  },
  [Locale.Swedish]: {
    engaged: [
      'Överträffa alltid prestandakraven',
      'Känn en känsla av tillhörighet till organisationen',
      'Motiverad att gå den extra milen',
      'Revisar gärna potentiella medarbetare',
      'Tror på, stödjer och är engagerade i organisationens mål och mål',
    ],
    disengaged: [
      'Saknar entusiasm och initiativ för att nå målen',
      'Tenderar att missa deadlines, komma för sent till jobbet och bryta sina löften',
      'Inte motiverad att hjälpa andra eller gå utöver det',
      'Söker aktivt nytt jobb',
      'Tro inte på, stödja eller engagera dig i organisationens mål och mål',
    ],
  },
  [Locale.BrazilianSpanish]: {
    engaged: [
      'Superar el rendimiento requerido consistentemente',
      'Sentirse parte de la organización',
      'Motivado para caminar la segunda milla',
      'Feliz y dispuesto a recomendar empleados potenciales.',
      'Creen, apoyan y están comprometidos con las metas y objetivos de la organización.',
    ],
    disengaged: [
      'Falta de entusiasmo e iniciativa para alcanzar los objetivos.',
      'Tendencia a incumplir plazos, llegar tarde al trabajo y romper promesas.',
      'Sin motivación para ayudar a otros o hacer un esfuerzo adicional',
      'Buscando activamente otro trabajo',
      'No creen, apoyan o no están comprometidos con las metas y objetivos organizacionales.',
    ],
  },
  [Locale.CanadianFrench]: {
    engaged: [
      'Dépasser systématiquement les exigences de rendement',
      'Ressentir un sentiment d’appartenance à l’organisation',
      'Motivé à faire un effort supplémentaire',
      'Disposé et heureux de référer des employés potentiels',
      'Croire, soutenir et s’engager à l’égard des buts et objectifs de l’organisation',
    ],
    disengaged: [
      'Manque d’enthousiasme et d’initiative pour atteindre les objectifs',
      'Ont tendance à manquer les délais, à arriver en retard au travail et à rompre leurs promesses',
      'Pas motivé pour aider les autres ou aller au-delà',
      'Activement à la recherche d’un nouvel emploi',
      'Ne croyez pas aux buts et aux objectifs de l’organisation, ne les soutenez pas et ne vous y engagez pas',
    ],
  },
  [Locale.EUSpanish]: {
    engaged: [
      'Consistently exceed performance requirements',
      'Feel a sense of belonging to the organization',
      'Motivated to go the extra mile',
      'Willing and happy to refer potential employees',
      'Believe in, support, and are committed to the goals and objectives of the organization',
    ],
    disengaged: [
      'Lack enthusiasm and initiative to meet goals',
      'Tend to miss deadlines, arrive late for work, and break their promises',
      'Not motivated to help others or go above and beyond',
      'Actively looking for a new job',
      'Do not believe in, support, or committed to organizational goals and objectives',
    ],
  },
  [Locale.Russian]: {
    engaged: [
      'Dépasser systématiquement les exigences de rendement',
      'Ressentir un sentiment d’appartenance à l’organisation',
      'Motivé à faire un effort supplémentaire',
      'Disposé et heureux de référer des employés potentiels',
      'Croire, soutenir et s’engager à l’égard des buts et objectifs de l’organisation',
    ],
    disengaged: [
      'Manque d’enthousiasme et d’initiative pour atteindre les objectifs',
      'Ont tendance à manquer les délais, à arriver en retard au travail et à rompre leurs promesses',
      'Pas motivé pour aider les autres ou aller au-delà',
      'Activement à la recherche d’un nouvel emploi',
      'Ne croyez pas aux buts et aux objectifs de l’organisation, ne les soutenez pas et ne vous y engagez pas',
    ],
  },
}


export const progressBarLegend = {
  [Locale.English]: {
    description: [
      '( 0% - 69% ) ',
      '( 70% - 79% )',
      '( 80% - 100% ) ',
    ],
  },
  [Locale.BrazilianPortuguese]: {
    description: [
      '( 0% - 69% )',
      '( 70% - 79% ) ',
      '( 80% - 100% ) ',
    ],
  },
  [Locale.Spanish]: {
    description: [
      '( 0% - 69% ) ',
      '( 70% - 79% )',
      '( 80% - 100% )',
    ],
  },
  [Locale.Japanese]: {
    description: [
      ' ( 0% - 69% )',
      '( 70% - 79% )',
      '( 80% - 100% )',
    ],
  },
  [Locale.German]: {
    description: [
      '( 0% - 69% )',
      '( 70% - 79% )',
      '( 80% - 100% )',
    ],
  },
  [Locale.Arabic]: {
    description: [
      '( 0% - 69% )',
      '( 70% - 79% )',
      '( 80% - 100% )',
    ],
  },
  [Locale.Danish]: {
    description: [
      '( 0% - 69% )',
      '( 70% - 79% )',
      '( 80% - 100% )',
    ],
  },
  [Locale.Dutch]: {
    description: [
      '( 0% - 69% )',
      '( 70% - 79% )',
      '( 80% - 100% )',
    ],
  },
  [Locale.French]: {
    description: [
      '( 0% - 69% )',
      '( 70% - 79% )',
      '( 80% - 100% )',
    ],
  },
  [Locale.Finnish]: {
    description: [
      '( 0% - 69% )',
      '( 70% - 79% )',
      '( 80% - 100% )',
    ],
  },
  [Locale.Italian]: {
    description: [
      '( 0% - 69% )',
      '( 70% - 79% )',
      '( 80% - 100% )',
    ],
  },
  [Locale.Swedish]: {
    description: [
      '( 0% - 69% )',
      '( 70% - 79% )',
      '( 80% - 100% )',
    ],
  },
  [Locale.BrazilianSpanish]: {
    description: [
      '( 0% - 69% )',
      '( 70% - 79% )',
      '( 80% - 100% )',
    ],
  },
  [Locale.CanadianFrench]: {
    description: [
      '( 0% - 69% )',
      '( 70% - 79% )',
      '( 80% - 100% )',
    ],
  },
  [Locale.EUSpanish]: {
    description: [
      '( 0% - 69% )',
      '( 70% - 79% )',
      '( 80% - 100% )',
    ],
  },
  [Locale.Russian]: {
    description: [
      '( 0% - 69% )',
      '( 70% - 79% )',
      '( 80% - 100% )',
    ],
  },
}

export const comparisonGraph = {
  [Locale.English]: {
    legend: ['engaged', 'neutrals', 'disengaged'],
  },
  [Locale.BrazilianPortuguese]: {
    legend: ['noivo', 'neutro', 'desengatado'],
  },
  [Locale.Spanish]: {
    legend: ['comprometido', 'neutrals', 'desacoplado'],
  },
  [Locale.Japanese]: {
    legend: ['従事し', 'ニュートラル', '解放された'],
  },
  [Locale.German]: {
    legend: ['engagiert', 'Neutrale', 'ausgerückt'],
  },
  [Locale.Arabic]: {
    legend: ['مرتبط', 'محايدون', 'مفكك'],
  },
  [Locale.Danish]: {
    legend: ['beskæftiget', 'neutralen', 'ontkoppeld'],
  },
  [Locale.Dutch]: {
    legend: ['betrokken', 'neutralen', 'ontkoppeld'],
  },
  [Locale.French]: {
    legend: ['engagé', 'neutres', 'désengagé'],
  },
  [Locale.Finnish]: {
    legend: ['kihloissa', 'neutraaleja', 'irti'],
  },
  [Locale.Italian]: {
    legend: ['impegnato', 'neutri', 'disimpegnato'],
  },
  [Locale.Swedish]: {
    legend: ['engagerad', 'neutrala', 'urkopplad'],
  },
  [Locale.BrazilianSpanish]: {
    legend: ['comprometido', 'neutrales', 'desacoplado'],
  },
  [Locale.CanadianFrench]: {
    legend: ['engagé', 'neutres', 'désengagé'],
  },
  [Locale.EUSpanish]: {
    legend: ['comprometido', 'neutrals', 'desacoplado'],
  },
  [Locale.Russian]: {
    legend: ['engagé', 'neutres', 'désengagé'],
  },
  
}

export const beheardReportExample = {
  sections_allowed: {
    filter: true,
    participation: true,
    comparison_graph: true,
    results: true,
    top_5_action_areas: true,
    top_5_engagement_strengths: true,
    top_5_importance: true,
    recommendations: true,
    employee_turnover_forecast: true,
    full_report: true,
    custom_ratings: false,
    custom_open_ended_download: false,
  },
  has_open_ended_answers: true,
  company_id: 44,
  survey: {
    id: 53,
    name: '#BeHeard Survey',
    open_at: '2021-10-10T00:00:00.000Z',
    close_at: '2021-10-24T00:00:00.000Z',
    invites: 80,
    current_participation: 50,
    participation_rate: 62.5,
  },
  participation_by_demographic: [
    {
      label: 'Department',
      values: [
        { label: 'IT', percentage: 68 },
        { label: 'Marketing', percentage: 54 },
      ],
    },
    {
      label: 'Tenure',
      values: [
        { label: '1 year and below', percentage: 53 },
        { label: '2 to 5 years', percentage: 67 },
        { label: '6 to 10 years', percentage: 86 },
        { label: '10 or more years', percentage: 'N/A' },
      ],
    },
    {
      label: 'Age',
      values: [
        { label: '18 to 25', percentage: 74 },
        { label: '26 to 35', percentage: 56 },
        { label: '36 to 45', percentage: 65 },
        { label: '46 to 55', percentage: 'N/A' },
        { label: '56 to 65', percentage: 'N/A' },
        { label: '65 years or more', percentage: 'N/A' },
      ],
    },
    {
      label: 'Gender',
      values: [
        { label: 'Female', percentage: 'N/A' },
        { label: 'Male', percentage: 74 },
      ],
    },
    {
      label: 'Location',
      values: [
        { label: 'Sacramento, California', percentage: 60 },
        { label: 'Los Angeles, California', percentage: 69 },
      ],
    },
    {
      label: 'Business Unit',
      values: [
        { label: 'Happy', percentage: 74 },
        { label: 'Fun', percentage: 71 },
      ],
    },
    { label: 'Territory', values: [] },
    { label: 'Region', values: [] },
    { label: 'Custom Demographic #1', values: [] },
    { label: 'Custom Demographic #2', values: [] },
  ],
  comparisons: [
    {
      label: 'Age',
      values: [
        {
          label: '18 to 25',
          percentages: { engaged: 89, neutrals: 11, disengaged: 0 },
        },
        {
          label: '26 to 35',
          percentages: { engaged: 88, neutrals: 6, disengaged: 6 },
        },
        {
          label: '36 to 45',
          percentages: { engaged: 89, neutrals: 8, disengaged: 3 },
        },
      ],
    },
    {
      label: 'Department',
      values: [
        {
          label: 'IT',
          percentages: { engaged: 88, neutrals: 8, disengaged: 4 },
        },
        {
          label: 'Marketing',
          percentages: { engaged: 91, neutrals: 7, disengaged: 2 },
        },
      ],
    },
    {
      label: 'Gender',
      values: [
        {
          label: 'Male',
          percentages: { engaged: 84, neutrals: 11, disengaged: 5 },
        },
      ],
    },
    {
      label: 'Location',
      values: [
        {
          label: 'Sacramento, California',
          percentages: { engaged: 91, neutrals: 8, disengaged: 1 },
        },
        {
          label: 'Los Angeles, California',
          percentages: { engaged: 78, neutrals: 7, disengaged: 15 },
        },
      ],
    },
    {
      label: 'Business Unit',
      values: [
        {
          label: 'Happy',
          percentages: { engaged: 86, neutrals: 9, disengaged: 5 },
        },
        {
          label: 'Fun',
          percentages: { engaged: 82, neutrals: 18, disengaged: 0 },
        },
      ],
    },
    {
      label: 'Tenure',
      values: [
        {
          label: '1 year and below',
          percentages: { engaged: 91, neutrals: 9, disengaged: 0 },
        },
        {
          label: '2 to 5 years',
          percentages: { engaged: 89, neutrals: 5, disengaged: 6 },
        },
        {
          label: '6 to 10 years',
          percentages: { engaged: 88, neutrals: 12, disengaged: 0 },
        },
      ],
    },
    { label: 'Territory', values: [] },
    { label: 'Region', values: [] },
    { label: 'Custom Demographic #1', values: [] },
    { label: 'Custom Demographic #2', values: [] },
  ],
  scores: {
    percents: {
      engagement: [
        { label: 'Engaged', value: 89 },
        { label: 'Neutral', value: 8 },
        { label: 'Disengaged', value: 3 },
      ],
      drivers: [
        { label: 'Engagement', value: 89 },
        { label: 'Fit', value: 83 },
        { label: 'Alignment', value: 90 },
        { label: 'Team', value: 81 },
        { label: 'Valuing', value: 81 },
        { label: 'Growth', value: 85 },
        { label: 'Custom Ratings Questions', value: 0 },
      ],
    },
  },
  turnover: {
    short_term: {
      at_risk: 4,
      at_risk_percentage: 8,
      passive: 5,
      passive_percentage: 10,
      commited: 40,
      commited_percentage: 82,
    },
    long_term: {
      at_risk: 1,
      at_risk_percentage: 2,
      passive: 7,
      passive_percentage: 14,
      commited: 41,
      commited_percentage: 84,
    },
    previous: {
      short_term: {
        at_risk: 1,
        at_risk_percentage: 3,
        passive: 4,
        passive_percentage: 13,
        commited: 26,
        commited_percentage: 84,
      },
      long_term: {
        at_risk: 1,
        at_risk_percentage: 3,
        passive: 6,
        passive_percentage: 19,
        commited: 24,
        commited_percentage: 77,
      },
    },
  },
  top_five: [
    {
      type: 'action',
      question_id: 35,
      statement:
        'This organization provides me with the tools I need to help me advance my career.',
      benchmark: 68,
      engaged_percentage: 86,
      neutrals_percentage: 6,
      disengaged_percentage: 8,
      previous_total_satisfaction: 85,
      total_satisfaction: 84,
      total_importance: 92,
      roi_gap: 8,
      recommendations: [
        'Encourage employees to set career goals, and support their efforts to attend continuing training and education (courses, conferences, etc.) that represent movement towards goals.',
        'To expand knowledge and experience, provide exposure to other opportunities in the company (e.g., cross-functional teams).',
      ],
    },
    {
      type: 'action',
      question_id: 38,
      statement:
        'My manager provides me with regular feedback to help me improve my performance.',
      benchmark: 74,
      engaged_percentage: 70,
      neutrals_percentage: 20,
      disengaged_percentage: 10,
      previous_total_satisfaction: 76,
      total_satisfaction: 81,
      total_importance: 91,
      roi_gap: 10,
      recommendations: [
        'Schedule one-on-one time with each of your employees to create an individual development plan. Identify with each employee a developmental goal that would increase his or her performance. ',
        'Provide timely and specific feedback (positive and constructive) on a regular basis.',
      ],
    },
    {
      type: 'action',
      question_id: 21,
      statement:
        'My manager fosters genuine and trusting relationships on the team.',
      benchmark: 78,
      engaged_percentage: 80,
      neutrals_percentage: 10,
      disengaged_percentage: 10,
      previous_total_satisfaction: 88,
      total_satisfaction: 83,
      total_importance: 90,
      roi_gap: 7,
      recommendations: [
        'Periodically, check in privately to see how individuals are doing',
        'Conduct a team-building exercises to help individuals get to know each other. Continue these on an ongoing basis to reinforce team bonding. ',
      ],
    },
    {
      type: 'action',
      question_id: 27,
      statement: 'I have the resources I need to do an effective job.',
      benchmark: 76,
      engaged_percentage: 74,
      neutrals_percentage: 18,
      disengaged_percentage: 8,
      previous_total_satisfaction: 81,
      total_satisfaction: 80,
      total_importance: 90,
      roi_gap: 10,
      recommendations: [
        'Engage in an ongoing discussion with your team to communicate any lack of resources for tasks or projects. ',
        'Arrange for both technical and professional skill training to help the employee be effective in the current role. Provide additional job resources, such as autonomy, social support, or supervisory coaching',
      ],
    },
    {
      type: 'strength',
      question_id: 6,
      statement: 'I am proud to work for this organization.',
      benchmark: 83,
      engaged_percentage: 96,
      neutrals_percentage: 2,
      disengaged_percentage: 2,
      previous_total_satisfaction: 95,
      total_satisfaction: 94,
      total_importance: 95,
      roi_gap: 1,
      recommendations: [
        'Instill a culture of transparency, respect, and continuous development',
        'Regularly communicate how the employee’s efforts contribute to the overall success of the organization',
      ],
    },
    {
      type: 'strength',
      question_id: 34,
      statement:
        'This organization has a culture where I can learn new skills and grow.',
      benchmark: 70,
      engaged_percentage: 90,
      neutrals_percentage: 8,
      disengaged_percentage: 2,
      previous_total_satisfaction: 91,
      total_satisfaction: 91,
      total_importance: 95,
      roi_gap: 4,
      recommendations: [
        'Provide greater challenges and more significant assignments that lead to new skill development',
        'Provide employees with assignments that aid in their development. Set stretch goals with them.',
      ],
    },
    {
      type: 'strength',
      question_id: 14,
      statement: "I believe in our organization's purpose.",
      benchmark: 84,
      engaged_percentage: 96,
      neutrals_percentage: 2,
      disengaged_percentage: 2,
      previous_total_satisfaction: 92,
      total_satisfaction: 92,
      total_importance: 94,
      roi_gap: 2,
      recommendations: [
        'Educate employees about the mission, vision, and values of the organization',
        'Ensure the organization’s purpose becomes rooted in the culture and employees have a clear understanding of how their jobs matter in achieving company goals',
        'Lead by example (e.g. upper management, executives, etc.)',
      ],
    },
    {
      type: 'strength',
      question_id: 4,
      statement: 'I care about the results that this organization achieves.',
      benchmark: 91,
      engaged_percentage: 94,
      neutrals_percentage: 2,
      disengaged_percentage: 4,
      previous_total_satisfaction: 96,
      total_satisfaction: 95,
      total_importance: 94,
      roi_gap: 1,
      recommendations: [
        'Give employees the opportunity to become a stakeholder by contributing to the company planning and goal setting',
        'Align individual incentives and rewards with the achievements of the organization',
      ],
    },
    {
      type: 'strength',
      question_id: 5,
      statement:
        'I feel driven to do what it takes to help this organization succeed.',
      benchmark: 84,
      engaged_percentage: 98,
      neutrals_percentage: 2,
      disengaged_percentage: 0,
      previous_total_satisfaction: 94,
      total_satisfaction: 94,
      total_importance: 93,
      roi_gap: 1,
      recommendations: [
        'Help employees understand the big picture of assignments and how they impact the organization',
        'Align individual incentives and rewards with the achievements of the organization',
      ],
    },
    {
      type: 'strength',
      question_id: 26,
      statement: 'My manager is approachable.',
      benchmark: 80,
      engaged_percentage: 84,
      neutrals_percentage: 12,
      disengaged_percentage: 4,
      previous_total_satisfaction: 85,
      total_satisfaction: 86,
      total_importance: 92,
      roi_gap: 6,
      recommendations: [
        'Manage By Wandering Around (MBWA) to engage with employees and spot opportunities to help them succeed',
        'Maintain an open-door policy and encourage employees to approach you with concerns, ideas, or suggestions.',
      ],
    },
    {
      type: 'strength',
      question_id: 30,
      statement:
        'This organization has a culture of recognition for good performance.',
      benchmark: 68,
      engaged_percentage: 92,
      neutrals_percentage: 4,
      disengaged_percentage: 4,
      previous_total_satisfaction: 90,
      total_satisfaction: 90,
      total_importance: 92,
      roi_gap: 2,
      recommendations: [
        'Create an internal recognition award that employees can receive and then pass along to another person when they discover good performance. Encourage employees to recognize each other For example, create postcards that say “You Done Good” and have employees give them to each other. The employee with the most cards quarterly wins a special award.',
        'Discuss with your employees about how they would like to be recognized so your recognition awards are personalized. ',
      ],
    },
    {
      type: 'strength',
      question_id: 18,
      statement:
        "I can see how my work directly contributes to this organization's results.",
      benchmark: 86,
      engaged_percentage: 92,
      neutrals_percentage: 6,
      disengaged_percentage: 2,
      previous_total_satisfaction: 93,
      total_satisfaction: 92,
      total_importance: 91,
      roi_gap: 1,
      recommendations: [
        'At every staff meeting, explain how the team or individual’s work contributes to overall company success',
        'Help employees understand the big picture of assignments and how they impact the business',
      ],
    },
    {
      type: 'strength',
      question_id: 13,
      statement:
        'I have confidence in the direction that our organization is taking.',
      benchmark: 74,
      engaged_percentage: 92,
      neutrals_percentage: 6,
      disengaged_percentage: 2,
      previous_total_satisfaction: 90,
      total_satisfaction: 90,
      total_importance: 91,
      roi_gap: 1,
      recommendations: [
        'Be transparent by regularly updating employees on the company strategy, tactics, and where the organization stands compared to goals',
        'Include employees in the planning and goal setting processes',
      ],
    },
    {
      type: 'strength',
      question_id: 7,
      statement: 'I feel that I will have a long career at this organization.',
      benchmark: 79,
      engaged_percentage: 84,
      neutrals_percentage: 14,
      disengaged_percentage: 2,
      previous_total_satisfaction: 84,
      total_satisfaction: 88,
      total_importance: 91,
      roi_gap: 3,
      recommendations: [
        'Have frequent and ongoing career discussions, not just at the end of the year',
        'Schedule one-on-one time with each of your employees to create an individual development plan. Identify a developmental goal for each employee.',
      ],
    },
    {
      type: 'strength',
      question_id: 16,
      statement: "I am committed to our organization's mission and values.",
      benchmark: 80,
      engaged_percentage: 94,
      neutrals_percentage: 4,
      disengaged_percentage: 2,
      previous_total_satisfaction: 92,
      total_satisfaction: 90,
      total_importance: 91,
      roi_gap: 1,
      recommendations: [
        'Reinforce the company goals and objectives through regular communication',
        'Ensure upper management is embodying the mission and values.',
        'Develop a strong culture by integrating the mission and core values into every facet of the organization',
      ],
    },
    {
      type: 'strength',
      question_id: 3,
      statement: 'I feel motivated to `go the extra mile`.',
      benchmark: 80,
      engaged_percentage: 92,
      neutrals_percentage: 6,
      disengaged_percentage: 2,
      previous_total_satisfaction: 88,
      total_satisfaction: 89,
      total_importance: 91,
      roi_gap: 2,
      recommendations: [
        'Ask the employee what motivates him or her, and use that to help the employee become more motivated',
        'Assess if your team has the needed KSAOs in order to go above and beyond. What are the hurdles that prevent them from doing this? Is this type of behavior rewarded, recognized, and encouraged?  ',
      ],
    },
    {
      type: 'strength',
      question_id: 10,
      statement: 'I have clearly defined goals for my job.',
      benchmark: 81,
      engaged_percentage: 80,
      neutrals_percentage: 12,
      disengaged_percentage: 8,
      previous_total_satisfaction: 86,
      total_satisfaction: 85,
      total_importance: 91,
      roi_gap: 6,
      recommendations: [
        'Provide ongoing feedback to employees by conducting prigress reviews while coaching and offering guidance.',
        'Schedule one-on-one time with each of your employees to discuss expectations, responsibilities, and goals of the job',
      ],
    },
    {
      type: 'strength',
      question_id: 17,
      statement: 'I understand the most important goals for my department.',
      benchmark: 82,
      engaged_percentage: 92,
      neutrals_percentage: 4,
      disengaged_percentage: 4,
      previous_total_satisfaction: 90,
      total_satisfaction: 89,
      total_importance: 90,
      roi_gap: 1,
      recommendations: [
        'Frequent communication regarding the department’s goal priorities, putting the most important tasks first',
        'Involve employees in setting department goals and objectives',
      ],
    },
    {
      type: 'strength',
      question_id: 15,
      statement: 'I have confidence in our senior leadership team.',
      benchmark: 74,
      engaged_percentage: 86,
      neutrals_percentage: 8,
      disengaged_percentage: 6,
      previous_total_satisfaction: 88,
      total_satisfaction: 87,
      total_importance: 90,
      roi_gap: 3,
      recommendations: [
        'Lead by example: Model the behavior you seek',
        'Reinforce the company vision by tying it to individual and team goals',
      ],
    },
    {
      type: 'strength',
      question_id: 36,
      statement: 'My manager supports my professional growth and development.',
      benchmark: 77,
      engaged_percentage: 82,
      neutrals_percentage: 14,
      disengaged_percentage: 4,
      previous_total_satisfaction: 86,
      total_satisfaction: 85,
      total_importance: 90,
      roi_gap: 5,
      recommendations: [
        'Understand employees’ areas of interest in professional development. Attempt to design assignments that accommodate these areas.',
        'Have regular professional development discussions regarding progress toward professional growth goals.',
      ],
    },
    {
      type: 'strength',
      question_id: 9,
      statement: 'The type of work that I do leverages my personal strengths.',
      benchmark: 82,
      engaged_percentage: 86,
      neutrals_percentage: 12,
      disengaged_percentage: 2,
      previous_total_satisfaction: 86,
      total_satisfaction: 89,
      total_importance: 90,
      roi_gap: 1,
      recommendations: [
        'Make sure the right people have the right assignments: don’t delegate tasks you know they are weak in without giving them training and coaching',
        'Establish where the current job can leverage their strengths and where employees need to get outside help',
      ],
    },
    {
      type: 'strength',
      question_id: 33,
      statement: 'My manager treats me with respect.',
      benchmark: 83,
      engaged_percentage: 80,
      neutrals_percentage: 16,
      disengaged_percentage: 4,
      previous_total_satisfaction: 88,
      total_satisfaction: 87,
      total_importance: 90,
      roi_gap: 3,
      recommendations: [
        'Be a good listener by taking the time to listen to employees’ concerns and needs',
        'Acknowledge employees’ contributions by expressing praise and appreciation for their efforts',
      ],
    },
    {
      type: 'importance',
      question_id: 6,
      statement: 'I am proud to work for this organization.',
      benchmark: 83,
      engaged_percentage: 96,
      neutrals_percentage: 2,
      disengaged_percentage: 2,
      previous_total_satisfaction: 95,
      total_satisfaction: 94,
      total_importance: 95,
      roi_gap: 1,
      recommendations: [
        'Regularly communicate how the employee’s efforts contribute to the overall success of the organization',
        'Instill a culture of transparency, respect, and continuous development',
      ],
    },
    {
      type: 'importance',
      question_id: 34,
      statement:
        'This organization has a culture where I can learn new skills and grow.',
      benchmark: 70,
      engaged_percentage: 90,
      neutrals_percentage: 8,
      disengaged_percentage: 2,
      previous_total_satisfaction: 91,
      total_satisfaction: 91,
      total_importance: 95,
      roi_gap: 4,
      recommendations: [
        'Provide employees with assignments that aid in their development. Set stretch goals with them.',
        'Provide greater challenges and more significant assignments that lead to new skill development',
      ],
    },
    {
      type: 'importance',
      question_id: 4,
      statement: 'I care about the results that this organization achieves.',
      benchmark: 91,
      engaged_percentage: 94,
      neutrals_percentage: 2,
      disengaged_percentage: 4,
      previous_total_satisfaction: 96,
      total_satisfaction: 95,
      total_importance: 94,
      roi_gap: 1,
      recommendations: [
        'Align individual incentives and rewards with the achievements of the organization',
        'Give employees the opportunity to become a stakeholder by contributing to the company planning and goal setting',
      ],
    },
    {
      type: 'importance',
      question_id: 14,
      statement: "I believe in our organization's purpose.",
      benchmark: 84,
      engaged_percentage: 96,
      neutrals_percentage: 2,
      disengaged_percentage: 2,
      previous_total_satisfaction: 92,
      total_satisfaction: 92,
      total_importance: 94,
      roi_gap: 2,
      recommendations: [
        'Lead by example (e.g. upper management, executives, etc.)',
        'Educate employees about the mission, vision, and values of the organization',
        'Ensure the organization’s purpose becomes rooted in the culture and employees have a clear understanding of how their jobs matter in achieving company goals',
      ],
    },
    {
      type: 'importance',
      question_id: 5,
      statement:
        'I feel driven to do what it takes to help this organization succeed.',
      benchmark: 84,
      engaged_percentage: 98,
      neutrals_percentage: 2,
      disengaged_percentage: 0,
      previous_total_satisfaction: 94,
      total_satisfaction: 94,
      total_importance: 93,
      roi_gap: 1,
      recommendations: [
        'Help employees understand the big picture of assignments and how they impact the organization',
        'Align individual incentives and rewards with the achievements of the organization',
      ],
    },
    {
      type: 'importance',
      question_id: 30,
      statement:
        'This organization has a culture of recognition for good performance.',
      benchmark: 68,
      engaged_percentage: 92,
      neutrals_percentage: 4,
      disengaged_percentage: 4,
      previous_total_satisfaction: 90,
      total_satisfaction: 90,
      total_importance: 92,
      roi_gap: 2,
      recommendations: [
        'Create an internal recognition award that employees can receive and then pass along to another person when they discover good performance. Encourage employees to recognize each other For example, create postcards that say “You Done Good” and have employees give them to each other. The employee with the most cards quarterly wins a special award.',
        'Discuss with your employees about how they would like to be recognized so your recognition awards are personalized. ',
      ],
    },
    {
      type: 'importance',
      question_id: 26,
      statement: 'My manager is approachable.',
      benchmark: 80,
      engaged_percentage: 84,
      neutrals_percentage: 12,
      disengaged_percentage: 4,
      previous_total_satisfaction: 85,
      total_satisfaction: 86,
      total_importance: 92,
      roi_gap: 6,
      recommendations: [
        'Maintain an open-door policy and encourage employees to approach you with concerns, ideas, or suggestions.',
        'Manage By Wandering Around (MBWA) to engage with employees and spot opportunities to help them succeed',
      ],
    },
    {
      type: 'importance',
      question_id: 35,
      statement:
        'This organization provides me with the tools I need to help me advance my career.',
      benchmark: 68,
      engaged_percentage: 86,
      neutrals_percentage: 6,
      disengaged_percentage: 8,
      previous_total_satisfaction: 85,
      total_satisfaction: 84,
      total_importance: 92,
      roi_gap: 8,
      recommendations: [
        'Encourage employees to set career goals, and support their efforts to attend continuing training and education (courses, conferences, etc.) that represent movement towards goals.',
        'To expand knowledge and experience, provide exposure to other opportunities in the company (e.g., cross-functional teams).',
      ],
    },
    {
      type: 'importance',
      question_id: 18,
      statement:
        "I can see how my work directly contributes to this organization's results.",
      benchmark: 86,
      engaged_percentage: 92,
      neutrals_percentage: 6,
      disengaged_percentage: 2,
      previous_total_satisfaction: 93,
      total_satisfaction: 92,
      total_importance: 91,
      roi_gap: 1,
      recommendations: [
        'At every staff meeting, explain how the team or individual’s work contributes to overall company success',
        'Help employees understand the big picture of assignments and how they impact the business',
      ],
    },
    {
      type: 'importance',
      question_id: 13,
      statement:
        'I have confidence in the direction that our organization is taking.',
      benchmark: 74,
      engaged_percentage: 92,
      neutrals_percentage: 6,
      disengaged_percentage: 2,
      previous_total_satisfaction: 90,
      total_satisfaction: 90,
      total_importance: 91,
      roi_gap: 1,
      recommendations: [
        'Be transparent by regularly updating employees on the company strategy, tactics, and where the organization stands compared to goals',
        'Include employees in the planning and goal setting processes',
      ],
    },
    {
      type: 'importance',
      question_id: 16,
      statement: "I am committed to our organization's mission and values.",
      benchmark: 80,
      engaged_percentage: 94,
      neutrals_percentage: 4,
      disengaged_percentage: 2,
      previous_total_satisfaction: 92,
      total_satisfaction: 90,
      total_importance: 91,
      roi_gap: 1,
      recommendations: [
        'Reinforce the company goals and objectives through regular communication',
        'Ensure upper management is embodying the mission and values.',
        'Develop a strong culture by integrating the mission and core values into every facet of the organization',
      ],
    },
    {
      type: 'importance',
      question_id: 3,
      statement: 'I feel motivated to `go the extra mile`.',
      benchmark: 80,
      engaged_percentage: 92,
      neutrals_percentage: 6,
      disengaged_percentage: 2,
      previous_total_satisfaction: 88,
      total_satisfaction: 89,
      total_importance: 91,
      roi_gap: 2,
      recommendations: [
        'Assess if your team has the needed KSAOs in order to go above and beyond. What are the hurdles that prevent them from doing this? Is this type of behavior rewarded, recognized, and encouraged?  ',
        'Ask the employee what motivates him or her, and use that to help the employee become more motivated',
      ],
    },
    {
      type: 'importance',
      question_id: 7,
      statement: 'I feel that I will have a long career at this organization.',
      benchmark: 79,
      engaged_percentage: 84,
      neutrals_percentage: 14,
      disengaged_percentage: 2,
      previous_total_satisfaction: 84,
      total_satisfaction: 88,
      total_importance: 91,
      roi_gap: 3,
      recommendations: [
        'Have frequent and ongoing career discussions, not just at the end of the year',
        'Schedule one-on-one time with each of your employees to create an individual development plan. Identify a developmental goal for each employee.',
      ],
    },
    {
      type: 'importance',
      question_id: 10,
      statement: 'I have clearly defined goals for my job.',
      benchmark: 81,
      engaged_percentage: 80,
      neutrals_percentage: 12,
      disengaged_percentage: 8,
      previous_total_satisfaction: 86,
      total_satisfaction: 85,
      total_importance: 91,
      roi_gap: 6,
      recommendations: [
        'Provide ongoing feedback to employees by conducting prigress reviews while coaching and offering guidance.',
        'Schedule one-on-one time with each of your employees to discuss expectations, responsibilities, and goals of the job',
      ],
    },
    {
      type: 'importance',
      question_id: 38,
      statement:
        'My manager provides me with regular feedback to help me improve my performance.',
      benchmark: 74,
      engaged_percentage: 70,
      neutrals_percentage: 20,
      disengaged_percentage: 10,
      previous_total_satisfaction: 76,
      total_satisfaction: 81,
      total_importance: 91,
      roi_gap: 10,
      recommendations: [
        'Provide timely and specific feedback (positive and constructive) on a regular basis.',
        'Schedule one-on-one time with each of your employees to create an individual development plan. Identify with each employee a developmental goal that would increase his or her performance. ',
      ],
    },
    {
      type: 'importance',
      question_id: 9,
      statement: 'The type of work that I do leverages my personal strengths.',
      benchmark: 82,
      engaged_percentage: 86,
      neutrals_percentage: 12,
      disengaged_percentage: 2,
      previous_total_satisfaction: 86,
      total_satisfaction: 89,
      total_importance: 90,
      roi_gap: 1,
      recommendations: [
        'Make sure the right people have the right assignments: don’t delegate tasks you know they are weak in without giving them training and coaching',
        'Establish where the current job can leverage their strengths and where employees need to get outside help',
      ],
    },
    {
      type: 'importance',
      question_id: 17,
      statement: 'I understand the most important goals for my department.',
      benchmark: 82,
      engaged_percentage: 92,
      neutrals_percentage: 4,
      disengaged_percentage: 4,
      previous_total_satisfaction: 90,
      total_satisfaction: 89,
      total_importance: 90,
      roi_gap: 1,
      recommendations: [
        'Involve employees in setting department goals and objectives',
        'Frequent communication regarding the department’s goal priorities, putting the most important tasks first',
      ],
    },
    {
      type: 'importance',
      question_id: 15,
      statement: 'I have confidence in our senior leadership team.',
      benchmark: 74,
      engaged_percentage: 86,
      neutrals_percentage: 8,
      disengaged_percentage: 6,
      previous_total_satisfaction: 88,
      total_satisfaction: 87,
      total_importance: 90,
      roi_gap: 3,
      recommendations: [
        'Lead by example: Model the behavior you seek',
        'Reinforce the company vision by tying it to individual and team goals',
      ],
    },
    {
      type: 'importance',
      question_id: 33,
      statement: 'My manager treats me with respect.',
      benchmark: 83,
      engaged_percentage: 80,
      neutrals_percentage: 16,
      disengaged_percentage: 4,
      previous_total_satisfaction: 88,
      total_satisfaction: 87,
      total_importance: 90,
      roi_gap: 3,
      recommendations: [
        'Be a good listener by taking the time to listen to employees’ concerns and needs',
        'Acknowledge employees’ contributions by expressing praise and appreciation for their efforts',
      ],
    },
    {
      type: 'importance',
      question_id: 36,
      statement: 'My manager supports my professional growth and development.',
      benchmark: 77,
      engaged_percentage: 82,
      neutrals_percentage: 14,
      disengaged_percentage: 4,
      previous_total_satisfaction: 86,
      total_satisfaction: 85,
      total_importance: 90,
      roi_gap: 5,
      recommendations: [
        'Have regular professional development discussions regarding progress toward professional growth goals.',
        'Understand employees’ areas of interest in professional development. Attempt to design assignments that accommodate these areas.',
      ],
    },
    {
      type: 'importance',
      question_id: 21,
      statement:
        'My manager fosters genuine and trusting relationships on the team.',
      benchmark: 78,
      engaged_percentage: 80,
      neutrals_percentage: 10,
      disengaged_percentage: 10,
      previous_total_satisfaction: 88,
      total_satisfaction: 83,
      total_importance: 90,
      roi_gap: 7,
      recommendations: [
        'Periodically, check in privately to see how individuals are doing',
        'Conduct a team-building exercises to help individuals get to know each other. Continue these on an ongoing basis to reinforce team bonding. ',
      ],
    },
    {
      type: 'importance',
      question_id: 27,
      statement: 'I have the resources I need to do an effective job.',
      benchmark: 76,
      engaged_percentage: 74,
      neutrals_percentage: 18,
      disengaged_percentage: 8,
      previous_total_satisfaction: 81,
      total_satisfaction: 80,
      total_importance: 90,
      roi_gap: 10,
      recommendations: [
        'Engage in an ongoing discussion with your team to communicate any lack of resources for tasks or projects. ',
        'Arrange for both technical and professional skill training to help the employee be effective in the current role. Provide additional job resources, such as autonomy, social support, or supervisory coaching',
      ],
    },
    {
      type: 'importance',
      question_id: 11,
      statement: 'I enjoy the work that I do.',
      benchmark: 82,
      engaged_percentage: 82,
      neutrals_percentage: 14,
      disengaged_percentage: 4,
      previous_total_satisfaction: 86,
      total_satisfaction: 86,
      total_importance: 89,
      roi_gap: 3,
      recommendations: [
        'Increase job resources, such as autonomy, job variety, opportunities for development, or social support',
        'Ensure the employee has the knowledge, skills, and abilities to meet the demands of the job',
      ],
    },
    {
      type: 'importance',
      question_id: 8,
      statement:
        'The chances of leaving my job voluntarily during the next year are extremely low.',
      benchmark: 60,
      engaged_percentage: 82,
      neutrals_percentage: 10,
      disengaged_percentage: 8,
      previous_total_satisfaction: 87,
      total_satisfaction: 84,
      total_importance: 89,
      roi_gap: 5,
      recommendations: [
        'Schedule a one-on-one with the employee to identify potential actions the company can take to eliminate the employee’s roadblocks for continued employment',
        'Ask them what might be making them consider leaving the job and what could be done to have them stay.',
        'Communicate to the employee other potential opportunities within the company that may be available to them e.g. job rotation, promotions',
      ],
    },
    {
      type: 'importance',
      question_id: 1,
      statement: 'On most days, I feel energized when I am at work.',
      benchmark: 76,
      engaged_percentage: 74,
      neutrals_percentage: 20,
      disengaged_percentage: 6,
      previous_total_satisfaction: 81,
      total_satisfaction: 80,
      total_importance: 89,
      roi_gap: 9,
      recommendations: [
        'Ask employees what they need to feel excited about their work',
        'Lead by example by demonstrating enthusiasm and commitment to your employees and the organization as a whole',
      ],
    },
    {
      type: 'importance',
      question_id: 28,
      statement:
        'My manager gathers my input when making decisions that affect me.',
      benchmark: 77,
      engaged_percentage: 70,
      neutrals_percentage: 18,
      disengaged_percentage: 12,
      previous_total_satisfaction: 81,
      total_satisfaction: 78,
      total_importance: 89,
      roi_gap: 11,
      recommendations: [
        'Provide transparent and regular communication of the decision-making process and encourage feedback.',
        'Ask for employee input when making decisions that personally impact them.',
      ],
    },
    {
      type: 'importance',
      question_id: 37,
      statement: 'My manager is aware of my career goals.',
      benchmark: 74,
      engaged_percentage: 76,
      neutrals_percentage: 16,
      disengaged_percentage: 8,
      previous_total_satisfaction: 84,
      total_satisfaction: 84,
      total_importance: 88,
      roi_gap: 4,
      recommendations: [
        'Encourage employees to write and share both short- and long-term career goals. Track the progress of their career goals and actively align job tasks with goals. ',
        'Schedule one-on-one time with each of your employees to discuss career goals. Identify with each employee a developmental opportunity (job assignment, training, cross-functional team project, etc.) that will help the employee move towards achievement of his or her career goal',
      ],
    },
    {
      type: 'importance',
      question_id: 31,
      statement:
        'My manager keeps me informed about organizational changes that impact me.',
      benchmark: 76,
      engaged_percentage: 82,
      neutrals_percentage: 12,
      disengaged_percentage: 6,
      previous_total_satisfaction: 79,
      total_satisfaction: 82,
      total_importance: 88,
      roi_gap: 6,
      recommendations: [
        'Involve employees in the change process through surveys, conducting interviews, or holding town hall meetings',
        'Provide frequent communications and updates on organizational changes. Have a platform for the team to submit any discrepancies. ',
      ],
    },
    {
      type: 'importance',
      question_id: 25,
      statement:
        'My manager promotes an environment where I feel free to express my opinion.',
      benchmark: 79,
      engaged_percentage: 76,
      neutrals_percentage: 12,
      disengaged_percentage: 12,
      previous_total_satisfaction: 83,
      total_satisfaction: 79,
      total_importance: 88,
      roi_gap: 9,
      recommendations: [
        'Instill a culture that encourages an honest, open work environment that holds space for employees to express their opinions and feel heard.',
        'Have an open communication channel where employees can voice their concerns, suggestions, or offer feedback',
      ],
    },
    {
      type: 'importance',
      question_id: 24,
      statement:
        'I believe employees are treated fairly within this organization.',
      benchmark: 77,
      engaged_percentage: 66,
      neutrals_percentage: 16,
      disengaged_percentage: 18,
      previous_total_satisfaction: 84,
      total_satisfaction: 75,
      total_importance: 88,
      roi_gap: 13,
      recommendations: [
        'Promote fairness in evaluation, compensation, and promotion. Harbor a culture of transparency so your team feels comfortable coming to you if they feel they are being treated unfairly. ',
        'Communicate openly about how decisions are made, take time to develop fair procedures, and provide feedback to employees who might not be happy with the decisions that are made.',
      ],
    },
    {
      type: 'importance',
      question_id: 12,
      statement: 'Working here fits my lifestyle needs.',
      benchmark: 72,
      engaged_percentage: 54,
      neutrals_percentage: 24,
      disengaged_percentage: 22,
      previous_total_satisfaction: 73,
      total_satisfaction: 71,
      total_importance: 88,
      roi_gap: 17,
      recommendations: [
        'Help employees find ways to balance work and personal life',
        'Ensure employees have a work schedule, and a compensation and benefits package most valuable to both them and the company',
      ],
    },
    {
      type: 'importance',
      question_id: 19,
      statement: 'In general, communication within this organization is open.',
      benchmark: 68,
      engaged_percentage: 86,
      neutrals_percentage: 6,
      disengaged_percentage: 8,
      previous_total_satisfaction: 86,
      total_satisfaction: 83,
      total_importance: 87,
      roi_gap: 4,
      recommendations: [
        'Keep all team members informed of decisions and developments affecting the relevant individuals, team, and the project',
        'Encourage transparency, participation, and open discussion in meetings',
      ],
    },
    {
      type: 'importance',
      question_id: 20,
      statement:
        'My department coordinates effectively with other groups to achieve goals.',
      benchmark: 79,
      engaged_percentage: 82,
      neutrals_percentage: 14,
      disengaged_percentage: 4,
      previous_total_satisfaction: 79,
      total_satisfaction: 83,
      total_importance: 87,
      roi_gap: 4,
      recommendations: [
        'Assign people as liaisons to other areas or departments to build inter-team collaboration',
        'Utilize team goal setting and work planning processes when needed.',
        'Utilize job rotation and cross-training',
      ],
    },
    {
      type: 'importance',
      question_id: 32,
      statement:
        'My manager recognizes me in a manner that I personally value.',
      benchmark: 77,
      engaged_percentage: 70,
      neutrals_percentage: 24,
      disengaged_percentage: 6,
      previous_total_satisfaction: 83,
      total_satisfaction: 80,
      total_importance: 87,
      roi_gap: 7,
      recommendations: [
        'Recognize behavior, actions, and efforts, not just results',
        'Ask the employee how he or she prefers to be recognized for a job well done',
      ],
    },
    {
      type: 'importance',
      question_id: 29,
      statement:
        'My working conditions support the results I am expected to achieve.',
      benchmark: 73,
      engaged_percentage: 66,
      neutrals_percentage: 22,
      disengaged_percentage: 12,
      previous_total_satisfaction: 80,
      total_satisfaction: 78,
      total_importance: 87,
      roi_gap: 9,
      recommendations: [
        'Provide the tools, resources, and infrastructure to get the job done',
        'Constantly ask employees, “What can I do to help you succeed?”',
      ],
    },
    {
      type: 'importance',
      question_id: 22,
      statement: 'My manager selects competent people for our team.',
      benchmark: 79,
      engaged_percentage: 62,
      neutrals_percentage: 20,
      disengaged_percentage: 18,
      previous_total_satisfaction: 82,
      total_satisfaction: 76,
      total_importance: 86,
      roi_gap: 10,
      recommendations: [
        'Be clear with recruiters on the job description so they can help choose candidates with the knowledge, skills, and abilities necessary for the role',
        'Involve your team in the recruitment and interviewing effort. Utilize them to establish gaps between skillsets to ensure oncoming team members are a good fit. ',
      ],
    },
    {
      type: 'importance',
      question_id: 2,
      statement:
        'I would refer this organization to a friend as a good place to work.',
      benchmark: 76,
      engaged_percentage: 88,
      neutrals_percentage: 8,
      disengaged_percentage: 4,
      previous_total_satisfaction: 89,
      total_satisfaction: 89,
      total_importance: 85,
      roi_gap: 4,
      recommendations: [
        'Ask your team what would make this a better place to work.',
        'Implement an employee referral program to incentivize, recognize, and reward referrals.',
      ],
    },
    {
      type: 'importance',
      question_id: 23,
      statement: 'I feel valued and appreciated.',
      benchmark: 77,
      engaged_percentage: 62,
      neutrals_percentage: 18,
      disengaged_percentage: 20,
      previous_total_satisfaction: 82,
      total_satisfaction: 74,
      total_importance: 83,
      roi_gap: 9,
      recommendations: [
        'Express appreciation to employees for their contributions to the team and the organization as a whole',
        'Individually recognize employees for their specific achievements in a manner that they personally like to be recognized. ',
      ],
    },
  ],
  engagement_index: [
    {
      driver: { label: 'Engagement' },
      questions: [
        {
          id: 1,
          statement: 'On most days, I feel energized when I am at work.',
          percentages: {
            benchmark: 76,
            engaged: 74,
            neutrals: 20,
            disengaged: 6,
            importance: 89,
            effectiveness: 80,
          },
        },
        {
          id: 2,
          statement:
            'I would refer this organization to a friend as a good place to work.',
          percentages: {
            benchmark: 76,
            engaged: 90,
            neutrals: 8,
            disengaged: 2,
            importance: 85,
            effectiveness: 89,
          },
        },
        {
          id: 3,
          statement: 'I feel motivated to `go the extra mile`.',
          percentages: {
            benchmark: 80,
            engaged: 92,
            neutrals: 6,
            disengaged: 2,
            importance: 91,
            effectiveness: 89,
          },
        },
        {
          id: 4,
          statement:
            'I care about the results that this organization achieves.',
          percentages: {
            benchmark: 91,
            engaged: 96,
            neutrals: 2,
            disengaged: 2,
            importance: 94,
            effectiveness: 95,
          },
        },
        {
          id: 5,
          statement:
            'I feel driven to do what it takes to help this organization succeed.',
          percentages: {
            benchmark: 84,
            engaged: 98,
            neutrals: 2,
            disengaged: 0,
            importance: 93,
            effectiveness: 94,
          },
        },
        {
          id: 6,
          statement: 'I am proud to work for this organization.',
          percentages: {
            benchmark: 83,
            engaged: 96,
            neutrals: 2,
            disengaged: 2,
            importance: 95,
            effectiveness: 94,
          },
        },
        {
          id: 7,
          statement:
            'I feel that I will have a long career at this organization.',
          percentages: {
            benchmark: 79,
            engaged: 84,
            neutrals: 14,
            disengaged: 2,
            importance: 91,
            effectiveness: 88,
          },
        },
        {
          id: 8,
          statement:
            'The chances of leaving my job voluntarily during the next year are extremely low.',
          percentages: {
            benchmark: 60,
            engaged: 82,
            neutrals: 10,
            disengaged: 8,
            importance: 89,
            effectiveness: 84,
          },
        },
      ],
    },
    {
      driver: { label: 'Fit' },
      questions: [
        {
          id: 9,
          statement:
            'The type of work that I do leverages my personal strengths.',
          percentages: {
            benchmark: 82,
            engaged: 86,
            neutrals: 12,
            disengaged: 2,
            importance: 90,
            effectiveness: 89,
          },
        },
        {
          id: 10,
          statement: 'I have clearly defined goals for my job.',
          percentages: {
            benchmark: 81,
            engaged: 82,
            neutrals: 12,
            disengaged: 6,
            importance: 91,
            effectiveness: 85,
          },
        },
        {
          id: 11,
          statement: 'I enjoy the work that I do.',
          percentages: {
            benchmark: 82,
            engaged: 82,
            neutrals: 14,
            disengaged: 4,
            importance: 89,
            effectiveness: 86,
          },
        },
        {
          id: 12,
          statement: 'Working here fits my lifestyle needs.',
          percentages: {
            benchmark: 72,
            engaged: 54,
            neutrals: 24,
            disengaged: 22,
            importance: 88,
            effectiveness: 71,
          },
        },
      ],
    },
    {
      driver: { label: 'Alignment' },
      questions: [
        {
          id: 13,
          statement:
            'I have confidence in the direction that our organization is taking.',
          percentages: {
            benchmark: 74,
            engaged: 92,
            neutrals: 6,
            disengaged: 2,
            importance: 91,
            effectiveness: 90,
          },
        },
        {
          id: 14,
          statement: "I believe in our organization's purpose.",
          percentages: {
            benchmark: 84,
            engaged: 96,
            neutrals: 2,
            disengaged: 2,
            importance: 94,
            effectiveness: 92,
          },
        },
        {
          id: 15,
          statement: 'I have confidence in our senior leadership team.',
          percentages: {
            benchmark: 74,
            engaged: 86,
            neutrals: 8,
            disengaged: 6,
            importance: 90,
            effectiveness: 87,
          },
        },
        {
          id: 16,
          statement: "I am committed to our organization's mission and values.",
          percentages: {
            benchmark: 80,
            engaged: 94,
            neutrals: 4,
            disengaged: 2,
            importance: 91,
            effectiveness: 90,
          },
        },
        {
          id: 17,
          statement: 'I understand the most important goals for my department.',
          percentages: {
            benchmark: 82,
            engaged: 92,
            neutrals: 4,
            disengaged: 4,
            importance: 90,
            effectiveness: 89,
          },
        },
        {
          id: 18,
          statement:
            "I can see how my work directly contributes to this organization's results.",
          percentages: {
            benchmark: 86,
            engaged: 94,
            neutrals: 6,
            disengaged: 0,
            importance: 91,
            effectiveness: 92,
          },
        },
      ],
    },
    {
      driver: { label: 'Team' },
      questions: [
        {
          id: 19,
          statement:
            'In general, communication within this organization is open.',
          percentages: {
            benchmark: 68,
            engaged: 86,
            neutrals: 6,
            disengaged: 8,
            importance: 87,
            effectiveness: 83,
          },
        },
        {
          id: 20,
          statement:
            'My department coordinates effectively with other groups to achieve goals.',
          percentages: {
            benchmark: 79,
            engaged: 82,
            neutrals: 14,
            disengaged: 4,
            importance: 87,
            effectiveness: 83,
          },
        },
        {
          id: 21,
          statement:
            'My manager fosters genuine and trusting relationships on the team.',
          percentages: {
            benchmark: 78,
            engaged: 82,
            neutrals: 10,
            disengaged: 8,
            importance: 90,
            effectiveness: 83,
          },
        },
        {
          id: 22,
          statement: 'My manager selects competent people for our team.',
          percentages: {
            benchmark: 79,
            engaged: 62,
            neutrals: 20,
            disengaged: 18,
            importance: 86,
            effectiveness: 76,
          },
        },
      ],
    },
    {
      driver: { label: 'Valuing' },
      questions: [
        {
          id: 23,
          statement: 'I feel valued and appreciated.',
          percentages: {
            benchmark: 77,
            engaged: 62,
            neutrals: 18,
            disengaged: 20,
            importance: 83,
            effectiveness: 74,
          },
        },
        {
          id: 24,
          statement:
            'I believe employees are treated fairly within this organization.',
          percentages: {
            benchmark: 77,
            engaged: 66,
            neutrals: 16,
            disengaged: 18,
            importance: 88,
            effectiveness: 75,
          },
        },
        {
          id: 25,
          statement:
            'My manager promotes an environment where I feel free to express my opinion.',
          percentages: {
            benchmark: 79,
            engaged: 76,
            neutrals: 12,
            disengaged: 12,
            importance: 88,
            effectiveness: 79,
          },
        },
        {
          id: 26,
          statement: 'My manager is approachable.',
          percentages: {
            benchmark: 80,
            engaged: 84,
            neutrals: 12,
            disengaged: 4,
            importance: 92,
            effectiveness: 86,
          },
        },
        {
          id: 27,
          statement: 'I have the resources I need to do an effective job.',
          percentages: {
            benchmark: 76,
            engaged: 74,
            neutrals: 18,
            disengaged: 8,
            importance: 90,
            effectiveness: 80,
          },
        },
        {
          id: 28,
          statement:
            'My manager gathers my input when making decisions that affect me.',
          percentages: {
            benchmark: 77,
            engaged: 72,
            neutrals: 18,
            disengaged: 10,
            importance: 89,
            effectiveness: 78,
          },
        },
        {
          id: 29,
          statement:
            'My working conditions support the results I am expected to achieve.',
          percentages: {
            benchmark: 73,
            engaged: 66,
            neutrals: 22,
            disengaged: 12,
            importance: 87,
            effectiveness: 78,
          },
        },
        {
          id: 30,
          statement:
            'This organization has a culture of recognition for good performance.',
          percentages: {
            benchmark: 68,
            engaged: 92,
            neutrals: 4,
            disengaged: 4,
            importance: 92,
            effectiveness: 90,
          },
        },
        {
          id: 31,
          statement:
            'My manager keeps me informed about organizational changes that impact me.',
          percentages: {
            benchmark: 76,
            engaged: 82,
            neutrals: 12,
            disengaged: 6,
            importance: 88,
            effectiveness: 82,
          },
        },
        {
          id: 32,
          statement:
            'My manager recognizes me in a manner that I personally value.',
          percentages: {
            benchmark: 77,
            engaged: 70,
            neutrals: 24,
            disengaged: 6,
            importance: 87,
            effectiveness: 80,
          },
        },
        {
          id: 33,
          statement: 'My manager treats me with respect.',
          percentages: {
            benchmark: 83,
            engaged: 82,
            neutrals: 16,
            disengaged: 2,
            importance: 90,
            effectiveness: 87,
          },
        },
      ],
    },
    {
      driver: { label: 'Growth' },
      questions: [
        {
          id: 34,
          statement:
            'This organization has a culture where I can learn new skills and grow.',
          percentages: {
            benchmark: 70,
            engaged: 90,
            neutrals: 8,
            disengaged: 2,
            importance: 95,
            effectiveness: 91,
          },
        },
        {
          id: 35,
          statement:
            'This organization provides me with the tools I need to help me advance my career.',
          percentages: {
            benchmark: 68,
            engaged: 86,
            neutrals: 6,
            disengaged: 8,
            importance: 92,
            effectiveness: 84,
          },
        },
        {
          id: 36,
          statement:
            'My manager supports my professional growth and development.',
          percentages: {
            benchmark: 77,
            engaged: 82,
            neutrals: 14,
            disengaged: 4,
            importance: 90,
            effectiveness: 85,
          },
        },
        {
          id: 37,
          statement: 'My manager is aware of my career goals.',
          percentages: {
            benchmark: 74,
            engaged: 80,
            neutrals: 16,
            disengaged: 4,
            importance: 88,
            effectiveness: 84,
          },
        },
        {
          id: 38,
          statement:
            'My manager provides me with regular feedback to help me improve my performance.',
          percentages: {
            benchmark: 74,
            engaged: 70,
            neutrals: 20,
            disengaged: 10,
            importance: 91,
            effectiveness: 81,
          },
        },
      ],
    },
    { driver: { label: 'Custom Ratings Questions' }, questions: [] },
  ],
  previous_engagement_index: [
    {
      driver: { label: 'Engagement' },
      questions: [
        {
          id: 1,
          statement: 'On most days, I feel energized when I am at work.',
          percentages: {
            benchmark: 76,
            engaged: 81,
            neutrals: 13,
            disengaged: 6,
            importance: 89,
            effectiveness: 81,
          },
        },
        {
          id: 2,
          statement:
            'I would refer this organization to a friend as a good place to work.',
          percentages: {
            benchmark: 76,
            engaged: 87,
            neutrals: 13,
            disengaged: 0,
            importance: 87,
            effectiveness: 89,
          },
        },
        {
          id: 3,
          statement: 'I feel motivated to `go the extra mile`.',
          percentages: {
            benchmark: 80,
            engaged: 87,
            neutrals: 13,
            disengaged: 0,
            importance: 92,
            effectiveness: 88,
          },
        },
        {
          id: 4,
          statement:
            'I care about the results that this organization achieves.',
          percentages: {
            benchmark: 91,
            engaged: 100,
            neutrals: 0,
            disengaged: 0,
            importance: 95,
            effectiveness: 96,
          },
        },
        {
          id: 5,
          statement:
            'I feel driven to do what it takes to help this organization succeed.',
          percentages: {
            benchmark: 84,
            engaged: 97,
            neutrals: 3,
            disengaged: 0,
            importance: 95,
            effectiveness: 94,
          },
        },
        {
          id: 6,
          statement: 'I am proud to work for this organization.',
          percentages: {
            benchmark: 83,
            engaged: 97,
            neutrals: 3,
            disengaged: 0,
            importance: 93,
            effectiveness: 95,
          },
        },
        {
          id: 7,
          statement:
            'I feel that I will have a long career at this organization.',
          percentages: {
            benchmark: 79,
            engaged: 78,
            neutrals: 19,
            disengaged: 3,
            importance: 91,
            effectiveness: 84,
          },
        },
        {
          id: 8,
          statement:
            'The chances of leaving my job voluntarily during the next year are extremely low.',
          percentages: {
            benchmark: 60,
            engaged: 84,
            neutrals: 13,
            disengaged: 3,
            importance: 86,
            effectiveness: 87,
          },
        },
      ],
    },
    {
      driver: { label: 'Fit' },
      questions: [
        {
          id: 9,
          statement:
            'The type of work that I do leverages my personal strengths.',
          percentages: {
            benchmark: 82,
            engaged: 94,
            neutrals: 3,
            disengaged: 3,
            importance: 90,
            effectiveness: 86,
          },
        },
        {
          id: 10,
          statement: 'I have clearly defined goals for my job.',
          percentages: {
            benchmark: 81,
            engaged: 84,
            neutrals: 10,
            disengaged: 6,
            importance: 91,
            effectiveness: 86,
          },
        },
        {
          id: 11,
          statement: 'I enjoy the work that I do.',
          percentages: {
            benchmark: 82,
            engaged: 84,
            neutrals: 13,
            disengaged: 3,
            importance: 91,
            effectiveness: 86,
          },
        },
        {
          id: 12,
          statement: 'Working here fits my lifestyle needs.',
          percentages: {
            benchmark: 72,
            engaged: 52,
            neutrals: 32,
            disengaged: 16,
            importance: 90,
            effectiveness: 73,
          },
        },
      ],
    },
    {
      driver: { label: 'Alignment' },
      questions: [
        {
          id: 13,
          statement:
            'I have confidence in the direction that our organization is taking.',
          percentages: {
            benchmark: 74,
            engaged: 87,
            neutrals: 13,
            disengaged: 0,
            importance: 95,
            effectiveness: 90,
          },
        },
        {
          id: 14,
          statement: "I believe in our organization's purpose.",
          percentages: {
            benchmark: 84,
            engaged: 94,
            neutrals: 6,
            disengaged: 0,
            importance: 88,
            effectiveness: 92,
          },
        },
        {
          id: 15,
          statement: 'I have confidence in our senior leadership team.',
          percentages: {
            benchmark: 74,
            engaged: 87,
            neutrals: 10,
            disengaged: 3,
            importance: 93,
            effectiveness: 88,
          },
        },
        {
          id: 16,
          statement: "I am committed to our organization's mission and values.",
          percentages: {
            benchmark: 80,
            engaged: 94,
            neutrals: 6,
            disengaged: 0,
            importance: 88,
            effectiveness: 92,
          },
        },
        {
          id: 17,
          statement: 'I understand the most important goals for my department.',
          percentages: {
            benchmark: 82,
            engaged: 87,
            neutrals: 13,
            disengaged: 0,
            importance: 92,
            effectiveness: 90,
          },
        },
        {
          id: 18,
          statement:
            "I can see how my work directly contributes to this organization's results.",
          percentages: {
            benchmark: 86,
            engaged: 97,
            neutrals: 3,
            disengaged: 0,
            importance: 94,
            effectiveness: 93,
          },
        },
      ],
    },
    {
      driver: { label: 'Team' },
      questions: [
        {
          id: 19,
          statement:
            'In general, communication within this organization is open.',
          percentages: {
            benchmark: 68,
            engaged: 84,
            neutrals: 16,
            disengaged: 0,
            importance: 88,
            effectiveness: 86,
          },
        },
        {
          id: 20,
          statement:
            'My department coordinates effectively with other groups to achieve goals.',
          percentages: {
            benchmark: 79,
            engaged: 74,
            neutrals: 16,
            disengaged: 10,
            importance: 89,
            effectiveness: 79,
          },
        },
        {
          id: 21,
          statement:
            'My manager fosters genuine and trusting relationships on the team.',
          percentages: {
            benchmark: 78,
            engaged: 81,
            neutrals: 16,
            disengaged: 3,
            importance: 90,
            effectiveness: 88,
          },
        },
        {
          id: 22,
          statement: 'My manager selects competent people for our team.',
          percentages: {
            benchmark: 79,
            engaged: 78,
            neutrals: 16,
            disengaged: 6,
            importance: 90,
            effectiveness: 82,
          },
        },
      ],
    },
    {
      driver: { label: 'Valuing' },
      questions: [
        {
          id: 23,
          statement: 'I feel valued and appreciated.',
          percentages: {
            benchmark: 77,
            engaged: 75,
            neutrals: 19,
            disengaged: 6,
            importance: 92,
            effectiveness: 82,
          },
        },
        {
          id: 24,
          statement:
            'I believe employees are treated fairly within this organization.',
          percentages: {
            benchmark: 77,
            engaged: 81,
            neutrals: 16,
            disengaged: 3,
            importance: 88,
            effectiveness: 84,
          },
        },
        {
          id: 25,
          statement:
            'My manager promotes an environment where I feel free to express my opinion.',
          percentages: {
            benchmark: 79,
            engaged: 84,
            neutrals: 6,
            disengaged: 10,
            importance: 84,
            effectiveness: 83,
          },
        },
        {
          id: 26,
          statement: 'My manager is approachable.',
          percentages: {
            benchmark: 80,
            engaged: 77,
            neutrals: 23,
            disengaged: 0,
            importance: 89,
            effectiveness: 85,
          },
        },
        {
          id: 27,
          statement: 'I have the resources I need to do an effective job.',
          percentages: {
            benchmark: 76,
            engaged: 78,
            neutrals: 19,
            disengaged: 3,
            importance: 90,
            effectiveness: 81,
          },
        },
        {
          id: 28,
          statement:
            'My manager gathers my input when making decisions that affect me.',
          percentages: {
            benchmark: 77,
            engaged: 77,
            neutrals: 13,
            disengaged: 10,
            importance: 88,
            effectiveness: 81,
          },
        },
        {
          id: 29,
          statement:
            'My working conditions support the results I am expected to achieve.',
          percentages: {
            benchmark: 73,
            engaged: 74,
            neutrals: 13,
            disengaged: 13,
            importance: 90,
            effectiveness: 80,
          },
        },
        {
          id: 30,
          statement:
            'This organization has a culture of recognition for good performance.',
          percentages: {
            benchmark: 68,
            engaged: 87,
            neutrals: 10,
            disengaged: 3,
            importance: 92,
            effectiveness: 90,
          },
        },
        {
          id: 31,
          statement:
            'My manager keeps me informed about organizational changes that impact me.',
          percentages: {
            benchmark: 76,
            engaged: 67,
            neutrals: 23,
            disengaged: 10,
            importance: 89,
            effectiveness: 79,
          },
        },
        {
          id: 32,
          statement:
            'My manager recognizes me in a manner that I personally value.',
          percentages: {
            benchmark: 77,
            engaged: 78,
            neutrals: 19,
            disengaged: 3,
            importance: 90,
            effectiveness: 83,
          },
        },
        {
          id: 33,
          statement: 'My manager treats me with respect.',
          percentages: {
            benchmark: 83,
            engaged: 81,
            neutrals: 19,
            disengaged: 0,
            importance: 90,
            effectiveness: 88,
          },
        },
      ],
    },
    {
      driver: { label: 'Growth' },
      questions: [
        {
          id: 34,
          statement:
            'This organization has a culture where I can learn new skills and grow.',
          percentages: {
            benchmark: 70,
            engaged: 90,
            neutrals: 10,
            disengaged: 0,
            importance: 92,
            effectiveness: 91,
          },
        },
        {
          id: 35,
          statement:
            'This organization provides me with the tools I need to help me advance my career.',
          percentages: {
            benchmark: 68,
            engaged: 81,
            neutrals: 16,
            disengaged: 3,
            importance: 92,
            effectiveness: 85,
          },
        },
        {
          id: 36,
          statement:
            'My manager supports my professional growth and development.',
          percentages: {
            benchmark: 77,
            engaged: 87,
            neutrals: 10,
            disengaged: 3,
            importance: 92,
            effectiveness: 86,
          },
        },
        {
          id: 37,
          statement: 'My manager is aware of my career goals.',
          percentages: {
            benchmark: 74,
            engaged: 87,
            neutrals: 3,
            disengaged: 10,
            importance: 90,
            effectiveness: 84,
          },
        },
        {
          id: 38,
          statement:
            'My manager provides me with regular feedback to help me improve my performance.',
          percentages: {
            benchmark: 74,
            engaged: 58,
            neutrals: 26,
            disengaged: 16,
            importance: 90,
            effectiveness: 76,
          },
        },
      ],
    },
    { driver: { label: 'Custom Ratings Questions' }, questions: [] },
  ],
}
