import { ReactNode } from 'react'

import { Grid } from '@mui/material'

import { initialDialog } from 'constants/dialog'
import DialogTeleport from './DialogTeleport'
import { DialogData } from 'types/dialog'

type Props = {
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  children: ReactNode
  previewDialog: DialogData
  setPreviewDialog: (value: DialogData) => void
}

export default function SurveyPreviewDialog({
  size,
  children,
  previewDialog,
  setPreviewDialog,
}: Props) {
  return (
    <DialogTeleport
      dialogOpen={previewDialog.isOpen}
      dialogSize={size}
      dialogTitle=''
      handleDialogClose={() => setPreviewDialog(initialDialog)}
    >
      <Grid container>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </DialogTeleport>
  )
}
