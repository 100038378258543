import { useEffect, useState } from 'react'

import { Typography } from '@mui/material'

import comingSoonImage from 'assets/images/versions/version2_0/coming-soon.png'
import newSparckImage from 'assets/images/versions/version2_0/new-sparck.png'
import remindersImage from 'assets/images/versions/version2_0/reminders.png'
import rewardsImage from 'assets/images/versions/version2_0/rewards.png'
import sparckAppreciationStyleImage from 'assets/images/versions/version2_0/sparck-appreciation-style.png'

import { PopupSteps } from 'components'

const steps = [
  {
    id: 'welcome',
    label: 'Welcome to Sparck 2.0!',
    description:
      "We've redesigned Sparck with improved functionality and made it easier to use! We are very excited to share what's new in the next slides.",
    image: comingSoonImage,
  },
  {
    id: 'step2',
    label: 'Dashboard & Feed',
    CustomDescription: (
      <ul>
        <Typography component='li' variant='body1' color='text.secondary'>
          Birthday and Workiversary Reminders
        </Typography>

        <Typography component='li' variant='body1' color='text.secondary'>
          Recognition will be even more fun with the ability to now add giphy's,
          video, and pictures just like you can with posts.
        </Typography>

        <Typography component='li' variant='body1' color='text.secondary'>
          Tag multiple people in post and recognition messages
        </Typography>

        <Typography component='li' variant='body1' color='text.secondary'>
          Recognize multiple people at once!
        </Typography>
      </ul>
    ),
    image: newSparckImage,
  },
  {
    id: 'step3',
    label: 'Integrations & Notifications',
    CustomDescription: (
      <>
        <Typography variant='body1' color='text.secondary'>
          Officially meet Sparcky the Dog, your new furry partner! He will join
          you in Slack or MS Teams to keep you connected and notified with your
          team through real-time alerts and notifications.
        </Typography>

        <ul>
          <Typography component='li' variant='body1' color='text.secondary'>
            Sparcky will never let you forget those important moments, like team
            member who publically share their birthdays and work anniversaries.
            He will also notify you of personal recognitions, messages, posts,
            and so much more!
          </Typography>

          <Typography component='li' variant='body1' color='text.secondary'>
            All notifications will include a custom link so you are directed to
            the specific screen to take action and save time.
          </Typography>
        </ul>
      </>
    ),
    image: remindersImage,
  },
  {
    id: 'step4',
    label: 'Rewards Updates',
    CustomDescription: (
      <ul>
        <Typography component='li' variant='body1' color='text.secondary'>
          Easily access your e-gift cards directly from your profile page under
          rewards. You have the option to bank your reward dollars or exchange
          it for a different retailer.
        </Typography>

        <Typography component='li' variant='body1' color='text.secondary'>
          Track rewards that are purchased outside of Sparck. Follow internal
          processes to submit those and we will track it in your budget for you.
        </Typography>

        <Typography component='li' variant='body1' color='text.secondary'>
          Coming in April, gain access to over 2,000 experiential rewards like
          wine tasting, hot air balloon rides, and so much more.
        </Typography>
      </ul>
    ),
    image: rewardsImage,
  },
  {
    id: 'step5',
    label: 'Sparck Appreciation Assessment Update',
    CustomDescription: (
      <Typography variant='body1' color='text.secondary'>
        If you want to share the Sparck Appreciation Assessment with your family
        and friends to help them uncover their “inner Sparck” feel free to share
        this link:{' '}
        <a
          href='https://my.sparckco.com/sparck-appreciation-assessment'
          target='_blank'
          rel='noopener noreferrer'
        >
          https://my.sparckco.com/sparck-appreciation-assessment
        </a>
      </Typography>
    ),
    image: sparckAppreciationStyleImage,
  },
  {
    id: 'step6',
    label: 'Coming Soon!',
    CustomDescription: (
      <>
        <Typography variant='body1' color='text.secondary'>
          We are working hard to give you the best experience - expect new
          features in the upcoming weeks, such as:
        </Typography>

        <ul>
          <Typography component='li' variant='body1' color='text.secondary'>
            MS Teams Fully integrated
          </Typography>

          <Typography component='li' variant='body1' color='text.secondary'>
            Manager Certification process integrated with our Sparck platform
          </Typography>

          <Typography component='li' variant='body1' color='text.secondary'>
            and more!
          </Typography>
        </ul>
      </>
    ),
    image: comingSoonImage,
  },
]

const LS_USER_VIEWED_RELEASE_NOTES_2_0 = 'user_viewed_release_notes_2_0_2'

const TODAY = new Date()
const MAX_DATE_TO_DISPLAY_RELEASE = new Date('04/10/2022')

export default function Release2_0() {
  const [dialogOpen, setDialogOpen] = useState(false)

  useEffect(() => {
    if (
      !localStorage.getItem(LS_USER_VIEWED_RELEASE_NOTES_2_0) &&
      TODAY < MAX_DATE_TO_DISPLAY_RELEASE
    ) {
      setDialogOpen(true)
    }
  }, [])

  return (
    <PopupSteps
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      steps={steps}
      localStorageKey={LS_USER_VIEWED_RELEASE_NOTES_2_0}
    />
  )
}
