import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { API } from 'core/requests'

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  styled,
  TextField,
  Tooltip,
  Typography,
  useTheme,
  Autocomplete,
} from '@mui/material'
import { 
  InfoOutlined as InfoIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon, 
} from '@mui/icons-material'
// import { DatePicker } from '@mui/lab'
import { addDays } from 'date-fns'
import { useIntl } from 'react-intl'

import {
  MoneyInput,
  // CRUDTable,
  CRUDTableDND,
  DialogTeleport,
  SurveyPreviewDialog,
  DemographicsPicker,
  RolesPicker,
  UserAvatar,
} from 'components'

// import ManageSurveyQuestions from '../ManageSurveyQuestions/ManageSurveyQuestions'
import { SurveyStatus } from 'enums/survey'
import { t } from 'i18n'
import { Surveys } from 'pages'
import SurveyDriverDialog from 'components/SurveyDriverDialog'
import { data } from './driverQuestion'
import DriversComponent from './Drivers'
import { timezones } from 'constants/company'
import {
  DateTimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)

const RATING_QUESTIONS_LIMIT = 50
const OPEN_ENDED_QUESTIONS_LIMIT = 50

export default function ManageSurveyForm({
  formik,
  isClone,
  demographics,
  demographicsIsLoading,
  ratingQuestions,
  setRatingQuestions,
  openEndedQuestions,
  setOpenEndedQuestions,
  previewDialog,
  setPreviewDialog,
  companyState,
  setCompanyState
}: any) {
  const intl = useIntl()

  // MJ Changes START
  // const token = (JSON.stringify(localStorage.getItem('sparck_user_token'))).split('"').join('')
  // console.log(token)
  // const [manager, setManager] = useState([])
  // console.log(manager)
  const [check, setCheck] = useState(true)
  const [companyTimezone, ] = useState(companyState.timezone)
  
  const managercheck = () => {
    if(check) {
      setCheck(false)
      formik.values.manager_alt = ''
    } else {
      setCheck(true)
    }
  }
  {
    /*
  const fetchData = async () => {
    return await fetch('https://api.sparckco.com/v1/companies/1', {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(resp => resp.json())
      .then(json => setManager(json))
  }
  useEffect(() => {
    fetchData();
  }, [])

  */
  }
  const theme: any = useTheme()

  const [noNGCSetupDialogOpen, setNoNGCSetupDialogOpen] = useState(false)

  const [driverDialog, setDriverDialog] = useState({ isOpen: false, data: '' })

  const { data: roles } = useQuery('getRoles', () => API.get('roles'))

  const { data: employees, isLoading: employeesIsLoading } = useQuery(
    'employees',
    () => API.get('employeesAutocomplete'),
  )

  const [excludedEmployees, setExcludedEmployees] = useState<any>([]);

  // const { data: pulseLibraryQuestions } = useQuery(
  //   'getPulseLibraryQuestions',
  //   () => API.get('pulseLibraryQuestions'),
  // )

  // const [pulseQuestions, setPulseQuestions] = useState<
  //   {
  //     statement: string
  //     show_importance: boolean
  //     open_ended: boolean
  //   }[]
  // >(data?.questions_attributes ?? [])

  // const savedEmployees = formik?.values?.excluded_employees || []

  {
    /*
  const handleAllowReward = () => {
    if (company?.ngc_account_id) {
      formik.setFieldValue('allow_reward', !formik.values.allow_reward)
    } else {
      setNoNGCSetupDialogOpen(true)
    }
  }
  */
  }

  const StyledDatepicker = styled(DateTimePicker)(() => ({
    width: '100%'
  }))

  useEffect(() => {
    if (!formik?.values?.status) return

    if (formik?.values?.status === SurveyStatus.AwaitingManualOpening) {
      formik.setFieldValue('open_at', null)
      formik.setFieldValue('close_at', null)
    }

    if (formik?.values?.status === SurveyStatus.Scheduled) {
      const openAt = new Date()
      openAt.setHours(openAt.getHours()+1,0,0)
      formik.setFieldValue('open_at', dayjs(formik?.values?.open_at).tz(companyState.timezone) ?? openAt)
      formik.setFieldValue(
        'close_at',
        dayjs(formik?.values?.close_at).tz(companyState.timezone) ?? addDays(new Date().setHours(0,0,0), 5),
      )
    }

    setExcludedEmployees(formik?.values?.excluded_employees || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values.status])

  useEffect(() => {
    if (demographics?.length > 0) {
      const savedCategories = formik.values.demographics?.map(d => d.id) || []
      // const savedCategories: any = [];

      const newDemographics = demographics
        .filter(demographic => !savedCategories.includes(demographic.id))
        .map(demographic => ({
          category: demographic.id,
          demographics: [],
        }))

      formik.setFieldValue('demographics', [
        ...(formik.values.demographics ?? []),
        ...newDemographics,
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demographics])

  useEffect(() => {
    if (isClone) {
      formik.setFieldValue('name', '')
      formik.setFieldValue('status', null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClone])
  
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id='name'
              name='name'
              label={t('surveyDialogs.formLabels.surveyName')}
              placeholder='#BeHeard Survey 001'
              variant='outlined'
              data-cy='beHeardName'
              value={formik.values.name}
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} my={1}>
            <Grid
              container
              p={2}
              sx={{
                border: `1px solid ${theme.palette.text.veryLight}`,
                borderRadius: 2,
              }}
            >
              <Grid item>
                <FormControl
                  error={formik.touched.status && formik.errors.status}
                  variant='standard'
                >
                  <FormLabel id='launch-type-radio-buttons-group-label'>
                    {t('surveyLaunchMode.label')}
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby='launch-type-radio-buttons-group'
                    name='status'
                    value={formik.values.status ?? null}
                    onChange={formik.handleChange}
                    sx={{ mt: 1 }}
                  >
                    <FormControlLabel
                      value={SurveyStatus.AwaitingManualOpening}
                      control={<Radio />}
                      label={t('surveyLaunchMode.manual.label')}
                    />

                    <FormControlLabel
                      value={SurveyStatus.Scheduled}
                      control={<Radio />}
                      label={t('surveyLaunchMode.scheduled.label')}
                    />
                  </RadioGroup>
                  {formik.touched.status && formik.errors.status && (
                    <FormHelperText>{formik.errors.status}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item my={2}>
                <Typography variant='body2' color='text.secondary'>
                  {formik.values.status ===
                    SurveyStatus.AwaitingManualOpening &&
                    t('surveyLaunchMode.manual.description', {
                      launchButtonLabel: t('surveyOverview.launchSurvey'),
                    })}

                  {formik.values.status === SurveyStatus.Scheduled &&
                    t('surveyLaunchMode.scheduled.description', {
                      launchButtonLabel: t('surveyOverview.launchSurvey'),
                    })}
                </Typography>
              </Grid>

              {formik.values.status === SurveyStatus.Scheduled && (
                <Grid item xs={12} mt={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <StyledDatepicker
                        label={t('surveyDialogs.formLabels.launchDate')}
                        value={dayjs(formik.values.open_at)}
                        disablePast
                        onChange={(value: any) => {
                          formik.setFieldValue('open_at', dayjs(value))
                        }}
                        views={['year', 'day', 'hours']}
                        timezone={companyTimezone}
                      />
                      {formik.touched.open_at && Boolean(formik.errors.open_at) && (
                        <FormHelperText>{formik.errors.open_at}</FormHelperText>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <StyledDatepicker
                        label={t('surveyDialogs.formLabels.closeDate')}
                        value={dayjs(formik.values.close_at)}
                        disablePast
                        onChange={(value: any) => {
                          formik.setFieldValue('close_at', dayjs(value))
                        }}
                        minDate={dayjs(formik.values.open_at)}
                        views={['year', 'day', 'hours']}
                        timezone={companyTimezone}
                      />
                      {formik.touched.close_at && Boolean(formik.errors.close_at) && (
                        <FormHelperText>{formik.errors.open_at}</FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        select
                        data-cy='timezone'
                        id='timezone'
                        name='timezone'
                        label={intl.formatMessage({
                          id: 'settings.formLabels.timezone',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'settings.placeholders.timezone',
                        })}
                        variant='outlined'
                        fullWidth
                        margin='normal'
                        style={{ margin: '0px' }}
                        value={companyState.timezone}
                        onChange={event =>
                          setCompanyState({
                            ...companyState,
                            timezone: event.target.value,
                          })
                        }
                        disabled
                      >
                        {timezones.map(timezone => (
                          <MenuItem
                            key={timezone.value}
                            value={timezone.value}
                            data-cy='timezone_option'
                          >
                            {timezone.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          {/*
            <Grid
              item
              xs={12}
              sm='auto'
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <FormControlLabel
                key={'allow_reward'}
                control={
                  <Checkbox
                    data-cy='allow_reward'
                    checked={!!formik.values.allow_reward}
                    onChange={() => handleAllowReward()}
                    name={'allow_reward'}
                  />
                }
                label={
                  <Typography>
                    <Grid container alignItems='center' component='span'>
                      {t('surveyDialogs.formLabels.allowReward')}{' '}
                      <Tooltip
                        title={t('surveyDialogs.formLabels.allowRewardTooltip')}
                      >
                        <InfoIcon style={{ color: '#AAA', marginLeft: 8 }} />
                      </Tooltip>
                    </Grid>
                  </Typography>
                }
              />
            </Grid> 
          */}

          {formik.values.allow_reward && (
            <Grid item xs={12} sm>
              <TextField
                data-cy='reward_amount'
                id='reward_amount'
                name='reward_amount'
                label={t('surveyDialogs.formLabels.rewardAmount')}
                variant='outlined'
                value={formik.values.reward_amount}
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.reward_amount &&
                  Boolean(formik.errors.reward_amount)
                }
                helperText={
                  formik.touched.reward_amount && formik.errors.reward_amount
                }
                fullWidth
                InputProps={{
                  inputComponent: MoneyInput,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          )}

          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <FormControlLabel
              key={'allow_answers_to_be_updated'}
              control={
                <Checkbox
                  data-cy='allow_answers_to_be_updated'
                  checked={!!formik.values.allow_answers_to_be_updated}
                  onChange={formik.handleChange}
                  name={'allow_answers_to_be_updated'}
                />
              }
              label={
                <Typography>
                  <Grid container alignItems='center' component='span'>
                    {t('surveyDialogs.formLabels.allowAnswersToBeUpdated')}{' '}
                    <Tooltip
                      title={t(
                        'surveyDialogs.formLabels.allowAnswersToBeUpdatedTooltip',
                      )}
                    >
                      <InfoIcon style={{ color: '#AAA', marginLeft: 8 }} />
                    </Tooltip>
                  </Grid>
                </Typography>
              }
            />
          </Grid>

          {/* this area to give possibility to write something else lable instead of manager. */}
          <Grid
            item
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <FormControlLabel
              key={'allowManagerAlternativeText'}
              control={
                <Checkbox
                  data-cy='allowManagerAlternativeText'
                  checked={check}
                  name='allowManagerAlternativeText'
                  onChange={managercheck}
                />
              }
              label={
                <Typography>
                  <Grid container alignItems='center' component='span'>
                    {t('surveyLaunchMode.changeManagerText.label')}{' '}
                    <Tooltip
                      title={t(
                        'surveyLaunchMode.changeManagerText.toolTipDescription',
                      )}
                    >
                      <InfoIcon style={{ color: '#AAA', marginLeft: 4 }} />
                    </Tooltip>
                  </Grid>
                </Typography>
              }
            />
          </Grid>
          <Grid mt={5}>
            <TextField
              id='manager_alt'
              name='manager_alt'
              label='Enter Text'
              placeholder='Director'
              variant='outlined'
              data-cy='beHeardLeaderName'
              value={formik.values.manager_alt}
              onChange={formik.handleChange}
              // error={formik.touched.name && Boolean(formik.errors.name)}
              onBlur={formik.handleBlur}
              disabled={(check) ? false : true}
              hidden={(check) ? false : true}
            />
          </Grid>

          <Grid item xs={12}>
            <DemographicsPicker
              key='demographics'
              fieldName='demographics'
              formik={formik}
              demographics={demographics}
              demographicsIsLoading={demographicsIsLoading}
              label={t('surveyDialogs.demographicsPickerLabel')}
            />
          </Grid>

          <Grid item xs={12}>
            <RolesPicker
              fieldName='roles'
              roles={roles}
              formik={formik}
              selectLabel={t(
                'surveyDialogs.rolesPicker.selectLabel',
              )}
              label={t('surveyDialogs.rolesPicker.label')}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="excluded_employees"
              loading={employeesIsLoading}
              loadingText={t('loading')}
              options={employees || []}
              disableCloseOnSelect
              value={excludedEmployees}
              isOptionEqualToValue={(value, option) => {
                return value.id === option;
              }}
              onChange={(event, value: any) => {
                let selectedEmployeeIds: any = [];
                value.map(employee => {
                  if(typeof employee === 'object'){
                    if(!excludedEmployees.includes(employee.id)){
                      selectedEmployeeIds.push(employee.id)
                    } else {
                      selectedEmployeeIds = excludedEmployees.filter(excludedEmployee => excludedEmployee !== employee.id)
                    }
                  } else {
                    selectedEmployeeIds.push(employee)
                  }
                  // return (typeof employee === 'object') ? employee.id : employee
                });
                
                setExcludedEmployees(selectedEmployeeIds)
                formik.setFieldValue("excluded_employees", selectedEmployeeIds)
              }}
              getOptionLabel={(option: any) => {
                if(typeof option !== 'object') {
                  const selectedEmployee = (employees ?? []).filter(employee => employee.id === option)
                  return `${selectedEmployee[0]?.first_name} ${selectedEmployee[0]?.last_name}`
                }
                return ''
                // return `${option?.first_name} ${option?.last_name}`
              }}
              renderOption={(props, option) => (
                <Box
                  component='li'
                  sx={{
                    '& > img': { mr: 3, flexShrink: 0 },
                    '&.MuiAutocomplete-option[aria-selected="true"]': {
                      backgroundColor: 'transparent',
                      width: '100%',
                    },
                  }}
                  {...props}
                  key={option.id}
                >
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                    checkedIcon={<CheckBoxIcon fontSize='small' />}
                    style={{ marginRight: 8, marginLeft: -16 }}
                    // checked={(employees ?? []).map(employee => employee.id)
                    //   .includes(option.id)}
                    // checked={(formik?.values?.excluded_employees ?? []).map(employee => employee.id)
                    //     .includes(option.id)}
                    // checked={(excludedEmployees).map(employee => employee.id)
                    //   .includes(option.id)}
                    checked={excludedEmployees.includes(option.id)}
                  />

                  <UserAvatar
                    name={option?.first_name}
                    src={option?.avatar?.url}
                    size={24}
                  />

                  <Box component='span' ml={1}>
                    {`${option?.first_name} ${option?.last_name}`}
                  </Box>
                </Box>
              )}
              renderInput={(params) => (
                <TextField  {...params}
                  label={t(
                    'surveyDialogs.employeesPicker.selectLabel',
                  )} 
                  variant='outlined'
                  // required={(formik?.values?.excluded_employees?.length === 0)}
                  name='excluded_employees'
                />
              )}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <Box width={1} my={5}>
              <Typography variant='h4' color='text.primary' paragraph>
                {t('surveyDialogs.customizedQuestions.header')}
              </Typography>

              <Typography variant='body1' color='text.secondary' paragraph>
                {t('surveyDialogs.customizedQuestions.description')}{' '}
                <a
                  style={{ fontSize: '20px' }}
                  href='https://faq.sparckco.com/knowledge/include-custom-questions-in-your-beheard-survey'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  {t('surveyDialogs.customizedQuestions.link')}
                </a>
                .
              </Typography>
            </Box>
          </Grid>

          {/* <Grid item xs={12}>
            <ManageSurveyQuestions
              pulseQuestions={pulseQuestions}
              setPulseQuestions={setPulseQuestions}
              pulseLibraryQuestions={pulseLibraryQuestions}
            />
          </Grid> */}

          <Grid item xs={12}>
            <Typography variant='h6' color='text.secondary'>
              {t('surveyDialogs.customizedQuestions.fiveStarQuestions.header')}
            </Typography>
          </Grid>
          <Grid item xs={12} data-cy='starRatingQuestion'>
            <CRUDTableDND
              rows={ratingQuestions}
              setRows={setRatingQuestions}
              columns={[
                {
                  id: 'statement',
                  // label: t('surveyDialogs.customizedQuestions.statement'),
                  label: '',
                  type: 'text',
                  align: 'left',
                  required: true,
                  disabled: () => false,
                  showEdit: () => true,
                  defaultValue: '',
                  textFieldProps: {
                    placeholder: intl.formatMessage({
                      id: 'surveyDialogs.customizedQuestions.statementPlaceholder',
                    }),
                  },
                },
              ]}
              surveyFlag={true}
              limit={RATING_QUESTIONS_LIMIT}
              showAdd
              showEdit={() => true}
              showRemove={() => true}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant='h6' color='text.secondary'>
              {t('surveyDialogs.customizedQuestions.openEndedQuestions.header')}
            </Typography>
          </Grid>
          <Grid item xs={12} data-cy='openEndedQuestion'>
            <CRUDTableDND
              rows={openEndedQuestions}
              setRows={setOpenEndedQuestions}
              columns={[
                {
                  id: 'statement',
                  // label: t('surveyDialogs.customizedQuestions.statement'),
                  label: '',
                  type: 'text',
                  align: 'left',
                  required: true,
                  disabled: () => false,
                  showEdit: () => true,
                  defaultValue: '',
                  textFieldProps: {
                    placeholder: intl.formatMessage({
                      id: 'surveyDialogs.customizedQuestions.statementPlaceholder',
                    }),
                  },
                },
              ]}
              surveyFlag={true}
              limit={OPEN_ENDED_QUESTIONS_LIMIT}
              showAdd
              showEdit={() => true}
              showRemove={() => true}
            />
          </Grid>
        </Grid>

        <DialogTeleport
          dialogOpen={noNGCSetupDialogOpen}
          dialogSize='sm'
          dialogTitle={'Set up Rewards'}
          handleDialogClose={() => setNoNGCSetupDialogOpen(false)}
        >
          <Typography variant='body1'>
            Do you want to offer rewards for completing your BeHeard survey?
            Click{' '}
            <Link
              href='https://faq.sparckco.com/knowledge/how-do-i-set-up-rewards-in-sparck'
              target='_blank'
            >
              Get Started
            </Link>{' '}
            to view the set up process.
          </Typography>
        </DialogTeleport>

        <SurveyPreviewDialog
          size='lg'
          previewDialog={previewDialog}
          setPreviewDialog={setPreviewDialog}
        >
          <Surveys preview={previewDialog.data} />
        </SurveyPreviewDialog>

        {driverDialog.isOpen && (
          <SurveyDriverDialog
            size='lg'
            driverDialog={driverDialog}
            setDriverDialog={setDriverDialog}
          >
            {/* 
              <Typography>
                {data[driverDialog.data].map(res => (
                  <Grid container alignItems='center' component='span'>
                    <h4>{res}</h4>{'           '}
                  </Grid>
                ))}
              </Typography> 
            */}

            <DriversComponent driverQuestions={data[driverDialog.data]} />
          </SurveyDriverDialog>
        )}
      </LocalizationProvider>
    </>
  )
}
