import { useEffect, useState } from 'react'

import { Grid } from '@mui/material'

import { CRUDTable, DialogTeleport } from 'components'
import { Props as VirginExperienceProps } from 'pages/virginExperiencesOrganizational/VirginExperiencesOrganizational.props'

export default function VirginExperiencesOrganizationalUpdateAlertsDialog(
  props: VirginExperienceProps,
) {
  const {
    company,
    virginExperiencesOrganizational,
    updateBalanceAlerts,
    updateBalanceAlertsDialogOpen,
    setUpdateBalanceAlertsDialogOpen,
  } = props

  const [creditAlerts, setBalanceAlerts] = useState<any[]>([])

  const handleDialogClose = () => setUpdateBalanceAlertsDialogOpen(false)

  useEffect(() => {
    if (virginExperiencesOrganizational?.remaining_balance_alerts) {
      setBalanceAlerts([
        ...virginExperiencesOrganizational.remaining_balance_alerts,
      ])
    }
  }, [virginExperiencesOrganizational])

  return (
    <DialogTeleport
      dialogOpen={updateBalanceAlertsDialogOpen}
      handleDialogClose={handleDialogClose}
      dialogSize={'xs'}
      dialogTitle={`Update alerts for ${company?.name}`}
      dialogAction={{
        function: () =>
          updateBalanceAlerts.mutate({
            company_id: company?.id,
            amounts: creditAlerts
              ?.filter(alert => !alert?._destroy)
              .map(alert => alert.remaining_amount),
          }),
        label: 'Update Alerts Limit',
        disabled: updateBalanceAlerts.isLoading,
        isLoading: updateBalanceAlerts.isLoading,
      }}
      isFullWidth
    >
      <Grid container>
        <Grid item xs={12}>
          <CRUDTable
            rows={creditAlerts}
            setRows={setBalanceAlerts}
            columns={[
              {
                id: 'remaining_amount',
                label: 'Amount',
                required: true,
                showEdit: () => true,
                type: 'money',
                defaultValue: '',
              },
            ]}
            limit={20}
            showAdd
            showEdit={() => true}
            showRemove={() => true}
          />
        </Grid>
      </Grid>
    </DialogTeleport>
  )
}
