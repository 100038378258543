import { useEffect, useState } from 'react'

import {
  Avatar,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material'
import { teal } from '@mui/material/colors'
import {
  PriorityHigh as TakeActionIcon,
  CalendarToday as CalendarIcon,
  Clear as CancelIcon,
  ChatOutlined as CommentIcon,
  Check as CheckIcon,
} from '@mui/icons-material'
import { get, capitalize } from 'lodash'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import { useMutation } from 'react-query'

import { TakeActionReminder } from './components'
import sparckLogo from 'assets/images/sparck-logo-colored.png'
import { CompanyAdminLayout, DialogTeleport, UserAvatar } from 'components'
import { initialDialog } from 'constants/dialog'
import { recognitionGroupsSettings } from 'constants/recognition'
import { useUser } from 'contexts'
import { API } from 'core/requests'
import { ROUTES_PATHS } from 'core/routes'
import { RecognitionActionStatus } from 'enums/recognitionAction'
import { RewardType } from 'enums/reward'
import { UserPermission } from 'enums/user'
import { t } from 'i18n'

export default function TakeAction() {
  const history = useHistory()
  const intl = useIntl()

  const { enqueueSnackbar } = useSnackbar()
  const { user } = useUser()!

  const [reminderDate, setReminderDate] = useState(new Date())
  const [reminderDialog, setReminderDialog] = useState(initialDialog)
  const [shouldRefetch, setShouldRefetch] = useState<boolean>(false)
  const [dialogUsersRecognized, setDialogUsersRecognized] =
    useState(initialDialog)

  const takeActionMutation = useMutation(
    (payload: any) => API.update('takeRecognitionAction', payload),
    {
      onSuccess: (response: any) => {
        if (response.id) {
          enqueueSnackbar(t('takeAction.snackbar.success.actionCompleted'), {
            variant: 'success',
          })

          setShouldRefetch(true)
        }
      },
    },
  )

  const disregardActionMutation = useMutation(
    (payload: any) => API.update('disregardRecognitionAction', payload),
    {
      onSuccess: (response: any) => {
        if (response.id) {
          enqueueSnackbar(t('takeAction.snackbar.success.actionDisregarded'), {
            variant: 'success',
          })

          setShouldRefetch(true)
        }
      },
    },
  )

  return (
    <>
      <CompanyAdminLayout
        slug='takeAction'
        title={intl.formatMessage({ id: 'takeAction.title' })}
        description={intl.formatMessage({ id: 'takeAction.description' })}
        color={teal[500]}
        requestUrl='recognitionActions'
        filters={[
          {
            id: 'status',
            label: 'Status',
            defaultValue: ['needed'],
            values: [
              {
                value: 'needed',
                label: intl.formatMessage({ id: 'takeAction.filters.open' }),
              },
              {
                value: 'taken',
                label: intl.formatMessage({
                  id: 'takeAction.filters.complete',
                }),
              },
              {
                value: 'disregarded',
                label: intl.formatMessage({
                  id: 'Declined',
                }),
              },
            ],
          },
        ]}
        tableProps={{
          columns: [
            {
              title: intl.formatMessage({
                id: 'takeAction.fields.whoWasRecognized',
              }),
              field: 'recognition.users_recognized',
              editable: 'never',
              render: (rowData: any) => {
                const usersRecognized = rowData?.recognition?.users_recognized
                if (usersRecognized?.length >= 3) {
                  return (
                    <Button
                      color='primary'
                      variant='contained'
                      onClick={() =>
                        setDialogUsersRecognized({
                          isOpen: true,
                          data: usersRecognized,
                        })
                      }
                    >
                      See All
                    </Button>
                  )
                }

                return (usersRecognized ?? [])
                  .map(user => user?.full_name)
                  .join(', ')
              },
            },
            {
              title: intl.formatMessage({ id: 'takeAction.fields.byWhom' }),
              field: 'recognition.user_recognizing.name',
              editable: 'never',
            },
            {
              title: intl.formatMessage({
                id: 'takeAction.fields.recognitionEvent',
              }),
              field: 'recognition.event.name',
              editable: 'never',
            },
            {
              title: intl.formatMessage({ id: 'takeAction.fields.details' }),
              field: 'see_sparck',
              editable: 'never',
              render: (rowData: any) =>
                rowData?.recognition?.has_public_access && (
                  <Button
                    color='primary'
                    size='small'
                    sx={{ minWidth: 120, p: 0 }}
                    onClick={() =>
                      history.push(
                        `${ROUTES_PATHS.sparck}/${get(
                          rowData,
                          'recognition.id',
                        )}`,
                      )
                    }
                  >
                    {intl.formatMessage({
                      id: 'takeAction.fields.viewMessage',
                    })}
                  </Button>
                ),
            },
            {
              title: intl.formatMessage({ id: 'reward' }),
              field: 'recognition.event.title',
              editable: 'never',
              render: (rowData: any) => {
                const retailerReward = rowData.rewards?.find(
                  reward => reward.type === RewardType.Retailer,
                )

                if (!retailerReward) return 'N/A'

                return `${capitalize(retailerReward?.type)}`
              },
            },
            {
              title: intl.formatMessage({
                id: 'takeAction.fields.recognizeThemThisWay',
              }),
              field: 'from_where',
              editable: 'never',
              render: rowData =>
                recognitionGroupsSettings.find(
                  setting =>
                    get(rowData, 'recognition.from_where') === setting.value,
                )?.label,
            },
          ],
          ...((user.permissions ?? []).includes(
            UserPermission.ManageTakeActions,
          ) && {
            actions: [
              {
                icon: () => (
                  <Avatar
                    src={sparckLogo}
                    alt='Sparck logo'
                    style={{ width: 24, height: 24 }}
                  />
                ),
                disabled: action =>
                  action.status !== RecognitionActionStatus.Needed,
                tooltip: () => t('takeAction.recognize'),
                onClick: (_, action) =>
                  history.push(
                    `${ROUTES_PATHS.sparck}?user_to_recognize_id=${get(
                      action,
                      'recognition.users_recognized[0].id',
                    )}`,
                  ),
              },
              {
                icon: () => <CalendarIcon />,
                disabled: action =>
                  action.status !== RecognitionActionStatus.Needed,
                tooltip: () => t('takeAction.addReminder'),
                onClick: (_, action) =>
                  setReminderDialog({ isOpen: true, data: action }),
              },
              {
                icon: () => <CommentIcon />,
                disabled: action => !get(action, 'recognition.post.id'),
                tooltip: () => t('takeAction.postComment'),
                onClick: (_, action) =>
                  history.push(
                    `${ROUTES_PATHS.feed}/${get(
                      action,
                      'recognition.post.id',
                    )}`,
                  ),
              },
              {
                icon: () => <CheckIcon color='primary' />,
                disabled: action =>
                  action.status !== RecognitionActionStatus.Needed,
                tooltip: () => t('takeAction.markAsComplete'),
                onClick: (_, action) => takeActionMutation.mutate(action),
              },
              {
                icon: () => <CancelIcon color='error' />,
                disabled: action =>
                  action.status !== RecognitionActionStatus.Needed,
                tooltip: () => t('takeAction.delete'),
                onClick: (_, action) => disregardActionMutation.mutate(action),
              },
            ],
          }),
          editActionProps: {
            hidden: () => true,
          },
          deleteActionProps: {
            hidden: () => true,
          },
        }}
        managementPermission={UserPermission.ManageTakeActions}
        IconHeader={TakeActionIcon}
      >
        {({ fetchData }) => (
          <Actions
            shouldRefetch={shouldRefetch}
            setShouldRefetch={setShouldRefetch}
            fetchData={fetchData}
          />
        )}
      </CompanyAdminLayout>

      <TakeActionReminder
        reminderDate={reminderDate}
        setReminderDate={setReminderDate}
        reminderDialog={reminderDialog}
        setReminderDialog={setReminderDialog}
      />

      <DialogTeleport
        dialogTitle={'Recognized Users'}
        dialogSize='sm'
        dialogOpen={dialogUsersRecognized.isOpen}
        handleDialogClose={() => setDialogUsersRecognized(initialDialog)}
      >
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {(dialogUsersRecognized?.data ?? []).map((user: any) => {
            return (
              <ListItem key={user.id} disablePadding sx={{ mb: 2 }}>
                <ListItemAvatar>
                  <UserAvatar
                    name={`Avatar ${user.full_name}`}
                    src={user.avatar?.url}
                    size={40}
                  />
                </ListItemAvatar>
                <ListItemText id={user.id} primary={user.full_name} />
              </ListItem>
            )
          })}
        </List>
      </DialogTeleport>
    </>
  )
}

function Actions({ fetchData, shouldRefetch, setShouldRefetch }) {
  useEffect(() => {
    if (shouldRefetch) {
      fetchData()

      setShouldRefetch(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetch])

  return null
}
