import { useState, useEffect } from 'react'

import { get, isEmpty } from 'lodash'
import { useSnackbar } from 'notistack'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'

import { ProfileComponent } from './components'
import { useUser } from 'contexts/UserContext'
import { API } from 'core/requests'
import { ROUTES_PATHS } from 'core/routes'
import { APIError } from 'enums/api'
import { ActiveInactiveStatus } from 'enums/app'
import { UserPermission } from 'enums/user'
import { t } from 'i18n'

export default function Profile() {
  const [dialogChangePicture, setDialogChangePicture] = useState(false)
  const [croppedPicture, setCroppedPicture] = useState(null)
  const [changePictureIsLoading, setChangePictureIsLoading] = useState(false)

  const history = useHistory()
  const { user, setUser } = useUser()!
  const { enqueueSnackbar } = useSnackbar()
  const { profileId }: any = useParams()

  const [profileState, setProfileState] = useState<any>({})

  const queryParams = new URLSearchParams(window.location.search)
  const tabIndex = (Number(queryParams.get('tab')) && Number(queryParams.get('tab')) < 4) ? Number(queryParams.get('tab')) : 0

  const isProfileFromLoggedUser = !profileId || user.id === profileId

  const userCanEdit =
    isProfileFromLoggedUser ||
    ((profileState.superior_direct_reports_attributes ?? []).find(
      supervisor => get(supervisor, 'superior.id') === user.id,
    ) &&
      user.permissions.includes(UserPermission.ManageTeamsProfiles))

  const { isLoading: userIsLoading, refetch: fetchUser } = useQuery(
    'user',
    () => API.get('users', profileId ?? user.id),
    {
      enabled: false,
      onSettled: (response: any) => {
        if (response?.error_type === APIError?.RecordNotFound) {
          enqueueSnackbar(t('profile.snackbar.error.userNotFound'), {
            variant: 'error',
          })
          history.push(ROUTES_PATHS.feed)
        }

        setProfileState({
          ...response,
          personal_interests_visibility:
            response.personal_interests_visibility ?? 'everyone',
          professional_interests_visibility:
            response.professional_interests_visibility ?? 'only_managers',
          date_of_birth_visibility:
            response.date_of_birth_visibility ?? 'everyone',
        })
        if (isProfileFromLoggedUser) {
          setUser({
            ...user,
            avatar: response.avatar,
            mfa_details: response?.mfa_details,
          })
        }
      },
    },
  )

  const {
    data: userInsights,
    isLoading: myInsightsAreLoading,
    refetch: fetchUserInsights,
  } = useQuery(
    'userInsights',
    () =>
      API.get('userInsights', null, {
        queryParams: {
          user_id: profileId ?? user.id,
        },
      }),
    {
      enabled: true,
    },
  )

  const userMutation = useMutation(
    (payload: any) => API.createOrUpdate('users', payload),
    {
      onSettled: (response: any) => {
        if (response && response.id) {
          fetchUser()
          enqueueSnackbar(t('profile.snackbar.success.informationUpdated'), {
            variant: 'success',
          })
        }
      },
    },
  )

  const { data: personalQuestions } = useQuery('personalQuestions', () =>
    API.get('profileQuestions', null, {
      queryParams: {
        status: ActiveInactiveStatus.Active,
        type: 'personal',
        answers: true,
      },
    }),
  )

  const { data: professionalQuestions } = useQuery(
    'professionalQuestions',
    () =>
      API.get('profileQuestions', null, {
        queryParams: {
          status: ActiveInactiveStatus.Active,
          type: 'professional',
          answers: true,
        },
      }),
  )

  const uploadPictureMutation = useMutation(
    (payload: any) => API.createOrUpdate('uploads', payload),
    {
      onSuccess: (response: any) => {
        setChangePictureIsLoading(false)
        setDialogChangePicture(false)
        if (!response.errors) {
          enqueueSnackbar(t('profile.snackbar.success.profilePictureUpdated'), {
            variant: 'success',
          })
        }
        fetchUser()
      },
    },
  )

  useEffect(() => {
    if (user.id && isEmpty(profileState)) {
      fetchUser()
      fetchUserInsights()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <ProfileComponent
      profileId={profileId}
      profileState={profileState}
      setProfileState={setProfileState}
      userIsLoading={userIsLoading}
      fetchUser={fetchUser}
      user={user}
      userCanEdit={userCanEdit}
      editUser={userMutation.mutate}
      editUserStatus={userMutation}
      userInsights={userInsights?.data}
      myInsightsAreLoading={myInsightsAreLoading}
      personalQuestions={personalQuestions}
      professionalQuestions={professionalQuestions}
      dialogChangePicture={dialogChangePicture}
      setDialogChangePicture={setDialogChangePicture}
      uploadPicture={uploadPictureMutation.mutate}
      uploadPictureStatus={uploadPictureMutation}
      croppedPicture={croppedPicture}
      setCroppedPicture={setCroppedPicture}
      changePictureIsLoading={changePictureIsLoading}
      setChangePictureIsLoading={setChangePictureIsLoading}
      isProfileFromLoggedUser={isProfileFromLoggedUser}
      tabIndex = {tabIndex}
    />
  )
}
