import { Fragment, useState } from 'react'

import {
  ButtonBase,
  Collapse,
  Grid,
  Link,
  Tooltip,
  Typography,
} from '@mui/material'
import { isToday, parseISO } from 'date-fns'
import { FormattedDate } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { UserAvatar } from 'components'
import { ROUTES_PATHS } from 'core/routes'
import { t } from 'i18n'
import { ReminderType } from 'types'

export type DashboardRemindersProps = {
  reminders: ReminderType[] | any
}

export default function DashboardReminders(props: DashboardRemindersProps) {
  const { reminders } = props

  return (
    <Grid container>
      {reminders?.length > 0 ? (
        reminders.map(reminderSection => (
          <DashboardReminderSection
            key={reminderSection.type}
            reminderSection={reminderSection}
          />
        ))
      ) : (
        <Typography variant='h6' color='text.secondary'>
          {t('dashboard.noReminders')}
        </Typography>
      )}
    </Grid>
  )
}

function DashboardReminderSection({ reminderSection }) {
  const history = useHistory()
  const [showUpcomingReminders, setShowUpcomingReminders] = useState(false)

  const reminderSectionName =
    reminderSection.type === 'peer_birthdays' ? 'Birthdays' : 'Anniversaries'

  const renderBirthday = (reminder) => {
    return (
      <Grid item xs={12} key={reminder.id} mb={1}>
        <ButtonBase
          onClick={() => {
            history.push(
              `${ROUTES_PATHS.sparck}?user_to_recognize_id=${reminder.id}&event=birthday`,
            )
          }}
        >
          <Tooltip title='Click to Recognize!'>
            <Grid container justifyContent='center' alignItems='center' spacing={2}>
              <Grid item xs='auto'>
                <UserAvatar
                  name={`${reminder.first_name} ${reminder.last_name}`}
                  size={40}
                  src={reminder.avatar?.url}
                />
              </Grid>
    
              <Grid item xs>
                <Typography variant='body2' fontWeight={500} align='left'>
                  {reminder.first_name} {reminder.last_name}
                </Typography>
    
                <Typography variant='body2' align='left'>
                  <FormattedDate
                    value={reminder.date_of_birth}
                    month='long'
                    day='numeric'
                    weekday='long'
                    timeZone='utc'
                  />
                </Typography>
              </Grid>
            </Grid>
          </Tooltip>
        </ButtonBase>
      </Grid>
    )
  }
  
  const renderWorkiversary = (reminder) => {
    return (
      <Grid item xs={12} key={reminder.id} mb={1}>
        <ButtonBase
          onClick={() => {
            history.push(
              `${ROUTES_PATHS.sparck}?user_to_recognize_id=${reminder.id}&event=anniversary`,
            )
          }}
        >
          <Tooltip title='Click to Recognize!'>
            <Grid container justifyContent='center' alignItems='center' spacing={2}>
              <Grid item xs='auto'>
                <UserAvatar
                  name={`${reminder.first_name} ${reminder.last_name}`}
                  size={40}
                  src={reminder.avatar?.url}
                />
              </Grid>
    
              <Grid item xs>
                <Typography variant='body2' fontWeight={500} align='left'>
                  {reminder.first_name} {reminder.last_name}
                </Typography>
    
                {/* <Typography variant='body2' align='left'>
                  {t('dashboard.reminders.workiversaryOn', {
                    userName: reminder.will_be,
                    years:
                      reminder.will_be > 1
                        ? t('dashboard.reminders.years')
                        : t('dashboard.reminders.year'),
                    date: (
                      <FormattedDate
                        value={reminder.hired_at}
                        month='long'
                        day='numeric'
                        weekday='long'
                        timeZone='utc'
                      />
                    ),
                  })}
                </Typography> */}
                <Typography variant='body2' align='left'>
                  <FormattedDate
                    value={reminder.hired_at}
                    month='long'
                    day='numeric'
                    weekday='long'
                    timeZone='utc'
                  />
                </Typography>
              </Grid>
            </Grid>
          </Tooltip>
        </ButtonBase>
      </Grid>
    )
  }

  const renderReminderByType = {
    ['peer_birthdays']: renderBirthday,
    ['peer_workiversaries']: renderWorkiversary,
  }

  const todaysReminders = reminderSection?.data?.filter(reminder =>
    isToday(
      parseISO(
        reminder[
          reminderSection.type === 'peer_birthdays'
            ? 'date_of_birth'
            : 'hired_at'
        ],
      ),
    ),
  )

  const upcomingReminders = reminderSection?.data?.filter(
    reminder =>
      !isToday(
        parseISO(
          reminder[
            reminderSection.type === 'peer_birthdays'
              ? 'date_of_birth'
              : 'hired_at'
          ],
        ),
      ),
  )

  return (
    <Grid key={reminderSection.title} item xs={12} mb={3}>
      <Grid container>
        {upcomingReminders?.length > 0 && (
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Grid container>
              <Link
                color='primary'
                onClick={() => setShowUpcomingReminders(!showUpcomingReminders)}
                sx={{ fontSize: '0.85em' }}
              >
                {showUpcomingReminders
                  ? t('dashboard.reminders.showToday', {
                      sectionName: reminderSectionName,
                    })
                  : t('dashboard.reminders.showUpcoming', {
                      sectionName: reminderSectionName,
                    })}
              </Link>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography variant='body1' color='text.primary' fontWeight={500}>
            {t('dashboard.reminders.today', {
              sectionName: reminderSectionName,
            })}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            {todaysReminders?.length > 0 ? (
              todaysReminders.map((reminder, index) => (
                <Fragment key={index}>
                  {renderReminderByType[reminderSection.type](reminder)}
                </Fragment>
              ))
            ) : (
              <Grid item xs={12}>
                <Typography variant='body2' color='text.secondary' paragraph>
                  {t('dashboard.reminders.noRemindersToday', {
                    reminderSectionName: reminderSectionName,
                  })}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Collapse in={showUpcomingReminders}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant='body1'
                  color='text.primary'
                  fontWeight={500}
                >
                  {t('dashboard.reminders.upcoming', {
                    sectionName: reminderSectionName,
                  })}
                </Typography>
              </Grid>

              {upcomingReminders.map((reminder, index) => (
                <Fragment key={index}>
                  {renderReminderByType[reminderSection.type](reminder)}
                </Fragment>
              ))}
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Grid>
  )
}
