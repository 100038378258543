import { Fragment } from 'react'

import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'

import { useUser } from 'contexts'
import { t } from 'i18n'
import { userHasBeseenPermissions } from 'utils/user'

export default function ProfileSettings({
  profileState,
  setProfileState,
  editMode,
}) {
  const { user } = useUser()!

  const visibilityOptions = [
    {
      value: 'everyone',
      label: t('profile.profileVisibilityLabels.everyone'),
    },
    {
      value: 'only_managers',
      label: t('profile.profileVisibilityLabels.managersOnly'),
    },
  ]

  const settingsSections = [
    {
      id: 'generalSettings',
      label: t('profile.profileItemLabels.generalSettings'),
      settings: [
        {
          id: 'notification_preference',
          label: t('profile.profileItemLabels.preferredNotification'),
          defaultValue: 'email',
          display: true,
          options: [
            {
              value: 'email',
              label: t('email'),
            },
            {
              value: 'sms',
              label: t('profile.profileItemLabels.sms'),
            },
            {
              value: 'all',
              label: t('profile.profileItemLabels.all'),
            },
          ],
        },
      ],
    },
    {
      id: 'visibilitySettings',
      label: t('profile.profileItemLabels.visibilitySettings'),
      settings: [
        {
          id: 'personal_interests_visibility',
          label: t('profile.profileItemLabels.personalInterests'),
          defaultValue: 'everyone',
          display: userHasBeseenPermissions(user),
          options: visibilityOptions,
        },
        {
          id: 'professional_interests_visibility',
          label: t('profile.profileItemLabels.professionalInterests'),
          defaultValue: 'only_managers',
          display: userHasBeseenPermissions(user),
          options: visibilityOptions,
        },
        {
          id: 'date_of_birth_visibility',
          label: t('profile.profileItemLabels.dateOfBirth'),
          defaultValue: 'everyone',
          display: true,
          options: visibilityOptions,
        },
      ],
    },
  ]

  return (
    <>
      <Grid container>
        {settingsSections.map(settingSection => (
          <Fragment key={settingSection.id}>
            <Grid item xs={12} key={settingSection.id}>
              <Typography variant='h6' color='text.secondary'>
                {settingSection.label}
              </Typography>
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 24 }}>
              <Grid container spacing={2} justifyContent='space-between'>
                {settingSection.settings.map(setting =>
                  setting.display ? (
                    <Grid item xs={12} sm={4} key={setting.id}>
                      <Box mt={2} width={1} data-cy='profile_settings'>
                        {editMode ? (
                          <FormControl component='fieldset'>
                            <FormLabel component='legend'>
                              <Typography
                                variant='body2'
                                color='text.secondary'
                              >
                                {setting.label}
                              </Typography>
                            </FormLabel>
                            <RadioGroup
                              aria-label='gender'
                              name='gender1'
                              value={
                                profileState[setting.id] ?? setting.defaultValue
                              }
                              onChange={event =>
                                setProfileState({
                                  ...profileState,
                                  [setting.id]: event.target.value,
                                })
                              }
                            >
                              {setting.options.map(option => (
                                <FormControlLabel
                                  key={option.value}
                                  value={option.value}
                                  control={<Radio />}
                                  label={option.label}
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        ) : (
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography
                                variant='body2'
                                color='text.secondary'
                              >
                                {setting.label}
                              </Typography>
                            </Grid>

                            <Grid item xs={12}>
                              <Typography
                                variant='body1'
                                color='text.primary'
                                fontWeight={600}
                              >
                                {setting.options.find(
                                  option =>
                                    option.value === profileState[setting.id],
                                )
                                  ? setting.options.find(
                                      option =>
                                        option.value ===
                                        profileState[setting.id],
                                    )!.label
                                  : 'N/A'}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </Box>
                    </Grid>
                  ) : null,
                )}
              </Grid>
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </>
  )
}
