import { useEffect } from 'react'

import { Grid, MenuItem, Paper, TextField/*, Typography*/ } from '@mui/material'
import { addYears, /*isValid,*/ parseISO, subDays } from 'date-fns'
// import { FormattedDate } from 'react-intl'

// import { DatePickerWithWorkaround, MoneyInput } from 'components'
import {
  budgetPeriods,
  // budgetYearTypes,
  // budgetNewEmployeesBudgetTypes,
} from 'constants/budget'
import { /*BudgetNewEmployeesBudgetType,*/ BudgetYearType } from 'enums/budget'
import { t } from 'i18n'
import ManageBudgetLimitTypes from '../ManageBudgetLimitTypes/ManageBudgetLimitTypes'

export default function ManageBudgetGeneralForm(props) {
  const { formik, isDisabled } = props;
  useEffect(() => {
    if (formik.values.year_type === BudgetYearType.Calendar) {
      const currentYear: any = new Date().getFullYear()

      formik.setFieldValue('year_start_date', `01-01-${currentYear}`)
      formik.setFieldValue('year_end_date', new Date(`12-31-${currentYear}`))
    }

    if (formik.values.year_type === BudgetYearType.Fiscal) {
      formik.setFieldValue('year_end_date', null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.year_type])

  useEffect(() => {
    if (
      formik.values.year_type === BudgetYearType.Fiscal &&
      formik.values.year_start_date
    ) {
      const finalDateOfFiscalYear = subDays(
        addYears(parseISO(formik.values.year_start_date), 1),
        1,
      )

      formik.setFieldValue('year_end_date', finalDateOfFiscalYear)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.year_start_date])

  return (
    <Paper sx={{ p: 3 }} key={'general'}>
      <Grid container spacing={2}>
        {/* <Grid item xs={12}>
          <Typography variant='h6' component='h3' fontWeight={500}>
            {t('budget.manageBudgetGeneralForm.title')}
          </Typography>
        </Grid> */}

        {/* <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                select
                id='year_type'
                name='year_type'
                label={t('budget.manageBudgetGeneralForm.labels.yearType')}
                variant='outlined'
                value={formik.values.year_type ?? ''}
                required
                onChange={formik.handleChange}
                fullWidth
                margin='dense'
              >
                {budgetYearTypes.map(yearType => (
                  <MenuItem key={yearType.value} value={yearType.value}>
                    {yearType.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={6} sm={4}>
              {formik.values.year_type === BudgetYearType.Calendar ? (
                <Grid container alignItems='center' sx={{ height: 1 }}>
                  <Typography variant='body1' color='text.secondary'>
                    {t('budget.manageBudgetGeneralForm.labels.startDate')}:{' '}
                    <Typography
                      component='span'
                      variant='body1'
                      color='text.primary'
                      fontWeight={600}
                    >
                      <FormattedDate
                        value={formik.values.year_start_date}
                        year='numeric'
                        month='numeric'
                        day='numeric'
                        timeZone='utc'
                      />
                    </Typography>
                  </Typography>
                </Grid>
              ) : (
                <DatePickerWithWorkaround
                  value={formik.values.year_start_date}
                  onChange={value =>
                    formik.setFieldValue('year_start_date', value)
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      data-cy='year_start_date'
                      id='year_start_date'
                      name='year_start_date'
                      label={t(
                        'budget.manageBudgetGeneralForm.labels.startDate',
                      )}
                      variant='outlined'
                      margin='dense'
                      required
                      fullWidth
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.year_start_date &&
                        Boolean(formik.errors.year_start_date)
                      }
                      helperText={
                        formik.touched.year_start_date &&
                        formik.errors.year_start_date
                      }
                    />
                  )}
                />
              )}
            </Grid>

            {formik.values.year_end_date && (
              <Grid item xs={6} sm={4}>
                <Grid container alignItems='center' sx={{ height: 1 }}>
                  <Typography variant='body1' color='text.secondary'>
                    {t('budget.manageBudgetGeneralForm.labels.endDate')}:{' '}
                    <Typography
                      component='span'
                      variant='body1'
                      color='text.primary'
                      fontWeight={600}
                    >
                      {formik.values.year_end_date &&
                      isValid(formik.values.year_end_date) ? (
                        <FormattedDate
                          value={formik.values.year_end_date}
                          year='numeric'
                          month='numeric'
                          day='numeric'
                          timeZone='utc'
                        />
                      ) : (
                        t('budget.manageBudgetGeneralForm.invalidDate')
                      )}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid> */}

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                select
                id='period'
                name='period'
                label={t('budget.manageBudgetGeneralForm.labels.budgetPeriod')}
                variant='outlined'
                defaultValue=''
                value={formik.values.period ?? ''}
                required
                onChange={formik.handleChange}
                fullWidth
                margin='dense'
                disabled={isDisabled}
              >
                {budgetPeriods.map(period => (
                  <MenuItem key={period.value} value={period.value}>
                    {period.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <ManageBudgetLimitTypes {...props} />

            {/* <Grid item xs={12} sm={4}>
              <TextField
                select
                id='new_employees_budget_type'
                name='new_employees_budget_type'
                label={t(
                  'budget.manageBudgetGeneralForm.labels.budgetTypeForNewEmployees',
                )}
                variant='outlined'
                defaultValue=''
                value={formik.values.new_employees_budget_type ?? ''}
                required
                onChange={formik.handleChange}
                fullWidth
                margin='dense'
              >
                {budgetNewEmployeesBudgetTypes.map(newEmployeeBudgetType => (
                  <MenuItem
                    key={newEmployeeBudgetType.value}
                    value={newEmployeeBudgetType.value}
                  >
                    {newEmployeeBudgetType.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid> */}

            {/* {formik.values.new_employees_budget_type ===
              BudgetNewEmployeesBudgetType.Automatic && (
              <Grid item xs={12} sm={4}>
                <TextField
                  id='new_employees_base_amount'
                  name='new_employees_base_amount'
                  label={t(
                    'budget.manageBudgetGeneralForm.labels.amountPerNewEmployee',
                  )}
                  variant='outlined'
                  value={formik.values.new_employees_base_amount}
                  onChange={formik.handleChange}
                  required
                  fullWidth
                  margin='dense'
                  InputProps={{
                    inputComponent: MoneyInput,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            )} */}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}
