import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'

import { OnboardingCard } from '../../components'

export default function OnboardingSimpleSelectQuestions({
  simpleSelectQuestions,
  answers,
  setAnswers,
  theme,
  isMobile,
}) {
  return simpleSelectQuestions.map(simpleSelectQuestion => {
    const simpleSelectAnswer = answers.find(
      answer => answer.question_id === simpleSelectQuestion.id,
    )

    if (simpleSelectAnswer) {
      return (
        <OnboardingCard
          key={simpleSelectQuestion.id}
          backgroundColor1={theme.palette.secondary.dark}
          backgroundColor2={theme.palette.secondary.light}
          title={simpleSelectQuestion.statement}
        >
          <Grid container justifyContent='center'>
            <Grid item xs={12} sm={11} md={10} lg={8}>
              <FormControl component='fieldset' style={{ width: '100%' }}>
                <RadioGroup
                  aria-label='simple-select-check'
                  name='simple-select-check'
                  value={simpleSelectAnswer.selected_choice_id}
                  onChange={event => {
                    simpleSelectAnswer.selected_choice_id = +event.target.value

                    setAnswers([...answers])
                  }}
                >
                  <Grid container justifyContent='center'>
                    {simpleSelectQuestion.onboarding_choices_attributes.map(
                      choice => (
                        <Grid item xs={12} key={choice.id}>
                          <Box width={1} mb={2}>
                            <Paper
                              {...(choice.id ===
                              simpleSelectAnswer.selected_choice_id
                                ? {
                                    style: {
                                      backgroundColor: `${theme.palette.primary.main}15`,
                                      outline: `1px solid ${theme.palette.primary.main}`,
                                    },
                                  }
                                : {})}
                            >
                              <FormControlLabel
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  paddingLeft: 24,
                                }}
                                value={choice.id}
                                control={<Radio color='primary' />}
                                label={
                                  <Typography
                                    variant={isMobile ? 'body2' : 'body1'}
                                    color={
                                      choice.id ===
                                      simpleSelectAnswer.selected_choice_id
                                        ? 'primary.main'
                                        : 'text.secondary'
                                    }
                                    fontWeight={
                                      choice.id ===
                                      simpleSelectAnswer.selected_choice_id
                                        ? 600
                                        : 400
                                    }
                                  >
                                    <Box width={1} py={3} px={2}>
                                      {choice.statement}
                                    </Box>
                                  </Typography>
                                }
                              />
                            </Paper>
                          </Box>
                        </Grid>
                      ),
                    )}
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </OnboardingCard>
      )
    } else {
      return null
    }
  })
}
