import { useState, useEffect } from 'react'

import { get, isEmpty } from 'lodash'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { OnboardingComponent, OnboardingFinish } from './components'
import { useUser } from 'contexts'
import { API } from 'core/requests'
import { ActiveInactiveStatus } from 'enums/app'
import {
  OnboardingQuestionType,
  OnboardingPage,
} from 'enums/onboardingQuestion'

export default function Onboarding() {
  const { user, setUser } = useUser()!

  const { userId }: any = useParams()

  const [pages, setPages] = useState<any[]>([OnboardingPage.Landing])
  const [dialogFinish, setDialogFinish] = useState<boolean>(false)

  const [profileState, setProfileState] = useState<any>({})
  const [answers, setAnswers] = useState<any[]>([])
  const [pagination, setPagination] = useState(pages[0])

  const onboardingFromLoggedUser = profileState.id === user.id

  const isSupervisorView = (
    profileState.superior_direct_reports_attributes ?? []
  ).find(supervisor => get(supervisor, 'superior.id') === userId)

  const {
    data: profileQuestions,
    refetch: fetchProfileQuestions,
    isSuccess: profileQuestionsIsFetched,
  } = useQuery('profileQuestions', () =>
    API.get('profileQuestions', null, {
      queryParams: { answers: true, status: ActiveInactiveStatus.Active },
    }),
  )

  const {
    data: userFromAPI,
    refetch: fetchUser,
    isSuccess: userIsFetched,
  } = useQuery('getUser', () => API.get('users', userId ?? user.id), {
    enabled: false,
    onSuccess: response => {
      setUser({
        ...user,
      })
      setProfileState(response)
    },
  })

  const { data: onboardingQuestions, isSuccess: onboardingQuestionsIsFetched } =
    useQuery('onboardingQuestions', () => API.get('onboardingQuestions'))

  const finishOnboardingMutation = useMutation(
    (payload: any) => API.update('finishOnboarding', payload),
    {
      onSuccess: async (response: any) => {
        if (response.message) {
          await fetchUser()
          setDialogFinish(true)
        }
      },
    },
  )

  const profileQuestionsMutation = useMutation(
    (payload: any) => API.createOrUpdate('users', payload),
    {
      onSettled: (response: any) => {
        if (response && response.id) {
          fetchUser()
        }
      },
    },
  )

  const getRatingQuestions = (onboardingAnswers: any[]) => {
    const ratingQuestions = onboardingQuestions
      .filter(question => question.type === OnboardingQuestionType.Rating)
      .flatMap(question => {
        return question.onboarding_choices_attributes.flatMap(attribute => {
          const answer = onboardingAnswers.find(
            answer => answer.selected_choice_id === attribute.id,
          )

          const questionId = answer ? answer.question.id : question.id
          const selectedChoiceId = answer
            ? answer.selected_choice_id
            : get(attribute, 'id')
          const satisfaction = answer ? answer.satisfaction : 1

          return {
            question_id: questionId,
            type: OnboardingQuestionType.Rating,
            selected_choice_id: selectedChoiceId,
            satisfaction: satisfaction,
          }
        })
      })

    return ratingQuestions
  }

  // const getRewardQuestions = onboardingAnswers => {
  //   const rewardQuestions = onboardingQuestions
  //     .filter(question => question.type === OnboardingQuestionType.Reward)
  //     .flatMap(question => {
  //       return question.onboarding_choices_attributes.flatMap(attribute => {
  //         const answer = onboardingAnswers.find(
  //           answer => answer.selected_choice_id === attribute.id,
  //         )

  //         const questionId = answer ? answer.question.id : question.id
  //         const selectedChoiceId = answer
  //           ? answer.selected_choice_id
  //           : get(attribute, 'id')
  //         const satisfaction = answer ? answer.satisfaction : 1

  //         return {
  //           question_id: questionId,
  //           type: OnboardingQuestionType.Reward,
  //           selected_choice_id: selectedChoiceId,
  //           satisfaction: satisfaction,
  //         }
  //       })
  //     })

  //   return rewardQuestions
  // }

  const getSimpleSelectQuestions = onboardingAnswers => {
    const simpleSelectQuestions = onboardingQuestions
      .filter(question => question.type === OnboardingQuestionType.SimpleSelect)
      .flatMap(question => {
        const answer = onboardingAnswers.find(
          answer => answer.question.id === question.id,
        )

        const questionId = answer ? answer.question.id : question.id
        const selectedChoiceId = answer ? answer.selected_choice_id : null

        return {
          question_id: questionId,
          type: OnboardingQuestionType.SimpleSelect,
          selected_choice_id: selectedChoiceId,
        }
      })

    return simpleSelectQuestions
  }

  const getEventQuestions = onboardingAnswers => {
    const eventQuestions = onboardingQuestions
      .filter(question => question.type === OnboardingQuestionType.Event)
      .flatMap(question => {
        return question.onboarding_choices_attributes.flatMap(attribute => {
          const answer = onboardingAnswers.find(
            answer =>
              answer.event_id === get(attribute, 'recognitions_event.id'),
          )

          const questionId = answer ? answer.question.id : question.id

          return {
            question_id: questionId,
            type: OnboardingQuestionType.Event,
            event_id: answer
              ? answer.event_id
              : get(attribute, 'recognitions_event.id'),
            event_name: answer
              ? get(answer, 'recognitions_event.name')
              : get(attribute, 'recognitions_event.name'),
            event_description: get(answer, 'recognitions_event.description')
              ? get(answer, 'recognitions_event.description')
              : get(attribute, 'recognitions_event.description'),
            wants_to_be_recognized: answer && answer.wants_to_be_recognized,
            wants_to_be_recognized_in_public:
              answer && answer.wants_to_be_recognized_in_public,
            wants_to_be_recognized_by_who: answer
              ? answer.wants_to_be_recognized_by_who
              : 'peers',
            wants_to_be_recognized_in_what_setting: answer
              ? answer.wants_to_be_recognized_in_what_setting
              : 'personal_note',
          }
        })
      })

    return eventQuestions
  }

  const getChoiceGroupQuestions = onboardingAnswers => {
    const choiceGroupAnswers = onboardingAnswers
      .filter(
        answer =>
          answer.question.type === OnboardingQuestionType.ChoiceGroup &&
          answer.choice?.group !== null,
      )
      .map(answer => ({
        question_id: answer.question.id,
        type: OnboardingQuestionType.ChoiceGroup,
        choice_group: get(answer, 'choice.group'),
        selected_choice_id: answer.selected_choice_id ?? 0,
      }))

    onboardingQuestions
      .filter(question => question.type === OnboardingQuestionType.ChoiceGroup)
      .flatMap(question => {
        const choiceGroups = question.onboarding_choices_attributes.map(
          attribute => attribute.choice_group,
        )

        const uniqueChoiceGroups: any[] = Array.from(new Set(choiceGroups))

        uniqueChoiceGroups.forEach(choiceGroup => {
          if (
            !choiceGroupAnswers.find(
              answer => answer.choice_group === choiceGroup,
            )
          ) {
            choiceGroupAnswers.push({
              question_id: question.id,
              type: OnboardingQuestionType.ChoiceGroup,
              choice_group: choiceGroup,
              selected_choice_id: null,
            })
          }
        })
      })

    return choiceGroupAnswers
  }

  useEffect(() => {
    if (
      profileQuestionsIsFetched &&
      onboardingQuestionsIsFetched &&
      userIsFetched &&
      isEmpty(answers)
    ) {
      const onboardingAnswers = get(
        userFromAPI,
        'recognition.onboarding.answers',
      )

      setAnswers([
        ...getRatingQuestions(onboardingAnswers),
        ...getSimpleSelectQuestions(onboardingAnswers),
        // ...getRewardQuestions(onboardingAnswers),
        ...getEventQuestions(onboardingAnswers),
        ...getChoiceGroupQuestions(onboardingAnswers),
      ])

      setPages([
        OnboardingPage.Landing,
        ...(getRatingQuestions(onboardingAnswers).length > 0 ||
        getSimpleSelectQuestions(onboardingAnswers).length > 0
          ? [OnboardingPage.RatingAndSimpleSelect]
          : []),
        ...(profileQuestions.length > 0
          ? [OnboardingPage.ProfileQuestions]
          : []),
        // ...(getRewardQuestions(onboardingAnswers).length > 0
        //   ? [OnboardingPage.Reward]
        //   : []),
        ...(getEventQuestions(onboardingAnswers).length > 0
          ? [OnboardingPage.Event]
          : []),
        ...(getChoiceGroupQuestions(onboardingAnswers).length > 0
          ? [OnboardingPage.ChoiceGroup]
          : []),
      ])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingQuestions, userFromAPI, profileQuestions])

  useEffect(() => {
    fetchProfileQuestions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if ((userId || user.id) && !userFromAPI) {
      fetchUser()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (
      isSupervisorView &&
      pages.includes(OnboardingPage.RatingAndSimpleSelect) &&
      pagination === OnboardingPage.Landing
    ) {
      setPagination(OnboardingPage.RatingAndSimpleSelect)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages])

  return (
    <>
      <OnboardingComponent
        onboardingQuestions={onboardingQuestions || []}
        profileQuestions={profileQuestions}
        finishOnboarding={() =>
          finishOnboardingMutation.mutate({ answers: answers })
        }
        answers={answers}
        setAnswers={setAnswers}
        pages={pages}
        pagination={pagination}
        setPagination={setPagination}
        profileState={profileState}
        setProfileState={setProfileState}
        saveProfileQuestions={() =>
          profileQuestionsMutation.mutate(profileState)
        }
        isSupervisorView={isSupervisorView}
        onboardingFromLoggedUser={onboardingFromLoggedUser}
        userId={userId}
      />

      <OnboardingFinish
        dialogFinish={dialogFinish}
        setDialogFinish={setDialogFinish}
      />
    </>
  )
}
