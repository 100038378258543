export const data = {
    "fit": [
        "1. The type of work that I do leverages my personal strengths.",
        "2. I have clearly defined goals for my job.",
        "3. I enjoy the work that I do.",
        "4. Working here fits my lifestyle needs."
    ],
    "alignment": [
        "1. I have confidence in the direction that our organization is taking.",
        "2. I believe in our organization's purpose.",
        "3. I have confidence in our senior leadership team.",
        "4. I am committed to our organization's mission and values.",
        "5. I understand the most important goals for my department.",
        "6. I can see how my work directly contributes to this organization's results."
    ],
    "team": [
        "1. In general, communication within this organization is open.",
        "2. My department coordinates effectively with other groups to achieve goals.",
        "3. My manager fosters genuine and trusting relationships on the team.",
        "4. My manager selects competent people for our team."
    ],
    "value": [
        "1. I feel valued and appreciated.",
        "2. I believe employees are treated fairly within this organization.",
        "3. My manager promotes an environment where I feel free to express my opinion.",
        "4. My manager is approachable.",
        "5. I have the resources I need to do an effective job.",
        "6. My manager gathers my input when making decisions that affect me.",
        "7. My working conditions support the results I am expected to achieve.",
        "8. This organization has a culture of recognition for good performance.",
        "9. My manager keeps me informed about organizational changes that impact me.",
        "10. My manager recognizes me in a manner that I personally value.",
        "11. My manager treats me with respect."
    ],
    "growth": [
        "1. This organization has a culture where I can learn new skills and grow.",
        "2. This organization provides me with tools I need to help me advance my career.",
        "3. My manager supports my professional growth and development.",
        "4. My manager is aware of my career goals.",
        "5. My manager provides me with regular feedback to help me improve my performance."
    ]
}