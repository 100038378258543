import {
  Box,
  Grid,
  Paper,
  useMediaQuery,
  Typography,
  useTheme,
} from '@mui/material'
import { isEmpty } from 'lodash'

import {
  SparckAppreciationAssessmentForm,
  SparckAppreciationAssessmentQuestions,
} from '../../components'
import sparckLogo from 'assets/images/sparck-logo-colored.png'
import wave05 from 'assets/images/waves/wave-05.png'
import { t } from 'i18n'

const LOGO_SIZE = 88

export default function SparckAppreciationAssessment({
  formik,
  questions,
  answers,
  setAnswers,
  answerAssessment,
  assessmentIsLoading,
  assessmentResults,
}) {
  const theme: any = useTheme()

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'))

  return (
    <Grid container justifyContent='center'>
      <Grid item xs={12} sm={10} md={9} lg={7} xl={5}>
        <Paper style={{ position: 'relative' }}>
          <img
            src={wave05}
            alt='wave'
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              width: '100%',
              minHeight: 120,
              zIndex: 1,
            }}
          />
          <Box mt={5} py={5} px={3} width={1}>
            <Grid container justifyContent='center'>
              <Grid item style={{ zIndex: 999 }}>
                <Paper
                  elevation={4}
                  style={{
                    borderRadius: '100%',
                    padding: 24,
                  }}
                >
                  <Box
                    component='img'
                    sx={{
                      width: `${LOGO_SIZE}px`,
                      height: `${LOGO_SIZE}px`,
                      zIndex: 999,
                    }}
                    src={sparckLogo}
                    alt='Sparck Logo'
                  />
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Box width={1} mt={2} mb={4}>
                  <Typography
                    variant='h4'
                    fontWeight={600}
                    align='center'
                    color='text.primary'
                    paragraph
                  >
                    {!isEmpty(assessmentResults)
                      ? t('sparckAppreciationAssessment.results.title')
                      : !isEmpty(questions)
                      ? t('sparckAppreciationAssessment.questions.title')
                      : t('sparckAppreciationAssessment.landing.title')}
                  </Typography>
                </Box>
              </Grid>

              {isEmpty(questions) ? (
                <Grid item xs={12}>
                  <Grid container justifyContent='center'>
                    <Grid item xs={12} sm={11} md={10} lg={9}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box width={1} mb={5}>
                            <Typography
                              variant='body1'
                              color='text.secondary'
                              paragraph
                            >
                              {t(
                                'sparckAppreciationAssessment.landing.description',
                              )}
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12}>
                          <SparckAppreciationAssessmentForm
                            formik={formik}
                            assessmentIsLoading={assessmentIsLoading}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <SparckAppreciationAssessmentQuestions
                    questions={questions}
                    answers={answers}
                    setAnswers={setAnswers}
                    answerAssessment={answerAssessment}
                    assessmentResults={assessmentResults}
                    theme={theme}
                    isMobile={isMobile}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}
