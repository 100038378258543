import React from 'react'

import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { SignupForm, SignupConfirmEmail } from '../../components'
import sparckFullColored from 'assets/images/sparck-full-colored.png'
import { t } from 'i18n'
import { DialogData } from 'types/dialog'

export type SignupComponentProps = RouteComponentProps & {
  dialogConfirmEmail: DialogData
  setDialogConfirmEmail: React.Dispatch<React.SetStateAction<DialogData>>
  createAccount: any
  isSignupRequestLoading: boolean
}
const fullHeight = { height: '100%' }
const fullWidth = { width: '100%' }

function SignupComponent(props: SignupComponentProps) {
  const {
    dialogConfirmEmail,
    setDialogConfirmEmail,
    createAccount,
    isSignupRequestLoading,
  } = props

  return (
    <>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        style={fullHeight}
      >
        <Grid item xs={11} sm={10} md={8} lg={6}>
          <Box my={5}>
            <Card>
              <CardContent>
                <Box p={2} py={4}>
                  <Grid container justifyContent='center'>
                    <Grid item xs={12} sm={6} md={5}>
                      <Box mb={2}>
                        <img
                          src={sparckFullColored}
                          alt='Sparck logo'
                          style={fullWidth}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant='body1'
                        color='text.secondary'
                        align='center'
                      >
                        {t('signup.description')}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Box mt={8}>
                    <SignupForm {...props} />
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>

      <SignupConfirmEmail
        dialog={dialogConfirmEmail}
        setDialog={setDialogConfirmEmail}
        createAccount={createAccount}
        isSignupRequestLoading={isSignupRequestLoading}
      />
    </>
  )
}

export default withRouter(SignupComponent)
