import { useState } from 'react'

import { Box, Button, Grid, Paper, Slider, Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles'

import { t } from 'i18n'

const SparckSlider = withStyles(theme => ({
  root: {
    color: theme.palette.secondary.main,
    height: 36,
    padding: 0,
  },
  thumb: {
    height: 36,
    width: 24,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    marginTop: 0,
    borderRadius: 0,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 36,
    borderRadius: 4,
  },
  rail: {
    height: 36,
    borderRadius: 4,
  },
}))(Slider)

const EMPLOYEES_MAX = 1000
const EMPLOYEES_MIN = 0
const COST_PER_EMPLOYEE = 19

export default function UpgradeCostEstimate() {
  const [employeesCounter, setEmployeesCounter]: any = useState(EMPLOYEES_MIN)

  const getEstimatedReportCost = () => {
    if (employeesCounter > EMPLOYEES_MAX) {
      return (
        <Button color='primary' variant='contained' fullWidth>
          {t('components.upgradeCostEstimate.contactUs')}
        </Button>
      )
    }
    if (employeesCounter < EMPLOYEES_MIN) {
      return (
        <span>
          ${COST_PER_EMPLOYEE * EMPLOYEES_MIN}{' '}
          <span style={{ fontWeight: 400, fontSize: 14 }}>(fixed cost)</span>
        </span>
      )
    }
    return (
      <span>
        ${employeesCounter * COST_PER_EMPLOYEE}{' '}
        <span style={{ fontWeight: 400, fontSize: 14 }}>
          (${COST_PER_EMPLOYEE} per employee)
        </span>
      </span>
    )
  }

  return (
    <Paper>
      <Box p={3}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='h4' color='text.secondary' paragraph>
              {t('components.upgradeCostEstimate.estimateYourCost')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body2' color='text.secondary' paragraph>
              {t('components.upgradeCostEstimate.numberOfEmployees')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SparckSlider
              valueLabelDisplay='auto'
              aria-label='pretto slider'
              defaultValue={EMPLOYEES_MIN}
              min={0}
              max={EMPLOYEES_MAX + 1}
              onChange={(e, value) => setEmployeesCounter(value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent='space-between'>
              {[0, EMPLOYEES_MAX].map(value => (
                <Grid item key={value}>
                  <Typography variant='body2' color='text.secondary'>
                    {value} {t('components.upgradeCostEstimate.employees')}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent='center'>
              <Typography variant='h6' color='secondary.main' fontWeight='bold'>
                {employeesCounter > EMPLOYEES_MAX ? (
                  <>1000+</>
                ) : (
                  employeesCounter
                )}{' '}
                {t('components.upgradeCostEstimate.employees')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box my={1}>
              <Typography variant='body2' color='text.secondary' paragraph>
                {employeesCounter <= EMPLOYEES_MAX && (
                  <Box component='span' mr={2}>
                    {t('components.upgradeCostEstimate.estimatedReportCost')}
                  </Box>
                )}
                <Typography
                  component='span'
                  variant='h5'
                  color='primary.main'
                  fontWeight='bold'
                >
                  {getEstimatedReportCost()}
                </Typography>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}
