import { useMemo } from 'react'

import { get } from 'lodash'
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import { FavoriteBorder as HeartIcon } from '@mui/icons-material'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router'

import { useUser } from 'contexts'
import { ROUTES_PATHS } from 'core/routes'
import { API } from 'core/requests'
import { t } from 'i18n'

type RouteParams = {
  profileId: string
}

export default function ProfileMemories({
  profileState,
  userInsights,
  userIsLoading,
  isProfileFromLoggedUser,
}) {
  const history = useHistory()

  const { user } = useUser()!

  const { profileId } = useParams() as RouteParams

  const { data: visibleUser } = useQuery(
    'visibleUser',
    () => API.get('users', profileId),
    {
      enabled: true,
    },
  )

  const superiorIds = get(
    visibleUser,
    'superior_direct_reports_attributes',
    [],
  ).map(user => user.superior.id)

  const loggedUserIsDirectManager = superiorIds.includes(get(user, 'id'))

  const recognitionsCount = useMemo(() => {
    const recognitionsByEvents = userInsights?.recognitions?.by_events
    const count = {
      total: 0,
      sent: 0,
      received: 0,
    }

    if (!recognitionsByEvents) return count

    recognitionsByEvents.forEach(item => {
      if (item.total_received) count['received'] += item.total_received
      if (item.total_sent) count['sent'] += item.total_sent
    })

    count['total'] = count['received'] + count['sent']

    return count
  }, [userInsights])

  const showUsersRecognitionsToSuperiorButton =
    !isProfileFromLoggedUser &&
    loggedUserIsDirectManager &&
    recognitionsCount.total > 0

  return (
    <Grid
      container
      direction='column'
      justifyContent='space-between'
      style={{ height: '100%' }}
    >
      <Grid item>
        <Grid container justifyContent='center'>
          {userIsLoading ? (
            <CircularProgress size={64} style={{ color: 'white' }} />
          ) : (
            <>
              <HeartIcon style={{ fontSize: 200, color: '#FFF' }} />

              <Grid item xs={12}>
                {recognitionsCount.total > 0 && (
                  <Typography
                    variant='h1'
                    color='text.white'
                    align='center'
                    fontWeight={700}
                  >
                    {recognitionsCount.total}
                  </Typography>
                )}

                <Typography
                  variant={recognitionsCount.total > 0 ? 'h5' : 'h4'}
                  color='text.white'
                  align='center'
                  fontWeight={600}
                  paragraph
                >
                  {recognitionsCount.total > 0 ? (
                    <span>
                      {recognitionsCount.total === 1
                        ? t('profile.recognitionMemories.titleSingleMemory')
                        : t('profile.recognitionMemories.title')}
                    </span>
                  ) : (
                    <span>
                      {profileId
                        ? t(
                            'profile.recognitionMemories.noRecognitionMemories.userDoesntHave',
                            {
                              userName: profileState.first_name,
                              title: t('profile.recognitionMemories.title'),
                            },
                          )
                        : t(
                            'profile.recognitionMemories.noRecognitionMemories.youDontHave',
                            {
                              title: t('profile.recognitionMemories.title'),
                            },
                          )}
                    </span>
                  )}
                </Typography>

                {recognitionsCount.total > 0 && (
                  <>
                    <Typography
                      variant='body1'
                      color='text.white'
                      align='center'
                      fontWeight={500}
                      paragraph
                    >
                      {t('profile.recognitionMemories.received')}:{' '}
                      {recognitionsCount.received}
                    </Typography>
                    <Typography
                      variant='body1'
                      color='text.white'
                      align='center'
                      fontWeight={500}
                      paragraph
                    >
                      {t('profile.recognitionMemories.sent')}:{' '}
                      {recognitionsCount.sent}
                    </Typography>
                  </>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      {recognitionsCount.total > 0 && isProfileFromLoggedUser && (
        <Grid item>
          <Box width={1} mb={7}>
            <Button
              variant='outlined'
              size='large'
              sx={{
                fontWeight: 700,
                '&.MuiButton-outlined': {
                  color: 'white',
                  borderColor: 'white',
                },
              }}
              onClick={() => history.push(ROUTES_PATHS.sparckMemories)}
              fullWidth
            >
              {t('profile.recognitionMemories.viewAll')}
            </Button>
          </Box>
        </Grid>
      )}

      {showUsersRecognitionsToSuperiorButton && (
        <Grid item>
          <Box width={1} mb={7}>
            <Button
              variant='outlined'
              size='large'
              sx={{
                fontWeight: 700,
                '&.MuiButton-outlined': {
                  color: 'white',
                  borderColor: 'white',
                },
              }}
              onClick={() =>
                history.push({
                  pathname: ROUTES_PATHS.sparckMemories,
                  state: {
                    visibleUserID: profileId,
                  },
                })
              }
              fullWidth
            >
              {t('profile.recognitionMemories.viewGivenUserRecognitions', {
                userName: profileState.first_name,
              })}
            </Button>
          </Box>
        </Grid>
      )}
    </Grid>
  )
}
