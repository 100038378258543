import { Grid, List, ListItem, ListItemText, Typography } from '@mui/material'
import { useIntl } from 'react-intl'

import {
  containsCapitalLetter,
  containsLowercaseLetter,
  containsMinimumLengthRequired,
  containsNumber,
  containsSpecialCharacter,
} from 'utils/formValidation'

export default function PasswordRules({ password }) {
  const intl = useIntl()

  const passwordRules = [
    {
      label: intl.formatMessage({
        id: 'components.passwordRules.lowercaseLetter',
      }),
      validation: containsLowercaseLetter,
    },
    {
      label: intl.formatMessage({
        id: 'components.passwordRules.capitalLetter',
      }),
      validation: containsCapitalLetter,
    },
    {
      label: intl.formatMessage({
        id: 'components.passwordRules.atLeastEightCharacters',
      }),
      validation: containsMinimumLengthRequired,
    },
    {
      label: intl.formatMessage({
        id: 'components.passwordRules.number',
      }),
      validation: containsNumber,
    },
    {
      label: intl.formatMessage({
        id: 'components.passwordRules.specialCharacter',
      }),
      validation: containsSpecialCharacter,
    },
  ]

  return (
    <Grid item xs={12}>
      <Typography variant={'body2'} color='text.secondary'>
        {intl.formatMessage({
          id: 'components.passwordRules.passwordMustContain',
        })}
      </Typography>
      <List dense={false}>
        {passwordRules.map(passwordRule => (
          <ListItem
            key={passwordRule.label}
            disableGutters
            style={{ padding: 0 }}
          >
            <Typography variant={'body2'} color='text.secondary'>
              <ListItemText
                primary={passwordRule.label}
                style={{
                  textDecoration: passwordRule.validation(password)
                    ? 'line-through'
                    : 'none',
                }}
              />
            </Typography>
          </ListItem>
        ))}
      </List>
    </Grid>
  )
}
