import { Grid, useTheme, Paper, Theme } from '@mui/material'

import { useCompany } from 'contexts'
import { t } from 'i18n'
import { isCompanySpoofedMode } from 'utils/admin'

export default function AdminMode() {
  const theme: Theme = useTheme()
  const { company } = useCompany()!

  if (isCompanySpoofedMode(company)) {
    return (
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          margin: '0 auto',
          zIndex: 1202,
        }}
      >
        <Paper
          data-cy='is_admin_mode'
          elevation={4}
          style={{
            borderRadius: '0 0 8px 8px',
            backgroundColor: theme.palette.secondary.dark,
            color: '#FFF',
            padding: '8px 24px',
            textAlign: 'center',
          }}
        >
          {t('components.adminMode')} - {company.name}
        </Paper>
      </Grid>
    )
  }

  return null
}
