import { useState, useEffect } from 'react'

import { get, isEmpty } from 'lodash'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import { useQuery } from 'react-query'
import * as yup from 'yup'

import { ManageEmployeeForm } from './components'
import { FormHandler } from 'components'
import { API } from 'core/requests'
import { DemographicCategory } from 'enums/demographic'
import { t } from 'i18n'
import { EmployeeType } from 'types'

const validationSchema = yup.object({
  company_internal_id: yup.string().nullable(),
  first_name: yup.string(),
  last_name: yup.string(),
  title: yup.string().nullable(),
  email: yup
    .string()
    .email(t('employees.validations.invalidEmailFormat'))
    .required(t('employees.validations.emailIsRequired')),
  phone_number: yup.string().nullable(),
  // .test(
  //   'matches-phone-number',
  //   'Must be a valid US phone number.',
  //   (phone_number: any) =>
  //     !phone_number ||
  //     (phone_number &&
  //       phone_number.match(/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/)),
  // ),
  location: yup.string(),
  department: yup.string(),
  business_unit: yup.string().nullable(),
  region: yup.string().nullable(),
  territory: yup.string().nullable(),
  job_type: yup.string().nullable(),
  hired_at: yup.date().nullable().typeError(t('invalidDateFormat')),
  date_of_birth: yup.date().nullable().typeError(t('invalidDateFormat')),
  gender: yup.string().nullable(),
  ethnicity: yup.string().nullable(),
  performance_level: yup.string().nullable(),
})

const bulkEditValidationSchema = yup.object({
  location: yup.string(),
  department: yup.string(),
  business_unit: yup.string().nullable(),
  region: yup.string().nullable(),
  territory: yup.string().nullable(),
  job_type: yup.string().nullable(),
})

const defaultDemographicsCategories = [
  DemographicCategory.BusinessUnit,
  DemographicCategory.Department,
  DemographicCategory.Ethnicity,
  DemographicCategory.Gender,
  DemographicCategory.JobType,
  DemographicCategory.Location,
  DemographicCategory.Region,
  DemographicCategory.Territory,
]

export default function ManageEmployee({
  dialog,
  closeDialog,
  fetchRows,
  tableRef
}: any) {
  const intl = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const [selectedSuperiors, setSelectedSuperiors] = useState<any[]>([])
  const [selectedRoles, setSelectedRoles] = useState<any[]>([])
  const [bulkUsers, setBulkUsers] = useState<any[]>([])
  const [defaultDemographics, setDefaultDemographics] = useState({})
  const [detailsRequestDone, setDetailsRequestDone] = useState(false)

  const bulkEditMode = dialog.bulkMode

  const initialValues: EmployeeType = dialog.data
    ? dialog.data
    : bulkEditMode
    ? {
        location: '',
        department: '',
        business_unit: '',
        region: '',
        territory: '',
        job_type: '',
        high_value_talent: false,
        demographics: [],
      }
    : {
        company_internal_id: '',
        first_name: '',
        last_name: '',
        title: '',
        email: '',
        phone_number: '',
        location: '',
        department: '',
        business_unit: '',
        region: '',
        territory: '',
        job_type: '',
        hired_at: null,
        date_of_birth: null,
        gender: '',
        ethnicity: '',
        performance_level: '',
        high_value_talent: false,
        demographics: [],
      }

  const { data: employees } = useQuery('getSupervisorsEmployees', () =>
    API.get('users', null, {
      queryParams: {
        role_types: ['manager', 'executive', 'ceo'],
        limit: 100,
      },
    }).then((response) => {
      return response.users;
    }),
  )

  const { data: roles } = useQuery('getRoles', () => API.get('roles'))
  const { data: demographics } = useQuery('getDemographics', () =>
    API.get('demographics'),
  )

  function getPayloadDemographics(payload) {
    const newDemographics: {
      category: number
      demographic: number
    }[] = []

    defaultDemographicsCategories.forEach(demographicCategory => {
      const demographicCategoryId = demographics.find(
        demographic => demographic.category === demographicCategory,
      )?.id

      if (payload[demographicCategory]) {
        newDemographics.push({
          category: demographicCategoryId,
          demographic: payload[demographicCategory],
        })
      }
    })

    const highValueTalentDemographic = demographics.find(
      d => d.category === DemographicCategory.HighValueTalent,
    )

    if (highValueTalentDemographic?.id) {
      newDemographics.push({
        category: highValueTalentDemographic?.id,
        demographic:
          highValueTalentDemographic.demographics_attributes.find(
            attribute => attribute.flag === payload.high_value_talent,
          )?.id || false,
      })
    }

    const jobTypeDemographic = demographics.find(
      d => d.category === DemographicCategory.JobType,
    )
    const jobTypeDemographicSelectedValue =
      jobTypeDemographic?.demographics_attributes.find(
        attribute => attribute.flag === payload.job_type,
      )

    if (jobTypeDemographic?.id && jobTypeDemographicSelectedValue) {
      newDemographics.push({
        category: jobTypeDemographic?.id,
        demographic: jobTypeDemographicSelectedValue?.id,
      })
    }

    return newDemographics
  }

  useEffect(() => {
    if (!isEmpty(demographics)) {
      const newDemographicCategories = {}

      demographics
        ?.filter(demographic =>
          defaultDemographicsCategories.includes(demographic.category),
        )
        ?.forEach(demographic => {
          newDemographicCategories[demographic.category] =
            demographic.demographics_attributes
        })

      setDefaultDemographics(newDemographicCategories)
    }
  }, [demographics])

  return (
    <FormHandler
      title={intl.formatMessage({ id: 'employees.title' })}
      requestUrl='employees'
      detailsRequestId={get(dialog, 'data.id')}
      customMutationRequest={(payload: any) => {
        const newDemographics = getPayloadDemographics(payload)

        const commonAttributes = {
          ...payload,
          superior_direct_reports_attributes: selectedSuperiors,
          role_users_attributes: selectedRoles,
          demographics: newDemographics,
        }

        const newPayload = bulkEditMode
          ? {
              users: bulkUsers.map(user => user.id),
              attributes: commonAttributes,
            }
          : commonAttributes

        if (bulkEditMode) return API.update('employeesBulk', newPayload)
        if (!selectedRoles.length) {
          enqueueSnackbar(t('employees.snackbars.error.assignAtLeastOneRole'), {
            variant: 'error',
          })
        } else {
          return API.createOrUpdate('employees', newPayload).then((response) => {
            if (tableRef?.current && tableRef?.current?.onQueryChange) {
              tableRef?.current?.onQueryChange();
            }
            
            return (response && response?.success === false) ? false : true
          })
        }
      }}
      detailsRequestCallback={() => {
        setDetailsRequestDone(true)
      }}
      data={dialog.data}
      closeDialog={closeDialog}
      fetchRows={fetchRows}
      initialValues={initialValues}
      validationSchema={
        bulkEditMode ? bulkEditValidationSchema : validationSchema
      }
      hideHeader
      hideActionButton
    >
      {({ formik, mutation, isEditMode }) => (
        <ManageEmployeeForm
          title={intl.formatMessage({ id: 'employees.title' })}
          formik={formik}
          mutation={mutation}
          isEditMode={isEditMode}
          bulkEditMode={bulkEditMode}
          bulkUsers={bulkUsers}
          setBulkUsers={setBulkUsers}
          selectedSuperiors={selectedSuperiors}
          setSelectedSuperiors={setSelectedSuperiors}
          employees={employees}
          demographics={demographics}
          defaultDemographics={defaultDemographics}
          roles={roles}
          selectedRoles={selectedRoles}
          setSelectedRoles={setSelectedRoles}
          detailsRequestDone={detailsRequestDone}
        />
      )}
    </FormHandler>
  )
}
