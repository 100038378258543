import { useEffect, useState } from 'react'

import { useTheme } from '@mui/material'
import { get, groupBy, isEmpty, uniqBy } from 'lodash'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import { SparckComponent } from './components'
import { VIRGIN_EXPERIENCES_CALIFORNIA_REGION_ID } from 'constants/app'
import { useCompany } from 'contexts'
import { API } from 'core/requests'
import { ROUTES_PATHS } from 'core/routes'
import { ActiveInactiveStatus } from 'enums/app'
import { getRandomColor } from 'types/colors'
import { SparckType } from 'types/sparck'
import { useURLQuery } from 'utils/hooks'
import { virginExperiencesGiftCard } from 'utils/virginExperiences'

export const VIRGIN_EXPERIENCES_PER_PAGE = 50

export default function Sparck({ fromFeed = false, sendSparckCallback }) {
  const query: any = useURLQuery()

  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const theme = useTheme()
  const intl = useIntl()
  const { company } = useCompany()!

  const [sparckState, setSparckState] = useState<SparckType>({
    card_id: null,
    users_to_recognize: [],
    individual_to_recognize: null,
    who_is_meaning_from_id: null,
    event: null,
    event_id: null,
    title: '',
    message: '',
    from_where: '',
    has_public_access: true,
    rewards: [],
    CorporateRewardsCategory: [],
    corporateRewardsLoading: false,
  })

  const [rewardsState, setRewardsState] = useState([])
  const [rewardsState2, setRewardsState2] = useState([])
  const [sparckIdeas, setSparckIdeas] = useState<any>([])
  const [sparckMedia, setSparckMedia] = useState<any>({})
  const [recognitionEvents, setRecognitionEvents] = useState<any>([])
  const [dialogSparckIdeas, setDialogSparckIdeas] = useState<boolean>(false)
  const [dialogSparckPreview, setDialogSparckPreview] = useState<boolean>(false)
  const [advancedSearchFilters, setAdvancedSearchFilters] = useState({})
  const [dialogRecognitionErrorMsg, setDialogRecognitionErrorMsg] = useState<boolean>(false)
  const [recognitionErrorMsg, setRecognitionErrorMsg] = useState<any>([])

  const uploadSparckMediaMutation = useMutation(
    (payload: any) => API.createOrUpdate('uploads', payload),
    {
      onSettled: async (response: any) => {
        if (response && response.id) {
          setSparckMedia({})
          history.push(ROUTES_PATHS.feed)

          if (sendSparckCallback) sendSparckCallback()
        }
      },
    },
  )

  const sendSparckMutation = useMutation(
    (payload: any) => API.createOrUpdate('sparcks', payload),
    {
      onSuccess: (response: any) => {
        if (response?.id) {
          enqueueSnackbar(
            `${intl.formatMessage({
              id: 'sparcks.snackbar.success.youSparcked',
            })}`,
            {
              variant: 'success',
            },
          )

          if (!isEmpty(sparckMedia)) {
            const formData = new FormData()

            formData.append('file', sparckMedia)
            formData.append('attach_to', 'Recognitions::Recognition')
            formData.append('record_id', response.id)
            formData.append('record_field', 'media')

            return uploadSparckMediaMutation.mutate(formData)
          }

          setSparckMedia({})
          history.push(ROUTES_PATHS.feed)

          if (sendSparckCallback) sendSparckCallback()
        } else if(response?.success === false) {
          setDialogRecognitionErrorMsg(true)
          setRecognitionErrorMsg(response?.errors.join('\n'))
        }
      },
    },
  )

  const { mutate: fetchRecognitionEvents } = useMutation(
    () =>
      API.createOrUpdate('possibleEventsFromUsers', {
        users: sparckState.users_to_recognize.map(user => +user.id),
      }),
    {
      onSuccess: (response: any) => {
        if (response) {
          setRecognitionEvents(response)

          if (
            !response.find(event => event.event_id === sparckState.event_id)
              ?.allowed
          ) {
            setSparckState({
              ...sparckState,
              event: null,
              event_id: null,
            })
          }
        }
      },
    },
  )

  const {
    data: topFiveWishlistedRewards,
    refetch: fetchTopFiveWishlistedRewards,
  } = useQuery(
    'getTopFiveWishlistedRewards',
    () =>
      API.get('topFiveWishlistedRewards', null, {
        queryParams: {
          top: 5,
          max_amount:
            +sparckState?.event?.maximum_reward_amount_for_each_recognition,
        },
      }),
    { enabled: false },
  )

  const { data: employees, isLoading: employeesIsLoading } = useQuery(
    'employees',
    () => API.get('employeesAutocomplete'),
  )

  const { refetch: fetchUser } = useQuery(
    'user',
    () =>
      API.get(
        'users',
        sparckState.individual_to_recognize?.id ??
        query.get('user_to_recognize_id'),
      ),
    {
      enabled: false,
      onSuccess: (response: any) => {
        if (!response.errors) {
          setSparckState({
            ...sparckState,
            individual_to_recognize: response,
            users_to_recognize: uniqBy(
              [...sparckState.users_to_recognize, response],
              'id',
            ),
          })
        }
      },
    },
  )

  const { mutate: fetchSurveyRewardIntentions } = useMutation(
    (text: any) => {
      setSparckState({ ...sparckState, corporateRewardsLoading: true })
      return API.createOrUpdate('surveyRewardIntentions', {
        price: +sparckState?.event?.maximum_reward_amount_for_each_recognition,
        hobbies: text.searchText,
        catalog_id: text.currSelected,
        user_id: sparckState.users_to_recognize.map(user => +user.id)
      })
    },
    {
      onSuccess: (response: any) => {
        if (response) {
          setRewardsState2(response)
          setSparckState({ ...sparckState, corporateRewardsLoading: false })
        }
        if (response === null) {
          setSparckState({ ...sparckState, corporateRewardsLoading: false })
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError(error, variables, context) {
        error && setSparckState({ ...sparckState, corporateRewardsLoading: false })
      },
    },
  )
  // ends here
  // WIP: REPLACE WITH RECOGNITION'S ALLOWED PERKS
  const { data: rewards, refetch: fetchRewards } = useQuery(
    'getrewards',
    () =>
      API.get('rewards', null, {
        queryParams: {
          status: ActiveInactiveStatus.Active,
          max_amount:
            +sparckState?.event?.maximum_reward_amount_for_each_recognition,
        },
      }),
    { enabled: false },
  )

  const { data: rewardsNGC, refetch: fetchRewardsNGC } = useQuery(
    'getRewardsNGC',

    () =>
      API.get('rewardsNGC', null, {
        queryParams: {
          max_amount: get(
            sparckState,
            'event.maximum_reward_amount_for_each_recognition',
          ),
        },
      }),
    { enabled: false },
  )

  const {
    data: rewardsVirginExperiences,
    refetch: fetchRewardsVirginExperiences,
  } = useQuery(
    'getRewardsVirginExperiences',
    () =>
      API.get('rewardsVirginExperiences', null, {
        queryParams: {
          per_page: 200,
          q: {
            product_options_price_lteq: get(
              sparckState,
              'event.maximum_reward_amount_for_each_recognition',
            ),
            region_id_eq: VIRGIN_EXPERIENCES_CALIFORNIA_REGION_ID,
          },
        },
      }),
    { enabled: false },
  )

  const { data: cards, refetch: fetchCards } = useQuery(
    'getCards',
    () => API.get('cards'),
    { enabled: false },
  )

  const {
    data: demographics,
    isLoading: demographicsIsLoading,
    refetch: fetchDemographics,
  } = useQuery('getDemographics', () => API.get('demographics'), {
    enabled: false,
  })

  useQuery(
    'getSparckSuggestions',
    () =>
      API.get('suggestions', null, {
        queryParams: {
          types: 'manager',
        },
      }),
    {
      onSuccess: (response: any) => {
        setSparckIdeas(groupBy(response, 'name'))
      },
    },
  )

  const {
    data: sparckMessageReuseDetails,
    refetch: fetchSparckMessageReuseDetails,
  } = useQuery(
    'getSparckMessageReuse',
    () =>
      API.get('sparckMessageReuseCheck', null, {
        queryParams: {
          user_ids: sparckState.users_to_recognize.map(user => user.id),
          company_id: company?.id,
          message: sparckState?.message,
        },
      }),
    {
      enabled: false,
    },
  )

  const handleSendSparck = () => {
    sendSparckMutation.mutate({
      ...sparckState,
      title: `${get(sparckState, 'event.event_name')}`,
      users_to_recognize: sparckState.users_to_recognize.map(user => user.id),
    })
  }

  const handleSearch = (text: any) => {
    fetchSurveyRewardIntentions(text)
  }

  const { mutate: fetchCorporateRewardsCategoryList } = useMutation(() => API.createOrUpdate('rewards_carlton_catalogs', ""),
    {
      onSuccess: (response: any) => {
        if (response) {
          setSparckState({
            ...sparckState,
            CorporateRewardsCategory: response
          })
        }
      },
    },
  )

  useEffect(() => {
    fetchCards()
    fetchCorporateRewardsCategoryList()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (employees && query.get('user_to_recognize_id') && !sparckState.users_to_recognize.length) {
      const employee = employees.filter((epm: any) => epm.id == query.get('user_to_recognize_id'))
      setSparckState({
        ...sparckState,
        users_to_recognize: employee,
        individual_to_recognize: employee?.length === 1 ? employee[0] : null,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees, sparckState.users_to_recognize])

  useEffect(() => {
    if (recognitionEvents?.length > 0 && query.get('event') && sparckState.event === null) {
      const recognitionEvent = recognitionEvents.filter((recognitionEvent: any) => recognitionEvent.icon == query.get('event'))

      setSparckState({
        ...sparckState,
        event_id: recognitionEvent?.length > 0 && recognitionEvent[0]?.validation?.allowed === true ? recognitionEvent[0].event_id : null,
        event: recognitionEvent?.length > 0 && recognitionEvent[0]?.validation?.allowed === true ? recognitionEvent[0] : null
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recognitionEvents, sparckState.event, sparckState.event_id])

  useEffect(() => {
    if (
      sparckState.individual_to_recognize &&
      !sparckState.individual_to_recognize?.recognition
    ) {
      fetchUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sparckState.individual_to_recognize])

  useEffect(() => {
    if (sparckState.event_id && sparckState?.event) {
      setSparckState({
        ...sparckState,
        rewards: [],
      })
      fetchTopFiveWishlistedRewards()
      fetchRewards()
      fetchRewardsNGC()
      fetchRewardsVirginExperiences()

      console.log("sparckState: ", sparckState)
      handleSearch({ searchText: "amazon", currSelected: sparckState?.CorporateRewardsCategory[1]["id"] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sparckState.event_id])

  useEffect(() => {
    if (!isEmpty(rewards)) {
      setRewardsState(
        rewards?.map(reward => ({
          ...reward,
          backgroundColor: getRandomColor(theme, { isGradient: true }),
        })),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rewards])

  return (
    <SparckComponent
      fromFeed={fromFeed}
      sparckState={sparckState}
      setSparckState={setSparckState}
      handleSendSparck={handleSendSparck}
      sendSparckIsLoading={
        sendSparckMutation.isLoading || uploadSparckMediaMutation.isLoading
      }
      setSparckMedia={setSparckMedia}
      company={company}
      employees={employees}
      employeesIsLoading={employeesIsLoading}
      recognitionEvents={recognitionEvents}
      fetchRecognitionEvents={fetchRecognitionEvents}
      rewards={rewardsState ?? []}
      rewardsNGC={[
        ...((company?.virgin_experience_enabled
          ? [virginExperiencesGiftCard]
          : []) as any),
        ...(rewardsNGC ?? []),
      ]}
      surveyRewardIntentions={rewardsState2 ?? []}
      handleSearch={handleSearch}
      rewardsVirginExperiences={rewardsVirginExperiences ?? []}
      fetchRewardsVirginExperiences={fetchRewardsVirginExperiences}
      topFiveWishlistedRewards={topFiveWishlistedRewards ?? null}
      cards={cards ?? []}
      demographics={demographics}
      demographicsIsLoading={demographicsIsLoading}
      fetchDemographics={fetchDemographics}
      advancedSearchFilters={advancedSearchFilters}
      setAdvancedSearchFilters={setAdvancedSearchFilters}
      sparckIdeas={sparckIdeas}
      dialogSparckIdeas={dialogSparckIdeas}
      setDialogSparckIdeas={setDialogSparckIdeas}
      dialogSparckPreview={dialogSparckPreview}
      setDialogSparckPreview={setDialogSparckPreview}
      sparckMessageReuseDetails={sparckMessageReuseDetails}
      fetchSparckMessageReuseDetails={fetchSparckMessageReuseDetails}
      dialogRecognitionErrorMsg={dialogRecognitionErrorMsg}
      setDialogRecognitionErrorMsg={setDialogRecognitionErrorMsg}
      recognitionErrorMsg={recognitionErrorMsg}
    />
  )
}