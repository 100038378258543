export enum MFAFactorType {
  AuthApp = 'auth_app',
  SMS = 'sms',
  Email = 'email',
  BackupCode = 'backup_code',
}

export enum MFAStep {
  BackupCodes = 'backupCodes',
  ChooseMFAMethod = 'chooseMFAMethod',
  CreateAlternativeMethod = 'createAlternativeMethod',
  EnterCredentials = 'enterCredentials',
  EnterVerificationCode = 'enterVerificationCode',
  MethodSetup = 'methodSetup',
  ScanQRCode = 'scanQRCode',
  Success = 'success',
}

export enum MFAAuthenticatorAppSteps {
  EnterCredentials = 'enterCredentials',
  ScanQRCode = 'scanQRCode',
  EnterVerificationCode = 'enterVerificationCode',
  Success = 'success',
}

export enum MFABackupCodesSteps {
  EnterCredentials = 'enterCredentials',
  BackupCodes = 'backupCodes',
}

export enum MFAEmailSteps {
  EnterCredentials = 'enterCredentials',
  EnterVerificationCode = 'enterVerificationCode',
  Success = 'success',
}

export enum MFASMSSteps {
  EnterCredentials = 'enterCredentials',
  MethodSetup = 'methodSetup',
  EnterVerificationCode = 'enterVerificationCode',
  Success = 'success',
}
