import Picker from 'emoji-picker-react'
import { InsertEmoticon as EmojiIcon } from '@mui/icons-material'
import { Grid, IconButton, Popover } from '@mui/material'
import {
  bindPopover,
  usePopupState,
  bindTrigger,
} from 'material-ui-popup-state/hooks'

export default function TextFieldEmojiPicker({ onEmojiSelect }) {
  const emojiMenuState = usePopupState({
    variant: 'popover',
    popupId: `emojiMenu`,
  })

  return (
    <>
      <IconButton {...bindTrigger(emojiMenuState)} size='large'>
        <EmojiIcon />
      </IconButton>
      <Popover
        {...bindPopover(emojiMenuState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Picker
              onEmojiClick={(_, emojiObject) => onEmojiSelect(emojiObject)}
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  )
}
