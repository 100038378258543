import { useEffect } from 'react'

import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useScrollTrigger,
} from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'

import {
  TopbarApprovals,
  TopbarAvatar,
  TopbarLogo,
  TopbarNotifications,
  TopbarShoppingCart,
  TopbarTakeAction,
} from '.'
import { GoBackButton, LanguagePicker } from 'components'
import { useBadgesCount, useUser } from 'contexts'
import {
  DRAWER_MARGIN_LEFT,
  DRAWER_OPENED_WIDTH,
  TOP_LINE_TEAL_HEIGHT,
  TOPBAR_HEIGHT,
} from 'constants/app'
import { useTranslation } from 'i18n'

type Props = {
  routeId: string
  title?: string
  showSidebar?: boolean
  showTopbar?: boolean
  showGoBackButton?: boolean
  setMobileDrawerOpen(value: boolean): void
  isMobile: boolean
  isTablet: boolean
  isLaptop: boolean
  isDesktop: boolean
  fullWidth?: boolean
}

export default function Topbar(props: Props) {
  const {
    routeId,
    title,
    showSidebar,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    showTopbar,
    showGoBackButton,
    setMobileDrawerOpen,
    isMobile,
    isTablet,
    isLaptop,
    isDesktop,
    fullWidth,
  }: Props = props

  const { user, setUser } = useUser()!
  const { badgesCount } = useBadgesCount()!

  const documentTitle = useTranslation(`routes.${routeId}`)

  useEffect(() => {
    document.title = `Sparck ${documentTitle ? `- ${documentTitle}` : ''}`
  }, [documentTitle])

  const isScrolledDown = useScrollTrigger({
    disableHysteresis: true,
    threshold: 40,
  })

  return (
    <AppBar
      position='fixed'
      sx={{
        width:
          showSidebar && !isMobile && !isTablet
            ? `calc(100% - ${DRAWER_OPENED_WIDTH + DRAWER_MARGIN_LEFT}px)`
            : '100%',
        ml: `${showSidebar && !isMobile && !isTablet ? DRAWER_OPENED_WIDTH : 0
        }px`,
        mt: `${TOP_LINE_TEAL_HEIGHT}px`,
        backgroundColor: 'background.default',
        boxShadow: isScrolledDown
          ? '0px 0px 24px rgba(110, 120, 130, 0.25)'
          : 0,
      }}
    >
      <Toolbar
        sx={{
          height: TOPBAR_HEIGHT,
          px: fullWidth ? 0 : isMobile ? 2 : 3,
        }}
      >
        <Grid container justifyContent='center'>
          <Grid
            item
            xs
            sx={{
              maxWidth: fullWidth ? '100%' : '1280px',
              ...(showSidebar && {
                marginRight: {
                  xxl: '300px',
                },
              }),
            }}
          >
            {(isLaptop || isDesktop) && (
              <>
                <Grid
                  container
                  spacing={2}
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Grid item xs>
                    <Grid container spacing={2} alignItems='center'>
                      {showGoBackButton && (
                        <Grid item>
                          <GoBackButton sx={{ mr: 3, fontWeight: 500 }} />
                        </Grid>
                      )}

                      <Grid item>
                        <Typography
                          variant='h6'
                          component='h1'
                          color='text.secondary'
                          fontWeight={'bold'}
                          noWrap
                        >
                          {title}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item mr={1}>
                    <Grid
                      container
                      spacing={2}
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <TopbarTakeAction
                        takeActionsCount={badgesCount?.take_actions}
                      />
                      <LanguagePicker />
                      <TopbarNotifications
                        notificationsCount={badgesCount?.unread_notifications}
                      />

                      <TopbarShoppingCart
                        user={user}
                        shoppingCartItemsCount={
                          badgesCount?.shopping_cart_items
                        }
                      />

                      <TopbarApprovals
                        approvalsRequestsCount={
                          badgesCount?.pending_approval_requests_count
                        }
                      />
                    </Grid>
                  </Grid>

                  <TopbarAvatar user={user} setUser={setUser} />
                </Grid>
              </>
            )}

            {(isMobile || isTablet) && (
              <Grid
                container
                justifyContent='space-between'
                alignItems='center'
              >
                <Box visibility={showSidebar ? 'visible' : 'hidden'}>
                  <IconButton onClick={() => setMobileDrawerOpen(true)}>
                    <MenuIcon />
                  </IconButton>
                </Box>

                <TopbarLogo />

                <Box sx={{ display: 'inline-flex' }}>
                  <TopbarTakeAction
                    takeActionsCount={badgesCount?.take_actions}
                  />
                  <LanguagePicker />
                  <TopbarNotifications
                    notificationsCount={badgesCount?.unread_notifications}
                  />

                  <TopbarShoppingCart
                    user={user}
                    shoppingCartItemsCount={badgesCount?.shopping_cart_items}
                  />

                  <TopbarApprovals
                    approvalsRequestsCount={
                      badgesCount?.pending_approval_requests_count
                    }
                  />
                  <TopbarAvatar user={user} setUser={setUser} isMobile />
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
