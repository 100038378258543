import React from 'react'

import { Box, Grid, Typography } from '@mui/material'
import { FetchOptions } from 'react-query/types/core/query'

import { t } from 'i18n'
import { EmployeeType } from 'types'

type Props = {
  slug: string
  employeesIsLoading: boolean
  fetchEmployees: (
    options?: FetchOptions | undefined,
  ) => Promise<EmployeeType[] | undefined>
  employeesCount: number
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  pageSize: number
  totalCount: number
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
  favorite: ({ favorite: { user_id: number } }) => void
  unfavorite: (employeeFavoriteId: number) => void
  favoritesOnly: boolean
  setFavoritesOnly: React.Dispatch<React.SetStateAction<boolean>>
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function EmployeesDirectoryComponent(props: Props) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box my={2} width={1}>
          <Grid container justifyContent='space-between'>
            <Typography variant='h5' color='text.secondary'>
              {t('people.connect')}
            </Typography>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}
