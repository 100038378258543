import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import {
  Box,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material'

import { t } from 'i18n'

function filterData(data) {

  let result = { ...data };
  if (data) {
    if (data?.label?.includes("Below")) {
      const splittedData = data.label?.split(" ")[1];
      result = { ...result, index: (parseInt(splittedData) - 1) };
    }
    if (data?.label?.includes("-")) {
      const splittedData = data.label?.split("-")[0];
      result = { ...result, index: parseInt(splittedData) };
    }
    if (data?.label?.includes("+")) {
      const splittedData = data.label?.split("-")[0];
      result = { ...result, index: parseInt(splittedData) };
    }
  }

  return result;
}

export default function SurveyParticipation({ surveyDetails }: any) {
  const participationBySegment = surveyDetails.participation_by_demographic
  const survey = get(surveyDetails, 'survey')

  const [tabState, setTabState] = useState(0)
  const [filteredParticipationBySegment, setFilteredParticipationBySegment] =
    useState<any[]>([])

  const theme: any = useTheme()

  useEffect(() => {
    if (participationBySegment) {
      setFilteredParticipationBySegment([
        ...participationBySegment.filter(segment => segment.values.length > 0),
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participationBySegment])

  if (filteredParticipationBySegment?.length && survey) {
    return (
      <Grid container>
        <Grid container>
          <Typography variant='h5' color='text.secondary' paragraph>
            {t('beheardReport.participation.overallRate')}:{' '}
          </Typography>
          <Typography
            variant='h5'
            color='secondary.main'
            fontWeight='bold'
            paragraph
            style={{ paddingLeft: 16 }}
          >
            {Math.round(survey.participation_rate)}% (
            {survey.current_participation}/{survey.invites})
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography paragraph>
            {t('beheardReport.participation.description')}
          </Typography>
        </Grid>
 
        <Grid item xs={12}>
          <Paper square>
            <Tabs
              value={tabState}
              indicatorColor='primary'
              textColor='primary'
              variant='scrollable'
              scrollButtons='auto'
              onChange={(
                event: React.SyntheticEvent<Element, Event>,
                newValue: number,
              ) => setTabState(newValue)}
              aria-label='disabled tabs example'
            >
              {filteredParticipationBySegment.map(
                (participation, index: number) => (
                  <Tab
                    key={participation.label + index}
                    label={participation.label}
                  />
                ),
              )}
            </Tabs>

            {filteredParticipationBySegment?.map((participation: any, index: number) => (
                <div key={index} hidden={index !== tabState}>
                  <Box p={5}>
                    <Grid container key={participation.label} spacing={2}>
                      {filteredParticipationBySegment[index].values.length ===
                        0 && (
                        <Typography variant='h6' color='text.secondary'>
                          {t('beheardReport.participation.noParticipation')}
                        </Typography>
                      )}
                      {filteredParticipationBySegment[index].values?.map(res =>
                        filterData(res))?.sort((a, b) =>
                          parseFloat(a.index) - parseFloat(b.index))?.map(todo => {
                            delete todo.index; return todo;
                          }).filter(value => value.label).map((segment: any) => (
                          <Grid
                            item
                            xs={6}
                            sm={4}
                            md={3}
                            lg={2}
                            key={segment.label}
                          >
                            <Grid
                              container
                              direction='column'
                              justifyContent='space-between'
                              style={{ height: '100%' }}
                            >
                              <Grid item>
                                <Typography
                                  color='text.secondary'
                                  variant='body2'
                                  fontWeight={400}
                                >
                                  {segment.label}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography
                                  color='text.primary'
                                  variant='h4'
                                  fontWeight='bold'
                                  paragraph
                                >
                                  {typeof segment.percentage === 'number' ? (
                                    `${segment.percentage}%`
                                  ) : (
                                    <span
                                      style={{
                                        color: theme.palette.text.veryLight,
                                      }}
                                    >
                                      {segment.minimum_users_required
                                        ? segment.percentage
                                        : 'N/A'}
                                    </span>
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                    </Grid>
                  </Box>
                </div>
              ),
            )}
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='body2' color='text.light' sx={{ mt: 1 }}>
            {t(
              'beheardReport.participation.demographicsWithLessThanFiveParticipants',
            )}
          </Typography>
        </Grid>
      </Grid>
    )
  } else {
    return null
  }
}
