import palette from 'theme/palette'

export default darkMode => ({
  styleOverrides: {
    root: {
      '&$selected': {
        backgroundColor: palette(darkMode).background.default,
      },
      '&$hover': {
        '&:hover': {
          backgroundColor: palette(darkMode).background.default,
        },
      },
    },
  },
})
