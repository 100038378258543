import { UserRole, UserPermission } from 'enums/user'

export const beheardOnlyPermissions = [
  UserPermission.ManageBeheardSurveys,
  UserPermission.ManageCoreValues,
  UserPermission.ManageEmployees,
  UserPermission.ManageShoppingCarts,
  UserPermission.SendEmails,
  UserPermission.ShareReport,
  UserPermission.TakeBeheardSurvey,
  UserPermission.ViewAdministrators,
  UserPermission.ViewBeheardSurveys,
  UserPermission.ViewEmployees,
  UserPermission.ViewPriorityTasks,
  UserPermission.ViewRegularTasks,
  UserPermission.ViewReminders,
  UserPermission.ViewShoppingCarts,
]

export const permissionsByRoleType = {
  [UserRole.Employee]: [
    UserPermission.CreateFeedRecognition,
    UserPermission.CreateRecognition,
    UserPermission.ManageComments,
    UserPermission.ManagePosts,
    UserPermission.ManageTakeActions,
    UserPermission.ManageTeamsProfiles,
    UserPermission.TakeBeheardSurvey,
    UserPermission.TakeOnboarding,
    UserPermission.TakePulseSurvey,
    UserPermission.ViewBeheardSurveys,
    UserPermission.ViewComments,
    UserPermission.ViewCoreValues,
    UserPermission.ViewEmployees,
    UserPermission.ViewEmployeesProfile,
    UserPermission.ViewPosts,
    UserPermission.ViewPriorityTasks,
    UserPermission.ViewPulseSurveys,
    UserPermission.ViewRecognitions,
    UserPermission.ViewRegularTasks,
    UserPermission.ViewReminders,
    UserPermission.ViewSuggestionsBox,
    UserPermission.ViewTakeActions,
    UserPermission.ViewTeamsProfiles,
  ],
  [UserRole.SecondAdmin]: [
    UserPermission.CreateFeedRecognition,
    UserPermission.CreateRecognition,
    UserPermission.ManageComments,
    UserPermission.ManagePosts,
    UserPermission.ManageTakeActions,
    UserPermission.ManageTeamsProfiles,
    UserPermission.TakeBeheardSurvey,
    UserPermission.TakeOnboarding,
    UserPermission.TakePulseSurvey,
    UserPermission.ViewBeheardSurveys,
    UserPermission.ViewComments,
    UserPermission.ViewCoreValues,
    UserPermission.ViewEmployees,
    UserPermission.ViewEmployeesProfile,
    UserPermission.ViewPosts,
    UserPermission.ViewPriorityTasks,
    UserPermission.ViewPulseSurveys,
    UserPermission.ViewRecognitions,
    UserPermission.ViewRegularTasks,
    UserPermission.ViewReminders,
    UserPermission.ViewSuggestionsBox,
    UserPermission.ViewTakeActions,
    UserPermission.ViewTeamsProfiles,
  ],
  [UserRole.Manager]: [
    UserPermission.CreateFeedRecognition,
    UserPermission.CreateRecognition,
    UserPermission.ManageComments,
    UserPermission.ManagePosts,
    UserPermission.ManageTakeActions,
    UserPermission.ManageTeamsProfiles,
    UserPermission.TakeBeheardSurvey,
    UserPermission.TakeOnboarding,
    UserPermission.TakePulseSurvey,
    UserPermission.ViewBeheardSurveys,
    UserPermission.ViewComments,
    UserPermission.ViewCompanyInsights,
    UserPermission.ViewCoreValues,
    UserPermission.ViewEmployees,
    UserPermission.ViewEmployeesProfile,
    UserPermission.ViewPosts,
    UserPermission.ViewPriorityTasks,
    UserPermission.ViewPulseSurveys,
    UserPermission.ViewRecognitions,
    UserPermission.ViewRegularTasks,
    UserPermission.ViewReminders,
    UserPermission.ViewSuggestionsBox,
    UserPermission.ViewTakeActions,
    UserPermission.ViewTeamsInsights,
    UserPermission.ViewTeamsProfiles,
  ],
  [UserRole.Executive]: [
    UserPermission.CreateFeedRecognition,
    UserPermission.CreateRecognition,
    UserPermission.ManageComments,
    UserPermission.ManagePosts,
    UserPermission.ManageTakeActions,
    UserPermission.ManageTeamsProfiles,
    UserPermission.TakeBeheardSurvey,
    UserPermission.TakeOnboarding,
    UserPermission.TakePulseSurvey,
    UserPermission.ViewBeheardSurveys,
    UserPermission.ViewComments,
    UserPermission.ViewCompanyInsights,
    UserPermission.ViewCoreValues,
    UserPermission.ViewEmployees,
    UserPermission.ViewEmployeesProfile,
    UserPermission.ViewPosts,
    UserPermission.ViewPriorityTasks,
    UserPermission.ViewPulseSurveys,
    UserPermission.ViewRecognitions,
    UserPermission.ViewRegularTasks,
    UserPermission.ViewReminders,
    UserPermission.ViewSuggestionsBox,
    UserPermission.ViewTakeActions,
    UserPermission.ViewTeamsInsights,
    UserPermission.ViewTeamsProfiles,
  ],
  [UserRole.CEO]: [
    UserPermission.CreateFeedRecognition,
    UserPermission.CreateRecognition,
    UserPermission.ManageComments,
    UserPermission.ManagePosts,
    UserPermission.ManageTakeActions,
    UserPermission.ManageTeamsProfiles,
    UserPermission.TakeBeheardSurvey,
    UserPermission.TakeOnboarding,
    UserPermission.TakePulseSurvey,
    UserPermission.ViewBeheardSurveys,
    UserPermission.ViewComments,
    UserPermission.ViewCompanyInsights,
    UserPermission.ViewCoreValues,
    UserPermission.ViewEmployees,
    UserPermission.ViewEmployeesProfile,
    UserPermission.ViewPosts,
    UserPermission.ViewPriorityTasks,
    UserPermission.ViewPulseSurveys,
    UserPermission.ViewRecognitions,
    UserPermission.ViewRegularTasks,
    UserPermission.ViewReminders,
    UserPermission.ViewSuggestionsBox,
    UserPermission.ViewTakeActions,
    UserPermission.ViewTeamsInsights,
    UserPermission.ViewTeamsProfiles,
  ],
  [UserRole.CompanyAdmin]: [
    UserPermission.CreateFeedRecognition,
    UserPermission.CreateRecognition,
    UserPermission.ManageBeheardSurveys,
    UserPermission.ManageBudget,
    UserPermission.ManageComments,
    UserPermission.ManageCoreValues,
    UserPermission.ManageEmployees,
    UserPermission.ManageOnboardingQuestions,
    UserPermission.ManageRewards,
    UserPermission.ManagePosts,
    UserPermission.ManageProfileQuestions,
    UserPermission.ManagePulseSurveys,
    UserPermission.ManageRecognitionEvents,
    UserPermission.ManageShoppingCarts,
    UserPermission.ManageSuggestions,
    UserPermission.ManageTakeActions,
    UserPermission.ManageTeamsProfiles,
    UserPermission.SendEmails,
    UserPermission.ShareReport,
    UserPermission.TakeBeheardSurvey,
    UserPermission.TakePulseSurvey,
    UserPermission.ViewAdministrators,
    UserPermission.ViewBeheardSurveys,
    UserPermission.ViewBudget,
    UserPermission.ViewComments,
    UserPermission.ViewCompanyInsights,
    UserPermission.ViewCompanyReporting,
    UserPermission.ViewCoreValues,
    UserPermission.ViewEmployees,
    UserPermission.ViewEmployeesProfile,
    UserPermission.ViewInvoices,
    UserPermission.ViewOnboardingQuestions,
    UserPermission.ViewOtherManagersInsights,
    UserPermission.ViewRewards,
    UserPermission.ViewPosts,
    UserPermission.ViewPriorityTasks,
    UserPermission.ViewProfileQuestionLibrary,
    UserPermission.ViewProfileQuestions,
    UserPermission.ViewPulseSurveyLibrary,
    UserPermission.ViewPulseSurveys,
    UserPermission.ViewRecognitions,
    UserPermission.ViewRegularTasks,
    UserPermission.ViewReminders,
    UserPermission.ViewSuggestions,
    UserPermission.ViewSettings,
    UserPermission.ViewShoppingCarts,
    UserPermission.ViewSuggestionsBox,
    UserPermission.ViewTakeActions,
    UserPermission.ViewTeamsInsights,
    UserPermission.ViewTeamsProfiles,
  ],
}
