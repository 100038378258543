import { useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import { get, isEmpty } from 'lodash'
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import {
  FiberManualRecord as CircleIcon,
  Send as SendIcon,
} from '@mui/icons-material'
import { FormattedDate, useIntl } from 'react-intl'
import { useMutation, useQuery } from 'react-query'

import sparckLogoWhite from 'assets/images/sparck-logo-white.png'
import { LoadMoreButton } from 'components'
import { useUser } from 'contexts'
import { API } from 'core/requests'
import { ROUTES_PATHS } from 'core/routes'
import { UserRole } from 'enums/user'
import { getUniqueColor } from 'types/colors'

const NUMBER_OF_QUESTIONS_INCREMENT = 5

const TextFieldLight = withStyles({
  root: {
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid #DDD',
    },
    minWidth: 240,
  },
})(TextField)

const initialDialogRemoveAnswer = { isOpen: false, answer: null }

function AutocompleteQuestion({
  profileState,
  setProfileState,
  question,
  answers,
  answersAttributes,
  setShouldSaveAnswers,
  isLoading,
  user,
  userCanEdit,
}) {
  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('md'),
    {
      defaultMatches: true,
    },
  )

  const intl = useIntl()

  const [inputValue, setInputValue] = useState<string>('')
  const [dialogRemoveAnswer, setDialogRemoveAnswer] = useState(
    initialDialogRemoveAnswer,
  )

  const closeDialogRemoveAnswer = () => {
    setDialogRemoveAnswer(initialDialogRemoveAnswer)
  }

  const userCanManageProfileAnswers = () => {
    if (user.sparck_administrator) return true

    return userCanEdit
  }

  const send = async () => {
    if (!isLoading && inputValue) {
      const isDuplicate = profileState[answersAttributes].filter(
        currentAnswer =>
          currentAnswer.profile_question_id === question.id &&
          currentAnswer.text === inputValue &&
          !currentAnswer._destroy,
      )

      if (isEmpty(isDuplicate)) {
        await setProfileState({
          ...profileState,
          [answersAttributes]: [
            ...profileState[answersAttributes],
            {
              profile_question_id: question.id,
              profile_library_question_id: question.profile_library_question_id,
              text: inputValue,
            },
          ],
        })
      }

      setInputValue('')
      setShouldSaveAnswers(true)
    }
  }

  const removeAnswer = async answer => {
    if (!isLoading) {
      profileState[answersAttributes]
        .filter(
          currentAnswer =>
            currentAnswer.profile_question_id === question.id &&
            currentAnswer.text === answer.text,
        )
        .forEach(currentAnswer => {
          currentAnswer._destroy = true
        })

      await setProfileState({
        ...profileState,
        [answersAttributes]: profileState[answersAttributes],
      })

      setShouldSaveAnswers(true)
    }
  }

  return (
    <>
      <Paper style={{ position: 'relative' }}>
        <Box
          component='div'
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: 0,
            height: 0,
            borderTop: `80px solid ${question.color}`,
            borderRight: '80px solid transparent',
          }}
        />

        <img
          src={sparckLogoWhite}
          alt='Sparck logo'
          style={{
            position: 'absolute',
            left: 8,
            top: 8,
            width: 32,
            height: 32,
          }}
        />
        <Box width={1} p={2} pl={isMobile ? 2 : 8} pt={isMobile ? 8 : 5}>
          <Grid container spacing={3} alignItems='center'>
            <Grid item xs={12}>
              <Typography
                variant='h6'
                color='text.secondary'
                pl={isMobile ? 1 : 0}
              >
                {get(question, 'profile_library_question.statement') ??
                  question.statement}
              </Typography>
            </Grid>
            {userCanManageProfileAnswers() && (
              <Grid
                item
                xs={12}
                sm
                onKeyUp={({ key }) => key === 'Enter' && send()}
                style={{ width: '80%' }}
              >
                <TextFieldLight
                  value={inputValue}
                  variant='standard'
                  fullWidth
                  placeholder={intl.formatMessage({
                    id: 'components.profileAutocompleteQuestions.placeholder',
                  })}
                  onChange={event => setInputValue(event.target.value)}
                />
              </Grid>
            )}

            {inputValue && (
              <Grid item xs={12} sm='auto'>
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  aria-label='add profile answer'
                  onClick={send}
                >
                  {intl.formatMessage({
                    id: 'components.profileAutocompleteQuestions.save',
                  })}{' '}
                  <SendIcon style={{ marginLeft: 8 }} />
                </Button>
              </Grid>
            )}

            <Grid item xs={12}>
              {answers?.map(answer => (
                <Chip
                  key={answer.text}
                  label={
                    <Typography 
                      style={{
                        flexWrap: "wrap",
                        flex: 1,
                        whiteSpace: "pre-line"
                      }}
                    >
                      {answer.text}
                      {answer.updated_at && (
                        <Typography
                          component='span'
                          color='text.light'
                          fontSize={12}
                          ml={1}
                        >
                          <FormattedDate
                            value={answer.updated_at}
                            year='numeric'
                            month='numeric'
                            timeZone='utc'
                          />
                        </Typography>
                      )}
                    </Typography>
                  }
                  variant='outlined'
                  color={answer.added_by_manager ? 'secondary' : 'primary'}
                  style={{
                    fontWeight: 600,
                    marginBottom: 16,
                    height: "100%"
                  }}
                  disabled={isLoading}
                  {...(userCanManageProfileAnswers() && {
                    onDelete: () =>
                      setDialogRemoveAnswer({ isOpen: true, answer: answer }),
                  })}
                />
              ))}
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <Dialog
        open={dialogRemoveAnswer.isOpen}
        onClose={closeDialogRemoveAnswer}
        aria-labelledby='answer-confirm-delete-title'
        aria-describedby='answer-confirm-delete-description'
      >
        <DialogTitle id='answer-confirm-delete-title'>
          {intl.formatMessage({
            id: 'components.profileAutocompleteQuestions.dialogTitle',
          })}
        </DialogTitle>
        {dialogRemoveAnswer.answer && (
          <DialogContent>
            <DialogContentText id='answer-confirm-delete-description'>
              {intl.formatMessage({
                id: 'components.profileAutocompleteQuestions.dialogContent',
              })}{' '}
              <strong>{get(dialogRemoveAnswer, 'answer.text')}</strong>?
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={closeDialogRemoveAnswer} color='primary'>
            {intl.formatMessage({
              id: 'cancel',
            })}
          </Button>
          <Button
            onClick={() => {
              closeDialogRemoveAnswer()
              removeAnswer(get(dialogRemoveAnswer, 'answer'))
            }}
            color='secondary'
            disabled={false}
            autoFocus
          >
            {intl.formatMessage({
              id: 'components.profileAutocompleteQuestions.removeAnswer',
            })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default function ProfileAutocompleteQuestions(props: any) {
  const {
    profileState,
    setProfileState,
    questions,
    answersAttributes,
    showLoadMore,
    profileId,
    isFromOnboarding,
    userCanEdit,
  } = props

  const [shouldSaveAnswers, setShouldSaveAnswers] = useState(false)
  const [numberOfAttributes, setNumberOfAttributes] = useState<number>(
    NUMBER_OF_QUESTIONS_INCREMENT,
  )

  const { user, setUser } = useUser()!
  const theme: any = useTheme()
  const location = useLocation()

  const intl = useIntl()

  const { isLoading: userIsLoading, refetch: fetchUser } = useQuery(
    'user',
    () => API.get('users', profileId ?? user.id),
    {
      enabled: false,
      onSuccess: async response => {
        if (userCanEdit) {
          setUser({
            ...user,
            [answersAttributes]: response[answersAttributes],
          })
        }

        if (JSON.stringify(response) !== JSON.stringify(profileState)) {
          await setProfileState(response)
        }
      },
    },
  )

  const profileQuestionsMutation = useMutation(
    (payload: any) => API.createOrUpdate('users', payload),
    {
      onSettled: () => {
        fetchUser()
      },
    },
  )

  useEffect(() => {
    if (shouldSaveAnswers) {
      profileQuestionsMutation.mutate(profileState)
      setShouldSaveAnswers(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSaveAnswers])

  return (
    <Grid container>
      {!isFromOnboarding && (
        <Grid item xs={12}>
          <Box my={2} width={1}>
            {
              (!user.roles.includes(UserRole.Employee)) && (
                <Grid container>
                  {[
                    {
                      id: 'managers',
                      label: intl.formatMessage({
                        id: 'components.profileAutocompleteQuestions.legendLabels.managers',
                      }),
                      color: 'secondary',
                    },
                    {
                      id: 'employees',
                      label: intl.formatMessage({
                        id: 'components.profileAutocompleteQuestions.legendLabels.employees',
                      }),
                      color: 'primary',
                    },
                  ].map(legend => (
                    <Typography
                      key={legend.id}
                      variant='body2'
                      color='text.primary'
                      mr={2}
                    >
                      <Grid container component='span' alignItems='center'>
                        <CircleIcon
                          style={{
                            color: theme.palette[legend.color].main,
                            marginRight: 4,
                          }}
                        />{' '}
                        {legend.label}
                      </Grid>
                    </Typography>
                  ))}
                </Grid>
            )}
          </Box>
        </Grid>
      )}
      {questions?.filter((_, index) =>
          showLoadMore ? index < numberOfAttributes : index < questions.length,
        )
        .map(question => {
          question.color = getUniqueColor(theme, {
            id: question.id,
          })

          const answers = profileState[answersAttributes]?.filter(
            attribute =>
              attribute.profile_question_id === question.id &&
              !attribute._destroy,
          )

          return (
            <Grid item xs={12} key={question.id}>
              <Box width={1} mt={2}>
                <AutocompleteQuestion
                  {...props}
                  question={question}
                  answers={answers}
                  setShouldSaveAnswers={setShouldSaveAnswers}
                  user={user}
                  isLoading={
                    userIsLoading ?? profileQuestionsMutation.isLoading
                  }
                />
              </Box>
            </Grid>
          )
        })}
      {location.pathname === ROUTES_PATHS.onboarding && (
        <Grid item xs={12}>
          <LoadMoreButton
            limit={numberOfAttributes}
            setLimit={setNumberOfAttributes}
            list={questions}
            listLabel={intl.formatMessage({
              id: 'components.profileAutocompleteQuestions.listLabel',
            })}
            numberOfQuestionsIncrement={NUMBER_OF_QUESTIONS_INCREMENT}
          />
        </Grid>
      )}
    </Grid>
  )
}
