import { Box, Grid, Typography, useTheme } from '@mui/material'

import sparckLogoWhite from 'assets/images/sparck-logo-white.png'
import { t } from 'i18n'

export default function SurveyLandingWelcomeHeader() {
  const theme = useTheme()

  return (
    <Grid item xs={12}>
      <Grid
        container
        justifyContent='center'
        sx={{
          width: '100%',
          color: '#fff',
          background: `linear-gradient(127.84deg, ${theme.palette.secondary.dark} 0%, ${theme.palette.secondary.main} 100%)`,
          padding: '16px',
          marginBottom: '32px',
        }}
      >
        <Grid item xs={12} lg={11} xl={10}>
          <Box ml={2}>
            <Grid container justifyContent='flex-start' alignItems='center'>
              <Box mr={2}>
                <img
                  src={sparckLogoWhite}
                  alt='Sparck logo'
                  style={{ width: 40 }}
                />
              </Box>
              <Typography variant='h5' fontWeight={500}>
                {t('surveyLanding.welcomeToSparck')}
              </Typography>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}
