import React from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Button } from '@mui/material';
import { useMutation } from 'react-query';
import { API } from 'core/requests';
import CardSection from './CardSection';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';


export default function CheckoutForm({ handleClose, setWalletMoney, amount, getCurrentTransaction, refreshTableData }: {
  handleClose: any;
  setWalletMoney: any;
  amount: any;
  getCurrentTransaction: () => void;
  refreshTableData: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();

  const stripe = useStripe();
  const elements = useElements();

  const sparck_company: any = localStorage.getItem("sparck_company");
  const com_id: any = JSON.parse(sparck_company).id;

  const { mutate: addmoneyCard } = useMutation(
    (payload: any) => API.createOrUpdate('addMoneyWithCart', payload),
    {
      onSuccess: (response: any) => {
        if (!response.errors) {
          setWalletMoney(1.0);
          enqueueSnackbar(
            'Deposit in progress.  Please allow 3-4 business days for the processing.',
            { variant: 'success' },
          );
          getCurrentTransaction();
          refreshTableData();
        }
      },
    },
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubmit = async (event) => {
    if (!stripe || !elements) {
      return;
    }

    const card: any = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      // Show error to your customer.
      enqueueSnackbar(result?.error.message,
        { variant: 'error' },
      );
    } else {
      const cartData = {
        amount: parseFloat(amount),
        currency: "usd",
        company_id: com_id,
        token: result.token?.id,
        stripe_data: result.token
      }
      addmoneyCard(cartData)
      handleClose();
    }
  };

  return (
    <>
      <CardSection />
      <Box mt={3}>
        <Button variant="contained" color={'primary'} fullWidth onClick={(e) => handleSubmit(e)}>Pay</Button>
      </Box>
    </>
  );
}