import { useMemo } from 'react'

import { Grid, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { get, uniqBy } from 'lodash'

import { MyRewardsGrid } from '../../components'
// import { GiftCardRewardCard } from 'components'
// import { useCompany } from 'contexts'
import { RewardIntentionStatus, RewardType } from 'enums/reward'
import { t } from 'i18n'

const REWARDS_PREVIEW_COUNT = 5

const ColoredTypography = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(to left, ${theme.palette.secondary.light}, ${theme.palette.secondary.dark})`,
  display: 'inline-block',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
}))

export default function MyRewardsHome(props) {
  // const { company } = useCompany()!
  const { myRewards, topFiveWishlistedRewards } = props

  const newRewards = (myRewards ?? []).filter(
    reward => reward.status === RewardIntentionStatus.Waiting,
  )

  const getRewardsPreview = () => {
    if (topFiveWishlistedRewards) {
      const topOrganizationRewards = get(
        topFiveWishlistedRewards,
        `data.company.retailer`,
        [],
      )

      const topSparckRewards = get(
        topFiveWishlistedRewards,
        `data.sparck.retailer`,
        [],
      )

      const rewardsPreview = uniqBy(
        [
          ...topOrganizationRewards,
          ...topSparckRewards,
          ...(myRewards ?? [])?.filter(
            reward => reward?.type === RewardType.Retailer,
          ),
        ],
        'id',
      ).slice(0, REWARDS_PREVIEW_COUNT)

      return rewardsPreview
    }

    return []
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const rewardsPreview = useMemo(
    () => getRewardsPreview(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [topFiveWishlistedRewards],
  )

  return (
    <Grid container>
      {newRewards?.length ? (
        <Grid item xs={12}>
          <ColoredTypography variant='h4' fontWeight={700} paragraph>
            {t('myRewards.youHaveNewRewards', {
              count: newRewards?.length,
            })}
          </ColoredTypography>
        </Grid>
      ) : null}

      <Grid item xs={12} sx={{ mb: newRewards?.length ? 3 : 0 }}>
        <MyRewardsGrid {...props} myRewards={newRewards} />
      </Grid>

      {/* {rewardsPreview?.length > 0 && (
        <>
          <Grid item xs={12}>
            <Typography
              color='text.primary'
              variant='h6'
              fontWeight={600}
              paragraph
            >
              Most Hearted {company?.name ?? ''} Rewards
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {rewardsPreview
                .filter((_, index) => index < 5)
                .map((reward, index) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={index <= 1 ? 6 : 4}
                      lg={
                        rewardsPreview?.length < REWARDS_PREVIEW_COUNT
                          ? 3
                          : true
                      }
                      key={reward.id}
                    >
                      <GiftCardRewardCard reward={reward} />
                    </Grid>
                  )
                })}
            </Grid>
          </Grid>
        </>
      )} */}
    </Grid>
  )
}
