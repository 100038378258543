import { useMutation } from 'react-query'

import { decodeJWT } from './auth/auth'
import {
  SPARCK_USER_TOKEN,
  SPARCK_USER_ID,
  SPARCK_SPOOFED_USER_TOKEN,
  SPARCK_SPOOFED_USER_ID,
} from 'constants/app'
import { useUser } from 'contexts'
import { API } from 'core/requests'

export const useSpoofUser = () => {
  const { setUser } = useUser()!

  const { mutate: spoof } = useMutation(
    (payload: any) => API.create('spoofEmployee', payload),
    {
      onSettled: (response: any) => {
        if (response && response.data) {
          const responseData = response.data
          const userData = decodeJWT(responseData.access_token)

          localStorage.setItem(
            SPARCK_SPOOFED_USER_TOKEN,
            responseData.access_token,
          )
          localStorage.setItem(SPARCK_SPOOFED_USER_ID, userData?.sub)

          window.location.reload()
        }
      },
    },
  )

  const unspoof = () => {
    localStorage.removeItem(SPARCK_SPOOFED_USER_TOKEN)
    localStorage.removeItem(SPARCK_SPOOFED_USER_ID)

    const userId = localStorage.getItem(SPARCK_USER_ID)
    const userToken = localStorage.getItem(SPARCK_USER_TOKEN)
    const userData = decodeJWT(userToken)

    setUser({
      ...userData,
      sub: userId,
      token: userToken,
    })
  }

  return { spoof, unspoof }
}
