import { useState } from 'react'

import { useMutation, useQuery } from 'react-query'
import { useSnackbar } from 'notistack'

import { NominationsManagementComponent } from './components'
import { API } from 'core/requests'

enum NominationEventStatus {
  Open = 'open',
  Closed = 'closed',
}

export default function NominationsManagement() {
  const [currentNominations, setCurrentNominations] = useState<any[]>([])
  const [previousNominations, setPreviousNominations] = useState<any[]>([])

  const { enqueueSnackbar } = useSnackbar()

  const {
    isLoading: currentNominationsAreLoading,
    refetch: fetchCurrentNominations,
  } = useQuery('getNominationsPrograms', () => API.get('nominationsPrograms'), {
    enabled: true,
    onSuccess: response => {
      if (response?.data) {
        const newNominations: any[] = []

        response.data.map((nomination: any) => {
          nomination.events.map((nominationEvent: any) => {
            newNominations.push({
              ...nominationEvent,
              frequency: nomination.frequency,
              occurrence_type: nomination.occurrence_type,
              nominations: nominationEvent.nominations.map(nomination => ({
                ...nomination,
                nominee: nomination.nominees[0]?.user,
              })),
            })
          })
        })

        const newCurrentNominations = newNominations.filter(
          nomination => nomination.status === NominationEventStatus.Open,
        )
        const newPreviousNominations = newNominations.filter(
          nomination => nomination.status === NominationEventStatus.Closed,
        )

        setCurrentNominations(newCurrentNominations)
        setPreviousNominations(newPreviousNominations)
      }
    },
  })

  const awardNomineesMutation = useMutation(
    (payload: any) => API.create('nominationsAwards', payload),
    {
      onSuccess: response => {
        if (!response.errors) {
          enqueueSnackbar('Successfully awarded!', { variant: 'success' })
          fetchCurrentNominations()
        }
      },
    },
  )

  return (
    <>
      <NominationsManagementComponent
        previousNominations={previousNominations}
        currentNominations={currentNominations ?? []}
        currentNominationsAreLoading={currentNominationsAreLoading}
        awardNomineesMutation={awardNomineesMutation}
      />
    </>
  )
}
