import { InfoOutlined as InfoIcon } from '@mui/icons-material'
import { Grid, InputAdornment, TextField, Switch, Tooltip } from '@mui/material'
import { useIntl } from 'react-intl'

import { SettingsControlSection } from '../SettingsComponent/SettingsComponent'

export default function SettingsFeed(props) {
  const { companyState, setCompanyState } = props

  const intl = useIntl()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} data-cy='show_suggestion_box'>
        <SettingsControlSection
          {...props}
          id='show_suggestion_box'
          label={intl.formatMessage({
            id: 'settings.sectionControlLabels.feed.suggestionBox',
          })}
          ControlComponent={Switch}
        />

        {companyState.show_suggestion_box && (
          <TextField
            data-cy='suggestion_box_name'
            id='suggestion_box_name'
            name='suggestion_box_name'
            label={intl.formatMessage({
              id: 'settings.formLabels.suggestionBox',
            })}
            placeholder={intl.formatMessage({
              id: 'settings.placeholders.suggestionBox',
            })}
            variant='outlined'
            value={companyState.suggestion_box_name}
            onChange={event =>
              setCompanyState({
                ...companyState,
                suggestion_box_name: event.target.value,
              })
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Tooltip
                    title={intl.formatMessage({
                      id: 'settings.suggestionBoxTooltipTitle',
                    })}
                  >
                    <InfoIcon style={{ color: '#AAA' }} />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            fullWidth
            margin='normal'
          />
        )}
      </Grid>
    </Grid>
  )
}
