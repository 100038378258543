import { useState, useEffect } from 'react'

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material'
import { useHistory } from 'react-router-dom'

import { Props } from '../../Login.props'
import { ROUTES_PATHS } from 'core/routes'
import { MFAFactorType } from 'enums/multiFactorAuthentication'
import { t } from 'i18n'

const alternateMFAOptions = [
  {
    id: MFAFactorType.AuthApp,
    label: t('login.loginForm.labels.authApp'),
  },
  {
    id: MFAFactorType.Email,
    label: t('email'),
  },
  {
    id: MFAFactorType.SMS,
    label: t('phoneNumber'),
  },
  {
    id: MFAFactorType.BackupCode,
    label: t('login.loginForm.labels.useBackupCode'),
  },
]

export default function LoginForm(props: Props) {
  const {
    loginState,
    setLoginState,
    mfaDetails,
    loginMutation,
    startMFAAuthenticationMutation,
    finishMFAAuthenticationMutation,
  } = props

  const history = useHistory()

  const [selectedVerificationMethod, setSelectedVerificationMethod] = useState(
    mfaDetails?.factor_type,
  )

  const [showVerificationMethods, setShowVerificationMethods] = useState(false)

  const showBackupCodeInput =
    selectedVerificationMethod === MFAFactorType.BackupCode

  useEffect(() => {
    setSelectedVerificationMethod(mfaDetails?.factor_type)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mfaDetails?.factor_type])

  return (
    <>
      <Grid
        container
        onKeyUp={({ key }) =>
          key === 'Enter' &&
          (mfaDetails
            ? finishMFAAuthenticationMutation.mutate(loginState)
            : loginMutation.mutate(loginState))
        }
      >
        <Grid container justifyContent='center'>
          <Grid item xs={12}>
            <Typography variant='h5' color='text.secondary' align='center'>
              {t('login.loginForm.welcome')}
            </Typography>
          </Grid>
        </Grid>

        <Box my={5} width={1}>
          <Grid container spacing={2}>
            {!mfaDetails && (
              <>
                <Grid item xs={12}>
                  <TextField
                    id='email'
                    type='email'
                    label={t('email')}
                    variant='filled'
                    data-cy='email'
                    value={loginState.email}
                    onChange={e =>
                      setLoginState({
                        ...loginState,
                        email: e.target.value,
                      })
                    }
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id='password'
                    type='password'
                    label={t('login.loginForm.labels.password')}
                    variant='filled'
                    data-cy='password'
                    value={loginState.password}
                    onChange={e =>
                      setLoginState({
                        ...loginState,
                        password: e.target.value,
                      })
                    }
                    fullWidth
                  />
                </Grid>
              </>
            )}

            {mfaDetails && (
              <>
                <MFAVerificationCodeMessage
                  mfaDetails={mfaDetails}
                  selectedVerificationMethod={selectedVerificationMethod}
                />

                <Grid item xs={12}>
                  <TextField
                    id='factor_code'
                    type='factor_code'
                    label={
                      showBackupCodeInput
                        ? t('login.loginForm.labels.backupCode')
                        : t('login.loginForm.labels.verificationCode')
                    }
                    variant='filled'
                    data-cy='factor_code'
                    value={loginState.mfa_backup_code}
                    onChange={e =>
                      setLoginState({
                        ...loginState,
                        factor_code: e.target.value,
                      })
                    }
                    fullWidth
                  />
                </Grid>
              </>
            )}

            {mfaDetails?.mfa_type_options?.length > 0 &&
              !showVerificationMethods && (
                <Grid item xs={12}>
                  <Typography align='center'>
                    <Link onClick={() => setShowVerificationMethods(true)}>
                      {t('login.loginForm.labels.useDifferentMFAMethod')}
                    </Link>
                  </Typography>
                </Grid>
              )}

            {showVerificationMethods && (
              <Grid item xs={12}>
                {alternateMFAOptions
                  .filter(
                    option =>
                      (option.id !== selectedVerificationMethod &&
                        mfaDetails?.mfa_type_options?.includes(option.id)) ||
                      option.id === MFAFactorType.BackupCode,
                  )
                  .map(option => (
                    <Grid
                      key={option.id}
                      container
                      spacing={2}
                      justifyContent='space-between'
                      alignItems='center'
                      sx={{ mb: 1 }}
                    >
                      <Grid item xs>
                        {option.label}
                      </Grid>

                      <Grid item xs='auto'>
                        <Button
                          color='primary'
                          variant='outlined'
                          size='small'
                          disabled={
                            loginState.factor_type === option.id &&
                            startMFAAuthenticationMutation.isLoading
                          }
                          onClick={() => {
                            setLoginState({
                              ...loginState,
                              factor_type: option.id,
                            })

                            startMFAAuthenticationMutation.mutate({
                              ...loginState,
                              factor_type: option.id,
                            })
                          }}
                        >
                          {loginState.factor_type === option.id &&
                          startMFAAuthenticationMutation.isLoading ? (
                            <CircularProgress size={24} />
                          ) : (
                            'Send'
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            )}
          </Grid>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              color='primary'
              variant='contained'
              size='large'
              onClick={() =>
                mfaDetails
                  ? finishMFAAuthenticationMutation.mutate(loginState)
                  : loginMutation.mutate(loginState)
              }
              disabled={
                loginMutation.isLoading ||
                finishMFAAuthenticationMutation.isLoading
              }
              data-cy='submit_form'
              fullWidth
            >
              {loginMutation.isLoading ||
              finishMFAAuthenticationMutation.isLoading ? (
                <CircularProgress size={24} />
              ) : (
                t('login.loginForm.loginButton')
              )}
            </Button>
          </Grid>

          {!mfaDetails && (
            <Grid item xs={12}>
              <Button
                color='primary'
                variant='outlined'
                data-cy='forgot_password'
                size='large'
                onClick={() => history.push(ROUTES_PATHS.recoverPassword)}
                fullWidth
              >
                {t('login.loginForm.forgotPasswordButton')}
              </Button>
            </Grid>
          )}

          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Link
              onClick={() => {
                localStorage.clear()
                window.location.reload()
              }}
              sx={{ fontSize: '0.85em' }}
            >
              Login problems? Click here to clear all cache and reload
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

function MFAVerificationCodeMessage({
  mfaDetails,
  selectedVerificationMethod,
}) {
  return (
    <Grid item>
      <Typography sx={{ mb: 2 }}>
        {selectedVerificationMethod === MFAFactorType.AuthApp &&
          t('mfa.enterVerificationCodeMessage.authenticatorApp')}
        {selectedVerificationMethod === MFAFactorType.SMS &&
          t('mfa.enterVerificationCodeMessage.sms', {
            phoneNumberLastDigits: mfaDetails.masked_phone_number,
          })}
        {selectedVerificationMethod === MFAFactorType.Email &&
          t('mfa.enterVerificationCodeMessage.email', {
            email: mfaDetails.masked_email,
          })}
      </Typography>
    </Grid>
  )
}
