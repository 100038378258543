export enum OnboardingQuestionType {
  Rating = 'rating',
  Reward = 'reward',
  SimpleSelect = 'simple_select',
  MultipleSelect = 'multiple_select',
  Event = 'event',
  ChoiceGroup = 'choice_group',
}

export enum OnboardingQuestionVisibility {
  InsideCompany = 'inside_company',
  AcrossCompanies = 'across_companies',
}

export enum OnboardingPage {
  Landing = 'landing',
  RatingAndSimpleSelect = 'rating_and_simple_select',
  ProfileQuestions = 'profile_questions',
  Reward = 'reward',
  Event = 'event',
  ChoiceGroup = 'choice_group',
}
