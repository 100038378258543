import { useCallback, useMemo, useState } from 'react'

import {
  alpha,
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  useTheme,
} from '@mui/material'
import { differenceInDays } from 'date-fns'
import { get } from 'lodash'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'

import { SparckTable, UserAvatar } from 'components'
import { ROUTES_PATHS } from 'core/routes'
import { t } from 'i18n'
import { getPagination } from 'utils/pagination'
import { getUserFullName } from 'utils/user'
import { getDateLabel } from 'utils/tables/dataTypes'

const slug = 'mySparcks'

export default function TeamInsightsComponent({ insights, insightsMeta }) {
  const [pagination, setPagination] = useState(getPagination(slug))
  const [sparcksType, setSparcksType] = useState('my_recognitions')

  const theme = useTheme()
  const history = useHistory()
  const intl = useIntl()

  const mySparcks = useMemo(
    () => get(insights, `recognitions.${sparcksType}`, []),
    [insights, sparcksType],
  )

  const MAX_NUMBER_OF_DAYS_UNTIL_RECOGNITION = useMemo(
    () =>
      insightsMeta?.recognition_frequency?.manager_level ??
      insightsMeta?.recognition_frequency?.company_level,
    [insightsMeta],
  )

  const userMustBeRecognizedUrgently = useCallback(
    data => {
      if (data?.last_recognition_sent_at) {
        return (
          differenceInDays(
            new Date(),
            new Date(data.last_recognition_sent_at),
          ) > MAX_NUMBER_OF_DAYS_UNTIL_RECOGNITION
        )
      }

      return (
        differenceInDays(
          new Date(),
          new Date(insightsMeta?.applications?.beseen?.created_at),
        ) > MAX_NUMBER_OF_DAYS_UNTIL_RECOGNITION
      )
    },
    [insightsMeta, MAX_NUMBER_OF_DAYS_UNTIL_RECOGNITION],
  )

  const commonColumns = [
    {
      title: intl.formatMessage({
        id: 'insights.team.sparckTable.picture',
      }),
      field: 'avatar',
      render: (rowData: any) => (
        <UserAvatar
          userId={get(rowData, 'id')}
          name={rowData.first_name}
          src={get(rowData, 'avatar.url')}
          size={40}
        />
      ),
    },
    {
      title: intl.formatMessage({ id: 'name' }),
      field: 'first_name',
      render: getUserFullName,
    },
    {
      title: intl.formatMessage({
        id: 'insights.team.sparckTable.recognitionsSent',
      }),
      field: 'total_recognitions_sent',
      align: 'center',
    },
  ]

  const columns: any =
    sparcksType === 'my_recognitions'
      ? [
          ...commonColumns,
          {
            title: intl.formatMessage({
              id: 'insights.team.sparckTable.rewardsSent',
            }),
            field: 'rewards_sent',
            align: 'center',
          },
          {
            title: intl.formatMessage({
              id: 'insights.team.sparckTable.lastRecognitionSent',
            }),
            field: 'last_recognition_sent_at',
            align: 'center',
            render: (rowData: any) =>
              rowData?.last_recognition_sent_at
                ? getDateLabel(rowData.last_recognition_sent_at)
                : intl.formatMessage({
                    id: 'insights.team.sparckTable.never',
                  }),
          },
          {
            title: intl.formatMessage({
              id: 'insights.team.sparckTable.action',
            }),
            field: 'recognize_now',
            align: 'center',
            render: (rowData: any) => (
              <Button
                color={
                  userMustBeRecognizedUrgently(rowData) ? 'error' : 'primary'
                }
                size='small'
                onClick={() =>
                  history.push(
                    `${ROUTES_PATHS.sparck}?user_to_recognize_id=${rowData.id}`,
                  )
                }
              >
                {intl.formatMessage({
                  id: 'insights.team.sparckTable.recognizeNow',
                })}
              </Button>
            ),
          },
        ]
      : [
          ...commonColumns,
          {
            title: intl.formatMessage({
              id: 'insights.team.sparckTable.recognitionsReceived',
            }),
            field: 'total_recognitions_received',
            align: 'center',
          },
          {
            title: intl.formatMessage({
              id: 'insights.team.sparckTable.recognitionReadiness',
            }),
            field: 'relationship_strength',
            align: 'center',
            render: (rowData: any) => `${rowData.relationship_strength}%`,
          },
        ]

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box width={1} p={2}>
          <Grid container>
            <Grid item xs={12} sm={8} md={6}>
              <TextField
                select
                id='sparcksType'
                variant='outlined'
                value={sparcksType}
                onChange={event => setSparcksType(event.target.value)}
                fullWidth
              >
                {[
                  {
                    id: 'my_recognitions',
                    label: t(
                      'insights.team.sparckTable.titles.myRecognitionReport',
                    ),
                  },
                  {
                    id: 'my_team_recognitions',
                    label: t(
                      'insights.team.sparckTable.titles.myTeamRecognitionReport',
                    ),
                  },
                ].map(type => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <SparckTable
          slug={slug}
          data={mySparcks}
          columns={columns}
          pagination={pagination}
          setPagination={setPagination}
          options={{
            rowStyle: rowData => ({
              backgroundColor: userMustBeRecognizedUrgently(rowData)
                ? alpha(theme.palette.error.light, 0.04)
                : 'inherit',
              border: userMustBeRecognizedUrgently(rowData)
                ? `2px solid ${alpha(theme.palette.error.light, 0.5)}`
                : 'none',
            }),
          }}
        />
      </Grid>
    </Grid>
  )
}
