import { Generation } from 'enums/generation'

export const GENERATIONS = [
  {
    label: 'sparckAppreciationAssessment.generations.babyBoomer',
    value: Generation.BabyBoomer,
  },
  {
    label: 'sparckAppreciationAssessment.generations.generationX',
    value: Generation.GenerationX,
  },
  {
    label: 'sparckAppreciationAssessment.generations.generationY',
    value: Generation.GenerationY,
  },
  {
    label: 'sparckAppreciationAssessment.generations.generationZ',
    value: Generation.GenerationZ,
  },
  {
    label: 'sparckAppreciationAssessment.generations.generationAlpha',
    value: Generation.GenerationAlpha,
  },
]
