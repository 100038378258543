import { Grid, Typography } from '@mui/material'
import { useMutation } from 'react-query'

import { DialogTeleport } from 'components'
import { initialDialog } from 'constants/dialog'
import { API } from 'core/requests'
import { AssignMode } from 'enums/suggestion'

export default function SuggestionAssigneDialog({
  dialog,
  setDialog,
  fetchSuggestions,
}: any) {
  const suggestionId = dialog?.data?.id
  const assignMode = dialog?.data?.assignMode

  const assignToSuggestionMutation = useMutation(
    () =>
      API.update('assignToSuggestion', {
        id: suggestionId,
      }),
    {
      onSuccess: (response: any) => {
        if (response.id) {
          fetchSuggestions()
          setDialog(initialDialog)
        }
      },
    },
  )

  const unassignFromSuggestionMutation = useMutation(
    () =>
      API.update('unassignToSuggestion', {
        id: suggestionId,
      }),
    {
      onSuccess: (response: any) => {
        if (response.id) {
          fetchSuggestions()
          setDialog(initialDialog)
        }
      },
    },
  )
  return (
    <DialogTeleport
      dialogTitle={
        assignMode === AssignMode.Assign ? 'Assign to Me' : 'Unassign'
      }
      dialogAction={{
        label: assignMode === AssignMode.Assign ? 'Assign to Me' : 'Unassign',
        function:
          assignMode === AssignMode.Assign
            ? assignToSuggestionMutation.mutate
            : unassignFromSuggestionMutation.mutate,
      }}
      dialogSize='sm'
      dialogOpen={dialog.isOpen}
      handleDialogClose={() => setDialog({ isOpen: false, data: null })}
      isFullWidth
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='body1' color='text.primary'>
            {assignMode === AssignMode.Assign
              ? 'This will assign this suggestion to you so any additional notifications will be directed to you for review and reply.'
              : 'This will remove you as the assigned person for this suggestion and change its status to unassigned.  Notifications will still come to you as the last person assigned until this suggestion is assigned to someone else.'}
          </Typography>
        </Grid>
      </Grid>
    </DialogTeleport>
  )
}
