import { useState } from 'react'

import {
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { InfoOutlined as InfoIcon } from '@mui/icons-material'

import { OnboardingCard } from '../../components'
import { LoadMoreButton, ThumbsSelector } from 'components'
import {
  recognitionGroups,
  recognitionGroupsSettings,
} from 'constants/recognition'
import { t } from 'i18n'

const recognitionColumns: any = [
  {
    id: 'recognition_areas',
    name: t('onboarding.recognitionColumns.recognitionAreas'),
    align: 'left',
  },
  {
    id: 'recognition_be_meaningful',
    name: t('onboarding.recognitionColumns.recognitionMeaningful'),
    align: 'center',
  },
  {
    id: 'public_setting',
    name: t('onboarding.recognitionColumns.publicSetting'),
    align: 'center',
  },
  {
    id: 'by_who',
    name: t('onboarding.recognitionColumns.byWho'),
    align: 'left',
  },
  {
    id: 'how',
    name: t('onboarding.recognitionColumns.how'),
    align: 'left',
  },
]

const NUMBER_OF_QUESTIONS_INCREMENT = 5

export default function OnboardingEventQuestions({
  eventQuestions,
  answers,
  setAnswers,
  theme,
}) {
  const [numberOfAttributes, setNumberOfAttributes] = useState<number>(
    NUMBER_OF_QUESTIONS_INCREMENT,
  )

  return eventQuestions.map(eventQuestion => (
    <OnboardingCard
      key={eventQuestion.id}
      backgroundColor1={theme.palette.warning.dark}
      backgroundColor2={theme.palette.warning.main}
      title={eventQuestion.statement}
    >
      <Grid container>
        <Grid item xs={12}>
          <TableContainer>
            <Table aria-label={`recognition-${eventQuestion.id}`}>
              <TableHead>
                <TableRow>
                  {recognitionColumns.map(column => (
                    <TableCell key={column.id} align={column.align}>
                      <Typography variant='body2' color='text.secondary'>
                        <b>{column.name}</b>
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {answers
                  .filter(answer => answer.question_id === eventQuestion.id)
                  .filter((_, index) => index < numberOfAttributes)
                  .map((answer: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell component='th' scope='row'>
                        <Typography variant='body2' color='text.secondary'>
                          <Grid container component='span' alignItems='center'>
                            <Grid item xs>
                              {answer.event_name}{' '}
                            </Grid>

                            <Grid item xs='auto'>
                              {answer.event_description && (
                                <Tooltip title={answer.event_description}>
                                  <InfoIcon style={{ color: '#AAA' }} />
                                </Tooltip>
                              )}
                            </Grid>
                          </Grid>
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Grid container justifyContent='center'>
                          <ThumbsSelector
                            value={answer.wants_to_be_recognized}
                            onClick={button => {
                              if (
                                answer.wants_to_be_recognized === button.value
                              ) {
                                answer.wants_to_be_recognized = null
                              } else {
                                answer.wants_to_be_recognized = button.value
                              }

                              setAnswers([...answers])
                            }}
                          />
                        </Grid>
                      </TableCell>

                      <TableCell>
                        {answer.wants_to_be_recognized && (
                          <Grid container justifyContent='center'>
                            <ThumbsSelector
                              value={answer.wants_to_be_recognized_in_public}
                              onClick={button => {
                                if (
                                  answer.wants_to_be_recognized_in_public ===
                                  button.value
                                ) {
                                  answer.wants_to_be_recognized_in_public = null
                                } else {
                                  answer.wants_to_be_recognized_in_public =
                                    button.value
                                }

                                answer.wants_to_be_recognized_in_what_setting =
                                  answer.wants_to_be_recognized_in_public
                                    ? 'staff_meeting'
                                    : 'personal_note'

                                setAnswers([...answers])
                              }}
                            />
                          </Grid>
                        )}
                      </TableCell>

                      <TableCell>
                        {answer.wants_to_be_recognized && (
                          <TextField
                            select
                            id='wants_to_be_recognized_by_who'
                            name='wants_to_be_recognized_by_who'
                            variant='outlined'
                            value={answer.wants_to_be_recognized_by_who}
                            required
                            onChange={event => {
                              answer.wants_to_be_recognized_by_who =
                                event.target.value

                              setAnswers([...answers])
                            }}
                            fullWidth
                            size='small'
                            disabled={!answer.wants_to_be_recognized}
                          >
                            {recognitionGroups.map(recognitionGroup => (
                              <MenuItem
                                key={recognitionGroup.value}
                                value={recognitionGroup.value}
                              >
                                {recognitionGroup.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      </TableCell>

                      <TableCell>
                        {answer.wants_to_be_recognized && (
                          <TextField
                            select
                            id='wants_to_be_recognized_in_what_setting'
                            name='wants_to_be_recognized_in_what_setting'
                            variant='outlined'
                            value={
                              answer.wants_to_be_recognized_in_what_setting
                            }
                            required
                            onChange={event => {
                              answer.wants_to_be_recognized_in_what_setting =
                                event.target.value

                              setAnswers([...answers])
                            }}
                            fullWidth
                            size='small'
                            disabled={!answer.wants_to_be_recognized}
                          >
                            {recognitionGroupsSettings
                              .filter(
                                setting =>
                                  setting.public_recognition ===
                                  answer.wants_to_be_recognized_in_public,
                              )
                              .map(recognitionGroupSettings => (
                                <MenuItem
                                  key={recognitionGroupSettings.value}
                                  value={recognitionGroupSettings.value}
                                >
                                  {recognitionGroupSettings.label}
                                </MenuItem>
                              ))}
                          </TextField>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12}>
          <LoadMoreButton
            limit={numberOfAttributes}
            setLimit={setNumberOfAttributes}
            list={answers.filter(
              answer => answer.question_id === eventQuestion.id,
            )}
            listLabel='events'
            numberOfQuestionsIncrement={NUMBER_OF_QUESTIONS_INCREMENT}
          />
        </Grid>
      </Grid>
    </OnboardingCard>
  ))
}
