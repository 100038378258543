export default {
  beheardReport: {
    reportBeingGenerated: 'Seu Relatório #BeHeard está sendo gerado...',
    title: {
      report: 'Relatório #BeHeard {type}',
      premium: 'Premium',
      free: 'Gratuito',
    },
    shortTermQuestion:
      'As chances de deixar meu trabalho voluntariamente durante o próximo ano são Extremamente Baixas',
    longTermQuestion:
      'Eu sinto que terei uma longa carreira nesta organização.',
    sectionTitles: {
      overview: 'VISÃO GERAL',
      characteristics: 'CARACTERÍSTICAS',
      participation: 'PARTICIPAÇÃO',
      results: 'RESULTADOS',
      topFiveRecommendations: '5 PRINCIPAIS RECOMENDAÇÕES',
      employeeTurnoverForecast: 'TURNOVER DE FUNCIONÁRIOS',
      comparisonGraphs: 'GŔAFICOS DE COMPARAÇÃO',
      fullReport: 'RELATÓRIO COMPLETO',
      customQuestions: 'QUESTÕES CUSTOMIZADAS',
    },
    navigationTitles: {
      overview: 'Visão Geral',
      characteristics: 'Características',
      results: 'Resultados',
      topFiveRecommendations: 'Recomendações',
      employeeTurnoverForecast: 'Turnover',
      comparisonGraphs: 'Gráficos de Comparação',
      fullReport: 'Relatório Completo',
      customQuestions: 'Questões Customizadas',
    },
    overview: {
      overviewDescription:
        'Seu Relatório #BeHeard Premium fornece muito mais do que o grau de satisfação de um funcionário. Ele fornece informações chave sobre o quanto os funcionários são apaixonados por seu trabalho, estão comprometidos com sua organização, colocam esforço discricionário em seu trabalho e estão dispostos a realizar recomendações. Isso porque o envolvimento de um colaborador está diretamente relacionado à produtividade, rendimento, assiduidade, turnover, satisfação do cliente e outras percepções organizacionais. Além do grau de envolvimento geral dos colaboradores, este relatório fornece também resultados de cinco indicadores de engajamento, projeções de resultados de curto e longo prazo e recomendações customizadas para a tomada de ações para aumentar o envolvimento.',
    },
    characteristics: {
      description:
        'Antes de se aprofundar nos resultados, vamos conhecer as características chave de funcionários engajados e desengajados. É por isso que agir para estimular o comprometimento dos funcionários é importante!',
      engagedEmployees: { title: 'Funcionários Engajados' },
      disengagedEmployees: { title: 'Funcionários Desengajados' },
    },
    participation: {
      overallRate: 'Nível Geral',
      description:
        'Clique em cada indicador demográfico abaixo para ver as taxas de participação específicas.',
      noParticipation:
        'Ainda não houve nenhuma participação. Verifique mais tarde.',
      demographicsWithLessThanFiveParticipants:
        'Grupos Demográficos com menos de 5 participantes para um valor específico mostrarão N/A (ex.: 4 participantes no Departamento de TI mostrará N/A',
    },
    results: {
      description:
        'Esta secão fornece uma relação de seus resultados de engajamento incluindo as 5 Principais Áreas de Ação, os 5 Principais Pontos Fortes e os 5 Itens Mais Importantes para seus funcionários.',
      engagementScore: { engagement: 'Nível de', score: 'Engajamento' },
      engagementDriversHeader: 'Placar dos Indicadores de Engajamento',
      effectiveness: 'EFICIÊNCIA',
    },
    progressBarLegend: {
      legendTitle: 'Effectiveness Score Legend',
      colorMeaning: {
        negative: 'Unfavorable',
        neutral: 'Neutral',
        positive: 'Favorable',
      },
    },
    driverScore: {
      alignment:
        'Até que ponto os funcionários acreditam e se esforçam para cumprir a missão, os valores e as metas desta organização.',
      engagement:
        'Até que ponto os funcionários são apaixonados por seu trabalho, estão comprometidos com nossa organização, colocam esforço discricionário em seu trabalho e estão dispostos a realizar recomendações.',
      fit: 'Até que ponto os funcionários sentem que estão nas funções certas e têm as habilidades, conhecimentos e habilidades necessários para desempenhar adequadamente suas funções.',
      growth:
        'Até que ponto os funcionários se sentem apoiados para avançar em suas carreiras ou desenvolver conjuntos de habilidades pessoais dentro desta organização.',
      team: 'Até que ponto esta organização promove um estilo de trabalho baseado em equipe (ou seja, departamentos ou grupos trabalham bem juntos).',
      valuing:
        'Até que ponto os funcionários se sentem valorizados por esta organização.',
      custom_ratings_questions: 'Questões de Avaliação Customizadas',
    },
    topFiveText: {
      action: {
        title: '5 Principais Áreas de Ação',
        description:
          'Seus funcionários classificaram as afirmações a seguir como de baixa eficiência e alta em importância (ou seja, estes itens têm a maior lacuna de ROI). Você obterá o maior retorno sobre o investimento se concentrar seus esforços de engajamento nessas cinco áreas (listadas em ordem de prioridade).',
      },
      strength: {
        title: '5 Principais Pontos Fortes',
        description:
          'Seus funcionários classificaram as afirmações a seguir como altas em eficiência e altas em importância (ou seja, estes itens têm a menor lacuna de ROI). Use esses dados para promover a marca de seu funcionário em estratégias de atração, engajamento e retenção de talentos. Estes são seus maiores pontos fortes!',
      },
      importance: {
        title: '5 Itens Mais Importantes',
        description:
          'Seus funcionários classificaram as cinco afirmações a seguir como as mais importantes para eles.',
      },
      topFiveResultDetails: {
        effectiveness: 'Eficiência',
        importance: 'Importância',
        previousEffectiveness: 'Eficiência Anterior',
        benchmarkEffectiveness: 'Eficiência de Benchmark',
      },
      topFiveRecommendations: {
        description:
          'Estas recomendações são baseadas nas 5 Principais Áreas de ação. É aqui que seus esforços para potencializar o engajamento te darão o maior retorno de investimento. Hora de agir!',
        noRecommendations: 'Não há recomendações para esse resultado.',
      },
      tooltip: { roiGap: 'Diferença entre eficiência e importância' },
    },
    filterButtons: { filters: 'Filters', download: 'Download' },
    downloadMenu: {
      pdfNewTab: 'Abrir em nova aba (PDF)',
      pdfDownload: 'PDF',
      xlsxDownload: 'Planilha (.xlsx)',
    },
    noData: 'Não há {title} para os resultados mostrados.',
    turnoverOverviewDescription:
      'A probabilidade de seus funcionários saírem da sua empresa nos próximos doze meses.',
    turnoverForecast: {
      statement: 'AFIRMAÇÃO',
      atRisk: 'Em Risco',
      passive: 'Passivos',
      committed: 'Comprometidos',
      employee: 'funcionário',
      employees: 'funcionários',
      potentialTurnover: 'Turnover Potencial',
      previousTurnover: 'Turnover Anterior',
      description:
        'Sua estimativa de turnover a curto e longo prazo é fornecida usando as respostas dos seus funcionários às afirmações relativas a esse aspecto na pesquisa.',
      shortTerm: 'Estimativa a Curto Prazo',
      longTerm: 'Estimativa a Longo Prazo',
    },
    comparisonGraphs: {
      description:
        'Agora é hora de olhar detalhadamente os resultados de engajamento em toda a organização. Basta posicionar o cursor sobre os gráficos para ver os resultados específicos. Dependendo de suas permissões, você pode usar filtros para mudar as comparações.',
      demographic: 'Indicador Demográfico',
      legend: {
        engaged: 'Engajado',
        neutrals: 'Neutro',
        disengaged: 'Desmotivado',
      },
    },
    fullReport: {
      engagementScore: 'Nível de Engajamento',
      participationRate: 'Nível de Participação',
      legend: {
        strengths: 'Pontos Fortes',
        recommendedActionAreas: 'Áreas de Ação Recomendadas',
      },
      tableHeaders: {
        score: 'Pontuação',
        result: 'Resultado',
        effectiveness: 'Eficiência',
        importance: 'Importância',
        previousEffectiveness: 'Eficiência Anterior',
        benchmark: 'Benchmark',
      },
      tableData: { viewRecommendations: 'Ver recomendações' },
      openEndedQuestions: 'Questões Abertas',
      noCustomRatingQuestions:
        'Não foram usadas questões de avaliação customizadas nesta pesquisa.',
      noCustomOpenEndedQuestions:
        'Não foram usadas questões abertas customizadas nesta pesquisa.',
      buttonLabels: {
        xlsx: 'Baixar respostas no formato XLSX',
        pdf: 'Baixar respostas no formato PDF',
      },
    },
    unlockPremiumReport: 'CLIQUE AQUI PARA LIBERAR O RELATÓRIO PREMIUM',
    snackbar: {
      success: {
        surveyAddedToCart: 'Pesquisa adicionada ao seu carrinho com sucesso',
        filters: 'Seus filtros foram aplicados!',
      },
    },
  },
  report: {
    upgradeReport: 'Fazer Upgrade no Relatório',
    freeReport: 'Relatório Gratuito',
    premiumReport: 'Relatório Premium',
    shareReport: 'Compartilhar Relatório',
  },
  survey: {
    status: {
      scheduled: 'Agendada',
      open: 'Aberta',
      closed: 'Fechada',
      awaitingManualOpening: 'Aguardando Lançamento Manual',
    },
    snackbar: {
      success: {
        alreadyFinished: 'Você já concluiu esta pesquisa',
        finishedSurvey: 'Você finalizou a pesquisa! Obrigado por tomar o tempo',
        giftCardRedeemed:
          'Gift Card resgatado com sucesso! Fique atento ao recebimento de um email com o link do seu gift card eletrônico.',
      },
    },
  },
  surveyKey: {
    status: {
      surveykey: 'Survey Key',
      paragraph1:
        'A pesquisa usa Rostos e Estrelas para classificação.  Avalie sua experiência no local de trabalho e quão importante ela é para cada declaração. A Satisfação é baseada em Rostos e a Importância é baseada em Estrelas de 1 a 5. ',
      paragraph2: 'Avalie sua experiência sobre o uso do nosso produto.',
      title1: 'Satisfaction',
      title2: 'Importance',
    },
  },
  surveyinstruction: {
    surveyinstructionkey: 'Instruções do inquérito',
    surveytitle: ' Este inquérito tem uma',
    twopart: 'processo de classificação em duas partes',
    using: 'para cada instrução utilizando',
    faces: 'Rostos',
    and: 'e ainda',
    star: 'Estrelas.',
    rate: 'Avalie quanto você',
    agree: 'concordo ou discordo',
    statement: 'com cada declaração utilizando a seringa',
    then: 'E depois',
    how: 'como',
    important: 'importante',
    eachstatement:
      'Cada declaração é dirigida a si no local de trabalho utilizando um',
    starrating: 'Classificação de 5 estrelas',
    scale: 'Escala.',
  },
  surveyDialogs: {
    title: 'Pesquisas',
    addSurvey: {
      descriptionFirstLine:
        'É hora de tornar a pesquisa sua. Primeiro, selecione a data de lançamento e encerramento abaixo para começar.  Você pode fazer mudanças antes do lançamento da pesquisa e estender sua data antes de ser encerrada. Clique',
      link: 'AQUI',
      descriptionSecondLine:
        'para melhores práticas de lançamento e gerenciamento da pesquisa.',
    },
    editSurvey: {
      description:
        'Precisa editar sua pesquisa? Sem problema. Você pode fazer isso aqui. Por favor, faça suas mudanças abaixo.',
    },
    extendSurvey: {
      title: 'Estender Pesquisa',
      closeDate: 'Data de Encerramento',
    },
    confirmClose: {
      title: 'Encerrar Pesquisa',
      actionLabel: 'Encerrar Pesquisa',
      close: 'encerrar',
    },
    confirmDelete: {
      title: 'Remover Pesquisa',
      actionLabel: 'Excluir Pesquisa',
    },
    formLabels: {
      surveyName: 'Nome da Pesquisa',
      launchDate: 'Data de Lançamento',
      closeDate: 'Data de Encerramento',
      minDateMessage: 'Data não pode ser anterior à Data de Lançamento',
      allowReward: 'Recompensar participação na pesquisa com um Gift Card',
      allowRewardTooltip:
        'Os Funcionários poderão selecionar seu gift card diretamente do nosso sistema depois da conclusão da pesquisa',
      allowAnswersToBeUpdated:
        'Permitir que funcionários possam atualizar suas respostas',
      allowAnswersToBeUpdatedTooltip:
        'As respostas só podem ser atualizadas enquanto a pesquisa estiver aberta',
      rewardAmount: 'Valor do Gift Card',
    },
    customizedQuestions: {
      header: 'Adicionar Questões/Afirmações Customizadas',
      description:
        'Além das 38 questões na nossa pesquisa de engajamento #BeHeard estatisticamente válida, você pode acrescentar até 5 questões de avaliação por 5 estrelas e cinco questões abertas. Se você quer algumas ideias para suas questões customizadas, verifique alguns exemplos',
      link: 'AQUI',
      statement: 'Afirmação',
      statementPlaceholder: 'Digite sua questão/afirmação...',
      fiveStarQuestions: { header: 'Questões de Avaliação por 5 Estrelas' },
      openEndedQuestions: { header: 'Questões Abertas' },
    },
  },
  surveyLanding: {
    welcomeToSparck: 'Bem-vindo(a) ao Sparck!',
    areYouReady: 'Pronto(a) para ser ouvido(a)?',
    thankYou: 'Obrigado por se permitir ser ouvido(a).',
    noActiveSurveys:
      'No momento não há nenhuma pesquisa ativa, mas muito obrigado por verificar.',
    whatBeHeardSurveyIs:
      'A Pesquisa #BeHeard Survey é uma pesquisa anônima com o propósito de ser um lugar seguro para você compartilhar seu feedback sobre o ambiente de trabalho. Sua organização se importa com o que você pensa e quer oferecer a oportunidade de se expressar de forma livre e confidencial, e por isso fez parceria com o Sparck! No final, serão compartilhados somente os resultados agregados para ajudar a delinear as estratégias organizacionais baseadas nas áreas que mais importam para você e seus colegas.',
    youAreAwesome:
      'Você é INCRÍVEL! Obrigado por tirar um tempo para ser ouvido(a). Sua opinião não tem preço.',
    feedbackImportance:
      'Sua opinião é muito importante para nós, por isso te enviaremos um email assim que uma nova pesquisa estiver disponível!',
    eachItemExplanation:
      'A pesquisa leva cerca de 10 minutos para ser completada. Cada item terá duas partes: primeiro, você irá avaliar até que ponto você concorda ou discorda de cada afirmação sobre o ambiente de trabalho escolhendo um dos rostos. Depois, você avaliará o quão importante cada afirmação é para você através das 5 estrelas. Observe o exemplo!',
    iAmReady: 'Estou pronto para começar!',
    updateMyAnswers: 'Revisar minhas perguntas!',
    surveyPagination: { previous: 'Anterior', nextPage: 'Página seguinte ' },
    submitSurvey: 'Enviar Pesquisa',
    pendingGiftCards: 'Você tem {count} Gift Cards pendentes! 🎉',
    pendingGiftCardLabel: '{rewardValue} para {surveyName}',
    redeem: 'Resgatar',
    giftCardRedeemed: 'Gift Card resgatado com sucesso!',
    departmentTerm: 'Departamento',
    departmentValue: ' ',
    missionAndValueTerm: 'Missão e Valores',
    missionAndValueText:
      'Estamos a construir algo especial, focados em desbloquear os melhores resultados para os nossos clientes por meio de integração e automação inteligentes',
    missionAndValueValue: 'Valores: Seja ousado. Seja você. Seja Boomi.',
    managerTerm: 'Diretor',
    managerValue:
      'O seu superior hierárquico direto à data de 8 de junho de 2023',
    seniorLeadershipTeamTerm: 'Equipa de liderança superior',
    seniorLeadershipTeamValue: 'Equipa de liderança executiva',
    surveyInstructionsTitle: 'Survey Instructions',
    surveyInstructionsContent:
      'This survey has a two-part rating process for each statement using Faces  and Stars.  Rate how much you agree or disagree with each statement using the faces and then how important each statement is to you in the workplace using a 5-star rating scale.',
    boomiSurveyLandingContent:
      'O Inquérito #BeHeard é um inquérito anónimo que visa ser um lugar seguro para partilhar os seus comentários sobre a sua experiência na Boomi. A Boomi preocupa-se com o que pensa e quer dar-lhe uma voz confidencial para falar livremente, razão pela qual estabeleceu uma parceria com a Sparck! Só serão partilhados no final resultados agregados para ajudar a definir as estratégias organizacionais futuras com base nas áreas de maior significado para si e para os seus colegas na Boomi. Antes de começar, eis algumas definições para ajudar ao alinhamento de todos:',
    boomiSurveyLandingContentTiming:
      'O inquérito deve demorar apenas cerca de 15 minutos a concluir. Cada item terá duas partes: primeiro, avaliará a medida em que concorda ou discorda em relação a cada afirmação sobre o local de trabalho escolhendo uma das faces. Uma vez terminada essa parte, avaliará o grau de importância de cada afirmação para si usando as estrelas. Veja o exemplo animado!',
    topicCards: {
      secure: {
        title: 'Seus Dados estão {keyword}',
        keyword: 'Seguros',
        description:
          'Tratamos a proteção de dados com muita seriedade. O Sparck usa uma série de tecnologias e protocolos que previnem vazamento de dados e ataques cibernéticos.',
      },
      anonymous: {
        title: 'Sua Participação é {keyword}',
        keyword: 'Anônima',
        description:
          'Seu gerente nunca saberá como você respondeu uma questão. Todas as respostas são anonimizadas e agregadas para fornecer à organização uma visão geral de como seus departamentos estão indo.',
      },
      giftCard: {
        title: 'Esta Pesquisa Tem um {keyword}!',
        keyword: 'Gift Card',
        description:
          'Isso mesmo! Como agradecimento por completar a pesquisa, você poderá escolher um gift card ao fim dessa pesquisa.',
      },
      answersUpdate: {
        title: '{keyword} Suas Respostas',
        keyword: 'Atualize',
        description:
          'Boas notícias! Você pode voltar e editar suas respostas a qualquer momento enquanto a pesquisa estiver aberta. Basta se autenticar novamente!',
      },
    },
  },
  surveyOverview: {
    snackbarSuccess: {
      openSurvey: 'A pesquisa foi aberta!',
      extendedSurvey:
        'A data de encerramento da pesquisa foi estendida com sucesso!',
      closedSurvey: 'A pesquisa foi encerrada',
      deletedSurvey: 'A pesquisa foi excluída!',
      employeesInvited: 'Funcionários convidados com sucesso!',
      addedToCart: 'Pesquisa adicionada ao seu carrinho com sucesso',
    },
    selectLabel: 'Pesquisa',
    minimumNumberOfEmployees:
      'Para criar uma nova pesquisa sua organização deve possuir pelo menos 5 funcionários.',
    whenSurveyCloses:
      'Quando sua pesquisa for encerrada os resultados #BeHeard serão disponibilizados imediatamente.',
    scheduledLaunchSurveyInfo:
      'Sua pesquisa ainda não foi disponibilizada. Será lançada automaticamente em {launchDate}. Se desejar fazer o lançamento manual, você pode clicar no botão Lançar Pesquisa ou editá-la para ser lançada manualmente.',
    manualLaunchSurveyInfo:
      'Sua pesquisa ainda não foi disponibilizada e está configurada para Lançar Manualmente. Quando você estiver pronto para lançá-la, clique no botão Lançar Pesquisa.',
    newSurvey: 'Nova Pesquisa',
    launchSurvey: 'Lançar Pesquisa',
    editSurvey: 'Editar Pesquisa',
    extendSurvey: 'Estender Pesquisa',
    closeSurvey: 'Encerrar Pesquisa',
    applyUpgrade: 'Aplicar Upgrade',
    printSurvey: 'Print',
    applyDowngrade: 'Aplicar Downgrade',
    inviteEmployees: 'Convidar Funcionários',
    deleteSurvey: 'Excluir Pesquisa',
    anticipatedTurnover: 'Turnover Antecipado em Mais de 12 Meses',
    person: 'Pessoa',
    people: 'Pessoas',
    employeesInvitation: {
      inviteEmployees: 'Convidar Funcionários',
      chooseEmployees:
        'Escolha abaixo os funcionários que você quer convidar para',
      textFieldLabel: 'Escolha um ou mais funcionários',
      cancelButton: 'Cancelar',
      inviteButton: 'Convidar Funcionários',
    },
    tabs: {
      labels: { general: 'Geral', sharedReports: 'Relatórios Compartilhados' },
    },
    validations: {
      surveyNameIsRequired: 'Nome da Pesquisa é obrigatório',
      minimumLaunchDate: 'Você não pode selecionar uma data passada',
      maximumLaunchDate:
        'Data de Lançamento não pode ser depois da data de Encerramento',
      launchDateIsRequired: 'Data de Lançamento é obrigatória',
      minimumCloseDate:
        'Data de Encerramento não pode ser anterior à data de Lançamento',
      closeDateIsRequired: 'Data de Encerramento é obrigatória',
      rewardAmountIsRequired: 'Valor do Gift Card é obrigatório',
    },
  },
  surveyProgress: {
    bulletLabels: {
      createdOn: 'Criada em',
      openOn: 'Aberta em',
      closedOn: 'Encerrada em',
      willCloseOn: 'Encerrará em',
    },
  },
  ratingSection: {
    toWhatExtent: 'Até que ponto você concorda ou discorda dessa afirmação?',
    important: 'Isto é importante para mim.',
    ratingLabels: {
      stronglyDisagree: 'Discordo Totalmente',
      disagree: 'Discordo',
      neutral: 'Neutro',
      agree: 'Concordo',
      stronglyAgree: 'Concordo Totalmente',
      notApplicable: 'Não se Aplica',
    },
    change: 'Mudar',
    unansweredItem: 'Por favor, responda todos os itens antes de prosseguir.',
    formLabels: {
      textField: 'Sua resposta',
      placeholder: 'Digite o que quiser aqui...',
      helperText:
        'Para garantir sua confidencialidade, não coloque nenhuma informação de identificação pessoal.',
    },
  },
  manageShareReport: {
    reportSections: {
      labels: {
        comparisonGraph: 'Gráfico de Comparações',
        results: 'Resultados',
        topFiveActionAreas: '5 Principais Áreas de Ação',
        topFiveStrengths: '5 Principais Pontos Fortes',
        topFiveMostImportantItems: '5 Itens Mais Importantes',
        recommendations: 'Recomendações',
        turnoverForecast: 'Turnover de Funcionários',
        fullReport: 'Relatório Completo',
        customRatingQuestions: 'Questões de Avaliação Customizadas',
        customOpenEndedQuestions: 'Questões Abertas Customizadas',
      },
    },
    previewFor: 'Preview para {name}',
    reportWillBeSharedWithRoles: 'Este relatório será compartilhado com',
    formHandler: {
      title: 'Compartilhar Relatório',
      labels: {
        name: 'Nome do Relatório Compartilhado',
        expirationDate: 'Até quando ficará disponível?',
        whatToSee: 'O que eles podem ver?',
        limitReport: 'Limitar Relatório por Dados Demográficos',
        whichRoles:
          'Quais Responsabilidades tem permissão para ver este Relatório Compartilhado?',
        roles: 'Responsabilidades',
        whichDemographics:
          'Quais Grupos Demográficos receberão este Relatório Compartilhado?',
      },
      placeholders: {
        beheardReport: 'Compartilhamento do Relatório #BeHeard 001',
      },
      dialog: {
        title: 'Aviso!',
        content: {
          firstLine:
            'Tem certeza que deseja conceder acesso às respostas de seus funcionários?',
          secondLine:
            'As respostas contêm informações muito sensíveis. Por favor, tenha certeza absoluta de haver selecionado os indicadores demográficos corretos para esta ação.',
        },
        buttonText: 'Entendi!',
      },
    },
    snackbars: { invitesSent: 'Convites enviados com sucesso' },
  },
  inviteShareReport: {
    dialogTitle: 'Convidar para o Relatório Compartilhado',
    dialogActionLabel: 'Compartilhar este relatório',
    clickToInvite:
      'Seu Relatório Compartilhado foi criado, mas você precisa enviar convites. Clique em "Compartilhar este relatório" para enviar emails para seus convidados. ',
  },
  shareReport: {
    title: 'Compartilhar Relatórios',
    description:
      'Você controla quem tem acesso a seus Relatórios #BeHeard Premium',
    columnHeaders: {
      sharedReport: 'Nome do Relatório Compartilhado',
      surveyName: 'Nome da Pesquisa',
      expires: 'Expira em',
    },
    validations: {
      minimumExpirationDate: 'Você não pode selecionar uma data passada',
      expirationDateIsRequired: 'Data de Expiração é obrigatória',
    },
  },
}
