import { useState, useEffect } from 'react'

import { isEmpty } from 'lodash'
import { useQuery, useMutation } from 'react-query'

import { RewardsCatalogComponent } from './components'
import { useUser } from 'contexts'
import { API } from 'core/requests'
import { ActiveInactiveStatus } from 'enums/app'
import { CatalogType } from 'enums/rewardsCatalog'

export default function RewardsCatalog() {
  const [selectedTab, setSelectedTab] = useState(CatalogType.Rewards)

  const { user } = useUser()!

  const { data: wishlist, refetch: fetchWishlist } = useQuery(
    'getWishlist',
    () =>
      API.get('wishlists', user.id, {
        queryParams: {
          user_id: user.id,
        },
      }),
    {
      enabled: false,
    },
  )

  const {
    data: rewards,
    refetch: fetchRewards,
    isLoading: rewardsIsLoading,
  } = useQuery(
    'getRewardsCatalog',
    () =>
      API.get('rewards', null, {
        queryParams: {
          status: ActiveInactiveStatus.Active,
        },
      }),
    {
      enabled: false,
    },
  )

  const {
    data: rewardsNGC,
    refetch: fetchRewardsNGC,
    isLoading: rewardsNGCIsLoading,
  } = useQuery('getRewardsNGCCatalog', () => API.get('rewardsNGC'), {
    enabled: false,
  })

  const addToWishlist = useMutation(
    (payload: any) => API.create('wishes', payload),
    {
      onSuccess: () => {
        selectedTab === CatalogType.Rewards ? fetchRewards() : fetchRewardsNGC()
      },
    },
  )

  const removeFromWishlist = useMutation(
    (payload: any) => API.delete('wishes', payload),
    {
      onSettled: () => {
        selectedTab === CatalogType.Rewards ? fetchRewards() : fetchRewardsNGC()
      },
    },
  )

  const updateWishlist = useMutation(
    (payload: any) => API.update('wishlists', payload),
    {
      onSuccess: () => {
        fetchWishlist()
      },
    },
  )

  useEffect(() => {
    if (isEmpty(rewards) && selectedTab === CatalogType.Rewards) {
      fetchRewards()
    }

    if (isEmpty(rewardsNGC) && selectedTab === CatalogType.RewardsNGC) {
      fetchRewardsNGC()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab])

  useEffect(() => {
    if (user) {
      fetchWishlist()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <RewardsCatalogComponent
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      rewards={rewards}
      rewardsIsLoading={rewardsIsLoading}
      rewardsNGC={rewardsNGC}
      rewardsNGCIsLoading={rewardsNGCIsLoading}
      addToWishlist={addToWishlist}
      removeFromWishlist={removeFromWishlist}
      wishlist={wishlist}
      updateWishlist={updateWishlist}
    />
  )
}
