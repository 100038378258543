import { NominationFrequency, NominationOccurrenceType } from 'enums/nomination'

export const nominationFrequencies = [
  {
    value: NominationFrequency.Monthly,
    // label: t('nominations.frequencies.monthly'),
    label: 'Monthly',
  },
  {
    value: NominationFrequency.Quarterly,
    // label: t('nominations.frequencies.quarterly'),
    label: 'Quarterly',
  },
  {
    value: NominationFrequency.SemiAnnually,
    // label: t('nominations.frequencies.semiAnnually'),
    label: 'Semi-Annually',
  },
  {
    value: NominationFrequency.Annually,
    // label: t('nominations.frequencies.annually'),
    label: 'Annually',
  },
]

export const nominationOccurrenceTypes = [
  {
    value: NominationOccurrenceType.AlwaysOpen,
    label: 'Leave Open, Multiple Winners',
  },
  {
    value: NominationOccurrenceType.OneOff,
    label: 'Automatically Close Once Awarded',
  },
  {
    value: NominationOccurrenceType.Periodical,
    label: 'Custom Nominations Timeline',
  },
]
