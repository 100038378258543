import { useState } from 'react'

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import { useMutation } from 'react-query'

import { API } from 'core/requests'

export default function SuggestionResolveDialog({
  dialog,
  setDialog,
  mutate,
  mutateIsLoading,
  actionLabel,
  actionButtonColor,
  setRefetchSuggestion,
  setSuggestionChatDialog,
}) {
  const intl = useIntl()
  const { enqueueSnackbar } = useSnackbar()

  const suggestionNoteMutation = useMutation(
    (payload: any) => API.create('suggestionNotes', payload),
    {
      onSuccess: (response: any) => {
        if (response.id) {
          enqueueSnackbar(intl.formatMessage({ id: 'formHandler.success' }), {
            variant: 'success',
          })

          setRefetchSuggestion(true)
        }
      },
    },
  )

  const [note, setNote] = useState('')

  const handleCloseDialog = () => setDialog({ isOpen: false, data: null })
  const handleResolve = async () => {
    await mutate({ id: dialog.data?.id })

    if (note) {
      await suggestionNoteMutation.mutate({
        suggestion_box_idea_id: dialog?.data?.id,
        note: note,
      })

      setSuggestionChatDialog({ isOpen: true, data: dialog.data })
    } else {
      setRefetchSuggestion(true)
    }
  }

  return (
    <Dialog open={dialog.isOpen} onClose={handleCloseDialog}>
      <DialogTitle>
        {actionLabel} Suggestion #{dialog.data?.id}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DialogContentText>
              Suggestion:{' '}
              <Typography component='span' variant='body2' fontWeight={500}>
                {dialog.data?.description}
              </Typography>
            </DialogContentText>
          </Grid>

          <Grid item xs={12}>
            <TextField
              id={`note-${dialog.data?.id}`}
              value={note}
              onChange={event => setNote(event.target.value)}
              label={`Add a note (for company admins only)`}
              required
              variant='outlined'
              margin='normal'
              size='small'
              fullWidth
              multiline
              minRows={3}
              maxRows={5}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCloseDialog} color='primary'>
          {intl.formatMessage({ id: 'cancel' })}
        </Button>

        <Button
          onClick={handleResolve}
          color={actionButtonColor || 'primary'}
          disabled={
            !note || suggestionNoteMutation.isLoading || mutateIsLoading
          }
          autoFocus
        >
          {suggestionNoteMutation.isLoading || mutateIsLoading ? (
            <CircularProgress size={24} />
          ) : (
            actionLabel
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
