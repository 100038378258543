import { Box, Button, Grid, Typography } from '@mui/material'
import { t } from 'i18n'

import ManagerCertificationSection from '../ManagerCertificationSection/ManagerCertificationSection'

export default function ManagerCertification5DayRoadMap() {
  return (
    <ManagerCertificationSection>
      <Grid
        container
        spacing={4}
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item md={6}>
          <Typography
            color='text.primary'
            variant='h5'
            fontWeight={600}
            textAlign='left'
            paragraph
          >
            {t('managerCertification.managerCertification5DayRoadmap.title')}
          </Typography>
          <Typography
            color='text.secondary'
            variant='body1'
            paragraph
            align='justify'
          >
            {t(
              'managerCertification.managerCertification5DayRoadmap.description',
            )}
          </Typography>

          <Button
            variant='contained'
            color='secondary'
            component='a'
            href='https://f.hubspotusercontent10.net/hubfs/5358414/5-Day%20Roadmap%20(1).pdf?hsCtaTracking=fe9ec488-3944-49eb-9f67-6b872b2990c7%7Ce81bb34c-4d30-4ae6-acf8-f4b3bd4a1ed7'
          >
            {t(
              'managerCertification.managerCertification5DayRoadmap.downloadRoadmap',
            )}
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            component='img'
            src='https://resources.sparckco.com/hs-fs/hubfs/screenshot%205%20day%20roadmap.jpg?width=806&amp;height=602&amp;name=screenshot%205%20day%20roadmap.jpg'
            width={1}
            height={1}
          />
        </Grid>
      </Grid>
    </ManagerCertificationSection>
  )
}
