import { Box } from '@mui/material'
import { ReactComponent as HeaderBackground } from 'assets/svg/surveyHeader.svg'

export default function SurveyHeaderBackground() {
  return (
    <>
      <Box display={{ xs: 'none', md: 'block' }}>
        <HeaderBackground
          style={{
            width: '100%',
            height: 350,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1,
          }}
        />
      </Box>
    </>
  )
}
