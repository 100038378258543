import {
  BugReportOutlined as BugFixIcon,
  CakeOutlined as BirthdayIcon,
  EmojiObjectsOutlined as InnovationIcon,
  EventOutlined as AnniversaryIcon,
  SportsTennisOutlined as FunIcon,
  GroupOutlined as TeamworkIcon,
  MenuBookOutlined as MentorIcon,
  ToysOutlined as CreativeIcon,
  TrackChangesOutlined as StrategyIcon,
  VerifiedUserOutlined as TrainingIcon,
  PregnantWoman as PregnancyIcon,
  ChildFriendly as NewFamilyIcon,
  House as NewHomeIcon,
  Explore as RetirementIcon,
  School as GraduationIcon,
} from '@mui/icons-material'
import {
  FaThermometerFull as ExceededGoalsIcon,
  FaConnectdevelop as TechnicalDevelopmentIcon,
} from 'react-icons/fa'
import { GiPartyPopper as CelebrationIcon } from 'react-icons/gi'

import {
  RecognitionFrequency,
  RecognitionGroup,
  RecognitionGroupSetting,
  RecognitionMilestone,
  RecognitionOffer,
  RecognitionType as RecognitionTypeEnum,
} from 'enums/recognition'
import { t } from 'i18n'

export const recognitionTypes = [
  {
    value: RecognitionTypeEnum.Custom,
    label: t('recognitions.types.customRecognitionProgram'),
  },
  {
    value: RecognitionTypeEnum.Birthday,
    label: t('birthday'),
  },
  {
    value: RecognitionTypeEnum.Workiversary,
    label: t('recognitions.types.workiversary'),
  },
  {
    value: RecognitionTypeEnum.LifeEvent,
    label: t('recognitions.types.lifeEvent'),
  },
  {
    value: RecognitionTypeEnum.CoreValue,
    label: t('recognitions.types.coreValues'),
  },
  {
    value: RecognitionTypeEnum.PeerToPeer,
    label: t('recognitions.types.peerToPeer'),
  },
  {
    value: RecognitionTypeEnum.SpotBonus,
    label: t('recognitions.types.spotBonus'),
  },
  {
    value: RecognitionTypeEnum.PeerNominated,
    label: t('recognitions.types.peerNominatedAward'),
  },
  {
    value: RecognitionTypeEnum.ManagerNominated,
    label: t('recognitions.types.managerNominatedAward'),
  },
  {
    value: RecognitionTypeEnum.EmployeeOfTheMonth,
    label: t('recognitions.types.employeeOfTheMonth'),
  },
  {
    value: RecognitionTypeEnum.EmployeeOfTheYear,
    label: t('recognitions.types.employeeOfTheYear'),
  },
]

export const recognitionOffers = [
  {
    value: RecognitionOffer.Reward,
    label: t('recognition.offers.reward'),
  },
  {
    value: RecognitionOffer.EmployeeChoice,
    label: t('recognition.offers.employeeSChoice'),
  },
]

export const recognitionFrequencies = [
  {
    value: RecognitionFrequency.Monthly,
    label: t('recognitions.frequencies.monthly'),
  },
  {
    value: RecognitionFrequency.Quarterly,
    label: t('recognitions.frequencies.quarterly'),
  },
  {
    value: RecognitionFrequency.SemiAnnually,
    label: t('recognitions.frequencies.semiAnnually'),
  },
  {
    value: RecognitionFrequency.Annually,
    label: t('recognitions.frequencies.annually'),
  },
  {
    value: RecognitionFrequency.Lifetime,
    label: t('recognitions.frequencies.lifetime'),
  },
]

export const recognitionMilestones = [
  {
    value: RecognitionMilestone.EveryYearMilestone,
    label: t('recognitions.milestones.everyYear'),
  },
  {
    value: RecognitionMilestone.FixedMilestone,
    label: t('recognitions.milestones.fixed'),
  },
  {
    value: RecognitionMilestone.CustomMilestone,
    label: t('recognitions.milestones.customMilestone'),
  },
]

export const recognitionGroups = [
  {
    value: RecognitionGroup.Peers,
    label: t('recognitions.groups.peers'),
  },
  {
    value: RecognitionGroup.Manager,
    label: t('recognitions.groups.manager'),
  },
  {
    value: RecognitionGroup.ExecutiveTeam,
    label: t('recognitions.groups.executiveTeam'),
  },
  {
    value: RecognitionGroup.CEO,
    label: t('recognitions.groups.ceo'),
  },
]

export const recognitionGroupsSettings = [
  {
    value: RecognitionGroupSetting.PersonalNote,
    label: t('recognitions.settings.aPersonalNote'),
    public_recognition: false,
  },
  {
    value: RecognitionGroupSetting.PersonalPhoneCall,
    label: t('recognitions.settings.aPersonalPhoneCall'),
    public_recognition: false,
  },
  {
    value: RecognitionGroupSetting.PersonalText,
    label: t('recognitions.settings.aPersonalText'),
    public_recognition: false,
  },
  {
    value: RecognitionGroupSetting.InPerson,
    label: t('recognitions.settings.inPerson'),
    public_recognition: false,
  },
  {
    value: RecognitionGroupSetting.StaffMeeting,
    label: t('recognitions.settings.staffMeeting'),
    public_recognition: true,
  },
  {
    value: RecognitionGroupSetting.CompanyEvent,
    label: t('recognitions.settings.companyEvent'),
    public_recognition: true,
  },
  {
    value: RecognitionGroupSetting.GroupEmail,
    label: t('recognitions.settings.groupEmail'),
    public_recognition: true,
  },
  {
    value: RecognitionGroupSetting.CompanyDashboard,
    label: t('recognitions.settings.companyDashboard'),
    public_recognition: true,
  },
  {
    value: RecognitionGroupSetting.SocialMediaShoutout,
    label: t('recognitions.settings.socialMediaShoutout'),
    public_recognition: true,
  },
]

export const recognitionReminders = [5, 10, 20, 30, 45, 60]
export const recognitionLimitToAward = [
  'company_wide',
  1,
  2,
  5,
  10,
  15,
  20,
  25,
  30,
  40,
  50,
  75,
  100,
  150,
  200,
  250,
  300,
  350,
  400,
  500,
]

export const recognitionLimitRecognitionsPerEmployee = [
  1, 2, 3, 5, 7, 10, 15, 20, 0,
]

export const recognitionAttributes = [
  {
    type: RecognitionTypeEnum.Workiversary,
    value: 'recognition_milestones_attributes',
    accessor: 'year',
    label: t('recognitions.attributes.workiversaryYear'),
    fieldType: 'text',
  },
  {
    type: RecognitionTypeEnum.LifeEvent,
    value: 'recognition_life_events_attributes',
    accessor: 'life_event',
    label: t('recognitions.attributes.lifeEvent'),
    fieldType: 'text',
  },
  {
    type: RecognitionTypeEnum.CoreValue,
    value: 'recognition_core_values_attributes',
    accessor: 'core_value_id',
    label: t('recognitions.attributes.coreValue'),
    fieldType: 'select',
  },
]

export const recognitionIcons = [
  {
    value: 'birthday',
    label: t('birthday'),
    Icon: BirthdayIcon,
  },
  {
    value: 'anniversary',
    label: t('recognitions.icons.anniversary'),
    Icon: AnniversaryIcon,
  },
  {
    value: 'team_work',
    label: t('recognitions.icons.teamWork'),
    Icon: TeamworkIcon,
  },
  {
    value: 'innovation',
    label: t('recognitions.icons.innovation'),
    Icon: InnovationIcon,
  },

  {
    value: 'strategic',
    label: t('recognitions.icons.strategic'),
    Icon: StrategyIcon,
  },
  {
    value: 'mentor',
    label: t('recognitions.icons.mentorTeach'),
    Icon: MentorIcon,
  },
  {
    value: 'training',
    label: t('recognitions.icons.training'),
    Icon: TrainingIcon,
  },
  {
    value: 'creative',
    label: t('recognitions.icons.creative'),
    Icon: CreativeIcon,
  },
  {
    value: 'new_family_member',
    label: t('recognitions.icons.newFamilyMember'),
    Icon: NewFamilyIcon,
  },
  {
    value: 'new_home',
    label: t('recognitions.icons.newHome'),
    Icon: NewHomeIcon,
  },
  {
    value: 'pregnancy',
    label: t('recognitions.icons.havingABaby'),
    Icon: PregnancyIcon,
  },
  {
    value: 'graduation',
    label: t('recognitions.icons.graduation'),
    Icon: GraduationIcon,
  },
  {
    value: 'retirement',
    label: t('recognitions.icons.retirement'),
    Icon: RetirementIcon,
  },
  {
    value: 'technical_development',
    label: t('recognitions.icons.technicalDevelopment'),
    Icon: TechnicalDevelopmentIcon,
  },
  {
    value: 'exceeded_goals',
    label: t('recognitions.icons.exceededGoals'),
    Icon: ExceededGoalsIcon,
  },
  { value: 'fun', label: t('recognitions.icons.fun'), Icon: FunIcon },
  {
    value: 'celebration',
    label: t('recognitions.icons.celebration'),
    Icon: CelebrationIcon,
  },
  { value: 'bug_fix', label: t('recognitions.icons.bugFix'), Icon: BugFixIcon },
]
