import { SuggestionStatus } from 'enums/suggestion'
import { t } from 'i18n'

export const getSuggestionsStatusDetails = theme => ({
  [SuggestionStatus.Pending]: {
    label: t('statuses.pending'),
    color: theme.palette.secondary.main,
  },
  [SuggestionStatus.Accepted]: {
    label: t('statuses.accepted'),
    color: theme.palette.primary.main,
  },
  [SuggestionStatus.Declined]: {
    label: t('statuses.declined'),
    color: theme.palette.error.light,
  },
  [SuggestionStatus.Reviewed]: {
    label: t('suggestions.settings.reviewed'),
    color: theme.palette.info.main,
  },
  [SuggestionStatus.Implemented]: {
    label: t('suggestions.settings.implemented'),
    color: theme.palette.primary.light,
  },
})
