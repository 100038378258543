import { get, isEmpty } from 'lodash'

import { UserPermission, UserRole } from 'enums/user'

export const getDisplayNameForGroup = (allUsers, usersToShow = 2) => {
  const onlyUser = allUsers[0]
  const users = allUsers.slice(0, usersToShow)
  const usersButLastOne = users
    .slice(0, users.length - 1)
    .map(user => user.full_name)
    .join(', ')
  const lastUserName = users[users.length - 1].full_name
  const totalOtherUsers = allUsers.length - usersToShow
  const othersText = totalOtherUsers === 1 ? 'other' : 'others'

  if (allUsers.length > usersToShow) {
    return `${usersButLastOne}, ${lastUserName} and ${totalOtherUsers} ${othersText}`
  }

  if (allUsers.length > 1) {
    return `${usersButLastOne} and ${lastUserName}`
  }

  return onlyUser.full_name
}

export const getRoles = roles =>
  isEmpty(roles) ? [UserRole.Employee] : roles.map(role => role?.role?.type)

export const getPermissions = permissions =>
  permissions.map(permission => permission?.key)

export const getUserFullName = user =>
  `${get(user, 'first_name', '')} ${get(user, 'last_name', '')}`

export const userHasBeseenPermissions = user =>
  !!get(user, 'company.beseen_application') &&
  !!get(user, 'applications.beseen')

export const userHasBeheardPermissions = user =>
  !!get(user, 'company.beheard_application') &&
  !!get(user, 'applications.beheard')

export const userIsCompanyAdmin = user =>
  user?.roles?.includes(UserRole.CompanyAdmin)

export const displayProfileSection = (
  isProfileFromLoggedUser,
  visibility,
  loggedUser,
  userFromProfile,
) => {
  const loggedUserIsSupervisor = (
    userFromProfile.superior_direct_reports_attributes ?? []
  ).find(supervisor => get(supervisor, 'superior.id') === loggedUser.id)

  const userHasPermission =
    (loggedUser.permissions ?? []).includes(
      UserPermission.ViewEmployeesProfile,
    ) ||
    (loggedUserIsSupervisor &&
      loggedUser.permissions.includes(UserPermission.ViewTeamsProfiles))

  if (!isProfileFromLoggedUser && !userHasPermission) return false

  return (
    isProfileFromLoggedUser ||
    visibility === 'everyone' ||
    (visibility === 'only_managers' && loggedUserIsSupervisor)
  )
}
