import { useTheme } from '@mui/material'
import { Assignment as RolesIcon } from '@mui/icons-material'
import { useIntl } from 'react-intl'

import ManageRole from './manageRole/ManageRole'
import { CompanyAdminLayout } from 'components'

export default function Roles() {
  const theme: any = useTheme()
  const intl = useIntl()

  return (
    <>
      <CompanyAdminLayout
        slug='roles'
        title={intl.formatMessage({ id: 'roles.title' })}
        description={intl.formatMessage({ id: 'roles.description' })}
        color={theme.palette.primary.main}
        requestUrl='roles'
        tableProps={{
          columns: [
            {
              title: intl.formatMessage({ id: 'name' }),
              field: 'name',
              editable: 'never',
            },
            {
              title: intl.formatMessage({ id: 'type' }),
              field: 'type',
              editable: 'never',
            },
          ],
        }}
        ManagementForm={ManageRole}
        managementDialogProps={{
          dialogSize: 'sm',
        }}
        IconHeader={RolesIcon}
      />
    </>
  )
}
