import { useState } from 'react'

import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import { useMutation } from 'react-query'

import { DialogTeleport } from 'components'
import { API } from 'core/requests'
import { t } from 'i18n'

export default function ReportInappropriateContent(props) {
  const { recordId, recordType, dialog, setDialog } = props

  const { enqueueSnackbar } = useSnackbar()
  const intl = useIntl()

  const { mutate: reportContent } = useMutation(
    (payload: any) => API.create('reportedRecords', payload),
    {
      onSuccess: (response: any) => {
        if (response.id) {
          enqueueSnackbar(
            t(
              'components.reportInappropriateContent.snackbar.success.reportSent',
            ),
            { variant: 'success' },
          )
          setDialog(false)
        }
      },
    },
  )

  const [inappropriateContent, setInappropriateContent] = useState({
    record_id: recordId,
    record_type: recordType,
    has_inappropriate_text: true,
    has_inappropriate_image: false,
    has_inappropriate_video: false,
  })

  const checkboxes = [
    {
      id: 'has_inappropriate_text',
      label: intl.formatMessage({
        id: 'components.reportInappropriateContent.contentLabels.text',
      }),
    },
    {
      id: 'has_inappropriate_image',
      label: intl.formatMessage({
        id: 'components.reportInappropriateContent.contentLabels.image',
      }),
    },
    {
      id: 'has_inappropriate_video',
      label: intl.formatMessage({
        id: 'components.reportInappropriateContent.contentLabels.video',
      }),
    },
  ]

  return (
    <DialogTeleport
      dialogTitle={`${intl.formatMessage({
        id: 'components.reportInappropriateContent.header',
      })} ${recordType}`}
      dialogAction={{
        function: () =>
          reportContent({ reported_record: inappropriateContent }),
        label: intl.formatMessage({
          id: 'components.reportInappropriateContent.report',
        }),
      }}
      dialogSize='sm'
      dialogOpen={!!dialog}
      handleDialogClose={() => setDialog(false)}
      isFullWidth
    >
      <Typography variant='body2' color='text.secondary' paragraph>
        {t('components.reportInappropriateContent.question.firstLine')}{' '}
        {recordType}{' '}
        {t('components.reportInappropriateContent.question.secondLine')}
      </Typography>

      <Grid container>
        {checkboxes.map(checkbox => (
          <Grid item xs={12} key={checkbox.id}>
            <FormControlLabel
              control={
                <Checkbox
                  color='error'
                  checked={inappropriateContent[checkbox.id]}
                  onChange={event =>
                    setInappropriateContent({
                      ...inappropriateContent,
                      [checkbox.id]: event.target.checked,
                    })
                  }
                  name={checkbox.id}
                />
              }
              label={checkbox.label}
            />
          </Grid>
        ))}
      </Grid>
    </DialogTeleport>
  )
}
