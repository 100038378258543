import { Grade as RecognitionsIcon } from '@mui/icons-material'
import { Box } from '@mui/material'
import { teal } from '@mui/material/colors'
import { FormattedDate, useIntl } from 'react-intl'

import ManageRecognition from './manageRecognition/ManageRecognition'
import { CompanyAdminLayout } from 'components'
import { UserPermission } from 'enums/user'
import { formatToUSD } from 'utils/currency'
import { getBoolean, getNotApplicable } from 'utils/tables/dataTypes'

export default function Recognitions() {
  const intl = useIntl()

  return (
    <>
      <CompanyAdminLayout
        slug='recognitions'
        title={intl.formatMessage({ id: 'recognitions.title' })}
        description={intl.formatMessage({ id: 'recognitions.description' })}
        color={teal[800]}
        requestUrl='recognitions'
        tableProps={{
          columns: [
            {
              title: intl.formatMessage({ id: 'recognitions.fields.program' }),
              field: 'name',
              editable: 'never',
            },
            {
              title: intl.formatMessage({
                id: 'recognitions.fields.hasReward',
              }),
              field: 'has_reward',
              editable: 'never',
              // align: 'center',
              render: (rowData: any) => getBoolean(rowData.has_reward),
            },
            {
              title: intl.formatMessage({
                id: 'recognitions.fields.rewardAmount',
              }),
              field: 'maximum_reward_amount_for_each_recognition',
              editable: 'never',
              // align: 'center',
              render: (rowData: any) =>
                +rowData.maximum_reward_amount_for_each_recognition ? (
                  <Box
                    component='span'
                    sx={{ color: 'success.main', fontWeight: 600 }}
                  >
                    {formatToUSD(
                      rowData.maximum_reward_amount_for_each_recognition,
                    )}
                  </Box>
                ) : (
                  getNotApplicable()
                ),
            },
            {
              title: intl.formatMessage({
                id: 'recognitions.fields.timeframe',
              }),
              field: 'time_frame_start_at',
              editable: 'never',
              // align: 'center',
              render: (rowData: any) =>
                rowData.time_frame_start_at && rowData.time_frame_end_at ? (
                  <>
                    <FormattedDate
                      value={rowData.time_frame_start_at}
                      year='numeric'
                      month='numeric'
                      day='numeric'
                    />{' '}
                    -{' '}
                    <FormattedDate
                      value={rowData.time_frame_end_at}
                      year='numeric'
                      month='numeric'
                      day='numeric'
                    />
                  </>
                ) : (
                  getNotApplicable()
                ),
            },
          ],
          hasStatusSwitch: true,
        }}
        managementPermission={UserPermission.ManageRecognitionEvents}
        ManagementForm={ManageRecognition}
        IconHeader={RecognitionsIcon}
      />
    </>
  )
}
