import { Fragment, useMemo, useState } from 'react'

import {
  EmojiEvents as AwardIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Box,
  Button,
  Collapse,
  Chip,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import { groupBy } from 'lodash'
import pluralize from 'pluralize'

import { NominationsManagementConfirmNomination } from '../../components'
import { DialogTeleport, SparckTable } from 'components'
import { initialDialog } from 'constants/dialog'
import { getDateLabel } from 'utils/tables/dataTypes'
import { NominationOccurrenceType } from 'enums/nomination'
import { nominationOccurrenceTypes } from 'constants/nomination'

export default function NominationsManagementNominationEvent(props) {
  const { nomination } = props

  return (
    <Box
      key={nomination.id}
      component={Paper}
      elevation={3}
      sx={{
        p: 2.5,
        borderRadius: '0.5em',
        backgroundColor: 'inherit',
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography
            component='h1'
            variant='h6'
            color='text.primary'
            fontWeight={600}
            sx={{ mb: 1 }}
          >
            {nomination?.name}
          </Typography>
        </Grid>

        {(nomination?.frequency || nomination?.occurrence_type) && (
          <Grid item xs={12}>
            <Typography variant='body1' color='text.primary' paragraph>
              {nomination?.occurrence_type ===
              NominationOccurrenceType.Periodical
                ? nomination?.frequency
                : nominationOccurrenceTypes.find(
                    type => type.value === nomination?.occurrence_type,
                  )!.label}
            </Typography>
          </Grid>
        )}

        {nomination.awards?.length > 0 && (
          <Grid item xs={12}>
            <Paper
              sx={{
                py: 2,
                px: 3,
                background: theme =>
                  `linear-gradient(270deg, ${theme.palette.secondary.light} 0%, ${theme.palette.secondary.dark} 100%)`,
                mb: 2,
              }}
            >
              <Grid
                container
                spacing={2}
                justifyContent='space-between'
                alignItems='center'
              >
                <Grid item xs='auto'>
                  <AwardIcon sx={{ color: 'white', fontSize: '2.5em' }} />
                </Grid>

                <Grid item xs>
                  {nomination.awards?.map(award => (
                    <Fragment key={award.id}>
                      <Grid container alignItems='center' key={award.id}>
                        <Grid item xs={12} md>
                          <Typography
                            variant='h6'
                            color='text.white'
                            fontWeight={700}
                          >
                            {award.winners
                              ?.map(winner => winner.user?.full_name)
                              .join(', ')}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md='auto'>
                          <Typography
                            variant='body1'
                            color='text.white'
                            fontWeight={500}
                          >
                            {award.date}
                          </Typography>
                        </Grid>
                      </Grid>

                      {nomination.awards?.length > 1 && (
                        <Divider sx={{ color: 'text.white', my: 1.5 }} />
                      )}
                    </Fragment>
                  ))}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}

        <Grid item xs={12}>
          <NominationsManagementPendingNominees {...props} />
        </Grid>
      </Grid>
    </Box>
  )
}

function NominationsManagementPendingNominees({
  nomination,
  showAddToAwardedListButton,
  awardNomineesMutation,
}) {
  const [selectedAwarded, setSelectedAwarded] = useState<any>([])
  const [selectedNomineeDialog, setSelectedNomineeDialog] =
    useState(initialDialog)

  const addNomineeToAwardedList = nominee => {
    setSelectedAwarded([...selectedAwarded, nominee])
  }

  const removeNomineeFromAwardedList = nomineeId => {
    setSelectedAwarded([
      ...selectedAwarded.filter(award => award.user?.id !== nomineeId),
    ])
  }

  const pendingNominationsByUser = useMemo(
    () => groupBy(nomination.nominations ?? [], 'nominee.full_name'),
    [nomination],
  )

  return (
    <>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Collapse in={selectedAwarded.length > 0}>
              <Paper
                sx={{
                  p: 2,
                  mb: 2,
                  border: theme => `2px solid ${theme.palette.primary.main}`,
                  bgcolor: theme => alpha(theme.palette.primary.main, 0.03),
                }}
              >
                <Grid container sx={{ mb: 1 }}>
                  <Grid item xs={12}>
                    <Typography
                      variant='body1'
                      color='text.primary'
                      fontWeight={600}
                      paragraph
                    >
                      Confirm the Winners
                    </Typography>
                  </Grid>

                  {selectedAwarded.map(award => (
                    <SelectedNomination
                      key={award?.user}
                      nominee={award?.user}
                      removeNomineeFromAwardedList={
                        removeNomineeFromAwardedList
                      }
                    />
                  ))}

                  <Grid item xs={12}>
                    <Grid container justifyContent='flex-end'>
                      <Grid item xs='auto'>
                        <Button
                          variant='contained'
                          color='primary'
                          size='large'
                          onClick={() => {
                            setSelectedNomineeDialog({
                              isOpen: true,
                              data: selectedAwarded,
                            })
                          }}
                        >
                          Confirm
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Collapse>
          </Grid>

          <Grid item xs={12}>
            {Object.entries(pendingNominationsByUser)?.length > 0 ? (
              Object.entries(pendingNominationsByUser)
                .sort(([, a], [, b]) => b.length - a.length)
                .map(([key, values]) => (
                  <PendingNomination
                    nominee={key}
                    values={values}
                    selectedAwarded={selectedAwarded}
                    addNomineeToAwardedList={addNomineeToAwardedList}
                    showAddToAwardedListButton={showAddToAwardedListButton}
                  />
                ))
            ) : (
              <Typography variant='body1' color='text.secondary'>
                No nominations for this event occurrence yet.
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>

      <DialogTeleport
        dialogOpen={selectedNomineeDialog.isOpen}
        dialogSize='md'
        dialogTitle={'Select a nomination message'}
        handleDialogClose={() => setSelectedNomineeDialog(initialDialog)}
        isFullWidth
      >
        <NominationsManagementConfirmNomination
          nomination={nomination}
          selectedAwarded={selectedNomineeDialog.data}
          awardNomineesMutation={awardNomineesMutation}
          setSelectedNomineeDialog={setSelectedNomineeDialog}
        />
      </DialogTeleport>
    </>
  )
}

function SelectedNomination({ nominee, removeNomineeFromAwardedList }) {
  return (
    <Grid item key={nominee.id}>
      <Chip
        key={nominee.id}
        variant='filled'
        label={
          <Typography variant='body2' fontWeight={600}>
            {nominee.full_name}
          </Typography>
        }
        onDelete={() => removeNomineeFromAwardedList(nominee.id)}
        sx={{
          bgcolor: theme => alpha(theme.palette.primary.main, 0.15),
          border: theme => `1px solid ${theme.palette.primary.main}`,
        }}
      />
    </Grid>
  )
}

function PendingNomination({
  nominee,
  values,
  selectedAwarded,
  addNomineeToAwardedList,
  showAddToAwardedListButton,
}) {
  return (
    <Accordion key={nominee + 'pending'}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1bh-content'
        id='panel1bh-header'
      >
        <Typography
          variant='body1'
          fontWeight={600}
          sx={{ width: '33%', flexShrink: 0 }}
        >
          {nominee}
        </Typography>
        <Typography sx={{ color: 'text.primary' }}>
          {values.length} {pluralize('nomination', values.length)}
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SparckTable
              slug='currentNominations'
              data={values}
              columns={[
                {
                  field: 'nominator.full_name',
                  title: 'Owner',
                  editable: 'never',
                },
                {
                  field: 'message',
                  title: 'Message',
                  editable: 'never',
                },
                {
                  field: 'created_at',
                  title: 'Date',
                  editable: 'never',
                  render: (rowData: any) => getDateLabel(rowData.created_at),
                },
              ]}
              style={{ boxShadow: 'none' }}
              options={{
                toolbar: false,
              }}
            />
          </Grid>

          {showAddToAwardedListButton && (
            <Grid item xs={12}>
              <Grid container justifyContent='flex-end'>
                <Grid item xs='auto'>
                  <Button
                    variant='outlined'
                    color='primary'
                    disabled={
                      !!selectedAwarded.find(
                        award => award.user?.id === values[0]?.nominee?.id,
                      )
                    }
                    onClick={() => {
                      addNomineeToAwardedList({
                        user: values[0]?.nominee,
                        nominations: values,
                      })
                    }}
                  >
                    Select {nominee} as a Winner
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}
