import { Grid } from '@mui/material'
import {
  CompanyBeheard,
  CompanyCostAnalysis,
  CompanyFreeResources,
  CompanyGotQuestions,
  CompanyHeader,
  CompanyOverview,
  CompanyPulseSurveyUpdates,
  CompanySuggestionUpdates,
  CompanySurveyUpdates,
} from '../../components'
import { useUser } from 'contexts'
import { userHasBeseenPermissions } from 'utils/user'

export default function CompanyComponent(props: any) {
  const { user } = useUser()!

  return (
    <Grid container spacing={2}>
      <CompanyHeader />

      <Grid item xs={12} sm={4}>
        <CompanyOverview {...props} />
      </Grid>

      <Grid item xs={12} sm={4}>
        <CompanyBeheard />
      </Grid>

      <Grid item xs={12} sm={4}>
        <CompanyGotQuestions />
      </Grid>

      <Grid item xs={12}>
        <CompanySurveyUpdates {...props} />
      </Grid>

      {userHasBeseenPermissions(user) && (
        <Grid item xs={12}>
          <CompanyPulseSurveyUpdates {...props} />
        </Grid>
      )}

      {userHasBeseenPermissions(user) && (
        <Grid item xs={12}>
          <CompanySuggestionUpdates {...props} />
        </Grid>
      )}

      <Grid item xs={12} sm={6}>
        <CompanyFreeResources />
      </Grid>

      <Grid item xs={12} sm={6}>
        <CompanyCostAnalysis />
        <br />
        <br />
      </Grid>
    </Grid>
  )
}
