import { useState, useEffect } from 'react'

import { isEmpty } from 'lodash'
import { useQuery, useMutation } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import { PulseSurveyOverviewComponent } from './components'
import { useShoppingCartItemsCount } from 'contexts'
import { API } from 'core/requests'
import { ROUTES_PATHS } from 'core/routes'
import { SurveyStatus as PulseSurveyStatus } from 'enums/survey'
import { t } from 'i18n'
import { PulseSurveyType } from 'types'

export default function PulseSurveyOverview() {
  const [confirmCloseDialogOpen, setConfirmCloseDialogOpen] = useState(false)
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false)
  const [confirmLaunchDialogOpen, setConfirmLaunchDialogOpen] = useState(false)
  const [extendPulseSurveyDialogOpen, setExtendPulseSurveyDialogOpen] =
    useState(false)

  const initialSelectedPulseSurvey: PulseSurveyType | null | any = null
  const [selectedPulseSurvey, setSelectedPulseSurvey] = useState(
    initialSelectedPulseSurvey,
  )

  const query: any = useParams()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { setShoppingCartItemsCount } = useShoppingCartItemsCount()!

  const {
    isLoading: isPulseSurveysLoading,
    data: pulseSurveys,
    isSuccess: isPulseSurveysSuccess,
    refetch: refetchPulseSurveys,
  } = useQuery<PulseSurveyType[], Error>('pulseSurveys', () =>
    API.get('pulseSurveys'),
  )

  const launchPulseSurveyMutation = useMutation(
    (payload: any) => API.update('openPulseSurvey', payload),
    {
      onSuccess: (response: any) => {
        if (response && response.id) {
          enqueueSnackbar(
            t('pulseSurveyOverview.snackbarSuccess.openedPulseSurvey'),
            {
              variant: 'success',
            },
          )
          refetchPulseSurveys()
          setConfirmLaunchDialogOpen(false)
        }
      },
    },
  )

  const extendPulseSurveyMutation = useMutation(
    (payload: any) => API.update('extendPulseSurvey', payload),
    {
      onSettled: (response: any) => {
        if (response && response.id) {
          enqueueSnackbar(
            t('pulseSurveyOverview.snackbarSuccess.extendedPulseSurvey'),
            {
              variant: 'success',
            },
          )
          refetchPulseSurveys()
          setExtendPulseSurveyDialogOpen(false)
        }
      },
    },
  )

  const closePulseSurveyMutation = useMutation(
    (payload: any) => API.update('closePulseSurvey', payload),
    {
      onSettled: (response: any) => {
        if (response && response.id) {
          enqueueSnackbar(
            t('pulseSurveyOverview.snackbarSuccess.closedPulseSurvey'),
            {
              variant: 'success',
            },
          )
          refetchPulseSurveys()
          setConfirmCloseDialogOpen(false)
        }
      },
    },
  )

  const deletePulseSurveyMutation = useMutation(
    (payload: any) => API.delete('pulseSurveys', payload.id),
    {
      onSettled: (response: any) => {
        if (response && response.success) {
          enqueueSnackbar(
            t('pulseSurveyOverview.snackbarSuccess.deletedPulseSurvey'),
            {
              variant: 'success',
            },
          )
          refetchPulseSurveys()
          setConfirmDeleteDialogOpen(false)
        }
      },
    },
  )

  const addPulseSurveyToCartMutation = useMutation(
    (payload: any) => API.create('shoppingCartAddItem', payload),
    {
      onSettled: (response: any) => {
        if ((response && response.id) || (response && response.success)) {
          enqueueSnackbar(
            t('pulseSurveyOverview.snackbarSuccess.addedToCart'),
            {
              variant: 'success',
            },
          )
          setShoppingCartItemsCount(state => state + 1)
          history.push(ROUTES_PATHS.marketing)
        } else if (response && response.status === 'conflict') {
          history.push(ROUTES_PATHS.invoices)
        }
      },
    },
  )

  useEffect(() => {
    if (isPulseSurveysSuccess) {
      if (!isEmpty(pulseSurveys)) {
        if (query.id) {
          setSelectedPulseSurvey(
            pulseSurveys!.find(pulseSurvey => pulseSurvey.id === +query.id!) ||
              pulseSurveys![0],
          )
        } else {
          setSelectedPulseSurvey(
            pulseSurveys!.find(
              pulseSurvey => pulseSurvey.type !== PulseSurveyStatus.Close,
            ) || pulseSurveys![0],
          )
        }
      } else {
        history.push(ROUTES_PATHS.managePulseSurvey)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPulseSurveysSuccess, pulseSurveys])

  useEffect(() => {
    // if (selectedPulseSurvey && selectedPulseSurvey.id) {
    //   if (!query.id || query.id !== selectedPulseSurvey.id) {
    //     history.push(
    //       `${ROUTES_PATHS.pulseSurveyOverview}/${selectedPulseSurvey.id}`,
    //     )
    //   }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPulseSurvey])

  const addSelectedPulseSurveyToCart = () => {
    addPulseSurveyToCartMutation.mutate({
      product_key: 'full_report',
      object_name_to_upgrade: 'PulseSurvey',
      object_id_to_upgrade: selectedPulseSurvey.id,
    })
  }

  return (
    <PulseSurveyOverviewComponent
      pulseSurveys={pulseSurveys!}
      isPulseSurveysLoading={isPulseSurveysLoading}
      selectedPulseSurvey={selectedPulseSurvey}
      setSelectedPulseSurvey={setSelectedPulseSurvey}
      launchPulseSurvey={launchPulseSurveyMutation}
      closePulseSurvey={closePulseSurveyMutation}
      isClosePulseSurveyLoading={closePulseSurveyMutation.isLoading}
      deletePulseSurvey={deletePulseSurveyMutation}
      isDeletePulseSurveyLoading={deletePulseSurveyMutation.isLoading}
      confirmCloseDialogOpen={confirmCloseDialogOpen}
      setConfirmCloseDialogOpen={setConfirmCloseDialogOpen}
      confirmDeleteDialogOpen={confirmDeleteDialogOpen}
      setConfirmDeleteDialogOpen={setConfirmDeleteDialogOpen}
      isLaunchPulseSurveyLoading={launchPulseSurveyMutation.isLoading}
      confirmLaunchDialogOpen={confirmLaunchDialogOpen}
      setConfirmLaunchDialogOpen={setConfirmLaunchDialogOpen}
      extendPulseSurveyDialogOpen={extendPulseSurveyDialogOpen}
      setExtendPulseSurveyDialogOpen={setExtendPulseSurveyDialogOpen}
      extendPulseSurveyMutation={extendPulseSurveyMutation}
      addPulseSurveyToCart={addSelectedPulseSurveyToCart}
      addPulseSurveyToCartIsLoading={addPulseSurveyToCartMutation.isLoading}
      refetchPulseSurveys={refetchPulseSurveys}
    />
  )
}
