import sparckMountainWavesTeal from 'assets/images/waves/sparck-mountain-waves-teal.png'
import sparckMountainWavesOrange from 'assets/images/waves/sparck-mountain-waves-orange.png'
import sparckMountainWavesYellow from 'assets/images/waves/sparck-mountain-waves-yellow.png'
import sparckMountainWavesGrey from 'assets/images/waves/sparck-mountain-waves-grey.png'
import celebrateIcon from 'assets/images/icons/celebrate-icon.png'
import connectIcon from 'assets/images/icons/connect-icon.png'
import showIcon from 'assets/images/icons/show-icon.png'
import supportIcon from 'assets/images/icons/support-icon.png'
import tellIcon from 'assets/images/icons/tell-icon.png'
import undefinedIcon from 'assets/images/icons/undefined-icon.png'
import sparckAppreciationCardHeader_1 from 'assets/images/recognitionStyles/sparck-appreciation-card-header-1.png'
import sparckAppreciationCardHeader_2 from 'assets/images/recognitionStyles/sparck-appreciation-card-header-2.png'
import sparckAppreciationCardHeader_3 from 'assets/images/recognitionStyles/sparck-appreciation-card-header-3.png'
import sparckAppreciationCardHeader_4 from 'assets/images/recognitionStyles/sparck-appreciation-card-header-4.png'
import sparckAppreciationCardHeader_5 from 'assets/images/recognitionStyles/sparck-appreciation-card-header-5.png'
import sparckAppreciationCardHeader_6 from 'assets/images/recognitionStyles/sparck-appreciation-card-header-6.png'
import { t } from 'i18n'

export const getRecognitionStyle = {
  A: {
    id: 'show_me',
    name: t('profile.profileRecognitionStyle.showMe.title'),
    image: sparckMountainWavesTeal,
    backgroundImage: sparckAppreciationCardHeader_1,
    icon: showIcon,
    descriptionSeeLess: t(
      'profile.profileRecognitionStyle.showMe.descriptionSeeLess',
    ),
    descriptionSeeMore: t(
      'profile.profileRecognitionStyle.showMe.descriptionSeeMore',
    ),
  },
  B: {
    id: 'celebrate_with_me',
    name: t('profile.profileRecognitionStyle.celebrateWithMe.title'),
    image: sparckMountainWavesOrange,
    backgroundImage: sparckAppreciationCardHeader_2,
    icon: celebrateIcon,
    descriptionSeeLess: t(
      'profile.profileRecognitionStyle.celebrateWithMe.descriptionSeeLess',
    ),
    descriptionSeeMore: t(
      'profile.profileRecognitionStyle.celebrateWithMe.descriptionSeeMore',
    ),
  },
  C: {
    id: 'connect_with_me',
    name: t('profile.profileRecognitionStyle.connectWithMe.title'),
    image: sparckMountainWavesTeal,
    backgroundImage: sparckAppreciationCardHeader_3,
    icon: connectIcon,
    descriptionSeeLess: t(
      'profile.profileRecognitionStyle.connectWithMe.descriptionSeeLess',
    ),
    descriptionSeeMore: t(
      'profile.profileRecognitionStyle.connectWithMe.descriptionSeeMore',
    ),
  },
  D: {
    id: 'tell_me',
    name: t('profile.profileRecognitionStyle.tellMe.title'),
    image: sparckMountainWavesOrange,
    backgroundImage: sparckAppreciationCardHeader_4,
    icon: tellIcon,
    descriptionSeeLess: t(
      'profile.profileRecognitionStyle.tellMe.descriptionSeeLess',
    ),
    descriptionSeeMore: t(
      'profile.profileRecognitionStyle.tellMe.descriptionSeeMore',
    ),
  },
  E: {
    id: 'support_me',
    name: t('profile.profileRecognitionStyle.supportMe.title'),
    image: sparckMountainWavesYellow,
    backgroundImage: sparckAppreciationCardHeader_5,
    icon: supportIcon,
    descriptionSeeLess: t(
      'profile.profileRecognitionStyle.supportMe.descriptionSeeLess',
    ),
    descriptionSeeMore: t(
      'profile.profileRecognitionStyle.supportMe.descriptionSeeMore',
    ),
  },
  F: {
    id: 'trust_me',
    name: t('profile.profileRecognitionStyle.trustMe.title'),
    image: sparckMountainWavesOrange,
    backgroundImage: sparckAppreciationCardHeader_6,
    icon: tellIcon,
    descriptionSeeLess: t(
      'profile.profileRecognitionStyle.trustMe.descriptionSeeLess',
    ),
    descriptionSeeMore: t(
      'profile.profileRecognitionStyle.trustMe.descriptionSeeMore',
    ),
  },
  undefined: {
    id: 'recognition_style_undefined',
    name: t('profile.profileRecognitionStyle.undefined.title'),
    image: sparckMountainWavesGrey,
    icon: undefinedIcon,
    descriptionSeeLess: (isLoggedUser, visitedUserName) =>
      isLoggedUser
        ? t('profile.profileRecognitionStyle.undefined.descriptionSeeLess')
        : t('profile.profileRecognitionStyle.undefined.noStyleYet', {
            userName: visitedUserName,
          }),
  },
}
