import 'core-js'
import 'react-app-polyfill/ie11'
import React from 'react'
import ReactDOM from 'react-dom'
import ApplicationMonitoring from 'core/ApplicationMonitoring'

ReactDOM.render(
  <React.StrictMode>
    <ApplicationMonitoring />
  </React.StrictMode>,
  document.getElementById('root'),
)
