import React from 'react'
import clsx from 'clsx'
import { lighten, Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Checkbox from '@mui/material/Checkbox'
import { Button, Typography } from '@mui/material'

import { t } from 'i18n'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>
  columns: any[]
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: string
  rowCount: number
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    classes,
    columns,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {columns.map(column => (
          <TableCell
            key={column.id}
            align={column.align ?? 'left'}
            sortDirection={orderBy === column.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : 'asc'}
              onClick={createSortHandler(column.id)}
            >
              {column.label}
              {orderBy === column.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc'
                    ? t('simpleLibrarySelection.sortedDescending')
                    : t('simpleLibrarySelection.sortedAscending')}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.mode === 'light'
        ? {
            color: theme.palette.primary.main,
            backgroundColor: lighten(theme.palette.primary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.primary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  }),
)

interface EnhancedTableToolbarProps {
  title: string
  selected: any[]
  onSelectClick: any
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { title, selected, onSelectClick } = props

  const classes = useToolbarStyles()
  const numberOfSelected = selected.length

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numberOfSelected > 0,
      })}
    >
      {numberOfSelected > 0 ? (
        <Typography
          className={classes.title}
          color='inherit'
          variant='body1'
          component='div'
          fontWeight={600}
        >
          {numberOfSelected}{' '}
          {numberOfSelected > 1
            ? t('simpleLibrarySelection.selected.plural')
            : t('simpleLibrarySelection.selected.singular')}
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant='h6'
          id='tableTitle'
          component='div'
        >
          {title}
        </Typography>
      )}
      {numberOfSelected > 0 && (
        <Button
          data-cy='submitButton'
          variant='contained'
          color='primary'
          aria-label='delete'
          onClick={() => onSelectClick(selected)}
        >
          {t('select')} {title ?? 'items'}
        </Button>
      )}
    </Toolbar>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: 24,
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
)

export type LibrarySelectionProps = {
  title: string
  rows: any[]
  columns: {
    id: string
    label: string
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
    render?(row: any): any
  }[]
  orderBy: string
  rowsPerPage?: number
  onSelectClick: any
  selectedIds: any[]
}

export default function LibrarySelection(props: LibrarySelectionProps) {
  const { rows, columns, title, onSelectClick, selectedIds } = props

  const classes = useStyles()

  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<any>(props.orderBy)
  const [selected, setSelected] = React.useState<any[]>(
    rows.filter(row => selectedIds.includes(row.id)),
  )
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(
    props.rowsPerPage ?? rows.length,
  )

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(rows)
      return
    }
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    const selectedIndex = selected.findIndex(
      selectedRow => selectedRow.id === row.id,
    )
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (row: any) =>
    selected.find(selectedRow => selectedRow.id === row.id)

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  React.useEffect(() => {
    setRowsPerPage(props.rowsPerPage ?? rows.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rowsPerPage])

  return (
    <div className={classes.root}>
      <EnhancedTableToolbar
        title={title}
        selected={selected}
        onSelectClick={onSelectClick}
      />
      <TableContainer>
        <Table
          data-cy='table_body'
          className={classes.table}
          aria-labelledby='tableTitle'
          aria-label='enhanced table'
        >
          <EnhancedTableHead
            classes={classes}
            columns={columns}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = !!isSelected(row)
                const labelId = `enhanced-table-checkbox-${index}`

                return (
                  <TableRow
                    data-cy='checkbox'
                    hover
                    onClick={event => handleClick(event, row)}
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell padding='checkbox'>
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    {columns.map((column, columnIndex) => (
                      <TableCell
                        {...(columnIndex === 0 && {
                          component: 'th',
                          scope: 'row',
                        })}
                        key={columnIndex}
                        id={labelId}
                      >
                        {column.render ? column.render(row) : row[column.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {rowsPerPage !== rows.length && (
        // @ts-ignore
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          // @ts-ignore
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  )
}
