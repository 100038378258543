import React from 'react'
import SwipeableViews from 'react-swipeable-views'
import {
  AppBar,
  Box,
  Tab,
  Tabs,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useIntl } from 'react-intl'

// import { getEnvironmentName } from 'core/environments'
import { ProfileAboutMe } from '../../components'
import { ProfileAutocompleteQuestions } from 'components'
import { MyRewards } from 'pages'
import { a11yTabProps } from 'utils/accessibility'
import { displayProfileSection, userHasBeseenPermissions } from 'utils/user'
import { useCompany } from 'contexts'

interface TabPanelProps {
  children?: React.ReactNode
  dir?: string
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

export default function ProfileTabs(props) {
  const {
    tab,
    setTab,
    profileState,
    personalQuestions,
    professionalQuestions,
    isProfileFromLoggedUser,
    user,
    profileId,
  } = props

  const intl = useIntl()
  const { company } = useCompany()!
  // const environment = getEnvironmentName()
  // const companyId = (environment === "production") ? 1025 : 928;

  const profilePages = [
    {
      title: intl.formatMessage(
        {
          id: 'profile.tabs.labels.about',
        },
        {
          userName: profileId
            ? profileState.first_name
            : intl.formatMessage({
                id: 'profile.tabs.labels.me',
              }),
        },
      ),
      Component: ProfileAboutMe,
      display: true,
    },
    {
      title: intl.formatMessage({
        id: 'profile.tabs.labels.personal',
      }),
      Component: ProfileAutocompleteQuestions,
      display:
        displayProfileSection(
          isProfileFromLoggedUser,
          profileState.personal_interests_visibility,
          user,
          profileState,
        ) && userHasBeseenPermissions(user),
      props: {
        questions: personalQuestions,
        answersAttributes: 'profile_answers_attributes',
      },
    },
    {
      title: intl.formatMessage({
        id: 'profile.tabs.labels.professional',
      }),
      Component: ProfileAutocompleteQuestions,
      display:
        displayProfileSection(
          isProfileFromLoggedUser,
          profileState.professional_interests_visibility,
          user,
          profileState,
        ) && userHasBeseenPermissions(user),
      props: {
        questions: professionalQuestions,
        answersAttributes: 'profile_answers_attributes',
      },
    },
  ]
  
  if(company?.enable_rewards_store === true){
    profilePages.push({
      title: intl.formatMessage({
        id: 'profile.tabs.labels.myRewards',
      }),
      Component: MyRewards,
      display: isProfileFromLoggedUser && userHasBeseenPermissions(user),
    });
  }

  const theme = useTheme()

  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('md'),
    {
      defaultMatches: true,
    },
  )

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    setTab(newValue)
  }

  const handleChangeIndex = (index: number) => {
    setTab(index)
  }

  return (
    <>
      <AppBar
        elevation={0}
        position='static'
        style={{ backgroundColor: theme.palette.primary.dark }}
        square
      >
        <Tabs
          value={tab}
          onChange={handleChange}
          textColor='inherit'
          indicatorColor='secondary'
          variant={isMobile ? 'scrollable' : 'fullWidth'}
          aria-label='full width tabs'
        >
          {profilePages
            .filter(page => page.display)
            .map((profilePage, index) => (
              <Tab
                key={index}
                label={profilePage.title}
                {...a11yTabProps(index)}
              />
            ))}
        </Tabs>
      </AppBar>
      <SwipeableViews index={tab} onChangeIndex={handleChangeIndex}>
        {profilePages.map((profilePage, index) => (
          <TabPanel value={tab} index={index} key={index}>
            <profilePage.Component {...props} {...profilePage.props} />
          </TabPanel>
        ))}
      </SwipeableViews>
    </>
  )
}