import { useState, useEffect } from 'react'

import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { Skeleton } from '@mui/material'
import { get } from 'lodash'
import Confetti from 'react-confetti'
import { useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { useTimeoutFn, useWindowSize } from 'react-use'

import sparckGiftRounded from 'assets/images/sparck-gift-rounded.png'
import { useCompany } from 'contexts'
import { API } from 'core/requests'
import { ROUTES_PATHS } from 'core/routes'
import { t } from 'i18n'
import { DialogData } from 'types'
import { formatToUSD } from 'utils/currency'
import { shouldDisableRewardRedeem } from 'utils/reward'

const borderRadius = 32

type SurveyRewardProps = {
  dialogReward: DialogData
  setDialogReward(value: DialogData): void
  surveyState: any
  confirmGiftCard(): any
  confirmGiftCardIsLoading?: boolean
  selectedGiftCard: any
  setSelectedGiftCard: (value: any) => void
}

export default function SurveyReward(props: SurveyRewardProps) {
  const {
    dialogReward,
    setDialogReward,
    surveyState,
    confirmGiftCard,
    confirmGiftCardIsLoading,
    selectedGiftCard,
    setSelectedGiftCard,
  } = props

  const history = useHistory()
  const location = useLocation()
  const { width, height } = useWindowSize()
  const { company } = useCompany()!

  const [stopConfetti, setStopConfetti] = useState(false)

  const {
    data: rewards,
    isLoading: rewardsIsLoading,
    refetch: fetchRewards,
  } = useQuery(
    'getRewards',

    () =>
      API.get('rewardsNGC', null, {
        queryParams: {
          fixed_amount: get(surveyState, 'reward_amount', 100),
        },
      }),
    { enabled: false },
  )

  useEffect(() => {
    if (dialogReward.isOpen) {
      fetchRewards()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogReward])

  useTimeoutFn(() => {
    setStopConfetti(true)
  }, 8000)

  const dialogFullScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  )

  if (!dialogReward.isOpen) return null

  const redirectAfterClose = () => {
    if (location.pathname !== ROUTES_PATHS.surveyLanding)
      history.push(ROUTES_PATHS.surveyLanding)
  }

  return (
    <>
      <Dialog
        open={dialogReward.isOpen}
        onClose={() => {
          setDialogReward({ isOpen: false, data: null })
          redirectAfterClose()
        }}
        maxWidth='md'
        fullWidth
        fullScreen={dialogFullScreen}
      >
        <DialogContent>
          <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent='center'>
                <img
                  src={sparckGiftRounded}
                  alt='Sparck Gift illustration'
                  style={{ maxWidth: 200, maxHeight: 200, marginTop: 16 }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography
                data-cy='surveyReward'
                variant='h3'
                color='text.primary'
                fontWeight={600}
                align='center'
                mt={2}
              >
                {t('components.surveyReward.title', {
                  rewardAmount:
                    surveyState.reward_amount &&
                    formatToUSD(surveyState.reward_amount),
                })}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant='h6'
                color='text.secondary'
                align='center'
                paragraph
                mb={5}
              >
                {shouldDisableRewardRedeem(company) ? (
                  <>
                    Thank you for participating in the survey!
                    <br />
                    {get(company, 'name', 'This company')} has a gift for you.
                    Email{' '}
                    <a
                      href='mailto:joe.champion@sparckco.com'
                      rel='noopener noreferrer'
                    >
                      joe.champion@sparckco.com
                    </a>{' '}
                    to redeem.
                  </>
                ) : (
                  t('components.surveyReward.description', {
                    companyName: get(company, 'name', ''),
                    rewardAmount:
                      surveyState.reward_amount &&
                      formatToUSD(surveyState.reward_amount),
                  })
                )}
              </Typography>
            </Grid>

            {shouldDisableRewardRedeem(company) ? null : (
              <>
                {selectedGiftCard ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <img
                        src={
                          selectedGiftCard?.product?.faceplate_url ??
                          selectedGiftCard?.brand?.logo_url
                        }
                        alt={selectedGiftCard?.brand?.name}
                        style={{ width: '100%' }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Grid
                        container
                        justifyContent='center'
                        alignItems='center'
                        style={{ height: '100%' }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            variant='h6'
                            color='text.primary'
                            align='center'
                            paragraph
                            mb={3}
                          >
                            {t('components.surveyReward.youSelected', {
                              giftCardName: (
                                <b>{selectedGiftCard?.brand?.name}</b>
                              ),
                            })}
                          </Typography>

                          <Grid item xs={12}>
                            <Grid container justifyContent='center'>
                              <Box m={1}>
                                <Button
                                  variant='outlined'
                                  color='secondary'
                                  onClick={() => setSelectedGiftCard(null)}
                                >
                                  {t('components.surveyReward.chooseAnother')}
                                </Button>
                              </Box>

                              <Box m={1}>
                                <Button
                                  variant='contained'
                                  color='primary'
                                  disabled={confirmGiftCardIsLoading}
                                  onClick={() => confirmGiftCard()}
                                >
                                  {confirmGiftCardIsLoading && (
                                    <CircularProgress
                                      size={16}
                                      style={{ marginRight: 8 }}
                                    />
                                  )}{' '}
                                  {t(
                                    'components.surveyReward.confirmThisGiftCard',
                                  )}
                                </Button>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : rewardsIsLoading ? (
                  [...Array(6)].map((_, index) => (
                    <Grid item key={index} xs={6} sm={4}>
                      <Skeleton
                        variant='rectangular'
                        height={200}
                        style={{ borderRadius: borderRadius }}
                      />
                    </Grid>
                  ))
                ) : (
                  (rewards ?? []).map(({ product, brand }) => (
                    <Grid item key={brand?.id} xs={6} sm={4}>
                      <Card
                        style={{ borderRadius: borderRadius }}
                        onClick={() =>
                          setSelectedGiftCard({
                            product: product,
                            brand: brand,
                            sku: product?.range_options[0]?.sku,
                          })
                        }
                      >
                        <CardActionArea style={{ borderRadius: borderRadius }}>
                          <CardMedia
                            component='img'
                            image={product?.faceplate_url ?? brand?.logo_url}
                            style={{
                              width: '100%',
                              aspectRatio: '16/9',
                              borderRadius: borderRadius,
                            }}
                            alt={brand?.name}
                            title={brand?.name}
                          />
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))
                )}
              </>
            )}
          </Grid>
        </DialogContent>
      </Dialog>

      <Confetti
        width={width}
        height={height}
        recycle={!stopConfetti}
        numberOfPieces={1200}
        gravity={0.3}
        style={{ zIndex: 999 }}
      />
    </>
  )
}
