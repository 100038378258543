import CryptoJS from 'crypto-js'

const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export async function getCroppedImage(imageSrc, pixelCrop, rotation = 0) {
  const image: any = await createImage(imageSrc)
  const canvas: any = document.createElement('canvas')
  const ctx: any = canvas.getContext('2d')

  const maxSize = Math.max(image.width, image.height)
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea
  canvas.height = safeArea

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2)
  ctx.rotate(getRadianAngle(rotation))
  ctx.translate(-safeArea / 2, -safeArea / 2)

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5,
  )
  const data = ctx.getImageData(0, 0, safeArea, safeArea)

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
  )
  return new Promise(resolve => resolve(canvas))
}

export function toHash(imageBinaryString) {
  /** 
    All strings in JavaScript - even "binary strings" - are actually UTF-16 characters. 
    A "binary string" is one that chooses to use only the first 256 code points. 
    Since the Latin-1 encoding also uses exactly the first 256 code points, you can convert the string to bytes using Latin-1.
  */
  const binaryRepresentation = CryptoJS.enc.Latin1.parse(imageBinaryString)
  const md5OfBinary = CryptoJS.MD5(binaryRepresentation)
  const base64ofMD5 = md5OfBinary.toString(CryptoJS.enc.Base64)
  return base64ofMD5
}
