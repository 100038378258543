import { useState } from 'react'

import { Autocomplete, Box, Grid, TextField } from '@mui/material'
import InputMask from 'react-input-mask'

import { Locale } from 'enums/locale'
import { t } from 'i18n'

const countries = [
  { id: Locale.English, code: 'US', label: 'United States', phone: '1', },
  { id: Locale.BrazilianPortuguese, code: 'BR', label: 'Brazil', phone: '55' },
  { id: Locale.Spanish, code: 'ES', label: 'Spain', phone: '34' },
  { id: Locale.Japanese, code: 'ES', label: 'Japan', phone: '34' },
  { id: Locale.German, code: 'DE', label: 'Germany', phone: '31' },
  { id: Locale.Arabic, code: 'SA', label: 'Saudi Arabia', phone: '966' },
  { id: Locale.Danish, code: 'DK', label: 'Denmark', phone: '45' },
  { id: Locale.Dutch, code: 'NL', label: 'Nether Land', phone: '31' },
  { id: Locale.French, code: 'CA', label: 'Canada', phone: '1' },
  { id: Locale.Finnish, code: 'FL', label: 'Finland', phone: '358' },
  { id: Locale.Italian, code: 'IT', label: 'Italy', phone: '39' },
  { id: Locale.Russian, code: 'RU', label: 'Russia', phone: '7' },
  { id: Locale.Swedish, code: 'SE', label: 'Sweden', phone: '46' },
  { id: Locale.BrazilianSpanish, code: 'BR', label: 'Brazil', phone: '55' },
  { id: Locale.CanadianFrench, code: 'CF', label: 'CanadaF', phone: '1' },
  { id: Locale.EUSpanish, code: 'EU', label: 'euSpain', phone: '34' },

]

const INPUT_MASK = {
  [Locale.English]: '+1\\ 999-999-9999',
  [Locale.BrazilianPortuguese]: '+55\\ 99-99999-9999',
  [Locale.Spanish]: '+34\\ 999999999',
  [Locale.Japanese]: '+34\\ 999999999',
  [Locale.German]: '+31\\ 999999999',
  [Locale.Arabic]: '+966\\ 999999999',
  [Locale.Danish]: '+45\\ 999999999',
  [Locale.Dutch]: '+31\\ 999999999',
  [Locale.French]: '+1\\ 999999999',
  [Locale.Finnish]: '+358\\ 999999999',
  [Locale.Italian]: '+39\\ 999999999',
  [Locale.Russian]: '+7\\ 999999999',
  [Locale.Swedish]: '+46\\ 999999999',
  [Locale.BrazilianSpanish]: '+55\\ 99-99999-9999',
  [Locale.CanadianFrench]: '+1\\ 99-99999-9999',
  [Locale.EUSpanish]: '+34\\ 99-99999-9999',

}

type Props = {
  formik: any
  phoneNumberInputProps: any
}

export default function PhoneNumberInputWithFormatPicker(props: Props) {
  const { formik, phoneNumberInputProps } = props
  const [selectedCountry, setSelectedCountry] = useState(countries[0])

  const onCountryChange = (_, newCountry) => {
    setSelectedCountry(newCountry)
    formik.setFieldValue('phone_number', '')
  }

  return (
    <>
      <Grid item xs={12} md={4} lg={4}>
        <Autocomplete
          id='country-select'
          value={selectedCountry as any}
          onChange={onCountryChange}
          options={countries}
          autoHighlight
          getOptionLabel={option => option.label}
          renderOption={(props, option) => (
            <Box
              component='li'
              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading='lazy'
                width='20'
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=''
              />
              {option.label} ({option.code}) +{option.phone}
            </Box>
          )}
          renderInput={params => (
            <TextField
              {...params}
              label={t(
                'components.phoneNumberInputWithFormatPicker.chooseCountry',
              )}
              // margin='normal'
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={8} lg={4}>
        <InputMask
          mask={INPUT_MASK[selectedCountry.id]}
          value={formik.values[phoneNumberInputProps.name]}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        >
          {inputProps => (
            <TextField
              {...inputProps}
              id={phoneNumberInputProps.id}
              name={phoneNumberInputProps.name}
              type='tel'
              label={phoneNumberInputProps.label}
              variant='outlined'
              data-cy={phoneNumberInputProps.data_cy}
              error={
                formik.touched[phoneNumberInputProps.name] &&
                Boolean(formik.errors[phoneNumberInputProps.name])
              }
              helperText={
                formik.touched[phoneNumberInputProps.name] &&
                formik.errors[phoneNumberInputProps.name] &&
                t(formik.errors[phoneNumberInputProps.name])
              }
              fullWidth
            />
          )}
        </InputMask>
      </Grid>
    </>
  )
}
