import { Check as CheckIcon } from '@mui/icons-material'
import { Box, Button, Grid, Typography, useTheme } from '@mui/material'

import { MFAFactorType } from 'enums/multiFactorAuthentication'
import { t } from 'i18n'

export default function MFAFactorSection(props) {
  const {
    factor,
    setDialogOpen,
    setSelectedFactor,
    setActiveStepId,
    setMFAPrimaryFactor,
  } = props

  const theme = useTheme()

  return (
    <>
      <Grid item xs={12}>
        <Grid
          container
          p={2}
          sx={{
            border: `1px solid ${theme.palette.text.secondary}`,
            borderRadius: 2,
          }}
        >
          <Grid item xs={12} sx={{ height: 1 }}>
            <Box
              width={1}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant='h6'>{factor.title}</Typography>

              <Button
                variant='outlined'
                onClick={() => {
                  setSelectedFactor(factor)
                  setActiveStepId(factor.steps[0].id)

                  if (setDialogOpen) {
                    setDialogOpen(true)
                  }
                }}
              >
                {factor.id === MFAFactorType.BackupCode
                  ? t('mfa.viewOrDownload')
                  : factor.isEnabled
                  ? t('mfa.disable')
                  : t('mfa.enable')}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography>{factor.description}</Typography>
          </Grid>

          {factor.isEnabled && factor.isPrimary && (
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography color='primary' fontWeight={600}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'right',
                      }}
                    >
                      {t('mfa.primary')}
                      <CheckIcon />
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}

          {factor.isEnabled && !factor.isPrimary && (
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'right',
                }}
              >
                <Button
                  variant='text'
                  color='primary'
                  onClick={() =>
                    setMFAPrimaryFactor.mutate({ factor_type: factor.id })
                  }
                >
                  {t('mfa.makePrimary')}
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  )
}
