import {
  KeyboardArrowLeft as ArrowLeftIcon,
  KeyboardArrowRight as ArrowRightIcon,
} from '@mui/icons-material'
import { Button, CircularProgress, MobileStepper } from '@mui/material'
import { t } from 'i18n'

export default function MFAStepper(props) {
  const {
    steps,
    activeStep,
    setUserClickedNextPage,
    goToPreviousStep,
    nextPageIsLoading,
  } = props

  const activeStepIndex = steps?.map(step => step?.id).indexOf(activeStep?.id)

  return (
    <MobileStepper
      steps={steps?.length}
      position='static'
      variant='text'
      activeStep={activeStepIndex}
      backButton={
        <Button
          size='small'
          onClick={goToPreviousStep}
          disabled={activeStepIndex === 0}
        >
          <ArrowLeftIcon />
          {t('back')}
        </Button>
      }
      nextButton={
        <Button
          size='small'
          onClick={() => setUserClickedNextPage(true)}
          disabled={nextPageIsLoading || activeStepIndex === steps.length - 1}
        >
          {t('mfa.next')}
          {nextPageIsLoading ? (
            <CircularProgress size={16} sx={{ ml: 1 }} />
          ) : (
            <ArrowRightIcon />
          )}
        </Button>
      }
    />
  )
}
