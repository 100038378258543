import { Box, Badge, Grid, Link, Menu, MenuItem, useTheme } from '@mui/material'

import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material'
import { get } from 'lodash'
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { useHistory } from 'react-router'

import { ROUTES_PATHS, TOPBAR_PROFILE_ROUTES } from 'core/routes'
import { UserAvatar } from 'components'
import { t } from 'i18n'
import { validateRoutePermission } from 'utils/app'
import { useLogout } from 'utils/hooks'

type Props = {
  user: any
  setUser(value: any): void
  isMobile?: boolean
}

const TODAY = new Date()
const MAX_DATE_TO_DISPLAY_MY_SETTINGS_BADGE = new Date('07/23/2022')

export default function TopbarAvatar({ user }: Props) {
  const theme: any = useTheme()
  const history = useHistory()
  const { logout } = useLogout()!

  const profileListMenuState = usePopupState({
    variant: 'popover',
    popupId: `profileListMenu`,
  })

  const handleLogout = () => {
    logout()
  }

  const LS_USER_ENTERED_MY_SETTINGS = 'user_entered_my_settings'
  const userEnteredMySettings = localStorage.getItem(
    LS_USER_ENTERED_MY_SETTINGS,
  )

  const showMySettingsNewBadge =
    !userEnteredMySettings && TODAY < MAX_DATE_TO_DISPLAY_MY_SETTINGS_BADGE

  return (
    <Grid item>
      <Box position='relative'>
        <Badge
          color='primary'
          overlap='circular'
          badgeContent='!'
          invisible={!showMySettingsNewBadge}
        >
          <UserAvatar
            name={get(user, 'first_name')}
            src={get(user, 'avatar.url')}
            size={48}
            aria-controls='profile-list'
            aria-haspopup='true'
            onClick={(event: any) =>
              bindTrigger(profileListMenuState).onClick(event)
            }
          />
        </Badge>
        <ArrowDropDownIcon
          style={{
            position: 'absolute',
            right: '-24px',
            bottom: 10,
            color: theme.palette.text.light,
            cursor: 'pointer',
          }}
          {...bindTrigger(profileListMenuState)}
        />
      </Box>
      {bindMenu(profileListMenuState).open && (
        <Menu keepMounted {...bindMenu(profileListMenuState)}>
          {TOPBAR_PROFILE_ROUTES.map(
            route =>
              validateRoutePermission(user, route) && (
                <MenuItem
                  onClick={() => {
                    if (route.id === 'mySettings' && showMySettingsNewBadge) {
                      localStorage.setItem(LS_USER_ENTERED_MY_SETTINGS, 'true')
                    }

                    history.push(route.path)
                  }}
                  key={route.id}
                >
                  {route.title}{' '}
                  {route.id === 'mySettings' && showMySettingsNewBadge && (
                    <Badge
                      color='primary'
                      badgeContent='NEW'
                      sx={{
                        '& .MuiBadge-badge': {
                          right: -28,
                          top: 0,
                          borderRadius: '4px',
                          height: '18px',
                          fontSize: '0.7rem',
                          fontWeight: 600,
                        },
                      }}
                    />
                  )}
                </MenuItem>
              ),
          )}

          <Box
            style={{
              borderTop: '1px solid lightgray',
              borderBottom: '1px solid lightgray',
            }}
          >
            <MenuItem>
              <Link
                href='https://faq.sparckco.com/knowledge/release-notes'
                target='_blank'
                style={{ color: 'inherit' }}
              >
                {t('topbar.viewReleaseNotes')}
              </Link>
            </MenuItem>

            <MenuItem>
              <Link
                href='https://faq.sparckco.com/knowledge/terms-of-service'
                target='_blank'
                style={{ color: 'inherit' }}
              >
                {t('topbar.termsOfService')}
              </Link>
            </MenuItem>

            <MenuItem>
              <Link
                href='https://faq.sparckco.com/knowledge/sparck-privacy-policy'
                target='blank'
                style={{ color: 'inherit' }}
              >
                {t('topbar.privacyPolicy')}
              </Link>
            </MenuItem>
          </Box>

          <MenuItem onClick={() => history.push(ROUTES_PATHS.changePassword)}>
            {t('routes.changePassword')}
          </MenuItem>
          <MenuItem onClick={handleLogout}>{t('routes.logout')}</MenuItem>
        </Menu>
      )}
    </Grid>
  )
}
