import { useState } from 'react'

import { Box, Chip, Grid, MenuItem, TextField } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import { useMutation } from 'react-query'
import * as yup from 'yup'

import { useFetchProfileQuestionsLibrary } from '../ProfileQuestions.queries'
import { DialogTeleport, FormHandler, LibrarySelection } from 'components'
import { initialDialog } from 'constants/dialog'
import { useUser } from 'contexts'
import { API } from 'core/requests'
import { ProfileQuestionType as ProfileQuestionTypeEnum } from 'enums/profileQuestion'
import { UserPermission } from 'enums/user'
import { t } from 'i18n'
import { ProfileQuestionType } from 'types'
import { capitalizeFirstLetter } from 'utils/app'

const validationSchema = yup.object().shape({
  statement: yup
    .string()
    .required(t('profileQuestions.validations.statementRequired')),
  type: yup
    .string()
    .required(t('profileQuestions.validations.questionTypeRequired')),
})

export default function ManageProfileQuestion({
  dialog,
  closeDialog,
  fetchRows,
  rows,
}: any) {
  const [dialogLibrary, setDialogLibrary] = useState(initialDialog)

  const { enqueueSnackbar } = useSnackbar()
  const { user } = useUser()!
  const intl = useIntl()

  const { data: profileQuestionsLibrary } = useFetchProfileQuestionsLibrary()

  const pickFromLibraryMutation = useMutation(
    (payload: any) => API.update('profileQuestionsPickFromLibrary', payload),
    {
      onSettled: (response: any) => {
        if (response && response.success) {
          fetchRows()

          enqueueSnackbar(t('profileQuestions.questionsSelectedFromLibrary'), {
            variant: 'success',
          })
        }
      },
    },
  )

  const handleLibraryClose = () => {
    setDialogLibrary(initialDialog)
  }

  const initialValues: ProfileQuestionType = dialog.data
    ? dialog.data
    : {
        statement: '',
        type: ProfileQuestionTypeEnum.Personal,
      }

  return (
    <>
      <FormHandler
        title={intl.formatMessage({ id: 'profileQuestions.title' })}
        requestUrl='profileQuestions'
        data={dialog.data}
        closeDialog={closeDialog}
        fetchRows={fetchRows}
        initialValues={initialValues}
        validationSchema={validationSchema}
        {...((user.permissions ?? []).includes(
          UserPermission.ViewProfileQuestionLibrary,
        ) && {
          secondaryActionButtons: [
            {
              id: 'library',
              label: intl.formatMessage({
                id: 'profileQuestions.selectFromLibrary',
              }),
              color: 'secondary',
              variant: 'outlined',
              onClick: () => {
                setDialogLibrary({
                  isOpen: true,
                  data: null,
                })
              },
              isLoading: false,
            },
          ],
        })}
      >
        {({ formik }) => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} data-cy='profileQuestionName'>
              <TextField
                id='statement'
                name='statement'
                label={t('profileQuestions.fields.statement')}
                variant='outlined'
                value={formik.values.statement}
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.statement && Boolean(formik.errors.statement)
                }
                helperText={formik.touched.statement && formik.errors.statement}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6} data-cy='profileQuestionType'>
              <TextField
                select
                id='type'
                name='type'
                label={t('type')}
                variant='outlined'
                value={formik.values.type}
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.type && Boolean(formik.errors.type)}
                helperText={formik.touched.type && formik.errors.type}
                fullWidth
              >
                <MenuItem
                  data-cy='questionTypePersonal'
                  key={ProfileQuestionTypeEnum.Personal}
                  value={ProfileQuestionTypeEnum.Personal}
                >
                  {t('profileQuestions.fields.personal')}
                </MenuItem>

                <MenuItem
                  data-cy='questionTypeProfessional'
                  key={ProfileQuestionTypeEnum.Professional}
                  value={ProfileQuestionTypeEnum.Professional}
                >
                  {t('profileQuestions.fields.professional')}
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>
        )}
      </FormHandler>

      <DialogTeleport
        dialogTitle=''
        dialogAction={null}
        dialogSize='md'
        dialogOpen={!!dialogLibrary.isOpen}
        handleDialogClose={handleLibraryClose}
        isFullWidth
      >
        <LibrarySelection
          title={intl.formatMessage({ id: 'profileQuestions.questions' })}
          rows={profileQuestionsLibrary ?? []}
          columns={[
            {
              id: 'statement',
              label: t('profileQuestions.fields.statement'),
            },
            {
              id: 'type',
              label: t('type'),
              render: row => (
                <Box mt={1}>
                  <Chip
                    label={
                      <span style={{ fontWeight: 500 }}>
                        {capitalizeFirstLetter(row.type)}
                      </span>
                    }
                    color={
                      row.type === 'professional' ? 'primary' : 'secondary'
                    }
                  />
                </Box>
              ),
            },
          ]}
          orderBy='statement'
          selectedIds={rows
            .filter(row => row.profile_library_question_id)
            .map(row => row.profile_library_question_id)}
          onSelectClick={selected => {
            const selectedIds = selected.map(question => question.id)

            const newSelected: any[] = []

            rows
              .filter(question => question.profile_library_question_id)
              .forEach(question => {
                newSelected.push({
                  id: question.id,
                  profile_library_question_id:
                    question.profile_library_question_id,
                  _destroy: !selectedIds.includes(
                    question.profile_library_question_id,
                  ),
                })
              })

            selectedIds
              .filter(
                id =>
                  !newSelected
                    .map(question => question.profile_library_question_id)
                    .includes(id),
              )
              .forEach(id => {
                newSelected.push({ profile_library_question_id: id })
              })

            pickFromLibraryMutation.mutate({
              library_questions: newSelected,
            })
            handleLibraryClose()
            closeDialog()
          }}
        />
      </DialogTeleport>
    </>
  )
}
