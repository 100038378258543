import { useState } from 'react'

import { EmojiEvents as RewardIcon } from '@mui/icons-material'
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'

import { get } from 'lodash'
import { SparckTable } from 'components'
import { t } from 'i18n'
import { getUniqueColor } from 'types/colors'
// import { formatToUSD } from 'utils/currency'
import { getPagination } from 'utils/pagination'
import { getDateLabel } from 'utils/tables/dataTypes'

const slug = 'myRewardsPurchases'

export default function MyRewardsPurchases({
  // rewardsPurchases,
  // rewardsPurchasesIsLoading,
  rewardStorePurchases,
  rewardStorePurchasesIsLoading
}) {
  const theme = useTheme()

  const [pagination, setPagination] = useState(getPagination(slug))

  return (
    <Grid container spacing={2} justifyContent='center'>
      { rewardStorePurchasesIsLoading ? (
        <Grid item xs='auto' sx={{ my: 3 }}>
          <CircularProgress size={40} />
        </Grid>
      ) : rewardStorePurchases?.length ? (
        // <Grid item xs={12}>
        //   <SparckTable
        //     slug='rewardsPurchases'
        //     data={rewardsPurchases}
        //     columns={[
        //       {
        //         title: t(
        //           'myRewards.myRewardsPurchasesTable.columnTitles.giftCard',
        //         ),
        //         field: 'product_details.product_faceplate_url',
        //         render: (rowData: any) => {
        //           const image =
        //             rowData.product_details?.product_faceplate_url ??
        //             rowData.product_details?.image_url

        //           return image ? (
        //             <Box
        //               component='img'
        //               src={image}
        //               alt={'Gift Card logo'}
        //               sx={{
        //                 aspectRatio: '16/9',
        //                 borderRadius: '0.5em',
        //                 width: '10em',
        //               }}
        //             />
        //           ) : (
        //             <Box
        //               p={2}
        //               sx={{
        //                 aspectRatio: '16/9',
        //                 borderRadius: '0.5em',
        //                 width: '10em',
        //                 backgroundColor: getUniqueColor(theme, {
        //                   id: rowData.id,
        //                 }),
        //               }}
        //             >
        //               <Grid
        //                 container
        //                 justifyContent='center'
        //                 alignItems='center'
        //                 height={1}
        //               >
        //                 <RewardIcon sx={{ color: '#FFF', fontSize: '3em' }} />
        //               </Grid>
        //             </Box>
        //           )
        //         },
        //       },
        //       {
        //         title: t(
        //           'myRewards.myRewardsPurchasesTable.columnTitles.brand',
        //         ),
        //         field: 'product_details.brand_name',
        //         render: (rowData: any) =>
        //           rowData?.product_details?.brand_name ??
        //           rowData?.product_details?.name,
        //       },
        //       {
        //         title: t(
        //           'myRewards.myRewardsPurchasesTable.columnTitles.amount',
        //         ),
        //         field: 'amount',
        //         render: (rowData: any) => formatToUSD(rowData.amount),
        //       },
        //       {
        //         title: t(
        //           'myRewards.myRewardsPurchasesTable.columnTitles.acceptedAt',
        //         ),
        //         field: 'created_at',
        //         render: (rowData: any) => getDateLabel(rowData.created_at),
        //       },
        //     ]}
        //     pagination={pagination}
        //     setPagination={setPagination}
        //   />
        // </Grid>

        <Grid item xs={12}>
          <SparckTable
            slug='rewardStorePurchases'
            data={rewardStorePurchases}
            columns={[
              {
                title: t(
                  'myRewards.myRewardStorePurchasesTable.columnTitles.orderNumber',
                ),
                field: 'details.order.orderNumber',
                render: (rowData: any) => get(rowData, 'details.order.orderNumber')
              },
              {
                title: t(
                  'myRewards.myRewardStorePurchasesTable.columnTitles.orderItem',
                ),
                field: 'details.order.orderItems',
                render: (rowData: any) => {
                  
                  const image = get(rowData, 'details.order.orderItems[0].images[1].url')

                  return image ? (
                    <Box
                      component='img'
                      src={image}
                      alt={'Item Image'}
                      sx={{
                        aspectRatio: '16/9',
                        borderRadius: '0.5em',
                        width: '10em',
                      }}
                    />
                  ) : (
                    <Box
                      p={2}
                      sx={{
                        aspectRatio: '16/9',
                        borderRadius: '0.5em',
                        width: '10em',
                        backgroundColor: getUniqueColor(theme, {
                          id: rowData.id,
                        }),
                      }}
                    >
                      <Grid
                        container
                        justifyContent='center'
                        alignItems='center'
                        height={1}
                      >
                        <RewardIcon sx={{ color: '#FFF', fontSize: '3em' }} />
                      </Grid>
                    </Box>
                  )
                },
              },
              {
                title: t(
                  'myRewards.myRewardStorePurchasesTable.columnTitles.itemName',
                ),
                field: 'details.order.orderItems.name',
                render: (rowData: any) => get(rowData, 'details.order.orderItems[0].name')
              },
              {
                title: t(
                  'myRewards.myRewardStorePurchasesTable.columnTitles.amount',
                ),
                field: 'amount',
                render: (rowData: any) => get(rowData, 'details.order.totalPointCost'),
              },
              {
                title: t(
                  'myRewards.myRewardStorePurchasesTable.columnTitles.acceptedAt',
                ),
                field: 'created_at',
                render: (rowData: any) => getDateLabel(get(rowData, 'created_at')),
              },
            ]}
            pagination={pagination}
            setPagination={setPagination}
          />
        </Grid>

      ) : (
        <Typography variant='body1' align='center' sx={{ my: 3 }}>
          {t('myRewards.myRewardsPurchasesTable.noPurchases')}
        </Typography>
      )}
    </Grid>
  )
}
