import { Locale } from 'enums/locale'

export const daysOfWeek = {
  [Locale.English]: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  [Locale.BrazilianPortuguese]: [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
  ],
  [Locale.Spanish]: [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ],
  [Locale.Japanese]: [
    '「ドミンゴ」',
    '「ルネス」、',
    'マルテス ',
    '「ミエルコレス」、',
    'ジュエベス',
    '「ヴィエルヌ」、',
    '土曜日',
  ],
  [Locale.German]: [
    'deutsch Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ],
  [Locale.Arabic]: [
    'الأحد',
    'لونيس',
    'مارتيس',
    'ميركوليس',
    'جويفيس',
    'فيرنيس',
    'السبت',
  ],
  [Locale.Danish]: [
    'Søndag',
    'Mandag',
    'tirsdag',
    'onsdag',
    'torsdag',
    'fredag',
    'lørdag',
  ],
  [Locale.Dutch]: [
    'zondag',
    'Maandag',
    'Dinsdag',
    'Woensdag',
    'Donderdag',
    'Vrijdag',
    'Zaterdag',
  ],
  [Locale.French]: [
    'Dimanche',
    'lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
  ],
  [Locale.Finnish]: [
    'sunnuntai',
    'Maanantai',
    'Tiistai',
    'Keskiviikko',
    'Torstai',
    'Perjantai',
    'Lauantai',
  ],
  [Locale.Italian]: [
    'Sunnuntai',
    'Maanantai',
    'Tiistai',
    'keskiviikkona',
    'torstaina',
    'perjantaina',
    'Lauantai',
  ],
  [Locale.Russian]: [
    'Воскресенье',
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
  ],
  [Locale.Swedish]: [
    'söndag',
    'Måndag',
    'Tisdag',
    'Onsdag',
    'Torsdag',
    'Fredag',
    'lördag',
  ],
  [Locale.BrazilianSpanish]: [
    'Domingo',
    'Segunda',
    'Terça',
    'Miércoles',
    'Quinto',
    'Viernes',
    'Sábado',
  ],
  [Locale.CanadianFrench]: [
    'Dimanche',
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
  ],
  [Locale.EUSpanish]: [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ],
}

export const partOfDay = {
  [Locale.English]: ['morning', 'afternoon', 'evening'],
  [Locale.BrazilianPortuguese]: ['manhã', 'tarde', 'noite'],
  [Locale.Spanish]: ['mañana', 'tarde', 'noche'],
  [Locale.Japanese]: ['朝', '午後', '夜'],
  [Locale.German]: ['morgen', 'Nachmittag', 'Abend'],
  [Locale.Arabic]: ['صباح', 'بعد الظهر', 'مساء'],
  [Locale.Danish]: ['morgen', 'eftermiddag', 'aften'],
  [Locale.Dutch]: ['ochtend', 'middag', 'avond'],
  [Locale.French]: ['matin', 'après-midi', 'soir'],
  [Locale.Finnish]: ['aamu', 'iltapäivällä', 'ilta'],
  [Locale.Italian]: ['Mattina', 'pomeriggio', 'sera'],
  [Locale.Russian]: ['Утро', 'полдень', 'вечер'],
  [Locale.Swedish]: ['Morgon', 'eftermiddag', 'kväll'],
  [Locale.BrazilianSpanish]: ['manhã', 'tarde', 'noche'],
  [Locale.CanadianFrench]: ['Matin', 'Après-midi', 'Soir'],
  [Locale.EUSpanish]: ['mañana', 'tarde', 'noche'],
}

export const getDayOfWeek = locale => {
  return daysOfWeek[locale ?? Locale.English][new Date().getDay()]
}

export const getPartOfDay = locale => {
  const TODAY = new Date()
  const CURRENT_HOURS = TODAY.getHours()

  return CURRENT_HOURS < 12
    ? partOfDay[locale ?? Locale.English][0]
    : CURRENT_HOURS < 18
      ? partOfDay[locale ?? Locale.English][1]
      : partOfDay[locale ?? Locale.English][2]
}

export const getDateWithoutTimezone = (date: Date) =>
  new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000)

export const daysBetweenTwoDates = (firstDate, lastDate) => {
  const oneDay = 24 * 60 * 60 * 1000

  return Math.round(Math.abs((firstDate - lastDate) / oneDay))
}
