import { useState } from 'react'

import {
  alpha,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  CheckOutlined as CheckIcon,
  DeleteOutlined as DeleteIcon,
  EditOutlined as EditIcon,
} from '@mui/icons-material'
import { formatRelative, parseISO } from 'date-fns'
import parse from 'html-react-parser'
import { get } from 'lodash'

import { MediaDisplay, TextFieldWithMentions, UserAvatar } from 'components'
import { MediaType } from 'components/MediaPicker'
import { UserPermission } from 'enums/user'
import { t } from 'i18n'
import { UseMutationResult } from 'react-query'
import { CommentType } from 'types'
import { mentionsToPlainText } from 'utils/mentionsParser'
export interface CommentState extends CommentType {
  editMode: boolean
}

type Props = {
  comment: CommentType
  publishComment: (any) => void
  publishCommentStatus: UseMutationResult
  deleteCommentMutation: UseMutationResult
  user: {
    id: number
    permissions?: any[]
  }
  style?: React.CSSProperties
  highlightedCommentId?: any
}

export const gifStyle = {
  width: 240,
  maxWidth: '100%',
  maxHeight: 240,
  borderRadius: 4,
  marginTop: 8,
}

export default function FeedComment({
  comment,
  publishComment,
  publishCommentStatus,
  deleteCommentMutation,
  user,
  highlightedCommentId,
}: Props) {
  const [commentState, setCommentState] = useState(comment)

  const [commentIsEditMode, setCommentIsEditMode] = useState<boolean>(false)

  const theme = useTheme()

  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('md'),
    {
      defaultMatches: true,
    },
  )

  const formattedComment = mentionsToPlainText(commentState.content)

  return (
    <Box
      marginBottom={2}
      width={1}
      id={`comment-${comment.id}`}
      key={comment.id}
    >
      <Grid
        item
        xs={12}
        key={comment.id}
        {...(highlightedCommentId === comment.id && {
          sx: {
            outline: `1.5px solid ${theme.palette.primary.main}`,
            bgcolor: alpha(theme.palette.primary.main, 0.05),
            p: 1.5,
            borderRadius: 2,
          },
        })}
      >
        <Grid container spacing={1} data-cy='comment'>
          <Grid item xs={2} md='auto'>
            <UserAvatar
              userId={get(comment, 'user.id')}
              name={get(comment, 'user.name')}
              src={get(comment, 'user.avatar.url')}
              size={40}
            />
          </Grid>
          <Grid item xs={10} md>
            <Box
              component='div'
              sx={{
                backgroundColor: '#f0f2f5',
                borderRadius: '16px',
                padding: '8px 16px',
              }}
            >
              <Grid
                container
                alignItems='center'
                justifyContent='space-between'
                spacing={1}
                sx={{ mb: 1 }}
              >
                <Grid item>
                  <Grid container alignItems='center' spacing={1}>
                    <Grid item>
                      <Typography
                        variant='body2'
                        color='text.primary'
                        fontWeight={600}
                        style={{ maxWidth: isMobile ? '160px' : '' }}
                      >
                        {get(comment, 'user.name')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {user.id === get(comment, 'user.id') &&
                  (user.permissions ?? []).includes(
                    UserPermission.ManageComments,
                  ) && (
                    <Box>
                      <Grid container alignItems='center'>
                        <IconButton
                          data-cy='edit_comment'
                          size='small'
                          onClick={() => {
                            if (commentIsEditMode) {
                              publishComment({
                                ...commentState,
                              })
                            }

                            setCommentIsEditMode(!commentIsEditMode)
                          }}
                        >
                          {publishCommentStatus.isLoading &&
                          get(publishCommentStatus, 'variables.id') ===
                            comment.id ? (
                            <CircularProgress size={24} color='primary' />
                          ) : commentIsEditMode ? (
                            <CheckIcon color='primary' />
                          ) : (
                            <EditIcon color='primary' />
                          )}
                        </IconButton>

                        <IconButton
                          data-cy='delete_comment'
                          size='small'
                          onClick={() =>
                            deleteCommentMutation.mutate({ id: comment.id })
                          }
                        >
                          {deleteCommentMutation.isLoading &&
                          get(deleteCommentMutation, 'variables.id') ===
                            comment.id ? (
                            <CircularProgress size={24} color='secondary' />
                          ) : (
                            <DeleteIcon color='error' />
                          )}
                        </IconButton>
                      </Grid>
                    </Box>
                  )}
              </Grid>
              {commentIsEditMode ? (
                <>
                  <TextFieldWithMentions
                    initialContent={commentState?.content}
                    onContentChange={(content, mentions) =>
                      setCommentState({
                        ...commentState,
                        content,
                        mentions,
                      })
                    }
                  />
                  {comment?.gif_id_on_provider && (
                    <MediaDisplay
                      media={{
                        id: comment?.gif_id_on_provider,
                        src: '',
                        isImage: true,
                        name: comment?.gif_id_on_provider,
                        type: MediaType.Gif,
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  <Typography
                    variant='body1'
                    color='text.primary'
                    style={{ maxWidth: '60ch' }}
                  >
                    {parse(formattedComment)}
                  </Typography>

                  {comment?.gif_id_on_provider && (
                    <MediaDisplay
                      media={{
                        id: comment?.gif_id_on_provider,
                        src: '',
                        isImage: true,
                        name: comment?.gif_id_on_provider,
                        type: MediaType.Gif,
                      }}
                    />
                  )}
                </>
              )}

              {comment.updated_at !== comment.created_at && (
                <Box mt={1} width={1}>
                  <Grid container justifyContent='flex-end'>
                    <Typography
                      variant='body2'
                      style={{ fontSize: '11px' }}
                      color='text.secondary'
                    >
                      {t('feed.commentInfo.updated')}{' '}
                      {formatRelative(parseISO(comment.updated_at), new Date())}
                    </Typography>
                  </Grid>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
