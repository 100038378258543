import { useState } from 'react'

import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material'
import { sortBy } from 'lodash'
import InputMask from 'react-input-mask'
import { useHistory } from 'react-router'
import * as yup from 'yup'

import { FormHandler, TermsAndConditions } from 'components'
import { INDUSTRIES } from 'constants/industry'
import { phoneNumberRegExp, urlRegExp } from 'constants/validations'
import { useUser } from 'contexts/UserContext'
import { ROUTES_PATHS } from 'core/routes'
import { t } from 'i18n'
import { SignupType } from 'types'
import { SignupComponentProps } from '../SignupComponent/SignupComponent'

const sparckAdminValidationSchema = yup.object({
  company_name: yup
    .string()
    .required(t('signup.validations.sparckAdminSchema.companyNameIsRequired')),
  website_url: yup
    .string()
    .nullable()
    .matches(urlRegExp, 'signup.validations.sparckAdminSchema.invalidUrlFormat')
    .required('signup.validations.sparckAdminSchema.websiteUrlIsRequired'),
  industry: yup
    .string()
    .required(t('signup.validations.sparckAdminSchema.industryIsRequired')),
})

const employeesValidationSchema = yup.object({
  company_name: yup
    .string()
    .required(t('signup.validations.employeesSchema.companyNameIsRequired')),
  website_url: yup
    .string()
    .nullable()
    .matches(urlRegExp, 'signup.validations.employeesSchema.invalidUrlFormat')
    .required('signup.validations.employeesSchema.websiteUrlIsRequired'),
  industry: yup
    .string()
    .required(t('signup.validations.employeesSchema.industryIsRequired')),
  first_name: yup
    .string()
    .required(t('signup.validations.employeesSchema.firstNameIsRequired')),
  last_name: yup
    .string()
    .required(t('signup.validations.employeesSchema.lastNameIsRequired')),
  phone_number: yup
    .string()
    .matches(
      phoneNumberRegExp,
      'signup.validations.employeesSchema.phoneNumberInvalidFormat',
    )
    .required('signup.validations.employeesSchema.phoneNumberIsRequired'),
  email: yup
    .string()
    .email(t('signup.validations.employeesSchema.invalidEmailFormat'))
    .required(t('signup.validations.employeesSchema.emailIsRequired')),
  password: yup
    .string()
    .test(
      'contains-capital-letter',
      t('signup.validations.employeesSchema.passwordCapitalLetter'),
      (password: any) => password && password.match(/[A-Z]/),
    )
    .test(
      'contains-lowercase-letter',
      t('signup.validations.employeesSchema.passwordLowercaseLetter'),
      (password: any) => password && password.match(/[a-z]/),
    )
    .test(
      'contains-number',
      t('signup.validations.employeesSchema.passwordNumber'),
      (password: any) => password && password.match(/[0-9]/),
    )
    .test(
      'contains-special-character',
      t('signup.validations.employeesSchema.passwordSpecialCharacter'),
      (password: any) => password && password.match(/[+#!?@]/),
    )
    .required(t('signup.validations.employeesSchema.passwordIsRequired')),
})

export default function SignupForm(props: SignupComponentProps) {
  const { setDialogConfirmEmail } = props

  const { user } = useUser()!
  const history = useHistory()!

  const initialValues = {
    company_name: '',
    website_url: '',
    industry: '',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    password: '',
    notify: true,
  } as SignupType

  const [showPassword, setShowPassword] = useState(false)

  const [dialogTermsAndConditions, setDialogTermsAndConditions] =
    useState(false)

  return (
    <>
      <FormHandler
        requestUrl='companies'
        initialValues={initialValues}
        validationSchema={
          user && user.sparck_administrator
            ? sparckAdminValidationSchema
            : employeesValidationSchema
        }
        onSubmit={values => {
          setDialogConfirmEmail({ isOpen: true, data: values })
        }}
        submitLabel={t('signup.createAccount')}
        secondaryActionButtons={[
          {
            id: 'backToLogin',
            label: t('signup.secondaryActionButtonLabels.backToLogin'),
            onClick: () => history.push(ROUTES_PATHS.login),
            variant: 'outlined',
            color: 'primary',
            isLoading: false,
          },
        ]}
      >
        {({ formik }) => (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                id='company_name'
                name='company_name'
                label={t('signup.formLabels.companyName')}
                placeholder='Wayne Enterprises, Inc'
                variant='outlined'
                data-cy='company_name'
                value={formik.values.company_name}
                required={!!formik.errors.company_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.company_name &&
                  Boolean(formik.errors.company_name)
                }
                helperText={
                  formik.touched.company_name && formik.errors.company_name
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                select
                id='industry'
                name='industry'
                label={t('signup.formLabels.industry')}
                variant='outlined'
                data-cy='industry'
                value={formik.values.industry}
                required={!!formik.errors.industry}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.industry && Boolean(formik.errors.industry)
                }
                helperText={formik.touched.industry && formik.errors.industry}
                fullWidth
              >
                {sortBy(INDUSTRIES ?? [], ['name']).map(
                  (industry: { id: number; name: string }) => (
                    <MenuItem
                      key={industry.id}
                      value={industry.id}
                      data-cy='industryOption'
                    >
                      {t(industry.name)}
                    </MenuItem>
                  ),
                )}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id='website_url'
                name='website_url'
                label={t('signup.formLabels.websiteUrl')}
                placeholder='www.your-website-here.com'
                variant='outlined'
                data-cy='website_url'
                value={formik.values.website_url}
                required={!!formik.errors.website_url}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.website_url &&
                  Boolean(formik.errors.website_url)
                }
                helperText={
                  formik.touched.website_url &&
                  formik.errors.website_url &&
                  t(formik.errors.website_url)
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputMask
                mask='(999) 999-9999'
                value={formik.values.phone_number}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                {inputProps => (
                  <TextField
                    {...inputProps}
                    id='phone_number'
                    name='phone_number'
                    type='tel'
                    label={t('phoneNumber')}
                    variant='outlined'
                    data-cy='phone_number'
                    placeholder='(123) 456-7890'
                    error={
                      formik.touched.phone_number &&
                      Boolean(formik.errors.phone_number)
                    }
                    helperText={
                      formik.touched.phone_number &&
                      formik.errors.phone_number &&
                      t(formik.errors.phone_number)
                    }
                    fullWidth
                  />
                )}
              </InputMask>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id='first_name'
                name='first_name'
                label={t('firstName')}
                placeholder='Bruce'
                variant='outlined'
                data-cy='first_name'
                value={formik.values.first_name}
                required={!!formik.errors.first_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.first_name && Boolean(formik.errors.first_name)
                }
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id='last_name'
                name='last_name'
                label={t('lastName')}
                placeholder='Wayne'
                variant='outlined'
                data-cy='last_name'
                value={formik.values.last_name}
                required={!!formik.errors.last_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
                helperText={formik.touched.last_name && formik.errors.last_name}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                type='email'
                id='email'
                name='email'
                label={t('email')}
                placeholder='brucewayne@email.com'
                variant='outlined'
                data-cy='email'
                value={formik.values.email}
                required={!!formik.errors.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                type={showPassword ? 'text' : 'password'}
                id='password'
                name='password'
                label={t('signup.formLabels.password')}
                variant='outlined'
                data-cy='password'
                value={formik.values.password}
                required={!!formik.errors.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setShowPassword(!showPassword)}
                        edge='end'
                        data-cy='show_password'
                        size='large'
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {user.sparck_administrator && (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.notify}
                      onChange={formik.handleChange}
                      name='notify'
                    />
                  }
                  label={t('signup.formLabels.sendEmail')}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Box my={4}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant='body2' color='text.secondary'>
                      {t('signup.termsAndConditionsLink.firstLine')}
                      <Link
                        onClick={() => setDialogTermsAndConditions(true)}
                        data-cy='terms_and_conditions'
                      >
                        {' '}
                        {t('signup.termsAndConditionsLink.secondLine')}
                      </Link>
                      .
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        )}
      </FormHandler>

      <TermsAndConditions
        dialogOpen={dialogTermsAndConditions}
        setDialogOpen={setDialogTermsAndConditions}
      />
    </>
  )
}
