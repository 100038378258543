import * as React from 'react'

export default function GrowthIcon(props) {
  return (
    <svg
      width={101.234}
      height={101.234}
      viewBox='0 0 101.234 101.234'
      {...props}
    >
      <defs>
        <clipPath id='prefix__a'>
          <path
            className='prefix__a'
            d='M0 21.7h55.927v-43.77H0z'
            transform='translate(0 22.066)'
          />
        </clipPath>
        <style>{'.prefix__a{fill:#fa8d25}'}</style>
      </defs>
      <g transform='translate(-909 -1848)'>
        <circle
          cx={50.617}
          cy={50.617}
          r={50.617}
          transform='translate(909 1848)'
          fill='#fff'
        />
        <g transform='translate(935 1878.066)' clipPath='url(#prefix__a)'>
          <path
            className='prefix__a'
            d='M3.703 25.439a43.951 43.951 0 019.581-8.391 47.2 47.2 0 019.64-5 53.207 53.207 0 018.807-2.261 59.557 59.557 0 016.189-.833q1.607-.06 2.44-.06l-3.213 1.192a80.2 80.2 0 00-8.391 4.047q-5.061 2.74-10.473 6.308a56.182 56.182 0 00-10.473 9.1A42.357 42.357 0 00.133 41.03q-.6 1.312 1.488 2.321t2.8-.417a46.7 46.7 0 016.78-10.473q14.52 5.475 22.732-.238a19.589 19.589 0 005.534-6.249 51.3 51.3 0 003.868-7.855 30.746 30.746 0 014.642-7.855 23.827 23.827 0 017.736-6.486.379.379 0 00.119-.6Q54.167 1.517 44.167.204q-19.281-2.38-30.111 3.928Q2.391 11.038 3.703 25.439'
          />
        </g>
      </g>
    </svg>
  )
}
