import { useState, useEffect } from 'react'

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Tooltip,
} from '@mui/material'
import { useIntl } from 'react-intl'

import { CRUDTable } from 'components'
import { RecognitionFrequency } from 'enums/recognition'

export default function ManageRecognitionMilestonesTable({ formik }) {
  const [selectedMilestones, setSelectedMilestones] = useState(
    formik.values.milestones_attributes ?? [],
  )

  const milestoneIsDisabled = !(
    formik.values.has_reward &&
    formik.values.has_recurrence &&
    formik.values.recurrence_type === RecognitionFrequency.Annually
  )

  const intl = useIntl()

  useEffect(() => {
    formik.setFieldValue('milestones_attributes', selectedMilestones)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMilestones])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm='auto'>
        <FormGroup>
          <Tooltip
            title={
              milestoneIsDisabled
                ? intl.formatMessage({
                    id: 'recognitions.fields.setMilestoneBonusTooltip',
                  })
                : ''
            }
          >
            <FormControlLabel
              control={
                <Checkbox
                  data-cy='hasMilestone'
                  name='has_milestone'
                  checked={!!formik.values.has_milestone}
                  onChange={() =>
                    formik.setFieldValue(
                      'has_milestone',
                      !formik.values.has_milestone,
                    )
                  }
                  disabled={milestoneIsDisabled}
                />
              }
              label={intl.formatMessage({
                id: 'recognitions.fields.setMilestoneBonus',
              })}
            />
          </Tooltip>
        </FormGroup>
      </Grid>

      {formik.values.has_milestone && !milestoneIsDisabled && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} data-cy='eventOccurrence'>
              <CRUDTable
                rows={selectedMilestones}
                setRows={setSelectedMilestones}
                limit={30}
                columns={[
                  {
                    id: 'year',
                    label: intl.formatMessage({
                      id: 'recognitions.milestonesTable.columns.yearNumber',
                    }),
                    type: 'text',
                    align: 'left',
                    required: true,
                    disabled: () => false,
                    showEdit: () => true,
                    defaultValue: '',
                    textFieldProps: {
                      type: 'number',
                    },
                  },
                  {
                    id: 'reward_amount',
                    label: intl.formatMessage({
                      id: 'recognitions.milestonesTable.columns.rewardAmount',
                    }),
                    type: 'money',
                    align: 'left',
                    required: true,
                    disabled: () => false,
                    showEdit: () => true,
                    defaultValue: '',
                  },
                ]}
                showAdd
                showEdit={() => true}
                showRemove={() => true}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
