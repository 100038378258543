import { Chip, Grid, MenuItem, TextField, Typography } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import { t } from 'i18n'
import InputMask from 'react-input-mask'

import { getUserFullName } from 'utils/user'

export default function ManageEmployeeInfo({
  formik,
  bulkEditMode,
  bulkUsers,
  setBulkUsers,
  setDialogSelectEmployees,
  defaultDemographics,
}) {
  const handleBulkRemove = user => {
    setBulkUsers(bulkUsers.filter(bulkUser => bulkUser.id !== user.id))
  }

  return (
    <Grid container spacing={2}>
      {bulkEditMode && (
        <Grid item xs={12}>
          {bulkUsers.map(user => (
            <Chip
              label={getUserFullName(user)}
              color='primary'
              variant='outlined'
              style={{ marginRight: 8 }}
              onDelete={() => handleBulkRemove(user)}
            />
          ))}
          <Chip
            icon={<AddIcon />}
            label={
              <span style={{ fontWeight: 700 }}>
                {t('employees.selectEmployees')}...
              </span>
            }
            color='primary'
            onClick={() => setDialogSelectEmployees(true)}
          />
        </Grid>
      )}

      {!bulkEditMode && (
        <>
          <Grid item xs={12}>
            <Typography variant='h6' color='text.secondary'>
              {t('employees.generalInformation')}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              data-cy='company_internal_id'
              id='company_internal_id'
              name='company_internal_id'
              label={t('employees.fields.companyInternalId')}
              placeholder='e.g. E001'
              variant='outlined'
              value={formik.values.company_internal_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.company_internal_id &&
                Boolean(formik.errors.company_internal_id)
              }
              helperText={
                formik.touched.company_internal_id &&
                formik.errors.company_internal_id
              }
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              data-cy='first_name'
              id='first_name'
              name='first_name'
              label={t('firstName')}
              placeholder='John'
              variant='outlined'
              value={formik.values.first_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.first_name && Boolean(formik.errors.first_name)
              }
              helperText={formik.touched.first_name && formik.errors.first_name}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              data-cy='last_name'
              id='last_name'
              name='last_name'
              label={t('lastName')}
              placeholder='Doe'
              variant='outlined'
              value={formik.values.last_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.last_name && Boolean(formik.errors.last_name)
              }
              helperText={formik.touched.last_name && formik.errors.last_name}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              data-cy='title'
              id='title'
              name='title'
              label={t('employees.fields.title')}
              placeholder=''
              variant='outlined'
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
              fullWidth
            />
          </Grid>
        </>
      )}

      {!bulkEditMode && (
        <>
          <Grid item xs={12}>
            <Typography variant='h6' color='text.secondary'>
              {t('employees.employeeContact')}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <TextField
              data-cy='email'
              id='email'
              name='email'
              type='email'
              label={t('employees.fields.email')}
              placeholder='name@email.com'
              variant='outlined'
              value={formik.values.email}
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <InputMask
              mask='(999) 999-9999'
              value={formik.values.phone_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.phone_number &&
                Boolean(formik.errors.phone_number)
              }
              helperText={
                formik.touched.phone_number && formik.errors.phone_number
              }
            >
              {inputProps => (
                <TextField
                  {...inputProps}
                  data-cy='phone_number'
                  id='phone_number'
                  name='phone_number'
                  type='tel'
                  label={t('phoneNumber')}
                  variant='outlined'
                  placeholder='(123) 456-7890'
                  error={
                    formik.touched.phone_number &&
                    Boolean(formik.errors.phone_number)
                  }
                  helperText={
                    formik.touched.phone_number && formik.errors.phone_number
                  }
                  fullWidth
                />
              )}
            </InputMask>
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <Typography variant='h6' color='text.secondary'>
          {t('employees.employeeLocation')}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={6}>
        <TextField
          data-cy='location'
          select
          id='location'
          name='location'
          label={t('employees.fields.location')}
          placeholder='Sacramento'
          variant='outlined'
          value={formik.values.location}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.location && Boolean(formik.errors.location)}
          helperText={formik.touched.location && formik.errors.location}
          fullWidth
        >
          {defaultDemographics?.location?.map(location => (
            <MenuItem
              key={location.id}
              value={location.id}
              data-cy='locationOption'
            >
              {location.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextField
          data-cy='region'
          select
          id='region'
          name='region'
          label={t('employees.fields.region')}
          placeholder='Western Region'
          variant='outlined'
          value={formik.values.region}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.region && Boolean(formik.errors.region)}
          helperText={formik.touched.region && formik.errors.region}
          fullWidth
        >
          {defaultDemographics?.region?.map(region => (
            <MenuItem key={region.id} value={region.id} data-cy='regionOption'>
              {region.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <TextField
          data-cy='territory'
          select
          id='territory'
          name='territory'
          label={t('employees.fields.territory')}
          placeholder='Northern California'
          variant='outlined'
          value={formik.values.territory}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.territory && Boolean(formik.errors.territory)}
          helperText={formik.touched.territory && formik.errors.territory}
          fullWidth
        >
          {defaultDemographics?.territory?.map(territory => (
            <MenuItem
              key={territory.id}
              value={territory.id}
              data-cy='territoryOption'
            >
              {territory.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          data-cy='department'
          select
          id='department'
          name='department'
          label={t('department')}
          placeholder='Finance'
          variant='outlined'
          value={formik.values.department}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.department && Boolean(formik.errors.department)}
          helperText={formik.touched.department && formik.errors.department}
          fullWidth
        >
          {defaultDemographics?.department?.map(department => (
            <MenuItem
              key={department.id}
              value={department.id}
              data-cy='departmentOption'
            >
              {department.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          data-cy='business_unit'
          select
          id='business_unit'
          name='business_unit'
          label={t('employees.fields.businessUnit')}
          placeholder='Engineering Unit'
          variant='outlined'
          value={formik.values.business_unit}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.business_unit && Boolean(formik.errors.business_unit)
          }
          helperText={
            formik.touched.business_unit && formik.errors.business_unit
          }
          fullWidth
        >
          {defaultDemographics?.business_unit?.map(business_unit => (
            <MenuItem
              key={business_unit.id}
              value={business_unit.id}
              data-cy='businessUnitOption'
            >
              {business_unit.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  )
}
