import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'

import tabletSurveyReport from 'assets/images/marketing/tablet-survey-report.png'
import { t } from 'i18n'

export default function MarketingHeader(props) {
  const { checkoutCurrentCartMutation } = props

  return (
    <Grid
      container
      justifyContent='center'
      sx={{
        backgroundColor: 'primary.light',
        padding: '24px 0px',
      }}
    >
      <Grid item xs={11} sm={10} md={9} lg={8} xl={7}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={7}>
            <Typography
              variant='h2'
              component='h1'
              color='text.white'
              paragraph
              fontWeight={700}
            >
              {t('marketing.marketingHeader.upgradeToday')}
            </Typography>

            <Typography
              variant='h4'
              color='text.white'
              paragraph
              fontWeight={500}
              style={{ lineHeight: 1.2 }}
            >
              {t('marketing.marketingHeader.getMoreStrategicLook')}
            </Typography>

            <Box my={2}>
              <Typography
                variant='h6'
                color='text.white'
                paragraph
                style={{ lineHeight: 1.3 }}
              >
                {t('marketing.marketingHeader.beheardPremiumReportDescription')}
              </Typography>
            </Box>

            <Button
              color='secondary'
              variant='contained'
              onClick={checkoutCurrentCartMutation.mutate}
              disabled={checkoutCurrentCartMutation.isLoading}
              size='large'
            >
              {checkoutCurrentCartMutation.isLoading ? (
                <CircularProgress size={24} />
              ) : (
                t('marketing.marketingHeader.upgradeNow')
              )}
            </Button>
          </Grid>

          <Grid item xs={12} sm={5}>
            <Grid
              container
              justifyContent='center'
              alignItems='center'
              style={{ height: '100%' }}
            >
              <Box
                component='img'
                sx={{
                  width: '90%',
                }}
                src={tabletSurveyReport}
                alt='Tablet survey report image'
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
