import * as React from 'react'

export default function TurnoverIcon(props) {
  return (
    <svg
      width={101.234}
      height={101.234}
      viewBox='0 0 101.234 101.234'
      {...props}
    >
      <defs>
        <clipPath id='prefix__a'>
          <path
            className='prefix__a'
            d='M0 16.755h45.7V-27H0z'
            transform='translate(0 27)'
          />
        </clipPath>
        <style>{'.prefix__a{fill:#fa8d25}'}</style>
      </defs>
      <g transform='translate(-1309 -1849)'>
        <circle
          cx={50.617}
          cy={50.617}
          r={50.617}
          transform='translate(1309 1849)'
          fill='#fff'
        />
        <g transform='translate(1337 1875.245)' clipPath='url(#prefix__a)'>
          <path
            className='prefix__a'
            d='M29.608 29.316a6.238 6.238 0 01-1.7-4.619 3.683 3.683 0 011.118-2.334 7.094 7.094 0 001.507-3.6 1.4 1.4 0 01.437-.873 1.822 1.822 0 01.681-.389c.227-.063.452-.34.681-.826a6.136 6.136 0 00.438-2.085q0-1.653-.875-2.042l.583-4.278a7.458 7.458 0 00-1.945-5.445Q28.294.005 22.852.005t-7.633 2.82a7.959 7.959 0 00-1.995 5.44q.194 1.847.583 4.278l-.194.194a1.3 1.3 0 00-.438.583 2.837 2.837 0 00-.243 1.27 6.06 6.06 0 00.438 2.086c.227.486.452.763.681.826a1.847 1.847 0 01.68.388 1.412 1.412 0 01.439.876 8.329 8.329 0 001.554 3.549 3.942 3.942 0 011.07 2.382 6.343 6.343 0 01-1.653 4.619q-1.655 1.7-6.223 3.355Q0 36.171 0 38.602v5.153h45.7v-5.153q0-2.428-9.918-5.931a17.71 17.71 0 01-6.174-3.355'
          />
        </g>
      </g>
    </svg>
  )
}
