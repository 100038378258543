import { useEffect, useState } from 'react'
import { get } from 'lodash'
import { useMutation, useQuery } from 'react-query'
import { EmployeesDirectoryComponent } from './components'
import { API } from 'core/requests'

const EMPLOYEES_PER_PAGE = 36

export default function EmployeesDirectory() {
  const slug = 'employees-directory'

  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [favoritesOnly, setFavoritesOnly] = useState(false)

  const {
    data: employees,
    isLoading: employeesIsLoading,
    refetch: fetchEmployees,
  } = useQuery(
    'employees-directory',
    () =>
      API.get('employeesSearch', null, {
        queryParams: {
          q: search,
          size: EMPLOYEES_PER_PAGE,
          page: currentPage,
          favorites_only: favoritesOnly,
        },
      }),
    {
      enabled: false,
    },
  )

  const favoriteMutation = useMutation(
    (payload: any) => API.create('favorite', payload),
    {
      onSuccess: (response: any) => {
        if (response) {
          fetchEmployees()
        }
      },
    },
  )

  const unfavoriteMutation = useMutation(
    (payload: any) => API.delete('unfavorite', payload),
    {
      onSettled: () => {
        fetchEmployees()
      },
    },
  )

  useEffect(() => {
    fetchEmployees()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, search, favoritesOnly])

  return (
    <EmployeesDirectoryComponent
      slug={slug}
      employees={get(employees, 'data', [])}
      employeesIsLoading={employeesIsLoading}
      fetchEmployees={fetchEmployees}
      employeesCount={get(employees, 'meta.count', 0)}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      pageSize={EMPLOYEES_PER_PAGE}
      totalCount={get(employees, 'meta.pages.total')}
      search={search}
      setSearch={setSearch}
      favorite={favoriteMutation.mutate}
      unfavorite={unfavoriteMutation.mutate}
      favoritesOnly={favoritesOnly}
      setFavoritesOnly={setFavoritesOnly}
    />
  )
}
