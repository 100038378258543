import { useState, useEffect } from 'react'

import { Box, Card, CardContent, Grid, CircularProgress } from '@mui/material'
import {
  DashboardHeader,
  DashboardProgressTasks,
  DashboardReminders,
  DashboardTasks,
} from '../../components'
import { useUser } from 'contexts'
import { UserPermission } from 'enums/user'
import { ReminderType, TaskLevelType, TaskType } from 'types'
import { getEnvironmentName } from 'core/environments'

export type DashboardComponentProps = {
  tasks: TaskType[]
  isTasksLoading: boolean
  reminders: ReminderType[]
}

export default function DashboardComponent(props: DashboardComponentProps) {
  const { tasks, isTasksLoading, reminders } = props

  const { user } = useUser()!

  const initialRegularTasks: TaskType[] = []
  const initialProgressTasks: TaskType[] = []

  const [regularTasks, setRegularTasks] = useState(initialRegularTasks)
  const [progressTasks, setProgressTasks] = useState(initialProgressTasks)

  const lSCompanyId = localStorage.getItem('sparck_company')
  const CompanyID = JSON.parse(JSON.parse(JSON.stringify(lSCompanyId)))
  const environment = getEnvironmentName()
  const companyId = (environment === "production") ? 1025 : 928;

  useEffect(() => {
    const getTasksToShow = type =>
      tasks.filter(task => task.show && task.level === type)

    if (tasks) {
      const regularTasks = getTasksToShow(TaskLevelType.Regular)
      let priorityTasks = getTasksToShow(TaskLevelType.Priority)
      
      // Hack for Heroku company ID 928 or production company ID 1025 to hide wishlist
      if(priorityTasks.length > 0 && CompanyID && CompanyID['id'] === companyId){
        priorityTasks = priorityTasks.filter(
          priorityTask =>
          priorityTask.id !== 761,
        )
      }
      
      setRegularTasks(regularTasks)
      setProgressTasks(priorityTasks)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks])

  let displayReminders = (user.permissions ?? []).includes(
    UserPermission.ViewReminders,
  )
  displayReminders = (user?.company?.beseen_application === true) ? displayReminders : false;

  return (
    <>
      <Grid container spacing={2}>
        <DashboardHeader />

        <Grid
          item
          xs={12}
          md={displayReminders ? 8 : 12}
          xl={displayReminders ? 9 : 12}
        >
          {isTasksLoading ? (
            <Grid container justifyContent='center'>
              <CircularProgress size={48} />
            </Grid>
          ) : (
            <>
              {(user.permissions ?? []).includes(
                UserPermission.ViewPriorityTasks,
              ) && (user?.company?.beseen_application === true) && (
                  <DashboardProgressTasks
                    {...props}
                    progressTasks={progressTasks}
                    withFlag
                  />
                )}

              {(user.permissions ?? []).includes(
                UserPermission.ViewRegularTasks,
              ) && <DashboardTasks {...props} tasks={regularTasks} />}
            </>
          )}
        </Grid>

        {displayReminders && CompanyID && CompanyID['id'] !== 694 && (
          <Grid item xs={12} md={4} xl={3}>
            <Card elevation={2}>
              <CardContent>
                <Box p={1}>
                  <DashboardReminders reminders={reminders} />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  )
}
