export default {
  styleOverrides: {
    rounded: {
      borderRadius: '1rem',
    },
    elevation1: {
      boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.1)',
    },
    elevation2: {
      boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.25)',
    },
    elevation3: {
      boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.25)',
    },
    elevation4: {
      boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.4)',
    },
  },
}

// boxShadow:
//   '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
