import { useEffect, useState } from 'react'

import { Grid, TextField } from '@mui/material'

import { DialogTeleport, MoneyInput } from 'components'
import { Props as VirginExperienceProps } from 'pages/virginExperiencesOrganizational/VirginExperiencesOrganizational.props'

export default function VirginExperiencesOrganizationalUpdateBalanceDialog(
  props: VirginExperienceProps,
) {
  const {
    virginExperiencesOrganizational,
    company,
    addFunds,
    addFundsDialogOpen,
    setUpdateBalanceLimitDialogOpen,
  } = props

  const [amount, setAmount] = useState<string | number>(0)

  const handleDialogClose = () => setUpdateBalanceLimitDialogOpen(false)
  const updateNewBalanceLimit = event => setAmount(event.target.value)

  useEffect(() => {
    if (virginExperiencesOrganizational?.balance) {
      setAmount(virginExperiencesOrganizational.balance)
    }
  }, [virginExperiencesOrganizational])

  return (
    <DialogTeleport
      dialogOpen={addFundsDialogOpen}
      handleDialogClose={handleDialogClose}
      dialogSize={'xs'}
      dialogTitle={`Add funds to ${company?.name}`}
      dialogAction={{
        function: () =>
          addFunds.mutate({ amount: +amount, company_id: company?.id }),
        label: 'Add Funds',
        disabled: addFunds.isLoading,
        isLoading: addFunds.isLoading,
      }}
      isFullWidth
    >
      <Grid container>
        <Grid item xs={12}>
          <TextField
            id='amount'
            name='amount'
            label={'Add Funds'}
            variant='outlined'
            value={amount}
            onChange={updateNewBalanceLimit}
            required
            fullWidth
            margin='dense'
            InputProps={{
              inputComponent: MoneyInput,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
    </DialogTeleport>
  )
}
