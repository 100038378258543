import {
  BudgetBreakdownFilter,
  BudgetDistributionType,
  BudgetNewEmployeesBudgetType,
  BudgetPeriod,
  BudgetProrateType,
  BudgetYearType,
} from 'enums/budget'
import { t } from 'i18n'

export const budgetBreakdownFilters = [
  {
    label: t(
      'budgets.companyRewardBudgetBreakdown.budgetBreakdownFilters.businessUnit',
    ),
    value: BudgetBreakdownFilter.BusinessUnit,
  },
  {
    label: t('department'),
    value: BudgetBreakdownFilter.Department,
  },
  {
    label: t(
      'budgets.companyRewardBudgetBreakdown.budgetBreakdownFilters.employee',
    ),
    value: BudgetBreakdownFilter.Employees,
  },
  {
    label: t(
      'budgets.companyRewardBudgetBreakdown.budgetBreakdownFilters.region',
    ),
    value: BudgetBreakdownFilter.Region,
  },
  {
    label: t(
      'budgets.companyRewardBudgetBreakdown.budgetBreakdownFilters.territory',
    ),
    value: BudgetBreakdownFilter.Territory,
  },
]

export const budgetPeriods = [
  {
    label: t('budget.budgetPeriods.monthly'),
    value: BudgetPeriod.Monthly,
  },
  {
    label: t('budget.budgetPeriods.quarterly'),
    value: BudgetPeriod.Quarterly,
  },
  {
    label: t('budget.budgetPeriods.semiAnnually'),
    value: BudgetPeriod.SemiAnnually,
  },
  {
    label: t('budget.budgetPeriods.annually'),
    value: BudgetPeriod.Annually,
  },
]

export const budgetProrateTypes = [
  {
    label: t('budget.budgetProrateTypes.noBudgetInPeriod'),
    value: BudgetProrateType.NoBudgetInPeriod,
  },
  {
    label: t('budget.budgetProrateTypes.remainingFullMonths'),
    value: BudgetProrateType.RemainingFullMonths,
  },
  {
    label: t('budget.budgetProrateTypes.remainingMonthsWithCurrent'),
    value: BudgetProrateType.RemainingMonthsWithCurrent,
  },
]

export const budgetDistributionTypes = [
  {
    value: BudgetDistributionType.Flat,
    label: t('budget.budgetDistributionTypes.flat.label'),
    descriptionSpending: t(
      'budget.budgetDistributionTypes.flat.descriptionSpending',
    ),
    descriptionReceiving: t(
      'budget.budgetDistributionTypes.flat.descriptionReceiving',
    ),
  },
  {
    value: BudgetDistributionType.Individual,
    label: t('budget.budgetDistributionTypes.individual.label'),
    descriptionSpending: t(
      'budget.budgetDistributionTypes.individual.descriptionSpending',
    ),
  },
  {
    value: BudgetDistributionType.Demographics,
    label: t('budget.budgetDistributionTypes.demographics.label'),
    descriptionReceiving: t(
      'budget.budgetDistributionTypes.demographics.descriptionReceiving',
    ),
  },
]

export const budgetNewEmployeesBudgetTypes = [
  {
    label: t('budget.budgetNewEmployeesBudgetTypes.automatic'),
    value: BudgetNewEmployeesBudgetType.Automatic,
  },
  {
    label: t('budget.budgetNewEmployeesBudgetTypes.manual'),
    value: BudgetNewEmployeesBudgetType.Manual,
  },
]

export const budgetYearTypes = [
  {
    label: t('budget.budgetYearTypes.fiscal'),
    value: BudgetYearType.Fiscal,
  },
  {
    label: t('budget.budgetYearTypes.calendar'),
    value: BudgetYearType.Calendar,
  },
]

export const budgetDistributionTableFields = [
  // {
  //   name: "date",
  //   type: "date",
  //   format: 'date',
  //   label: "Date",
  //   null: true,
  //   default: null
  // },
  {
    name: "full_name",
    type: "string",
    format: null,
    label: "Name",
    null: true,
    default: null
  },
  {
    name: "amount",
    type: "integer",
    format: "currency",
    label: "Amount",
    null: true,
    default: null
  }
]

export const budgetRewardsTableFilters = [
  {
    name: "full_name",
    label: "Recipient Name",
  },
  {
    name: "event_name",
    label: "Recognition Event",
  },
  {
    name: "department_demographic",
    label: "Department",
  },
  {
    name: "location_demographic",
    label: "Location",
  },
  {
    name: "user_rewarding_full_name",
    label: "From Name",
  },
]

export const budgetRewardsFiltersInitialState = {
  full_name: "",
  event_name: "",
  department_demographic: "",
  location_demographic: "",
  user_rewarding_full_name: "",
}

export const budgetLoadingInitialState = {
  addBankLoading: false,
  addAmountLoading: false,
}

export const budgetAddBackFormInitialState = {
  account_holder_name: '',
  account_holder_type: '',
  account_number: '',
  routing_number: '',
  amount: "0",
  save_bank: false
}

export const budgetAccountHolderTypes = [
  {
    value: 'individual',
    label: 'Individual',
  },
  {
    value: 'company',
    label: 'Company',
  },
];