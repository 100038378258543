import { useEffect, useState } from 'react'

import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TextFieldProps,
  Typography,
  Paper,
} from '@mui/material'
import {
  Add as AddIcon,
  Edit as EditIcon,
  Check as SaveIcon,
  Close as DeleteIcon,
  DragIndicator as DragIcon,
} from '@mui/icons-material'
import { get, isEmpty, remove } from 'lodash'
import { useIntl } from 'react-intl'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { AvoidCopy, DialogTeleport, MoneyInput } from 'components'
import { initialDialog } from 'constants/dialog'
import { t } from 'i18n'
import LibrarySelection, {
  LibrarySelectionProps,
} from '../librarySelection/LibrarySelection'

import { formatToUSD } from 'utils/currency'

type CRUDTableProps = {
  rows: any[]
  setRows: any
  columns: {
    id: string
    label: string
    type: 'text' | 'select' | 'money' | 'checkbox'
    required: boolean
    disabled?: boolean | any
    showEdit(row: any): boolean
    defaultValue: string | number | boolean
    textFieldProps?: TextFieldProps
    selectOptions?: {
      value: string
      label: string
      list: any[]
    }
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
  }[]
  limit: number
  surveyFlag?: boolean
  showAdd?: boolean
  showEdit(row: any): boolean
  showRemove(row: any): boolean
  libraryProps?: LibrarySelectionProps
}

export default function CRUDTable(props: CRUDTableProps) {
  const {
    rows,
    setRows,
    columns,
    limit,
    libraryProps,
    showAdd,
    surveyFlag = false,
  } = props

  const defaultInputValues = {}

  columns.forEach(column => {
    defaultInputValues[column.id] = column.defaultValue
  })

  const [inputValues, setInputValues] = useState(defaultInputValues)
  const [showLimitWarning, setShowLimitWarning] = useState(false)
  const [dialogLibrary, setDialogLibrary] = useState(initialDialog)

  const intl = useIntl()

  const handleLibraryClose = () => setDialogLibrary(initialDialog)

  useEffect(() => {
    if (rows?.length > limit) {
      rows.length = limit

      setShowLimitWarning(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows])

  function tableRowForAdd(props) {
    return (
      <CRUDTableRow
        {...props}
        defaultInputValues={defaultInputValues}
        inputValues={inputValues}
        surveyFlag={surveyFlag}
        setInputValues={setInputValues}
        setShowLimitWarning={setShowLimitWarning}
      />
    )
  }

  const handleDragEnd = (result) => {
    if (!result.destination) return; // Item was dropped outside the droppable area
    const items = Array.from(rows);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Update the position based on the new order
    const updatedRows = items.map((row, index) => ({
      ...row,
      position: index+1,
    }));

    setRows(updatedRows);
  };


  return (
    <>
      <Grid container>
        {libraryProps && (
          <Grid item xs={12}>
            <Box mb={1}>
              <Grid
                container
                justifyContent='space-between'
                alignItems='center'
              >
                {get(libraryProps, 'title') && (
                  <Typography variant='h6' color='text.secondary'>
                    {get(libraryProps, 'title')}
                  </Typography>
                )}
                <Button
                  data-cy='open_dialog_button'
                  color='primary'
                  onClick={() =>
                    setDialogLibrary({
                      isOpen: true,
                      data: null,
                    })
                  }
                >
                  {get(libraryProps, 'title')
                    ? `${intl.formatMessage({
                        id: 'select',
                      })} ${get(libraryProps, 'title')}`
                    : intl.formatMessage({ id: 'select' })}
                </Button>
              </Grid>
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <div style={{ overflow: 'auto' }}>
              <TableContainer component={Paper}>
                <Table aria-label='list table'>
                  {!surveyFlag && (
                    <TableHead>
                      <TableRow>
                        {columns.map(column => (
                          <TableCell key={column.id} align={column.align ?? 'left'}>
                            {column.label}
                          </TableCell>
                        ))}
                        <TableCell align='center'>
                          {t('simpleCrudTable.actions')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  )}
                  <Droppable droppableId="table">
                    {(provided) => (
                      <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                        {showAdd && surveyFlag && tableRowForAdd(props)}
                        {rows
                          ?.filter(row => !row._destroy)
                          .map((row, index) => (
                            <CRUDTableRow
                              {...props}
                              key={index}
                              rows={rows}
                              defaultInputValues={defaultInputValues}
                              inputValues={inputValues}
                              setInputValues={setInputValues}
                              row={row}
                              index={index}
                              setShowLimitWarning={setShowLimitWarning}
                            />
                          ))}
                        {isEmpty(rows) && !surveyFlag && (
                          <TableRow>
                            <TableCell colSpan={columns.length + 1}>
                              <Typography
                                variant='body2'
                                color='text.secondary'
                                my={2}
                                ml={1}
                              >
                                {t('thereAreNo', {
                                  name: libraryProps?.title
                                    ? libraryProps?.title
                                    : t('simpleCrudTable.rows'),
                                })}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                        {showAdd && !surveyFlag && tableRowForAdd(props)}
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </Table>
              </TableContainer>
            </div>
          </DragDropContext>
        </Grid>

        {showLimitWarning && (
          <Grid item xs={12}>
            <Box mt={2} width={1}>
              <Typography
                variant='body2'
                color='secondary.dark'
                fontWeight={600}
                align='center'
              >
                {t('simpleCrudTable.limit.firstLine')} {limit}{' '}
                {t('simpleCrudTable.limit.secondLine')}
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>

      {libraryProps && (
        <DialogTeleport
          dialogTitle=''
          dialogAction={null}
          dialogSize='md'
          dialogOpen={!!dialogLibrary.isOpen}
          handleDialogClose={handleLibraryClose}
          isFullWidth
        >
          <AvoidCopy>
            <LibrarySelection
              {...libraryProps}
              onSelectClick={selected => {
                libraryProps.onSelectClick(selected)

                handleLibraryClose()
              }}
            />
          </AvoidCopy>
        </DialogTeleport>
      )}
    </>
  )
}

type CRUDTableRowProps = CRUDTableProps & {
  row?: any
  index?: any
  inputValues: any
  surveyFlag?: boolean
  setInputValues: any
  defaultInputValues: any
  setShowLimitWarning: any
}

function CRUDTableRow({
  row,
  index,
  rows,
  setRows,
  inputValues,
  setInputValues,
  defaultInputValues,
  columns,
  limit,
  setShowLimitWarning,
  showAdd,
  surveyFlag,
  showEdit,
  showRemove,
}: CRUDTableRowProps) {
  const [isEditMode, setIsEditMode] = useState(false)

  const areInputsFromHeader = !row || index === null
  const showField = areInputsFromHeader || isEditMode

  const addRow = () => {
    if (rows.length < limit) {
      setRows([...rows, inputValues])
      setInputValues(defaultInputValues)
    } else {
      setShowLimitWarning(true)
    }
  }

  const handleRemove = row => {
    if (row.id) {
      rows.find(selectedRow => selectedRow.id === row.id)._destroy = true
    } else {
      remove(rows, row)
    }

    setRows([...rows])
    setShowLimitWarning(false)
  }

  const requestedFieldsAreFilled = () =>
    isEmpty(
      columns.filter(column => column.required && !inputValues[column.id]),
    )

  const getSelectLabel = (value, column) => {
    const selectedItem = get(column, 'selectOptions.list', []).find(
      item => item[get(column, 'selectOptions.value')] === value,
    )

    if (selectedItem) return selectedItem[get(column, 'selectOptions.label')]
    return value
  }

  const getLabel = (value, column) => ({
    text: value ?? column.defaultValue,
    money: formatToUSD(value) ?? column.defaultValue,
    select: getSelectLabel(value, column) ?? column.defaultValue,
    checkbox: <Checkbox color='primary' checked={!!value} disabled />,
  })

  const addIconStyle = !surveyFlag
    ? {
        borderRadius: '100%',
        height: 48,
        width: 48,
        minWidth: 48,
      }
    : {}

  const getField = (column, value) => ({
    text: (
      <TextField
        {...(column.textFieldProps ?? {})}
        id={`${column.id}-${index}`}
        name={column.id}
        value={value}
        onChange={event => {
          if (!areInputsFromHeader) {
            rows.filter(row => !row._destroy)[index][column.id] =
              event.target.value
            setRows([...rows])
          } else {
            inputValues[column.id] = event.target.value
            inputValues['position'] = rows.length + 1
            // console.log("inputValues: ", inputValues);
            setInputValues({ ...inputValues })
          }
        }}
        disabled={column.disabled ? column.disabled(row ?? inputValues) : false}
        size={areInputsFromHeader ? 'medium' : 'small'}
        variant='outlined'
        fullWidth
      />
    ),
    money: (
      <TextField
        {...(column.textFieldProps ?? {})}
        id={`${column.id}-${index}`}
        name={column.id}
        value={value}
        onChange={event => {
          if (!areInputsFromHeader) {
            rows[index][column.id] = event.target.value
            setRows([...rows])
          } else {
            inputValues[column.id] = event.target.value
            setInputValues({ ...inputValues })
          }
        }}
        disabled={column.disabled ? column.disabled(row ?? inputValues) : false}
        size={areInputsFromHeader ? 'medium' : 'small'}
        variant='outlined'
        fullWidth
        InputProps={{
          inputComponent: MoneyInput,
        }}
      />
    ),
    select: (
      <TextField
        {...(column.textFieldProps ?? {})}
        select
        id={`${column.id}-${index}`}
        name={column.id}
        value={value}
        onChange={event => {
          if (!areInputsFromHeader) {
            rows[index][column.id] = event.target.value
            setRows([...rows])
          } else {
            inputValues[column.id] = event.target.value
            setInputValues({ ...inputValues })
          }
        }}
        disabled={column.disabled ? column.disabled(row ?? inputValues) : false}
        size={areInputsFromHeader ? 'medium' : 'small'}
        variant='outlined'
        fullWidth
      >
        {get(column, 'selectOptions.list', []).map(item => (
          <MenuItem
            key={item[get(column, 'selectOptions.value')]}
            value={item[get(column, 'selectOptions.value')]}
          >
            {item[get(column, 'selectOptions.label')]}
          </MenuItem>
        ))}
      </TextField>
    ),
    checkbox: (
      <Checkbox
        name={column.id}
        color='primary'
        checked={!!value}
        disabled={column.disabled ? column.disabled(row ?? inputValues) : false}
        onChange={event => {
          if (!areInputsFromHeader) {
            rows[index][column.id] = event.target.checked
            setRows([...rows])
          } else {
            inputValues[column.id] = event.target.checked
            setInputValues({ ...inputValues })
          }
        }}
      />
    ),
  })

  return (
    (row) ? (
      <Draggable key={row.position} draggableId={row.position.toString()} index={index}>
        {(provided) => (
          <TableRow
            key={index}
            onKeyUp={({ key }) => {
              if (
                key === 'Enter' &&
                areInputsFromHeader &&
                requestedFieldsAreFilled()
              )
                addRow()
            }}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {columns.map((column, columnIndex) => (
              <TableCell
                key={column.id}
                align={column.align ?? 'left'}
                {...(columnIndex === 0 && {
                  component: 'td',
                  scope: 'row',
                  
                })}
                sx={{
                  '&.custom-cell': {
                    width: '85% !important',
                    minWidth: '85% !important',
                  },
                }}
                className="custom-cell"
              >
                {
                  (columnIndex === 0) && (
                    <DragIcon color='primary' style={{verticalAlign: 'middle'}}/>
                  )
                }
                {!showField || (row && !column.showEdit(row))
                  ? getLabel(
                      rows.filter(row => !row._destroy)[index][column.id],
                      column,
                    )[column.type]
                  : getField(
                      column,
                      !areInputsFromHeader
                        ? rows.filter(row => !row._destroy)[index][column.id]
                        : inputValues[column.id],
                    )[column.type]}
              </TableCell>
            ))}
            <TableCell align='center' width="15%" style={{ width: 120 }}>
              {!areInputsFromHeader ? (
                <Grid
                  container
                  spacing={1}
                  justifyContent='center'
                  alignItems='center'
                >
                  {showEdit(row) && (
                    <IconButton
                      color='primary'
                      onClick={() => setIsEditMode(!isEditMode)}
                      size='large'
                      data-cy='edit_item'
                    >
                      {isEditMode ? <SaveIcon /> : <EditIcon />}
                    </IconButton>
                  )}

                  {showRemove(row) && (
                    <IconButton
                      color='secondary'
                      onClick={() => handleRemove(row)}
                      size='large'
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Grid>
              ) : (
                showAdd && (
                  <Button
                    data-cy='add_item'
                    style={addIconStyle}
                    variant={surveyFlag ? 'outlined' : 'contained'}
                    color='primary'
                    disabled={!requestedFieldsAreFilled()}
                    onClick={addRow}
                  >
                    {surveyFlag ? 'Add' : <AddIcon />}
                  </Button>
                )
              )}
            </TableCell>
          </TableRow>
        )}
      </Draggable>
    ) : (
      <TableRow
        key={index}
        onKeyUp={({ key }) => {
          if (
            key === 'Enter' &&
            areInputsFromHeader &&
            requestedFieldsAreFilled()
          )
            addRow()
        }}
      >
        {columns.map((column, columnIndex) => (
          <TableCell
            key={column.id}
            align={column.align ?? 'left'}
            {...(columnIndex === 0 && {
              component: 'th',
              scope: 'row',
            })}
          >
            {!showField || (row && !column.showEdit(row))
              ? getLabel(
                  rows.filter(row => !row._destroy)[index][column.id],
                  column,
                )[column.type]
              : getField(
                  column,
                  !areInputsFromHeader
                    ? rows.filter(row => !row._destroy)[index][column.id]
                    : inputValues[column.id],
                )[column.type]}
          </TableCell>
        ))}
        <TableCell align='center' style={{ width: 120 }}>
          {!areInputsFromHeader ? (
            <Grid
              container
              spacing={1}
              justifyContent='center'
              alignItems='center'
            >
              {showEdit(row) && (
                <IconButton
                  color='primary'
                  onClick={() => setIsEditMode(!isEditMode)}
                  size='large'
                  data-cy='edit_item'
                >
                  {isEditMode ? <SaveIcon /> : <EditIcon />}
                </IconButton>
              )}

              {showRemove(row) && (
                <IconButton
                  color='secondary'
                  onClick={() => handleRemove(row)}
                  size='large'
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Grid>
          ) : (
            showAdd && (
              <Button
                data-cy='add_item'
                style={addIconStyle}
                variant={surveyFlag ? 'outlined' : 'contained'}
                color='primary'
                disabled={!requestedFieldsAreFilled()}
                onClick={addRow}
              >
                {surveyFlag ? 'Add' : <AddIcon />}
              </Button>
            )
          )}
        </TableCell>
      </TableRow>
    )
  )
}
