import MuiAvatar from './MuiAvatar'
import MuiButton from './MuiButton'
import MuiCardContent from './MuiCardContent'
import MuiChip from './MuiChip'
import MuiDialogContent from './MuiDialogContent'
import MuiIconButton from './MuiIconButton'
import MuiPaper from './MuiPaper'
import MuiTableCell from './MuiTableCell'
import MuiTableHead from './MuiTableHead'
import MuiTableRow from './MuiTableRow'
import MuiTooltip from './MuiTooltip'
import MuiTypography from './MuiTypography'
import MuiSelect from './MuiSelect'

export default darkMode => ({
  MuiAvatar: MuiAvatar(darkMode),
  MuiButton,
  MuiCardContent,
  MuiChip,
  MuiDialogContent,
  MuiIconButton: MuiIconButton(darkMode),
  MuiPaper,
  MuiTableCell: MuiTableCell(darkMode),
  MuiTableHead,
  MuiTableRow: MuiTableRow(darkMode),
  MuiTooltip: MuiTooltip(darkMode),
  MuiTypography,
  MuiSelect: MuiSelect
})
