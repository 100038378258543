import { useCallback } from 'react'

import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, DatePickerProps } from '@mui/lab'

/**
 * [DEPRECATED] This component refers to the material-ui-pickers lib issue:
 * https://github.com/mui-org/material-ui-pickers/issues/1526
 * This component refers to the mui lib issue:
 * https://github.com/mui-org/material-ui/issues/28922
 * This component disregards the client timezone when passing value to the material-ui-pickers component
 * @returns
 */

function jsDateToLocalISO8601DateString(date) {
  return [
    String(date.getFullYear()),
    String(101 + date.getMonth()).substring(1),
    String(100 + date.getDate()).substring(1),
  ].join('-')
}

function dateStringToLocalDate(dateString) {
  if (!dateString) return null
  return new DateFnsUtils().parse(dateString, 'yyyy-MM-dd')
}

export default function DatePickerWithWorkaround(
  props: Omit<DatePickerProps, 'onChange'> & { onChange: any },
) {
  const { value, onChange } = props

  const handleChange = useCallback(
    date => {
      onChange(date ? jsDateToLocalISO8601DateString(date) : null)
    },
    [onChange],
  )

  return (
    <DatePicker
      {...props}
      value={dateStringToLocalDate(value) ?? ''}
      onChange={handleChange}
    />
  )
}
