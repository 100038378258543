import { Locale } from '../../enums/locale'
import intlComponents from './intlComponents'
import intlBeheard from './intlBeheard'
import intlBeseen from './intlBeseen'
import intlGeneral from './intlGeneral'

export default {
  [Locale.Japanese]: {
    ...intlGeneral,
    ...intlBeheard,
    ...intlBeseen,
    ...intlComponents,
  },
}
