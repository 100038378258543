export default {
  components: {
    surveyReward: {
      title: 'Você recebeu um cartão de {rewardAmount}!',
      description:
        'Como agradecimento por participar da Pesquisa BeHeard, {companyName} está oferecendo um gift card no valor de {rewardAmount}. Por favor, clique em alguma marca abaixo para selecionar seu cartão. Obrigado novamente por fazer sua voz ser ouvida!',
      youSelected: 'Você selecionou: {giftCardName}',
      chooseAnother: 'Escolha Outro',
      confirmThisGiftCard: 'Confirme este Gift Card',
    },
    demographicsFilters: {
      noResults: 'Nenhum resultado correspondente a sua pesquisa.',
      lessThanFiveRespondents:
        'Para proteger a privacidade dos funcionários, não mostramos resultados com menos de 5 participantes.',
      modifySelections: 'Por favor, altere as opções e tente novamente.',
      snackbar: {
        success: { resetFilters: 'Seus filtros foram redefinidos.' },
      },
      buttons: {
        resetFilters: 'Redefinir Filtros',
        applyFilters: 'Aplicar Filtros',
      },
    },
    survey: {
      engagementReportComparisonComponent: {
        info: 'Encontre informações específicas para cada métrica quando passar o mouse sobre este ícone.',
      },
    },
    mediaDisplay: {
      snackbar: {
        warning:
          'Não foi possível localizar esta mídia.Por favor, tente novamente.',
      },
    },
    receivedReward: {
      snackbar: {
        success: {
          acceptedReward: 'Você aceitou este benefício!',
          bankedReward: 'Você guardou este benefício!',
          redeemedReward: 'Você resgatou este benefício!',
        },
      },
      getStatusLabels: {
        received: 'recebeu um',
        accepted: 'aceitou este',
        banked: 'guardou este',
        redeemed: 'resgatou este',
      },
      getStatusLabelsTooltip: {
        received:
          'You just received this reward! You can accept it or bank it. Banked rewards will add up to your wallet balance',
        accepted: 'You accepted this reward. Make sure you redeemed it',
        banked:
          'You banked this reward. This reward amount was already added to your wallet balance',
        redeemed:
          'You redeemed this reward! You should have received an e-mail with details about it',
      },
      shareWhyYouBankedIt: 'Share with us why you banked it',
      rewardWasAutomaticallyBanked:
        'Esta recompensa foi automaticamente adicionada ao seu banco',
      rewardStatus: 'Você {status} Benefício!',
      actionButtonLabels: { bank: 'Guardar', accept: 'Aceitar' },
      bankedReason: 'Banked Reason: {reason}',
      youRatedThisReward: 'Você avaliou esta recompensa:',
      rateThisReward: 'Avalie esta recompensa:',
      notAcceptedReasonDialogTitle: 'Você guardou {rewardName}',
      bankedReasonLabel: 'Banked Reason',
      bankedReasonPlaceholder: 'I decided to bank this reward because...',
    },
    purchaseRetailerRewardDialog: {
      title: 'Selecione a Recompensa de Varejista',
      retailerRewardDetails: 'Detalhes da Recompensa',
      amountToSpend: 'Quantidade que deseja gastar',
      maxAmountMessage: 'Quantidade não pode ser maior que {amount}',
      searchRewards: 'Pesquisar Recompensas',
      noRewards:
        'Não há Recompensas. Certifique-se de haver informado uma quantidade no campo acima.',
      purchaseFromBrand:
        'Deseja comprar um gift card no valor de {amount} de {brand}?',
      confirmPurchase: 'Confirmar Compra',
      snackbar: {
        giftCardPurchased: 'Seu gift card foi adquirido com sucesso!',
      },
    },
    reportInappropriateContent: {
      snackbar: {
        success: {
          reportSent:
            'Sua denúncia foi enviada a nossa equipe moderadora com sucesso.',
        },
      },
      header: 'Denunciar',
      question: {
        firstLine: 'O que você encontrou neste(a)',
        secondLine: 'que é inapropriado?',
      },
      contentLabels: {
        text: 'Possui Texto Inapropriado',
        image: 'Possui Imagem Inapropriada',
        video: 'Possui Vídeo Inapropriado',
      },
      report: 'Denunciar',
    },
    adminCompaniesSelect: { textFieldLabel: 'Organização como Admin' },
    adminMode: 'Modo Admin',
    dropzone:
      'Arraste e solte alguns arquivos aqui, ou clique para selecionar arquivos',
    errorBoundary: {
      somethingHappened: 'Bem, isso foi inesperado.',
      unexpectedError:
        'Sabemos que isso não é o que você esperava, mas você acabou de encontrar um bug e está melhorando nosso sistema. Nossa equipe foi notificada e devemos ter tudo o que precisamos para corrigir esse problema rapidamente. No entanto, se você quiser entrar em contato com nossa equipe de suporte, basta enviar um e-mail para {email}. Caso contrário, você pode clicar no botão abaixo e nós o levaremos a um lugar livre de bugs... esperamos.',
      reloadThisPage: 'Leve-me para outra página',
    },
    loadMoreButton: { loadMore: 'Carregar mais', remaining: 'restante(s)...' },
    passwordRules: {
      lowercaseLetter: 'uma letra minúscula',
      capitalLetter: 'uma letra maiúscula',
      number: 'um número',
      atLeastEightCharacters: 'pelo menos 8 caracteres',
      specialCharacter: 'caractere especial (+, #, !, ? or @)',
      passwordMustContain: 'A senha deve conter:',
    },
    profileAutocompleteQuestions: {
      placeholder: 'Por favor, envie uma resposta de cada vez...',
      save: 'Salvar',
      dialogTitle: 'Remover Resposta',
      dialogContent: 'Tem certeza que deseja remover',
      removeAnswer: 'Remover Resposta',
      legendLabels: {
        managers: 'Respostas dos Gerentes',
        employees: 'Respostas dos Funcionários',
      },
      listLabel: 'questões',
    },
    recognitionsByEvent: {
      noData: 'Não há dados a serem mostrados no momento.',
    },
    termsAndConditions: {
      dialogTitle: 'Sparck Terms and Conditions',
      okButton: 'Ok, entendi',
    },
    upgradeCostEstimate: {
      contactUs: 'Entre em contato para uma cotação customizada',
      estimateYourCost: 'Faça uma Estimativa do seu Custo de Upgrade',
      numberOfEmployees:
        'Quantos funcionários você possui? (Deslize a barra até alcançar a quantidade desejada).',
      employees: 'funcionários',
      estimatedReportCost: 'Custo Estimado do Relatório',
    },
    reportComparison: {
      features: 'Funcionalidades',
      freeReport: 'Relatório\nGratuito',
      premiumReport: 'Relatório\nPremium',
      companyEngagementScore: {
        title: 'Pontuação de Engajamento da Organização',
        tooltip:
          'Até que ponto os funcionários são apaixonados por seu trabalho, estão comprometidos com nossa organização, colocam esforço discricionário em seu trabalho e estão dispostos a realizar recomendações. Os resultados podem ser facilmente filtrados por dados demográficos do local de trabalho.',
      },
      participationRate: { title: 'Taxa de Participação' },
      customQuestions: { title: 'Perguntas Customizadas' },
      shortTermTurnover: {
        title: 'Turnover a Curto Prazo',
        tooltip:
          'Com base em seus resultados, você pode obter uma noção sobre a rotatividade potencial nos próximos 12 meses. Os resultados podem ser facilmente filtrados por dados demográficos do local de trabalho.',
      },
      longTermTurnover: {
        title: 'Turnover a Longo Prazo',
        tooltip:
          'Com base em seus resultados, você pode ter uma ideia do quanto seus funcionários estão comprometidos em construir suas carreiras com você a longo prazo. Os resultados podem ser facilmente filtrados por dados demográficos do local de trabalho.',
      },
      filters: {
        title: 'Filtros',
        tooltip:
          'Use filtros demográficos para analisar dados, incluindo motivadores de engajamento, rotatividade, áreas de ação, pontos fortes e itens mais importantes.',
      },
      collaborationEffectivenessScore: {
        title: 'Pontuação de Eficácia de Colaboração',
        tooltip:
          'Até que ponto a organização promove um estilo de trabalho baseado em equipe (ou seja, departamentos ou grupos trabalham bem juntos).',
      },
      jobFitEffectivenessScore: {
        title: 'Pontuação de Eficácia em Adequado ao Trabalho',
        tooltip:
          'Até que ponto os funcionários sentem que estão nas funções certas e têm as habilidades, conhecimentos e habilidades necessárias para desempenhar adequadamente suas funções.',
      },
      employeeAppreciationScore: {
        title: 'Pontuação de Apreciação do Funcionário',
        tooltip:
          'Até que ponto os funcionários se sentem valorizados pela organização. Os resultados podem ser facilmente filtrados por dados demográficos do local de trabalho.',
      },
      organizationalAlignmentScore: {
        title: 'Pontuação de Alinhamento Organizacional',
        tooltip:
          'Até que ponto os funcionários acreditam e se esforçam para cumprir a missão, os valores e as metas da organização.',
      },
      growthEffectivenessScore: {
        title: 'Pontuação de Eficácia de Crescimento',
        tooltip:
          'Até que ponto os funcionários se sentem apoiados para avançar em suas carreiras ou desenvolver conjuntos de habilidades pessoais dentro da organização. Os resultados podem ser facilmente filtrados por dados demográficos do local de trabalho.',
      },
      topFiveMostImportantAreas: {
        title: 'Cinco Principais Áreas mais Importantes',
        tooltip:
          'Essas são as áreas de engajamento classificadas como mais importantes para seus funcionários. Os resultados podem ser facilmente filtrados por dados demográficos do local de trabalho.',
      },
      topFiveFocusAreasForGreatestRoi: {
        title: 'Cinco Principais Áreas de Foco para o Maior ROI',
        tooltip:
          'Essas são as áreas nas quais você deve se concentrar para obter o maior retorno de seu investimento e aumentar o engajamento. Essas são as áreas de engajamento mais importantes para seus funcionários, onde eles sentem que a organização é menos eficiente. Os resultados podem ser facilmente filtrados por dados demográficos do local de trabalho.',
      },
      topFiveCompanyStrengths: {
        title: 'Cinco Principais Pontos Fortes da Organização',
        tooltip:
          'Essas são as áreas em que você é mais forte, ótimos dados para construir sua marca de empregador. Essas são as áreas de engajamento mais importantes para seus funcionários e onde eles também sentiram que você era mais eficiente. Os resultados podem ser facilmente filtrados por dados demográficos do local de trabalho.',
      },
      actionableRecommendationsOrganizationAndManagers: {
        title: 'Ações Recomendadas (Empresa e Gestores)',
        tooltip:
          'Com base em suas cinco principais áreas de foco para obter o maior ROI de engajamento, uma equipe de psicólogos de trabalho adotou estratégias comprovadas para personalizar cada recomendação organizacional e de gerenciamento. Os resultados podem ser facilmente filtrados por dados demográficos do local de trabalho.',
      },
    },
    employeesUploadComponent: {
      warningMessage:
        'Foi possível importar somente {importedEmployees} funcionários. Por favor, corrija os erros abaixo e tente novamente.',
      errorContainerMessage:
        'Para ver quais campos são obrigatórios ou o formato correto para qualquer campo, acesse nossa planilha no link acima.',
      errorTable: {
        columns: {
          row: 'Linha',
          missingValues: 'Não Informados',
          invalidFormat: 'Formato Inválido',
        },
      },
      employeeUploadFields: {
        companyInternalId: 'ID do Funcionário',
        cellNumber: 'Celular',
        title: 'Título',
        hiredAt: 'Data de Admissão',
        gender: 'Gênero',
        ethnicity: 'Etnia',
        dateOfBirth: 'Data de Nascimento',
        jobType: 'Jornada de Trabalho',
        businessUnit: 'Unidade de Negócios',
        region: 'Região',
        territory: 'Território',
        locationName: 'Local',
        city: 'Cidade',
        state: 'Estado/Província',
        country: 'País',
        postalCode: 'CEP',
        performanceLevel: 'Nível de Performance',
        highValueTalent: 'Talento de Alto Valor',
        roles: 'Responsabilidades',
        superiors: 'Supervisores',
        preferredLanguage: 'Idioma',
      },
    },
    mediaPicker: {
      addMedia: 'Adicionar Imagem/Vídeo',
      addGif: 'Adicionar GIF',
      addCard: 'Adicionar Cartão',
      dragAndDrop: 'Arraste e solte imagens e vídeos ou clique aqui!',
      pasteLink: 'ou cole o link de uma imagem/video:',
      pasteLinkPlaceholder: 'e.g. imagem, youtube, vimeo, etc',
      verify: 'Verificar',
      sparckCardsDialog: {
        title: 'Cartões Sparck',
        noCards: 'Não há cartões disponíveis.',
      },
    },
    demographicsPicker: {
      add: 'Adicionar',
      additional: 'Outro',
      demographic: 'Dado Demográfico',
    },
    popupSteps: {
      gotIt: 'Entendi!',
      next: 'Próximo',
      dontShowAgain: 'Não mostre de novo',
      back: 'Voltar',
    },
    phoneNumberInputWithFormatPicker: {
      chooseCountry: 'Escolha um país',
    },
    confirmationDialog: {
      delete: 'archive',
      defaultMessage: 'Tem certeza de que deseja {action} {item}?',
      launchBeheardSurveyMessage:
        'Você está prestes a lançar sua pesquisa e enviar um email convidando cada funcionário a participar. Por favor, certifique-se de ter adicionado todos os funcionários participantes antes de lançar a pesquisa clicando {link}. Se você estiver pronto para fazer o lançamento, clique no botão Lançar Pesquisa abaixo.',
      launchPulseSurveyMessage:
        'Você está prestes a lançar sua pesquisa e enviar um email convidando cada funcionário a participar. Se você estiver pronto para fazer o lançamento, clique no botão Lançar Pesquisa Pulse abaixo.',
    },
  },
}
