export const DEV = 'dev'
export const HEROKU = 'heroku'
export const PROD = 'prod'

// export const DEV_HOSTNAME = 'my.testing.sparckco.com'
export const DEV_HOSTNAME = ['localhost', '192.168.0.22']
export const HEROKU_HOSTNAME = 'sparck-dev.herokuapp.com'
export const PROD_HOSTNAME = 'my.sparckco.com'

// export const DEV_BASE_URL = 'https://api.testing.sparckco.com/v1/'
export const DEV_BASE_URL = 'https://sparck-dev-api.herokuapp.com/v1/'
export const HEROKU_BASE_URL = 'https://sparck-dev-api.herokuapp.com/v1/'
export const PROD_BASE_URL = 'https://api.sparckco.com/v1/'

export const hostname = window && window.location && window.location.hostname

const getEnvironment = () => {
  if (DEV_HOSTNAME.includes(hostname)) return [DEV, DEV_BASE_URL]
  if (hostname === HEROKU_HOSTNAME) return [HEROKU, HEROKU_BASE_URL]
  if (hostname === PROD_HOSTNAME) return [PROD, PROD_BASE_URL]

  return [PROD, PROD_BASE_URL]
}

export const isDevEnvironment =
  getEnvironment()[0] === DEV || getEnvironment()[0] === HEROKU

export const getEnvironmentName = () =>
  isDevEnvironment ? 'development' : 'production'

export const [ENV, API_BASE_URL] = getEnvironment()
