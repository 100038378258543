import { FormattedMessage, useIntl } from 'react-intl'

const t = (id, value = {}) => <FormattedMessage id={id} values={{ ...value }} />

export const useTranslation = (id, values = {}): any => {
  const intl = useIntl()

  return intl.formatMessage({ id: id }, { ...values })
}

export default t
2
