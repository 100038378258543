import { Typography } from '@mui/material'

import { DialogTeleport } from 'components'
import { initialDialog } from 'constants/dialog'
import { t } from 'i18n'

export default function InviteShareReport({ dialog, setDialog, invite }) {
  return (
    <DialogTeleport
      dialogTitle={t('inviteShareReport.dialogTitle')}
      dialogAction={{
        label: t('inviteShareReport.dialogActionLabel'),
        function: invite,
      }}
      dialogSize='sm'
      dialogOpen={dialog.isOpen}
      handleDialogClose={() => setDialog(initialDialog)}
      isFullWidth
    >
      <Typography variant='body1'>
        {t('inviteShareReport.clickToInvite')}
      </Typography>
    </DialogTeleport>
  )
}
