import { useState, useEffect } from 'react'

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  useTheme,
} from '@mui/material'
import { isEmpty } from 'lodash'

import { PulseSurveyQuestionItem } from '../../components'
import { PulseSurveyQuestionType } from 'enums/pulseSurvey'
import { t } from 'i18n'

const QUESTIONS_PER_PAGE = 5

const satisfactionIdToValue = {
  not_applicable: 'not_applicable',
  strongly_disagree: 1,
  disagree: 2,
  neutral: 3,
  agree: 4,
  strongly_agree: 5,
}

export default function PulseSurveyQuestion({
  pulseSurvey: pulseSurveyData,
  finishPulseSurvey,
  finishPulseSurveyStatus,
  answerPulseSurvey,
  preview,
}) {
  const [answers, setAnswers] = useState<any>([])
  const [page, setPage] = useState<number>(1)

  const theme: any = useTheme()

  const pulseSurvey = preview ? preview : pulseSurveyData

  useEffect(() => {
    if (pulseSurvey) {
      if (isEmpty(pulseSurvey.answers)) {
        setAnswers(
          pulseSurvey.questions_attributes.map(question => ({
            survey_id: pulseSurvey.id,
            question_id: question.id,
            ...(question.type !== PulseSurveyQuestionType.OpenEnded &&
              question.type !==
                PulseSurveyQuestionType.OpenEndedAndImportance && {
                satisfaction: null,
              }),
            ...((question.type ===
              PulseSurveyQuestionType.SatisfactionAndImportance ||
              question.type ===
                PulseSurveyQuestionType.OpenEndedAndImportance) && {
              importance: null,
            }),
            ...((question.type === PulseSurveyQuestionType.OpenEnded ||
              question.type ===
                PulseSurveyQuestionType.OpenEndedAndImportance) && {
              open_ended_text: '',
            }),
            anonymous: question.anonymous,
          })),
        )
      } else {
        setAnswers(
          pulseSurvey.answers.map(answer => ({
            ...answer,
            satisfaction: answer.satisfaction
              ? satisfactionIdToValue[answer.satisfaction]
              : null,
          })),
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pulseSurvey.answers])

  const getAnswer = question =>
    answers.find(answer => answer.question_id === question.id)

  const paginateQuestions = (_: any, index: number) =>
    index >= (page - 1) * QUESTIONS_PER_PAGE &&
    index < (page - 1) * QUESTIONS_PER_PAGE + QUESTIONS_PER_PAGE

  return (
    <Paper>
      <Box
        py={2}
        px={3}
        width={1}
        style={{
          background: `linear-gradient(135deg, ${theme.palette.secondary.dark}, ${theme.palette.secondary.light})`,
        }}
      >
        <Typography variant='h6' color='text.white' fontWeight={700}>
          {preview && !pulseSurvey.name ? 'Survey Preview' : pulseSurvey.name}
        </Typography>
      </Box>
      <Box p={3}>
        {!isEmpty(answers) &&
          pulseSurvey.questions_attributes
            .filter(paginateQuestions)
            .map(question => {
              const answer = getAnswer(question)
              const index = pulseSurvey.questions_attributes?.findIndex(
                q => q.id === question.id,
              )

              return (
                <PulseSurveyQuestionItem
                  key={index}
                  index={index}
                  question={question}
                  answer={answer}
                  answers={answers}
                  setAnswers={setAnswers}
                />
              )
            })}

        {!isEmpty(answers) && (
          <Box mt={5} width={1}>
            <Grid container justifyContent='space-between'>
              <Button disabled={page === 1} onClick={() => setPage(page - 1)}>
                {t('pulseSurvey.previous')}
              </Button>

              <Button
                data-cy='next_page'
                color='primary'
                variant='contained'
                onClick={() => {
                  if (!preview) {
                    answerPulseSurvey({
                      id: pulseSurvey.id,
                      answers: answers,
                    })
                  }

                  if (
                    page <
                    +(
                      pulseSurvey.questions_attributes.length /
                      QUESTIONS_PER_PAGE
                    )
                  ) {
                    setPage(page + 1)
                  } else if (!preview) {
                    finishPulseSurvey({
                      id: pulseSurvey.id,
                      answers: answers,
                    })
                  }
                }}
                {...(!preview && {
                  disabled:
                    finishPulseSurveyStatus.isLoading ||
                    !isEmpty(
                      pulseSurvey.questions_attributes
                        .filter(paginateQuestions)
                        .filter(
                          question =>
                            (question.type ===
                              PulseSurveyQuestionType.OpenEnded &&
                              !getAnswer(question).open_ended_text) ||
                            (![
                              PulseSurveyQuestionType.OpenEnded,
                              PulseSurveyQuestionType.OpenEndedAndImportance,
                            ].includes(question.type) &&
                              !getAnswer(question).satisfaction) ||
                            (question.type ===
                              PulseSurveyQuestionType.SatisfactionAndImportance &&
                              !getAnswer(question).importance) ||
                            (question.type ===
                              PulseSurveyQuestionType.OpenEndedAndImportance &&
                              (!getAnswer(question).importance ||
                                !getAnswer(question).open_ended_text)),
                        ),
                    ),
                })}
              >
                {finishPulseSurveyStatus.isLoading ? (
                  <CircularProgress size={24} />
                ) : page <
                  +(
                    pulseSurvey.questions_attributes.length / QUESTIONS_PER_PAGE
                  ) ? (
                  t('pulseSurvey.next')
                ) : (
                  t('pulseSurvey.finish')
                )}
              </Button>
            </Grid>
          </Box>
        )}
      </Box>
    </Paper>
  )
}
