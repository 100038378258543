import { Grid } from '@mui/material'

import {
  ManagerCertification5DayRoadMap,
  ManagerCertificationCelebrationBoard,
  ManagerCertificationEmployeeRecognition,
  ManagerCertificationLeaderboard,
  ManagerCertificationSection,
  ManagerCertificationSneakPeek,
  ManagerCertificationSparckStory,
  ManagerCertificationTasks,
} from '../../components'

type Props = {
  roadmap: any
  userCertificationBoards: any
  fetchUserCertificationBoards(): any
  userCertification: any
  fetchUserCertification(): any
}

export default function ManagerCertificationComponent(props: Props) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item>
          <ManagerCertificationEmployeeRecognition />
        </Grid>

        <Grid item>
          <ManagerCertificationSparckStory />
        </Grid>

        <Grid item>
          <ManagerCertificationSneakPeek />
        </Grid>

        <Grid item xs={12}>
          <ManagerCertification5DayRoadMap />
        </Grid>

        <Grid item>
          <ManagerCertificationSection>
            <ManagerCertificationTasks {...props} />
          </ManagerCertificationSection>
        </Grid>

        <Grid item>
          <ManagerCertificationSection>
            <ManagerCertificationCelebrationBoard {...props} />
          </ManagerCertificationSection>
        </Grid>

        <Grid item xs={12}>
          <ManagerCertificationSection>
            <ManagerCertificationLeaderboard {...props} />
          </ManagerCertificationSection>
        </Grid>
      </Grid>
    </>
  )
}
