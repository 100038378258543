import { useState } from 'react'

import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import {
  AssessmentOutlined as AssessmentIcon,
  CloseOutlined as DeleteIcon,
  PaymentOutlined as PaymentIcon,
} from '@mui/icons-material'
import { get } from 'lodash'
import { useIntl } from 'react-intl'

import { ConfirmationDialog } from 'components'
import { initialDialog } from 'constants/dialog'
import { useUser } from 'contexts'
import { UserPermission } from 'enums/user'
import { t } from 'i18n'
import { formatToUSD } from 'utils/currency'

export default function ShoppingCartComponent({
  shoppingCart,
  theme,
  handleProceedToCheckout,
  totalAmount,
  handleRemoveItemFromCart,
}) {
  const { user } = useUser()!
  const intl = useIntl()

  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('md'),
    {
      defaultMatches: true,
    },
  )

  const shoppingCartItems = get(shoppingCart, 'cart.items', [])

  const [dialogShoppingCart, setDialogShoppingCart] =
    useState<any>(initialDialog)
  const [removeItemFromCartIsLoading, setRemoveItemFromCartIsLoading] =
    useState(true)

  const removeItemFromCart = item => {
    setDialogShoppingCart({ isOpen: true, data: item })
    setRemoveItemFromCartIsLoading(false)
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box my={2} mb={3} width={1}>
            <Typography
              variant='h5'
              color='text.primary'
              fontWeight={500}
              paragraph
            >
              {t('shoppingCart.yourShoppingCartItems')}
            </Typography>
          </Box>
        </Grid>

        {shoppingCartItems.map(item => (
          <Grid container key={item} alignItems='center'>
            <Grid item xs={12}>
              <Paper>
                <Box p={2} mb={1}>
                  <Grid
                    container
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Typography variant='body2' color='text.secondary'>
                      <Grid container alignItems='center'>
                        <Box mr={1}>
                          <AssessmentIcon
                            color='primary'
                            style={{
                              marginRight: 8,
                              marginBottom: isMobile ? 8 : 0,
                            }}
                          />
                        </Box>
                        {t('shoppingCart.productTitle', {
                          productName: item.product.name,
                          itemToUpgradeName: item.product.item_to_upgrade.name,
                          employeesQuantity: `${
                            item.product.number_of_employees
                          } ${
                            item.product.number_of_employees <= 1
                              ? intl.formatMessage({
                                  id: 'shoppingCart.employee',
                                })
                              : intl.formatMessage({
                                  id: 'shoppingCart.employees',
                                })
                          }`,
                        })}
                        :
                      </Grid>
                    </Typography>

                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='space-between'
                      width={isMobile ? '100%' : 'auto'}
                    >
                      <Typography variant='body2' color='text.secondary'>
                        {formatToUSD(item.product.amount)}
                      </Typography>
                      {(user.permissions ?? []).includes(
                        UserPermission.ManageShoppingCarts,
                      ) && (
                        <Tooltip title={t('shoppingCart.removeFromCart')}>
                          <IconButton
                            aria-describedby='Remove from cart'
                            type='button'
                            onClick={() => removeItemFromCart(item)}
                            style={{ marginLeft: isMobile ? 0 : 16 }}
                            size='large'
                          >
                            <DeleteIcon color='error' />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        ))}

        {shoppingCartItems.length > 0 ? (
          <Grid container alignItems='center' spacing={2}>
            <Grid item xs={12}>
              <Box
                p={3}
                width={1}
                style={{ backgroundColor: theme.palette.grey[200] }}
              >
                <Grid
                  container
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Typography variant='body2' color='text.secondary'>
                    {t('shoppingCart.total')}:{' '}
                    <Typography
                      variant='h6'
                      fontWeight={700}
                      color='primary.main'
                    >
                      {formatToUSD(totalAmount)}
                    </Typography>
                  </Typography>

                  {(user.permissions ?? []).includes(
                    UserPermission.ManageShoppingCarts,
                  ) && (
                    <Button
                      variant='contained'
                      color='primary'
                      size='large'
                      onClick={handleProceedToCheckout}
                    >
                      <PaymentIcon style={{ marginRight: 8 }} />{' '}
                      {t('shoppingCart.proceedToPayment')}
                    </Button>
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Typography variant='body2' color='text.secondary'>
            {t('shoppingCart.noItems')}
          </Typography>
        )}
      </Grid>

      <ConfirmationDialog
        dialogOpen={dialogShoppingCart.isOpen}
        setDialogOpen={setDialogShoppingCart}
        message={t('components.confirmationDialog.defaultMessage', {
          action: t('components.confirmationDialog.delete'),
          item: dialogShoppingCart?.data?.product.item_to_upgrade?.name,
        })}
        item={dialogShoppingCart?.data?.product}
        actionProps={{
          onClick: () => {
            handleRemoveItemFromCart({
              object_name_to_upgrade: 'Survey',
              object_id_to_upgrade: get(
                dialogShoppingCart,
                'data.product.item_to_upgrade.id',
              ),
            })

            setDialogShoppingCart(initialDialog)
          },
          isLoading: removeItemFromCartIsLoading,
          label: t('components.confirmationDialog.delete'),
          color: 'error',
        }}
      />
    </>
  )
}
