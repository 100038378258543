import React from 'react'

import { IconButton, CssBaseline, Theme } from '@mui/material'
import { ThemeProvider as MaterialProvider } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import {
  CheckCircle as CheckIcon,
  Close as CloseIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Warning as WarningIcon,
} from '@mui/icons-material'

import { useDarkMode } from 'contexts'
import ApplicationRequest from 'core/ApplicationRequest'
import { SnackbarUtilsConfigurator } from 'core/SnackbarConfiguration'
import { SnackbarProvider } from 'notistack'
import { materialTheme } from 'theme/material'
import GlobalStyle from 'theme/material/GlobalStyle'
declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const useStyles = makeStyles({
  success: { backgroundColor: '#ceffe8 !important' },
  error: { backgroundColor: '#ffcece !important' },
  warning: { backgroundColor: '#fff6ce !important' },
  info: { backgroundColor: '#ceefff !important' },
})

const snackbarIcons = {
  success: CheckIcon,
  error: ErrorIcon,
  warning: WarningIcon,
  info: InfoIcon,
}

const snackbarIconsColors = {
  success: '#4dd8a3',
  error: '#F0555A',
  warning: '#d8be4d',
  info: '#55b2f0',
}

export default function ApplicationStyle() {
  const notistackRef: any = React.createRef()

  const classes = useStyles()
  const { darkMode } = useDarkMode()!

  const onClickDismiss = key => () => {
    // @ts-ignore
    notistackRef.current.closeSnackbar(key)
  }

  const getSnackbarIcon = iconVariant => {
    const Icon = snackbarIcons[iconVariant]
    return (
      <Icon
        style={{
          fontSize: 32,
          marginRight: 16,
          color: snackbarIconsColors[iconVariant],
        }}
      />
    )
  }

  return (
    <CssBaseline>
      <MaterialProvider theme={materialTheme(darkMode)}>
        <GlobalStyle />
        <SnackbarProvider
          preventDuplicate
          maxSnack={3}
          data-cy='snackbarGlobal'
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          ref={notistackRef}
          classes={{
            variantSuccess: classes.success,
            variantError: classes.error,
            variantWarning: classes.warning,
            variantInfo: classes.info,
          }}
          iconVariant={{
            success: getSnackbarIcon('success'),
            error: getSnackbarIcon('error'),
            warning: getSnackbarIcon('warning'),
            info: getSnackbarIcon('info'),
          }}
          action={key => (
            <IconButton onClick={onClickDismiss(key)} size='large'>
              <CloseIcon style={{ color: '#555' }} />
            </IconButton>
          )}
        >
          <SnackbarUtilsConfigurator />
          <ApplicationRequest />
        </SnackbarProvider>
      </MaterialProvider>
    </CssBaseline>
  )
}
