export const INDUSTRIES = [
  { id: 1, name: 'industries.accounting' },
  { id: 2, name: 'industries.airlinesAviation' },
  { id: 3, name: 'industries.alternativeDisputeResolution' },
  { id: 4, name: 'industries.alternativeMedicine' },
  { id: 5, name: 'industries.animation' },
  { id: 6, name: 'industries.apparelFashion' },
  { id: 7, name: 'industries.architecturePlanning' },
  { id: 8, name: 'industries.artsCrafts' },
  { id: 9, name: 'industries.automotive' },
  { id: 10, name: 'industries.aviationAerospace' },
  { id: 11, name: 'industries.banking' },
  { id: 12, name: 'industries.biotechnology' },
  { id: 13, name: 'industries.broadcastMedia' },
  { id: 14, name: 'industries.buildingMaterials' },
  { id: 15, name: 'industries.businessSuppliesEquipment' },
  { id: 16, name: 'industries.cannabis' },
  { id: 17, name: 'industries.capitalMarkets' },
  { id: 18, name: 'industries.chemicals' },
  { id: 19, name: 'industries.civicSocialOrganization' },
  { id: 20, name: 'industries.civilEngineering' },
  { id: 21, name: 'industries.commercialRealEstate' },
  { id: 22, name: 'industries.computerNetworkSecurity' },
  { id: 23, name: 'industries.computerGames' },
  { id: 24, name: 'industries.computerHardware' },
  { id: 25, name: 'industries.computerNetworking' },
  { id: 26, name: 'industries.computerSoftware' },
  { id: 27, name: 'industries.construction' },
  { id: 28, name: 'industries.consumerElectronics' },
  { id: 29, name: 'industries.consumerGoods' },
  { id: 30, name: 'industries.consumerServices' },
  { id: 31, name: 'industries.cosmetics' },
  { id: 32, name: 'industries.dairy' },
  { id: 33, name: 'industries.defenseSpace' },
  { id: 34, name: 'industries.design' },
  { id: 35, name: 'industries.eLearning' },
  { id: 36, name: 'industries.educationManagement' },
  { id: 37, name: 'industries.electricalElectronicManufacturing' },
  { id: 38, name: 'industries.entertainment' },
  { id: 39, name: 'industries.environmentalServices' },
  { id: 40, name: 'industries.eventServices' },
  { id: 41, name: 'industries.executiveOffice' },
  { id: 42, name: 'industries.facilitiesServices' },
  { id: 43, name: 'industries.farming' },
  { id: 44, name: 'industries.financialServices' },
  { id: 45, name: 'industries.fineArt' },
  { id: 46, name: 'industries.fishery' },
  { id: 47, name: 'industries.foodBeverages' },
  { id: 48, name: 'industries.foodProduction' },
  { id: 49, name: 'industries.fundraising' },
  { id: 50, name: 'industries.furniture' },
  { id: 51, name: 'industries.gamblingCasinos' },
  { id: 52, name: 'industries.glassCeramicsConcrete' },
  { id: 53, name: 'industries.governmentAdministration' },
  { id: 54, name: 'industries.governmentRelations' },
  { id: 55, name: 'industries.graphicDesign' },
  { id: 56, name: 'industries.healthWellnessFitness' },
  { id: 57, name: 'industries.higherEducation' },
  { id: 58, name: 'industries.hospitalHealthCare' },
  { id: 59, name: 'industries.hospitality' },
  { id: 60, name: 'industries.humanResources' },
  { id: 61, name: 'industries.importExport' },
  { id: 62, name: 'industries.individualFamilyServices' },
  { id: 63, name: 'industries.industrialAutomation' },
  { id: 64, name: 'industries.informationServices' },
  { id: 65, name: 'industries.informationTechnologyServices' },
  { id: 66, name: 'industries.insurance' },
  { id: 67, name: 'industries.internationalAffairs' },
  { id: 68, name: 'industries.internationalTradeDevelopment' },
  { id: 69, name: 'industries.internet' },
  { id: 70, name: 'industries.investmentManagement' },
  { id: 71, name: 'industries.investmentBanking' },
  { id: 72, name: 'industries.judiciary' },
  { id: 73, name: 'industries.lawEnforcement' },
  { id: 74, name: 'industries.lawPractice' },
  { id: 75, name: 'industries.legalServices' },
  { id: 76, name: 'industries.legislativeOffice' },
  { id: 77, name: 'industries.leisureTravelTourism' },
  { id: 78, name: 'industries.libraries' },
  { id: 79, name: 'industries.logisticsSupplyChain' },
  { id: 80, name: 'industries.luxuryGoodsJewelry' },
  { id: 81, name: 'industries.machinery' },
  { id: 82, name: 'industries.managementConsulting' },
  { id: 83, name: 'industries.maritime' },
  { id: 84, name: 'industries.marketResearch' },
  { id: 85, name: 'industries.marketingAdvertising' },
  { id: 86, name: 'industries.mechanicalOrIndustrialEngineering' },
  { id: 87, name: 'industries.mediaProduction' },
  { id: 88, name: 'industries.medicalDevice' },
  { id: 89, name: 'industries.medicalPractice' },
  { id: 90, name: 'industries.mentalHealthCare' },
  { id: 91, name: 'industries.military' },
  { id: 92, name: 'industries.miningMetals' },
  { id: 93, name: 'industries.motionPicturesFilm' },
  { id: 94, name: 'industries.museumsInstitutions' },
  { id: 95, name: 'industries.music' },
  { id: 96, name: 'industries.nanotechnology' },
  { id: 97, name: 'industries.newspapers' },
  { id: 98, name: 'industries.nonProfitManagement' },
  { id: 99, name: 'industries.oilEnergy' },
  { id: 100, name: 'industries.onlineMedia' },
  { id: 101, name: 'industries.outsourcingOffshoring' },
  { id: 102, name: 'industries.packageFreightDelivery' },
  { id: 103, name: 'industries.packagingContainers' },
  { id: 104, name: 'industries.paperForestProducts' },
  { id: 105, name: 'industries.performingArts' },
  { id: 106, name: 'industries.pharmaceuticals' },
  { id: 107, name: 'industries.philanthropy' },
  { id: 108, name: 'industries.photography' },
  { id: 109, name: 'industries.plastics' },
  { id: 110, name: 'industries.politicalOrganization' },
  { id: 111, name: 'industries.primarySecondaryEducation' },
  { id: 112, name: 'industries.printing' },
  { id: 113, name: 'industries.professionalTrainingCoaching' },
  { id: 114, name: 'industries.programDevelopment' },
  { id: 115, name: 'industries.publicPolicy' },
  { id: 116, name: 'industries.publicRelationsCommunications' },
  { id: 117, name: 'industries.publicSafety' },
  { id: 118, name: 'industries.publishing' },
  { id: 119, name: 'industries.railroadManufacturer' },
  { id: 120, name: 'industries.ranching' },
  { id: 121, name: 'industries.realEstate' },
  { id: 122, name: 'industries.recreationalFacilitiesServices' },
  { id: 123, name: 'industries.religiousInstitutions' },
  { id: 124, name: 'industries.renewablesEnvironment' },
  { id: 125, name: 'industries.research' },
  { id: 126, name: 'industries.restaurants' },
  { id: 127, name: 'industries.retail' },
  { id: 128, name: 'industries.securityInvestigations' },
  { id: 129, name: 'industries.semiconductors' },
  { id: 130, name: 'industries.shipbuilding' },
  { id: 131, name: 'industries.sportingGoods' },
  { id: 133, name: 'industries.staffingRecruiting' },
  { id: 134, name: 'industries.supermarkets' },
  { id: 135, name: 'industries.telecommunications' },
  { id: 136, name: 'industries.textiles' },
  { id: 137, name: 'industries.thinkTanks' },
  { id: 138, name: 'industries.tobacco' },
  { id: 139, name: 'industries.translationLocalization' },
  { id: 140, name: 'industries.transportationTruckingRailroad' },
  { id: 141, name: 'industries.utilities' },
  { id: 142, name: 'industries.ventureCapitalPrivateEquity' },
  { id: 143, name: 'industries.veterinary' },
  { id: 144, name: 'industries.warehousing' },
  { id: 145, name: 'industries.wholesale' },
  { id: 146, name: 'industries.wineSpirits' },
  { id: 147, name: 'industries.wireless' },
  { id: 148, name: 'industries.writingEditing' },
]
