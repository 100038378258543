import { useState, useEffect } from 'react'

import {
  Send as SendIcon,
  Clear as CancelIcon,
  Check as CheckIcon,
} from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { alpha } from '@mui/material/styles'
import { formatRelative, parseISO } from 'date-fns'
import { useIntl } from 'react-intl'
import { UseMutationResult } from 'react-query'

import { useCompany, useUser } from 'contexts'
import { TOPBAR_GREEN_HEIGHT, TOPBAR_HEIGHT } from 'constants/app'
import { getSuggestionsStatusDetails } from 'constants/suggestions'
import { SuggestionStatus } from 'enums/suggestion'
import { t } from 'i18n'
import { userIsCompanyAdmin } from 'utils/user'

type Props = {
  fromDialog?: boolean
  setSuggestionAcceptDialog?: any
  setSuggestionDeclineDialog?: any
  suggestion: any
  suggestionIsLoading: boolean
  sendReplyMutation: UseMutationResult
}

const SUGGESTION_CHAT_TITLE_HEIGHT_EM = 5
const SUGGESTION_CHAT_ACTION_HEIGHT_EM = 4

export default function SuggestionChatComponent(props: Props) {
  const {
    fromDialog,
    setSuggestionAcceptDialog,
    setSuggestionDeclineDialog,
    suggestion,
    suggestionIsLoading,
    sendReplyMutation,
  } = props

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      sx={{
        height: `calc(100vh - ${TOPBAR_HEIGHT}px - ${TOPBAR_GREEN_HEIGHT}px - 24px ${
          fromDialog ? '- 64px' : ''
        })`,
      }}
    >
      <Grid
        item
        xs={12}
        {...(!fromDialog && {
          sm: 10,
          md: 8,
          lg: 7,
          xl: 8,
        })}
        sx={{ height: 1 }}
      >
        <Grid container sx={{ height: 1 }}>
          <Grid item xs={12} sx={{ height: 1 }}>
            <Box
              sx={{
                width: 1,
                height: 1,
                borderRadius: '1em',
                bgcolor: '#EEE',
                py: 2,
                px: 3,
                pt: SUGGESTION_CHAT_TITLE_HEIGHT_EM + 1 + 'em',
                pb: SUGGESTION_CHAT_ACTION_HEIGHT_EM + 1 + 'em',
                position: 'relative',
              }}
            >
              <SuggestionChatHeader
                suggestion={suggestion}
                suggestionIsLoading={suggestionIsLoading}
                setSuggestionAcceptDialog={setSuggestionAcceptDialog}
                setSuggestionDeclineDialog={setSuggestionDeclineDialog}
              />
              {!suggestionIsLoading && (
                <SuggestionChatMessages suggestion={suggestion} />
              )}
              <SuggestionChatAction
                sendReplyMutation={sendReplyMutation}
                suggestionIsLoading={suggestionIsLoading}
              />
              {suggestionIsLoading && <SuggestionChatLoading />}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

function SuggestionChatHeader({
  suggestion,
  suggestionIsLoading,
  setSuggestionAcceptDialog,
  setSuggestionDeclineDialog,
}) {
  const { company } = useCompany()!
  const theme = useTheme()

  const suggestionStatusLabel =
    getSuggestionsStatusDetails(theme)[
      suggestion?.status ?? SuggestionStatus.Pending
    ]?.label
  const suggestionStatusColor =
    getSuggestionsStatusDetails(theme)[
      suggestion?.status ?? SuggestionStatus.Pending
    ]?.color

  return (
    <Paper
      sx={{
        py: 2,
        px: 3,
        width: 1,
        height: `${SUGGESTION_CHAT_TITLE_HEIGHT_EM}em`,
        borderRadius: '1em 1em 0em 0em',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
      }}
    >
      {suggestionIsLoading ? (
        <Skeleton width={'80%'} />
      ) : (
        <Grid
          container
          spacing={1}
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item xs={12} sm>
            <Typography variant='h6' color='text.primary'>
              {company?.suggestion_box_name ?? 'Suggestion'} # {suggestion?.replies.length}
            </Typography>

            {suggestionStatusLabel && (
              <Typography variant='body2' color='text.primary'>
                Status:{' '}
                <Typography
                  component='span'
                  variant='body2'
                  color={suggestionStatusColor ?? 'text.primary'}
                  fontWeight={600}
                >
                  {suggestionStatusLabel}
                </Typography>
              </Typography>
            )}
          </Grid>

          {(setSuggestionAcceptDialog || setSuggestionDeclineDialog) && (
            <Grid item xs='auto' sx={{ display: { xs: 'none', sm: 'flex' } }}>
              <Grid container spacing={1}>
                {suggestion?.status !== SuggestionStatus.Declined && (
                  <IconButton
                    onClick={() =>
                      setSuggestionDeclineDialog({
                        isOpen: true,
                        data: suggestion,
                      })
                    }
                  >
                    <CancelIcon sx={{ color: 'error.main' }} />
                  </IconButton>
                )}

                {suggestion?.status !== SuggestionStatus.Accepted && (
                  <IconButton
                    onClick={() =>
                      setSuggestionAcceptDialog({
                        isOpen: true,
                        data: suggestion,
                      })
                    }
                  >
                    <CheckIcon sx={{ color: 'primary.main' }} />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </Paper>
  )
}

function SuggestionChatMessages({ suggestion }) {
  const { user } = useUser()!

  const suggestionOwnerName = suggestion?.user?.full_name || 'Anonymous'

  return (
    <Box id='chat-box' sx={{ overflowY: 'auto', height: 1 }}>
      <Grid container justifyContent='center'>
        <Grid item>
          <Typography
            variant='body2'
            color='text.light'
            align='center'
            paragraph
          >
            {t('suggestions.whereSuggestionChatBegins')}
          </Typography>
        </Grid>
      </Grid>

      <SuggestionChatMessage
        key={`suggestion-${suggestion.id}`}
        message={suggestion?.description}
        messageCreatedAt={suggestion?.created_at}
        messageUserName={suggestionOwnerName}
        messageIsFromLoggedUser={user?.id === suggestion?.user_id}
      />

      <Box mb={3}>
        <Divider sx={{ borderColor: 'text.veryLight' }} />
      </Box>

      {user?.id &&
        suggestion?.replies.map(reply => {
          const loggedUserIsAdmin = userIsCompanyAdmin(user)
          const currentUser = user?.id
          const suggestionOwner = suggestion?.user_id
          const replyOwner = reply?.user_id

          let messagePosition = 'left'

          if (currentUser === suggestionOwner && replyOwner === currentUser) {
            messagePosition = 'right'
          }

          if (loggedUserIsAdmin && suggestionOwner !== replyOwner) {
            messagePosition = 'right'
          }

          return (
            <SuggestionChatMessage
              key={reply.id}
              message={reply?.body}
              messageCreatedAt={reply?.created_at}
              messageUserName={reply?.user?.full_name || suggestionOwnerName}
              messageIsFromLoggedUser={messagePosition === 'right'}
            />
          )
        })}
    </Box>
  )
}

function SuggestionChatMessage({
  message,
  messageCreatedAt,
  messageUserName,
  messageIsFromLoggedUser,
}) {
  const theme = useTheme()

  return (
    <Grid
      container
      justifyContent={messageIsFromLoggedUser ? 'flex-end' : 'flex-start'}
      sx={{ mb: 3 }}
    >
      <Grid item sx={{ maxWidth: '80%' }}>
        <Box
          sx={{
            borderRadius: `16px 16px ${messageIsFromLoggedUser ? 0 : 16}px ${
              messageIsFromLoggedUser ? 16 : 0
            }px`,
            p: 2,
            width: 1,
            bgcolor: messageIsFromLoggedUser
              ? alpha(theme.palette.primary.main, 0.15)
              : '#FFF',
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant='body1'
                color='text.primary'
                fontWeight={messageIsFromLoggedUser ? 500 : 400}
                paragraph
              >
                {message}
              </Typography>
            </Grid>

            {messageCreatedAt && (
              <Grid item xs={12}>
                <Grid container spacing={1} justifyContent='flex-end'>
                  <Typography variant='body2' color='text.secondary'>
                    {messageUserName} •{' '}
                    {formatRelative(parseISO(messageCreatedAt), new Date())}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

function SuggestionChatAction({ sendReplyMutation, suggestionIsLoading }) {
  const [message, setMessage] = useState('')

  const intl = useIntl()

  useEffect(() => {
    if (message) setMessage('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestionIsLoading])

  return (
    <Paper
      sx={{
        py: 2,
        px: 3,
        width: 1,
        height: `${SUGGESTION_CHAT_ACTION_HEIGHT_EM}em`,
        borderRadius: '0em 0em 1em 1em',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <Grid
        container
        spacing={2}
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item xs>
          <TextField
            id='message'
            data-cy='message'
            name='message'
            value={message}
            onChange={event => setMessage(event.target.value)}
            placeholder={intl.formatMessage({
              id: 'suggestions.typeYourReplyHere',
            })}
            size='small'
            variant='standard'
            fullWidth
          />
        </Grid>

        <Grid item xs='auto'>
          <Button
            size='small'
            variant='contained'
            color='primary'
            disabled={!message}
            onClick={() => {
              sendReplyMutation.mutate(message)
              setMessage('')
            }}
          >
            {sendReplyMutation.isLoading ? (
              <CircularProgress size={16} sx={{ mr: 1, color: '#FFF' }} />
            ) : (
              <SendIcon sx={{ mr: 1 }} />
            )}{' '}
            Send
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

function SuggestionChatLoading() {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress size={64} />
    </Box>
  )
}
