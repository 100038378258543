import { HelpOutline as HelpIcon } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { useIntl } from 'react-intl'

import { CURRENT_POSITIONS } from 'constants/currentPosition'
import { useLocale } from 'contexts'
import { LANGUAGES } from 'constants/locale'
import { GENERATIONS } from 'constants/generation'
import { t } from 'i18n'

export default function SparckAppreciationAssessmentForm({
  formik,
  assessmentIsLoading,
}) {
  const intl = useIntl()
  const { setLocale }: any = useLocale()

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        spacing={2}
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6' color='text.light' fontWeight={500}>
                {t('sparckAppreciationAssessment.labels.contactInfo')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                data-cy='firstName'
                id='first_name'
                name='first_name'
                variant='outlined'
                label={t('firstName')}
                value={formik.values.first_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.first_name && Boolean(formik.errors.first_name)
                }
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                data-cy='lastName'
                id='last_name'
                name='last_name'
                variant='outlined'
                label={t('lastName')}
                value={formik.values.last_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
                helperText={formik.touched.last_name && formik.errors.last_name}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                data-cy='email'
                id='email'
                name='email'
                variant='outlined'
                label={t('sparckAppreciationAssessment.labels.email')}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6' color='text.light' fontWeight={500}>
                <Grid component='span' container alignItems='center'>
                  {t('sparckAppreciationAssessment.labels.demographicInfo')}

                  <Tooltip
                    title={intl.formatMessage({
                      id: 'sparckAppreciationAssessment.labels.demographicInfoTooltip',
                    })}
                  >
                    <HelpIcon sx={{ color: 'text.light', ml: 1 }} />
                  </Tooltip>
                </Grid>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                data-cy='gender'
                id='gender'
                name='gender'
                variant='outlined'
                label={t('sparckAppreciationAssessment.labels.gender')}
                value={formik.values.gender}
                onChange={formik.handleChange}
                error={formik.touched.gender && Boolean(formik.errors.gender)}
                helperText={formik.touched.gender && formik.errors.gender}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                data-cy='generation'
                type='text'
                select
                label={t('sparckAppreciationAssessment.labels.generation')}
                id='generation'
                name='generation'
                variant='outlined'
                value={formik.values.generation}
                onChange={formik.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              >
                {GENERATIONS.map(generation => (
                  <MenuItem
                    key={generation.value}
                    value={generation.value}
                    data-cy='generations'
                  >
                    {t(`${generation.label}`)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                data-cy='companyName'
                id='company_name'
                name='company_name'
                variant='outlined'
                label={t('sparckAppreciationAssessment.labels.companyName')}
                value={formik.values.company_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.company_name &&
                  Boolean(formik.errors.company_name)
                }
                helperText={
                  formik.touched.company_name && formik.errors.company_name
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                data-cy='currentPosition'
                type='text'
                select
                label={t('sparckAppreciationAssessment.labels.currentPosition')}
                id='current_position'
                name='current_position'
                variant='outlined'
                value={formik.values.current_position}
                onChange={formik.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              >
                {CURRENT_POSITIONS.map(position => (
                  <MenuItem
                    key={position.value}
                    value={position.value}
                    data-cy='positions'
                  >
                    {t(`${position.label}`)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                data-cy='preferredLanguage'
                type='text'
                select
                label={t(
                  'sparckAppreciationAssessment.labels.preferredLanguage',
                )}
                id='locale'
                name='locale'
                variant='outlined'
                value={formik.values.locale}
                onChange={formik.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              >
                {LANGUAGES.map(language => (
                  <MenuItem
                    data-cy='languages'
                    key={language.id}
                    value={language.id}
                    onClick={() => {
                      setLocale(language.value)
                    }}
                  >
                    {language.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box
            component='div'
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: 2,
            }}
          >
            <Button
              data-cy='getStarted'
              variant='contained'
              color='primary'
              size='large'
              disabled={formik.errors.email && !assessmentIsLoading}
              type='submit'
            >
              {t('sparckAppreciationAssessment.labels.getStarted')}{' '}
              {assessmentIsLoading && (
                <CircularProgress size={24} style={{ marginLeft: 16 }} />
              )}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}
