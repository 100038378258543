import { Grid, MenuItem, TextField } from '@mui/material'
import { useIntl } from 'react-intl'

import { timezones } from 'constants/company'
import { useUser } from 'contexts'
import { userHasBeseenPermissions } from 'utils/user'

export default function SettingsGeneral({ companyState, setCompanyState }) {
  const { user } = useUser()!
  const intl = useIntl()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md>
        {userHasBeseenPermissions(user) && (
          <TextField
            data-cy='company_purpose'
            id='purpose_statement'
            name='purpose_statement'
            label={intl.formatMessage({
              id: 'settings.formLabels.companyPurpose',
            })}
            placeholder={intl.formatMessage({
              id: 'settings.placeholders.companyPurpose',
            })}
            variant='outlined'
            value={companyState.purpose_statement}
            onChange={event =>
              setCompanyState({
                ...companyState,
                purpose_statement: event.target.value,
              })
            }
            fullWidth
            margin='normal'
          />
        )}
      </Grid>

      <Grid item xs={12} md='auto'>
        <TextField
          select
          data-cy='timezone'
          id='timezone'
          name='timezone'
          label={intl.formatMessage({ id: 'settings.formLabels.timezone' })}
          placeholder={intl.formatMessage({
            id: 'settings.placeholders.timezone',
          })}
          variant='outlined'
          value={companyState.timezone}
          onChange={event =>
            setCompanyState({
              ...companyState,
              timezone: event.target.value,
            })
          }
          fullWidth
          margin='normal'
        >
          {timezones.map(timezone => (
            <MenuItem
              key={timezone.value}
              value={timezone.value}
              data-cy='timezone_option'
            >
              {timezone.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  )
}
