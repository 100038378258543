import { useEffect, useRef, useState } from 'react'

import {
  Alert,
  AlertTitle,
  Box,
  Collapse,
  Grid,
  Link,
  Typography,
} from '@mui/material'
import { isEmpty } from 'lodash'
import { useIntl } from 'react-intl'
import { useDebounce } from 'react-use'

import { SparckIdeas, SparckMentionWarning } from '..'
import { TextFieldWithMentions } from 'components'
import { MediaPicker, MediaType } from 'components/MediaPicker'
import { t } from 'i18n'
import { getUserFullName } from 'utils/user'

export default function SparckForm(props) {
  const intl = useIntl()

  const [
    dialogMentionInPrivateSparckAlreadyOpened,
    setDialogMentionInPrivateSparckAlreadyOpened,
  ] = useState(false)

  const [
    dialogMentionInPrivateSparckOpen,
    setDialogMentionInPrivateSparckOpen,
  ] = useState(false)

  const textFieldMentionsRef = useRef<any>(null)

  const {
    fromFeed,
    sparckState,
    setSparckState,
    setSparckMedia,
    setDialogSparckIdeas,
    showMessageInput,
    setShowMessageInput,
    fetchSparckMessageReuseDetails,
    sparckMessageReuseDetails,
  } = props

  const [debounceFetchRecognitionMessageReuseDetails] = useDebounce(
    () => {
      if (sparckState?.message) {
        fetchSparckMessageReuseDetails()
      }
    },
    2500,
    [sparckState?.message],
  )

  const showMessageReuseAlert =
    sparckMessageReuseDetails?.data?.attributes?.similar_messages_count > 0

  const messageReuseCount =
    sparckMessageReuseDetails?.data?.attributes?.similar_messages_count

  useEffect(() => {
    if (sparckState.event_id) {
      if (!showMessageInput) {
        setShowMessageInput(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sparckState.event_id])

  useEffect(() => {
    if (
      !sparckState.has_public_access &&
      sparckState.mentions?.length &&
      !dialogMentionInPrivateSparckAlreadyOpened
    ) {
      setDialogMentionInPrivateSparckOpen(true)
      setDialogMentionInPrivateSparckAlreadyOpened(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sparckState.has_public_access, sparckState.mentions])

  return (
    <>
      <Grid item xs={12}>
        <Collapse
          in={
            !isEmpty(sparckState.users_to_recognize) &&
            sparckState.event_id &&
            showMessageInput
          }
          timeout={600}
        >
          <Grid container>
            <Grid item xs={12}>
              <Box width={1} mb={1}>
                <Grid container justifyContent='space-between'>
                  <Typography
                    variant='h6'
                    color='text.secondary'
                    fontWeight={600}
                  >
                    {t('sparcks.sparck.personalize')} ("Sparck")
                  </Typography>

                  {!fromFeed && (
                    <Link
                      data-cy='sparck_ideas'
                      component='button'
                      variant='body1'
                      onClick={() => setDialogSparckIdeas(true)}
                    >
                      {t('sparcks.sparck.needSomeIdeas')}
                    </Link>
                  )}
                </Grid>
              </Box>
            </Grid>

            {showMessageReuseAlert && (
              <Grid container>
                <Grid item xs={12} mb={2}>
                  <Alert severity='warning'>
                    <AlertTitle>Recognition Message Reuse</AlertTitle>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant='body1' paragraph>
                          We noticed you have used this message or a very
                          similar one at least {messageReuseCount} time(s).
                          Would you like to rewrite it and make it more personal
                          and meaningful?
                        </Typography>
                      </Grid>
                    </Grid>
                  </Alert>
                </Grid>
              </Grid>
            )}

            <Grid item xs={12}>
              <TextFieldWithMentions
                ref={textFieldMentionsRef}
                onContentChange={(content, mentions) => {
                  setSparckState({
                    ...sparckState,
                    message: content,
                    mentions,
                  })

                  debounceFetchRecognitionMessageReuseDetails()
                }}
                placeholder={`${intl.formatMessage(
                  { id: 'sparcks.sparck.sparckMessagePlaceholder' },
                  {
                    userName: sparckState.individual_to_recognize
                      ? getUserFullName(sparckState.individual_to_recognize)
                      : intl.formatMessage({
                          id: 'sparcks.sparck.selectedUsers',
                        }),
                  },
                )}`}
              />
            </Grid>

            <SparckIdeas
              setTextFieldWithMentionsContent={
                textFieldMentionsRef?.current?.setContent
              }
              {...props}
            />

            <Grid item xs={12}>
              <Grid container justifyContent='flex-end'>
                <MediaPicker
                  allowGifs
                  allowCards
                  allowImagesAndVideos
                  onSelectMedia={media => {
                    setSparckMedia({})

                    if (media.type === MediaType.SparckCard) {
                      return setSparckState({
                        ...sparckState,
                        card_id: media.id,
                      })
                    }

                    if (media.type === MediaType.ObjectURL && media.file) {
                      return setSparckMedia(media.file)
                    }

                    if (media.type === MediaType.URL) {
                      return setSparckState({
                        ...sparckState,
                        external_media_url: media.src,
                      })
                    }

                    if (media.type === MediaType.Gif) {
                      return setSparckState({
                        ...sparckState,
                        gif_id_on_provider: media.name ?? '',
                      })
                    }

                    return setSparckState({
                      ...sparckState,
                      card_id: null,
                      external_media_url: '',
                    })
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>

      <SparckMentionWarning
        dialogOpen={dialogMentionInPrivateSparckOpen}
        setDialogOpen={setDialogMentionInPrivateSparckOpen}
      />
    </>
  )
}
