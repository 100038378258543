import { useState } from 'react'

import { Box, Grid, Tab, Tabs, useTheme } from '@mui/material'
import { get } from 'lodash'

import { MyRewardsHeader, MyRewardsHome, MyRewardsList } from '..'
import { MyRewardsTab } from '../../MyRewards'
import {
  DialogTeleport,
  PurchaseRetailerRewardDialog,
  ReceivedReward,
} from 'components'
import { t } from 'i18n'
import { MyRewardsPurchases, RewardsCatalog } from 'pages'
import { getUniqueColor } from 'types/colors'
import { a11yTabProps } from 'utils/accessibility'

export default function MyRewardsComponent(props) {
  const {
    fetchRewards,
    fetchWishes,
    tabs,
    selectedTab,
    setSelectedTab,
    redeemRewardDialog,
    setRedeemRewardDialog,
    dialogCatalogOpen,
    setDialogCatalogOpen,
  } = props

  const theme = useTheme()

  const [purchaseGiftDialogOpen, setPurchaseGiftDialogOpen] = useState(false)
  const [dialogRewardsPurchases, setDialogRewardsPurchases] = useState(false)

  const closeRedeemRewardDialog = () =>
    setRedeemRewardDialog({ isOpen: false, data: null })

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  return (
    <>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          <MyRewardsHeader
            {...props}
            setPurchaseGiftDialogOpen={setPurchaseGiftDialogOpen}
          />
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label='rewards-tabs'
            >
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  data-cy='rewardsTabs'
                  value={tab.value}
                  label={tab.label}
                  {...a11yTabProps(index)}
                />
              ))}
            </Tabs>
          </Box>
        </Grid>

        <Grid item xs={12}>
          {selectedTab === MyRewardsTab.Home && <MyRewardsHome {...props} />}

          {selectedTab === MyRewardsTab.MyRewards && (
            <MyRewardsList {...props} />
          )}

          {selectedTab === MyRewardsTab.MyPurchases && (
            <MyRewardsPurchases {...props} />
          )}
        </Grid>

        <DialogTeleport
          dialogTitle={
            redeemRewardDialog.isOpen
              ? t('myRewards.redeem', {
                  name: redeemRewardDialog?.data?.product?.name,
                })
              : ''
          }
          dialogAction={null}
          dialogSize='sm'
          dialogOpen={!!redeemRewardDialog.isOpen}
          handleDialogClose={() => closeRedeemRewardDialog()}
          isFullWidth
        >
          <ReceivedReward
            reward={{
              ...redeemRewardDialog.data,
              backgroundColor: getUniqueColor(theme, {
                isGradient: true,
                id: get(redeemRewardDialog, 'data.product.id'),
              }),
            }}
            fetchSparck={fetchRewards}
            redeemRewardCallback={closeRedeemRewardDialog}
          />
        </DialogTeleport>

        <DialogTeleport
          dialogTitle={dialogCatalogOpen ? t('myRewards.catalog.title') : ''}
          dialogAction={null}
          dialogSize='xl'
          dialogOpen={!!dialogCatalogOpen}
          handleDialogClose={() => {
            fetchWishes()
            setDialogCatalogOpen(false)
          }}
          isFullWidth
        >
          <RewardsCatalog />
        </DialogTeleport>

        <PurchaseRetailerRewardDialog
          dialogOpen={purchaseGiftDialogOpen}
          setDialogOpen={setPurchaseGiftDialogOpen}
        />

        <DialogTeleport
          dialogTitle={t('myRewards.myGiftCardPurchases')}
          dialogAction={null}
          dialogSize='lg'
          dialogOpen={!!dialogRewardsPurchases}
          handleDialogClose={() => setDialogRewardsPurchases(false)}
          isFullWidth
        >
          <MyRewardsPurchases />
        </DialogTeleport>
      </Grid>
    </>
  )
}
