import React from 'react'

import NumberFormat from 'react-number-format'

export const MoneyInput = React.forwardRef<any, any>(function MoneyInput(
  props,
  ref,
) {
  const { onChange, value, ...other } = props

  return (
    <NumberFormat
      {...other}
      value={value ?? ''}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      isNumericString
      decimalScale={2}
      fixedDecimalScale
      prefix='$'
    />
  )
})
