import {
  Box,
  Card,
  CardActionArea,
  Grid,
  // Rating,
  Typography,
  // useTheme,
} from '@mui/material'
// import withStyles from '@mui/styles/withStyles'
// import { formatToUSD } from 'utils/currency'

// import { getUniqueColor } from 'types/colors'
// import { getRewardName } from 'utils/reward'

// const WhiteRating = withStyles({
//   iconFilled: {
//     color: '#FFF',
//   },
// })(Rating)

type Props = {
  reward: any
  onClick?: (reward: any) => any
  cardSx?: any
}

export default function CorporateRewardCard({
  reward,
  onClick,
  cardSx,
}: Props) {
  // const theme = useTheme()

  // const rewardBackgroundColor = getUniqueColor(theme, {
  //   id: reward?.id,
  //   isGradient: true,
  // })
  // const title = reward?.name
  // const description = reward?.meta_description
  // const image = reward?.details?.image_url
  // const rewardOptionsPrices = reward.product_options?.map(
  //   option => +option.price,
  // )

  // const cheapestRewardAmount = Math.min(...(rewardOptionsPrices ?? []))
  // const mostExpensiveRewardAmount = Math.max(...(rewardOptionsPrices ?? []))


  return (
    <Card
      key={`${reward.id}`}
      sx={{
        position: 'relative',
        overflow: 'inherit',
        height: 1,
        //  background: rewardBackgroundColor,
        ...(cardSx && {
          ...cardSx,
        }),
      }}
    >
      <CardActionArea
        onClick={() => onClick && onClick(reward)}
        // sx={{ height: 1, borderRadius: 4 }}
      >
        <Box p={2} width={1} height={1} position='relative'>
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            style={{ height: '100%', width: "100% " }}
          >
            <img src={reward?.images[2].url} style={{ minWidth: 200, maxHeight: 140, borderRadius: 2}} />
            <Grid container>
              <Grid item xs={12}>
              <Typography
                variant='body1'
                color='text.primary'
                fontWeight={500}
                align='center'
                sx={{ mb: 0 }}
              >
                {reward?.name}
              </Typography>
                {/* <Typography
                  variant='body1'
                  fontWeight={500}
                  color='text.white'
                  align='center'
                  sx={{ mb: 0.5 }}
                >
                  {reward?.name}
                </Typography> */}
              </Grid>
              <Grid item xs={12}>
              <Typography
                variant='body1'
                color='text.primary'
                fontWeight={500}
                align='center'
                sx={{ mb: 5 }}
              >
                {reward?.brand}
              </Typography>
                {/* <Typography
                  variant='body1'
                  fontWeight={500}
                  color='text.white'
                  align='center'
                  sx={{ mb: 0.5 }}
                >
                  {reward?.brand}
                </Typography> */}
              </Grid>
              {/* <Grid item xs={12}>
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 128,
                    left: 0,
                    right: 0,
                    margin: '0 auto',
                    padding: 1,
                    bgcolor: 'text.primary',
                    color: 'text.white',
                  }}
                >
                  <Typography variant='body2' fontWeight={600} align='center'>
                    {/* {cheapestRewardAmount === mostExpensiveRewardAmount
                      ? formatToUSD(reward?.sub_total)
                      : `${formatToUSD(reward?.sub_total)}  Points`} *}
                      {cheapestRewardAmount === mostExpensiveRewardAmount
                      ? reward?.sub_total
                      : `${reward?.sub_total} Points`}
                          
                  </Typography>
                </Box>
                {/* <Typography
                  variant='body1'
                  fontWeight={500}
                  color='text.white'
                  align='center'
                  sx={{ mb: 0.5 }}
                >
                  {reward?.sub_total} points
                </Typography> *}
              </Grid> */}
            </Grid>
          </Grid>
        </Box>
      </CardActionArea>
    </Card>
  )
}
