export default {
  beheardReport: {
    reportBeingGenerated: 'Your #BeHeard Report is being generated...',
    title: {
      report: '#BeHeard {type} Report',
      premium: 'Premium',
      free: 'Free',
    },
    shortTermQuestion:
      'The chances of leaving my job voluntarily during the next year are Extremely Low.',
    longTermQuestion:
      'I feel that I will have a long career at this organization.',
    sectionTitles: {
      overview: 'OVERVIEW',
      characteristics: 'CHARACTERISTICS',
      participation: 'PARTICIPATION',
      results: 'RESULTS',
      topFiveRecommendations: 'TOP 5 RECOMMENDATIONS',
      employeeTurnoverForecast: 'EMPLOYEE TURNOVER FORECAST',
      comparisonGraphs: 'COMPARISON GRAPHS',
      fullReport: 'FULL REPORT',
      customQuestions: 'CUSTOM QUESTIONS',
    },
    navigationTitles: {
      overview: 'Overview',
      characteristics: 'Characteristics',
      results: 'Results',
      topFiveRecommendations: 'Top 5 Recommendations',
      employeeTurnoverForecast: 'Turnover',
      comparisonGraphs: 'Comparison Graphs',
      fullReport: 'Full Report',
      customQuestions: 'Custom Questions',
    },
    overview: {
      overviewDescription:
        'Your #BeHeard Premium Report provides much more than an employee engagement score. It provides key findings about the extent your employees feel passionate about their jobs, are committed to your organization, put discretionary effort into their work and are willing to refer. That’s because employee engagement relates directly to productivity, revenue, absenteeism, turnover, customer satisfaction and other organization insights. In addition to your overall employee engagement score, this report provides results on five engagement drivers, forecasts short and long-term turnover, and offers customized recommendations for taking action to increase engagement.',
    },
    characteristics: {
      description:
        'Before you dive into the results, let’s start by looking at the key characteristics of engaged and disengaged employees. This is why taking action to build employee engagement matters!',
      engagedEmployees: { title: 'Engaged Employees' },
      disengagedEmployees: { title: 'Disengaged Employees' },
    },
    participation: {
      overallRate: 'Overall Rate',
      description:
        'Click on each demographic filter below to view specific participation rates.',
      noParticipation: 'Check back, no one has participated yet.',
      demographicsWithLessThanFiveParticipants:
        'Demographics that have less than 5 participants for a particular value will show N/A. (e.g., 4 participants in the IT Department will show N/A)',
    },
    results: {
      description:
        'This section provides a snapshot of your engagement results including the Top 5 Action Areas, the Top 5 Strengths, and the Top 5 Most Important Items to your employees.',
      engagementScore: { engagement: 'Engagement', score: 'Score' },
      engagementDriversHeader: 'Engagement Driver Scores',
      effectiveness: 'EFFECTIVENESS',
    },
    progressBarLegend: {
      legendTitle: 'Effectiveness Score Legend',
      colorMeaning: {
        negative: 'Unfavorable',
        neutral: 'Neutral',
        positive: 'Favorable',
      },
    },
    driverScore: {
      alignment:
        'The extent to which employees believe in and put effort toward this organization’s mission, values and goals.',
      engagement:
        'The extent to which employees feel passionate about their jobs, are committed to our organization, put discretionary effort into their work and are willing to refer.',
      fit: 'The extent to which employees feel they are in the right jobs and have the necessary skills, knowledge and abilities to adequately perform in their roles.',
      growth:
        'The extent to which employees feel supported to advance their career or develop personal skill sets within this organization.',
      team: 'The extent to which this organization promotes a team-based work style (i.e. departments or groups work well together).',
      valuing:
        'The extent to which employees feel valued by this organization.',
      custom_ratings_questions: 'Custom Ratings Questions',
    },
    topFiveText: {
      action: {
        title: 'Top 5 Action Areas',
        description:
          'Your employees ranked the following statements as low in effectiveness and high in importance (i.e. these items have the biggest ROI Gap). You will get the greatest return on investment if you focus your engagement efforts on these five areas (listed in order of priority).',
      },
      strength: {
        title: 'Top 5 Strengths',
        description:
          'Your employees ranked the following statements as high in effectiveness and high in importance (i.e. these items have the smallest ROI Gap). Use this data to promote your employee brand in talent attraction, engagement and retention strategies. These are your greatest strengths!',
      },
      importance: {
        title: 'Top 5 Most Important Items',
        description:
          'Your employees ranked the following five statements as the most important to them.',
      },
      topFiveResultDetails: {
        effectiveness: 'Effectiveness',
        importance: 'Importance',
        previousEffectiveness: 'Previous Effectiveness',
        benchmarkEffectiveness: 'Benchmark Effectiveness',
      },
      topFiveRecommendations: {
        description:
          'These actionable recommendations are based on your Top 5 Action Areas. This is where your efforts to boost engagement will give you the largest return on investment. Time to take action!',
        noRecommendations:
          'There are no Recommendations for the results displayed.',
      },
      tooltip: { roiGap: 'Difference between effectiveness and importance' },
    },
    filterButtons: { filters: 'Filters', download: 'Download' },
    downloadMenu: {
      pdfNewTab: 'Open in new tab (PDF)',
      pdfDownload: 'PDF',
      xlsxDownload: 'Spreadsheet (.xlsx)',
    },
    noData: 'There are no {title} for the results displayed.',
    turnoverOverviewDescription:
      'The likelihood employees will leave your organization within the next twelve months.',
    turnoverForecast: {
      statement: 'STATEMENT',
      atRisk: 'At Risk',
      passive: 'Passive',
      committed: 'Committed',
      employee: 'employee',
      employees: 'employees',
      potentialTurnover: 'Potential Turnover',
      previousTurnover: 'Previous Turnover',
      description:
        "Your short and long-term outlook is predicted using your employees' responses to the turnover statements in the survey.",
      shortTerm: 'Short-Term Outlook',
      longTerm: 'Long-Term Outlook',
    },
    comparisonGraphs: {
      description:
        'Now it’s time to take a closer look at engagement results across the organization. Simply hover over the bar charts to see specific results. Depending on your permission settings, you can use Filters to change the comparison graphs.',
      demographic: 'Demographic',
      legend: {
        engaged: 'Engaged',
        neutrals: 'Neutral',
        disengaged: 'Disengaged',
      },
    },
    fullReport: {
      engagementScore: 'Engagement Score',
      participationRate: 'Participation Rate',
      legend: {
        strengths: 'Strengths',
        recommendedActionAreas: 'Recommended Action Areas',
      },
      tableHeaders: {
        score: 'Score',
        result: 'Result',
        effectiveness: 'Effectiveness',
        importance: 'Importance',
        previousEffectiveness: 'Prev. Effec.',
        benchmark: 'Benchmark',
      },
      tableData: { viewRecommendations: 'View recommendations here' },
      openEndedQuestions: 'Open-Ended Questions',
      noCustomRatingQuestions:
        'No custom ratings questions were used in this survey.',
      noCustomOpenEndedQuestions:
        'No custom open-ended questions were used in this survey.',
      buttonLabels: {
        xlsx: 'Download answers as XLSX',
        pdf: 'Download answers as PDF',
      },
    },
    unlockPremiumReport: 'CLICK HERE TO UNLOCK THE PREMIUM REPORT',
    snackbar: {
      success: {
        surveyAddedToCart: 'Survey added to your cart successfully',
        filters: 'Your filters have been applied!',
      },
    },
  },
  report: {
    upgradeReport: 'Upgrade Report',
    freeReport: 'Free Report',
    premiumReport: 'Premium Report',
    shareReport: 'Share Report',
  },
  survey: {
    status: {
      scheduled: 'Scheduled',
      open: 'Open',
      closed: 'Closed',
      awaitingManualOpening: 'Awaiting Manual Launch',
    },
    snackbar: {
      success: {
        alreadyFinished: 'You already finished this survey',
        finishedSurvey: 'You finished the survey! Thanks for taking the time',
        giftCardRedeemed:
          'Gift Card redeemed successfully! Watch for an email with your electronic gift card link.',
      },
    },
  },
  surveyKey: {
    status: {
      surveykey: 'Survey Key',
      paragraph1:
        'この調査では、評価に顔と星を使用します。 職場での経験と、各ステートメントの重要性を評価します。満足度は顔に基づいており、重要度は1から5までの星に基づいています。 ',
      paragraph2: '当社の製品の使用に関するあなたの経験を評価してください。',
      title1: 'Satisfaction',
      title2: 'Importance',
    },
  },
  surveyinstruction: {
    surveyinstructionkey: '規模',
    surveytitle: 'この調査には、',
    twopart: '2部構成の評価プロセス',
    using: 'を使用して各ステートメントに対して',
    faces: '顔',
    and: 'そして',
    star: '星.',
    rate: 'あなたがいくらかを評価してください',
    agree: '賛成または反対',
    statement: 'を使用して各ステートメントで',
    then: 'それから',
    how: 'どう',
    important: '大事な',
    eachstatement: '各ステートメントは、職場で',
    starrating: '5つ星の評価',
    scale: '規模.',
  },
  surveyDialogs: {
    title: '調査',
    addSurvey: {
      descriptionFirstLine:
        'It’s time to make the survey your own. First, select your launch timeline below to get started. You can make changes before the survey opens and extend the survey before it closes. Click',
      link: 'HERE',
      descriptionSecondLine:
        'for best practices to launch and market the survey.',
    },
    editSurvey: {
      description:
        'Need to edit your survey? No problem. You can do that here. Please make your changes below.',
    },
    extendSurvey: { title: 'Extend Survey', closeDate: 'Close Date' },
    confirmClose: {
      title: 'Close Survey',
      actionLabel: 'Close Survey',
      close: 'close',
    },
    confirmDelete: { title: 'Remove Survey', actionLabel: 'Archive Survey' },
    formLabels: {
      surveyName: 'Survey Name',
      launchDate: 'Launch Date',
      closeDate: 'Close Date',
      allowReward: 'Reward survey participation with a Gift Card',
      allowRewardTooltip:
        'Employees will be able to select their gift card directly from our system after they participate',
      allowAnswersToBeUpdated:
        'Give employees permission to edit their survey responses',
      allowAnswersToBeUpdatedTooltip:
        'Edits can only be made when the survey is open',
      rewardAmount: 'Gift Card Amount',
    },
    customizedQuestions: {
      header: 'Add Custom Questions/Statements',
      description:
        'In addition to the 38 questions in our statistically valid #BeHeard engagement survey, you can add up to five 5-star rating questions and five open ended questions. If you want some ideas for your custom questions, check out examples',
      link: 'HERE',
      statement: 'Statement',
      statementPlaceholder: 'Type your question/statement...',
      fiveStarQuestions: { header: '5-Star Rating Questions' },
      openEndedQuestions: { header: 'Open-Ended Questions' },
    },
  },
  surveyLanding: {
    welcomeToSparck: 'へようこそ Sparck!',
    areYouReady: '{beheard}の準備はできていますか?',
    thankYou: 'あなたの声を聞かせてくれてありがとう。',
    noActiveSurveys:
      "There is no active survey to complete right now, but we're glad you checked in.",
    whatBeHeardSurveyIs:
      '#BeHeard 調査は、職場に関するフィードバックを安全に共有できるように設計された匿名の調査です。あなたの組織はあなたの考えを気にかけ、あなたが自由に発言できる秘密の声をあなたに与えたいと考えています。それが彼らがSparkと提携した理由です!集計結果のみが最後に共有され、あなたとあなたの同僚にとって最も重要な分野に基づいて前進する組織戦略を形成するのに役立ちます。.',
    youAreAwesome:
      'あなたは素晴らしいです！ #BeHard にお時間を割いていただきありがとうございます。あなたのフィードバックは貴重です。',
    feedbackImportance:
      "Your feedback is really important to us, so we'll send you an email as soon as a new survey is posted!",
    eachItemExplanation:
      'アンケートの所要時間はわずか 10 分ほどです。各項目には 2 つの部分があります。まず、顔の 1 つを選択して、各職場のステートメントにどの程度同意するか、または同意しないかを評価します。それが終わったら、星を使って各ステートメントがあなたにとってどれほど重要かを評価します。アニメーションの例をチェックしてください!',
    iAmReady: 'アンケートに答える準備ができました！',
    updateMyAnswers: 'Review my answers!',
    surveyPagination: { previous: '前', nextPage: '次のページ' },
    submitSurvey: '調査を送信する',
    pendingGiftCards: 'You have {count} pending Gift Cards! 🎉',
    pendingGiftCardLabel: '{rewardValue} for {surveyName}',
    redeem: 'Redeem',
    giftCardRedeemed: 'Gift Card redeemed successfully!',
    departmentTerm: '部署',
    departmentValue: ' ',
    missionAndValueTerm: 'ミッションと価値観',
    missionAndValueText:
      '私たちは、インテリジェントな統合と自動化を通じて、お客様のために最高の結果を引き出すことに注力し、特別なものを構築します。',
    missionAndValueValue: '価値観：大胆であれ。自分らしくあれ。Boomiであれ。',
    managerTerm: 'マネージャー',
    managerValue: '2023年6月8日現在での直属の上司',
    seniorLeadershipTeamTerm: 'シニアリーダーシップチーム',
    seniorLeadershipTeamValue: 'エグゼクティブリーダーシップチーム',
    surveyInstructionsTitle: 'Survey Instructions',
    surveyInstructionsContent:
      'This survey has a two-part rating process for each statement using Faces  and Stars.  Rate how much you agree or disagree with each statement using the faces and then how important each statement is to you in the workplace using a 5-star rating scale.',
    boomiSurveyLandingContent:
      '#BeHeardアンケート調査は、Boomiでのあなたの体験についてフィードバックを共有していただく安全な場所となるようデザインされた匿名アンケート調査です。Boomiでは、皆さんの意見を大切にしており、秘密が守られ自由に話せる手段を皆さんに提供したいということで、Sparckと提携したわけです。Boomiにおいてあなたやあなたの同僚にとって最も大事な領域に基づいた組織としての今後の戦略の策定に役立つよう、集約された結果のみが共有されます。取り掛かる前に、全員が同一線上に並ぶよう幾つか用語の定義をいたします。',
    boomiSurveyLandingContentTiming:
      '本アンケート調査の所要時間はほんの15分ほどです。各項目は2つの部分で構成されています。 まず、職場に関する記述にどの程度同意するか、同意しないかを、顔のうちから一つ選ぶことで評価します。それが済んだら、それぞれの記述が自分にとってどのくらい大事なものかを星の数によって評価します。 アニメーションの例をご覧ください。',
    topicCards: {
      secure: {
        title: 'あなたのデータは{keyword}',
        keyword: '安全',
        description:
          '私たちはデータ保護を非常に真剣に受け止めています。 Spark は一連のテクノロジーとプロトコルを使用して、データ漏洩とサイバー攻撃を防ぎます。',
      },
      anonymous: {
        title: '参加は{keyword}',
        keyword: '匿名',
        description:
          '機密性は私たちにとって非常に重要です!あなたの組織やマネージャーは、あなたの個別の回答を見ることはできません。私たちが提供するデータは厳密に匿名です。',
      },
      giftCard: {
        title: 'This Survey Has a {keyword}!',
        keyword: 'Gift Card',
        description:
          'That’s right! As a “Thank You” for completing the survey, your organization is treating you to a giftcard of your choice. You can redeem it at the end. Enjoy!',
      },
      answersUpdate: {
        title: '{keyword} Your Answers',
        keyword: 'Update',
        description:
          'Great news! You can come back to update and edit your answers at any point while the survey is still open. Just log back in!',
      },
    },
  },
  surveyOverview: {
    snackbarSuccess: {
      openSurvey: 'The survey is now open!',
      extendedSurvey: 'The survey close date has been extended successfully!',
      closedSurvey: 'The survey has been closed!',
      deletedSurvey: 'The survey has been archived!',
      employeesInvited: 'Employees have been invited successfully!',
      addedToCart: 'Survey added to your cart successfully',
    },
    selectLabel: 'Survey',
    minimumNumberOfEmployees:
      'Your organization must have at least 5 employees to create a new survey.',
    whenSurveyCloses:
      'When your survey closes the #BeHeard results will be immediately available.',
    scheduledLaunchSurveyInfo:
      "Your survey isn't live yet.  It will Launch Automatically on {launchDate}. If you would like to launch it manually you can click the Launch Survey button or edit your survey to Launch Manually.",
    manualLaunchSurveyInfo:
      "Your survey isn't live yet and it is set to Launch Manually. When you are ready to launch your survey, click the Launch Survey button.",
    newSurvey: 'New Survey',
    launchSurvey: 'Launch Survey',
    editSurvey: 'Edit Survey',
    extendSurvey: 'Extend Survey',
    closeSurvey: 'Close Survey',
    applyUpgrade: 'Apply Upgrade',
    printSurvey: 'Print',
    applyDowngrade: 'Apply Downgrade',
    inviteEmployees: 'Invite Employees',
    deleteSurvey: 'Archive Survey',
    anticipatedTurnover: 'Anticipated Turnover more than 12 Months Away',
    overallParticipation: 'Overall Participation Rate',
    person: 'Person',
    people: 'People',
    employeesInvitation: {
      inviteEmployees: 'Invite Employees',
      chooseEmployees: 'Choose below the employees you want to invite for',
      textFieldLabel: 'Choose one or more employees',
      cancelButton: 'Cancel',
      inviteButton: 'Invite Employees',
    },
    tabs: { labels: { general: 'General', sharedReports: 'Shared Reports' } },
    validations: {
      surveyNameIsRequired: 'Survey Name is required',
      minimumLaunchDate: 'You cannot select a past date',
      maximumLaunchDate: 'Launch Date cannot be after the Close Date',
      launchDateIsRequired: 'Launch Date is required',
      minimumCloseDate: 'Close Date cannot be before Launch Date',
      closeDateIsRequired: 'Close Date is required',
      rewardAmountIsRequired: 'Gift Card amount is required',
    },
  },
  surveyProgress: {
    bulletLabels: {
      createdOn: 'Created on',
      openOn: 'Open on',
      closedOn: 'Closed on',
      willCloseOn: 'Will Close on',
    },
  },
  ratingSection: {
    toWhatExtent: 'あなたはこの意見にどの程度同意しますか?',
    important: 'これは私にとって重要です',
    ratingLabels: {
      stronglyDisagree: '強く同意しない',
      disagree: '同意しない',
      neutral: '中性',
      agree: '同意',
      stronglyAgree: '強く同意します',
      notApplicable: '適用できない',
    },
    change: '変化',
    unansweredItem: 'Please respond to every item before continuing.',
    formLabels: {
      textField: 'Your answer',
      placeholder: 'Type anything you want here...',
      helperText:
        'To ensure your confidentiality, do not put any personally identifiable information.',
    },
  },
  manageShareReport: {
    reportSections: {
      labels: {
        comparisonGraph: 'Comparison Graph',
        results: 'Results',
        topFiveActionAreas: 'Top 5 Action Areas',
        topFiveStrengths: 'Top 5 Strengths',
        topFiveMostImportantItems: 'Top 5 Most Important Items',
        recommendations: 'Recommendations',
        turnoverForecast: 'Employee turnover forecast',
        fullReport: 'Full Report',
        customRatingQuestions: 'Custom Ratings Questions',
        customOpenEndedQuestions: 'Custom Open-Ended Questions',
      },
    },
    previewFor: 'Preview for {name}',
    reportWillBeSharedWithRoles: 'This report will be shared with',
    formHandler: {
      title: 'Share Report',
      labels: {
        name: 'Shared Report Name',
        expirationDate: 'How long is it available?',
        whatToSee: 'What can they see?',
        limitReport: 'Limit Report by Demographics',
        whichRoles: 'Which Roles can see this Share Report?',
        roles: 'Roles',
        whichDemographics: 'Which Demographics will receive this Share Report?',
      },
      placeholders: { beheardReport: '#BeHeard ShareReport 001' },
      dialog: {
        title: 'Warning!',
        content: {
          firstLine:
            "Are you sure you want to give access to your employee's answers?",
          secondLine:
            'The answers contain very sensitive information. Please be absolutely sure that you selected the correct demographics for this action.',
        },
        buttonText: 'I understood!',
      },
    },
    snackbars: { invitesSent: 'Invites sent successfully' },
  },
  inviteShareReport: {
    dialogTitle: 'Invite for Share Report',
    dialogActionLabel: 'Share this report',
    clickToInvite:
      'Your Shared Report was created but you need to invite. Click "Share this report" to send e-mails to your invitees',
  },
  shareReport: {
    title: 'Share Reports',
    description: 'You control who has access to your #BeHeard Premium Reports',
    columnHeaders: {
      sharedReport: 'Shared Report Name',
      surveyName: 'Survey Name',
      expires: 'Expires',
    },
    validations: {
      minimumExpirationDate: 'You cannot select a past date',
      expirationDateIsRequired: 'Expiration Date is required',
    },
  },
}
