import {
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
} from '@mui/material'
import { Send as SendIcon } from '@mui/icons-material'

import { adminEmails } from 'constants/emails'
import { t } from 'i18n'

export default function CompaniesEmails({ company, handleSendEmail }) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {adminEmails.map(companyEmail => (
            <ListItem key={companyEmail.id}>
              <ListItemText
                primary={t(companyEmail.name)}
                secondary={t(companyEmail.description)}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge='end'
                  aria-label='send'
                  onClick={() =>
                    handleSendEmail(companyEmail.endpoint, company.id)
                  }
                  size="large">
                  <SendIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
}
