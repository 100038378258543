import { Box, Grid, Typography } from '@mui/material'

import { OnboardingPage } from 'enums/onboardingQuestion'
import { t } from 'i18n'

export default function OnboardingHeader({ pagination }) {
  const paginationToHeaderContent = {
    [OnboardingPage.ProfileQuestions]: {
      title: t(`onboarding.header.${OnboardingPage.ProfileQuestions}.title`),
      description: t(
        `onboarding.header.${OnboardingPage.ProfileQuestions}.description`,
      ),
    },
    [OnboardingPage.RatingAndSimpleSelect]: {
      title: t(
        `onboarding.header.${OnboardingPage.RatingAndSimpleSelect}.title`,
      ),
      description: t(
        `onboarding.header.${OnboardingPage.RatingAndSimpleSelect}.description`,
      ),
    },
    [OnboardingPage.Reward]: {
      title: t(`onboarding.header.${OnboardingPage.Reward}.title`),
      description: t(`onboarding.header.${OnboardingPage.Reward}.description`),
    },
    [OnboardingPage.Event]: {
      title: t(`onboarding.header.${OnboardingPage.Event}.title`),
      description: (
        <span>
          {t(`onboarding.header.${OnboardingPage.Event}.description1`)}
          <p>{t(`onboarding.header.${OnboardingPage.Event}.description2`)}</p>
        </span>
      ),
    },
    [OnboardingPage.ChoiceGroup]: {
      title: t(`onboarding.header.${OnboardingPage.ChoiceGroup}.title`),
      description: t(
        `onboarding.header.${OnboardingPage.ChoiceGroup}.description`,
      ),
    },
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid
          container
          justifyContent='center'
          style={{
            background: 'linear-gradient(90deg,#fff2f2 0%,#fff2f2 100%)',
          }}
        >
          <Grid item xs={11} sm={10} md={8}>
            <Box width={1} py={3} pt={7}>
              <Typography
                variant='h3'
                color='text.primary'
                align='center'
                fontWeight={600}
                paragraph
              >
                {paginationToHeaderContent[pagination].title}
              </Typography>

              <Typography
                variant='h6'
                color='text.secondary'
                align='center'
                paragraph
              >
                {paginationToHeaderContent[pagination].description}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <div style={{ height: 100, overflow: 'hidden' }}>
          <svg
            viewBox='0 0 500 120'
            preserveAspectRatio='none'
            style={{
              height: '100%',
              width: '100%',
              filter: 'drop-shadow( 3px 2px 10px rgba(0, 0, 0, .2))',
            }}
          >
            <defs>
              <linearGradient id='myGradient'>
                <stop offset='0%' stopColor='#fff2f2' />
                <stop offset='100%' stopColor='#fff2f2' />
              </linearGradient>
            </defs>
            <path
              d='M0.00,49.98 C76.75,142.59 271.49,-49.98 502.82,66.61 L500.00,0.00 L0.00,0.00 Z'
              style={{ stroke: 'none', fill: "url('#myGradient')" }}
            ></path>
          </svg>
        </div>
      </Grid>
    </Grid>
  )
}
