export enum NominationFrequency {
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  SemiAnnually = 'semi_annually',
  Annually = 'annually',
}

export enum NominationOccurrenceType {
  AlwaysOpen = 'always_open',
  OneOff = 'one_off',
  Periodical = 'periodical',
}
