/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  // Checkbox,
  // FormControlLabel,
  Grid,
  // Paper,
  // Typography,
} from '@mui/material'

import { ManageBudgetReceivingForm/*, ManageBudgetSpendingForm*/ } from '..'
import { t } from 'i18n'

const renderButtonSection = ({
  id,
  formik,
  title,
  description,
  Component,
  props,
}) => (<Grid item xs={12} >
  {formik.values[id] && <Component {...props} />}
</Grid>)
// (
//   <Paper sx={{ p: 3 }} key={id}>
//     {/* <Grid container> */}
//       {/* <Grid item xs={12}>
//         <Typography variant='h6' component='h3' fontWeight={500} paragraph>
//           {title}
//         </Typography>

//         <Typography variant='body1' color='text.secondary' paragraph>
//           {description}
//         </Typography>
//       </Grid> */}
//       {/* hide Set Receiving Based Limit rules Checkbox */}
//       {/* <Grid item xs={12}>
//         <FormControlLabel
//           control={
//             <Checkbox
//               data-cy='budgetLimitTypes'
//               checked={formik.values[id] ?? false}
//               onChange={() => formik.setFieldValue(id, !formik.values[id])}
//             />
//           }
//           label={t('budget.manageBudgetLimitTypes.setRules', {
//             title: title,
//           })}
//         />
//       </Grid> */}
//     {/* </Grid> */}

//     <Grid item xs={12} sx={{ mt: 3 }}>
//       {formik.values[id] && <Component {...props} />}
//     </Grid>
//   </Paper>
// )

export default function ManageBudgetLimitTypes(props) {
  return (<Grid item xs={12} lg={12}>
    {renderButtonSection({
      id: 'has_receiving_limit',
      formik: props.formik,
      title: t(
        'budget.manageBudgetLimitTypes.receivingLimitSection.title',
      ),
      description: t(
        'budget.manageBudgetLimitTypes.receivingLimitSection.description',
      ),
      Component: ManageBudgetReceivingForm,
      props,
    })}
  </Grid>);
  // return (
  //   <Grid item xs={12}>
  //     <Grid container spacing={2}>
  //       {/* <Grid item xs={12} lg={6}>
  //         {renderButtonSection({
  //           id: 'has_spending_limit',
  //           formik: props.formik,
  //           title: t(
  //             'budget.manageBudgetLimitTypes.spendingLimitSection.title',
  //           ),
  //           description: t(
  //             'budget.manageBudgetLimitTypes.spendingLimitSection.description',
  //           ),
  //           Component: ManageBudgetSpendingForm,
  //           props,
  //         })}
  //       </Grid> */}

  //       <Grid item xs={12} lg={12}>
  //         {renderButtonSection({
  //           id: 'has_receiving_limit',
  //           formik: props.formik,
  //           title: t(
  //             'budget.manageBudgetLimitTypes.receivingLimitSection.title',
  //           ),
  //           description: t(
  //             'budget.manageBudgetLimitTypes.receivingLimitSection.description',
  //           ),
  //           Component: ManageBudgetReceivingForm,
  //           props,
  //         })}
  //       </Grid>
  //     </Grid>
  //   </Grid>
  // )
}
