export default {
  beheardReport: {
    reportBeingGenerated: 'Your #BeHeard Report is being generated...',
    title: {
      report: '#BeHeard {type} Report',
      premium: 'Premium',
      free: 'Free',
    },
    shortTermQuestion:
      'The chances of leaving my job voluntarily during the next year are Extremely Low.',
    longTermQuestion:
      'I feel that I will have a long career at this organization.',
    sectionTitles: {
      overview: 'OVERVIEW',
      characteristics: 'CHARACTERISTICS',
      participation: 'PARTICIPATION',
      results: 'RESULTS',
      topFiveRecommendations: 'TOP 5 RECOMMENDATIONS',
      employeeTurnoverForecast: 'EMPLOYEE TURNOVER FORECAST',
      comparisonGraphs: 'COMPARISON GRAPHS',
      fullReport: 'FULL REPORT',
      customQuestions: 'CUSTOM QUESTIONS',
    },
    navigationTitles: {
      overview: 'Overview',
      characteristics: 'Characteristics',
      results: 'Results',
      topFiveRecommendations: 'Top 5 Recommendations',
      employeeTurnoverForecast: 'Turnover',
      comparisonGraphs: 'Comparison Graphs',
      fullReport: 'Full Report',
      customQuestions: 'Custom Questions',
    },
    overview: {
      overviewDescription:
        'Your #BeHeard Premium Report provides much more than an employee engagement score. It provides key findings about the extent your employees feel passionate about their jobs, are committed to your organization, put discretionary effort into their work and are willing to refer. That’s because employee engagement relates directly to productivity, revenue, absenteeism, turnover, customer satisfaction and other organization insights. In addition to your overall employee engagement score, this report provides results on five engagement drivers, forecasts short and long-term turnover, and offers customized recommendations for taking action to increase engagement.',
    },
    characteristics: {
      description:
        'Before you dive into the results, let’s start by looking at the key characteristics of engaged and disengaged employees. This is why taking action to build employee engagement matters!',
      engagedEmployees: { title: 'Engaged Employees' },
      disengagedEmployees: { title: 'Disengaged Employees' },
    },
    participation: {
      overallRate: 'Overall Rate',
      description:
        'Click on each demographic filter below to view specific participation rates.',
      noParticipation: 'Check back, no one has participated yet.',
      demographicsWithLessThanFiveParticipants:
        'Demographics that have less than 5 participants for a particular value will show N/A. (e.g., 4 participants in the IT Department will show N/A)',
    },
    results: {
      description:
        'This section provides a snapshot of your engagement results including the Top 5 Action Areas, the Top 5 Strengths, and the Top 5 Most Important Items to your employees.',
      engagementScore: { engagement: 'Engagement', score: 'Score' },
      engagementDriversHeader: 'Engagement Driver Scores',
      effectiveness: 'EFFECTIVENESS',
    },
    progressBarLegend: {
      legendTitle: 'Effectiveness Score Legend',
      colorMeaning: {
        negative: 'Unfavorable',
        neutral: 'Neutral',
        positive: 'Favorable',
      },
    },
    driverScore: {
      alignment:
        'The extent to which employees believe in and put effort toward this organization’s mission, values and goals.',
      engagement:
        'The extent to which employees feel passionate about their jobs, are committed to our organization, put discretionary effort into their work and are willing to refer.',
      fit: 'The extent to which employees feel they are in the right jobs and have the necessary skills, knowledge and abilities to adequately perform in their roles.',
      growth:
        'The extent to which employees feel supported to advance their career or develop personal skill sets within this organization.',
      team: 'The extent to which this organization promotes a team-based work style (i.e. departments or groups work well together).',
      valuing:
        'The extent to which employees feel valued by this organization.',
      custom_ratings_questions: 'Custom Ratings Questions',
    },
    topFiveText: {
      action: {
        title: 'Top 5 Action Areas',
        description:
          'Your employees ranked the following statements as low in effectiveness and high in importance (i.e. these items have the biggest ROI Gap). You will get the greatest return on investment if you focus your engagement efforts on these five areas (listed in order of priority).',
      },
      strength: {
        title: 'Top 5 Strengths',
        description:
          'Your employees ranked the following statements as high in effectiveness and high in importance (i.e. these items have the smallest ROI Gap). Use this data to promote your employee brand in talent attraction, engagement and retention strategies. These are your greatest strengths!',
      },
      importance: {
        title: 'Top 5 Most Important Items',
        description:
          'Your employees ranked the following five statements as the most important to them.',
      },
      topFiveResultDetails: {
        effectiveness: 'Effectiveness',
        importance: 'Importance',
        previousEffectiveness: 'Previous Effectiveness',
        benchmarkEffectiveness: 'Benchmark Effectiveness',
      },
      topFiveRecommendations: {
        description:
          'These actionable recommendations are based on your Top 5 Action Areas. This is where your efforts to boost engagement will give you the largest return on investment. Time to take action!',
        noRecommendations:
          'There are no Recommendations for the results displayed.',
      },
      tooltip: { roiGap: 'Difference between effectiveness and importance' },
    },
    filterButtons: { filters: 'Filters', download: 'Download' },
    downloadMenu: {
      pdfNewTab: 'Open in new tab (PDF)',
      pdfDownload: 'PDF',
      xlsxDownload: 'Spreadsheet (.xlsx)',
    },
    noData: 'There are no {title} for the results displayed.',
    turnoverOverviewDescription:
      'The likelihood employees will leave your organization within the next twelve months.',
    turnoverForecast: {
      statement: 'STATEMENT',
      atRisk: 'At Risk',
      passive: 'Passive',
      committed: 'Committed',
      employee: 'employee',
      employees: 'employees',
      potentialTurnover: 'Potential Turnover',
      previousTurnover: 'Previous Turnover',
      description:
        "Your short and long-term outlook is predicted using your employees' responses to the turnover statements in the survey.",
      shortTerm: 'Short-Term Outlook',
      longTerm: 'Long-Term Outlook',
    },
    comparisonGraphs: {
      description:
        'Now it’s time to take a closer look at engagement results across the organization. Simply hover over the bar charts to see specific results. Depending on your permission settings, you can use Filters to change the comparison graphs.',
      demographic: 'Demographic',
      legend: {
        engaged: 'Engaged',
        neutrals: 'Neutral',
        disengaged: 'Disengaged',
      },
    },
    fullReport: {
      engagementScore: 'Engagement Score',
      participationRate: 'Participation Rate',
      legend: {
        strengths: 'Strengths',
        recommendedActionAreas: 'Recommended Action Areas',
      },
      tableHeaders: {
        score: 'Score',
        result: 'Result',
        effectiveness: 'Effectiveness',
        importance: 'Importance',
        previousEffectiveness: 'Prev. Effec.',
        benchmark: 'Benchmark',
      },
      tableData: { viewRecommendations: 'View recommendations here' },
      openEndedQuestions: 'Open-Ended Questions',
      noCustomRatingQuestions:
        'No custom ratings questions were used in this survey.',
      noCustomOpenEndedQuestions:
        'No custom open-ended questions were used in this survey.',
      buttonLabels: {
        xlsx: 'Download answers as XLSX',
        pdf: 'Download answers as PDF',
      },
    },
    unlockPremiumReport: 'CLICK HERE TO UNLOCK THE PREMIUM REPORT',
    snackbar: {
      success: {
        surveyAddedToCart: 'Survey added to your cart successfully',
        filters: 'Your filters have been applied!',
      },
    },
  },
  report: {
    upgradeReport: 'Upgrade Report',
    freeReport: 'Free Report',
    premiumReport: 'Premium Report',
    shareReport: 'Share Report',
  },
  survey: {
    status: {
      scheduled: 'Scheduled',
      open: 'Open',
      closed: 'Closed',
      awaitingManualOpening: 'Awaiting Manual Launch',
    },
    snackbar: {
      success: {
        alreadyFinished: 'You already finished this survey',
        finishedSurvey: 'You finished the survey! Thanks for taking the time',
        giftCardRedeemed:
          'Gift Card redeemed successfully! Watch for an email with your electronic gift card link.',
      },
    },
  },
  surveyKey: {
    status: {
      surveykey: 'Survey Key',
      paragraph1:
        'L’enquête utilise les visages et les étoiles pour la notation.  Évaluez votre expérience en milieu de travail et son importance pour chaque déclaration. La satisfaction est basée sur les visages et l’importance est basée sur les étoiles de 1 à 5. ',
      paragraph2: 'Évaluez votre expérience d’utilisation de notre produit.',
      title1: 'Satisfaction',
      title2: 'Importance',
    },
  },
  surveyinstruction: {
    surveyinstructionkey: 'Instructions pour l’enquête',
    surveytitle: ' Cette enquête comporte un',
    twopart: 'Processus de notation en deux parties',
    using: 'pour chaque instruction utilisant',
    faces: 'Visages',
    and: 'et',
    star: 'Étoiles.',
    rate: 'Évaluez combien vous',
    agree: 'd’accord ou pas d’accord',
    statement: 'avec chaque instruction utilisant le',
    then: 'Et puis',
    how: 'comment',
    important: 'importante',
    eachstatement:
      'Chaque énoncé vous est adressé sur le lieu de travail à l’aide d’un',
    starrating: '5 étoiles',
    scale: 'écaille.',
  },
  surveyDialogs: {
    title: 'enquête',
    addSurvey: {
      descriptionFirstLine:
        'It’s time to make the survey your own. First, select your launch timeline below to get started. You can make changes before the survey opens and extend the survey before it closes. Click',
      link: 'HERE',
      descriptionSecondLine:
        'for best practices to launch and market the survey.',
    },
    editSurvey: {
      description:
        'Need to edit your survey? No problem. You can do that here. Please make your changes below.',
    },
    extendSurvey: { title: 'Extend Survey', closeDate: 'Close Date' },
    confirmClose: {
      title: 'Close Survey',
      actionLabel: 'Close Survey',
      close: 'close',
    },
    confirmDelete: { title: 'Remove Survey', actionLabel: 'Archive Survey' },
    formLabels: {
      surveyName: 'Survey Name',
      launchDate: 'Launch Date',
      closeDate: 'Close Date',
      allowReward: 'Reward survey participation with a Gift Card',
      allowRewardTooltip:
        'Employees will be able to select their gift card directly from our system after they participate',
      allowAnswersToBeUpdated:
        'Give employees permission to edit their survey responses',
      allowAnswersToBeUpdatedTooltip:
        'Edits can only be made when the survey is open',
      rewardAmount: 'Gift Card Amount',
    },
    customizedQuestions: {
      header: 'Add Custom Questions/Statements',
      description:
        'In addition to the 38 questions in our statistically valid #BeHeard engagement survey, you can add up to five 5-star rating questions and five open ended questions. If you want some ideas for your custom questions, check out examples',
      link: 'HERE',
      statement: 'Statement',
      statementPlaceholder: 'Type your question/statement...',
      fiveStarQuestions: { header: '5-Star Rating Questions' },
      openEndedQuestions: { header: 'Open-Ended Questions' },
    },
  },
  surveyLanding: {
    welcomeToSparck: 'Bienvenue à Sparck!',
    areYouReady: 'Êtes-vous prêt à {beheard}?',
    thankYou: 'Merci de faire entendre votre voix.',
    noActiveSurveys:
      "There is no active survey to complete right now, but we're glad you checked in.",
    whatBeHeardSurveyIs:
      'Le sondage #BeHeard est un sondage anonyme conçu pour être un endroit sûr où vous pouvez partager vos commentaires sur le lieu de travail. Votre organisation se soucie de ce que vous pensez et veut vous donner une voix confidentielle pour parler librement, cest pourquoi elle sest associée à Sparck ! Seuls les résultats agrégés seront partagés à la fin pour aider à façonner les stratégies organisationnelles en fonction des domaines qui comptent le plus pour vous et vos collègue.',
    youAreAwesome:
      'Vous êtes génial! Merci davoir pris le temps de #BeHeard. Vos commentaires sont inestimables.',
    feedbackImportance:
      "Your feedback is really important to us, so we'll send you an email as soon as a new survey is posted!",
    eachItemExplanation:
      "Le sondage ne vous prendra que 10 minutes environ. Chaque élément comportera deux parties : premièrement, vous évaluerez dans quelle mesure vous êtes d'accord ou en désaccord avec chaque énoncé sur le lieu de travail en choisissant l'un des visages. Une fois que vous avez fait cela, vous évaluerez l'importance de chaque énoncé pour vous en utilisant les étoiles. Vérifiez l'exemple animé!",
    iAmReady: 'Je suis prêt à répondre au sondage!',
    updateMyAnswers: 'Review my answers!',
    surveyPagination: { previous: 'Précédent', nextPage: 'Page suivante' },
    submitSurvey: 'Soumettez lenquête',
    pendingGiftCards: 'You have {count} pending Gift Cards! 🎉',
    pendingGiftCardLabel: '{rewardValue} for {surveyName}',
    redeem: 'Redeem',
    giftCardRedeemed: 'Gift Card redeemed successfully!',
    departmentTerm: 'Service',
    departmentValue: ' ',
    missionAndValueTerm: 'Mission et valeurs',
    missionAndValueText:
      'Nous bâtissons quelque chose de spécial, en visant à obtenir les meilleurs résultats pour nos clients grâce à la connectivité intelligente et à l’automatisation',
    missionAndValueValue:
      'Valeurs : Être audacieux. Être soi-même. Être Boomi.',
    managerTerm: 'Responsable',
    managerValue: 'Votre responsable direct au 8 juin 2023',
    seniorLeadershipTeamTerm: 'Équipe de direction',
    seniorLeadershipTeamValue: 'Membres de la haute direction',
    surveyInstructionsTitle: 'Survey Instructions',
    surveyInstructionsContent:
      'This survey has a two-part rating process for each statement using Faces  and Stars.  Rate how much you agree or disagree with each statement using the faces and then how important each statement is to you in the workplace using a 5-star rating scale.',
    boomiSurveyLandingContent:
      'L’enquête #BeHeard est une enquête anonyme dont le but est de vous permettre de partager en toute confidentialité vos commentaires sur votre expérience chez Boomi.  Boomi se soucie de ce que vous pensez et souhaite vous donner un moyen de vous exprimer librement en toute confidentialité, c’est pourquoi la société a conclu un partenariat avec Sparck ! Seuls les résultats cumulés seront communiqués à la fin de l’enquête en vue de contribuer à l’élaboration de stratégies pour l’avenir de l’organisation, en tenant compte de ce qui compte le plus pour vous et vos collègues chez Boomi. Avant de commencer, voici quelques définitions pour que tout le monde soit au diapason :',
    boomiSurveyLandingContentTiming:
      'Il ne vous faudra qu’une quinzaine de minutes pour répondre à l’enquête. Chaque question comporte deux parties. Tout d’abord, vous indiquerez dans quelle mesure vous êtes d’accord ou non avec chaque affirmation concernant le lieu de travail en choisissant l’un des visages. Ensuite, vous indiquerez l’importance que vous accordez à chaque affirmation à l’aide des étoiles. Voici une animation qui illustre ce processus!',
    topicCards: {
      secure: {
        title: 'Vos données sont {keyword}',
        keyword: 'Sécurisé',
        description:
          'Nous prenons la protection des données très au sérieux. Sparck utilise un ensemble de technologies et de protocoles qui empêchent les fuites de données et les cyberattaques.',
      },
      anonymous: {
        title: 'La participation est {keyword}',
        keyword: 'Anonyme',
        description:
          'La confidentialité est très importante pour nous ! Votre organisation ou votre responsable ne pourra jamais voir vos réponses individuelles. Les données que nous fournissons sont strictement anonymes.',
      },
      giftCard: {
        title: 'This Survey Has a {keyword}!',
        keyword: 'Gift Card',
        description:
          'That’s right! As a “Thank You” for completing the survey, your organization is treating you to a giftcard of your choice. You can redeem it at the end. Enjoy!',
      },
      answersUpdate: {
        title: '{keyword} Your Answers',
        keyword: 'Update',
        description:
          'Great news! You can come back to update and edit your answers at any point while the survey is still open. Just log back in!',
      },
    },
  },
  surveyOverview: {
    snackbarSuccess: {
      openSurvey: 'The survey is now open!',
      extendedSurvey: 'The survey close date has been extended successfully!',
      closedSurvey: 'The survey has been closed!',
      deletedSurvey: 'The survey has been archived!',
      employeesInvited: 'Employees have been invited successfully!',
      addedToCart: 'Survey added to your cart successfully',
    },
    selectLabel: 'Survey',
    minimumNumberOfEmployees:
      'Your organization must have at least 5 employees to create a new survey.',
    whenSurveyCloses:
      'When your survey closes the #BeHeard results will be immediately available.',
    scheduledLaunchSurveyInfo:
      "Your survey isn't live yet.  It will Launch Automatically on {launchDate}. If you would like to launch it manually you can click the Launch Survey button or edit your survey to Launch Manually.",
    manualLaunchSurveyInfo:
      "Your survey isn't live yet and it is set to Launch Manually. When you are ready to launch your survey, click the Launch Survey button.",
    newSurvey: 'New Survey',
    launchSurvey: 'Launch Survey',
    editSurvey: 'Edit Survey',
    extendSurvey: 'Extend Survey',
    closeSurvey: 'Close Survey',
    applyUpgrade: 'Apply Upgrade',
    printSurvey: 'Print',
    applyDowngrade: 'Apply Downgrade',
    inviteEmployees: 'Invite Employees',
    deleteSurvey: 'Archive Survey',
    anticipatedTurnover: 'Anticipated Turnover more than 12 Months Away',
    overallParticipation: 'Overall Participation Rate',
    person: 'Person',
    people: 'People',
    employeesInvitation: {
      inviteEmployees: 'Invite Employees',
      chooseEmployees: 'Choose below the employees you want to invite for',
      textFieldLabel: 'Choose one or more employees',
      cancelButton: 'Cancel',
      inviteButton: 'Invite Employees',
    },
    tabs: { labels: { general: 'General', sharedReports: 'Shared Reports' } },
    validations: {
      surveyNameIsRequired: 'Survey Name is required',
      minimumLaunchDate: 'You cannot select a past date',
      maximumLaunchDate: 'Launch Date cannot be after the Close Date',
      launchDateIsRequired: 'Launch Date is required',
      minimumCloseDate: 'Close Date cannot be before Launch Date',
      closeDateIsRequired: 'Close Date is required',
      rewardAmountIsRequired: 'Gift Card amount is required',
    },
  },
  surveyProgress: {
    bulletLabels: {
      createdOn: 'Created on',
      openOn: 'Open on',
      closedOn: 'Closed on',
      willCloseOn: 'Will Close on',
    },
  },
  ratingSection: {
    toWhatExtent:
      'Dans quelle mesure êtes-vous daccord ou en désaccord avec cette affirmation?',
    important: 'Cest important pour moi',
    ratingLabels: {
      stronglyDisagree: 'Pas du tout daccord',
      disagree: 'Être en désaccord',
      neutral: 'Neutre',
      agree: 'Accepter',
      stronglyAgree: 'Tout à fait daccord',
      notApplicable: 'Nest pas applicable',
    },
    change: 'Changement',
    unansweredItem: 'Please respond to every item before continuing.',
    formLabels: {
      textField: 'Your answer',
      placeholder: 'Type anything you want here...',
      helperText:
        'To ensure your confidentiality, do not put any personally identifiable information.',
    },
  },
  manageShareReport: {
    reportSections: {
      labels: {
        comparisonGraph: 'Comparison Graph',
        results: 'Results',
        topFiveActionAreas: 'Top 5 Action Areas',
        topFiveStrengths: 'Top 5 Strengths',
        topFiveMostImportantItems: 'Top 5 Most Important Items',
        recommendations: 'Recommendations',
        turnoverForecast: 'Employee turnover forecast',
        fullReport: 'Full Report',
        customRatingQuestions: 'Custom Ratings Questions',
        customOpenEndedQuestions: 'Custom Open-Ended Questions',
      },
    },
    previewFor: 'Preview for {name}',
    reportWillBeSharedWithRoles: 'This report will be shared with',
    formHandler: {
      title: 'Share Report',
      labels: {
        name: 'Shared Report Name',
        expirationDate: 'How long is it available?',
        whatToSee: 'What can they see?',
        limitReport: 'Limit Report by Demographics',
        whichRoles: 'Which Roles can see this Share Report?',
        roles: 'Roles',
        whichDemographics: 'Which Demographics will receive this Share Report?',
      },
      placeholders: { beheardReport: '#BeHeard ShareReport 001' },
      dialog: {
        title: 'Warning!',
        content: {
          firstLine:
            "Are you sure you want to give access to your employee's answers?",
          secondLine:
            'The answers contain very sensitive information. Please be absolutely sure that you selected the correct demographics for this action.',
        },
        buttonText: 'I understood!',
      },
    },
    snackbars: { invitesSent: 'Invites sent successfully' },
  },
  inviteShareReport: {
    dialogTitle: 'Invite for Share Report',
    dialogActionLabel: 'Share this report',
    clickToInvite:
      'Your Shared Report was created but you need to invite. Click "Share this report" to send e-mails to your invitees',
  },
  shareReport: {
    title: 'Share Reports',
    description: 'You control who has access to your #BeHeard Premium Reports',
    columnHeaders: {
      sharedReport: 'Shared Report Name',
      surveyName: 'Survey Name',
      expires: 'Expires',
    },
    validations: {
      minimumExpirationDate: 'You cannot select a past date',
      expirationDateIsRequired: 'Expiration Date is required',
    },
  },
}
