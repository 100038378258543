import { ReactNode } from 'react'
import { Card, Box, CardContent } from '@mui/material'

type CardSectionProps = {
  children: ReactNode
}

export default function ManagerCertificationdSection({
  children,
}: CardSectionProps) {
  return (
    <Box mb={3}>
      <Card elevation={2}>
        <Box sx={{ py: 1, px: { xs: 0, sm: 2 } }}>
          <CardContent>{children}</CardContent>
        </Box>
      </Card>
    </Box>
  )
}
