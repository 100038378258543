import { useState } from 'react'

import { Box, Grid, Tab, Tabs } from '@mui/material'

import {
  SparckGiftCardsRewardsList,
  SparckVirginExperiencesRewardsList,
} from '../../components'
import { a11yTabProps } from 'utils/accessibility'
import { useCompany } from 'contexts'

enum RewardTab {
  GiftCards = 'giftCards',
  VirginExperiences = 'virginExperiences',
}

export default function SparckRetailerRewardsList(props: any) {
  const { rewardsNGC, rewardsVirginExperiences } = props

  const { company } = useCompany()!

  const [selectedTab, setSelectedTab] = useState(RewardTab.GiftCards)

  const retailerRewardsTypes = [
    {
      value: RewardTab.GiftCards,
      label: 'Gift Cards',
      Component: SparckGiftCardsRewardsList,
      display: true,
      props: {
        rewardsNGC: rewardsNGC,
      },
    },
    {
      value: RewardTab.VirginExperiences,
      label: 'Experiences',
      Component: SparckVirginExperiencesRewardsList,
      display: company?.virgin_experience_enabled,
      props: {
        rewardsVirginExperiences: rewardsVirginExperiences,
      },
    },
  ]

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  return (
    <Grid container justifyContent='center' spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
          <Tabs value={selectedTab} onChange={handleTabChange}>
            {retailerRewardsTypes
              .filter(tab => tab.display)
              .map((tab, index) => (
                <Tab
                  key={index}
                  data-cy='tabs'
                  value={tab.value}
                  label={tab.label}
                  {...a11yTabProps(index)}
                />
              ))}
          </Tabs>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {retailerRewardsTypes.map(
              retailerRewardType =>
                selectedTab === retailerRewardType.value && (
                  <retailerRewardType.Component
                    {...retailerRewardType.props}
                    {...props}
                    key={retailerRewardType?.label}
                  />
                ),
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

// TO-DO: VIRGIN EXPERIENCES GIFT CARD
