import { useEffect, useState } from 'react'

import { Grid, Typography } from '@mui/material'

import { CRUDTable, DialogTeleport } from 'components'
import { Props as VirginExperienceProps } from 'pages/virginExperiencesSettings/VirginExperiencesSettings.props'

export default function VirginExperiencesSettingsUpdateAlertsDialog(
  props: VirginExperienceProps,
) {
  const {
    virginExperiencesSettings,
    updateCreditAlerts,
    updateCreditAlertsDialogOpen,
    setUpdateCreditAlertsDialogOpen,
  } = props

  const [creditAlerts, setCreditAlerts] = useState<any[]>([])

  const handleDialogClose = () => setUpdateCreditAlertsDialogOpen(false)

  useEffect(() => {
    if (virginExperiencesSettings?.remaining_credit_alerts) {
      setCreditAlerts([...virginExperiencesSettings.remaining_credit_alerts])
    }
  }, [virginExperiencesSettings])

  return (
    <DialogTeleport
      dialogOpen={updateCreditAlertsDialogOpen}
      handleDialogClose={handleDialogClose}
      dialogSize={'xs'}
      dialogTitle={"Update Sparck's Alerts"}
      dialogAction={{
        function: () =>
          updateCreditAlerts.mutate({
            amounts: creditAlerts
              ?.filter(alert => !alert?._destroy)
              .map(alert => alert.remaining_amount),
          }),
        label: 'Update Alerts Limit',
        disabled: updateCreditAlerts.isLoading,
        isLoading: updateCreditAlerts.isLoading,
      }}
      isFullWidth
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='body1' color='text.secondary' paragraph>
            Enter the dollar amounts you want to be alerted at
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <CRUDTable
            rows={creditAlerts}
            setRows={setCreditAlerts}
            columns={[
              {
                id: 'remaining_amount',
                label: 'Amount',
                required: true,
                showEdit: () => true,
                type: 'money',
                defaultValue: '',
              },
            ]}
            limit={20}
            showAdd
            showEdit={() => true}
            showRemove={() => true}
          />
        </Grid>
      </Grid>
    </DialogTeleport>
  )
}
