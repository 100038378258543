import { useEffect } from 'react'

import { Grid, Typography } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { MFAFactorSetupProps } from '../MFAFactorSetup/MFAFactorSetup'
import { PhoneNumberInputWithFormatPicker } from 'components'
import { MFAFactorType } from 'enums/multiFactorAuthentication'
import { t } from 'i18n'

const phoneNumberInitialValues: { phone_number: string } = {
  phone_number: '',
}

type Props = MFAFactorSetupProps & {
  activeStep: any
  userClickedNextPage: boolean
  setUserClickedNextPage(value: boolean): void
}

export default function MFAEnterPhoneNumber(props: Props) {
  const {
    userClickedNextPage,
    setUserClickedNextPage,
    activeStep,
    selectedFactor,
  } = props

  const validationSchema = yup.object({
    phone_number: yup.string().required(),
  })

  const formik = useFormik({
    initialValues: phoneNumberInitialValues,
    onSubmit: () => {},
    validationSchema: validationSchema,
  })

  const phoneNumberInputProps = {
    id: 'phone_number',
    name: 'phone_number',
    label: t('phoneNumber'),
    data_cy: 'phone_number',
  }

  useEffect(() => {
    if (userClickedNextPage) {
      activeStep?.onClickNextPage(formik.values.phone_number)
      setUserClickedNextPage(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userClickedNextPage])

  if (selectedFactor?.id === MFAFactorType.SMS) {
    return (
      <Grid container>
        <Grid item xs={12} sx={{ my: 2 }}>
          <Typography>{t('mfa.enterPhoneNumber')}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            component='form'
            spacing={2}
            onSubmit={formik.handleSubmit}
          >
            <PhoneNumberInputWithFormatPicker
              formik={formik}
              phoneNumberInputProps={phoneNumberInputProps}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return null
}
