import {
  Download as DownloadIcon,
  Print as PrintIcon,
  ContentCopy as CopyIcon,
} from '@mui/icons-material'
import { Box, Grid, IconButton, Typography } from '@mui/material'

import { MFAFactorSetupProps } from '../MFAFactorSetup/MFAFactorSetup'
import { t } from 'i18n'
import {
  copyToClipboard,
  donwnloadOrPrintBackupCodes,
} from 'utils/multiFactorAuthentication'

type Props = MFAFactorSetupProps & {
  activeStep: any
}

export default function MFABackupCodes(props: Props) {
  const { backupCodes } = props

  const backupCodeActions = [
    {
      id: 'download',
      label: t('mfa.mfaFactors.backupCodes.actions.download'),
      Icon: DownloadIcon,
      onClick: () => donwnloadOrPrintBackupCodes(backupCodes, 'download'),
    },
    {
      id: 'print',
      label: t('mfa.mfaFactors.backupCodes.actions.print'),
      Icon: PrintIcon,
      onClick: () => donwnloadOrPrintBackupCodes(backupCodes, 'print'),
    },
    {
      id: 'copy',
      label: t('mfa.mfaFactors.backupCodes.actions.copy'),
      Icon: CopyIcon,
      onClick: () => copyToClipboard(backupCodes?.toString()),
    },
  ]

  const outlineStyle = '2px solid gray'

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justifyContent='center'>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '80%',
              outline: outlineStyle,
              borderRadius: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 2,
                backgroundColor: '#e3e8ec',
              }}
            >
              <Grid container spacing={2}>
                {backupCodes?.map(code => (
                  <Grid item xs={12} lg={6} textAlign='center' key={code}>
                    <Typography fontWeight='500'>{code}</Typography>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box sx={{ borderTop: outlineStyle, width: '100%' }}>
              <Grid container>
                {backupCodeActions.map((action, index) => (
                  <Grid
                    item
                    xs={4}
                    sx={{
                      textAlign: 'center',
                      borderRight:
                        index !== backupCodeActions.length - 1
                          ? outlineStyle
                          : null,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: 1,
                      }}
                    >
                      <IconButton onClick={action.onClick} size='large'>
                        <action.Icon color='primary' />
                      </IconButton>
                      <Typography
                        variant='body1'
                        fontWeight={500}
                        color='text.primary'
                        paragraph
                      >
                        {action.label}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}
