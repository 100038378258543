import { Grid, Typography } from '@mui/material'
import { QRCodeSVG } from 'qrcode.react'

import { MFAFactorSetupProps } from '../MFAFactorSetup/MFAFactorSetup'
import { t } from 'i18n'
import { useEffect } from 'react'

type Props = MFAFactorSetupProps & {
  activeStep: any
  userClickedNextPage: boolean
  setUserClickedNextPage(value: boolean): void
}

export default function MFAScanQRCode(props: Props) {
  const {
    QRCodeDetails,
    userClickedNextPage,
    setUserClickedNextPage,
    activeStep,
  } = props

  useEffect(() => {
    if (userClickedNextPage) {
      activeStep?.onClickNextPage()
      setUserClickedNextPage(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userClickedNextPage])

  return (
    <Grid container justifyContent='center' spacing={2}>
      <>
        <Grid item xs={12} sx={{ my: 2 }}>
          <Typography align='center'>
            {t('mfa.youNeedAnAuthApp', {
              example: (
                <Typography
                  component='a'
                  href='https://authy.com/'
                  target='_blank'
                  rel='noopener noreferrer'
                  color='primary'
                >
                  Authy
                </Typography>
              ),
            })}{' '}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1' fontWeight={700} align='center'>
            {t('mfa.scanQRCode')}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Grid container justifyContent='center'>
            <Grid item>
              <QRCodeSVG value={QRCodeDetails?.url} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography align='center' fontWeight={500}>
              {QRCodeDetails?.secret}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography align='center'>{t('mfa.whenYouAreDone')}</Typography>
        </Grid>
      </>
    </Grid>
  )
}
