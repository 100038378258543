/* eslint-disable @typescript-eslint/no-unused-vars */
import { forwardRef, useEffect, useState } from 'react'
import { Box,CircularProgress, circularProgressClasses, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Edit as EditIcon, Clear as ClearIcon, SaveAlt } from '@mui/icons-material'

import MaterialTable, {
  MTableAction,
} from '@material-table/core'
import { ExportCsv, ExportPdf } from '@material-table/exporters'

import {
  getSortedColumnsFromLocalStorage,
  handleOnColumnDragged,
} from 'utils/tables/localStorage'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { materialTableIcons } from 'utils/tables/icons'
import { SparckTableProps } from './SparckTable.types'
import { getSwitch } from 'utils/tables/dataTypes'
import { useIntl } from 'react-intl'
import SnackbarUtils from 'core/SnackbarConfiguration'

import { API } from 'core/requests'
import { useQuery, useQueryClient } from 'react-query'
import dayjs from 'dayjs'

const useStyles = makeStyles((theme: any) => ({
  gridContainer: {
    '& .MuiToolbar-root': {
      flexDirection: 'column',

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    '& .MuiTableCell-paddingNone': {
      '& div': {
        justifyContent: 'center',
      },
    },
    '& .MuiTableCell-head': {
      backgroundColor: theme.palette.background.paper,
      padding: 16,
    },
  }
}))

/**
 * Material-UI Table with additional features, such as column reordering, responsiveness, native & custom actions, CSV export etc
 * @param {*} props
 */
const SparckTable = (props: SparckTableProps) => {
  const intl = useIntl()

  const translate = id => intl.formatMessage({ id: id })

  const {
    slug,
    data = [] as Record<any, unknown>[],
    isLoading,
    columns,
    pagination,
    setPagination,
    editAction,
    deleteAction,
    actions = [],
    options,
    style,
    updateItem,
    hasStatusSwitch,
    hideToolbar,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    totalCount,
    setSearchText,
    ...rest
  } = props

  const classes = useStyles()
  const [rowData, setData] = useState<any>(data)
  const [rows, setRows] = useState<any>([])
  const [offset, setOffset] = useState(0)
  const [enabled, setEnabled] = useState(false)
  const [cols, setCols] = useState([])
  const [exportType, setExportType] = useState()
  const [progress, setProgress] = useState(0)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const queryClient = useQueryClient()

  const renderSwitch = (row: any) => getSwitch(row, 'status', updateItem)

  if (hasStatusSwitch && !columns.find(column => column.field === 'status')) {
    columns.push({
      title: 'Status',
      field: 'status',
      render: renderSwitch,
      editable: 'never' as any,
    })
  }

  /**
   *  Updates Local Storage everytime pagination's value changes
   */
  useEffect(() => {
    if (pagination) {
      localStorage.setItem(`${slug}PageSize`, pagination?.pageSize.toString())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  useEffect(() => {
    setData([...data])
  }, [data])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isFetching: dataIsLoading, refetch: fetchData } = useQuery(
    [slug, offset],
    async () => {
      const response = await API.get('employees', null, {
        queryParams: {
          limit: 100,
          offset: offset,
        },
      })
      return response
    },
    {
      onSuccess: response => {
        if (response?.users) {
          const newProgress = (rows.length / response?.users_count) * 100
          setProgress(Math.round(newProgress))
          setRows(prevRows => [...prevRows, ...response?.users])
          if (rows.length < response?.users_count) {
            setOffset(prevOffset => prevOffset + 100)
          } else {
            setEnabled(false)
            const finalData = exportDataAdapter();
            
            if (exportType === 'pdf') {
              ExportPdf(cols, finalData, `${slug}-pdf-data`)
            } else {
              ExportCsv(cols, finalData, `${slug}-csv-data`)
            }
            setRows([])
            setOffset(0)
            setEnabled(false)
            setCols([])
            setExportType(undefined)
            setProgress(0)
          }
        }
      },
      onError: err => {
        SnackbarUtils.error('Error while Exporting file')
        setRows([])
        setOffset(0)
        setEnabled(false)
        setCols([])
        setExportType(undefined)
        setProgress(0)
      },

      enabled: enabled,
    },
  )

  const exportDataAdapter = (): any =>{
    const data = rows.map((data)=>{
      const tempSupervior:any = []
        data?.superiors?.forEach((supervisor:any)=>{
          tempSupervior.push(`${supervisor?.superior?.first_name} ${supervisor?.superior?.last_name}`)
        })
      const temp:any =[]
      const tempObj = {
        "ID":data?.id,
        "Name":data?.full_name,
        "E-mail":data?.email,
        "Roles":data?.roles.join('; '),
        "Title":data?.title,
        "Hire Date": " "+dayjs(data?.hired_at).format('MM/DD/YYYY').trim(),
        "Date of Birth": " "+dayjs(data?.date_of_birth).format('MM/DD/YYYY').trim(),
        "Location":data?.demographics?.Location,
        "Region":data?.demographics?.Region,
        "Territory":data?.demographics?.Territory,
        "Department":data?.demographics?.Department,
        "Business Unit":data?.demographics?.['Business Unit'],
        "Supervisors":tempSupervior.join("; "),
      }
      cols.map((col:any)=>{
        temp.push(tempObj[col.title])
      })
      return temp;
    })
    return data;
  }

  const exportServerData = (cols, type) => {
    setEnabled(true)
    setCols(cols)
    setExportType(type)
  }

  return (
    <Grid container className={classes.gridContainer}>
      <Grid
        item
        xs={12}
        className={
          !options?.selection &&
          (actions.filter(action => !action.isFreeAction).length > 0 ||
            editAction ||
            deleteAction)
            ? 'sparck-table-with-actions'
            : ''
        }
      >
        <MaterialTable
          {...rest}
          // data={rowData}
          data={
            slug === 'employees'
              ? query =>
                  new Promise((resolve, reject) => {
                    const offset = query.page * query.pageSize
                    API.get('employees', null, {
                      queryParams: {
                        limit: query.pageSize,
                        offset: offset,
                        search: query.search,
                      },
                    })
                      .then(response => {
                        resolve({
                          data: response.users,
                          page: query.page,
                          totalCount: response.users_count,
                        })
                      })
                      .catch(error => {
                        reject(error)
                      })
                  })
              : rowData
          }
          isLoading={isLoading}
          columns={getSortedColumnsFromLocalStorage(
            `${slug}ColumnsOrder`,
            columns,
          )}
          totalCount={(data ?? []).length}
          actions={[
            ...(editAction
              ? [
                  rowData => ({
                    icon: () => (
                      <EditIcon
                        color={
                          editAction.disabled && editAction.disabled(rowData)
                            ? 'disabled'
                            : 'primary'
                        }
                      />
                    ),
                    tooltip:
                      editAction.tooltip ?? translate('table.body.editTooltip'),
                    onClick: (_, row) => editAction.onClick(row),
                    hidden: editAction.hidden && editAction.hidden(rowData),
                    disabled:
                      editAction.disabled && editAction.disabled(rowData),
                  }),
                ]
              : []),
            ...(deleteAction
              ? [
                  rowData => ({
                    icon: () => (
                      <ClearIcon
                        color={
                          deleteAction.disabled &&
                          deleteAction.disabled(rowData)
                            ? 'disabled'
                            : 'error'
                        }
                      />
                    ),
                    tooltip:
                      deleteAction.tooltip ??
                      translate('table.body.deleteTooltip'),
                    onClick: (_, row) => deleteAction.onClick(row),
                    hidden: deleteAction.hidden && deleteAction.hidden(rowData),
                    disabled:
                      deleteAction.disabled && deleteAction.disabled(rowData),
                  }),
                ]
              : []),
            ...actions
              .filter(action => !action.isFreeAction)
              .map((action: any) => rowData => ({
                tooltip: action.tooltip && action.tooltip(rowData),
                hidden: action.hidden && action.hidden(rowData),
                disabled: action.disabled && action.disabled(rowData),
                onClick: (_, row) => action.onClick && action.onClick(_, row),
                icon: () => action.icon && action.icon(rowData),
              })),
            // ...actions
            //   .filter(action => action.isFreeAction)
            //   .map((action: any) => ({
            //     ...action,
            //     tooltip: action.tooltip && action.tooltip(),
            //     hidden: action.hidden && action.hidden(),
            //     disabled: action.disabled && action.disabled(),
            //   })),
          ]}
          {...(pagination &&
            setPagination && {
              onPageChange: (currentPage, pageSize) => {
                setPagination({
                  pageSize: pageSize ? pageSize : pagination.pageSize,
                  currentPage: currentPage,
                })
              },
              onRowsPerPageChange: pageSize => {
                setPagination({
                  pageSize: pageSize ? pageSize : pagination.pageSize,
                  currentPage: 0,
                })
              },
            })}
            icons={{
              Export: forwardRef((props, ref) => {
                
             return( <> {enabled ? (
                <Box sx={{ position: "relative" ,display:"inline-block"}}>
                  <div style={{position:'absolute',    left: '-8px', bottom: '-9px'}}>
                    <CircularProgress
                      variant="determinate"
                      sx={{
                        color: (theme) =>
                        theme.palette.grey[300] ,
                      }}
                      size={40}
                      thickness={4}
                      {...props}
                      value={100}
                    />
                    <CircularProgress
                      variant="determinate"
                      disableShrink
                      sx={{
                        color: (theme) =>
                          "#1EB3AB",
                        animationDuration: "550ms",
                        position: "absolute",
                        left: 0,
                        [`& .${circularProgressClasses.circle}`]: {
                          strokeLinecap: "round"
                        }
                      }}
                      size={40}
                      thickness={4}
                      {...props}
                      value={progress}
                    />
                  </div>
                  <SaveAlt  style={{color: enabled?'#dddddd':null,pointerEvents:'none !important'}} {...props} />
                </Box>
              ) : <SaveAlt  style={{color: enabled?'#dddddd':null,pointerEvents:'none !important'}} {...props} />}
              
              </>)
            })
            }}
          onColumnDragged={(source: number, destination: number) => {
            handleOnColumnDragged(
              `${slug}ColumnsOrder`,
              columns,
              source,
              destination,
            )
          }}
          {...(slug === 'employees'
            ? {
                onSearchChange: searchText => {
                  setSearchText && setSearchText(searchText)
                },
              }
            : {})}
          options={{
            actionsColumnIndex: -1,
            columnsButton: true,
            debounceInterval: 200,
            emptyRowsWhenPaging: false,
            exportAllData: true,
            toolbar: !hideToolbar,
            paging: !!pagination,
            exportMenu: [
              {
                label: translate('table.toolbar.exportPDFName'),
                exportFunc: (cols, datas) => {
                  if (slug === 'employees') {
                    if(!enabled) {

                      exportServerData(cols, 'pdf')
                    }else {
                      SnackbarUtils.warning('Export already in progress')
                    }
                  } else {
                    ExportPdf(cols, datas, `${slug}-pdf-data`)
                  }
                },
              },
              {
                label: translate('table.toolbar.exportCSVName'),
                exportFunc: (cols, datas) => {
                  if (slug === 'employees') {
                    if(!enabled) {

                      exportServerData(cols, 'csv')
                     } else {
                      SnackbarUtils.warning('Export already in progress')
                    }
                  } else {
                    const finalData = datas.map(data => {
                      return data.map(info => {
                        if (Array.isArray(info)) {
                          return info
                            .map(data => {
                              if (typeof data === 'object') {
                                return (
                                  data.superior.first_name +
                                  ' ' +
                                  data.superior.last_name
                                )
                              } else {
                                return data
                              }
                            })
                            .join('; ')
                        } else {
                          return info
                        }
                      })
                    })

                    return ExportCsv(cols, finalData, `${slug}-csv-data`)
                  }
                },
              },
            ],
            pageSize: 10,
            pageSizeOptions: [5, 10, 20, 50],
            searchFieldAlignment: 'left',
            showTitle: false,
            ...options,
          }}
          localization={{
            body: {
              emptyDataSourceMessage: translate(
                'table.body.emptyDataSourceMessage',
              ),
              addTooltip: translate('table.body.addTooltip'),
              deleteTooltip: translate('table.body.deleteTooltip'),
              editTooltip: translate('table.body.editTooltip'),
              filterRow: {
                filterPlaceHolder: translate(
                  'table.body.filterRow.filterPlaceHolder',
                ),
                filterTooltip: translate('table.body.filterRow.filterTooltip'),
              },
              editRow: {
                deleteText: translate('table.body.editRow.deleteText'),
                cancelTooltip: translate('table.body.editRow.cancelTooltip'),
                saveTooltip: translate('table.body.editRow.saveTooltip'),
              },
            },
            grouping: {
              placeholder: translate('table.grouping.placeholder'),
              groupedBy: translate('table.grouping.groupedBy'),
            },
            header: {
              actions: translate('table.header.actions'),
            },
            pagination: {
              labelDisplayedRows: translate(
                'table.pagination.labelDisplayedRows',
              ),
              labelRowsSelect: translate('table.pagination.labelRowsSelect'),
              labelRowsPerPage: translate('table.pagination.labelRowsPerPage'),
              firstAriaLabel: translate('table.pagination.firstAriaLabel'),
              firstTooltip: translate('table.pagination.firstTooltip'),
              previousAriaLabel: translate(
                'table.pagination.previousAriaLabel',
              ),
              previousTooltip: translate('table.pagination.previousTooltip'),
              nextAriaLabel: translate('table.pagination.nextAriaLabel'),
              nextTooltip: translate('table.pagination.nextTooltip'),
              lastAriaLabel: translate('table.pagination.lastAriaLabel'),
              lastTooltip: translate('table.pagination.lastTooltip'),
            },
            toolbar: {
              addRemoveColumns: translate('table.toolbar.addRemoveColumns'),
              nRowsSelected: translate('table.toolbar.nRowsSelected'),
              showColumnsTitle: translate('table.toolbar.showColumnsTitle'),
              showColumnsAriaLabel: translate(
                'table.toolbar.showColumnsAriaLabel',
              ),
              exportTitle: translate('table.toolbar.exportTitle'),
              exportAriaLabel: translate('table.toolbar.exportAriaLabel'),
              exportPDFName: translate('table.toolbar.exportPDFName'),
              exportCSVName: translate('table.toolbar.exportCSVName'),
              searchTooltip: translate('table.toolbar.searchTooltip'),
              searchPlaceholder: translate('table.toolbar.searchPlaceholder'),
            },
          }}
          components={{
            Action: props => (
              // <span style={{ marginRight: -15 }}>
              <MTableAction {...props} />
              // </span>
            )
            //  ...(slug === 'employees'? {Pagination: props=>{
            //   const {count,onPageChange,page,...rest} = props

            //   return <MTablePagination
            //   page={pagination?.currentPage}
            // onPageChange={(e,currentPage)=>{
            //   setPagination && setPagination({pageSize:props.rowsPerPage?props.rowsPerPage:pagination?.pageSize,currentPage:currentPage})
            // }}
            //   count={totalCount} {...rest} />
            // }} :{})
          }}
          style={{
            boxShadow:
              '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
            ...style,
          }}
        />
      </Grid>
    </Grid>
  )
}

export default SparckTable
