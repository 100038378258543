import en from './en-US/en-US'
import es from './es-ES/es-ES'
import ptbr from './pt-BR/pt-BR'
import jp from './jp-JP/jp-JP'
import de from './de-DE/de-DE'
import ar from './ar-SA/ar-SA'
import da from './da-DK/da-DK'
import nl from './nl-NL/nl-NL'
import fr from './fr-CA/fr-CA'
import fi from './fi-FL/fi-FL'
import it from './it-IT/it-IT'
import ru from './ru-RU/ru-RU'
import sv from './sv-SE/sv-SE'
import esbr from './es-BR/es-BR'
import FR from './ca-CF/ca-CF'
import eu from './eu-ES/eu-ES'
import translate from './translate'
import { useTranslation } from './translate'

export default {
  ...en,
  ...es,
  ...ptbr,
  ...jp,
  ...de,
  ...da,
  ...nl,
  ...fr,
  ...fi,
  ...it,
  ...ru,
  ...ar,
  ...sv,
  ...esbr,
  ...FR,
  ...eu,
}

export const t: any = translate
export { useTranslation }
