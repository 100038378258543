import React, { useEffect, useState } from 'react'

import * as FullStory from '@fullstory/browser'
import { useQuery } from 'react-query'
import { API } from 'core/requests'
import { SPARCK_COMPANY } from 'constants/app'
import { getSpoofedCompanyFromLocalStorage } from 'utils/admin'

type CompanyContextType = {
  company: any
  companyIsLoading: boolean
  setCompany: (value: any) => void
}
const CompanyContext = React.createContext<CompanyContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

export const CompanyProvider = ({ children }: Props) => {
  const [company, setCompany] = useState<any>(undefined)

  const { isLoading: companyIsLoading } = useQuery(
    'getCompany',
    () =>
      API.get(
        'companies',
        getSpoofedCompanyFromLocalStorage()?.id ?? company?.id,
      ),
    {
      enabled: Boolean(company?.id && !company?.name),
      onSuccess: (response: any) => {
        setCompany(response.company)
        if (response.company) localStorage.setItem(SPARCK_COMPANY, JSON.stringify(response.company))
      },
    },
  )

  useEffect(() => {
    if (company) {
      FullStory.setUserVars({
        companyId: company!.id,
        companyName: company!.name,
      })
    }
  }, [company])

  return (
    <CompanyContext.Provider value={{ company, companyIsLoading, setCompany }}>
      {children}
    </CompanyContext.Provider>
  )
}

export const useCompany = () => React.useContext(CompanyContext)
