import { Fragment } from 'react'

import { cloneDeep } from 'lodash'
import {
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  Menu,
  Typography,
} from '@mui/material'
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material'
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { useIntl } from 'react-intl'

import { Props } from '../../CompanyAdminLayout.types'

type FiltersProps = Props & {
  filtersState: any
  setFiltersState: any
  dataIsLoading: boolean
}

export default function CompanyAdminLayoutFilters({
  filters = [],
  filtersState,
  setFiltersState,
  dataIsLoading,
}: FiltersProps) {
  const filterMenuState = usePopupState({
    variant: 'popover',
    popupId: 'filterMenu',
  })

  const intl = useIntl()

  const handleChange = (filter, filterValue) => event => {
    event.stopPropagation()

    const newFilters = cloneDeep(filtersState)

    if (event.target.checked) {
      newFilters[filter.id].push(filterValue.value)
    } else {
      newFilters[filter.id].splice(
        newFilters[filter.id].indexOf(filterValue.value),
        1,
      )
    }

    setFiltersState(newFilters)
  }

  if (filters.length > 0 && filtersState) {
    return (
      <Grid container justifyContent='center'>
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            style={{ marginTop: 24, zIndex: 999, marginLeft: 0 }}
          >
            {filters.map(filter => (
              <Fragment key={filter.id}>
                <Chip
                  style={{ backgroundColor: '#fff' }}
                  label={
                    <Grid
                      container
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <Grid item>
                        <Typography
                          component='span'
                          variant='body2'
                          color='text.primary'
                        >
                          {filter.label}{' '}
                          <Typography
                            component='span'
                            variant='body2'
                            fontWeight={
                              filtersState[filter.id]?.length > 0 ? 600 : 400
                            }
                            color={
                              filtersState[filter.id]?.length > 0
                                ? 'primary.main'
                                : 'text.secondary'
                            }
                            ml={1}
                          >
                            {filtersState[filter.id]?.length > 0
                              ? filter.values
                                  .filter(value =>
                                    filtersState[filter.id]?.includes(
                                      value.value,
                                    ),
                                  )
                                  .map(value => value.label)
                                  .join(', ')
                              : intl.formatMessage({
                                  id: 'companyAdminLayout.all',
                                })}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Grid container alignItems='center'>
                          <KeyboardArrowDownIcon
                            style={{ marginLeft: 4, color: '#777' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                  {...bindTrigger(filterMenuState)}
                />
                <Menu {...bindMenu(filterMenuState)}>
                  <FormGroup style={{ paddingLeft: 8 }}>
                    {filter.values.map(filterValue => (
                      <FormControlLabel
                        key={filterValue.value}
                        control={
                          <Checkbox
                            color='primary'
                            disabled={dataIsLoading}
                            checked={filtersState[filter.id]?.includes(
                              filterValue.value,
                            )}
                            onChange={handleChange(filter, filterValue)}
                            name={filterValue.value}
                          />
                        }
                        label={filterValue.label}
                      />
                    ))}
                  </FormGroup>
                </Menu>
              </Fragment>
            ))}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return null
}
