import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'

import { initialDialog } from 'constants/dialog'
import { t } from 'i18n'

export default function SignupConfirmEmail({
  dialog,
  setDialog,
  createAccount,
  isSignupRequestLoading,
}: any) {
  const handleClose = () => {
    setDialog(initialDialog)
  }

  const { data } = dialog

  return (
    <Dialog
      open={dialog.isOpen}
      onClose={handleClose}
      aria-labelledby='signupConfirmEmailDialog'
    >
      <DialogTitle id='signupConfirmEmailDialog'>
        {t('signup.confirmEmailDialog.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('signup.confirmEmailDialog.content')}
        </DialogContentText>
        <Typography
          variant='h6'
          color='text.primary'
          data-cy='email_confirmation'
        >
          {data && data.email}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary' data-cy='change_email'>
          {t('signup.confirmEmailDialog.changeEmail')}
        </Button>
        <Button
          onClick={() =>
            createAccount({
              notify: data.notify,
              company: {
                hubspot_id: 1,
                naics_code: 1,
                name: data.company_name,
                url: data.website_url,
                user: {
                  first_name: data.first_name,
                  last_name: data.last_name,
                  email: data.email,
                  phone_number: data.phone_number,
                  password: data.password,
                },
              },
            })
          }
          color='primary'
          disabled={isSignupRequestLoading}
          data-cy='email_is_correct'
        >
          {isSignupRequestLoading ? (
            <CircularProgress size={24} />
          ) : (
            t('signup.confirmEmailDialog.emailIsCorrect')
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
