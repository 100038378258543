import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { UserAvatar } from 'components'
import { ROUTES_PATHS } from 'core/routes'
import { useHistory } from 'react-router-dom'

export default function FeedPostUsersInteractions({ usersInteractions }) {
  const history = useHistory()

  return (
    <List sx={{ width: '100%' }}>
      {usersInteractions?.map(({ user }) => (
        <ListItem id={user.id} disablePadding>
          <ListItemButton
            onClick={() => history.push(`${ROUTES_PATHS.profile}/${user.id}`)}
          >
            <ListItemAvatar>
              <UserAvatar
                name={user.full_name}
                size={40}
                src={user.avatar?.url}
              />
            </ListItemAvatar>
            <ListItemText primary={user.full_name} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}
