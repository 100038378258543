export enum TaskLevelType {
  Regular = "regular",
  Priority = "priority"
}

export type TaskType = {
  id: number
  title: string
  description: string
  link: string
  level: TaskLevelType
  show: boolean
  icon: string
}
