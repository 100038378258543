

import { ReactNode } from 'react'

import { Grid } from '@mui/material'

import { initialDialog } from 'constants/dialog'
import DialogTeleport from './DialogTeleport'
import { DialogData } from 'types/dialog'

type Props = {
    size: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
    children: ReactNode
    driverDialog: DialogData
    setDriverDialog: (value: DialogData) => void
}

export default function SurveyPreviewDialog({
    size,
    children,
    driverDialog,
    setDriverDialog,
}: Props) {
    return (
        <DialogTeleport
            dialogOpen={driverDialog.isOpen}
            dialogSize={size}
            dialogTitle=''
            handleDialogClose={() => setDriverDialog(initialDialog)}
        >
            <Grid container>
                <Grid item xs={12}>
                    {children}
                </Grid>
            </Grid>
        </DialogTeleport>
    )
}
