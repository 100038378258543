import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { isArray } from 'lodash'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import { UseMutationResult } from 'react-query'

import { Dropzone } from 'components'
import { employeeUploadFields } from 'constants/employeeUploadError'
import { useCompany } from 'contexts'
import { t } from 'i18n'
import { EmployeeUploadError } from 'types'

type Props = {
  uploadSpreadsheet: UseMutationResult
  loading: boolean
  setLoading(value: boolean): void
  uploadErrors: EmployeeUploadError[]
  importedEmployees: number
}

enum UploadErrorType {
  MissingValue = 'missing_value',
  InvalidFormat = 'invalid_format',
}

export default function EmployeesUploadComponent(props: Props) {
  const {
    uploadSpreadsheet,
    loading,
    setLoading,
    uploadErrors,
    importedEmployees,
  } = props

  const { enqueueSnackbar } = useSnackbar()
  const { company } = useCompany()!

  const intl = useIntl()

  const renderUploadErrors = uploadErrorList => {
    const getErrorMessagesByType = (error, type) =>
      error.messages
        .filter(message => message.error_type === type)
        .map(item => item.field)
        /**
         * The template literals below fixes a lib issue related to create-react-app
         * not being able to generate dynamic ids without plugins and babel-customizations.
         * Removing it prevents the code from rendering.
         * https://github.com/formatjs/formatjs/issues/2151
         * @returns
         */
        .map(field =>
          intl.formatMessage({ id: `${employeeUploadFields[field]}` }),
        )
        .join(', ')

    return (
      <TableContainer component={Paper} sx={{ my: 2, maxHeight: 600 }}>
        <Table size='small' data-cy='uploadErrors'>
          <TableHead>
            <TableRow>
              {[
                intl.formatMessage({
                  id: 'components.employeesUploadComponent.errorTable.columns.row',
                }),
                intl.formatMessage({
                  id: 'components.employeesUploadComponent.errorTable.columns.missingValues',
                }),
                intl.formatMessage({
                  id: 'components.employeesUploadComponent.errorTable.columns.invalidFormat',
                }),
              ].map(column => (
                <TableCell>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {uploadErrorList
              .filter(uploadError => uploadError.messages)
              .map(uploadError => (
                <TableRow key={uploadError.row_number}>
                  <TableCell component='th' scope='row'>
                    {uploadError.row_number}
                  </TableCell>
                  {[
                    UploadErrorType.MissingValue,
                    UploadErrorType.InvalidFormat,
                  ].map(errorType => (
                    <TableCell>
                      {getErrorMessagesByType(uploadError, errorType)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={1}>
          <Typography variant='h5' color='text.primary' paragraph>
            {t('employeesUpload.uploadEmployeeInformation')}
          </Typography>

          <Typography variant='body2' color='text.secondary' paragraph>
            {t('employeesUpload.description1')}
          </Typography>

          <Typography variant='body2' color='text.secondary' paragraph>
            {t('employeesUpload.description2')}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} sx={{ mb: 2, mt: 1 }}>
        <Link
          href='/files/upload-employees-template.xlsx'
          target='_self'
          download
          sx={{ fontWeight: 600 }}
        >
          {t('employeesUpload.downloadExcelTemplate')}
        </Link>
      </Grid>

      <Grid item xs={12} data-cy='dropzone'>
        {loading ? (
          <Grid container spacing={2} justifyContent='center' sx={{ my: 3 }}>
            <Grid item xs='auto'>
              <CircularProgress size={64} />
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant='body1'
                color='text.secondary'
                align='center'
                fontWeight={600}
              >
                {t('employeesUpload.importingYourSpreadsheet')}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <>
            <Dropzone
              allowedFileTypes='.xlsx'
              multiple={false}
              onDrop={(acceptedFiles, fileRejections) => {
                if (acceptedFiles.length > 0) {
                  const formData = new FormData()

                  formData.append('file', acceptedFiles[0])
                  formData.append('attach_to', 'Company')
                  formData.append('record_id', company.id)
                  formData.append('record_field', 'spreadsheet')

                  setLoading(true)

                  uploadSpreadsheet.mutate(formData)
                }

                fileRejections.forEach(rejectedFile => {
                  rejectedFile.errors.forEach(error => {
                    enqueueSnackbar(error.message, {
                      variant: 'error',
                    })
                  })
                })
              }}
              label={t('employeesUpload.dragAndDrop')}
            />

            {importedEmployees > 0 && uploadErrors.length > 0 && (
              <Alert
                severity='warning'
                sx={{
                  my: 2,
                }}
              >
                {t('components.employeesUploadComponent.warningMessage', {
                  importedEmployees: importedEmployees,
                })}
              </Alert>
            )}

            {isArray(uploadErrors) && uploadErrors.length > 0 && (
              <Alert
                severity='error'
                sx={{
                  marginTop:
                    importedEmployees > 0 && uploadErrors.length > 0 ? 0 : 2,
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    width: 8,
                  },
                  '&::-webkit-scrollbar-thumb': {
                    borderRadius: 8,
                    backgroundColor: '#d9d9d9',
                  },
                }}
              >
                *{' '}
                {t('components.employeesUploadComponent.errorContainerMessage')}
                <Grid container>
                  <Grid item xs={12}>
                    {renderUploadErrors(uploadErrors)}
                  </Grid>
                </Grid>
              </Alert>
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
}
