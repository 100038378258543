import { useState, useEffect } from 'react'

import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'

import { MFASetupComponent } from './components'
import { useUser } from 'contexts'
import { API } from 'core/requests'
import { ROUTES_PATHS } from 'core/routes'
import { t } from 'i18n'
import { userHasMFAEnabled } from 'utils/multiFactorAuthentication'

type Props = {
  finishButton?: any
  onVerifyMFAFactorSuccess: any
}

export default function MFASetup(props: Props) {
  const { onVerifyMFAFactorSuccess } = props

  const { user, fetchUser } = useUser()!
  const { enqueueSnackbar } = useSnackbar()
  const location = useLocation()
  const history = useHistory()

  const [factorSetupDialogOpen, setFactorSetupDialogOpen] = useState(false)
  const [selectedFactor, setSelectedFactor] = useState<any>(null)
  const [activeStepId, setActiveStepId] = useState<any>(null)
  const [QRCodeDetails, setQRCodeDetails] = useState<any>(null)
  const [backupCodes, setBackupCodes] = useState([])
  const [userCheckedCredentials, setUserCheckedCredentials] = useState(false)

  const checkCredentialsMutation = useMutation(
    (payload: any) => API.create('checkCredentials', payload),
    {
      onSuccess: response => {
        if (response?.valid) {
          setUserCheckedCredentials(true)

          if (selectedFactor?.checkCredentialsCallback) {
            selectedFactor?.checkCredentialsCallback(response)
          }
        }
      },
    },
  )

  const createMFAFactorMutation = useMutation(
    (payload: any) => API.create('createMFAFactor', payload),
    {
      onSuccess: (response: any) => {
        if (response?.backup_codes) {
          setBackupCodes(response.backup_codes)
        }

        if (response?.payload?.binding_qr_code_string) {
          setQRCodeDetails({
            ...QRCodeDetails,
            url: response.payload.binding_qr_code_string,
            secret: response.payload.binding_secret,
          })
        }

        if (!response?.errors && selectedFactor?.createMFAFactorCallback) {
          selectedFactor?.createMFAFactorCallback(response)
        }
      },
    },
  )

  const verifyMFAFactorMutation = useMutation(
    (payload: any) => API.update('verifyMFAFactor', payload),
    {
      onSuccess: (response: any) => {
        if (response && response.status === 'verified') {
          setBackupCodes(response.backup_codes)

          if (selectedFactor?.verifyMFAFactorCallback) {
            selectedFactor?.verifyMFAFactorCallback(response)
          }

          if (onVerifyMFAFactorSuccess) onVerifyMFAFactorSuccess()
        }
      },
    },
  )

  const setMFAPrimaryFactor = useMutation(
    (payload: any) => API.update('mfaSetPrimaryFactor', payload),
    {
      onSuccess: fetchUser,
    },
  )

  const backupCodesQuery = useQuery(
    'getUserBackupCodes',
    () => API.get('getMFABackupCodes'),
    {
      enabled: false,
      onSuccess: response => {
        if (response?.backup_codes) {
          setBackupCodes(response.backup_codes)

          if (selectedFactor?.fetchBackupCodesCallback) {
            selectedFactor?.fetchBackupCodesCallback(response)
          }
        }
      },
    },
  )

  const disableMFAFactorMutation = useMutation(
    (payload: any) =>
      API.delete('removeMFAFactor', null, {
        body: payload,
      }),
    {
      onSettled: (response: any) => {
        if (response && response.removed) {
          fetchUser()
          setFactorSetupDialogOpen(false)

          enqueueSnackbar(
            t('mfa.snackbars.success.mfaDisabled', {
              selectedFactor: selectedFactor?.title,
            }),
            {
              variant: 'success',
            },
          )

          if (selectedFactor?.disableMFAFactorCallback) {
            selectedFactor?.disableMFAFactorCallback(response)
          }
        }
      },
    },
  )

  useEffect(() => {
    if (
      location.pathname === ROUTES_PATHS.MFASetupRequired &&
      userHasMFAEnabled(user) &&
      !factorSetupDialogOpen
    ) {
      history.push(ROUTES_PATHS.dashboard)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, factorSetupDialogOpen])

  return (
    <MFASetupComponent
      factorSetupDialogOpen={factorSetupDialogOpen}
      setFactorSetupDialogOpen={setFactorSetupDialogOpen}
      selectedFactor={selectedFactor}
      setSelectedFactor={setSelectedFactor}
      activeStepId={activeStepId}
      setActiveStepId={setActiveStepId}
      backupCodes={backupCodes}
      QRCodeDetails={QRCodeDetails}
      userCheckedCredentials={userCheckedCredentials}
      checkCredentialsMutation={checkCredentialsMutation}
      createMFAFactorMutation={createMFAFactorMutation}
      verifyMFAFactorMutation={verifyMFAFactorMutation}
      disableMFAFactorMutation={disableMFAFactorMutation}
      setMFAPrimaryFactor={setMFAPrimaryFactor}
      backupCodesQuery={backupCodesQuery}
      resendVerificationCodeMutation={createMFAFactorMutation}
    />
  )
}
