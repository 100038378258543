import { useEffect, useState, useMemo } from 'react'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  lighten,
  Link,
  MenuItem,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { Skeleton } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { get, isEmpty } from 'lodash'
import InputMask from 'react-input-mask'
import { useIntl } from 'react-intl'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router'

import {
  ProfileMemories,
  ProfileMyImpact,
  ProfileRecognitionStyle,
  ProfileSettings,
} from '../../components'
import MyImpactHeader from './MyImpactHeader'
import {
  CardColoredWithLogo,
  DatePickerWithWorkaround,
  ProgressBar,
} from 'components'
import { getRecognitionStyle } from 'constants/recognitionStyle'
import { API } from 'core/requests'
import { DemographicCategory } from 'enums/demographic'
import { t } from 'i18n'
import { displayProfileSection, userHasBeseenPermissions } from 'utils/user'

type ContactDetail = {
  id: string
  values: {
    id: string
    display?: boolean
    label: string
    getValue: any
    getInput?: any
  }[]
}

const LEFT_INPUT_MARGIN_TOP = 8
const RIGHT_INPUT_MARGIN_TOP = 20

export default function ProfileAboutMe(props: any) {
  const {
    profileState,
    setProfileState,
    editMode,
    isProfileFromLoggedUser,
    user,
    userIsLoading,
    userInsights,
    myInsightsAreLoading,
  } = props

  const intl = useIntl()

  const [showCustomPronounInput, setShowCustomPronounInput] = useState(false)

  const { data: demographics, refetch: fetchDemographics } = useQuery(
    'getDemographics',
    () => API.get('demographics'),
    {
      enabled: false,
    },
  )

  useEffect(() => {
    fetchDemographics()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const departmentDemographics = (demographics ?? []).find(
    demographicItem =>
      demographicItem.category === DemographicCategory.Department,
  )

  const locationDemographics = (demographics ?? []).find(
    demographicItem =>
      demographicItem.category === DemographicCategory.Location,
  )

  const getDemographicValue = demographics => {
    const selectedDemographicId = profileState?.demographics?.find(
      d => d?.category === demographics?.id,
    )?.demographic

    return demographics?.demographics_attributes?.find(
      attribute => attribute.id === selectedDemographicId,
    )?.name
  }

  const getSuperiorValue = superiors => {
    let superiorValues: any = [];
    if(superiors?.length > 0){
      superiorValues = superiors?.map((superior) => {
        return (`${superior?.superior?.first_name} ${superior?.superior?.last_name}`).trim()
      })
    }

    return superiorValues
  }

  const handleDemographicChange = (demographics, value, state, setState) => {
    const newDemographics = profileState.demographics.filter(
      d => d.category !== demographics.id,
    )

    newDemographics.push({
      category: demographics.id,
      demographic: value,
    })

    setState({
      ...state,
      demographics: newDemographics,
    })
  }

  const memoizedContactDetails: ContactDetail[] = useMemo(
    () => [
      {
        id: 'leftSide',
        values: [
          {
            id: 'first_name',
            label: t('firstName'),
            display: true,
            getValue: value => value,
            getInput: (id, state, setState) => (
              <TextField
                type='text'
                placeholder='e.g. John'
                id={id}
                name={id}
                label={t('firstName')}
                variant='outlined'
                value={state[id] ?? ''}
                onChange={event =>
                  setState({
                    ...state,
                    [id]: event.target.value,
                  })
                }
                fullWidth
                style={{ marginTop: LEFT_INPUT_MARGIN_TOP }}
              />
            ),
          },
          {
            id: 'last_name',
            label: t('lastName'),
            display: true,
            getValue: value => value,
            getInput: (id, state, setState) => (
              <TextField
                data-cy='last_name'
                type='text'
                placeholder='e.g. Whithers'
                id={id}
                name={id}
                label={t('lastName')}
                variant='outlined'
                value={state[id] ?? ''}
                onChange={event =>
                  setState({
                    ...state,
                    [id]: event.target.value,
                  })
                }
                fullWidth
                style={{ marginTop: LEFT_INPUT_MARGIN_TOP }}
              />
            ),
          },
          {
            id: 'pronoun',
            label: t('profile.profileItemLabels.preferredPronouns'),
            display: true,
            getValue: value => value,
            getInput: (id, state, setState) => (
              <TextField
                data-cy='pronoun'
                type='text'
                placeholder={intl.formatMessage({
                  id: 'profile.profileItemLabels.preferredPronounsPlaceholder',
                })}
                id={id}
                name={id}
                label={t('profile.profileItemLabels.preferredPronouns')}
                variant='outlined'
                defaultValue=''
                value={state[id] ?? ''}
                select
                onChange={event =>
                  setState({
                    ...state,
                    [id]: event.target.value,
                  })
                }
                fullWidth
                style={{ marginTop: LEFT_INPUT_MARGIN_TOP }}
              >
                {[
                  {
                    id: 1,
                    label: intl.formatMessage({ id: 'profile.pronouns.he' }),
                    value: intl.formatMessage({ id: 'profile.pronouns.he' }),
                  },
                  {
                    id: 2,
                    label: intl.formatMessage({ id: 'profile.pronouns.she' }),
                    value: intl.formatMessage({ id: 'profile.pronouns.she' }),
                  },
                  {
                    id: 3,
                    label: intl.formatMessage({ id: 'profile.pronouns.they' }),
                    value: intl.formatMessage({ id: 'profile.pronouns.they' }),
                  },
                ].map(option => (
                  <MenuItem
                    key={option.id}
                    value={option.value}
                    data-cy='pronouns_option'
                  >
                    {option.label}
                  </MenuItem>
                ))}
                <MenuItem
                  value='custom'
                  onClick={() => setShowCustomPronounInput(true)}
                >
                  Custom
                </MenuItem>
              </TextField>
            ),
          },
          {
            id: 'customPronoun',
            label: t('profile.profileItemLabels.howShouldRefer'),
            display: editMode && showCustomPronounInput,
            getValue: value => value,
            getInput: (id, state, setState) => (
              <TextField
                type='text'
                placeholder={intl.formatMessage({
                  id: 'profile.profileItemLabels.preferredPronounsPlaceholder',
                })}
                id={id}
                name={id}
                label={t('profile.profileItemLabels.customPreferredPronouns')}
                variant='outlined'
                value={state[id] ?? ''}
                onChange={event =>
                  setState({
                    ...state,
                    [id]: event.target.value,
                  })
                }
                fullWidth
                style={{ marginTop: LEFT_INPUT_MARGIN_TOP }}
              />
            ),
          },
          {
            id: 'title',
            label: t('profile.profileItemLabels.position'),
            display: true,
            getValue: value => value,
            getInput: (id, state, setState) => (
              <TextField
                type='text'
                placeholder={intl.formatMessage({
                  id: 'profile.profileItemLabels.positionPlaceholder',
                })}
                id={id}
                name={id}
                label={t('profile.profileItemLabels.position')}
                variant='outlined'
                value={state[id] ?? ''}
                onChange={event =>
                  setState({
                    ...state,
                    [id]: event.target.value,
                  })
                }
                fullWidth
                style={{ marginTop: LEFT_INPUT_MARGIN_TOP }}
              />
            ),
          },
          {
            id: 'email',
            label: t('email'),
            display: true,
            getValue: value => <Link href={`mailto:${value}`}>{value}</Link>,
            getInput: (id, state, setState) => (
              <TextField
                type='text'
                placeholder='e.g. example@email.com'
                id={id}
                name={id}
                label={t('email')}
                variant='outlined'
                value={state[id] ?? ''}
                onChange={event =>
                  setState({
                    ...state,
                    [id]: event.target.value,
                  })
                }
                fullWidth
                style={{ marginTop: LEFT_INPUT_MARGIN_TOP }}
              />
            ),
          },
          {
            id: 'phone_number',
            label: t('phoneNumber'),
            display: true,
            getValue: value => <Link href={`tel:${value}`}>{value}</Link>,
            getInput: (id, state, setState) => (
              <InputMask
                mask='(999) 999-9999'
                value={state[id] ?? ''}
                onChange={event =>
                  setState({ ...state, [id]: event.target.value })
                }
              >
                {inputProps => (
                  <TextField
                    {...inputProps}
                    type='tel'
                    label={t('phoneNumber')}
                    variant='outlined'
                    placeholder='(123) 456-7890'
                    fullWidth
                    style={{ marginTop: LEFT_INPUT_MARGIN_TOP }}
                  />
                )}
              </InputMask>
            ),
          },
        ],
      },
      {
        id: 'rightSide',
        values: [
          {
            id: 'department',
            label: t('department'),
            display: true,
            getInput: (id, state, setState) => (
              <TextField
                id={id}
                name={id}
                label={t('department')}
                variant='outlined'
                defaultValue=''
                value={
                  profileState.demographics?.find(
                    d => d.category === departmentDemographics?.id,
                  )?.demographic
                }
                select
                onChange={event =>
                  handleDemographicChange(
                    departmentDemographics,
                    event.target.value,
                    state,
                    setState,
                  )
                }
                fullWidth
                style={{ marginTop: LEFT_INPUT_MARGIN_TOP }}
              >
                {departmentDemographics &&
                  departmentDemographics?.demographics_attributes?.map(
                    department => (
                      <MenuItem key={department.id} value={department.id}>
                        {department.name}
                      </MenuItem>
                    ),
                  )}
              </TextField>
            ),
            getValue: () => getDemographicValue(departmentDemographics),
          },
          {
            id: 'hired_at',
            label: t('profile.profileItemLabels.hireDate'),
            display: true,
            getValue: value => value && format(parseISO(value), 'MMMM dd Y'),
          },
          {
            id: 'location',
            label: t('profile.profileItemLabels.location'),
            display: true,
            getInput: (id, state, setState) => (
              <TextField
                id={id}
                name={id}
                label={t('profile.profileItemLabels.location')}
                variant='outlined'
                defaultValue=''
                value={
                  profileState.demographics?.find(
                    d => d.category === locationDemographics?.id,
                  )?.demographic
                }
                select
                onChange={event =>
                  handleDemographicChange(
                    locationDemographics,
                    event.target.value,
                    state,
                    setState,
                  )
                }
                fullWidth
                style={{ marginTop: RIGHT_INPUT_MARGIN_TOP }}
              >
                {locationDemographics &&
                  locationDemographics?.demographics_attributes?.map(
                    location => (
                      <MenuItem key={location.id} value={location.id}>
                        {location.name}
                      </MenuItem>
                    ),
                  )}
              </TextField>
            ),
            getValue: () => getDemographicValue(locationDemographics),
          },
          {
            id: 'manager',
            label: t('profile.profileItemLabels.reportsTo'),
            display: true,
            getValue: () => {
              return <span style={{ whiteSpace: 'pre-line' }}>
                {getSuperiorValue(profileState.superior_direct_reports_attributes).join('\n')}
              </span>
            },
          },
          {
            id: 'date_of_birth',
            label: t('birthday'),
            display: displayProfileSection(
              isProfileFromLoggedUser,
              profileState.date_of_birth_visibility,
              user,
              profileState,
            ),
            getInput: (id, state, setState) => (
              <DatePickerWithWorkaround
                label={t('birthday')}
                value={state[id] ?? ''}
                onChange={value => setState({ ...state, [id]: value })}
                renderInput={params => (
                  <TextField
                    {...params}
                    id={id}
                    style={{ marginTop: RIGHT_INPUT_MARGIN_TOP }}
                    fullWidth
                    name={id}
                    variant='outlined'
                  />
                )}
              />
            ),
            getValue: value => value && format(parseISO(value), 'MMMM dd'),
          },
        ],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profileState],
  )

  const theme: any = useTheme()
  const history: any = useHistory()

  const [recognitionStyle, setRecognitionStyle] = useState<any>({})

  useEffect(() => {
    if (isEmpty(recognitionStyle) && !isEmpty(profileState)) {
      const styles = get(profileState, 'recognition.styles', [])

      if (styles.length > 0) {
        const style = styles[0]

        if (style && style.letter) {
          return setRecognitionStyle(getRecognitionStyle[style.letter])
        }
      }
      return setRecognitionStyle(getRecognitionStyle.undefined)
    }
  }, [profileState, recognitionStyle])

  const renderContactDetails = () => {
    // const getValue = contactDetail =>
    //   contactDetail.getValue(profileState[contactDetail.id])
    //     ? contactDetail.getValue(profileState[contactDetail.id])
    //     : profileState[contactDetail.id]

    const getValue = contactDetail => {
      return contactDetail.getValue(profileState[contactDetail.id])
        ? contactDetail.getValue(profileState[contactDetail.id])
        : profileState[contactDetail.id];
    };

    return memoizedContactDetails.map((contactDetailSection, index) => (
      <Grid item xs={12} sm={6} key={index}>
        {contactDetailSection.values.map(
          (contactDetail, index) =>
            contactDetail.display && (
              <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
                <Grid item xs={12}>
                  {editMode && contactDetail.getInput ? (
                    <>
                      {contactDetail.getInput(
                        contactDetail.id,
                        profileState,
                        setProfileState,
                      )}
                    </>
                  ) : (
                    <>
                      <Typography variant='body2' color='text.secondary'>
                        {contactDetail.label}
                      </Typography>
                      <Typography
                        variant='body1'
                        color={`text.${
                          getValue(contactDetail) ? 'primary' : 'veryLight'
                        }`}
                        fontWeight={600}
                      >
                        {userIsLoading && (
                          <Skeleton width={index % 2 === 0 ? 160 : 100} />
                        )}

                        {!userIsLoading && (
                          <>{getValue(contactDetail) ?? 'N/A'}</>
                        )}
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            ),
        )}
      </Grid>
    ))
  }

  const renderProfileStrength = () => (
    <Grid item xs={12} style={{ marginTop: 16 }}>
      <Paper>
        <Box p={3} width={1}>
          <Grid container justifyContent='space-between'>
            <Typography variant='h6' color='text.secondary'>
              {t('profile.profileStrength')}
            </Typography>
            <Typography variant='h5' color='text.secondary' fontWeight='bold'>
              {get(profileState, 'recognition.readiness', 0)}%
            </Typography>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <ProgressBar
                progress={[
                  {
                    percentage: get(profileState, 'recognition.readiness', 0),
                    color: 'secondary',
                  },
                ]}
                showLabel
                labelVariant='h6'
                withGradient
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  )

  const renderRecognitionStyle = () => (
    <Grid item xs={12} md={4}>
      <ProfileRecognitionStyle
        {...props}
        recognitionStyle={recognitionStyle}
        history={history}
      />
    </Grid>
  )

  const renderMyImpact = () => (
    <Grid item xs={12} md={4}>
      <Paper elevation={2} style={{ height: '100%' }}>
        <MyImpactHeader {...props} />

        <Box width={1} p={3}>
          <Grid
            container
            justifyContent='center'
            alignItems='center'
            style={{ height: '100%' }}
          >
            {myInsightsAreLoading ? (
              <CircularProgress size={24} />
            ) : (
              <ProfileMyImpact
                {...props}
                userInsights={userInsights}
                theme={theme}
              />
            )}
          </Grid>
        </Box>
      </Paper>
    </Grid>
  )

  const renderRecognitionMemories = () => (
    <Grid item xs={12} md={4}>
      <CardColoredWithLogo
        backgroundColor1={lighten(theme.palette.error.dark, 0.2)}
        backgroundColor2={lighten(theme.palette.error.light, 0.2)}
        fullHeight
        top
      >
        <Typography
          variant='h6'
          color='text.white'
          align='center'
          fontWeight={500}
          paragraph
        >
          {t('profile.recognitionMemories.title')}
        </Typography>

        <ProfileMemories {...props} />
      </CardColoredWithLogo>
    </Grid>
  )

  const renderVisibilitySettings = () =>
    isProfileFromLoggedUser && (
      <Grid item xs={12}>
        <Box mb={2} width={1}>
          <ProfileSettings {...props} />
        </Box>
      </Grid>
    )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='about-me-content'
            id='about-me-header'
          >
            <Grid container alignItems='center'>
              <Typography
                variant='h6'
                fontWeight={600}
                sx={{ mr: 3, flexShrink: 0 }}
              >
                About Me
              </Typography>

              <Typography sx={{ color: 'text.secondary' }}>
                Click to check your profile's details
              </Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {renderContactDetails()}
              {renderVisibilitySettings()}
              {renderProfileStrength()}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      {userHasBeseenPermissions(user) && (
        <>
          {renderMyImpact()}
          {renderRecognitionStyle()}
          {renderRecognitionMemories()}
        </>
      )}
    </Grid>
  )
}
