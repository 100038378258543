import { useState } from 'react'

import { useFormik } from 'formik'
import { groupBy } from 'lodash'
import { useQuery, useMutation } from 'react-query'
import * as yup from 'yup'

import { SparckAppreciationAssessmentComponent } from './components'
import { API } from 'core/requests'
import { t } from 'i18n'

const validationSchema = yup.object({
  first_name: yup.string().nullable(),
  last_name: yup.string().nullable(),
  company_name: yup.string().nullable(),
  email: yup
    .string()
    .email(t('sparckAppreciationAssessment.validations.validEmail'))
    .required(t('sparckAppreciationAssessment.validations.requiredEmail')),
})

export default function SparckAppreciationAssessment() {
  const [questions, setQuestions] = useState<any[]>([])
  const [answers, setAnswers] = useState<any[]>([])
  const [assessmentResults, setAssessmentResults] = useState(null)

  const formik = useFormik({
    initialValues: {
      locale: 'en',
      first_name: '',
      last_name: '',
      company_name: '',
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      fetchAssessment()
    },
  })

  const { isLoading: assessmentIsLoading, refetch: fetchAssessment } = useQuery(
    'publicSparckStyleAssessment',
    () =>
      API.get('takePublicSparckStyleAssessment', null, {
        queryParams: formik.values,
      }),
    {
      enabled: false,
      onSuccess: response => {
        if (response) {
          const questions = response[0].onboarding_choices_attributes
          setQuestions(questions)

          setAnswers([
            ...Object.keys(groupBy(questions, 'choice_group')).map(
              choiceGroupId => ({
                choiceGroupId: choiceGroupId,
                answerId: null,
              }),
            ),
          ])
        }
      },
    },
  )

  const answerAssessment = useMutation(
    () =>
      API.update('answerPublicSparckStyleAssessment', {
        email: formik.values.email,
        answers: answers.map(answer => answer.answerId),
      }),
    {
      onSuccess: (response: any) => {
        if (response) {
          finishAssessment.mutate({ email: formik.values.email })
        }
      },
    },
  )

  const finishAssessment = useMutation(
    (payload: any) => API.update('finishPublicSparckStyleAssessment', payload),
    {
      onSuccess: (response: any) => {
        if (response) {
          // setAssessmentResults(response.slice(0, 2))
          setAssessmentResults(response)
        }
      },
    },
  )

  return (
    <SparckAppreciationAssessmentComponent
      formik={formik}
      questions={questions}
      answers={answers}
      setAnswers={setAnswers}
      answerAssessment={answerAssessment}
      assessmentIsLoading={assessmentIsLoading}
      assessmentResults={assessmentResults}
    />
  )
}
