import { Fragment, useRef } from 'react'

import { Grid, Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import { UseMutationResult, UseQueryResult } from 'react-query'

import {
  MFAEnterCredentials,
  MFAEnterVerificationCode,
  MFAFactorSection,
  MFAFactorSetup,
  MFAEnterPhoneNumber,
  MFAScanQRCode,
  MFASuccess,
} from '..'
import { useUser } from 'contexts'
import { MFAFactorType, MFAStep } from 'enums/multiFactorAuthentication'
import { t } from 'i18n'
import { userHasMFAEnabled } from 'utils/multiFactorAuthentication'

export type MFASetupProps = {
  factorSetupDialogOpen: boolean
  setFactorSetupDialogOpen(value: boolean): void
  selectedFactor: any
  setSelectedFactor(value: any): void
  activeStepId: MFAStep | null
  setActiveStepId(value: MFAStep | null): void
  backupCodes: any
  QRCodeDetails: any
  userCheckedCredentials: boolean
  finishButton?: any
  checkCredentialsMutation: UseMutationResult
  createMFAFactorMutation: UseMutationResult
  verifyMFAFactorMutation: UseMutationResult
  disableMFAFactorMutation: UseMutationResult
  setMFAPrimaryFactor: UseMutationResult
  backupCodesQuery: UseQueryResult
  resendVerificationCodeMutation: UseMutationResult
}

export default function MFASetupComponent(props: MFASetupProps) {
  const { user } = useUser()!

  const intl = useIntl()

  const {
    factorSetupDialogOpen,
    setFactorSetupDialogOpen,
    selectedFactor,
    activeStepId,
    setActiveStepId,
    checkCredentialsMutation,
    createMFAFactorMutation,
    verifyMFAFactorMutation,
    backupCodesQuery,
  } = props

  const selectedFactorRef: any = useRef()
  const activeStepIdRef: any = useRef()

  selectedFactorRef.current = selectedFactor as any
  activeStepIdRef.current = activeStepId as any

  const getStepsIds = () =>
    selectedFactorRef?.current?.steps?.map(step => step?.id)

  const goToPreviousStep = () => {
    const stepsIds = getStepsIds()

    if (stepsIds?.length) {
      setActiveStepId(stepsIds[stepsIds.indexOf(activeStepIdRef.current) - 1])
    }
  }

  const goToNextStep = () => {
    const stepsIds = getStepsIds()

    if (stepsIds?.length) {
      setActiveStepId(stepsIds[stepsIds.indexOf(activeStepIdRef.current) + 1])
    }
  }

  const credentialsStep = {
    id: MFAStep.EnterCredentials,
    title: t('mfa.enterPassword'),
    Component: MFAEnterCredentials,
    onClickNextPage: payload => {
      checkCredentialsMutation.mutate({ password: payload })
    },
  }

  const mfaFactors = [
    {
      id: MFAFactorType.AuthApp,
      title: intl.formatMessage({ id: 'mfa.mfaFactors.authApp.title' }),
      description: t('mfa.mfaFactors.authApp.description'),
      isEnabled: user?.mfa_details?.auth_app_enabled,
      isPrimary: user?.mfa_details?.primary_factor === MFAFactorType.AuthApp,
      steps: [
        credentialsStep,
        {
          id: MFAStep.ScanQRCode,
          title: t('mfa.mfaFactors.authApp.steps.scanQRCode'),
          Component: MFAScanQRCode,
          onClickNextPage: goToNextStep,
        },
        {
          id: MFAStep.EnterVerificationCode,
          title: t('mfa.enterVerificationCode'),
          Component: MFAEnterVerificationCode,
          onClickNextPage: payload => {
            verifyMFAFactorMutation.mutate({
              factor_type: MFAFactorType.AuthApp,
              factor_code: payload,
            })
          },
        },
        {
          id: MFAStep.Success,
          title: t('mfa.mfaFactors.authApp.steps.success'),
          Component: MFASuccess,
        },
      ],
      checkCredentialsCallback: () => {
        createMFAFactorMutation.mutate({
          factor_type: MFAFactorType.AuthApp,
        })
      },
      createMFAFactorCallback: goToNextStep,
      verifyMFAFactorCallback: goToNextStep,
    },
    {
      id: MFAFactorType.SMS,
      title: intl.formatMessage({ id: 'mfa.mfaFactors.sms.title' }),
      description: t('mfa.mfaFactors.sms.description'),
      isEnabled: user?.mfa_details?.sms_enabled,
      isPrimary: user?.mfa_details?.primary_factor === MFAFactorType.SMS,
      steps: [
        credentialsStep,
        {
          id: MFAStep.MethodSetup,
          title: t('mfa.mfaFactors.sms.steps.smsSetup'),
          Component: MFAEnterPhoneNumber,
          onClickNextPage: payload => {
            createMFAFactorMutation.mutate({
              factor_type: MFAFactorType.SMS,
              phone_number: payload,
            })
          },
        },
        {
          id: MFAStep.EnterVerificationCode,
          title: t('mfa.enterVerificationCode'),
          Component: MFAEnterVerificationCode,
          onClickNextPage: payload => {
            verifyMFAFactorMutation.mutate({
              factor_type: MFAFactorType.SMS,
              factor_code: payload,
            })
          },
        },
        {
          id: MFAStep.Success,
          title: t('mfa.mfaFactors.sms.steps.success'),
          Component: MFASuccess,
        },
      ],
      checkCredentialsCallback: goToNextStep,
      createMFAFactorCallback: goToNextStep,
      verifyMFAFactorCallback: goToNextStep,
    },
    {
      id: MFAFactorType.Email,
      title: intl.formatMessage({ id: 'mfa.mfaFactors.email.title' }),
      description: t('mfa.mfaFactors.email.description'),
      isEnabled: user?.mfa_details?.email_enabled,
      isPrimary: user?.mfa_details?.primary_factor === MFAFactorType.Email,
      steps: [
        credentialsStep,
        {
          id: MFAStep.Success,
          title: t('mfa.mfaFactors.email.steps.success'),
          Component: MFASuccess,
        },
      ],
      checkCredentialsCallback: () => {
        createMFAFactorMutation.mutate({
          factor_type: MFAFactorType.Email,
        })
      },
      createMFAFactorCallback: goToNextStep,
    },
    {
      id: MFAFactorType.BackupCode,
      title: intl.formatMessage({ id: 'mfa.mfaFactors.backupCodes.title' }),
      description: t('mfa.mfaFactors.backupCodes.description'),
      steps: [
        credentialsStep,
        {
          id: MFAStep.Success,
          title: t('mfa.yourBackupCodes'),
          Component: MFASuccess,
        },
      ],
      checkCredentialsCallback: backupCodesQuery.refetch,
      fetchBackupCodesCallback: goToNextStep,
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ]

  return (
    <>
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant='h6'>{t('mfa.setupComponentTitle')}</Typography>
        </Grid>
        <Grid item>
          <Typography>{t('mfa.setupComponentDescription')}</Typography>
        </Grid>
        {mfaFactors
          .filter(factor =>
            factor.id === MFAFactorType.BackupCode && !userHasMFAEnabled(user)
              ? false
              : true,
          )
          .map(factor => (
            <Fragment key={factor.id}>
              <MFAFactorSection
                {...props}
                setDialogOpen={setFactorSetupDialogOpen}
                factor={factor}
              />
            </Fragment>
          ))}
      </Grid>

      {factorSetupDialogOpen && (
        <MFAFactorSetup
          {...props}
          goToPreviousStep={goToPreviousStep}
          goToNextStep={goToNextStep}
        />
      )}
    </>
  )
}
