import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Menu,
  SvgIconTypeMap,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import {
  Email as EmailIcon,
  Phone as PhoneIcon,
  Star as StarIcon,
  StarBorder as StarOutlinedIcon,
} from '@mui/icons-material'
import { UserAvatar } from 'components'
import { getUserFullName } from 'utils/user'
import { ROUTES_PATHS } from 'core/routes'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'

import sparckLogoColored from 'assets/images/sparck-logo-colored.png'
import { useUser } from 'contexts'
import { UserRole } from 'enums/user'
import { t } from 'i18n'
import { EmployeeType } from 'types'

type Props = {
  theme: Theme
  employee: EmployeeType
  contact: {
    accessor: string
    label: string
    colorVariant: string
    hrefPrefixer: string
    Icon: OverridableComponent<SvgIconTypeMap<Record<any, unknown>, 'svg'>>
  }
}

function EmployeeDirectoryContactItem(props: Props) {
  const { employee, contact } = props

  const contactMenuState = usePopupState({
    variant: 'popover',
    popupId: `${contact.accessor}Menu`,
  })

  return (
    <>
      <Button
        color='primary'
        disabled={!employee[contact.accessor]}
        sx={{ borderRadius: 0, borderRight: '1px solid #ddd' }}
        fullWidth
        {...bindTrigger(contactMenuState)}
      >
        <Grid container justifyContent='center'>
          <contact.Icon />
          <Box mt={1} width={1}>
            <Typography
              variant='body2'
              fontWeight={600}
              align='center'
              style={{ fontSize: 10 }}
            >
              {contact.label}
            </Typography>
          </Box>
        </Grid>
      </Button>
      <Menu {...bindMenu(contactMenuState)}>
        <Box p={2} width={1}>
          <Typography variant='body1' color='text.secondary' fontWeight={500}>
            {contact.label}:
          </Typography>
          <Typography
            fontWeight={600}
            variant='body2'
            style={{ wordBreak: 'break-all' }}
          >
            <Link
              href={`${contact.hrefPrefixer}:${employee[contact.accessor]}`}
            >
              {employee[contact.accessor]}
            </Link>
          </Typography>
        </Box>
      </Menu>
    </>
  )
}

export default function EmployeeDirectoryItems(props: any) {
  const { employees, employeesIsLoading, favorite, unfavorite } = props

  const theme: any = useTheme()
  const history = useHistory()
  const { user } = useUser()!

  return (
    <Grid container justifyContent='flex-start' spacing={2}>
      {employeesIsLoading ? (
        <Box py={5} width={1}>
          <Grid container justifyContent='center'>
            <CircularProgress size={40} />
          </Grid>
        </Box>
      ) : (
        employees.map(employee => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={employee.id}>
              <Card
                style={{
                  height: '100%',
                  position: 'relative',
                  borderRadius: 0,
                }}
              >
                <IconButton
                  size='small'
                  style={{ position: 'absolute', right: 16, top: 16 }}
                >
                  {employee.favorite_id ? (
                    <Tooltip title={t('people.tooltip.remove')}>
                      <StarIcon
                        style={{ color: theme.palette.secondary.light }}
                        onClick={() => unfavorite(employee.favorite_id)}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title={t('people.tooltip.add')}>
                      <StarOutlinedIcon
                        onClick={() =>
                          favorite({ favorite: { user_id: employee.id } })
                        }
                        style={{ color: theme.palette.text.veryLight }}
                      />
                    </Tooltip>
                  )}
                </IconButton>
                <Grid
                  container
                  direction='column'
                  justifyContent='space-between'
                  style={{ height: '100%' }}
                >
                  <Grid item style={{ width: '100%' }}>
                    <Box p={2} pb={0}>
                      <Grid container justifyContent='center'>
                        <Box mb={1}>
                          <UserAvatar
                            userId={get(employee, 'id')}
                            name={get(employee, 'first_name')}
                            src={get(employee, 'avatar.url')}
                            size={64}
                          />
                        </Box>

                        <Grid item xs={12}>
                          <Typography
                            variant='body1'
                            color='text.primary'
                            fontWeight={600}
                            align='center'
                          >
                            {getUserFullName(employee)}
                            {employee.pronoun && (
                              <Typography
                                component='span'
                                variant='body1'
                                color='text.grey'
                                fontWeight='500'
                                style={{ fontSize: 12 }}
                                paragraph
                              >
                                {` (${employee.pronoun})`}
                              </Typography>
                            )}
                          </Typography>

                          <Typography
                            variant='body2'
                            color='text.secondary'
                            fontWeight={500}
                            align='center'
                          >
                            {`${employee.title ? employee.title : ''} ${
                              employee.title && employee.location ? '•' : ''
                            } ${employee.location ? employee.location : ''}`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item style={{ width: '100%' }}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box px={2} my={2} mb={4}>
                          <Typography
                            variant='body2'
                            fontWeight={600}
                            align='center'
                            paragraph
                          >
                            <Link
                              onClick={() =>
                                history.push(
                                  `${ROUTES_PATHS.profile}/${employee.id}`,
                                )
                              }
                            >
                              {t('people.viewProfile')}
                            </Link>
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container>
                          {[
                            {
                              accessor: 'email',
                              label: t('email'),
                              Icon: EmailIcon,
                              colorVariant: 'main',
                              hrefPrefixer: 'mailto',
                            },
                            {
                              accessor: 'phone_number',
                              label: t('phoneNumber'),
                              Icon: PhoneIcon,
                              colorVariant: 'main',
                              hrefPrefixer: 'tel',
                            },
                          ].map(contact => (
                            <Grid item xs={4} key={contact.accessor}>
                              <EmployeeDirectoryContactItem
                                employee={employee}
                                contact={contact}
                                theme={theme}
                              />
                            </Grid>
                          ))}

                          <Grid item xs={4}>
                            <Button
                              color='secondary'
                              sx={{ borderRadius: 0 }}
                              onClick={() =>
                                history.push(
                                  `${
                                    user.sparck_administrator ||
                                    [UserRole.CompanyAdmin].some(role =>
                                      user.roles.includes(role),
                                    )
                                      ? ROUTES_PATHS.sparck
                                      : ROUTES_PATHS.feed
                                  }?user_to_recognize_id=${employee.id}`,
                                )
                              }
                              fullWidth
                            >
                              <Grid container justifyContent='center'>
                                <img
                                  src={sparckLogoColored}
                                  alt='Sparck logo white'
                                  style={{ height: 24, width: 24 }}
                                />
                                <Box mt={1} width={1}>
                                  <Typography
                                    variant='body2'
                                    fontWeight={600}
                                    align='center'
                                    style={{ fontSize: 10 }}
                                  >
                                    {t('people.infoButtons.recognize')}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          )
        })
      )}
    </Grid>
  )
}
