import * as FullStory from '@fullstory/browser'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'

import ApplicationGlobalContexts from './ApplicationGlobalContexts'
import { isDevEnvironment } from 'core/environments'
import { FULLSTORY_ORG_ID } from 'constants/fullstory'

export default function ApplicationMonitoring() {
  if (!isDevEnvironment) {
    ReactGA.initialize('UA-131666931-1')
    TagManager.initialize({
      gtmId: 'GTM-W7PHFGT',
    })
  }

  FullStory.init({
    orgId: FULLSTORY_ORG_ID,
    devMode: isDevEnvironment,
  })

  return <ApplicationGlobalContexts />
}
