import { useEffect, useState } from 'react'

import { MilitaryTechOutlined as NominationsManagementIcon } from '@mui/icons-material'
import { Box, Grid, Tab, Tabs, useTheme } from '@mui/material'

import { NominationsManagementHistory, NominationsManagementPending } from '..'
import { CompanyAdminLayoutHeader } from 'components/companyAdminLayout/components'
import { a11yTabProps } from 'utils/accessibility'

enum NominationTab {
  CurrentNominations = 'currentNominations',
  History = 'history',
}

export default function NominationsManagementComponent(props) {
  const { currentNominations } = props

  const theme = useTheme()

  const [selectedTab, setSelectedTab] = useState(NominationTab.History)

  const tabs = [
    {
      value: NominationTab.CurrentNominations,
      label: 'Active',
      Component: NominationsManagementPending,
    },
    {
      value: NominationTab.History,
      label: 'History',
      Component: NominationsManagementHistory,
    },
  ]

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  useEffect(() => {
    if (currentNominations?.length) {
      setSelectedTab(NominationTab.CurrentNominations)
    }
  }, [currentNominations])

  return (
    <>
      <Grid container>
        <Grid item xs={12} sx={{ mb: 3 }}>
          <CompanyAdminLayoutHeader
            slug='nominations'
            title={'Nominations'}
            description={
              'Below are listed all nominations given for your organization'
            }
            color={theme.palette.warning.dark}
            IconHeader={NominationsManagementIcon}
            setDialogForm={null}
            userHasManagementPermission
          />
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
            <Tabs value={selectedTab} onChange={handleTabChange}>
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  data-cy='tabs'
                  value={tab.value}
                  label={tab.label}
                  {...a11yTabProps(index)}
                />
              ))}
            </Tabs>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {tabs.map((tab, index) => (
                <TabPanel
                  key={index}
                  value={tab.value}
                  selectedTab={selectedTab}
                  index={index}
                >
                  <tab.Component {...props} />
                </TabPanel>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

function TabPanel(props) {
  const { children, value, index, selectedTab, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== selectedTab}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === selectedTab && <Box width={1}>{children}</Box>}
    </div>
  )
}
