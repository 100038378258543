import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { CompanyInsightsComponent } from './components'
import { API } from 'core/requests'
import { Period } from 'enums/period'
import { useCompany } from 'contexts'
import { get } from 'lodash'

export default function Insights() {
  const { company } = useCompany()!

  const [filters, setFilters] = useState({
    period: Period.ThisYear,
    survey: null,
    pulse_survey: null,
    manager: null,
  })

  const {
    data: insights,
    isLoading: insightsIsLoading,
    refetch: fetchInsights,
  } = useQuery(
    'getCompanyInsights',

    () =>
      API.get('companyInsights', null, {
        queryParams: {
          period: filters.period,
          survey_id: get(filters, 'survey.id', ''),
          pulse_survey_id: get(filters, 'pulse_survey.id', ''),
          manager_id: get(filters, 'manager.id', ''),
        },
      }),
    {
      enabled: false,
    },
  )

  const { data: surveys, refetch: fetchSurveys } = useQuery(
    'getSurveys',
    () => API.get('surveys'),
    { enabled: false },
  )

  const { data: pulseSurveys, refetch: fetchPulseSurveys } = useQuery(
    'getPulseSurveys',
    () => API.get('pulseSurveys'),
    {
      enabled: false,
    },
  )

  useEffect(() => {
    fetchSurveys()
    fetchPulseSurveys()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchInsights()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  return (
    <CompanyInsightsComponent
      insights={insights?.data ?? {}}
      insightsIsLoading={insightsIsLoading}
      filters={filters}
      setFilters={setFilters}
      surveys={surveys ?? []}
      pulseSurveys={pulseSurveys ?? []}
      company={company}
    />
  )
}
