import { useEffect, useMemo, useState } from 'react'

import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from 'react-query'

import { MyRewardsComponent, MyRewardsHome, MyRewardsList } from './components'
import { initialDialog } from 'constants/dialog'
import { API } from 'core/requests'
import { WishType } from 'enums/wish'
import { t } from 'i18n'
import { MyRewardsPurchases } from 'pages'
import { getPagination } from 'utils/pagination'

const slug = 'myRewards'

export enum MyRewardsTab {
  Home = 'home',
  MyRewards = 'myRewards',
  MyPurchases = 'myPurchases',
}

export default function MyRewards() {
  const { enqueueSnackbar } = useSnackbar()

  const [redeemRewardDialog, setRedeemRewardDialog] = useState(initialDialog)
  const [dialogCatalogOpen, setDialogCatalogOpen] = useState(false)
  const [pagination, setPagination] = useState(getPagination(slug))
  const [myWishes, setMyWishes] = useState<any[]>([])
  const [selectedTab, setSelectedTab] = useState(MyRewardsTab.Home)

  const {
    data: myRewards,
    isLoading: myRewardsIsLoading,
    refetch: fetchRewards,
  } = useQuery('getMyRewards', () => API.get('myRewards'))

  const { refetch: fetchWishes, isLoading: wishesAreLoading } = useQuery(
    'getWishlistWishes',
    () => API.get('wishes'),
    {
      onSuccess: response => {
        if (!response.errors) {
          const retailerWishes = response
            .filter(wish => wish.wishable_type === WishType.Retailer)
            .map(wish => ({
              ...wish,
              preview_image:
                wish?.wishable?.brand_with_products?.products[0]?.faceplate_url,
            }))

          const companyWishes = response.filter(
            wish => wish.wishable_type === WishType.Company,
          )

          setMyWishes([...retailerWishes, ...companyWishes])
        }
      },
    },
  )

  const {
    data: topFiveWishlistedRewards,
    refetch: fetchTopFiveWishlistedRewards,
  } = useQuery(
    'getTopFiveWishlistedRewards',
    () =>
      API.get('topFiveWishlistedRewards', null, {
        queryParams: {
          top: 5,
        },
      }),
    { enabled: false },
  )

  const acceptRewardMutation = useMutation(
    (payload: any) => API.update('acceptReward', payload),
    {
      onSuccess: (response: any) => {
        if (response.id) {
          fetchRewards()
          enqueueSnackbar(t('myRewards.snackbar.success.acceptedReward'), {
            variant: 'success',
          })
        }
      },
    },
  )

  const addRewardAmountToWalletMutation = useMutation(
    (payload: any) => API.update('addRewardAmountToWallet', payload),
    {
      onSuccess: (response: any) => {
        if (response.id) {
          fetchRewards()
          enqueueSnackbar(t('myRewards.snackbar.success.bankedReward'), {
            variant: 'success',
          })
        }
      },
    },
  )

  const tabs = useMemo(
    () => [
      {
        value: MyRewardsTab.Home,
        label: t('myRewards.tabLabels.home'),
        Component: MyRewardsHome,
      },
      {
        value: MyRewardsTab.MyRewards,
        label: t('myRewards.tabLabels.myRewards'),
        Component: MyRewardsList,
      },
      {
        value: MyRewardsTab.MyPurchases,
        label: t('myRewards.tabLabels.myPurchases'),
        Component: MyRewardsPurchases,
      },
    ],
    [],
  )

  useEffect(() => {
    fetchTopFiveWishlistedRewards()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MyRewardsComponent
      tabs={tabs}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      myRewards={myRewards}
      myRewardsIsLoading={myRewardsIsLoading}
      wishes={myWishes}
      wishesAreLoading={wishesAreLoading}
      topFiveWishlistedRewards={topFiveWishlistedRewards}
      fetchWishes={fetchWishes}
      fetchRewards={fetchRewards}
      pagination={pagination}
      setPagination={setPagination}
      acceptReward={acceptRewardMutation.mutate}
      addRewardAmountToWallet={addRewardAmountToWalletMutation.mutate}
      redeemRewardDialog={redeemRewardDialog}
      setRedeemRewardDialog={setRedeemRewardDialog}
      dialogCatalogOpen={dialogCatalogOpen}
      setDialogCatalogOpen={setDialogCatalogOpen}
    />
  )
}
