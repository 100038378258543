import React, { useState, useEffect } from 'react'

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Link,
  Paper,
  Typography,
} from '@mui/material'
import {
  ChatOutlined as CommentsIcon,
  Favorite as LikeActiveIcon,
  FavoriteBorder as LikeInactiveIcon,
} from '@mui/icons-material'
import parse from 'html-react-parser'
import { get } from 'lodash'
import { UseMutationResult } from 'react-query'

import {
  FeedPostCommentAction,
  FeedPostHeader,
  FeedPostUsersInteractions,
} from '../../components'
import { GiphyDialog, MediaDisplay, TextFieldWithMentions } from 'components'
import { initialDialog } from 'constants/dialog'
import { UserPermission } from 'enums/user'
import { t } from 'i18n'
import { DialogData, LikeType, PostType } from 'types'
import { mentionsToPlainText } from 'utils/mentionsParser'
import { useURLQuery } from 'utils/hooks'
import { getDisplayNameForGroup } from 'utils/user'
import { MediaType } from 'components/MediaPicker'

type PostAuthor = {
  id: number
  name: string
  avatar: {
    url: string
  }
}

export interface UserData extends PostAuthor {
  sub: number
  sparck_administrator: boolean
  permissions?: any[]
}

export type Props = {
  post: PostType
  setFeedPostsConfirmDelete: React.Dispatch<React.SetStateAction<DialogData>>
  publishPost: (post: {
    id: number
    content: string | undefined
    mentions: number[]
  }) => void
  publishPostStatus: UseMutationResult
  uploadPostMediaStatus: UseMutationResult
  deleteCommentMutation: UseMutationResult
  publishComment: (value: any) => void
  publishCommentStatus: UseMutationResult
  likePost: (params: { post_id: number; method: string }) => void
  user: UserData
}

export default function FeedPost(props: Props) {
  const { post, likePost, user } = props

  const query: any = useURLQuery()

  const highlightedCommentId = +query.get('commentId')

  const [usersInteractionsDialog, setUsersInteractionsDialog] =
    useState<any>(initialDialog)
  const [dialogGifs, setDialogGifs] = useState(false)
  const [editPostState, setEditPostState] = useState<any>({
    content: '',
    mentions: [],
  })
  const [showComment, setShowComment] = useState<boolean>(false)
  const [postIsEditMode, setPostIsEditMode] = useState<boolean>(false)

  const [gif, setGif] = useState({ id: '', src: '' })

  const formattedPost = mentionsToPlainText(post?.content)

  const getNamesFromLikes = likes => {
    if (!likes.length) return ''

    const users: any = likes.map((like: LikeType) => like?.user)
    const employeeNames: any = getDisplayNameForGroup(users)

    return employeeNames
  }

  const LikeIcon = post.current_user_liked_this_post
    ? LikeActiveIcon
    : LikeInactiveIcon

  useEffect(() => {
    setEditPostState({
      content: post.content,
      mentions: post.mentions,
    })
  }, [post])

  useEffect(() => {
    if (
      highlightedCommentId &&
      post.comments?.find(comment => comment?.id === highlightedCommentId)
    ) {
      setShowComment(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedCommentId, post])

  return (
    <>
      <Grid container>
        <Grid item xs={12} key={post.id}>
          <Box width={1} marginTop={2}>
            <Paper data-cy='post'>
              <Box padding={2}>
                <FeedPostHeader
                  {...props}
                  editPostState={editPostState}
                  postIsEditMode={postIsEditMode}
                  setPostIsEditMode={setPostIsEditMode}
                />

                <Grid container>
                  <Grid item xs={12}>
                    <Box my={1} width={1} data-cy='editPostContent'>
                      {postIsEditMode ? (
                        <TextFieldWithMentions
                          initialContent={editPostState.content}
                          initialMentions={post.mentions}
                          onContentChange={(content, mentions) =>
                            setEditPostState({ content, mentions })
                          }
                        />
                      ) : (
                        <Typography variant='body2' color='text.secondary'>
                          {parse(formattedPost)}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {[
                {
                  id: 'card',
                  src: get(post, 'postable.content.card.url'),
                  type: MediaType.SparckCard,
                },
                {
                  id: 'internal_media',
                  src: (get(post, 'postable.type') === 'Recognitions::Recognition') ? get(post, 'postable.content.media_url') : get(post, 'media.url'),
                  type: MediaType.URL,
                },
                {
                  id: 'external_media',
                  src: get(post, 'external_media_url'),
                  type: MediaType.URL,
                },
                {
                  id: get(post, 'gif_id_on_provider'),
                  src: '',
                  type: MediaType.Gif,
                },
              ].map(
                media =>
                  (media.src || (media.id && media.type === MediaType.Gif)) && (
                    <Grid container key={media.id}>
                      <Grid item xs={12} style={{ maxHeight: 800 }}>
                        <Box width={1}>
                          <MediaDisplay
                            media={{
                              id: media.id,
                              src: media.src,
                              isImage: true,
                              name: media.src,
                              type: media.type,
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ),
              )}

              {(post?.likes?.length > 0 || post?.comments?.length > 0) && (
                <>
                  <Grid
                    container
                    spacing={2}
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{ p: 2 }}
                  >
                    <Grid item xs>
                      {post?.likes?.length > 0 && (
                        <Typography variant='body2' color='text.secondary'>
                          <Link
                            onClick={() =>
                              setUsersInteractionsDialog({
                                isOpen: true,
                                data: {
                                  title: 'People who liked this post:',
                                  usersInteractions: post?.likes,
                                } as any,
                              })
                            }
                            color='inherit'
                          >
                            <Grid
                              container
                              component='span'
                              alignItems='center'
                            >
                              <LikeActiveIcon
                                fontSize='small'
                                sx={{
                                  mr: 1,
                                  fill: '#ffa47a',
                                }}
                              />
                              {getNamesFromLikes(post.likes)}
                            </Grid>
                          </Link>
                        </Typography>
                      )}
                    </Grid>

                    {post?.comments?.length > 0 && (
                      <Grid item xs='auto'>
                        <Typography variant='body2' color='text.secondary'>
                          <Link
                            onClick={() => setShowComment(!showComment)}
                            color='inherit'
                          >
                            <Grid
                              container
                              component='span'
                              alignItems='center'
                            >
                              {post?.comments?.length}{' '}
                              {post?.comments?.length === 1
                                ? t('feed.comment')
                                : t('feed.comments')}
                            </Grid>
                          </Link>
                        </Typography>
                      </Grid>
                    )}
                  </Grid>

                  <Grid container>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid container>
                <Grid item xs={6}>
                  <Button
                    data-cy='like_post'
                    sx={{
                      color: post.current_user_liked_this_post
                        ? '#ffa47a'
                        : 'text.secondary',
                      fontWeight: post.current_user_liked_this_post ? 700 : 500,
                    }}
                    size='large'
                    onClick={() =>
                      likePost({
                        post_id: post.id,
                        method: post.current_user_liked_this_post
                          ? 'DELETE'
                          : 'POST',
                      })
                    }
                    fullWidth
                  >
                    <LikeIcon
                      fontSize='small'
                      sx={{
                        mr: 1,
                        fill: post.current_user_liked_this_post
                          ? '#ffa47a'
                          : '',
                      }}
                    />{' '}
                    {t('feed.like')}
                  </Button>
                </Grid>

                {(user.permissions ?? []).includes(
                  UserPermission.ViewComments,
                ) && (
                  <Grid item xs={6}>
                    <Button
                      data-cy='show_comment'
                      onClick={() => setShowComment(!showComment)}
                      sx={{ color: 'text.secondary' }}
                      size='large'
                      fullWidth
                    >
                      <CommentsIcon
                        fontSize='small'
                        style={{ marginRight: 8 }}
                      />{' '}
                      {t('feed.comment')}
                    </Button>
                  </Grid>
                )}
              </Grid>

              <Divider />

              {showComment && (
                <FeedPostCommentAction
                  {...props}
                  gif={gif}
                  setGif={setGif}
                  setDialogGifs={setDialogGifs}
                  highlightedCommentId={highlightedCommentId}
                />
              )}
            </Paper>
          </Box>
        </Grid>
      </Grid>

      <GiphyDialog
        dialogOpen={dialogGifs}
        setDialogOpen={setDialogGifs}
        setGif={setGif}
      />

      <Dialog
        open={usersInteractionsDialog.isOpen}
        maxWidth='xs'
        fullWidth
        onClose={() => setUsersInteractionsDialog(initialDialog)}
      >
        <DialogTitle>{usersInteractionsDialog.data?.title}</DialogTitle>
        <DialogContent sx={{ p: 0, pt: '0px !important', px: 2 }}>
          <FeedPostUsersInteractions
            usersInteractions={usersInteractionsDialog.data?.usersInteractions}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
