import { useEffect, useState } from 'react'

import { get } from 'lodash'
import { useIntl } from 'react-intl'
import { useMutation, useQuery } from 'react-query'
import * as yup from 'yup'

import { ManageRecognitionForm } from './components'
import { FormHandler } from 'components'
import { API } from 'core/requests'
import { NominationFrequency, NominationOccurrenceType } from 'enums/nomination'
import { RecognitionFrequency } from 'enums/recognition'
import { t } from 'i18n'

const validationSchema = yup.object({
  name: yup.string().required(t('nameIsRequired')),
  icon: yup.string().required(t('recognitions.validations.iconIsRequired')),
  maximum_reward_amount_for_each_recognition: yup
    .string()
    .nullable()
    .when('has_reward', {
      is: true,
      then: yup
        .string()
        .nullable()
        .required(t('recognitions.validations.budgetPerRewardIsRequired')),
    }),
  recurrence_type: yup
    .string()
    .nullable()
    .when('has_recurrence', {
      is: true,
      then: yup
        .string()
        .nullable()
        .required(t('recognitions.validations.recurrenceIsRequired')),
    }),
  recurrence_limit: yup.string().when('has_recurrence', {
    is: true,
    then: yup.string().required(t('recognitions.validations.limitIsRequired')),
  }),
  time_frame_start_at: yup
    .date()
    .nullable()
    .when('has_time_frame', {
      is: true,
      then: yup
        .date()
        .nullable()
        .required(t('recognitions.validations.startDateIsRequired')),
    }),
  time_frame_end_at: yup
    .date()
    .nullable()
    .when('has_time_frame', {
      is: true,
      then: yup
        .date()
        .nullable()
        .min(
          yup.ref('time_frame_start_at'),
          t('recognitions.validations.minimumEndDate'),
        )
        .required(t('recognitions.validations.endDateIsRequired')),
    }),
})

export enum NavigationMode {
  Tabs = 'tabs',
  Steps = 'steps',
}

export default function ManageRecognition({
  dialog,
  closeDialog,
  fetchRows,
}: any) {
  const intl = useIntl()

  const [navigationMode, setNavigationMode] = useState(null)
  const [nominationProgramId, setNominationProgramId] = useState(null)
  const [nominationProgramState, setNominationProgramState] =
    useState<any>(null)

  const initialValues: any = dialog.data
    ? dialog.data
    : {
        name: '',
        icon: '',
        has_employee_choice: false,
        has_reward: false,
        reward_type: 'reward',
        has_annual_limit: false,
        annual_limit: 0,
        has_approval: false,
        has_recurrence: false,
        recurrence_type: RecognitionFrequency.Annually,
        recurrence_limit: 1,
        has_recurrence_limit_per_employee: false,
        recurrence_limit_per_employee: 5,
        has_milestone: false,
        milestones_attributes: [],
        has_time_frame: false,
        time_frame_start_at: null,
        time_frame_end_at: null,
        has_limit_to_same_person: false,
        limit_to_same_person: 0,
        has_limit_from_same_person: false,
        limit_from_same_person: 0,
        has_maximum_reward_amount_for_each_recognition: false,
        maximum_reward_amount_for_each_recognition: 0,
        has_accept_action: false,
        has_bank_action: false,
        has_roles_who_can_recognize: false,
        roles_who_can_recognize: [],
        has_roles_who_can_reward: false,
        roles_who_can_reward: [],
        has_demographics: false,
        demographics: [],
        has_nominations: false,
        nomination: {
          occurrence_type: NominationOccurrenceType.AlwaysOpen,
          frequency: NominationFrequency.Annually,
          days_prior_start: 0,
          days_upon_finish: 0,
        },
      }

  const { data: demographics, isLoading: demographicsIsLoading } = useQuery(
    'getDemographics',
    () => API.get('demographics'),
  )

  const { data: roles, isLoading: rolesIsLoading } = useQuery('getRoles', () =>
    API.get('roles'),
  )

  const { data: nominationProgram } = useQuery(
    'getNominationProgram',
    () => API.get('nominationsPrograms', nominationProgramId),
    {
      enabled: !!nominationProgramId,
    },
  )

  const updateNominationProgramMutation = useMutation(
    (payload: any) => API.update('nominationsPrograms', payload),
    {
      onSettled: () => {
        setNominationProgramState(null)
      },
    },
  )

  useEffect(() => {
    if (
      nominationProgramState?.id &&
      nominationProgramState?.frequency &&
      !updateNominationProgramMutation.isLoading
    ) {
      updateNominationProgramMutation.mutate(nominationProgramState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nominationProgramState])

  return (
    <>
      <FormHandler
        title={intl.formatMessage({
          id: 'recognitions.manageRecognitionsTitle',
        })}
        descriptionCreateMode={t('recognitions.whatSpecialEvent')}
        requestUrl='recognitions'
        detailsRequestId={get(dialog, 'data.id')}
        detailsRequestCallback={response => {
          if (response.nomination_program_id) {
            setNominationProgramId(response.nomination_program_id)
          }
        }}
        data={dialog.data}
        closeDialog={closeDialog}
        fetchRows={fetchRows}
        initialValues={initialValues}
        validationSchema={validationSchema}
        customMutationRequest={async (payload: any) => {
          await setNominationProgramState(state => ({
            ...state,
            ...payload?.nomination,
          }))

          const demographicsCriteria = payload.demographics?.filter(
            demographic => demographic.demographics?.length > 0,
          )

          return API.createOrUpdate('recognitions', {
            ...payload,
            has_time_frame:
              payload.time_frame_start_at && payload.time_frame_end_at,
            has_maximum_reward_amount_for_each_recognition:
              +payload.maximum_reward_amount_for_each_recognition > 0,
            has_demographics: demographicsCriteria?.length > 0,
            demographics: demographicsCriteria,
            has_roles_who_can_recognize:
              payload.roles_who_can_recognize?.length > 0,
            has_roles_who_can_reward: payload.roles_who_can_reward?.length > 0,
            has_roles_filter:
              payload.roles_who_can_recognize?.length > 0 ||
              payload.roles_who_can_be_recognized?.length > 0,
          })
        }}
        mutationOnSuccess={response => {
          if (response.nomination_program_id) {
            setNominationProgramState(state => ({
              ...state,
              id: response.nomination_program_id,
            }))
          }

          closeDialog()
          fetchRows()
        }}
        hideActionButton
        hideHeader={navigationMode !== NavigationMode.Tabs}
      >
        {({ formik, mutation, isEditMode }) => (
          <ManageRecognitionForm
            title={intl.formatMessage({ id: 'recognitions.recognitionEvents' })}
            formik={formik}
            mutation={mutation}
            isEditMode={isEditMode}
            roles={roles}
            rolesIsLoading={rolesIsLoading}
            nominationProgram={nominationProgram?.data}
            demographics={demographics}
            demographicsIsLoading={demographicsIsLoading}
            navigationMode={navigationMode}
            setNavigationMode={setNavigationMode}
          />
        )}
      </FormHandler>
    </>
  )
}
