import { Box, Divider } from '@mui/material'

import {
  ManageRecognitionInfo,
  ManageRecognitionWhoIsThisEventFor,
  ManageRecognitionWhoCanRecognize,
} from '..'

export default function ManageRecognitionGeneral(props) {
  const { formik } = props

  return (
    <Box>
      <ManageRecognitionInfo formik={formik} />

      <Divider sx={{ my: 3 }} />

      <ManageRecognitionWhoIsThisEventFor {...props} />

      <Divider sx={{ my: 3 }} />

      <ManageRecognitionWhoCanRecognize {...props} />
    </Box>
  )
}
