import {
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Typography,
  Box,
} from '@mui/material'
import { usePopupState, bindMenu } from 'material-ui-popup-state/hooks'
import { useMutation, useQueryClient } from 'react-query'
import React from 'react'
import { useLocale, useUser } from 'contexts'
import { LANGUAGES } from 'constants/locale'
import { API } from 'core/requests'
import { t } from 'i18n'

export default function SurveyLanguagePicker() {
  const { user } = useUser()!
  const { setLocale, setAPILanguageChanged } = useLocale()!

  const queryClient = useQueryClient()

  const localeMenuState = usePopupState({
    variant: 'popover',
    popupId: `localeMenu`,
  })

  const changeLanguageMutation = useMutation(
    (selectedLanguage: any) =>
      API.update('users', {
        id: user.id,
        preferred_language: selectedLanguage,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries()
        setAPILanguageChanged(true)
      },
    },
  )

  const handleLanguageChange = language => {
    setLocale(language.value)
    localStorage.setItem('locale', language.value)
    changeLanguageMutation.mutate(language.id)

    bindMenu(localeMenuState).onClose()
    setOpen(false)
  }
  const [open, setOpen] = React.useState(true)

  const handleClose = () => {
    setOpen(false)
  }
  const renderLanguages = () => {
    return (
      <>
        {/* <Grid item>
          <Button
            size='small'
            sx={{
              fontSize: '1rem',
              fontWeight: 600,
              color: 'text.secondary',
              minWidth: '40px',
            }}
            {...bindTrigger(localeMenuState)}
          >
            {LANGUAGES.find(
              language => language.value === locale ?? Locale.English,
            )?.id.toUpperCase()}

            <ArrowDropDownIcon />
          </Button>
        </Grid> */}
        {/* <Menu {...bindMenu(localeMenuState)}> */}

        {/* <Typography
          variant={'h5'}
          color='text.secondary'
          fontWeight={700}
          sx={{ fontSize: 'h6.fontSize', m: 1 }}
        >
          <Grid
            container
            justifyContent='center'
            alignItems='stretch'
            sx={{
              mt: 4,
            }}
          >
            Choose the Language
          </Grid>
        </Typography> */}

        <div>
          {/* <Button variant="contained" onClick={handleClickOpen}>
      Choose the Language
      </Button> */}
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
              <Typography
                variant={'h5'}
                color='text.secondary'
                fontWeight={700}
                sx={{ fontSize: 'h6.fontSize', m: 1 }}
              >
                <Grid
                  container
                  justifyContent='center'
                  alignItems='center'
                  sx={{
                    mt: 2,
                  }}
                >
                  {t('language.chooselanguage')}
                </Grid>
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Grid container>
                  {LANGUAGES.map(language => (
                    <Grid
                      item
                      xs={12}
                      md={10}
                      lg={6}
                      xl={5}
                      display={'flex'}
                      justifyContent={'center'}
                    >
                      <Button
                        color='primary'
                        onClick={() => handleLanguageChange(language)}
                      >
                        <Box
                          component='span'
                          sx={{
                            p: 1,
                            ml: 8,
                            width: 250,
                            border: '2px solid grey',
                          }}
                        >
                          {' '}
                          {language.label}
                        </Box>
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        </div>

        {/* <Grid container justifyContent='center'>
          <Grid item xs={12} md={10} lg={6} xl={7}>
            <Grid
              container
              spacing={6}
              alignItems='stretch'
              justifyContent='center'
              sx={{
                mt: 4,
              }}
            >
              <Paper elevation={12}  style={{paddingTop:20}}>

              <RadioGroup
                row
                aria-label='language'
                sx={{ justifyContent: 'center' }}
                name='language'
                value={locale ? locale : Locale.English}
              // onChange={event =>        //   setProfileState({        //     ...profileState,        //     [setting.id]: event.target.value,        //   })        //  }
              >
                {LANGUAGES.map(language => (
                  <FormControlLabel
                    key={language.value}
                    value={language.value}
                    control={<Radio />}
                    label={language.label}
                    onClick={() => handleLanguageChange(language)}
                  />
                ))}
              </RadioGroup>
              </Paper>
            </Grid>
          </Grid>
        </Grid> */}

        {/* {LANGUAGES.map(language => (
          <Radio
            key={language.value}
            value={language.value}
            onClick={() => handleLanguageChange(language)}
          >
            {language.label}
          </Radio> */}
        {/* ))} */}
        {/* </Menu> */}
      </>
    )
  }

  // if (company && company.allow_internationalization) {
  return <Grid item>{renderLanguages()}</Grid>
  // }

  return null
}
