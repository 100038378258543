import { CheckCircle as CheckIcon } from '@mui/icons-material'
import { Grid, Paper, Typography } from '@mui/material'
import { t } from 'i18n'

export default function ManagerCertificationCelebrationBoard(props) {
  const { userCertificationBoards } = props

  const usersWhoFinishedCertification =
    userCertificationBoards?.random_completed_users

  function EmptyCelebrationBoardMessage() {
    return (
      <Grid container justifyContent='center'>
        <Grid item>
          <Typography align='center' variant='h6' color='text.light'>
            {t('managerCertification.noOneHasCompletedTheCertification')}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container>
      <Typography color='text.primary' variant='h5' fontWeight={600} paragraph>
        {t('managerCertification.celebrationBoard.title')}
      </Typography>
      <Typography color='text.secondary' variant='body1' paragraph>
        {t('managerCertification.celebrationBoard.description')}
      </Typography>

      {usersWhoFinishedCertification?.length ? (
        <Grid
          container
          sx={{
            borderRadius: '4px',
            backgroundColor: 'text.veryLight',
            mt: 2,
            pb: 2,
          }}
        >
          <Grid item xs={12} my={4}>
            <Typography
              color='text.primary'
              variant='h5'
              fontWeight={600}
              textAlign='center'
              paragraph
            >
              {t('managerCertification.celebrationBoard.congratulations')}
            </Typography>
          </Grid>
          {usersWhoFinishedCertification?.map(user => (
            <Grid item xs={12} md={4} key={user.user_id}>
              <Grid
                container
                justifyContent='center'
                alignItems='center'
                py={2}
              >
                <CheckIcon sx={{ color: 'primary.main', mr: 1 }} />
                <Grid item xs={8}>
                  <Paper
                    sx={{
                      borderRadius: '4px',
                      px: 2,
                      py: 1,
                      minWidth: '80%',
                      textAlign: 'center',
                    }}
                  >
                    {user.full_name}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      ) : (
        <EmptyCelebrationBoardMessage />
      )}
    </Grid>
  )
}
