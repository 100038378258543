export const adminEmails = [
  {
    id: 1,
    name: 'emails.welcomeEmailCompany.title',
    description: 'emails.welcomeEmailCompany.description',
    endpoint: 'sendWelcomeEmail',
  },
]

export const companyEmails = [
  {
    id: 1,
    name: 'emails.welcomeEmailAllEmployees.title',
    description: 'emails.welcomeEmailAllEmployees.description',
    endpoint: 'sendWelcomeEmailToEmployees',
    getPayload: company => ({ id: company.id, only_not_invited_yet: false }),
  },
  {
    id: 2,
    name: 'emails.welcomeEmailNewEmployees.title',
    description: 'emails.welcomeEmailNewEmployees.description',
    endpoint: 'sendWelcomeEmailToEmployees',
    getPayload: company => ({ id: company.id, only_not_invited_yet: true }),
  },
]

export const employeeEmails = [
  {
    id: 1,
    name: 'emails.welcomeEmailEmployee.title',
    description: 'emails.welcomeEmailEmployee.description',
    endpoint: 'sendWelcomeEmailToEmployee',
    getPayload: employee => ({ id: employee.id }),
  },
  // {
  //   id: 2,
  //   name: 'emails.betaTeamInviteEmail.title',
  //   description: 'emails.betaTeamInviteEmail.description',
  //   endpoint: 'sendBetaTeamInvite',
  //   getPayload: employee => ({ id: employee.id }),
  // },
  {
    id: 3,
    name: 'emails.resendSurvey.title',
    description: 'emails.resendSurvey.description',
    endpoint: 'resendEmailToEmployee',
    getPayload: employee => ({ email: employee.email }),
  },
  {
    id: 4,
    name: 'emails.resendPulseSurvey.title',
    description: 'emails.resendPulseSurvey.description',
    endpoint: 'resendPulseSurveyEmailToEmployee',
    getPayload: employee => ({ company_id: employee.id, email: employee.email }),
  },
]
