import React from 'react'

import { Grid } from '@mui/material'
import pluralize from 'pluralize'

import { Props } from '../../CompanyAdminLayout.types'
import { SparckTable } from 'components'
import { DialogData } from 'types'

type TableProps = Props & {
  slug: string
  data: Record<string, unknown>[]
  dataIsLoading?: boolean
  pagination: { currentPage: number; pageSize: number }
  setPagination: React.Dispatch<
    React.SetStateAction<{
      pageSize: number
      currentPage: number
    }>
  >
  setDialogForm: React.Dispatch<React.SetStateAction<DialogData>>
  setDialogDelete: React.Dispatch<React.SetStateAction<DialogData>>
  updateItem: any
  userHasManagementPermission: boolean
  totalCount?:number |undefined
  setSearchText?: React.Dispatch<
    React.SetStateAction<string>
  >
}

export default function CompanyAdminLayoutTable(props: TableProps) {
  const {
    tableProps,
    slug,
    data,
    title,
    dataIsLoading,
    pagination,
    setPagination,
    setDialogForm,
    setDialogDelete,
    ManagementForm,
    updateItem,
    userHasManagementPermission,
    intl,
    totalCount,
    setSearchText
  } = props

  if (tableProps) {
    const { editActionProps = {}, deleteActionProps = {} } = tableProps

    return (
      <Grid container justifyContent='center'>
        <Grid item xs={12}>
          <SparckTable
            {...tableProps}
            data={data}
            isLoading={dataIsLoading}
            slug={slug}
            title={title}
            updateItem={updateItem}
            pagination={pagination}
            setPagination={setPagination}
            {...(userHasManagementPermission && {
              editAction: {
                hidden: row =>
                  (editActionProps.hidden && editActionProps.hidden(row)) ||
                  !ManagementForm,
                onClick: row => {
                  if (editActionProps.onClick)
                    return editActionProps.onClick(row)
                  setDialogForm({ isOpen: true, data: row })
                },
                disabled: row =>
                  editActionProps.disabled && editActionProps.disabled(row),
                tooltip: editActionProps.tooltip ?? null,
              },
              deleteAction: {
                hidden: row =>
                  !!(deleteActionProps.hidden && deleteActionProps.hidden(row)),
                onClick: row => {
                  if (deleteActionProps.onClick)
                    return deleteActionProps.onClick(row)
                  setDialogDelete({ isOpen: true, data: row })
                },
                disabled: row =>
                  deleteActionProps.disabled && deleteActionProps.disabled(row),
                tooltip:
                  deleteActionProps.tooltip ??
                  `${intl.formatMessage({
                    id: 'companyAdminLayout.remove',
                  })} ${pluralize.singular(title)}`,
              },
            })}
            cellEditable={{
              isCellEditable: () => true,
              onCellEditApproved: async (
                newValue,
                oldValue,
                rowData,
                columnDef: any,
              ) => {
                if (newValue) {
                  await updateItem({ ...rowData, [columnDef.field]: newValue })
                }
              },
            }}
            style={{
              marginTop: 24,
            }}
            totalCount={totalCount}
            setSearchText={setSearchText}
          />
        </Grid>
      </Grid>
    )
  }

  return null
}
