import { useState } from 'react'

import { Box, Dialog, DialogContent, Grid, Tab, Tabs } from '@mui/material'

import {
  BudgetDetailsBody,
  BudgetDetailsHeader,
  BudgetReports,
} from '../../components'
import { Props } from '../BudgetComponent/BudgetComponent'
import { initialDialog } from 'constants/dialog'
import { t } from 'i18n'
import { ExternalRewardsUpload, ManageBudget } from 'pages'
import { a11yTabProps } from 'utils/accessibility'

enum BudgetDetailsTab {
  General = 'general',
  ExternalRewardsUpload = 'externalRewardsUpload',
  Reports = 'reports',
}

const tabs = [
  {
    value: BudgetDetailsTab.General,
    label: t('budget.budgetDetails.tabLabels.general'),
    Component: BudgetDetailsBody,
  },
  {
    value: BudgetDetailsTab.ExternalRewardsUpload,
    label: t('budget.budgetDetails.tabLabels.externalRewardsUpload'),
    Component: ExternalRewardsUpload,
  },
  {
    value: BudgetDetailsTab.Reports,
    label: t('budget.budgetDetails.tabLabels.reports'),
    Component: BudgetReports,
  },
]

export default function BudgetDetails(props: Props) {
  const [manageBudgetDialog, setManageBudgetDialog] = useState(initialDialog)
  const [selectedTab, setSelectedTab] = useState(BudgetDetailsTab.General)

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  const handleManageBudgetDialogClose = () =>
    setManageBudgetDialog({ data: null, isOpen: false })

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BudgetDetailsHeader
            {...props}
            setManageBudgetDialog={setManageBudgetDialog}
          />
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label='basic tabs example'
            >
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  data-cy='tabs'
                  value={tab.value}
                  label={tab.label}
                  {...a11yTabProps(index)}
                />
              ))}
            </Tabs>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {selectedTab === BudgetDetailsTab.General && (
                <BudgetDetailsBody {...props} />
              )}

              {selectedTab === BudgetDetailsTab.ExternalRewardsUpload && (
                <ExternalRewardsUpload {...props} />
              )}

              {selectedTab === BudgetDetailsTab.Reports && <BudgetReports />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={manageBudgetDialog.isOpen}
        onClose={handleManageBudgetDialogClose}
        maxWidth={'lg'}
        fullWidth
      >
        <DialogContent sx={{ backgroundColor: 'background.default' }}>
          <ManageBudget
            dialog={manageBudgetDialog}
            closeDialog={handleManageBudgetDialogClose}
            fetchBudgetSettings={props.fetchBudgetSettings}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}
