import { useState } from 'react'

import { Button, CircularProgress, Grid, TextField } from '@mui/material'

import { t } from 'i18n'
import { EmployeeType } from 'types'

type Props = {
  employee: EmployeeType | null
  changePasswordMutation: any
}

export default function EmployeesChangePassword({
  employee,
  changePasswordMutation,
}: Props) {
  const [password, setPassword] = useState('')

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          id='password'
          name='password'
          label={t('employees.changeEmployeePasswordDialog.changePassword')}
          variant='outlined'
          value={password}
          onChange={event => setPassword(event.target.value)}
          margin='normal'
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          variant='contained'
          color='primary'
          size='large'
          disabled={!password || changePasswordMutation.isLoading}
          onClick={() => {
            changePasswordMutation.mutate({
              id: employee?.id,
              new_password: password,
            })
          }}
          fullWidth
        >
          {t('employees.changeEmployeePasswordDialog.changePassword')}{' '}
          {changePasswordMutation.isLoading && (
            <CircularProgress style={{ marginLeft: 16 }} size={24} />
          )}
        </Button>
      </Grid>
    </Grid>
  )
}
