import { Checkbox, Grid } from '@mui/material'
import { useIntl } from 'react-intl'

import { SettingsControlSection } from '../SettingsComponent/SettingsComponent'

export default function SettingsInsightsPermissions(props) {
  const intl = useIntl()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <SettingsControlSection
          {...props}
          id='display_organization_wide_results'
          label={intl.formatMessage({
            id: 'settings.sectionControlLabels.insightsPermissions.organizationWideBeheard',
          })}
          ControlComponent={Checkbox}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <SettingsControlSection
          {...props}
          id='display_pulse_survey_results'
          label={intl.formatMessage({
            id: 'settings.sectionControlLabels.insightsPermissions.pulseSurvey',
          })}
          ControlComponent={Checkbox}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <SettingsControlSection
          {...props}
          id='display_managers_teams_beheard_results'
          label={intl.formatMessage({
            id: 'settings.sectionControlLabels.insightsPermissions.managerTeamBeheard',
          })}
          ControlComponent={Checkbox}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <SettingsControlSection
          {...props}
          id='display_managers_teams_pulse_survey_results'
          label={intl.formatMessage({
            id: 'settings.sectionControlLabels.insightsPermissions.managerTeamPulseSurvey',
          })}
          ControlComponent={Checkbox}
        />
      </Grid>
    </Grid>
  )
}
