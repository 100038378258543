export enum SuggestionStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Declined = 'declined',
  Reviewed = 'reviewed',
  Implemented = 'implemented',
}

export enum AssignMode {
  Assign = 'assign',
  Unassign = 'unassign',
}
