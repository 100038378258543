import { Box, Grid } from '@mui/material'

import {
  EngagementDriverScoresComponent,
  EngagementScoreComponent,
  TopFiveAreas,
} from 'components'
import { t } from 'i18n'

export default function BeheardReportResults(props: any) {
  const {
    Description,
    surveyDetails,
    isFullReport,
    addSurveyToCart,
    addSurveyToCartIsLoading,
  } = props

  const { sections_allowed } = surveyDetails

  return (
    <Grid container>
      <Grid item xs={12}>
        {sections_allowed?.results && (
          <>
            <Description>{t('beheardReport.results.description')}</Description>

            <Box my={3}>
              <Grid container spacing={2} justifyContent='space-evenly'>
                <Grid item xs={12}>
                  <Box width={1} height={1} py={2} px={3} pt={0}>
                    <EngagementScoreComponent surveyDetails={surveyDetails} />
                  </Box>
                </Grid>
                <Box display={{ xs: 'block', sm: 'none' }}>
                  <Grid item>
                    <div
                      style={{
                        height: '100%',
                        width: 1,
                        backgroundColor: '#EEE',
                      }}
                    ></div>
                  </Grid>
                </Box>
                <Grid item xs={12}>
                  <EngagementDriverScoresComponent
                    surveyDetails={surveyDetails}
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        )}

        {(sections_allowed?.top_5_action_areas ||
          sections_allowed?.top_5_engagement_strengths ||
          sections_allowed?.top_5_importance) && (
          <TopFiveAreas
            surveyDetails={surveyDetails}
            isFullReport={isFullReport}
            addSurveyToCart={addSurveyToCart}
            addSurveyToCartIsLoading={addSurveyToCartIsLoading}
          />
        )}
      </Grid>
    </Grid>
  )
}
