import { Box, useTheme } from '@mui/material'
import sparckLogoWhite from 'assets/images/sparck-logo-white.png'

export default function SparckFlag({ left }: any) {
  const theme = useTheme()

  return (
    <Box
      sx={{
        top: 0,
        bottom: 'inherit',
        [left ? 'left' : 'right']: 24,
        width: 60,
        height: 100,
        position: 'absolute',
        background: `linear-gradient(180deg, ${theme.palette.error.main} 0%, ${theme.palette.secondary.light} 100%) !important`,
      }}
    >
      <Box
        component='div'
        sx={{
          position: 'absolute',
          bottom: -1,
          left: 0,
          right: 0,
          borderLeft: '30px solid transparent',
          borderRight: '30px solid transparent',
          borderBottom: '20px solid #fff',
        }}
      />
      <Box
        component='img'
        sx={{
          width: '70%',
          margin: '0 auto',
          display: 'block',
          marginTop: '20px',
        }}
        src={sparckLogoWhite}
        alt='Sparck logo'
      />
    </Box>
  )
}
