import { useEffect, useState } from 'react'

import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { useQuery, useMutation } from 'react-query'

import {
  useFetchSurveys,
  useFetchCompanyAdministrators,
  useFetchEmployeesCount,
  useFetchSuggestions,
} from './Company.queries'
import { CompanyComponent } from './components'
import { useCompany, useShoppingCartItemsCount } from 'contexts'
import { useUser } from 'contexts/UserContext'
import { initialDialog } from 'constants/dialog'
import { API } from 'core/requests'
import { ROUTES_PATHS } from 'core/routes'
import { t } from 'i18n'
import { CompanyType } from 'types'

export default function Company() {
  const initialCompany: CompanyType = {}

  const { setShoppingCartItemsCount } = useShoppingCartItemsCount()!

  const [companyEmailsDialog, setCompanyEmailsDialog] = useState(initialDialog)
  const [companyState, setCompanyState] = useState(initialCompany)

  const { isLoading: isSurveysLoading, data: surveys } = useFetchSurveys()
  const { isLoading: isSuggestionsLoading, data: suggestions } =
    useFetchSuggestions()
  const { isLoading: companyAdminsIsLoading, data: companyAdmins } =
    useFetchCompanyAdministrators()

  const { user } = useUser()!
  const { company, setCompany } = useCompany()!

  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const { data: pulseSurveys } = useQuery('getPulseSurveys', () =>
    API.get('pulseSurveys'),
  )

  const {
    isLoading: employeesCountIsLoading,
    data: employeesCount,
    refetch: fetchEmployeesCount,
  } = useFetchEmployeesCount(get(company, 'id') ?? company)

  const updateCompanyMutation = useMutation(
    (payload: any) => {
      const companyId = company ? get(payload, 'id') : null 
      
      return API.update('companies', payload, {
        pathParams: {
          id: companyId ? companyId : null,
        },
      })
    },
    {
      onSettled: (response: any) => {
        if (response?.company) {
          setCompany(response.company)
          enqueueSnackbar(t('myCompany.snackbarSuccess.updated'), {
            variant: 'success',
          })
        }
      },
    },
  )

  const addSurveyToCartMutation = useMutation(
    (payload: any) => API.create('shoppingCartAddItem', payload),
    {
      onSettled: (response: any) => {
        if ((response && response.id) || (response && response.success)) {
          enqueueSnackbar(t('myCompany.snackbarSuccess.surveyAddedToCart'), {
            variant: 'success',
          })
          setShoppingCartItemsCount(state => state + 1)
          history.push(ROUTES_PATHS.marketing)
        } else if (response && response.status === 'conflict') {
          history.push(ROUTES_PATHS.invoices)
        }
      },
    },
  )

  const sendWelcomeEmailToEmployeesMutation = useMutation(
    (payload: any) => API.create('sendWelcomeEmailToEmployeesEmail', payload),
    {
      onSettled: (response: any) => {
        if (response && response.success) {
          enqueueSnackbar(response.message, {
            variant: 'success',
          })
          setCompanyEmailsDialog(initialDialog)
        }
      },
    },
  )

  const MAP_ENDPOINT_TO_FUNCTION = {
    sendWelcomeEmailToEmployees: sendWelcomeEmailToEmployeesMutation.mutate,
  }

  const handleSendEmail = (endpoint, payload) => {
    MAP_ENDPOINT_TO_FUNCTION[endpoint](payload)
  }

  useEffect(() => {
    if (get(company, 'id')) {
      setCompanyState({ ...company })
      fetchEmployeesCount()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company])

  return (
    <CompanyComponent
      company={company}
      surveys={surveys ?? []}
      isSurveysLoading={isSurveysLoading}
      pulseSurveys={(pulseSurveys ?? []).slice(0, 5)}
      isPulseSurveysLoading={false}
      suggestions={suggestions ?? []}
      isSuggestionsLoading={isSuggestionsLoading}
      companyAdmins={companyAdmins ?? []}
      companyAdminsIsLoading={companyAdminsIsLoading}
      employeesCount={employeesCount?.count}
      isEmployeesCountLoading={employeesCountIsLoading}
      user={user}
      addSurveyToCart={addSurveyToCartMutation.mutate}
      addSurveyToCartIsLoading={addSurveyToCartMutation.isLoading}
      companyEmailsDialog={companyEmailsDialog}
      setCompanyEmailsDialog={setCompanyEmailsDialog}
      handleSendEmail={handleSendEmail}
      companyState={companyState}
      setCompanyState={setCompanyState}
      handleCompanyAPIAction={updateCompanyMutation.mutate}
    />
  )
}
