import { useState } from 'react'

import { Box, Grid, IconButton, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  HelpOutline as QuestionIcon,
  SentimentVeryDissatisfied as SentimentVeryDissatisfiedIcon,
  SentimentDissatisfied as SentimentDissatisfiedIcon,
  SentimentSatisfied as SentimentSatisfiedIcon,
  SentimentSatisfiedAlt as SentimentSatisfiedAltIcon,
  SentimentVerySatisfied as SentimentVerySatisfiedIcon,
  Star as StarIcon,
} from '@mui/icons-material'
import { Rating } from '@mui/material'

import { RatingItemAnswered } from '../../components'
import { SurveyQuestionCardProps } from '../SurveyQuestionCard/SurveyQuestionCard'
import { SurveyQuestionScoreType } from 'enums/survey'
import { RatingItemType } from 'types'
import { t } from 'i18n'

type RatingItemProps = SurveyQuestionCardProps & {
  handleRating(value: string | number, accessor: string): any
}

const renderIcon = Icon => <Icon style={{ fontSize: 40, margin: '0px 8px' }} />

const satisfactionIcons = {
  1: SentimentVeryDissatisfiedIcon,
  2: SentimentDissatisfiedIcon,
  3: SentimentSatisfiedIcon,
  4: SentimentSatisfiedAltIcon,
  5: SentimentVerySatisfiedIcon,
}

const ratingLabels = {
  1: t('ratingSection.ratingLabels.stronglyDisagree'),
  2: t('ratingSection.ratingLabels.disagree'),
  3: t('ratingSection.ratingLabels.neutral'),
  4: t('ratingSection.ratingLabels.agree'),
  5: t('ratingSection.ratingLabels.stronglyAgree'),
}

const useStyles = makeStyles(theme => ({
  satisfactionIcon: {
    color: theme.palette.primary.main,
  },
  importanceIcon: {
    color: theme.palette.secondary.main,
  },
}))

function SatisfactionIcons(props) {
  const { value, ...other } = props
  return <span {...other}>{renderIcon(satisfactionIcons[value])}</span>
}

function ImportanceIcons(props) {
  const { ...other } = props
  return <span {...other}>{renderIcon(StarIcon)}</span>
}

export default function RatingSectionItems(props: RatingItemProps) {
  const { surveyQuestion, isMobile, handleRating, isUnansweredQuestion } = props
  const classes = useStyles()

  const [ratingHover, setRatingHover] = useState<number>(0)

  const accessor = !surveyQuestion.satisfactionScore
    ? SurveyQuestionScoreType.SatisfactionScore
    : !surveyQuestion.importanceScore
    ? SurveyQuestionScoreType.ImportanceScore
    : ''

  const renderItem = (
    ratingItem: RatingItemType,
    value: number | string,
    accessor: string,
    iconFontSize: string,
  ) => {
    const Icon = ratingItem.Icon ? ratingItem.Icon : QuestionIcon

    return (
      <IconButton
        onClick={() => handleRating(value, accessor)}
        size={isMobile ? 'small' : 'medium'}
      >
        <Tooltip title={ratingItem.description}>
          <Icon
            fontSize={iconFontSize}
            style={{
              fill: isUnansweredQuestion
                ? '#DDD'
                : ratingItem.color
                ? ratingItem.color
                : '',
            }}
          />
        </Tooltip>
      </IconButton>
    )
  }

  return (
    <>
      {surveyQuestion.satisfactionScore !== 0 && (
        <RatingItemAnswered
          {...props}
          accessor={SurveyQuestionScoreType.SatisfactionScore}
          renderItem={renderItem}
        />
      )}
      {surveyQuestion.importanceScore !== 0 && (
        <RatingItemAnswered
          {...props}
          accessor={SurveyQuestionScoreType.ImportanceScore}
          renderItem={renderItem}
        />
      )}

      {(!surveyQuestion.satisfactionScore ||
        !surveyQuestion.importanceScore) && (
        <Box position='relative'>
          {!isMobile && ratingHover > 0 && (
            <Grid
              item
              xs={12}
              style={{
                position: 'absolute',
                top: '-6px',
                bottom: 0,
                right: '-60px',
              }}
            >
              <Grid container justifyContent='center'>
                <Tooltip open title={ratingLabels[ratingHover]}>
                  <div></div>
                </Tooltip>
              </Grid>
            </Grid>
          )}
          <Rating
            key={`${surveyQuestion.id}-${
              !surveyQuestion.satisfactionScore ? 'satisfaction' : 'importance'
            }`}
            classes={{
              iconFilled: !surveyQuestion.satisfactionScore
                ? classes.satisfactionIcon
                : classes.importanceIcon,
            }}
            name={`rating-${surveyQuestion.id}-${
              !surveyQuestion.satisfactionScore ? 'satisfaction' : 'importance'
            }`}
            IconContainerComponent={
              !surveyQuestion.satisfactionScore
                ? SatisfactionIcons
                : ImportanceIcons
            }
            onChange={(event, value: any) => {
              handleRating(value, accessor)
            }}
            onChangeActive={(event, newHover) => {
              setRatingHover(newHover)
            }}
          />
        </Box>
      )}
    </>
  )
}
