import { useState, useEffect } from 'react'

import { isEmpty, omit } from 'lodash'
import { useSnackbar } from 'notistack'
import { useQuery, useMutation } from 'react-query'
import { useLocation, useHistory } from 'react-router-dom'

import { BeheardReportComponent } from './components'
import { API } from 'core/requests'
import { ROUTES_PATHS } from 'core/routes'
import { useLocale, useShoppingCartItemsCount } from 'contexts'
import { t } from 'i18n'

function useURLQuery() {
  return new URLSearchParams(useLocation().search)
}

export const getSurveyReportQueryParams = (filters, shared_report_id = '') => {
  const newFilters: any = { criteria: [] }

  Object.keys(omit(filters, 'roles')).forEach(key => {
    if (filters[key].length) {
      newFilters.criteria.push({
        category: +key,
        demographics: filters[key],
      })
    }
  })

  if (shared_report_id) {
    newFilters.shared_report_id = shared_report_id
  }

  if (filters.roles) {
    newFilters.roles = filters.roles
  }

  return newFilters
}

let filtersQuantity = 0

export default function BeheardReport() {
  const initialBeheardReport: any = {}
  const [beheardReport, setBeheardReport] = useState(initialBeheardReport)

  const [filtersState, setFiltersState] = useState({})
  const [filtersQuantityState, setFiltersQuantityState] = useState(0)

  const [minimumParticipantsAlert, setMinimumParticipantsAlert] =
    useState(false)
  const { setShoppingCartItemsCount } = useShoppingCartItemsCount()!

  useEffect(() => {
    filtersQuantity = 0

    if (!isEmpty(filtersState)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [_, value] of Object.entries(filtersState)) {
        // @ts-ignore
        filtersQuantity += value.length
      }
    }

    setFiltersQuantityState(filtersQuantity)
  }, [filtersState])

  const history = useHistory()
  const query = useURLQuery()
  const { APILanguageChanged, setAPILanguageChanged } = useLocale()!

  const { enqueueSnackbar } = useSnackbar()

  const isFullReport = query.get('full_report')

  useEffect(
    () => {
      const showPreviousReportVisual =
        query.get('id') == '369' || query.get('id') == '844' ? 'true' : 'false'
      localStorage.setItem('showPreviousReportVisual', showPreviousReportVisual)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const addSurveyToCartMutation = useMutation(
    (payload: any) => API.create('shoppingCartAddItem', payload),
    {
      onSettled: (response: any) => {
        if ((response && response.id) || (response && response.success)) {
          enqueueSnackbar(
            t('beheardReport.snackbar.success.surveyAddedToCart'),
            {
              variant: 'success',
            },
          )
          setShoppingCartItemsCount(state => state + 1)
          history.push(ROUTES_PATHS.marketing)
        }
      },
    },
  )

  const addSelectedSurveyToCart = () => {
    addSurveyToCartMutation.mutate({
      product_key: 'full_report',
      object_name_to_upgrade: 'Survey',
      object_id_to_upgrade:
        beheardReport && beheardReport.survey && beheardReport.survey.id,
    })
  }

  const surveyReportFromAPIMutation = useMutation(
    (payload: any) =>
      API.create('surveyReport', payload.queryParams, {
        pathParams: {
          id: payload.id,
          report_type: +payload.isFullReport ? 'full_report' : 'free_report',
        },
      }),
    {
      onSuccess: response => {
        setBeheardReport({ ...response })

        if (isEmpty(demographics)) {
          fetchDemographics()
        }
      },
      onSettled: (response: any) => {
        if (response?.error_type === 'minimum_participants_needed') {
          setMinimumParticipantsAlert(true)
        } else if (response && response.errors) {
          history.push(ROUTES_PATHS.surveyOverview)
        } else if (filtersQuantityState) {
          enqueueSnackbar(t('beheardReport.snackbar.success.filters'), {
            variant: 'success',
          })
        }

        if (
          (minimumParticipantsAlert && !filtersQuantityState) ||
          (minimumParticipantsAlert && response && !response.error_type)
        ) {
          setMinimumParticipantsAlert(false)
        }
      },
    },
  )

  const {
    data: demographics,
    isLoading: demographicsIsLoading,
    refetch: fetchDemographics,
  } = useQuery(
    'getDemographics',
    () => {
      const surveyId = query.get('id')

      return API.get('demographics', null, {
        queryParams: surveyId && {
          survey_id: surveyId,
        },
      })
    },
    {
      enabled: false,
    },
  )

  const { data: roles, refetch: fetchRoles } = useQuery(
    'getRoles',
    () => {
      return API.get('roles')
    },
    {
      enabled: false,
    },
  )

  useEffect(() => {
    if (!isEmpty(demographics)) {
      const filters = {}
      demographics.forEach(demographic => {
        filters[demographic.id] = []
      })

      setFiltersState(filters)
    }
  }, [demographics])

  const handleBeheardReportFetch = () => {
    surveyReportFromAPIMutation.mutate({
      id: query.get('id'),
      isFullReport: isFullReport,
      queryParams: getSurveyReportQueryParams(
        filtersState,
        query.get('shared_report_id') ?? '',
      ),
    })
  }

  useEffect(() => {
    handleBeheardReportFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (APILanguageChanged) {
      handleBeheardReportFetch()
      setAPILanguageChanged(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [APILanguageChanged])

  return (
    <BeheardReportComponent
      beheardReport={beheardReport}
      beheardReportIsLoading={surveyReportFromAPIMutation.isLoading}
      isFullReport={isFullReport}
      addSurveyToCart={addSelectedSurveyToCart}
      addSurveyToCartIsLoading={addSurveyToCartMutation.isLoading}
      demographics={demographics}
      demographicsIsLoading={demographicsIsLoading}
      roles={roles}
      fetchRoles={fetchRoles}
      filtersState={filtersState}
      filtersQuantityState={filtersQuantityState}
      setFiltersState={setFiltersState}
      fetchSurveyReportFromAPI={handleBeheardReportFetch}
      surveyPermissionId={query.get('shared_report_id')}
      minimumParticipantsAlert={minimumParticipantsAlert}
    />
  )
}
