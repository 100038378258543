import { ArrowBack as ArrowBackIcon } from '@mui/icons-material'
import { Grid, Link } from '@mui/material'
import { useLocation, useHistory } from 'react-router-dom'

import { ROUTES_PATHS } from 'core/routes'
import { t } from 'i18n'

type Location = {
  state: {
    goBackToDashboard: boolean
  }
}

export default function GoBackButton(props: any) {
  const { state } = useLocation() as Location
  const history = useHistory()

  return (
    <Link
      data-cy='goBack'
      variant='body1'
      sx={{ fontWeight: 500 }}
      {...props}
      component='button'
      onClick={() => {
        if (state?.goBackToDashboard)
          return history.push(ROUTES_PATHS.dashboard)

        return history.goBack()
      }}
    >
      <Grid container alignItems='center'>
        <ArrowBackIcon sx={{ mr: 1 }} />{' '}
        {t('sparcks.sparckDisplayComponent.goBack')}
      </Grid>
    </Link>
  )
}
