import { Box, Divider, Grid, Typography } from '@mui/material'
import { PollOutlined as PollIcon } from '@mui/icons-material'
import { format, parseISO } from 'date-fns'
import { useIntl } from 'react-intl'
import { Link as RouterLink } from 'react-router-dom'

import CompanyCard from '../CompanyCard/CompanyCard'
import { ROUTES_PATHS } from 'core/routes'
import { t } from 'i18n'
import { getSurveyStatusLabel } from 'utils/survey'

export default function CompanyPulseSurveyUpdatesComponent(props: any) {
  const { pulseSurveys, isPulseSurveysLoading } = props

  const intl = useIntl()

  const renderLabelAndValue = (label: string, value: string | any) => (
    <Box mr={1}>
      <Typography
        color='text.secondary'
        fontWeight='bold'
        variant='body2'
        paragraph
      >
        {label}:{' '}
        <Typography color='text.secondary' variant='body2' component='span'>
          {value}
        </Typography>
      </Typography>
    </Box>
  )

  const renderLink = (label: string, path: string) => (
    <Box mr={3}>
      <RouterLink to={`${path}`}>
        <Typography color='primary.dark' fontWeight={600} paragraph>
          {label}
        </Typography>
      </RouterLink>
    </Box>
  )

  return (
    <CompanyCard
      list={pulseSurveys}
      isListLoading={isPulseSurveysLoading}
      title={t('myCompany.companyPulseSurveyUpdates.cardTitle')}
      emptyMessage={t('myCompany.companyPulseSurveyUpdates.emptyMessage')}
      redirectMessage={t(
        'myCompany.companyPulseSurveyUpdates.seeAllPulseSurveys',
      )}
      redirectRoute={ROUTES_PATHS.pulseSurveyOverview}
    >
      {pulseSurveys.map((pulseSurvey: any, index: number) => (
        <Box mb={3} key={pulseSurvey.name + index}>
          <Grid container>
            <Grid item>
              <Box mr={3}>
                <PollIcon color='primary' fontSize='large' />
              </Box>
            </Grid>

            <Grid item xs>
              <Typography
                color='text.secondary'
                fontWeight='bold'
                variant='h6'
                paragraph
              >
                {pulseSurvey.name}
              </Typography>
              {(pulseSurvey.open_at && pulseSurvey.close_at) && (
              <Grid container justifyContent='space-between'>
                {renderLabelAndValue(
                  t('status'),
                  getSurveyStatusLabel(pulseSurvey.status, intl),
                )}
                {renderLabelAndValue(
                  t(
                    'myCompany.companyPulseSurveyUpdates.columnLabels.openDate',
                  ),
                  format(parseISO(pulseSurvey.open_at), 'P'),
                )}
                {renderLabelAndValue(
                  t(
                    'myCompany.companyPulseSurveyUpdates.columnLabels.closeDate',
                  ),
                  format(parseISO(pulseSurvey.close_at), 'P'),
                )}
              </Grid>

              )}
              <Grid container>
                {renderLink(
                  t(
                    'myCompany.companyPulseSurveyUpdates.linkLabels.managePulseSurvey',
                  ),
                  `${ROUTES_PATHS.pulseSurveyOverview}/${pulseSurvey.id}`,
                )}
              </Grid>
            </Grid>
          </Grid>
          <Divider />
        </Box>
      ))}
    </CompanyCard>
  )
}
