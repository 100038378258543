import { Box, Card, Grid, Typography } from '@mui/material'

export default function InsightsCard({ title, boxProps = {}, children }: any) {
  return (
    <Box width={1} mb={3} {...boxProps}>
      <Card style={{ height: '100%' }}>
        <Box p={2} width={1}>
          {title && (
            <Box mt={1} mb={2} width={1}>
              <Typography
                variant='h6'
                color='text.secondary'
                align='left'
                paragraph
                sx={{fontSize: "18px !important"}}
              >
                {title}
              </Typography>
            </Box>
          )}

          <Grid container>
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Box>
  )
}
