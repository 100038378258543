import { useState, useEffect } from 'react'

import { get } from 'lodash'
import { useQuery, useMutation } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import { SurveyOverviewComponent } from './components'
import {
  useFetchSurveys,
  useFetchUninvitedUsers,
} from './SurveyOverview.queries'
import { useCompany } from 'contexts/CompanyContext'
import { useShoppingCartItemsCount } from 'contexts'
import { API } from 'core/requests'
import { ROUTES_PATHS } from 'core/routes'
import { SurveyStatus } from 'enums/survey'
import { t } from 'i18n'
import { SurveyType } from 'types'

function useURLQuery() {
  return new URLSearchParams(useLocation().search)
}

export default function SurveyOverview() {
  const [confirmCloseDialogOpen, setConfirmCloseDialogOpen] = useState(false)
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false)
  const [count, setCount] = useState(1);
  const [confirmLaunchSurveyDialogOpen, setConfirmLaunchSurveyDialogOpen] =
    useState(false)
  const [extendSurveyDialogOpen, setExtendSurveyDialogOpen] = useState(false)
  const [inviteEmployeesDialogOpen, setInviteEmployeeDialogOpen] =
    useState(false)

  const query = useURLQuery()
  const history = useHistory()

  const { company } = useCompany()!
  const { setShoppingCartItemsCount } = useShoppingCartItemsCount()!

  const { enqueueSnackbar } = useSnackbar()

  const initialSelectedSurvey: SurveyType | null | any = null
  const [selectedSurvey, setSelectedSurvey] = useState(initialSelectedSurvey)

  const [surveyOverview, setSurveyOverview] = useState({})

  const { data: uninvitedUsers, refetch: fetchUninvitedUsers } =
    useFetchUninvitedUsers(get(selectedSurvey, 'id'))

  const {
    isLoading: isSurveysLoading,
    data: surveys,
    isSuccess: isSurveysSuccess,
    refetch: fetchSurveys,
  } = useFetchSurveys()

  const {
    refetch: getSurveyOverview,
    data: surveyOverviewFromAPI,
    isLoading: isSurveyOverviewLoading,
  } = useQuery(
    'getSurveyOverview',
    () => API.get('surveyOverview', get(selectedSurvey, 'id')),
    {
      enabled: false,
    },
  )

  const { refetch: getSurveyPreview, data: surveyPreview } = useQuery(
    'getSurveyPreview',
    () => API.get('surveys', selectedSurvey?.id),
    {
      enabled: false,
    },
  )

  const launchSurveyMutation = useMutation(
    (payload: any) => {
      return API.update('openSurvey', payload)
    },
    {
      onSuccess: (response: any) => {
        if (response && response.id) {
          enqueueSnackbar(t('surveyOverview.snackbarSuccess.openSurvey'), {
            variant: 'success',
          })
          getSurveyOverview()
          fetchSurveys()
          setConfirmLaunchSurveyDialogOpen(false)
        }
      },
    },
  )

  const extendSurveyMutation = useMutation(
    (payload: any) => API.update('extendSurvey', payload),
    {
      onSettled: (response: any) => {
        if (response && response.success) {
          enqueueSnackbar(t('surveyOverview.snackbarSuccess.extendedSurvey'), {
            variant: 'success',
          })
          getSurveyOverview()
          fetchSurveys()
          setExtendSurveyDialogOpen(false)
        }
      },
    },
  )

  const closeSurveyMutation = useMutation(
    (payload: any) => API.update('closeSurvey', payload),
    {
      onSettled: (response: any) => {
        if (response && response.success) {
          enqueueSnackbar(t('surveyOverview.snackbarSuccess.closedSurvey'), {
            variant: 'success',
          })
          getSurveyOverview()
          fetchSurveys()
          setConfirmCloseDialogOpen(false)
        }
      },
    },
  )

  const deleteSurveyMutation = useMutation(
    (payload: any) => API.delete('surveys', payload.id),
    {
      onSettled: (response: any) => {
        if (response && response.success) {
          enqueueSnackbar(t('surveyOverview.snackbarSuccess.deletedSurvey'), {
            variant: 'success',
          })
          getSurveyOverview()
          fetchSurveys()
          setConfirmDeleteDialogOpen(false)
        }
      },
    },
  )

  const inviteEmployeesMutation = useMutation(
    (payload: any) =>
      API.create('inviteEmployeesToSurvey', payload, {
        pathParams: {
          id: payload ? payload.survey_id : null,
        },
        body: { user_ids: payload.user_ids },
      }),
    {
      onSettled: (response: any) => {
        if (response && response.success) {
          enqueueSnackbar(
            t('surveyOverview.snackbarSuccess.employeesInvited'),
            {
              variant: 'success',
            },
          )

          setInviteEmployeeDialogOpen(false)
        }
      },
    },
  )

  const addSurveyToCartMutation = useMutation(
    (payload: any) => API.create('shoppingCartAddItem', payload),
    {
      onSettled: (response: any) => {
        if ((response && response.id) || (response && response.success)) {
          enqueueSnackbar(t('surveyOverview.snackbarSuccess.addedToCart'), {
            variant: 'success',
          })
          setShoppingCartItemsCount(state => state + 1)
          history.push(ROUTES_PATHS.marketing)
        } else if (response && response.status === 'conflict') {
          history.push(ROUTES_PATHS.invoices)
        }
      },
    },
  )

  const toggleSurveyReportLevelMutation = useMutation(
    (payload: any) => API.update('surveysToggleReportLevel', payload),
    {
      onSettled: (response: any) => {
        if ((response && response.id) || (response && response.success)) {
          enqueueSnackbar(response.message, {
            variant: 'success',
          })
          fetchSurveys()
        }
      },
    },
  )

  useEffect(() => {
    if (surveyOverviewFromAPI) {
      setSurveyOverview(surveyOverviewFromAPI)
    }
  }, [surveyOverviewFromAPI])

  useEffect(() => {
    if (isSurveysSuccess) {
      if (surveys!.length > 0) {
        if (query.get('id')) {
          setSelectedSurvey(
            surveys!.find(survey => survey.id === +query.get('id')!) ||
              surveys![0],
          )
        } else {
          setSelectedSurvey(
            surveys!.find(survey => survey.type !== SurveyStatus.Close) ||
              surveys![0],
          )
        }
      } else {
        history.push(ROUTES_PATHS.manageSurvey)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSurveysSuccess, surveys])

  useEffect(() => {
    if (selectedSurvey && selectedSurvey.id) {
      getSurveyOverview()
      fetchUninvitedUsers(get(selectedSurvey, 'id'))
    }
  }, [getSurveyOverview, fetchUninvitedUsers, selectedSurvey])

  const addSelectedSurveyToCart = () => {
    addSurveyToCartMutation.mutate({
      product_key: 'full_report',
      object_name_to_upgrade: 'Survey',
      object_id_to_upgrade: selectedSurvey.id,
    })
  }

  const setConfirmDeleteDialogOpenT = () => {
     setConfirmDeleteDialogOpen(true);
  }

  return (
    <SurveyOverviewComponent
      company={company}
      surveys={surveys!}
      isSurveysLoading={isSurveysLoading}
      fetchSurveys={fetchSurveys}
      surveyOverview={surveyOverview}
      preview={surveyPreview}
      getSurveyPreview={getSurveyPreview}
      isSurveyOverviewLoading={isSurveyOverviewLoading}
      uninvitedUsers={uninvitedUsers!}
      launchSurveyMutation={launchSurveyMutation}
      extendSurveyMutation={extendSurveyMutation}
      inviteEmployeesMutation={inviteEmployeesMutation}
      closeSurveyMutation={closeSurveyMutation}
      deleteSurveyMutation={deleteSurveyMutation}
      toggleSurveyReportLevelMutation={toggleSurveyReportLevelMutation}
      selectedSurvey={selectedSurvey}
      setSelectedSurvey={setSelectedSurvey}
      confirmCloseDialogOpen={confirmCloseDialogOpen}
      setConfirmCloseDialogOpen={setConfirmCloseDialogOpen}
      confirmDeleteDialogOpen={confirmDeleteDialogOpen}
      setConfirmDeleteDialogOpen={setConfirmDeleteDialogOpenT}
      count={count}
      setCount={setCount}
      confirmLaunchSurveyDialogOpen={confirmLaunchSurveyDialogOpen}
      setConfirmLaunchSurveyDialogOpen={setConfirmLaunchSurveyDialogOpen}
      extendSurveyDialogOpen={extendSurveyDialogOpen}
      setExtendSurveyDialogOpen={setExtendSurveyDialogOpen}
      inviteEmployeesDialogOpen={inviteEmployeesDialogOpen}
      setInviteEmployeeDialogOpen={setInviteEmployeeDialogOpen}
      getSurveyOverview={getSurveyOverview}
      addSurveyToCart={addSelectedSurveyToCart}
      addSurveyToCartIsLoading={addSurveyToCartMutation.isLoading}
    />
  )
}
