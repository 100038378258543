import { ROUTES_PATHS } from 'core/routes'

export const mentionsToPlainText = (content = '') => {
  const messageMentions = content.match(/@([^)]+)\)/g)

  const messageMentionsIds = messageMentions?.map(mention => {
    const messageMentionId = mention.match(/\(([^)]+)\)/g)

    if (messageMentionId) {
      return messageMentionId[0].replace('(', '').replace(')', '')
    }
  })

  messageMentions?.forEach((mention, index) => {
    const formattedMention = mention
      .replace(/\(\d*?\)/g, '')
      .replaceAll(
        '@[',
        `<a href="${ROUTES_PATHS.profile}/${
          messageMentionsIds![index]
        }" target='_blank' rel='noopener noreferrer'>@`,
      )
      .replaceAll(']', `</a>`)

    content = content.replace(mention, formattedMention)
  })

  return content
}
