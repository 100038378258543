import { useQuery, UseQueryResult } from 'react-query'
import { API } from 'core/requests'

export const useFetchProfileQuestionsLibrary = (): UseQueryResult<
  any[],
  Error
> => {
  return useQuery('profileQuestionsLibrary', () =>
    API.get('profileQuestionsLibrary'),
  )
}
