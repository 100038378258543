export default {
    actions: { create: 'Crear {name}', edit: 'Edit {name}', save: 'Save {name}' },
    routes: {
    approvals: 'Aprobaciones',
    beheardReport: '#BeHeard Report',
    beheardSurvey: 'Encuesta #BeHeard',
    budget: 'Budget',
    changePassword: 'Cambiar la Contraseña',
    companies: 'Organizations',
    companyInsights: 'Organization Insights',
    companyReporting: 'Reporting',
    coreValues: 'Core Values',
    dashboard: 'Dashboard',
    demographics: 'Demografía',
    employees: 'Employees',
    feed: 'Feed',
    inappropriateContent: 'Inappropriate Content',
    insights: 'Insights',
    invoices: 'Invoices',
    login: 'Login',
    logout: 'Cerrar Sesión',
    marketing: 'Upgrade',
    managePulseSurvey: 'Manage Pulse Survey',
    managerCertification: 'Certificación de Empleados',
    managerCertificationTask:
          'Tarea de Certificación de Reconocimento de Empleados',
        nominationsManagement: 'Nominations',
        pulseSurveyInsights: 'Insights',
        pulseSurveyOverview: 'Pulse Surveys',
        manageSurvey: ' ',
        surveyOverview: '#BeHeard Surveys',
          company: 'My Organization',
          myRewards: 'My Rewards',
          mySettings: 'My Settings',
          notifications: 'Notifications',
          onboarding: 'Onboarding',
          onboardingQuestions: 'Onboarding Questions',
          people: 'Gente',
          rewards: 'Rewards',
          profile: 'Perfil',
          profileQuestions: 'Profile Questions',
          pulseSurveys: 'Pulse Surveys',
          recognitionPrograms: 'Eventos de Reconocimiento',
          roles: 'Roles',
          settings: 'Settings',
          shareReport: 'Share Report',
          shoppingCart: 'Shopping Cart',
          signup: 'Signup',
          recognize: 'Recognize!',
          sparckDisplay: 'Sparck Display',
          sparckMemories: 'Sparck Memories',
          suggestionChat: 'Suggestion Chat',
          suggestions: 'Suggestions',
          survey: 'Encuesta',
          surveys: 'Encuestas',
          takeAction: 'Tomar Acción',
          teamInsights: 'Team Insights',
          virginExperiencesSettings: 'Virgin Experiences',
        },
        industries: {
          accounting: 'Accounting',
          airlinesAviation: 'Airlines/Aviation',
          alternativeDisputeResolution: 'Alternative Dispute Resolution',
          alternativeMedicine: 'Alternative Medicine',
          animation: 'Animation',
          apparelFashion: 'Apparel & Fashion',
          architecturePlanning: 'Architecture & Planning',
          artsCrafts: 'Arts & Crafts',
          automotive: 'Automotive',
          aviationAerospace: 'Aviation & Aerospace',
          banking: 'Banking',
          biotechnology: 'Biotechnology',
          broadcastMedia: 'Broadcast Media',
          buildingMaterials: 'Building Materials',
          businessSuppliesEquipment: 'Business Supplies & Equipment',
          cannabis: 'Cannabis',
          capitalMarkets: 'Capital Markets',
          chemicals: 'Chemicals',
          civicSocialOrganization: 'Civic & Social Organization',
          civilEngineering: 'Civil Engineering',
          commercialRealEstate: 'Commercial Real Estate',
          computerNetworkSecurity: 'Computer & Network Security',
          computerGames: 'Computer Games',
          computerHardware: 'Computer Hardware',
          computerNetworking: 'Computer Networking',
          computerSoftware: 'Computer Software',
          construction: 'Construction',
          consumerElectronics: 'Consumer Electronics',
          consumerGoods: 'Consumer Goods',
          consumerServices: 'Consumer Services',
          cosmetics: 'Cosmetics',
          dairy: 'Dairy',
          defenseSpace: 'Defense & Space',
          design: 'Design',
          eLearning: 'E-learning',
          educationManagement: 'Education Management',
          electricalElectronicManufacturing: 'Electrical & Electronic Manufacturing',
          entertainment: 'Entertainment',
          environmentalServices: 'Environmental Services',
          eventServices: 'Event Services',
          executiveOffice: 'Executive Office',
          facilitiesServices: 'Facilities Services',
          farming: 'Farming',
          financialServices: 'Financial Services',
          fineArt: 'Fine Art',
          fishery: 'Fishery',
          foodBeverages: 'Food & Beverages',
          foodProduction: 'Food Production',
          fundraising: 'Fundraising',
          furniture: 'Furniture',
          gamblingCasinos: 'Gambling & Casinos',
          glassCeramicsConcrete: 'Glass, Ceramics & Concrete',
          governmentAdministration: 'Government Administration',
          governmentRelations: 'Government Relations',
          graphicDesign: 'Graphic Design',
          healthWellnessFitness: 'Health, Wellness & Fitness',
          higherEducation: 'Higher Education',
          hospitalHealthCare: 'Hospital & Health Care',
          hospitality: 'Hospitality',
          humanResources: 'Human Resources',
          importExport: 'Import & Export',
          individualFamilyServices: 'Individual & Family Services',
          industrialAutomation: 'Industrial Automation',
          informationServices: 'Information Services',
          informationTechnologyServices: 'Information & Technology Services',
          insurance: 'Insurance',
          internationalAffairs: 'International Affairs',
          internationalTradeDevelopment: 'International Trade & Development',
          internet: 'Internet',
          investmentManagement: 'Investment Management',
          investmentBanking: 'Investment Banking',
          judiciary: 'Judiciary',
          lawEnforcement: 'Law Enforcement',
          lawPractice: 'Law Practice',
          legalServices: 'Legal Services',
          legislativeOffice: 'Legislative Office',
          leisureTravelTourism: 'Leisure, Travel & Tourism',
          libraries: 'Libraries',
          logisticsSupplyChain: 'Logistics & Supply Chain',
          luxuryGoodsJewelry: 'Luxury Goods & Jewelry',
          machinery: 'Machinery',
          managementConsulting: 'Management Consulting',
          maritime: 'Maritime',
          marketResearch: 'Market Research',
          marketingAdvertising: 'Marketing & Advertising',
          mechanicalOrIndustrialEngineering: 'Mechanical or Industrial Engineering',
          mediaProduction: 'Media Production',
          medicalDevice: 'Medical Device',
          medicalPractice: 'Medical Practice',
          mentalHealthCare: 'Mental Health Care',
          military: 'Military',
          miningMetals: 'Mining & Metals',
          motionPicturesFilm: 'Motion Pictures & Film',
          museumsInstitutions: 'Museums & Institutions',
          music: 'Music',
          nanotechnology: 'Nanotechnology',
          newspapers: 'Newspapers',
          nonProfitManagement: 'Non-Profit Management',
          oilEnergy: 'Oil & Energy',
          onlineMedia: 'Online Media',
          outsourcingOffshoring: 'Outsourcing/Offshoring',
          packageFreightDelivery: 'Package/Freight Delivery',
          packagingContainers: 'Packaging & Containers',
          paperForestProducts: 'Paper & Forest Products',
          performingArts: 'Performing Arts',
          pharmaceuticals: 'Pharmaceuticals',
          philanthropy: 'Philanthropy',
          photography: 'Photography',
          plastics: 'Plastics',
          politicalOrganization: 'Political Organization',
          primarySecondaryEducation: 'Primary/Secondary Education',
          printing: 'Printing',
          professionalTrainingCoaching: 'Professional Training & Coaching',
          programDevelopment: 'Program Development',
          publicPolicy: 'Public Policy',
          publicRelationsCommunications: 'Public Relations & Communications',
          publicSafety: 'Public Safety',
          publishing: 'Publishing',
          railroadManufacturer: 'Railroad Manufacturer',
          ranching: 'Ranching',
          realEstate: 'Real Estate',
          recreationalFacilitiesServices: 'Recreational Facilities & Services',
          religiousInstitutions: 'Religious Institutions',
          renewablesEnvironment: 'Renewables & Environment',
          research: 'Research',
          restaurants: 'Restaurants',
          retail: 'Retail',
          securityInvestigations: 'Security & Investigations',
          semiconductors: 'Semiconductors',
          shipbuilding: 'Shipbuilding',
          sportingGoods: 'Sporting Goods',
          staffingRecruiting: 'Staffing & Recruiting',
          supermarkets: 'Supermarkets',
          telecommunications: 'Telecommunications',
          textiles: 'Textiles',
          thinkTanks: 'Think Tanks',
          tobacco: 'Tobacco',
          translationLocalization: 'Translation & Localization',
          transportationTruckingRailroad: 'Transportation/Trucking/Railroad',
          utilities: 'Utilities',
          ventureCapitalPrivateEquity: 'Venture Capital & Private Equity',
          veterinary: 'Veterinary',
          warehousing: 'Warehousing',
          wholesale: 'Wholesale',
          wineSpirits: 'Wine & Spirits',
          wireless: 'Wireless',
          writingEditing: 'Writing & Editing',
        },
        topbar: {
          viewReleaseNotes: 'View Release Notes',
          termsOfService: 'View Terms of Service',
          privacyPolicy: 'View Privacy Policy',
        },
        footer: { weLove: 'Nosotros', slogan: '¡Reconocimiento tan único como tú!' },
        formHandler: {
          create: 'Crear',
          edit: 'Edit',
          update: 'Update',
          updated: 'updated',
          created: 'creado',
          successWithTitle: 'successfully',
          success: 'Success!',
        },
        confirmDelete: {
          confirmation: 'Estás seguro que quieres',
          deleteLabel: 'eliminar',
          delete: 'Eliminar Elemento',
        },
        table: {
          body: {
            emptyDataSourceMessage: 'No records to display',
            addTooltip: 'Add',
            deleteTooltip: 'Delete',
            editTooltip: 'Edit',
            filterRow: { filterPlaceHolder: 'Filter', filterTooltip: 'Filter' },
            editRow: {
              deleteText: 'Are you sure you want to delete this row?',
              cancelTooltip: 'Cancel',
              saveTooltip: 'Save',
            },
          },
          grouping: { placeholder: 'Drag headers...', groupedBy: 'Grouped By:' },
          header: { actions: 'Actions' },
          pagination: {
            labelDisplayedRows: '{from}-{to} of {count}',
            labelRowsSelect: 'rows',
            labelRowsPerPage: 'Rows per page:',
            firstAriaLabel: 'First Page',
            firstTooltip: 'First Page',
            previousAriaLabel: 'Previous Page',
            previousTooltip: 'Previous Page',
            nextAriaLabel: 'Next Page',
            nextTooltip: 'Next Page',
            lastAriaLabel: 'Last Page',
            lastTooltip: 'Last Page',
          },
          toolbar: {
            addRemoveColumns: 'Add or remove columns',
            nRowsSelected: '{0} row(s) selected',
            showColumnsTitle: 'Show Columns',
            showColumnsAriaLabel: 'Show Columns',
            exportTitle: 'Export',
            exportAriaLabel: 'Export',
            exportPDFName: 'Export as PDF',
            exportCSVName: 'Export as CSV',
            searchTooltip: 'Search',
            searchPlaceholder: 'Search',
          },
        },
        simpleCrudTable: {
          actions: 'Actions',
          limit: { firstLine: 'You can only add up to', secondLine: 'items.' },
          rows: 'filas',
        },
        simpleLibrarySelection: {
          selected: { singular: 'Seleccionado', plural: 'Seleccionados' },
          sortedAscending: 'sorted ascending',
          sortedDescending: 'sorted descending',
        },
        companyAdminLayout: {
          add: 'Agregar',
          remove: 'Remove',
          thisItem: 'this item',
          successfullyUpdated: 'successfully updated!',
          successfullyDeleted: 'successfully deleted!',
          success: 'Success!',
          all: 'All',
        },
        emails: {
          welcomeEmailCompany: {
            title: 'Send Welcome Email',
            description:
              'Send this email if the organization has not yet been notified about its organization creation',
          },
          welcomeEmailEmployee: {
            title: 'Send Welcome Email',
            description:
              'Send this email if you want to welcome an employee to our platform',
          },
          welcomeEmailAllEmployees: {
            title: 'Send Welcome Email to All Employees',
            description:
              'Send this email if you want to welcome all employees at once',
          },
          welcomeEmailNewEmployees: {
            title: 'Send Welcome Email to New Employees Only',
            description:
              'Send this email if you want to welcome only employees who have not yet received this welcome email',
          },
          betaTeamInviteEmail: {
            title: 'Beta Team Invite Email',
            description:
              'Send this email if you want to invite an employee to Sparck Beta',
          },
          resendSurvey: {
            title: 'Resend Survey',
            description:
              'Send this email if you want to invite an employee to active survey again',
          },
        },
        linkHere: 'AQUÍ',
        loading: 'Cargando...',
        yes: 'Sí',
        no: 'No',
        thereAreNo: 'No hay {name}',
        name: 'Name',
        nameIsRequired: 'Name is required',
        description: 'Description',
        firstName: 'First Name',
        lastName: 'Last Name',
        phoneNumber: 'Phone Number',
        cancel: 'Cancel',
        department: 'Department',
        email: 'Email',
        status: 'Status',
        birthday: 'Birthday',
        for: 'for',
        invalidDateFormat: 'Invalid Date',
        participation: 'Participation',
        reward: 'Reward',
        type: 'Type',
        value: 'Value',
        select: 'Select',
        back: 'Back',
        statuses: {
          accepted: 'Accepted',
          approved: 'Approved',
          declined: 'Declined',
          pending: 'Pending',
        },
        beheardSurveyDrivers: {
          alignment: 'Alignment',
          fit: 'Fit',
          growth: 'Growth',
          team: 'Team',
          valuing: 'Valuing',
          turnover: 'Turnover',
        },
        dashboard: {
          welcome: {
            title: '¡Bienvenido a Spark!',
            description:
              '¡Felicitaciones por configurar su cuenta de organización. Para comenzar, siga los pasos en este panel para lanzar con éxito su encuesta de participación gratuita #BeHeard!',
          },
          getStarted: 'Empezar',
          greetings: 'Feliz {day}, {userName}!',
          noReminders: 'Actualmente no tienes recordatorios.',
          reminders: {
            year: 'year',
            years: 'years',
            workiversaryOn: '{userName} {years} workiversary on {date}',
            birthdays: 'Birthdays',
            workiversaries: 'Workiversaries',
            showToday: "Show Today's {sectionName} only",
            showUpcoming: 'Show Upcoming {sectionName}',
            today: "Today's {sectionName}",
            upcoming: 'Upcoming {sectionName}',
            noRemindersToday: 'No {reminderSectionName} today',
          },
          profileProgress: 'El perfil de su organización está {percentage} completo.',
          tasks: 'Tareas',
          noTasks: '¡Estás al día con tus tareas!',
        },
        pulseSurvey: {
          header: 'Your voice matters',
          responsesAreAnonymous: 'All responses are anonymous.',
          needHelp: 'Need help?',
          clickHere: 'Click here',
          changeButtonText: 'Cambiar',
          previous: 'Anterior',
          next: 'Siguiente',
          finish: 'Finish',
          openEndedLabel: 'Your Answer',
          openEndedPlaceholder: 'Type your answer here...',
          openEndedHelperText:
            'To ensure your confidentiality, do not put any personally identifiable information.',
          noPulseSurveys:
            'There are no Pulse Surveys right now but we’ll ping you when there are.',
          statistics: {
            tableHeaders: {
              satisfaction: 'Satisfaction',
              importance: 'Importance',
              openEnded: 'Open Ended',
              statement: 'Statement',
              agree: 'agree',
              neutral: 'neutral',
              disagree: 'disagree',
              important: 'important',
              indifferent: 'indifferent',
              not_important: 'not important',
            },
            answers: 'Answers',
          },
        },
        pulseSurveyOverview: {
          header: 'Pulse Surveys',
          textFieldLabel: 'Pulse Survey',
          snackbarSuccess: {
            openedPulseSurvey: 'The Pulse Survey has been opened',
            closedPulseSurvey: 'The Pulse Survey has been closed',
            extendedPulseSurvey: 'The Pulse Survey has been extended',
            deletedPulseSurvey: 'Pulse Survey has been deleted!',
            addedToCart: 'Pulse Survey added to your cart successfully',
          },
          newPulseSurvey: 'New Pulse Survey',
          launchPulseSurvey: 'Launch Pulse Survey',
          editPulseSurvey: 'Edit Pulse Survey',
          deletePulseSurvey: 'Delete Pulse Survey',
          closePulseSurvey: 'Close Pulse Survey',
          extendPulseSurvey: 'Extend Pulse Survey',
          pulseReport: 'Pulse Report',
          dialogs: {
            title: 'Pulse Surveys',
            create: {
              descriptionFirstLine:
                'It’s time to make the Pulse Survey your own. You can make changes before the Pulse Survey opens and extend the Pulse Survey before it closes. Click',
              link: 'HERE',
              descriptionSecondLine: 'for best practices around Pulse Surveys.',
            },
            edit: {
              descriptionFirstLine:
                'Need to edit your pulse survey? No problem. You can do that here. Please make your changes below. Click',
              link: 'HERE',
              descriptionSecondLine: 'for best practices around Pulse Surveys.',
            },
            confirmClose: {
              title: 'Close Pulse Survey',
              actionLabel: 'Close Pulse Survey',
              deleteItemLabel: 'close',
            },
            confirmDelete: {
              title: 'Eliminar Encuesta de Pulso',
              actionLabel: 'Eliminar Encuesta de Pulso',
              alternateConfirmDeleteLabel:
                'Esta encuesta de pulso tiene respuestas asociadas. ¿Está seguro de que desea eliminarlo? Esto no se puede deshacer.',
            },
            demographicsPickerLabel:
              'Which Demographics will receive this Pulse Survey?',
            rolesPicker: {
              label: 'Which Roles will receive this Pulse Survey?',
              selectLabel: 'Roles',
            },
            formLabels: {
              pulseSurveyName: 'Pulse Survey Name',
              launchDate: 'Launch Date',
              closeDate: 'Close Date',
            },
            questionsTable: {
              header: 'Questions',
              tableHeaders: {
                statement: 'Statement',
                showImportance: 'Show Importance Scale?',
                isOpenEnded: 'Is Open Ended?',
              },
              statementPlaceholder: 'Type your question/statement...',
              statementHelperText:
                'Please type your question and click the "+" button to add it.',
              librarySelection: { headers: { statement: 'Statement' } },
            },
          },
          validations: {
            pulseSurveyNameIsRequired: 'Pulse Survey Name is required',
            minimumLaunchDate: 'You cannot select a past date',
            maximumLaunchDate: 'Launch Date cannot be after the Close Date',
            launchDateIsRequired: 'Launch date is required',
            minimumCloseDate: 'Close Date cannot be before Launch Date',
            closeDateIsRequired: 'Close date is required',
          },
        },
        surveyLaunchMode: {
          label: 'How would you like to launch your survey?',
          manual: {
            label: 'Launch Manually',
            description:
              "By selecting Launch Manually, your survey won't launch until you click the {launchButtonLabel} button on the Manage Surveys screen. You will also be required to enter a Close Date before you are able to launch your survey which will automatically close the survey on the date you selected.",
          },
          scheduled: {
            label: 'Schedule Launch',
            description:
              'By selecting Schedule Launch, your survey will automatically launch on the launch date you specified and will automatically close on the close date specified. You can always launch your survey earlier by clicking the {launchButtonLabel} button from the Manage Survey screen.',
          },
          drivers: {
            label: 'Which engagement drivers you want to include',
            description: 'TODO By selecting {launchButtonLabel} button from the Manage Survey screen.',
            fit: 'Fit',
            fitToolTipText: 'Fit',
            growth: 'Growth',
            growthToolTipText: 'Growth',
            alignment: 'Alignment',
            alignmentToolTipText: 'Alignment',
            value: 'Value',
            valueToolTipText: 'Value',
            team: 'Team',
            teamToolTipText: 'Team',
          },
          changeManagerText: {
            label: 'Use this text in place of Manager',
            toolTipDescription:
              'You can write text like: Managers, Director so it will be shown in your survey page',
          },
        },
        myCompany: {
          greetings: { good: 'Feliz', welcomeBack: '¡Bienvenido!' },
          companyOverview: {
            cardTitle: 'Organization Overview',
            sectionLabels: {
              name: 'Organization Name',
              numberOfEmployees: 'Number of Employees',
              companyAdministrators: 'Organization Administrators',
            },
            sendBulkEmails: 'Send Bulk Emails',
            textFieldLabel: 'Organization Name',
            seeLess: 'See Less',
            seeMore: 'See More',
            dialogTitle: 'Send e-mails',
          },
          companyBeheard: {
            cardTitle: 'Upgrade Report',
            getMoreFromResults:
              'Get more from your results. Learn more about the #BeHeard Premium Report.',
            learnMore: 'Learn More',
          },
          companyGotQuestions: {
            cardTitle: 'Got Questions?',
            weAreHereToHelp: 'We are here to help you!',
            sectionLabels: {
              customerExperience: 'Customer Experience Partner',
              technicalSupport: 'Technical Support',
            },
          },
          companySurveyUpdates: {
            cardTitle: 'Survey Updates',
            emptyMessage: 'There are currently no surveys.',
            seeAllSurveys: 'See all Surveys',
            columnLabels: {
              surveyType: 'Survey Type',
              openDate: 'Open Date',
              closeDate: 'Close Date',
              invites: 'Invites',
            },
            linkLabels: {
              manageBeheardSurvey: 'Manage #BeHeard Survey',
              beheardPremiumReport: '#BeHeard Premium Report',
              shareReport: 'Share Report',
              upgradeToBeheardPremiumReport: 'Upgrade to #BeHeard Premium Report',
              beheardFreeReport: '#BeHeard Free Report',
            },
          },
          companyPulseSurveyUpdates: {
            cardTitle: 'Pulse Survey Updates',
            emptyMessage: 'There are currently no Pulse Surveys.',
            seeAllPulseSurveys: 'See all Pulse Surveys',
            columnLabels: { openDate: 'Open Date', closeDate: 'Close Date' },
            linkLabels: { managePulseSurvey: 'Manage Pulse Survey' },
          },
          companySuggestionUpdates: {
            cardTitle: 'Suggestion Updates',
            emptyMessage: 'There are currently no suggestions.',
            seeAllSuggestions: 'See all Suggestions',
            columnLabels: {
              date: 'Date',
              from: 'From',
              numberOfReplies: 'Number of replies',
              suggestion: 'Suggestion',
            },
          },
          companyFreeResources: {
            cardTitle: 'Free Resources',
            content:
              'Check out our tool kit with everything you’ll need for an effective #BeHeard Survey experience.',
            letsDoIt: "Let's do it!",
          },
          companyCostAnalysis: {
            cardTitle: 'Cost Analysis',
            content:
              'How much is employee disengagement costing you? Find out with our FREE Engagement Cost Analysis.',
            getStartedNow: 'Get started now!',
          },
          companyEmails: {
            dialogTitle: 'Are you sure you want to send',
            sendEmails: 'Send e-mails',
          },
          snackbarSuccess: {
            updated: 'Organization successfully updated',
            surveyAddedToCart: 'Survey added to your cart successfully',
          },
        },
        companies: {
          addFunds: 'Add Funds',
          allowApplication: 'Allow {application} application',
          allowInternationalization: 'Allow Internationalization',
          beheardAccess: '#BeHeard Access',
          beseenAccess: '#Beseen Access',
          clientSince: 'Client Since',
          companyName: 'Organization Name',
          createCompany: 'Create Organization',
          description:
            'You, as the Sparck Administrator, can manage organizations the way you want :)',
          editCompany: 'Edit Organization',
          emails: 'E-mails',
          funds: 'Funds',
          fundsAmount: 'Funds amount',
          manageNgcFor: 'Manage NGC for',
          ngcAccountSuccessfullyUpdated: 'NGC account updated successfully!',
          ngcAmount: 'NGC Amount',
          ngcApiClientId: 'NGC API Client ID',
          ngcApiSecret: 'NGC API Secret',
          ngcManagement: 'NGC Management',
          virginExperiencesManagement: 'Virgin Experiences Management',
          released: 'Released',
          saveChanges: 'Save Changes',
          sendEmailsTo: 'Send e-mails to',
          sparckAmount: 'Sparck Amount',
          title: 'Organizations',
          total: 'Total',
          validations: { companyNameIsRequired: 'Organization Name is required' },
          snackbar: { success: { emailSent: 'This email was successfully sent!' } },
        },
        employees: {
          title: 'Empleados',
          description:
            '¡Bienvenido! Comenzar es rápido y fácil, simplemente cargue las direcciones de correo electrónico de sus empleados. Puede incluir información demográfica del lugar de trabajo más adelante si actualiza al informe Premium #BeHeard.',
          openedSurveyResent: 'The opened survey email was resent to this user!',
          betaTeamInviteSent: 'The Beta team invite email was sent to this user!',
          welcomeEmailSent: 'The Welcome email was sent to this user!',
          employeeRoleDisclaimer:
            'If you create an employee without adding a role they will get the Employee role by default. If the employee is a Manager or above, select the Role before creating the employee.',
          fields: {
            businessUnit: 'Business Unit',
            companyInternalId: 'Organization Internal ID',
            dateOfBirth: 'Date of Birth',
            email: 'Correo electrónico',
            ethnicity: 'Ethnicity',
            gender: 'Gender',
            highValueTalent: 'High Value Talent',
            highValueTalentTooltip:
              'Filter reports by level of performance based on internal metrics (ex. Low, medium, and high performance).',
            hireDate: 'Hire Date',
            jobType: 'Job Type',
            location: 'Location Name',
            performanceLevel: 'Performance Level',
            performanceLevelTooltip:
              'Filter reports by level of performance based on internal insights (ex. low, medium, high).',
            region: 'Region',
            roles: 'Roles',
            supervisors: 'Supervisors',
            territory: 'Territory',
            title: 'Title',
          },
          placeholders: { male: 'Male' },
          sendEmails: 'Send E-mails',
          tooltips: {
            changePassword: 'Change Password',
            spoofEmployee: 'Spoof This Employee',
          },
          bulkEditEmployees: 'Bulk Edit Employees',
          hrisSection: 'HRIS Section',
          hrisSectionLabel: 'Import Employees',
          hrisSectionDescription:
            'Facilite aún más la carga de sus empleados e integre con su sistema HRIS.',
          bulkUpload: 'Bulk Upload',
          bulkUploadLabel: 'Upload Spreadsheet',
          bulkUploadDescription:
            '200 rows? 500+ rows? We can handle your employee spreadsheet!',
          employeeManagement: 'Employee Management',
          employeeManagementLabel: 'Add an Employee',
          employeeManagementDescription:
            '¿Quiere agregar a sus empleados individualmente? ¡Puedes hacer eso aquí!',
          sendEmailsTo: 'Send e-mails to {name}',
          changeEmployeePasswordDialog: {
            title: 'Change Employee Password',
            changePassword: 'Change Password',
          },
          employeesGraphDialogTitle: 'Employees Graph Visualization',
          selectEmployees: 'Seleccionar Empleados',
          manageEmployeeFormTabs: {
            info: 'Info',
            demographics: 'Demographics',
            rolesAndSupervisors: 'Roles & Supervisors',
          },
          generalInformation: 'General Information',
          employeeContact: 'Employee Contact',
          employeeLocation: 'Employee Location',
          demographicInformation: 'Demographic Information',
          validations: {
            invalidEmailFormat: 'Invalid email format',
            emailIsRequired: 'Email is required',
          },
          snackbars: {
            error: {
              assignAtLeastOneRole: 'Por favor asigne al menos un rol al empleado.',
            },
          },
        },
        hrisImport: {
          trinet: 'TriNet',
          submittedHRISSystem:
            'You have submitted {hrisSuggestion} as your HRIS System. Thanks for letting us know.',
          employeesImported:
            'Your employees were successfully imported from your HRIS!',
          importEmployeesFromHRIS: 'Import employees from your HRIS system',
          selectHRIS:
            "Select an HRIS system and input your HRIS system ID - it's as simple as that!",
          hrisSystem: 'HRIS System',
          companyId: 'Organization ID',
          importEmployeesLabel: 'Import Employees from HRIS',
          doYouUseAnotherHRIS: 'Do you use another HRIS system?',
          comingSoon:
            'Si está utilizando un sistema HRIS diferente, avísenos. Ingrese el nombre a continuación y lo haremos una prioridad para futuras integraciones.',
          submit: 'Submit',
          futureDevelopment: 'We will put it on our list for future development',
        },
        employeesUpload: {
          employeesImportedSuccessfully: 'Employees imported successfully!',
          uploadEmployeeInformation: 'Upload Your Employee Information',
          description1:
            "Use our Excel template to upload your employees. The second tab of the sheet will provide instructions and examples if needed. Once your Excel file is completed, use the Upload button to send it. You will be able to check each employee's information before importing.",
          description2:
            'The template includes two orange columns (on the far right) for you to add custom workplace demographics. This will give you additional filtering options for your reporting.',
          downloadExcelTemplate: 'Click here to download the Excel template.',
          dragAndDrop: 'Drag and drop your spreadsheet or just click here!',
          importingYourSpreadsheet:
            '¡Importando su hoja de cálculo! Esto puede tomar algo de tiempo...',
        },
        invoices: {
          title: 'Invoices',
          description: 'Check and manage your invoices here',
          invoicePDF: 'Click here to get a PDF of the invoice',
          proceedToPayment: 'Proceed to Payment',
          fields: {
            invoiceDate: 'Invoice Date',
            receiptNumber: 'Receipt #',
            paid: 'Paid',
            amount: 'Amount',
          },
        },
        roles: {
          title: 'Roles',
          description:
            'Administre la configuración de permisos para cada función en su organización',
          types: {
            companyAdmin: 'Organization Admin',
            ceo: 'CEO',
            executive: 'Executive',
            manager: 'Manager',
            employee: 'Employee',
          },
          fields: {
            allowBeseenApplication: 'Allow #BeSeen application',
            permissions: 'Permissions',
            selectAll: 'Select All',
          },
          validations: {
            typeIsRequired: 'Type is required',
            descriptionIsRequired: 'Description is required',
          },
        },
        demographics: {
          title: 'Demografía',
          description: 'Descripción de datos demográficos',
          validations: { nameIsRequired: 'Se requiere el nombre' },
        },
        settings: {
          formLabels: {
            companyPurpose: "Organization's purpose",
            timezone: 'Timezone',
            suggestionBox: 'Name of Suggestion Box',
          },
          placeholders: {
            companyPurpose: "What is your organization's purpose?",
            timezone: 'Which timezone are you in?',
            suggestionBox: 'Share your awesome suggestions!',
          },
          sectionTitles: {
            socialMedia: 'Social Media',
            feed: 'Feed',
            insightsPermissions: 'Insights Permissions',
            nominations: 'Nominations',
            mfa: 'Multi-Factor Authentication',
            profanityFilter: 'Profanity Filter',
            integrations: 'Integrations',
            recognitionFrequency: 'Recognition Frequency',
          },
          sectionControlLabels: {
            socialMedia: {
              allowSharing: 'Allow Social Media Sharing',
              facebook: 'Facebook',
              linkedIn: 'LinkedIn',
              twitter: 'Twitter',
            },
            feed: { suggestionBox: 'Include Suggestion Box' },
            insightsPermissions: {
              organizationWideBeheard: 'Display Organization-Wide BeHeard',
              pulseSurvey: 'Display Pulse Survey',
              managerTeamBeheard: "Display Managers' Team BeHeard",
              managerTeamPulseSurvey: "Display Managers' Pulse Survey",
            },
            profanityFilter: { enableProfanityFilter: 'Enable Profanity Filter' },
            nominations: { enableNominations: 'Enable Nominations' },
            mfa: {
              requireMFA: 'Require Multi-Factor Authentication to all employees',
            },
            recognitionFrequency: { atLeastEvery: 'At least every {frequency} days' },
          },
          saveChanges: 'Save Changes',
          suggestionBoxTooltipTitle:
            'Give employees the opportunity to share their thoughts and ideas.',
          slackIntegration: {
            step1: "Step #1: Set your Slack's Workspace ID",
            workspaceIdOfSlack: 'Workspace ID of your Slack Organization',
            setWorkspaceID: 'Set Workspace ID',
            step2:
              'Step #2: Click the link below and allow Sparck to set Sparcky Bot to your Slack Workspace',
            allowSparckToIntegrateSlack:
              "Allow Sparck to integrate with your Slack's Workspace",
            alreadyClickedSparckBot:
              "Already clicked the button above and finished the process? {link} to reload the screen and check Slack's integration status!",
          },
          msTeamsIntegration: {
            stepsTutorialFindLink: {
              stepOne: {
                label: 'Click on the 3 dots button',
                description:
                  'The 3 dots button is located right to the channel you created. Then click "Get link to channel"',
              },
              stepTwo: { label: 'Click copy!' },
            },
            stepsTutorialInstallBot: {
              stepOne: {
                label: 'Download Sparck Bot',
                customDescription: {
                  partOne: 'Click "Download MS Teams Sparck Bot" in this screen',
                  partTwo:
                    "Go to MS Teams > Apps > Manage Your Apps > Upload an app to your org's app catalog",
                },
              },
              stepTwo: {
                label: 'Install App',
                description:
                  'Find Sparck Bot in your file explorer, select and click on Sparck App in MS Teams',
              },
              stepThree: {
                label: 'Add to Team',
                description:
                  'Click on the arrow down button and select "Add to a Team"',
              },
              stepFour: {
                label: 'Setting Up',
                description:
                  'Type the name of your company and select the option with the Sparck Feed channel you created in previous steps and click "Set up a Bot"',
              },
            },
            stepsTutorialSetPolicies: {
              stepOne: {
                label: 'Adding Policies',
                customDescription: {
                  partOne: 'Go to the page App Setup Policies of your MS Teams:',
                  partTwo: 'Click the link "Global (Org-wide default)"',
                  partThree: 'In the "Installed apps" section, click "Add apps"',
                  partFour:
                    'Type "Sparcky" on the search field and click "add" for the Sparcky app',
                },
              },
              stepTwo: {
                label: 'Pin App',
                customDescription: {
                  partOne:
                    'You should see the bot listed in the "Installed apps section"',
                  partTwo:
                    'Scroll down to the "Pinned apps" section and click on "+ Add apps" link',
                  partThree:
                    'Type "Sparcky" on the search field, click "add" for the Sparcky app and click "add" again to save this step',
                  partFour:
                    'Type "Sparcky" on the search field and click "add" for the Sparcky app',
                },
              },
              stepThree: {
                label: 'Done!',
                customDescription: {
                  partOne:
                    'You should see the bot listed in the "Pinned apps section"',
                  partTwo:
                    'Place the Sparcky in the position you want by dragging and dropping it',
                  partThree: 'Then click the "Save" button at the bottom',
                },
              },
            },
            stepOne: 'Step #1: Paste your MS Teams Channel link',
            channelLinkLabel: 'Channel Link of your MS Teams Organization',
            save: 'Save',
            stepTwo:
              'Step #2: Click the link below to download your Sparck Bot (.zip file)',
            downloadMSTeamsSparckBot: 'Download MS Teams Sparck Bot',
            frequentlyAskedQuestions: 'Frequently Asked Questions',
            whereToFindChannelLink: 'Where do I find my MS Teams Channel Link?',
            whatToDoNext: 'I downloaded my Sparck Bot. What do I do now?',
            sparckBotByDefault:
              "I want my organization's employees to have Sparck Bot by default. How do I do that?",
          },
          settingsIntegration: {
            sparckFeedChannelDefined: 'Sparck Feed channel already defined',
            integrate: 'Integrate',
            remove: 'Remove',
            integrationSectionTitles: {
              slackBot: 'Slack Bot Integration',
              msTeams: 'MS Teams Bot Integration',
            },
            slackBotIntegrationDialogTitle: 'Slack Bot Integration',
            msTeamsBotIntegrationDialogTitle: 'MS Teams Bot Integration',
            slackIntegrationConfirmDelete: {
              title: 'Remove Sparcky Bot Slack Integration',
              itemName: 'Sparcky Bot Slack Integration',
              actionLabel: 'remove',
            },
            msTeamsIntegrationConfirmDelete: {
              title: 'Remove Sparcky Bot MS Teams Integration',
              itemName: 'Sparcky Bot MS Teams Integration',
              actionLabel: 'remove',
            },
          },
          snackbarSuccess: { saved: 'Settings saved!' },
        },
        signup: {
          createAccount: 'Create Account',
          secondaryActionButtonLabels: { backToLogin: 'Back to Login' },
          description:
            'We are excited to help you launch your complimentary #BeHeard Survey. Get started for free and upgrade later. Please provide the following information to create your organization account. No credit card required.',
          confirmEmailDialog: {
            title: 'Almost there!',
            content:
              'Before we create your account, quick email check - does everything look correct?',
            changeEmail: 'Change email',
            emailIsCorrect: 'Email is correct',
          },
          formLabels: {
            companyName: 'Organization Name',
            industry: 'Industry',
            websiteUrl: 'Website URL',
            password: 'Password',
            sendEmail: 'Send e-mail notifying that the organization was created.',
          },
          termsAndConditionsLink: {
            firstLine: 'By clicking Create Account, you agree to the',
            secondLine: 'Sparck Terms and Conditions',
          },
          validations: {
            sparckAdminSchema: {
              companyNameIsRequired: 'Organization Name is required',
              invalidUrlFormat: 'Invalid URL format',
              websiteUrlIsRequired: 'Website URL is required',
              industryIsRequired: 'Industry is required',
            },
            employeesSchema: {
              companyNameIsRequired: 'Organization Name is required',
              invalidUrlFormat: 'Invalid URL format',
              websiteUrlIsRequired: 'Website URL is required',
              industryIsRequired: 'Industry is required',
              firstNameIsRequired: 'First name is required',
              lastNameIsRequired: 'Last name is required',
              phoneNumberInvalidFormat: 'Must be a valid US phone number',
              phoneNumberIsRequired: 'Phone Number is required',
              invalidEmailFormat: 'Invalid email format',
              emailIsRequired: 'Email is required',
              passwordCapitalLetter:
                'Password must include at least one capital letter.',
              passwordLowercaseLetter:
                'Password must include at least one lowercase letter.',
              passwordNumber: 'Password must include at least one number.',
              passwordSpecialCharacter:
                'Password must include at least one special character (+, #, !, ? or @).',
              passwordIsRequired: 'Password is required',
            },
          },
          snackbar: {
            success: {
              company: 'Organization',
              createdSuccessfully: 'created successfully!',
            },
          },
        },
        login: {
          snackbar: {
            success: {
              checkEmail: 'Please check your email for further instructions.',
              useNewPassword: 'Please use your new password to log in.',
              passwordUpdated: 'Password updated successfully!',
            },
          },
          changePassword: {
            title: 'Change Password',
            formLabels: {
              currentPassword: 'Current Password',
              newPassword: 'New Password',
            },
            changePasswordButton: 'Change Password',
            backToLoginButton: 'Back to login',
          },
          loginForm: {
            welcome: 'Welcome!',
            backupCodeInstruction:
              'Enter one of the backup codes that you saved when you enabled Multifactor Authentication',
            labels: {
              password: 'Password',
              verificationCode: 'Verification Code',
              useDifferentMFAMethod: 'Use a different MFA method',
              backupCode: 'Enter Backup Code',
              authApp: 'Auth App',
              useBackupCode: 'Use Backup Code',
            },
            resendVerificationCodeTooltip: 'Resend verification code to your phone',
            useBackupCode: 'Use a backup code',
            dontHaveBackupCode: "I don't have a backup code",
            loginButton: 'Login',
            forgotPasswordButton: 'Forgot my password',
          },
          recoverPassword: {
            setPassword: 'Set your password',
            forgotPassword: 'Forgot your password?',
            enterNewPassword: 'Enter your new password.',
            formLabels: { password: 'Password' },
            enterEmail: 'Enter your email address to recover your password.',
            setPasswordButton: 'Set Password',
            recoverPasswordButton: 'Recover Password',
            backToLoginButton: 'Back to login',
          },
        },
        mfa: {
          enable: 'Enable',
          disable: 'Disable',
          viewOrDownload: 'View/Download',
          primary: 'PRIMARY',
          makePrimary: 'Make Primary',
          gotIt: 'Got it!',
          enterPassword: 'Enter Your Password',
          enterVerificationCode: 'Enter Verification Code',
          mfaFactors: {
            authApp: {
              title: 'Authenticator App',
              description:
                'Use an authenticator app to generate one time security codes (most secure).',
              steps: {
                scanQRCode: 'Scan QR Code',
                success: 'You have successfully enabled Auth App!',
              },
            },
            sms: {
              title: 'SMS',
              description: 'Use your mobile phone to receive security codes.',
              steps: {
                smsSetup: 'SMS Setup',
                success: 'You have successfully enabled SMS!',
              },
            },
            email: {
              title: 'Email',
              description: 'Use your email to receive security codes (least secure).',
              steps: { success: 'You have successfully enabled Email!' },
            },
            backupCodes: {
              title: 'Backup Codes',
              description:
                'Use backup codes when you are unable to use another method.',
              steps: { success: 'Your Backup Codes' },
              actions: { download: 'Download', print: 'Print', copy: 'Copy' },
            },
            dialogTitle: {
              setup: 'MFA {selectedFactor} Setup',
              disable: 'MFA Disable {selectedFactor}',
            },
            disableDialogContent:
              'Are you sure you want to disable {selectedFactor} as a multi-factor authentication method?',
          },
          enterCredentials: 'To continue, please enter your password',
          enterVerificationCodeMessage: {
            email: 'We sent a verification code to this email: {email}.',
            sms: 'We sent a verification code via SMS to the number ending in {phoneNumberLastDigits}.',
            authenticatorApp:
              'Enter the verification code generated by your authenticator app.',
          },
          setupComponentTitle: 'Select authentication options',
          setupComponentDescription:
            "Each time you sign in to your Sparck account, you'll need your password and a verification code.",
          snackbars: {
            success: {
              mfaDisabled:
                '{selectedFactor} was successfully disabled as an MFA method!',
            },
            error: {
              verificationCode:
                'We could not verify the code you entered. Make sure to type a valid verification code',
            },
          },
          next: 'Next',
          youNeedAnAuthApp:
            'You will need an authentication app to complete this process such as {example}',
          scanQRCode: 'Scan the QR code into your app',
          whenYouAreDone: "When you're done scanning, click 'Next'",
          successfullyEnabled: 'Successfully enabled',
          yourBackupCodes: 'Your backup codes',
          yourBackupCodesDescription:
            'Save these emergency backup codes and store them somewhere safe. If you lose your phone or authentication device, you can use backup codes to sign in.',
          footerMessage: 'Authentication codes will be texted to the number you set.',
          validations: { verificationCodeRequired: 'Verification code is required' },
          enterSMSCode: 'Enter the 6-digit code sent to you via SMS',
          enterAuthAppCode: 'Enter the 6-digit code generated by your app',
          enterEmailCode: 'Enter the 6-digit code sent to your email',
          labels: { enterYourCode: 'Enter your code' },
          resendCode: 'Resend Code',
          enterPhoneNumber:
            'Please, enter the number you want to use to receive the verification codes.',
          mfaOptions: { authApp: 'Authenticator App', sms: 'Text Message (SMS)' },
          weRecommendAnApp:
            'We recommend a time-based password app to keep our customers secure.',
          mfaRequired:
            '{organizationName} requires Multi-Factor Authentication (MFA)',
          mfaRequiredDescription:
            "{organizationName} requires every employee to set up Multi-Factor Authentication for use with the Sparck Application. This adds an additional layer of security and protection for your and your company's information and is very easy to set up. Just select the method you would like to use below and we will walk you through setting it up. If you decide you want to use a different method in the future, you can update your MFA settings from your Profile screen.",
          startMFASetup: 'Start MFA Setup',
          takeMeToSparck: 'Take me to Sparck!',
          backupCodesDialog: {
            title: 'Your backup codes',
            resetSuccessAlert: {
              title: 'Sucess!',
              description: 'Your backup codes have just been reset successfully.',
            },
            resetBackupCodes: 'Reset Backup Codes',
            submit: 'Submit',
          },
        },
        marketing: {
          snackbar: {
            success: { surveyAddedToCart: 'Survey added to your cart successfully' },
          },
          testimonialFlexCare:
            "At FlexCare, our organization culture is really important to us because our employees are the heart of our operation. As we began to scale, Sparck's #BeHeard Premium Report helped us align our priorities based on our organizational effectiveness and what was most important to our employees. We were able to outline an organization-wide strategy yet personalize the results using location and departments while maintaining employee confidentiality. This served as a great coaching tool for our managers to give them better insight into how to lead a more productive and engaged team. I highly recommend this survey. It was an easy process with actionable insights!",
          testimonialTeledyneTechnologies:
            "Sparck's free Employee Engagement Cost Analysis tool gave us an easy and effective way to communicate how an increase in engagement would directly impact our organizations's performance. The customized one-page overview was a great addition to our executive meeting and gave us a way to look at our specific numbers and outline a solution. I highly recommend this tool to HR professionals who are mapping out their internal strategies and want to upgrade to the #BeHeard Premium Report.",
          upgradeNow: 'Upgrade now',
          marketingHeader: {
            upgradeToday: 'Upgrade Today!',
            getMoreStrategicLook:
              'Get a more strategic look at your #BeHeard Survey results with personalized recommendations and demographic insights.',
            beheardPremiumReportDescription:
              "Our statistically valid #BeHeard Premium Report will give you a deeper understanding of your results and actionable steps to increase your employee engagement at every level of your organization. Plus, you'll also receive critical insights and tailored advice when you upgrade to the Premium Report. Check out the details below, and if you want to chat, we are here for you.",
            upgradeNow: 'Upgrade Now',
          },
        },
        shoppingCart: {
          yourShoppingCartItems: 'Your Shopping Cart items',
          employee: 'employee',
          employees: 'employees',
          removeFromCart: 'Remove from Cart',
          proceedToPayment: 'Proceed to Payment',
          noItems: 'You have no items in your shopping cart.',
          productTitle: '{productName} - {itemToUpgradeName} for {employeesQuantity}',
          total: 'Total',
          snackbar: {
            success: { itemRemoved: 'Item removed from your cart successfully' },
            error: { couldNotRemoveItem: 'Item could not be removed from your cart' },
          },
        },
        notifications: {
          survey: {
            isNowOpened: '{surveyName} is now opened!',
            isClosed: '{surveyName} is closed!',
            isReopened: '{surveyName} is reopened!',
            wasExtended: '{surveyName} was extended!',
            canNowBeUpgraded: '{surveyName} can now be upgraded!',
            needsToBeFinished:
              'You have a {surveyName} survey that needs to be finished!',
            lastDayToFinish: 'Today is the last day to finish  {surveyName}!',
            thankYou:
              'Thank you so much for your participation in {surveyName} survey!',
            fullReportAvailable:
              'Congratulations! Your full {surveyName} #BeHeard Report is now available!',
            freeReportAvailable:
              'Your free {surveyName} #BeHeard Report is now available',
          },
          pulseSurvey: {
            isNowOpened: '{pulseSurveyName} is now opened!',
            isClosed: '{pulseSurveyName} is closed!',
            isReopened: '{pulseSurveyName} is reopened!',
            wasExtended: '{pulseSurveyName} was extended!',
            needsToBeFinished:
              'You have a {pulseSurveyName} pulse survey that needs to be finished!',
            lastDayToFinish: 'Today is the last day to finish {pulseSurveyName}!',
            thankYou:
              'Thank you so much for your participation in {pulseSurveyName} pulse survey!',
          },
          sparck: {
            youRecognized: "You've recognized {sparckedUser}!",
            recognizedBy: "You've been recognized by{sparckOwner}!",
            takeActionForRecognition:
              'Take Action for this recognition from {sparckOwner} to {sparckedUser}!',
            wasAnswered: 'Your Sparck was answered by {sparckedUser}:',
            rewardRedeemed: '{sparckedUser} has redeemed their reward',
            userExceededBudgetLimit: '{title} {recipient} hit their limit, so {sender} couldn’t reward them. Just keeping you in the loop!',
          },
          mentions: {
            title: "You've been mentioned by {mentioningUser} in a {sourceType}!",
          },
          suggestions: {
            newReply: {
              title: 'You received a {newReply} regarding a suggestion',
              newReplyLabel: 'new reply',
            },
            newSuggestion: { title: 'There is a new suggestion from an employee.' },
          },
          actionLabels: {
            viewSurvey: 'View Survey',
            viewPulseSurvey: 'View Pulse Survey',
            viewReport: 'View Report',
            viewSparck: 'View Sparck',
            takeAction: 'Take Action',
            viewPost: 'View Post',
            viewComment: 'View Comment',
            goToChat: 'Go to Chat',
            goToSuggestions: 'Go to Suggestions',
          },
          yourNotifications: 'Your Notifications',
          markAllRead: 'Mark All Read',
          noNotifications: ' You have no notifications.',
        },
        managerCertification: {
          managerCertificationData: {
            roadmap: {
              dayOne: {
                title: '¡Bienvenido A La Certificación Sparck!',
                description:
                  'Bienvenido a la Sección 1 de la Certificación de Reconocimiento de Empleados de Sparck. Para comenzar, vamos a iniciar la semana y avanzaremos hasta la línea de salida con una breve introducción al Sprint de Sparck de 3 minutos. Para completar con éxito la Sección 1, debe ver el video de capacitación y participar en nuestro cuestionario diario. ¡Divertirse!',
                sections: {
                  learnItDescription:
                    'Aquí es donde comienza la magia. En esta sección, puede acceder fácilmente al video de capacitación y a todos los recursos que necesita para desarrollar estas nuevas habilidades.',
                  masterItDescription:
                    '¡Espero que hayas disfrutado el primer video! Es hora de poner a prueba sus conocimientos hasta el momento y poner las ruedas en movimiento con las áreas de acción que Anna asignó. ¡No podemos esperar a ver a quién eliges reconocer!',
                },
                tasks: {
                  trainingVideoName: 'Video De Entrenamiento De la Sección 1',
                  challengeName: 'Desafío De la Sección 1',
                },
              },
              dayTwo: {
                title: 'Minuto 1: Reflexiona - ¡El Tiempo lo es Todo!',
                description:
                  '¡Bienvenidos a la Sección 2 de la Certificación! Ayer proporcionamos una introducción al Sparck Sprint de 3 minutos y ahora es el momento de retirar las capas. Hoy te sumergirás en el Minuto 1 del Sparck Sprint: Reflect. Para completar con éxito la Sección 2, mire el video de capacitación, realice el Tour de Sparck y responda el cuestionario. ¡Disfrutar!',
                sections: {
                  practiceItDescription:
                    'Esta es una nueva sección que se incorporará durante el resto de la semana. Anna tomará lo que aprendió en el video de capacitación y le mostrará cómo nuestra tecnología lo ayuda a aprovechar estas lecciones. ¡Abróchate el cinturón, es hora de la gira!',
                  masterItDescription:
                    'Es hora de tomar lo que ha aprendido y ponerlo a prueba con el cuestionario de la Sección 2. Esto no es para hacerte tropezar, sino para ayudarte a dominar cada minuto del Sprint. Asegúrese de iniciar sesión en su cuenta de Sparck y comenzar el proceso de reflexión con los conocimientos personalizados de su equipo.',
                },
                tasks: {
                  trainingVideoName: 'Video De Entrenamiento De la Sección 2',
                  challengeName: 'Desafío De la Sección 2',
                  challengeDescription:
                    'Como sabe, el objetivo de la reflexión es identificar quién, por qué y cuándo necesita reconocer a su equipo. Aproveche sus conocimientos de Spark y obtenga reconocimiento inmediato.',
                },
              },
              dayThree: {
                title: 'Minuto 2: Conéctate - Personaliza La Experiencia',
                description:
                  '¡Bienvenidos a la Sección 3 de la Certificación! Hoy se centrará en el Minuto 2 del Sparck Sprint: Connect. Para completar con éxito la Sección 3, mire el video de capacitación, realice el Sparck Tour y responda el cuestionario. Tenemos recursos como un desglose de los diferentes estilos de apreciación y una lista de preguntas F.R.O.G. (usted sabrá lo que esto significa después de la capacitación). Esto lo ayudará a elevar y fortalecer las conversaciones de sus empleados. ¡Asegúrate de ver el video de comedia también, te garantizamos que sonreirás!',
                sections: {
                  masterItDescription:
                    'Refuerce sus conocimientos y asegúrese de realizar el cuestionario de la Sección 3. Pero no se detenga allí, en función de a quién planea reconocer al final de la certificación, inicie sesión y consulte su Estilo de Reconocimiento de Sparck.',
                },
                tasks: {
                  trainingVideoName: 'Video De Entrenamiento De la Sección 3',
                  sparckAppreciationStyles: 'Estilos De Apreciación de Sparck',
                  sparckAppreciationStylesDescription:
                    '¡Aprenda las diferentes formas en que a sus empleados les gusta ser apreciados! Echa un vistazo a los diferentes estilos de apreciación de Sparck {link}. Para obtener más información sobre los diferentes estilos de apreciación (y reírse mucho), mire este video de comedia creado por Betsaida LeBron, estratega de creación de equipos, entrenadora de gestión y fundadora de ImprovEQ. Haga clic a continuación para ver!',
                  challengeName: 'Desafío De la Sección 3',
                  challengeDescription:
                    '¿Crees que ya conoces los estilos de apreciación de tu equipo? Mira si tienes razón.',
                },
              },
              dayFour: {
                title: 'Minuto 3: Reconocer - Hora de Actuar',
                description:
                  '¡Bienvenidos a la Sección 4 de la Certificación! Hoy nos centraremos en el Minuto 3 del Sparck Sprint: Reconocer. ¡Ya casi llegas a la línea de meta! Ahora que sabes cómo Reflexionar y Conectar, la pieza final es perfeccionar el reconocimiento. Para completar con éxito la Sección 4, mire el video de capacitación, realice el recorrido de Spark y complete el cuestionario. No olvide acceder a los aspectos destacados en sus recursos de capacitación. ¡Diviértase!',
                sections: {
                  masterItDescription:
                    'Estás tan cerca, pon a prueba tus conocimientos respondiendo el Examen de la Sección 4. Y después de hoy, es posible que sienta curiosidad por descubrir qué recompensas significarían más para su equipo. ¡Inicia sesión en tu cuenta y explora sus perfiles!',
                },
                tasks: {
                  trainingVideoName: 'Video De Entrenamiento De la Sección 4',
                  challengeName: 'Desafío De la Sección 4',
                  challengeDescription:
                    'Piensa en la persona que vas a reconocer esta semana. Si tuvieras $10 para gastar, ¿qué les darías? Estás a solo unos clics de saberlo.',
                },
              },
              dayFive: {
                title: '¡Es hora de reconocer a su empleado(a)!',
                description:
                  'Bienvenidos a la Sección 5 de la Certificación. ¡Estás a punto de cruzar la línea de meta! Use lo que ha aprendido y combine las piezas mágicas del Sparck Sprint de 3 minutos: Conectar, Reflexionar y Reconocer para crear experiencias de reconocimiento significativas. Mire el video de capacitación, realice el Sparck Tour y complete su prueba final. Para obtener más inspiración, consulte sus recursos de capacitación. {rememberMessage}',
                rememberMessage:
                  "Y recuerda: además de los ejercicios diarios, para obtener la certificación oficial necesitas tomar lo que has aprendido y reconocer, o 'Sparck', a uno de tus empleados. ¡No podemos esperar a escuchar la historia!",
                sections: {
                  masterItDescription:
                    "Lo lograste, ¡espero que hayas disfrutado el sprint! Cruza la línea de meta con el cuestionario de la Sección 5 y comparte tu historia de 'Sparck'. Si necesita algunas ideas antes de actuar, asegúrese de usar las ideas que Anna compartió durante el recorrido e inicie sesión en su cuenta.",
                },
                tasks: {
                  trainingVideoName: 'Video De Entrenamiento De la Sección 5',
                  challengeName: 'Desafío De la Sección 5',
                  challengeDescription:
                    '¡Eche un vistazo detrás de la cortina e identifique cómo reconocer a todos los miembros de su equipo en el Momento Correcto, de la Manera Correcta y con la Recompensa Correcta! Es hora de Sparck en tu lugar de trabajo.',
                },
              },
              trainingResources: 'Acceder a los Recursos de Formación',
              takeTheQuiz: 'Haz la Prueba',
              watchSparckTour: 'Mira el Recorrido Sparck',
            },
            learnIt: 'Aprenderlo',
            practiceIt: 'Practícalo',
            masterIt: 'Dominarlo',
          },
          celebrationBoard: {
            title: '¡Celebremos Su Certificación!',
            description:
              '¡Celébralo con nosotros! Siempre es más divertido compartir su entusiasmo para que todos los que completen la Certificación tengan sus nombres agregados al tablero. Estamos ansiosos por ver el hermoso impacto que tendrá en su organización ¡Vamos a encender el mundo!',
            congratulations: 'Felicitaciones!',
          },
          leaderboard: {
            title: '¡Diviértete y mira dónde aterrizas en la Tabla de Clasificación!',
            header: 'Tabla de Clasificación',
          },
          managerCertificationComponent: {
            minutes: {
              minuteOneDescription:
                'El Minuto 1 te enseña a Reflexionar y capturar oportunidades de reconocimiento en el Momento Correcto. ¡Tiempo lo es todo!',
              minuteTwoDescription:
                'El Minuto 2 te enseña a Conectar e identificar la Manera Correcta de reconocer. Es fundamental conocer las preferencias de reconocimiento de su empleado.',
              minuteThreeDescription:
                'El Minuto 3 te enseña a Reconocer con la Recompensa Correcta. La personalización es clave, no seas genérico.',
            },
            secretComponents: {
              rightTime: 'Tiempo Correcto',
              rightWay: 'Manera Correcta',
              rightReward: 'Recompensa Correcta',
            },
            sections: {
              welcome: {
                title: 'Certificación de Reconocimiento de Empleados',
                description: {
                  firstParagraph:
                    'Bienvenido, ¿listo para divertirte? En esta certificación, aprenderá cómo incorporar nuestro Sparck Sprint de 3 minutos en su día. Está diseñado para equiparlo para proporcionar un reconocimiento efectivo y personalizado. Esto no solo aumentará la felicidad de los empleados, sino también la productividad y el compromiso general. Esta Certificación se creó para completarse en un período de 5 días, ¡pero siéntase libre de sumergirse y completar todo a su propio ritmo!',
                  middleParagraph:
                    'Nuestro Sparck Sprint de 3 minutos lo preparará para brindar el tipo correcto de reconocimiento, en todo momento.',
                  lastParagraph:
                    'Hay un arte del reconocimiento que nunca se ha enseñado, estos son los componentes secretos para los que estarás preparado:',
                },
              },
              realLifeSparckStory: {
                title: 'Una historia Sparck de la Vida Real',
                subtitle:
                  '¿Quiere ver el impacto que puede tener el reconocimiento personalizado?',
                description:
                  'Vea cómo el Sparck Sprint de 3 minutos equipó a nuestra CEO, Anna Straus, para reconocer a Abigail Kidd en el momento correcto, de la manera correcta, con la recompensa correcta. ¡Esta historia se deconstruirá a lo largo de la certificación para dar vida a la capacitación!',
              },
              sneakPeek: {
                title: 'Obtenga Un Adelanto De La Certificación Sparck',
                description:
                  '¡Está a punto de embarcarse en un emocionante viaje con nuestra Certificación de Reconocimiento de Empleados! ¡Obtenga un adelanto de lo que está por venir mientras nuestra CEO Anna Straus lo entrena a través del Sparck Sprint de 3 minutos!',
              },
              roadmap: {
                title: 'Hoja de Ruta de la Certificación',
                description:
                  'En solo 10 minutos al día, durante un período de 5 días, obtendrá la certificación en el programa de reconocimiento de empleados de Sparck. Este conjunto de herramientas destacará cada día a medida que avanza en el programa para brindarle todo lo que necesita para tener éxito. Vea lo que hay en la tienda y acceda a la hoja de Ruta de 5 Secciones haciendo clic en las tarjetas a continuación.',
                enter: 'INGRESAR',
              },
            },
          },
          submitStoryCard: {
            title: 'Envíe Su Historia',
            description:
              '¡Comparta todos los emocionantes detalles con nosotros mientras celebramos su carrera a través de la línea de meta! Si tiene una historia de una vez que alguien en el trabajo lo hizo sentir realmente visto, ¡también puede compartir esta historia!',
            submitStoryForm: {
              snackbarSuccess: 'Su historia fue enviada con éxito',
              tellYourStory: 'Cuenta tu historia',
              permissionToShareOnSocialMedia:
                '¿Tenemos su permiso para compartir su reconocimiento en las redes sociales?',
              canWeTagYouOnSocialMedia:
                '¿Podemos etiquetarte? Si es así, proporcione sus identificadores de redes sociales. (IG, Twitter y LinkedIn)',
            },
          },
          managerCertificationTask: {
            day: 'Sección',
            clickForResource: 'Haga clic en {link} para descargar {name}',
            trainingResourceForSection:
              'Recurso de capacitación para la sección {section}',
            clickForVideoScript: 'Haga clic en {link} para el guión del video',
            frogQuestions: 'preguntas F.R.O.G.',
            takeTheQuiz: '¡Haz la prueba!',
            takeMeToTheChallenge: '¡Llévame al desafío!',
          },
          managerCertificationTasks: {
            trainingVideo: 'vídeo de entrenamiento',
            trainingVideos: 'vídeos de entrenamiento',
            trainingResource: 'recurso de formación',
            trainingResources: 'recursos de formación',
            quiz: 'cuestionario',
            quizzes: 'cuestionarios',
            challenge: 'desafío',
            challenges: 'desafíos',
          },
          managerCertificationFormBuilder: {
            termsOfCommunicationFirstParagraph:
              'Sparck se compromete a proteger y respetar su privacidad, y solo usaremos su información personal para administrar su cuenta y proporcionar los productos y servicios que nos solicitó. De vez en cuando, nos gustaría comunicarnos con usted acerca de nuestros productos y servicios, así como otros contenidos que puedan ser de su interés. Si acepta que nos comuniquemos con usted para este propósito, marque la casilla a continuación para decir cómo desea que nos comuniquemos con usted:',
            termsOfCommunicationMiddleParagraph:
              'Puede darse de baja de estas comunicaciones en cualquier momento. Para obtener más información sobre cómo darse de baja, nuestras prácticas de privacidad y cómo nos comprometemos a proteger y respetar su privacidad, consulte nuestra Política de privacidad.',
            termsOfCommunicationLastParagraph:
              'Al hacer clic en enviar a continuación, acepta permitir que Sparck almacene y procese la información personal enviada anteriormente para proporcionarle el contenido solicitado.',
            agreeToReceiveCommunications:
              'Acepto recibir otras comunicaciones de Sparck',
          },
          managerCertificationQuizDialog: {
            title: 'Cuestionario de la sección {section}',
            requiredField: 'Por favor complete este campo obligatorio',
            snackbarSuccess: '¡Sus respuestas fueron enviadas con éxito!',
            quizQuestions: {
              sectionOne: {
                whoAreYouGoingToRecognize:
                  '¿A quién vas a reconocer al final de esta certificación?',
                runningBuddy:
                  '¿A quién seleccionaste como tu "compañero de carrera"? (opcional)',
                threeComponentsOfThreeMinuteSparckSprint:
                  '¿Cuáles son los tres componentes del Sparck Sprint de tres minutos?',
                threeAreasToDeliverEffectiveRecognition:
                  'Las tres áreas que debe abordar para brindar un reconocimiento efectivo son el momento adecuado, la forma correcta y el ____ correcto.',
                feedbackOrSuggestionsSection1:
                  '¿Algún comentario, sugerencia o idea que quiera compartir sobre la capacitación? (opcional)',
              },
              sectionTwo: {
                didThePersonChange:
                  '¿La persona que elegiste para reconocer cambió en base a este tiempo de reflexión? Si es así, ¿a quién?',
                goalDuringYourReflection:
                  'El objetivo durante su tiempo de reflexión es identificar quién, por qué y ______ para reconocer a alguien.',
                feedbackOrSuggestionsSection2:
                  '¿Alguna idea, sugerencia o comentario que quiera compartir para que esta capacitación sea más beneficiosa? (opcional)',
              },
              sectionThree: {
                gInTheFrogStandsFor:
                  'La "G" en el F.R.O.G. marco conversacional significa qué?',
                yourSparckAppreciationStyle:
                  '¿Cuál es tu estilo de apreciación de Spark? ¿Con qué estás de acuerdo y qué cambiarías?',
                appreciationStyleOfTheEmployeeYouAreGoingToRecognize:
                  '¿Cuál es el estilo de reconocimiento de Spark para el empleado que va a reconocer?',
                feedbackOrSuggestionsSection3:
                  '¿Le gustaría compartir algún comentario, sugerencia o idea para mejorar esta capacitación? (opcional)',
              },
              sectionFour: {
                howWouldYouDescribeTheTwoTypesOrRewards:
                  'Después de esta capacitación, ¿cómo describiría los dos tipos de recompensas que puede ofrecer?',
                recognitionNeedsToBeFrequentSpecificAnd:
                  'El reconocimiento es un refuerzo positivo que debe ser frecuente, específico y _________.',
                whatAreYouRecognizingThemForSection4:
                  'Piensa en la persona que vas a reconocer, ¿por qué la vas a reconocer?',
                feedbackOrSuggestionsSection4:
                  '¿Le gustaría compartir algún comentario, sugerencia o idea para mejorar esta capacitación?',
              },
              sectionFive: {
                mostValuableThingYouLearnedThisWeek:
                  '¿Qué es lo más valioso que aprendiste esta semana?',
                whatWouldYouChangeAboutTheCertification:
                  '¿Qué cambiarías de la certificación?',
                whatDayAreYouGoingToRecognizeThem:
                  'Reflexiona: Ahora que ya sabes a quién vas a reconocer, ¿qué día los vas a reconocer?',
                howAreYouGoingToRecognizeThem:
                  'Conéctate: ahora que conoces sus preferencias de reconocimiento, ¿cómo vas a reconocerlos?',
                whatAreYouRecognizingThemForSection5:
                  'Reconocer: ahora que sabe que sus palabras deben ser genuinas y específicas para su impacto, ¿por qué las reconoce?',
                whatIsYourRecognitionPreference:
                  'Nos encanta celebrar a aquellos que están certificados. ¿Cuál es su preferencia de reconocimiento?',
                canWeTagYouInSocialMedia:
                  '¿Podemos etiquetarte en nuestra publicación de redes sociales cuando la completes? (Escriba Sí o No)',
                testimonial: {
                  description:
                    '¡Hola Sparck-Star! Estás en camino a ser certificado. Si cree que esta capacitación ha sido valiosa y tiene un testimonio que podemos compartir con otros gerentes, ¡nos encantaría escucharlo!',
                  label: 'Tu Testimonio',
                },
              },
            },
          },
          managerCertification5DayRoadmap: {
            title: 'Hoja de Ruta de Certificación de 5 Días',
            description:
              'Este kit de herramientas fue diseñado para un período de 5 días, ¡pero no necesita esperar! Puede completar el entrenamiento a su propio ritmo. Se destacará cada día a medida que avanza en el programa para brindarle todo lo que necesita para tener éxito. Vea lo que hay en la tienda y acceda a la Hoja de ruta de 5 días haciendo clic en el botón a continuación. Tendrá la oportunidad de leer más de estas historias a medida que se acerque a la finalización de la Certificación.',
            downloadRoadmap: 'Descarga la hoja de ruta',
          },
          submit: 'ENVIAR',
          noOneHasCompletedTheCertification:
            'Nadie ha completado la certificación todavía.',
        },
      }
    