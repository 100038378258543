import React, { useEffect, useMemo } from 'react'

import * as FullStory from '@fullstory/browser'
import { omit } from 'lodash'
import { useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'

import { useCompany } from 'contexts'
import { API } from 'core/requests'
import { ROUTES, ROUTES_PATHS } from 'core/routes'
import { decodeJWT, getUserTokenFromLocalStorage, isTokenExpired } from 'utils'
import { urlParams } from 'utils/queryParams'
import { getPermissions, getRoles } from 'utils/user'

const initialUser = {
  ...decodeJWT(getUserTokenFromLocalStorage()),
  token: getUserTokenFromLocalStorage(),
}

type UserContextType = {
  user: any
  userIsLoading: boolean
  setUser: (value: any) => void
  fetchUser(): any
  routeIsPrivate: boolean
}
const UserContext = React.createContext<UserContextType | undefined>(undefined)

type Props = {
  children: React.ReactNode
}

export const UserProvider = ({ children }: Props) => {
  const [user, setUser] = React.useState<any>(initialUser)

  const history = useHistory()
  const location = useLocation()

  const routeIsPrivate = useMemo(() => {
    const splitPath = location.pathname.split('/')
    const currentPathBase = `/${splitPath[1]}`

    const route = ROUTES.find(route => {
      if (typeof route.path === 'string') {
        return route.path.startsWith(currentPathBase)
      } else {
        return route.path[0]?.startsWith(currentPathBase)
      }
    })

    return route ? route?.isPrivate : true
  }, [location])

  const routeIsLogin = useMemo(() => {
    return location.pathname === ROUTES_PATHS.login
  }, [location])

  const { company, setCompany } = useCompany()!

  const params: any = urlParams(history.location.search)
  const redirect = params.redirect && `/${params.redirect}`

  const redirectAfterLogin = () => {
    const redirectOptions = omit(params, 'redirect')

    const searchParams = new URLSearchParams(redirectOptions).toString()

    if (redirect) {
      history.push({
        pathname: redirect,
        search: `?${searchParams}`,
        state: {
          goBackToDashboard: true,
        },
      })
    } else {
      history.push(ROUTES_PATHS.dashboard)
    }
  }

  const { isLoading: userIsLoading, refetch: fetchUser } = useQuery(
    'user',
    () => API.get('users', user.sub),
    {
      enabled: Boolean(
        !user?.id &&
          user?.sub &&
          user?.token &&
          !isTokenExpired(user?.token) &&
          (routeIsLogin || routeIsPrivate),
      ),
      onSuccess: (response: any) => {
        if (!response.errors) {
          setUser({
            ...user,
            ...response,
            roles: getRoles(response?.role_users_attributes),
            permissions: getPermissions(response?.granted_permissions),
          })

          if (!company && response?.company_id) {
            setCompany({ id: response.company_id })
          }

          FullStory.identify(user.id, {
            displayName: user.first_name,
            email: user.email,
          })

          if (location.pathname === ROUTES_PATHS.login) redirectAfterLogin()
        }
      },
    },
  )

  useEffect(() => {
    if (company?.name && !user?.company?.name) {
      setUser({ ...user, company: company })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company])

  return (
    <UserContext.Provider
      value={{ user, userIsLoading, setUser, fetchUser, routeIsPrivate }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => React.useContext(UserContext)
