import { Box, Divider } from '@mui/material'

import {
  ManageRecognitionApproval,
  ManageRecognitionAvailability,
  ManageRecognitionMilestonesTable,
  ManageRecognitionNomination,
  ManageRecognitionRewards,
  ManageRecognitionTimeframe,
} from '..'

export default function ManageRecognitionAdvanced({ formik, roles }) {
  return (
    <Box>
      <ManageRecognitionRewards formik={formik} roles={roles} />
      <Divider sx={{ my: 3 }} />

      <ManageRecognitionTimeframe formik={formik} />
      <Divider sx={{ my: 3 }} />

      {formik.values.type !== 'birthday' && (
        <>
          <ManageRecognitionAvailability formik={formik} />
          <Divider sx={{ my: 3 }} />
        </>
      )}

      {formik.values.has_nominations && formik.values.has_recurrence && (
        <>
          <ManageRecognitionNomination formik={formik} />
          <Divider sx={{ my: 3 }} />
        </>
      )}

      <ManageRecognitionMilestonesTable formik={formik} />
      <Divider sx={{ my: 3 }} />

      <ManageRecognitionApproval formik={formik} />
    </Box>
  )
}
