import { useEffect, useMemo, useState } from 'react'

import { useMutation } from 'react-query'
import { useTimeoutFn } from 'react-use'

import { API } from './requests'
import { SPARCK_REFRESH_USER_TOKEN, SPARCK_USER_TOKEN } from 'constants/app'
import { useCompany, useUser } from 'contexts'
import ApplicationContainer from 'core/ApplicationContainer'
import ApplicationLoading from 'core/ApplicationLoading'
import {
  getUserTokenFromLocalStorage,
  isTokenExpired,
  secondsToExpireToken,
} from 'utils'
import { useLogout } from 'utils/hooks'

const DAY_IN_SECONDS = 60 * 60 * 24
const SECONDS_BEFORE_LOGOUT = 30000

export default function ApplicationAuthentication() {
  const { user, setUser, userIsLoading, routeIsPrivate } = useUser()!
  const { company, companyIsLoading } = useCompany()!
  const { logout } = useLogout()!

  const userToken = useMemo(
    () => user?.token ?? getUserTokenFromLocalStorage(),
    [user],
  )

  const [refreshTokenCounter, setRefreshTokenCounter] = useState(
    secondsToExpireToken(userToken) - SECONDS_BEFORE_LOGOUT || DAY_IN_SECONDS,
  )
  const [logoutCounter, setLogoutCounter] = useState(
    secondsToExpireToken(userToken) || DAY_IN_SECONDS,
  )

  const refreshToken = useMutation(
    (payload: any) => API.create('refreshToken', payload),
    {
      onSuccess: response => {
        if (response.success && response?.data?.access_token) {
          localStorage.setItem(SPARCK_USER_TOKEN, response?.data?.access_token)
          setUser(user => ({ ...user, token: response?.data?.access_token }))
        }
      },
    },
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [shouldRefreshToken, cancelTokenRefresh, resetTokenRefreshCounter] =
    useTimeoutFn(() => {
      const refreshUserToken = localStorage.getItem(SPARCK_REFRESH_USER_TOKEN)

      if (
        refreshUserToken &&
        !refreshToken.isLoading &&
        (user?.sub || user?.id)
      ) {
        refreshToken.mutate({ refresh_token: refreshUserToken })
      }
    }, refreshTokenCounter)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [shouldLogout, cancelLogout, resetLogoutCounter] = useTimeoutFn(() => {
    if (user?.id) logout()
  }, logoutCounter)

  useEffect(() => {
    cancelTokenRefresh()
    cancelLogout()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user?.token) {
      setRefreshTokenCounter(
        secondsToExpireToken(userToken) - SECONDS_BEFORE_LOGOUT,
      )
      setLogoutCounter(secondsToExpireToken(userToken))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  if (
    (!user?.id &&
      user?.token &&
      !user?.company &&
      !isTokenExpired(user?.token) &&
      routeIsPrivate) ||
    userIsLoading ||
    (companyIsLoading && !company?.name)
  ) {
    return <ApplicationLoading />
  }

  return <ApplicationContainer />
}
