import { useEffect, useRef, useState } from 'react'

import {
  Box,
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from 'react-query'

import { MediaPicker } from 'components'
import { API } from 'core/requests'
import { t } from 'i18n'

const initialValues = {
  story: '',
  allow_sparck_to_share_your_story: null,
  social_media_info: '',
}

export default function ManageCertificationSubmitStoryForm(props) {
  const { setSubmitStoryDialogOpen } = props

  const [storyMedia, setStoryMedia] = useState<any>({})

  const { enqueueSnackbar } = useSnackbar()

  const mediaPickerRef = useRef<any>(null)

  const { data: userCertification, refetch: fetchUserCertification } = useQuery(
    'getUserCertificationDetails',
    () => {
      return API.get('employeeRecognitionCertification')
    },
    {
      enabled: false,
    },
  )

  const uploadStoryMediaMutation = useMutation(
    (payload: any) => API.createOrUpdate('uploads', payload),
    {
      onSettled: async (response: any) => {
        if (response && response.id) {
          setStoryMedia({})
        }
      },
    },
  )

  const userCertificationMutation = useMutation(
    (payload: any) => API.update('employeeRecognitionCertification', payload),
    {
      onSuccess: (response: any) => {
        if (response && response.id) {
          if (!isEmpty(storyMedia)) {
            const formData = new FormData()

            formData.append('file', storyMedia)
            formData.append('attach_to', 'UserCertification')
            formData.append('record_id', response.id)
            formData.append('record_field', 'story_media')

            uploadStoryMediaMutation.mutate(formData)
          }
          setSubmitStoryDialogOpen(false)
          enqueueSnackbar(
            t(
              'managerCertification.submitStoryCard.submitStoryForm.snackbarSuccess',
            ),
            {
              variant: 'success',
            },
          )

          fetchUserCertification()
        }
      },
    },
  )

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {},
  })

  const handleSubmit = () => {
    userCertificationMutation.mutate({
      ...userCertification,
      storyMedia: storyMedia,
      certification_state: {
        ...userCertification?.certification_state,
        story: formik.values.story,
        social_media_info: formik.values.social_media_info,
        allow_sparck_to_share_your_story:
          formik.values.allow_sparck_to_share_your_story,
      },
    })
  }

  useEffect(() => {
    fetchUserCertification()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container component='form'>
      <Grid item xs={12} mt={2}>
        <MediaPicker
          ref={mediaPickerRef}
          allowImagesAndVideos
          onSelectMedia={media => {
            setStoryMedia({})

            if (media.file) {
              setStoryMedia(media.file)
            }
          }}
        />
      </Grid>

      <Grid item xs={12} mt={2}>
        <TextField
          id='story'
          name='story'
          type='text'
          label={t(
            'managerCertification.submitStoryCard.submitStoryForm.tellYourStory',
          )}
          variant='outlined'
          data-cy='story'
          multiline
          value={formik.values.story}
          required={!!formik.errors.story}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.story && Boolean(formik.errors.story)}
          helperText={formik.touched.story && formik.errors.story}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} mt={2}>
        <FormLabel id='demo-radio-buttons-group-label'>
          {t(
            'managerCertification.submitStoryCard.submitStoryForm.permissionToShareOnSocialMedia',
          )}
        </FormLabel>
        <RadioGroup
          aria-labelledby='demo-radio-buttons-group-label'
          defaultValue='female'
          name='allow_sparck_to_share_your_story'
          onChange={formik.handleChange}
        >
          <FormControlLabel value='yes' control={<Radio />} label={t('yes')} />
          <FormControlLabel value='no' control={<Radio />} label={t('no')} />
        </RadioGroup>
      </Grid>

      {formik.values.allow_sparck_to_share_your_story === 'yes' && (
        <Grid item xs={12} mt={2}>
          <TextField
            id='social_media_info'
            name='social_media_info'
            type='text'
            label={t(
              'managerCertification.submitStoryCard.submitStoryForm.canWeTagYouOnSocialMedia',
            )}
            variant='outlined'
            data-cy='social_media_info'
            multiline
            value={formik.values.social_media_info}
            required={!!formik.errors.social_media_info}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.social_media_info &&
              Boolean(formik.errors.social_media_info)
            }
            helperText={
              formik.touched.social_media_info &&
              formik.errors.social_media_info
            }
            fullWidth
          />
        </Grid>
      )}

      <Grid item xs={12} mt={2}>
        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
          <Button variant='contained' onClick={handleSubmit}>
            {t('managerCertification.submit')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}
