import { Checkbox, Grid, Switch } from '@mui/material'

import { SettingsControlSection } from '../SettingsComponent/SettingsComponent'
import { t } from 'i18n'

export default function SettingsSocialMedia(props) {
  const { companyState } = props

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} data-cy='social_media'>
        <SettingsControlSection
          {...props}
          id='social_media_sharing'
          label={t('settings.sectionControlLabels.socialMedia.allowSharing')}
          ControlComponent={Switch}
        />

        {companyState.social_media_sharing && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm='auto'>
              <SettingsControlSection
                {...props}
                id='social_media_sharing_on_facebook'
                label={t('settings.sectionControlLabels.socialMedia.facebook')}
                ControlComponent={Checkbox}
              />
            </Grid>

            <Grid item xs={12} sm='auto'>
              <SettingsControlSection
                {...props}
                id='social_media_sharing_on_linkedin'
                label={t('settings.sectionControlLabels.socialMedia.linkedIn')}
                ControlComponent={Checkbox}
              />
            </Grid>

            <Grid item xs={12} sm='auto'>
              <SettingsControlSection
                {...props}
                id='social_media_sharing_on_twitter'
                label={t('settings.sectionControlLabels.socialMedia.twitter')}
                ControlComponent={Checkbox}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
