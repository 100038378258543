import { Box, Grid, Theme, useMediaQuery, useTheme } from '@mui/material'
import { Skeleton } from '@mui/material'
import {
  OnboardingPage,
  OnboardingQuestionType,
} from 'enums/onboardingQuestion'
import { isEmpty } from 'lodash'
import { getRewardName } from 'utils/reward'
import {
  OnboardingChoiceGroupQuestions,
  OnboardingEventQuestions,
  OnboardingHeader,
  OnboardingLanding,
  OnboardingPagination,
  OnboardingProfileQuestions,
  OnboardingProgress,
  OnboardingRatingQuestions,
  OnboardingSimpleSelectQuestions,
} from '../../components'

export default function Onboarding(props) {
  const { onboardingQuestions, answers, pages, pagination } = props

  const theme: Theme = useTheme()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const ratingQuestions = onboardingQuestions.filter(
    question => question.type === OnboardingQuestionType.Rating,
  )

  const rewardQuestions = onboardingQuestions.filter(
    question => question.type === OnboardingQuestionType.Reward,
  )

  const simpleSelectQuestions = onboardingQuestions.filter(
    question => question.type === OnboardingQuestionType.SimpleSelect,
  )

  const eventQuestions = onboardingQuestions.filter(
    question => question.type === OnboardingQuestionType.Event,
  )

  const choiceGroupQuestions = onboardingQuestions.filter(
    question => question.type === OnboardingQuestionType.ChoiceGroup,
  )

  const withOnboardingChoices = question =>
    question.onboarding_choices_attributes.length > 0

  return (
    <Grid
      container
      justifyContent='center'
      style={{
        minHeight: '100vh',
        width: '100%',
        background: 'linear-gradient(127.84deg, #bbf7f7 0%, #f3e1fc 100%)',
      }}
    >
      {!isEmpty(pages) && pagination !== OnboardingPage.Landing && (
        <Grid item xs={12}>
          <OnboardingHeader pagination={pagination} />
        </Grid>
      )}
      <Grid item xs={11} sm={10} md={8}>
        {!isEmpty(pages) &&
        !isEmpty(answers) &&
        !isEmpty(onboardingQuestions) ? (
          <>
            <OnboardingProgress {...props} />

            {pagination === OnboardingPage.Landing && (
              <OnboardingLanding {...props} />
            )}

            {pagination === OnboardingPage.RatingAndSimpleSelect && (
              <>
                <OnboardingRatingQuestions
                  {...props}
                  ratingQuestions={ratingQuestions.filter(
                    withOnboardingChoices,
                  )}
                  getStatement={rating => rating.statement}
                  theme={theme}
                  isMobile={isMobile}
                />

                <OnboardingSimpleSelectQuestions
                  {...props}
                  simpleSelectQuestions={simpleSelectQuestions.filter(
                    withOnboardingChoices,
                  )}
                  theme={theme}
                  isMobile={isMobile}
                />
              </>
            )}

            {pagination === OnboardingPage.ProfileQuestions && (
              <OnboardingProfileQuestions {...props} theme={theme} />
            )}

            {pagination === OnboardingPage.Reward &&
              !isEmpty(rewardQuestions) && (
                <OnboardingRatingQuestions
                  {...props}
                  ratingQuestions={rewardQuestions.filter(
                    withOnboardingChoices,
                  )}
                  getStatement={question => getRewardName(question.reward)}
                  theme={theme}
                  isMobile={isMobile}
                />
              )}

            {pagination === OnboardingPage.Event && (
              <OnboardingEventQuestions
                {...props}
                eventQuestions={eventQuestions.filter(withOnboardingChoices)}
                theme={theme}
              />
            )}

            {pagination === OnboardingPage.ChoiceGroup && (
              <OnboardingChoiceGroupQuestions
                {...props}
                choiceGroupQuestions={choiceGroupQuestions.filter(
                  withOnboardingChoices,
                )}
                theme={theme}
                isMobile={isMobile}
              />
            )}

            <OnboardingPagination {...props} />
          </>
        ) : (
          <Grid item xs={12}>
            {[...Array(3)].map((el, index) => (
              <Box key={index} mt={3}>
                <Skeleton variant='rectangular' width={'100%'} height={250} />
              </Box>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
