import { Link } from 'react-router-dom'
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material'

import { SparckFlag } from 'components'

export default function CompanyCard(props: any) {
  const {
    list,
    isListLoading,
    title,
    emptyMessage,
    redirectMessage,
    redirectRoute,
    children,
  } = props

  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('md'),
    {
      defaultMatches: true,
    },
  )

  return (
    <Card style={{ position: 'relative' }}>
      <SparckFlag left={false} />
      <CardContent>
        {isListLoading ? (
          <Grid container justifyContent='center'>
            <CircularProgress size={48} />
          </Grid>
        ) : (
          <>
            <Typography
              color='text.primary'
              variant={isMobile ? 'h6' : 'h5'}
              style={{ maxWidth: `${isMobile && '150px'}` }}
            >
              {title}
            </Typography>
            <br />
            <br />
            <br />
            {list.length > 0 ? (
              children
            ) : (
              <Typography color='text.secondary' variant='h6'>
                {emptyMessage}
              </Typography>
            )}
            <Grid container justifyContent={isMobile ? 'center' : 'flex-end'}>
              <Link to={redirectRoute}>
                <Button color='primary' variant='outlined' size='large'>
                  {redirectMessage}
                </Button>
              </Link>
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  )
}
