export enum RewardType {
  Company = 'company',
  Retailer = 'retailer',
  Corporate = 'corporate',
}

export enum RewardProductType {
  Company = 'Rewards::Product',
  GiftCard = 'Rewards::NGC::Product',
  VirginExperiences = 'VirginExperience::Catalog::ProductOption',
}

export enum RewardProvider {
  Company = 'company',
  NGC = 'ngc',
  VirginExperiences = 'virgin_experience',
  Corporate = 'corporate',
}

export enum RewardQuantityControl {
  QuantityLimited = 'quantity_limited',
  QuantityUnlimited = 'quantity_unlimited',
}

export enum RewardIntentionStatus {
  Waiting = 'waiting',
  Accepted = 'accepted',
  Banked = 'in_wallet',
  Redeemed = 'redeemed',
}
