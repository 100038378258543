import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material'
import { get } from 'lodash'
import { useIntl } from 'react-intl'
import * as yup from 'yup'

import { FormHandler } from 'components'
import { useCompany } from 'contexts'
import { API } from 'core/requests'
import { t } from 'i18n'
import { CompanyType } from 'types'

const applications = [
  {
    id: 'beheard_application',
    label: '#BeHeard',
  },
  {
    id: 'beseen_application',
    label: '#BeSeen',
  },
]

const validationSchema = yup.object({
  name: yup.string().required(t('companies.validations.companyNameIsRequired')),
  allow_internationalization: yup.boolean(),
})

export default function ManageCompany({
  dialog,
  closeDialog,
  fetchRows,
}: any) {
  const intl = useIntl()
  const { company } = useCompany()!

  const initialValues: CompanyType = dialog.data
    ? dialog.data
    : {
        name: '',
        beseen_application: false,
        beheard_application: false,
        allow_internationalization: false,
        has_integrations: false,
      }

  return (
    <FormHandler
      title={intl.formatMessage({ id: 'companies.title' })}
      requestUrl='companies'
      customMutationRequest={(payload: any) => {
        const companyId = get(payload, 'id') || get(company, 'id')

        return API.update('companies', payload, {
          pathParams: {
            id: companyId ? companyId : null,
          },
        })
      }}
      data={dialog.data}
      closeDialog={closeDialog}
      fetchRows={fetchRows}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ formik }) => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id='name'
              name='name'
              data-cy='companyNameInput'
              label={intl.formatMessage({ id: 'companies.companyName' })}
              variant='outlined'
              value={formik.values.name}
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            {applications.map(application => (
              <FormControlLabel
                key={application.id}
                control={
                  <Checkbox
                    checked={!!formik.values[application.id]}
                    onChange={formik.handleChange}
                    name={application.id}
                    data-cy='checkbox'
                  />
                }
                label={intl.formatMessage(
                  { id: 'companies.allowApplication' },
                  { application: application.label },
                )}
              />
            ))}

            <FormControlLabel
              key={'enable_rewards_store'}
              control={
                <Checkbox
                  checked={!!formik.values.enable_rewards_store}
                  onChange={formik.handleChange}
                  name={'enable_rewards_store'}
                  data-cy='checkbox'
                />
              }
              label={intl.formatMessage({
                id: 'companies.enableRewardsStore',
              })}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              key={'allow_internationalization'}
              control={
                <Checkbox
                  checked={!!formik.values.allow_internationalization}
                  onChange={formik.handleChange}
                  name={'allow_internationalization'}
                  data-cy='checkbox'
                />
              }
              label={intl.formatMessage({
                id: 'companies.allowInternationalization',
              })}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              key={'has_integrations'}
              control={
                <Checkbox
                  checked={!!formik.values.has_integrations}
                  onChange={() =>
                    formik.setFieldValue(
                      'has_integrations',
                      !formik.values.has_integrations,
                    )
                  }
                  name={'has_integrations'}
                  data-cy='checkbox'
                />
              }
              label={'Has external Sparck API access'}
            />
          </Grid>
        </Grid>
      )}
    </FormHandler>
  )
}
