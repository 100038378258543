import { useState, useEffect } from 'react'

import {
  Button,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { useIntl } from 'react-intl'

import { MoneyInput } from 'components'
import { allowedDemographicsCategories } from 'constants/demographics'
import { t } from 'i18n'

export default function ManageBudgetDistributionDemographics({
  formik,
  demographics,
  demographicsIsLoading,
  type,
}) {
  const [distributeEvenlyAmount, setDistributeEvenlyAmount] = useState<any>(0)
  const [selectedDemographicCategoryId, setSelectedDemographicCategoryId] =
    useState(formik.values[type].category_id ?? null)

  const intl = useIntl()

  const selectedDemographicCategory = demographics?.find(
    d => d.id === selectedDemographicCategoryId,
  )

  const handleAmountChange = (attribute, value) => {
    const newValues = formik.values[type].amount

    newValues.find(
      demographic => demographic.demographic_id === attribute.demographic_id,
    ).amount = value

    formik.setFieldValue(type, {
      ...formik.values[type],
      amount: newValues,
    })
  }

  const distributeAmountEvenlyByDemographics = () => {
    const divider = selectedDemographicCategory?.demographics_attributes?.length

    const evenlyAmount =
      Math.round((distributeEvenlyAmount / divider + Number.EPSILON) * 100) /
      100

    let evenlyAmountAccumulator = 0

    const newDemographicAttributes =
      selectedDemographicCategory?.demographics_attributes.map(demographic => {
        evenlyAmountAccumulator += evenlyAmount

        return {
          ...demographic,
          demographic_id: demographic.id,
          demographic_name: demographic.name,
          amount: evenlyAmount,
        }
      })

    newDemographicAttributes[newDemographicAttributes.length - 1].amount =
      evenlyAmount + (distributeEvenlyAmount - evenlyAmountAccumulator)

    formik.setFieldValue(type, {
      ...formik.values[type],
      amount: newDemographicAttributes,
    })
  }

  const distributeAmountEvenlyByIndividuals = () => {
    const totalUsers =
      selectedDemographicCategory?.demographics_attributes.reduce(
        (acc, demographic) => acc + demographic.users?.length,
        0,
      )

    const divider = totalUsers

    const evenlyAmount =
      Math.round((distributeEvenlyAmount / divider + Number.EPSILON) * 100) /
      100

    let evenlyAmountAccumulator = 0

    const newDemographicAttributes =
      selectedDemographicCategory?.demographics_attributes.map(demographic => {
        evenlyAmountAccumulator += evenlyAmount * demographic.users?.length

        return {
          ...demographic,
          demographic_id: demographic.id,
          demographic_name: demographic.name,
          amount: evenlyAmount * demographic.users?.length,
        }
      })

    formik.setFieldValue(type, {
      ...formik.values[type],
      amount: newDemographicAttributes,
    })

    setDistributeEvenlyAmount(+evenlyAmountAccumulator)
  }

  useEffect(() => {
    if (selectedDemographicCategoryId) {
      formik.setFieldValue(type, {
        ...formik.values[type],
        category_id: selectedDemographicCategoryId,
        amount: selectedDemographicCategory?.demographics_attributes.map(
          demographic => ({
            demographic_id: demographic.id,
            demographic_name: demographic.name,
            amount: 0,
          }),
        ),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDemographicCategoryId])

  return (
    <Grid container>
      {demographics && (
        <Grid item xs={12}>
          <TextField
            select
            id='selectedDemographicCategoryId'
            name='selectedDemographicCategoryId'
            label={intl.formatMessage(
              { id: 'budget.manageBudgetDistributionDemographics.breakdownBy' },
              {
                selectedDemographic:
                  selectedDemographicCategory?.name ??
                  intl.formatMessage({
                    id: 'budget.manageBudgetDistributionDemographics.selectedDemographic',
                  }),
              },
            )}
            variant='outlined'
            value={selectedDemographicCategoryId ?? ''}
            required
            onChange={(event: any) =>
              setSelectedDemographicCategoryId(event.target.value)
            }
            fullWidth
            margin='normal'
            disabled={demographicsIsLoading}
          >
            {demographics
              ?.filter(d => allowedDemographicsCategories.includes(d.category))
              .map(demographic => (
                <MenuItem key={demographic.id} value={demographic.id}>
                  {demographic.name}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
      )}

      {selectedDemographicCategoryId && (
        <>
          <Grid item xs={12} mt={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  id='distributeEvenlyAmount'
                  name='distributeEvenlyAmount'
                  label={t(
                    'budget.manageBudgetDistributionDemographics.labels.distributeEvenly',
                  )}
                  variant='outlined'
                  value={distributeEvenlyAmount}
                  onChange={event =>
                    setDistributeEvenlyAmount(event.target.value)
                  }
                  fullWidth
                  size='small'
                  InputProps={{
                    inputComponent: MoneyInput,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={6} sm={4}>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={distributeAmountEvenlyByDemographics}
                  disabled={!distributeEvenlyAmount}
                  fullWidth
                >
                  {t(
                    'budget.manageBudgetDistributionDemographics.byDemographic',
                  )}
                </Button>
              </Grid>

              <Grid item xs={6} sm={4}>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={distributeAmountEvenlyByIndividuals}
                  fullWidth
                  disabled={!distributeEvenlyAmount}
                >
                  {t('budget.manageBudgetDistributionDemographics.byEmployee')}
                </Button>
              </Grid>

              <Grid item xs={12} mb={2}>
                <FormHelperText>
                  {t(
                    'budget.manageBudgetDistributionDemographics.formHelperText',
                  )}
                </FormHelperText>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {formik.values[type]?.amount?.map(attribute => (
              <Grid
                container
                spacing={2}
                alignItems='center'
                key={attribute.demographic_id}
              >
                <Grid item xs={6} sm={8}>
                  <Typography variant='body1'>
                    {attribute.demographic_name}
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={4}>
                  <TextField
                    id='amount'
                    name='amount'
                    label={t(
                      'budget.manageBudgetDistributionDemographics.labels.amount',
                    )}
                    variant='outlined'
                    value={attribute.amount}
                    onChange={event =>
                      handleAmountChange(attribute, event.target.value)
                    }
                    fullWidth
                    margin='dense'
                    InputProps={{
                      inputComponent: MoneyInput,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Grid>
  )
}
