import { MediaType } from 'components/MediaPicker'

export const getRecognitionMedia = recognition => {
  if (recognition?.card?.url) {
    return {
      src: recognition.card.url,
      name: recognition.card.title,
      isImage: true,
      type: MediaType.SparckCard,
    }
  }

  if (recognition?.media_url || recognition?.external_media_url) {
    return {
      src: recognition.media_url ?? recognition.external_media_url,
      name: recognition.media_url ?? recognition.external_media_url,
      isImage: true,
      type: MediaType.URL,
    }
  }

  if (recognition.gif_id_on_provider) {
    return {
      id: recognition.gif_id_on_provider,
      src: '',
      name: recognition.gif_id_on_provider,
      isImage: true,
      type: MediaType.Gif,
    }
  }
}
