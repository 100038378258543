import { useState } from 'react'

import { Box, Button, CircularProgress, Grid, useTheme } from '@mui/material'
import { useHistory, useLocation } from 'react-router'
import { Link } from 'react-router-dom'

import {
  ConfirmationDialog,
  DialogTeleport,
  SurveyOpenAndCloseDateDialog,
  SurveyPreviewDialog,
} from 'components'
import { initialDialog } from 'constants/dialog'
import { ROUTES_PATHS } from 'core/routes'
import { SurveyStatus as PulseSurveyStatus } from 'enums/survey'
import { t } from 'i18n'
import { ManagePulseSurvey, PulseSurveys } from 'pages'
import dayjs from 'dayjs'

export default function PulseSurveyOverviewActions({
  selectedPulseSurvey,
  // setSelectedPulseSurvey,
  setConfirmCloseDialogOpen,
  setConfirmDeleteDialogOpen,
  confirmLaunchDialogOpen,
  setConfirmLaunchDialogOpen,
  extendPulseSurveyDialogOpen,
  setExtendPulseSurveyDialogOpen,
  extendPulseSurveyMutation,
  launchPulseSurvey,
  refetchPulseSurveys,
  addPulseSurveyToCart,
  addPulseSurveyToCartIsLoading,
}: any) {
  const theme = useTheme()

  const [dialogManagePulseSurvey, setDialogManagePulseSurvey] =
    useState(initialDialog)
  const [closeDateDialogOpen, setCloseDateDialogOpen] = useState(false)
  const [surveyCloseDate, setSurveyCloseDate] = useState(
    selectedPulseSurvey?.close_at,
  )
  const [previewDialog, setPreviewDialog] = useState(initialDialog)

  const history = useHistory()
  const location = useLocation()

  selectedPulseSurvey?.questions_attributes?.sort((a: any, b: any) => a.position - b.position);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box mb={1}>
            <Button
              data-cy='new_pulse_survey'
              variant='outlined'
              color='primary'
              fullWidth
              onClick={() =>
                setDialogManagePulseSurvey({ isOpen: true, data: null })
              }
            >
              {t('pulseSurveyOverview.newPulseSurvey')}
            </Button>
          </Box>

          <Box my={1}>
            <Button
              data-cy='clone_pulse_survey'
              variant='outlined'
              color='primary'
              fullWidth
              onClick={() =>
                setDialogManagePulseSurvey({
                  isOpen: true,
                  data: {
                    ...selectedPulseSurvey,
                    isClone: true,
                  },
                })
              }
            >
              Clone Pulse Survey
            </Button>
          </Box>

          {(selectedPulseSurvey.status === PulseSurveyStatus.Scheduled ||
            selectedPulseSurvey.status ===
              PulseSurveyStatus.AwaitingManualOpening) && (
            <>
              <Box mb={1}>
                <Button
                  data-cy='launch_pulse_survey'
                  variant='outlined'
                  color='primary'
                  fullWidth
                  onClick={() => setCloseDateDialogOpen(true)}
                >
                  {t('pulseSurveyOverview.launchPulseSurvey')}
                </Button>
              </Box>
              <Box mb={1}>
                <Button
                  data-cy='edit_pulse_survey'
                  variant='outlined'
                  color='primary'
                  fullWidth
                  onClick={() =>
                    setDialogManagePulseSurvey({
                      isOpen: true,
                      data: selectedPulseSurvey,
                    })
                  }
                >
                  {t('pulseSurveyOverview.editPulseSurvey')}
                </Button>
              </Box>

              <Box mb={1}>
                <Button
                  data-cy='preview_pulse_survey'
                  variant='outlined'
                  color='primary'
                  fullWidth
                  onClick={() =>
                    setPreviewDialog({
                      isOpen: true,
                      data: selectedPulseSurvey,
                    })
                  }
                >
                  Preview Pulse Survey
                </Button>
              </Box>
            </>
          )}

          {selectedPulseSurvey.status !== PulseSurveyStatus.Open && (
            <Box mb={1}>
              <Button
                data-cy='delete_pulse_survey'
                variant='outlined'
                color='error'
                fullWidth
                onClick={() => setConfirmDeleteDialogOpen(true)}
              >
                {t('pulseSurveyOverview.deletePulseSurvey')}
              </Button>
            </Box>
          )}

          {selectedPulseSurvey.status === PulseSurveyStatus.Open && (
            <>
              <Box mb={1}>
                <Button
                  variant='outlined'
                  color='primary'
                  fullWidth
                  onClick={() => setExtendPulseSurveyDialogOpen(true)}
                >
                  {t('pulseSurveyOverview.extendPulseSurvey')}
                </Button>
              </Box>
              <Box mb={1}>
                <Button
                  data-cy='close_pulse_survey'
                  variant='outlined'
                  color='error'
                  fullWidth
                  onClick={() => setConfirmCloseDialogOpen(true)}
                >
                  {t('pulseSurveyOverview.closePulseSurvey')}
                </Button>
              </Box>
            </>
          )}

          {selectedPulseSurvey.status === PulseSurveyStatus.Close &&
            selectedPulseSurvey.report_level === 'free' && (
              <Box mb={1}>
                <Button
                  variant='contained'
                  sx={{
                    background: `linear-gradient(135deg, ${theme.palette.secondary.main}, ${theme.palette.error.light})`,
                  }}
                  fullWidth
                  onClick={addPulseSurveyToCart}
                  disabled={addPulseSurveyToCartIsLoading}
                >
                  {addPulseSurveyToCartIsLoading ? (
                    <CircularProgress size={24} color='secondary' />
                  ) : (
                    t('report.upgradeReport')
                  )}
                </Button>
              </Box>
            )}

          {selectedPulseSurvey.status === PulseSurveyStatus.Close &&
            selectedPulseSurvey.report_level === 'free' && (
              <Box mb={1}>
                <Link
                  to={`${ROUTES_PATHS.beheardReport}?id=${selectedPulseSurvey.id}&full_report=0`}
                >
                  <Button
                    variant='contained'
                    sx={{
                      background: `linear-gradient(135deg, ${theme.palette.secondary.main}, ${theme.palette.error.light})`,
                    }}
                    fullWidth
                    onClick={() => {}}
                  >
                    {t('report.freeReport')}
                  </Button>
                </Link>
              </Box>
            )}

          {selectedPulseSurvey.status === PulseSurveyStatus.Close &&
            selectedPulseSurvey.report_level === 'upgraded' && (
              <>
                <Box mb={1}>
                  <Link
                    to={`${ROUTES_PATHS.beheardReport}?id=${selectedPulseSurvey.id}&full_report=1`}
                  >
                    <Button
                      variant='contained'
                      sx={{
                        background: `linear-gradient(135deg, ${theme.palette.secondary.main}, ${theme.palette.error.light})`,
                      }}
                      fullWidth
                      onClick={() => {}}
                    >
                      {t('report.premiumReport')}
                    </Button>
                  </Link>
                </Box>

                <Box mb={1}>
                  <Link
                    to={`${ROUTES_PATHS.shareReport}?pulseSurvey_id=${selectedPulseSurvey.id}`}
                  >
                    <Button
                      variant='contained'
                      sx={{
                        background: `linear-gradient(135deg, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
                      }}
                      fullWidth
                      onClick={() => {}}
                    >
                      {t('report.shareReport')}
                    </Button>
                  </Link>
                </Box>
              </>
            )}

          {selectedPulseSurvey.status === PulseSurveyStatus.Close &&
            !(
              location.pathname.startsWith('/manage-pulse-surveys/') &&
              location.pathname.endsWith('insights')
            ) && (
              <Grid item xs='auto'>
                <Button
                  variant='contained'
                  sx={{
                    background: `linear-gradient(135deg, ${theme.palette.secondary.main}, ${theme.palette.error.light})`,
                  }}
                  fullWidth
                  onClick={() =>
                    history.push(
                      ROUTES_PATHS.pulseSurveyInsights.replace(
                        ':id',
                        selectedPulseSurvey.id,
                      ),
                    )
                  }
                >
                  {t('pulseSurveyOverview.pulseReport')}
                </Button>
              </Grid>
            )}
        </Grid>
      </Grid>
      {dialogManagePulseSurvey.isOpen && (
        <DialogTeleport
          dialogTitle=''
          dialogAction={null}
          dialogSize='md'
          dialogOpen={!!dialogManagePulseSurvey.isOpen}
          handleDialogClose={() =>
            setDialogManagePulseSurvey({ isOpen: false, data: null })
          }
          isFullWidth
        >
          <ManagePulseSurvey
            dialog={dialogManagePulseSurvey}
            closeDialog={() =>
              setDialogManagePulseSurvey({ isOpen: false, data: null })
            }
            fetchPulseSurveys={refetchPulseSurveys}
          />
        </DialogTeleport>
      )}

      {closeDateDialogOpen && (
        <SurveyOpenAndCloseDateDialog
          title='When do you want your survey to close?'
          isDialogOpen={closeDateDialogOpen}
          setIsDialogOpen={setCloseDateDialogOpen}
          survey={selectedPulseSurvey}
          action={date => {
           const formatedDate = dayjs(date).set('hour', 19).set('minute', 0).set('second', 0).format('YYYY-MM-DDTHH:mm:ss[Z]')
            setSurveyCloseDate(formatedDate)
            setCloseDateDialogOpen(false)
            setConfirmLaunchDialogOpen(true)
          }}
          showCloseDate
        />
      )}

      {extendPulseSurveyDialogOpen && (
        <SurveyOpenAndCloseDateDialog
          title={t('pulseSurveyOverview.extendPulseSurvey')}
          isDialogOpen={extendPulseSurveyDialogOpen}
          setIsDialogOpen={setExtendPulseSurveyDialogOpen}
          survey={selectedPulseSurvey}
          action={newCloseDate =>
            extendPulseSurveyMutation.mutate({
              id: selectedPulseSurvey.id,
              new_date: newCloseDate,
            })
          }
          showCloseDate
          isLoading={extendPulseSurveyMutation.isLoading}
        />
      )}

      <ConfirmationDialog
        dialogOpen={confirmLaunchDialogOpen}
        setDialogOpen={setConfirmLaunchDialogOpen}
        message={t('components.confirmationDialog.launchPulseSurveyMessage')}
        item={selectedPulseSurvey}
        actionProps={{
          onClick: () =>
            launchPulseSurvey.mutate({
              ...selectedPulseSurvey,
              close_at: surveyCloseDate,
            }),
          isLoading: launchPulseSurvey.isLoading,
          label: t('pulseSurveyOverview.launchPulseSurvey'),
        }}
      />

      <SurveyPreviewDialog
        size='xl'
        previewDialog={previewDialog}
        setPreviewDialog={setPreviewDialog}
      >
        <PulseSurveys preview={previewDialog?.data} />
      </SurveyPreviewDialog>
    </>
  )
}
